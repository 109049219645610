import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Stack,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import CloseIcon from "@mui/icons-material/Close";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import TableChartIcon from "@mui/icons-material/TableChart";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ArticleIcon from "@mui/icons-material/Article";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { East as ArrowIcon } from "@mui/icons-material";

import LanguageSelector from "./LanguageSelector";

const ExplainDialog = ({ open, onClose, onSubmit, theme }) => {
  const [topic, setTopic] = useState("");
  const [format, setFormat] = useState("text");
  const [language, setLanguage] = useState("svenska");
  const [storyTheme, setStoryTheme] = useState("");

  const handleSubmit = () => {
    if (!topic.trim()) return;

    const promptTemplates = {
      text: `Förklara ${topic} på ett tydligt och koncist sätt. Språk: ${language}`,
      table: `Förklara ${topic} i en överskådlig tabell med viktiga punkter och exempel. Språk: ${language}`,
      bullets: `Förklara ${topic} i punktform. Var noggrann med att inkludera huvudpunkter och viktiga detaljer. Språk: ${language}`,
      longform: `Ge mig en detaljerad förklaring av ${topic}. Inkludera bakgrund, huvudkoncept, exempel och praktiska tillämpningar. Språk: ${language}`,
      story: `Förklara ${topic} genom en engagerande historia med liknelser och exempel som gör det lätt att förstå. ${storyTheme ? `Temat ska vara: ${storyTheme}` : ""} Språk: ${language}`,
    };

    onSubmit(promptTemplates[format]);
    onClose();
    setTopic("");
    setFormat("text");
  };

  const formatButtons = [
    { value: "text", label: "Normal text", icon: TextFieldsIcon },
    { value: "table", label: "Tabell", icon: TableChartIcon },
    { value: "bullets", label: "Punktlista", icon: FormatListBulletedIcon },
    { value: "longform", label: "Utförlig text", icon: ArticleIcon },
    { value: "story", label: "Engagerande historia", icon: MenuBookIcon },
  ];

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: 700,
          backgroundColor:
            theme.palette.mode === "dark" ? "background.default" : "white",
          border:
            theme.palette.mode === "dark"
              ? "1px solid #323d48"
              : "1px solid #e2e8f0",
          p: 3,
        },
      }}
    >
      <IconButton
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 16,
          top: 16,
          color:
            theme.palette.mode === "dark"
              ? "rgba(255, 255, 255, 0.7)"
              : "inherit",
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 1,
          color:
            theme.palette.mode === "dark"
              ? "rgba(255, 255, 255, 0.7)"
              : "inherit",
          pt: 2,
        }}
      >
        <Typography variant="h5" fontWeight={700}>
          Vad vill du ha förklarat?
        </Typography>
      </DialogTitle>

      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: 4,
          pb: 4,
        }}
      >
        <TextField
          autoFocus
          fullWidth
          value={topic}
          onChange={(e) => setTopic(e.target.value)}
          placeholder="Skriv ämnet här..."
          sx={{
            mt: 2,
            mb: 4,
          }}
        />

        <Stack spacing={2} width="100%" alignItems="center" sx={{ mb: 4 }}>
          <Typography variant="subtitle1" fontWeight={600}>
            Välj språk:
          </Typography>
          <LanguageSelector language={language} setLanguage={setLanguage} />
        </Stack>

        <Stack spacing={3} width="100%" alignItems="center">
          <Typography variant="subtitle1" fontWeight={600}>
            Välj format:
          </Typography>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: 3,
              width: "100%",
            }}
          >
            {formatButtons.map(({ value, label, icon: Icon }) => (
              <Button
                key={value}
                variant={format === value ? "save" : "print"}
                onClick={() => setFormat(value)}
                sx={{ height: 45 }}
                color={format === value ? "primary" : "inherit"}
                startIcon={format === value ? <CheckCircleIcon /> : <Icon />}
              >
                {label}
              </Button>
            ))}
          </Box>
        </Stack>

        {/* Nytt textfält för story theme */}
        {format === "story" && (
          <Stack spacing={2} width="100%" alignItems="center" sx={{ mt: 4 }}>
            <Typography variant="subtitle1" fontWeight={600}>
              Valfritt tema för historien:
            </Typography>
            <TextField
              fullWidth
              value={storyTheme}
              onChange={(e) => setStoryTheme(e.target.value)}
              placeholder="T.ex. lastbilar, rymden, djur..."
              sx={{ mt: 1 }}
            />
          </Stack>
        )}
      </DialogContent>

      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          onClick={handleSubmit}
          variant="purp"
          disabled={!topic.trim()}
          sx={{ width: 200, height: 45 }}
        >
          Förklara <ArrowIcon sx={{ ml: 1 }} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExplainDialog;
