import React from "react";
import { Box } from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

// Basic formatting options
const modules = {
  toolbar: [
    [{ header: [1, 2, 3, false] }],
    ["blockquote"],
    [{ align: [] }],
    [{ color: [] }, { background: [] }],
    ["bold", "italic", "underline", "strike"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }],
    ["clean"],
  ],
  clipboard: {
    matchVisual: false, // Prevents Quill from adding extra breaks
  },
};

const formats = [
  "header",
  "blockquote",
  "color",
  "background",
  "bold",
  "italic",
  "underline",
  "strike",
  "list",
  "bullet",
  "indent",
];

const Quill = ({ value, onChange, theme }) => {
  return (
    <Box
      sx={{
        "& .ql-editor p": {
          fontFamily: "Poppins, Open Sans, -apple-system, georgia",
          fontSize: "16px !important",
          lineHeight: "1.8 !important",
          paddingBottom: "2.1rem !important",
        },
        "& .ql-editor blockquote": {
          fontFamily: "Poppins, Open Sans, -apple-system, georgia",
          fontSize: "16px !important",
          lineHeight: "1.8 !important",
          marginBottom: "2.1rem !important",
        },
        "& .ql-editor li": {
          fontFamily: "Poppins, Open Sans, -apple-system, georgia",
          fontSize: "16px !important",
          lineHeight: "1.8 !important",
        },
        "& .ql-editor ul": {
          fontFamily: "Poppins, Open Sans, -apple-system, georgia",
          fontSize: "16px !important",
          lineHeight: "1.8 !important",
          paddingBottom: "2.1rem !important",
        },
        "& .ql-editor ol": {
          fontFamily: "Poppins, Open Sans, -apple-system, georgia",
          fontSize: "16px !important",
          lineHeight: "1.8 !important",
          paddingBottom: "2.1rem !important",
        },
        "& .ql-editor h1": {
          fontFamily: "Poppins, Open Sans, -apple-system, georgia",
          fontSize: "32px !important",
          lineHeight: "1.2 !important",
          paddingBottom: "1.5rem !important",
        },
        "& .ql-editor h2": {
          fontFamily: "Poppins, Open Sans, -apple-system, georgia",
          fontSize: "24px !important",
          lineHeight: "1.3 !important",
          paddingBottom: "1.25rem !important",
        },
        "& .ql-editor h3": {
          fontFamily: "Poppins, Open Sans, -apple-system, georgia",
          fontSize: "20px !important",
          lineHeight: "1.4 !important",
          paddingBottom: "1rem !important",
        },
        "& .ql-toolbar": {
          fontFamily: "Poppins, Open Sans, -apple-system, georgia",
          borderTopLeftRadius: "8px !important",
          borderTopRightRadius: "8px !important",
          borderColor: theme.palette.mode === "dark" ? "#394755" : "#e0e0e0",
          padding: "14px",
        },
        "& .ql-container": {
          borderBottomLeftRadius: "8px !important",
          borderBottomRightRadius: "8px !important",
          borderColor: theme.palette.mode === "dark" ? "#394755" : "#e0e0e0",
        },
        "& .ql-editor": {
          padding: "20px",
        },
      }}
    >
      <ReactQuill
        theme="snow"
        value={value}
        onChange={onChange}
        modules={modules}
        formats={formats}
        style={{
          width: "100%",
          backgroundColor: theme.palette.mode === "dark" ? "#1e2732" : "#fff",
          borderRadius: "4px",
        }}
      />
    </Box>
  );
};

export default Quill;
