import React, { useState } from "react";
import {
  Box,
  Divider,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  IconButton,
  CircularProgress,
  Grid,
  Select,
  MenuItem,
  Switch,
  Tooltip,
  FormControl,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import QuizIcon from "@mui/icons-material/Quiz";
import { callApi } from "./api";
import QuestionTypeSelector from "./quiz/QuestionTypeSelector";
import TeacherQuizDisplay from "./TeacherQuizDisplay";
import GenerateIcon from "@mui/icons-material/AutoAwesome";
import { handlePrintQuiz, handlePrint1X2Quiz } from "./printFunctions";
import ProgressBar from "./ProgressBar";
import LanguageSelector from "./LanguageSelector";

import Logger from "./Logger";

const QuizDialog = ({
  open,
  onClose,

  lessonData,
  title,
  theme,
  user,
  defaultLanguage = "svenska",
  styles,
  showSnackbar,
  addQuizToLessonObject,
}) => {
  const [questionType, setQuestionType] = useState("multipleChoice");
  const [isGeneratingQuiz, setIsGeneratingQuiz] = useState(false);
  const [quizData, setQuizData] = useState(null);
  const [progress, setProgress] = useState(0);
  const [language, setLanguage] = useState(defaultLanguage);
  const [includeHints, setIncludeHints] = useState(true);
  const [numQuestions, setNumQuestions] = useState(5);
  const [aiInstructions, setAiInstructions] = useState("");

  const handleQuizUpdate = (updatedQuiz) => {
    setQuizData(updatedQuiz);
  };

  const simulateProgress = (
    startProgress,
    endProgress,
    duration,
    setProgressFn,
  ) => {
    const stepSize = 0.5;
    const steps = (endProgress - startProgress) / stepSize;
    const stepDuration = duration / steps;
    let currentProgress = startProgress;

    const intervalId = setInterval(() => {
      currentProgress += stepSize;
      if (currentProgress >= endProgress) {
        clearInterval(intervalId);
      } else {
        setProgressFn(currentProgress);
      }
    }, stepDuration);

    return () => clearInterval(intervalId);
  };

  const handleGenerateQuiz = async () => {
    setIsGeneratingQuiz(true);
    setProgress(0);
    setQuizData(null);
    let stopProgressSimulation = null;

    Logger.log("language: ", language);

    try {
      stopProgressSimulation = simulateProgress(0, 90, 15000, setProgress);

      // Base format examples for different question types with hints
      const formatExamples = {
        default: `[{
      "question": "Who won the FIFA World Cup in 2018?",
      "options": ["Brazil", "France", "Germany", "Argentina"],
      "correct": "France",
      ${
        includeHints
          ? `"hints": [
        "This team also won their first World Cup in 1998",
        "Their star player Kylian Mbappé was only 19 during this tournament",
        "They defeated Croatia in the final"
      ]`
          : ""
      }
    }]`,
        "fill-in-the-blank": `[{
      "question": "En sekvens av _ baser bestämmer genetisk information",
      "answer": "Kvävehaltiga",
      "options": ["Kvävehaltiga", "Guld", "Brom", "Gas"],
      ${
        includeHints
          ? `"hints": [
        "Denna typ av bas är en viktig del av DNA-strukturen",
        "Det finns fyra olika typer av dessa baser i DNA",
        "Dessa baser innehåller grundämnet N"
      ]`
          : ""
      }
    }]`,
        "true-or-false": `[{
      "question": "Is the Earth flat?",
      "options": ["Sant", "Falskt"],
      "correct": "Falskt",
      ${
        includeHints
          ? `"hints": [
        "Tänk på vad som händer när ett skepp seglar mot horisonten",
        "Fundera på månens form och andra himlakroppar",
        "Detta bevisades vetenskapligt redan på 1500-talet"
      ]`
          : ""
      }
    }]`,

        exam: `[{
      "question": "Förklara hur fotosyntesen fungerar och dess betydelse för livet på jorden",
      "type": "exam",
      "answer": "Fotosyntesen är den process där växter omvandlar solenergi till kemisk energi. ",
      ${
        includeHints
          ? `"hints": [
        "Tänk på vilka grundämnen som ingår i processen",
        "Fundera på vad som krävs för att processen ska fungera",
        "Reflektera över processens slutprodukter"
      ]`
          : ""
      }
    }]`,
      };

      let instructions = "";
      const hintInstruction = includeHints
        ? `Each question should include a 'hints' array with exactly 3 progressively more specific hints that help lead to the correct answer. The hints should be challenging enough that students need to think and connect information, but not so obvious that they give away the answer immediately. Make the hints creative and thought-provoking.`
        : "";

      if (questionType === "fill-in-the-blank") {
        instructions = `Generate a fill in the blanks quiz with ${numQuestions}  questions based on the provided text. ${aiInstructions}. Everything should be in ${language}. Each question should be a JSON object with a 'question' field containing the text with a blank (_), an 'options' field containing 4 options, and a 'correct' field. ${hintInstruction} Ensure all questions are returned in a single JSON array. Format example: ${formatExamples["fill-in-the-blank"]}`;
      } else if (questionType === "true-or-false") {
        instructions = `Generate a true or false quiz with ${numQuestions}  questions. ${aiInstructions}. The quiz should be in ${language}. Each question should be a JSON object with a "question" field, an "options" field containing only "True" and "False" as options, and a "correct" field. ${hintInstruction} Ensure all questions are returned in a single JSON array. Do not use any markdown formatting. Ensure that the JSON keys 'question', 'options', and 'correct' are kept in English, even when the content is in another language. Format example: ${formatExamples["true-or-false"]}`;
      } else if (questionType === "exam") {
        instructions = `Generate an exam quiz with ${numQuestions} questions. The quiz should be in ${language}. Each question should be a JSON object with a "question" field, a "type" field set to "exam", and an "answer" field containing the model answer. ${hintInstruction} Ensure all questions are returned in a single JSON array. Format example: ${formatExamples["exam"]} Ensure all questions are returned in a single JSON array. The questions should be open-ended and require written answers. Do not use any markdown formatting.`;
      } else {
        instructions = `Generate a quiz with ${numQuestions}  questions. ${aiInstructions}. The quiz should be in ${language}. Each question should be a JSON object with a "question" field, an "options" field containing 4 options, and a "correct" field. ${hintInstruction} Ensure all questions are returned in a single JSON array. Do not use any markdown formatting. Ensure that the JSON keys 'question', 'options', and 'correct' are kept in English, even when the content is in another language. Format example: ${formatExamples["default"]}`;
      }

      const response = await callApi(
        [
          {
            role: "system",
            content: instructions,
          },
          {
            role: "user",
            content: `Generate questions based on this lesson content: ${JSON.stringify(lessonData)}.  The quiz should be in ${language}`,
          },
        ],
        6000,
        true,
        user.uid,
      );

      if (stopProgressSimulation) stopProgressSimulation();
      setProgress(95);

      let messageContent = response.data.choices[0].message.content.trim();
      if (messageContent.startsWith("```json")) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith("```")) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      const questions = JSON.parse(messageContent);

      Logger.log("Quiz: ", JSON.stringify(questions, null, 2));

      setProgress(100);
      await new Promise((resolve) => setTimeout(resolve, 800));
      setQuizData(questions);
    } catch (error) {
      console.error("Error generating quiz:", error);
      showSnackbar("Något gick fel. Vänligen försök igen.", "error");
    } finally {
      setIsGeneratingQuiz(false);
    }
  };

  const handleClose = () => {
    onClose();
    // Optional: Reset state when dialog closes
    setQuizData(null);
    setProgress(0);
    setNumQuestions(5);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: { minWidth: "1200px" },
      }}
    >
      <DialogTitle sx={{ padding: "20px" }}>
        <Box sx={{ position: "relative" }}>
          <Typography
            variant="h6"
            sx={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "1.6rem",
              padding: "5px",
            }}
          >
            Generera quiz
          </Typography>
          <IconButton
            onClick={handleClose}
            sx={{ position: "absolute", top: 0, right: 0 }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Box sx={{ p: 2, width: "100%" }}>
          <Box sx={{ mb: 6 }}>
            <QuestionTypeSelector
              selected={questionType}
              setQuestionType={setQuestionType}
              theme={theme}
              user={user}
            />
          </Box>

          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Typography
                variant="formLabelSinUnderline"
                sx={{
                  fontWeight: "500",

                  display: "block",
                  mb: 1,
                }}
              >
                Språk:
              </Typography>

              <LanguageSelector language={language} setLanguage={setLanguage} />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <Typography
                  variant="formLabelSinUnderline"
                  sx={{ fontWeight: "500" }}
                >
                  Antal frågor
                </Typography>
                <Select
                  labelId="num-questions-label"
                  id="num-questions-select"
                  value={numQuestions}
                  sx={{ mt: 1 }}
                  onChange={(e) => setNumQuestions(e.target.value)}
                >
                  <MenuItem value={2} selected>
                    2
                  </MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={7}>7</MenuItem>

                  <Tooltip title="Endast tillgängligt för premium" arrow>
                    <span>
                      <MenuItem value={10} disabled>
                        10
                      </MenuItem>
                      <MenuItem value={15} disabled>
                        15
                      </MenuItem>
                      <MenuItem value={20} disabled>
                        20
                      </MenuItem>
                      <MenuItem value={25} disabled>
                        25
                      </MenuItem>
                      <MenuItem value={30} disabled>
                        30
                      </MenuItem>
                      <MenuItem value={40} disabled>
                        40
                      </MenuItem>
                      <MenuItem value={50} disabled>
                        50
                      </MenuItem>
                    </span>
                  </Tooltip>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography
                variant="formLabelSinUnderline"
                sx={{ display: "block", mt: 0, fontWeight: "500" }}
              >
                Kompletterande instruktioner (valfritt):
              </Typography>

              <TextField
                fullWidth
                sx={{ mt: 1 }}
                margin="normal"
                placeholder="T.ex. 'Ta inte med händelser från år 1942', eller 'undvik böcker skrivna av amerikanska författare'."
                value={aiInstructions}
                onChange={(e) => setAiInstructions(e.target.value)}
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                variant="formLabelSinUnderline"
                sx={{ display: "block", mt: 0, mb: 1, fontWeight: "500" }}
              >
                Ta med ledtrådar
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  backgroundColor:
                    theme.palette.mode === "dark" ? "#394653" : "#f5f5f5",
                  borderRadius: "8px",
                  mb: 4,
                  mt: 0,
                  padding: 1,
                  paddingLeft: "15px",
                }}
              >
                <Typography variant="formLabelSinUnderline">
                  Inkludera ledtrådar
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Switch
                    checked={includeHints}
                    onChange={(e) => setIncludeHints(e.target.checked)} // This is the correct way
                    inputProps={{ "aria-label": "include hints" }}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "12px",
              width: "100%",
            }}
          >
            <Button
              variant={theme.palette.mode === "light" ? "purp" : "contained"}
              color="primary"
              onClick={handleGenerateQuiz}
              disabled={isGeneratingQuiz}
              startIcon={
                !isGeneratingQuiz ? (
                  <GenerateIcon style={{ color: "#fff" }} />
                ) : (
                  <CircularProgress style={{ color: "#fff" }} size={16} />
                )
              }
              sx={{ mt: 2, mb: 2 }}
            >
              {isGeneratingQuiz ? "Genererar quiz..." : "Generera quiz"}
            </Button>
          </div>

          {isGeneratingQuiz && (
            <ProgressBar progress={progress} label="Genererar quiz..." />
          )}

          {quizData && (
            <TeacherQuizDisplay
              quiz={quizData}
              quizName={title}
              user={user}
              questionType={questionType}
              onQuizUpdate={handleQuizUpdate}
              addQuizToLessonObject={(updateFn) => {
                addQuizToLessonObject(updateFn);
                showSnackbar("Quiz tillagt under din lektion!", "success");
                handleClose();
              }}
              styles={styles}
              onPrint={() =>
                questionType === "current-events"
                  ? handlePrint1X2Quiz(
                      quizData,
                      title,
                      questionType,
                      theme.palette.primary.main,
                    )
                  : handlePrintQuiz(
                      quizData,
                      title,
                      questionType,
                      theme.palette.primary.main,
                    )
              }
            />
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

const AddonQuiz = ({
  lessonData,
  title,
  theme,
  user,
  defaultLanguage = "svenska",
  styles,
  showSnackbar,
  addQuizToLessonObject,
  buttonRef,
  buttonText = "Quiz",
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Button
        ref={buttonRef}
        variant="purp"
        startIcon={<GenerateIcon />}
        onClick={handleOpen}
      >
        {buttonText}
      </Button>

      <QuizDialog
        open={open}
        onClose={handleClose}
        lessonData={lessonData}
        title={title}
        theme={theme}
        styles={styles}
        user={user}
        defaultLanguage={defaultLanguage}
        showSnackbar={showSnackbar}
        addQuizToLessonObject={addQuizToLessonObject}
      />
    </>
  );
};

export default AddonQuiz;
