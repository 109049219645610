import React, { useState, useRef, useEffect } from "react";

import {
  TextField,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  Typography,
  alpha,
  Chip,
  ListItemText,
  ListItemSecondaryAction,
  Avatar,
  CircularProgress,
  Button,
  Box,
  Tooltip,
  Paper,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Search as SearchIcon } from "@mui/icons-material";
import {
  collection,
  query,
  where,
  limit,
  getDocs,
  orderBy,
  startAt,
  endAt,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "./firebase";
import debounce from "lodash/debounce";
import { useSnackbar } from "./SnackbarHandler";

import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
const TeacherSearch = ({ currentUserId, styles, theme }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { showSnackbar } = useSnackbar();
  const [disabledButtons, setDisabledButtons] = useState(new Set());

  const wrapperRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setSearchResults([]);
        setSearchTerm("");
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Debounced search function to minimize Firestore reads
  const debouncedSearch = debounce(async (term) => {
    if (!term || term.length < 2) {
      setSearchResults([]);
      return;
    }

    setIsLoading(true);
    try {
      // Query for teachers where displayName starts with the search term
      // Using compound index on accountType and displayName for efficiency
      const teachersRef = collection(db, "users");
      const q = query(
        teachersRef,
        // Moonshine: tar vi bort acctype = teacher kommer reads öka markant - kolla firestore
        //where("accountType", "==", "teacher"),
        orderBy("displayName"),
        // Try BOTH capitalizations
        startAt(term.toLowerCase()),
        endAt(term.toLowerCase() + "\uf8ff"),
        limit(5),
      );

      // Get first batch
      const snapshot1 = await getDocs(q);

      const q2 = query(
        teachersRef,
        //where("accountType", "==", "teacher"),
        orderBy("displayName"),
        // Try BOTH capitalizations
        startAt(term[0].toUpperCase() + term.slice(1)),
        endAt(term[0].toUpperCase() + term.slice(1) + "\uf8ff"),
        limit(5),
      );

      // Get second batch
      const snapshot2 = await getDocs(q2);

      // Combine results
      const teachers = [
        ...snapshot1.docs.map((doc) => ({ id: doc.id, ...doc.data() })),
        ...snapshot2.docs.map((doc) => ({ id: doc.id, ...doc.data() })),
      ].filter((teacher) => teacher.id !== currentUserId);

      setSearchResults(teachers);
    } catch (error) {
      console.error("Error searching teachers:", error);
    } finally {
      setIsLoading(false);
    }
  }, 150);

  const handleSearchChange = (event) => {
    const term = event.target.value;
    setSearchTerm(term);
    debouncedSearch(term);
  };

  const sendFriendRequest = async (recipientId) => {
    try {
      setDisabledButtons((prev) => new Set([...prev, recipientId]));

      // Add notification with type friend_request
      await addDoc(collection(db, "notifications"), {
        senderId: currentUserId,
        recipientId,
        type: "friend_request",
        status: "pending",
        read: false,
        createdAt: serverTimestamp(),
      });

      showSnackbar("Förfrågan skickad!", "success");
    } catch (error) {
      console.error("Error sending friend request:", error);
    }
  };

  return (
    <Box
      ref={wrapperRef}
      sx={{ width: "100%", maxWidth: 500, mx: "auto", position: "relative" }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <TextField
          fullWidth
          value={searchTerm}
          onChange={handleSearchChange}
          placeholder="Sök efter lärare..."
          sx={{
            borderRadius: "50px",
            "& .MuiInputBase-root": {
              backgroundColor:
                theme.palette.mode === "dark" ? "#15202b" : "#ececec",
              borderRadius: "50px",
            },

            "& .MuiOutlinedInput-notchedOutline": {
              border: "0 !important",
              outline: "none !important",
              boxShadow: "none !important",
              borderRadius: "50px",
            },
            "& .MuiOutlinedInput-root": {
              border: "0 !important",
              outline: "none !important",
              borderRadius: "50px",
              boxShadow: "none !important",
              "&:hover .MuiOutlinedInput-notchedOutline": {
                border: "0 !important",
                outline: "none !important",
                boxShadow: "none !important",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "0 !important",
                outline: "none !important",
                boxShadow: "none !important",
              },
            },
          }}
          InputProps={{
            sx: {
              "& input": {
                fontWeight: 400,
                borderRadius: "50px",
                height: "15px",
                border: "none",
                color: theme.palette.mode === "dark" ? "#8899a6" : "black",
              },
            },
            startAdornment: (
              <Box
                sx={{
                  width: 24,
                  height: 24,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mr: 1,
                }}
              >
                {isLoading ? (
                  <CircularProgress size={16} />
                ) : (
                  <SearchIcon sx={{ fontSize: 20 }} color="action" />
                )}
              </Box>
            ),
          }}
        />

        <Tooltip title="Blir man vänner så kan man enkelt dela pedagogiskt material med varandra.">
          <IconButton size="small" sx={{ ml: 0.5, cursor: "default" }}>
            <HelpOutlineIcon fontSize="small" />
          </IconButton>
        </Tooltip>

        {searchResults.length > 0 && (
          <Box
            sx={{
              ...styles.freshBox,
              boxShadow:
                "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
              padding: "10px",
              paddingBottom: "15px",
              position: "absolute",
              width: "calc(100% + 110px)",
              top: "100%", // Add this to position below the search field
              left: "50%", // Add this for horizontal centering
              transform: "translateX(-50%)", // Add this for horizontal centering
              zIndex: 1000,
              mt: 1,
              borderRadius: 4,
              maxHeight: "300px",
              overflow: "auto",
            }}
          >
            <List sx={{ marginBottom: "0px", paddingBottom: "0px" }}>
              {searchResults.map((teacher) => (
                <ListItem
                  key={teacher.id}
                  sx={{
                    "&:hover": {
                      borderRadius: "18px !important",
                    },
                  }}
                >
                  <ListItemAvatar>
                    <Avatar
                      src={teacher.photoURL}
                      alt={teacher.displayName}
                      sx={{ width: "30px", height: "30px" }}
                    >
                      {teacher.displayName?.[0]}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={teacher.displayName}
                    primaryTypographyProps={{
                      fontWeight: 500, // or 'bold'
                    }}
                    sx={{ marginLeft: "-13px" }}
                  />

                  {teacher.id === "Z6BLSMsm38Un5mgW0O6Z3TToHq12" && (
                    <Chip
                      size="small"
                      label="Administratör"
                      sx={{
                        position: "absolute",
                        left: 120,
                        height: "24px",
                        background: "rgba(255, 77, 77, 0.1)",
                        color: "#FF4D4D",

                        fontSize: "11px",
                        fontWeight: 500,
                        "& .MuiChip-icon": {
                          fontSize: 14,
                          color: "inherit",
                        },
                      }}
                    />
                  )}

                  <ListItemSecondaryAction>
                    <Button
                      variant="purp"
                      startIcon={
                        disabledButtons.has(teacher.id) ? "" : <AddIcon />
                      }
                      sx={{ ...styles.tightButton }}
                      disabled={disabledButtons.has(teacher.id)}
                      onClick={() => sendFriendRequest(teacher.id)}
                    >
                      {disabledButtons.has(teacher.id) ? "Vän" : "Vän"}
                    </Button>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default TeacherSearch;
