import React from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  useTheme,
  Link as MuiLink,
} from "@mui/material";
import FeedbackIcon from "@mui/icons-material/Feedback";
import { Link as RouterLink } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { useLocation } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";

const Footer = ({ contactFormRef }) => {
  const theme = useTheme();

  const location = useLocation();
  const isStartPage = location.pathname === "/";

  const linkStyle = {
    color: theme.palette.text.secondary,
    textDecoration: "none",

    marginBottom: "10px",
    display: "block",
    "&:hover": {
      color:
        theme.palette.mode === "dark"
          ? theme.palette.primary.light
          : theme.palette.primary.dark,
    },
  };

  const scrollToContact = () => {
    contactFormRef?.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Box
      component="footer"
      sx={{
        bgcolor: theme.palette.background.default,
        padding: "60px",
        paddingTop: "100px",
        paddingBottom: "100px",
      }}
    >
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          {/* First column */}
          <Grid item xs={12} sm={6} md={3}>
            <Box
              component="img"
              src="/img/AI.png"
              alt="Studera.AI Logo"
              sx={{ height: 40, mb: 2 }}
            />

            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ fontSize: "1.0rem" }}
            >
              AI-verktyg för lärare, pedagoger, vikarier, läxhjälpare, elever
              och studenter.
              <br />
              <br />
              Vi revolutionerar lärande med AI-teknologi för att göra utbildning
              mer tillgänglig och effektiv för alla.
            </Typography>
          </Grid>

          {/* Third column */}
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant="h6" gutterBottom sx={{ mb: 3.4 }}>
              Resurser
            </Typography>

            <MuiLink
              component={RouterLink}
              to="/ai-verktyg-larare"
              sx={linkStyle}
            >
              AI-verktyg för lärare
            </MuiLink>
            <MuiLink
              component={RouterLink}
              to="/ai-verktyg-elever"
              sx={linkStyle}
            >
              AI-verktyg för elever
            </MuiLink>
            <MuiLink
              component={RouterLink}
              to="/integritetspolicy-gdpr"
              sx={linkStyle}
            >
              Integritetspolicy & GDPR
            </MuiLink>
            <MuiLink component={RouterLink} to="/om-oss" sx={linkStyle}>
              Om Studera.ai
            </MuiLink>
            <MuiLink component={RouterLink} to="#" sx={linkStyle}>
              Artiklar
            </MuiLink>
          </Grid>

          {/* Second column */}
          <Grid item xs={12} sm={6} md={3} sx={{ display: "none" }}>
            <Typography variant="h6" gutterBottom sx={{ mb: 3.4 }}>
              Populärt
            </Typography>
            <MuiLink
              component={RouterLink}
              to="/funktioner/fraga-ai"
              sx={linkStyle}
            >
              Fråga AI
            </MuiLink>
            <MuiLink
              component={RouterLink}
              to="/skapa-kurser-med-ai"
              sx={linkStyle}
            >
              Skapa kurser med AI
            </MuiLink>
            <MuiLink
              component={RouterLink}
              to="/funktioner/lar-dig-sprak-med-ai"
              sx={linkStyle}
            >
              Lär dig språk med AI
            </MuiLink>
            <MuiLink
              component={RouterLink}
              to="/funktioner/skapa-kurser-med-ai"
              sx={linkStyle}
            >
              Skapa kurser med AI
            </MuiLink>
            <MuiLink
              component={RouterLink}
              to="/funktioner/skapa-quiz-med-ai"
              sx={linkStyle}
            >
              Skapa quiz med AI
            </MuiLink>
          </Grid>

          {/* Fourth column */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" gutterBottom sx={{ mb: 3.4 }}>
              Kontakta oss
            </Typography>
            <Box display="flex" alignItems="center" mb={1}>
              <EmailIcon sx={{ mr: 1, color: "text.secondary" }} />
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ fontSize: "16px" }}
              >
                info@studera.ai
              </Typography>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              mb={1}
              sx={{ display: "none" }}
            >
              <PhoneIcon sx={{ mr: 1, color: "text.secondary" }} />
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ fontSize: "16px" }}
              >
                +46 735 91 33 20
              </Typography>
            </Box>

            <Box display="flex" alignItems="center" mb={1}>
              <HomeIcon sx={{ mr: 1, color: "text.secondary" }} />
              <MuiLink
                component={RouterLink}
                to="/om-oss"
                sx={{
                  color: "text.secondary",
                  textDecoration: "none",
                  "&:hover": {
                    color:
                      theme.palette.mode === "dark"
                        ? theme.palette.primary.light
                        : theme.palette.primary.dark,
                  },
                  fontSize: "16px",
                }}
              >
                Om Studera.AI
              </MuiLink>
            </Box>

            {isStartPage && (
              <Box display="flex" alignItems="center" mb={1} mt={4}>
                <FeedbackIcon sx={{ mr: 1, color: "text.secondary" }} />
                <MuiLink
                  onClick={scrollToContact}
                  sx={{
                    ...linkStyle,
                    marginBottom: 0,
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  Lämna önskemål
                </MuiLink>
              </Box>
            )}

            {/*
            <Box display='flex' alignItems='center'>
              <LocationOnIcon sx={{ mr: 1, color: 'text.secondary' }} />
              <Typography variant='body2' color='text.secondary'>
                Gladiovägen 167
              </Typography>
            </Box>
            */}
          </Grid>
        </Grid>
      </Container>

      <Grid container sx={{ mt: 10 }} spacing={3}>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            align="center"
            gutterBottom
            sx={{ mb: 2, fontSize: "17px" }}
          >
            FÖLJ OSS PÅ SOCIALA MEDIER
          </Typography>
          <Box display="flex" justifyContent="center" gap={4}>
            <MuiLink
              href="https://www.facebook.com/groups/1581685186109626/"
              target="_blank"
              sx={{
                color: "text.secondary",
                "&:hover": { color: theme.palette.primary.main },
                display: "flex",
                alignItems: "center",
                gap: 1,
                textDecoration: "none",
              }}
            >
              <FacebookIcon sx={{ fontSize: 24 }} />
              <Typography>Facebook</Typography>
            </MuiLink>
            <MuiLink
              href="https://linkedin.com"
              target="_blank"
              sx={{
                color: "text.secondary",
                "&:hover": { color: theme.palette.primary.main },
                display: "flex",
                alignItems: "center",
                gap: 1,
                textDecoration: "none",
                display: "none",
              }}
            >
              <LinkedInIcon sx={{ fontSize: 24 }} />
              <Typography sx={{ textDecoration: "none" }}>Linkedin</Typography>
            </MuiLink>
            <MuiLink
              href="https://www.youtube.com/channel/UCoZ28VBVOPkQ2oiA3L0_H0Q"
              target="_blank"
              sx={{
                color: "text.secondary",
                "&:hover": { color: theme.palette.primary.main },
                display: "flex",
                alignItems: "center",
                gap: 1,
                textDecoration: "none",
              }}
            >
              <YouTubeIcon sx={{ fontSize: 24 }} />
              <Typography>Youtube</Typography>
            </MuiLink>
          </Box>
        </Grid>
      </Grid>

      <Box mt={5} bgcolor={theme.palette.background.default} py={2}>
        <Typography variant="body2" color="text.secondary" align="center">
          © {new Date().getFullYear()} Studera.ai. Alla rättigheter
          förbehållna.
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
