import React, { useState, useEffect } from "react";
import { Box, Typography, IconButton, TextField, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import HoverableImage from "./HoverableImage";

const MindMap = ({
  items,
  centerImage,
  centerText,
  themeColor,
  generatingItemIndex,

  onImageUpdate,
  userId,
  showSnackbar,
  theme,
  onImageClick,
  viewMode,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedItems, setEditedItems] = useState(items);
  const centerSize = 165;
  const itemSize = 165;
  const radius = 370; // Increased radius as per previous request
  const [isHovering, setIsHovering] = useState(false);
  const [editedCenterText, setEditedCenterText] = useState(centerText);

  useEffect(() => {
    setEditedItems(items);
  }, [items]);

  const handleNameChange = (index, newName) => {
    const updatedItems = editedItems.map((item, i) =>
      i === index ? { ...item, name: newName } : item,
    );
    setEditedItems(updatedItems);
  };

  const handleItemImageClick = (index) => {
    onImageClick("item", index);
  };

  const handleCenterImageClick = () => {
    onImageClick("center", -999);
  };

  const toggleEdit = () => {
    if (isEditing) {
      onImageUpdate("item-names", null, editedItems);
      onImageUpdate("center-text", null, editedCenterText);
      showSnackbar("Tankekarta uppdaterad!", "success");
    } else {
      setEditedItems([...items]);
      setEditedCenterText(centerText);
    }
    setIsEditing(!isEditing);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      toggleEdit();
    }
  };

  // Calculate the center point of the container
  const centerPoint = {
    x: radius + itemSize / 2,
    y: radius + itemSize / 2,
  };

  return (
    <Box
      sx={{
        position: "relative",
        width: radius * 2 + itemSize,
        height: radius * 2 + itemSize,
        margin: "0 auto",
      }}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      {/* SVG layer for connection lines */}
      <svg
        width="100%"
        height="100%"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          pointerEvents: "none",
          zIndex: 1,
        }}
      >
        {editedItems.map((item, index) => {
          const angle =
            (index / editedItems.length) * 2 * Math.PI - Math.PI / 2;

          // Calculate the center points of each box
          const itemCenterX = Math.cos(angle) * radius + centerPoint.x;
          const itemCenterY = Math.sin(angle) * radius + centerPoint.y;

          // Calculate where the line should intersect with the boxes
          // For center box (start point)
          const centerBoxRadius = centerSize * 0.75; // Half of the center box width
          const startX = centerPoint.x + Math.cos(angle) * centerBoxRadius;
          const startY = centerPoint.y + Math.sin(angle) * centerBoxRadius;

          // For outer box (end point)
          const outerBoxRadius = itemSize * 0.75; // Half of the outer box width
          const vectorLength = Math.sqrt(
            Math.pow(itemCenterX - centerPoint.x, 2) +
              Math.pow(itemCenterY - centerPoint.y, 2),
          );
          const endX =
            itemCenterX -
            (Math.cos(angle) * outerBoxRadius * radius) / vectorLength;
          const endY =
            itemCenterY -
            (Math.sin(angle) * outerBoxRadius * radius) / vectorLength;

          return (
            <line
              key={`line-${index}`}
              x1={startX}
              y1={startY}
              x2={endX}
              y2={endY}
              stroke="black"
              strokeWidth="14"
            />
          );
        })}
      </svg>

      {!viewMode && (
        <Tooltip
          title={
            isEditing ? "Spara ändringar" : "Redigera texten i tankekartan"
          }
          open={isHovering}
          placement="top"
        >
          <IconButton
            onClick={toggleEdit}
            sx={{
              position: "absolute",
              top: -48,
              right: 0,
              zIndex: 4,
              opacity: isHovering ? 1 : 0,
              transition: "opacity 0.2s",
            }}
          >
            {isEditing ? <SaveIcon /> : <EditIcon />}
          </IconButton>
        </Tooltip>
      )}
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: centerSize * 1.5,
          height: centerSize * 1.5,
          zIndex: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          border: "6px solid black",
          borderRadius: "100%",
        }}
      >
        <Box sx={{ position: "relative", mt: 0 }}>
          <HoverableImage
            imageUrl={centerImage}
            isLoading={
              generatingItemIndex === -999 || generatingItemIndex === -1
            }
            onGenerateClick={handleCenterImageClick}
            userId={userId}
            onImageUpdate={(newUrl) => onImageUpdate("center", null, newUrl)}
            showSnackbar={showSnackbar}
            theme={theme}
            tinyMode={true}
            size={centerSize * 0.8}
          />
        </Box>
        {isEditing ? (
          <TextField
            value={editedCenterText}
            onChange={(e) => setEditedCenterText(e.target.value)}
            onKeyPress={handleKeyPress}
            variant="standard"
            sx={{
              width: centerSize * 1.5,
              mt: 1,
              "& input": {
                textAlign: "center",
                color: themeColor,
                fontWeight: "bold",
                fontSize: "1.1rem",
              },
            }}
          />
        ) : (
          <Typography
            variant="subtitle"
            align="center"
            sx={{
              width: centerSize * 1.2, // Reduced from 2
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              lineHeight: 1.2,
              height: "2.4em",
              fontWeight: "500",
              fontSize: "1rem",

              mt: 1,
              px: 1, // Added padding
            }}
          >
            {editedCenterText}
          </Typography>
        )}
      </Box>

      {editedItems.map((item, index) => {
        const angle = (index / editedItems.length) * 2 * Math.PI - Math.PI / 2;
        const x = Math.cos(angle) * radius + radius + itemSize / 2;
        const y = Math.sin(angle) * radius + radius + itemSize / 2;

        return (
          <Box
            key={index}
            sx={{
              position: "absolute",
              top: y,
              left: x,
              transform: "translate(-50%, -50%)",
              width: itemSize * 1.5,
              height: itemSize * 1.5,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 3,
              border: "6px solid black",
              borderRadius: "100%",
            }}
          >
            <Box sx={{ position: "relative", mt: 0 }}>
              <HoverableImage
                imageUrl={item.imageUrl}
                isLoading={
                  generatingItemIndex === index || generatingItemIndex === -1
                }
                loadingIndex={index}
                currentIndex={index}
                onGenerateClick={() => handleItemImageClick(index)}
                userId={userId}
                onImageUpdate={(newUrl) => onImageUpdate("item", index, newUrl)}
                showSnackbar={showSnackbar}
                theme={theme}
                tinyMode={true}
                size={itemSize * 0.8}
              />
            </Box>
            {isEditing ? (
              <TextField
                value={item.name}
                onChange={(e) => handleNameChange(index, e.target.value)}
                onKeyPress={handleKeyPress}
                variant="standard"
                sx={{
                  width: itemSize * 1.5,
                  mt: 1,
                  "& input": {
                    textAlign: "center",
                    color: themeColor,
                    fontWeight: "bold",
                    fontSize: "1rem",
                  },
                }}
              />
            ) : (
              <Typography
                variant="subtitle"
                align="center"
                sx={{
                  width: itemSize * 1.2, // Reduced from 2
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  lineHeight: 1.2,
                  height: "2.4em",
                  fontWeight: "500",
                  fontSize: "1rem",

                  mt: 1,
                  px: 1, // Added padding
                }}
              >
                {item.name}
              </Typography>
            )}
          </Box>
        );
      })}
    </Box>
  );
};

export default MindMap;
