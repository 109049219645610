import {
  Science as ScienceIcon,
  Castle as CastleIcon,
  Psychology as PsychologyIcon,
  Pets as PetsIcon,
  Public as PublicIcon,
  Brush as BrushIcon,
  Calculate as CalculateIcon,
  Language as LanguageIcon,
  EmojiObjects as EmojiObjectsIcon,
  LocalFlorist as LocalFloristIcon,
  WbSunny as WbSunnyIcon,
  Workspaces as WorkspacesIcon,
  Agriculture as AgricultureIcon,
  MonitorHeart as MonitorHeartIcon,
  Park as ParkIcon,
  Biotech as BiotechIcon,
  MenuBook as MenuBookIcon,
  EmojiEvents as EmojiEventsIcon,
  Museum as MuseumIcon,
  Nature as NatureIcon,
} from "@mui/icons-material";
import {
  History,
  Castle,
  Factory,
  PublicOutlined,
  Science,
  Biotech,
  Functions,
  Timeline,
  CalculateOutlined,
  MenuBook,
  Mosque,
} from "@mui/icons-material";
// Default themes that show when no subject is selected
export const defaultThemes = [
  { type: "subheader", label: "Teman som lämpar sig extra bra för quiz:" },
  {
    value: "selma",
    label: "Selma Lagerlöf",
    content: "Gör ett quiz om selma lagerlöf",
    Icon: MenuBook,
  },
  {
    value: "ancient-civilizations",
    label: "Antikens civilisationer",
    content:
      "Generera ett quiz om de stora antika civilisationerna som Egypten, Grekland, Rom och Mesopotamien. Fokusera på deras kulturer, styrelseskick, arkitektur och bidrag till världshistorien.",
    Icon: History,
  },
  {
    value: "medieval-feudalism",
    label: "Medeltiden och feodalism",
    content:
      "Utforma ett quiz om det medeltida samhället i Europa, med fokus på feodalismen. Inkludera frågor om samhällsklasser, riddarväsendet, kyrkans roll och den medeltida ekonomin.",
    Icon: Castle,
  },
  {
    value: "industrialization",
    label: "Industrialiseringen",
    content:
      "Skapa ett quiz om industrialiseringens framväxt och effekter. Täck in viktiga uppfinningar, sociala förändringar, urbanisering och hur den påverkade olika länder och samhällsklasser.",
    Icon: Factory,
  },
  {
    value: "colonialism-effects",
    label: "Kolonialismens effekter",
    content:
      "Generera ett quiz om kolonialismens långsiktiga konsekvenser för både kolonisatörer och koloniserade. Inkludera frågor om ekonomisk exploatering, kulturell påverkan och avkolonialiseringen.",
    Icon: PublicOutlined,
  },
  {
    value: "immune-evolution",
    label: "Evolution av immunförsvar hos olika organismer",
    content:
      "Generera ett quiz om hur immunförsvaret har utvecklats hos olika organismgrupper. Inkludera frågor om medfödd och adaptiv immunitet, evolutionära anpassningar och jämförelser mellan arter.",
    Icon: Science,
  },
  {
    value: "clinical-biology",
    label: "Klinisk biologi och laboratoriemedicin",
    content:
      "Skapa ett quiz om biologiska tekniker som används inom klinisk diagnostik. Inkludera frågor om blodprovsanalyser, mikrobiologiska tester, genetisk screening och bilddiagnostik.",
    Icon: Biotech,
  },
  {
    value: "probability-statistics",
    label: "Sannolikhet och statistik",
    content:
      "Utforma ett quiz om grundläggande sannolikhetsteori och statistik. Täck in begrepp som sannolikhetsfördelningar, väntevärde, standardavvikelse, hypotesprövning och konfidensintervall.",
    Icon: Functions,
  },
  {
    value: "mathematical-patterns",
    label: "Matematiska mönster och sekvenser",
    content:
      "Skapa ett quiz om olika typer av matematiska mönster och sekvenser. Inkludera aritmetiska och geometriska följder, Fibonacci-sekvensen och rekursiva formler.",
    Icon: Timeline,
  },
  {
    value: "trigonometry",
    label: "Trigonometri",
    content:
      "Generera ett quiz om trigonometriska funktioner, identiteter och ekvationer. Inkludera frågor om enhetscirkeln, trigonometriska grafer och tillämpningar i verkliga livet.",
    Icon: CalculateOutlined,
  },
  {
    value: "sufi-texts",
    label: "Sufismens heliga texter",
    content:
      "Skapa ett quiz om de heliga texterna inom sufismen, som Rumi och Hafiz' verk. Fråga om deras centrala teman och poesi.",
    Icon: Mosque,
  },
  {
    value: "quran-mysticism",
    label: "Koranens mystiska tolkningar",
    content:
      "Generera ett quiz om de mystiska tolkningarna av Koranen inom islams traditioner, inklusive sufismens synsätt.",
    Icon: Mosque,
  },
];

// Subject-specific themes
export const subjectThemes = {
  biologi: [
    {
      type: "subheader",
      label: "Biologiteman som lämpar sig extra bra för denna lektionstyp:",
    },
    {
      value: "cellbiologi",
      label: "Cellbiologi",
      content: "Gör en lektion om cellens olika delar och funktioner",
      Icon: BiotechIcon,
    },
    {
      value: "fotosyntesen",
      label: "Fotosyntesen",
      content: "Gör en lektion om hur fotosyntesen fungerar",
      Icon: WbSunnyIcon,
    },

    {
      value: "evolution",
      label: "Evolution",
      content: "Gör en lektion om evolutionens mekanismer",
      Icon: WorkspacesIcon,
    },
  ],

  historia: [
    {
      type: "subheader",
      label: "Historieteman som lämpar sig extra bra för denna lektionstyp:",
    },
    {
      value: "industriella-revolutionen",
      label: "Industriella revolutionen",
      content: "Gör en lektion om den industriella revolutionen",
      Icon: CastleIcon,
    },
    {
      value: "franska-revolutionen",
      label: "Franska revolutionen",
      content: "Gör en lektion om franska revolutionen och dess orsaker",
      Icon: CastleIcon,
    },
    {
      value: "vikingatiden",
      label: "Vikingatiden",
      content: "Gör en lektion om vikingatiden och dess samhälle",
      Icon: CastleIcon,
    },
  ],

  fysik: [
    {
      type: "subheader",
      label: "Fysikteman som lämpar sig extra bra för denna lektionstyp:",
    },
    {
      value: "elektricitet",
      label: "Elektricitet",
      content: "Gör en lektion om elektriska kretsar och komponenter",
      Icon: EmojiObjectsIcon,
    },
    {
      value: "magnetism",
      label: "Magnetism",
      content: "Gör en lektion om magnetfält och magnetiska material",
      Icon: ScienceIcon,
    },
    {
      value: "optik",
      label: "Optik",
      content: "Gör en lektion om ljusets egenskaper och optiska instrument",
      Icon: ScienceIcon,
    },
  ],

  kemi: [
    {
      type: "subheader",
      label: "Kemiteman som lämpar sig extra bra för denna lektionstyp:",
    },
    {
      value: "periodiska-systemet",
      label: "Periodiska systemet",
      content: "Gör en lektion om grundämnenas periodiska system",
      Icon: ScienceIcon,
    },
    {
      value: "kemiska-bindningar",
      label: "Kemiska bindningar",
      content: "Gör en lektion om olika typer av kemiska bindningar",
      Icon: WorkspacesIcon,
    },
    {
      value: "syror-baser",
      label: "Syror och baser",
      content: "Gör en lektion om syror, baser och pH-skalan",
      Icon: ScienceIcon,
    },
  ],

  matematik: [
    {
      type: "subheader",
      label: "Matematikteman som lämpar sig extra bra för denna lektionstyp:",
    },
    {
      value: "pythagoras",
      label: "Pythagoras sats",
      content: "Gör en lektion om Pythagoras sats och dess tillämpningar",
      Icon: CalculateIcon,
    },
    {
      value: "matematikens-historia",
      label: "Matematikens historia",
      content: "Gör en lektion om viktiga matematiker genom historien",
      Icon: CalculateIcon,
    },
    {
      value: "geometriska-former",
      label: "Geometriska former",
      content:
        "Gör en lektion om olika geometriska former och deras egenskaper",
      Icon: CalculateIcon,
    },
  ],

  svenska: [
    {
      type: "subheader",
      label: "Svenskateman som lämpar sig extra bra för denna lektionstyp:",
    },
    {
      value: "svenska-forfattare",
      label: "Svenska författare",
      content: "Gör en lektion om viktiga svenska författare",
      Icon: LanguageIcon,
    },
    {
      value: "sprakhistoria",
      label: "Språkhistoria",
      content: "Gör en lektion om det svenska språkets utveckling",
      Icon: LanguageIcon,
    },
    {
      value: "litteraturepoker",
      label: "Litteraturepoker",
      content: "Gör en lektion om olika litterära epoker",
      Icon: LanguageIcon,
    },
  ],
};

// Helper function to get appropriate themes based on subject
export const getThemes = (subject) => {
  if (!subject) return defaultThemes;
  return subjectThemes[subject] || defaultThemes;
};

export default getThemes;
