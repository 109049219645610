import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Divider,
  CircularProgress,
  IconButton,
  FormControl,
  Tooltip,
  Avatar,
  Collapse,
  Grid,
  TextField,
} from "@mui/material";

import GenerateIcon from "@mui/icons-material/AutoAwesome";
import ImageIcon from "@mui/icons-material/Image";
import PrintIcon from "@mui/icons-material/Print";
import { useSearchParams, useParams } from "react-router-dom";

import { GiBookshelf } from "react-icons/gi";

import { callApi } from "./api";
import LoadingBar from "react-top-loading-bar";
import SaveIcon from "@mui/icons-material/Save";
import LanguageSelector from "./LanguageSelector";
import InfoBox from "./InfoBox";
import { handlePrintDetectiveLesson } from "./printFunctions";
import GavelIcon from "@mui/icons-material/Gavel";
import ReadingComprehensionDialog from "./ReadingComprehensionDialog";
import { generateAndProcessImage } from "./functions";
import { useSnackbar } from "./SnackbarHandler";
import ProgressBar from "./ProgressBar";
import { ThemeSelector } from "./ThemeSelector";
import ImageGenerationModal from "./ImageGenerationModal";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import GradeSelector, { gradeInstructions } from "./GradeSelector";
import { useTheme } from "@mui/material/styles";
import { getStyles } from "../index";
import { trackLesson } from "./functions";
import EditIcon from "@mui/icons-material/Edit";

import PetsIcon from "@mui/icons-material/Pets";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import TheaterComedyIcon from "@mui/icons-material/TheaterComedy";

import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import ArtTrackIcon from "@mui/icons-material/ArtTrack";

import { FourSquare } from "react-loading-indicators";

import MovieIcon from "@mui/icons-material/Movie";

import ChildFriendlyIcon from "@mui/icons-material/ChildFriendly";

import LocalBarIcon from "@mui/icons-material/LocalBar";

import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import LocalSeeIcon from "@mui/icons-material/LocalSee";
import SailingIcon from "@mui/icons-material/Sailing";

// Replace CrownIcon with another royal/prestigious icon
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium"; // instead of CrownIcon

// Replace CruiseIcon with another water/boat-related icon
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat"; // instead of CruiseIcon

import Logger from "./Logger";

import LessonDrawer from "./LessonDrawer";
import SaveLessonDialog from "./SaveLessonDialog";
import HoverableImage from "./HoverableImage";
import HistoryIcon from "@mui/icons-material/History";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import { ThemeSelectorFlow } from "./ThemeSelectorFlow";

const DetectiveReadingCompLesson = ({ user }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);

  const [showForm, setShowForm] = useState(true);

  const [language, setLanguage] = useState("svenska");
  const [titleText, setTitleText] = useState("");
  const [grade, setGrade] = useState("åk6");
  const [progress, setProgress] = useState(0);
  const [isGeneratingLesson, setIsGeneratingLesson] = useState(false);
  const [isGeneratingImages, setIsGeneratingImages] = useState(false);
  const [editorContent, setEditorContent] = useState("");
  const [customTheme, setCustomTheme] = useState("");
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageInstructions, setImageInstructions] = useState("");
  const [isEditingSolution, setIsEditingSolution] = useState(false);
  const [openAiInstructions, setOpenAiInstructions] = useState("");
  const [lessonData, setLessonData] = useState(null);
  const editorRef = useRef(null);
  const { showSnackbar } = useSnackbar();
  const theme = useTheme();
  const styles = getStyles(theme);
  const urlContentRef = useRef("");
  const bookContentRef = useRef("");

  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [isEditingContent, setIsEditingContent] = useState(false);
  const [editingSections, setEditingSections] = useState({});
  const [loadingImageIndex, setLoadingImageIndex] = useState(null);

  const [lessonTheme, setLessonTheme] = useState("generateWithAi");
  const [predefinedTheme, setPredefinedTheme] = useState(null);
  const lessonTitleRef = useRef(null); // Add this ref
  const transcriptRef = useRef("");
  const uploadedFileRef = useRef("");

  const wikipediaContentRef = useRef("");
  const randomizedContentRef = useRef("");
  const coursePlanContentRef = useRef("");

  const detectiveThemes = [
    {
      type: "subheader",
      label: "Teman som lämpar sig extra bra för denna lektionstyp:",
    },
    {
      value: "mordet-teaterscenen",
      label: "Mordet på teaterscenen",
      content: "",
      Icon: TheaterComedyIcon,
    },
    {
      value: "forsvunna-diamanten",
      label: "Den försvunna diamanten",
      content: "",
      Icon: LocalPoliceIcon,
    },
    {
      value: "brottet-bakom-biografen",
      label: "Brottet bakom biografen",
      content: "",
      Icon: MovieIcon,
    },
    {
      value: "mordfallet-haststallet",
      label: "Mordfallet i häststallet",
      content: "",
      Icon: PetsIcon,
    },
    {
      value: "tjuven-pa-lyxkryssaren",
      label: "Tjuven på lyxkryssaren",
      content: "",
      Icon: DirectionsBoatIcon,
    },
    {
      value: "giftet-pa-vinodlingen",
      label: "Giftet på vinodlingen",
      content: "",
      Icon: LocalBarIcon,
    },

    {
      value: "spionen-i-gamla-stan",
      label: "Spionen i Gamla Stan",
      content: "",
      Icon: LocalSeeIcon,
    },
    {
      value: "smugglarna-i-skärgården",
      label: "Smugglarna i skärgården",
      content: "",
      Icon: SailingIcon,
    },
    {
      value: "det-forsvunna-tavlan",
      label: "Den försvunna tavlan",
      content: "",
      Icon: ArtTrackIcon,
    },
    {
      value: "kungliga-smycket",
      label: "Det kungliga smycket",
      content: "",
      Icon: WorkspacePremiumIcon,
    },

    {
      value: "spionen-fran-rymdstationen",
      label: "Spionen från rymdstationen",
      content: "",
      Icon: RocketLaunchIcon,
    },

    {
      value: "dockmakarens-mystiska-hus",
      label: "Dockmakarens mystiska hus",
      content: "",
      Icon: ChildFriendlyIcon,
    },

    {
      value: "fallet-pa-finansbanken",
      label: "Fallet på finansbanken",
      content: "",
      Icon: MonetizationOnIcon,
    },
  ];

  const [searchParams] = useSearchParams();
  const subject = searchParams.get("subject") || null;

  useEffect(() => {
    Logger.log("subject: ", subject);
  }, [subject]);

  useEffect(() => {
    Logger.log("lessonTheme: ", lessonTheme);
  }, [lessonTheme]);

  useEffect(() => {
    Logger.log("predefinedTheme: ", predefinedTheme);
  }, [predefinedTheme]);

  useEffect(() => {
    Logger.log("customTheme: ", customTheme);
  }, [customTheme]);

  useEffect(() => {
    Logger.log("youtubeTranscript: ", transcriptRef.current);
  }, [transcriptRef.current]);

  useEffect(() => {
    Logger.log("wikipediaContentRef: ", wikipediaContentRef.current);
  }, [wikipediaContentRef.current]);
  useEffect(() => {
    Logger.log("uploadedFileRef: ", uploadedFileRef.current);
  }, [uploadedFileRef.current]);

  useEffect(() => {
    Logger.log("randomizedContentRef: ", randomizedContentRef.current);
  }, [randomizedContentRef.current]);

  useEffect(() => {
    Logger.log("coursePlanContentRef: ", coursePlanContentRef.current);
  }, [coursePlanContentRef.current]);

  const handlePrintClick = async () => {
    if (
      lessonData &&
      lessonData.images &&
      lessonData.images.every((image) => image.imageUrl)
    ) {
      Logger.log("Saving lesson since all images have been generated");
      await trackLesson(
        user.uid,
        "DetectiveReadingCompLesson.js",
        lessonData,
        null,
        true,
      );
    }
    handlePrintDetectiveLesson(lessonData, theme);
  };

  const handleSaveLessonClick = () => {
    setSaveDialogOpen(true);
  };

  const handleSaveWithComment = async (comment, name, coverImage, tags) => {
    Logger.log(JSON.stringify(lessonData, null, 2));
    await trackLesson(
      user.uid,
      "DetectiveReadingCompLesson.js",
      lessonData,
      null,
      true,
      comment,
      name,
      coverImage,
      tags,
    );
    setDrawerOpen(true);
    showSnackbar("Lektionen sparad i din lektionsbank!", "success");
  };

  const simulateProgress = (startProgress, endProgress, duration) => {
    const stepSize = 0.5;
    const steps = (endProgress - startProgress) / stepSize;
    const stepDuration = duration / steps;
    let currentProgress = startProgress;

    const intervalId = setInterval(() => {
      currentProgress += stepSize;
      if (currentProgress >= endProgress) {
        clearInterval(intervalId);
      } else {
        setProgress(currentProgress);
      }
    }, stepDuration);

    return () => clearInterval(intervalId);
  };

  const handleGenerateLesson = async () => {
    setProgress(0);
    let stopProgressSimulation = null;
    setIsGeneratingLesson(true);
    const gradeInstructionsText = gradeInstructions[grade];

    try {
      let themeToUse;

      if (lessonTheme === "youtube") {
        themeToUse = transcriptRef.current;
      } else if (lessonTheme === "customTheme") {
        themeToUse = customTheme;
      } else if (lessonTheme === "predefined") {
        themeToUse = predefinedTheme;
      } else if (lessonTheme === "fileUpload") {
        themeToUse = uploadedFileRef.current;
      } else if (lessonTheme === "wikipedia") {
        themeToUse = wikipediaContentRef.current;
      } else if (lessonTheme === "randomizer") {
        themeToUse = randomizedContentRef.current;
      } else if (lessonTheme === "book") {
        themeToUse = bookContentRef.current;
      } else if (lessonTheme === "url") {
        themeToUse = urlContentRef.current;
      } else {
        //
        themeToUse = coursePlanContentRef.current;
      }

      Logger.log("themeToUse: ", themeToUse);

      stopProgressSimulation = simulateProgress(0, 90, 15000, setProgress);

      let response = await callApi(
        [
          {
            role: "system",
            content: `You are an expert teacher creating engaging detective reading comprehension lessons 
          in ${language}. Follow the exact JSON structure provided in the prompt.`,
          },
          {
            role: "user",
            content: `Skapa ett mordfall med en förutbestämd lösning i form av en kortnovell. Texten ska vara skriven på ${language}.

      Denna berättelse är skapad för elever som ska agera detektiver och försöka lösa fallet. Novellen ska vara mellan 50 och 60 meningar och uppdelad i tydliga stycken.

    Stämning: Ge novellen en mystisk och engagerande ton, som fångar elevernas intresse från början.

    Intrig och ledtrådar: Introducera en gåta där ett mord har skett under märkliga omständigheter. Presentera misstänkta, möjliga motiv, och gömda ledtrådar genom hela berättelsen. Se till att några ledtrådar är subtila, så att eleverna måste läsa noggrant.

    Ingen lösning i texten: Beskriv händelseförloppet men avslöja inte lösningen. Se till att eleverna behöver analysera ledtrådarna själva för att lista ut vem som är skyldig och varför.
      
      Svara i följande JSON-format:
      {
        "title": "Lektionens titel",
        "theme": "Mysterietema",
        "content": "Mysterietexten här...",
        "solution": "Lösningen på fallet här...",
        "exercises": [
          {
            "type": "scoring-clues",
            "title": "Poängledtrådar",
            "content": {
              "12": ["Mycket svår ledtråd som kräver noggrann läsning och analys..."],
              "10": ["Svår ledtråd som kräver god analytisk förmåga..."],
              "8": ["Medelnivå ledtråd som ger tydligare koppling till lösningen..."],
              "6": ["Enklare ledtråd som pekar mer direkt mot lösningen..."],
              "4": ["Tydlig ledtråd som ger stark indikation på lösningen..."],
              "2": ["Mycket tydlig ledtråd som nästan avslöjar lösningen..."]
            }
          },
          {
            "type": "questions",
            "title": "Utredningsfrågor",
            "content": ["Fråga 1", "Fråga 2", "Fråga 3"]
          },
          {
            "type": "clues",
            "title": "Viktiga ledtrådar",
            "content": ["Ledtråd 1", "Ledtråd 2", "Ledtråd 3"]
          },
          {
            "type": "red-herrings",
            "title": "Villospår",
            "content": ["Villospår 1", "Villospår 2", "Villospår 3"]
          }
        ],
        "teacherNotes": "Noter för läraren här..."
      }
      Theme: "${themeToUse}".
      ${openAiInstructions}.
      ${gradeInstructionsText}`,
          },
        ],
        6000,
        true,
        user.uid,
      );

      if (stopProgressSimulation) {
        stopProgressSimulation();
      }

      setProgress(95);
      let messageContent = response.data.choices[0].message.content.trim();

      if (messageContent.startsWith("```json")) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith("```")) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      const data = JSON.parse(messageContent);
      setProgress(100);

      setLessonData({
        ...data,
        images: [{ imageUrl: null }, { imageUrl: null }], // Initialize with two empty image slots
      });

      setTitleText(data.title);
      await new Promise((resolve) => setTimeout(resolve, 800));

      await trackLesson(user.uid, "DetectiveReadingCompLesson.js", null, {
        language: language,
        lessonTheme: lessonTheme,
      });

      setShowForm(false);
    } catch (error) {
      console.error("Error generating lesson:", error);
      showSnackbar(
        "Ett fel uppstod när lektionen skulle genereras. Försök igen!",
        "error",
      );
    } finally {
      setIsGeneratingLesson(false);
    }
  };

  const handleImageButtonClick = (index = null) => {
    setSelectedImageIndex(index);
    setShowImageModal(true);
  };

  const handleCloseModal = () => {
    setShowImageModal(false);
    setImageInstructions("");
  };

  const handleGenerateWithInstructions = async () => {
    if (selectedImageIndex !== null) {
      handleCloseModal();

      await handleGenerateSpecificImage(selectedImageIndex);
    } else {
      handleCloseModal();
      await handleGenerateAllImages();
    }
  };

  const handleSectionEdit = (section, index) => {
    setEditingSections((prev) => ({
      ...prev,
      [`${section}-${index}`]: true,
    }));
  };

  const handleSectionSave = (section, index) => {
    setEditingSections((prev) => ({
      ...prev,
      [`${section}-${index}`]: false,
    }));
    showSnackbar("Sektionen har uppdaterats", "success");
  };

  const handleExerciseUpdate = (index, key, value) => {
    setLessonData((prev) => {
      const newExercises = [...prev.exercises];
      newExercises[index] = {
        ...newExercises[index],
        [key]: value,
      };
      return {
        ...prev,
        exercises: newExercises,
      };
    });
  };

  const handleGenerateSpecificImage = async (index) => {
    setIsGeneratingImages(true);
    setLoadingImageIndex(index);

    handleCloseModal();

    try {
      const imagePrompt = `Create an detective scene image for the story: "${titleText}". ${imageInstructions}`;
      const imageUrl = await generateAndProcessImage(imagePrompt, user.uid);

      if (imageUrl) {
        setLessonData((prev) => {
          const newImages = [...prev.images];
          if (index !== null && index < newImages.length) {
            newImages[index] = { imageUrl };
          } else {
            const emptyIndex = newImages.findIndex((img) => !img.imageUrl);
            const updateIndex = emptyIndex >= 0 ? emptyIndex : 0;
            newImages[updateIndex] = { imageUrl };
          }
          return { ...prev, images: newImages };
        });
        showSnackbar("Bilden har genererats!", "success");
      }
    } catch (error) {
      console.error("Error generating image:", error);
      showSnackbar("Ett fel uppstod när bilden skulle genereras", "error");
    } finally {
      setIsGeneratingImages(false);
      setLoadingImageIndex(null);
    }
  };

  const handleGenerateAllImages = async () => {
    setIsGeneratingImages(true);
    setLoadingImageIndex(null); // Set to null for batch generation

    try {
      const basePrompt = `Create an detective scene image for the story: "${titleText}".`;

      const prompts = [
        `${basePrompt} Show the initial crime scene or the main location where the mystery takes place.`,
        `${basePrompt} Show a key detail, clue, or suspect that's important to the mystery.`,
      ];

      // Generate all images concurrently
      const imagePromises = prompts.map((prompt) =>
        generateAndProcessImage(prompt, user.uid),
      );

      const imageUrls = await Promise.all(imagePromises);

      // Update all images at once
      setLessonData((prev) => ({
        ...prev,
        images: imageUrls.map((imageUrl) => ({ imageUrl })),
      }));

      showSnackbar("Alla bilder har genererats!", "success");
    } catch (error) {
      console.error("Error generating images:", error);
      showSnackbar("Ett fel uppstod när bilderna skulle genereras", "error");
    } finally {
      setIsGeneratingImages(false);
      setLoadingImageIndex(null);
    }
  };

  return (
    <Box sx={styles.container}>
      <Box
        sx={{
          ...styles.innerContainer,
          width: { xs: "100%", md: "87%" },
          margin: "0 auto",
        }}
      >
        <Typography
          variant="h1"
          gutterBottom
          sx={{
            fontWeight: "bold",
            fontSize: "1.8em",
            mb: 3,
            mt: 5,
            textAlign: "center",
          }}
        >
          Läsdetektiven
        </Typography>

        <Box
          sx={{
            ...styles.buttonsContainer,
            ...styles.additionalButtonsContainer,
          }}
        >
          <Tooltip title="Dina sparade lektioner">
            <Button
              variant="contained"
              color="primary"
              disableRipple
              onClick={() => setDrawerOpen(true)}
              startIcon={<HistoryIcon />}
              sx={{
                padding: "8px",
                marginRight: "0px",
                paddingLeft: "14px",
                paddingRight: "3px",
                minWidth: "auto",
                textTransform: "none",
              }}
            />
          </Tooltip>
        </Box>

        <LessonDrawer
          userId={user.uid}
          lessonType="DetectiveReadingCompLesson.js"
          theme={theme}
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          lessonIcon={GavelIcon}
          onSelectLesson={(lesson) => {
            setLessonData(lesson.data);
            setDrawerOpen(false);

            showSnackbar(`Lektionen har laddats in.`, "success");
            setTimeout(() => {
              lessonTitleRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "start", // This ensures it scrolls to the top of the element
              });
            }, 100);
          }}
        />

        <InfoBox
          icon={GavelIcon}
          title="Läsdetektiven"
          instructions={[
            "Generera en spännande mysterieberättelse med dolda ledtrådar",
            "Få poängsatta ledtrådar för olika svårighetsgrader",
            "Analysera texten för att lösa mysteriet",
          ]}
          theme={theme}
          styles={styles}
        />

        <ThemeSelectorFlow
          subject={subject}
          value={lessonTheme}
          onChange={(e) => setLessonTheme(e.target.value)}
          customTheme={customTheme}
          onCustomThemeChange={(e) => setCustomTheme(e.target.value)}
          onPredefinedThemeChange={(e) => setPredefinedTheme(e.target.value)}
          onBookContentChange={(content) => {
            Logger.log("onBookContentChange", content.substring(0, 100));
            bookContentRef.current = content;
          }}
          onUrlContentChange={(content) => {
            Logger.log(
              "Parent: URL content received",
              content.substring(0, 300),
            );
            urlContentRef.current = content;
          }}
          onYoutubeTranscriptChange={(transcriptContent) => {
            Logger.log(
              "onYoutubeTranscriptChange körs med: ",
              transcriptContent,
            );
            transcriptRef.current = transcriptContent;
          }}
          onFileUpload={(content) => {
            Logger.log("onFileUpload körs med: ", content);
            uploadedFileRef.current = content;
          }}
          onWikipediaContentChange={(content) => {
            Logger.log(
              "Parent: Wikipedia content received",
              content.substring(0, 100),
            );
            wikipediaContentRef.current = content;
          }}
          onRandomizedContentChange={(content) => {
            Logger.log("randomizedContentRef", content.substring(0, 100));
            randomizedContentRef.current = content;
          }}
          onCoursePlanContentChange={(content) => {
            Logger.log("onCoursePlanChange", content.substring(0, 100));
            coursePlanContentRef.current = content;
          }}
          lessonType="DetectiveReadingCompLesson.js"
          user={user}
          theme={theme}
          styles={styles}
          onLessonSelect={(lesson) => {
            Logger.log("onLessonSelect körs");
            setLessonData(lesson.data);
            setTimeout(() => {
              lessonTitleRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "start", // This ensures it scrolls to the top of the element
              });
            }, 100);
            showSnackbar("Lektionen har laddats in.", "success");
          }}
          additionalThemes={detectiveThemes}
        />
        <Box sx={styles.headlineLessonBox}>
          <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
            2. Generera lektionen
          </Typography>
        </Box>

        <Box
          sx={{
            ...styles.freshBox,
            padding: "30px",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: "500", mb: 1, mt: 0 }}
              >
                Årskurs:
              </Typography>
              <Box sx={{ mt: 0.5, mb: 3 }}>
                <GradeSelector grade={grade} setGrade={setGrade} />
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" sx={{ fontWeight: "500", mt: 0 }}>
                Språk:
              </Typography>
              <Box sx={{ mt: 1, mb: 3 }}>
                <LanguageSelector
                  language={language}
                  setLanguage={setLanguage}
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography variant="subtitle1" sx={{ fontWeight: "500" }}>
                Specifika instruktioner till AI:n:
              </Typography>
              <FormControl fullWidth margin="normal" sx={{ mb: 3, mt: 1 }}>
                <TextField
                  value={openAiInstructions}
                  onChange={(e) => setOpenAiInstructions(e.target.value)}
                  placeholder="T.ex. Gör mysteriet extra spännande med oväntade vändningar."
                  variant="outlined"
                />
              </FormControl>
            </Grid>
          </Grid>

          <Button
            variant={theme.palette.mode === "light" ? "purp" : "contained"}
            color="primary"
            onClick={handleGenerateLesson}
            disabled={isGeneratingLesson}
            sx={{ mt: 4 }}
            startIcon={
              !isGeneratingLesson ? (
                <GenerateIcon style={{ color: "#fff" }} />
              ) : (
                <CircularProgress size={16} />
              )
            }
          >
            {isGeneratingLesson ? "Genererar..." : "Generera lektion"}
          </Button>

          {isGeneratingLesson && (
            <Box sx={{ mt: 6 }}>
              <ProgressBar
                progress={progress}
                label="Genererar lektion — tar 30-60 sekunder..."
              />
            </Box>
          )}
        </Box>

        {lessonData && (
          <>
            <SaveLessonDialog
              open={saveDialogOpen}
              onClose={() => setSaveDialogOpen(false)}
              onSave={handleSaveWithComment}
              theme={theme}
              lessonObject={lessonData}
              lessonName={lessonData?.title || ""}
            />

            <ImageGenerationModal
              open={showImageModal}
              onClose={handleCloseModal}
              onGenerate={handleGenerateWithInstructions}
              isGenerating={isGeneratingImages}
              instructions={imageInstructions}
              setInstructions={setImageInstructions}
              theme={theme}
            />
            <Box
              ref={lessonTitleRef}
              sx={{ ...styles.headlineLessonBox, mt: 10 }}
            >
              <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
                3. Din lektion
              </Typography>
            </Box>
            <Box sx={{ ...styles.freshBox, padding: "30px" }}>
              <Box sx={styles.lessonControlPanel}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    px: 1,
                  }}
                >
                  <Box>
                    <Button
                      variant={
                        theme.palette.mode === "light" ? "purp" : "contained"
                      }
                      color="primary"
                      onClick={() => handleImageButtonClick(null)}
                      disabled={isGeneratingImages}
                      startIcon={
                        !isGeneratingImages ? (
                          <ImageIcon style={{ color: "#fff" }} />
                        ) : (
                          <CircularProgress size={16} />
                        )
                      }
                    >
                      {isGeneratingImages
                        ? "Genererar..."
                        : "Generera alla bilder"}
                    </Button>
                    <Box sx={{ display: "inline-block", ml: 2 }}>
                      <ReadingComprehensionDialog
                        key={lessonData?.title}
                        lessonData={lessonData}
                        user={user}
                        theme={theme}
                        styles={styles}
                        buttonText="Specifika övningar"
                        tooltip="Perfekt för att generera specfika övningar för enskilda elever"
                      />
                    </Box>
                  </Box>
                  <Box>
                    <Button
                      variant="print"
                      startIcon={<PrintIcon />}
                      sx={{ ml: 2 }}
                      onClick={handlePrintClick}
                    >
                      Skriv ut
                    </Button>
                    <Button
                      variant="print"
                      startIcon={<SaveAsIcon />}
                      sx={{ ml: 2 }}
                      onClick={handleSaveLessonClick}
                    >
                      Spara i lektionsbank
                    </Button>
                  </Box>
                </Box>
              </Box>

              {/* Title Section */}
              {isEditingTitle ? (
                <TextField
                  value={lessonData.title}
                  onChange={(e) =>
                    setLessonData((prev) => ({
                      ...prev,
                      title: e.target.value,
                    }))
                  }
                  onBlur={() => {
                    setIsEditingTitle(false);
                    showSnackbar("Titeln har uppdaterats", "success");
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setIsEditingTitle(false);
                      showSnackbar("Titeln har uppdaterats", "success");
                    }
                  }}
                  autoFocus
                  fullWidth
                  variant="standard"
                  sx={{
                    backgroundColor: "transparent",
                    "& .MuiInputBase-input": {
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "2.6em",
                      marginBottom: "1.2rem",
                      marginTop: "1.5rem",
                    },
                  }}
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              ) : (
                <Box
                  sx={{
                    position: "relative",
                    display: "inline-block",
                    margin: "0 auto",
                    width: "100%",
                    mb: 3,
                    mt: 5,
                    ":hover .edit-icon": {
                      opacity: 1,
                    },
                  }}
                >
                  <Typography
                    variant="h1"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "2.6em",
                      textAlign: "center",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 2,
                    }}
                  >
                    {lessonData.title || " "}
                    <Box
                      component="span"
                      sx={{
                        opacity: 0,
                        transition: "opacity 0.2s",
                        display: "inline-flex",
                        gap: 0.5,
                      }}
                      className="edit-icon"
                    >
                      <Tooltip title="Redigera" placement="top">
                        <IconButton
                          size="small"
                          onClick={() => setIsEditingTitle(true)}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Typography>
                </Box>
              )}

              {/* Content Section */}
              <Box
                sx={{
                  backgroundColor: (theme) =>
                    theme.palette.mode === "dark" ? "#15202b" : "#e0e0e029",
                  borderRadius: "10px",
                  padding: "20px",
                  mb: 4,
                  mt: 4,
                }}
              >
                <Box
                  sx={{
                    "&:hover .controls": {
                      opacity: 1,
                    },
                    display: "flex",
                    alignItems: "flex-start",
                    gap: 1,
                  }}
                >
                  {isEditingContent ? (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        width: "100%",
                      }}
                    >
                      <TextField
                        fullWidth
                        multiline
                        rows={17}
                        value={lessonData.content}
                        onChange={(e) =>
                          setLessonData((prev) => ({
                            ...prev,
                            content: e.target.value,
                          }))
                        }
                        onBlur={() => {
                          setIsEditingContent(false);
                          showSnackbar("Innehållet har uppdaterats", "success");
                        }}
                        autoFocus
                        sx={{
                          "& .MuiInputBase-input": {
                            lineHeight: 1.8,
                          },
                          ...styles.editingTextField,
                        }}
                      />
                      <Tooltip title="Spara ändringar" placement="top">
                        <IconButton
                          onClick={() => {
                            setIsEditingContent(false);
                            showSnackbar(
                              "Innehållet har uppdaterats",
                              "success",
                            );
                          }}
                        >
                          <SaveAsIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  ) : (
                    <Typography
                      variant="body1"
                      sx={{
                        lineHeight: 1.8,
                        flex: 1,
                        display: "flex",
                        alignItems: "flex-start",
                        gap: 1,
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      {lessonData.content || " "}
                      <Box
                        component="span"
                        className="controls"
                        sx={{
                          opacity: 0,
                          transition: "opacity 0.2s",
                          display: "inline-flex",
                          gap: 0.5,
                          ml: 1,
                          mt: 0.5,
                        }}
                      >
                        <Tooltip title="Redigera" placement="top">
                          <IconButton
                            size="small"
                            onClick={() => setIsEditingContent(true)}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Typography>
                  )}
                </Box>
              </Box>

              {/* Images Grid */}
              <Grid
                container
                spacing={2}
                sx={{ mb: 4, justifyContent: "center" }}
              >
                {lessonData.images.map((image, index) => (
                  <Grid item xs={12} md={6} key={index}>
                    <HoverableImage
                      imageUrl={image.imageUrl}
                      isLoading={isGeneratingImages}
                      loadingIndex={loadingImageIndex}
                      currentIndex={index}
                      userId={user.uid}
                      theme={theme}
                      showSnackbar={showSnackbar}
                      onGenerateClick={() => handleImageButtonClick(index)}
                      onImageUpdate={(newImageUrl) => {
                        setLessonData((prev) => {
                          const newImages = [...prev.images];
                          newImages[index] = { imageUrl: newImageUrl };
                          return { ...prev, images: newImages };
                        });
                      }}
                    />
                  </Grid>
                ))}
              </Grid>

              {/* Exercises Sections */}
              {lessonData.exercises.map((exercise, index) => (
                <Box
                  key={index}
                  sx={{
                    position: "relative",
                    marginTop: 7,
                    "&:hover": {
                      "& .section-controls": {
                        opacity: 1,
                        visibility: "visible",
                      },
                    },
                  }}
                >
                  <Box
                    className="section-controls"
                    sx={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      opacity: 0,
                      visibility: "hidden",
                      transition: "all 0.2s ease",
                      display: "flex",
                      gap: 1,
                      zIndex: 1,
                    }}
                  >
                    <Tooltip
                      title={
                        editingSections[`${exercise.type}-${index}`]
                          ? "Spara ändringar"
                          : "Redigera sektion"
                      }
                      placement="top"
                    >
                      <IconButton
                        size="small"
                        onClick={() =>
                          editingSections[`${exercise.type}-${index}`]
                            ? handleSectionSave(exercise.type, index)
                            : handleSectionEdit(exercise.type, index)
                        }
                      >
                        {editingSections[`${exercise.type}-${index}`] ? (
                          <SaveAsIcon fontSize="small" />
                        ) : (
                          <EditIcon fontSize="small" />
                        )}
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Ta bort sektion" placement="top">
                      <IconButton
                        size="small"
                        onClick={() => {
                          const updatedExercises = lessonData.exercises.filter(
                            (_, i) => i !== index,
                          );
                          setLessonData((prev) => ({
                            ...prev,
                            exercises: updatedExercises,
                          }));
                          showSnackbar("Sektionen har tagits bort", "success");
                        }}
                      >
                        <DeleteOutlineIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Box>

                  {editingSections[`${exercise.type}-${index}`] ? (
                    <Box
                      sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                    >
                      <TextField
                        value={exercise.title}
                        onChange={(e) =>
                          handleExerciseUpdate(index, "title", e.target.value)
                        }
                        sx={{ width: "80%" }}
                        InputProps={{
                          sx: {
                            fontSize: "1.5em",
                            fontWeight: "bold",
                          },
                        }}
                      />
                      <Box
                        sx={{
                          backgroundColor: (theme) =>
                            theme.palette.mode === "dark"
                              ? "#15202b"
                              : "#e0e0e029",
                          borderRadius: "10px",
                          padding: "20px",
                        }}
                      >
                        {exercise.type === "scoring-clues"
                          ? Object.entries(exercise.content).map(
                              ([points, clues]) => (
                                <Box key={points} sx={{ mb: 3 }}>
                                  <Typography
                                    variant="subtitle1"
                                    sx={{ mb: 1, fontWeight: "bold" }}
                                  >
                                    {points} poäng:
                                  </Typography>
                                  {clues.map((clue, clueIndex) => (
                                    <TextField
                                      key={clueIndex}
                                      fullWidth
                                      multiline
                                      value={clue}
                                      onChange={(e) => {
                                        const newContent = {
                                          ...exercise.content,
                                        };
                                        newContent[points][clueIndex] =
                                          e.target.value;
                                        handleExerciseUpdate(
                                          index,
                                          "content",
                                          newContent,
                                        );
                                      }}
                                      sx={{ ...styles.editingTextField, mb: 1 }}
                                    />
                                  ))}
                                </Box>
                              ),
                            )
                          : exercise.content.map((item, i) => (
                              <TextField
                                key={i}
                                fullWidth
                                multiline
                                value={item}
                                onChange={(e) => {
                                  const newContent = [...exercise.content];
                                  newContent[i] = e.target.value;
                                  handleExerciseUpdate(
                                    index,
                                    "content",
                                    newContent,
                                  );
                                }}
                                sx={{ ...styles.editingTextField, mb: 2 }}
                              />
                            ))}
                        <Button
                          variant={
                            theme.palette.mode === "light"
                              ? "purp"
                              : "contained"
                          }
                          color="primary"
                          onClick={() =>
                            handleSectionSave(exercise.type, index)
                          }
                          sx={{ mt: 2 }}
                          startIcon={<SaveAsIcon />}
                        >
                          Spara ändringar
                        </Button>
                      </Box>
                    </Box>
                  ) : (
                    <>
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: "bold", mb: 2, fontSize: "1.5em" }}
                      >
                        {exercise.title || ""}
                      </Typography>
                      <Box
                        sx={{
                          backgroundColor: (theme) =>
                            theme.palette.mode === "dark"
                              ? "#15202b"
                              : "#e0e0e029",
                          borderRadius: "10px",
                          padding: "20px",
                          "& li": {
                            marginBottom: "12px",
                            lineHeight: "25px",
                          },
                        }}
                      >
                        {exercise.type === "scoring-clues" ? (
                          Object.entries(exercise.content).map(
                            ([points, clues]) => (
                              <Box key={points} sx={{ mb: 3 }}>
                                <Typography
                                  variant="subtitle1"
                                  sx={{ mb: 1, fontWeight: "bold" }}
                                >
                                  {points} poäng:
                                </Typography>
                                <ul>
                                  {clues.map((clue, clueIndex) => (
                                    <li key={clueIndex}>
                                      <Typography variant="body1">
                                        {clue}
                                      </Typography>
                                    </li>
                                  ))}
                                </ul>
                              </Box>
                            ),
                          )
                        ) : (
                          <ul>
                            {exercise.content.map((item, i) => (
                              <li key={i}>
                                <Typography variant="body1">{item}</Typography>
                              </li>
                            ))}
                          </ul>
                        )}
                      </Box>
                    </>
                  )}
                </Box>
              ))}

              {/* Solution Section */}
              {/* Solution Section */}
              {lessonData.solution && (
                <Box
                  sx={{
                    mt: 4,
                    position: "relative",
                    "&:hover": {
                      "& .solution-controls": {
                        opacity: 1,
                        visibility: "visible",
                      },
                    },
                  }}
                >
                  <Box
                    className="solution-controls"
                    sx={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      opacity: 0,
                      visibility: "hidden",
                      transition: "all 0.2s ease",
                      display: "flex",
                      gap: 1,
                      zIndex: 1,
                    }}
                  >
                    <Tooltip
                      title={
                        isEditingSolution
                          ? "Spara ändringar"
                          : "Redigera lösning"
                      }
                      placement="top"
                    >
                      <IconButton
                        size="small"
                        onClick={() => {
                          if (isEditingSolution) {
                            setIsEditingSolution(false);
                            showSnackbar(
                              "Lösningen har uppdaterats",
                              "success",
                            );
                          } else {
                            setIsEditingSolution(true);
                          }
                        }}
                      >
                        {isEditingSolution ? (
                          <SaveAsIcon fontSize="small" />
                        ) : (
                          <EditIcon fontSize="small" />
                        )}
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Ta bort lösning" placement="top">
                      <IconButton
                        size="small"
                        onClick={() => {
                          setLessonData((prev) => ({
                            ...prev,
                            solution: null,
                          }));
                          showSnackbar("Lösningen har tagits bort", "success");
                        }}
                      >
                        <DeleteOutlineIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "bold", mb: 2, fontSize: "1.5em" }}
                  >
                    Lösning
                  </Typography>
                  <Box
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === "dark" ? "#15202b" : "#e0e0e029",
                      borderRadius: "10px",
                      padding: "20px",
                    }}
                  >
                    {isEditingSolution ? (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 2,
                        }}
                      >
                        <TextField
                          fullWidth
                          multiline
                          rows={6}
                          value={lessonData.solution}
                          onChange={(e) =>
                            setLessonData((prev) => ({
                              ...prev,
                              solution: e.target.value,
                            }))
                          }
                          sx={{
                            "& .MuiInputBase-input": {
                              lineHeight: 1.8,
                            },
                            ...styles.editingTextField,
                          }}
                        />
                        <Button
                          variant={
                            theme.palette.mode === "light"
                              ? "purp"
                              : "contained"
                          }
                          color="primary"
                          onClick={() => {
                            setIsEditingSolution(false);
                            showSnackbar(
                              "Lösningen har uppdaterats",
                              "success",
                            );
                          }}
                          sx={{
                            mt: 2,
                            alignSelf: "flex-start", // This prevents the button from stretching
                            textTransform: "none", // Matches other buttons' styling
                          }}
                          startIcon={<SaveAsIcon />}
                        >
                          Spara ändringar
                        </Button>
                      </Box>
                    ) : (
                      <Typography
                        variant="body1"
                        sx={{ lineHeight: 1.8, whiteSpace: "pre-wrap" }}
                      >
                        {lessonData.solution}
                      </Typography>
                    )}
                  </Box>
                </Box>
              )}
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default DetectiveReadingCompLesson;
