import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  TextField,
  Box,
  IconButton,
  Stepper,
  Step,
  StepLabel,
  Paper,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import GenerateIcon from "@mui/icons-material/AutoAwesome";
import Logger from "./Logger";
import { ArrowBack, East as ArrowIcon } from "@mui/icons-material";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import LanguageSelector from "./LanguageSelector";
import ShortTextIcon from "@mui/icons-material/ShortText";
import GradeSelector, { gradeInstructions } from "./GradeSelector";
import ThemeFlowBox from "./ThemeFlowBox";
import {
  MusicNote as MusicNoteIcon,
  Article as ArticleIcon,
  MenuBook as MenuBookIcon,
} from "@mui/icons-material";

const ExercisesDialog = ({
  lessonData,
  user,
  theme,
  styles,
  buttonText = "Generera övningar",
  tooltip,
  addExercisesToLessonObject,
  buttonRef,
  open: externalOpen,
  initialStep = 0,
  initialEditMode = false,
  initialExercise = null,
  onClose,
  onUpdateExercise,
  showSnackbar,
  callApi,
}) => {
  const [internalOpen, setInternalOpen] = useState(false);
  const open = externalOpen !== undefined ? externalOpen : internalOpen;

  const [isGenerating, setIsGenerating] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [language, setLanguage] = useState("svenska");
  const [additionalInstructions, setAdditionalInstructions] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [grade, setGrade] = useState("åk6");
  const [generatedData, setGeneratedData] = useState(null);

  const [editedData, setEditedData] = useState(null);
  const [selectedExercises, setSelectedExercises] = useState({
    rhyme: false,
    poem: false,
    story: false,
  });

  useEffect(() => {
    if (initialExercise && initialEditMode) {
      setActiveStep(2);
      setIsEditing(true);
      setGeneratedData({
        title: "Redigera övning",
        exercises: [initialExercise],
      });
      setEditedData({
        title: "Redigera övning",
        exercises: [initialExercise],
      });
    }
  }, [initialExercise, initialEditMode]);

  const handleOpen = () => setInternalOpen(true);

  const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      setInternalOpen(false);
    }
    resetDialog();
  };

  const resetDialog = () => {
    setActiveStep(0);
    setIsGenerating(false);
    setIsEditing(false);
    setAdditionalInstructions("");
    setLanguage("svenska");
    setGrade("åk6");
    setGeneratedData(null);
    setEditedData(null);
    setSelectedExercises({
      rhyme: false,
      poem: false,
      story: false,
    });
  };

  const steps = [
    "Välj övningar",
    "Inställningar",
    addExercisesToLessonObject
      ? "Redigera och lägg till i lektion"
      : initialEditMode
        ? "Redigera övning"
        : "Redigera och spara",
  ];

  const handleNext = () => {
    if (activeStep === 1) {
      handleGenerate();
    }
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
    setGeneratedData(null);
    setIsGenerating(false);
    setIsEditing(false);
    setEditedData(null);
  };

  const handleStartEditing = () => {
    setIsEditing(true);
    setEditedData(JSON.parse(JSON.stringify(generatedData)));
  };

  const handleSaveEdits = () => {
    setGeneratedData(editedData);
    setIsEditing(false);

    if (onUpdateExercise && initialExercise) {
      onUpdateExercise(editedData.exercises[0]);
      handleClose();
    }
  };

  const handleGenerate = async () => {
    if (!Object.values(selectedExercises).some((value) => value)) {
      alert("Välj minst en övningstyp");
      return;
    }

    setIsGenerating(true);

    try {
      const response = await callApi(
        [
          {
            role: "system",
            content:
              "Du är en expert på att skapa kreativa skrivövningar. Följ det exakta JSON-formatet som anges i prompten.",
          },
          {
            role: "user",
            content: `Generera skrivövningar baserade på dessa ord: "${JSON.stringify(lessonData.words.map((w) => w.word))}"

- Övningarna ska vara på ${language}
- ${gradeInstructions[grade]}
${additionalInstructions ? `\n${additionalInstructions}` : ""}

- VIKTIGT: Använd \\n för radbrytningar i dikter och ramsor. ANVÄND INTE snedstreck.



Inkludera följande typer:
${selectedExercises.rhyme ? "- En rolig ramsa som använder orden\n" : ""}
${selectedExercises.poem ? "- En dikt som använder orden\n" : ""}
${selectedExercises.story ? "- En kort berättelse som använder orden\n" : ""}
${selectedExercises.examples ? "- EN pedagogisk exempelmening per ord som visar orden i naturligt sammanhang\n" : ""}


Svara i detta JSON-format:
{
  "title": "Skrivövningar",
  "exercises": [
    ${
      selectedExercises.rhyme
        ? `{
        "type": "rhyme",
        "title": "Ramsa",
        "content": "Den genererade ramsan"
      },`
        : ""
    }
    ${
      selectedExercises.poem
        ? `{
        "type": "poem",
        "title": "Dikt",
        "content": "Den genererade dikten"
      },`
        : ""
    }
    ${
      selectedExercises.story
        ? `{
        "type": "story",
        "title": "Berättelse",
        "content": "Den genererade berättelsen"
      }`
        : ""
    }

  ${
    selectedExercises.examples
      ? `{
      "type": "examples",
      "title": "Exempelmeningar",
      "content": [
        {
          "word": "ord1",
          "sentences": ["Första exempelmeningen", "Andra exempelmeningen"]
        },
        {
          "word": "ord2",
          "sentences": ["Första exempelmeningen", "Andra exempelmeningen"]
        }
      ]
    }`
      : ""
  }
        
  ]
}`,
          },
        ],
        4000,
        true,
        user.uid,
      );

      let messageContent = response.data.choices[0].message.content.trim();
      if (messageContent.startsWith("```json")) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith("```")) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      const data = JSON.parse(messageContent);

      Logger.log("data: ", JSON.stringify(data, null, 2));

      setGeneratedData(data);
    } catch (error) {
      console.error("Error generating exercises:", error);
      alert("Ett fel uppstod när övningarna skulle genereras. Försök igen.");
    } finally {
      setIsGenerating(false);
    }
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Box sx={{ mt: 1 }}>
            <Grid container spacing={2}>
              {[
                [
                  "rhyme",
                  "Ramsa",
                  "Lär dig orden genom roliga ramsor",
                  MusicNoteIcon,
                ],
                [
                  "poem",
                  "Dikt",
                  "Skapa en dikt med de valda orden",
                  ArticleIcon,
                ],
                [
                  "story",
                  "Berättelse",
                  "Skriv en kort berättelse med orden",
                  MenuBookIcon,
                ],

                [
                  "examples",
                  "Exempelmeningar",
                  "Se hur orden används i meningar",
                  ShortTextIcon,
                ],
              ].map(([key, title, subtitle, Icon]) => (
                <Grid item xs={12} sm={4} key={key}>
                  <ThemeFlowBox
                    title={title}
                    subtitle={subtitle}
                    icon={Icon}
                    isSelected={selectedExercises[key]}
                    diffView={true}
                    onClick={() =>
                      setSelectedExercises((prev) => ({
                        ...prev,
                        [key]: !prev[key],
                      }))
                    }
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        );

      case 1:
        return (
          <Grid
            container
            spacing={3}
            sx={{ width: { xs: "100%", md: "60%" }, margin: "0 auto", mt: 1 }}
          >
            <Grid item xs={12} md={12}>
              <Typography variant="subtitle1" sx={{ fontWeight: "500", mb: 1 }}>
                Årskurs:
              </Typography>
              <GradeSelector grade={grade} setGrade={setGrade} />
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: "500", mb: 1, mt: 1 }}
              >
                Språk:
              </Typography>
              <LanguageSelector language={language} setLanguage={setLanguage} />
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: "500", mb: 1, mt: 1 }}
              >
                Kompletterande instruktioner (valfritt):
              </Typography>
              <TextField
                fullWidth
                multiline
                rows={3}
                placeholder="T.ex. fokusera på vissa teman eller stilar..."
                value={additionalInstructions}
                onChange={(e) => setAdditionalInstructions(e.target.value)}
              />
            </Grid>
          </Grid>
        );

      case 2:
        return (
          <Box sx={{ mt: 2 }}>
            {generatedData && (
              <>
                {isEditing ? (
                  <Box>
                    <TextField
                      fullWidth
                      value={editedData.title}
                      onChange={(e) =>
                        setEditedData({
                          ...editedData,
                          title: e.target.value,
                        })
                      }
                      sx={{
                        mb: 6,
                        textAlign: "center",
                        display: initialExercise ? "none" : "block",
                      }}
                      InputProps={{
                        sx: {
                          "& input": { fontWeight: 600, textAlign: "center" },
                        },
                      }}
                    />
                    {editedData.exercises.map((exercise, index) => (
                      <Paper
                        key={index}
                        sx={{
                          ...styles.freshBox,
                          p: 3,
                          mb: 6,
                          boxShadow: "0 3px 28px rgba(12,8,51,.06)",
                          borderRadius: 4,
                          backgroundColor:
                            theme.palette.mode === "dark"
                              ? "#15202b"
                              : "#e0e0e029",
                          "&:hover": {
                            border: (theme) =>
                              `1px solid ${theme.palette.primary.main}`,
                          },
                        }}
                      >
                        <TextField
                          fullWidth
                          value={exercise.title}
                          onChange={(e) => {
                            const newData = { ...editedData };
                            newData.exercises[index].title = e.target.value;
                            setEditedData(newData);
                          }}
                          sx={{ mb: 2 }}
                          InputProps={{
                            sx: {
                              "& input": { fontWeight: 600 },
                            },
                          }}
                        />

                        {exercise.type === "examples" ? (
                          <Box sx={{ mt: 4 }}>
                            {exercise.content.map((wordExample, wordIndex) => (
                              <Box
                                key={wordIndex}
                                sx={{
                                  mb: 4,
                                  p: 3,
                                  border: "1px solid",
                                  borderColor:
                                    theme.palette.mode === "dark"
                                      ? "rgba(255,255,255,0.1)"
                                      : "rgba(0,0,0,0.1)",
                                  borderRadius: 2,
                                }}
                              >
                                <TextField
                                  fullWidth
                                  label="Ord"
                                  value={wordExample.word}
                                  onChange={(e) => {
                                    const newData = { ...editedData };
                                    newData.exercises[index].content[
                                      wordIndex
                                    ].word = e.target.value;
                                    setEditedData(newData);
                                  }}
                                  sx={{ mb: 2 }}
                                />
                                {wordExample.sentences.map(
                                  (sentence, sentenceIndex) => (
                                    <TextField
                                      key={sentenceIndex}
                                      fullWidth
                                      label={`Exempelmening ${sentenceIndex + 1}`}
                                      value={sentence}
                                      onChange={(e) => {
                                        const newData = { ...editedData };
                                        newData.exercises[index].content[
                                          wordIndex
                                        ].sentences[sentenceIndex] =
                                          e.target.value;
                                        setEditedData(newData);
                                      }}
                                      sx={{ mb: 2 }}
                                    />
                                  ),
                                )}
                              </Box>
                            ))}
                          </Box>
                        ) : (
                          <TextField
                            fullWidth
                            multiline
                            rows={6}
                            value={exercise.content}
                            onChange={(e) => {
                              const newData = { ...editedData };
                              newData.exercises[index].content = e.target.value;
                              setEditedData(newData);
                            }}
                          />
                        )}
                      </Paper>
                    ))}
                  </Box>
                ) : (
                  <>
                    {generatedData.exercises.map((exercise, index) => (
                      <Box key={index} sx={{ mt: 4 }}>
                        <Typography
                          variant="h6"
                          sx={{ fontWeight: "bold", mb: 2 }}
                        >
                          {exercise.title}
                        </Typography>
                        <Box
                          sx={{
                            backgroundColor:
                              theme.palette.mode === "dark"
                                ? "#15202b"
                                : "#e0e0e029",
                            borderRadius: "10px",
                            padding: "20px",
                          }}
                        >
                          {exercise.type === "examples" ? (
                            exercise.content.map((wordExample) => (
                              <Box key={wordExample.word} sx={{ mb: 3 }}>
                                <Typography sx={{ fontWeight: "500", mb: 1 }}>
                                  {wordExample.word}:
                                </Typography>
                                {wordExample.sentences.map(
                                  (sentence, sentenceIndex) => (
                                    <Typography
                                      key={sentenceIndex}
                                      sx={{ ml: 3, mb: 1 }}
                                    >
                                      • {sentence}
                                    </Typography>
                                  ),
                                )}
                              </Box>
                            ))
                          ) : (
                            <Typography
                              variant="body1"
                              sx={{ whiteSpace: "pre-line" }}
                            >
                              {exercise.content}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    ))}
                  </>
                )}
              </>
            )}
          </Box>
        );

      default:
        return null;
    }
  };

  return (
    <>
      <Button
        ref={buttonRef}
        variant={theme.palette.mode === "light" ? "purp" : "contained"}
        onClick={handleOpen}
        startIcon={<GenerateIcon />}
      >
        {buttonText}
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: { position: "relative", minWidth: "1200px" },
        }}
      >
        <Backdrop
          open={isGenerating}
          sx={{
            position: "absolute",
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor:
              theme.palette.mode === "dark"
                ? "rgba(0, 0, 0, 0.85)"
                : "rgba(0, 0, 0, 0.8)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CircularProgress sx={{ color: "white" }} size={40} />
            <Typography variant="h6" sx={{ mt: 2, color: "white" }}>
              Genererar ditt material...
            </Typography>
          </Box>
        </Backdrop>

        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>

          <Typography
            sx={{
              textAlign: "center",
              fontWeight: "600",
              mt: 3,
              fontSize: "1.5rem",
            }}
          >
            {activeStep === 0
              ? "Välj övningar"
              : activeStep === 1
                ? "Inställningar"
                : activeStep === 2
                  ? addExercisesToLessonObject
                    ? "Redigera och lägg till i lektion"
                    : initialExercise
                      ? "Redigera och spara"
                      : "Redigera och spara"
                  : ""}
          </Typography>

          <Box sx={{ mt: 4, mb: 4, position: "relative" }}>
            {activeStep > 0 && (
              <IconButton
                onClick={handleBack}
                sx={{
                  position: "absolute",
                  top: -7,
                  left: 0,
                  zIndex: 10,
                  display: initialExercise ? "none" : undefined,
                }}
              >
                <ArrowBack />
              </IconButton>
            )}
            <Stepper
              activeStep={initialExercise ? 0 : activeStep}
              sx={{
                mb: 4,
                width: { xs: "100%", md: "60%" },
                margin: "0 auto",
                display: initialExercise ? "none" : undefined,
              }}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        </DialogTitle>

        <DialogContent sx={{ position: "relative", minHeight: "440px" }}>
          {renderStepContent(activeStep)}
        </DialogContent>

        <DialogActions sx={{ p: 2, justifyContent: "space-between" }}>
          {activeStep === 2 ? (
            <>
              <div>
                {isEditing ? (
                  <Button
                    onClick={handleSaveEdits}
                    variant="purp"
                    sx={{ mr: 2 }}
                    startIcon={<SaveAsIcon />}
                  >
                    Spara ändringar
                  </Button>
                ) : (
                  <Button
                    startIcon={<EditIcon />}
                    variant="purp"
                    onClick={handleStartEditing}
                    sx={{ mr: 2 }}
                  >
                    Redigera övningar
                  </Button>
                )}

                {addExercisesToLessonObject && (
                  <Button
                    variant="success"
                    onClick={() => {
                      addExercisesToLessonObject((prevLessonData) => {
                        // Add the exercises to the lesson data
                        const existingExercises =
                          prevLessonData.exercises?.filter(
                            (existingEx) =>
                              !generatedData.exercises.some(
                                (newEx) => newEx.type === existingEx.type,
                              ),
                          ) || [];

                        // Add new exercises while keeping other existing ones
                        const updatedData = {
                          ...prevLessonData,
                          exercises: [
                            ...existingExercises,
                            ...generatedData.exercises,
                          ],
                        };
                        return updatedData;
                      });
                      showSnackbar(
                        "Övningarna har lagts till i din lektion!",
                        "success",
                      );

                      handleClose();
                    }}
                    sx={{ mr: 2 }}
                    startIcon={<GenerateIcon />}
                  >
                    Lägg till i din lektion
                  </Button>
                )}
              </div>

              {!initialExercise && (
                <Button onClick={handleClose} variant="print">
                  Stäng
                </Button>
              )}
            </>
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <Button
                  disabled={
                    activeStep === 0 &&
                    !Object.values(selectedExercises).some((value) => value)
                  }
                  onClick={handleNext}
                  variant="purp"
                  startIcon={
                    activeStep === 1 && isGenerating ? (
                      <CircularProgress size={20} />
                    ) : null
                  }
                >
                  {activeStep === steps.length - 1 ? "Slutför" : "Nästa"}{" "}
                  <ArrowIcon sx={{ ml: 1 }} />
                </Button>
              </Box>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ExercisesDialog;
