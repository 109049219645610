import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  Grid,
  Stepper,
  Step,
  Tooltip,
  StepLabel,
  DialogActions,
  IconButton,
  Divider,
} from "@mui/material";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import CloseIcon from "@mui/icons-material/Close";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ThemeFlowBox from "./ThemeFlowBox";

import MapIcon from "@mui/icons-material/Map";
import EditIcon from "@mui/icons-material/Edit";
import MosqueIcon from "@mui/icons-material/Mosque";
import {
  MenuBook,
  Science,
  Language,
  Psychology,
  SportsHandball,
  Calculate,
  History,
  Architecture,
  Church,
  Translate,
  Public,
  MusicNote,
  BiotechOutlined,
  Science as Chemistry,
  Brush,
  EmojiObjects,
  School,
} from "@mui/icons-material";

const subjectDescriptions = {
  Historia:
    "Från stenåldern till nutid - människans och samhällets utveckling genom tiderna",
  Samhällskunskap: "Demokrati, ekonomi, samhälle och aktuella samhällsfrågor",
  Geografi:
    "Kartor, klimat, befolkning och samspelet mellan människa och natur",
  Religion: "Världsreligioner, livsåskådningar och existentiella frågor",
};

const subjectData = {
  Historia: {
    "Människans förhistoria och stenåldern": [
      {
        title: "Människans förhistoria och stenålder",
        url: "https://www.so-rummet.se/kategorier/historia/manniskans-forhistoria-och-stenaldern",
      },
      {
        title: "När jordbruket kom till Sverige",
        url: "https://www.so-rummet.se/fakta-artiklar/nar-jordbruket-kom-till-sverige",
      },
      {
        title: "Lätta fakta om den senaste istiden",
        url: "https://www.so-rummet.se/fakta-artiklar/latta-fakta-om-den-senaste-istiden",
      },
      {
        title: "De första bönderna",
        url: "https://www.so-rummet.se/fakta-artiklar/de-forsta-bonderna",
      },
      {
        title: "Människans äldsta historia",
        url: "https://www.so-rummet.se/fakta-artiklar/manniskans-aldsta-historia",
      },
      {
        title: "Evolutionen - människans utveckling",
        url: "https://www.so-rummet.se/fakta-artiklar/evolutionen-manniskans-utveckling",
      },
      {
        title: "Jordens historia hoptryckt till ett dygn",
        url: "https://www.so-rummet.se/fakta-artiklar/jordens-historia-hoptryckt-till-ett-dygn",
      },
      {
        title: "Husdjurens betydelse för människan",
        url: "https://www.so-rummet.se/fakta-artiklar/husdjurens-betydelse-manniskan",
      },
    ],
    "Forntiden och antiken": [
      {
        title: "Forntidens och antikens huvudlinjer",
        url: "https://www.so-rummet.se/kategorier/historia/forntiden-och-antiken/forntidens-och-antikens-huvudlinjer",
      },
      {
        title: "Forntidens Egypten",
        url: "https://www.so-rummet.se/kategorier/historia/forntiden-och-antiken/forntidens-egypten",
      },
      {
        title: "Kinas fornhistoria",
        url: "https://www.so-rummet.se/kategorier/historia/forntiden-och-antiken/kinas-fornhistoria",
      },
      {
        title: "Bronsåldern i Norden",
        url: "https://www.so-rummet.se/kategorier/historia/forntiden-och-antiken/bronsaldern-i-norden",
      },
      {
        title: "Antikens Persien",
        url: "https://www.so-rummet.se/kategorier/historia/forntiden-och-antiken/antikens-persien",
      },
      {
        title: "Antikens Grekland",
        url: "https://www.so-rummet.se/kategorier/historia/forntiden-och-antiken/antikens-grekland",
      },
      {
        title: "Romarriket",
        url: "https://www.so-rummet.se/kategorier/historia/forntiden-och-antiken/romarriket",
      },
      {
        title: "Järnåldern i Norden",
        url: "https://www.so-rummet.se/kategorier/historia/forntiden-och-antiken/jarnaldern-i-norden",
      },
      {
        title: "Folkvandringstiden",
        url: "https://www.so-rummet.se/kategorier/historia/forntiden-och-antiken/folkvandringstiden",
      },
    ],
    Medeltiden: [
      {
        title: "Medeltidens huvudlinjer",
        url: "https://www.so-rummet.se/kategorier/historia/medeltiden/medeltidens-huvudlinjer",
      },
      {
        title: "Vikingatiden",
        url: "https://www.so-rummet.se/kategorier/historia/medeltiden/vikingatiden",
      },
      {
        title: "Kyrkan och klostren på medeltiden",
        url: "https://www.so-rummet.se/kategorier/historia/medeltiden/kyrkan-och-klostren-pa-medeltiden",
      },
      {
        title: "Feodalismen",
        url: "https://www.so-rummet.se/kategorier/historia/medeltiden/feodalismen",
      },
      {
        title: "Riddare och riddarordnar",
        url: "https://www.so-rummet.se/kategorier/historia/medeltiden/riddare-och-riddarordnar",
      },
      {
        title: "Korstågen",
        url: "https://www.so-rummet.se/kategorier/historia/medeltiden/korstagen",
      },
      {
        title: "Digerdöden",
        url: "https://www.so-rummet.se/kategorier/historia/medeltiden/digerdoden",
      },
      {
        title: "Livet på landet och i staden under medeltiden",
        url: "https://www.so-rummet.se/kategorier/historia/medeltiden/livet-pa-landet-och-i-staden-pa-medeltiden",
      },
      {
        title: "Ekonomi och handel på medeltiden",
        url: "https://www.so-rummet.se/kategorier/historia/medeltiden/ekonomi-och-handel-pa-medeltiden",
      },
      {
        title: "Medeltida vetenskap och teknik",
        url: "https://www.so-rummet.se/kategorier/historia/medeltiden/medeltida-vetenskap-teknik-och-kommunikationer",
      },
    ],
    "Nya tiden": [
      {
        title: "Nya tidens huvudlinjer",
        url: "https://www.so-rummet.se/kategorier/historia/nya-tiden/nya-tidens-huvudlinjer",
      },
      {
        title: "Renässans, upptäcktsresor och en ny världsbild",
        url: "https://www.so-rummet.se/kategorier/historia/nya-tiden/renassans-upptacktsresor-och-en-ny-varldsbild",
      },
      {
        title: "Reformationen",
        url: "https://www.so-rummet.se/kategorier/historia/nya-tiden/reformationen",
      },
      {
        title: "Vasatidens Sverige",
        url: "https://www.so-rummet.se/kategorier/historia/nya-tiden/vasatidens-sverige",
      },
      {
        title: "Stormaktstidens Sverige",
        url: "https://www.so-rummet.se/kategorier/historia/nya-tiden/stormaktstidens-sverige",
      },
      {
        title: "Häxprocesserna",
        url: "https://www.so-rummet.se/kategorier/historia/nya-tiden/haxprocesserna",
      },
      {
        title: "Frihetstidens Sverige",
        url: "https://www.so-rummet.se/kategorier/historia/nya-tiden/frihetstidens-sverige",
      },
      {
        title: "Upplysningen",
        url: "https://www.so-rummet.se/kategorier/historia/nya-tiden/upplysningen",
      },
      {
        title: "Livet på landet och i staden 1500-1776",
        url: "https://www.so-rummet.se/kategorier/historia/nya-tiden/livet-pa-landet-och-i-staden-1500-1776",
      },

      {
        title: "Ekonomi och handel 1500-1776",
        url: "https://www.so-rummet.se/kategorier/historia/nya-tiden/ekonomi-och-handel-1500-1776",
      },
    ],
    "Det långa 1800-talet": [
      {
        title: "Det långa 1800-talets huvudlinjer",
        url: "https://www.so-rummet.se/kategorier/historia/det-langa-1800-talet/det-langa-1800-talets-huvudlinjer",
      },
      {
        title: "Amerikanska revolutionen",
        url: "https://www.so-rummet.se/kategorier/historia/det-langa-1800-talet/amerikanska-revolutionen",
      },
      {
        title: "Franska revolutionen",
        url: "https://www.so-rummet.se/kategorier/historia/det-langa-1800-talet/franska-revolutionen",
      },
      {
        title: "Napoleontiden",
        url: "https://www.so-rummet.se/kategorier/historia/det-langa-1800-talet/napoleontiden",
      },
      {
        title: "Gustavianska tidens Sverige",
        url: "https://www.so-rummet.se/kategorier/historia/det-langa-1800-talet/gustavianska-tidens-sverige",
      },
      {
        title: "Sverige under 1800-talet",
        url: "https://www.so-rummet.se/kategorier/historia/det-langa-1800-talet/sverige-under-1800-talet",
      },
      {
        title: "Emigrationen",
        url: "https://www.so-rummet.se/kategorier/historia/det-langa-1800-talet/emigrationen",
      },

      {
        title: "Livet på landet och i staden 1776-1914",
        url: "https://www.so-rummet.se/kategorier/historia/det-langa-1800-talet/livet-pa-landet-och-i-staden-1776-1914",
      },
      {
        title: "Ekonomi och handel 1776-1914",
        url: "https://www.so-rummet.se/kategorier/historia/det-langa-1800-talet/ekonomi-och-handel-1776-1914",
      },
      {
        title: "Industriella revolutionen",
        url: "https://www.so-rummet.se/kategorier/historia/det-langa-1800-talet/industriella-revolutionen",
      },
    ],
    "Det korta 1900-talet": [
      {
        title: "Första världskriget",
        url: "https://www.so-rummet.se/kategorier/historia/det-korta-1900-talet/forsta-varldskriget",
      },
      {
        title: "Ryska revolutionen",
        url: "https://www.so-rummet.se/kategorier/historia/det-korta-1900-talet/ryska-revolutionen",
      },
      {
        title: "Mellankrigstiden",
        url: "https://www.so-rummet.se/kategorier/historia/det-korta-1900-talet/mellankrigstiden",
      },
      {
        title: "Andra världskriget",
        url: "https://www.so-rummet.se/kategorier/historia/det-korta-1900-talet/andra-varldskriget",
      },
      {
        title: "Sverige under andra världskriget",
        url: "https://www.so-rummet.se/kategorier/historia/det-korta-1900-talet/sverige-under-andra-varldskriget",
      },
      {
        title: "Efterkrigstidens huvudlinjer",
        url: "https://www.so-rummet.se/kategorier/historia/det-korta-1900-talet/efterkrigstidens-huvudlinjer",
      },
      {
        title: "Kalla kriget",
        url: "https://www.so-rummet.se/kategorier/historia/det-korta-1900-talet/kalla-kriget",
      },
      {
        title: "Sverige under 1900-talet",
        url: "https://www.so-rummet.se/kategorier/historia/det-korta-1900-talet/sverige-under-1900-talet",
      },
      {
        title: "Ekonomi och handel 1914-1991",
        url: "https://www.so-rummet.se/kategorier/historia/det-korta-1900-talet/ekonomi-och-handel-1914-1991",
      },
      {
        title: "Vetenskap, teknik och kommunikationer 1914-1991",
        url: "https://www.so-rummet.se/kategorier/historia/det-korta-1900-talet/vetenskap-teknik-och-kommunikationer-1914-1991",
      },
    ],
    Nutidshistoria: [
      {
        title: "Dagens och morgondagens folkrörelser",
        url: "https://www.so-rummet.se/fakta-artiklar/dagens-och-morgondagens-folkrorelser",
      },
      {
        title: "Demokratins globala vågor",
        url: "https://www.so-rummet.se/fakta-artiklar/demokratins-globala-vagor",
      },
      {
        title: "Sverige och EU",
        url: "https://www.so-rummet.se/fakta-artiklar/sverige-och-eu",
      },
      {
        title: "Globalisering - orsaker och följder",
        url: "https://www.so-rummet.se/fakta-artiklar/globalisering-orsaker-och-foljder",
      },
      {
        title: "Vad är terrorism?",
        url: "https://www.so-rummet.se/fakta-artiklar/vad-ar-terrorism",
      },
      {
        title: "Terrorismens historia",
        url: "https://www.so-rummet.se/fakta-artiklar/terrorismens-historia",
      },
      {
        title: "Kosovokriget",
        url: "https://www.so-rummet.se/fakta-artiklar/kosovokriget",
      },
      {
        title: "Östersjön - fredens hav?",
        url: "https://www.so-rummet.se/fakta-artiklar/ostersjon-fredens-hav",
      },
      {
        title: "Gerilla",
        url: "https://www.so-rummet.se/fakta-artiklar/gerilla",
      },
      {
        title: "Kuwaitkriget",
        url: "https://www.so-rummet.se/fakta-artiklar/kuwaitkriget",
      },
    ],
    "Historiska teman": [
      {
        title: "Kvinnohistoria och genushistoria",
        url: "https://www.so-rummet.se/kategorier/historia/historiska-teman/kvinnohistoria-och-genushistoria",
      },
      {
        title: "Brott och straff förr",
        url: "https://www.so-rummet.se/kategorier/historia/historiska-teman/brott-och-straff-forr",
      },
      {
        title: "Idrottshistoria",
        url: "https://www.so-rummet.se/kategorier/historia/historiska-teman/idrottshistoria",
      },
      {
        title: "Historia om läkekonst och sjukdomar",
        url: "https://www.so-rummet.se/kategorier/historia/historiska-teman/historia-om-lakekonst-och-sjukdomar",
      },
      {
        title: "Nationalismens historia",
        url: "https://www.so-rummet.se/kategorier/historia/historiska-teman/nationalismens-historia",
      },
      {
        title: "Svenska högtider och traditioner förr och idag",
        url: "https://www.so-rummet.se/kategorier/historia/historiska-teman/svenska-hogtider-och-traditioner-forr-och-idag",
      },
      {
        title: "Ekonomisk historia",
        url: "https://www.so-rummet.se/kategorier/historia/historiska-teman/ekonomisk-historia",
      },
      {
        title: "Demokratins historia",
        url: "https://www.so-rummet.se/kategorier/historia/historiska-teman/demokratins-historia",
      },
      {
        title: "Arkitektur- och stilhistoria",
        url: "https://www.so-rummet.se/kategorier/historia/historiska-teman/arkitektur-och-stilhistoria",
      },
      {
        title: "Historia om mode, utseende och hygien",
        url: "https://www.so-rummet.se/kategorier/historia/historiska-teman/historia-om-mode-utseende-och-hygien",
      },
    ],
    "Världens länder - historia": [
      {
        title: "Albaniens historia",
        url: "https://www.so-rummet.se/kategorier/historia/varldens-lander-historia/europa-historia/albaniens-historia",
      },
      {
        title: "Andorras historia",
        url: "https://www.so-rummet.se/kategorier/historia/varldens-lander-historia/europa-historia/andorras-historia",
      },
      {
        title: "Belgiens historia",
        url: "https://www.so-rummet.se/kategorier/historia/varldens-lander-historia/europa-historia/belgiens-historia",
      },
      {
        title: "Belarus historia",
        url: "https://www.so-rummet.se/kategorier/historia/varldens-lander-historia/europa-historia/belarus-historia",
      },
      {
        title: "Bosnien-Hercegovinas historia",
        url: "https://www.so-rummet.se/kategorier/historia/varldens-lander-historia/europa-historia/bosnien-hercegovinas-historia",
      },
      {
        title: "Bulgariens historia",
        url: "https://www.so-rummet.se/kategorier/historia/varldens-lander-historia/europa-historia/bulgariens-historia",
      },
      {
        title: "Cyperns historia",
        url: "https://www.so-rummet.se/kategorier/historia/varldens-lander-historia/europa-historia/cyperns-historia",
      },
      {
        title: "Danmarks historia",
        url: "https://www.so-rummet.se/kategorier/historia/varldens-lander-historia/europa-historia/danmarks-historia",
      },
      {
        title: "Estlands historia",
        url: "https://www.so-rummet.se/kategorier/historia/varldens-lander-historia/europa-historia/estlands-historia",
      },
      {
        title: "Finlands historia",
        url: "https://www.so-rummet.se/kategorier/historia/varldens-lander-historia/europa-historia/finlands-historia",
      },
    ],
    Historieteori: [
      {
        title: "Historisk analys och begrepp",
        url: "https://www.so-rummet.se/kategorier/historia/historieteori/historisk-analys-och-begrepp",
      },
      {
        title: "Historiekultur och historiemedvetande",
        url: "https://www.so-rummet.se/kategorier/historia/historieteori/historiekultur-och-historiemedvetande",
      },
    ],
  },
  Samhällskunskap: {
    "Individers och gruppers identitet och relationer": [
      {
        title: "Arbete och boende",
        url: "https://www.so-rummet.se/kategorier/samhallskunskap/individers-och-gruppers-identitet-och-relationer/arbete-och-boende",
      },
      {
        title: "Svenska välfärdssamhället och välfärdsstrukturer",
        url: "https://www.so-rummet.se/kategorier/samhallskunskap/individers-och-gruppers-identitet-och-relationer/svenska-valfardssamhallet-och-valfardsstrukturer",
      },
      {
        title: "Sexualitet, könsroller och jämställdhet",
        url: "https://www.so-rummet.se/kategorier/samhallskunskap/individers-och-gruppers-identitet-och-relationer/sexualitet-konsroller-och-jamstalldhet",
      },
      {
        title: "Sveriges nationella minoriteter",
        url: "https://www.so-rummet.se/kategorier/samhallskunskap/individers-och-gruppers-identitet-och-relationer/sveriges-nationella-minoriteter",
      },
      {
        title: "Sveriges befolkning, migration och integration",
        url: "https://www.so-rummet.se/kategorier/samhallskunskap/individers-och-gruppers-identitet-och-relationer/sveriges-befolkning-migration-och-integration",
      },
      {
        title: "Rasism och främlingsfientlighet",
        url: "https://www.so-rummet.se/kategorier/samhallskunskap/individers-och-gruppers-identitet-och-relationer/rasism-och-framlingsfientlighet",
      },
    ],
    Demokrati: [
      {
        title: "Demokratins grunder",
        url: "https://www.so-rummet.se/kategorier/samhallskunskap/demokrati/demokratins-grunder",
      },
      {
        title: "Sveriges politiska system",
        url: "https://www.so-rummet.se/kategorier/samhallskunskap/demokrati/sveriges-politiska-system",
      },
      {
        title: "Politiska partier i Sveriges riksdag",
        url: "https://www.so-rummet.se/kategorier/samhallskunskap/demokrati/politiska-partier-i-sveriges-riksdag",
      },
      {
        title: "Demokratisk påverkan och demokratimodeller",
        url: "https://www.so-rummet.se/kategorier/samhallskunskap/demokrati/demokratisk-paverkan-och-demokratimodeller",
      },
      {
        title: "Demokratiska system och olika statsskick",
        url: "https://www.so-rummet.se/kategorier/samhallskunskap/demokrati/demokratiska-system-och-olika-statsskick",
      },
    ],
    "Politiska ideologier": [
      {
        title: "Liberalism",
        url: "https://www.so-rummet.se/kategorier/samhallskunskap/politiska-ideologier/liberalism",
      },
      {
        title: "Konservatism",
        url: "https://www.so-rummet.se/kategorier/samhallskunskap/politiska-ideologier/konservatism",
      },
      {
        title: "Socialism",
        url: "https://www.so-rummet.se/kategorier/samhallskunskap/politiska-ideologier/socialism",
      },
      {
        title: "Feminism",
        url: "https://www.so-rummet.se/kategorier/samhallskunskap/politiska-ideologier/feminism",
      },
      {
        title: "Ekologism",
        url: "https://www.so-rummet.se/kategorier/samhallskunskap/politiska-ideologier/ekologism",
      },
      {
        title: "Anarkism",
        url: "https://www.so-rummet.se/kategorier/samhallskunskap/politiska-ideologier/anarkism",
      },
      {
        title: "Nationalism",
        url: "https://www.so-rummet.se/kategorier/samhallskunskap/politiska-ideologier/nationalism",
      },
      {
        title: "Fascism och nazism",
        url: "https://www.so-rummet.se/kategorier/samhallskunskap/politiska-ideologier/fascism-och-nazism",
      },
    ],
    "Lag och rätt": [
      {
        title: "Regler och lagar",
        url: "https://www.so-rummet.se/kategorier/samhallskunskap/lag-och-ratt/regler-och-lagar",
      },
      {
        title: "Rättssystemet och domstolarna",
        url: "https://www.so-rummet.se/kategorier/samhallskunskap/lag-och-ratt/rattssystemet-och-domstolarna",
      },
      {
        title: "Rättegång - från brott till straff",
        url: "https://www.so-rummet.se/kategorier/samhallskunskap/lag-och-ratt/rattegang-fran-brott-till-straff",
      },
      {
        title: "Brottslighet och kriminalvård",
        url: "https://www.so-rummet.se/kategorier/samhallskunskap/lag-och-ratt/brottslighet-och-kriminalvard",
      },
    ],
    "Medier och kommunikation": [
      {
        title: "Mediernas uppbyggnad och funktion",
        url: "https://www.so-rummet.se/kategorier/samhallskunskap/medier-och-kommunikation/mediernas-uppbyggnad-och-funktion",
      },
      {
        title: "Media och samhället",
        url: "https://www.so-rummet.se/kategorier/samhallskunskap/medier-och-kommunikation/media-och-samhallet",
      },
      {
        title: "Nyhetsvärdering",
        url: "https://www.so-rummet.se/kategorier/samhallskunskap/medier-och-kommunikation/nyhetsvardering",
      },
      {
        title: "Media och påverkan",
        url: "https://www.so-rummet.se/kategorier/samhallskunskap/medier-och-kommunikation/media-och-paverkan",
      },
    ],
    "Ekonomi och handel": [
      {
        title: "Privatekonomi",
        url: "https://www.so-rummet.se/kategorier/samhallskunskap/ekonomi-och-handel/privatekonomi",
      },
      {
        title: "Samhällsekonomi",
        url: "https://www.so-rummet.se/kategorier/samhallskunskap/ekonomi-och-handel/samhallsekonomi",
      },
      {
        title: "Arbetsmarknad, arbetsrätt och arbetsmiljö",
        url: "https://www.so-rummet.se/kategorier/samhallskunskap/ekonomi-och-handel/arbetsmarknad-arbetsratt-och-arbetsmiljo",
      },
      {
        title: "Internationell ekonomi och handel",
        url: "https://www.so-rummet.se/kategorier/samhallskunskap/ekonomi-och-handel/internationell-ekonomi-och-handel",
      },
      {
        title: "Nationalekonomiska teorier",
        url: "https://www.so-rummet.se/kategorier/samhallskunskap/ekonomi-och-handel/nationalekonomiska-teorier",
      },
    ],
    "Internationell politik och globala samhällsfrågor": [
      {
        title: "Globalisering",
        url: "https://www.so-rummet.se/kategorier/samhallskunskap/internationell-politik-och-globala-samhallsfragor/globalisering",
      },
      {
        title: "Mänskliga rättigheter",
        url: "https://www.so-rummet.se/kategorier/samhallskunskap/internationell-politik-och-globala-samhallsfragor/manskliga-rattigheter",
      },
      {
        title: "Folkrätt och krigets lagar",
        url: "https://www.so-rummet.se/kategorier/samhallskunskap/internationell-politik-och-globala-samhallsfragor/folkratt-och-krigets-lagar",
      },
      {
        title: "Internationella relationer",
        url: "https://www.so-rummet.se/kategorier/samhallskunskap/internationell-politik-och-globala-samhallsfragor/internationella-relationer",
      },
    ],
    "Sveriges beredskap": [
      {
        title: "Sveriges beredskap",
        url: "https://www.so-rummet.se/kategorier/samhallskunskap/sveriges-beredskap",
      },
    ],
    "Världens länder - samhällskunskap": [
      {
        title: "Fakta om Albanien",
        url: "https://www.so-rummet.se/kategorier/samhallskunskap/varldens-lander-samhallskunskap/europa-samhallskunskap/fakta-om-albanien",
      },
      {
        title: "Fakta om Andorra",
        url: "https://www.so-rummet.se/kategorier/samhallskunskap/varldens-lander-samhallskunskap/europa-samhallskunskap/fakta-om-andorra",
      },
      {
        title: "Fakta om Belarus",
        url: "https://www.so-rummet.se/kategorier/samhallskunskap/varldens-lander-samhallskunskap/europa-samhallskunskap/fakta-om-belarus",
      },
      {
        title: "Fakta om Belgien",
        url: "https://www.so-rummet.se/kategorier/samhallskunskap/varldens-lander-samhallskunskap/europa-samhallskunskap/fakta-om-belgien",
      },
      {
        title: "Fakta om Bosnien-Hercegovina",
        url: "https://www.so-rummet.se/kategorier/samhallskunskap/varldens-lander-samhallskunskap/europa-samhallskunskap/fakta-om-bosnien-hercegovina",
      },
      {
        title: "Fakta om Bulgarien",
        url: "https://www.so-rummet.se/kategorier/samhallskunskap/varldens-lander-samhallskunskap/europa-samhallskunskap/fakta-om-bulgarien",
      },
      {
        title: "Fakta om Cypern",
        url: "https://www.so-rummet.se/kategorier/samhallskunskap/varldens-lander-samhallskunskap/europa-samhallskunskap/fakta-om-cypern",
      },
      {
        title: "Fakta om Danmark",
        url: "https://www.so-rummet.se/kategorier/samhallskunskap/varldens-lander-samhallskunskap/europa-samhallskunskap/fakta-om-danmark",
      },
      {
        title: "Fakta om Estland",
        url: "https://www.so-rummet.se/kategorier/samhallskunskap/varldens-lander-samhallskunskap/europa-samhallskunskap/fakta-om-estland",
      },
      {
        title: "Fakta om Finland",
        url: "https://www.so-rummet.se/kategorier/samhallskunskap/varldens-lander-samhallskunskap/europa-samhallskunskap/fakta-om-finland",
      },
      {
        title: "Fakta om Frankrike",
        url: "https://www.so-rummet.se/kategorier/samhallskunskap/varldens-lander-samhallskunskap/europa-samhallskunskap/fakta-om-frankrike",
      },
      {
        title: "Fakta om Georgien",
        url: "https://www.so-rummet.se/kategorier/samhallskunskap/varldens-lander-samhallskunskap/europa-samhallskunskap/fakta-om-georgien",
      },
    ],
  },
  Geografi: {
    Klimat: [
      {
        title: "Lätta fakta om Sveriges väder och klimat",
        url: "https://www.so-rummet.se/fakta-artiklar/latta-fakta-om-sveriges-vader-och-klimat",
      },
      {
        title: "Lätta fakta om den senaste istiden",
        url: "https://www.so-rummet.se/fakta-artiklar/latta-fakta-om-den-senaste-istiden",
      },
      {
        title: "Lätta fakta om vindar",
        url: "https://www.so-rummet.se/fakta-artiklar/latta-fakta-om-vindar",
      },
      {
        title: "Atmosfären, moln och vindar",
        url: "https://www.so-rummet.se/fakta-artiklar/atmosfaren-moln-och-vindar",
      },
      {
        title: "Orkaner - ett fruktat väderfenomen",
        url: "https://www.so-rummet.se/fakta-artiklar/orkaner-ett-fruktat-vaderfenomen",
      },
      {
        title: "Jordens istider",
        url: "https://www.so-rummet.se/fakta-artiklar/jordens-istider",
      },
      {
        title: "Havsströmmar",
        url: "https://www.so-rummet.se/fakta-artiklar/havsstrommar",
      },
    ],
    "Växtlighet och vatten": [
      {
        title: "Lätta fakta om havet och kusten",
        url: "https://www.so-rummet.se/fakta-artiklar/latta-fakta-om-havet-och-kusten",
      },
      {
        title: "Lätta fakta om älvar",
        url: "https://www.so-rummet.se/fakta-artiklar/latta-fakta-om-alvar",
      },
      {
        title: "Lätta fakta om sjöar",
        url: "https://www.so-rummet.se/fakta-artiklar/latta-fakta-om-sjoar",
      },
      {
        title: "Lätta fakta om Sveriges skog",
        url: "https://www.so-rummet.se/fakta-artiklar/latta-fakta-om-sveriges-skog",
      },

      {
        title: "Lätta fakta om fjällen",
        url: "https://www.so-rummet.se/fakta-artiklar/latta-fakta-om-fjallen",
      },
      {
        title: "Vattenkvarnar och vattenhjul",
        url: "https://www.so-rummet.se/fakta-artiklar/vattenkvarnar-och-vattenhjul",
      },
      {
        title: "Lätta fakta om Alperna",
        url: "https://www.so-rummet.se/fakta-artiklar/latta-fakta-om-alperna",
      },
      {
        title: "Naturens rättigheter",
        url: "https://www.so-rummet.se/fakta-artiklar/naturens-rattigheter",
      },
      {
        title: "Lätta fakta om Nilen",
        url: "https://www.so-rummet.se/fakta-artiklar/latta-fakta-om-nilen",
      },
      {
        title: "Biologisk mångfald - lika viktigt som klimatfrågan",
        url: "https://www.so-rummet.se/fakta-artiklar/biologisk-mangfald-lika-viktigt-som-klimatfragan",
      },
      {
        title: "Lätta fakta om jorden",
        url: "https://www.so-rummet.se/fakta-artiklar/latta-fakta-om-jorden",
      },
    ],
    "Jordens inre och yttre krafter": [
      {
        title: "Lätta fakta om den senaste istiden",
        url: "https://www.so-rummet.se/fakta-artiklar/latta-fakta-om-den-senaste-istiden",
      },
      {
        title: "Lätta fakta om Alperna",
        url: "https://www.so-rummet.se/fakta-artiklar/latta-fakta-om-alperna",
      },
      {
        title: "Lätta fakta om jordbävningar",
        url: "https://www.so-rummet.se/fakta-artiklar/latta-fakta-om-jordbavningar",
      },
      {
        title: "Lätta fakta om kontinentaldrift",
        url: "https://www.so-rummet.se/fakta-artiklar/latta-fakta-om-kontinentaldrift",
      },
      {
        title: "Lätta fakta om öknar",
        url: "https://www.so-rummet.se/fakta-artiklar/latta-fakta-om-oknar",
      },
      {
        title: "Orkaner - ett fruktat väderfenomen",
        url: "https://www.so-rummet.se/fakta-artiklar/orkaner-ett-fruktat-vaderfenomen",
      },
      {
        title: "Jordens istider",
        url: "https://www.so-rummet.se/fakta-artiklar/jordens-istider",
      },
    ],

    "Jordens resurser och handelsmönster": [
      {
        title: "Lätta fakta om Sveriges jordbruksbygd och jordbruk",
        url: "https://www.so-rummet.se/fakta-artiklar/latta-fakta-om-sveriges-jordbruksbygd-och-jordbruk",
      },
      {
        title: "Lätta fakta om havet och kusten",
        url: "https://www.so-rummet.se/fakta-artiklar/latta-fakta-om-havet-och-kusten",
      },
      {
        title: "Lätta fakta om älvar",
        url: "https://www.so-rummet.se/fakta-artiklar/latta-fakta-om-alvar",
      },
      {
        title: "Lätta fakta om sjöar",
        url: "https://www.so-rummet.se/fakta-artiklar/latta-fakta-om-sjoar",
      },
      {
        title: "Lätta fakta om Sveriges skog",
        url: "https://www.so-rummet.se/fakta-artiklar/latta-fakta-om-sveriges-skog",
      },
      {
        title: "Lätta fakta om fjällen",
        url: "https://www.so-rummet.se/fakta-artiklar/latta-fakta-om-fjallen",
      },
      {
        title: "Fiskets historia - från vardagsfiske till överfiske",
        url: "https://www.so-rummet.se/fakta-artiklar/fiskets-historia-fran-vardagsfiske-till-overfiske",
      },
      {
        title:
          "Påskön - en historia om miljöförstöring och överexploatering av naturresurser",
        url: "https://www.so-rummet.se/fakta-artiklar/paskon-en-historia-om-miljoforstoring-och-overexploatering-av-naturresurser",
      },
      {
        title: "Naturens rättigheter",
        url: "https://www.so-rummet.se/fakta-artiklar/naturens-rattigheter",
      },
      {
        title: "Miljö, energi och energikällor",
        url: "https://www.so-rummet.se/fakta-artiklar/miljo-energi-och-energikallor",
      },
    ],
    "Miljö och hållbarhetsfrågor": [
      {
        title: "Desinformation",
        url: "https://www.so-rummet.se/fakta-artiklar/desinformation",
      },
      {
        title: "Hemberedskap",
        url: "https://www.so-rummet.se/fakta-artiklar/hemberedskap",
      },
      {
        title: "Sveriges militära försvar",
        url: "https://www.so-rummet.se/fakta-artiklar/sveriges-militara-forsvar",
      },
      {
        title: "Totalförsvaret",
        url: "https://www.so-rummet.se/fakta-artiklar/totalforsvaret",
      },
      {
        title: "Frivilliga försvarsorganisationer",
        url: "https://www.so-rummet.se/fakta-artiklar/frivilliga-forsvarsorganisationer",
      },
      {
        title: "Klimatförändringar kan ha orsakat syndafloden",
        url: "https://www.so-rummet.se/fakta-artiklar/klimatforandringar-kan-ha-orsakat-syndafloden",
      },
      {
        title: "NATO:s historia och organisation",
        url: "https://www.so-rummet.se/fakta-artiklar/natos-historia-och-organisation",
      },
      {
        title: "Förenta nationerna: WHO",
        url: "https://www.so-rummet.se/fakta-artiklar/forenta-nationerna-who",
      },
      {
        title: "Globalisering - orsaker och följder",
        url: "https://www.so-rummet.se/fakta-artiklar/globalisering-orsaker-och-foljder",
      },
      {
        title: "Vad är terrorism?",
        url: "https://www.so-rummet.se/fakta-artiklar/vad-ar-terrorism",
      },
    ],
  },
  Religion: {
    "Allmänt om religion": [
      {
        title: "Människans syn på hästen som helig varelse",
        url: "https://www.so-rummet.se/fakta-artiklar/manniskans-syn-pa-hasten-som-helig-varelse",
      },
      {
        title: "Naturreligion - ett samspel med naturen",
        url: "https://www.so-rummet.se/fakta-artiklar/naturreligion-ett-samspel-med-naturen",
      },
      {
        title: "Naturreligion i afrikanska samhällen",
        url: "https://www.so-rummet.se/fakta-artiklar/naturreligion-i-afrikanska-samhallen",
      },
      {
        title: "Frågor om livet: Vad tror jag själv?",
        url: "https://www.so-rummet.se/fakta-artiklar/fragor-om-livet-vad-tror-jag-sjalv",
      },
      {
        title: "Argument mot Guds existens",
        url: "https://www.so-rummet.se/fakta-artiklar/argument-mot-guds-existens",
      },
      {
        title: "Argument för Guds existens",
        url: "https://www.so-rummet.se/fakta-artiklar/argument-guds-existens",
      },
    ],
    "Religion och vetenskap": [
      {
        title:
          "Upplysning och pietism satte igång sekulariseringen redan på 1700-talet",
        url: "https://www.so-rummet.se/fakta-artiklar/upplysning-och-pietism-satte-igang-sekulariseringen-redan-pa-1700-talet",
      },
      {
        title: "Argument mot Guds existens",
        url: "https://www.so-rummet.se/fakta-artiklar/argument-mot-guds-existens",
      },
      {
        title: "Argument för Guds existens",
        url: "https://www.so-rummet.se/fakta-artiklar/argument-guds-existens",
      },
      {
        title: "Tro och vetande - vad är kunskap (sant)?",
        url: "https://www.so-rummet.se/fakta-artiklar/tro-och-vetande-vad-ar-kunskap-sant",
      },
      {
        title: "Att vara människa",
        url: "https://www.so-rummet.se/fakta-artiklar/att-vara-manniska",
      },
      {
        title: "Kreationismen och 'aprättegången'",
        url: "https://www.so-rummet.se/fakta-artiklar/kreationismen-och-aprattegangen",
      },
    ],
    "Myt och rit": [
      {
        title: "Hästen i den fornnordiska tron",
        url: "https://www.so-rummet.se/fakta-artiklar/hasten-i-den-fornnordiska-tron",
      },
      {
        title: "Myrra och rökelse var värdefulla varor under antiken",
        url: "https://www.so-rummet.se/fakta-artiklar/myrra-och-rokelse-var-vardefulla-varor-under-antiken",
      },
      {
        title: "Människans syn på hästen som helig varelse",
        url: "https://www.so-rummet.se/fakta-artiklar/manniskans-syn-pa-hasten-som-helig-varelse",
      },
      {
        title: "Världsreligionernas syn på döden och begravning",
        url: "https://www.so-rummet.se/fakta-artiklar/varldsreligionernas-syn-pa-doden-och-begravning",
      },
      {
        title: "Gamla testamentets berättelser - Abraham, Isak, Jakob och Mose",
        url: "https://www.so-rummet.se/fakta-religion/gamla-testamentets-berattelser-abraham-isak-jakob-och-mose",
      },
      {
        title:
          "Gamla testamentets berättelser: Kung David, landets delning och babyloniska fångenskapen",
        url: "https://www.so-rummet.se/fakta-religion/gamla-testamentets-berattelser-kung-david-landets-delning-och-babyloniska-fangenskapen",
      },
    ],
    "Etik och moral": [
      {
        title: "Etiska teorier och normativ etik",
        url: "https://www.so-rummet.se/kategorier/religion/etik-och-moral/etiska-teorier-och-normativ-etik",
      },
      {
        title: "Kristen etik och vardag",
        url: "https://www.so-rummet.se/fakta-artiklar/kristen-etik-och-vardag",
      },
      {
        title: "Fakta om abort",
        url: "https://www.so-rummet.se/fakta-artiklar/fakta-om-abort",
      },
      {
        title: "P-pillrets tidiga historia",
        url: "https://www.so-rummet.se/fakta-artiklar/p-pillrets-tidiga-historia",
      },
      {
        title: "Hur behandlar vi våra djur?",
        url: "https://www.so-rummet.se/fakta-artiklar/hur-behandlar-vi-vara-djur",
      },
      {
        title: "Husdjurens betydelse för människan",
        url: "https://www.so-rummet.se/fakta-artiklar/husdjurens-betydelse-manniskan",
      },
      {
        title: "Djurförsök",
        url: "https://www.so-rummet.se/fakta-artiklar/djurforsok",
      },
      {
        title: "Dödshjälp och eutanasi",
        url: "https://www.so-rummet.se/kategorier/religion/etik-och-moral/dodshjalp-och-eutanasi",
      },
      {
        title: "Dödsstraff",
        url: "https://www.so-rummet.se/kategorier/religion/etik-och-moral/dodsstraff",
      },
      {
        title: "Bödeln och bödelyrkets historia",
        url: "https://www.so-rummet.se/fakta-artiklar/bodeln-och-bodelyrkets-historia",
      },

      {
        title: "Lätta fakta om giljotinen",
        url: "https://www.so-rummet.se/fakta-artiklar/latta-fakta-om-giljotinen",
      },
      {
        title: "Genetik",
        url: "https://www.so-rummet.se/kategorier/religion/etik-och-moral/genetik",
      },
      {
        title:
          "Sjukvården förändras - viktiga medicinska framsteg under 1800-talet och 1900-talet",
        url: "https://www.so-rummet.se/fakta-artiklar/sjukvarden-forandras-viktiga-medicinska-framsteg-under-1800-talet-och-1900-talet",
      },
    ],
    "Forntidens och antikens religioner": [
      {
        title: "Egyptisk mytologi",
        url: "https://www.so-rummet.se/kategorier/religion/forntidens-och-antikens-religioner/egyptisk-mytologi",
      },
      {
        title: "Pyramiderna",
        url: "https://www.so-rummet.se/fakta-artiklar/pyramiderna",
      },
      {
        title: "Egyptiska civilisationen - från arkaisk tid till sentid",
        url: "https://www.so-rummet.se/fakta-artiklar/egyptiska-civilisationen-fran-arkaisk-tid-till-sentid",
      },
      {
        title: "Jordbruk och pyramidbyggen i forntidens Egypten",
        url: "https://www.so-rummet.se/fakta-artiklar/jordbruk-och-pyramidbyggen-i-forntidens-egypten",
      },
      {
        title: "Upptäckten av Tutankhamuns grav 1922",
        url: "https://www.so-rummet.se/fakta-artiklar/upptackten-av-tutankhamuns-grav-1922",
      },
      {
        title: "Grekisk mytologi",
        url: "https://www.so-rummet.se/kategorier/religion/forntidens-och-antikens-religioner/grekisk-mytologi",
      },
      {
        title: "Trojanska kriget - en saga?",
        url: "https://www.so-rummet.se/fakta-artiklar/trojanska-kriget-en-saga",
      },
      {
        title: "Odysseus irrfärder",
        url: "https://www.so-rummet.se/fakta-artiklar/odysseus-irrfarder",
      },
      {
        title: "Drömmen om Troja",
        url: "https://www.so-rummet.se/fakta-artiklar/drommen-om-troja",
      },
      {
        title: "Grekiska gudar och hjältar",
        url: "https://www.so-rummet.se/fakta-artiklar/grekiska-gudar-och-hjaltar",
      },

      {
        title: "Gudar och gudinnor i grekisk mytologi",
        url: "https://www.so-rummet.se/fakta-artiklar/gudar-och-gudinnor-i-grekisk-mytologi",
      },
      {
        title: "Religion i antikens Grekland",
        url: "https://www.so-rummet.se/fakta-artiklar/religion-i-antikens-grekland",
      },
      {
        title: "Romersk religion och mytologi",
        url: "https://www.so-rummet.se/kategorier/religion/forntidens-och-antikens-religioner/romersk-religion-och-mytologi",
      },
      {
        title: "Roms uppkomst och myten om Romulus och Remus",
        url: "https://www.so-rummet.se/fakta-artiklar/roms-uppkomst-och-myten-om-romulus-och-remus",
      },
      {
        title: "Våra månaders namn kommer från Rom",
        url: "https://www.so-rummet.se/fakta-artiklar/vara-manaders-namn-kommer-fran-rom",
      },
      {
        title: "Etruskerna",
        url: "https://www.so-rummet.se/fakta-artiklar/etruskerna",
      },
    ],
    "Fornnordisk religion och asatro": [
      {
        title: "Fornnordisk religion och asatro",
        url: "https://www.so-rummet.se/kategorier/religion/fornnordisk-religion-och-asatro",
      },
      {
        title: "Hästen i den fornnordiska tron",
        url: "https://www.so-rummet.se/fakta-artiklar/hasten-i-den-fornnordiska-tron",
      },
      {
        title: "Snorre Sturlasson och de isländska sagorna",
        url: "https://www.so-rummet.se/fakta-artiklar/snorre-sturlasson-och-de-islandska-sagorna",
      },
      {
        title: "Nordisk mytologi och dess arv",
        url: "https://www.so-rummet.se/fakta-artiklar/nordisk-mytologi-och-dess-arv",
      },
      {
        title: "Bildstenar och runstenar",
        url: "https://www.so-rummet.se/fakta-artiklar/bildstenar-och-runstenar",
      },
      {
        title: "Världsalltet och viktiga gudar och gudinnor i nordisk mytologi",
        url: "https://www.so-rummet.se/fakta-artiklar/varldsalltet-och-viktiga-gudar-och-gudinnor-i-nordisk-mytologi",
      },
      {
        title: "Ansgar och Birka",
        url: "https://www.so-rummet.se/fakta-artiklar/ansgar-och-birka",
      },
    ],
    "Samisk religion": [
      {
        title: "Samisk religion",
        url: "https://www.so-rummet.se/kategorier/religion/samisk-religion",
      },
      {
        title: "Lätta fakta om samerna",
        url: "https://www.so-rummet.se/fakta-artiklar/latta-fakta-om-samerna",
      },
      {
        title: "Naturreligion - ett samspel med naturen",
        url: "https://www.so-rummet.se/fakta-artiklar/naturreligion-ett-samspel-med-naturen",
      },
      {
        title: "Samerna - från trumman till korset",
        url: "https://www.so-rummet.se/fakta-artiklar/samerna-fran-trumman-till-korset",
      },
      {
        title: "Samernas religion",
        url: "https://www.so-rummet.se/fakta-artiklar/samernas-religion",
      },
      {
        title: "Samiska nationaldagen",
        url: "https://www.so-rummet.se/fakta-artiklar/samiska-nationaldagen",
      },
    ],
    Judendomen: [
      {
        title: "Judendomens grunder",
        url: "https://www.so-rummet.se/kategorier/religion/judendomen/judendomens-grunder",
      },
      {
        title: "Höga Visan",
        url: "https://www.so-rummet.se/fakta-artiklar/hoga-visan",
      },
      {
        title: "Judendomens lära",
        url: "https://www.so-rummet.se/fakta-artiklar/judendomens-lara",
      },

      {
        title: "Judisk historia",
        url: "https://www.so-rummet.se/kategorier/religion/judendomen/judisk-historia",
      },
      {
        title: "Warszawagettot och judarnas uppror",
        url: "https://www.so-rummet.se/fakta-artiklar/warszawagettot-och-judarnas-uppror",
      },
      {
        title: "Fredsprocessen i Palestinakonflikten och mordet på Rabin",
        url: "https://www.so-rummet.se/fakta-artiklar/fredsprocessen-i-palestinakonflikten-och-mordet-pa-rabin",
      },
      {
        title: "Judarnas historia",
        url: "https://www.so-rummet.se/fakta-artiklar/judarnas-historia",
      },
      {
        title: "Judisk invandring till Sverige i ett historiskt perspektiv",
        url: "https://www.so-rummet.se/fakta-artiklar/judisk-invandring-till-sverige-i-ett-historiskt-perspektiv",
      },

      {
        title: "Europas judar mellan 1920-1945",
        url: "https://www.so-rummet.se/fakta-artiklar/europas-judar-mellan-1920-1945",
      },
      {
        title: "Judiskt vardagsliv",
        url: "https://www.so-rummet.se/kategorier/religion/judendomen/judiskt-vardagsliv",
      },
      {
        title: "Judiska riktningar i Sverige",
        url: "https://www.so-rummet.se/fakta-artiklar/judiska-riktningar-i-sverige",
      },
      {
        title: "Judiskt familjeliv",
        url: "https://www.so-rummet.se/fakta-artiklar/judiskt-familjeliv",
      },
      {
        title: "Pesach - den judiska påsken",
        url: "https://www.so-rummet.se/fakta-artiklar/pesach-den-judiska-pasken",
      },
      {
        title: "Sabbaten - judendomens heliga vilodag",
        url: "https://www.so-rummet.se/fakta-artiklar/sabbaten-judendomens-heliga-vilodag",
      },
      {
        title: "Matregler inom judendomen",
        url: "https://www.so-rummet.se/fakta-artiklar/matregler-inom-judendomen",
      },
      {
        title: "Riktningar inom judendomen",
        url: "https://www.so-rummet.se/fakta-religion/riktningar-inom-judendomen",
      },
    ],
    Kristendomen: [
      {
        title: "Kristendomen",
        url: "https://www.so-rummet.se/kategorier/religion/kristendomen",
      },
      {
        title: "Yttersta tiden och domedagen",
        url: "https://www.so-rummet.se/fakta-artiklar/yttersta-tiden-och-domedagen",
      },

      {
        title: "Vem var Jesus?",
        url: "https://www.so-rummet.se/fakta-artiklar/vem-var-jesus",
      },
      {
        title: "Treenigheten",
        url: "https://www.so-rummet.se/fakta-artiklar/treenigheten",
      },
      {
        title: "Argument mot Guds existens",
        url: "https://www.so-rummet.se/fakta-artiklar/argument-mot-guds-existens",
      },
      {
        title: "Argument för Guds existens",
        url: "https://www.so-rummet.se/fakta-artiklar/argument-guds-existens",
      },
      {
        title: "Finns Gud?",
        url: "https://www.so-rummet.se/fakta-artiklar/finns-gud",
      },
      {
        title: "Kristendomens historia och kyrkohistoria",
        url: "https://www.so-rummet.se/kategorier/religion/kristendomen/kristendomens-historia-och-kyrkohistoria",
      },
      {
        title: "Lätta fakta om Roms katakomber",
        url: "https://www.so-rummet.se/fakta-artiklar/latta-fakta-om-roms-katakomber",
      },
      {
        title:
          "Den senmedeltida kyrkans förfall, reformationen och splittringen",
        url: "https://www.so-rummet.se/fakta-artiklar/den-senmedeltida-kyrkans-forfall-reformationen-och-splittringen",
      },

      {
        title:
          "Upplysning och pietism satte igång sekulariseringen redan på 1700-talet",
        url: "https://www.so-rummet.se/fakta-artiklar/upplysning-och-pietism-satte-igang-sekulariseringen-redan-pa-1700-talet",
      },
      {
        title:
          "1600-talet, renlärighetens epok: Katolsk motreformation och evangelisk konfessionalism",
        url: "https://www.so-rummet.se/fakta-artiklar/1600-talet-renlarighetens-epok-katolsk-motreformation-och-evangelisk-konfessionalism",
      },
      {
        title: "Paulus - kristendomens näst viktigaste person?",
        url: "https://www.so-rummet.se/fakta-artiklar/paulus-kristendomens-nast-viktigaste-person",
      },
      {
        title: "Medeltidens helgonkult",
        url: "https://www.so-rummet.se/fakta-artiklar/medeltidens-helgonkult",
      },
      {
        title: "Kristnandet av Sverige",
        url: "https://www.so-rummet.se/fakta-artiklar/kristnandet-av-sverige",
      },
      {
        title: "Medeltidens kyrka och reformationen",
        url: "https://www.so-rummet.se/fakta-artiklar/medeltidens-kyrka-och-reformationen",
      },
      {
        title: "Kristendomens genombrott i Norden",
        url: "https://www.so-rummet.se/fakta-artiklar/kristendomens-genombrott-i-norden",
      },
    ],
    Islam: [
      {
        title: "Islam",
        url: "https://www.so-rummet.se/kategorier/religion/islam",
      },
      {
        title: "Matregler inom islam",
        url: "https://www.so-rummet.se/fakta-artiklar/matregler-inom-islam",
      },
      {
        title: "Islams gudssyn och kärna",
        url: "https://www.so-rummet.se/fakta-artiklar/islams-gudssyn-och-karna",
      },
      {
        title: "Mekka - världens centrum",
        url: "https://www.so-rummet.se/fakta-artiklar/mekka-varldens-centrum",
      },
      {
        title: "Sufismen - islams mystik",
        url: "https://www.so-rummet.se/fakta-artiklar/sufismen-islams-mystik",
      },
      {
        title: "Sharia - ett på uppenbarelsen vilande rättssystem",
        url: "https://www.so-rummet.se/fakta-artiklar/sharia-ett-pa-uppenbarelsen-vilande-rattssystem",
      },
      {
        title: "Islams skrifter: hadith, sira och sunna",
        url: "https://www.so-rummet.se/fakta-artiklar/islams-skrifter-hadith-sira-och-sunna",
      },
      {
        title: "Islams skrifter: Koranen - Guds ord till människan",
        url: "https://www.so-rummet.se/fakta-artiklar/islams-skrifter-koranen-guds-ord-till-manniskan",
      },
      {
        title: "Fem saker som du trodde om islam men som inte är sant",
        url: "https://www.so-rummet.se/fakta-artiklar/fem-saker-som-du-trodde-om-islam-men-som-inte-ar-sant",
      },
      {
        title: "Synen på män och kvinnor inom rättssystemet och äktenskapet",
        url: "https://www.so-rummet.se/fakta-artiklar/synen-pa-man-och-kvinnor-inom-rattssystemet-och-aktenskapet",
      },
      {
        title: "Varför får muslimer och judar inte äta gris?",
        url: "https://www.so-rummet.se/fakta-artiklar/varfor-far-muslimer-och-judar-inte-ata-gris",
      },
      {
        title: "Profeten och hans ställföreträdare",
        url: "https://www.so-rummet.se/fakta-artiklar/profeten-och-hans-stallforetradare",
      },
      {
        title: "Olika riktningar inom islam",
        url: "https://www.so-rummet.se/kategorier/religion/islam/olika-riktningar-inom-islam",
      },
      {
        title: "Politisk islam",
        url: "https://www.so-rummet.se/kategorier/religion/islam/politisk-islam",
      },
    ],
    Hinduismen: [
      {
        title: "Hinduismen",
        url: "https://www.so-rummet.se/kategorier/religion/hinduismen",
      },
      {
        title: "Hinduismens viktigaste heliga skrifter",
        url: "https://www.so-rummet.se/fakta-artiklar/hinduismens-viktigaste-heliga-skrifter",
      },
      {
        title: "Hinduismens viktigaste gudar",
        url: "https://www.so-rummet.se/fakta-artiklar/hinduismens-viktigaste-gudar",
      },
      {
        title: "Hinduismens historia",
        url: "https://www.so-rummet.se/kategorier/religion/hinduismen/hinduismens-historia",
      },
      {
        title: "Utgrävningar i Harappa - spår efter Induskulturen",
        url: "https://www.so-rummet.se/fakta-artiklar/utgravningar-i-harappa-spar-efter-induskulturen",
      },
      {
        title: "Vardagsliv och samhället inom hinduismen",
        url: "https://www.so-rummet.se/kategorier/religion/hinduismen/vardagsliv-och-samhallet-inom-hinduismen",
      },
      {
        title: "Kastsystemet i Indien",
        url: "https://www.so-rummet.se/fakta-artiklar/kastsystemet-i-indien",
      },
    ],
    Buddhismen: [
      {
        title: "Buddhismen",
        url: "https://www.so-rummet.se/kategorier/religion/buddhismen",
      },
      {
        title: "Kortfattat om buddhismens lära, etik och riktningar",
        url: "https://www.so-rummet.se/fakta-artiklar/kortfattat-om-buddhismens-lara-etik-och-riktningar",
      },
      {
        title: "Buddha och andra buddhor",
        url: "https://www.so-rummet.se/fakta-artiklar/buddha-och-andra-buddhor",
      },
      {
        title: "Tangdynastin (618-907)",
        url: "https://www.so-rummet.se/fakta-artiklar/tangdynastin-618-907",
      },
      {
        title: "Splittringsperioden (220-589) och Suidynastin (589-618)",
        url: "https://www.so-rummet.se/fakta-artiklar/splittringsperioden-220-589-och-suidynastin-589-618",
      },
      {
        title: "Ashoka - Indiens förste kejsare",
        url: "https://www.so-rummet.se/fakta-artiklar/ashoka-indiens-forste-kejsare",
      },
      {
        title: "Munkar och nunnor inom buddhismen",
        url: "https://www.so-rummet.se/fakta-artiklar/munkar-och-nunnor-inom-buddhismen",
      },
      {
        title: "Buddhism och politik",
        url: "https://www.so-rummet.se/fakta-artiklar/buddhism-och-politik",
      },
      {
        title: "Olika riktningar inom buddhismen",
        url: "https://www.so-rummet.se/kategorier/religion/buddhismen/olika-riktningar-inom-buddhismen",
      },
      {
        title: "Zenbuddhism",
        url: "https://www.so-rummet.se/kategorier/religion/buddhismen/zenbuddhism",
      },

      {
        title: "Lamaism",
        url: "https://www.so-rummet.se/kategorier/religion/buddhismen/lamaism",
      },
    ],
    "Andra asiatiska religioner": [
      {
        title: "Jainism",
        url: "https://www.so-rummet.se/kategorier/religion/andra-asiatiska-religioner/jainism",
      },
      {
        title: "Konfucianism",
        url: "https://www.so-rummet.se/kategorier/religion/andra-asiatiska-religioner/konfucianism",
      },
      {
        title: "Shintoism",
        url: "https://www.so-rummet.se/kategorier/religion/andra-asiatiska-religioner/shintoism",
      },
      {
        title: "Sikhism",
        url: "https://www.so-rummet.se/kategorier/religion/andra-asiatiska-religioner/sikhism",
      },
      {
        title: "Taoism",
        url: "https://www.so-rummet.se/kategorier/religion/andra-asiatiska-religioner/taoism",
      },
    ],
    "Nyreligiösa rörelser": [
      {
        title: "New Age",
        url: "https://www.so-rummet.se/kategorier/religion/nyreligiosa-rorelser/new-age",
      },
      {
        title: "Enighetskyrkan",
        url: "https://www.so-rummet.se/kategorier/religion/nyreligiosa-rorelser/enighetskyrkan",
      },
      {
        title: "Hare Krishna",
        url: "https://www.so-rummet.se/kategorier/religion/nyreligiosa-rorelser/hare-krishna",
      },
      {
        title: "Satanism",
        url: "https://www.so-rummet.se/kategorier/religion/nyreligiosa-rorelser/satanism",
      },

      {
        title: "Scientologi",
        url: "https://www.so-rummet.se/kategorier/religion/nyreligiosa-rorelser/scientologi",
      },
    ],
    "Om sekter": [
      {
        title: "Om sekter",
        url: "https://www.so-rummet.se/kategorier/religion/om-sekter",
      },
    ],
    "Ockulta inriktningar": [
      {
        title: "Ockulta inriktningar",
        url: "https://www.so-rummet.se/kategorier/religion/ockulta-inriktningar",
      },
    ],
    "Icke religiösa livsåskådningar": [
      {
        title: "Ekosofi",
        url: "https://www.so-rummet.se/kategorier/religion/icke-religiosa-livsaskadningar/ekosofi",
      },
      {
        title: "Existentialism",
        url: "https://www.so-rummet.se/kategorier/religion/icke-religiosa-livsaskadningar/existentialism",
      },
      {
        title: "Humanism",
        url: "https://www.so-rummet.se/kategorier/religion/icke-religiosa-livsaskadningar/humanism",
      },
      {
        title: "Marxism",
        url: "https://www.so-rummet.se/kategorier/religion/icke-religiosa-livsaskadningar/marxism",
      },
      {
        title: "Socialdarwinism",
        url: "https://www.so-rummet.se/kategorier/religion/icke-religiosa-livsaskadningar/socialdarwinism",
      },
    ],
    Livsfrågor: [
      {
        title: "Frågor om livet: Vad tror jag själv?",
        url: "https://www.so-rummet.se/fakta-artiklar/fragor-om-livet-vad-tror-jag-sjalv",
      },
      {
        title: "Att vara människa",
        url: "https://www.so-rummet.se/fakta-artiklar/att-vara-manniska",
      },
      {
        title: "Vad är religion?",
        url: "https://www.so-rummet.se/fakta-artiklar/vad-ar-religion",
      },
    ],
    Folktro: [
      {
        title: "Folktro",
        url: "https://www.so-rummet.se/kategorier/religion/folktro",
      },
      {
        title: "Häxfebern i Stockholm 1676",
        url: "https://www.so-rummet.se/fakta-artiklar/haxfebern-i-stockholm-1676",
      },
    ],
    "Sekularisering och religionskritik": [
      {
        title: "Sekularisering",
        url: "https://www.so-rummet.se/kategorier/religion/sekularisering-och-religionskritik/sekularisering",
      },
      {
        title: "Religionskritik",
        url: "https://www.so-rummet.se/kategorier/religion/sekularisering-och-religionskritik/religionskritik",
      },
    ],
  },
};

const descriptions = {
  Samhällskunskap: {
    "Individers och gruppers identitet och relationer":
      "Om identitet, sexualitet, könsroller, jämställdhet, minoriteter, arbete, boende, välfärdsstrukturer, migration, integration, rasism och främlingsfientlighet.",
    Demokrati:
      "Om demokratins grunder, Sveriges politiska system, politiska partier i Sveriges riksdag, demokratisk påverkan, demokratimodeller, demokratiska system och olika statsskick.",
    "Politiska ideologier":
      "En politisk ideologi är ett sammanhängande system av politiska idéer om hur samhället ska vara. Här presenteras några av de mest kända politiska ideologierna.",
    "Lag och rätt":
      "Om regler, lagar, rättssystemet, domstolarna, rättegång, brottslighet, brottsoffer och kriminalvård.",
    "Medier och kommunikation":
      "Mediekunskap handlar om medierna och deras funktion i samhället.",
    "Ekonomi och handel":
      "Avsnittet fokuserar på privatekonomi, samhällsekonomi, internationell ekonomi och handel. Vi ska också titta närmare på nationalekonomiska teorier samt på arbetsmarknaden, arbetsrätt och arbetsmiljö.",
    "Internationell politik och globala samhällsfrågor":
      "Här kan du läsa om aktuella samhällsfrågor, hotbilder och konflikter i världen. Avsnittet handlar också om globaliseringen, mänskliga rättigheter, FN:s syfte och huvudsakliga uppdrag, andra former av internationell konflikthantering och folkrätten i väpnade konflikter.",
    "Sveriges beredskap":
      "Sveriges beredskap handlar om att förebygga och vara beredd på att hantera hot mot vårt samhälle om de blir verklighet.",
    "Världens länder - samhällskunskap":
      "Aktuella samhällsfakta samt politisk och ekonomisk bakgrund till olika världsdelar och världens länder.",
  },
  Geografi: {
    "Kartor och kartkunskap":
      "Om olika typer av kartor, jordens koordinatsystem, GPS, skala och kartprojektioner. Bland relaterat material hittar du även olika kartsamlingar.",
    Klimat:
      "Om skillnaden mellan väder och klimat, jordens klimatzoner, olika naturliga faktorer som påverkar klimatet samt människans påverkan på klimatet.",
    "Växtlighet och vatten":
      "Naturgeografi handlar om jordens olika landformer och de processer som påverkar dem. Här hittar du bland annat material om skog, berg, sjöar och vattendrag.",
    "Jordens inre och yttre krafter":
      "Jordens inre och yttre krafter omformar hela tiden jordens yta. De inre krafterna utgörs främst av vulkaner och jordskalv som ökar på jordskorpans höjdskillnader. De yttre krafterna består i första hand av vatten, is och vind som slipar ner jordytan.",
    "Världens befolkning":
      "Ämnesområdet världens befolkning handlar om befolkningsutveckling, befolkningsfördelning, migration, urbanisering och städer samt fattigdom och ohälsa i världen.",
    "Jordens resurser och handelsmönster":
      "Om människans behov av naturresurser och hur människors försörjning och handelsmönster har förändrats över tid.",
    "Miljö och hållbarhetsfrågor":
      "Miljö och hållbarhetsfrågor handlar om samspelet mellan människan och jordens natur och naturtillgångar. Genom en hållbar utveckling kan vi tillgodose våra behov utan att förstöra för kommande generationer.",
  },
  Religion: {
    "Allmänt om religion":
      "Om religion som fenomen, religionens ursprung och människors religiositet. Religion och religiositet har i alla tider hjälpt människan att tolka, förstå och utstå tillvaron och livet.",
    "Religion och vetenskap":
      "Går det att förena vetenskap och religion? Än idag finns en laddad debatt kring evolutionsläran där vetenskap och religion står emot varandra. Men det finns också de som menar att evolutionsläran går att förena med en religiös tro.",
    "Myt och rit":
      "Myter är berättelser inom religionerna som försöker besvara de stora livsfrågorna. Religiösa riter är bestämda symboliska handlingar som ges ett heligt värde.",
    "Fornnordisk religion och asatro":
      "Fornnordisk religion (inkl. nordisk mytologi) utövades i det förkristna Norden. Gudarna bodde i Asgård och människorna i Midgård. Asatron är en nutida variant av fornnordisk religion.",
    "Samisk religion":
      "Samernas naturreligion från förkristen tid. Den samiska religionen gick ut på att naturen var besjälad och att förfäderna levde kvar som övernaturliga väsen.",
    "Om sekter":
      "Vad är en sekt och vad kännetecknar den? Läs om sekters dragningskraft och andra psykologiska och sociala aspekter som hör till fenomenet.",
    "Ockulta inriktningar":
      "Gemensamt för ockultismen och ockulta rörelser är deras tro på att det bortom den synliga verkligheten finns krafter som människan kan utnyttja.",
    Livsfrågor:
      "Livsfrågor är de stora frågorna som vi alla ställer oss under livets gång. Livsfrågor handlar om meningen med livet och hur vi ska leva vårt liv.",
    Folktro: "Folklig tro på övernaturliga väsen och övernaturliga makter.",
  },
  Historia: {
    "Människans förhistoria och stenåldern":
      "Människans allra tidigaste historia. Stenåldern brukar delas in i två perioder: äldre stenåldern (ca 40 000-10 000 f.Kr) och yngre stenåldern (ca 10 000-3000 f.Kr).",

    "Forntiden och antiken":
      "Tidiga civilisationer och imperier från forntiden till slutet av antiken (3000 f.Kr - 500 e.Kr).",

    Medeltiden:
      "Medeltiden (500-1500) var en händelserik period då en europeisk kultur framträdde med kristendomen som enande faktor.",

    "Nya tiden":
      "Den nya tiden, ibland kallad tidigmodern tid (1500-1776), inkluderar flera epokgörande händelser som gjorde världen större.",

    "Det långa 1800-talet":
      "Det långa 1800-talet (1776-1914) var revolutionernas, imperialismens och kapitalismens tidsålder då världen förändrades.",

    "Det korta 1900-talet":
      "Världskrigens och kalla krigets tid (1914-1991). 1900-talet präglades av stora internationella konflikter, styrda av bakomliggande ideologiska drivkrafter.",

    Nutidshistoria:
      "Vår nutidshistoria från 1991 och framåt i form av utmärkande tidstypiska utvecklingslinjer och viktiga händelser.",

    "Historiska teman":
      "Historia indelad i olika ämnen. Här finns intressant historia för alla smaker.",

    "Världens länder - historia":
      "Intressant och spännande historia som handlar om världsdelarna och världens länder. Välj en världsdel så visas länderna som hör till den.",

    Historieteori:
      "Om historieämnets vetenskapliga och källkritiska bakgrund och de metoder som används för att framställa och betrakta historia. Det finns bl.a. olika sätt att se på historien och framställa den för andra.",

    "Historiska arkiv, kartor och statistik":
      "Samlingar med historiska kartor och statistik för historieämnet. Historiska kartor och statistik ger en snabb överblick av historiska skeenden och fenomen.",

    "Okategoriserad historia":
      "Material med innehåll som inte passar in någon annanstans.",
  },
};

const SORummet = ({ onLinkSelect, styles }) => {
  const [open, setOpen] = useState(false);
  const [currentSubject, setCurrentSubject] = useState(null);
  const [currentSubTopic, setCurrentSubTopic] = useState(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setCurrentSubject(null);
    setCurrentSubTopic(null);
  };

  const handleSubjectClick = (subject) => setCurrentSubject(subject);
  const handleSubTopicClick = (subTopic) => setCurrentSubTopic(subTopic);

  const handleLinkClick = (url) => {
    onLinkSelect(url);
    handleClose();
  };

  const handleBack = () => {
    if (currentSubTopic) {
      setCurrentSubTopic(null);
    } else if (currentSubject) {
      setCurrentSubject(null);
    }
  };

  const getSteps = () => {
    return [
      {
        label: "Välj ämne",
        completed: currentSubject !== null,
        onClick: () => {
          setCurrentSubject(null);
          setCurrentSubTopic(null);
        },
      },
      {
        label: currentSubject || "(Valt ämne)",
        completed: currentSubTopic !== null,
        onClick: () => {
          setCurrentSubTopic(null);
        },
      },
      {
        label: currentSubTopic || "(Valt tema)",
        completed: false,
        onClick: () => {},
      },
    ];
  };

  const renderContent = () => {
    if (!currentSubject) {
      return (
        <Grid container spacing={3}>
          {Object.keys(subjectData).map((subject) => (
            <Grid item xs={12} sm={6} md={4} key={subject}>
              <ThemeFlowBox
                title={subject}
                subtitle={subjectDescriptions[subject]}
                icon={
                  subject === "Historia"
                    ? History
                    : subject === "Samhällskunskap"
                      ? Public
                      : subject === "Religionskunskap"
                        ? MosqueIcon
                        : subject === "Geografi"
                          ? MapIcon
                          : RadioButtonCheckedIcon
                }
                onClick={() => handleSubjectClick(subject)}
                diffView={true}
                isSelected={false}
                clickable={true}
              />
            </Grid>
          ))}
        </Grid>
      );
    }

    if (!currentSubTopic) {
      return (
        <Grid container spacing={3}>
          {Object.keys(subjectData[currentSubject]).map((subTopic) => (
            <Grid item xs={6} sm={6} md={4} key={subTopic}>
              <ThemeFlowBox
                title={subTopic}
                icon={RadioButtonCheckedIcon}
                subtitle={
                  descriptions[currentSubject]?.[subTopic]?.substring(0, 50) +
                  (descriptions[currentSubject]?.[subTopic]?.length > 50
                    ? "..."
                    : "")
                }
                onClick={() => handleSubTopicClick(subTopic)}
                diffView={true}
                isSelected={false}
                clickable={true}
              />
            </Grid>
          ))}
        </Grid>
      );
    }

    return (
      <Grid container spacing={3}>
        {subjectData[currentSubject][currentSubTopic].map((link, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Box sx={{ position: "relative" }}>
              <ThemeFlowBox
                title={
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>{link.title}</span>
                    <Tooltip title="Läs artikeln hos SO-rummet.se">
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          window.open(link.url, "_blank");
                        }}
                        sx={{ ml: 1, position: "absolute", top: 3, right: 3 }}
                      >
                        <OpenInNewIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                }
                icon={RadioButtonCheckedIcon}
                onClick={() => handleLinkClick(link.url)}
                diffView={true}
                isSelected={false}
                clickable={true}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <>
      <Button
        variant="print"
        onClick={handleOpen}
        startIcon={
          <img
            src="/img/so-logga.png"
            alt="SO logo"
            style={{ width: "20px", height: "20px", borderRadius: "2px" }}
          />
        }
      >
        Artiklar från SO-rummet
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 4,
            maxWidth: 1100,
            height: {
              xs: 500, // Mobile
              sm: 600, // Tablet
              md: 800, // Desktop
            },
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
          },
        }}
      >
        <DialogTitle sx={{ textAlign: "center", position: "relative" }}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            variant="h6"
            sx={{
              textAlign: "center",
              fontWeight: "700",
              mt: 2,
              fontSize: "1.5rem",
            }}
          >
            Artiklar från SO-Rummet
          </Typography>
        </DialogTitle>
        <Divider sx={{ my: 2 }} />
        <Stepper
          activeStep={currentSubTopic ? 2 : currentSubject ? 1 : 0}
          sx={{ width: "60%", margin: "0 auto", mt: 2, mb: 4 }}
        >
          {getSteps().map((step, index) => (
            <Step
              key={index}
              completed={step.completed}
              sx={{
                cursor:
                  index < (currentSubTopic ? 2 : currentSubject ? 1 : 0)
                    ? "pointer"
                    : "default",
                "&:hover":
                  index < (currentSubTopic ? 2 : currentSubject ? 1 : 0)
                    ? {
                        "& .MuiStepLabel-label": {
                          color: "primary.main",
                        },
                        "& .MuiStepIcon-root": {
                          color: "primary.main",
                        },
                      }
                    : {},
              }}
              onClick={() => {
                if (index < (currentSubTopic ? 2 : currentSubject ? 1 : 0)) {
                  step.onClick();
                }
              }}
            >
              <StepLabel>
                {step.label.length > 20
                  ? `${step.label.substr(0, 20)}...`
                  : step.label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>

        <DialogContent sx={{ padding: "40px" }}>
          {renderContent()}
        </DialogContent>

        <Divider />
        <DialogActions
          sx={{
            p: 0,
            position: "relative",
          }}
        >
          {" "}
          <a
            href="https://www.so-rummet.se/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/img/so-rummet.jpg"
              alt="SO-rummet logo"
              style={{ height: "60px", position: "absolute", top: -4, left: 8 }}
            />
          </a>
          <Button
            onClick={handleClose}
            startIcon={<CloseIcon />}
            variant="print"
          >
            Avbryt
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SORummet;
