import React, { useState, useEffect } from "react";
import { db } from "./firebase";
import {
  collection,
  getDocs,
  query,
  where,
  limit,
  updateDoc,
  doc,
  getDoc,
} from "firebase/firestore";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import ViewAgendaIcon from "@mui/icons-material/ViewAgenda";
import { useSpring, animated } from "@react-spring/web";
import FlashcardGame from "./FlashcardGame";
import "../App.css";
import ShuffleIcon from "@mui/icons-material/Shuffle";
import SpaceBarIcon from "@mui/icons-material/SpaceBar";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import LooksThreeIcon from "@mui/icons-material/Looks3";

import Match from "./Match";
import ViewWeekIcon from "@mui/icons-material/ViewWeek";

import LooksFourIcon from "@mui/icons-material/Looks4";
import GenerateIcon from "@mui/icons-material/AutoAwesome";
import Looks3Icon from "@mui/icons-material/Looks3";
import IconButton from "@mui/material/IconButton";
import { Helmet } from "react-helmet-async";
import BookmarkIcon from "@mui/icons-material/BookmarkBorder";
import CheckCircleIcon from "@mui/icons-material/Check";
import { useSnackbar } from "./SnackbarHandler";
import { formatDate } from "./functions";
import Looks4Icon from "@mui/icons-material/Looks4";
import { lighten, darken } from "@mui/material/styles";
import Voice from "./Voice";
import Tooltip from "@mui/material/Tooltip";
import CircleIcon from "@mui/icons-material/Circle";
import { callOpenAITTS } from "./api";
import AddIcon from "@mui/icons-material/Add";
import { addNote, deleteNote } from "./NotesView";
import { useMediaQuery, Divider } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUp";
import Logger from "./Logger";
import UserCollectionsDrawer from "./flashcards/UserCollectionsDrawer";
import TrainingSummary from "./flashcards/TrainingSummary";

import MyFlashcards from "./flashcards/MyFlashcards";

import PublicCollectionsDrawer from "./flashcards/PublicCollectionsDrawer";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import axios from "axios";

import Hint from "./Hint";
import FlashcardsCreateView from "./FlashcardsCreateView"; // Importera FlashcardsCreateView

import { useTheme } from "@mui/material/styles";

import LinearProgress from "@mui/material/LinearProgress";

import AIDialog from "./AIDialog";

import { getStyles } from "../index";
import { Howl } from "howler";
import selectSoundFile from "./sounds/select.mp3";
import select2 from "./sounds/select2.mp3";
import done from "./sounds/done.mp3";
import SegmentIcon from "@mui/icons-material/Segment";

import { useParams, useNavigate } from "react-router-dom";
import RecommendationComponent from "./RecommendationComponent";

const FlashcardsView = ({ user, isDark }) => {
  const [bookmarks, setBookmarks] = useState({});

  const { collectionId, collectionName } = useParams();
  const navigate = useNavigate();
  const [viewMode, setViewMode] = useState("cards"); // 'cards' or 'match'

  const [collections, setCollections] = useState([]);
  const [currentCollection, setCurrentCollection] = useState(null);
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [showBack, setShowBack] = useState(false);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState("all");
  const [sessionSummary, setSessionSummary] = useState(null);

  const [aiDialogOpen, setAiDialogOpen] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  const [creatorInfo, setCreatorInfo] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [audio, setAudio] = useState(null);
  const [animateButton, setAnimateButton] = useState(null);

  const [isCreateViewVisible, setIsCreateViewVisible] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerOpenLibrary, setDrawerLibraryOpen] = useState(false);

  const selectSound = new Howl({ src: [selectSoundFile], volume: 0.1 });
  const nextSound = new Howl({ src: [select2], volume: 0.6 });
  const doneSound = new Howl({ src: [done], volume: 0.2 });

  const { showSnackbar } = useSnackbar();

  const theme = useTheme();
  const styles = getStyles(theme);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [dynamicTitle, setDynamicTitle] = useState(
    "Flashcards — Skapa flashcards med AI — Studera.AI",
  );
  const [dynamicDescription, setDynamicDescription] = useState(
    "Skapa flashcards med AI. Du kan generera flashcards med AI genom att skriva in ett ämne eller en längre textmassa.",
  );

  const [originalCollectionId, setOriginalCollectionId] = useState("");
  const [collectionInUse, setCollectionInUse] = useState("");

  const toggleBookmark = async () => {
    const currentCard = filterFlashcards(currentCollection)[currentCardIndex];
    const content = `Framsida: ${currentCard.front}\nBaksida: ${currentCard.back}`;
    const conversationType = `flashcards-${currentCollection.id}`;

    const noteId = await addNote(user.uid, content, null, conversationType);
    setBookmarks((prev) => ({ ...prev, [currentCardIndex]: noteId }));
  };

  const shuffleCards = () => {
    const shuffledFlashcards = [...currentCollection.flashcards];
    for (let i = shuffledFlashcards.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledFlashcards[i], shuffledFlashcards[j]] = [
        shuffledFlashcards[j],
        shuffledFlashcards[i],
      ];
    }
    setCurrentCollection({
      ...currentCollection,
      flashcards: shuffledFlashcards,
    });
    setCurrentCardIndex(0);
    setShowBack(false);
  };

  const handleKeyDown = (
    e,
    currentCollection,
    showBack,
    flipCard,
    rateCard,
    showCreateView,
    aiDialog,
  ) => {
    Logger.log("handleKeyDown loggas med Logger");

    // Check for CMD+N (Mac) or CTRL+N (Windows)
    if ((e.metaKey || e.ctrlKey) && e.key === "ArrowRight") {
      e.preventDefault(); // Förhindra standard webbläsarbeteende
      showCreateView();
      return;
    }
    Logger.log(e.key);

    if (!currentCollection) return; // Exit early if there's no current collection

    if (e.key === "F" || e.key === "f") {
      e.preventDefault();
      handleAskAI();
      return;
    }

    if (e.key === " " || e.code === "Space") {
      e.preventDefault();
      flipCard();
    } else if (showBack) {
      switch (e.key) {
        case "1":
          rateCard("fail");
          break;
        case "2":
          rateCard("hard");
          break;
        case "3":
          rateCard("good");
          break;
        case "4":
          rateCard("easy");
          break;
        default:
          break;
      }
    }
  };

  // Update the toggleDrawer function
  const toggleDrawer = (open) => () => {
    Logger.log("toggleDrawer: ", open);
    setDrawerOpen(open);
  };

  // Update the toggleDrawerLibrary function
  const toggleDrawerLibrary = (open) => () => {
    Logger.log("toggleDrawerLibrary: ", open);
    setDrawerLibraryOpen(open);
  };

  const showCreateView = () => {
    setIsCreateViewVisible(true);
    setCurrentCollection(null);
    setSessionSummary(null);

    navigate(`/app/flashcards`);
  };

  const filterFlashcards = (collection) => {
    if (!collection) return [];
    if (filter === "all") {
      return collection.flashcards;
    }
    return collection.flashcards.filter((card) => card.correct === filter);
  };

  const formatUrlPart = (str) => {
    return str
      .replace(/\s+/g, "-") // Ersätt mellanslag med bindestreck
      .replace(/[åä]/g, "a") // Ersätt å och ä med a
      .replace(/[ö]/g, "o") // Ersätt ö med o
      .replace(/[^a-z0-9-]/gi, "") // Ta bort alla andra specialtecken
      .toLowerCase();
  };

  useEffect(() => {
    if (currentCollection) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [currentCollection]);

  useEffect(() => {
    const fetchCollections = async () => {
      setLoading(true);
      try {
        if (collectionId) {
          // First try exact match
          const docRef = doc(db, "flashcardCollections", collectionId);
          let docSnap = await getDoc(docRef);

          // If exact match fails, try partial match
          if (!docSnap.exists()) {
            const q = query(
              collection(db, "flashcardCollections"),
              where("__name__", ">=", collectionId),
              where("__name__", "<=", collectionId + "\uf8ff"),
              limit(1),
            );
            const querySnapshot = await getDocs(q);
            if (!querySnapshot.empty) {
              docSnap = querySnapshot.docs[0];
            }
          }

          if (docSnap?.exists()) {
            const selectedCollection = {
              id: docSnap.id,
              ...docSnap.data(),
            };

            setCollectionInUse(selectedCollection);
            Logger.log("selectedCollection ", selectedCollection);
            setDynamicTitle(
              `${selectedCollection.collectionName} — Flashcards — Studera.AI`,
            );
            setDynamicDescription(
              `Testa din kunskap i våra flashcards om ${selectedCollection.collectionName}. Svårighetsgraden är ${selectedCollection.difficulty}.`,
            );

            if (selectedCollection.originalCollectionId) {
              setOriginalCollectionId(selectedCollection.originalCollectionId);
              Logger.log(
                "setOriginalCollectionId: ",
                selectedCollection.originalCollectionId,
              );
            } else {
              setOriginalCollectionId(collectionId);
              Logger.log("else setOriginalCollectionId: ", collectionId);
            }

            // Hämta användarinformation här
            if (selectedCollection.userId) {
              const userIdToFetch =
                selectedCollection.isCopied && selectedCollection.originalUserId
                  ? selectedCollection.originalUserId
                  : selectedCollection.userId;
              Logger.log("här");
              const userDoc = await getDoc(doc(db, "users", userIdToFetch));
              if (userDoc.exists()) {
                Logger.log("här 2");
                const userData = userDoc.data();
                setCreatorInfo({
                  displayName:
                    userData.displayName || userData.email || "Unknown",
                  avatarUrl: userData.photoURL || null,
                  upvotes: selectedCollection.upvotes || 0,
                });
              }
            }
            startTraining(selectedCollection);
            navigate(
              `/app/flashcards/${selectedCollection.id}/${formatUrlPart(selectedCollection.collectionName)}`,
              { replace: true },
            );
          } else {
            console.error("Collection not found");
          }
        }
      } catch (error) {
        console.error("Error fetching collections:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchCollections();
  }, [user.uid, refreshTrigger, collectionId, navigate]);

  // useEffect(() => {
  //   const fetchCollections = async () => {
  //     setLoading(true);
  //     try {
  //       if (collectionId) {
  //         const querySnapshot = await getDocs(
  //           collection(db, "flashcardCollections"),
  //         );
  //         const allCollections = querySnapshot.docs.map((doc) => ({
  //           id: doc.id,
  //           ...doc.data(),
  //         }));
  //         const selectedCollection = allCollections.find(
  //           (c) => c.id === collectionId,
  //         );

  //         if (selectedCollection) {
  //           setCollectionInUse(selectedCollection);
  //           Logger.log("selectedCollection ", selectedCollection);

  //           setDynamicTitle(
  //             `${selectedCollection.collectionName} — Flashcards — Studera.AI`,
  //           );
  //           setDynamicDescription(
  //             `Testa din kunskap i våra flashcards om ${selectedCollection.collectionName}. Svårighetsgraden är ${selectedCollection.difficulty}.`,
  //           );

  //           if (selectedCollection.originalCollectionId) {
  //             setOriginalCollectionId(selectedCollection.originalCollectionId);
  //             Logger.log(
  //               "setOriginalCollectionId: ",
  //               selectedCollection.originalCollectionId,
  //             );
  //           } else {
  //             setOriginalCollectionId(collectionId);
  //             Logger.log("else setOriginalCollectionId: ", collectionId);
  //           }

  //           // Hämta användarinformation här
  //           if (selectedCollection.userId) {
  //             const userIdToFetch =
  //               selectedCollection.isCopied && selectedCollection.originalUserId
  //                 ? selectedCollection.originalUserId
  //                 : selectedCollection.userId;

  //             Logger.log("här");
  //             const userDoc = await getDoc(doc(db, "users", userIdToFetch));
  //             if (userDoc.exists()) {
  //               Logger.log("här 2");
  //               const userData = userDoc.data();
  //               setCreatorInfo({
  //                 displayName:
  //                   userData.displayName || userData.email || "Unknown",
  //                 avatarUrl: userData.photoURL || null,
  //                 upvotes: selectedCollection.upvotes || 0,
  //               });
  //             }
  //           }

  //           startTraining(selectedCollection);
  //           navigate(
  //             `/app/flashcards/${selectedCollection.id}/${formatUrlPart(selectedCollection.collectionName)}`,
  //             { replace: true },
  //           );
  //         } else {
  //           console.error("Collection not found");
  //         }
  //       }
  //     } catch (error) {
  //       console.error("Error fetching collections:", error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };
  //   fetchCollections();
  // }, [user.uid, refreshTrigger, collectionId, navigate]);

  const handleCollectionCreated = (newCollection) => {
    setRefreshTrigger((prev) => prev + 1);
    setIsCreateViewVisible(false);

    // Moonshine
    //setCollections(prevCollections => [...prevCollections, newCollection]);
    startTraining(newCollection);

    navigate(
      `/app/flashcards/${newCollection.id}/${formatUrlPart(newCollection.collectionName)}`,
    );
  };

  const startTraining = (collection) => {
    if (!collection) {
      showSnackbar("Collection not found", "error");

      // Hantera felet, t.ex. visa ett meddelande till användaren
      return;
    }

    //doneSound.play();
    toggleDrawer(false)();
    toggleDrawerLibrary(false)();
    setCurrentCollection(collection);
    setCurrentCardIndex(0);
    setShowBack(false);
    setFilter("all");
    setSessionSummary(null);
    setIsCreateViewVisible(false);
    //setOriginalCollectionId('');
    Logger.log("Started training with collection:", collection);

    navigate(
      `/app/flashcards/${collection.id}/${formatUrlPart(collection.collectionName)}`,
    );
  };

  const flipCard = () => {
    selectSound.play();
    setShowBack(!showBack);
    Logger.log("Flipped card:", showBack ? "back to front" : "front to back");
  };

  const handleAskAI = () => {
    Logger.log("Opening AI Dialog from handleAskAI");
    setAiDialogOpen(true);
  };

  const rateCard = async (rating) => {
    nextSound.play();

    setAnimateButton(rating);
    setTimeout(() => setAnimateButton(null), 300);

    const updatedFlashcards = [...currentCollection.flashcards];
    const currentCard = updatedFlashcards[currentCardIndex];

    if (currentCard) {
      currentCard.correct = rating;
      Logger.log("Rated card:", currentCard, "with rating:", rating);
    }

    // Optimistically update the UI
    //setCurrentCollection({ ...currentCollection, flashcards: updatedFlashcards });

    if (
      currentCardIndex <
      filterFlashcards({ ...currentCollection, flashcards: updatedFlashcards })
        .length -
        1
    ) {
      setCurrentCardIndex(currentCardIndex + 1);
      setShowBack(false);
    } else {
      const summary = calculateSessionSummary(updatedFlashcards);
      setSessionSummary(summary);

      const collectionRef = doc(
        db,
        "flashcardCollections",
        currentCollection.id,
      );
      await updateDoc(collectionRef, {
        flashcards: updatedFlashcards,
        lastTrainingSession: Date.now(), // Update this every time a card is rated
      });
      Logger.log(
        "Collection after DB update:",
        JSON.stringify(
          {
            ...currentCollection,
            flashcards: updatedFlashcards,
            lastTrainingSession: Date.now(),
          },
          null,
          2,
        ),
      );
    }

    try {
      const collectionRef = doc(
        db,
        "flashcardCollections",
        currentCollection.id,
      );
      await updateDoc(collectionRef, { flashcards: updatedFlashcards });
    } catch (error) {
      console.error("Error updating flashcard:", error);
      // Revert the optimistic update if the database update fails
      setCurrentCollection(currentCollection);
      showSnackbar("Failed to update flashcard. Please try again.", "error");
    }
  };
  const calculateSessionSummary = (flashcards) => {
    doneSound.play();
    const summary = flashcards.reduce(
      (acc, card) => {
        acc[card.correct] += 1;
        return acc;
      },
      { fail: 0, hard: 0, good: 0, easy: 0 },
    );
    return summary;
  };

  useEffect(() => {
    const handleKeyDownWrapper = (e) =>
      handleKeyDown(
        e,
        currentCollection,
        showBack,
        flipCard,
        rateCard,
        showCreateView,
        handleAskAI,
      );

    window.addEventListener("keydown", handleKeyDownWrapper);
    return () => {
      window.removeEventListener("keydown", handleKeyDownWrapper);
    };
  }, [currentCollection, showBack, flipCard, rateCard, showCreateView]);

  const { transform, opacity } = useSpring({
    opacity: showBack ? 1 : 0,
    transform: `perspective(800px) rotateY(${showBack ? 180 : 0}deg)`,
    config: { mass: 5, tension: 500, friction: 80 },
  });

  useEffect(() => {
    if (currentCollection) {
      const filteredFlashcards = filterFlashcards(currentCollection);
      const newIndex = filteredFlashcards.findIndex(
        (card) => card === currentCollection.flashcards[currentCardIndex],
      );
      if (newIndex !== -1) {
        setCurrentCardIndex(newIndex);
      } else {
        setCurrentCardIndex(0);
      }
    }
  }, [filter, currentCollection]);

  // if (loading) return <SkeletonLoader width="100%" height="700px" />;

  return (
    <>
      <Helmet>
        <title>{dynamicTitle}</title>
        <meta name="description" content={dynamicDescription} />
      </Helmet>

      <Box
        sx={{
          ...styles.container,
          backgroundColor: isDark === true ? "#15202B" : "",
        }}
      >
        <Box sx={styles.innerContainer}>
          <AIDialog
            userId={user.uid}
            open={aiDialogOpen}
            onClose={() => {
              setAiDialogOpen(false);
            }}
            initialQuestion={
              currentCollection &&
              currentCollection.flashcards &&
              currentCollection.flashcards[currentCardIndex]
                ? `${currentCollection.flashcards[currentCardIndex].front} - ${currentCollection.flashcards[currentCardIndex].back}
Please explain these flashcards and help me understand the answer options. Skriv på svenska.`
                : "Please explain the current flashcard and help me understand the answer options. Skriv på svenska."
            }
            systemMessage="You are a helpful AI assistant designed to explain and assist flashcards practiceProvide clear, pedagogical explanations using analogies and examples when appropriate. Skriv på svenska, skriv kortfattat."
            theme={theme}
          />

          <UserCollectionsDrawer
            user={user}
            theme={theme}
            open={drawerOpen}
            onClose={toggleDrawer(false)}
            onStartTraining={startTraining}
            theme={theme}
          />

          <PublicCollectionsDrawer
            user={user}
            theme={theme}
            open={drawerOpenLibrary}
            onClose={toggleDrawerLibrary(false)}
            onStartTraining={startTraining}
            setCollections={setCollections}
            setShowLibrary={setDrawerLibraryOpen}
            setRefreshTrigger={setRefreshTrigger}
          />

          <Box
            sx={{
              ...styles.buttonsContainer,
              ...styles.additionalButtonsContainer,
            }}
          >
            {currentCollection &&
              (isMobile ? (
                <Button
                  variant="contained"
                  color="primary"
                  disableRipple
                  onClick={showCreateView}
                  startIcon={<AddIcon />}
                  sx={{
                    padding: "8px",
                    marginRight: "0px",
                    paddingLeft: "14px",
                    paddingRight: "3px",
                    minWidth: "auto",
                    textTransform: "none",
                  }}
                />
              ) : (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={showCreateView}
                    startIcon={<AddIcon />}
                  >
                    Skapa
                  </Button>

                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      setViewMode(viewMode === "cards" ? "match" : "cards")
                    }
                    startIcon={<ViewWeekIcon />}
                    sx={{ marginLeft: "8px", display: "none" }}
                  >
                    {viewMode === "cards" ? "Match Mode" : "Card Mode"}
                  </Button>
                </>
              ))}

            {isMobile ? (
              <Button
                variant="contained"
                color="primary"
                disableRipple
                onClick={toggleDrawerLibrary(true)}
                startIcon={<ViewAgendaIcon />}
                sx={{
                  padding: "8px",
                  marginRight: "0px",
                  paddingLeft: "14px",
                  paddingRight: "3px",
                  minWidth: "auto",
                  textTransform: "none",
                  display: "none",
                }}
              />
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={toggleDrawerLibrary(true)}
                startIcon={<ViewAgendaIcon />}
                sx={{ marginLeft: "0px", display: "none" }}
              >
                Delade
              </Button>
            )}

            <Button
              variant="contained"
              color="primary"
              disableRipple
              onClick={toggleDrawer(true)}
              startIcon={<SegmentIcon />}
              sx={{
                padding: "8px",
                marginRight: "0px",
                paddingLeft: "14px",
                paddingRight: "3px",
                minWidth: "auto",
                textTransform: "none",
              }}
            />
          </Box>

          {sessionSummary ? (
            <TrainingSummary
              sessionSummary={sessionSummary}
              currentCollection={currentCollection}
              startTraining={startTraining}
              theme={theme}
              styles={styles}
              onBack={showCreateView}
            />
          ) : currentCollection ? (
            <>
              {viewMode === "cards" ? (
                <>
                  <FlashcardGame
                    currentCollection={currentCollection}
                    currentCardIndex={currentCardIndex}
                    showBack={showBack}
                    sessionSummary={sessionSummary}
                    bookmarks={bookmarks}
                    creatorInfo={creatorInfo}
                    originalCollectionId={originalCollectionId}
                    user={user}
                    theme={theme}
                    styles={styles}
                    isDark={isDark}
                    filterFlashcards={filterFlashcards}
                    onFlipCard={flipCard}
                    onShuffleCards={shuffleCards}
                    onToggleBookmark={toggleBookmark}
                    onRateCard={rateCard}
                    onAskAI={handleAskAI}
                  />

                  <Box
                    sx={{
                      mt: 12,
                      width: { xs: "100%", md: "100%" },
                      margin: "0 auto",
                    }}
                  >
                    <Box sx={styles.headlineLessonBox}>
                      <Typography
                        variant="h6"
                        sx={{ ...styles.headlineLessonBoxTitle, mt: 15 }}
                      >
                        Mina flashcards
                      </Typography>
                    </Box>
                    <Box sx={{ ...styles.freshBox, padding: "0px" }}>
                      <MyFlashcards
                        user={user}
                        theme={theme}
                        styles={styles}
                        onStartTraining={startTraining}
                      />
                    </Box>
                  </Box>
                </>
              ) : (
                <Match
                  flashcards={currentCollection.flashcards}
                  isDark={isDark}
                  onComplete={(stats) => {
                    console.log("Match game completed:", stats);
                  }}
                />
              )}
            </>
          ) : (
            <>
              <Box sx={{ marginTop: "60px" }}>
                {isCreateViewVisible ? (
                  <>
                    <FlashcardsCreateView
                      user={user}
                      onCollectionCreated={handleCollectionCreated}
                      isDark={isDark}
                      startTraining={startTraining}
                    />
                  </>
                ) : (
                  <Box>
                    <Typography variant="body1">
                      Välj en samling ovan eller skapa dina egna flashcards för
                      att börja träna.
                    </Typography>
                  </Box>
                )}
              </Box>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default FlashcardsView;
