import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";

const ConfirmationDialog = ({
  open,
  onClose,
  onConfirm,
  title = "Bekräfta åtgärd",
  message = "Är du säker på att du vill fortsätta?",
  confirmText = "Bekräfta",
  cancelText = "Avbryt",
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ fontWeight: "600", p: 3 }}>{title}</DialogTitle>
      <DialogContent>
        <Typography>{message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="print" onClick={onClose}>
          {cancelText}
        </Button>
        <Button onClick={onConfirm} variant="purp" autoFocus>
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
