import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  IconButton,
  Tooltip,
  useTheme,
  Grid,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/SaveAs";
import AddIcon from "@mui/icons-material/Add";

const QuestionDisplay = ({
  question,
  index,
  type,
  onUpdateQuestion,
  onDeleteQuestion,
  styles,
  className,
  isAnimating,
}) => {
  const theme = useTheme();
  const [isEditing, setIsEditing] = useState(question.question === "");
  const [isHovered, setIsHovered] = useState(false);
  const [editedQuestion, setEditedQuestion] = useState({
    ...question,
    hints: question.hints || [],
  });

  const getLetterForIndex = (idx) => String.fromCharCode(65 + idx);

  const handleSave = () => {
    onUpdateQuestion(index, editedQuestion);
    setIsEditing(false);
  };

  const letterBoxStyles = {
    width: 30,
    height: 30,
    bgcolor: "background.paper",
    border: "2px solid",
    borderColor: "text.main",
    color: "text.main",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    mr: 2,
    borderRadius: 1,
    fontSize: "0.9rem",
    fontWeight: "bold",
    "&:hover": {
      bgcolor: "text.main",
      color: "white",
    },
  };

  const renderQuestionContent = () => {
    if (isEditing) {
      if (type === "exam") {
        return (
          <Box sx={{ mt: 4 }}>
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: 500,
                color: theme.palette.text.secondary,
                fontSize: "1rem",
                mb: 1,
              }}
            >
              Ledtrådar:
            </Typography>
            {editedQuestion.hints?.map((hint, hintIdx) => (
              <Box
                key={hintIdx}
                sx={{ display: "flex", alignItems: "center", mb: 1 }}
              >
                <TextField
                  fullWidth
                  value={hint}
                  placeholder={`Ledtråd ${hintIdx + 1}`}
                  onChange={(e) => {
                    const newHints = [...(editedQuestion.hints || [])];
                    newHints[hintIdx] = e.target.value;
                    setEditedQuestion({ ...editedQuestion, hints: newHints });
                  }}
                  onBlur={() => onUpdateQuestion(index, editedQuestion)}
                  sx={{ ...styles.editingTextField }}
                />
                <IconButton
                  onClick={() => {
                    const newHints = editedQuestion.hints.filter(
                      (_, idx) => idx !== hintIdx,
                    );
                    setEditedQuestion({ ...editedQuestion, hints: newHints });
                  }}
                  sx={{
                    width: 40,
                    height: 40,
                    ml: 1,
                  }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Box>
            ))}
            <Button
              variant="print"
              onClick={() => {
                const newHints = [...(editedQuestion.hints || []), ""];
                setEditedQuestion({ ...editedQuestion, hints: newHints });
              }}
              startIcon={<AddIcon />}
              sx={{ mt: 1 }}
            >
              Lägg till ledtråd
            </Button>
          </Box>
        );
      }

      return (
        <Box>
          {editedQuestion.options?.map((option, optIdx) => (
            <Box
              key={optIdx}
              sx={{ display: "flex", alignItems: "center", mb: 2 }}
            >
              <Box sx={letterBoxStyles}>{getLetterForIndex(optIdx)}</Box>
              <TextField
                fullWidth
                value={option}
                sx={{ ...styles.editingTextField }}
                onChange={(e) => {
                  const newOptions = [...editedQuestion.options];
                  newOptions[optIdx] = e.target.value;
                  setEditedQuestion({ ...editedQuestion, options: newOptions });
                }}
                onBlur={() => onUpdateQuestion(index, editedQuestion)}
              />
            </Box>
          ))}

          <TextField
            fullWidth
            label="Rätt svar"
            value={editedQuestion.correct}
            onChange={(e) =>
              setEditedQuestion({ ...editedQuestion, correct: e.target.value })
            }
            onBlur={() => onUpdateQuestion(index, editedQuestion)}
            sx={{ ...styles.editingTextField, mt: 2 }}
          />
          <Box sx={{ mt: 4 }}>
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: 500,
                color: theme.palette.text.secondary,
                fontSize: "1rem",
                mb: 1,
              }}
            >
              Ledtrådar:
            </Typography>
            {editedQuestion.hints?.map((hint, hintIdx) => (
              <Box
                key={hintIdx}
                sx={{ display: "flex", alignItems: "center", mb: 1 }}
              >
                <TextField
                  fullWidth
                  value={hint}
                  placeholder={`Ledtråd ${hintIdx + 1}`}
                  onChange={(e) => {
                    const newHints = [...(editedQuestion.hints || [])];
                    newHints[hintIdx] = e.target.value;
                    setEditedQuestion({ ...editedQuestion, hints: newHints });
                  }}
                  onBlur={() => onUpdateQuestion(index, editedQuestion)}
                  sx={{ ...styles.editingTextField }}
                />
                <IconButton
                  onClick={() => {
                    const newHints = editedQuestion.hints.filter(
                      (_, idx) => idx !== hintIdx,
                    );
                    setEditedQuestion({ ...editedQuestion, hints: newHints });
                  }}
                  sx={{
                    width: 40,
                    height: 40,
                    ml: 1,
                  }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Box>
            ))}
            <Button
              variant="print"
              onClick={() => {
                const newHints = [...(editedQuestion.hints || []), ""];
                setEditedQuestion({ ...editedQuestion, hints: newHints });
              }}
              startIcon={<AddIcon />}
              sx={{ mt: 1 }}
            >
              Lägg till ledtråd
            </Button>
          </Box>
          <Button
            variant="purp"
            onClick={handleSave}
            startIcon={<SaveIcon />}
            sx={{ mt: 4 }}
          >
            Spara
          </Button>
        </Box>
      );
    }

    return (
      <Box>
        {question.options?.map((option, optIdx) => (
          <Box
            key={optIdx}
            sx={{ display: "flex", alignItems: "center", mb: 2 }}
          >
            <Box sx={letterBoxStyles}>{getLetterForIndex(optIdx)}</Box>
            <Typography>{option}</Typography>
          </Box>
        ))}
      </Box>
    );
  };

  return (
    <Grid item xs={12} md={6}>
      <Card
        elevation={0}
        className={className}
        sx={{
          height: "100%",
          backgroundColor: theme.palette.background.paperHover,
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: "15px",
          padding: "20px",
          position: "relative",
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <CardContent>
          {(isHovered || isEditing || isAnimating) && (
            <Box
              sx={{
                position: "absolute",
                left: "50%",
                top: 20,
                display: "flex",
                gap: 1,
                transform: "translateX(-50%)",
              }}
            >
              <Tooltip
                title={
                  isEditing
                    ? "Spara ändringar"
                    : "Redigera fråga och svarsalternativen"
                }
                placement="top"
              >
                <Button
                  variant={isEditing ? "success" : "print"}
                  onClick={isEditing ? handleSave : () => setIsEditing(true)}
                  startIcon={
                    isEditing ? (
                      <SaveIcon sx={{ color: isEditing ? "white" : "green" }} />
                    ) : (
                      <EditIcon sx={{ color: isEditing ? "white" : "green" }} />
                    )
                  }
                  sx={{
                    ...styles.tightButton,
                    minWidth: "auto",
                    animation: isAnimating
                      ? "pulseWithColor 1s infinite"
                      : "none",
                    "@keyframes pulseWithColor": {
                      "0%": { transform: "scale(1)", color: "inherit" },
                      "50%": {
                        transform: "scale(1.2)",
                        color: theme.palette.text.main,
                      },
                      "100%": { transform: "scale(1)", color: "inherit" },
                    },
                  }}
                >
                  {isEditing ? "Spara" : "Redigera"}
                </Button>
              </Tooltip>

              <Tooltip title="Radera frågan från quizzet" placement="top">
                <Button
                  variant="print"
                  onClick={() => onDeleteQuestion(index)}
                  startIcon={<DeleteIcon sx={{ color: "#ad0000" }} />}
                  sx={{
                    ...styles.tightButton,
                    minWidth: "auto",
                    animation: isAnimating
                      ? "pulseWithColor 1s infinite 0.2s"
                      : "none",
                    "@keyframes pulseWithColor": {
                      "0%": { transform: "scale(1)", color: "inherit" },
                      "50%": {
                        transform: "scale(1.2)",
                        color: theme.palette.text.main,
                      },
                      "100%": { transform: "scale(1)", color: "inherit" },
                    },
                  }}
                >
                  Radera
                </Button>
              </Tooltip>
            </Box>
          )}

          <Typography
            variant="h6"
            sx={{
              fontWeight: 500,
              color: theme.palette.text.secondary,
              fontSize: "1rem",
            }}
          >
            Fråga {index + 1}
          </Typography>

          {isEditing ? (
            <TextField
              fullWidth
              value={editedQuestion.question}
              onChange={(e) =>
                setEditedQuestion({
                  ...editedQuestion,
                  question: e.target.value,
                })
              }
              onBlur={() => onUpdateQuestion(index, editedQuestion)}
              sx={{ ...styles.editingTextField, mb: 6, mt: 1 }}
            />
          ) : (
            <Typography
              variant="body1"
              sx={{
                mb: 6,
                mt: 1,
                fontWeight: 600,
                fontSize: "1.3rem",
                color: theme.palette.text.primary,
              }}
            >
              {question.question}
            </Typography>
          )}

          {renderQuestionContent()}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default QuestionDisplay;
