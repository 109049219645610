import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    Divider,
    Button,
} from "@mui/material";
import { InstructionItem } from "./functions";

const TocDialog = ({ open, onClose }) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle
                sx={{
                    fontSize: "1.5rem",
                    fontWeight: 600,
                    borderBottom: "1px solid",
                    borderColor: "divider",
                }}
            >
                Användarvillkor och integritetspolicy
            </DialogTitle>
            <DialogContent
                sx={{
                    mt: 2,
                }}
            >
                <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ mt: 2, fontWeight: 600 }}
                >
                    1. Insamling av personuppgifter
                </Typography>
                <Typography paragraph>
                    Vi samlar in personuppgifter som du frivilligt lämnar till
                    oss när du använder vår tjänst, såsom namn och e-postadress.
                </Typography>

                <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ mt: 5, fontWeight: 600 }}
                >
                    2. Användning av personuppgifter
                </Typography>
                <Typography paragraph>
                    Dina personuppgifter används för att:
                </Typography>

                <InstructionItem
                    text="Tillhandahålla och förbättra våra tjänster"
                    marginBottom={1.5}
                    simple={true}
                />
                <InstructionItem
                    text="Anpassa din inlärningsupplevelse"
                    marginBottom={1.5}
                    simple={true}
                />
                <InstructionItem
                    text="Kommunicera med dig om din användning av tjänsten"
                    marginBottom={1.5}
                    simple={true}
                />
                <InstructionItem
                    text="Följa lagliga skyldigheter"
                    marginBottom={1.5}
                    simple={true}
                />
                <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ mt: 5, fontWeight: 600 }}
                >
                    3. Delning av personuppgifter
                </Typography>
                <Typography paragraph>
                    Vi delar inte dina personuppgifter med tredje part, förutom
                    när det krävs för att tillhandahålla våra tjänster eller när
                    vi är skyldiga att göra det enligt lag.
                </Typography>

                <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ mt: 5, fontWeight: 600 }}
                >
                    4. Dina rättigheter enligt GDPR
                </Typography>
                <Typography paragraph>
                    Som användare har du rätt att:
                </Typography>

                <InstructionItem
                    text="Få tillgång till dina personuppgifter"
                    marginBottom={1.5}
                    simple={true}
                />
                <InstructionItem
                    text="Begära rättelse av felaktiga uppgifter "
                    marginBottom={1.5}
                    simple={true}
                />
                <InstructionItem
                    text="Begära radering av dina uppgifter"
                    marginBottom={1.5}
                    simple={true}
                />
                <InstructionItem
                    text="Begära begränsning av behandling"
                    marginBottom={1.5}
                    simple={true}
                />
                <InstructionItem
                    text="Invända mot behandling"
                    marginBottom={1.5}
                    simple={true}
                />
                <InstructionItem
                    text="Begära dataportabilitet"
                    marginBottom={1.5}
                    simple={true}
                />
                <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ mt: 5, fontWeight: 600 }}
                >
                    5. Datasäkerhet
                </Typography>
                <Typography paragraph>
                    Vi använder lämpliga tekniska och organisatoriska åtgärder
                    för att skydda dina personuppgifter mot obehörig åtkomst,
                    förlust eller missbruk.
                </Typography>

                <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ mt: 5, fontWeight: 600 }}
                >
                    6. AI-teknologi och datasäkerhet
                </Typography>
                <Typography paragraph>
                    Vi har implementerat flera viktiga säkerhetsåtgärder för att
                    säkerställa trygg användning av AI-teknologi:
                </Typography>

                <InstructionItem text="GDPR-säker plattform: Vi följer strikt dataskyddslagstiftning för att skydda elever och lärare." />

                <InstructionItem text="Anonymisering av data: All data som skickas till AI anonymiseras och innehåller aldrig personlig information." />

                <InstructionItem text="Ingen datadelning: Vi delar aldrig elev- eller lärardata med andra tredje parter." />
                <InstructionItem text="Opt-out från modellträning: Våra förfrågningar till AI-modeller används inte för att förbättra deras modeller." />
                <InstructionItem text="Transparent datasäkerhet: Vi är öppna med hur din data hanteras och ger full kontroll till användaren." />

                <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ mt: 5, fontWeight: 600 }}
                >
                    7. Kontakta oss
                </Typography>
                <Typography paragraph>
                    Om du har frågor om vår integritetspolicy eller vill utöva
                    dina rättigheter, vänligen kontakta oss på info@studera.ai.
                </Typography>

                <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ mt: 5, fontWeight: 600 }}
                >
                    8. Åldersgräns och samtycke
                </Typography>
                <Typography paragraph>
                    För att använda Studera.ai måste du vara minst 13 år gammal.
                    Användare mellan 13-16 år behöver målsmans godkännande för
                    att skapa ett konto. Som användare under 16 år bekräftar du
                    att du har fått detta godkännande innan du registrerar dig.
                </Typography>

                <Typography paragraph>
                    För användning i skolmiljö gäller följande:
                </Typography>

                <InstructionItem
                    text="Skolan ansvarar för att inhämta nödvändiga samtycken från vårdnadshavare"
                    marginBottom={1.5}
                    simple={true}
                />
                <InstructionItem
                    text="Lärare som registrerar elever måste ha behörighet från sin skola"
                    marginBottom={1.5}
                    simple={true}
                />
                <InstructionItem
                    text="Skoladministratörer ansvarar för att verifiera lärarnas behörighet"
                    marginBottom={1.5}
                    simple={true}
                />

                <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ mt: 5, fontWeight: 600 }}
                >
                    9. Användaransvar och uppföranderegler
                </Typography>
                <Typography paragraph>
                    Som användare av Studera.ai förväntas du följa dessa
                    grundläggande regler:
                </Typography>

                <InstructionItem
                    text="Dela aldrig dina inloggningsuppgifter med andra"
                    marginBottom={1.5}
                    simple={true}
                />
                <InstructionItem
                    text="Använd inte tjänsten för att sprida olagligt eller stötande material"
                    marginBottom={1.5}
                    simple={true}
                />
                <InstructionItem
                    text="Respektera andra användares integritet och rättigheter"
                    marginBottom={1.5}
                    simple={true}
                />
                <InstructionItem
                    text="Försök inte kringgå några tekniska begränsningar i tjänsten"
                    marginBottom={1.5}
                    simple={true}
                />

                <Typography paragraph sx={{ mt: 5 }}>
                    Vid överträdelse av dessa regler kan följande åtgärder
                    vidtas:
                </Typography>

                <InstructionItem
                    text="Temporär avstängning från tjänsten"
                    marginBottom={1.5}
                    simple={true}
                />
                <InstructionItem
                    text="Permanent avstängning vid upprepade eller allvarliga överträdelser"
                    marginBottom={1.5}
                    simple={true}
                />
                <InstructionItem
                    text="Radering av innehåll som bryter mot reglerna"
                    marginBottom={1.5}
                    simple={true}
                />

                <Typography paragraph sx={{ mt: 5 }}>
                    Allt innehåll som du skapar och delar via Studera.ai måste
                    följa svensk lag och får inte:
                </Typography>

                <InstructionItem
                    text="Inkräkta på andras upphovsrätt eller äganderätt"
                    marginBottom={1.5}
                    simple={true}
                />
                <InstructionItem
                    text="Innehålla personuppgifter om andra utan deras samtycke"
                    marginBottom={1.5}
                    simple={true}
                />
                <InstructionItem
                    text="Användas för att sprida desinformation eller vilseledande innehåll"
                    marginBottom={1.5}
                    simple={true}
                />
            </DialogContent>
            <Divider />
            <DialogActions sx={{ p: 0 }}>
                <Button onClick={onClose} variant="print">
                    Stäng
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default TocDialog;
