import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  TextField,
  Typography,
  Grid,
  IconButton,
  Button,
  Card,
  CardContent,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";

const LessonPlanEditor = ({ open, onClose, lessonData, onUpdate, onSave }) => {
  const handleBasicChange = (field, value) => {
    onUpdate({
      ...lessonData,
      lektionsplan: {
        ...lessonData.lektionsplan,
        [field]: value,
      },
    });
  };

  const handleNestedChange = (section, field, value) => {
    onUpdate({
      ...lessonData,
      lektionsplan: {
        ...lessonData.lektionsplan,
        [section]: {
          ...lessonData.lektionsplan[section],
          [field]: value,
        },
      },
    });
  };

  const handleArrayChange = (section, field, index, value) => {
    const newArray = [...lessonData.lektionsplan[section][field]];
    newArray[index] = value;

    onUpdate({
      ...lessonData,
      lektionsplan: {
        ...lessonData.lektionsplan,
        [section]: {
          ...lessonData.lektionsplan[section],
          [field]: newArray,
        },
      },
    });
  };

  const handleArrayRemove = (section, field, index) => {
    const newArray = lessonData.lektionsplan[section][field].filter(
      (_, i) => i !== index,
    );

    onUpdate({
      ...lessonData,
      lektionsplan: {
        ...lessonData.lektionsplan,
        [section]: {
          ...lessonData.lektionsplan[section],
          [field]: newArray,
        },
      },
    });
  };

  const handleArrayAdd = (section, field) => {
    const newArray = [...lessonData.lektionsplan[section][field], ""];

    onUpdate({
      ...lessonData,
      lektionsplan: {
        ...lessonData.lektionsplan,
        [section]: {
          ...lessonData.lektionsplan[section],
          [field]: newArray,
        },
      },
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      sx={{
        "& .MuiDialog-paper": {
          maxHeight: "90vh",
        },
      }}
    >
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          fontWeight: "600",
        }}
      >
        Redigera lektionsplan
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        <Box sx={{ p: 2 }}>
          {/* Basic Information */}
          <Card sx={{ mb: 4, borderRadius: 2 }}>
            <CardContent>
              <Typography variant="h6" sx={{ mb: 3 }}>
                Grundinformation
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "500", mb: 1 }}
                  >
                    Titel
                  </Typography>
                  <TextField
                    fullWidth
                    value={lessonData.lektionsplan.titel}
                    onChange={(e) => handleBasicChange("titel", e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "500", mb: 1 }}
                  >
                    Undertitel
                  </Typography>
                  <TextField
                    fullWidth
                    value={lessonData.lektionsplan.undertitel}
                    onChange={(e) =>
                      handleBasicChange("undertitel", e.target.value)
                    }
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          {/* Mål */}
          <Card sx={{ mb: 4, borderRadius: 2 }}>
            <CardContent>
              <Typography variant="h6" sx={{ mb: 3 }}>
                Mål
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "500", mb: 1 }}
                  >
                    Syfte
                  </Typography>
                  <TextField
                    fullWidth
                    multiline
                    rows={3}
                    value={lessonData.lektionsplan.mål.syfte}
                    onChange={(e) =>
                      handleNestedChange("mål", "syfte", e.target.value)
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "500", mb: 1 }}
                  >
                    Koppling till kursplan
                  </Typography>
                  {lessonData.lektionsplan.mål.kopplingTillKursplan.map(
                    (koppling, index) => (
                      <Box key={index} sx={{ display: "flex", gap: 1, mb: 2 }}>
                        <TextField
                          fullWidth
                          value={koppling}
                          onChange={(e) =>
                            handleArrayChange(
                              "mål",
                              "kopplingTillKursplan",
                              index,
                              e.target.value,
                            )
                          }
                        />
                        <IconButton
                          onClick={() =>
                            handleArrayRemove(
                              "mål",
                              "kopplingTillKursplan",
                              index,
                            )
                          }
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      </Box>
                    ),
                  )}
                  <Button
                    variant="print"
                    startIcon={<AddIcon />}
                    onClick={() =>
                      handleArrayAdd("mål", "kopplingTillKursplan")
                    }
                  >
                    Lägg till koppling
                  </Button>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "500", mb: 1 }}
                  >
                    Lärandemål
                  </Typography>
                  {lessonData.lektionsplan.mål.lärandemål.map((mål, index) => (
                    <Box key={index} sx={{ display: "flex", gap: 1, mb: 2 }}>
                      <TextField
                        fullWidth
                        value={mål}
                        onChange={(e) =>
                          handleArrayChange(
                            "mål",
                            "lärandemål",
                            index,
                            e.target.value,
                          )
                        }
                      />
                      <IconButton
                        onClick={() =>
                          handleArrayRemove("mål", "lärandemål", index)
                        }
                      >
                        <DeleteOutlineIcon />
                      </IconButton>
                    </Box>
                  ))}
                  <Button
                    variant="print"
                    startIcon={<AddIcon />}
                    onClick={() => handleArrayAdd("mål", "lärandemål")}
                  >
                    Lägg till lärandemål
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          {/* Uppvärmning */}
          <Card sx={{ mb: 4, borderRadius: 2 }}>
            <CardContent>
              <Typography variant="h6" sx={{ mb: 3 }}>
                Uppvärmning
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "500", mb: 1 }}
                  >
                    Aktivitet
                  </Typography>
                  <TextField
                    fullWidth
                    multiline
                    rows={2}
                    value={lessonData.lektionsplan.uppvärmning.aktivitet}
                    onChange={(e) =>
                      handleNestedChange(
                        "uppvärmning",
                        "aktivitet",
                        e.target.value,
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "500", mb: 1 }}
                  >
                    Koppling till tema
                  </Typography>
                  <TextField
                    fullWidth
                    value={lessonData.lektionsplan.uppvärmning.kopplingTillTema}
                    onChange={(e) =>
                      handleNestedChange(
                        "uppvärmning",
                        "kopplingTillTema",
                        e.target.value,
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "500", mb: 1 }}
                  >
                    Tidsåtgång (minuter)
                  </Typography>
                  <TextField
                    type="number"
                    value={lessonData.lektionsplan.uppvärmning.tidsåtgång}
                    onChange={(e) =>
                      handleNestedChange(
                        "uppvärmning",
                        "tidsåtgång",
                        parseInt(e.target.value),
                      )
                    }
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          {/* Inledning */}
          <Card sx={{ mb: 4, borderRadius: 2 }}>
            <CardContent>
              <Typography variant="h6" sx={{ mb: 3 }}>
                Inledning
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "500", mb: 1 }}
                  >
                    Beskrivning
                  </Typography>
                  <TextField
                    fullWidth
                    multiline
                    rows={3}
                    value={lessonData.lektionsplan.inledning.beskrivning}
                    onChange={(e) =>
                      handleNestedChange(
                        "inledning",
                        "beskrivning",
                        e.target.value,
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "500", mb: 1 }}
                  >
                    Frågor
                  </Typography>
                  {lessonData.lektionsplan.inledning.frågor.map(
                    (fråga, index) => (
                      <Box key={index} sx={{ display: "flex", gap: 1, mb: 2 }}>
                        <TextField
                          fullWidth
                          value={fråga}
                          onChange={(e) =>
                            handleArrayChange(
                              "inledning",
                              "frågor",
                              index,
                              e.target.value,
                            )
                          }
                        />
                        <IconButton
                          onClick={() =>
                            handleArrayRemove("inledning", "frågor", index)
                          }
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      </Box>
                    ),
                  )}
                  <Button
                    variant="print"
                    startIcon={<AddIcon />}
                    onClick={() => handleArrayAdd("inledning", "frågor")}
                  >
                    Lägg till fråga
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "500", mb: 1 }}
                  >
                    Tidsåtgång (minuter)
                  </Typography>
                  <TextField
                    type="number"
                    value={lessonData.lektionsplan.inledning.tidsåtgång}
                    onChange={(e) =>
                      handleNestedChange(
                        "inledning",
                        "tidsåtgång",
                        parseInt(e.target.value),
                      )
                    }
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          {/* Huvudaktivitet */}
          <Card sx={{ mb: 4, borderRadius: 2 }}>
            <CardContent>
              <Typography variant="h6" sx={{ mb: 3 }}>
                Huvudaktivitet
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "500", mb: 1 }}
                  >
                    Instruktioner
                  </Typography>
                  {lessonData.lektionsplan.huvudaktivitet.instruktioner.map(
                    (instruktion, index) => (
                      <Box key={index} sx={{ display: "flex", gap: 1, mb: 2 }}>
                        <TextField
                          fullWidth
                          multiline
                          rows={2}
                          value={instruktion}
                          onChange={(e) =>
                            handleArrayChange(
                              "huvudaktivitet",
                              "instruktioner",
                              index,
                              e.target.value,
                            )
                          }
                        />
                        <IconButton
                          onClick={() =>
                            handleArrayRemove(
                              "huvudaktivitet",
                              "instruktioner",
                              index,
                            )
                          }
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      </Box>
                    ),
                  )}
                  <Button
                    variant="print"
                    startIcon={<AddIcon />}
                    onClick={() =>
                      handleArrayAdd("huvudaktivitet", "instruktioner")
                    }
                  >
                    Lägg till instruktion
                  </Button>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "500", mb: 1 }}
                  >
                    Steg
                  </Typography>
                  {lessonData.lektionsplan.huvudaktivitet.steg.map(
                    (steg, index) => (
                      <Box
                        key={index}
                        sx={{
                          border: "1px solid #ddd",
                          p: 2,
                          mb: 2,
                          borderRadius: 1,
                        }}
                      >
                        <Typography variant="subtitle2" sx={{ mb: 1 }}>
                          Steg {index + 1}
                        </Typography>
                        <TextField
                          fullWidth
                          multiline
                          rows={2}
                          label="Beskrivning"
                          value={steg.beskrivning}
                          onChange={(e) => {
                            const newSteg = [
                              ...lessonData.lektionsplan.huvudaktivitet.steg,
                            ];
                            newSteg[index] = {
                              ...steg,
                              beskrivning: e.target.value,
                            };
                            handleNestedChange(
                              "huvudaktivitet",
                              "steg",
                              newSteg,
                            );
                          }}
                          sx={{ mb: 2 }}
                        />
                        <TextField
                          type="number"
                          label="Tidsåtgång (minuter)"
                          value={steg.tidsåtgång}
                          onChange={(e) => {
                            const newSteg = [
                              ...lessonData.lektionsplan.huvudaktivitet.steg,
                            ];
                            newSteg[index] = {
                              ...steg,
                              tidsåtgång: parseInt(e.target.value),
                            };
                            handleNestedChange(
                              "huvudaktivitet",
                              "steg",
                              newSteg,
                            );
                          }}
                        />
                        <IconButton
                          onClick={() => {
                            const newSteg =
                              lessonData.lektionsplan.huvudaktivitet.steg.filter(
                                (_, i) => i !== index,
                              );
                            handleNestedChange(
                              "huvudaktivitet",
                              "steg",
                              newSteg,
                            );
                          }}
                          sx={{ float: "right" }}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      </Box>
                    ),
                  )}
                  <Button
                    variant="print"
                    startIcon={<AddIcon />}
                    onClick={() => {
                      const newSteg = [
                        ...lessonData.lektionsplan.huvudaktivitet.steg,
                      ];
                      newSteg.push({
                        stegNummer: newSteg.length + 1,
                        beskrivning: "",
                        tidsåtgång: 0,
                      });
                      handleNestedChange("huvudaktivitet", "steg", newSteg);
                    }}
                  >
                    Lägg till steg
                  </Button>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "500", mb: 1 }}
                  >
                    Förväntade resultat
                  </Typography>
                  {lessonData.lektionsplan.huvudaktivitet.förväntadeResultat.map(
                    (resultat, index) => (
                      <Box key={index} sx={{ display: "flex", gap: 1, mb: 2 }}>
                        <TextField
                          fullWidth
                          value={resultat}
                          onChange={(e) =>
                            handleArrayChange(
                              "huvudaktivitet",
                              "förväntadeResultat",
                              index,
                              e.target.value,
                            )
                          }
                        />
                        <IconButton
                          onClick={() =>
                            handleArrayRemove(
                              "huvudaktivitet",
                              "förväntadeResultat",
                              index,
                            )
                          }
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      </Box>
                    ),
                  )}
                  <Button
                    variant="print"
                    startIcon={<AddIcon />}
                    onClick={() =>
                      handleArrayAdd("huvudaktivitet", "förväntadeResultat")
                    }
                  >
                    Lägg till förväntat resultat
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          {/* Material */}
          <Card sx={{ mb: 4, borderRadius: 2 }}>
            <CardContent>
              <Typography variant="h6" sx={{ mb: 3 }}>
                Material
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "500", mb: 1 }}
                  >
                    Material och resurser
                  </Typography>
                  {lessonData.lektionsplan.material.map((item, index) => (
                    <Box key={index} sx={{ display: "flex", gap: 1, mb: 2 }}>
                      <TextField
                        fullWidth
                        value={item}
                        onChange={(e) =>
                          handleArrayChange(
                            "material",
                            "",
                            index,
                            e.target.value,
                          )
                        }
                      />
                      <IconButton
                        onClick={() => handleArrayRemove("material", "", index)}
                      >
                        <DeleteOutlineIcon />
                      </IconButton>
                    </Box>
                  ))}
                  <Button
                    variant="print"
                    startIcon={<AddIcon />}
                    onClick={() => handleArrayAdd("material", "")}
                  >
                    Lägg till material
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          {/* Utvärdering */}
          <Card sx={{ mb: 4, borderRadius: 2 }}>
            <CardContent>
              <Typography variant="h6" sx={{ mb: 3 }}>
                Utvärdering
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "500", mb: 1 }}
                  >
                    Självutvärdering
                  </Typography>
                  <TextField
                    fullWidth
                    multiline
                    rows={3}
                    value={lessonData.lektionsplan.utvärdering.självutvärdering}
                    onChange={(e) =>
                      handleNestedChange(
                        "utvärdering",
                        "självutvärdering",
                        e.target.value,
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "500", mb: 1 }}
                  >
                    Lärarens bedömning
                  </Typography>
                  <TextField
                    fullWidth
                    multiline
                    rows={3}
                    value={
                      lessonData.lektionsplan.utvärdering.lärarensBedömning
                    }
                    onChange={(e) =>
                      handleNestedChange(
                        "utvärdering",
                        "lärarensBedömning",
                        e.target.value,
                      )
                    }
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          {/* Lärarens reflektion */}
          <Card sx={{ mb: 4, borderRadius: 2 }}>
            <CardContent>
              <Typography variant="h6" sx={{ mb: 3 }}>
                Lärarens reflektion
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "500", mb: 1 }}
                  >
                    Framgångar
                  </Typography>
                  <TextField
                    fullWidth
                    multiline
                    rows={2}
                    value={
                      lessonData.lektionsplan.lärarensReflektion.framgångar
                    }
                    onChange={(e) =>
                      handleNestedChange(
                        "lärarensReflektion",
                        "framgångar",
                        e.target.value,
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "500", mb: 1 }}
                  >
                    Förbättringsområden
                  </Typography>
                  <TextField
                    fullWidth
                    multiline
                    rows={2}
                    value={
                      lessonData.lektionsplan.lärarensReflektion
                        .förbättringsområden
                    }
                    onChange={(e) =>
                      handleNestedChange(
                        "lärarensReflektion",
                        "förbättringsområden",
                        e.target.value,
                      )
                    }
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </DialogContent>

      <DialogActions sx={{ p: 3 }}>
        <Button variant="print" onClick={onClose} color="inherit">
          Avbryt
        </Button>
        <Button
          onClick={() => {
            onSave();
            onClose();
          }}
          variant="purp"
        >
          Spara ändringar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LessonPlanEditor;
