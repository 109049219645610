import React from "react";
import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  Switch,
  Typography,
  Box,
  Tooltip,
  Grid,
} from "@mui/material";
import LanguageSelector from "./LanguageSelector";

import { AIModelSelect } from "./TestComponents2";

const AdditionalFields = ({
  aiInstructions,
  setAiInstructions,
  numQuestions,
  setNumQuestions,
  category,
  setCategory,
  difficulty,
  setDifficulty,
  questionType,
  setQuestionType,
  selectedLanguage,
  setSelectedLanguage,
  isPublic,
  handlePublicChange,
  theme,
  selectedModel,
  setSelectedModel,
  includeHints,
  setIncludeHints,
  duration,
  setDuration,
  includeTimer,
  setIncludeTimer,
}) => {
  return (
    <Box>
      <Grid container spacing={4}>
        {/* Number of Questions */}

        {/* Category */}

        <Grid item xs={12} md={6}>
          <Typography
            variant="formLabelSinUnderline"
            sx={{ fontWeight: "500", mb: 1, display: "block" }}
          >
            Inkludera timer
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor:
                theme.palette.mode === "dark" ? "#394653" : "#f5f5f5",
              borderRadius: "8px",

              mt: 0,
              padding: 1,
              paddingLeft: "15px",
            }}
          >
            <Typography variant="formLabelSinUnderline">
              {includeTimer
                ? "X antal sekunder per fråga"
                : "Nej, tack. Ingen stress."}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Switch
                checked={includeTimer}
                onChange={(e) => setIncludeTimer(e.target.checked)} // This is the correct way
                inputProps={{ "aria-label": "include hints" }}
              />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="subtitle1" sx={{ fontWeight: "500", mt: -0.6 }}>
            Antal sekunder för varje fråga:
          </Typography>
          <Box sx={{ mt: 1 }}>
            <FormControl fullWidth>
              <Select
                value={duration}
                onChange={(e) => setDuration(e.target.value)}
                variant="outlined"
              >
                <MenuItem value={30}>30 sekunder</MenuItem>
                <MenuItem value={60}>60 sekunder</MenuItem>
                <MenuItem value={120}>120 sekunder (2 minuter)</MenuItem>
                <MenuItem value={240}>240 sekunder (4 minuter)</MenuItem>
                <MenuItem value={300}>300 sekunder (5 minuter)</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <Typography
              variant="formLabelSinUnderline"
              sx={{ fontWeight: "500" }}
            >
              Kategori
            </Typography>
            <Select
              sx={{ mt: 1 }}
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              <MenuItem value="Välj kategori" disabled>
                Välj kategori
              </MenuItem>
              <MenuItem value="Historia">Historia</MenuItem>
              <MenuItem value="Matematik">Matematik</MenuItem>
              <MenuItem value="Vetenskap">Vetenskap</MenuItem>
              <MenuItem value="Fysik">Fysik</MenuItem>
              <MenuItem value="Språk">Språk</MenuItem>
              <MenuItem value="Litteratur">Litteratur</MenuItem>
              <MenuItem value="Film">Film</MenuItem>
              <MenuItem value="Konst">Konst</MenuItem>
              <MenuItem value="Musik">Musik</MenuItem>
              <MenuItem value="Idrott">Idrott</MenuItem>
              <MenuItem value="Teknik">Teknik</MenuItem>
              <MenuItem value="Filosofi">Filosofi</MenuItem>
              <MenuItem value="Religion">Religion</MenuItem>
              <MenuItem value="Biologi">Biologi</MenuItem>
              <MenuItem value="Psykologi">Psykologi</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/* Difficulty */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth sx={{ mt: 0 }}>
            <Typography
              variant="formLabelSinUnderline"
              sx={{ fontWeight: "500" }}
            >
              Svårighetsgrad
            </Typography>
            <Select
              sx={{ mt: 1 }}
              value={difficulty}
              onChange={(e) => setDifficulty(e.target.value)}
            >
              <MenuItem value="Lätt">Lätt</MenuItem>
              <MenuItem value="Medel">Medel</MenuItem>
              <MenuItem value="Svårt">Svårt</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography
            variant="formLabelSinUnderline"
            sx={{ fontWeight: "500", display: "block" }}
          >
            AI-modell
          </Typography>

          <AIModelSelect
            selectedModel={selectedModel}
            setSelectedModel={setSelectedModel}
            disabledOptions={["Gemini Pro", "Mistral Large"]}
          />
        </Grid>

        {/* Visibility - Full width */}

        <Grid item xs={12} md={6}>
          <Typography
            variant="formLabelSinUnderline"
            sx={{ fontWeight: "500", mb: 1, display: "block" }}
          >
            Synlighet för andra användare
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",

              backgroundColor:
                theme.palette.mode === "dark" ? "#394653" : "#f5f5f5",
              borderRadius: "8px",
              mb: 4,
              mt: 0,
              padding: 1,
              paddingLeft: "15px",
            }}
          >
            <Typography variant="formLabelSinUnderline">Synlighet</Typography>

            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="caption" sx={{ mr: 1 }}>
                {isPublic ? "Publikt" : "Privat"}
              </Typography>
              <Switch
                checked={isPublic}
                onChange={handlePublicChange}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Typography
            variant="formLabelSinUnderline"
            sx={{ display: "block", mt: 0, fontWeight: "500" }}
          >
            Ytterligare instruktioner:
          </Typography>

          <TextField
            fullWidth
            sx={{ mt: 1 }}
            margin="normal"
            placeholder="T.ex. 'Ta inte med händelser från år 1942', eller 'undvik böcker skrivna av amerikanska författare'."
            value={aiInstructions}
            onChange={(e) => setAiInstructions(e.target.value)}
            inputProps={{ maxLength: 100 }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AdditionalFields;
