import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Grid,
  Box,
  useTheme,
  Button,
  Card,
  CardContent,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { motion } from "framer-motion";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  CircularProgress,
} from "@mui/material";
import { useSnackbar } from "./app/SnackbarHandler";
import { Link } from "react-router-dom";

// Price Toggle Component
const PriceToggle = ({ currentPlan, setCurrentPlan, theme }) => {
  return (
    <Box sx={{ textAlign: "center", mb: 7 }}>
      <Box
        sx={{
          display: "inline-flex",
          borderRadius: "38px",
          backgroundColor:
            theme.palette.mode === "dark" ? "#3a454f" : "#d9d9d982",
          padding: "4px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "200px",
            height: "36px",
            borderRadius: "18px",
            cursor: "pointer",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              width: "50%",
              height: "100%",
              borderRadius: "18px",
              backgroundColor: "#8b5cf6",
              transition: "transform 0.3s ease",
              transform:
                currentPlan === "larare" ? "translateX(0)" : "translateX(100%)",
            }}
          />
          <Typography
            onClick={() => setCurrentPlan("larare")}
            variant="body2"
            sx={{
              flex: 1,
              textAlign: "center",
              zIndex: 1,
              color:
                currentPlan === "larare"
                  ? "#fff"
                  : theme.palette.mode === "dark"
                    ? "#fff"
                    : "#000",
              transition: "color 0.3s ease",
              fontWeight: "600",
              cursor: "pointer",
              fontSize: "1rem",
            }}
          >
            Lärare
          </Typography>
          <Typography
            onClick={() => setCurrentPlan("skolor")}
            variant="body2"
            sx={{
              flex: 1,
              textAlign: "center",
              zIndex: 1,
              color:
                currentPlan === "skolor"
                  ? "#fff"
                  : theme.palette.mode === "dark"
                    ? "#fff"
                    : "#000",
              transition: "color 0.3s ease",
              fontWeight: "600",
              cursor: "pointer",
              fontSize: "1rem",
            }}
          >
            Skolor
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const teacherPlans = [
  {
    title: "BASIC",
    price: "0 kr",
    period: "",
    gradient: "linear-gradient(45deg, #FFD700, #FFA500)",
    features: [
      "Generera 20 lektioner",
      "Generera 10 bilder",
      "Grundläggande lektionstyper",
      "AI-verktyg för elever",
      "Ingen registrering krävs",
      "Personlig support",
    ],
    buttonText: "Testa helt gratis",
    isBasic: true,
  },
  {
    title: "MEDIUM",
    price: "99 kr",
    period: "/ månad",
    gradient: "linear-gradient(45deg, #7B68EE, #9370DB)",
    features: [
      "200 AI-genererade lektioner/mån",
      "Generera bilder (100 st/mån)",
      "Åtkomst till 10 lektionstyper",
      "AI-verktyg för elever",
      "Personlig support",
    ],
    buttonText: "Intresseanmälan",
    popular: true,
  },
  {
    title: "PREMIUM",
    price: "1299 kr",
    period: "/ år",
    gradient: "linear-gradient(45deg, #20B2AA, #48D1CC)",
    features: [
      "500 AI-genererade lektioner/mån",
      "Generera bilder (150 st/mån)",
      "Åtkomst till 20 lektionstyper",
      "Moderering för elevsäkerhet",
      "AI-verktyg för elever",
      "Prioriterad support",
    ],
    buttonText: "Intresseanmälan",
  },
];

const schoolPlans = [
  {
    title: "10 KONTON",
    price: "5990 kr",
    period: "/ år",
    gradient: "linear-gradient(45deg, #20B2AA, #48D1CC)",
    features: [
      "10 lärarkonton",
      "500 AI-genererade lektioner per år",
      "Åtkomst till samtliga lektionstyper",
      "Generera bilder (100/konto/mån)",
      "Moderering för elevsäkerhet",
      "Utbildning & onboarding",
    ],
    buttonText: "Intresseanmälan",
  },
  {
    title: "20 KONTON",
    price: "10990 kr",
    period: "/ år",
    gradient: "linear-gradient(45deg, #7B68EE, #9370DB)",
    features: [
      "20 lärarkonton",
      "1000 AI-genererade lektioner per år",
      "Åtkomst till samtliga lektionstyper",
      "Generera bilder (100/konto/mån)",
      "Moderering för elevsäkerhet",
      "Utbildning & onboarding",
    ],
    buttonText: "Intresseanmälan",
    popular: true,
  },
  {
    title: "50 KONTON",
    price: "23990 kr",
    period: "/ år",
    gradient: "linear-gradient(45deg, #FFD700, #FFA500)",
    features: [
      "50 lärarkonton",
      "2500 AI-genererade lektioner per år",
      "Åtkomst till samtliga lektionstyper",
      "Generera bilder (100/konto/mån)",
      "Moderering för elevsäkerhet",
      "Utbildning & onboarding",
    ],
    buttonText: "Intresseanmälan",
  },
];

const PricesSection = ({ hideBasic = false, strikeThrough = false, user }) => {
  const theme = useTheme();
  const [currentPlan, setCurrentPlan] = useState("larare");

  const [openModal, setOpenModal] = useState(false);
  const [email, setEmail] = useState(""); // THIS IS THE NEW LINE
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    if (user?.email) {
      setEmail(user.email);
    }
  }, [user?.email]);

  const handleSubmit = async () => {
    if (!/\S+@\S+\.\S+/.test(email)) {
      return;
    }

    try {
      setIsSubmitting(true);
      const response = await fetch(
        `${process.env.NODE_ENV === "production" ? "/api" : "http://localhost:3001/api"}/send-email`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email,
            message: `Intresseanmälan för ${selectedPlan?.title}`,
            subject: "Intresseanmälan",
          }),
        },
      );

      if (response.ok) {
        setOpenModal(false);
        showSnackbar("Tack! Vi hör av oss!", "success");
      }
    } catch (error) {
      console.error(error);
      showSnackbar("Ett fel inträffade. Vänligen försök igen.", "error");
    } finally {
      setIsSubmitting(false);
    }
  };

  const currentPlans =
    currentPlan === "larare"
      ? hideBasic
        ? teacherPlans.filter((plan) => !plan.isBasic)
        : teacherPlans
      : schoolPlans;

  return (
    <Container>
      <PriceToggle
        currentPlan={currentPlan}
        setCurrentPlan={setCurrentPlan}
        theme={theme}
      />

      <Grid container spacing={4} justifyContent="center">
        {currentPlans.map((plan, index) => (
          <Grid
            item
            xs={12}
            md={currentPlan === "skolor" ? 4 : hideBasic ? 6 : 4}
            key={index}
          >
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4, delay: index * 0.1 }}
            >
              <Card
                sx={{
                  height: "100%",
                  background:
                    theme.palette.mode === "dark"
                      ? "rgba(255, 255, 255, 0.05)"
                      : "white",
                  backdropFilter: "blur(10px)",
                  borderRadius: "20px",
                  border: "1px solid rgba(255, 255, 255, 0.1)",
                  boxShadow: "0 20px 40px rgba(0,0,0,0.08)",
                  transition: "all 0.3s ease",
                  transform:
                    !hideBasic && plan.popular ? "scale(1.05)" : "scale(1)",
                  "&:hover": {
                    transform:
                      !hideBasic && plan.popular
                        ? "scale(1.05) translateY(-10px)"
                        : "translateY(-10px)",
                    boxShadow: "0 20px 40px rgba(0,0,0,0.12)",
                  },
                }}
              >
                <CardContent sx={{ p: 4 }}>
                  <Box
                    sx={{
                      display: "inline-block",
                      background: plan.gradient,
                      borderRadius: "12px",
                      px: 2,
                      py: 0.5,
                      mb: 2,
                    }}
                  >
                    <Typography variant="subtitle2" sx={{ color: "white" }}>
                      {plan.title}
                    </Typography>
                  </Box>
                  <Typography variant="h3" sx={{ my: 2, fontWeight: "800" }}>
                    {plan.price}
                    <Typography component="span" variant="body2" sx={{ ml: 1 }}>
                      {plan.period}
                    </Typography>
                  </Typography>
                  <Box sx={{ mb: 4 }}>
                    {plan.features.map((feature, idx) => (
                      <Box
                        key={idx}
                        sx={{ display: "flex", alignItems: "center", mb: 2 }}
                      >
                        <Box
                          sx={{
                            width: 20,
                            height: 20,
                            borderRadius: "50%",
                            backgroundColor: "rgba(123, 104, 238, 0.1)",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            mr: 2,
                          }}
                        >
                          <CheckIcon sx={{ fontSize: 14, color: "#7B68EE" }} />
                        </Box>
                        <Typography variant="body2">{feature}</Typography>
                      </Box>
                    ))}
                  </Box>
                  {strikeThrough && plan.isBasic ? (
                    <Button
                      fullWidth
                      variant={
                        theme.palette.mode === "light" ? "purp" : "contained"
                      }
                      color="primary"
                      disabled
                      sx={{
                        padding: "10px",
                        fontSize: "0.9rem",
                        textDecoration: "line-through",
                        opacity: 0.7,
                        "&.Mui-disabled": {
                          textDecoration: "line-through",
                        },
                      }}
                    >
                      {plan.buttonText}
                    </Button>
                  ) : plan.buttonText === "Testa helt gratis" ? (
                    <Link to="/app" style={{ textDecoration: "none" }}>
                      <Button
                        fullWidth
                        variant={
                          theme.palette.mode === "light" ? "purp" : "contained"
                        }
                        color="primary"
                        sx={{ padding: "10px", fontSize: "0.9rem" }}
                      >
                        {plan.buttonText}
                      </Button>
                    </Link>
                  ) : (
                    <Button
                      fullWidth
                      variant={
                        theme.palette.mode === "light" ? "purp" : "contained"
                      }
                      color="primary"
                      sx={{ padding: "10px", fontSize: "0.9rem" }}
                      onClick={() => {
                        setSelectedPlan(plan);
                        setOpenModal(true);
                      }}
                    >
                      {plan.buttonText}
                    </Button>
                  )}
                </CardContent>
              </Card>
            </motion.div>
          </Grid>
        ))}
      </Grid>

      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>
          <Typography
            variant="h6"
            component="div"
            sx={{ textAlign: "center", fontWeight: "600", mb: 2 }}
          >
            Intresseanmälan
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ textAlign: "center", fontWeight: "500", fontSize: "1rem" }}
          >
            Vi kommer snart erbjuda smidiga betalningslösningar, vill du få ett
            mail när det är klart?
          </Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="E-postadress"
            type="email"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="print"
            onClick={() => setOpenModal(false)}
            color="primary"
          >
            Nej, tack
          </Button>
          <Button
            onClick={handleSubmit}
            variant="purp"
            disabled={isSubmitting}
            endIcon={
              isSubmitting ? (
                <CircularProgress size={16} color="inherit" />
              ) : null
            }
          >
            Ja, tack!
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default PricesSection;
