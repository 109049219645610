import React, { createContext, useState, useContext } from "react";
import { Snackbar, Alert, Slide } from "@mui/material";

const SnackbarHandler = createContext();

function SlideTransition(props) {
  return <Slide {...props} direction="down" />;
}

export const SnackbarProvider = ({ children }) => {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "info",
    duration: 2300,
  });

  const showSnackbar = (message, severity = "info", duration = 2300) => {
    const formattedMessage = message.replace(/\n/g, "<br />");
    setSnackbar({ open: true, message: formattedMessage, severity, duration });
  };

  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <SnackbarHandler.Provider value={{ showSnackbar }}>
      {children}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={snackbar.duration}
        onClose={closeSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        TransitionComponent={SlideTransition}
      >
        <Alert
          onClose={closeSnackbar}
          severity={snackbar.severity}
          variant="filled"
          sx={{
            width: { xs: "100%", md: "100%" },
            borderRadius: "16px",
            boxShadow: (theme) => `
              0 0 1px ${theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.05)"},
              0 2px 4px ${theme.palette.mode === "dark" ? "rgba(0, 0, 0, 0.6)" : "rgba(0, 0, 0, 0.1)"},
              0 8px 24px ${theme.palette.mode === "dark" ? "rgba(0, 0, 0, 0.8)" : "rgba(0, 0, 0, 0.2)"},
              0 16px 32px ${theme.palette.mode === "dark" ? "rgba(0, 0, 0, 0.7)" : "rgba(0, 0, 0, 0.15)"}
            `,
            display: "flex",
            alignItems: "center",
            padding: "6px 16px",
            "& .MuiAlert-icon": {
              marginRight: "12px",
              padding: 0,
              display: "flex",
              alignItems: "center",
            },
            "& .MuiAlert-message": {
              padding: "8px 0",
              flexGrow: 1,
              marginRight: "34px",
            },
            "& .MuiAlert-action": {
              padding: 0,
              marginRight: "-8px",
              display: "flex",
              alignItems: "center",
            },
            "& .MuiIconButton-root": {
              padding: "8px",
            },
          }}
        >
          <span dangerouslySetInnerHTML={{ __html: snackbar.message }} />
        </Alert>
      </Snackbar>
    </SnackbarHandler.Provider>
  );
};

export const useSnackbar = () => useContext(SnackbarHandler);
