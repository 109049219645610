import React, { useState, useEffect } from "react";
import {
    Grid,
    CssBaseline,
    Avatar,
    Box,
    Menu,
    MenuItem,
    Backdrop,
    IconButton,
    CircularProgress,
    Typography,
    Tooltip,
    useMediaQuery,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { FourSquare, ThreeDot } from "react-loading-indicators";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import SupportWidget from "./SupportWidget";
import { HelmetProvider, Helmet } from "react-helmet-async";

import LiteratureSchool from "./LitteratureSchool";
import VisionViewer from "./VisionViewer";

import Sidebar from "./Sidebar";
import VisionAnalysis from "./VisionAnalysis";
import RolePlayLesson from "./RolePlayLesson";
import PodcastGenerator from "./PodcastGenerator";
import PrefixSuffixLesson from "./PrefixSuffixLesson";
import LessonPlanRevamp from "./LessonPlanRevamp";
import LimitDialog from "./LimitDialog";

import DiaryLesson from "./DiaryLesson";
import Quest from "./Quest";
import EscapeRoom from "./EscapeRoom";
import NewsLesson from "./NewsLesson";
import TimelineBuilderLesson from "./TimelineBuilderLesson";

import AdminUserDashboard from "./AdminUserDashboard";
import LetterLesson from "./LetterLesson";
import Room from "./Room";
import MyOwnQuizzes from "./MyOwnQuizzes";
import SharedLessons from "./SharedLessons";
import RoomDetails from "./RoomDetails";
import JeopardyLesson from "./JeopardyLesson";
import MyLessons from "./MyLessons";

import { ScrollRestoration } from "react-router-dom";
import MyFiles from "./MyFiles";
import MindMapLesson from "./MindMapLesson";
import LessonBank from "./LessonBank";

import MyOwnFlashcards from "./MyOwnFlashcards";

import BasicReadingCompLesson from "./BasicReadingCompLesson";
import DetectiveReadingCompLesson from "./DetectiveReadingCompLesson";
import ComparativeReadingCompLesson from "./ComparativeReadingCompLesson";
import CreativeReadingCompLesson from "./CreativeReadingCompLesson";
import WordCompLesson from "./WordCompLesson";

import ArgumentationLesson from "./ArgumentationLesson";
import { useSnackbar } from "./SnackbarHandler";

import IdiomsLesson from "./IdiomsLesson";
import GrammarLesson from "./GrammarLesson";

import AdminDashboard from "./AdminDashboard";

import ImageLimitDialog from "./ImageLimitDialog";
import RhymesLesson from "./RhymesLesson";

import TongueTwistersLesson from "./TongueTwistersLesson";

import HistoryTeacherView from "./HistoryTeacherView";
import ConversationLesson from "./ConversationLesson";
import HomeworkHelperView from "./HomeworkHelperView";

import FlashcardsView from "./FlashcardsView";
import LessonPlan from "./LessonPlan";
import WritingLesson from "./WritingLesson";
import WeeklyWordsLesson from "./WeeklyWordsLesson";
import LetterBoxLesson from "./LetterBoxLesson";
import BookReader from "./BookReader";
import NarrativeLesson from "./NarrativeLesson";
import Courses from "./Courses";
import SentenceFinisherLesson from "./SentenceFinisherLesson";
import FindWordsLesson from "./FindWordsLesson";
import CoursesPublic from "./CoursesPublic";
import PowerPointGenerator from "./PowerPointGenerator";
import SpanishChat from "./SpanishChat";
import MysticImageLesson from "./MysticImageLesson";
import ClueGenerator from "./ClueGenerator";
import TermsGrid from "./TermsGrid";
import ImageGenerator from "./ImageGenerator";
import ColoringLesson from "./ColoringLesson";
import SharedConversation from "./SharedConversation";
import Essays from "./Essays";
import SettingsDialog from "./SettingsDialog";
import WritingAssistant from "./WritingAssistant";
import TextManager from "./TextManager";
import AskAI from "./AskAI";
import SettingsPopover from "./SettingsPopover"; // Add this import
import FlashcardsCreateView from "./FlashcardsCreateView";
import Logger from "./Logger";
import TypeWriter from "./TypeWriter";
import EssayResult from "./EssayResult";
import DebateView from "./DebateView";
import Rubrics from "./Rubrics";
import Dashboard from "./Dashboard";
import TeacherDetails from "./TeacherDetails";
import QuizView from "./QuizView";
import PublicTeachers from "./PublicTeachers";
import PublicTeachers2 from "./PublicTeachers2";
import PublicQuiz from "./PublicQuiz";
import LessonPackage from "./LessonPackage";
import GrejOfTheDay from "./GrejOfTheDay";
import WordView from "./WordView";
import EssayUpload from "./EssayUpload";

import HistoricPersonLesson from "./HistoricPersonLesson";

import TimelineLesson from "./TimelineLesson";

import LanguageTest from "./LanguageTest";
import FormativeComments from "./FormativeComments";

import HP from "./HP";
import PublicHP from "./PublicHP";

import DetectorView from "./DetectorView";
import EssayWriterView from "./EssayWriterView";
import NotesView from "./NotesView";

import LoginView from "./LoginView";
import CreateAccountView from "./CreateAccountView";

import { useParams } from "react-router-dom";

import LoadingSpinner from "./LoadingSpinner";

import { useAuth } from "./useAuth";

import TeacherChat from "./TeacherChat";
import TeacherDialog from "./TeacherDialog";
import TeacherHandler from "./TeacherHandler";

import { useTheme } from "@mui/material/styles";
import { getStyles } from "../index";

import { GoogleOAuthProvider } from "@react-oauth/google";

const TeacherChatWrapper = (props) => {
    const { teacherId } = useParams();
    return <TeacherChat key={teacherId} {...props} teacherId={teacherId} />;
};

function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

function ExistingApp({ isDark, toggleDarkMode, setOverrideTheme }) {
    const { user, loading, handleGoogleLogin, updateUser, setUser } = useAuth();

    const [anchorEl, setAnchorEl] = useState(null);
    const [googleOneTapInitialized, setGoogleOneTapInitialized] =
        useState(false);
    const location = useLocation(); // Add this import from react-router-dom
    const [dialogOpen, setDialogOpen] = React.useState(false);

    const [imageLimitDialog, setImageLimitDialog] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isImageLimit, setIsImageLimit] = useState(false);
    const [isLessonLimit, setIsLessonLimit] = useState(true);

    const [resetTime, setResetTime] = useState(null);
    const [systemLoading, setSystemLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [shouldRedirect, setShouldRedirect] = useState(false);
    const { showSnackbar } = useSnackbar();
    const theme = useTheme();
    const styles = getStyles(theme);
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const [sidebarOpen, setSidebarOpen] = useState(!isMobile);

    const excludedPaths = [
        "/app/logga-in/",
        "/app/skapa-konto/",
        "/app/logga-in",
        "/app/skapa-konto",

        "/app/bok",
        "/app/lektioner/bygg-tidslinjer",
        "/app/lektioner/escape-room",
        "/app/lektioner/rollkort",
        "/app/lektioner/fiktiva-nyhetsredaktionen",
        "/app/lektioner/fiktiva-dagbocker",
        "/app/lektioner/fiktiva-brev",
        "/app/lektioner/lasforstaelse",
        "/app/lektioner/jeopardy",
        "/app/lektioner/lektionsplan",
        "/app/lektioner/fiktiva-brev",
        "/app/lektioner/historisk-person",
        "/app/lektioner/grej-of-the-day",
        "/app/lektioner/tidslinjer",
        "/app/lektioner/tankekartor",
        "/app/lektioner/konversation",
        "/app/lektioner/litteraturskolan",
        "/app/lektioner/skrivlektion",
        "/app/lektionsbanken",
        "/app/lektioner/fortsatt-berattelsen",
        "/app/lektioner/glosor-i-lador",
        "/app/lektioner/hitta-orden",
        "/app/lektioner/mystiska-bilden",
        "/app/vision",
        /^\/app\/quiz\/[^/]+\/.*$/,
        /^\/app\/delad-konversation\/[^/]+$/,
    ];

    const showSidebar = !excludedPaths.some((path) => {
        if (typeof path === "string") {
            if (path === "/app/lektioner/bygg-tidslinjer") {
                // Check if the pathname matches exactly OR matches the pattern with an ID
                const escapeRoomRegex = new RegExp(`^${path}(?:/[^/]+)?$`);
                if (!escapeRoomRegex.test(location.pathname)) return false;

                // If path matches, check for v=t parameter
                const searchParams = new URLSearchParams(location.search);
                return searchParams.get("v") === "t";
            }
            if (path === "/app/lektioner/escape-room") {
                // Check if the pathname matches exactly OR matches the pattern with an ID
                const escapeRoomRegex = new RegExp(`^${path}(?:/[^/]+)?$`);
                if (!escapeRoomRegex.test(location.pathname)) return false;

                // If path matches, check for v=t parameter
                const searchParams = new URLSearchParams(location.search);
                return searchParams.get("v") === "t";
            }

            if (path === "/app/vision") {
                // Check if the pathname matches exactly OR matches the pattern with an ID
                const escapeRoomRegex = new RegExp(`^${path}(?:/[^/]+)?$`);
                if (!escapeRoomRegex.test(location.pathname)) return false;
                // If path matches, check for v=t parameter
                const searchParams = new URLSearchParams(location.search);
                return searchParams.get("v") === "t";
            }

            if (path === "/app/lektioner/rollkort") {
                // Check if the pathname matches exactly OR matches the pattern with an ID
                const escapeRoomRegex = new RegExp(`^${path}(?:/[^/]+)?$`);
                if (!escapeRoomRegex.test(location.pathname)) return false;

                // If path matches, check for v=t parameter
                const searchParams = new URLSearchParams(location.search);
                return searchParams.get("v") === "t";
            }

            if (path === "/app/lektioner/fiktiva-nyhetsredaktionen") {
                // Check if the pathname matches exactly OR matches the pattern with an ID
                const escapeRoomRegex = new RegExp(`^${path}(?:/[^/]+)?$`);
                if (!escapeRoomRegex.test(location.pathname)) return false;

                // If path matches, check for v=t parameter
                const searchParams = new URLSearchParams(location.search);
                return searchParams.get("v") === "t";
            }

            if (path === "/app/lektioner/fiktiva-dagbocker") {
                // Check if the pathname matches exactly OR matches the pattern with an ID
                const escapeRoomRegex = new RegExp(`^${path}(?:/[^/]+)?$`);
                if (!escapeRoomRegex.test(location.pathname)) return false;

                // If path matches, check for v=t parameter
                const searchParams = new URLSearchParams(location.search);
                return searchParams.get("v") === "t";
            }

            if (path === "/app/lektioner/lasforstaelse") {
                // Check if the pathname matches exactly OR matches the pattern with an ID
                const escapeRoomRegex = new RegExp(`^${path}(?:/[^/]+)?$`);
                if (!escapeRoomRegex.test(location.pathname)) return false;

                // If path matches, check for v=t parameter
                const searchParams = new URLSearchParams(location.search);
                return searchParams.get("v") === "t";
            }

            if (path === "/app/lektioner/jeopardy") {
                // Check if the pathname matches exactly OR matches the pattern with an ID
                const escapeRoomRegex = new RegExp(`^${path}(?:/[^/]+)?$`);
                if (!escapeRoomRegex.test(location.pathname)) return false;

                // If path matches, check for v=t parameter
                const searchParams = new URLSearchParams(location.search);
                return searchParams.get("v") === "t";
            }

            if (path === "/app/lektioner/lektionsplan") {
                // Check if the pathname matches exactly OR matches the pattern with an ID
                const escapeRoomRegex = new RegExp(`^${path}(?:/[^/]+)?$`);
                if (!escapeRoomRegex.test(location.pathname)) return false;

                // If path matches, check for v=t parameter
                const searchParams = new URLSearchParams(location.search);
                return searchParams.get("v") === "t";
            }

            if (path === "/app/lektioner/fiktiva-brev") {
                // Check if the pathname matches exactly OR matches the pattern with an ID
                const escapeRoomRegex = new RegExp(`^${path}(?:/[^/]+)?$`);
                if (!escapeRoomRegex.test(location.pathname)) return false;

                // If path matches, check for v=t parameter
                const searchParams = new URLSearchParams(location.search);
                return searchParams.get("v") === "t";
            }

            if (path === "/app/lektioner/historisk-person") {
                // Check if the pathname matches exactly OR matches the pattern with an ID
                const escapeRoomRegex = new RegExp(`^${path}(?:/[^/]+)?$`);
                if (!escapeRoomRegex.test(location.pathname)) return false;

                // If path matches, check for v=t parameter
                const searchParams = new URLSearchParams(location.search);
                return searchParams.get("v") === "t";
            }

            if (path === "/app/lektioner/grej-of-the-day") {
                // Check if the pathname matches exactly OR matches the pattern with an ID
                const escapeRoomRegex = new RegExp(`^${path}(?:/[^/]+)?$`);
                if (!escapeRoomRegex.test(location.pathname)) return false;

                // If path matches, check for v=t parameter
                const searchParams = new URLSearchParams(location.search);
                return searchParams.get("v") === "t";
            }

            if (path === "/app/lektioner/tidslinjer") {
                // Check if the pathname matches exactly OR matches the pattern with an ID
                const escapeRoomRegex = new RegExp(`^${path}(?:/[^/]+)?$`);
                if (!escapeRoomRegex.test(location.pathname)) return false;

                // If path matches, check for v=t parameter
                const searchParams = new URLSearchParams(location.search);
                return searchParams.get("v") === "t";
            }

            if (path === "/app/lektioner/tankekartor") {
                // Check if the pathname matches exactly OR matches the pattern with an ID
                const escapeRoomRegex = new RegExp(`^${path}(?:/[^/]+)?$`);
                if (!escapeRoomRegex.test(location.pathname)) return false;

                // If path matches, check for v=t parameter
                const searchParams = new URLSearchParams(location.search);
                return searchParams.get("v") === "t";
            }

            if (path === "/app/lektioner/fiktiva-brev") {
                // Check if the pathname matches exactly OR matches the pattern with an ID
                const escapeRoomRegex = new RegExp(`^${path}(?:/[^/]+)?$`);
                if (!escapeRoomRegex.test(location.pathname)) return false;

                // If path matches, check for v=t parameter
                const searchParams = new URLSearchParams(location.search);
                return searchParams.get("v") === "t";
            }

            if (path === "/app/lektioner/konversation") {
                // Check if the pathname matches exactly OR matches the pattern with an ID
                const escapeRoomRegex = new RegExp(`^${path}(?:/[^/]+)?$`);
                if (!escapeRoomRegex.test(location.pathname)) return false;

                // If path matches, check for v=t parameter
                const searchParams = new URLSearchParams(location.search);
                return searchParams.get("v") === "t";
            }

            if (path === "/app/lektioner/litteraturskolan") {
                // Check if the pathname matches exactly OR matches the pattern with an ID
                const escapeRoomRegex = new RegExp(`^${path}(?:/[^/]+)?$`);
                if (!escapeRoomRegex.test(location.pathname)) return false;

                // If path matches, check for v=t parameter
                const searchParams = new URLSearchParams(location.search);
                return searchParams.get("v") === "t";
            }

            if (path === "/app/lektioner/skrivlektion") {
                // Check if the pathname matches exactly OR matches the pattern with an ID
                const escapeRoomRegex = new RegExp(`^${path}(?:/[^/]+)?$`);
                if (!escapeRoomRegex.test(location.pathname)) return false;

                // If path matches, check for v=t parameter
                const searchParams = new URLSearchParams(location.search);
                return searchParams.get("v") === "t";
            }

            if (path === "/app/lektioner/fortsatt-berattelsen") {
                // Check if the pathname matches exactly OR matches the pattern with an ID
                const escapeRoomRegex = new RegExp(`^${path}(?:/[^/]+)?$`);
                if (!escapeRoomRegex.test(location.pathname)) return false;

                // If path matches, check for v=t parameter
                const searchParams = new URLSearchParams(location.search);
                return searchParams.get("v") === "t";
            }

            if (path === "/app/lektioner/glosor-i-lador") {
                // Check if the pathname matches exactly OR matches the pattern with an ID
                const escapeRoomRegex = new RegExp(`^${path}(?:/[^/]+)?$`);
                if (!escapeRoomRegex.test(location.pathname)) return false;

                // If path matches, check for v=t parameter
                const searchParams = new URLSearchParams(location.search);
                return searchParams.get("v") === "t";
            }

            if (path === "/app/lektioner/hitta-orden") {
                // Check if the pathname matches exactly OR matches the pattern with an ID
                const escapeRoomRegex = new RegExp(`^${path}(?:/[^/]+)?$`);
                if (!escapeRoomRegex.test(location.pathname)) return false;

                // If path matches, check for v=t parameter
                const searchParams = new URLSearchParams(location.search);
                return searchParams.get("v") === "t";
            }

            if (path === "/app/lektioner/mystiska-bilden") {
                // Check if the pathname matches exactly OR matches the pattern with an ID
                const escapeRoomRegex = new RegExp(`^${path}(?:/[^/]+)?$`);
                if (!escapeRoomRegex.test(location.pathname)) return false;

                // If path matches, check for v=t parameter
                const searchParams = new URLSearchParams(location.search);
                return searchParams.get("v") === "t";
            }

            return location.pathname === path;
        }
        // If it's a regex pattern
        return path.test(location.pathname);
    });
    // const excludedPaths = [
    //     "/app/logga-in/",
    //     "/app/skapa-konto/",
    //     "/app/logga-in",
    //     "/app/skapa-konto",
    //     "/app/bok",
    //     "/app/lektioner/bygg-tidslinjer?v=t",
    //     /^\/app\/quiz\/[^/]+\/.*$/,
    // ];
    // const excludedPathsForNoLeftPadding = [
    //     "/app/lektionsbanken/",
    //     "/app/lektionsbanken",
    // ];
    // const showSidebar = !excludedPaths.some((path) => {
    //     if (typeof path === "string") {
    //         return location.pathname === path;
    //     }
    //     // If it's a regex pattern
    //     return path.test(location.pathname);
    // });

    const isAllakando =
        new URLSearchParams(window.location.search).has("userData") ||
        user.preferredTheme === "allakando";

    useEffect(() => {
        Logger.log("ExistingApp user changed:", JSON.stringify(user, null, 2));
    }, [user]);

    useEffect(() => {
        setSidebarOpen(!isMobile);
    }, [isMobile]);

    const handleMobileWarning = () => {
        const hasShownMobileWarning = localStorage.getItem(
            "hasShownMobileWarning",
        );

        if (isMobile && !hasShownMobileWarning && !user?.guest) {
            showSnackbar(
                "Många funktioner är inte anpassade för mobilen.",
                "warning",
            );
            localStorage.setItem("hasShownMobileWarning", "true");
        }
    };

    // Add this useEffect after your other useEffect hooks
    useEffect(() => {
        handleMobileWarning();
    }, [isMobile, user?.guest]); // Re-run if isMobile or guest status changes

    useEffect(() => {
        if (!loading) {
            // Add a small delay to ensure smooth transition
            const timer = setTimeout(() => {
                setSystemLoading(false);
            }, 500);
            return () => clearTimeout(timer);
        }
    }, [loading]);

    useEffect(() => {
        const initializeGoogleOneTap = () => {
            // Check if current path is in excluded paths
            const shouldShowGoogleOneTap = !excludedPaths.includes(
                location.pathname,
            );

            if (
                !loading &&
                user.guest &&
                !googleOneTapInitialized &&
                window.google?.accounts?.id &&
                !sessionStorage.getItem("ssssssssss") &&
                shouldShowGoogleOneTap
            ) {
                window.google.accounts.id.initialize({
                    client_id:
                        "981080802387-cplabtnr7806g0dmduu9hkms8o2eus7j.apps.googleusercontent.com",
                    callback: handleGoogleLogin,
                    auto_select: true,
                    use_fedcm_for_prompt: true,
                });
                setTimeout(() => {
                    window.google.accounts.id.prompt();
                }, 100);
                setGoogleOneTapInitialized(true);
            }
        };

        if (!loading) {
            initializeGoogleOneTap();
        }

        return () => {
            window.google?.accounts?.id?.cancel();
        };
    }, [
        user.guest,
        handleGoogleLogin,
        loading,
        googleOneTapInitialized,
        location.pathname,
    ]); // Added location.pathname to dependencies

    // useEffect(() => {
    //     const initializeGoogleOneTap = () => {
    //         if (
    //             !loading &&
    //             user.guest &&
    //             !googleOneTapInitialized &&
    //             window.google?.accounts?.id &&
    //             !sessionStorage.getItem('ssssssssss')
    //         ) {
    //             window.google.accounts.id.initialize({
    //                 client_id:
    //                     '981080802387-cplabtnr7806g0dmduu9hkms8o2eus7j.apps.googleusercontent.com',
    //                 callback: handleGoogleLogin,
    //                 auto_select: true,
    //                 use_fedcm_for_prompt: true,
    //             });

    //             setTimeout(() => {
    //                 window.google.accounts.id.prompt();
    //             }, 100);
    //             setGoogleOneTapInitialized(true);
    //         }
    //     };

    //     if (!loading) {
    //         initializeGoogleOneTap();
    //     }

    //     return () => {
    //         window.google?.accounts?.id?.cancel();
    //     };
    // }, [user.guest, handleGoogleLogin, loading, googleOneTapInitialized]);

    // useEffect(() => {
    //     const initializeGoogleOneTap = () => {
    //         if (
    //             !loading &&
    //             user.guest &&
    //             !googleOneTapInitialized &&
    //             window.google &&
    //             window.google.accounts &&
    //             window.google.accounts.id
    //         ) {
    //             window.google.accounts.id.initialize({
    //                 client_id:
    //                     '981080802387-cplabtnr7806g0dmduu9hkms8o2eus7j.apps.googleusercontent.com',
    //                 callback: handleGoogleLogin,
    //                 auto_select: true,
    //                 use_fedcm_for_prompt: true,
    //             });

    //             setTimeout(() => {
    //                 window.google.accounts.id.prompt();
    //             }, 100);
    //             setGoogleOneTapInitialized(true);
    //         }
    //     };

    //     // Only run the initialization when loading is false (user state is determined)
    //     if (!loading) {
    //         initializeGoogleOneTap();
    //     }

    //     return () => {
    //         if (window.google && window.google.accounts && window.google.accounts.id) {
    //             window.google.accounts.id.cancel();
    //         }
    //     };
    // }, [user.guest, handleGoogleLogin, loading, googleOneTapInitialized]);

    const handleSettingsClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    const handleLogout = async () => {
        // Implement logout logic here
    };
    const toggleSidebar = () => {
        setSidebarOpen((prevState) => !prevState);
    };

    const toggleDarkModish = () => {
        Logger.log(
            "toggleDarkModish user.preferredTheme: ",
            user.preferredTheme,
        );
        toggleDarkMode();
    };

    const goToLoginPage = () => {
        window.location.href = "/login";
    };

    useEffect(() => {
        const handleImageError = (event) => {
            if (event.detail?.type === "IMAGE_LIMIT_ERROR") {
                setResetTime(event.detail.resetTime);
                setIsImageLimit(true);
                setIsDialogOpen(true);
            }
        };

        const handleLessonError = (event) => {
            if (event.detail?.type === "LESSON_LIMIT_ERROR") {
                setResetTime(event.detail.resetTime);
                setIsLessonLimit(true);
                setIsDialogOpen(true);
            }
        };

        window.addEventListener("imageError", handleImageError);
        window.addEventListener("lessonError", handleLessonError);

        return () => {
            window.removeEventListener("imageError", handleImageError);
            window.removeEventListener("lessonError", handleLessonError);
        };
    }, []);

    const lessonRoutes = {
        litteraturskolan: {
            component: LiteratureSchool,
            title: "Litteraturskolan | Studera.AI",
            description: "X",
        },

        "historisk-person": {
            component: HistoricPersonLesson,
            title: "Historisk person | Studera.AI",
            description: "X",
        },
        "bygg-tidslinjer": {
            component: TimelineBuilderLesson,
            title: "Bygg tidslinjer | Studera.AI",
            description: "X",
        },
        lektionsplan: {
            component: LessonPlanRevamp,
            title: "Generer lektionsplan | Studera.AI",
            description: "X",
        },
        rollkort: {
            component: RolePlayLesson,
            title: "Rollkort | Studera.AI",
            description: "X",
        },
        "fiktiva-brev": {
            component: LetterLesson,
            title: "Fiktiva brev | Studera.AI",
            description: "X",
        },
        "fiktiva-dagbocker": {
            component: DiaryLesson,
            title: "Dagbok | Studera.AI",
            description: "X",
        },

        "escape-room": {
            component: EscapeRoom,
            title: "Escape Room | Studera.AI",
            description: "X",
        },

        quest: {
            component: Quest,
            title: "Escape Room | Studera.AI",
            description: "Skapa Jeopardy-lektioner med AI.",
        },

        "fiktiva-nyhetsredaktionen": {
            component: NewsLesson,
            title: "Fiktiva nyhetsredaktionen | Studera.AI",
            description: "Skapa Nyhetslektioner med AI.",
        },

        jeopardy: {
            component: JeopardyLesson,
            title: "Jeopardy | Studera.AI",
            description: "Skapa Jeopardy-lektioner med AI.",
        },
        tidslinjer: {
            component: TimelineLesson,
            title: "Tidslinjer | Studera.AI",
            description: "Skapa tidslinjer med AI.",
        },
        tankekartor: {
            component: MindMapLesson,
            title: "Tankekartor | Studera.AI",
            description: "Skapa tankekartor-lektioner med AI.",
        },
        tungvrickare: {
            component: TongueTwistersLesson,
            title: "Tungvrickare | Studera.AI",
            description:
                "Skapa tungvrickare-lektioner med AI, som t.ex. sex laxar i en laxask.",
        },
        rimverkstaden: {
            component: RhymesLesson,
            title: "Rimverkstaden | Studera.AI",
            description: "Skapa rim-lektioner med AI.",
        },
        ordforstaelse: {
            component: WordCompLesson,
            title: "Ordförståelse — Läsförståelse | Studera.AI",
            description:
                "Skapa läsförståelse-lektioner med fokus på ordförståelse med AI.",
        },
        "kreativ-lasforstaelse": {
            component: CreativeReadingCompLesson,
            title: "Kreativ läsförståelse | Studera.AI",
            description:
                "Skapa engagerande läsförståelse-lektioner med AI där eleven ska fortsätta skriva på berättelsen för att visa att de förstått den.",
        },
        "jamforande-lasforstaelse": {
            component: ComparativeReadingCompLesson,
            title: "Jämförande läsning — Läsförståelse | Studera.AI",
            description:
                "Skapa engagerande läsförståelse-lektioner med AI där eleven ska förstå skillnader mellan två texter.",
        },
        "detektiv-lasforstaelse": {
            component: DetectiveReadingCompLesson,
            title: "Läsdetektiven — Läsförståelse | Studera.AI",
            description:
                "Skapa engagerande läsförståelse-lektioner med AI där eleven ska försöka lösa ett mordfall som en detektiv.",
        },
        lasforstaelse: {
            component: BasicReadingCompLesson,
            title: "Grundläggande läsförståelse | Studera.AI",
            description: "Skapa grundläggande läsförståelse-lektioner med AI.",
        },
        grammatik: {
            component: GrammarLesson,
            title: "Grammatik | Studera.AI",
            description: "Skapa grammatik-lektioner med AI.",
        },
        "veckans-ord": {
            component: WeeklyWordsLesson,
            title: "Veckans ord | Studera.AI",
            description: "Skapa veckans ord-lektioner med AI.",
        },
        idiom: {
            component: IdiomsLesson,
            title: "Idiom | Studera.AI",
            description:
                "Skapa idiom-lektioner med AI som genererar ordspråk elever kan lära sig.",
        },
        "pa-sparet": {
            component: ClueGenerator,
            title: "På spåret | Studera.AI",
            description: "Skapa på spåret-lektioner med AI.",
        },
        "mystiska-bilden": {
            component: MysticImageLesson,
            title: "Mystiska bilden | Studera.AI",
            description: "Skapa mystiska bilden-lektioner med AI.",
        },
        konversation: {
            component: ConversationLesson,
            title: "Konversation | Studera.AI",
            description: "Skapa konversation-lektioner med AI.",
        },
        "prefix-suffix": {
            component: PrefixSuffixLesson,
            title: "Prefix & suffix | Studera.AI",
            description: "Skapa prefix- och suffix-lektioner med AI.",
        },
        "grej-of-the-day": {
            component: GrejOfTheDay,
            title: "Grej of the Day | Studera.AI",
            description: "Skapa Grej of the Day-lektioner med AI.",
        },
        argumentera: {
            component: ArgumentationLesson,
            title: "Argumentera | Studera.AI",
            description:
                "Skapa debatt-lektioner med argument för och emot med AI.",
        },
        "berattande-text": {
            component: NarrativeLesson,
            title: "Berättande text | Studera.AI",
            description: "Skapa berättande text-lektioner med AI.",
        },
        "fortsatt-berattelsen": {
            component: SentenceFinisherLesson,
            title: "Fortsätt berättelsen | Studera.AI",
            description: "Skapa fortsätt berättelsen-lektioner med AI.",
        },
        malarbilder: {
            component: ColoringLesson,
            title: "Målarbilder | Studera.AI",
            description: "Skapa målarbilder med AI.",
        },
        bokstaver: {
            component: LetterBoxLesson,
            title: "Bokstäver | Studera.AI",
            description: "Skapa bokstavsövningar med AI.",
        },
        skrivlektion: {
            component: WritingLesson,
            title: "Lär dig orden | Studera.AI",
            description: "Skapa skrivlektion med AI.",
        },
        "hitta-orden": {
            component: FindWordsLesson,
            title: "Hitta ordet | Studera.AI",
            description: "Skapa hitta ordet-lektioner med AI.",
        },
        "glosor-i-lador": {
            component: TermsGrid,
            title: "Glosor i lådor | Studera.AI",
            description: "Skapa glosor i lådor-lektioner med AI.",
        },
        lektionspaket: {
            component: LessonPackage,
            title: "Lektionspaket | Studera.AI",
            description: "Skapa lektionspaket med AI.",
        },
    };

    // Generate the routes array
    const generateLessonRoutes = () => {
        return Object.entries(lessonRoutes)
            .map(([path, routeConfig]) => [
                // Base route
                <Route
                    key={`/lektioner/${path}`}
                    path={`/lektioner/${path}`}
                    element={
                        <PageWrapper
                            isOpen={sidebarOpen}
                            component={routeConfig.component}
                            theme={theme}
                            styles={styles}
                            user={user}
                            isDark={isDark}
                            title={routeConfig.title}
                            description={routeConfig.description}
                        />
                    }
                />,
                // Route with ID parameter
                <Route
                    key={`/lektioner/${path}/:lessonId`}
                    path={`/lektioner/${path}/:lessonId`}
                    element={
                        <PageWrapper
                            isOpen={sidebarOpen}
                            component={routeConfig.component}
                            theme={theme}
                            styles={styles}
                            user={user}
                            isDark={isDark}
                            title={routeConfig.title}
                            description={routeConfig.description}
                        />
                    }
                />,
            ])
            .flat();
    };

    return (
        <GoogleOAuthProvider clientId="981080802387-cplabtnr7806g0dmduu9hkms8o2eus7j.apps.googleusercontent.com">
            <HelmetProvider>
                <SupportWidget showSnackbar={showSnackbar} />
                <Backdrop
                    transitionDuration={1000}
                    sx={{
                        color: "#fff",
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 4,
                        bgcolor: "rgba(0, 0, 0, 0.85)",
                        backdropFilter: "blur(8px)",
                    }}
                    open={systemLoading}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: 3,
                        }}
                    >
                        <Box
                            sx={{
                                position: "relative",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                mb: 1,
                            }}
                        >
                            <Box
                                component="img"
                                src={
                                    isAllakando
                                        ? "/img/allakando-logo.svg"
                                        : "/img/AI.png"
                                }
                                alt="AI Logo"
                                sx={{
                                    width: isAllakando ? "190px" : 120,
                                    height: isAllakando ? "auto" : 120,
                                    animation: "pulse 1s infinite ease-in-out",
                                    filter: "drop-shadow(0 0 20px rgba(255, 255, 255, 0.2))",
                                }}
                            />
                        </Box>
                        <Typography
                            variant="h6"
                            sx={{
                                fontWeight: 300,
                                letterSpacing: 1,
                                opacity: 0.9,
                                textAlign: "center",
                                fontSize: "1.55rem",
                                marginBottom: "20px",
                            }}
                        >
                            {user.preferredTheme === "allakando"
                                ? `Välkommen ${user.displayName}`
                                : isAllakando
                                  ? "Förbereder Allakando AI..."
                                  : "Förbereder Studera.AI..."}
                        </Typography>
                        <CircularProgress
                            sx={{ color: isAllakando ? "#96c933" : "#ffffff" }}
                            size="30px"
                        />
                        {/*
                        <ThreeDot
                            color={isAllakando ? '#96c933' : '#441f97'}
                            size='medium'
                            text=''
                            textColor=''
                        />*/}
                    </Box>
                </Backdrop>

                <LimitDialog
                    open={isDialogOpen}
                    onClose={() => {
                        setIsDialogOpen(false);
                        setIsImageLimit(false);
                        setIsLessonLimit(false);
                        setResetTime(null);
                    }}
                    userId={user.uid}
                    isImageLimit={isImageLimit}
                    isLessonLimit={isLessonLimit}
                    resetTime={resetTime}
                    user={user}
                    styles={styles}
                />

                <Grid container component="main" sx={{ ...styles.pageBg }}>
                    <CssBaseline />
                    {showSidebar && (
                        <Sidebar
                            isOpen={sidebarOpen}
                            toggleSidebar={toggleSidebar}
                            isDark={isDark}
                            toggleDarkMode={toggleDarkModish}
                            setOverrideTheme={setOverrideTheme}
                            onAddTeacher={() => setDialogOpen(true)}
                        />
                    )}
                    <TeacherDialog
                        open={dialogOpen}
                        onClose={() => setDialogOpen(false)}
                    />

                    <Grid
                        item
                        xs={12}
                        sx={{
                            marginLeft: {
                                xs: 0,
                                md: showSidebar
                                    ? sidebarOpen
                                        ? "270px"
                                        : "10px"
                                    : 0,
                            },

                            paddingLeft: {
                                xs: "0px",
                                md: showSidebar ? "30px" : "0px",
                            },
                            width: {
                                xs: "100%",
                                md: sidebarOpen ? "calc(100% - 270px)" : "100%",
                            },
                            transition: "margin-left 0.1s, width 0.1s",
                        }}
                    >
                        {loading ? (
                            <LoadingSpinner size="20" />
                        ) : (
                            <>
                                {/*
                                {user && (
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: 10,
                                            right: 16,
                                            zIndex: 999,
                                            display: { xs: 'none', md: 'block' },
                                        }}
                                    >
                                        {user.guest ? (
                                            <IconButton onClick={goToLoginPage}>
                                                <Avatar
                                                    src={user.photoURL}
                                                    alt={user.displayName}
                                                />
                                            </IconButton>
                                        ) : (
                                            <IconButton onClick={handleSettingsClick}>
                                                <Avatar
                                                    src={user.photoURL}
                                                    alt={user.displayName}
                                                    sx={{ width: 30, height: 30 }}
                                                />
                                            </IconButton>
                                        )}
                                    </Box>
                                )}

                                <SettingsPopover
                                    user={user}
                                    isDark={isDark}
                                    openPopover={Boolean(anchorEl)}
                                    anchorEl={anchorEl}
                                    handlePopoverClose={handlePopoverClose}
                                    handleLogout={handleLogout}
                                    openSettings={() => setOpen(true)}
                                />

                                <SettingsDialog
                                    open={open}
                                    onClose={() => setOpen(false)}
                                    onUserUpdate={updateUser}
                                    setOverrideTheme={setOverrideTheme}
                                />
                                */}

                                <Box
                                    sx={{
                                        height: "100%",
                                        position: "relative",
                                        flexBasis: { xs: "100% !important" },
                                    }}
                                >
                                    {shouldRedirect ? (
                                        <Routes>
                                            <Route
                                                path="/skapa-konto"
                                                element={
                                                    <PageWrapper
                                                        component={
                                                            CreateAccountView
                                                        }
                                                        setUser={user}
                                                        title="Skapa konto"
                                                        description="Skapa ett konto på Studera.ai"
                                                    />
                                                }
                                            />
                                            <Route
                                                path="*"
                                                element={
                                                    <Navigate
                                                        to="/skapa-konto"
                                                        replace
                                                    />
                                                }
                                            />
                                        </Routes>
                                    ) : (
                                        <>
                                            <ScrollToTop />
                                            <Routes>
                                                {/* Home route */}
                                                <Route
                                                    path="/"
                                                    element={
                                                        <PageWrapper
                                                            component={
                                                                Dashboard
                                                            }
                                                            user={user}
                                                            setOverrideTheme={
                                                                setOverrideTheme
                                                            }
                                                            onUpdateUser={
                                                                updateUser
                                                            }
                                                            title={
                                                                theme.palette
                                                                    .themeType ===
                                                                "allakando"
                                                                    ? "Allakando AI"
                                                                    : "Studera.AI"
                                                            }
                                                            description="AI-verktyg för lärare, pedagoger, elever, studenter och skolor."
                                                        />
                                                    }
                                                />
                                                {/* Routes with parameters */}
                                                <Route
                                                    path="chat/:teacherId"
                                                    element={
                                                        <PageWrapper
                                                            isOpen={sidebarOpen}
                                                            component={
                                                                TeacherChatWrapper
                                                            }
                                                            user={user}
                                                            isDark={isDark}
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path="/larare/:teacherId"
                                                    element={
                                                        <PageWrapper
                                                            isOpen={sidebarOpen}
                                                            component={
                                                                TeacherHandler
                                                            }
                                                            user={user}
                                                            isDark={isDark}
                                                            title="Hantera lärare"
                                                            description="Chatta med vår AI och få svar på dina frågor."
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path="/larare/:teacherId/:teacherName"
                                                    element={
                                                        <PageWrapper
                                                            isOpen={sidebarOpen}
                                                            component={
                                                                TeacherDetails
                                                            }
                                                            user={user}
                                                            isDark={isDark}
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path="/matris/ny"
                                                    element={
                                                        <PageWrapper
                                                            isOpen={sidebarOpen}
                                                            component={Rubrics}
                                                            user={user}
                                                            isDark={isDark}
                                                            title="Skapa bedömningsmatris | Studera.AI"
                                                            description="Skapa en bedömningsmatris som sedan används för att med AI bedöma den uppsatsen du laddar upp."
                                                        />
                                                    }
                                                />
                                                ImageGenerator
                                                <Route
                                                    path="/generera-bild"
                                                    element={
                                                        <PageWrapper
                                                            isOpen={sidebarOpen}
                                                            component={
                                                                ImageGenerator
                                                            }
                                                            user={user}
                                                            isDark={isDark}
                                                            title="Generera bild | Studera.AI"
                                                            description="Generera bild med AI som du kan använda som pedagogiskt material i klassrummet."
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path="/uppsats/ny"
                                                    element={
                                                        <PageWrapper
                                                            isOpen={sidebarOpen}
                                                            component={
                                                                EssayUpload
                                                            }
                                                            user={user}
                                                            isDark={isDark}
                                                            title="Ladda upp uppsats | Studera.AI"
                                                            description="Ladda upp din uppsats och få den bedömd av vår AI utifrån bedömningsmatriser."
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path="/uppsatser"
                                                    element={
                                                        <PageWrapper
                                                            isOpen={sidebarOpen}
                                                            component={Essays}
                                                            user={user}
                                                            isDark={isDark}
                                                            title="Dina texter/uppsatser | Studera.AI"
                                                            description="Här hittar du de texter/uppdatser du laddat upp för att få betygsatt av AI."
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path="/uppsats/:docId"
                                                    element={
                                                        <PageWrapper
                                                            isOpen={sidebarOpen}
                                                            component={
                                                                EssayResult
                                                            }
                                                            user={user}
                                                            isDark={isDark}
                                                            title="Uppsats | Studera.AI"
                                                            description="X"
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path="/rum/:roomId"
                                                    element={
                                                        <PageWrapper
                                                            isOpen={sidebarOpen}
                                                            component={
                                                                RoomDetails
                                                            }
                                                            user={user}
                                                            styles={styles}
                                                            isDark={isDark}
                                                            title="Rum | Studera.AI"
                                                            description=""
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path="/rum"
                                                    element={
                                                        <PageWrapper
                                                            isOpen={sidebarOpen}
                                                            component={Room}
                                                            user={user}
                                                            isDark={isDark}
                                                            title="Rum | Studera.AI"
                                                            description=""
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path="/rum"
                                                    element={
                                                        <PageWrapper
                                                            isOpen={sidebarOpen}
                                                            component={Room}
                                                            user={user}
                                                            isDark={isDark}
                                                            title="Rum | Studera.AI"
                                                            description=""
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path="/kurser/gratis-kurser"
                                                    element={
                                                        <PageWrapper
                                                            isOpen={sidebarOpen}
                                                            component={
                                                                CoursesPublic
                                                            }
                                                            user={user}
                                                            isDark={isDark}
                                                            title="Gratis kurser | Studera.AI"
                                                            description="Använd de gratis kurser som våra användare skapat för att lära dig mer om valfritt ämne."
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path="/kurser/:courseName"
                                                    element={
                                                        <PageWrapper
                                                            isOpen={sidebarOpen}
                                                            component={Courses}
                                                            user={user}
                                                            isDark={isDark}
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path="/kurser/:courseName/:moduleName"
                                                    element={
                                                        <PageWrapper
                                                            isOpen={sidebarOpen}
                                                            component={Courses}
                                                            user={user}
                                                            isDark={isDark}
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path="/kurser/:courseName/:moduleName/:chapterName"
                                                    element={
                                                        <PageWrapper
                                                            isOpen={sidebarOpen}
                                                            component={Courses}
                                                            user={user}
                                                            isDark={isDark}
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path="/mina-quiz/"
                                                    element={
                                                        <PageWrapper
                                                            isOpen={sidebarOpen}
                                                            component={
                                                                MyOwnQuizzes
                                                            }
                                                            user={user}
                                                            styles={styles}
                                                            theme={theme}
                                                            isDark={isDark}
                                                            title="Mina quiz | Studera.AI"
                                                            description=""
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path="/mina-filer/"
                                                    element={
                                                        <PageWrapper
                                                            isOpen={sidebarOpen}
                                                            component={MyFiles}
                                                            user={user}
                                                            styles={styles}
                                                            theme={theme}
                                                            isDark={isDark}
                                                            title="Mina filer | Studera.AI"
                                                            description=""
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path="/mina-flashcards/"
                                                    element={
                                                        <PageWrapper
                                                            isOpen={sidebarOpen}
                                                            component={
                                                                MyOwnFlashcards
                                                            }
                                                            user={user}
                                                            styles={styles}
                                                            theme={theme}
                                                            isDark={isDark}
                                                            title="Mina flashcards | Studera.AI"
                                                            description=""
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path="/flashcards/:collectionId/:collectionName"
                                                    element={
                                                        <PageWrapper
                                                            isOpen={sidebarOpen}
                                                            component={
                                                                FlashcardsView
                                                            }
                                                            user={user}
                                                            isDark={isDark}
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path="/quiz/:quizId/:urlQuizName"
                                                    element={
                                                        <PageWrapper
                                                            isOpen={sidebarOpen}
                                                            component={QuizView}
                                                            user={user}
                                                            isDark={isDark}
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path="/quiz/:quizId"
                                                    element={
                                                        <PageWrapper
                                                            isOpen={sidebarOpen}
                                                            component={QuizView}
                                                            user={user}
                                                            isDark={isDark}
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path="/hp/:testId?"
                                                    element={
                                                        <PageWrapper
                                                            isOpen={sidebarOpen}
                                                            component={HP}
                                                            user={user}
                                                            isDark={isDark}
                                                            key={
                                                                window.location
                                                                    .pathname
                                                            }
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path="/delad-konversation/:chatId"
                                                    element={
                                                        <SharedConversation />
                                                    }
                                                />
                                                <Route
                                                    path="/shared-conversation"
                                                    element={
                                                        <PageWrapper
                                                            isOpen={sidebarOpen}
                                                            component={
                                                                SharedConversation
                                                            }
                                                            user={user}
                                                            isDark={isDark}
                                                            title=""
                                                            description=""
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path="/larare"
                                                    element={
                                                        <PageWrapper
                                                            isOpen={sidebarOpen}
                                                            component={
                                                                TeacherHandler
                                                            }
                                                            user={user}
                                                            isDark={isDark}
                                                            title="Skapa AI-karaktär | Studera.AI"
                                                            description="Designa din egen AI-karaktär för personlig och interaktiv inlärning om alla tänkbara ämnen och språk."
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path="/hogskoleprov"
                                                    element={
                                                        <PageWrapper
                                                            isOpen={sidebarOpen}
                                                            component={PublicHP}
                                                            user={user}
                                                            isDark={isDark}
                                                            title="Alla högskoleprov — Gamla högskoleprov & AI-genererade | Studera.AI"
                                                            description="Öva på gamla och AI-genererade högskoleprov. Förbered dig effektivt med vår omfattande provbank."
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path="/personal"
                                                    element={
                                                        <PageWrapper
                                                            isOpen={sidebarOpen}
                                                            component={
                                                                PublicTeachers
                                                            }
                                                            user={user}
                                                            isDark={isDark}
                                                            title="AI-lärare, karaktärer och mentorer | Studera.AI"
                                                            description="Utforska vår samling av AI-lärare och mentorer. Få personlig hjälp och vägledning i dina studier."
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path="/spraktest"
                                                    noIndex={true}
                                                    element={
                                                        <PageWrapper
                                                            isOpen={sidebarOpen}
                                                            component={
                                                                LanguageTest
                                                            }
                                                            user={user}
                                                            isDark={isDark}
                                                            title="Språktest | Studera.AI"
                                                            description="Läs och studera smidigt med vår digitala bokreader. Förbättra din läsförståelse med AI-stöd."
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path="/vision/:lessonId"
                                                    element={
                                                        <PageWrapper
                                                            isOpen={sidebarOpen}
                                                            component={
                                                                VisionAnalysis
                                                            }
                                                            user={user}
                                                            styles={styles}
                                                            theme={theme}
                                                            isDark={isDark}
                                                            title="Bildanalys | Studera.AI"
                                                            description=""
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path="/vision"
                                                    element={
                                                        <PageWrapper
                                                            isOpen={sidebarOpen}
                                                            component={
                                                                VisionAnalysis
                                                            }
                                                            user={user}
                                                            styles={styles}
                                                            theme={theme}
                                                            isDark={isDark}
                                                            title="Bildanalys  | Studera.AI"
                                                            description="X."
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path="/bok"
                                                    noIndex={true}
                                                    element={
                                                        <PageWrapper
                                                            isOpen={sidebarOpen}
                                                            component={
                                                                BookReader
                                                            }
                                                            user={user}
                                                            isDark={isDark}
                                                            title="Bokläsare | Studera.AI"
                                                            description="Läs och studera smidigt med vår digitala bokläsare. Förbättra din läsförståelse med AI som stöd."
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path="/kurser"
                                                    noIndex={true}
                                                    element={
                                                        <PageWrapper
                                                            isOpen={sidebarOpen}
                                                            component={Courses}
                                                            user={user}
                                                            isDark={isDark}
                                                            title="Kurser | Studera.AI"
                                                            description="Läs och studera smidigt med vår digitala bokreader. Förbättra din läsförståelse med AI-stöd."
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path="/write"
                                                    noIndex={true}
                                                    element={
                                                        <PageWrapper
                                                            isOpen={sidebarOpen}
                                                            component={
                                                                WritingAssistant
                                                            }
                                                            user={user}
                                                            isDark={isDark}
                                                            title="AI Skrivhjälp | Studera.AI"
                                                            description="Förbättra ditt skrivande med vår AI-drivna skrivassistent. Få hjälp med struktur, grammatik och stil."
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path="/fraga-ai"
                                                    element={
                                                        <PageWrapper
                                                            isOpen={sidebarOpen}
                                                            component={AskAI}
                                                            user={user}
                                                            isDark={isDark}
                                                            title="Fråga AI | Studera.AI"
                                                            description="Chatta med vår avancerade AI för snabb och pålitlig studiehjälp. Fråga AI vad som helst!"
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path="/quiz"
                                                    element={
                                                        <PageWrapper
                                                            isOpen={sidebarOpen}
                                                            component={QuizView}
                                                            user={user}
                                                            isDark={isDark}
                                                            title="Skapa quiz med AI | Studera.AI"
                                                            description="Skapa AI-genererade quiz baserade på ämne, längre text eller Word-dokument. Lär dig medan du har kul!"
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path="/alla-quiz"
                                                    element={
                                                        <PageWrapper
                                                            isOpen={sidebarOpen}
                                                            component={
                                                                PublicQuiz
                                                            }
                                                            user={user}
                                                            isDark={isDark}
                                                            title="Quiz-bibliotek | Studera.AI"
                                                            description="Utforska vårt omfattande bibliotek av AI-genererade quiz inom olika ämnen. Hitta den perfekta utmaningen för dig."
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path="/skrivmaskinen"
                                                    element={
                                                        <PageWrapper
                                                            isOpen={sidebarOpen}
                                                            component={
                                                                TypeWriter
                                                            }
                                                            user={user}
                                                            isDark={isDark}
                                                            title="Generera text med AI | Studera.AI"
                                                            description="Använd vår AI-baserade skrivmaskin för att generera text. Gör skrivprocessen mer levande!"
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path="/debatt"
                                                    element={
                                                        <PageWrapper
                                                            isOpen={sidebarOpen}
                                                            component={
                                                                DebateView
                                                            }
                                                            user={user}
                                                            isDark={isDark}
                                                            title="AI Debattforum | Studera.AI"
                                                            description="Öva dina debattfärdigheter och kritiskt tänkande i vårt AI-drivna debattforum."
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path="/hoodUsers"
                                                    element={
                                                        <PageWrapper
                                                            isOpen={sidebarOpen}
                                                            component={
                                                                AdminUserDashboard
                                                            }
                                                            noIndex={true}
                                                            theme={theme}
                                                            styles={styles}
                                                            user={user}
                                                            isDark={isDark}
                                                            title="Hood Users | Studera.AI"
                                                            description="Hood."
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path="/hood"
                                                    element={
                                                        <PageWrapper
                                                            isOpen={sidebarOpen}
                                                            component={
                                                                AdminDashboard
                                                            }
                                                            noIndex={true}
                                                            theme={theme}
                                                            styles={styles}
                                                            user={user}
                                                            isDark={isDark}
                                                            title="Hood | Studera.AI"
                                                            description="Hood."
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path="/pod"
                                                    element={
                                                        <PageWrapper
                                                            isOpen={sidebarOpen}
                                                            component={
                                                                PodcastGenerator
                                                            }
                                                            theme={theme}
                                                            styles={styles}
                                                            noIndex={true}
                                                            user={user}
                                                            isDark={isDark}
                                                            title="Pod | Studera.AI"
                                                            description="Pod"
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path="/delade-lektioner"
                                                    element={
                                                        <PageWrapper
                                                            isOpen={sidebarOpen}
                                                            component={
                                                                SharedLessons
                                                            }
                                                            theme={theme}
                                                            styles={styles}
                                                            user={user}
                                                            isDark={isDark}
                                                            title="Delade lektioner | Studera.AI"
                                                            description=""
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path="/mina-lektioner"
                                                    element={
                                                        <PageWrapper
                                                            isOpen={sidebarOpen}
                                                            component={
                                                                MyLessons
                                                            }
                                                            theme={theme}
                                                            styles={styles}
                                                            user={user}
                                                            isDark={isDark}
                                                            title="Mina lektioner | Studera.AI"
                                                            description="Här ser du alla lektioner som du har skapat med Studera.AI."
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path="/lektionsbanken"
                                                    element={
                                                        <PageWrapper
                                                            isOpen={sidebarOpen}
                                                            component={
                                                                LessonBank
                                                            }
                                                            theme={theme}
                                                            styles={styles}
                                                            noIndex={true}
                                                            user={user}
                                                            isDark={isDark}
                                                            title="Lektionsbanken | Studera.AI"
                                                            description="Ta del av alla lektioner som våra lärare har skapat i vår lektionsbank - helt gratis!"
                                                        />
                                                    }
                                                />
                                                {generateLessonRoutes()}
                                                <Route
                                                    path="/generera-powerpoint"
                                                    element={
                                                        <PageWrapper
                                                            isOpen={sidebarOpen}
                                                            component={
                                                                PowerPointGenerator
                                                            }
                                                            theme={theme}
                                                            styles={styles}
                                                            user={user}
                                                            isDark={isDark}
                                                            title="Generera PowerPoint | Studera.AI"
                                                            description="Generera PowerPoint med AI. Snygga presentationer på nolltid."
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path="/generera-powerpoint/:lessonId"
                                                    element={
                                                        <PageWrapper
                                                            isOpen={sidebarOpen}
                                                            component={
                                                                PowerPointGenerator
                                                            }
                                                            theme={theme}
                                                            styles={styles}
                                                            user={user}
                                                            isDark={isDark}
                                                            title="Generera PowerPoint | Studera.AI"
                                                            description=""
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path="/bedomning/formativ-bedomning"
                                                    element={
                                                        <PageWrapper
                                                            isOpen={sidebarOpen}
                                                            component={
                                                                FormativeComments
                                                            }
                                                            theme={theme}
                                                            styles={styles}
                                                            user={user}
                                                            isDark={isDark}
                                                            title="Formativ bedömning | Studera.AI"
                                                            description="Skapa formativa bedömningar med AI."
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path="/textlabbet"
                                                    element={
                                                        <PageWrapper
                                                            isOpen={sidebarOpen}
                                                            component={
                                                                TextManager
                                                            }
                                                            user={user}
                                                            isDark={isDark}
                                                            title="Textlabbet - Textverktyg | Studera.AI"
                                                            description="Förbättra dina texter med 13 kraftfulla AI-verktyg. Perfekt för studenter, akademiker och professionella skribenter."
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path="/ai-detektor"
                                                    element={
                                                        <PageWrapper
                                                            isOpen={sidebarOpen}
                                                            component={
                                                                DetectorView
                                                            }
                                                            user={user}
                                                            isDark={isDark}
                                                            title="AI-Detektor | Studera.AI"
                                                            description="Analysera och identifiera AI-genererad text på svenska med vår avancerade AI-detektor."
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path="/sparat"
                                                    element={
                                                        <PageWrapper
                                                            isOpen={sidebarOpen}
                                                            component={
                                                                NotesView
                                                            }
                                                            user={user}
                                                            isDark={isDark}
                                                            title="Dina sparade anteckningar | Studera.AI"
                                                            description="Hantera och organisera dina studiematerial effektivt med vårt smarta anteckningssystem. Allt du sparar på sidan hamnar här."
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path="/laxhjalp"
                                                    noIndex={true}
                                                    element={
                                                        <PageWrapper
                                                            isOpen={sidebarOpen}
                                                            component={
                                                                HomeworkHelperView
                                                            }
                                                            user={user}
                                                            isDark={isDark}
                                                            title="AI Läxhjälp | Studera.AI"
                                                            description="Få personlig hjälp med dina läxor från vår AI-assistent. Förbättra dina studieresultat."
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path="/flashcards"
                                                    element={
                                                        <PageWrapper
                                                            isOpen={sidebarOpen}
                                                            component={
                                                                FlashcardsView
                                                            }
                                                            user={user}
                                                            isDark={isDark}
                                                            title="Skapa flashcards med AI | Studera.AI"
                                                            description="Skapa och använd AI-genererade flashcards för effektiv memorering och repetition av studiematerial."
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path="/skapa-flashcards"
                                                    element={
                                                        <PageWrapper
                                                            isOpen={sidebarOpen}
                                                            component={
                                                                FlashcardsCreateView
                                                            }
                                                            user={user}
                                                            isDark={isDark}
                                                            title="Skapa flashcards med AI  | Studera.AI"
                                                            description="kapa och använd AI-genererade flashcards för effektiv memorering och repetition av studiematerial."
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path="/hogskoleprovet-verbal-del"
                                                    noIndex={true}
                                                    element={
                                                        <PageWrapper
                                                            isOpen={sidebarOpen}
                                                            component={WordView}
                                                            user={user}
                                                            isDark={isDark}
                                                            title="Högskoleprovet Verbal Del | Studera.AI"
                                                            description="Träna på den verbala delen av högskoleprovet. Förbättra ditt ordförråd och din läsförståelse."
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path="/skriv-uppsats"
                                                    noIndex={true}
                                                    element={
                                                        <PageWrapper
                                                            isOpen={sidebarOpen}
                                                            component={
                                                                EssayWriterView
                                                            }
                                                            user={user}
                                                            isDark={isDark}
                                                            title="AI Uppsatshjälp | Studera.AI"
                                                            description="Få hjälp att strukturera och skriva bättre uppsatser med vår AI-drivna uppsatsassistent."
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path="/logga-in"
                                                    element={
                                                        <PageWrapper
                                                            component={
                                                                LoginView
                                                            }
                                                            setUser={user}
                                                            title="Logga in på Studera.AI"
                                                            description="Logga in för att få tillgång till alla Studera.AI:s funktioner och personlig inlärning. Det är helt gratis."
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path="/skapa-konto"
                                                    element={
                                                        <PageWrapper
                                                            component={
                                                                CreateAccountView
                                                            }
                                                            setUser={user}
                                                            title="Skapa konto på Studera.AI"
                                                            description="Skapa konto för att få tillgång till alla Studera.AI:s funktioner och personlig inlärning. Det är helt gratis."
                                                        />
                                                    }
                                                />
                                                {/* Catch-all route for undefined paths */}
                                                <Route
                                                    path="*"
                                                    element={
                                                        <Navigate to="/app" />
                                                    }
                                                />
                                            </Routes>
                                        </>
                                    )}
                                </Box>
                            </>
                        )}
                    </Grid>
                </Grid>
            </HelmetProvider>
        </GoogleOAuthProvider>
    );
}

const PageWrapper = ({
    component: Component,
    user,
    isOpen,
    title,
    description,
    isDark,
    theme,
    styles,
    noIndex,
    onUpdateUser,
    setOverrideTheme,
}) => {
    const params = useParams();

    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                {noIndex && <meta name="robots" content="noindex" />}
            </Helmet>
            <Component
                user={user}
                isOpen={isOpen}
                isDark={isDark}
                theme={theme}
                onUpdateUser={onUpdateUser}
                setOverrideTheme={setOverrideTheme}
                styles={styles}
                {...params}
            />
        </>
    );
};

export default ExistingApp;
