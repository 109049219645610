import React, { useState, useEffect, useRef } from "react";
import { db } from "./firebase";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import FlashcardItem from "./flashcards/FlashcardItem";
import ProgressBar from "./ProgressBar";
import GenerateIcon from "@mui/icons-material/AutoAwesome";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import AddIcon from "@mui/icons-material/Add";
import { ThemeSelectorFlow } from "./ThemeSelectorFlow";
import { trackLesson } from "./functions";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import { Alert, Snackbar, Collapse, Grid, Tooltip, Fade } from "@mui/material";
import { Link as LinkIcon } from "@mui/icons-material";
import axios from "axios";
import FlashcardImageUpload from "./flashcards/FlashcardImageUpload";
import SettingsIcon from "@mui/icons-material/Settings";

import InfoBox from "./InfoBox";

import LanguageSelector from "./LanguageSelector";
import ShareDialog from "./ShareDialog";
import Logger from "./Logger";
import {
  useParams,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { East as ArrowIcon } from "@mui/icons-material";
import ShareIcon from "@mui/icons-material/Share";

import ViewAgendaIcon from "@mui/icons-material/ViewAgenda";

import AdditionalFlashFields from "./AdditionalFlashFields";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { getStyles } from "../index";

import { callApi } from "./api";
import LoadingSpinner from "./LoadingSpinner";
import { useSnackbar } from "./SnackbarHandler";

import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import LoadingBackdrop from "./LoadingBackdrop";
import { sanitizeInput } from "./functions";

import MyFlashcards from "./flashcards/MyFlashcards";

import { getThemes } from "./additionalThemes/Flashcards";

const FlashcardsCreateView = ({
  user,
  onCollectionCreated,
  isDark,
  startTraining,
}) => {
  const [collectionName, setCollectionName] = useState("");
  const [flashcards, setFlashcards] = useState([
    {
      front: "",
      back: "",
      correct: "false",
      imageUrlFront: "",
      imageUrlBack: "",
    },
  ]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const [showAIInput, setShowAIInput] = useState(true); // State to toggle AI input box
  const [aiInput, setAIInput] = useState(""); // State to store AI input
  const [inputType, setInputType] = useState("subject"); // State to manage which radio button is selected
  const [shareDialogOpenFriends, setShareDialogOpenFriends] = useState(false);
  const [searchParams] = useSearchParams();
  const [error, setError] = useState(null);
  const [isPublic, setIsPublic] = useState(true);
  const [viewIsLoading, setViewIsLoading] = useState(true);

  const bookContentRef = useRef("");
  const [myFlashcardsReloadTrigger, setMyFlashcardsReloadTrigger] = useState(0);

  const [selectedModel, setSelectedModel] = useState("Studera.AI");

  const selectedSuggestionRef = useRef("");
  const [progress, setProgress] = useState(0);

  const [isSharing, setIsSharing] = useState(false);

  const [visibility, setVisibility] = useState("public");
  const collectionNameRef = useRef(null);
  const theme = useTheme();
  const styles = getStyles(theme);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [numFlashcards, setNumFlashcards] = useState(10);
  const [category, setCategory] = useState("Välj kategori");
  const [language, setLanguage] = useState("svenska");
  const [difficulty, setDifficulty] = useState("Medel");
  const [aiInstructions, setAiInstructions] = useState("");

  const [loadingSaving, setLoadingSaving] = useState(false);

  const { showSnackbar } = useSnackbar();
  const urlContentRef = useRef("");
  const subjectInputRef = useRef(null);

  const [renderedFlashcards, setRenderedFlashcards] = useState([]);
  const [renderInterval, setRenderInterval] = useState(null);

  const location = useLocation();

  const [customTheme, setCustomTheme] = useState("");
  const [lessonTheme, setLessonTheme] = useState("");
  const [predefinedTheme, setPredefinedTheme] = useState(null);

  const transcriptRef = useRef("");
  const uploadedFileRef = useRef("");

  const wikipediaContentRef = useRef("");
  const randomizedContentRef = useRef("");
  const coursePlanContentRef = useRef("");

  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const subject = searchParams.get("subject") || null;
  const additionalThemes = getThemes(subject);

  // useEffect(() => {
  //   if (flashcards.length > 1) {
  //     setTimeout(() => {
  //       const addMore = document.querySelector(".addMore");
  //       if (addMore) {
  //         addMore.scrollIntoView({
  //           behavior: "smooth",
  //           block: "end",
  //         });
  //       }
  //     }, 500);
  //   }
  // }, [flashcards]);

  useEffect(() => {
    return () => {
      if (renderInterval) {
        clearInterval(renderInterval);
      }
    };
  }, [renderInterval]);

  useEffect(() => {
    setViewIsLoading(false);
  }, []);

  if (viewIsLoading) {
    return <LoadingSpinner />;
  }

  const handleShare = async ({ roomId, studentIds, comment, fileUrl }) => {
    setIsSharing(true);

    await new Promise((resolve) => setTimeout(resolve, 1500));
    // try {
    //   // Create a document for each student
    //   const sharePromises = studentIds.map((studentId) => {
    //     const documentData = {
    //       sharedTo: studentId,
    //       comment: comment,
    //       fileUrl: fileUrl,
    //       shared: true,
    //       userId: user.uid,
    //       collectionName: collectionName,
    //       flashcards: flashcards.map((card) => ({
    //         front: sanitizeInput(card.front),
    //         back: sanitizeInput(card.back),
    //         correct: card.correct,
    //         imageUrlFront: card.imageUrlFront || "",
    //         imageUrlBack: card.imageUrlBack || "",
    //       })),
    //       isPublic,
    //       createdAt: Date.now(),
    //       numFlashcards,
    //       category: category,
    //       language: language,
    //       difficulty: difficulty,
    //       aiInstructions: aiInstructions,
    //       selectedModel: selectedModel,
    //       lastTrainingSession: null,
    //       upvotes: 0,
    //       downvotes: 0,
    //       userVotes: {},
    //     };

    //     Logger.log(
    //       "Adding document for student",
    //       studentId,
    //       ":",
    //       JSON.stringify(documentData, null, 2),
    //     );

    //     return addDoc(collection(db, "flashcardCollections"), documentData);
    //   });

    //   await Promise.all(sharePromises);

    //   showSnackbar(`Du har nu delat flashcards med eleverna!`, "success");
    //   setCollectionName("");
    //   setFlashcards([
    //     {
    //       front: "",
    //       back: "",
    //       correct: "false",
    //       imageUrlFront: "",
    //       imageUrlBack: "",
    //     },
    //   ]);
    // }
    try {
      // Create a document for each student
      const sharePromises = studentIds.map(async (studentId) => {
        const documentData = {
          sharedTo: studentId,
          comment: comment,
          fileUrl: fileUrl,
          shared: true,
          userId: user.uid,
          collectionName: collectionName,
          flashcards: flashcards.map((card) => ({
            front: sanitizeInput(card.front),
            back: sanitizeInput(card.back),
            correct: card.correct,
            imageUrlFront: card.imageUrlFront || "",
            imageUrlBack: card.imageUrlBack || "",
          })),
          isPublic,
          createdAt: Date.now(),
          numFlashcards,
          category: category,
          language: language,
          difficulty: difficulty,
          aiInstructions: aiInstructions,
          selectedModel: selectedModel,
          lastTrainingSession: null,
          upvotes: 0,
          downvotes: 0,
          userVotes: {},
        };

        // Create the flashcard collection document and get its ID
        const flashcardDoc = await addDoc(
          collection(db, "flashcardCollections"),
          documentData,
        );
        Logger.log(
          "Adding document for student",
          studentId,
          ":",
          JSON.stringify(documentData, null, 2),
        );

        // Create notification with the flashcard collection doc ID
        await addDoc(collection(db, "notifications"), {
          type: "flashcards_shared",
          recipientId: studentId,
          senderId: user.uid,
          read: false,
          createdAt: serverTimestamp(),
          flashcardData: {
            collectionId: flashcardDoc.id,
            collectionName: documentData.collectionName,
            fileUrl: documentData.fileUrl,
            comment: documentData.comment,
            category: documentData.category,
            difficulty: documentData.difficulty,
            numFlashcards: documentData.numFlashcards,
          },
        });

        return flashcardDoc;
      });

      await Promise.all(sharePromises);
      showSnackbar(`Du har nu delat flashcards med eleverna!`, "success");
      setCollectionName("");
      setFlashcards([
        {
          front: "",
          back: "",
          correct: "false",
          imageUrlFront: "",
          imageUrlBack: "",
        },
      ]);
    } catch (error) {
      showSnackbar("Fel vid delande av flashcards: " + error.message, "error");
    } finally {
      setIsSharing(false);
    }
  };

  const handleSaveFlashcards = async () => {
    if (!collectionName.trim()) {
      showSnackbar("Samlingens namn får inte vara tomt.", "info");
      collectionNameRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      return;
    }
    if (
      flashcards.some((card) => !card?.front?.trim() || !card?.back?.trim())
    ) {
      showSnackbar(
        "Inga fält för framsida eller baksida får vara tomma.",
        "info",
      );
      return;
    }

    setLoadingSaving(true);
    let userId = user.uid;

    try {
      // Sanitize the collection name and flashcards
      const sanitizedCollectionName = sanitizeInput(collectionName);
      const sanitizedFlashcards = flashcards.map((card) => ({
        front: sanitizeInput(card.front),
        back: sanitizeInput(card.back),
        correct: card.correct,
        imageUrlFront: card.imageUrlFront || "",
        imageUrlBack: card.imageUrlBack || "",
      }));

      // Sanitize other fields
      const sanitizedCategory = sanitizeInput(category);
      const sanitizedLanguage = sanitizeInput(language);
      const sanitizedDifficulty = sanitizeInput(difficulty);
      const sanitizedAiInstructions = sanitizeInput(aiInstructions);
      const sanitizedSelectedModel = sanitizeInput(selectedModel);

      const collectionRef = await addDoc(
        collection(db, "flashcardCollections"),
        {
          userId,
          collectionName: sanitizedCollectionName,
          flashcards: sanitizedFlashcards,
          isPublic,
          createdAt: Date.now(),
          numFlashcards,
          category: sanitizedCategory,
          language: sanitizedLanguage,
          difficulty: sanitizedDifficulty,
          aiInstructions: sanitizedAiInstructions,
          selectedModel: sanitizedSelectedModel,
          lastTrainingSession: null,
          upvotes: 0,
          downvotes: 0,
          userVotes: {},
        },
      );

      showSnackbar(`Samlingen har sparats framgångsrikt!`, "success");
      setCollectionName("");
      setFlashcards([
        {
          front: "",
          back: "",
          correct: "false",
          imageUrlFront: "",
          imageUrlBack: "",
        },
      ]);

      setMyFlashcardsReloadTrigger((prev) => prev + 1);
    } catch (error) {
      showSnackbar("Fel vid sparande av samling: " + error.message, "error");
    } finally {
      setLoadingSaving(false);
    }
  };

  const handlePublicChange = (event) => {
    const newVisibility = event.target.checked ? "public" : "private";
    setVisibility(newVisibility);
    setIsPublic(newVisibility === "public");
  };

  const handleCollectionNameChange = (e) => {
    setCollectionName(e.target.value);
  };

  const handleFrontChange = (index, e) => {
    const newFlashcards = [...flashcards];
    newFlashcards[index].front = e.target.value;
    setFlashcards(newFlashcards);
  };

  const handleBackChange = (index, e) => {
    const newFlashcards = [...flashcards];
    newFlashcards[index].back = e.target.value;
    setFlashcards(newFlashcards);
  };

  const handleAddCard = (index = -1) => {
    const newFlashcards = [...flashcards];
    newFlashcards.splice(index + 1, 0, {
      front: "",
      back: "",
      correct: "false",
      imageUrlFront: "",
      imageUrlBack: "",
    });
    setFlashcards(newFlashcards);
    setTimeout(() => {
      document.getElementById(`front-${index + 1}`).focus();
    }, 0);
  };

  const handleDeleteCard = (index) => {
    const newFlashcards = flashcards.filter((_, i) => i !== index);
    setFlashcards(newFlashcards);
  };

  const handleTabPress = (index, isBack, e) => {
    if (e.key === "Tab" && !e.shiftKey) {
      e.preventDefault();
      if (isBack) {
        if (index === flashcards.length - 1) {
          handleAddCard(index);
        } else {
          document.getElementById(`front-${index + 1}`).focus();
        }
      } else {
        document.getElementById(`back-${index}`).focus();
      }
    }
  };

  const handleSubmit = async () => {
    if (!collectionName.trim()) {
      showSnackbar("Samlingens namn får inte vara tomt.", "info");
      collectionNameRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      return;
    }
    if (
      flashcards.some((card) => !card?.front?.trim() || !card?.back?.trim())
    ) {
      showSnackbar(
        "Inga fält för framsida eller baksida får vara tomma.",
        "info",
      );
      return;
    }

    setLoadingSaving(true);
    setMessage("");
    let userId = user.uid;
    Logger.log("userId flashcard create: ", userId);

    try {
      // Sanitize the collection name
      const sanitizedCollectionName = sanitizeInput(collectionName);

      // Sanitize the flashcards
      const sanitizedFlashcards = flashcards.map((card) => ({
        front: sanitizeInput(card.front),
        back: sanitizeInput(card.back),
        correct: card.correct,
        imageUrlFront: card.imageUrlFront || "",
        imageUrlBack: card.imageUrlBack || "",
      }));

      // Sanitize other string fields
      const sanitizedCategory = sanitizeInput(category);
      const sanitizedLanguage = sanitizeInput(language);
      const sanitizedDifficulty = sanitizeInput(difficulty);
      const sanitizedAiInstructions = sanitizeInput(aiInstructions);
      const sanitizedSelectedModel = sanitizeInput(selectedModel);

      const collectionRef = await addDoc(
        collection(db, "flashcardCollections"),
        {
          userId,
          collectionName: sanitizedCollectionName,
          flashcards: sanitizedFlashcards,
          isPublic,
          createdAt: Date.now(),
          numFlashcards,
          category: sanitizedCategory,
          language: sanitizedLanguage,
          difficulty: sanitizedDifficulty,
          aiInstructions: sanitizedAiInstructions,
          selectedModel: sanitizedSelectedModel,
          lastTrainingSession: Date.now(),
          upvotes: 0,
          downvotes: 0,
          userVotes: {},
        },
      );

      const newCollection = {
        id: collectionRef.id,
        userId,
        collectionName: sanitizedCollectionName,
        flashcards: sanitizedFlashcards,
        isPublic,
      };

      onCollectionCreated(newCollection);
      showSnackbar(`Samlingen har skapats framgångsrikt!`, "success");
      setCollectionName("");
      setFlashcards([
        {
          front: "",
          back: "",
          correct: "false",
          imageUrlFront: "",
          imageUrlBack: "",
        },
      ]);

      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }, 50);
    } catch (error) {
      showSnackbar("Fel vid skapande av samling: " + error.message, "info");
    } finally {
      setLoadingSaving(false);
    }
  };

  const simulateProgress = (startProgress, endProgress, duration) => {
    const stepSize = 0.5;
    const steps = (endProgress - startProgress) / stepSize;
    const stepDuration = duration / steps;
    let currentProgress = startProgress;

    const intervalId = setInterval(() => {
      currentProgress += stepSize;
      if (currentProgress >= endProgress) {
        clearInterval(intervalId);
      } else {
        setProgress(currentProgress);
      }
    }, stepDuration);

    return () => clearInterval(intervalId);
  };

  const handleGenerateFlashcards = async () => {
    setProgress(0);
    setLoading(true);
    setMessage("");
    setFlashcards([]);

    setTimeout(() => {
      const progressBar = document.querySelector(".progress-bar-container");
      if (progressBar) {
        progressBar.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }, 500);

    Logger.log("inputType ", inputType);
    Logger.log("aiInput ", aiInput);

    Logger.log("Generating flashcards with:", {
      numFlashcards,
      category,
      language,
      difficulty,
      aiInstructions,
    });

    let userInput;

    if (lessonTheme === "youtube") {
      userInput = transcriptRef.current;
    } else if (lessonTheme === "customTheme") {
      userInput = customTheme;
    } else if (lessonTheme === "predefined") {
      userInput = predefinedTheme;
    } else if (lessonTheme === "fileUpload") {
      userInput = uploadedFileRef.current;
    } else if (lessonTheme === "wikipedia") {
      userInput = wikipediaContentRef.current;
    } else if (lessonTheme === "randomizer") {
      userInput = randomizedContentRef.current;
    } else if (lessonTheme === "book") {
      userInput = bookContentRef.current;
    } else if (lessonTheme === "url") {
      userInput = urlContentRef.current;
    } else {
      //
      userInput = coursePlanContentRef.current;
    }

    Logger.log("themeToUse: ", userInput);

    if (!userInput?.trim()) {
      showSnackbar(
        "Du måste välja underlag för dina flashcards i steg 1.",
        "info",
      );
      setLoading(false);
      return;
    }

    let stopProgressSimulation = null;
    try {
      Logger.log("Selected model:", selectedModel);
      stopProgressSimulation = simulateProgress(0, 90, 15000);
      let diff =
        difficulty === "Svårt"
          ? "Extremt svåra flashcards, ytterst sällsynta."
          : difficulty;

      const messages = [
        {
          role: "system",
          content: `Generate EXACTLY ${numFlashcards} flashcards with the following format with "front" and "back" fields based on the users input. Everything should be in ${language}. Difficulty level: ${difficulty}. ${aiInstructions}. Follow this exact format every time: [{ "front": "question", "back": "answer" }, { "front": "question2", "back": "answer2" }].`,
        },
        { role: "user", content: userInput },
      ];
      const response = await callApi(messages, undefined, true, user.uid);

      Logger.log("response: ", response);

      let messageContent = response.data.choices[0].message.content.trim();
      Logger.log("messageContent", messageContent);
      if (messageContent.startsWith("```json")) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith("```")) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      // Kontrollera om JSON-strukturen är korrekt
      const parsedFlashcards = JSON.parse(messageContent);
      Logger.log("parsedFlashcards", parsedFlashcards);

      if (!Array.isArray(parsedFlashcards)) {
        throw new Error(
          "The response format is incorrect. Expected an array of flashcards.",
        );
      }

      if (stopProgressSimulation) {
        stopProgressSimulation();
      }

      setProgress(100);

      // Se till att varje objekt har rätt nycklar

      const formattedFlashcards = parsedFlashcards.map((card) => {
        if (card.front && card.back) {
          return {
            front: card.front,
            back: card.back,
            correct: "false",
          };
        } else {
          throw new Error(
            'Flashcard format is incorrect. Each card must have "front" and "back" fields.',
          );
        }
      });

      //setFlashcards(formattedFlashcards);

      let index = 0;
      for (let i = 0; i < formattedFlashcards.length; i++) {
        await new Promise((resolve) => setTimeout(resolve, 100)); // 100ms fördröjning
        setFlashcards((prev) => [...prev, formattedFlashcards[i]]);
      }

      //setRenderInterval(interval);

      await trackLesson(user.uid, "Flashcards.js", null, {
        lessonTheme: lessonTheme,
      });

      setAIInput("");

      selectedSuggestionRef.current = "";
    } catch (error) {
      showSnackbar(
        "Fel vid generering av flashcards. Vänligen klicka på Generera igen. ",
        "info",
      );
      setProgress(0);
    } finally {
      setLoading(false);
      setProgress(0);
    }
  };

  return (
    <>
      <Box sx={styles.container}>
        <Box sx={{ ...styles.innerContainer }}>
          {error && (
            <Snackbar
              open={!!error}
              onClose={() => setError(null)}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              autoHideDuration={4000} // Optional: Automatically hides the alert after 6 seconds
            >
              <Alert
                onClose={() => setError(null)}
                severity="info"
                sx={{ width: "100%" }} // Ensures the Alert takes full width of the Snackbar
              >
                {` ${error}`}
              </Alert>
            </Snackbar>
          )}

          <Box sx={{ width: { xs: "100%", md: "100%" }, margin: "0 auto" }}>
            <Typography
              variant="h1"
              gutterBottom
              sx={{
                fontWeight: "bold",
                fontSize: "1.8em",
                mb: 3,

                textAlign: "center",
              }}
            >
              Flashcards
            </Typography>

            <InfoBox
              icon={ViewAgendaIcon}
              title="Flashcards"
              instructions={[
                "Välj underlag till dina flashcards och generera — eller skapa manuellt",
                "Starta igång träningen direkt eller dela flashcards med elever",
                "Se din historik över resultat, senaste träningstillfället och mycket mer",
              ]}
              theme={theme}
              styles={styles}
            />

            <ThemeSelectorFlow
              value={lessonTheme}
              onChange={(e) => setLessonTheme(e.target.value)}
              customTheme={customTheme}
              onCustomThemeChange={(e) => setCustomTheme(e.target.value)}
              onBookContentChange={(content) => {
                Logger.log("onBookContentChange", content.substring(0, 100));
                bookContentRef.current = content;
              }}
              onPredefinedThemeChange={(e) =>
                setPredefinedTheme(e.target.value)
              }
              onUrlContentChange={(content) => {
                Logger.log(
                  "Parent: URL content received",
                  content.substring(0, 300),
                );
                urlContentRef.current = content;
              }}
              onYoutubeTranscriptChange={(transcriptContent) => {
                Logger.log(
                  "onYoutubeTranscriptChange körs med: ",
                  transcriptContent,
                );
                transcriptRef.current = transcriptContent;
              }}
              onFileUpload={(content) => {
                Logger.log("onFileUpload körs med: ", content);
                uploadedFileRef.current = content;
              }}
              onWikipediaContentChange={(content) => {
                Logger.log(
                  "Parent: Wikipedia content received",
                  content.substring(0, 100),
                );
                wikipediaContentRef.current = content;
              }}
              onRandomizedContentChange={(content) => {
                Logger.log("randomizedContentRef", content.substring(0, 100));
                randomizedContentRef.current = content;
              }}
              onCoursePlanContentChange={(content) => {
                Logger.log("onCoursePlanChange", content.substring(0, 100));
                coursePlanContentRef.current = content;
              }}
              lessonType="Flashcards"
              user={user}
              theme={theme}
              styles={styles}
              additionalThemes={additionalThemes}
              forFlashcards={true}
            />
          </Box>

          <Box sx={styles.headlineLessonBox}>
            <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
              2. Generera flashcards
            </Typography>
          </Box>

          {showAIInput && (
            <Box
              sx={{
                ...styles.freshBox,
                position: "relative",
                padding: "30px",
              }}
            >
              <Grid container spacing={5}>
                <Grid item xs={12} md={6}>
                  <Typography
                    variant="formLabelSinUnderline"
                    sx={{ display: "block" }}
                  >
                    Namn på din samling
                  </Typography>

                  <TextField
                    ref={collectionNameRef}
                    placeholder="Namn på samlingen"
                    value={collectionName}
                    onChange={handleCollectionNameChange}
                    margin="normal"
                    fullWidth
                    sx={{ mt: 1 }}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Typography
                    variant="formLabelSinUnderline"
                    sx={{ display: "block" }}
                  >
                    Språk
                  </Typography>
                  <Box sx={{ mt: 1 }}>
                    <LanguageSelector
                      language={language}
                      setLanguage={setLanguage}
                    />
                  </Box>
                </Grid>
              </Grid>

              <Collapse in={showMoreOptions}>
                <div style={{ marginTop: "20px" }}>
                  <AdditionalFlashFields
                    aiInstructions={aiInstructions}
                    setAiInstructions={setAiInstructions}
                    numFlashcards={numFlashcards}
                    setNumFlashcards={setNumFlashcards}
                    category={category}
                    setCategory={setCategory}
                    language={language}
                    setLanguage={setLanguage}
                    difficulty={difficulty}
                    setDifficulty={setDifficulty}
                    visibility={visibility}
                    handleVisibilityChange={handlePublicChange}
                    theme={theme}
                    selectedModel={selectedModel}
                    setSelectedModel={setSelectedModel}
                  />
                </div>
              </Collapse>

              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                  mt: 7,
                  justifyContent: "center",
                }}
                className="progress-bar-container"
              >
                <Button
                  onClick={handleGenerateFlashcards}
                  variant={
                    theme.palette.mode === "light" ? "purp" : "contained"
                  }
                  color="primary"
                  disabled={loading}
                  startIcon={
                    !loading ? (
                      <GenerateIcon style={{ color: "#fff" }} />
                    ) : (
                      <CircularProgress style={{ color: "#fff" }} size={18} />
                    )
                  }
                >
                  Generera flashcards
                </Button>

                <Typography
                  sx={{
                    mx: 4,
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                    fontWeight: "500",
                    fontSize: "0.9rem",
                    "&::before, &::after": {
                      content: '""',
                      position: "absolute",
                      top: "50%",
                      width: { xs: "0%", md: "40%" },
                      height: "1px",
                      backgroundColor: (theme) =>
                        theme.palette.mode === "dark" ? "grey.800" : "grey.500",
                    },
                    "&::before": { left: "43px" },
                    "&::after": { right: "43px" },
                  }}
                >
                  ELLER
                </Typography>

                <Button
                  startIcon={<AddIcon />}
                  variant="print"
                  onClick={() => handleAddCard(flashcards.length - 1)}
                >
                  Skapa manuellt
                </Button>
                <Button
                  variant="print"
                  disableRipple
                  startIcon={
                    showMoreOptions ? <ExpandLessIcon /> : <SettingsIcon />
                  }
                  onClick={() => setShowMoreOptions(!showMoreOptions)}
                  sx={{
                    position: "absolute",
                    right: "30px",
                    bottom: "30px",

                    marginRight: "0px",
                    paddingLeft: "20px",
                    paddingRight: "8px",
                    paddingTop: "14px",
                    paddingBottom: "14px",
                    minWidth: "auto",
                    textTransform: "none",
                  }}
                />
              </Box>

              {loading && (
                <>
                  <Box sx={{ mt: 6 }}>
                    <ProgressBar
                      progress={progress}
                      label="Genererar flashcards — tar 30-60 sekunder..."
                    />
                  </Box>
                </>
              )}
            </Box>
          )}

          {flashcards.length === 0 && !loading && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleAddCard()}
              sx={{ mt: 2, mb: 2, width: "170px", display: "none" }}
              startIcon={<AddIcon />}
            >
              Lägg till kort
            </Button>
          )}

          <Box sx={{ mt: 5 }}>
            {flashcards.map((flashcard, index) => (
              <FlashcardItem
                flashcard={flashcard}
                index={index}
                handleFrontChange={handleFrontChange}
                handleBackChange={handleBackChange}
                handleDeleteCard={handleDeleteCard}
                handleTabPress={handleTabPress}
                isDark={isDark}
                styles={styles}
                user={user}
                showSnackbar={showSnackbar}
                onImageUpdate={(index, url, side) => {
                  const newFlashcards = [...flashcards];
                  if (side === "front") {
                    newFlashcards[index].imageUrlFront = url;
                  } else {
                    newFlashcards[index].imageUrlBack = url;
                  }
                  setFlashcards(newFlashcards);
                }}
              />
            ))}
            {flashcards.length > 0 && (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "12px",
                    width: "100%",
                  }}
                >
                  <Button
                    startIcon={<AddIcon />}
                    variant="print"
                    onClick={() => handleAddCard(flashcards.length - 1)}
                    sx={{ mt: 4, mb: 4 }}
                  >
                    Lägg till flashcard
                  </Button>
                </div>
              </>
            )}
          </Box>

          {flashcards.length > 0 && (
            <>
              <Box sx={{ ...styles.headlineLessonBox, mt: 8 }}>
                <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
                  3. Öva, spara eller dela
                </Typography>
              </Box>

              <Box
                sx={{
                  ...styles.freshBox,
                  position: "relative",
                  padding: "40px",
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: "1.1rem",
                    fontWeight: "500",
                    textAlign: "center",
                    mb: 4,
                    color: "text.secondary",
                  }}
                >
                  När du är nöjd med dina flashcards kan du göra följande:
                </Typography>
                <Box
                  sx={{
                    width: { xs: "100%", md: "100%" },
                    margin: { xs: undefined, md: "0 auto" },
                  }}
                  className="addMore"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Tooltip title="Starta interaktiv träning!">
                      <Button
                        variant={
                          theme.palette.mode === "light" ? "purp" : "contained"
                        }
                        color="primary"
                        onClick={handleSubmit}
                        disabled={loadingSaving}
                        sx={{ width: "auto", ml: 2 }}
                        startIcon={
                          !loadingSaving ? (
                            <PlayArrowIcon />
                          ) : (
                            <CircularProgress size={18} />
                          )
                        }
                      >
                        Spara & starta träning
                      </Button>
                    </Tooltip>

                    <Tooltip title="Dela med dina vänner">
                      <Button
                        variant="success"
                        onClick={() => setShareDialogOpenFriends(true)}
                        sx={{ width: "auto", ml: 2 }}
                        startIcon={<ShareIcon />}
                      >
                        Dela med vänner
                      </Button>
                    </Tooltip>

                    <Tooltip title="Dela med dina elever">
                      <Button
                        variant="success"
                        onClick={() => setShareDialogOpen(true)}
                        sx={{ width: "auto", ml: 2 }}
                        startIcon={<ShareIcon />}
                      >
                        Dela med elever
                      </Button>
                    </Tooltip>

                    <Tooltip title="Sparas i 'Mina flashcards'">
                      <Button
                        variant="purp"
                        sx={{ width: "auto", ml: 2 }}
                        onClick={handleSaveFlashcards}
                        startIcon={
                          !loadingSaving ? (
                            <SaveAsIcon />
                          ) : (
                            <CircularProgress
                              sx={{ color: "white" }}
                              size={18}
                            />
                          )
                        }
                      >
                        Spara i mitt arkiv
                      </Button>
                    </Tooltip>

                    <ShareDialog
                      open={shareDialogOpen}
                      onClose={() => setShareDialogOpen(false)}
                      onShare={handleShare}
                      user={user}
                    />

                    <ShareDialog
                      open={shareDialogOpenFriends}
                      onClose={() => setShareDialogOpenFriends(false)}
                      onShare={handleShare}
                      user={user}
                      theme={theme}
                      shareWithFriends={true}
                    />
                  </div>
                </Box>{" "}
              </Box>
            </>
          )}
          {message && (
            <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
              {message}
            </Typography>
          )}
          <Box sx={{ mt: 12, margin: "0 auto" }}>
            <Box sx={styles.headlineLessonBox}>
              <Typography
                variant="h6"
                sx={{ ...styles.headlineLessonBoxTitle, mt: 15 }}
              >
                Mina flashcards
              </Typography>
            </Box>
            <Box sx={{ ...styles.freshBox, padding: "0px" }}>
              <MyFlashcards
                user={user}
                theme={theme}
                styles={styles}
                onStartTraining={startTraining}
                reloadTrigger={myFlashcardsReloadTrigger}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <LoadingBackdrop
        isOpen={isSharing}
        imagePath="/img/AI.png"
        loadingText={"Delar flashcards med eleverna..."}
      />
    </>
  );
};

export default FlashcardsCreateView;
