import {
  Restaurant,
  Park,
  Psychology,
  SportsBasketball,
  Work,
  MonitorHeart,
  FlightTakeoff,
  Computer,
} from '@mui/icons-material';

export const defaultThemes = [
  { type: 'subheader', label: 'Teman som lämpar sig extra bra för denna lektionstyp:' },
  {
    value: 'mat',
    label: 'Mat och matlagning',
    content: 'Gör en text om mat och matlagning',
    Icon: Restaurant,
  },
  {
    value: 'natur',
    label: 'Natur och miljö',
    content: 'Gör en text om natur och miljö',
    Icon: Park,
  },
  {
    value: 'kanslor',
    label: 'Känslor och sinnesstämningar',
    content: 'Gör en text om känslor och sinnesstämningar',
    Icon: Psychology,
  },
  {
    value: 'sport',
    label: 'Sport och fritidsaktiviteter',
    content: 'Gör en text om sport och fritidsaktiviteter',
    Icon: SportsBasketball,
  },
  {
    value: 'yrken',
    label: 'Yrken och arbetsplatser',
    content: 'Gör en text om yrken och arbetsplatser',
    Icon: Work,
  },
  {
    value: 'kropp',
    label: 'Kroppen och hälsa',
    content: 'Gör en text om kroppen och hälsa',
    Icon: MonitorHeart,
  },
  {
    value: 'resor',
    label: 'Resor och transport',
    content: 'Gör en text om resor och transport',
    Icon: FlightTakeoff,
  },
  {
    value: 'teknik',
    label: 'Teknik och digitala verktyg',
    content: 'Gör en text om teknik och digitala verktyg',
    Icon: Computer,
  },
];
// Subject-specific themes
export const subjectThemes = {
  biologi: [
    { type: 'subheader', label: 'Biologiteman som lämpar sig extra bra för denna lektionstyp:' },
  ],

  historia: [
    { type: 'subheader', label: 'Historieteman som lämpar sig extra bra för denna lektionstyp:' },
  ],

  fysik: [
    { type: 'subheader', label: 'Fysikteman som lämpar sig extra bra för denna lektionstyp:' },
  ],

  kemi: [{ type: 'subheader', label: 'Kemiteman som lämpar sig extra bra för denna lektionstyp:' }],

  matematik: [
    { type: 'subheader', label: 'Matematikteman som lämpar sig extra bra för denna lektionstyp:' },
  ],

  svenska: [
    { type: 'subheader', label: 'Svenskateman som lämpar sig extra bra för denna lektionstyp:' },
  ],
};

// Helper function to get appropriate themes based on subject
export const getThemes = subject => {
  if (!subject) return defaultThemes;
  return subjectThemes[subject] || defaultThemes;
};

export default getThemes;
