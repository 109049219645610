import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  FormControl,
  Select,
  MenuItem,
  Typography,
  Avatar,
  Grid,
  CircularProgress,
  IconButton,
  Tooltip,
  Paper,
  Divider,
  TextField,
  useTheme,
} from "@mui/material";
import GenerateIcon from "@mui/icons-material/AutoAwesome";
import ImageIcon from "@mui/icons-material/Image";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import { callApi, generateImage } from "./api";
import PrintIcon from "@mui/icons-material/Print";

import PrintAsPdf from "./PrintAsPdf";
import SlideInPaper from "./SlideInPaper";
import Translate from "./Translate";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import ShareLink from "./ShareLink";
import ShareDialog from "./ShareDialog";
import ShareIcon from "@mui/icons-material/Share";
import LoadingBackdrop from "./LoadingBackdrop";
import ImageLoadingDialog from "./ImageLoadingDialog";

import { OrbitProgress } from "react-loading-indicators";
import WindowIcon from "@mui/icons-material/Window";
import { generateAndProcessImage, trackLesson } from "./functions";
import LanguageSelector from "./LanguageSelector";
import { handlePrint } from "./printFunctions";
import { useSnackbar } from "./SnackbarHandler";
import { db } from "./firebase";
import {
  collection,
  query,
  where,
  limit,
  addDoc,
  updateDoc,
  getDocs,
  serverTimestamp,
} from "firebase/firestore";

import Voice from "./Voice";
import InfoBox from "./InfoBox";
import { useSearchParams, useParams } from "react-router-dom";
import { ThemeSelector } from "./ThemeSelector";
import ImageGenerationModal from "./ImageGenerationModal";
import GradeSelector, { gradeInstructions } from "./GradeSelector";
import HoverableImage from "./HoverableImage";
import { getStyles } from "../index";

import Logger from "./Logger";
import LessonDrawer from "./LessonDrawer";
import SaveLessonDialog from "./SaveLessonDialog";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import HistoryIcon from "@mui/icons-material/History";

import { ThemeSelectorFlow } from "./ThemeSelectorFlow";
import {
  Science as ScienceIcon,
  Castle as CastleIcon,
  Psychology as PsychologyIcon,
  Pets as PetsIcon,
  Public as PublicIcon,
  Brush as BrushIcon,
  EmojiEvents as EmojiEventsIcon,
  Museum as MuseumIcon,
  Nature as NatureIcon,
} from "@mui/icons-material";
import MenuBookIcon from "@mui/icons-material/MenuBook";
const TermsGrid = ({ user }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);
  const urlToTheme = {
    jul: "christmas",
    host: "fall",
    var: "spring",
    vinter: "winter",
    sommar: "summer",
    pask: "easter",
    halloween: "halloween",
    nyar: "newYear",
  };

  const [gridTheme, setGridTheme] = useState(() => {
    const urlTheme = searchParams.get("tema");
    return urlToTheme[urlTheme] || urlTheme || "generateWithAi";
  });
  const translateButtonRef = useRef(null);
  const pdfButtonRef = useRef(null);
  const shareLinkButtonRef = useRef(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showControlPanel, setShowControlPanel] = useState(false);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [isSharing, setIsSharing] = useState(false);
  const [shareDialogOpenFriends, setShareDialogOpenFriends] = useState(false);
  const [language, setLanguage] = useState("svenska");
  const [secondLanguage, setSecondLanguage] = useState("arabiska");
  const [terms, setTerms] = useState([]);
  const [storyTheme, setStoryTheme] = useState("");
  const [isGeneratingTerms, setIsGeneratingTerms] = useState(false);
  const [themeImage, setThemeImage] = useState(null);
  const [themeTranslation, setThemeTranslation] = useState("");
  const [isGeneratingTermImages, setIsGeneratingTermImages] = useState(false);
  const [isGeneratingThemeImage, setIsGeneratingThemeImage] = useState(false);
  const [themeColor, setThemeColor] = useState("#5e5e5e");
  const theme = useTheme();
  const styles = getStyles(theme);
  const [isEditingTheme, setIsEditingTheme] = useState(false);
  const [generatedTermImages, setGeneratedTermImages] = useState(new Set());
  const { showSnackbar } = useSnackbar();

  const [customTheme, setCustomTheme] = useState("");

  const [grade, setGrade] = useState("åk6");

  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [titleText, setTitleText] = useState("Glosor i lådor");

  const [showImageModal, setShowImageModal] = useState(false);
  const [imageInstructions, setImageInstructions] = useState("");
  const [imageGenerationType, setImageGenerationType] = useState(null);
  const [selectedTermIndex, setSelectedTermIndex] = useState(null);
  const [loadingImageIndex, setLoadingImageIndex] = useState(null);

  const [lessonTheme, setLessonTheme] = useState("generateWithAi");
  const [predefinedTheme, setPredefinedTheme] = useState(null);
  const lessonTitleRef = useRef(null); // Add this ref
  const transcriptRef = useRef("");
  const uploadedFileRef = useRef("");

  const bookContentRef = useRef("");

  const wikipediaContentRef = useRef("");
  const randomizedContentRef = useRef("");
  const coursePlanContentRef = useRef("");
  const urlContentRef = useRef("");
  const additionalThemes = [
    {
      type: "subheader",
      label: "Teman som lämpar sig extra bra för denna lektionstyp:",
    },
    {
      value: "selma",
      label: "Selma Lagerlöf",
      content: "Gör en lektion om selma lagerlöf",
      Icon: MenuBookIcon,
    },
    {
      value: "dronten",
      label: "Dronten",
      content: "Gör en lektion om den utdöda arten dronten",
      Icon: PetsIcon,
    },
    {
      value: "vikingar",
      label: "Vikingar",
      content: "Gör en lektion om vikingatiden",
      Icon: CastleIcon,
    },
    {
      value: "rymden",
      label: "Rymden",
      content: "Gör en lektion om vårt solsystem",
      Icon: PublicIcon,
    },
    {
      value: "dinosaurier",
      label: "Dinosaurier",
      content: "Gör en lektion om olika dinosaurier",
      Icon: NatureIcon,
    },
    {
      value: "nobel",
      label: "Nobelpriset",
      content: "Gör en lektion om nobelpriset och Alfred Nobel",
      Icon: EmojiEventsIcon,
    },
    {
      value: "uppfinnare",
      label: "Svenska uppfinnare",
      content: "Gör en lektion om kända svenska uppfinnare",
      Icon: ScienceIcon,
    },
    {
      value: "psychology",
      label: "Känslor",
      content: "Gör en lektion om olika känslor",
      Icon: PsychologyIcon,
    },
    {
      value: "konst",
      label: "Svensk konst",
      content: "Gör en lektion om svensk konsthistoria",
      Icon: MuseumIcon,
    },
  ];

  const subject = searchParams.get("subject") || null;
  const viewMode = searchParams.get("v") === "t";
  useEffect(() => {
    Logger.log("subject: ", subject);
  }, [subject]);

  useEffect(() => {
    Logger.log("lessonTheme: ", lessonTheme);
  }, [lessonTheme]);

  useEffect(() => {
    Logger.log("predefinedTheme: ", predefinedTheme);
  }, [predefinedTheme]);

  useEffect(() => {
    Logger.log("customTheme: ", customTheme);
  }, [customTheme]);

  useEffect(() => {
    Logger.log("youtubeTranscript: ", transcriptRef.current);
  }, [transcriptRef.current]);

  useEffect(() => {
    Logger.log("wikipediaContentRef: ", wikipediaContentRef.current);
  }, [wikipediaContentRef.current]);
  useEffect(() => {
    Logger.log("uploadedFileRef: ", uploadedFileRef.current);
  }, [uploadedFileRef.current]);

  useEffect(() => {
    Logger.log("randomizedContentRef: ", randomizedContentRef.current);
  }, [randomizedContentRef.current]);

  useEffect(() => {
    Logger.log("coursePlanContentRef: ", coursePlanContentRef.current);
  }, [coursePlanContentRef.current]);

  const { lessonId } = useParams();

  useEffect(() => {
    const loadLessonFromId = async () => {
      if (lessonId) {
        try {
          const q = query(
            collection(db, "lesson_usage"),
            where("__name__", ">=", lessonId),
            where("__name__", "<=", lessonId + "\uf8ff"),
            where("lessonType", "==", "TermsGridLesson.js"),
            limit(1),
          );

          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            const docData = querySnapshot.docs[0].data();

            if (!docData.hasOpened) {
              await updateDoc(querySnapshot.docs[0].ref, {
                hasOpened: true,
                openedAt: Date.now(),
              });
            }

            setTerms(docData.data);
            setTitleText(docData.lessonName);

            // Scroll to lesson content
            setTimeout(() => {
              lessonTitleRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }, 100);

            showSnackbar("Lektionen har laddats in.", "success");
          } else {
            showSnackbar("Kunde inte hitta lektionen", "error");
          }
        } catch (error) {
          console.error("Error loading lesson:", error);
          showSnackbar("Ett fel uppstod när lektionen skulle laddas", "error");
        }
      }
    };

    loadLessonFromId();
  }, lessonId);

  useEffect(() => {
    Logger.log("lessonData: ", terms);
    const isDataValid =
      terms !== null && terms !== undefined && Object.keys(terms).length > 0;

    if (isDataValid !== showControlPanel) {
      const timer = setTimeout(() => {
        setShowControlPanel(isDataValid);
      }, 100);

      return () => clearTimeout(timer);
    }
  }, [terms, showControlPanel]);

  const handleShare = async (
    lessonComment,
    lessonName,
    selectedImage,
    tags,
    shareData,
  ) => {
    setIsSharing(true);
    await new Promise((resolve) => setTimeout(resolve, 1500));
    // try {
    //   // Create a document for each student
    //   const sharePromises = shareData.studentIds.map((studentId) => {
    //     const documentData = {
    //       userId: user.uid,
    //       shared: true,
    //       sharedTo: studentId,
    //       comment: shareData.comment,
    //       sharedAt: Date.now(),
    //       fileUrl: shareData.fileUrl,
    //       lessonType: "LettersLesson.js",
    //       timestamp: serverTimestamp(),
    //       lessonComment,
    //       lessonName,
    //       coverImage: selectedImage,
    //       hasPrinted: true,
    //       data: lessonData,
    //     };
    //     Logger.log(
    //       "Adding document for student",
    //       studentId,
    //       ":",
    //       JSON.stringify(documentData, null, 2),
    //     );
    //     return addDoc(collection(db, "lesson_usage"), documentData);
    //   });
    //   await Promise.all(sharePromises);
    //   showSnackbar(`Du har nu delat lektionen!`, "success");
    // }

    try {
      // Create a document for each student and then create notifications
      const sharePromises = shareData.studentIds.map(async (studentId) => {
        const documentData = {
          userId: user.uid,
          shared: true,
          sharedTo: studentId,
          comment: shareData.comment,
          sharedAt: Date.now(),
          fileUrl: shareData.fileUrl,
          lessonType: "TermsGridLesson.js",
          timestamp: serverTimestamp(),
          lessonComment,
          lessonName,
          coverImage: selectedImage,
          hasPrinted: true,
          data: terms,
        };
        // Create lesson document and get ID
        const lessonDoc = await addDoc(
          collection(db, "lesson_usage"),
          documentData,
        );
        Logger.log(
          "Adding document for student",
          studentId,
          ":",
          JSON.stringify(documentData, null, 2),
        );
        // Create notification with lesson doc ID
        await addDoc(collection(db, "notifications"), {
          type: "lesson_shared",
          recipientId: studentId,
          senderId: user.uid,
          read: false,
          createdAt: serverTimestamp(),
          lessonData: {
            lessonId: lessonDoc.id,
            lessonType: documentData.lessonType,
            lessonName: documentData.lessonName,
            fileUrl: documentData.fileUrl,
            comment: documentData.comment,
            coverImage: selectedImage,
          },
        });
        return lessonDoc;
      });
      await Promise.all(sharePromises);
      showSnackbar(`Du har nu delat lektionen!`, "success");
    } catch (error) {
      showSnackbar("Fel vid delande av lektionen: " + error.message, "error");
      console.error(error);
    } finally {
      setIsSharing(false);
    }
  };

  const handleSaveLessonClick = () => {
    setSaveDialogOpen(true);
  };

  const handleSaveWithComment = async (comment, name, coverImage, tags) => {
    Logger.log(JSON.stringify(terms, null, 2));
    await trackLesson(
      user.uid,
      "TermsGridLesson.js",
      terms,
      null,
      true,
      comment,
      name,
      coverImage,
      tags,
    );
    setDrawerOpen(true);
    showSnackbar("Lektionen sparad i din lektionsbank!", "success");
  };

  const toggleViewMode = () => {
    const currentParams = Object.fromEntries(searchParams.entries());
    if (currentParams.v === "t") {
      delete currentParams.v;
    } else {
      currentParams.v = "t";
    }
    setSearchParams(currentParams);
  };

  const handleGenerateSpecificImage = async () => {
    if (imageGenerationType === "topic") {
      setIsGeneratingThemeImage(true);
      try {
        const prompt = `Create an image representing the theme "${storyTheme}". ${imageInstructions}`;
        const imageUrl = await generateAndProcessImage(prompt, user.uid, {
          size: "1024x1024",
          model: "dall-e-3",
          quality: "standard",
        });
        setThemeImage(imageUrl);
        showSnackbar("Temabilden har genererats!", "success");
      } catch (error) {
        showSnackbar("Failed to generate theme image", "error");
      } finally {
        setIsGeneratingThemeImage(false);
      }
    } else if (selectedTermIndex !== null) {
      setLoadingImageIndex(selectedTermIndex);
      try {
        const term = terms[selectedTermIndex];
        const prompt = `Create an image representing the word "${term.word}" (${term.translation}). ${imageInstructions}`;
        const imageUrl = await generateAndProcessImage(prompt, user.uid, {
          size: "1024x1024",
          model: "dall-e-3",
          quality: "standard",
        });

        const updatedTerms = [...terms];
        updatedTerms[selectedTermIndex] = {
          ...updatedTerms[selectedTermIndex],
          image: imageUrl,
        };
        setTerms(updatedTerms);
        showSnackbar("Bild genererad!", "success");
      } catch (error) {
        showSnackbar("Failed to generate image", "error");
      } finally {
        setLoadingImageIndex(null);
      }
    }
  };

  const handleImageButtonClick = (type) => {
    setImageGenerationType(type);
    setShowImageModal(true);
  };

  const handleCloseModal = () => {
    setShowImageModal(false);
    setImageInstructions("");
  };

  const handleGenerateWithInstructions = async () => {
    handleCloseModal();

    if (imageGenerationType === "topic") {
      await handleGenerateThemeImage();
    } else if (imageGenerationType === "facts") {
      await handleGenerateTermImages();
    }
  };

  const handlePrintClick = () => {
    handlePrint(
      terms,
      storyTheme,
      themeImage,
      themeTranslation,
      themeColor,
      titleText,
    );
  };

  const handleGenerateThemeImage = async () => {
    setIsGeneratingThemeImage(true);
    try {
      const prompt = `Create an image representing the theme "${storyTheme}". Do not include any text in the image. ${imageInstructions}`;

      const imageUrl = await generateAndProcessImage(prompt, user.uid, {
        size: "1024x1024",
        model: "dall-e-3",
        quality: "standard",
      });

      setThemeImage(imageUrl);
      showSnackbar("Temabild har genererats", "success");
    } catch (error) {
      console.error("Error generating theme image:", error);
      showSnackbar("Kunde inte generera temabild. Försök igen.", "error");
    } finally {
      setIsGeneratingThemeImage(false);
    }
  };

  const handleGenerateTermImages = async () => {
    setIsGeneratingTermImages(true);
    setGeneratedTermImages(new Set());
    try {
      const totalTerms = terms.length;
      let completedCount = 0;

      // Create a copy of terms to update
      const updatedTerms = [...terms];

      for (const [index, term] of terms.entries()) {
        try {
          const prompt = `Create an image representing the word "${term.word}" (${term.translation}). Do not include any text in the image. ${imageInstructions}`;

          const imageUrl = await generateAndProcessImage(prompt, user.uid, {
            size: "1024x1024",
            model: "dall-e-3",
            quality: "standard",
          });

          // Update the specific term with its new image
          updatedTerms[index] = {
            ...updatedTerms[index],
            image: imageUrl,
          };

          // Update state to show progress
          setTerms(updatedTerms);
          setGeneratedTermImages((prev) => new Set([...prev, index]));

          completedCount++;
          showSnackbar(
            `Genererat bild ${completedCount} av ${totalTerms}`,
            "info",
          );
        } catch (error) {
          console.error(
            `Error generating image for term "${term.word}":`,
            error,
          );
          showSnackbar(
            `Kunde inte generera bild för "${term.word}"`,
            "warning",
          );
          continue;
        }
      }

      showSnackbar("Alla bilder har genererats!", "success");
    } catch (error) {
      console.error("Error in image generation process:", error);
      showSnackbar("Ett fel uppstod i bildgenereringsprocessen", "error");
    } finally {
      setIsGeneratingTermImages(false);
    }
  };

  const handleGenerateTerms = async () => {
    setIsGeneratingTerms(true);
    try {
      const generateTheme = gridTheme === "generateWithAi";
      const gradeInstructionsText = gradeInstructions[grade];
      let themeToUse;

      if (lessonTheme === "youtube") {
        themeToUse = transcriptRef.current;
      } else if (lessonTheme === "customTheme") {
        themeToUse = customTheme;
      } else if (lessonTheme === "predefined") {
        themeToUse = predefinedTheme;
      } else if (lessonTheme === "fileUpload") {
        themeToUse = uploadedFileRef.current;
      } else if (lessonTheme === "wikipedia") {
        themeToUse = wikipediaContentRef.current;
      } else if (lessonTheme === "randomizer") {
        themeToUse = randomizedContentRef.current;
      } else if (lessonTheme === "book") {
        themeToUse = bookContentRef.current;
      } else if (lessonTheme === "url") {
        themeToUse = urlContentRef.current;
      } else {
        //
        themeToUse = coursePlanContentRef.current;
      }

      Logger.log("themeToUse: ", themeToUse);

      let response = await callApi(
        [
          {
            role: "system",
            content:
              "You are an assistant that generates terms for language lessons, optionally including a theme.",
          },
          {
            role: "user",
            content: `Generate JSON with exactly 10 terms related to the theme "${themeToUse}" in "${language}" with translations in "${secondLanguage}". Include the theme (if generated), its translation in ${secondLanguage}, each term, and its translation. 
${gradeInstructionsText}.
Important: Ensure that the theme (if generated) is in ${language} and its translation is accurately provided in ${secondLanguage}.

Example response:
{
  "theme": "theme in ${language}",
  "themeTranslation": "accurate translation of the theme in ${secondLanguage}",
  "terms": [
    { "word": "word in ${language}", "translation": "translation in ${secondLanguage}",
    "exampleSentence": "Example sentence using the word in ${language}",
      "translatedExampleSentence": "Translation of the example sentence in ${secondLanguage}"


     },
    { 
      "word": "kock", 
    "translation": "chef",
      "exampleSentence": "Example sentence using the word in ${language}",
      "translatedExampleSentence": "Translation of the example sentence in ${secondLanguage}"

     }
  ]
}`,
          },
        ],
        4000,
        true,
        user.uid,
      );

      console.log("response:", response);

      let messageContent = response.data.choices[0].message.content.trim();

      if (messageContent.startsWith("```json")) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith("```")) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      const data = JSON.parse(messageContent);

      const themeTranslation = data.themeTranslation || gridTheme;

      setTerms(
        data.terms.map((term) => ({
          ...term,
          image: null,
        })),
      );
      setStoryTheme(data.theme);
      setThemeTranslation(themeTranslation);

      showSnackbar("Glosor i lådor har genererats.", "success");
      Logger.log(JSON.stringify(terms, null, 2));
      await trackLesson(user.uid, "TermsGridLesson.js", null, {
        language: language,
        secondLanguage: secondLanguage,
        lessonTheme: lessonTheme,
      });
    } catch (error) {
      console.error("Error generating terms:", error);
    } finally {
      setIsGeneratingTerms(false);
    }
  };

  const handleSaveTerm = (index, newWord, newTranslation) => {
    const updatedTerms = [...terms];
    updatedTerms[index] = {
      ...updatedTerms[index],
      word: newWord,
      translation: newTranslation,
    };
    setTerms(updatedTerms);
  };
  const handleEditTheme = () => {
    setIsEditingTheme(true);
  };

  const handleSaveTheme = (newStoryTheme, newThemeTranslation) => {
    setStoryTheme(newStoryTheme);
    setThemeTranslation(newThemeTranslation);
    setIsEditingTheme(false);
  };

  const TermBox = ({
    term,
    index,
    isGeneratingTermImages,
    generatedTermImages,
    themeColor,
    onSave,
    theme,
  }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [localWord, setLocalWord] = useState(term.word);
    const [localTranslation, setLocalTranslation] = useState(term.translation);
    const [isHovered, setIsHovered] = useState(false);

    const isLoading = isGeneratingTermImages && !generatedTermImages.has(index);

    const handleSave = () => {
      onSave(index, localWord, localTranslation);
      setIsEditing(false);
    };

    return (
      <Box
        sx={{
          padding: "10px",
          textAlign: "center",
          border: `2px solid ${themeColor}`,
          borderRadius: "8px",
          backgroundColor: "transparent",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          position: "relative",
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {isHovered && !isEditing && !viewMode && (
          <IconButton
            sx={{ position: "absolute", top: 5, right: 5 }}
            onClick={() => setIsEditing(true)}
          >
            <EditIcon />
          </IconButton>
        )}
        {isEditing ? (
          <>
            <TextField
              value={localWord}
              onChange={(e) => setLocalWord(e.target.value)}
              variant="standard"
              sx={{
                "& .MuiInputBase-input": {
                  textAlign: "center",
                  fontWeight: "600",
                },
              }}
            />
            <Box sx={{ position: "relative", margin: "10px auto" }}>
              <Avatar
                src={term.image}
                alt={term.word}
                sx={{ width: 80, height: 80 }}
              />
              {isLoading && (
                <Box
                  sx={{
                    position: "absolute",
                    top: "5px",
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "50%",
                  }}
                >
                  <OrbitProgress
                    color="#8b5cf6"
                    size="medium"
                    text=""
                    textColor=""
                  />
                </Box>
              )}
            </Box>
            <TextField
              value={localTranslation}
              onChange={(e) => setLocalTranslation(e.target.value)}
              variant="standard"
              sx={{
                "& .MuiInputBase-input": {
                  textAlign: "center",
                  fontWeight: "600",
                },
              }}
            />
            <Button
              startIcon={<SaveIcon />}
              variant={theme.palette.mode === "light" ? "purp" : "contained"}
              color="primary"
              onClick={handleSave}
              sx={{ mt: 1 }}
            >
              Spara
            </Button>
          </>
        ) : (
          <>
            <Box sx={{ position: "absolute", top: 0, left: 0, zIndex: 1 }}>
              <Voice
                content={term.word}
                fontSize={25}
                userId={user.uid}
                language={language}
              />
            </Box>
            <Box sx={{ position: "absolute", bottom: 0, left: 0, zIndex: 1 }}>
              <Voice
                content={term.translation}
                fontSize={25}
                userId={user.uid}
                language={secondLanguage}
              />
            </Box>

            <Typography
              variant="subtitle1"
              sx={{ fontWeight: "600", fontSize: "18px" }}
            >
              {term.word}
            </Typography>
            <Box sx={{ position: "relative", margin: "10px auto" }}>
              <HoverableImage
                imageUrl={term.image}
                isLoading={
                  loadingImageIndex === index ||
                  (isGeneratingTermImages && !generatedTermImages.has(index))
                }
                loadingIndex={index}
                currentIndex={index}
                userId={user.uid}
                onGenerateClick={() => {
                  setSelectedTermIndex(index);
                  setImageGenerationType("facts");
                  setShowImageModal(true);
                }}
                onImageUpdate={(newImageUrl) => {
                  const updatedTerms = [...terms];
                  updatedTerms[index] = {
                    ...updatedTerms[index],
                    image: newImageUrl,
                  };
                  setTerms(updatedTerms);
                }}
                height="80px"
                tinyMode={true}
                size={80}
                theme={theme}
                showSnackbar={showSnackbar}
              />
            </Box>
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: "600", fontSize: "18px" }}
            >
              {term.translation}
            </Typography>
          </>
        )}
      </Box>
    );
  };

  const ThemeBox = () => {
    const [isHovered, setIsHovered] = useState(false);
    const [localStoryTheme, setLocalStoryTheme] = useState(storyTheme);
    const [localThemeTranslation, setLocalThemeTranslation] =
      useState(themeTranslation);

    return (
      <Box
        sx={{
          padding: "20px",
          border: `2px solid ${themeColor}`,
          borderRadius: "8px",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor:
            theme.palette.mode === "dark" ? "#15202b" : "#f9f9f9",
          position: "relative",
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {isHovered && !isEditingTheme && (
          <IconButton
            sx={{ position: "absolute", top: 5, right: 5 }}
            onClick={handleEditTheme}
          >
            <EditIcon />
          </IconButton>
        )}
        {isEditingTheme ? (
          <>
            <TextField
              value={localStoryTheme}
              onChange={(e) => setLocalStoryTheme(e.target.value)}
              variant="standard"
              sx={{
                "& .MuiInputBase-input": {
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "24px",
                  color: themeColor,
                },
              }}
            />
            <Box sx={{ position: "relative", margin: "10px 0" }}>
              <Avatar
                src={themeImage}
                alt={localStoryTheme}
                sx={{ width: 60, height: 60 }}
              />
              {isGeneratingThemeImage && (
                <Box
                  sx={{
                    position: "absolute",
                    top: "5px",
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",

                    borderRadius: "50%",
                  }}
                >
                  <OrbitProgress
                    color="#8b5cf6"
                    size="medium"
                    text=""
                    textColor=""
                  />
                </Box>
              )}
            </Box>
            <TextField
              value={localThemeTranslation}
              onChange={(e) => setLocalThemeTranslation(e.target.value)}
              variant="standard"
              sx={{
                "& .MuiInputBase-input": {
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "20px",
                  color: themeColor,
                },
              }}
            />
            <Button
              startIcon={<SaveIcon />}
              variant={theme.palette.mode === "light" ? "purp" : "contained"}
              color="primary"
              onClick={() =>
                handleSaveTheme(localStoryTheme, localThemeTranslation)
              }
              sx={{ mt: 1 }}
            >
              Spara
            </Button>
          </>
        ) : (
          <>
            <Box sx={{ position: "absolute", top: 0, left: 0, zIndex: 1 }}>
              <Voice
                content={storyTheme}
                fontSize={25}
                userId={user.uid}
                language={language}
              />
            </Box>
            <Box sx={{ position: "absolute", bottom: 0, left: 0, zIndex: 1 }}>
              <Voice
                content={themeTranslation}
                fontSize={25}
                userId={user.uid}
                language={secondLanguage}
              />
            </Box>
            <Typography
              variant="h5"
              fontWeight="bold"
              sx={{ color: `${themeColor}` }}
            >
              {storyTheme}
            </Typography>
            <Box sx={{ position: "relative", margin: "10px 0" }}>
              <HoverableImage
                imageUrl={themeImage}
                isLoading={isGeneratingThemeImage}
                loadingIndex={null}
                currentIndex={null}
                onGenerateClick={() => {
                  setImageGenerationType("topic");
                  setShowImageModal(true);
                }}
                userId={user.uid}
                onImageUpdate={(newImageUrl) => setThemeImage(newImageUrl)}
                height="80px"
                tinyMode={true}
                size={80}
                theme={theme}
                showSnackbar={showSnackbar}
              />
            </Box>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ color: `${themeColor}` }}
            >
              {themeTranslation}
            </Typography>
          </>
        )}
      </Box>
    );
  };

  const ExampleSentences = () => {
    const [editingIndex, setEditingIndex] = useState(null);
    const [editData, setEditData] = useState({});

    const handleEditClick = (index, term) => {
      setEditingIndex(index);
      setEditData({
        word: term.word,
        translation: term.translation,
        exampleSentence: term.exampleSentence,
        translatedExampleSentence: term.translatedExampleSentence,
      });
    };

    const handleDelete = (index) => {
      const updatedTerms = [...terms];
      updatedTerms[index] = {
        ...updatedTerms[index],
        exampleSentence: "",
        translatedExampleSentence: "",
      };
      setTerms(updatedTerms);
    };

    const handleSave = (index) => {
      const updatedTerms = [...terms];
      updatedTerms[index] = {
        ...updatedTerms[index],
        ...editData,
      };
      setTerms(updatedTerms);
      setEditingIndex(null);
      setEditData({});
    };

    return (
      <Box sx={{ mt: 12, width: "100%" }}>
        <Typography
          variant="h6"
          sx={{
            mb: 3,
            fontWeight: "bold",
            textAlign: "center",
            fontSize: "25px",
          }}
        >
          Exempelmeningar
        </Typography>
        <Grid container spacing={3}>
          {terms.map((term, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Box
                sx={{
                  ...styles.freshBox,
                  padding: "25px",
                  position: "relative",
                  transition: "transform 0.2s ease-in-out",
                  "&:hover": {
                    transform: "translateY(-4px)",
                    "& .edit-icon": {
                      opacity: 1,
                    },
                  },
                }}
              >
                {editingIndex === index ? (
                  // Edit Mode
                  <>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                      <Avatar
                        src={term.image}
                        alt={editData.word}
                        sx={{
                          width: 40,
                          height: 40,
                          mr: 2,
                          bgcolor: "#f1f1f1",
                        }}
                      />
                      <Box sx={{ display: "flex", gap: 1, width: "100%" }}>
                        <TextField
                          value={editData.word}
                          onChange={(e) =>
                            setEditData({ ...editData, word: e.target.value })
                          }
                          variant="standard"
                          size="small"
                          label="Ord"
                          sx={{ flex: 1 }}
                        />
                        <TextField
                          value={editData.translation}
                          onChange={(e) =>
                            setEditData({
                              ...editData,
                              translation: e.target.value,
                            })
                          }
                          variant="standard"
                          size="small"
                          label="Översättning"
                          sx={{ flex: 1 }}
                        />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                        mb: 2,
                      }}
                    >
                      <TextField
                        value={editData.exampleSentence}
                        onChange={(e) =>
                          setEditData({
                            ...editData,
                            exampleSentence: e.target.value,
                          })
                        }
                        variant="standard"
                        multiline
                        label="Exampelmening"
                        fullWidth
                      />
                      <TextField
                        value={editData.translatedExampleSentence}
                        onChange={(e) =>
                          setEditData({
                            ...editData,
                            translatedExampleSentence: e.target.value,
                          })
                        }
                        variant="standard"
                        multiline
                        label="Översatt exempelmening"
                        fullWidth
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: 1,
                      }}
                    >
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => setEditingIndex(null)}
                      >
                        Avbryt
                      </Button>
                      <Button
                        variant={
                          theme.palette.mode === "light" ? "purp" : "contained"
                        }
                        size="small"
                        onClick={() => handleSave(index)}
                      >
                        Spara
                      </Button>
                    </Box>
                  </>
                ) : (
                  // View Mode
                  <>
                    {!viewMode && (
                      <Tooltip title="Redigera" placement="top">
                        <IconButton
                          className="edit-icon"
                          sx={{
                            position: "absolute",
                            top: 8,
                            right: 8,
                            opacity: 0,
                            transition: "opacity 0.2s",
                          }}
                          onClick={() => handleEditClick(index, term)}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Box
                      sx={{
                        position: "absolute",
                        right: "45px",
                        bottom: "-15px",
                        transform: "translateY(-50%)",
                      }}
                    >
                      <Voice
                        content={term.exampleSentence}
                        fontSize={24}
                        userId={user.uid}
                        language={language}
                      />
                    </Box>

                    <Box
                      sx={{
                        position: "absolute",
                        right: "5px",
                        bottom: "-15px",
                        transform: "translateY(-50%)",
                      }}
                    >
                      <Voice
                        content={term.translatedExampleSentence}
                        fontSize={24}
                        userId={user.uid}
                        language={secondLanguage}
                      />
                    </Box>

                    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                      <Avatar
                        src={term.image}
                        alt={term.word}
                        sx={{
                          width: 40,
                          height: 40,
                          mr: 2,
                          bgcolor: "#f1f1f1",
                        }}
                      />
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: "bold",
                          color: themeColor,
                        }}
                      >
                        {term.word} / {term.translation}
                      </Typography>
                    </Box>
                    <Box sx={{ position: "relative", mb: 2 }}>
                      <Typography
                        variant="body1"
                        sx={{ fontStyle: "italic", mb: 1 }}
                      >
                        {term.exampleSentence}
                      </Typography>
                      <Typography variant="body1" sx={{ fontStyle: "italic" }}>
                        {term.translatedExampleSentence}
                      </Typography>
                    </Box>
                  </>
                )}
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  };
  return (
    <>
      <Box sx={styles.container}>
        <Box
          sx={{
            ...styles.innerContainer,
            width: { xs: "100%", md: "100%" },
            margin: "0 auto",
          }}
        >
          {!viewMode && (
            <Typography
              variant="h1"
              gutterBottom
              sx={{
                fontWeight: "bold",
                fontSize: "1.8em",
                mb: 3,
                mt: 5,
                textAlign: "center",
              }}
            >
              Glosor i lådor
            </Typography>
          )}
          <Box
            sx={{
              ...styles.buttonsContainer,
              ...styles.additionalButtonsContainer,
            }}
          >
            {terms && user.accountType === "teacher" && (
              <Button
                variant="purp"
                sx={{ ...styles.tightButton }}
                disableRipple
                onClick={() => {
                  setTimeout(() => {
                    lessonTitleRef.current?.scrollIntoView({
                      behavior: "smooth",
                      block: "start", // This ensures it scrolls to the top of the element
                    });
                  }, 100);
                  toggleViewMode();
                }}
                startIcon={
                  searchParams.get("v") === "t" ? (
                    <FullscreenExitIcon />
                  ) : (
                    <FullscreenIcon />
                  )
                }
              >
                {searchParams.get("v") === "t" ? "Redigeringsläge" : "Elevläge"}
              </Button>
            )}

            {!viewMode && (
              <Button
                variant="purp"
                sx={{ ...styles.tightButton }}
                onClick={() => setDrawerOpen(true)}
                startIcon={<BookmarkBorderIcon />}
              >
                Sparat
              </Button>
            )}
          </Box>
          {!viewMode && (
            <>
              <InfoBox
                icon={WindowIcon}
                title="Powerpoint Instructions"
                instructions={[
                  "Generera glosor i lådor samt exempelmeningar för varje ord",
                  "Spela upp ljud för att höra uttalet",
                  "Lägg till AI-genererade bilder",
                  "Skriv ut lektionen",
                ]}
                theme={theme}
                styles={styles}
              />

              <LessonDrawer
                userId={user.uid}
                lessonType="TermsGridLesson.js"
                theme={theme}
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                lessonIcon={WindowIcon}
                onSelectLesson={(lesson) => {
                  setTerms(lesson.data);
                  setTitleText(lesson.lessonName);
                  setDrawerOpen(false);

                  showSnackbar(`Lektionen har laddats in.`, "success");
                  setTimeout(() => {
                    lessonTitleRef.current?.scrollIntoView({
                      behavior: "smooth",
                      block: "start", // This ensures it scrolls to the top of the element
                    });
                  }, 100);
                }}
              />

              <ThemeSelectorFlow
                subject={subject}
                value={lessonTheme}
                onChange={(e) => setLessonTheme(e.target.value)}
                customTheme={customTheme}
                onCustomThemeChange={(e) => setCustomTheme(e.target.value)}
                onPredefinedThemeChange={(e) =>
                  setPredefinedTheme(e.target.value)
                }
                onBookContentChange={(content) => {
                  Logger.log("onBookContentChange", content.substring(0, 100));
                  bookContentRef.current = content;
                }}
                onUrlContentChange={(content) => {
                  Logger.log(
                    "Parent: URL content received",
                    content.substring(0, 300),
                  );
                  urlContentRef.current = content;
                }}
                onYoutubeTranscriptChange={(transcriptContent) => {
                  Logger.log(
                    "onYoutubeTranscriptChange körs med: ",
                    transcriptContent,
                  );
                  transcriptRef.current = transcriptContent;
                }}
                onFileUpload={(content) => {
                  Logger.log("onFileUpload körs med: ", content);
                  uploadedFileRef.current = content;
                }}
                onWikipediaContentChange={(content) => {
                  Logger.log(
                    "Parent: Wikipedia content received",
                    content.substring(0, 100),
                  );
                  wikipediaContentRef.current = content;
                }}
                onRandomizedContentChange={(content) => {
                  Logger.log("randomizedContentRef", content.substring(0, 100));
                  randomizedContentRef.current = content;
                }}
                onCoursePlanContentChange={(content) => {
                  Logger.log("onCoursePlanChange", content.substring(0, 100));
                  coursePlanContentRef.current = content;
                }}
                lessonType="TermsGridLesson.js"
                user={user}
                theme={theme}
                styles={styles}
                onLessonSelect={(lesson) => {
                  Logger.log("onLessonSelect körs");
                  setTerms(lesson.data);
                  setTitleText(lesson.lessonName);
                  setTimeout(() => {
                    lessonTitleRef.current?.scrollIntoView({
                      behavior: "smooth",
                      block: "start", // This ensures it scrolls to the top of the element
                    });
                  }, 100);
                  showSnackbar("Lektionen har laddats in.", "success");
                }}
                additionalThemes={additionalThemes}
              />
              <Box sx={styles.headlineLessonBox}>
                <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
                  2. Generera lektionen
                </Typography>
              </Box>

              <Box sx={{ ...styles.freshBox, padding: "30px" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <Typography
                      variant="formLabelSinUnderline"
                      sx={{ fontWeight: "500", mb: 0 }}
                    >
                      Årskurs:
                    </Typography>
                    <Box sx={{ mt: 1, mb: 3 }}>
                      <GradeSelector grade={grade} setGrade={setGrade} />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography
                      variant="formLabelSinUnderline"
                      sx={{ fontWeight: "500" }}
                    >
                      Språk:
                    </Typography>

                    <Box sx={{ mt: 1, mb: 3 }}>
                      <LanguageSelector
                        language={language}
                        setLanguage={setLanguage}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography
                      variant="formLabelSinUnderline"
                      sx={{ fontWeight: "500" }}
                    >
                      Andra språk:
                    </Typography>
                    <Box sx={{ mt: 1, mb: 3 }}>
                      <LanguageSelector
                        language={secondLanguage}
                        setLanguage={setSecondLanguage}
                      />
                    </Box>
                  </Grid>{" "}
                </Grid>

                <Box>
                  <Button
                    variant={
                      theme.palette.mode === "light" ? "purp" : "contained"
                    }
                    color="primary"
                    onClick={handleGenerateTerms}
                    disabled={isGeneratingTerms}
                    sx={{ marginTop: "20px" }}
                    startIcon={
                      !isGeneratingTerms ? (
                        <GenerateIcon style={{ color: "#fff" }} />
                      ) : (
                        <CircularProgress size={16} />
                      )
                    }
                  >
                    {isGeneratingTerms ? "Genererar..." : "Generera lådor"}
                  </Button>
                </Box>
              </Box>
            </>
          )}
          {terms.length > 0 && (
            <>
              {!viewMode && (
                <Box
                  ref={lessonTitleRef}
                  sx={{ ...styles.headlineLessonBox, mt: 10 }}
                >
                  <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
                    3. Din lektion
                  </Typography>
                </Box>
              )}
              <Box
                sx={{
                  ...styles.freshBox,
                  padding: "30px",

                  width: {
                    xs: "100%",
                    sm: viewMode ? "80%" : undefined,
                  },
                  margin: viewMode ? "0 auto" : undefined,
                  marginTop: viewMode ? "20px" : undefined,
                }}
              >
                {!viewMode && (
                  <Box sx={{ position: "relative" }}>
                    <Box
                      sx={{
                        ...styles.lessonControlPanel,
                        ...styles.lessonControlPanelNuevo,

                        "& .MuiButton-root": {
                          ...styles.controlPanelButtons,
                          ...styles.lessonControlPanelNewButtons,
                        },
                      }}
                    >
                      <Button
                        variant="print"
                        startIcon={<SaveAsIcon />}
                        sx={{ ml: 2 }}
                        onClick={handleSaveLessonClick}
                      >
                        Spara i lektionsbank
                      </Button>
                      <Button
                        variant="print"
                        color="primary"
                        onClick={handlePrintClick}
                        startIcon={<PrintIcon />}
                      >
                        Skriv ut
                      </Button>
                      <Button
                        variant="success"
                        sx={{}}
                        onClick={() => setShareDialogOpenFriends(true)}
                        startIcon={<ShareIcon />}
                      >
                        Dela med vänner
                      </Button>
                      <Button
                        variant="success"
                        sx={{
                          mt: 2,
                          ml: 2,
                        }}
                        onClick={() => setShareDialogOpen(true)}
                        startIcon={<ShareIcon />}
                      >
                        Dela med elever
                      </Button>

                      <ShareLink
                        buttonRef={shareLinkButtonRef}
                        key={lessonId}
                        lessonId={lessonId}
                        lessonData={terms}
                        user={user}
                        lessonType={"TermsGridLesson.js"}
                        onCopy={(message, severity) =>
                          showSnackbar(message, severity)
                        }
                        sx={{ mt: 2 }}
                      />
                      <PrintAsPdf
                        buttonRef={pdfButtonRef}
                        onPrint={handlePrintClick}
                        sx={{ ml: 2 }}
                      />

                      <Button
                        variant={
                          theme.palette.mode === "light" ? "purp" : "contained"
                        }
                        color="primary"
                        onClick={() => handleImageButtonClick("facts")}
                        disabled={isGeneratingTermImages}
                        startIcon={
                          !isGeneratingTermImages ? (
                            <ImageIcon />
                          ) : (
                            <CircularProgress size={16} />
                          )
                        }
                      >
                        {isGeneratingTermImages
                          ? "Genererar..."
                          : "Generera termbilder"}
                      </Button>

                      <Button
                        variant={
                          theme.palette.mode === "light" ? "purp" : "contained"
                        }
                        color="primary"
                        onClick={() => handleImageButtonClick("topic")}
                        disabled={isGeneratingThemeImage}
                        sx={{ marginLeft: "10px" }}
                        startIcon={
                          !isGeneratingThemeImage ? (
                            <ImageIcon />
                          ) : (
                            <CircularProgress size={16} />
                          )
                        }
                      >
                        {isGeneratingThemeImage
                          ? "Genererar..."
                          : "Generera temabild"}
                      </Button>
                    </Box>
                    <Box
                      sx={{
                        ...styles.lessonControlPanelFade,
                      }}
                    />
                  </Box>
                )}

                <SaveLessonDialog
                  key={titleText}
                  open={saveDialogOpen}
                  onClose={() => setSaveDialogOpen(false)}
                  onSave={handleSaveWithComment}
                  theme={theme}
                  lessonObject={terms}
                  lessonName={titleText}
                />

                <ImageGenerationModal
                  open={showImageModal}
                  onClose={() => {
                    setShowImageModal(false);
                    setImageInstructions("");
                    setSelectedTermIndex(null);
                  }}
                  onGenerate={() => {
                    // Close modal immediately when Generate is clicked
                    setShowImageModal(false);
                    setImageInstructions("");
                    // Then trigger the image generation
                    if (
                      selectedTermIndex !== null ||
                      imageGenerationType === "topic"
                    ) {
                      handleGenerateSpecificImage();
                    } else {
                      handleGenerateWithInstructions();
                    }
                  }}
                  isGenerating={
                    isGeneratingThemeImage || isGeneratingTermImages
                  }
                  instructions={imageInstructions}
                  setInstructions={setImageInstructions}
                  generationType={imageGenerationType}
                  theme={theme}
                />

                {isEditingTitle ? (
                  <TextField
                    value={titleText}
                    onChange={(e) => setTitleText(e.target.value)}
                    onBlur={() => {
                      setIsEditingTitle(false);
                      showSnackbar("Titeln har uppdaterats", "success");
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        setIsEditingTitle(false);
                        showSnackbar("Titeln har uppdaterats", "success");
                      }
                    }}
                    autoFocus
                    fullWidth
                    variant="standard"
                    sx={{
                      mt: 4,
                      backgroundColor: "transparent",
                      "& .MuiInputBase-input": {
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "2.6em",
                        marginBottom: "1.2rem",
                        marginTop: "1.5rem",
                      },
                    }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                ) : (
                  <Box
                    sx={{
                      position: "relative",
                      display: "inline-block",
                      margin: "0 auto",
                      width: "100%",
                      mb: 3,
                      mt: 5,
                      ":hover .edit-icon": {
                        opacity: 1,
                      },
                    }}
                  >
                    <Typography
                      variant="h1"
                      sx={{
                        fontWeight: "bold",
                        mt: 4,
                        fontSize: "2.6em",
                        cursor: "pointer",
                        textAlign: "center",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 2,
                      }}
                    >
                      {titleText || " "}
                      <Box
                        component="span"
                        sx={{
                          opacity: 0,
                          transition: "opacity 0.2s",
                          display: viewMode ? "none" : "inline-flex",
                          gap: 0.5,
                        }}
                        className="edit-icon"
                      >
                        <Tooltip title="Redigera" placement="top">
                          <IconButton
                            size="small"
                            onClick={() => setIsEditingTitle(true)}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Typography>
                  </Box>
                )}

                <Grid container spacing={2} sx={{ marginTop: "40px" }}>
                  {terms.slice(0, 4).map((term, index) => (
                    <Grid item xs={3} key={index}>
                      <TermBox
                        term={term}
                        index={index}
                        isGeneratingTermImages={isGeneratingTermImages}
                        generatedTermImages={generatedTermImages}
                        themeColor={themeColor}
                        onSave={handleSaveTerm}
                        theme={theme}
                      />
                    </Grid>
                  ))}

                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={3}>
                          {terms[4] && (
                            <TermBox
                              term={terms[4]}
                              index={4}
                              isGeneratingTermImages={isGeneratingTermImages}
                              generatedTermImages={generatedTermImages}
                              themeColor={themeColor}
                              onSave={handleSaveTerm}
                              theme={theme}
                            />
                          )}
                        </Grid>

                        <Grid item xs={6}>
                          <ThemeBox />
                        </Grid>

                        <Grid item xs={3}>
                          {terms[5] && (
                            <TermBox
                              term={terms[5]}
                              index={5}
                              isGeneratingTermImages={isGeneratingTermImages}
                              generatedTermImages={generatedTermImages}
                              themeColor={themeColor}
                              onSave={handleSaveTerm}
                              theme={theme}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>

                  {terms.slice(6).map((term, index) => (
                    <Grid item xs={3} key={index + 6}>
                      <TermBox
                        term={term}
                        index={index + 6}
                        isGeneratingTermImages={isGeneratingTermImages}
                        generatedTermImages={generatedTermImages}
                        themeColor={themeColor}
                        onSave={handleSaveTerm}
                        theme={theme}
                      />
                    </Grid>
                  ))}
                </Grid>

                <ExampleSentences />
              </Box>
            </>
          )}
        </Box>

        <SlideInPaper
          isExpanded={isExpanded}
          onExpandedChange={setIsExpanded}
          lessonTitleRef={lessonTitleRef}
          viewMode={viewMode}
          toggleViewMode={toggleViewMode}
          theme={theme}
          open={showControlPanel}
          user={user}
          onPrint={handlePrintClick}
          onSaveAsPdf={() => pdfButtonRef.current?.click()}
          onShareLink={() => shareLinkButtonRef.current?.click()}
          onShareWithStudents={() => setShareDialogOpen(true)}
          onShareWithFriends={() => setShareDialogOpenFriends(true)}
          onSaveToBank={() => setSaveDialogOpen(true)}
          onStudentMode={() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
            toggleViewMode();
          }}
        />

        <ShareDialog
          open={shareDialogOpen}
          onClose={() => setShareDialogOpen(false)}
          onShare={handleShare}
          user={user}
          isLessonSharing={true}
          theme={theme}
          lessonName={titleText || ""}
          lessonObject={terms}
          lessonType={"TermsGridLesson.js"}
        />

        <ShareDialog
          open={shareDialogOpenFriends}
          onClose={() => setShareDialogOpenFriends(false)}
          onShare={handleShare}
          user={user}
          isLessonSharing={true}
          theme={theme}
          lessonName={titleText || ""}
          lessonObject={terms}
          lessonType={"TermsGridLesson.js"}
          shareWithFriends={true}
        />
        <LoadingBackdrop
          isOpen={isSharing}
          imagePath="/img/AI.png"
          loadingText={"Delar lektionen..."}
        />
        <ImageLoadingDialog
          open={isGeneratingTermImages}
          message="Genererar bilder... Tar 30-60 sekunder."
        />
      </Box>
    </>
  );
};

export default TermsGrid;
