import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Stepper,
  Step,
  StepLabel,
  TableContainer,
  Table,
  Divider,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Paper,
  TextField,
  Tabs,
  Tab,
  MenuItem,
  InputAdornment,
  IconButton,
  Tooltip,
  Menu,
} from "@mui/material";
import { db } from "./firebase";
import {
  collection,
  query,
  getDocs,
  addDoc,
  updateDoc,
  where,
  orderBy,
  deleteDoc,
  doc,
} from "firebase/firestore";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import { useNavigate } from "react-router-dom";
import { lighten } from "@mui/material/styles";
import { gapi } from "gapi-script";
import KeyIcon from "@mui/icons-material/Key";
import RoomCreate from "./RoomCreate";
import ShortcutEditorDialog from "./ShortcutEditorDialog";
import { Avatar } from "@mui/material";

import { useTheme } from "@mui/material/styles";
import { ListSubheader } from "@mui/material"; // Add this import

import { getStyles } from "../index";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import TableSortLabel from "@mui/material/TableSortLabel";
import CancelIcon from "@mui/icons-material/Cancel";
import ShareRoomDialog from "./ShareRoomDialog";

import AddIcon from "@mui/icons-material/Add";
import { useSnackbar } from "./SnackbarHandler";
import ThemeFlowBox from "./ThemeFlowBox";
import GoogleIcon from "@mui/icons-material/Google";
import ShareIcon from "@mui/icons-material/Share";
import QrCodeIcon from "@mui/icons-material/QrCode2";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import EditIcon from "@mui/icons-material/Edit";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import ClearIcon from "@mui/icons-material/Clear";
import Logger from "./Logger";
import DeleteIcon from "@mui/icons-material/Delete";

import CheckIcon from "@mui/icons-material/Check";
import LockIcon from "@mui/icons-material/Lock";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import DashboardIcon from "@mui/icons-material/Dashboard";

import { shortcuts } from "./DashboardToolsShortcuts";

import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";

const Room = ({ user }) => {
  const [rooms, setRooms] = useState([]);
  const [isCreatingRoom, setIsCreatingRoom] = useState(false);
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [selectedRoom, setSelectedRoom] = useState(null);

  const theme = useTheme();
  const styles = getStyles(theme);
  const [gapiLoaded, setGapiLoaded] = useState(false);

  const [order, setOrder] = useState(null); // Changed from "asc" to null
  const [orderedBy, setorderedBy] = useState(null); // Changed from "name" to null

  const [shareDialogOpen, setShareDialogOpen] = useState(false);

  const [statusMenuAnchorEl, setStatusMenuAnchorEl] = useState(null);
  const [selectedRoomForStatus, setSelectedRoomForStatus] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [shortcutEditorOpen, setShortcutEditorOpen] = useState(false);

  // Add these state variables inside the Room component
  const [editingRoomId, setEditingRoomId] = useState(null);
  const [editedRoomName, setEditedRoomName] = useState("");

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteConfirmationText, setDeleteConfirmationText] = useState("");
  const [roomToDelete, setRoomToDelete] = useState(null);

  // Modify handleDeleteRoom to store the room before closing the menu
  const handleDeleteRoom = () => {
    setRoomToDelete(selectedRoom); // Store the room before closing menu
    setDeleteDialogOpen(true);
    handleMenuClose();
  };

  const getGradeDisplay = (grade) => {
    const gradeMap = {
      åk1: "Åk 1",
      åk2: "Åk 2",
      åk3: "Åk 3",
      åk4: "Åk 4",
      åk5: "Åk 5",
      åk6: "Åk 6",
      åk7: "Åk 7",
      åk8: "Åk 8",
      åk9: "Åk 9",
      firstRing: "1:an",
      secondRing: "2:an",
      thirdRing: "3:an",
    };

    return gradeMap[grade] || grade;
  };

  const handleOpenShortcutEditor = () => {
    setShortcutEditorOpen(true);
  };

  const handleCloseShortcutEditor = () => {
    setShortcutEditorOpen(false);
    handleMenuClose();
  };

  // Modify handleDeleteConfirm to use roomToDelete instead of selectedRoom
  const handleDeleteConfirm = async () => {
    if (!roomToDelete) return;

    if (deleteConfirmationText === roomToDelete.name) {
      try {
        await deleteDoc(doc(db, "rooms", roomToDelete.id));
        showSnackbar("Rummet har raderats", "success");
        setDeleteDialogOpen(false);
        setDeleteConfirmationText("");
        setRoomToDelete(null); // Clear the stored room
        fetchRooms();
      } catch (error) {
        console.error("Error deleting room:", error);
        showSnackbar("Ett fel uppstod när rummet skulle raderas", "error");
      }
    }
  };

  // Modify handleDeleteCancel to also clear roomToDelete
  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
    setDeleteConfirmationText("");
    setRoomToDelete(null);
  };

  // Add these handlers inside the Room component
  const handleEditStart = (room) => {
    setEditingRoomId(room.id);
    setEditedRoomName(room.name);
  };

  const handleEditSave = async () => {
    try {
      await updateDoc(doc(db, "rooms", editingRoomId), {
        name: editedRoomName,
      });
      showSnackbar("Rummets namn har uppdaterats!", "success");
      setEditingRoomId(null);
      fetchRooms();
    } catch (error) {
      console.error("Error updating room name:", error);
      showSnackbar("Ett fel uppstod när namnet skulle uppdateras", "error");
    }
  };

  const handleEditCancel = () => {
    setEditingRoomId(null);
    setEditedRoomName("");
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Add these new handlers
  const handleStatusMenuOpen = (event, room) => {
    event.stopPropagation(); // Prevent triggering the main menu
    setStatusMenuAnchorEl(event.currentTarget);
    setSelectedRoomForStatus(room);
  };

  const handleStatusMenuClose = () => {
    setStatusMenuAnchorEl(null);
    setSelectedRoomForStatus(null);
  };

  // Add this helper function in your component
  const getStatusIcon = (status) => {
    switch (status) {
      case "active":
        return <RadioButtonCheckedIcon sx={{ color: "#4CAF50" }} />;
      case "paused":
        return <PauseCircleIcon sx={{ color: "#FFA726" }} />;
      case "locked":
        return <LockIcon sx={{ color: "#F44336" }} />;
      default:
        return null;
    }
  };

  // Modify handleStatusChange to accept a room parameter
  const handleStatusChange = async (newStatus, roomToUpdate) => {
    // Use the passed room or fall back to selectedRoom
    const room = roomToUpdate || selectedRoom;
    if (!room) return;

    try {
      await updateDoc(doc(db, "rooms", room.id), {
        status: newStatus,
      });
      showSnackbar("Rummets status har uppdaterats!", "success");
      handleMenuClose();
      handleStatusMenuClose(); // Add this to close both menus
      fetchRooms();
    } catch (error) {
      console.error("Error updating room status:", error);
      showSnackbar("Ett fel uppstod när status skulle uppdateras", "error");
    }
  };

  // Add this handler in your Room component:
  const handleShareDialogOpen = () => {
    Logger.log("selectedRoom: ", selectedRoom);
    setShareDialogOpen(true);
  };

  const handleShareDialogClose = () => {
    setShareDialogOpen(false);
    handleMenuClose();
  };

  // Add this function inside your component
  const handleRequestSort = (property) => {
    const isAsc = orderedBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setorderedBy(property);
  };

  // Add this helper function
  const descendingComparator = (a, b, orderedBy) => {
    if (b[orderedBy] < a[orderedBy]) return -1;
    if (b[orderedBy] > a[orderedBy]) return 1;
    return 0;
  };

  const getComparator = (order, orderedBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderedBy)
      : (a, b) => -descendingComparator(a, b, orderedBy);
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://apis.google.com/js/platform.js";
    script.onload = () => {
      setGapiLoaded(true);
    };
    document.head.appendChild(script);
  }, []);

  useEffect(() => {
    fetchRooms();
  }, []);

  const fetchRooms = async () => {
    try {
      const roomsRef = collection(db, "rooms");
      const q = query(
        roomsRef,
        where("userId", "==", user.uid),
        orderBy("createdAt", "desc"), // Add this line
      );
      const querySnapshot = await getDocs(q);
      const roomsList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setRooms(roomsList);
    } catch (error) {
      console.error("Error fetching rooms:", error);
    }
  };

  const handleMenuOpen = (event, room) => {
    setMenuAnchorEl(event.currentTarget);
    setSelectedRoom(room);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    setSelectedRoom(null);
  };

  const handleCancel = () => {
    setIsCreatingRoom(false);
  };

  const handleFinish = async () => {
    setIsCreatingRoom(false);

    fetchRooms();
  };

  return !isCreatingRoom ? (
    <>
      <Box sx={styles.container}>
        <Box
          sx={{
            ...styles.innerContainer,
            width: { xs: "100%", md: "100%" },
            margin: "0 auto",
          }}
        >
          <Box
            sx={{
              ...styles.buttonsContainer,
              ...styles.additionalButtonsContainer,
            }}
          >
            <Button
              variant="purp"
              startIcon={<RocketLaunchIcon />}
              onClick={() => setIsCreatingRoom(true)}
            >
              Skapa nytt rum
            </Button>
          </Box>
          <Box sx={{ ...styles.headlineLessonBox, mt: 8 }}>
            <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
              Mina rum för elever
            </Typography>
          </Box>
          <Box
            sx={{
              ...styles.freshBox,
              padding: "0px",
              minHeight: { xs: "100%", md: "660px" },
            }}
          >
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                background:
                  theme.palette.mode === "dark" ? "#22303c" : "#f9f9fa",
                borderTopLeftRadius: "7px",
                borderTopRightRadius: "7px",
              }}
            >
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                sx={{
                  borderColor: "divider",
                  "& .MuiTab-root": {
                    textTransform: "none",
                    fontWeight: "500",
                    fontSize: "0.95rem",
                  },
                }}
              >
                <Tab
                  icon={
                    <DashboardIcon
                      sx={{ fontSize: "20px", color: "primary.main" }}
                    />
                  }
                  label="Alla rum"
                  sx={{
                    flexDirection: "row",
                    "& .MuiTab-iconWrapper": {
                      marginRight: 1,
                      marginBottom: 0,
                    },
                  }}
                />
                <Tab
                  icon={
                    <RadioButtonCheckedIcon
                      sx={{ fontSize: "20px", color: "#4CAF50" }}
                    />
                  }
                  label="Aktiva"
                  sx={{
                    flexDirection: "row",
                    "& .MuiTab-iconWrapper": {
                      marginRight: 1,
                      marginBottom: 0,
                    },
                  }}
                />
                <Tab
                  icon={
                    <PauseCircleIcon
                      sx={{ fontSize: "20px", color: "#FFA726" }}
                    />
                  }
                  label="Pausade"
                  sx={{
                    flexDirection: "row",
                    "& .MuiTab-iconWrapper": {
                      marginRight: 1,
                      marginBottom: 0,
                    },
                  }}
                />
                <Tab
                  icon={
                    <LockIcon sx={{ fontSize: "20px", color: "#F44336" }} />
                  }
                  label="Låsta"
                  sx={{
                    flexDirection: "row",
                    "& .MuiTab-iconWrapper": {
                      marginRight: 1,
                      marginBottom: 0,
                    },
                  }}
                />
              </Tabs>
            </Box>
            <Box
              sx={{
                padding: "0px",
                paddingTop: "30px",
                paddingBottom: "30px",
              }}
            >
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <TableSortLabel
                          active={orderedBy === "name"}
                          direction={orderedBy === "name" ? order : "asc"}
                          onClick={() => handleRequestSort("name")}
                        >
                          Namn på rummet
                        </TableSortLabel>
                      </TableCell>

                      <TableCell>Verktyg</TableCell>

                      <TableCell>
                        <TableSortLabel
                          active={orderedBy === "studentCount"}
                          direction={
                            orderedBy === "studentCount" ? order : "asc"
                          }
                          onClick={() => handleRequestSort("studentCount")}
                        >
                          <Tooltip
                            title="Antal elever i rummet"
                            placement="left"
                          >
                            <PeopleOutlineIcon />
                          </Tooltip>
                        </TableSortLabel>
                      </TableCell>
                      <TableCell sx={{ display: "none" }}>
                        <TableSortLabel
                          active={orderedBy === "maxStudents"}
                          direction={
                            orderedBy === "maxStudents" ? order : "asc"
                          }
                          onClick={() => handleRequestSort("maxStudents")}
                        >
                          Max antal
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={orderedBy === "maxStudents"}
                          direction={
                            orderedBy === "maxStudents" ? order : "asc"
                          }
                          onClick={() => handleRequestSort("maxStudents")}
                        >
                          Status
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={orderedBy === "grade"}
                          direction={orderedBy === "grade" ? order : "asc"}
                          onClick={() => handleRequestSort("grade")}
                        >
                          Årskurs
                        </TableSortLabel>
                      </TableCell>
                      <TableCell align="right">Hantera</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {[...rooms]
                      .sort(getComparator(order, orderedBy))
                      .filter((room) => {
                        if (tabValue === 0) return true; // "Alla" tab - show all rooms
                        if (tabValue === 1) return room.status === "active"; // "Aktiva" tab
                        if (tabValue === 2) return room.status === "paused"; // "Pausade" tab
                        if (tabValue === 3) return room.status === "locked"; // "Låsta" tab
                        return true;
                      })
                      .map((room) => (
                        <TableRow
                          key={room.id}
                          onClick={() => navigate(`/app/rum/${room.id}`)}
                          sx={{
                            cursor: "pointer",
                            "&:hover": { backgroundColor: "action.hover" },
                          }}
                        >
                          <TableCell sx={{ width: "400px" }}>
                            {editingRoomId === room.id ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 1,
                                  width: "100%",
                                }}
                              >
                                <Avatar
                                  sx={{
                                    width: 22,
                                    height: 22,
                                    fontSize: "0.775rem",
                                    bgcolor: "primary.main",
                                  }}
                                >
                                  {editedRoomName.charAt(0).toUpperCase()}
                                </Avatar>
                                <TextField
                                  value={editedRoomName}
                                  onChange={(e) =>
                                    setEditedRoomName(e.target.value)
                                  }
                                  size="small"
                                  fullWidth
                                  autoFocus
                                  sx={{ width: "100%" }}
                                  InputProps={{
                                    endAdornment: (
                                      <Box sx={{ display: "flex", gap: 0.5 }}>
                                        <Tooltip title="Spara">
                                          <IconButton
                                            size="small"
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              handleEditSave();
                                            }}
                                          >
                                            <SaveAsIcon fontSize="small" />
                                          </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Avbryt">
                                          <IconButton
                                            size="small"
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              handleEditCancel();
                                            }}
                                          >
                                            <CancelIcon fontSize="small" />
                                          </IconButton>
                                        </Tooltip>
                                      </Box>
                                    ),
                                  }}
                                />
                              </Box>
                            ) : (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  <Avatar
                                    sx={{
                                      width: 22,
                                      height: 22,
                                      fontSize: "0.775rem",
                                      bgcolor: "primary.main",
                                    }}
                                  >
                                    {room.name.charAt(0).toUpperCase()}
                                  </Avatar>
                                  <span>{room.name}</span>
                                </Box>
                                <Tooltip title="Redigera namn">
                                  <IconButton
                                    size="small"
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      handleEditStart(room);
                                    }}
                                  >
                                    <EditIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            )}
                          </TableCell>

                          <TableCell>
                            <Tooltip
                              title={
                                <Box sx={{ p: 1 }}>
                                  {shortcuts
                                    .filter((shortcut) =>
                                      room.shortcuts?.includes(shortcut.id),
                                    )
                                    .map((shortcut, index, arr) => (
                                      <Box
                                        key={shortcut.id}
                                        sx={{
                                          display: "grid",
                                          gridTemplateColumns: "27px 1fr",
                                          gap: 1,
                                          alignItems: "center",
                                          my: 0.5,
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            backgroundColor: (theme) =>
                                              lighten(
                                                theme.palette.primary.main,
                                                0.8,
                                              ),
                                            borderRadius: "50%",
                                            padding: "4px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            position: "relative",
                                            zIndex: arr.length - index,
                                            "&:hover": {
                                              zIndex: arr.length + 1,
                                            },
                                          }}
                                        >
                                          {typeof shortcut.icon === "string" &&
                                          shortcut.icon.includes("http") ? (
                                            <img
                                              src={shortcut.icon}
                                              alt=""
                                              style={{
                                                width: "20px",
                                                height: "20px",
                                                borderRadius: "50%",
                                              }}
                                            />
                                          ) : (
                                            React.cloneElement(shortcut.icon, {
                                              sx: {
                                                fontSize: "1.1rem",
                                                color: "primary.main",
                                              },
                                            })
                                          )}
                                        </Box>
                                        <span>{shortcut.title}</span>
                                      </Box>
                                    ))}
                                </Box>
                              }
                              arrow
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  "& > *:not(:first-of-type)": {
                                    marginLeft: "-8px",
                                  },
                                }}
                              >
                                {(() => {
                                  const filteredShortcuts = shortcuts.filter(
                                    (shortcut) =>
                                      room.shortcuts?.includes(shortcut.id),
                                  );
                                  const visibleShortcuts =
                                    filteredShortcuts.slice(0, 7);
                                  const remainingCount =
                                    filteredShortcuts.length - 7;

                                  return (
                                    <>
                                      {visibleShortcuts.map(
                                        (shortcut, index, arr) => (
                                          <Box
                                            key={shortcut.id}
                                            sx={{
                                              cursor: "pointer",
                                              backgroundColor: (theme) =>
                                                lighten(
                                                  theme.palette.primary.main,
                                                  0.8,
                                                ),
                                              borderRadius: "50%",
                                              padding: "4px",
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                              position: "relative",
                                              zIndex: arr.length - index,
                                              "&:hover": {
                                                zIndex: arr.length + 1,
                                              },
                                            }}
                                          >
                                            {typeof shortcut.icon ===
                                              "string" &&
                                            shortcut.icon.includes("http") ? (
                                              <img
                                                src={shortcut.icon}
                                                alt=""
                                                style={{
                                                  width: "20px",
                                                  height: "20px",
                                                  borderRadius: "50%",
                                                }}
                                              />
                                            ) : (
                                              React.cloneElement(
                                                shortcut.icon,
                                                {
                                                  sx: {
                                                    fontSize: "1.1rem",
                                                    color: "primary.main",
                                                  },
                                                },
                                              )
                                            )}
                                          </Box>
                                        ),
                                      )}
                                      {remainingCount > 0 && (
                                        <Box
                                          sx={{
                                            cursor: "pointer",
                                            backgroundColor: (theme) =>
                                              lighten(
                                                theme.palette.primary.main,
                                                0.8,
                                              ),
                                            borderRadius: "50%",
                                            padding: "4px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            position: "relative",
                                            zIndex: 0,
                                          }}
                                        >
                                          <MoreHorizIcon
                                            sx={{
                                              fontSize: "1.1rem",
                                              color: "primary.main",
                                            }}
                                          />
                                        </Box>
                                      )}
                                    </>
                                  );
                                })()}
                              </Box>
                            </Tooltip>
                          </TableCell>
                          <TableCell>{room.studentCount}</TableCell>
                          <TableCell sx={{ display: "none" }}>
                            {room.maxStudents}
                          </TableCell>

                          <TableCell>
                            <Tooltip title="Ändra status" placement="left">
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 1,
                                  cursor: "pointer",
                                }}
                                onClick={(event) => {
                                  event.stopPropagation(); // Prevent navigation
                                  handleStatusMenuOpen(event, room);
                                }}
                              >
                                {getStatusIcon(room.status)}
                                <Typography sx={{ fontSize: "0.9rem" }}>
                                  {room.status === "active"
                                    ? "Aktiv"
                                    : room.status === "paused"
                                      ? "Pausad"
                                      : "Låst"}
                                </Typography>
                              </Box>
                            </Tooltip>
                          </TableCell>
                          <TableCell>{getGradeDisplay(room.grade)}</TableCell>

                          <TableCell align="right">
                            <IconButton
                              onClick={(event) => {
                                event.stopPropagation(); // This prevents the click from bubbling up to the TableRow
                                handleMenuOpen(event, room);
                              }}
                              size="small"
                            >
                              <MoreHorizIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>

                <Menu
                  anchorEl={menuAnchorEl}
                  open={Boolean(menuAnchorEl)}
                  onClose={handleMenuClose}
                  PaperProps={{
                    sx: {
                      ...styles.roundMenu,
                      borderRadius: "8px",
                    },
                  }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <MenuItem
                    onClick={handleOpenShortcutEditor}
                    sx={styles.menuItemStyle}
                  >
                    <KeyIcon
                      fontSize="small"
                      style={{ marginRight: 8, color: "#7B68EE" }}
                    />
                    Redigera tillgång till verktyg
                  </MenuItem>
                  <Divider />

                  <MenuItem
                    onClick={() => handleStatusChange("active")}
                    sx={styles.menuItemStyle}
                  >
                    <RadioButtonCheckedIcon
                      fontSize="small"
                      style={{ marginRight: 8, color: "#4CAF50" }}
                    />
                    Aktiv
                    {selectedRoom?.status === "active" && (
                      <CheckIcon sx={{ ml: "auto", color: "#7B68EE" }} />
                    )}
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleStatusChange("paused")}
                    sx={styles.menuItemStyle}
                  >
                    <PauseCircleIcon
                      fontSize="small"
                      style={{ marginRight: 8, color: "#FFA726" }}
                    />
                    Pausad
                    {selectedRoom?.status === "paused" && (
                      <CheckIcon sx={{ ml: "auto", color: "#7B68EE" }} />
                    )}
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleStatusChange("locked")}
                    sx={styles.menuItemStyle}
                  >
                    <LockIcon
                      fontSize="small"
                      style={{ marginRight: 8, color: "#F44336" }}
                    />
                    Låst
                    {selectedRoom?.status === "locked" && (
                      <CheckIcon sx={{ ml: "auto", color: "#7B68EE" }} />
                    )}
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    onClick={handleShareDialogOpen}
                    sx={styles.menuItemStyle}
                  >
                    <ShareIcon
                      fontSize="small"
                      style={{ marginRight: 8, color: "#7B68EE" }}
                    />
                    Visa delningsinformation
                  </MenuItem>
                  <Divider />

                  <MenuItem
                    onClick={handleDeleteRoom}
                    sx={styles.menuItemStyle}
                  >
                    <DeleteIcon
                      fontSize="small"
                      style={{ marginRight: 8, color: "#7B68EE" }}
                    />
                    Radera rum
                  </MenuItem>
                </Menu>

                <Menu
                  anchorEl={statusMenuAnchorEl}
                  open={Boolean(statusMenuAnchorEl)}
                  onClose={handleStatusMenuClose}
                  PaperProps={{
                    sx: {
                      ...styles.roundMenu,
                      borderRadius: "8px",
                      minWidth: "160px",
                    },
                  }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <MenuItem
                    onClick={() =>
                      handleStatusChange("active", selectedRoomForStatus)
                    }
                    sx={styles.menuItemStyle}
                  >
                    <RadioButtonCheckedIcon
                      fontSize="small"
                      style={{ marginRight: 8, color: "#4CAF50" }}
                    />
                    Aktiv
                    {selectedRoomForStatus?.status === "active" && (
                      <CheckIcon sx={{ ml: "auto", color: "#7B68EE" }} />
                    )}
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      handleStatusChange("paused", selectedRoomForStatus)
                    }
                    sx={styles.menuItemStyle}
                  >
                    <PauseCircleIcon
                      fontSize="small"
                      style={{ marginRight: 8, color: "#FFA726" }}
                    />
                    Pausad
                    {selectedRoomForStatus?.status === "paused" && (
                      <CheckIcon sx={{ ml: "auto", color: "#7B68EE" }} />
                    )}
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      handleStatusChange("locked", selectedRoomForStatus)
                    }
                    sx={styles.menuItemStyle}
                  >
                    <LockIcon
                      fontSize="small"
                      style={{ marginRight: 8, color: "#F44336" }}
                    />
                    Låst
                    {selectedRoomForStatus?.status === "locked" && (
                      <CheckIcon sx={{ ml: "auto", color: "#7B68EE" }} />
                    )}
                  </MenuItem>
                </Menu>
              </TableContainer>

              {rooms.length === 0 && (
                <Box sx={{ textAlign: "center", mt: 2 }}>
                  <Typography>Inga rum skapade än</Typography>
                </Box>
              )}

              <Box
                sx={{
                  textAlign: "center",
                  mt: 10,
                  mb: 6,
                  display: rooms.length > 0 ? "none" : undefined,
                }}
              >
                <Button
                  variant="purp"
                  startIcon={<RocketLaunchIcon />}
                  onClick={() => setIsCreatingRoom(true)}
                >
                  Skapa nytt rum
                </Button>
              </Box>
            </Box>{" "}
            {/* End of Box with padding 30px */}
          </Box>{" "}
          {/* End of freshBox */}
          <ShareRoomDialog
            open={shareDialogOpen}
            onClose={handleShareDialogClose}
            room={selectedRoom}
          />
          <Dialog
            open={deleteDialogOpen}
            onClose={handleDeleteCancel}
            PaperProps={{
              sx: {
                width: "100%",
                maxWidth: "700px",
                borderRadius: "8px",
                p: 2,
              },
            }}
          >
            <DialogTitle sx={{ pb: 1, fontWeight: "700", mb: 5 }}>
              Bekräfta radering av rum
            </DialogTitle>
            <DialogContent>
              <Typography variant="body1" sx={{ mb: 2 }}>
                Skriv <strong>"{roomToDelete?.name}"</strong> i fältet nedan och
                klicka på bekräfta-knappen för att radera detta rum.
              </Typography>
              <TextField
                fullWidth
                value={deleteConfirmationText}
                onChange={(e) => setDeleteConfirmationText(e.target.value)}
                placeholder="Skriv namnet på rummet här"
                sx={{ mt: 1 }}
              />
            </DialogContent>
            <DialogActions sx={{ px: 3, pb: 2 }}>
              <Button onClick={handleDeleteCancel} variant="print">
                Avbryt
              </Button>
              <Button
                onClick={handleDeleteConfirm}
                variant="error-gradient"
                disabled={deleteConfirmationText !== roomToDelete?.name}
              >
                Radera rum
              </Button>
            </DialogActions>
          </Dialog>
          <ShortcutEditorDialog
            open={shortcutEditorOpen}
            onClose={handleCloseShortcutEditor}
            room={selectedRoom}
            onUpdate={fetchRooms}
            user={user}
            styles={styles}
          />
        </Box>
      </Box>
    </>
  ) : (
    <RoomCreate user={user} onCancel={handleCancel} onFinish={handleFinish} />
  );
};

// Move export statement outside of the component
export default Room;
