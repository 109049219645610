import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  TextField,
  IconButton,
  Box,
  Divider,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import FlashcardItem from "../flashcards/FlashcardItem";
import { useTheme } from "@mui/material/styles";
import { getStyles } from "../../index";
import { sanitizeInput } from "../functions";
import SaveAsIcon from "@mui/icons-material/SaveAs";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Chip } from "@mui/material";
const commonTags = [
  "Matematik",
  "Svenska",
  "Engelska",
  "Samhällskunskap",
  "Naturkunskap",
  "Kemi",
  "Fysik",
  "Biologi",
  "Historia",
  "Religion",
  "Geografi",
  "Programmering",
  "Teknik",
  "Bild",
  "Musik",
  "Slöjd",
  "Modersmål",
  "Moderna språk",
  "Språk",
  "Idrott och hälsa",
  "Spanska",
  "Tyska",
  "Franska",
  "Hem- och konsumentkunskap",
  "Företagsekonomi",
  "Psykologi",
  "Filosofi",
  "1-3",
  "4-6",
  "7-9",
  "Gymnasiet",
  "Nationella provet",
  "Påsk",
  "Alla hjärtans dag",
];

const FlashcardEditorDialog = ({
  open,
  onClose,
  collection,
  user,
  showSnackbar,
  onEditComplete,
}) => {
  const [flashcards, setFlashcards] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const theme = useTheme();
  const styles = getStyles(theme);
  const [collectionName, setCollectionName] = useState("");

  const [tags, setTags] = useState(collection?.tags || []);
  const [tagInput, setTagInput] = useState("");

  // Add this useEffect to initialize the collection name:
  useEffect(() => {
    if (collection?.collectionName) {
      setCollectionName(collection.collectionName);
    }
  }, [collection]);

  useEffect(() => {
    if (collection?.tags) {
      setTags(collection.tags);
    }
  }, [collection]);

  useEffect(() => {
    console.log("coll ", collection);
    if (collection?.flashcards) {
      setFlashcards(collection.flashcards);
    }
  }, [collection]);

  const handleFrontChange = (index, e) => {
    const newFlashcards = [...flashcards];
    newFlashcards[index].front = e.target.value;
    setFlashcards(newFlashcards);
  };

  const handleBackChange = (index, e) => {
    const newFlashcards = [...flashcards];
    newFlashcards[index].back = e.target.value;
    setFlashcards(newFlashcards);
  };

  const handleDeleteCard = (index) => {
    const newFlashcards = flashcards.filter((_, i) => i !== index);
    setFlashcards(newFlashcards);
  };

  const handleAddCard = (index = -1) => {
    const newFlashcards = [...flashcards];
    newFlashcards.splice(index + 1, 0, {
      front: "",
      back: "",
      correct: "false",
      imageUrlFront: "",
      imageUrlBack: "",
    });
    setFlashcards(newFlashcards);
    setTimeout(() => {
      document.getElementById(`front-${index + 1}`)?.focus();
    }, 0);
  };

  const handleTabPress = (index, isBack, e) => {
    if (e.key === "Tab" && !e.shiftKey) {
      e.preventDefault();
      if (isBack) {
        if (index === flashcards.length - 1) {
          handleAddCard(index);
        } else {
          document.getElementById(`front-${index + 1}`)?.focus();
        }
      } else {
        document.getElementById(`back-${index}`)?.focus();
      }
    }
  };

  const handleSave = async () => {
    if (
      flashcards.some((card) => !card?.front?.trim() || !card?.back?.trim())
    ) {
      showSnackbar(
        "Inga fält för framsida eller baksida får vara tomma.",
        "info",
      );
      return;
    }

    if (!collectionName.trim()) {
      showSnackbar("Samlingens namn får inte vara tomt.", "info");
      return;
    }

    setIsSaving(true);

    try {
      const sanitizedFlashcards = flashcards.map((card) => ({
        front: sanitizeInput(card.front),
        back: sanitizeInput(card.back),
        correct: card.correct,
        imageUrlFront: card.imageUrlFront || "",
        imageUrlBack: card.imageUrlBack || "",
      }));

      const docRef = doc(db, "flashcardCollections", collection.id);
      await updateDoc(docRef, {
        flashcards: sanitizedFlashcards,
        collectionName: sanitizeInput(collectionName),
        tags: tags,
      });

      showSnackbar("Flashcards har uppdaterats!", "success");
      onEditComplete && onEditComplete(sanitizedFlashcards);
      onClose();
    } catch (error) {
      showSnackbar(
        "Fel vid uppdatering av flashcards: " + error.message,
        "error",
      );
    } finally {
      setIsSaving(false);
    }
  };

  const handleClose = () => {
    setTags([]);
    setTagInput("");
    setCollectionName("");
    setFlashcards([]);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        sx: {
          minHeight: "80vh",
          maxHeight: "90vh",
        },
      }}
    >
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: "600" }}>
            Redigera flashcards - {collection?.collectionName}
          </Typography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Box sx={{ px: 3, pt: 2 }}>
        <TextField
          fullWidth
          label="Samlingens namn"
          value={collectionName}
          onChange={(e) => setCollectionName(e.target.value)}
        />
      </Box>
      <DialogContent sx={{ py: 2 }}>
        {flashcards.map((flashcard, index) => (
          <FlashcardItem
            key={index}
            flashcard={flashcard}
            index={index}
            handleFrontChange={handleFrontChange}
            handleBackChange={handleBackChange}
            handleDeleteCard={handleDeleteCard}
            handleTabPress={handleTabPress}
            isDark={theme.palette.mode === "dark"}
            styles={styles}
            user={user}
            showSnackbar={showSnackbar}
            onImageUpdate={(index, url, side) => {
              const newFlashcards = [...flashcards];
              if (side === "front") {
                newFlashcards[index].imageUrlFront = url;
              } else {
                newFlashcards[index].imageUrlBack = url;
              }
              setFlashcards(newFlashcards);
            }}
          />
        ))}

        <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
          <Button
            startIcon={<AddIcon />}
            variant="print"
            onClick={() => handleAddCard(flashcards.length - 1)}
            sx={{ mb: 5 }}
          >
            Lägg till flashcard
          </Button>
        </Box>

        <Box sx={{ mt: 4, px: 3 }}>
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            Taggar (valfritt):
          </Typography>
          <Box sx={{ mb: 2 }}>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
              Vanliga taggar:
            </Typography>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mb: 2 }}>
              {commonTags.map((tag) => (
                <Button
                  key={tag}
                  variant={
                    tag === "1-3" ||
                    tag === "4-6" ||
                    tag === "7-9" ||
                    tag === "Gymnasiet"
                      ? "success"
                      : tag === "Nationella provet"
                        ? "purp"
                        : "print"
                  }
                  startIcon={
                    tags.includes(tag) ? (
                      <CheckCircleIcon sx={{ color: "green" }} />
                    ) : null
                  }
                  onClick={() => {
                    if (tags.includes(tag)) {
                      setTags(tags.filter((t) => t !== tag));
                    } else {
                      setTags([...tags, tag]);
                    }
                  }}
                  sx={{
                    ...styles.tightButton,
                  }}
                >
                  {tag}
                </Button>
              ))}
            </Box>
          </Box>
          <Box sx={{ mb: 3 }}>
            <TextField
              fullWidth
              value={tagInput}
              onChange={(e) => setTagInput(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter" && tagInput.trim()) {
                  setTags([...new Set([...tags, tagInput.trim()])]);
                  setTagInput("");
                }
              }}
              placeholder="Skriv och tryck enter för att lägga till tagg"
              variant="outlined"
            />
            <Box sx={{ mt: 1, display: "flex", flexWrap: "wrap", gap: 1 }}>
              {tags.map((tag) => (
                <Chip
                  key={tag}
                  label={tag}
                  onDelete={() => setTags(tags.filter((t) => t !== tag))}
                  sx={{ m: 0.5 }}
                />
              ))}
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ px: 3, pb: 3 }}>
        <Button onClick={handleClose} variant="print" startIcon={<CloseIcon />}>
          Avbryt
        </Button>
        <Button
          onClick={handleSave}
          variant="purp"
          disabled={isSaving}
          startIcon={
            isSaving ? (
              <CircularProgress sx={{ color: "white" }} size={16} />
            ) : (
              <SaveAsIcon />
            )
          }
        >
          {isSaving ? "Sparar ändringar...." : "Spara ändringar"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FlashcardEditorDialog;
