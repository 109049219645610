import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    DialogTitle,
    IconButton,
    Avatar,
    Box,
    Typography,
    Button,
    Popover,
    Tooltip,
    LinearProgress,
    Fade,
    Chip,
} from "@mui/material";
import SettingsDialog from "./SettingsDialog";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import SearchIcon from "@mui/icons-material/Search";
import { TextField, InputAdornment } from "@mui/material";
import ChatIcon from "@mui/icons-material/Chat";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";

import Logger from "./Logger";
import { TbLayoutSidebarLeftCollapse } from "react-icons/tb";

import AttachmentIcon from "@mui/icons-material/Attachment";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import GridViewIcon from "@mui/icons-material/GridView";
import ClearIcon from "@mui/icons-material/Clear";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SettingsPopover from "./SettingsPopover";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import Person3Icon from "@mui/icons-material/Person3";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import NoteIcon from "@mui/icons-material/Note";
import MenuOpenIcon from "@mui/icons-material/MenuBook";
import { Grading as GradingIcon } from "@mui/icons-material";
import AssessmentIcon from "@mui/icons-material/Assessment";
import KeyboardIcon from "@mui/icons-material/Keyboard";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings"; // Add this import
import LightModeIcon from "@mui/icons-material/LightMode";
import { ClipLoader } from "react-spinners";
import LoadingSpinner from "./LoadingSpinner";
import CastForEducationIcon from "@mui/icons-material/CastForEducation";
import MenuIcon from "@mui/icons-material/Menu";
import LanguageIcon from "@mui/icons-material/Language";
import ViewAgendaIcon from "@mui/icons-material/ViewAgenda";
import QuizIcon from "@mui/icons-material/Quiz";
import LogoutIcon from "@mui/icons-material/Logout";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { SvgIcon } from "@mui/material";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import { useTheme } from "@mui/material";
import SchoolIcon from "@mui/icons-material/School";
import { useMediaQuery } from "@mui/material";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

import FeedbackIcon from "@mui/icons-material/Feedback";
import FeedbackDialog from "./FeedbackDialog";
import TeacherList from "./TeacherList";
import { shortcuts } from "./DashboardToolsShortcuts";
import { db } from "./firebase";
import { useAuth } from "./useAuth";
import {
    collection,
    addDoc,
    deleteDoc,
    getDocs,
    query,
    where,
    doc,
    onSnapshot,
} from "firebase/firestore";
import { updateUserTokens } from "./functions";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import CreateIcon from "@mui/icons-material/Create";
import DescriptionIcon from "@mui/icons-material/Description";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import { getStyles } from "../index";
import { signOut } from "firebase/auth";
import { auth } from "./firebase";

const Sidebar = ({
    handleDialogOpen,
    isOpen,
    toggleSidebar,
    isDark,
    toggleDarkMode,
    setOverrideTheme,
}) => {
    const { user, updateUser, justLoggedIn, setJustLoggedIn } = useAuth();
    const theme = useTheme();

    const styles = getStyles(theme);
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const navigate = useNavigate();
    const [openDialog, setOpenDialog] = useState(false);
    const [isLogin, setIsLogin] = useState(true);
    const [activeItem, setActiveItem] = useState("Hem");
    const [anchorEl, setAnchorEl] = useState(null);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(false);

    const [showGuestItem, setShowGuestItem] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [tokens, setTokens] = useState(0);
    const userRef = useRef(user);
    const [expandedCategories, setExpandedCategories] = useState({});

    // const teacherShortcuts = shortcuts.filter(
    //     (shortcut) =>
    //         shortcut.roles.includes("teacher") &&
    //         !["Lektioner", "Läsförståelse"].includes(shortcut.category),
    // );

    const [userDisplayName, setUserDisplayName] = useState(user?.displayName);
    // useEffect(() => {
    //     setUserDisplayName(auth.currentUser?.displayName);
    // }, [auth.currentUser?.displayName]);

    useEffect(() => {
        Logger.log("uname: ", auth.currentUser?.displayName);
        Logger.log("uname dis: ", user?.displayName);
        if (auth.currentUser?.displayName) {
            setUserDisplayName(auth.currentUser.displayName);
        }
    }, [auth.currentUser?.displayName]);

    useEffect(() => {
        if (user?.displayName) {
            setUserDisplayName(user.displayName);
        }
    }, [user?.displayName]);
    const teacherShortcuts = shortcuts.filter((shortcut) =>
        shortcut.roles.includes("teacher"),
    );

    const filteredTeacherShortcuts = teacherShortcuts.filter(
        (shortcut) =>
            shortcut.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
            (shortcut.description &&
                shortcut.description
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())),
    );

    const teacherCategories = [
        ...new Set(
            filteredTeacherShortcuts.map((shortcut) => shortcut.category),
        ),
    ];

    const maxTokens = user.guest
        ? process.env.REACT_APP_GUEST_TOKEN_LIMIT
        : process.env.REACT_APP_REGISTERED_TOKEN_LIMIT;
    const tokensExceeded = tokens > maxTokens;

    const [accountType, setAccountType] = useState(
        user?.accountType || "teacher",
    );

    // Keep all your original state variables
    const [untrainedFlashcardCount, setUntrainedFlashcardCount] = useState(0);
    const [untrainedQuizCount, setUntrainedQuizCount] = useState(0);
    const [undownloadedFiles, setUndownloadedFiles] = useState(0);
    const [unOpenedLessons, setUnOpenedLessons] = useState(0);
    const [teacherSharedQuizCount, setTeacherSharedQuizCount] = useState(0);
    const [teacherSharedFlashcardCount, setTeacherSharedFlashcardCount] =
        useState(0);
    const [teacherSharedFileCount, setTeacherSharedFileCount] = useState(0);
    const [teacherSharedLessonsCount, setTeacherSharedLessonsCount] =
        useState(0);

    useEffect(() => {
        if (!user?.uid) return;

        // Student content
        const unsubscribeFlashcards = onSnapshot(
            query(
                collection(db, "flashcardCollections"),
                where("shared", "==", true),
            ),
            (querySnapshot) => {
                const untrainedCount = querySnapshot.docs.filter((doc) => {
                    const data = doc.data();
                    return (
                        data.userId !== user.uid &&
                        data.sharedTo?.includes(user.uid) &&
                        !data.lastTrainingSession
                    );
                }).length;
                setUntrainedFlashcardCount(untrainedCount);
            },
        );

        const unsubscribeQuizzes = onSnapshot(
            query(collection(db, "quiz"), where("shared", "==", true)),
            (querySnapshot) => {
                const untrainedCount = querySnapshot.docs.filter((doc) => {
                    const data = doc.data();
                    return (
                        data.userId !== user.uid &&
                        data.sharedTo?.includes(user.uid) &&
                        !data.lastTrainingSession
                    );
                }).length;
                setUntrainedQuizCount(untrainedCount);
            },
        );

        const unsubscribeFiles = onSnapshot(
            query(collection(db, "files"), where("shared", "==", true)),
            (querySnapshot) => {
                const untrainedCount = querySnapshot.docs.filter((doc) => {
                    const data = doc.data();
                    return (
                        data.userId !== user.uid &&
                        data.sharedTo?.includes(user.uid) &&
                        !data.downloaded
                    );
                }).length;
                setUndownloadedFiles(untrainedCount);
            },
        );

        const unsubscribeSharedLessons = onSnapshot(
            query(collection(db, "lesson_usage"), where("shared", "==", true)),
            (querySnapshot) => {
                const count = querySnapshot.docs.filter((doc) => {
                    const data = doc.data();
                    return (
                        data.userId !== user.uid &&
                        data.sharedTo?.includes(user.uid) &&
                        !data.hasOpened
                    );
                }).length;
                setUnOpenedLessons(count);
            },
        );

        // Teacher content
        const unsubscribeTeacherQuizzes = onSnapshot(
            query(
                collection(db, "quiz"),
                where("shared", "==", true),
                where("userId", "==", user.uid),
            ),
            (querySnapshot) => {
                const count = querySnapshot.docs.filter((doc) => {
                    return !doc.data().lastTrainingSession;
                }).length;
                setTeacherSharedQuizCount(count);
            },
        );

        const unsubscribeTeacherFlashcards = onSnapshot(
            query(
                collection(db, "flashcardCollections"),
                where("shared", "==", true),
                where("userId", "==", user.uid),
            ),
            (querySnapshot) => {
                const count = querySnapshot.docs.filter((doc) => {
                    return !doc.data().lastTrainingSession;
                }).length;
                setTeacherSharedFlashcardCount(count);
            },
        );

        const unsubscribeTeacherFiles = onSnapshot(
            query(
                collection(db, "files"),
                where("shared", "==", true),
                where("userId", "==", user.uid),
            ),
            (querySnapshot) => {
                const count = querySnapshot.docs.filter((doc) => {
                    return !doc.data().downloaded;
                }).length;
                setTeacherSharedFileCount(count);
            },
        );

        const unsubscribeTeacherLessons = onSnapshot(
            query(
                collection(db, "lesson_usage"),
                where("shared", "==", true),
                where("userId", "==", user.uid),
            ),
            (querySnapshot) => {
                const count = querySnapshot.docs.filter((doc) => {
                    return !doc.data().hasOpened;
                }).length;
                setTeacherSharedLessonsCount(count);
            },
        );

        return () => {
            unsubscribeFlashcards();
            unsubscribeQuizzes();
            unsubscribeFiles();
            unsubscribeSharedLessons();
            unsubscribeTeacherQuizzes();
            unsubscribeTeacherFlashcards();
            unsubscribeTeacherFiles();
            unsubscribeTeacherLessons();
        };
    }, [user?.uid]);

    // 3. ListItems remain the same as before, just using these new count variables

    // Add this effect to listen for user document changes
    useEffect(() => {
        if (user?.uid) {
            const unsubscribe = onSnapshot(
                doc(db, "users", user.uid),
                (docSnapshot) => {
                    if (docSnapshot.exists()) {
                        const userData = docSnapshot.data();
                        setAccountType(userData.accountType || "student");
                    }
                },
            );

            return () => unsubscribe();
        }
    }, [user?.uid]);

    useEffect(() => {
        Logger.log("user updated in sidebar: ", user.displayName);
        Logger.log("acc type: ", user.accountType);
        userRef.current = user;
    }, [user]);

    useEffect(() => {
        if (justLoggedIn) {
            setJustLoggedIn(false);
            //navigate('/app');
        }
    }, [justLoggedIn, navigate, setJustLoggedIn]);

    useEffect(() => {
        if (user) {
            //Logger.log('User in Sidebar useEffect:', user);
            const unsubscribe = onSnapshot(
                doc(db, "users", user.uid),
                (doc) => {
                    if (doc.exists()) {
                        const newTokens = doc.data().tokens || 0;
                        Logger.log(
                            "New tokens:",
                            newTokens,
                            "Max tokens:",
                            maxTokens,
                        );
                        setTokens(newTokens);

                        if (
                            newTokens > maxTokens &&
                            window.location.pathname !==
                                "/app/skapa-konto?tokens=exceeded"
                        ) {
                            Logger.log(
                                "Redirecting to login due to exceeded tokens",
                            );
                            navigate("/app/skapa-konto?tokens=exceeded");
                        }
                    }
                },
            );
            return () => unsubscribe();
        } else {
            Logger.log("No user in Sidebar useEffect");
            setTokens(0);
        }
    }, [user, maxTokens, navigate]);

    const handleLogout = async () => {
        try {
            await signOut(auth);
            navigate("/app/logga-in");
        } catch (error) {
            console.error("Error logging out:", error);
            // Optionally, you can show an error message to the user here
        }
    };

    // useEffect(() => {

    //     // Moonshine. Tidigare: if (user && !user.guest) { // Tog bort user.guest så tokens uppdateras för dom ockås
    //     if (user) {
    //         const unsubscribe = onSnapshot(doc(db, "users", user.uid), (doc) => {
    //             if (doc.exists()) {
    //                 const newTokens = doc.data().tokens || 0;
    //                 setTokens(newTokens);
    //                 if (newTokens > maxTokens) {
    //                     navigate('/app/login');
    //                 }
    //             }
    //         });
    //         return () => unsubscribe();
    //     } else {
    //         setTokens(user?.tokens || 0);
    //         if (user?.tokens > maxTokens) {
    //             navigate('/app/login');
    //         }
    //     }
    // }, [user, maxTokens, navigate]);

    useEffect(() => {
        if (user.guest) {
            const timer = setTimeout(() => {
                setShowGuestItem(true);
            }, 4000); // Delay of 2 seconds

            return () => clearTimeout(timer);
        } else {
            setShowGuestItem(false);
        }
    }, [user.guest]);

    const handleDialogOpenInternal = (isLoginView) => {
        setIsLogin(isLoginView);
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    const handleItemClick = (path, itemName) => {
        navigate(path);
        setActiveItem(itemName);
        setIsDrawerOpen(false);

        if (isMobile) {
            toggleSidebar();
        }
    };

    useEffect(() => {
        if (user) {
            setOpenDialog(false);
            Logger.log("user tokens: ", user.tokens);
        }
    }, [user]);

    const activeStyles = {
        color:
            theme.palette.themeType === "allakando"
                ? theme.palette.primary.hover
                : isDark
                  ? "#fff"
                  : "#8b5cf6",
        backgroundColor:
            theme.palette.themeType === "allakando"
                ? theme.palette.background.card
                : isDark
                  ? "#15202B"
                  : "#eee9ff",
        borderRadius: "7px",
    };

    const openFeedbackDialog = useCallback(() => {
        setFeedbackDialogOpen(true);
    }, []);

    const menuItems = [
        { text: "Hem", icon: <GridViewIcon />, path: "/app" },
        {
            text: "Mina quiz",
            icon: <QuizIcon />,
            path: "/app/mina-quiz",
            chip:
                untrainedQuizCount > 0 ? (
                    <Chip
                        label={untrainedQuizCount}
                        size="small"
                        sx={{
                            backgroundColor: isDark ? "#2a3b4c" : "#e0e7ff",
                            color: isDark ? "#60a5fa" : "#3b82f6",
                            fontWeight: "bold",
                            fontSize: "0.7rem",
                            height: "20px",
                            marginLeft: "8px",
                        }}
                    />
                ) : null,
        },
        {
            text: "Mina flashcards",
            icon: <ViewAgendaIcon />,
            path: "/app/mina-flashcards",
            chip:
                untrainedFlashcardCount > 0 ? (
                    <Chip
                        label={untrainedFlashcardCount}
                        size="small"
                        sx={{
                            backgroundColor: isDark ? "#2a3b4c" : "#e0e7ff",
                            color: isDark ? "#60a5fa" : "#3b82f6",
                            fontWeight: "bold",
                            fontSize: "0.7rem",
                            height: "20px",
                            marginLeft: "8px",
                        }}
                    />
                ) : null,
        },

        {
            text:
                accountType === "teacher" ? "Mina lektioner" : "Mina lektioner",
            icon: <BookmarkBorderIcon />,
            path: "/app/delade-lektioner",
            chip:
                unOpenedLessons > 0 ? (
                    <Chip
                        label={unOpenedLessons}
                        size="small"
                        sx={{
                            backgroundColor: isDark ? "#2a3b4c" : "#e0e7ff",
                            color: isDark ? "#60a5fa" : "#3b82f6",
                            fontWeight: "bold",
                            fontSize: "0.7rem",
                            height: "20px",
                            marginLeft: "8px",
                        }}
                    />
                ) : null,
        },
        {
            text: "Mina filer",
            icon: <AttachmentIcon />,
            path: "/app/mina-filer",
            chip:
                undownloadedFiles > 0 ? (
                    <Chip
                        label={undownloadedFiles}
                        size="small"
                        sx={{
                            backgroundColor: isDark ? "#2a3b4c" : "#e0e7ff",
                            color: isDark ? "#60a5fa" : "#3b82f6",
                            fontWeight: "bold",
                            fontSize: "0.7rem",
                            height: "20px",
                            marginLeft: "8px",
                        }}
                    />
                ) : null,
        },

        {
            type: "header",
            component: (
                <Box
                    sx={{
                        fontWeight: "600",
                        fontSize: "14px",
                        background: isDark ? "#192734" : "white",
                        paddingTop: "32px",
                        paddingLeft: "15px",
                        paddingBottom: "10px",
                        textTransform: "uppercase",
                        display: "block",
                    }}
                >
                    Verktyg
                </Box>
            ),
        },
        { text: "Fråga AI", icon: <ChatIcon />, path: "/app/fraga-ai" },

        { text: "Quiz", icon: <QuizIcon />, path: "/app/quiz" },
        //{ text: 'Språktest', icon: <QuizIcon />, path: '/spraktest' },
        {
            text: "Flashcards",
            icon: <ViewAgendaIcon />,
            path: "/app/flashcards",
        },

        { text: "Textlabbet", icon: <CreateIcon />, path: "/app/textlabbet" },
        {
            text: "Betygsätt min text",
            icon: <GradingIcon />,
            path: "/app/uppsats/ny",
        },

        { text: "AI-karaktärer", icon: <Person3Icon />, path: "/app/personal" },
        {
            text: "Skapa kurs",
            icon: <MenuOpenIcon />,
            path: "/app/kurser",
        },

        {
            text: "Högskoleprovet",
            icon: <SchoolIcon />,
            path: "/app/hogskoleprov",
            // chip: (
            //     <Chip
            //         label='BETA'
            //         size='small'
            //         sx={{
            //             backgroundColor: isDark ? '#2a3b4c' : '#e0e7ff',
            //             color: isDark ? '#60a5fa' : '#3b82f6',
            //             fontWeight: 'bold',
            //             fontSize: '0.7rem',
            //             height: '20px',
            //             marginLeft: '8px',
            //         }}
            //     />
            // ),
        },

        // { text: "Debatt", icon: <QuestionAnswerIcon />, path: "/app/debatt" },

        // {
        //     text: "Skrivmaskinen",
        //     icon: <KeyboardIcon />,
        //     path: "/app/skrivmaskinen",
        // },

        //{ text: 'Historielärare', icon: <HistoryEduIcon />, path: '/historia' },

        // {
        //     text: "AI Detektor",
        //     icon: <AssessmentIcon />,
        //     path: "/app/ai-detektor",
        // },
        // { text: "Sparat", icon: <BookmarkIcon />, path: "/app/sparat" },
        //{ text: 'Ord', icon: <AssessmentIcon />, path: '/hogskoleprovet-verbal-del' },
    ];

    const [showAllMenuItems, setShowAllMenuItems] = useState(false);

    const toggleShowAllMenuItems = () => {
        setShowAllMenuItems(!showAllMenuItems);
    };

    const visibleMenuItems = showAllMenuItems
        ? menuItems
        : menuItems.slice(0, 16);
    const hiddenMenuItemsCount = menuItems.length - 16;

    const handleAvatarClick = (event) => {
        if (user.guest) {
            goToLogin();
        } else {
            setAnchorEl(event.currentTarget);
        }
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const openPopover = Boolean(anchorEl);
    const popoverId = openPopover ? "simple-popover" : undefined;

    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };

    const [teachers, setTeachers] = useState([]);

    const handleDeleteTeacher = async (teacherId) => {
        try {
            await deleteDoc(doc(db, "teachers", teacherId));
            setTeachers((prevTeachers) =>
                prevTeachers.filter((teacher) => teacher.id !== teacherId),
            );
        } catch (error) {
            console.error("Error deleting teacher:", error);
            // Hantera felet (t.ex. visa ett felmeddelande för användaren)
        }
    };

    useEffect(() => {
        if (user && !user.isTemporary) {
            const teachersCollection = collection(db, "teachers");
            const q = query(
                teachersCollection,
                where("userId", "==", user.uid),
            );

            Logger.log("getting teach from ", user.uid);

            const unsubscribeSnapshot = onSnapshot(q, (querySnapshot) => {
                const teachersList = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setTeachers(teachersList);
                setLoading(false);
            });
            return () => unsubscribeSnapshot();
        } else {
            setTeachers([]);
            setLoading(false);
        }
    }, [user]);

    const fetchTeachers = async (userId) => {
        try {
            const teachersCollection = collection(db, "teachers");
            const q = query(teachersCollection, where("userId", "==", userId));
            const teachersSnapshot = await getDocs(q);
            const teachersList = teachersSnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setTeachers(teachersList);
        } catch (error) {
            console.error("Fel vid hämtning av lärare:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleTeacherClick = (teacherId) => {
        navigate(`/app/chat/${teacherId}`);
    };
    const onAddTeacher = () => {
        navigate("/app/larare/");
    };

    const goToLogin = () => {
        navigate("/app/skapa-konto/");
    };

    const onPublicTeachers = () => {
        navigate("/app/personal/");
    };

    useEffect(() => {
        //Logger.log("User object changed:", user);
        //Logger.log("New display name:", user.displayName);
    }, [user]);

    // Add this before your List components
    const listItemStyles = {
        py: 0,
        paddingTop: "5px",
        paddingBottom: "5px",
        "&:not(:last-child)": {
            mb: "3px",
        },
        borderRadius: "7px",
        fontSize: "15px",
        "&:hover": {
            borderRadius: "7px !important",
            "& .MuiListItemIcon-root": {
                color:
                    theme.palette.themeType === "allakando"
                        ? theme.palette.primary.hover
                        : isDark
                          ? "rgb(16, 151, 218)"
                          : "#8b5cf6",
            },
            "& .MuiListItemText-root .MuiTypography-root": {
                color: isDark ? "#fff" : "#000",
            },
            "& .chevron-icon": {
                color:
                    theme.palette.themeType === "allakando"
                        ? theme.palette.primary.hover
                        : isDark
                          ? "rgb(16, 151, 218)"
                          : "#8b5cf6",
            },
        },
    };

    const listItemIconStyles = {
        minWidth: "20px",
        fontSize: "24px",
        paddingRight: "0px",
        color: "#94a3b8",
        "& .MuiSvgIcon-root": {
            fontSize: theme.palette.themeType === "allakando" ? "26px" : "24px",
        },
    };

    const listItemTextStyles = {
        marginLeft: "10px",
        "& .MuiTypography-root": {
            color: isDark ? "#b0b4b8" : "#4a5568",
            fontSize: theme.palette.themeType === "allakando" ? "16px" : "14px",
            fontWeight: "500",
        },
    };

    const chevronIcon = {
        fontSize: "20px",
        color: "#94a3b8",
        marginLeft: "auto",
        display: "none",
    };

    return (
        <>
            <IconButton
                sx={{
                    display: { xs: "block", md: isOpen ? "none" : "block" },
                    zIndex: 999,
                    position: "absolute",
                    top: 8,
                    left: 8,
                }}
                onClick={toggleSidebar}
            >
                <MenuIcon />
            </IconButton>

            <Drawer
                variant={isMobile ? "temporary" : "permanent"}
                open={isOpen}
                onClose={toggleSidebar}
                sx={{
                    backgroundColor: isDark ? "#192734" : "white",
                    width: 270,
                    flexShrink: 0,

                    "& .MuiDrawer-paper": {
                        backgroundColor: isDark ? "#192734" : "white",
                        width: 270,
                        boxSizing: "border-box",

                        display: {
                            xs: isOpen ? "block" : "none",
                            md: isOpen ? "block" : "none",
                        },
                    },
                }}
                PaperProps={{
                    sx: { overflowY: "hidden" },
                }}
            >
                {theme.palette.themeType === "allakando" ? (
                    <Box
                        sx={{
                            position: "absolute",
                            top: 17,
                            left: "50%",
                            transform: "translateX(-50%)",
                            zIndex: 1000,
                        }}
                    >
                        <img
                            src="/img/allakando-logo.svg"
                            alt="Allakando Logo"
                            style={{
                                height: "24px",
                                width: "auto",
                            }}
                        />
                    </Box>
                ) : (
                    <Box
                        sx={{
                            textAlign: "center",
                            mt: 2.6,
                            backgroundColor: isDark ? "#192734" : "white",
                            cursor: "pointer",
                        }}
                        onClick={() => navigate("/app")}
                    >
                        <img
                            src={
                                isDark
                                    ? "/img/logo-dark.png"
                                    : "/img/logo-2.png"
                            }
                            alt="studera.ai"
                            style={{
                                height: "34px",
                                width: "auto",
                                transition: "filter 0.2s",
                                borderRadius: "18px",
                            }}
                            onMouseOver={(e) =>
                                (e.target.style.filter = "brightness(0.96)")
                            }
                            onMouseOut={(e) =>
                                (e.target.style.filter = "brightness(1)")
                            }
                        />
                    </Box>
                )}

                <IconButton
                    sx={{
                        zIndex: 999,
                        position: "absolute",
                        bottom: 60,
                        right: 8,
                    }}
                    onClick={toggleDarkMode}
                >
                    {isDark ? (
                        <LightModeIcon sx={{ opacity: "0.5" }} />
                    ) : (
                        <DarkModeIcon sx={{ opacity: "0.5" }} />
                    )}
                </IconButton>

                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                        background: isDark ? "#192734" : "white",
                    }}
                >
                    <Box
                        sx={{
                            //display: "flex",
                            display: "none",
                            justifyContent: "flex-end",
                            alignItems: "flex-end",
                            p: 1,
                            mt: 1.3,
                        }}
                    >
                        <IconButton onClick={toggleSidebar}>
                            {" "}
                            <TbLayoutSidebarLeftCollapse />
                        </IconButton>
                    </Box>

                    <Box
                        sx={{
                            flexGrow: 1,
                            overflowY: "auto",
                            mt: 1,
                            marginBottom: "120px",

                            //marginBottom: "65px",
                            paddingBottom: "20px",

                            "&::-webkit-scrollbar": {
                                width: "8px",
                            },
                            "&::-webkit-scrollbar-track": {
                                background: "transparent",
                            },
                            "&::-webkit-scrollbar-thumb": {
                                background: isDark
                                    ? "rgba(255, 255, 255, 0.05)" // Lighter default for dark mode
                                    : "rgb(0 0 0 / 34%) transparent", // Lighter default for light mode
                                borderRadius: "4px",
                                "&:hover": {
                                    background: isDark
                                        ? "rgba(255, 255, 255, 0.1)" // Previous default color now on hover
                                        : "rgba(0, 0, 0, 0.06)", // Previous default color now on hover
                                },
                            },
                            // For Firefox
                            scrollbarWidth: "auto",
                            scrollbarColor: isDark
                                ? "rgba(255, 255, 255, 0.05) transparent" // Matching the webkit lighter color
                                : "rgb(0 0 0 / 14%) transparent", // Matching the webkit lighter color
                        }}
                    >
                        {loading ? (
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "100%",
                                }}
                            >
                                <LoadingSpinner loading={loading} />
                                {/*<LoadingSpinner size="20" noWrapper="true" /> */}
                            </Box>
                        ) : (
                            <>
                                <Box
                                    sx={{
                                        display:
                                            accountType === "teacher" ||
                                            accountType === "substitute"
                                                ? "block"
                                                : "none",
                                    }}
                                >
                                    <Box sx={{ px: 2, py: 1, display: "none" }}>
                                        <TextField
                                            size="small"
                                            placeholder="Sök..."
                                            value={searchTerm}
                                            onChange={(e) =>
                                                setSearchTerm(e.target.value)
                                            }
                                            fullWidth
                                            sx={{
                                                "& .MuiOutlinedInput-root": {
                                                    borderRadius: "9999px",

                                                    backgroundColor: isDark
                                                        ? "#22303C"
                                                        : "#f4f4f5",
                                                    "& fieldset": {
                                                        borderColor:
                                                            "transparent",
                                                    },
                                                    "&:hover fieldset": {
                                                        borderColor:
                                                            "transparent",
                                                    },
                                                    "&.Mui-focused fieldset": {
                                                        borderColor: "#8b5cf6",
                                                    },
                                                },
                                            }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon
                                                            sx={{
                                                                color: "#94a3b8",
                                                            }}
                                                        />
                                                    </InputAdornment>
                                                ),
                                                endAdornment: searchTerm && (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            size="small"
                                                            onClick={() =>
                                                                setSearchTerm(
                                                                    "",
                                                                )
                                                            }
                                                            sx={{
                                                                color: "#94a3b8",
                                                            }}
                                                        >
                                                            <ClearIcon fontSize="small" />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Box>

                                    <List sx={{ py: 0, margin: "8px" }}>
                                        {/* Your new list item */}
                                        <ListItem
                                            button
                                            component={Link}
                                            to="/app" // Replace with your path
                                            onClick={() => {
                                                setActiveItem("Hem");
                                                if (isMobile) toggleSidebar();
                                            }}
                                            sx={{
                                                ...listItemStyles,
                                                // ...(activeItem === "Hem"
                                                //     ? activeStyles
                                                //     : {}),
                                            }}
                                        >
                                            <ListItemIcon
                                                sx={listItemIconStyles}
                                            >
                                                <GridViewIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary="Hem"
                                                sx={listItemTextStyles}
                                            />
                                            <ChevronRightIcon
                                                className="chevron-icon"
                                                sx={chevronIcon}
                                            />
                                        </ListItem>

                                        {user?.admin && (
                                            <>
                                                <ListItem
                                                    button
                                                    component={Link}
                                                    to="/app/lektionsbanken"
                                                    onClick={() => {
                                                        if (isMobile)
                                                            toggleSidebar();
                                                    }}
                                                    sx={{
                                                        ...listItemStyles,
                                                        ...(activeItem ===
                                                        "Admin"
                                                            ? activeStyles
                                                            : {}),
                                                    }}
                                                >
                                                    <ListItemIcon
                                                        sx={listItemIconStyles}
                                                    >
                                                        <CastForEducationIcon />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary="Lektionsbanken"
                                                        sx={listItemTextStyles}
                                                    />
                                                    <ChevronRightIcon
                                                        className="chevron-icon"
                                                        sx={chevronIcon}
                                                    />
                                                </ListItem>

                                                <ListItem
                                                    button
                                                    component={Link}
                                                    to="/app/hood"
                                                    onClick={() => {
                                                        setActiveItem("Admin");
                                                        if (isMobile)
                                                            toggleSidebar();
                                                    }}
                                                    sx={{
                                                        ...listItemStyles,
                                                        ...(activeItem ===
                                                        "Admin"
                                                            ? activeStyles
                                                            : {}),
                                                    }}
                                                >
                                                    <ListItemIcon
                                                        sx={listItemIconStyles}
                                                    >
                                                        <AdminPanelSettingsIcon />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary="Admin"
                                                        sx={listItemTextStyles}
                                                    />
                                                    <ChevronRightIcon
                                                        className="chevron-icon"
                                                        sx={chevronIcon}
                                                    />
                                                </ListItem>
                                            </>
                                        )}
                                        {accountType === "teacher" && (
                                            <>
                                                <ListItem
                                                    button
                                                    component={Link}
                                                    to="/app/mina-quiz"
                                                    onClick={() => {
                                                        if (isMobile)
                                                            toggleSidebar();
                                                    }}
                                                    sx={{
                                                        ...listItemStyles,
                                                        ...(activeItem ===
                                                        "Admin"
                                                            ? activeStyles
                                                            : {}),
                                                    }}
                                                >
                                                    <ListItemIcon
                                                        sx={listItemIconStyles}
                                                    >
                                                        <QuizIcon />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary="Mina quiz"
                                                        sx={listItemTextStyles}
                                                    />

                                                    {teacherSharedQuizCount >
                                                        0 && (
                                                        <Tooltip
                                                            title={`${teacherSharedQuizCount} elever som inte börjat öva än`}
                                                            placement="right"
                                                        >
                                                            <Chip
                                                                label={
                                                                    teacherSharedQuizCount
                                                                }
                                                                size="small"
                                                                sx={{
                                                                    backgroundColor:
                                                                        isDark
                                                                            ? "#2a3b4c"
                                                                            : "#e0e7ff",
                                                                    color: isDark
                                                                        ? "#60a5fa"
                                                                        : "#3b82f6",
                                                                    fontWeight:
                                                                        "bold",
                                                                    fontSize:
                                                                        "0.7rem",
                                                                    height: "20px",
                                                                    marginLeft:
                                                                        "8px",
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    )}

                                                    <ChevronRightIcon
                                                        className="chevron-icon"
                                                        sx={chevronIcon}
                                                    />
                                                </ListItem>

                                                <ListItem
                                                    button
                                                    component={Link}
                                                    to="/app/mina-flashcards"
                                                    onClick={() => {
                                                        if (isMobile)
                                                            toggleSidebar();
                                                    }}
                                                    sx={{
                                                        ...listItemStyles,
                                                        ...(activeItem ===
                                                        "Admin"
                                                            ? activeStyles
                                                            : {}),
                                                    }}
                                                >
                                                    <ListItemIcon
                                                        sx={listItemIconStyles}
                                                    >
                                                        <ViewAgendaIcon />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary="Mina flashcards"
                                                        sx={listItemTextStyles}
                                                    />

                                                    {teacherSharedFlashcardCount >
                                                        0 && (
                                                        <Tooltip
                                                            title={`${teacherSharedFlashcardCount} elever som inte börjat öva än`}
                                                            placement="right"
                                                        >
                                                            <Chip
                                                                label={
                                                                    teacherSharedFlashcardCount
                                                                }
                                                                size="small"
                                                                sx={{
                                                                    backgroundColor:
                                                                        isDark
                                                                            ? "#2a3b4c"
                                                                            : "#e0e7ff",
                                                                    color: isDark
                                                                        ? "#60a5fa"
                                                                        : "#3b82f6",
                                                                    fontWeight:
                                                                        "bold",
                                                                    fontSize:
                                                                        "0.7rem",
                                                                    height: "20px",
                                                                    marginLeft:
                                                                        "8px",
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    )}

                                                    <ChevronRightIcon
                                                        className="chevron-icon"
                                                        sx={chevronIcon}
                                                    />
                                                </ListItem>

                                                <ListItem
                                                    button
                                                    component={Link}
                                                    to="/app/delade-lektioner"
                                                    onClick={() => {
                                                        if (isMobile)
                                                            toggleSidebar();
                                                    }}
                                                    sx={{
                                                        ...listItemStyles,
                                                        ...(activeItem ===
                                                        "Admin"
                                                            ? activeStyles
                                                            : {}),
                                                    }}
                                                >
                                                    <ListItemIcon
                                                        sx={listItemIconStyles}
                                                    >
                                                        <BookmarkBorderIcon />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary="Mina lektioner"
                                                        sx={listItemTextStyles}
                                                    />

                                                    {teacherSharedLessonsCount >
                                                        0 && (
                                                        <Tooltip
                                                            title={`${teacherSharedLessonsCount} elever som inte öppnat än`}
                                                            placement="right"
                                                        >
                                                            <Chip
                                                                label={
                                                                    teacherSharedLessonsCount
                                                                }
                                                                size="small"
                                                                sx={{
                                                                    backgroundColor:
                                                                        isDark
                                                                            ? "#2a3b4c"
                                                                            : "#e0e7ff",
                                                                    color: isDark
                                                                        ? "#60a5fa"
                                                                        : "#3b82f6",
                                                                    fontWeight:
                                                                        "bold",
                                                                    fontSize:
                                                                        "0.7rem",
                                                                    height: "20px",
                                                                    marginLeft:
                                                                        "8px",
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    )}

                                                    <ChevronRightIcon
                                                        className="chevron-icon"
                                                        sx={chevronIcon}
                                                    />
                                                </ListItem>

                                                <ListItem
                                                    button
                                                    component={Link}
                                                    to="/app/mina-filer"
                                                    onClick={() => {
                                                        if (isMobile)
                                                            toggleSidebar();
                                                    }}
                                                    sx={{
                                                        ...listItemStyles,
                                                        ...(activeItem ===
                                                        "Admin"
                                                            ? activeStyles
                                                            : {}),
                                                    }}
                                                >
                                                    <ListItemIcon
                                                        sx={listItemIconStyles}
                                                    >
                                                        <AttachmentIcon />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary="Mina filer"
                                                        sx={listItemTextStyles}
                                                    />

                                                    {teacherSharedFileCount >
                                                        0 && (
                                                        <Tooltip
                                                            title={`${teacherSharedFileCount} elever som inte laddat ned än`}
                                                            placement="right"
                                                        >
                                                            <Chip
                                                                label={
                                                                    teacherSharedFileCount
                                                                }
                                                                size="small"
                                                                sx={{
                                                                    backgroundColor:
                                                                        isDark
                                                                            ? "#2a3b4c"
                                                                            : "#e0e7ff",
                                                                    color: isDark
                                                                        ? "#60a5fa"
                                                                        : "#3b82f6",
                                                                    fontWeight:
                                                                        "bold",
                                                                    fontSize:
                                                                        "0.7rem",
                                                                    height: "20px",
                                                                    marginLeft:
                                                                        "8px",
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    )}

                                                    <ChevronRightIcon
                                                        className="chevron-icon"
                                                        sx={chevronIcon}
                                                    />
                                                </ListItem>

                                                <ListItem
                                                    button
                                                    component={Link}
                                                    to="/app/rum"
                                                    onClick={() => {
                                                        if (isMobile)
                                                            toggleSidebar();
                                                    }}
                                                    sx={{
                                                        ...listItemStyles,
                                                        backgroundColor:
                                                            theme.palette
                                                                .primary.main,
                                                        "& .MuiListItemIcon-root":
                                                            {
                                                                color: "#fff",
                                                            },
                                                        "& .MuiListItemText-root .MuiTypography-root":
                                                            {
                                                                color: "#fff",
                                                            },
                                                        "&:hover": {
                                                            ...listItemStyles[
                                                                "&:hover"
                                                            ],
                                                            backgroundColor:
                                                                theme.palette
                                                                    .primary
                                                                    .main,
                                                            "& .MuiListItemIcon-root":
                                                                {
                                                                    color: "#fff",
                                                                    background:
                                                                        "none",
                                                                },
                                                            "& .MuiListItemText-root .MuiTypography-root":
                                                                {
                                                                    color: "#fff",
                                                                },
                                                        },
                                                    }}
                                                >
                                                    <ListItemIcon
                                                        sx={listItemIconStyles}
                                                    >
                                                        <RocketLaunchIcon />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary="Aktivera för elever"
                                                        sx={listItemTextStyles}
                                                    />
                                                    <ChevronRightIcon
                                                        className="chevron-icon"
                                                        sx={chevronIcon}
                                                    />
                                                </ListItem>
                                            </>
                                        )}
                                    </List>

                                    {/* Add this before TeacherList */}

                                    {teacherCategories.map((category) => {
                                        const categoryShortcuts =
                                            filteredTeacherShortcuts
                                                .filter((item) => {
                                                    // Show 'Skapa konto' only for guest users
                                                    if (
                                                        item.title ===
                                                        "Skapa konto"
                                                    ) {
                                                        return user.guest;
                                                    }
                                                    if (
                                                        item.title ===
                                                        "Generera Powerpoints"
                                                    ) {
                                                        return false;
                                                    }

                                                    // Show all other items
                                                    return true;
                                                })
                                                .filter(
                                                    (item) =>
                                                        item.category ===
                                                        category,
                                                );

                                        const isExpanded =
                                            expandedCategories[category];
                                        const visibleShortcuts = isExpanded
                                            ? categoryShortcuts
                                            : categoryShortcuts.slice(0, 5);
                                        const hasMoreShortcuts =
                                            categoryShortcuts.length > 5;

                                        const toggleCategory = () => {
                                            setExpandedCategories((prev) => ({
                                                ...prev,
                                                [category]: !prev[category],
                                            }));
                                        };

                                        return (
                                            <React.Fragment key={category}>
                                                <Box
                                                    sx={{
                                                        fontWeight: "600",
                                                        fontSize: "14px",
                                                        background: isDark
                                                            ? "#192734"
                                                            : "white",
                                                        paddingTop: "32px",
                                                        paddingLeft: "15px",
                                                        textTransform:
                                                            "uppercase",
                                                        display:
                                                            category.length > 0
                                                                ? "block"
                                                                : "none",
                                                    }}
                                                >
                                                    {category}
                                                </Box>
                                                <List
                                                    sx={{
                                                        py: 0,
                                                        margin: "8px",
                                                    }}
                                                >
                                                    {visibleShortcuts.map(
                                                        (item) => (
                                                            <ListItem
                                                                button
                                                                key={item.title}
                                                                component={Link}
                                                                to={item.path}
                                                                onClick={() => {
                                                                    if (
                                                                        isMobile
                                                                    )
                                                                        toggleSidebar();
                                                                }}
                                                                sx={{
                                                                    ...listItemStyles,
                                                                    ...(activeItem ===
                                                                    item.title
                                                                        ? activeStyles
                                                                        : {}),
                                                                }}
                                                            >
                                                                <ListItemIcon
                                                                    sx={
                                                                        listItemIconStyles
                                                                    }
                                                                >
                                                                    {typeof item.icon ===
                                                                    "string" ? (
                                                                        <Avatar
                                                                            src={
                                                                                item.icon
                                                                            }
                                                                            sx={{
                                                                                width: 20,
                                                                                height: 20,
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        React.cloneElement(
                                                                            item.icon,
                                                                            {
                                                                                sx: {
                                                                                    fontSize:
                                                                                        "20px",
                                                                                },
                                                                            },
                                                                        )
                                                                    )}
                                                                </ListItemIcon>
                                                                <ListItemText
                                                                    primary={
                                                                        item.title
                                                                    }
                                                                    sx={
                                                                        listItemTextStyles
                                                                    }
                                                                />
                                                                {item.label && (
                                                                    <Chip
                                                                        label={
                                                                            item.label
                                                                        }
                                                                        size="small"
                                                                        sx={{
                                                                            display:
                                                                                "none",
                                                                            backgroundColor:
                                                                                isDark
                                                                                    ? "#2a3b4c"
                                                                                    : "#e0e7ff",
                                                                            color: isDark
                                                                                ? "#60a5fa"
                                                                                : "#3b82f6",
                                                                            fontWeight:
                                                                                "bold",
                                                                            fontSize:
                                                                                "0.7rem",
                                                                            height: "20px",
                                                                        }}
                                                                    />
                                                                )}
                                                                <ChevronRightIcon
                                                                    className="chevron-icon"
                                                                    sx={
                                                                        chevronIcon
                                                                    }
                                                                />
                                                            </ListItem>
                                                        ),
                                                    )}

                                                    {hasMoreShortcuts && (
                                                        <ListItem
                                                            button
                                                            onClick={
                                                                toggleCategory
                                                            }
                                                            sx={{
                                                                ...listItemStyles,
                                                            }}
                                                        >
                                                            <ListItemIcon
                                                                sx={
                                                                    listItemIconStyles
                                                                }
                                                            >
                                                                <MoreHorizIcon
                                                                    sx={{
                                                                        color: "#94a3b8",
                                                                    }}
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                primary={
                                                                    isExpanded
                                                                        ? "Visa färre"
                                                                        : "Visa fler"
                                                                }
                                                                sx={{
                                                                    ...listItemTextStyles,
                                                                    "& .MuiTypography-root":
                                                                        {
                                                                            ...listItemTextStyles[
                                                                                "& .MuiTypography-root"
                                                                            ],
                                                                            color: "#94a3b8",
                                                                        },
                                                                }}
                                                            />
                                                        </ListItem>
                                                    )}
                                                </List>
                                            </React.Fragment>
                                        );
                                    })}
                                </Box>
                                <List
                                    sx={{
                                        py: 0,
                                        margin: "8px",
                                        display:
                                            accountType === "teacher" ||
                                            accountType === "substitute"
                                                ? "none"
                                                : "block",
                                    }}
                                >
                                    {visibleMenuItems.map((item) => {
                                        if (item.type === "header") {
                                            return item.component;
                                        }
                                        return (
                                            <ListItem
                                                button
                                                component={Link}
                                                to={item.path}
                                                key={item.text}
                                                onClick={() => {
                                                    setActiveItem(item.text);
                                                    if (isMobile)
                                                        toggleSidebar();
                                                }}
                                                sx={{
                                                    ...listItemStyles, // Apply the shared styles
                                                    ...(activeItem ===
                                                    item.title
                                                        ? activeStyles
                                                        : {}),
                                                }}
                                            >
                                                <ListItemIcon
                                                    sx={listItemIconStyles}
                                                >
                                                    {React.cloneElement(
                                                        item.icon,
                                                        {
                                                            sx: {
                                                                fontSize:
                                                                    "20px",
                                                            },
                                                        },
                                                    )}
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={item.text}
                                                    sx={listItemTextStyles}
                                                />
                                                {item.chip}
                                            </ListItem>
                                        );
                                    })}

                                    {menuItems.length > 20 && (
                                        <ListItem
                                            button
                                            onClick={toggleShowAllMenuItems}
                                            className="rounded"
                                            sx={{
                                                marginBottom: "3px",
                                                paddingTop: "7px",
                                                paddingBottom: "7px",
                                                color: "#94a3b8",
                                            }}
                                        >
                                            <ListItemIcon
                                                sx={{
                                                    minWidth: "32px",
                                                    paddingRight: "8px",
                                                }}
                                            >
                                                <MoreHorizIcon
                                                    sx={{ color: "#94a3b8" }}
                                                />
                                            </ListItemIcon>
                                            <ListItemText
                                                //primary={showAllMenuItems ? "Visa färre" : `${hiddenMenuItemsCount} fler`}
                                                primary={
                                                    showAllMenuItems
                                                        ? "Mindre"
                                                        : `Mer`
                                                }
                                                sx={{
                                                    marginLeft: "10px",
                                                    color: "#94a3b8 !important",
                                                }}
                                                primaryTypographyProps={{
                                                    fontSize: "15px",
                                                    color: "#94a3b8 !important",
                                                }}
                                            />
                                            {/* {showAllMenuItems ? <ArrowDropUpIcon sx={{ color: '#94a3b8' }} /> : <ArrowDropDownIcon sx={{ color: '#94a3b8' }} />}*/}
                                        </ListItem>
                                    )}
                                </List>

                                <Box
                                    sx={{
                                        fontWeight: "600",
                                        fontSize: "14px",
                                        background: isDark
                                            ? "#192734"
                                            : "white",
                                        paddingTop: "32px",
                                        paddingLeft: "15px",
                                        paddingBottom: "10px",
                                        textTransform: "uppercase",
                                        display: "block",
                                    }}
                                >
                                    Chattar
                                </Box>

                                <TeacherList
                                    teachers={teachers}
                                    handleTeacherClick={handleTeacherClick}
                                    onDeleteTeacher={handleDeleteTeacher}
                                    onAddTeacher={onAddTeacher}
                                    isDark={isDark}
                                    user={user}
                                    onPublicTeachers={onPublicTeachers}
                                    toggleSidebar={toggleSidebar}
                                    isMobile={isMobile}
                                />
                            </>
                        )}
                    </Box>

                    <Box
                        sx={{
                            position: "absolute",
                            bottom: 103, // Adjust this value to leave space for the user profile box below
                            left: 0,
                            right: 0,
                            alignItems: "center",
                            padding: "10px",
                            //display: 'flex',
                            display: "none",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            width: "100%",
                            borderTop: "1px solid",
                            borderColor: (theme) => theme.palette.divider,
                            backgroundColor: isDark ? undefined : "#f4faeb",

                            "&:hover": {
                                cursor: "pointer",
                                backgroundColor: isDark ? "#22303c" : "#eee9ff",
                            },
                            "&::before": {
                                content: '""',
                                position: "absolute",
                                top: "-21px",
                                left: 0,
                                right: 0,
                                height: "20px",
                                background: isDark
                                    ? "linear-gradient(to bottom, transparent, #19242fba)"
                                    : "linear-gradient(to bottom, transparent, #ffffff)",
                                pointerEvents: "none",
                            },
                        }}
                        onClick={openFeedbackDialog}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                marginLeft: "13px",
                            }}
                        >
                            <FavoriteIcon
                                sx={{
                                    marginRight: 2,
                                    color: isDark ? undefined : "#14a561",
                                }}
                            />
                            <Typography
                                variant="subtitle1"
                                sx={{ fontWeight: "500" }}
                            >
                                Ge feedback
                            </Typography>
                        </Box>
                    </Box>

                    {user && (
                        <>
                            <Box
                                sx={{
                                    display: "none",
                                    position: "absolute",
                                    bottom: 55,
                                    left: 0,
                                    right: 0,
                                    padding: "10px 15px",
                                    backgroundColor: (theme) =>
                                        theme.palette.background.paper,
                                    borderTop: "1px solid",
                                    borderColor: (theme) =>
                                        theme.palette.divider,
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        marginBottom: "5px",
                                    }}
                                >
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            fontSize: "12px",
                                            color: tokensExceeded
                                                ? "#ff1744"
                                                : (theme) =>
                                                      theme.palette.text
                                                          .secondary,
                                        }}
                                    >
                                        {tokensExceeded
                                            ? "Skapa konto."
                                            : "Tokens:"}
                                    </Typography>

                                    <Typography
                                        variant="body2"
                                        sx={{
                                            fontSize: "12px",
                                            color: tokensExceeded
                                                ? "#ff1744"
                                                : (theme) =>
                                                      theme.palette.text
                                                          .secondary,
                                        }}
                                    >
                                        {tokens}/{maxTokens}
                                    </Typography>
                                </Box>
                                <LinearProgress
                                    variant="determinate"
                                    value={(tokens / maxTokens) * 100}
                                    sx={{
                                        height: 5,
                                        borderRadius: 5,
                                        backgroundColor: tokensExceeded
                                            ? "#ff1744"
                                            : isDark
                                              ? "#2a3b4c"
                                              : "#d4d4d8",
                                        "& .MuiLinearProgress-bar": {
                                            borderRadius: 2,
                                            backgroundColor: tokensExceeded
                                                ? "#ff1744"
                                                : isDark
                                                  ? "#14a561"
                                                  : "#14a561",
                                        },
                                    }}
                                />
                            </Box>

                            <Box
                                sx={{
                                    position: "absolute",
                                    bottom: 0,
                                    left: 0,
                                    right: 0,
                                    alignItems: "center",
                                    padding: "10px",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    width: "100%",
                                    backgroundColor: (theme) =>
                                        theme.palette.background.paper,
                                    borderTop: "1px solid",
                                    borderColor: (theme) =>
                                        theme.palette.divider,
                                    "&:hover": {
                                        cursor: "pointer",
                                        backgroundColor: isDark
                                            ? "#22303c"
                                            : "#eee9ff",
                                    },
                                }}
                                onClick={handleAvatarClick}
                            >
                                <Box
                                    sx={{
                                        //background:
                                        //"linear-gradient(45deg, #60A5FA, #7C3AED)",
                                        borderRadius: "50%",
                                        padding: "2px",
                                        marginLeft: "13px",
                                        display: "inline-block",
                                    }}
                                >
                                    <Avatar
                                        src={user.photoURL}
                                        alt={user.displayName}
                                        sx={{
                                            height: "27px",
                                            width: "27px",
                                        }}
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        flexGrow: 1,
                                        marginLeft: 2,
                                    }}
                                >
                                    <Typography
                                        variant="subtitle1"
                                        sx={{
                                            fontWeight:
                                                theme.palette.themeType ===
                                                "allakando"
                                                    ? "600"
                                                    : undefined,
                                        }}
                                    >
                                        {userDisplayName}
                                    </Typography>
                                </Box>
                                <IconButton
                                    size="small"
                                    sx={{ marginLeft: "auto" }}
                                >
                                    <NavigateNextIcon />
                                </IconButton>
                            </Box>
                        </>
                    )}
                </Box>
            </Drawer>
            <SettingsDialog
                open={open}
                onClose={() => setOpen(false)}
                onUserUpdate={updateUser}
                setOverrideTheme={setOverrideTheme}
            />

            <SettingsPopover
                user={user}
                isDark={isDark}
                openPopover={openPopover}
                anchorEl={anchorEl}
                handlePopoverClose={handlePopoverClose}
                handleLogout={handleLogout}
                openSettings={() => setOpen(true)}
                styles={styles}
                theme={theme}
                setOverrideTheme={setOverrideTheme}
            />

            <FeedbackDialog
                open={feedbackDialogOpen}
                onClose={() => setFeedbackDialogOpen(false)}
                user={user}
            />
        </>
    );
};

const CustomIcon = (props) => (
    <SvgIcon {...props} viewBox="0 0 24 24">
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M8.857 3h6.286c1.084 0 1.958 0 2.666.058.729.06 1.369.185 1.961.487a5 5 0 0 1 2.185 2.185c.302.592.428 1.233.487 1.961.058.708.058 1.582.058 2.666v3.286c0 1.084 0 1.958-.058 2.666-.06.729-.185 1.369-.487 1.961a5 5 0 0 1-2.185 2.185c-.592.302-1.232.428-1.961.487C17.1 21 16.227 21 15.143 21H8.857c-1.084 0-1.958 0-2.666-.058-.728-.06-1.369-.185-1.96-.487a5 5 0 0 1-2.186-2.185c-.302-.592-.428-1.232-.487-1.961C1.5 15.6 1.5 14.727 1.5 13.643v-3.286c0-1.084 0-1.958.058-2.666.06-.728.185-1.369.487-1.96A5 5 0 0 1 4.23 3.544c.592-.302 1.233-.428 1.961-.487C6.9 3 7.773 3 8.857 3M6.354 5.051c-.605.05-.953.142-1.216.276a3 3 0 0 0-1.311 1.311c-.134.263-.226.611-.276 1.216-.05.617-.051 1.41-.051 2.546v3.2c0 1.137 0 1.929.051 2.546.05.605.142.953.276 1.216a3 3 0 0 0 1.311 1.311c.263.134.611.226 1.216.276.617.05 1.41.051 2.546.051h.6V5h-.6c-1.137 0-1.929 0-2.546.051M11.5 5v14h3.6c1.137 0 1.929 0 2.546-.051.605-.05.953-.142 1.216-.276a3 3 0 0 0 1.311-1.311c.134-.263.226-.611.276-1.216.05-.617.051-1.41.051-2.546v-3.2c0-1.137 0-1.929-.051-2.546-.05-.605-.142-.953-.276-1.216a3 3 0 0 0-1.311-1.311c-.263-.134-.611-.226-1.216-.276C17.029 5.001 16.236 5 15.1 5zM5 8.5a1 1 0 0 1 1-1h1a1 1 0 1 1 0 2H6a1 1 0 0 1-1-1M5 12a1 1 0 0 1 1-1h1a1 1 0 1 1 0 2H6a1 1 0 0 1-1-1"
            clipRule="evenodd"
        />
    </SvgIcon>
);

export default Sidebar;
