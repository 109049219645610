import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Chip,
  IconButton,
  TextField,
  Tooltip,
  Button,
  Divider,
  Alert,
} from "@mui/material";
import {
  collection,
  query,
  where,
  orderBy,
  updateDoc,
  limit,
  doc,
  getDocs,
} from "firebase/firestore";
import { db } from "./firebase";
import { darken } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import LessonCard from "./LessonCard";
import LessonBankFilter from "./LessonBankFilter";
import FeedbackDialog from "./FeedbackDialog";

import { Collapse } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import {
  lessonMapping,
  getLessonTypeParam,
  lessonUrlMapping,
} from "./functions";
import { LessonsSkeleton } from "./TeacherSkeleton";
import EditIcon from "@mui/icons-material/Edit";
import { Star, StarBorder } from "@mui/icons-material";
import {
  Star as GoldStar,
  StarBorder as GoldStarBorder,
} from "@mui/icons-material";
import { useSnackbar } from "./SnackbarHandler";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import SaveLessonDialog from "./SaveLessonDialog";

const LessonBank = ({ theme, styles, user }) => {
  const [myLessons, setMyLessons] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [editingUpvotes, setEditingUpvotes] = useState(null);
  const [upvotesValue, setUpvotesValue] = useState("");
  const [filteredLessons, setFilteredLessons] = useState([]); // Add this state
  const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(false);
  const [themeExpanded, setThemeExpanded] = useState(false);

  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();

  // Add this near the top of the component with your other useEffect hooks
  useEffect(() => {
    if (user && !user.admin) {
      navigate("/app/logga-in");
    }
  }, [user, navigate]);

  useEffect(() => {
    if (user?.uid) {
      loadMyLessons();
    }
  }, [user]);

  // Add this useEffect to initialize filteredLessons
  useEffect(() => {
    setFilteredLessons(myLessons);
  }, [myLessons]);

  const handleFilterChange = ({ grades, subjects, themes, lessonTypes }) => {
    let filtered = [...myLessons];

    if (grades.length > 0) {
      filtered = filtered.filter((lesson) =>
        lesson.tags?.some((tag) => grades.includes(tag)),
      );
    }

    if (subjects.length > 0) {
      filtered = filtered.filter((lesson) =>
        lesson.tags?.some((tag) => subjects.includes(tag)),
      );
    }

    if (themes.length > 0) {
      filtered = filtered.filter((lesson) =>
        lesson.tags?.some((tag) => themes.includes(tag)),
      );
    }

    if (lessonTypes.length > 0) {
      filtered = filtered.filter((lesson) =>
        lessonTypes.includes(lessonMapping(lesson.lessonType)),
      );
    }

    setFilteredLessons(filtered);
  };

  const handleUpvotesUpdate = async (lessonId, newValue) => {
    try {
      const numericValue = parseInt(newValue, 10);
      if (isNaN(numericValue)) return;

      await updateDoc(doc(db, "lesson_usage", lessonId), {
        upvotes: numericValue,
      });

      setMyLessons((prevLessons) =>
        prevLessons.map((lesson) =>
          lesson.id === lessonId
            ? { ...lesson, upvotes: numericValue }
            : lesson,
        ),
      );

      setEditingUpvotes(null);
      setUpvotesValue("");
      showSnackbar("Likes uppdaterat!", "success");
    } catch (error) {
      console.error("Error updating upvotes:", error);
      showSnackbar("Failed to update upvotes", "error");
    }
  };

  const loadMyLessons = async () => {
    try {
      const adminIds = [
        "Z1dF5PGfjvdBGS9egzACvcSRZiN2",
        "x2rjH7JxWxc6GnFgPSTxGxN1bm63",
        "Z6BLSMsm38Un5mgW0O6Z3TToHq12",
      ];

      const q = query(
        collection(db, "lesson_usage"),
        where("userId", "in", adminIds),
        where("hasPrinted", "==", true),
        limit(500),
        orderBy("timestamp", "desc"),
      );

      const querySnapshot = await getDocs(q);
      const lessonData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        timestamp: doc.data().timestamp?.toDate(),
      }));
      setMyLessons(lessonData);
    } catch (error) {
      console.error("Error loading my lessons:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleToggleFeatured = async (e, lessonId, currentFeatured) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      await updateDoc(doc(db, "lesson_usage", lessonId), {
        featured: !currentFeatured,
      });
      setMyLessons((prevLessons) =>
        prevLessons.map((lesson) =>
          lesson.id === lessonId
            ? { ...lesson, featured: !lesson.featured }
            : lesson,
        ),
      );

      showSnackbar(
        currentFeatured
          ? "Lektion borttagen från underlagsväljaren!"
          : "Lektion tillagd i underlagsväljaren!",
        "success",
      );
    } catch (error) {
      console.error("Error updating featured status:", error);
    }
  };

  const handleToggleLessonBankFeatured = async (
    e,
    lessonId,
    currentFeatured,
  ) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      await updateDoc(doc(db, "lesson_usage", lessonId), {
        featuredInLessonBank: !currentFeatured,
      });
      setMyLessons((prevLessons) =>
        prevLessons.map((lesson) =>
          lesson.id === lessonId
            ? { ...lesson, featuredInLessonBank: !lesson.featuredInLessonBank }
            : lesson,
        ),
      );
      showSnackbar(
        currentFeatured
          ? "Lektion borttagen från lektionsbanken!"
          : "Lektion tillagd i lektionsbanken!",
        "success",
      );
    } catch (error) {
      console.error("Error updating lesson bank featured status:", error);
    }
  };

  const handleLessonClick = (lesson) => {
    const shortId = lesson.id.substring(0, 10);
    const typeParam = getLessonTypeParam(lesson.lessonType);
    const basePath =
      lesson.lessonType !== "PowerPointGenerator.js" ? "/lektioner" : "";
    const queryString =
      typeParam === "default" ? "?v=t" : `?type=${typeParam}&v=t`;
    navigate(
      `/app${basePath}/${lessonUrlMapping(lesson.lessonType)}/${shortId}${queryString}`,
    );
  };
  // Group lessons by their mapped type
  const groupedLessons = filteredLessons.reduce((acc, lesson) => {
    const mappedType = lessonMapping(lesson.lessonType);
    if (!acc[mappedType]) {
      acc[mappedType] = [];
    }
    acc[mappedType].push(lesson);
    return acc;
  }, {});

  // Sort lesson types with Läsförståelse first
  const sortedLessonTypes = Object.keys(groupedLessons).sort((a, b) => {
    if (a === "Läsförståelse") return -1;
    if (b === "Läsförståelse") return 1;
    return a.localeCompare(b);
  });

  return (
    <>
      <LessonBankFilter onFilterChange={handleFilterChange} styles={styles} />
      <Box sx={styles.container}>
        <Box
          sx={{
            ...styles.innerContainer,
            width: { xs: "100%", md: "100%" },
            margin: "0 auto",

            paddingLeft: "50px",
            paddingRight: "50px",
          }}
        >
          <Box
            sx={{
              background: "#9f84db",

              boxShadow: "0 2px 4px rgba(0,0,0,0.1)",

              borderRadius: 4,
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              width: themeExpanded ? "100%" : "100%",
              margin: "0 auto",
              "&:hover": {
                backgroundColor: (theme) =>
                  darken(theme.palette.text.main, 0.3),
              },
            }}
            onClick={() => setThemeExpanded(!themeExpanded)}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mb: !themeExpanded ? 0 : 4,
                cursor: "pointer",
                p: 3,
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 400,
                  color: "white",

                  fontSize: "1.4rem",
                  borderBottom: "2px solid transparent",
                  "&:hover": {
                    borderBottom: "2px solid rgba(255, 255, 255, 0.7)",
                  },
                }}
              >
                Aktuella teman
              </Typography>
              <IconButton
                onClick={() => setThemeExpanded(!themeExpanded)}
                sx={{ color: "white", ml: 1 }}
              >
                {themeExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </Box>
            <Typography
              variant="subtitle2"
              sx={{
                color: "rgba(255, 255, 255, 0.7)",
                textAlign: "center",
                mb: 3,
                display: "none",
              }}
            >
              Noggrant kurerade lektioner utifrån aktuella teman
            </Typography>
            <Collapse in={themeExpanded} timeout="auto">
              <Grid container spacing={3} sx={{ p: 3 }}>
                <Grid item xs={12} sm={6} md={4}>
                  <LessonCard
                    name="Nationella provet"
                    imageUrl="https://sportkuriren.se/wp-content/uploads/np.jpg"
                    width
                    centered={true}
                    onClick={() =>
                      navigate("/app/lektionsbanken?tema=nationella-provet")
                    }
                    comment="Utvalda lektioner inför Nationella provet"
                    height="230px"
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <Box sx={{ position: "relative" }}>
                    <Box
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backdropFilter: "blur(3px)",

                        zIndex: 2,
                        borderRadius: "12px",
                      }}
                    />
                    <LessonCard
                      name="Alla hjärtans dag"
                      imageUrl="https://sportkuriren.se/wp-content/uploads/alla hjärtans dag.jpg"
                      width
                      centered={true}
                      comment="Teman kring vänskap, omtanke och kärlek."
                      height="230px"
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <Box sx={{ position: "relative" }}>
                    <Box
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backdropFilter: "blur(3px)",

                        zIndex: 2,
                        borderRadius: "12px",
                      }}
                    />
                    <LessonCard
                      name="Världsbokdagen"
                      imageUrl="https://sportkuriren.se/wp-content/uploads/varldsbokdagen.jpg"
                      width
                      centered={true}
                      comment="Fokus på läsning, boktips och författare."
                      height="230px"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Collapse>
          </Box>
          <Alert
            severity="success"
            sx={{
              ...styles.freshBox,
              width: { xs: "100%", md: "69%" },
              margin: "0 auto",
              background: "#a4c4a42e",
              mt: 4,
              mb: 5,
              borderRadius: 4,
              "& .MuiAlert-message": {
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between", // This spreads out the items
                gap: 2,
                width: "100%",
                fontWeight: "500",
              },
              "& .MuiAlert-icon": {
                display: "flex",
                alignItems: "center",
                marginRight: 1,
                marginTop: 0,
              },
            }}
          >
            <span>
              Nya lektioner dyker upp varje vecka. Så titta gärna in titt som
              tätt! :)
            </span>
            <Button
              variant="success"
              sx={{
                ...styles.tightButton,
                marginLeft: "auto",
              }}
              onClick={() => setFeedbackDialogOpen(true)}
            >
              Jag vill tipsa om en lektion jag vill se här!
            </Button>
          </Alert>
          <Box sx={{ width: "100%" }}>
            {isLoading ? (
              <LessonsSkeleton theme={theme} lessonCount={18} />
            ) : sortedLessonTypes.length > 0 ? (
              <Box>
                {sortedLessonTypes.map((lessonType, typeIndex) => (
                  <Box key={lessonType} sx={{ mb: 4 }}>
                    <Typography
                      variant="h2"
                      sx={{
                        fontSize: "1.5em",
                        fontWeight: "600",
                        mb: 2,
                        mt: typeIndex > 0 ? 10 : 0,
                      }}
                    >
                      {lessonType}
                    </Typography>
                    <Box sx={{ ...styles.freshBox, padding: "30px" }}>
                      <Grid container spacing={3}>
                        {groupedLessons[lessonType].map((lesson) => (
                          <Grid item xs={12} sm={6} md={4} key={lesson.id}>
                            <Box sx={{ position: "relative" }}>
                              <Box
                                component="a"
                                href={`/app${lesson.lessonType !== "PowerPointGenerator.js" ? "/lektioner" : ""}/${lessonUrlMapping(lesson.lessonType)}/${lesson.id.substring(0, 10)}${getLessonTypeParam(lesson.lessonType) === "default" ? "?v=t" : `?type=${getLessonTypeParam(lesson.lessonType)}&v=t`}`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleLessonClick(lesson);
                                }}
                              >
                                <LessonCard
                                  name={lesson.lessonName}
                                  comment={lesson.comment}
                                  timestamp={lesson.timestamp}
                                  lessonType={lesson.lessonType}
                                  imageUrl={lesson.coverImage}
                                  size="large"
                                  width="200px"
                                  height="250px"
                                  centered={true}
                                />
                              </Box>
                              {user?.admin && (
                                <Box
                                  sx={{
                                    position: "absolute",
                                    top: 8,
                                    right: 8,
                                    display: "flex",
                                    gap: 1,
                                  }}
                                >
                                  <Tooltip
                                    title={
                                      lesson.featuredInLessonBank
                                        ? "Ta bort från lektionsbanken"
                                        : "Lägg till i lektionsbanken"
                                    }
                                    placement="top"
                                  >
                                    <IconButton
                                      onClick={(e) =>
                                        handleToggleLessonBankFeatured(
                                          e,
                                          lesson.id,
                                          lesson.featuredInLessonBank,
                                        )
                                      }
                                      sx={{
                                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                                        "&:hover": {
                                          backgroundColor: "rgba(0, 0, 0, 0.7)",
                                        },
                                      }}
                                    >
                                      {lesson.featuredInLessonBank ? (
                                        <GoldStar
                                          sx={{
                                            color: "#FFD700",
                                            fontSize: "medium",
                                          }}
                                        />
                                      ) : (
                                        <GoldStarBorder
                                          sx={{
                                            color: "#FFD700",
                                            fontSize: "medium",
                                          }}
                                        />
                                      )}
                                    </IconButton>
                                  </Tooltip>

                                  <Tooltip
                                    title={
                                      lesson.featured
                                        ? "Ta bort från underlagsväljaren"
                                        : "Lägg till i underlagsväljaren"
                                    }
                                    placement="top"
                                  >
                                    <IconButton
                                      onClick={(e) =>
                                        handleToggleFeatured(
                                          e,
                                          lesson.id,
                                          lesson.featured,
                                        )
                                      }
                                      sx={{
                                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                                        "&:hover": {
                                          backgroundColor: "rgba(0, 0, 0, 0.7)",
                                        },
                                      }}
                                    >
                                      {lesson.featured ? (
                                        <Star
                                          sx={{
                                            color: "white",
                                            fontSize: "medium",
                                          }}
                                        />
                                      ) : (
                                        <StarBorder
                                          sx={{
                                            color: "white",
                                            fontSize: "medium",
                                          }}
                                        />
                                      )}
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                              )}

                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  mt: 1,
                                }}
                              >
                                {user?.admin && (
                                  <>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        gap: 1,
                                        flex: 1,
                                      }}
                                    >
                                      {lesson.tags?.map((tag, index) => (
                                        <Chip
                                          key={index}
                                          label={tag}
                                          size="small"
                                        />
                                      ))}
                                    </Box>
                                    <IconButton
                                      size="small"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setSelectedLesson(lesson);
                                        setEditDialogOpen(true);
                                      }}
                                      sx={{ ml: 1 }}
                                    >
                                      <EditIcon sx={{ fontSize: "1.2rem" }} />
                                    </IconButton>
                                  </>
                                )}

                                {/* Upvotes section - visible to all users */}
                                {user?.admin && editingUpvotes === lesson.id ? (
                                  <TextField
                                    size="small"
                                    value={upvotesValue}
                                    onChange={(e) =>
                                      setUpvotesValue(e.target.value)
                                    }
                                    onKeyPress={(e) => {
                                      if (e.key === "Enter") {
                                        e.stopPropagation();
                                        handleUpvotesUpdate(
                                          lesson.id,
                                          upvotesValue,
                                        );
                                      }
                                    }}
                                    onBlur={() =>
                                      handleUpvotesUpdate(
                                        lesson.id,
                                        upvotesValue,
                                      )
                                    }
                                    onClick={(e) => e.stopPropagation()}
                                    sx={{ width: "70px", ml: 1 }}
                                  />
                                ) : (
                                  <IconButton
                                    size="small"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      if (user?.admin) {
                                        setEditingUpvotes(lesson.id);
                                        setUpvotesValue(
                                          lesson.upvotes?.toString() || "0",
                                        );
                                      }
                                    }}
                                    sx={{
                                      ml: 1,
                                      pointerEvents: user?.admin
                                        ? "auto"
                                        : "none",
                                    }}
                                  >
                                    <ThumbUpIcon sx={{ fontSize: "1.2rem" }} />
                                    <Typography
                                      variant="caption"
                                      sx={{
                                        ml: 0.5,
                                        fontWeight: "600",
                                        fontSize: "0.9rem",
                                      }}
                                    >
                                      {lesson.upvotes || 0}
                                    </Typography>
                                  </IconButton>
                                )}
                              </Box>
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </Box>
                ))}
              </Box>
            ) : (
              <Box sx={{ textAlign: "center", mt: 4 }}>
                <Typography variant="body1" color="text.secondary">
                  Du har inga lektioner än
                </Typography>
              </Box>
            )}
          </Box>
        </Box>

        <FeedbackDialog
          open={feedbackDialogOpen}
          onClose={() => setFeedbackDialogOpen(false)}
          user={user}
        />

        <SaveLessonDialog
          key={selectedLesson?.lessonName}
          open={editDialogOpen}
          onClose={() => {
            setEditDialogOpen(false);
            setSelectedLesson(null);
          }}
          theme={theme}
          lessonName={selectedLesson?.lessonName}
          lessonObject={selectedLesson?.data}
          lessonType={selectedLesson?.lessonType}
          lessonId={selectedLesson?.id}
          existingTags={selectedLesson?.tags}
        />
      </Box>
    </>
  );
};

export default LessonBank;
