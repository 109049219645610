import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  IconButton,
  Divider,
  Select,
  CircularProgress,
  MenuItem,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import BlurryBackdrop from "./BlurryBackdrop";
import { collection, addDoc } from "firebase/firestore";
import { db } from "./firebase";
import { useSnackbar } from "./SnackbarHandler";
import { getStyles } from "../index";
import SaveIcon from "@mui/icons-material/Save";
import { useTheme } from "@mui/material/styles";

const RubricsDialog = ({
  open,
  onClose,
  user,
  onRubricsUpdated,
  initialData,
}) => {
  const [rubricName, setRubricName] = useState("");
  const [criteria, setCriteria] = useState([
    { name: "", descriptors: [{ text: "", grade: "A" }] },
  ]);
  //const [criteria, setCriteria] = useState([]);
  const { showSnackbar } = useSnackbar();
  const [isExample, setIsExample] = useState(false);
  const theme = useTheme();
  const styles = getStyles(theme);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (open && initialData) {
      // Load existing rubric data
      setRubricName(initialData.name);
      setCriteria(initialData.criteria || []);
    } else if (!open) {
      // Reset form when closing
      setRubricName("");
      setCriteria([]);
      setIsExample(false);
    } else {
      // Initialize empty form for new rubric
      setRubricName("");
      setCriteria([{ name: "", descriptors: [{ text: "", grade: "A" }] }]);
    }
  }, [open, initialData]);
  const handleAddCriteria = () => {
    setCriteria([
      ...criteria,
      { name: "", descriptors: [{ text: "", grade: "A" }] },
    ]);
  };

  const handleRemoveCriteria = (index) => {
    const newCriteria = criteria.filter((_, i) => i !== index);
    setCriteria(newCriteria);
  };

  const handleAddDescriptor = (criteriaIndex) => {
    const newCriteria = [...criteria];
    newCriteria[criteriaIndex].descriptors.push({ text: "", grade: "A" });
    setCriteria(newCriteria);
  };

  const handleRemoveDescriptor = (criteriaIndex, descriptorIndex) => {
    const newCriteria = [...criteria];
    newCriteria[criteriaIndex].descriptors = newCriteria[
      criteriaIndex
    ].descriptors.filter((_, i) => i !== descriptorIndex);
    setCriteria(newCriteria);
  };

  const handleCriteriaChange = (index, value) => {
    const newCriteria = [...criteria];
    newCriteria[index].name = value;
    setCriteria(newCriteria);
  };

  const handleDescriptorChange = (
    criteriaIndex,
    descriptorIndex,
    field,
    value,
  ) => {
    const newCriteria = [...criteria];
    newCriteria[criteriaIndex].descriptors[descriptorIndex][field] = value;
    setCriteria(newCriteria);
  };

  const handleSaveRubric = async () => {
    // Check if all visible fields are filled
    if (rubricName.trim() === "") {
      showSnackbar("Vänligen fyll i namnet på bedömningsmatrisen", "error");
      return;
    }

    const isValid = criteria.every(
      (criterion) =>
        criterion.name.trim() !== "" &&
        criterion.descriptors.every(
          (descriptor) => descriptor.text.trim() !== "",
        ),
    );

    if (!isValid) {
      showSnackbar("Vänligen fyll i alla fält innan du sparar", "error");
      return;
    }

    setIsSaving(true);
    try {
      await addDoc(collection(db, "rubrics"), {
        name: rubricName,
        custom: false,
        criteria: criteria,
        formativeComments: true,
        createdAt: new Date(),
        userId: user.uid,
      });
      showSnackbar("Bedömningsmatrisen har sparats", "success");
      // Reset form
      setRubricName("");
      setCriteria([{ name: "", descriptors: [{ text: "", grade: "A" }] }]);
      if (onRubricsUpdated) {
        onRubricsUpdated();
      }
      onClose();
    } catch (error) {
      console.error("Error saving rubric:", error);
      showSnackbar(
        "Ett fel uppstod när bedömningsmatrisen skulle sparas",
        "error",
      );
    } finally {
      setIsSaving(false);
    }
  };

  const handleExampleClick = () => {
    if (isExample) {
      // Reset the form
      setRubricName("");
      setCriteria([{ name: "", descriptors: [{ text: "", grade: "A" }] }]);
      setIsExample(false);
    } else {
      setRubricName("Informerande");
      setCriteria([
        {
          name: "Innehåll och relevans",
          descriptors: [
            {
              text: "Omfattande och relevant information som täcker ämnet fullständigt.",
              grade: "A",
            },
            {
              text: "God informationsmängd men vissa aspekter kunde utvecklas.",
              grade: "C",
            },
            {
              text: "Grundläggande information men flera viktiga delar saknas.",
              grade: "E",
            },
            {
              text: "Bristfällig eller irrelevant information.",
              grade: "F",
            },
          ],
        },
        {
          name: "Källhantering",
          descriptors: [
            {
              text: "Utmärkt källkritik och korrekt referering till trovärdiga källor.",
              grade: "A",
            },
            {
              text: "God källhantering men vissa referenser kunde förbättras.",
              grade: "C",
            },
            {
              text: "Enkel källhantering med vissa brister i referering.",
              grade: "E",
            },
            {
              text: "Undermålig eller ingen källhantering.",
              grade: "F",
            },
          ],
        },
        {
          name: "Objektivitet och saklighet",
          descriptors: [
            {
              text: "Konsekvent objektivt och sakligt presenterad information.",
              grade: "A",
            },
            {
              text: "Mestadels saklig men med vissa subjektiva inslag.",
              grade: "C",
            },
            {
              text: "Grundläggande saklighet men flera subjektiva värderingar.",
              grade: "E",
            },
            {
              text: "Mycket subjektiv eller missvisande presentation.",
              grade: "F",
            },
          ],
        },
        {
          name: "Struktur och presentation",
          descriptors: [
            {
              text: "Logisk struktur som effektivt förmedlar informationen.",
              grade: "A",
            },
            {
              text: "Tydlig struktur men vissa delar kunde organiseras bättre.",
              grade: "C",
            },
            {
              text: "Grundläggande struktur men ibland svår att följa.",
              grade: "E",
            },
            {
              text: "Oorganiserad och svår att förstå.",
              grade: "F",
            },
          ],
        },
      ]);
      setIsExample(true);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      BackdropComponent={BlurryBackdrop}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: 1000,
          borderRadius: "20px",
          backgroundColor: theme.palette.mode === "dark" ? "#22303C" : "white",
          backgroundImage: theme.palette.mode === "dark" ? "none" : "white",
          padding: { xs: "0px", md: "20px" },
        },
      }}
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", fontSize: "1.6rem" }}
          >
            {initialData ? "Visar bedömningsmatris" : "Skapa bedömningsmatris"}
          </Typography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ ...styles.freshBox, padding: "20px", mt: 4 }}>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
            <Button
              onClick={handleExampleClick}
              variant="outlined"
              sx={{
                ...styles.beautyBtn,
                ...styles.exampleBtn,
              }}
            >
              {isExample ? "Nollställ" : "Exempel"}
            </Button>
          </Box>

          <Typography
            variant="formLabelSinUnderline"
            sx={{ fontWeight: "500" }}
          >
            Namn:
          </Typography>
          <TextField
            fullWidth
            placeholder="Namn på bedömningsmatris"
            value={rubricName}
            onChange={(e) => setRubricName(e.target.value)}
            margin="normal"
            sx={{ mt: 1 }}
          />
          {criteria.map((criterion, criteriaIndex) => (
            <Box
              key={criteriaIndex}
              elevation={3}
              sx={{
                ...styles.freshBox,
                mt: 2,
                padding: "20px",
                border:
                  theme.palette.mode === "dark"
                    ? "1px solid #3b4855"
                    : "inherit",
              }}
            >
              <Typography
                variant="formLabelSinUnderline"
                sx={{ fontWeight: "500" }}
              >
                Titel på kriterium:
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 1,
                  mt: 1,
                }}
              >
                <TextField
                  fullWidth
                  placeholder={`Kriterium ${criteriaIndex + 1}`}
                  value={criterion.name}
                  onChange={(e) =>
                    handleCriteriaChange(criteriaIndex, e.target.value)
                  }
                />
                <IconButton
                  onClick={() => handleRemoveCriteria(criteriaIndex)}
                  color="error"
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
              <Box display="flex" justifyContent="flex-start" sx={{ mb: 2 }}>
                <Typography variant="caption" sx={{ ...styles.grayText }}>
                  De aspekter av skrivprestationen som kommer att bedömas. Till
                  exempel: Argumentation, Bevisföring eller Tydlighet
                </Typography>
              </Box>
              {criterion.descriptors.map((descriptor, descriptorIndex) => (
                <Box
                  key={descriptorIndex}
                  sx={{ marginLeft: 5, marginBottom: 1 }}
                >
                  <Typography
                    variant="formLabelSinUnderline"
                    sx={{ fontWeight: "500" }}
                  >
                    Beskrivning:
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                    <TextField
                      fullWidth
                      multiline
                      rows={3}
                      placeholder="Beskrivning"
                      value={descriptor.text}
                      onChange={(e) =>
                        handleDescriptorChange(
                          criteriaIndex,
                          descriptorIndex,
                          "text",
                          e.target.value,
                        )
                      }
                      sx={{ marginRight: 1 }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <Typography
                        variant="formLabelSinUnderline"
                        sx={{ fontWeight: "500", mb: 1 }}
                      >
                        Betyg:
                      </Typography>
                      <Select
                        value={descriptor.grade}
                        onChange={(e) =>
                          handleDescriptorChange(
                            criteriaIndex,
                            descriptorIndex,
                            "grade",
                            e.target.value,
                          )
                        }
                        sx={{ width: "100px", marginRight: 1 }}
                      >
                        <MenuItem value="A">A</MenuItem>
                        <MenuItem value="C">C</MenuItem>
                        <MenuItem value="E">E</MenuItem>
                        <MenuItem value="F">F</MenuItem>
                      </Select>
                    </Box>
                    <IconButton
                      onClick={() =>
                        handleRemoveDescriptor(criteriaIndex, descriptorIndex)
                      }
                      color="error"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="flex-start"
                    sx={{ mb: 2 }}
                  >
                    <Typography variant="caption" sx={{ ...styles.grayText }}>
                      Ange vad texten behöver visa för att få detta betyg.
                      T.ex.: Texten är välstrukturerad.
                    </Typography>
                  </Box>
                </Box>
              ))}
              <Button
                startIcon={<AddIcon />}
                variant="print"
                onClick={() => handleAddDescriptor(criteriaIndex)}
                sx={{ marginTop: 1, ml: 5 }}
              >
                Lägg till beskrivning
              </Button>
            </Box>
          ))}
          <Button
            variant="print"
            startIcon={<AddIcon />}
            onClick={handleAddCriteria}
            sx={{ marginTop: 2 }}
          >
            Lägg till kriterium
          </Button>
          <Divider sx={{ mt: 5, mb: 3 }} />
          <Button
            startIcon={
              isSaving ? (
                <CircularProgress size={16} color="inherit" />
              ) : (
                <SaveIcon />
              )
            }
            variant={theme.palette.mode === "light" ? "purp" : "contained"}
            color="primary"
            onClick={handleSaveRubric}
            sx={{ marginTop: 2 }}
            disabled={isSaving}
          >
            {isSaving ? "Sparar..." : "Spara bedömningsmatris"}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default RubricsDialog;
