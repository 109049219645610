import React, { useRef, useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Box,
  TextField,
  Collapse,
  Paper,
  Stack,
  CircularProgress,
  Container,
  Menu,
  Tooltip,
  MenuItem,
  IconButton,
} from "@mui/material";

import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { BookPlanSelector } from "./BookPlanSelector";
import { Link } from "react-router-dom";
import {
  lessonMapping,
  getLessonTypeParam,
  lessonUrlMapping,
} from "./functions";
import { Link as LinkIcon } from "@mui/icons-material";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import { AnimatedHint, AnimatedText, SmoothTransitionHint } from "./Animations";

import SchoolIcon from "@mui/icons-material/School";
import HistoryIcon from "@mui/icons-material/History";
import ScienceIcon from "@mui/icons-material/Science";
import StyleIcon from "@mui/icons-material/Style";
import PublicIcon from "@mui/icons-material/Public";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { CheckCircleOutline } from "@mui/icons-material";
import CalculateIcon from "@mui/icons-material/Calculate";

import WordIcon2024 from "./WordIcon2024";
import WordIcon2019 from "./WordIcon2019";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import FileUploader from "./FileUploader";
import WikiFetcher from "./WikiFetcher";

import UrlFetcher from "./UrlFetcher";

import Randomizer from "./Randomizer";
import Suggestions from "./Suggestions";
import CustomThemeInput from "./CustomThemeInput";

import WikipediaIcon from "./WikipediaIcon";
import TranslateIcon from "@mui/icons-material/Translate";
import LanguageIcon from "@mui/icons-material/Language";
import NatureIcon from "@mui/icons-material/Nature";

import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";

import WavesIcon from "@mui/icons-material/Waves";
import PeopleIcon from "@mui/icons-material/People";

import { getTrackedLessons } from "./functions";
import LessonCard from "./LessonCard";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import MosqueIcon from "@mui/icons-material/Mosque";
import YouTubeIcon from "@mui/icons-material/YouTube";
import BiotechIcon from "@mui/icons-material/Biotech";
import ThemeFlowBox from "./ThemeFlowBox";
import { CoursePlanSelector } from "./CoursePlanSelector";
import { YouTubeSelector } from "./YouTubeSelector";
import Logger from "./Logger";

import ShuffleIcon from "@mui/icons-material/Shuffle";

import MusicNoteIcon from "@mui/icons-material/MusicNote";
import HandymanIcon from "@mui/icons-material/Handyman";
import KitchenIcon from "@mui/icons-material/Kitchen";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import BrushIcon from "@mui/icons-material/Brush";
const ThemeSelectorFlow = ({
  value,
  onChange,
  customTheme,
  onCustomThemeChange,
  onPredefinedThemeChange,
  additionalThemes = [],

  onYoutubeTranscriptChange,
  onFileUpload,
  onWikipediaContentChange,
  onRandomizedContentChange,
  onCoursePlanContentChange,
  onBookContentChange,
  onUrlContentChange,
  lessonType,
  onLessonSelect, // New callback prop for handling lesson selection
  user, // Need to pass the user prop for getTrackedLessons
  theme,
  styles,
  forQuiz = false,
  forFlashcards = false,
  forPowerPoint = false,
  forTextlabbet = false,
  forLessonPlan = false,
  subject,
}) => {
  const inputRef = useRef(null);
  const [step, setStep] = React.useState(1);
  const [selectedSource, setSelectedSource] = React.useState(null);
  const textFieldRef = useRef(null);
  const [lessons, setLessons] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [allThemes, setAllThemes] = useState(additionalThemes);
  const [selectedTheme, setSelectedTheme] = useState(null);
  const [isShimmering, setIsShimmering] = useState(false);

  const schoolSubjects = [
    { value: "historia", label: "Historia", Icon: HistoryIcon },
    { value: "fysik", label: "Fysik", Icon: WavesIcon },
    { value: "biologi", label: "Biologi", Icon: NatureIcon },
    { value: "geografi", label: "Geografi", Icon: PublicIcon },
    { value: "engelska", label: "Engelska", Icon: LanguageIcon },
    { value: "kemi", label: "Kemi", Icon: ScienceIcon },
    { value: "modersmål", label: "Modersmål", Icon: PublicIcon },
    { value: "religionskunskap", label: "Religionskunskap", Icon: MosqueIcon },
    { value: "samhällskunskap", label: "Samhällskunskap", Icon: PeopleIcon },
    { value: "svenska", label: "Svenska", Icon: TranslateIcon },
    { value: "matematik", label: "Matematik", Icon: CalculateIcon },
    { value: "bild", label: "Bild", Icon: BrushIcon },

    {
      value: "so",
      label: "SO",
      Icon: PeopleIcon, // Fitness center icon works well for physical education
    },
    {
      value: "no",
      label: "NO",
      Icon: ScienceIcon, // Fitness center icon works well for physical education
    },

    { value: "teknik", label: "Teknik", Icon: BiotechIcon },
    {
      value: "musik",
      label: "Musik",
      Icon: MusicNoteIcon, // Music note icon is perfect for music class
    },
    {
      value: "Slöjd",
      label: "Slöjd",
      Icon: HandymanIcon, // Handyman icon works well for crafts/woodworking
    },
    {
      value: "Hem- och konsumentkunskap",
      label: "Hem- och konsumentkunskap",
      Icon: KitchenIcon, // Kitchen icon is fitting for home economics
    },
    {
      value: "idrott och hälsa",
      label: "Idrott och hälsa",
      Icon: FitnessCenterIcon, // Fitness center icon works well for physical education
    },

    {
      value: "moderna språk",
      label: "Moderna språk",
      Icon: PublicIcon, // Fitness center icon works well for physical education
    },
  ];

  const validSchoolSubjects = schoolSubjects.map((subject) => subject.value);
  const hasCustomThemes = additionalThemes.length > 0;

  useEffect(() => {
    if (selectedSource === "lessonBank") {
      loadLessons();
    }
  }, [selectedSource]);

  useEffect(() => {
    Logger.log("subject in ThemeSelectorFlow: ", subject);
  }, [subject]);

  const loadLessons = async () => {
    setIsLoading(true);
    try {
      console.log("lessonType in loadLessons", lessonType);
      const trackedLessons = await getTrackedLessons(user.uid, lessonType);
      setLessons(trackedLessons);
    } catch (error) {
      console.error("Error loading lessons:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLessonSelect = (lesson) => {
    if (onLessonSelect) {
      onLessonSelect(lesson);
      onChange({ target: { value: "lessonBank" } });
    }
  };

  useEffect(() => {
    if (customTheme?.startsWith("Ämne:") && inputRef.current) {
      const timeoutId = setTimeout(() => {
        inputRef.current.focus();
        inputRef.current.setSelectionRange(
          customTheme.length,
          customTheme.length,
        );
      }, 0);
      return () => clearTimeout(timeoutId);
    }
  }, [customTheme]);

  useEffect(() => {
    // Om value är ett skolämne, sätt selectedSource till 'subject'
    if (validSchoolSubjects.includes(value)) {
      setSelectedSource("subject");
      setStep(2);
    }
  }, [value]);

  const handleSourceSelect = (source) => {
    setSelectedSource(source);
    if (source === "customTheme") {
      onChange({ target: { value: "customTheme" } });
    } else if (source === "youtube") {
      onChange({ target: { value: "youtube" } });
    } else if (source === "urplay") {
      onChange({ target: { value: "urplay" } });
    } else if (source === "lessonBank") {
      onChange({ target: { value: "lessonBank" } });
    } else if (source === "predefined") {
      onChange({ target: { value: "predefined" } });
    } else if (source === "fileUpload") {
      onChange({ target: { value: "fileUpload" } });
    } else if (source === "wikipedia") {
      onChange({ target: { value: "wikipedia" } });
    } else if (source === "randomizer") {
      onChange({ target: { value: "randomizer" } });
    } else if (source === "subject") {
      onChange({ target: { value: "subject" } });
    } else if (source === "book") {
      onChange({ target: { value: "book" } });
    } else if (source === "url") {
      onChange({ target: { value: "url" } });
    }
    setStep(2);
  };

  const handleSubjectSelect = (subject) => {
    onChange({ target: { value: subject } });
  };

  const handleSelect = (selected) => {
    if (selected.text && inputRef.current) {
      inputRef.current.value = selected.value;

      onCustomThemeChange({ target: { value: selected.value } });
    }
  };

  const handlePredefinedThemeSelect = (topic) => {
    const selectedTheme = allThemes.find((theme) => theme.value === topic);
    onPredefinedThemeChange({
      target: {
        value: selectedTheme
          ? `${selectedTheme.label}: ${selectedTheme.content}`
          : topic,
      },
    });
  };

  const ContentWrapper = ({ children, showBackButton, title }) => {
    const [isHovered, setIsHovered] = useState(false);
    const isStartPage = !showBackButton;
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();

    const handleSubjectMenuClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleSubjectMenuClose = () => {
      setAnchorEl(null);
    };

    const onSubjectMenuItemClick = (subjectValue) => {
      // Get current URL and search params
      const currentParams = new URLSearchParams(window.location.search);

      // Set the subject parameter
      currentParams.set("subject", subjectValue);

      // Create the new search string
      const newSearch = currentParams.toString();

      // Trigger shimmer effect
      setIsShimmering(true);
      setTimeout(() => setIsShimmering(false), 600);

      // Navigate with all params
      navigate(`?${newSearch}`);
      handleSubjectMenuClose();
    };

    const SubjectIcon = subject
      ? schoolSubjects.find((s) => s.value === subject)?.Icon || SchoolIcon
      : SchoolIcon;

    return (
      <Box sx={{ py: 2, mt: 6 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mb: 2,
            gap: 1,
            height: "40px",
            position: "relative",
          }}
        >
          {showBackButton && (
            <Box
              onClick={() => {
                setStep(1);
                setSelectedSource(null);
                onChange({ target: { value: "" } });
                onPredefinedThemeChange({ target: { value: "" } });
                onYoutubeTranscriptChange("");
                onWikipediaContentChange("");
                onUrlContentChange("");
                onRandomizedContentChange("");
                onFileUpload("");
                if (inputRef.current) {
                  inputRef.current.value = "";
                }
              }}
              sx={{
                ...styles.backButton,
              }}
            >
              <ArrowBackIcon />
              <Typography sx={{ fontSize: "0.875rem", fontWeight: "500" }}>
                Tillbaka
              </Typography>
            </Box>
          )}
          <Typography
            variant="h6"
            sx={{
              fontWeight: "600",
              fontSize: "1.45rem",
              width: "100%",
              textAlign: "center",
              position: "absolute",
              left: 0,
              right: 0,
            }}
          >
            {title}
          </Typography>

          {/* Add this new Box for the subject selector */}
          <Box
            sx={{
              position: "absolute",
              right: 0,
              zIndex: 2,
              display:
                forQuiz || forFlashcards || forTextlabbet ? "none" : undefined,
            }}
          >
            <Tooltip title="Byt ämne i underlagsväljaren" placement="left">
              <IconButton
                onClick={handleSubjectMenuClick}
                sx={{
                  color: "text.secondary",
                  "&:hover": { backgroundColor: "action.hover" },
                }}
              >
                <SubjectIcon />
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleSubjectMenuClose}
              PaperProps={{
                sx: {
                  ...styles.roundMenu,
                  borderRadius: "8px",
                },
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              {schoolSubjects.map((subject) => (
                <MenuItem
                  key={subject.value}
                  onClick={() => onSubjectMenuItemClick(subject.value)}
                  sx={styles.menuItemStyle}
                >
                  <subject.Icon
                    fontSize="small"
                    style={{ marginRight: 8, color: "#7B68EE" }}
                  />
                  {subject.label}
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Box>

        <Box
          sx={{
            ...styles.freshBox,
            padding: "30px",
            mb: 7,
            position: "relative",
            overflow: "hidden",
            "&::after": isShimmering
              ? {
                  content: '""',
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  animation: "colorShimmer 0.6s ease-in-out",
                  background: (theme) =>
                    `linear-gradient(90deg, transparent, ${theme.palette.mode === "dark" ? "rgba(255,255,255,0.1)" : "white"}, transparent)`,
                  transform: "translateX(-100%)",
                  "@keyframes colorShimmer": {
                    "0%": { transform: "translateX(-100%)", opacity: 0 },
                    "50%": { opacity: 1 },
                    "100%": { transform: "translateX(100%)", opacity: 0 },
                  },
                }
              : {},
          }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <Box
            sx={{
              position: "relative",
              "&::after":
                !isHovered && !forTextlabbet
                  ? {
                      content: '""',
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      right: 0,
                      height: "100px",
                      background: isStartPage
                        ? `linear-gradient(to bottom, transparent, ${theme.palette.background.paper})`
                        : "transparent",
                      pointerEvents: "none",
                      zIndex: 1,
                    }
                  : {},
            }}
          >
            {isStartPage ? (
              <Collapse
                in={isHovered}
                collapsedSize={forTextlabbet ? 190 : 390}
                timeout="auto"
              >
                {children}
              </Collapse>
            ) : (
              children
            )}
          </Box>
          {!isHovered && (
            <Box
              sx={{
                position: "absolute",
                bottom: 20,
                left: "50%",
                transform: "translateX(-50%)",
                color: "text.secondary",
                fontSize: "0.875rem",
                opacity: 0.7,
                transition: "opacity 0.2s",
                zIndex: 2,
                display: "none",
              }}
            >
              Visa mer
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  if (step === 1) {
    return (
      <ContentWrapper
        title={
          forQuiz
            ? "1. Välj underlag för ditt quiz"
            : forFlashcards
              ? "1. Välj underlag för dina flashcards"
              : forPowerPoint
                ? "1. Välj underlag för din PowerPoint"
                : forLessonPlan
                  ? "1. Välj underlag för din lektionsplan"
                  : forTextlabbet
                    ? "1. Ange din text"
                    : "1. Välj underlag för din lektion"
        }
      >
        <Grid container spacing={3}>
          {/* Custom instructions - First in quiz/flashcards mode */}
          <Grid
            item
            xs={12}
            md={hasCustomThemes ? 4 : 4}
            sx={{ order: forQuiz || forFlashcards ? 1 : 6 }}
          >
            <ThemeFlowBox
              icon={EditIcon}
              title={forTextlabbet ? "Skriv in texten" : "Egna instruktioner"}
              subtitle={
                forTextlabbet
                  ? "Skriv in eller klistra in din text."
                  : 'Skriv vad du vill, t.ex. "Jämställdhetens historia".'
              }
              onClick={() => handleSourceSelect("customTheme")}
              diffView={true}
            />
          </Grid>

          {/* File upload - Second in quiz/flashcards mode */}
          <Grid
            item
            xs={12}
            md={hasCustomThemes ? 4 : 4}
            sx={{ order: forQuiz || forFlashcards ? 4 : 7 }}
          >
            <ThemeFlowBox
              icon={WordIcon2019}
              title="Ladda upp fil"
              subtitle="Ladda upp en Word- eller textfil."
              onClick={() => handleSourceSelect("fileUpload")}
              diffView={true}
            />
          </Grid>

          {/* Subject - Third in quiz/flashcards mode */}
          <Grid
            item
            xs={12}
            md={hasCustomThemes ? 4 : 4}
            sx={{
              order: forQuiz || forFlashcards ? 2 : 1,
              display: forTextlabbet ? "none" : undefined,
            }}
          >
            <ThemeFlowBox
              icon={SchoolIcon}
              iconImage="/img/skolverkets.jpg"
              title={
                subject
                  ? `Kursplan — ${subject.charAt(0).toUpperCase() + subject.slice(1)}`
                  : "Ämne — Skolverket"
              }
              subtitle={
                subject
                  ? "Välj ämnesområde från kursplanen."
                  : "Ämnen från Skolverkets kursplan."
              }
              onClick={() => handleSourceSelect("subject")}
              diffView={true}
            />
          </Grid>

          {/* Books - Kulturrådets boklista */}
          <Grid
            item
            xs={12}
            md={hasCustomThemes ? 4 : 4}
            sx={{
              order: forLessonPlan ? 3 : forQuiz || forFlashcards ? 15 : 15,
              display: forTextlabbet ? "none" : undefined,
            }}
          >
            <ThemeFlowBox
              icon={SchoolIcon}
              iconImage="/img/kulturradet-liten.png"
              title="Kulturrådets läslistor"
              subtitle="Välj bok från Skolverket & Kulturrådets läslistor."
              onClick={() => handleSourceSelect("book")}
              diffView={true}
            />
          </Grid>

          {/* Lesson bank - Hide in quiz/flashcards mode */}
          {!forQuiz && !forFlashcards && (
            <Grid
              item
              xs={12}
              md={hasCustomThemes ? 4 : 4}
              sx={{ order: 2, display: forTextlabbet ? "none" : undefined }}
            >
              <ThemeFlowBox
                icon={StyleIcon}
                title={forPowerPoint ? "Exempel" : "Exempel från lektionsbank"}
                subtitle={
                  forPowerPoint
                    ? "Välj en färdig presentation att anpassa & ladda ned."
                    : "Utvalda lektioner från vår lektionsbank."
                }
                onClick={() => handleSourceSelect("lessonBank")}
                diffView={true}
              />
            </Grid>
          )}

          {/* Predefined themes - Show only if hasCustomThemes */}
          {hasCustomThemes && (
            <Grid
              item
              xs={12}
              md={4}
              sx={{ order: 3, display: forLessonPlan ? "none" : undefined }}
            >
              <ThemeFlowBox
                icon={AutoAwesomeIcon}
                title="Utvalda teman"
                subtitle={
                  forQuiz
                    ? "Teman som passar bra för quiz."
                    : forFlashcards
                      ? "Teman som passar bra för flashcards."
                      : forPowerPoint
                        ? "Teman som passar bra för en PowerPoint."
                        : "Teman som passar denna lektion."
                }
                onClick={() => handleSourceSelect("predefined")}
                diffView={true}
              />
            </Grid>
          )}

          {/* Rest of the items with appropriate order */}
          <Grid
            item
            xs={12}
            md={hasCustomThemes ? 4 : 4}
            sx={{ order: forQuiz || forFlashcards ? 4 : 8 }}
          >
            <ThemeFlowBox
              icon={WikipediaIcon}
              title="Wikipedia-artikel"
              subtitle="Hämta innehåll från en stjärnmärkt Wikipedia-artikel."
              onClick={() => handleSourceSelect("wikipedia")}
              diffView={true}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={hasCustomThemes ? 4 : 4}
            sx={{ order: forQuiz || forFlashcards ? 4 : 8 }}
          >
            <ThemeFlowBox
              icon={LinkIcon}
              title="Webbadress (URL)"
              subtitle="Hämta innehåll från en valfri URL."
              onClick={() => handleSourceSelect("url")}
              diffView={true}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={hasCustomThemes ? 4 : 4}
            sx={{ order: forQuiz || forFlashcards ? 4 : 6 }}
          >
            <ThemeFlowBox
              icon={LinkIcon}
              iconImage="/img/so-logga.png"
              title="SO-rummet"
              subtitle="Hämta innehåll från  valfri artikel från SO-rummet."
              onClick={() => handleSourceSelect("url")}
              diffView={true}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={hasCustomThemes ? 4 : 4}
            sx={{
              order: forQuiz || forFlashcards ? 6 : 25,
              display: forTextlabbet ? "none" : undefined,
            }}
          >
            {" "}
            <Box sx={{ position: "relative" }}>
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backdropFilter: "blur(3px)",
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  zIndex: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "12px",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "text.primary",
                    fontWeight: 600,
                    textAlign: "center",
                    backgroundColor:
                      theme.palette.mode === "dark"
                        ? "rgba(0, 0, 0, 0.4)"
                        : "rgba(255, 255, 255, 0.8)",
                    padding: "8px 16px",
                    borderRadius: "8px",
                  }}
                >
                  UR Play kommer snart!
                </Typography>
              </Box>
              <ThemeFlowBox
                icon={YouTubeIcon}
                iconImage="/img/urplaysmall.png"
                title="UR Play-filmer"
                subtitle="Välj en video producerad av UR Play."
                onClick={() => handleSourceSelect("urplay")}
                diffView={true}
              />
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            md={hasCustomThemes ? 4 : 4}
            sx={{
              order: forQuiz || forFlashcards ? 5 : 25,
              display: forTextlabbet ? "none" : "inherit",
            }}
          >
            <Box sx={{ position: "relative" }}>
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backdropFilter: "blur(3px)",
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  zIndex: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "12px",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "text.primary",
                    fontWeight: 600,
                    textAlign: "center",
                    backgroundColor:
                      theme.palette.mode === "dark"
                        ? "rgba(0, 0, 0, 0.4)"
                        : "rgba(255, 255, 255, 0.8)",
                    padding: "8px 16px",
                    borderRadius: "8px",
                  }}
                >
                  YouTube kommer snart!
                </Typography>
              </Box>
              <ThemeFlowBox
                icon={YouTubeIcon}
                title="YouTube-filmer"
                subtitle="Välj en YouTube-video eller skriv in egen URL."
                onClick={() => handleSourceSelect("youtube")}
                diffView={true}
              />
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            md={hasCustomThemes ? 4 : 4}
            sx={{
              order: 9,
              display: forTextlabbet || forLessonPlan ? "none" : "inherit",
            }}
          >
            <ThemeFlowBox
              icon={ShuffleIcon}
              title="Slumpa ämne"
              subtitle="Låt oss slumpa fram ett spännande ämne åt dig."
              onClick={() => handleSourceSelect("randomizer")}
              diffView={true}
            />
          </Grid>
        </Grid>
      </ContentWrapper>
    );
  }

  if (selectedSource === "lessonBank") {
    return (
      <ContentWrapper
        showBackButton={step > 1}
        title={
          forPowerPoint
            ? "Utvalda exempel på PowerPoints"
            : "Utvalda lektioner från lektionsbanken"
        }
      >
        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 4,
              minHeight: "276px",
            }}
          >
            <CircularProgress />
          </Box>
        ) : lessons.length < 1 ? (
          <Box sx={{ ...styles.successBox }}>
            <SentimentVerySatisfiedIcon
              sx={{ fontSize: 60, color: "success.main", mb: 3 }}
            />
            <Typography
              variant="h6"
              sx={{
                color: "success.main",
                fontWeight: 500,
                textAlign: "center",
                width: "80%",
                margin: "0 auto",
                mb: 3,
              }}
            >
              Snart kommer det att finnas färdiga, noggrant kurerade lektioner
              att välja mellan här. Så titta in snart igen, eller testa generera
              en lektion själv så länge! :)
            </Typography>
          </Box>
        ) : (
          <>
            <Typography
              variant="h6"
              sx={{
                fontWeight: "500",
                mb: 4,
                fontSize: "1rem",
                textAlign: "center",
              }}
            >
              {forPowerPoint
                ? "Välj bland några utvalda presentationer som våra användare skapat. Anpassa och ladda ned!"
                : "Välj bland de mest poulära lektionerna som våra användare skapat för denna lektionstyp."}
            </Typography>
            <Grid container spacing={2}>
              {lessons.map((lesson) => (
                <Grid item xs={12} sm={6} md={4} key={lesson.id}>
                  <Box sx={{ position: "relative" }}>
                    <Box
                      component={Link}
                      to={`/app${lesson.lessonType !== "PowerPointGenerator.js" ? "/lektioner" : ""}/${lessonUrlMapping(lesson.lessonType)}/${lesson.id.substring(0, 10)}${getLessonTypeParam(lesson.lessonType) === "default" ? "" : `?type=${getLessonTypeParam(lesson.lessonType)}`}`}
                      sx={{
                        cursor: "pointer",
                        textDecoration: "none",
                        "&:hover": {
                          opacity: 0.8,
                        },
                      }}
                    >
                      <LessonCard
                        name={lesson.lessonName}
                        comment={lesson.comment}
                        timestamp={lesson.timestamp}
                        lessonType={lesson.lessonType}
                        imageUrl={lesson.coverImage}
                        onClick={() => handleLessonSelect(lesson)}
                        size="large"
                        centered={true}
                        width="285px"
                        height="260px"
                      />
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </ContentWrapper>
    );
  }

  if (selectedSource === "predefined") {
    return (
      <ContentWrapper
        showBackButton={step > 1}
        title={
          forQuiz
            ? "Utvalda teman för quiz"
            : forFlashcards
              ? "Utvalda teman för flashcards"
              : forPowerPoint
                ? "Utvalda teman för PowerPoints"
                : "Utvalda teman för denna lektionstyp"
        }
      >
        <Box sx={{ py: 2 }}>
          {allThemes.length > 0 && (
            <>
              <Grid container spacing={2}>
                {allThemes.map((theme, index) => {
                  if (theme.type === "subheader") {
                    return (
                      <Grid item xs={12} key={`subheader-${index}`}>
                        <Typography
                          variant="h6"
                          sx={{
                            fontWeight: "500",
                            mb: 2,
                            fontSize: "1rem",
                            textAlign: "center",
                          }}
                        >
                          {theme.label}
                        </Typography>
                      </Grid>
                    );
                  }

                  return (
                    <Grid item xs={12} sm={6} md={4} key={theme.value}>
                      <ThemeFlowBox
                        icon={theme.Icon}
                        title={theme.label}
                        isSelected={selectedTheme === theme.value}
                        onClick={() => {
                          setSelectedTheme(theme.value);
                          handlePredefinedThemeSelect(theme.value);
                        }}
                      />
                    </Grid>
                  );
                })}
              </Grid>
              {selectedTheme && (
                <Box sx={{ mt: 4 }}>
                  <Box sx={{ ...styles.successBox }}>
                    <CheckCircleOutline
                      sx={{ fontSize: 60, color: "success.main", mb: 3 }}
                    />
                    <Typography
                      variant="h6"
                      sx={{
                        color: "success.main",
                        fontWeight: 500,
                        textAlign: "center",
                        width: "80%",
                        margin: "0 auto",
                        mb: 3,
                      }}
                    >
                      Tema valt:{" "}
                      {allThemes.find((t) => t.value === selectedTheme)
                        ?.content ||
                        allThemes.find((t) => t.value === selectedTheme)?.label}
                    </Typography>
                  </Box>
                </Box>
              )}

              <CustomThemeInput
                onSubmit={(customValue) => {
                  const newTheme = {
                    value: `custom-${Date.now()}`,
                    label: customValue,
                    Icon: EditIcon,
                    content: customValue,
                    type: "custom",
                  };
                  setAllThemes([...additionalThemes, newTheme]);
                  setSelectedTheme(newTheme.value);
                  handlePredefinedThemeSelect(customValue);
                }}
                theme={theme}
              />
            </>
          )}
        </Box>
      </ContentWrapper>
    );
  }

  if (selectedSource === "subject" || validSchoolSubjects.includes(value)) {
    return (
      <ContentWrapper showBackButton={step > 1} title="Välj ämne:">
        {!validSchoolSubjects.includes(value) && !subject ? (
          <Box
            sx={{
              borderRadius: "10px",
              padding: "24px",
              backgroundColor:
                theme.palette.mode === "dark"
                  ? "rgba(255, 255, 255, 0.05)"
                  : "rgba(252, 252, 252, 0.17)",
              border:
                theme.palette.mode === "dark"
                  ? "1px solid #51585f"
                  : "1px solid #ededed69",
            }}
          >
            <Grid container spacing={2}>
              {schoolSubjects.map((subject) => (
                <Grid item xs={12} sm={6} md={4} key={subject.value}>
                  <ThemeFlowBox
                    icon={subject.Icon}
                    title={subject.label}
                    onClick={() => handleSubjectSelect(subject.value)}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        ) : (
          <>
            <CoursePlanSelector
              subject={subject || value}
              theme={theme}
              styles={styles}
              onTopicSelect={onCoursePlanContentChange}
              forQuiz={forQuiz}
              forFlashcards={forFlashcards}
              forPowerPoint={forPowerPoint}
            />
          </>
        )}
      </ContentWrapper>
    );
  }

  if (selectedSource === "customTheme") {
    return (
      <ContentWrapper
        showBackButton={step > 1}
        title={forTextlabbet ? "Skriv in din text" : "Skriv in eget tema: "}
      >
        <TextField
          fullWidth
          defaultValue={customTheme}
          onBlur={(e) =>
            onCustomThemeChange({ target: { value: e.target.value } })
          }
          placeholder={
            forTextlabbet
              ? "Skriv in eller klistra in din text här."
              : "T.ex. Jämställdhetens historia, Nationaldagen i Albanien, Andra världskriget, Selma Lagerlöf..."
          }
          inputRef={inputRef}
          variant="outlined"
          multiline={true} // Set to true or false, but keep it fixed
          rows={8} // Set to a fixed number
        />

        {forQuiz && (
          <Box sx={{ mt: 2 }}>
            <Suggestions
              listType="quizIdeas"
              maxSuggestions={6}
              onSelect={handleSelect}
            />
          </Box>
        )}

        {forFlashcards && (
          <Box sx={{ mt: 2 }}>
            <Suggestions
              listType="flashcardIdeas"
              maxSuggestions={6}
              onSelect={handleSelect}
            />
          </Box>
        )}
      </ContentWrapper>
    );
  }

  if (selectedSource === "youtube") {
    return (
      <ContentWrapper showBackButton={step > 1} title="Välj video:">
        <YouTubeSelector
          subject={value}
          onTranscriptChange={onYoutubeTranscriptChange}
          forQuiz={forQuiz}
          forFlashcards={forFlashcards}
          forPowerPoint={forPowerPoint}
        />
      </ContentWrapper>
    );
  }

  if (selectedSource === "urplay") {
    return (
      <ContentWrapper showBackButton={step > 1} title="Välj video:">
        <YouTubeSelector
          subject={value}
          onTranscriptChange={onYoutubeTranscriptChange}
          urplay={true}
          forQuiz={forQuiz}
          forFlashcards={forFlashcards}
          forPowerPoint={forPowerPoint}
        />
      </ContentWrapper>
    );
  }

  if (selectedSource === "wikipedia") {
    return (
      <ContentWrapper showBackButton={step > 1} title="Hämta Wikipedia-artikel">
        <WikiFetcher
          forQuiz={forQuiz}
          forFlashcards={forFlashcards}
          forPowerPoint={forPowerPoint}
          forTextlabbet={forTextlabbet}
          styles={styles}
          theme={theme}
          onWikipediaChange={onWikipediaContentChange}
        />
      </ContentWrapper>
    );
  }

  if (selectedSource === "url") {
    return (
      <ContentWrapper
        showBackButton={step > 1}
        title="Hämta innehåll från webbadress"
      >
        <UrlFetcher
          forQuiz={forQuiz}
          forFlashcards={forFlashcards}
          forPowerPoint={forPowerPoint}
          forTextlabbet={forTextlabbet}
          styles={styles}
          theme={theme}
          onUrlContentChange={onUrlContentChange}
          user={user}
        />
      </ContentWrapper>
    );
  }

  if (selectedSource === "fileUpload") {
    return (
      <ContentWrapper
        showBackButton={step > 1}
        title="Ladda upp fil (.docx, .doc eller .txt)"
      >
        <FileUploader
          theme={theme}
          styles={styles}
          forQuiz={forQuiz}
          forFlashcards={forFlashcards}
          forPowerPoint={forPowerPoint}
          forTextlabbet={forTextlabbet}
          onFileContent={(content) => {
            onChange({ target: { value: "fileUpload" } });
            if (onFileUpload) {
              onFileUpload(content);
            }
          }}
        />
      </ContentWrapper>
    );
  }

  if (selectedSource === "randomizer") {
    return (
      <ContentWrapper showBackButton={step > 1} title="Slumpa fram ett ämne">
        <Randomizer
          styles={styles}
          theme={theme}
          forQuiz={forQuiz}
          forFlashcards={forFlashcards}
          forPowerPoint={forPowerPoint}
          onSelect={(content) => {
            if (onRandomizedContentChange) {
              onRandomizedContentChange(content);
            }
          }}
        />
      </ContentWrapper>
    );
  }

  if (selectedSource === "book") {
    return (
      <ContentWrapper
        showBackButton={step > 1}
        title="Välj från Kulturrådets läslistor"
      >
        <BookPlanSelector
          onBookSelect={onBookContentChange}
          styles={styles}
          theme={theme}
        />
      </ContentWrapper>
    );
  }

  return null;
};

export { ThemeSelectorFlow };
