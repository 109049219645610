import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Divider,
  CircularProgress,
  Tooltip,
  FormControl,
  Select,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Chip,
  TableBody,
  Paper,
  Switch,
  MenuItem,
  Grid,
  TextField,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import GenerateIcon from "@mui/icons-material/AutoAwesome";
import ShareLink from "./ShareLink";
import { motion, AnimatePresence } from "framer-motion";
import Dialog from "@mui/material/Dialog";
import { FourSquare } from "react-loading-indicators";
import JeopardyGame from "./JeopardyGame";
import Translate from "./Translate";
import SettingsIcon from "@mui/icons-material/Settings";

import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import SlideInPaper from "./SlideInPaper";
import DialogContent from "@mui/material/DialogContent";
import CheckCircleIcon from "@mui/icons-material/Check";
import LightbulbIcon from "@mui/icons-material/LightbulbOutlined";

import { db } from "./firebase";
import {
  updateDoc,
  collection,
  query,
  where,
  limit,
  getDocs,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";

import CloseIcon from "@mui/icons-material/Close"; // For "Stäng"

import ImageIcon from "@mui/icons-material/Image";

import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";

import PrintIcon from "@mui/icons-material/Print";

import { Alert, AlertTitle } from "@mui/material";
import { callApi } from "./api";

import Logger from "./Logger";

import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";

import TextFieldsIcon from "@mui/icons-material/TextFields";

import LanguageSelector from "./LanguageSelector";
import ClearIcon from "@mui/icons-material/Clear";
import { ThemeSelectorFlow } from "./ThemeSelectorFlow";
import InfoBox from "./InfoBox";
import {} from "./printFunctions";
import {
  generateAndProcessImage,
  handleImageGenerationError,
} from "./functions";
import { useSnackbar } from "./SnackbarHandler";
import ProgressBar from "./ProgressBar";

import ImageGenerationModal from "./ImageGenerationModal";
import GradeSelector, { gradeInstructions } from "./GradeSelector";
import { useTheme } from "@mui/material/styles";
import HistoryIcon from "@mui/icons-material/History";
import { getStyles } from "../index";
import { trackLesson } from "./functions";

import LessonDrawer from "./LessonDrawer";
import SaveLessonDialog from "./SaveLessonDialog";
import HoverableImage from "./HoverableImage";
import { getThemes } from "./additionalThemes/JeopardyLesson";
import { useSearchParams, useParams } from "react-router-dom";

import VisibilityIcon from "@mui/icons-material/Visibility";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import IconButton from "@mui/material/IconButton";

import ShareDialog from "./ShareDialog";
import ShareIcon from "@mui/icons-material/Share";
import LoadingBackdrop from "./LoadingBackdrop";

import { animated, useSpring } from "@react-spring/web";
import { alpha } from "@mui/material/styles";

const AnimatedHint = ({ isRevealed, children }) => {
  const springs = useSpring({
    from: { opacity: 0, transform: "translateY(20px)" },
    to: {
      opacity: isRevealed ? 1 : 0,
      transform: isRevealed ? "translateY(0px)" : "translateY(20px)",
    },
    config: { tension: 280, friction: 20 },
  });

  return <animated.div style={springs}>{children}</animated.div>;
};

//

const JeopardyLesson = ({ user }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);

  const lessonTitleRef = useRef(null); // Add this ref
  const urlContentRef = useRef("");
  const [searchParams, setSearchParams] = useSearchParams();

  const [lessonTheme, setLessonTheme] = useState("");
  const [language, setLanguage] = useState("svenska");

  const [grade, setGrade] = useState("åk6");
  const [revealedHints, setRevealedHints] = useState({});

  const [progress, setProgress] = useState(0);
  const [isGeneratingLesson, setIsGeneratingLesson] = useState(false);
  const [isGeneratingImages, setIsGeneratingImages] = useState(false);

  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [shareDialogOpenFriends, setShareDialogOpenFriends] = useState(false);
  const [isSharing, setIsSharing] = useState(false);

  const [customTheme, setCustomTheme] = useState("");
  const [includeHints, setIncludeHints] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageInstructions, setImageInstructions] = useState("");
  const [openAiInstructions, setOpenAiInstructions] = useState("");

  const [loadingImageIndex, setLoadingImageIndex] = useState(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const [predefinedTheme, setPredefinedTheme] = useState(null);
  const [lessonData, setLessonData] = useState(null);
  const subject = searchParams.get("subject") || null;
  const viewMode = searchParams.get("v") === "t";
  const additionalThemes = getThemes(subject);

  const { showSnackbar } = useSnackbar();
  const theme = useTheme();
  const styles = getStyles(theme);

  const transcriptRef = useRef("");
  const uploadedFileRef = useRef("");
  const [isEditing, setIsEditing] = useState(false);
  const [showEditTable, setShowEditTable] = useState(false);
  const wikipediaContentRef = useRef("");
  const randomizedContentRef = useRef("");
  const bookContentRef = useRef("");
  const coursePlanContentRef = useRef("");

  const [selectedCell, setSelectedCell] = useState(null);
  const [answeredQuestions, setAnsweredQuestions] = useState(new Set());
  const [showAnswer, setShowAnswer] = useState(false);
  const [showAlert, setShowAlert] = useState(true);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const boardRef = useRef(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showControlPanel, setShowControlPanel] = useState(false);
  const translateButtonRef = useRef(null);
  const shareLinkButtonRef = useRef(null);

  const handleRevealHint = (index) => {
    setRevealedHints((prev) => [...prev, index]);
  };

  const toggleViewMode = () => {
    const currentParams = Object.fromEntries(searchParams.entries());
    if (currentParams.v === "t") {
      delete currentParams.v;
    } else {
      currentParams.v = "t";
    }
    setSearchParams(currentParams);
  };

  const handleEditToggle = () => {
    if (!isEditing) {
      setIsEditing(true);
      // Small delay before showing the table
      setTimeout(() => {
        setShowEditTable(true);
      }, 100);
    } else {
      showSnackbar("Ändringarna sparade!", "success");
      setShowEditTable(false);
      setIsEditing(false);
    }
  };

  // Add this function near other handlers
  const handleFullscreen = () => {
    if (!document.fullscreenElement) {
      boardRef.current.requestFullscreen();
      setIsFullscreen(true);
    } else {
      document.exitFullscreen();
      setIsFullscreen(false);
    }
  };

  // Add this useEffect near other useEffects
  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };
    document.addEventListener("fullscreenchange", handleFullscreenChange);
    return () =>
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
  }, []);

  useEffect(() => {
    Logger.log("subject: ", subject);
  }, [subject]);
  useEffect(() => {
    Logger.log("lessonTheme: ", lessonTheme);
  }, [lessonTheme]);

  useEffect(() => {
    Logger.log("predefinedTheme: ", predefinedTheme);
  }, [predefinedTheme]);

  useEffect(() => {
    Logger.log("customTheme: ", customTheme);
  }, [customTheme]);

  useEffect(() => {
    Logger.log("youtubeTranscript: ", transcriptRef.current);
  }, [transcriptRef.current]);

  useEffect(() => {
    Logger.log("wikipediaContentRef: ", wikipediaContentRef.current);
  }, [wikipediaContentRef.current]);
  useEffect(() => {
    Logger.log("uploadedFileRef: ", uploadedFileRef.current);
  }, [uploadedFileRef.current]);

  useEffect(() => {
    Logger.log("randomizedContentRef: ", randomizedContentRef.current);
  }, [randomizedContentRef.current]);

  useEffect(() => {
    Logger.log("coursePlanContentRef: ", coursePlanContentRef.current);
  }, [coursePlanContentRef.current]);

  useEffect(() => {
    Logger.log("lessonData: ", lessonData);
    const isDataValid =
      lessonData !== null &&
      lessonData !== undefined &&
      Object.keys(lessonData).length > 0 &&
      !Object.values(lessonData).includes("");

    if (isDataValid !== showControlPanel) {
      const timer = setTimeout(() => {
        setShowControlPanel(isDataValid);
      }, 100);

      return () => clearTimeout(timer);
    }
  }, [lessonData, showControlPanel]);

  const { lessonId } = useParams();
  useEffect(() => {
    const loadLessonFromId = async () => {
      if (lessonId) {
        try {
          const q = query(
            collection(db, "lesson_usage"),
            where("__name__", ">=", lessonId),
            where("__name__", "<=", lessonId + "\uf8ff"),
            where("lessonType", "==", "JeopardyLesson.js"),
            limit(1),
          );

          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            const docData = querySnapshot.docs[0].data();

            if (!docData.hasOpened) {
              await updateDoc(querySnapshot.docs[0].ref, {
                hasOpened: true,
                openedAt: Date.now(),
              });
            }

            setLessonData(docData.data);

            // Scroll to lesson content
            setTimeout(() => {
              lessonTitleRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }, 100);

            showSnackbar("Lektionen har laddats in.", "success");
          } else {
            showSnackbar("Kunde inte hitta lektionen", "error");
          }
        } catch (error) {
          console.error("Error loading lesson:", error);
          showSnackbar("Ett fel uppstod när lektionen skulle laddas", "error");
        }
      }
    };

    loadLessonFromId();
  }, lessonId);

  const handleCellClick = (categoryIndex, questionIndex) => {
    if (!answeredQuestions.has(`${categoryIndex}-${questionIndex}`)) {
      setSelectedCell(`${categoryIndex}-${questionIndex}`);
    }
  };

  const handleShare = async (
    lessonComment,
    lessonName,
    selectedImage,
    tags,
    shareData,
  ) => {
    setIsSharing(true);
    await new Promise((resolve) => setTimeout(resolve, 1500));
    try {
      // Create a document for each student
      const sharePromises = shareData.studentIds.map(async (studentId) => {
        const documentData = {
          userId: user.uid,
          shared: true,
          sharedTo: studentId,
          comment: shareData.comment,
          sharedAt: Date.now(),
          fileUrl: shareData.fileUrl,
          lessonType: "JeopardyLesson.js",
          timestamp: serverTimestamp(),
          lessonComment,
          lessonName,
          coverImage: selectedImage,
          hasPrinted: true,
          data: lessonData,
        };

        // Create the lesson document and get its ID
        const lessonDoc = await addDoc(
          collection(db, "lesson_usage"),
          documentData,
        );

        Logger.log(
          "Adding document for student",
          studentId,
          ":",
          JSON.stringify(documentData, null, 2),
        );

        // Create notification with the lesson doc ID
        await addDoc(collection(db, "notifications"), {
          type: "lesson_shared",
          recipientId: studentId,
          senderId: user.uid,
          read: false,
          createdAt: serverTimestamp(),
          lessonData: {
            lessonId: lessonDoc.id,
            lessonType: documentData.lessonType,
            lessonName: documentData.lessonName,
            fileUrl: documentData.fileUrl,
            comment: documentData.comment,
            coverImage: selectedImage,
          },
        });

        return lessonDoc;
      });

      await Promise.all(sharePromises);
      showSnackbar(`Du har nu delat lektionen!`, "success");
    } catch (error) {
      showSnackbar("Fel vid delande av lektionen: " + error.message, "error");
      console.error(error);
    } finally {
      setIsSharing(false);
    }
  };

  // Animation variants for the dialog content
  const questionVariants = {
    hidden: {
      opacity: 0,
      scale: 0.8,
      rotateY: -90,
      x: -100,
    },
    visible: {
      opacity: 1,
      scale: 1,
      rotateY: 0,
      x: 0,
      transition: {
        type: "spring",
        duration: 0.8,
        bounce: 0.4,
        mass: 0.8,
        damping: 8,
      },
    },
    exit: {
      opacity: 0,
      scale: 0.8,
      rotateY: 90,
      x: 100,
      transition: {
        duration: 0.4,
      },
    },
  };

  const answerVariants = {
    hidden: {
      opacity: 0,
      scale: 1.5,
      y: 50,
      rotateX: 45,
    },
    visible: {
      opacity: 1,
      scale: 1,
      y: 0,
      rotateX: 0,
      transition: {
        type: "spring",
        duration: 0.6,
        bounce: 0.35,
        mass: 0.7,
        damping: 9,
      },
    },
  };

  const dialogVariants = {
    hidden: {
      opacity: 0,
      scale: 0.5,
      y: 100,
    },
    visible: {
      opacity: 1,
      scale: 1,
      y: 0,
      transition: {
        type: "spring",
        duration: 0.7,
        bounce: 0.3,
      },
    },
    exit: {
      opacity: 0,
      scale: 0.8,
      y: -100,
      transition: {
        duration: 0.3,
      },
    },

    shake: {
      x: [-10, 10, -10, 10, -5, 5, -2, 2, 0],
      transition: {
        duration: 0.5,
        ease: "easeInOut",
      },
    },
  };

  const pointsVariants = {
    hidden: { scale: 0.5, opacity: 0, y: -20 },
    visible: {
      scale: [1.2, 1],
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        times: [0, 1],
        ease: "backOut",
      },
    },
  };

  const buttonVariants = {
    hidden: {
      opacity: 0,
      y: 20,
      scale: 0.8,
    },
    visible: {
      opacity: 1,
      y: 0,
      scale: 1,
      transition: {
        delay: 0.3,
        type: "spring",
        stiffness: 200,
        damping: 12,
      },
    },
    hover: {
      scale: 1.05,
      transition: {
        duration: 0.2,
      },
    },
    tap: {
      scale: 0.95,
    },
  };

  const handleSaveLessonClick = () => {
    setSaveDialogOpen(true);
  };

  const handleSaveWithComment = async (comment, name, coverImage, tags) => {
    Logger.log(JSON.stringify(lessonData, null, 2));
    await trackLesson(
      user.uid,
      "JeopardyLesson.js",
      lessonData,
      null,
      true,
      comment,
      name,
      coverImage,
      tags,
    );
    setDrawerOpen(true);
    showSnackbar("Lektionen sparad i din lektionsbank!", "success");
  };

  const simulateProgress = (startProgress, endProgress, duration) => {
    const stepSize = 0.5;
    const steps = (endProgress - startProgress) / stepSize;
    const stepDuration = duration / steps;
    let currentProgress = startProgress;

    const intervalId = setInterval(() => {
      currentProgress += stepSize;
      if (currentProgress >= endProgress) {
        clearInterval(intervalId);
      } else {
        setProgress(currentProgress);
      }
    }, stepDuration);

    return () => clearInterval(intervalId);
  };

  const handleGenerateLesson = async () => {
    setProgress(0);
    let stopProgressSimulation = null;
    setIsGeneratingLesson(true);
    const gradeInstructionsText = gradeInstructions[grade];
    setLessonData(null);
    setIsEditing(false);
    setAnsweredQuestions(new Set());
    setSelectedCell(null);
    setShowAnswer(false);

    try {
      let themeToUse;

      if (lessonTheme === "youtube") {
        themeToUse = transcriptRef.current;
      } else if (lessonTheme === "customTheme") {
        themeToUse = customTheme;
      } else if (lessonTheme === "predefined") {
        themeToUse = predefinedTheme;
      } else if (lessonTheme === "fileUpload") {
        themeToUse = uploadedFileRef.current;
      } else if (lessonTheme === "wikipedia") {
        themeToUse = wikipediaContentRef.current;
      } else if (lessonTheme === "randomizer") {
        themeToUse = randomizedContentRef.current;
      } else if (lessonTheme === "book") {
        themeToUse = bookContentRef.current;
      } else if (lessonTheme === "url") {
        themeToUse = urlContentRef.current;
      } else {
        //
        themeToUse = coursePlanContentRef.current;
      }

      if (!themeToUse.trim()) {
        showSnackbar("Du måste välja underlag i steg 1.", "warning");
        return;
      }

      Logger.log("themeToUse: ", themeToUse);

      stopProgressSimulation = simulateProgress(0, 90, 15000);
      let response = await callApi(
        [
          {
            role: "system",
            content: `Du är en expertlärare som skapar engagerande och temabaserade Jeopardy-lektioner på ${language}. Follow the exact JSON structure provided in the prompt.`,
          },
          {
            role: "user",
            content: `
      Tema: "${themeToUse}".
      Instruktioner: Skapa ett Jeopardy-spel med EXAKT 5 kategorier som är relaterade till temat. Varje kategori ska innehålla fem frågor med stigande svårighetsgrad och poängvärden (100–500). Ge tydliga svar som överensstämmer entydigt med frågorna.
      Årskurs: "${gradeInstructionsText}".
      Jeopardy-spelet ska vara på "${language}".


Svara ALLTID i följande JSON-format:
      {
        "title": "Titeln på jeopardy-spelet",
        "categories": [
          {
            "name": "Namn på kategori 1",
            "questions": [
              {
                "points": 100,
                "answer": "Svaret för frågan på 100 poäng i denna kategori",
                "question": "Vad är den motsvarande frågan?",
                ${
                  includeHints
                    ? `"hints": [
                    "Tänk på vad som händer när ett skepp seglar mot horisonten",
                    "Fundera på månens form och andra himlakroppar",
                    "Detta bevisades vetenskapligt redan på 1500-talet"
                  ]`
                    : ""
                }
              },
              {
                "points": 200,
                "answer": "Svaret för frågan på 200 poäng i denna kategori",
                "question": "Vad är den motsvarande frågan?",
                 ${
                   includeHints
                     ? `"hints": [
                    "This team also won their first World Cup in 1998",
                    "Their star player Kylian Mbappé was only 19 during this tournament",
                    "They defeated Croatia in the final"
                  ]`
                     : ""
                 }
              }
            ]
          }
        ]
      }
      `,
          },
        ],
        16000,
        true,
        user.uid,
      );

      if (stopProgressSimulation) stopProgressSimulation();
      setProgress(95);

      let messageContent = response.data.choices[0].message.content.trim();
      if (messageContent.startsWith("```json")) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith("```")) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      const data = JSON.parse(messageContent);

      Logger.log(JSON.stringify(data, null, 2));

      setProgress(100);

      setLessonData({
        ...data,
        imageUrl: null,
      });

      await new Promise((resolve) => setTimeout(resolve, 800));

      setTimeout(() => {
        lessonTitleRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, 100);

      await trackLesson(user.uid, "JeopardyLesson.js", null, {
        language: language,
        lessonTheme: lessonTheme,
      });

      showSnackbar("Din lektion har genererats!", "success");
    } catch (error) {
      console.error("Error generating lesson:", error);
      showSnackbar(
        "Ett fel uppstod när lektionen skulle genereras. Försök igen!",
        "error",
      );
    } finally {
      setIsGeneratingLesson(false);
    }
  };

  const handleResetAnswers = () => {
    if (answeredQuestions.size === 0) {
      showSnackbar(
        "Du kan bara återställa svar om du har svarat på minst en fråga först.",
        "info",
      );
      return;
    }
    setAnsweredQuestions(new Set());
    setRevealedHints([]);
    setSelectedCell(null);
    setShowAnswer(false);

    showSnackbar("Brädet återställt!", "success");
  };

  /// Image Generation code

  const handleGenerateImages = async () => {
    if (!lessonData) return;
    setIsGeneratingImages(true);
    handleCloseModal();

    try {
      const imagePrompt = `Create an image with the theme: "${lessonData.title}". ${imageInstructions}`;
      const imagePromises = lessonData.images.map(() =>
        generateAndProcessImage(imagePrompt, user.uid),
      );
      const imageUrls = await Promise.all(imagePromises);

      setLessonData((prevData) => ({
        ...prevData,
        images: imageUrls.map((imageUrl) => ({ imageUrl })),
      }));

      showSnackbar("Bilderna har genererats!", "success");
    } catch (error) {
      handleImageGenerationError(error, true, showSnackbar);
    } finally {
      setIsGeneratingImages(false);
      setLoadingImageIndex(null);
    }
  };

  const handleGenerateSpecificImage = async (index) => {
    setIsGeneratingImages(true);
    setLoadingImageIndex(index);
    handleCloseModal();

    try {
      const imagePrompt = `Create an image with the theme: "${lessonData.title}". ${imageInstructions}`;
      const imageUrl = await generateAndProcessImage(imagePrompt, user.uid);

      if (imageUrl) {
        setLessonData((prevData) => ({
          ...prevData,
          imageUrl: imageUrl,
        }));
        showSnackbar("Bilden har genererats!", "success");
      }
    } catch (error) {
      handleImageGenerationError(error, false, showSnackbar);
    } finally {
      setIsGeneratingImages(false);
      setLoadingImageIndex(null);
    }
  };

  const handleImageButtonClick = (index = null) => {
    setSelectedImageIndex(index);
    setShowImageModal(true);
  };
  const handleCloseModal = () => {
    setShowImageModal(false);
    setImageInstructions("");
  };

  const handleGenerateWithInstructions = async () => {
    if (selectedImageIndex !== null) {
      handleCloseModal();
      await handleGenerateSpecificImage(selectedImageIndex);
    } else {
      handleCloseModal();
      await handleGenerateImages();
    }
  };

  return (
    <Box sx={styles.container}>
      <Box
        sx={{
          ...styles.innerContainer,
          width: { xs: "100%", md: "100%" },
          margin: "0 auto",
        }}
      >
        {!viewMode && (
          <Typography
            variant="h1"
            gutterBottom
            sx={{
              fontWeight: "bold",
              fontSize: "1.8em",
              mb: 3,
              mt: 5,
              textAlign: "center",
            }}
          >
            Jeopardy
          </Typography>
        )}
        <Box
          sx={{
            ...styles.buttonsContainer,
            ...styles.additionalButtonsContainer,
          }}
        >
          {lessonData && user.accountType === "teacher" && (
            <Button
              variant="purp"
              sx={{ ...styles.tightButton }}
              disableRipple
              onClick={() => {
                setTimeout(() => {
                  lessonTitleRef.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "start", // This ensures it scrolls to the top of the element
                  });
                }, 100);
                toggleViewMode();
              }}
              startIcon={
                searchParams.get("v") === "t" ? (
                  <FullscreenExitIcon />
                ) : (
                  <FullscreenIcon />
                )
              }
            >
              {searchParams.get("v") === "t" ? "Redigeringsläge" : "Elevläge"}
            </Button>
          )}
          {!viewMode && (
            <Button
              variant="purp"
              sx={{ ...styles.tightButton }}
              onClick={() => setDrawerOpen(true)}
              startIcon={<BookmarkBorderIcon />}
            >
              Sparat
            </Button>
          )}
        </Box>
        <LessonDrawer
          userId={user.uid}
          lessonType={"JeopardyLesson.js"}
          theme={theme}
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          lessonIcon={TextFieldsIcon}
          onSelectLesson={(lesson) => {
            setLessonData(lesson.data);
            setDrawerOpen(false);
            setTimeout(() => {
              lessonTitleRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "start", // This ensures it scrolls to the top of the element
              });
            }, 100);
            showSnackbar(`Lektionen har laddats in.`, "success");
          }}
        />

        {!viewMode && (
          <>
            <InfoBox
              icon={EmojiEventsIcon}
              title="Jeopardy"
              instructions={[
                "Välj underlag för vad ditt Jeopardy-bräde ska handla om",
                "Redigera brädet och anpassa kategorier, frågor och svar efter behov",
                'Klicka på knappen "Fullskärm" för att gå i ett fokuserat fullskärmsläge framför dina elever',
              ]}
              theme={theme}
              styles={styles}
            />

            <ThemeSelectorFlow
              subject={subject}
              value={lessonTheme}
              onChange={(e) => setLessonTheme(e.target.value)}
              customTheme={customTheme}
              onCustomThemeChange={(e) => setCustomTheme(e.target.value)}
              onPredefinedThemeChange={(e) =>
                setPredefinedTheme(e.target.value)
              }
              onUrlContentChange={(content) => {
                Logger.log(
                  "Parent: URL content received",
                  content.substring(0, 300),
                );
                urlContentRef.current = content;
              }}
              onYoutubeTranscriptChange={(transcriptContent) => {
                Logger.log(
                  "onYoutubeTranscriptChange körs med: ",
                  transcriptContent,
                );
                transcriptRef.current = transcriptContent;
              }}
              onFileUpload={(content) => {
                Logger.log("onFileUpload körs med: ", content);
                uploadedFileRef.current = content;
              }}
              onWikipediaContentChange={(content) => {
                Logger.log(
                  "Parent: Wikipedia content received",
                  content.substring(0, 100),
                );
                wikipediaContentRef.current = content;
              }}
              onRandomizedContentChange={(content) => {
                Logger.log("randomizedContentRef", content.substring(0, 100));
                randomizedContentRef.current = content;
              }}
              onCoursePlanContentChange={(content) => {
                Logger.log("onCoursePlanChange", content.substring(0, 100));
                coursePlanContentRef.current = content;
              }}
              onBookContentChange={(content) => {
                Logger.log("onBookContentChange", content.substring(0, 100));
                bookContentRef.current = content;
              }}
              lessonType={"JeopardyLesson.js"}
              user={user}
              theme={theme}
              styles={styles}
              onLessonSelect={(lesson) => {
                Logger.log("onLessonSelect körs");
                setLessonData(lesson.data);
                setTimeout(() => {
                  lessonTitleRef.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "start", // This ensures it scrolls to the top of the element
                  });
                }, 100);
                showSnackbar("Lektionen har laddats in.", "success");
              }}
              additionalThemes={additionalThemes}
            />

            <Box sx={styles.headlineLessonBox}>
              <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
                2. Generera lektionen
              </Typography>
            </Box>
            <Box sx={{ ...styles.freshBox, padding: "50px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "500", mb: 1 }}
                  >
                    Årskurs:
                  </Typography>
                  <Box sx={{ mt: 0.5, mb: 3 }}>
                    <GradeSelector grade={grade} setGrade={setGrade} />
                  </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle1" sx={{ fontWeight: "500" }}>
                    Språk:
                  </Typography>
                  <Box sx={{ mt: 1, mb: 3 }}>
                    <LanguageSelector
                      language={language}
                      setLanguage={setLanguage}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={4} sx={{ display: "none" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "500", mb: 1 }}
                  >
                    Ledtrådar:
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      backgroundColor:
                        theme.palette.mode === "dark" ? "#394653" : "#f5f5f5",
                      borderRadius: "8px",
                      mb: 4,
                      mt: 0,
                      padding: 1,
                      paddingLeft: "15px",
                    }}
                  >
                    <Typography variant="formLabelSinUnderline">
                      Inkludera ledtrådar
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Switch
                        checked={includeHints}
                        onChange={(e) => setIncludeHints(e.target.checked)} // This is the correct way
                        inputProps={{ "aria-label": "include hints" }}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "12px",
                  width: "100%",
                }}
              >
                <Button
                  variant={
                    theme.palette.mode === "light" ? "purp" : "contained"
                  }
                  color="primary"
                  onClick={handleGenerateLesson}
                  disabled={isGeneratingLesson}
                  sx={{ mt: 4 }}
                  startIcon={
                    !isGeneratingLesson ? (
                      <GenerateIcon style={{ color: "#fff" }} />
                    ) : (
                      <CircularProgress style={{ color: "#fff" }} size={16} />
                    )
                  }
                >
                  {isGeneratingLesson ? "Genererar..." : "Generera lektion"}
                </Button>
              </div>
              {isGeneratingLesson && (
                <Box sx={{ mt: 6 }}>
                  <ProgressBar
                    progress={progress}
                    label="Genererar lektion — tar 30-60 sekunder..."
                  />
                </Box>
              )}
            </Box>
          </>
        )}
        {lessonData && (
          <>
            {!viewMode && (
              <Box
                ref={lessonTitleRef}
                sx={{ ...styles.headlineLessonBox, mt: 10 }}
              >
                <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
                  3. Din lektion
                </Typography>
              </Box>
            )}
            <Box
              sx={{
                ...styles.freshBox,
                padding: "30px",
                width: viewMode ? "75%" : "100%",
                margin: "0 auto",

                ...(viewMode && { backgroundColor: "transparent" }),
                ...(viewMode && { borderColor: "transparent" }),
              }}
            >
              {!viewMode && (
                <Box sx={{ position: "relative" }}>
                  <Chip
                    label={
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                      >
                        <SettingsIcon sx={{ fontSize: 14 }} />
                        Kontrollpanel
                      </Box>
                    }
                    size="small"
                    sx={{
                      ...styles.greenChip,
                      backgroundColor: "#e7f0e7",

                      height: "24px",
                      position: "absolute",

                      top: "-10px",
                      transform: "translateX(-50%)",
                      zIndex: 9999999,
                      display: "none",
                    }}
                  />
                  <Box
                    sx={{
                      ...styles.lessonControlPanel,
                      ...styles.lessonControlPanelNuevo,

                      "& .MuiButton-root": {
                        ...styles.controlPanelButtons,
                        ...styles.lessonControlPanelNewButtons,
                      },
                    }}
                  >
                    <Button
                      variant="purp"
                      startIcon={
                        isEditing ? (
                          <SaveAsIcon sx={{ color: "green" }} />
                        ) : (
                          <EditIcon />
                        )
                      }
                      sx={{
                        ml: 2,

                        ...(isEditing && {
                          color: "green !important",
                          "&.MuiButton-root": {
                            fontWeight: "700 !important",
                          },
                        }),
                      }}
                      onClick={handleEditToggle}
                    >
                      {isEditing ? "Spara ändringar" : "Redigera brädet"}
                    </Button>
                    <Button
                      variant="print"
                      startIcon={<SaveAsIcon />}
                      onClick={handleSaveLessonClick}
                      sx={{ ml: 0, mt: 2 }}
                    >
                      Spara i min lektionsbank
                    </Button>
                    <ShareLink
                      buttonRef={shareLinkButtonRef}
                      key={lessonId}
                      lessonId={lessonId}
                      lessonData={lessonData}
                      user={user}
                      lessonType={"JeopardyLesson.js"}
                      onCopy={(message, severity) =>
                        showSnackbar(message, severity)
                      }
                      sx={{ mt: 0 }}
                    />

                    <Button
                      variant="success"
                      sx={{}}
                      onClick={() => setShareDialogOpenFriends(true)}
                      startIcon={<ShareIcon />}
                    >
                      Dela med vän
                    </Button>

                    <Button
                      variant="success"
                      sx={{}}
                      onClick={() => setShareDialogOpen(true)}
                      startIcon={<ShareIcon />}
                    >
                      Dela med elever
                    </Button>

                    <Button
                      variant="print"
                      startIcon={<ClearIcon />}
                      sx={{ mt: 2, ml: 2 }}
                      onClick={handleResetAnswers}
                    >
                      Återställ svar
                    </Button>
                    <Translate
                      buttonRef={translateButtonRef}
                      lessonData={lessonData}
                      onTranslated={setLessonData}
                      callApi={callApi}
                      user={user}
                      showSnackbar={showSnackbar}
                    />

                    <Tooltip title="Hur lektionen ser ut för eleverna">
                      <Button
                        variant="success"
                        onClick={() => {
                          window.scrollTo({ top: 0, behavior: "smooth" });
                          toggleViewMode();
                        }}
                        sx={{ ml: 2 }}
                        startIcon={
                          searchParams.get("v") === "t" ? (
                            <FullscreenExitIcon />
                          ) : (
                            <FullscreenIcon />
                          )
                        }
                      >
                        {searchParams.get("v") === "t"
                          ? "Redigeringsläge"
                          : "Elevläge"}
                      </Button>
                    </Tooltip>

                    <Button
                      variant="purp"
                      startIcon={
                        isFullscreen ? (
                          <FullscreenExitIcon />
                        ) : (
                          <FullscreenIcon />
                        )
                      }
                      sx={{ ml: 2 }}
                      onClick={handleFullscreen}
                    >
                      {isFullscreen ? "Avsluta fullskärm" : "Fullskärm"}
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      ...styles.lessonControlPanelFade,
                    }}
                  />
                </Box>
              )}
              {/* Jeopardy Board */}
              {/* Jeopardy Board */}

              <JeopardyGame
                lessonData={lessonData}
                setLessonData={setLessonData}
                isFullscreen={isFullscreen}
                theme={theme}
                viewMode={viewMode}
                showSnackbar={showSnackbar}
                boardRef={boardRef}
                isEditing={isEditing}
                showEditTable={showEditTable}
                answeredQuestions={answeredQuestions}
                setAnsweredQuestions={setAnsweredQuestions}
                revealedHints={revealedHints}
                setRevealedHints={setRevealedHints}
              />
              {!viewMode && (
                <>
                  <Divider sx={{ mt: 15, mb: 10 }} />

                  <Box sx={{ maxWidth: "400px", mx: "auto", mb: 4 }}>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontSize: "1rem",
                        color: theme.palette.text.secondary,
                        textAlign: "center",
                        mb: 2,
                      }}
                    >
                      Generera en lektionsbild som du kan använda när du sparar
                      din lektion för att enklare hitta den vid senare
                      tillfälle.
                    </Typography>

                    <HoverableImage
                      imageUrl={lessonData.imageUrl}
                      onGenerateClick={() => handleImageButtonClick(0)}
                      isLoading={isGeneratingImages}
                      loadingIndex={loadingImageIndex}
                      currentIndex={0}
                      userId={user.uid}
                      onImageUpdate={(url) =>
                        setLessonData((prev) => ({ ...prev, imageUrl: url }))
                      }
                      theme={theme}
                      showSnackbar={showSnackbar}
                    />
                  </Box>
                </>
              )}
              <SaveLessonDialog
                key={lessonData?.title}
                open={saveDialogOpen}
                onClose={() => setSaveDialogOpen(false)}
                onSave={handleSaveWithComment}
                theme={theme}
                lessonName={lessonData?.title || ""}
                lessonObject={lessonData}
                lessonType={"JeopardyLesson.js"}
              />

              <SlideInPaper
                isExpanded={isExpanded}
                onExpandedChange={setIsExpanded}
                lessonTitleRef={lessonTitleRef}
                viewMode={viewMode}
                toggleViewMode={toggleViewMode}
                theme={theme}
                open={showControlPanel}
                user={user}
                onTranslate={() => translateButtonRef.current?.click()}
                onShareLink={() => shareLinkButtonRef.current?.click()}
                onShareWithStudents={() => setShareDialogOpen(true)}
                onShareWithFriends={() => setShareDialogOpenFriends(true)}
                onSaveToBank={() => setSaveDialogOpen(true)}
                onStudentMode={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                  toggleViewMode();
                }}
                onEdit={(e) => {
                  e.stopPropagation();
                  handleEditToggle();
                  setIsExpanded(false);
                }}
                onResetAnswers={() => handleResetAnswers()}
                onFullscreen={() => handleFullscreen()}
              />

              <ShareDialog
                open={shareDialogOpen}
                onClose={() => setShareDialogOpen(false)}
                onShare={handleShare}
                user={user}
                isLessonSharing={true}
                theme={theme}
                lessonName={lessonData?.title || ""}
                lessonObject={lessonData}
                lessonType={"JeopardyLesson.js"}
              />

              <ShareDialog
                open={shareDialogOpenFriends}
                onClose={() => setShareDialogOpenFriends(false)}
                onShare={handleShare}
                user={user}
                isLessonSharing={true}
                theme={theme}
                lessonName={lessonData?.title || ""}
                lessonObject={lessonData}
                lessonType={"JeopardyLesson.js"}
                shareWithFriends={true}
              />

              <LoadingBackdrop
                isOpen={isSharing}
                imagePath="/img/AI.png"
                loadingText={"Delar lektionen..."}
              />

              <ImageGenerationModal
                open={showImageModal}
                onClose={handleCloseModal}
                onGenerate={handleGenerateWithInstructions}
                isGenerating={isGeneratingImages}
                instructions={imageInstructions}
                setInstructions={setImageInstructions}
                theme={theme}
              />
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default JeopardyLesson;
