import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Stack,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import TableChartIcon from "@mui/icons-material/TableChart";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import SentimentNeutralIcon from "@mui/icons-material/SentimentNeutral";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";

import { East as ArrowIcon } from "@mui/icons-material";
import LanguageSelector from "./LanguageSelector";

const SummarizeDialog = ({ open, onClose, onSubmit, theme }) => {
  const [topic, setTopic] = useState("");
  const [format, setFormat] = useState("text");
  const [difficulty, setDifficulty] = useState("medium");
  const [language, setLanguage] = useState("svenska");

  const handleSubmit = () => {
    if (!topic.trim()) return;

    const formatMap = {
      text: "Kort text",
      bullets: "Punktlista",
      table: "Tabell",
      qa: "Frågor och svar",
    };

    const difficultyMap = {
      easy: "lätt",
      medium: "medel",
      hard: "svår",
    };

    const prompt = `Sammanfatta det viktigaste från ${topic}. Format: ${formatMap[format]}. Svårighetsgrad: ${difficultyMap[difficulty]}. Språk: ${language}`;

    onSubmit(prompt);
    onClose();
    setTopic("");
    setFormat("text");
    setDifficulty("medium");
  };

  const formatButtons = [
    { value: "text", label: "Text", icon: TextFieldsIcon },
    { value: "bullets", label: "Punktlista", icon: FormatListBulletedIcon },
    { value: "table", label: "Tabell", icon: TableChartIcon },
    { value: "qa", label: "Frågor & svar", icon: QuestionAnswerIcon },
  ];

  const difficultyButtons = [
    { value: "easy", label: "Lätt", icon: SentimentSatisfiedAltIcon },
    { value: "medium", label: "Medel", icon: SentimentNeutralIcon },
    { value: "hard", label: "Svår", icon: SentimentVeryDissatisfiedIcon },
  ];

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: 680,
          backgroundColor:
            theme.palette.mode === "dark" ? "background.default" : "white",
          border:
            theme.palette.mode === "dark"
              ? "1px solid #323d48"
              : "1px solid #e2e8f0",
          p: 3,
        },
      }}
    >
      <IconButton
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 16,
          top: 16,
          color:
            theme.palette.mode === "dark"
              ? "rgba(255, 255, 255, 0.7)"
              : "inherit",
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 1,
          color:
            theme.palette.mode === "dark"
              ? "rgba(255, 255, 255, 0.7)"
              : "inherit",
          pt: 2,
        }}
      >
        <Typography variant="h5" fontWeight={700}>
          Vad vill du sammanfatta?
        </Typography>
      </DialogTitle>

      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: 4,
          pb: 4,
        }}
      >
        <TextField
          autoFocus
          fullWidth
          multiline
          rows={2}
          maxRows={2}
          value={topic}
          onChange={(e) => setTopic(e.target.value)}
          placeholder="Skriv ämnet eller texten här..."
          inputProps={{ maxLength: 5000 }}
          sx={{
            mt: 2,
            mb: 4,
          }}
        />

        <Stack spacing={2} width="100%" alignItems="center" sx={{ mb: 4 }}>
          <Typography variant="subtitle1" fontWeight={600}>
            Välj språk:
          </Typography>
          <LanguageSelector language={language} setLanguage={setLanguage} />
        </Stack>

        <Stack spacing={3} width="100%" alignItems="center">
          <Typography variant="subtitle1" fontWeight={600}>
            Välj format:
          </Typography>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: 2,
              width: "100%",
            }}
          >
            {formatButtons.map(({ value, label, icon: Icon }) => (
              <Button
                key={value}
                variant={format === value ? "save" : "print"}
                onClick={() => setFormat(value)}
                sx={{ height: 45 }}
                color={format === value ? "primary" : "inherit"}
                startIcon={format === value ? <CheckCircleIcon /> : <Icon />}
              >
                {label}
              </Button>
            ))}
          </Box>
        </Stack>

        <Stack spacing={3} width="100%" alignItems="center" sx={{ mt: 4 }}>
          <Typography variant="subtitle1" fontWeight={600}>
            Välj svårighetsgrad:
          </Typography>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gap: 2,
              width: "100%",
            }}
          >
            {difficultyButtons.map(({ value, label, icon: Icon }) => (
              <Button
                key={value}
                variant={difficulty === value ? "save" : "print"}
                onClick={() => setDifficulty(value)}
                sx={{ height: 45 }}
                color={difficulty === value ? "primary" : "inherit"}
              >
                {label}
              </Button>
            ))}
          </Box>
        </Stack>
      </DialogContent>

      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          onClick={handleSubmit}
          variant="purp"
          disabled={!topic.trim()}
          sx={{ width: 200, height: 45 }}
        >
          Sammanfatta <ArrowIcon sx={{ ml: 1 }} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SummarizeDialog;
