import React, { useState, useEffect } from "react";
import { Box, Typography, LinearProgress, Button } from "@mui/material";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "./firebase";

import NetworkCheckIcon from "@mui/icons-material/NetworkCheck";

const UsageBox = ({ userId, styles, compactMode }) => {
  const [lessonCount, setLessonCount] = useState(0);
  const [imageCount, setImageCount] = useState(0);
  const [timeUntilReset, setTimeUntilReset] = useState("");

  useEffect(() => {
    const fetchUsage = async () => {
      try {
        // Get start and end of current day in local timezone
        const now = new Date();
        const startOfDay = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate(),
        );
        const endOfDay = new Date(startOfDay);
        endOfDay.setDate(endOfDay.getDate() + 1);

        // Fetch lessons created today
        const lessonQuery = query(
          collection(db, "lesson_usage"),
          where("userId", "==", userId),
          where("timestamp", ">=", startOfDay),
          where("timestamp", "<", endOfDay),
        );
        const lessonDocs = await getDocs(lessonQuery);
        setLessonCount(lessonDocs.size);

        // Fetch images created today
        const imageQuery = query(
          collection(db, "images"),
          where("userId", "==", userId),
          where("createdAt", ">=", startOfDay),
          where("createdAt", "<", endOfDay),
        );
        const imageDocs = await getDocs(imageQuery);
        setImageCount(imageDocs.size);
      } catch (error) {
        console.error("Error fetching usage:", error);
      }
    };

    const updateTimeUntilReset = () => {
      const now = new Date();
      const tomorrow = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() + 1,
      );
      const diffMs = tomorrow - now;
      const diffHours = Math.floor(diffMs / (1000 * 60 * 60));
      const diffMinutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));

      let timeText = "";
      if (diffHours >= 1) {
        timeText = `om ${diffHours} ${diffHours === 1 ? "timme" : "timmar"}`;
        if (diffMinutes > 0) {
          timeText += ` och ${diffMinutes} ${diffMinutes === 1 ? "minut" : "minuter"}`;
        }
      } else {
        timeText = `om ${diffMinutes} ${diffMinutes === 1 ? "minut" : "minuter"}`;
      }

      setTimeUntilReset(timeText);
    };

    if (userId) {
      fetchUsage();
      updateTimeUntilReset();

      // Update the countdown every minute
      const timer = setInterval(updateTimeUntilReset, 60000);
      return () => clearInterval(timer);
    }
  }, [userId]);

  return (
    <Box
      sx={{
        ...styles.freshBox,
        ...(compactMode && styles.softBoxShadow),
        padding: compactMode ? "20px" : "30px",

        maxWidth: 360,
      }}
    >
      {!compactMode && (
        <Box
          sx={{
            ...styles.lessonControlPanel,
            padding: "20px",
            marginBottom: "20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex", // Add this
                alignItems: "center", // Add this
                gap: 1, // Optional: adds some spacing between icon and text
              }}
            >
              <NetworkCheckIcon />
              <Typography variant="body1" sx={{ fontWeight: "600" }}>
                Min användning idag
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
      {!compactMode && (
        <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
          Återställs {timeUntilReset}
        </Typography>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          variant="body1"
          sx={{
            mb: 1,
            color: "text.secondary",
            fontSize: "0.9rem",
            fontWeight: "500",
          }}
        >
          Bilder genererade
        </Typography>
        <Typography
          variant="body1"
          sx={{
            mb: 1,
            color: "text.secondary",
            fontSize: "0.9rem",
            fontWeight: "500",
          }}
        >
          {imageCount}/{process.env.REACT_APP_IMAGE_GENERATION_LIMIT}
        </Typography>
      </Box>
      <LinearProgress
        variant="determinate"
        value={
          (imageCount / process.env.REACT_APP_IMAGE_GENERATION_LIMIT) * 100
        }
        sx={{
          height: 10,
          mb: 2,
          borderRadius: 4,
          bgcolor: "grey.200",
          "& .MuiLinearProgress-bar": {
            bgcolor: "text.main",
          },
        }}
      />

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          variant="body1"
          sx={{
            mb: 1,
            color: "text.secondary",
            fontSize: "0.9rem",
            fontWeight: "500",
          }}
        >
          Lektioner genererade
        </Typography>
        <Typography
          variant="body1"
          sx={{
            mb: 1,
            color: "text.secondary",
            fontSize: "0.9rem",
            fontWeight: "500",
          }}
        >
          {lessonCount}/{process.env.REACT_APP_LESSON_GENERATION_LIMIT}
        </Typography>
      </Box>
      <LinearProgress
        variant="determinate"
        value={
          (lessonCount / process.env.REACT_APP_LESSON_GENERATION_LIMIT) * 100
        }
        sx={{
          height: 10,
          borderRadius: 4,
          bgcolor: "grey.200",
          "& .MuiLinearProgress-bar": {
            bgcolor: "#3b82f6",
          },
        }}
      />
      {!compactMode && (
        <Button variant="purp" color="primary" fullWidth sx={{ mt: 4 }}>
          Uppgradera
        </Button>
      )}
    </Box>
  );
};

export default UsageBox;
