import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Tooltip,
  IconButton,
  Typography,
  Divider,
  CircularProgress,
  Avatar,
  Grid,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import GenerateIcon from "@mui/icons-material/AutoAwesome";
import ProgressBar from "./ProgressBar";
import ImageIcon from "@mui/icons-material/Image";
import LoadingBackdrop from "./LoadingBackdrop";
import ImageLoadingDialog from "./ImageLoadingDialog";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";

import { callApi } from "./api";
import { handlePrintSentenceFinisher } from "./printFunctions";
import { useSearchParams, useParams } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import LanguageSelector from "./LanguageSelector";
import PrintIcon from "@mui/icons-material/Print";
import InfoBox from "./InfoBox";
import SaveIcon from "@mui/icons-material/Save";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import { generateAndProcessImage, trackLesson } from "./functions";
import { ThemeSelectorFlow } from "./ThemeSelectorFlow";
import { useSnackbar } from "./SnackbarHandler";
import { FourSquare } from "react-loading-indicators";
import ShareIcon from "@mui/icons-material/Share";

import Logger from "./Logger";

import ShareLink from "./ShareLink";
import PrintAsPdf from "./PrintAsPdf";
import SlideInPaper from "./SlideInPaper";
import Translate from "./Translate";
import ShareDialog from "./ShareDialog";

import { ThemeSelector } from "./ThemeSelector";
import ImageGenerationModal from "./ImageGenerationModal";
import GradeSelector, { gradeInstructions } from "./GradeSelector";

import { db } from "./firebase";
import {
  collection,
  query,
  where,
  limit,
  addDoc,
  updateDoc,
  getDocs,
  serverTimestamp,
} from "firebase/firestore";
import { useTheme } from "@mui/material/styles";
import { getStyles } from "../index";

import EditIcon from "@mui/icons-material/Edit";

import LessonDrawer from "./LessonDrawer";
import SaveLessonDialog from "./SaveLessonDialog";

import HistoryIcon from "@mui/icons-material/History";
import HoverableImage from "./HoverableImage";

import {
  School as SchoolIcon,
  Watch as WatchIcon,
  LocalLibrary as LocalLibraryIcon,
  Castle as CastleIcon,
  Computer as ComputerIcon,
  Group as GroupIcon,
  LocationCity as LocationCityIcon,
  Public as PublicIcon,
  Person as PersonIcon,
  Shield as ShieldIcon,
  MenuBook as MenuBookIcon,
  Science as ScienceIcon,
} from "@mui/icons-material";

const SentenceFinisherLesson = ({ user }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);

  const [lessonTheme, setLessonTheme] = useState("generateWithAi");
  const [language, setLanguage] = useState("svenska");

  const [loadingImageIndex, setLoadingImageIndex] = useState(null);
  const [progress, setProgress] = useState(0);

  const [isGeneratingLesson, setIsGeneratingLesson] = useState(false);

  const [customTheme, setCustomTheme] = useState("");
  const [isGeneratingStoryImages, setIsGeneratingStoryImages] = useState(false);
  const { showSnackbar } = useSnackbar();
  const loadingBarRef = useRef(null);
  const [grade, setGrade] = useState("åk6");
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageInstructions, setImageInstructions] = useState("");
  const [imageGenerationType, setImageGenerationType] = useState(null);

  const [lessonData, setLessonData] = useState(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const [isEditingStory, setIsEditingStory] = useState(false);

  const theme = useTheme();
  const styles = getStyles(theme);

  const [isEditingTitle, setIsEditingTitle] = useState(false);

  const [editingSections, setEditingSections] = useState({
    continuations: false,
    plotTwists: false,
    environments: false,
  });

  const [predefinedTheme, setPredefinedTheme] = useState(null);
  const lessonTitleRef = useRef(null); // Add this ref
  const transcriptRef = useRef("");
  const uploadedFileRef = useRef("");
  const urlContentRef = useRef("");
  const wikipediaContentRef = useRef("");
  const randomizedContentRef = useRef("");
  const coursePlanContentRef = useRef("");

  const translateButtonRef = useRef(null);
  const pdfButtonRef = useRef(null);
  const shareLinkButtonRef = useRef(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showControlPanel, setShowControlPanel] = useState(false);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [isSharing, setIsSharing] = useState(false);
  const [shareDialogOpenFriends, setShareDialogOpenFriends] = useState(false);

  const bookContentRef = useRef("");

  const additionalThemes = [
    {
      type: "subheader",
      label: "Teman som lämpar sig extra bra för denna lektionstyp:",
    },
    {
      value: "mysteriet-pa-internatskolan",
      label: "Mysteriet på internatskolan",
      content: "Mysteriet på internatskolan",
      Icon: SchoolIcon,
    },
    {
      value: "tidresenarens-dagbok",
      label: "Tidresenärens dagbok",
      content: "Tidresenärens dagbok",
      Icon: WatchIcon,
    },
    {
      value: "det-forbjudna-biblioteket",
      label: "Det förbjudna biblioteket",
      content: "Det förbjudna biblioteket",
      Icon: LocalLibraryIcon,
    },
    {
      value: "de-glomda-kungadomena",
      label: "De glömda kungadömena",
      content: "De glömda kungadömena",
      Icon: CastleIcon,
    },
    {
      value: "aventyr-i-den-digitala-varlden",
      label: "Äventyr i den digitala världen",
      content: "Äventyr i den digitala världen",
      Icon: ComputerIcon,
    },
    {
      value: "den-hemliga-klubben",
      label: "Den hemliga klubben",
      content: "Den hemliga klubben",
      Icon: GroupIcon,
    },
    {
      value: "overgivna-platser",
      label: "Övergivna platser och deras hemligheter",
      content: "Övergivna platser och deras hemligheter",
      Icon: LocationCityIcon,
    },
    {
      value: "varldernas-krig",
      label: "Världarnas krig",
      content: "Världarnas krig",
      Icon: PublicIcon,
    },
    {
      value: "den-sista-overlevaren",
      label: "Den sista överlevaren",
      content: "Den sista överlevaren",
      Icon: PersonIcon,
    },
    {
      value: "kampen-om-drakens-krona",
      label: "Kampen om drakens krona",
      content: "Kampen om drakens krona",
      Icon: ShieldIcon,
    },
  ];

  const [searchParams, setSearchParams] = useSearchParams();
  const subject = searchParams.get("subject") || null;

  const viewMode = searchParams.get("v") === "t";

  useEffect(() => {
    Logger.log("subject: ", subject);
  }, [subject]);

  useEffect(() => {
    Logger.log("lessonTheme: ", lessonTheme);
  }, [lessonTheme]);

  useEffect(() => {
    Logger.log("predefinedTheme: ", predefinedTheme);
  }, [predefinedTheme]);

  useEffect(() => {
    Logger.log("customTheme: ", customTheme);
  }, [customTheme]);

  useEffect(() => {
    Logger.log("youtubeTranscript: ", transcriptRef.current);
  }, [transcriptRef.current]);

  useEffect(() => {
    Logger.log("wikipediaContentRef: ", wikipediaContentRef.current);
  }, [wikipediaContentRef.current]);
  useEffect(() => {
    Logger.log("uploadedFileRef: ", uploadedFileRef.current);
  }, [uploadedFileRef.current]);

  useEffect(() => {
    Logger.log("randomizedContentRef: ", randomizedContentRef.current);
  }, [randomizedContentRef.current]);

  useEffect(() => {
    Logger.log("coursePlanContentRef: ", coursePlanContentRef.current);
  }, [coursePlanContentRef.current]);

  const { lessonId } = useParams();

  useEffect(() => {
    Logger.log("lessonData: ", JSON.stringify(lessonData, null, 2));
  }, [lessonData]);

  useEffect(() => {
    const loadLessonFromId = async () => {
      if (lessonId) {
        try {
          const q = query(
            collection(db, "lesson_usage"),
            where("__name__", ">=", lessonId),
            where("__name__", "<=", lessonId + "\uf8ff"),
            where("lessonType", "==", "SentenceFinisherLesson.js"),
            limit(1),
          );

          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            const docData = querySnapshot.docs[0].data();

            if (!docData.hasOpened) {
              await updateDoc(querySnapshot.docs[0].ref, {
                hasOpened: true,
                openedAt: Date.now(),
              });
            }

            setLessonData(docData.data);

            // Scroll to lesson content
            setTimeout(() => {
              lessonTitleRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }, 100);

            showSnackbar("Lektionen har laddats in.", "success");
          } else {
            showSnackbar("Kunde inte hitta lektionen", "error");
          }
        } catch (error) {
          console.error("Error loading lesson:", error);
          showSnackbar("Ett fel uppstod när lektionen skulle laddas", "error");
        }
      }
    };

    loadLessonFromId();
  }, lessonId);

  useEffect(() => {
    Logger.log("lessonData: ", lessonData);
    const isDataValid =
      lessonData !== null &&
      lessonData !== undefined &&
      Object.keys(lessonData).length > 0 &&
      !Object.values(lessonData).includes("");

    if (isDataValid !== showControlPanel) {
      const timer = setTimeout(() => {
        setShowControlPanel(isDataValid);
      }, 100);

      return () => clearTimeout(timer);
    }
  }, [lessonData, showControlPanel]);

  const handleShare = async (
    lessonComment,
    lessonName,
    selectedImage,
    tags,
    shareData,
  ) => {
    setIsSharing(true);
    await new Promise((resolve) => setTimeout(resolve, 1500));
    // try {
    //   // Create a document for each student
    //   const sharePromises = shareData.studentIds.map((studentId) => {
    //     const documentData = {
    //       userId: user.uid,
    //       shared: true,
    //       sharedTo: studentId,
    //       comment: shareData.comment,
    //       sharedAt: Date.now(),
    //       fileUrl: shareData.fileUrl,
    //       lessonType: "LettersLesson.js",
    //       timestamp: serverTimestamp(),
    //       lessonComment,
    //       lessonName,
    //       coverImage: selectedImage,
    //       hasPrinted: true,
    //       data: lessonData,
    //     };
    //     Logger.log(
    //       "Adding document for student",
    //       studentId,
    //       ":",
    //       JSON.stringify(documentData, null, 2),
    //     );
    //     return addDoc(collection(db, "lesson_usage"), documentData);
    //   });
    //   await Promise.all(sharePromises);
    //   showSnackbar(`Du har nu delat lektionen!`, "success");
    // }

    try {
      // Create a document for each student and then create notifications
      const sharePromises = shareData.studentIds.map(async (studentId) => {
        const documentData = {
          userId: user.uid,
          shared: true,
          sharedTo: studentId,
          comment: shareData.comment,
          sharedAt: Date.now(),
          fileUrl: shareData.fileUrl,
          lessonType: "SentenceFinisherLesson.js",
          timestamp: serverTimestamp(),
          lessonComment,
          lessonName,
          coverImage: selectedImage,
          hasPrinted: true,
          data: lessonData,
        };
        // Create lesson document and get ID
        const lessonDoc = await addDoc(
          collection(db, "lesson_usage"),
          documentData,
        );
        Logger.log(
          "Adding document for student",
          studentId,
          ":",
          JSON.stringify(documentData, null, 2),
        );
        // Create notification with lesson doc ID
        await addDoc(collection(db, "notifications"), {
          type: "lesson_shared",
          recipientId: studentId,
          senderId: user.uid,
          read: false,
          createdAt: serverTimestamp(),
          lessonData: {
            lessonId: lessonDoc.id,
            lessonType: documentData.lessonType,
            lessonName: documentData.lessonName,
            fileUrl: documentData.fileUrl,
            comment: documentData.comment,
            coverImage: selectedImage,
          },
        });
        return lessonDoc;
      });
      await Promise.all(sharePromises);
      showSnackbar(`Du har nu delat lektionen!`, "success");
    } catch (error) {
      showSnackbar("Fel vid delande av lektionen: " + error.message, "error");
      console.error(error);
    } finally {
      setIsSharing(false);
    }
  };

  const handleSaveLessonClick = () => {
    setSaveDialogOpen(true);
  };

  const handleSaveWithComment = async (comment, name, coverImage, tags) => {
    Logger.log(JSON.stringify(lessonData, null, 2));
    await trackLesson(
      user.uid,
      "SentenceFinisherLesson.js",
      lessonData,
      null,
      true,
      comment,
      name,
      coverImage,
      tags,
    );
    setDrawerOpen(true);
    showSnackbar("Lektionen sparad i din lektionsbank!", "success");
  };

  const toggleViewMode = () => {
    const currentParams = Object.fromEntries(searchParams.entries());
    if (currentParams.v === "t") {
      delete currentParams.v;
    } else {
      currentParams.v = "t";
    }
    setSearchParams(currentParams);
  };

  const handleSectionEdit = (section) => {
    setEditingSections((prev) => ({
      ...prev,
      [section]: true,
    }));
  };

  const handleSectionSave = (section) => {
    setEditingSections((prev) => ({
      ...prev,
      [section]: false,
    }));
    showSnackbar("Sektionen har uppdaterats", "success");
  };

  const handleKeyDown = (e, section) => {
    if (e.key === "Enter" && (e.ctrlKey || e.metaKey)) {
      handleSectionSave(section);
    }
  };

  const renderEditableSection = (section, titleKey, contentKey) => {
    const isEditing = editingSections[section];

    return (
      <Box
        sx={{
          position: "relative",
          marginTop: 7,
          "&:hover": {
            "& .section-controls": {
              opacity: 1,
              visibility: "visible",
            },
          },
        }}
      >
        <Box
          className="section-controls"
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            opacity: 0,
            visibility: "hidden",
            transition: "all 0.2s ease",
            display: viewMode ? "none" : "flex",
            gap: 1,
            zIndex: 1,
          }}
        >
          <Tooltip
            title={isEditing ? "Spara ändringar" : "Redigera sektion"}
            placement="top"
          >
            <IconButton
              size="small"
              onClick={() =>
                isEditing
                  ? handleSectionSave(section)
                  : handleSectionEdit(section)
              }
            >
              {isEditing ? (
                <SaveAsIcon fontSize="small" />
              ) : (
                <EditIcon fontSize="small" />
              )}
            </IconButton>
          </Tooltip>
          <Tooltip title={`Ta bort sektion`} placement="top">
            <IconButton
              size="small"
              onClick={() => {
                const updatedData = { ...lessonData };
                delete updatedData[contentKey];
                setLessonData(updatedData);
                showSnackbar(`Sektionen har tagits bort`, "success");
              }}
            >
              <DeleteOutlineIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>

        {isEditing ? (
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <TextField
              value={lessonData[titleKey] || ""}
              onChange={(e) =>
                setLessonData((prev) => ({
                  ...prev,
                  [titleKey]: e.target.value,
                }))
              }
              sx={{
                ...styles.editingTextField,
                width: "80%",
              }}
              InputProps={{
                sx: {
                  fontSize: "1.5em",
                  fontWeight: "bold",
                },
              }}
            />
            <Box
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === "dark" ? "#15202b" : "#e0e0e029",
                borderRadius: "10px",
                padding: "20px",
              }}
            >
              {lessonData[contentKey].map((item, index) => (
                <TextField
                  key={index}
                  fullWidth
                  multiline
                  value={item}
                  onChange={(e) => {
                    const newItems = [...lessonData[contentKey]];
                    newItems[index] = e.target.value;
                    setLessonData((prev) => ({
                      ...prev,
                      [contentKey]: newItems,
                    }));
                  }}
                  sx={{
                    mb: 2,
                    "& .MuiInputBase-root": {
                      fontSize: "1rem",
                      lineHeight: "1.5",
                    },
                    ...styles.editingTextField,
                  }}
                />
              ))}
              <Button
                variant={theme.palette.mode === "light" ? "purp" : "contained"}
                color="primary"
                startIcon={<SaveAsIcon />}
                onClick={() => handleSectionSave(section)}
                sx={{ mt: 2, textTransform: "none" }}
              >
                Spara ändringar
              </Button>
            </Box>
          </Box>
        ) : (
          <>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", mb: 2, fontSize: "1.5em" }}
            >
              {lessonData[titleKey] || ""}
            </Typography>
            <Box
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === "dark" ? "#15202b" : "#e0e0e029",
                borderRadius: "10px",
                padding: "20px",
                "& li": {
                  marginBottom: "12px",
                  lineHeight: "25px",
                  "& strong": {
                    fontWeight: "600",
                  },
                },
              }}
            >
              <ul>
                {lessonData[contentKey].map((item, index) => (
                  <li key={index}>
                    <Typography variant="body1">{item}</Typography>
                  </li>
                ))}
              </ul>
            </Box>
          </>
        )}
      </Box>
    );
  };

  const handleImageButtonClick = (index = null) => {
    setSelectedImageIndex(index);
    setShowImageModal(true);
  };

  const handleCloseModal = () => {
    setShowImageModal(false);
    setImageInstructions("");
  };

  const handleGenerateWithInstructions = async () => {
    if (selectedImageIndex !== null) {
      handleCloseModal();
      await handleGenerateSpecificImage(selectedImageIndex);
    } else {
      handleCloseModal();
      await handleGenerateImages();
    }
  };

  const simulateProgress = (startProgress, endProgress, duration) => {
    const stepSize = 0.5;
    const steps = (endProgress - startProgress) / stepSize;
    const stepDuration = duration / steps;
    let currentProgress = startProgress;

    const intervalId = setInterval(() => {
      currentProgress += stepSize;
      if (currentProgress >= endProgress) {
        clearInterval(intervalId);
      } else {
        setProgress(currentProgress);
      }
    }, stepDuration);

    return () => clearInterval(intervalId);
  };

  const handleGenerateLesson = async () => {
    setProgress(0);
    setIsGeneratingLesson(true);
    const gradeInstructionsText = gradeInstructions[grade];
    let stopProgressSimulation = null;
    try {
      const generateTheme = lessonTheme === "generateWithAi";
      let themeToUse;

      if (lessonTheme === "youtube") {
        themeToUse = transcriptRef.current;
      } else if (lessonTheme === "customTheme") {
        themeToUse = customTheme;
      } else if (lessonTheme === "predefined") {
        themeToUse = predefinedTheme;
      } else if (lessonTheme === "fileUpload") {
        themeToUse = uploadedFileRef.current;
      } else if (lessonTheme === "wikipedia") {
        themeToUse = wikipediaContentRef.current;
      } else if (lessonTheme === "randomizer") {
        themeToUse = randomizedContentRef.current;
      } else if (lessonTheme === "book") {
        themeToUse = bookContentRef.current;
      } else if (lessonTheme === "url") {
        themeToUse = urlContentRef.current;
      } else {
        //
        themeToUse = coursePlanContentRef.current;
      }

      Logger.log("themeToUse: ", themeToUse);
      stopProgressSimulation = simulateProgress(0, 90, 15000, setProgress);
      let response = await callApi(
        [
          {
            role: "system",
            content:
              "You are an assistant that generates short stories with continuation suggestions, plot twists, and environment suggestions for language lessons.",
          },
          {
            role: "user",
            content: `Generate a JSON object with a short story, 5 sentence continuation suggestions, 3 plot twists, and 3 environment suggestions in "${language}". Prioritize novelty and uniqueness in each generation. Avoid repeating themes, characters, or plot elements from previous responses. 

 ${gradeInstructionsText}
             The theme should be "${themeToUse}".

            Example response:
            {
              "theme": "theme in ${language}",
              "story": "Short story text in 10 sentences in ${language}...",
              "storyTitle": "Story title in ${language}...",
      
            "continuationTitle": "Förslag på nästa mening:",
              "continuations": [
                "Continuation suggestion 1",
                "Continuation suggestion 2",
                "Continuation suggestion 3",
                "Continuation suggestion 4",
                "Continuation suggestion 5"
              ],
                    "plotTwistTitle": "Förslag på plötsliga vändningar:",
              "plotTwists": [
                "Plot twist 1",
                "Plot twist 2",
                "Plot twist 3"
              ],
                            "environmentTitle": "Förslag på miljöer för nästa del av berättelsen:",
              "environments": [
                "Environment suggestion 1",
                "Environment suggestion 2",
                "Environment suggestion 3"
              ]
            }`,
          },
        ],
        6000,
        true,
        user.uid,
      );

      if (stopProgressSimulation) {
        stopProgressSimulation();
      }

      setProgress(95);
      let messageContent = response.data.choices[0].message.content.trim();

      if (messageContent.startsWith("```json")) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith("```")) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      const data = JSON.parse(messageContent);

      const dataWithImages = {
        ...data,
        images: [{ imageUrl: null }, { imageUrl: null }, { imageUrl: null }],
      };

      Logger.log(JSON.stringify(dataWithImages, null, 2));
      setProgress(100);

      setLessonData(dataWithImages);
      showSnackbar("Lektionen har genererats!", "success");

      setTimeout(() => {
        lessonTitleRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, 100);

      await trackLesson(user.uid, "SentenceFinisherLesson.js", null, {
        lessonTheme: lessonTheme,
        language: language,
      });
    } catch (error) {
      console.error("Error generating lesson:", error);
    } finally {
      setIsGeneratingLesson(false);
    }
  };

  const handleGenerateImages = async () => {
    if (!lessonData) return;

    setIsGeneratingStoryImages(true);
    handleCloseModal();

    let successCount = 0;
    const totalImages = 3;

    try {
      const storyPrompts = [
        `Create an image representing the story theme "${lessonData.storyTitle}". Do not include any text in the image. ${imageInstructions}`,
        `Create a scene showing this moment from the story: "${lessonData.continuations[0]}". Set in the context of "${lessonData.storyTitle}". Style: narrative, dynamic, emotive, no text. ${imageInstructions}`,
        `Create an atmospheric scene combining the environment "${lessonData.environments[0]}" with this dramatic moment: "${lessonData.plotTwists[0]}". Style: atmospheric, dramatic, detailed, no text. ${imageInstructions}`,
      ];

      const updatedImages = await Promise.all(
        storyPrompts.map(async (prompt, index) => {
          try {
            const imageUrl = await generateAndProcessImage(prompt, user.uid);
            successCount++;
            return { imageUrl };
          } catch (error) {
            console.error(`Error generating image ${index + 1}:`, error);
            return { imageUrl: null };
          }
        }),
      );

      setLessonData((prevData) => ({
        ...prevData,
        images: updatedImages,
      }));

      if (successCount === totalImages) {
        showSnackbar("Alla bilder genererades framgångsrikt!", "success");
      } else {
        showSnackbar(
          `${successCount}/${totalImages} bilder genererades framgångsrikt.`,
          "warning",
        );
      }
    } catch (error) {
      console.error("Error in story image generation process:", error);
      showSnackbar("Ett fel uppstod i bildgenereringsprocessen", "error");
    } finally {
      setIsGeneratingStoryImages(false);
    }
  };

  const handleGenerateSpecificImage = async (index) => {
    setIsGeneratingStoryImages(true);
    setLoadingImageIndex(index);
    handleCloseModal();

    try {
      // Create prompts using story elements based on index
      const storyPrompts = [
        `Create an image representing the story theme "${lessonData.storyTitle}". Do not include any text in the image. ${imageInstructions}`,
        `Create a scene showing this moment from the story: "${lessonData.continuations[0]}". Set in the context of "${lessonData.storyTitle}". Style: narrative, dynamic, emotive, no text. ${imageInstructions}`,
        `Create an atmospheric scene combining the environment "${lessonData.environments[0]}" with this dramatic moment: "${lessonData.plotTwists[0]}". Style: atmospheric, dramatic, detailed, no text. ${imageInstructions}`,
      ];

      const imageUrl = await generateAndProcessImage(
        storyPrompts[index],
        user.uid,
      );

      const updatedImages = [...lessonData.images];
      updatedImages[index] = { imageUrl };

      setLessonData({
        ...lessonData,
        images: updatedImages,
      });

      showSnackbar("Bilden har genererats!", "success");
    } catch (error) {
      console.error("Error generating image:", error);
      showSnackbar("Ett fel uppstod när bilden skulle genereras", "error");
    } finally {
      setIsGeneratingStoryImages(false);
      setLoadingImageIndex(null);
    }
  };

  return (
    <>
      <Box sx={styles.container}>
        <Box
          sx={{
            ...styles.innerContainer,
            width: { xs: "100%", md: "100%" },
            margin: "0 auto",
          }}
        >
          {!viewMode && (
            <Typography
              variant="h1"
              gutterBottom
              sx={{
                fontWeight: "bold",
                fontSize: "1.8em",
                mb: 3,
                mt: 5,
                textAlign: "center",
              }}
            >
              Fortsätt berättelsen
            </Typography>
          )}
          <Box
            sx={{
              ...styles.buttonsContainer,
              ...styles.additionalButtonsContainer,
            }}
          >
            {lessonData && user.accountType === "teacher" && (
              <Button
                variant="purp"
                sx={{ ...styles.tightButton }}
                disableRipple
                onClick={() => {
                  setTimeout(() => {
                    lessonTitleRef.current?.scrollIntoView({
                      behavior: "smooth",
                      block: "start", // This ensures it scrolls to the top of the element
                    });
                  }, 100);
                  toggleViewMode();
                }}
                startIcon={
                  searchParams.get("v") === "t" ? (
                    <FullscreenExitIcon />
                  ) : (
                    <FullscreenIcon />
                  )
                }
              >
                {searchParams.get("v") === "t" ? "Redigeringsläge" : "Elevläge"}
              </Button>
            )}

            {!viewMode && (
              <Button
                variant="purp"
                sx={{ ...styles.tightButton }}
                onClick={() => setDrawerOpen(true)}
                startIcon={<BookmarkBorderIcon />}
              >
                Sparat
              </Button>
            )}
          </Box>

          {!viewMode && (
            <>
              <InfoBox
                icon={AutoStoriesIcon}
                title="Powerpoint Instructions"
                instructions={[
                  "Generera en kort berättelse med förslag på fortsättningar för eleverna",
                  "Lägg till fantasieggande AI-genererade bilder",
                  "Skriv ut lektionen",
                ]}
                theme={theme}
                styles={styles}
              />
              <LessonDrawer
                userId={user.uid}
                lessonType="SentenceFinisherLesson.js"
                theme={theme}
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                lessonIcon={AutoStoriesIcon}
                onSelectLesson={(lesson) => {
                  setLessonData(lesson.data);

                  setDrawerOpen(false);

                  showSnackbar(`Lektionen har laddats in.`, "success");
                  setTimeout(() => {
                    lessonTitleRef.current?.scrollIntoView({
                      behavior: "smooth",
                      block: "start", // This ensures it scrolls to the top of the element
                    });
                  }, 100);
                }}
              />

              <ThemeSelectorFlow
                value={lessonTheme}
                subject={subject}
                onChange={(e) => setLessonTheme(e.target.value)}
                customTheme={customTheme}
                onCustomThemeChange={(e) => setCustomTheme(e.target.value)}
                onPredefinedThemeChange={(e) =>
                  setPredefinedTheme(e.target.value)
                }
                onBookContentChange={(content) => {
                  Logger.log("onBookContentChange", content.substring(0, 100));
                  bookContentRef.current = content;
                }}
                onYoutubeTranscriptChange={(transcriptContent) => {
                  Logger.log(
                    "onYoutubeTranscriptChange körs med: ",
                    transcriptContent,
                  );
                  transcriptRef.current = transcriptContent;
                }}
                onUrlContentChange={(content) => {
                  Logger.log(
                    "Parent: URL content received",
                    content.substring(0, 300),
                  );
                  urlContentRef.current = content;
                }}
                onFileUpload={(content) => {
                  Logger.log("onFileUpload körs med: ", content);
                  uploadedFileRef.current = content;
                }}
                onWikipediaContentChange={(content) => {
                  Logger.log(
                    "Parent: Wikipedia content received",
                    content.substring(0, 100),
                  );
                  wikipediaContentRef.current = content;
                }}
                onRandomizedContentChange={(content) => {
                  Logger.log("randomizedContentRef", content.substring(0, 100));
                  randomizedContentRef.current = content;
                }}
                onCoursePlanContentChange={(content) => {
                  Logger.log("onCoursePlanChange", content.substring(0, 100));
                  coursePlanContentRef.current = content;
                }}
                lessonType="SentenceFinisherLesson.js"
                user={user}
                theme={theme}
                styles={styles}
                onLessonSelect={(lesson) => {
                  Logger.log("onLessonSelect körs");
                  setLessonData(lesson.data);
                  setTimeout(() => {
                    lessonTitleRef.current?.scrollIntoView({
                      behavior: "smooth",
                      block: "start", // This ensures it scrolls to the top of the element
                    });
                  }, 100);
                  showSnackbar("Lektionen har laddats in.", "success");
                }}
                additionalThemes={additionalThemes}
              />
              <Box sx={styles.headlineLessonBox}>
                <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
                  2. Generera lektionen
                </Typography>
              </Box>

              <Box sx={{ ...styles.freshBox, padding: "30px" }}>
                <LoadingBar
                  color={theme.palette.primary.main} // Use your theme color
                  ref={loadingBarRef}
                  shadow={true}
                />

                <Typography
                  variant="formLabelSinUnderline"
                  sx={{ fontWeight: "500", mb: 0 }}
                >
                  Årskurs:
                </Typography>
                <Box sx={{ mt: 1, mb: 3 }}>
                  <GradeSelector grade={grade} setGrade={setGrade} />
                </Box>

                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "500", mt: 0 }}
                >
                  Språk:
                </Typography>
                <Box sx={{ mt: 1, mb: 3 }}>
                  <LanguageSelector
                    language={language}
                    setLanguage={setLanguage}
                  />
                </Box>
                <Box>
                  <Button
                    variant={
                      theme.palette.mode === "light" ? "purp" : "contained"
                    }
                    color="primary"
                    onClick={handleGenerateLesson}
                    disabled={isGeneratingLesson}
                    sx={{ mt: 2 }}
                    startIcon={
                      !isGeneratingLesson ? (
                        <GenerateIcon style={{ color: "#fff" }} />
                      ) : (
                        <CircularProgress size={16} />
                      )
                    }
                  >
                    {isGeneratingLesson ? "Genererar..." : "Generera lektion"}
                  </Button>
                </Box>
                {isGeneratingLesson && (
                  <Box sx={{ mt: 6 }}>
                    <ProgressBar
                      progress={progress}
                      label="Genererar lektion — tar 30-60 sekunder..."
                    />
                  </Box>
                )}
              </Box>
            </>
          )}
          <Box sx={{ mt: 4 }}>
            {lessonData && (
              <>
                {!viewMode && (
                  <Box
                    ref={lessonTitleRef}
                    sx={{ ...styles.headlineLessonBox, mt: 10 }}
                  >
                    <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
                      3. Din lektion
                    </Typography>
                  </Box>
                )}
                <Box
                  sx={{
                    ...styles.freshBox,
                    padding: "30px",

                    width: {
                      xs: "100%",
                      sm: viewMode ? "70%" : undefined,
                    },
                    margin: viewMode ? "0 auto" : undefined,
                    marginTop: viewMode ? "0px" : undefined,
                  }}
                >
                  {!viewMode && (
                    <Box sx={{ position: "relative" }}>
                      <Box
                        sx={{
                          ...styles.lessonControlPanel,
                          ...styles.lessonControlPanelNuevo,

                          "& .MuiButton-root": {
                            ...styles.controlPanelButtons,
                            ...styles.lessonControlPanelNewButtons,
                          },
                        }}
                      >
                        <Button
                          variant="print"
                          startIcon={<SaveAsIcon />}
                          sx={{ ml: 2 }}
                          onClick={handleSaveLessonClick}
                        >
                          Spara i lektionsbank
                        </Button>

                        <Button
                          variant="print"
                          startIcon={<PrintIcon />}
                          sx={{ ml: 2 }}
                          onClick={() =>
                            handlePrintSentenceFinisher(lessonData, theme)
                          }
                        >
                          Skriv ut
                        </Button>

                        <Button
                          variant="success"
                          sx={{}}
                          onClick={() => setShareDialogOpenFriends(true)}
                          startIcon={<ShareIcon />}
                        >
                          Dela med vänner
                        </Button>
                        <Button
                          variant="success"
                          sx={{
                            mt: 2,
                            ml: 2,
                          }}
                          onClick={() => setShareDialogOpen(true)}
                          startIcon={<ShareIcon />}
                        >
                          Dela med elever
                        </Button>

                        <ShareLink
                          buttonRef={shareLinkButtonRef}
                          key={lessonId}
                          lessonId={lessonId}
                          lessonData={lessonData}
                          user={user}
                          lessonType={"SentenceFinisherLesson.js"}
                          onCopy={(message, severity) =>
                            showSnackbar(message, severity)
                          }
                          sx={{ mt: 2 }}
                        />

                        <Tooltip title="Hur lektionen ser ut för eleverna">
                          <Button
                            variant="success"
                            onClick={() => {
                              window.scrollTo({ top: 0, behavior: "smooth" });
                              toggleViewMode();
                            }}
                            sx={{ ml: 2, mt: 2 }}
                            startIcon={
                              searchParams.get("v") === "t" ? (
                                <FullscreenExitIcon />
                              ) : (
                                <FullscreenIcon />
                              )
                            }
                          >
                            {searchParams.get("v") === "t"
                              ? "Redigeringsläge"
                              : "Elevläge"}
                          </Button>
                        </Tooltip>

                        <PrintAsPdf
                          buttonRef={pdfButtonRef}
                          onPrint={() =>
                            handlePrintSentenceFinisher(lessonData, theme)
                          }
                          sx={{ ml: 2 }}
                        />

                        <Translate
                          buttonRef={translateButtonRef}
                          lessonData={lessonData}
                          onTranslated={setLessonData}
                          callApi={callApi}
                          user={user}
                          showSnackbar={showSnackbar}
                        />

                        <Button
                          variant={
                            theme.palette.mode === "light"
                              ? "purp"
                              : "contained"
                          }
                          color="primary"
                          onClick={() => handleImageButtonClick(null)}
                          disabled={isGeneratingStoryImages}
                          startIcon={
                            !isGeneratingStoryImages ? (
                              <ImageIcon />
                            ) : (
                              <CircularProgress size={16} />
                            )
                          }
                        >
                          {isGeneratingStoryImages
                            ? "Genererar..."
                            : "Generera alla bilder"}
                        </Button>
                      </Box>
                      <Box
                        sx={{
                          ...styles.lessonControlPanelFade,
                        }}
                      />
                    </Box>
                  )}
                  <SaveLessonDialog
                    key={lessonData.storyTitle}
                    open={saveDialogOpen}
                    onClose={() => setSaveDialogOpen(false)}
                    onSave={handleSaveWithComment}
                    theme={theme}
                    lessonObject={lessonData}
                    lessonName={lessonData.storyTitle}
                  />

                  <ImageGenerationModal
                    open={showImageModal}
                    onClose={handleCloseModal}
                    onGenerate={handleGenerateWithInstructions}
                    isGenerating={isGeneratingStoryImages}
                    instructions={imageInstructions}
                    setInstructions={setImageInstructions}
                    generationType={imageGenerationType}
                    theme={theme}
                  />

                  <Box sx={{ mt: 7 }}>
                    {/* Title */}
                    {isEditingTitle ? (
                      <TextField
                        value={lessonData.storyTitle}
                        onChange={(e) =>
                          setLessonData((prev) => ({
                            ...prev,
                            storyTitle: e.target.value,
                          }))
                        }
                        onBlur={() => {
                          setIsEditingTitle(false);
                          showSnackbar("Titeln har uppdaterats", "success");
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            setIsEditingTitle(false);
                            showSnackbar("Titeln har uppdaterats", "success");
                          }
                        }}
                        autoFocus
                        fullWidth
                        variant="standard"
                        sx={{
                          backgroundColor: "transparent",
                          "& .MuiInputBase-input": {
                            textAlign: "center",
                            fontWeight: "bold",
                            fontSize: "2.6em",
                            marginBottom: "1.2rem",
                            marginTop: "1.5rem",
                          },
                        }}
                        InputProps={{
                          disableUnderline: true,
                        }}
                      />
                    ) : (
                      <Box
                        sx={{
                          position: "relative",
                          display: "inline-block",
                          margin: "0 auto",
                          width: "100%",
                          mb: 3,
                          mt: viewMode ? 0 : 5,
                          ":hover .edit-icon": {
                            opacity: 1,
                          },
                        }}
                      >
                        <Typography
                          variant="h1"
                          sx={{
                            fontWeight: "bold",
                            fontSize: "2.6em",

                            textAlign: "center",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: 2,
                          }}
                        >
                          {lessonData.storyTitle || " "}
                          <Box
                            component="span"
                            sx={{
                              opacity: 0,
                              transition: "opacity 0.2s",
                              display: viewMode ? "none" : "inline-flex",
                              gap: 0.5,
                            }}
                            className="edit-icon"
                          >
                            <Tooltip title="Redigera" placement="top">
                              <IconButton
                                size="small"
                                onClick={() => setIsEditingTitle(true)}
                              >
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Typography>
                      </Box>
                    )}

                    {/* Story */}
                    <Box
                      sx={{
                        backgroundColor: (theme) =>
                          theme.palette.mode === "dark"
                            ? "#15202b"
                            : "#e0e0e029",
                        borderRadius: "10px",
                        padding: "20px",
                        mb: 4,
                        mt: 4,
                      }}
                    >
                      <Box
                        sx={{
                          "&:hover .controls": {
                            opacity: 1,
                          },
                          display: "flex",
                          alignItems: "flex-start",
                          gap: 1,
                        }}
                      >
                        {isEditingStory ? (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "flex-start",
                              width: "100%",
                            }}
                          >
                            <TextField
                              fullWidth
                              multiline
                              rows={10}
                              value={lessonData.story}
                              onChange={(e) =>
                                setLessonData((prev) => ({
                                  ...prev,
                                  story: e.target.value,
                                }))
                              }
                              onBlur={() => {
                                setIsEditingStory(false);
                                showSnackbar(
                                  "Berättelsen har uppdaterats",
                                  "success",
                                );
                              }}
                              autoFocus
                              sx={{
                                ...styles.editingTextField,
                                "& .MuiInputBase-input": {
                                  lineHeight: 1.8,
                                },
                              }}
                            />
                            <Tooltip title="Spara ändringar" placement="top">
                              <IconButton
                                onClick={() => {
                                  setIsEditingStory(false);
                                  showSnackbar(
                                    "Berättelsen har uppdaterats",
                                    "success",
                                  );
                                }}
                              >
                                <SaveAsIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        ) : (
                          <Typography
                            variant="body1"
                            sx={{
                              lineHeight: 1.8,
                              flex: 1,
                              display: "flex",
                              alignItems: "flex-start",
                              gap: 1,
                              whiteSpace: "pre-wrap",
                            }}
                          >
                            {lessonData.story || " "}
                            <Box
                              component="span"
                              className="controls"
                              sx={{
                                opacity: 0,
                                transition: "opacity 0.2s",
                                display: viewMode ? "none" : "inline-flex",
                                gap: 0.5,
                                ml: 1,
                                mt: 0.5,
                              }}
                            >
                              <Tooltip title="Redigera" placement="top">
                                <IconButton
                                  size="small"
                                  onClick={() => setIsEditingStory(true)}
                                >
                                  <EditIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </Typography>
                        )}
                      </Box>
                    </Box>

                    {/* Images Grid */}
                    <Grid container spacing={2} sx={{ mb: 4 }}>
                      {lessonData.images.map((image, index) => (
                        <Grid item xs={12} md={4} key={index}>
                          <HoverableImage
                            imageUrl={image.imageUrl}
                            isLoading={isGeneratingStoryImages}
                            loadingIndex={loadingImageIndex}
                            currentIndex={index}
                            onGenerateClick={() => {
                              setSelectedImageIndex(index);
                              setShowImageModal(true);
                            }}
                            userId={user.uid}
                            onImageUpdate={(newImageUrl) => {
                              const updatedImages = [...lessonData.images];
                              updatedImages[index] = { imageUrl: newImageUrl };
                              setLessonData((prev) => ({
                                ...prev,
                                images: updatedImages,
                              }));
                            }}
                            height="240px"
                            theme={theme}
                            showSnackbar={showSnackbar}
                          />
                        </Grid>
                      ))}
                    </Grid>

                    {lessonData?.continuations &&
                      renderEditableSection(
                        "continuations",
                        "continuationTitle",
                        "continuations",
                      )}
                    {lessonData?.plotTwists &&
                      renderEditableSection(
                        "plotTwists",
                        "plotTwistTitle",
                        "plotTwists",
                      )}
                    {lessonData?.environments &&
                      renderEditableSection(
                        "environments",
                        "environmentTitle",
                        "environments",
                      )}
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </Box>

        <SlideInPaper
          isExpanded={isExpanded}
          onExpandedChange={setIsExpanded}
          lessonTitleRef={lessonTitleRef}
          viewMode={viewMode}
          toggleViewMode={toggleViewMode}
          theme={theme}
          open={showControlPanel}
          user={user}
          onPrint={() => handlePrintSentenceFinisher(lessonData, theme)}
          onTranslate={() => translateButtonRef.current?.click()}
          onSaveAsPdf={() => pdfButtonRef.current?.click()}
          onShareLink={() => shareLinkButtonRef.current?.click()}
          onShareWithStudents={() => setShareDialogOpen(true)}
          onShareWithFriends={() => setShareDialogOpenFriends(true)}
          onSaveToBank={() => setSaveDialogOpen(true)}
          onStudentMode={() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
            toggleViewMode();
          }}
        />

        <ShareDialog
          open={shareDialogOpen}
          onClose={() => setShareDialogOpen(false)}
          onShare={handleShare}
          user={user}
          isLessonSharing={true}
          theme={theme}
          lessonName={lessonData?.storyTitle || ""}
          lessonObject={lessonData}
          lessonType={"SentenceFinisherLesson.js"}
        />

        <ShareDialog
          open={shareDialogOpenFriends}
          onClose={() => setShareDialogOpenFriends(false)}
          onShare={handleShare}
          user={user}
          isLessonSharing={true}
          theme={theme}
          lessonName={lessonData?.storyTitle || ""}
          lessonObject={lessonData}
          lessonType={"SentenceFinisherLesson.js"}
          shareWithFriends={true}
        />
        <LoadingBackdrop
          isOpen={isSharing}
          imagePath="/img/AI.png"
          loadingText={"Delar lektionen..."}
        />
        <ImageLoadingDialog
          open={isGeneratingStoryImages}
          message="Genererar bilder... Tar 30-60 sekunder."
        />
      </Box>
    </>
  );
};

export default SentenceFinisherLesson;
