import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Box,
  DialogActions,
  Divider,
} from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import CloseIcon from "@mui/icons-material/Close";
import PrintIcon from "@mui/icons-material/Print";
import { InstructionItem } from "./functions";

const PrintAsPdf = ({ onPrint, sx = {}, buttonRef }) => {
  const [open, setOpen] = useState(false);

  const handlePrint = () => {
    onPrint();
    setOpen(false);
  };

  return (
    <>
      <Button
        ref={buttonRef}
        variant="purp"
        startIcon={<PictureAsPdfIcon />}
        onClick={() => setOpen(true)}
        sx={{ mt: 2, ml: 2, ...sx }}
      >
        Spara som PDF
      </Button>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle
          sx={{
            textAlign: "center",
            fontWeight: "700",
            padding: "25px",
            fontSize: "1.5rem",
          }}
        >
          <Box
            sx={{ display: "flex", width: "100%", justifyContent: "flex-end" }}
          >
            <IconButton
              onClick={() => setOpen(false)}
              size="small"
              sx={{ position: "absolute" }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          Spara som PDF
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ p: 6, pt: 2 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box sx={{ textAlign: "center", mt: 0 }}>
              <InstructionItem
                text='Klicka på "Skriv ut"-knappen nedan'
                simple={true}
                marginBottom={1}
              />

              <InstructionItem
                text='Välj "Spara som PDF" som destination i rutan (se bild)'
                simple={true}
                marginBottom={1}
              />

              <InstructionItem
                text='Klicka på "Spara" och PDF-filen laddas ned till din dator'
                simple={true}
                marginBottom={2}
              />
            </Box>

            <img
              src="https://sportkuriren.se/wp-content/uploads/pdf2.png"
              alt="PDF instruktioner"
              style={{
                maxWidth: "100%",
                height: "auto",
                borderRadius: 12,
              }}
            />
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            variant="print"
            onClick={() => setOpen(false)}
            startIcon={<CloseIcon />}
            sx={{ mr: 2 }}
          >
            Avbryt
          </Button>
          <Button
            variant="purp"
            onClick={handlePrint}
            sx={{ ml: 2 }}
            startIcon={<PrintIcon />}
          >
            Skriv ut
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PrintAsPdf;
