import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Box,
  Tooltip,
  Typography,
  CircularProgress,
  IconButton,
  Paper,
  Divider,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import AddIcon from "@mui/icons-material/Add";
import ImageIcon from "@mui/icons-material/Image";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import CloseIcon from "@mui/icons-material/Close";
import HoverableImage from "./HoverableImage";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { alpha, lighten } from "@mui/material/styles";

import Logger from "./Logger";

const TimeBuilderEditDialog = ({
  open,
  onClose,
  lessonData,
  onSave,
  isGeneratingImages,
  loadingImageIndex,
  onGenerateSpecificImage,
  onGenerateAllImages,
  user,
  theme,
  showSnackbar,
  handleImageButtonClick,
  fromLessonTimelineDisplay,
}) => {
  const [editedData, setEditedData] = useState(lessonData);
  editedData.timelines = editedData.timelines || editedData.timelinesSimple;

  useEffect(() => {
    setEditedData(lessonData);
  }, [lessonData]);

  Logger.log("lessonData: ", JSON.stringify(lessonData, null, 2));
  const handleSave = () => {
    onSave(editedData);
    onClose();
  };
  const handleDeleteTimeline = (timelineIndex) => {
    const newData = { ...editedData };
    newData.timelines.splice(timelineIndex, 1);
    setEditedData(newData);
  };

  const handleAddTimeline = () => {
    const newData = { ...editedData };
    newData.timelines.push({
      title: "",
      description: "",
      timeline: [],
    });
    setEditedData(newData);
  };
  // Moonshine: Lägg till denna igen om nåt knasar med andra timelines.
  // const handleAddEvent = (timelineIndex) => {
  //   const newData = { ...editedData };
  //   newData.timelines[timelineIndex].timeline.push({
  //     event: "",
  //     year: "",
  //     hint: "",

  //     imageUrl: null,
  //   });
  //   setEditedData(newData);
  // };

  const handleAddEvent = (timelineIndex) => {
    const newData = { ...editedData };
    const hasDescription = newData.timelines[timelineIndex].timeline.some(
      (event) => event.description !== undefined,
    );

    newData.timelines[timelineIndex].timeline.push({
      event: "",
      year: "",
      hint: "",
      ...(hasDescription && { description: "" }),
      imageUrl: null,
    });
    setEditedData(newData);
  };

  const handleDeleteEvent = (timelineIndex, eventIndex) => {
    const newData = { ...editedData };
    newData.timelines[timelineIndex].timeline.splice(eventIndex, 1);
    setEditedData(newData);
  };

  const handleEventChange = (timelineIndex, eventIndex, field, value) => {
    const newData = { ...editedData };
    newData.timelines[timelineIndex].timeline[eventIndex][field] = value;
    setEditedData(newData);
  };

  const handleTimelineChange = (timelineIndex, field, value) => {
    const newData = { ...editedData };
    newData.timelines[timelineIndex][field] = value;
    setEditedData(newData);
  };

  const handleAddArticleSection = (timelineIndex) => {
    const newData = { ...editedData };
    if (!newData.timelines[timelineIndex].articleSections) {
      newData.timelines[timelineIndex].articleSections = [];
    }
    newData.timelines[timelineIndex].articleSections.push({
      subheadline: "",
      content: "",
    });
    setEditedData(newData);
  };

  const handleDeleteArticleSection = (timelineIndex, sectionIndex) => {
    const newData = { ...editedData };
    newData.timelines[timelineIndex].articleSections.splice(sectionIndex, 1);
    setEditedData(newData);
  };

  const handleArticleSectionChange = (
    timelineIndex,
    sectionIndex,
    field,
    value,
  ) => {
    const newData = { ...editedData };
    newData.timelines[timelineIndex].articleSections[sectionIndex][field] =
      value;
    setEditedData(newData);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xl"
      fullWidth
      PaperProps={{
        sx: {
          minHeight: "80vh",
          maxHeight: "90vh",
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "1px solid",
          borderColor: "divider",
          mb: 2,
        }}
      >
        <Typography variant="h6" component="div" sx={{ fontWeight: "600" }}>
          Redigera tidslinje
        </Typography>
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          {!fromLessonTimelineDisplay && (
            <Button
              variant="print"
              onClick={() => handleImageButtonClick(null)}
              startIcon={
                isGeneratingImages ? (
                  <CircularProgress size={16} />
                ) : (
                  <ImageIcon />
                )
              }
              disabled={isGeneratingImages}
            >
              Generera alla bilder
            </Button>
          )}
          <Button
            onClick={handleSave}
            variant="purp"
            color="primary"
            disabled={isGeneratingImages}
            startIcon={<SaveAsIcon />}
          >
            Spara ändringar
          </Button>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent sx={{ padding: "50px", paddingTop: "30px !important" }}>
        {!fromLessonTimelineDisplay && (
          <Box sx={{ mb: 4 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: "500", mb: 1 }}>
              Titel på lektionen
            </Typography>
            <TextField
              fullWidth
              value={editedData?.title || ""}
              onChange={(e) =>
                setEditedData((prev) => ({ ...prev, title: e.target.value }))
              }
              sx={{ mb: 2 }}
            />

            <Typography variant="subtitle1" sx={{ fontWeight: "500", mb: 1 }}>
              Beskrivning av lektionen
            </Typography>
            <TextField
              fullWidth
              value={editedData?.description || ""}
              onChange={(e) =>
                setEditedData((prev) => ({
                  ...prev,
                  description: e.target.value,
                }))
              }
              multiline
              rows={2}
            />
          </Box>
        )}

        {editedData?.timelines.map((timeline, timelineIndex) => (
          <Accordion
            defaultExpanded
            key={timelineIndex}
            sx={{
              mb: 2,
              backgroundColor: "background.paper",
              "&:hover": {
                backgroundColor: "background.paperHover",
              },
              "&.Mui-expanded": {
                backgroundColor: lighten("#6D31ED", 0.92),
              },
              border: "1px solid",
              borderColor: alpha("#6D31ED", 0.12),
              borderRadius: 4,
              "&:hover": {
                borderColor: alpha("#6D31ED", 0.24),
              },
              "&.Mui-expanded": {
                borderColor: "#6D31ED",
              },
              boxShadow: "0px 2px 4px rgba(109, 49, 237, 0.05)",
              "&:hover": {
                boxShadow: "0px 4px 8px rgba(109, 49, 237, 0.08)",
              },
              "&.Mui-expanded": {
                boxShadow: "0px 6px 12px rgba(109, 49, 237, 0.12)",
              },
              "&:before": {
                backgroundColor: "transparent",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                borderRadius: 4,
                "&.Mui-expanded": {
                  backgroundColor: lighten("#6D31ED", 0.94),
                },
                "&:hover": {
                  backgroundColor: lighten("#6D31ED", 0.96),
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ fontWeight: "600" }}>
                  Redigera tidslinje {timelineIndex + 1}: {timeline.title}
                </Typography>

                <Tooltip title={`Ta bort tidslinjen "${timeline.title}"`}>
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteTimeline(timelineIndex);
                    }}
                    sx={{ mr: 2 }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="subtitle1" sx={{ fontWeight: "500", mb: 1 }}>
                Titel
              </Typography>
              <Box sx={{ mb: 3 }}>
                <TextField
                  fullWidth
                  value={timeline.title}
                  onChange={(e) =>
                    handleTimelineChange(timelineIndex, "title", e.target.value)
                  }
                  sx={{ mb: 2 }}
                />
                {!fromLessonTimelineDisplay && (
                  <>
                    <Typography
                      variant="subtitle1"
                      sx={{ fontWeight: "500", mb: 1 }}
                    >
                      Beskrivning
                    </Typography>
                    <TextField
                      fullWidth
                      value={timeline.description}
                      onChange={(e) =>
                        handleTimelineChange(
                          timelineIndex,
                          "description",
                          e.target.value,
                        )
                      }
                    />
                  </>
                )}
              </Box>

              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                  overflowX: "auto",
                  p: 2,
                  bgcolor:
                    theme.palette.mode === "dark"
                      ? "background.default"
                      : "white",
                  borderRadius: 1,
                  border: "1px dashed",
                  borderColor: "divider",
                  "&::-webkit-scrollbar": {
                    height: 8,
                  },
                  "&::-webkit-scrollbar-track": {
                    backgroundColor: "background.paper",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "primary.light",
                    borderRadius: 4,
                  },
                }}
              >
                {timeline.timeline.map((event, eventIndex) => (
                  <Paper
                    key={eventIndex}
                    sx={{
                      minWidth: 300,
                      bgcolor: "background.paper",
                      borderRadius: 2,
                      boxShadow: "none",
                      position: "relative",
                      overflow: "visible",
                    }}
                  >
                    <Tooltip title={`Ta bort "${event.event}"`}>
                      <IconButton
                        onClick={() =>
                          handleDeleteEvent(timelineIndex, eventIndex)
                        }
                        sx={{
                          position: "absolute",
                          right: 0,
                        }}
                        size="small"
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Box sx={{ p: 2 }}>
                      <Box>
                        <Typography
                          variant="subtitle1"
                          sx={{ fontWeight: "500", mb: 1 }}
                        >
                          Händelse
                        </Typography>
                        <TextField
                          value={event.event}
                          onChange={(e) =>
                            handleEventChange(
                              timelineIndex,
                              eventIndex,
                              "event",
                              e.target.value,
                            )
                          }
                          fullWidth
                        />
                        <Typography
                          variant="subtitle1"
                          sx={{ fontWeight: "500", mb: 1, mt: 2 }}
                        >
                          Årtal / Ordning
                        </Typography>
                        <TextField
                          fullWidth
                          value={event.year}
                          sx={{ mb: 2 }}
                          onChange={(e) =>
                            handleEventChange(
                              timelineIndex,
                              eventIndex,
                              "year",
                              e.target.value,
                            )
                          }
                        />
                      </Box>

                      <TextField
                        label="Tips"
                        value={event.hint}
                        onChange={(e) =>
                          handleEventChange(
                            timelineIndex,
                            eventIndex,
                            "hint",
                            e.target.value,
                          )
                        }
                        size="small"
                        fullWidth
                        multiline
                        rows={2}
                        sx={{ mb: 2, display: "none" }}
                      />

                      <Box sx={{ height: 220 }}>
                        <HoverableImage
                          imageUrl={event.imageUrl}
                          isLoading={isGeneratingImages}
                          loadingIndex={loadingImageIndex}
                          currentIndex={
                            timelineIndex * timeline.timeline.length +
                            eventIndex
                          }
                          onGenerateClick={() => {
                            const index =
                              timelineIndex * timeline.timeline.length +
                              eventIndex;
                            handleImageButtonClick(index); // This will open the modal first
                          }}
                          userId={user.uid}
                          onImageUpdate={(url) =>
                            handleEventChange(
                              timelineIndex,
                              eventIndex,
                              "imageUrl",
                              url,
                            )
                          }
                          theme={theme}
                          height="220px"
                          showSnackbar={showSnackbar}
                        />
                      </Box>

                      {event.description !== undefined && (
                        <>
                          <Typography
                            variant="subtitle1"
                            sx={{ fontWeight: "500", mb: 1, mt: 3 }}
                          >
                            Beskrivning
                          </Typography>
                          <TextField
                            fullWidth
                            value={event.description}
                            sx={{ mb: 2 }}
                            multiline
                            rows={5}
                            onChange={(e) =>
                              handleEventChange(
                                timelineIndex,
                                eventIndex,
                                "description",
                                e.target.value,
                              )
                            }
                          />
                        </>
                      )}
                    </Box>
                  </Paper>
                ))}

                <Paper
                  sx={{
                    minWidth: 300,
                    minHeight: 300,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    bgcolor: "background.paper",
                    borderRadius: 2,
                    border: "1px dashed",
                    boxShadow: "none",
                    borderColor: "divider",
                    cursor: "pointer",
                    "&:hover": {
                      borderColor: "primary.main",
                    },
                  }}
                  onClick={() => handleAddEvent(timelineIndex)}
                >
                  <Box sx={{ textAlign: "center", color: "text.secondary" }}>
                    <AddIcon sx={{ fontSize: 40, mb: 1 }} />
                    <Typography>Lägg till händelse</Typography>
                  </Box>
                </Paper>
              </Box>

              {/* Article Sections */}
              {!fromLessonTimelineDisplay && (
                <>
                  <Typography variant="h6" sx={{ mb: 2, mt: 4 }}>
                    Artikelsektioner
                  </Typography>
                  <Box sx={{ mt: 2 }}>
                    {timeline.articleSections?.map((section, sectionIndex) => (
                      <Paper
                        key={sectionIndex}
                        sx={{
                          p: 2,
                          mb: 2,
                          position: "relative",
                          bgcolor: "background.paper",
                          borderRadius: 2,
                        }}
                      >
                        <IconButton
                          onClick={() =>
                            handleDeleteArticleSection(
                              timelineIndex,
                              sectionIndex,
                            )
                          }
                          sx={{ position: "absolute", right: 8, top: 8 }}
                          size="small"
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>

                        <Typography
                          variant="subtitle1"
                          sx={{ fontWeight: "500", mb: 1, mt: 2 }}
                        >
                          Underrubrik
                        </Typography>
                        <TextField
                          fullWidth
                          value={section.subheadline}
                          onChange={(e) =>
                            handleArticleSectionChange(
                              timelineIndex,
                              sectionIndex,
                              "subheadline",
                              e.target.value,
                            )
                          }
                          sx={{ mb: 2 }}
                        />

                        <Typography
                          variant="subtitle1"
                          sx={{ fontWeight: "500", mb: 1, mt: 2 }}
                        >
                          Innehåll
                        </Typography>
                        <TextField
                          fullWidth
                          value={section.content}
                          onChange={(e) =>
                            handleArticleSectionChange(
                              timelineIndex,
                              sectionIndex,
                              "content",
                              e.target.value,
                            )
                          }
                          multiline
                          rows={8}
                        />
                      </Paper>
                    ))}

                    <Button
                      startIcon={<AddIcon />}
                      onClick={() => handleAddArticleSection(timelineIndex)}
                      variant="outlined"
                      sx={{ mt: 1 }}
                    >
                      Lägg till artikelsektion
                    </Button>
                  </Box>
                </>
              )}
            </AccordionDetails>
          </Accordion>
        ))}

        <Button
          startIcon={<AddIcon />}
          onClick={handleAddTimeline}
          variant="print"
          sx={{
            mt: 2,

            // Moonshine, lägg till detta när man kan generera alla bilder för alla tidslinjer, kanske man kan redan nu, men blir en massa bilder
            display: "none",
          }}
        >
          Lägg till tidslinje
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default TimeBuilderEditDialog;
