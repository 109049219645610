import React from "react";
import {
  Dialog,
  DialogContent,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";

import { useSnackbar } from "./SnackbarHandler";

const ImageLoadingDialog = ({ open, message = "Genererar bilder..." }) => {
  const { showSnackbar } = useSnackbar();

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      showSnackbar(
        "Vänligen låt genereringen slutföras. Det är snart klart.",
        "info",
      );
    }
  };

  return (
    <Dialog
      open={open}
      disableEscapeKeyDown
      onClose={handleClose}
      disablePortal
      hideBackdrop={false}
      PaperProps={{
        elevation: 0,
        sx: {
          backgroundColor: "background.paper",
          boxShadow: 24,
          borderRadius: 4,
        },
      }}
    >
      <DialogContent>
        <Box
          sx={{
            width: "100%",
            p: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 3,
          }}
        >
          <svg width={0} height={0}>
            <defs>
              <linearGradient
                id="my_gradient"
                x1="0%"
                y1="0%"
                x2="0%"
                y2="100%"
              >
                <stop offset="0%" stopColor="#e01cd5" />
                <stop offset="100%" stopColor="#1CB5E0" />
              </linearGradient>
            </defs>
          </svg>

          {/* CircularProgress with gradient */}
          <CircularProgress
            size={48}
            sx={{
              "svg circle": { stroke: "url(#my_gradient)" },
            }}
          />
          <Typography
            variant="h5"
            sx={{
              textAlign: "center",
              fontWeight: "500",
              fontSize: "1.3rem",
              mt: 3,
            }}
          >
            {message}
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ImageLoadingDialog;
