import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  IconButton,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomInputSwitch from "./CustomInputSwitch";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import { callApi } from "./api";

const QuizDifficultyDialog = ({
  open,
  onClose,
  quiz,
  onUpdateQuiz,

  showSnackbar,
  theme,
}) => {
  const [isGenerating, setIsGenerating] = useState(false);
  const [direction, setDirection] = useState("simplify");

  const handleRegenerate = async () => {
    setIsGenerating(true);

    try {
      const response = await callApi(
        [
          {
            role: "system",
            content: `Du är en expert på att ${direction === "simplify" ? "förenkla" : "göra mer avancerade"} 
        quiz-frågor för elever med olika behov. 
        VIKTIGT: Svara ENDAST med ett JSON-objekt, utan någon inledande eller avslutande text.`,
          },
          {
            role: "user",
            content: `${direction === "simplify" ? "Förenkla" : "Gör mer avancerade"} följande quiz-frågor. 
      Behåll samma struktur på quiz-objektet och antalet frågor, men anpassa språket och komplexiteten. 
      Returnera EXAKT samma JSON-struktur som input, men med uppdaterade frågor och svarsalternativ.
      Se till att svaren fortfarande är rimliga och pedagogiska.
      Var noga med att behålla alla ursprungliga fält i varje fråga.
      VIKTIGT: Svara ENDAST med JSON-objektet, utan någon förklarande text.
      
      Quiz att modifiera:
      ${JSON.stringify(quiz)}`,
          },
        ],
        10000,
        true,
      );

      let messageContent = response.data.choices[0].message.content.trim();

      // Remove markdown code blocks if present
      if (messageContent.startsWith("```json")) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith("```")) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      const newQuiz = JSON.parse(messageContent);
      onUpdateQuiz(newQuiz);
      showSnackbar("Quiz har uppdaterats!", "success");
      onClose();
    } catch (error) {
      console.error("Error generating content:", error);
      showSnackbar("Ett fel uppstod när quizet skulle genereras", "error");
    } finally {
      setIsGenerating(false);
    }
  };
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle
        sx={{ textAlign: "center", fontWeight: "700", padding: "25px" }}
      >
        <Box
          sx={{ display: "flex", width: "100%", justifyContent: "flex-end" }}
        >
          <IconButton
            onClick={onClose}
            size="small"
            sx={{ position: "absolute" }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        Anpassa svårighetsgrad
      </DialogTitle>

      <DialogContent>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            my: 4,
          }}
        >
          <CustomInputSwitch
            selectedIndex={direction === "simplify" ? 0 : 1}
            onIndexChange={(index) =>
              setDirection(index === 0 ? "simplify" : "advanced")
            }
            options={["Förenkla", "Försvåra"]}
            theme={theme}
          />
        </Box>
      </DialogContent>

      <DialogActions sx={{ p: 2, justifyContent: "space-between" }}>
        <Button
          disabled={isGenerating}
          onClick={onClose}
          variant="print"
          startIcon={<CloseIcon />}
        >
          Avbryt
        </Button>
        <Button
          variant={theme.palette.mode === "light" ? "purp" : "contained"}
          onClick={handleRegenerate}
          disabled={isGenerating}
          startIcon={
            isGenerating ? (
              <CircularProgress size={16} sx={{ color: "white" }} />
            ) : (
              <AutoFixHighIcon />
            )
          }
        >
          {isGenerating
            ? "Genererar — tar 30-60 sekunder..."
            : "Generera ny version"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default QuizDifficultyDialog;
