// AskAI.js
import React, { useState, useEffect } from "react";
import {
    Typography,
    Box,
    Button,
    Menu,
    MenuItem,
    ListItemIcon,
    Tooltip,
    Avatar,
    Drawer,
    List,
    ListItem,
    ListItemText,
    IconButton,
    TextField,
} from "@mui/material";
import { ClipLoader } from "react-spinners";
import Prompts from "./Prompts";
import ChevronRight from "@mui/icons-material/ChevronRight";
import MessageBubble from "./MessageBubble";
import TextInput from "./TextInput";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import SavedMessagesDrawer from "./SavedMessagesDrawer";
import LanguageSelector from "./LanguageSelector";
import BoltIcon from "@mui/icons-material/Bolt";
import AddIcon from "@mui/icons-material/Add";
import { TypeAnimation } from "react-type-animation";
import ChatIcon from "@mui/icons-material/Chat";
import AskAIDrawer from "./AskAIDrawer";
import RoleplayDialog from "./RoleplayDialog";
import EditIcon from "@mui/icons-material/Edit";
import ExplainDialog from "./ExplainDialog";
import SummarizeDialog from "./SummarizeDialog";
import SummarizeIcon from "@mui/icons-material/Summarize"; // Add this for the summarize icon
import CompareDialog from "./CompareDialog";
import GroupWorkDialog from "./GroupWorkDialog";
import DebateDialog from "./DebateDialog";
import VocabularyDialog from "./VocabularyDialog";
import SaveIcon from "@mui/icons-material/Save";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import SegmentIcon from "@mui/icons-material/Segment";
import SkeletonLoader from "./SkeletonLoader";
import { useNavigate, useLocation } from "react-router-dom";

import PromptBoxes from "./PromptBoxes";
import { styles } from "../index";
import useGeneralChat from "./useGeneralChat";
import { useTheme } from "@mui/material/styles";
import PromptDialog from "./PromptDialog";
import EmailDialog from "./EmailDialog";
import LoadingSpinner from "./LoadingSpinner";

import Logger from "./Logger";
import { getStyles } from "../index";

const AskAI = ({ user, isOpen, isDark }) => {
    const [language, setLanguage] = useState("svenska");

    const convoType = "chat";
    const {
        input,
        messages,
        loading,
        loadingMessage,
        anchorEl,
        clientAnchorEl,
        selectedPrompt,
        messagesEndRef,
        inputRef,
        drawerOpen,
        chats,
        currentChatId,
        editingChat,
        loadingLatestChat,
        loadingChat,
        creatingNewChat,
        savingChatName,
        loadingOldChats,
        base64Image,
        handleImageUpload,
        setInput,
        setAnchorEl,
        setClientAnchorEl,
        setSelectedPrompt,
        setDrawerOpen,
        handleCreateNewChat,
        handleChatSelect,
        handleChatNameChange,
        handleSaveChatName,
        handleStartEditing,
        handleSendMessage,
        handleInputChange,
        handleDeleteAllMsgs,
        setLoadingMessage,
        setMessages,
        sendMessage,
        handleGenerateIdeas,
        optimisticUpdate,
        streamingMessage,
        handleStreamingMessage,
        handleStreamComplete,
        completedStreamingId,
        isLive,
        isStreaming,
        handleStopStreaming,
        currentChatName,
        prompts,
        promptDialogOpen,
        setPromptDialogOpen,
        addPrompt,
        deletePrompt,
    } = useGeneralChat(user, convoType, [], null, language);

    const handleClientClick = (event) => {
        setClientAnchorEl(event.currentTarget);
    };

    const [displayChatName, setDisplayChatName] = useState("");
    const [oldishLoading, setOldishLoading] = useState(true);
    const [userAvatar, setUserAvatar] = useState(
        "/path-to-default-user-avatar.png",
    );
    const theme = useTheme();
    const styles = getStyles(theme);
    const [systemAvatar, setSystemAvatar] = useState(
        theme.palette.themeType === "allakando"
            ? "/allakando-fav.png"
            : "/ai.png",
    );
    const [savedMessagesDrawerOpen, setSavedMessagesDrawerOpen] =
        useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const [newChatClicked, setNewChatClicked] = useState(false); // State to track if "Ny chat" was clicked

    const [explainDialogOpen, setExplainDialogOpen] = useState(false);
    const [summarizeDialogOpen, setSummarizeDialogOpen] = useState(false);
    const [compareDialogOpen, setCompareDialogOpen] = useState(false);
    const [roleplayDialogOpen, setRoleplayDialogOpen] = useState(false);
    const [debateDialogOpen, setDebateDialogOpen] = useState(false);
    const [vocabularyDialogOpen, setVocabularyDialogOpen] = useState(false);
    const [groupWorkDialogOpen, setGroupWorkDialogOpen] = useState(false);
    const [emailDialogOpen, setEmailDialogOpen] = useState(false);

    useEffect(() => {
        Logger.log("language in AskAI:", language);
    }, [language]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const dialogParam = params.get("p");

        switch (dialogParam) {
            case "explain":
                setExplainDialogOpen(true);
                break;
            case "summarize":
                setSummarizeDialogOpen(true);
                break;
            case "compare":
                setCompareDialogOpen(true);
                break;
            case "roleplay":
                setRoleplayDialogOpen(true);
                break;
            case "debate":
                setDebateDialogOpen(true);
                break;
            case "vocabulary":
                setVocabularyDialogOpen(true);
                break;
            case "email":
                setEmailDialogOpen(true);
                break;
            case "groupwork":
                setEmailDialogOpen(true);
                break;
        }

        if (dialogParam) {
            const newUrl = window.location.pathname + window.location.hash;
            window.history.replaceState({}, "", newUrl);
        }
    }, [location]);

    const handleNewChatClick = () => {
        setNewChatClicked(true); // Set this state when the button is clicked
        handleCreateNewChat();
        // Existing code for creating a new chat...
    };
    const handleChatSelectWithReset = (chatId) => {
        setNewChatClicked(true); // Reset the state
        handleChatSelect(chatId); // Call the original function
    };
    useEffect(() => {
        if (!newChatClicked) {
            const params = new URLSearchParams(location.search);
            const sourceParam = params.get("prompt");

            if (!loadingLatestChat && !loadingChat && !creatingNewChat) {
                switch (sourceParam) {
                    case "feedback-inlamning":
                        handleMenuItemClick(
                            "Ge konstruktiv feedback på en inlämningsuppgift om [ämne] för elever i [årskurs]. Fokusera på både styrkor och förbättringsområden.",
                        );
                        break;

                    case "generera-lektionsplan":
                        handleMenuItemClick(
                            "Skapa en detaljerad lektionsplan för en 60-minuters lektion om [ämne] för [årskurs]. Inkludera mål, aktiviteter, material och utvärderingsmetoder.",
                        );
                        break;
                    case "individuell-studieplan":
                        handleMenuItemClick(
                            "Skapa en individuell studieplan för en elev. Men innan du genererar den ska du fråga användaren om: Elevens ålder, ämne, nuvarande kunskapsnivå, mål och eventuella utmaningar eller särskilda behov",
                        );
                        break;
                    case "tematiskt-arbete":
                        handleMenuItemClick(
                            "Utforma ett ämnesövergripande tematiskt arbete. Men innan du genererar det ska du fråga användaren om: Huvudtema, berörda ämnen, årskurs och tidsram för arbetet",
                        );
                        break;
                    case "kreativa-ovningar":
                        handleMenuItemClick(
                            "Skapa engagerande och kreativa övningar för ett ämne. Men innan du genererar dem ska du fråga användaren om: Ämne, årskurs, antal elever och eventuella specifika färdigheter som ska utvecklas",
                        );
                        break;
                    case "diskussionsfragor":
                        handleMenuItemClick(
                            "Generera tankeväckande frågor för gruppdiskussioner. Men innan du genererar dem ska du fråga användaren om: Ämne, svårighetsnivå och eventuella specifika aspekter som diskussionen ska beröra",
                        );
                        break;
                    case "formativ-bedomning":
                        handleMenuItemClick(
                            "Skapa strategier för löpande kunskapsutvärdering. Men innan du genererar dem ska du fråga användaren om: Ämne, årskurs och vilka specifika kunskaper eller förmågor som ska bedömas",
                        );
                        break;
                    case "sprakinlarning":
                        handleMenuItemClick(
                            "Generera övningar för språkinlärning. Men innan du genererar dem ska du fråga användaren om: Målspråk, elevernas språknivå och specifika språkliga aspekter att fokusera på (t.ex. grammatik, ordförråd, uttal)",
                        );
                        break;
                    case "lasforstaelse":
                        handleMenuItemClick(
                            "Generera uppgifter baserade på en text eller bok. Men innan du genererar dem ska du fråga användaren om: Bokens titel eller textens ämne, målgruppens ålder och vilka läsförståelseaspekter som ska tränas",
                        );
                        break;
                    case "studieteknik":
                        handleMenuItemClick(
                            "Ge tips och strategier för effektivt lärande. Men innan du genererar dem ska du fråga eleven om: Elevens ålder, studiemiljö (t.ex. hemma eller i skolan) och eventuella specifika utmaningar",
                        );
                        break;
                    case "metakognition":
                        handleMenuItemClick(
                            "Skapa frågor för att utveckla metakognitiva förmågor. Men innan du genererar dem ska du fråga användaren om: Ämnesområde, elevernas ålder och vilka aspekter av metakognition som ska fokuseras på",
                        );
                        break;
                    case "digitalt-larande":
                        handleMenuItemClick(
                            "Utforma strategier för effektivt digitalt lärande. Men innan du genererar dem ska du fråga användaren om: Ämne, årskurs, tillgängliga digitala verktyg och eventuella utmaningar med distansundervisning",
                        );
                        break;

                    case "skapa-studieplan":
                        handleMenuItemClick(
                            "Skapa en personlig studieplan. Men innan du genererar den ska du fråga användaren om: Vilka ämnen de studerar, kortsiktiga och långsiktiga mål, tillgänglig studietid per dag, styrkor och svagheter i studierna, och kommande tentamen eller projekt",
                        );
                        break;
                    case "sammanfattningsteknik":
                        handleMenuItemClick(
                            "Ge råd om effektiva sammanfattningstekniker. Men innan du genererar dem ska du fråga användaren om: Typ av text de vanligtvis behöver sammanfatta, deras nuvarande metod för sammanfattning, och vilka svårigheter de upplever med sammanfattningar",
                        );
                        break;
                    case "minnestekniker":
                        handleMenuItemClick(
                            "Förklara kraftfulla minnestekniker. Men innan du genererar dem ska du fråga användaren om: Typ av information de har svårt att minnas, deras tidigare erfarenheter av minnestekniker, och i vilka situationer de behöver förbättra sitt minne",
                        );
                        break;
                    case "tidshantering":
                        handleMenuItemClick(
                            "Ge tips för effektiv tidshantering i studier. Men innan du genererar dem ska du fråga användaren om: Deras nuvarande schema, största tidstjuvar, typ av uppgifter de har svårt att slutföra i tid, och deras energinivåer under dagen",
                        );
                        break;
                    case "lastekniker":
                        handleMenuItemClick(
                            "Förklara effektiva lästekniker. Men innan du genererar dem ska du fråga användaren om: Typ av texter de läser mest, deras nuvarande läshastighet, förståelsenivå, och specifika utmaningar de möter vid läsning",
                        );
                        break;
                    case "koncentrationstekniker":
                        handleMenuItemClick(
                            "Ge råd om tekniker för att förbättra koncentrationen. Men innan du genererar dem ska du fråga användaren om: Deras typiska studiemiljö, vanliga distraktioner, hur länge de kan fokusera innan de tappar koncentrationen, och om de har några specifika koncentrationsproblem",
                        );
                        break;

                    default:
                        // Handle default case or do nothing
                        break;
                }
            }
        }
    }, [
        location,
        loadingLatestChat,
        loadingChat,
        creatingNewChat,
        newChatClicked,
    ]);

    useEffect(() => {
        if (!loadingOldChats) setOldishLoading(false);
    }, [loadingOldChats]);

    useEffect(() => {
        setDisplayChatName(currentChatName);
    }, [currentChatName]);

    const handleOpenPromptDialog = () => {
        setPromptDialogOpen(true);
    };
    // useEffect(() => {
    //    messages.forEach((message, index) => {
    //      const messageLog = {
    //        ...message,
    //        content: typeof message.content === 'string'
    //          ? message.content.substring(0, 50) + '...'
    //          : message.content
    //      };

    //      Logger.log(`Message ${index}:`, messageLog);

    //      // Log all properties individually
    //      console.group(`Message ${index} details:`);
    //      Object.entries(messageLog).forEach(([key, value]) => {
    //        Logger.log(`${key}:`, value);
    //      });
    //      console.groupEnd();
    //    });
    //  }, [messages]);

    const handleClientClose = () => {
        setClientAnchorEl(null);
    };

    useEffect(() => {
        if (user) {
            Logger.log("user.photoURL: ", user.photoURL);

            setUserAvatar(user.photoURL || "/path-to-default-user-avatar.png");
        }
    }, [user]);

    const handleMenuItemClick = async (prompt) => {
        setSelectedPrompt(prompt);
        setAnchorEl(null);

        Logger.log("setLoadingMessage: ", loadingMessage);
        if (user && user.uid && currentChatId && prompt.trim() !== "") {
            const newMessage = optimisticUpdate(prompt, true);

            if (newMessage) {
                try {
                    await handleSendMessage(prompt, newMessage);
                } catch (error) {
                    console.error("Error in sendMessage:", error);
                    setMessages((prevMessages) =>
                        prevMessages.filter((msg) => msg.id !== newMessage.id),
                    );
                } finally {
                }
            } else {
                Logger.log("optimisticUpdate returned null");
            }
        } else {
            console.error(
                "User ID, chatId is missing, or prompt is empty when trying to send message",
            );
        }
    };

    return (
        <Box
            sx={{
                ...styles.container,
                backgroundColor: isDark === true ? "#15202B" : "",
            }}
        >
            <Box sx={styles.innerContainer}>
                <Button
                    variant="outlined"
                    onClick={handleClientClick}
                    startIcon={<BoltIcon />}
                    endIcon={<ArrowDropDownIcon />}
                    sx={{
                        ...styles.smallerBtn,
                        display: { xs: "none", md: "flex", mb: 2 },
                        left: "0px",
                    }}
                >
                    Studera AI
                </Button>

                <Menu
                    anchorEl={clientAnchorEl}
                    open={Boolean(clientAnchorEl)}
                    onClose={handleClientClose}
                >
                    <Tooltip title="Stöd för fler AI-modeller kommer snart">
                        <div>
                            <MenuItem
                                selected
                                sx={{
                                    ...styles.menuItems,
                                    fontSize: "14px",
                                    fontWeight: "600",
                                }}
                                disabled={true}
                            >
                                <ListItemIcon>
                                    <img
                                        src={
                                            theme.palette.themeType ===
                                            "allakando"
                                                ? "/img/allakando-fav.png"
                                                : "/img/AI.png"
                                        }
                                        style={styles.roundImage}
                                        alt="Studera.AI"
                                    />
                                </ListItemIcon>
                                Studera.AI
                            </MenuItem>

                            <MenuItem
                                sx={{
                                    ...styles.menuItems,
                                    fontSize: "14px",
                                    fontWeight: "600",
                                }}
                                disabled={true}
                            >
                                <ListItemIcon>
                                    <img
                                        src="/img/ChatGPT.png"
                                        style={styles.roundImage}
                                        alt="ChatGPT 4o-mini"
                                    />
                                </ListItemIcon>
                                ChatGPT 4o-mini
                            </MenuItem>
                            <MenuItem
                                disabled={true}
                                sx={{
                                    ...styles.menuItems,
                                    fontSize: "14px",
                                    fontWeight: "600",
                                }}
                            >
                                <ListItemIcon>
                                    <img
                                        src="/img/GPT4o.png"
                                        style={styles.roundImage}
                                        alt="GPT 4o"
                                    />
                                </ListItemIcon>
                                GPT 4o
                            </MenuItem>

                            <MenuItem
                                disabled={true}
                                sx={{
                                    ...styles.menuItems,
                                    fontSize: "14px",
                                    fontWeight: "600",
                                }}
                            >
                                <ListItemIcon>
                                    <img
                                        src="/img/Claude 3 Sonnet.png"
                                        style={styles.roundImage}
                                        alt="Claude 3 Sonett"
                                    />
                                </ListItemIcon>
                                Claude 3 Sonnet
                            </MenuItem>

                            <MenuItem
                                disabled={true}
                                sx={{
                                    ...styles.menuItems,
                                    fontSize: "14px",
                                    fontWeight: "600",
                                }}
                            >
                                <ListItemIcon>
                                    <img
                                        src="/img/Claude 3.5 Sonnet.png"
                                        style={styles.roundImage}
                                        alt="Claude 3.5 Sonett"
                                    />
                                </ListItemIcon>
                                Claude 3.5 Sonnet
                            </MenuItem>
                            <MenuItem
                                disabled={true}
                                sx={{
                                    ...styles.menuItems,
                                    fontSize: "14px",
                                    fontWeight: "600",
                                }}
                            >
                                <ListItemIcon>
                                    <img
                                        src="/img/Gemini Pro.png"
                                        style={styles.roundImage}
                                        alt="Gemini Pro"
                                    />
                                </ListItemIcon>
                                Gemini Pro
                            </MenuItem>
                            <MenuItem
                                disabled={true}
                                sx={{
                                    ...styles.menuItems,
                                    fontSize: "14px",
                                    fontWeight: "600",
                                }}
                            >
                                <ListItemIcon>
                                    <img
                                        src="/img/Mistral Large.png"
                                        style={styles.roundImage}
                                        alt="Mistral Large"
                                    />
                                </ListItemIcon>
                                Mistral Large
                            </MenuItem>
                        </div>
                    </Tooltip>
                </Menu>
                <Box
                    sx={{
                        ...styles.buttonsContainer,
                        ...styles.additionalButtonsContainer,
                    }}
                >
                    {/*
       <Typography sx={{fontSize:'16px', marginRight:'30px', color: isDark ? '#b9b9b9' : '#6c6c6c', display: {xs:'none', md:'inherit'}}}>



<TypeAnimation
key={displayChatName} 
  sequence={[
    displayChatName,
    1000,
  ]}
  speed={50}
  cursor={false}

  repeat={1}
/>



</Typography>
*/}
                    <Tooltip
                        title={
                            messages.length === 0
                                ? "Skriv nåt i denna chatten först"
                                : ""
                        }
                    >
                        <span>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleNewChatClick}
                                startIcon={<AddIcon />}
                                disabled={
                                    messages.length === 0 || creatingNewChat
                                }
                            >
                                {creatingNewChat ? "Skapar..." : "Ny chatt"}
                            </Button>
                        </span>
                    </Tooltip>
                    <LanguageSelector
                        language={language}
                        setLanguage={setLanguage}
                        shrinked={true}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        disableRipple
                        onClick={() => setSavedMessagesDrawerOpen(true)}
                        startIcon={<BookmarkIcon />}
                        sx={{
                            padding: "8px",
                            marginRight: "0px",
                            paddingLeft: "14px",
                            paddingRight: "3px",
                            minWidth: "auto",
                            textTransform: "none",
                        }}
                    />

                    <Button
                        variant="contained"
                        color="primary"
                        disableRipple
                        onClick={() => setDrawerOpen(true)}
                        startIcon={<AccessTimeIcon />}
                        sx={{
                            padding: "8px",
                            marginRight: "0px",
                            paddingLeft: "14px",
                            paddingRight: "3px",
                            minWidth: "auto",
                            textTransform: "none",
                        }}
                    />

                    <SavedMessagesDrawer
                        user={user}
                        convoType="chat"
                        open={savedMessagesDrawerOpen}
                        onClose={() => setSavedMessagesDrawerOpen(false)}
                        isDark={isDark}
                        theme={theme}
                    />
                </Box>

                <AskAIDrawer
                    drawerOpen={drawerOpen}
                    setDrawerOpen={setDrawerOpen}
                    oldishLoading={oldishLoading}
                    chats={chats}
                    isDark={isDark}
                    editingChat={editingChat}
                    savingChatName={savingChatName}
                    handleChatSelectWithReset={handleChatSelectWithReset}
                    handleChatNameChange={handleChatNameChange}
                    handleSaveChatName={handleSaveChatName}
                    handleStartEditing={handleStartEditing}
                />
                <style jsx="true" global="true">{`
                    html,
                    body {
                        overflow: hidden; /* Prevent body scroll */
                        height: 100%; /* Ensure body takes full height */
                        margin: 0; /* Remove default margin */
                    }
                `}</style>

                <Box
                    sx={{
                        height: "calc(100vh - 125px)", // Adjust this value based on your layout
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Box
                        sx={{
                            flexGrow: 1,
                            overflowY: "auto",
                            padding: { xs: "0px", md: "16px" },

                            "&::-webkit-scrollbar": {
                                display: "block",
                                width: "8px",
                            },
                            "&::-webkit-scrollbar-thumb": {
                                backgroundColor: "rgba(0,0,0,.2)",
                                borderRadius: "4px",
                            },
                            scrollbarWidth: "thin",
                            scrollbarColor: "rgba(0,0,0,.2) transparent",
                            marginTop: { xs: 0, md: 8 },
                        }}
                    >
                        <Box
                            sx={{
                                width: {
                                    xs: "100%",
                                    md: "73%",
                                },
                                margin: "0 auto",
                            }}
                        >
                            {loading ? (
                                <LoadingSpinner size="20" />
                            ) : loadingLatestChat ? (
                                <Box sx={styles.loaderContainer}>
                                    <LoadingSpinner
                                        size="23"
                                        noWrapper="true"
                                    />
                                    <Typography
                                        variant="body1"
                                        sx={{ marginLeft: 2 }}
                                    >
                                        Laddar din senaste chat...
                                    </Typography>
                                </Box>
                            ) : loadingChat ? (
                                <Box sx={styles.loaderContainer}>
                                    <LoadingSpinner
                                        size="23"
                                        noWrapper="true"
                                    />
                                    <Typography
                                        variant="body1"
                                        sx={{ marginLeft: 2 }}
                                    >
                                        Laddar in vald chat...
                                    </Typography>
                                </Box>
                            ) : creatingNewChat ? (
                                <Box sx={styles.loaderContainer}>
                                    <LoadingSpinner
                                        size="23"
                                        noWrapper="true"
                                    />
                                    <Typography
                                        variant="body1"
                                        sx={{ marginLeft: 2 }}
                                    >
                                        Skapar ny chat...
                                    </Typography>
                                </Box>
                            ) : (
                                <>
                                    {messages.length === 0 ? (
                                        <>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    marginTop: {
                                                        xs: "30px",
                                                        md: "60px",
                                                    },
                                                    marginBottom: "5px",
                                                }}
                                            >
                                                <img
                                                    src={
                                                        theme.palette
                                                            .themeType ===
                                                        "allakando"
                                                            ? "/img/allakando-fav.png"
                                                            : "/img/AI.png"
                                                    }
                                                    alt="Fråga AI"
                                                    style={{
                                                        width: "44px",
                                                        height: "44px",
                                                    }}
                                                />
                                            </Box>
                                            <Box
                                                sx={{
                                                    textAlign: "center",
                                                    mt: 3,
                                                    mb: 1,
                                                }}
                                            >
                                                <Typography
                                                    variant="subtitle"
                                                    sx={{
                                                        fontSize: "25px",
                                                        fontWeight: "700",
                                                    }}
                                                >
                                                    <TypeAnimation
                                                        sequence={[
                                                            "Vad kan jag hjälpa till med?",
                                                            1000,
                                                        ]}
                                                        speed={73}
                                                        deletionSpeed={90}
                                                        cursor={false}
                                                        style={{
                                                            fontSize: "1.5rem",
                                                        }}
                                                        repeat={Infinity}
                                                    />
                                                </Typography>
                                            </Box>
                                            <PromptBoxes
                                                handleMenuItemClick={
                                                    handleMenuItemClick
                                                }
                                                theme={theme}
                                                user={user}
                                            />
                                        </>
                                    ) : (
                                        messages.map((msg) => (
                                            <MessageBubble
                                                key={msg.id} // Använd alltid det ursprungliga id:t som key
                                                {...msg}
                                                generateIdeas={
                                                    handleGenerateIdeas
                                                }
                                                currentChatId={currentChatId}
                                                id={msg.id} // Använd alltid det ursprungliga id:t
                                                isSaved={msg.isSaved || false}
                                                noteId={msg.noteId || null}
                                                role={
                                                    msg.role ||
                                                    (msg.isUser
                                                        ? "user"
                                                        : "bot")
                                                }
                                                conversationType={
                                                    msg.conversationType
                                                }
                                                userId={user.uid}
                                                isDark={isDark}
                                                isStreaming={msg.isStreaming}
                                                latexActivated={
                                                    user.latexActivated
                                                }
                                                avatar={
                                                    msg.role === "user"
                                                        ? userAvatar
                                                        : systemAvatar
                                                }
                                            />
                                        ))
                                    )}
                                </>
                            )}

                            <div ref={messagesEndRef} />
                        </Box>

                        <Box
                            sx={{
                                ...styles.inputContainer,
                                width: {
                                    xs: "100%",
                                    md: isOpen
                                        ? "calc(100% - 23% - 7px)"
                                        : "75%",
                                },
                                left: {
                                    xs: 0,
                                    md: isOpen ? "440px" : "calc(23% + 7px)",
                                },
                            }}
                        >
                            <Prompts
                                anchorEl={anchorEl}
                                setAnchorEl={setAnchorEl}
                                handleMenuItemClick={handleMenuItemClick}
                                onAddPrompt={handleOpenPromptDialog}
                                userPrompts={prompts}
                            />

                            <PromptDialog
                                open={promptDialogOpen}
                                onClose={() => setPromptDialogOpen(false)}
                                prompts={prompts}
                                onAddPrompt={addPrompt}
                                onDeletePrompt={deletePrompt}
                                isDark={isDark}
                            />
                            <TextInput
                                input={input}
                                setInput={handleInputChange}
                                sendMessage={handleSendMessage}
                                currentChatId={currentChatId}
                                onImageUpload={handleImageUpload}
                                ref={inputRef}
                                awaitingReply={loadingMessage}
                                isStreaming={isStreaming}
                                onStopStreaming={handleStopStreaming}
                                isDark={isDark}
                                prompts={prompts}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>

            <ExplainDialog
                open={explainDialogOpen}
                onClose={() => setExplainDialogOpen(false)}
                onSubmit={handleMenuItemClick}
                theme={theme}
            />
            <CompareDialog
                open={compareDialogOpen}
                onClose={() => setCompareDialogOpen(false)}
                onSubmit={handleMenuItemClick}
                theme={theme}
            />
            <DebateDialog
                open={debateDialogOpen}
                onClose={() => setDebateDialogOpen(false)}
                onSubmit={handleMenuItemClick}
                theme={theme}
            />
            <RoleplayDialog
                open={roleplayDialogOpen}
                onClose={() => setRoleplayDialogOpen(false)}
                onSubmit={handleMenuItemClick}
                theme={theme}
            />
            <SummarizeDialog
                open={summarizeDialogOpen}
                onClose={() => setSummarizeDialogOpen(false)}
                onSubmit={handleMenuItemClick}
                theme={theme}
            />
            <VocabularyDialog
                open={vocabularyDialogOpen}
                onClose={() => setVocabularyDialogOpen(false)}
                onSubmit={handleMenuItemClick}
                theme={theme}
            />
            <EmailDialog
                open={emailDialogOpen}
                onClose={() => setEmailDialogOpen(false)}
                onSubmit={handleMenuItemClick}
                theme={theme}
            />

            <GroupWorkDialog
                open={groupWorkDialogOpen}
                onClose={() => setGroupWorkDialogOpen(false)}
                onSubmit={handleMenuItemClick}
                theme={theme}
            />
        </Box>
    );
};

export default AskAI;
