import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  Grid,
  Box,
  Typography,
  Button,
  IconButton,
  Divider,
  Paper,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import CodeIcon from "@mui/icons-material/Code";
import CopyIcon from "@mui/icons-material/FileCopy";
import DownloadIcon from "@mui/icons-material/GetApp";
import { useTheme } from "@mui/material/styles";
import { getStyles } from "../index";
import { DialogTransition } from "./functions";

const ImageDialog = ({
  open,
  onClose,
  selectedImage,
  onDownload,
  onCopyPrompt,
  showSnackbar,
}) => {
  const theme = useTheme();
  const styles = getStyles(theme);
  const [promptExpanded, setPromptExpanded] = useState(false);
  const [revisedPromptExpanded, setRevisedPromptExpanded] = useState(false);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      TransitionComponent={DialogTransition}
      PaperProps={{
        sx: {
          borderRadius: "16px",
          backgroundColor:
            theme.palette.mode === "dark" ? "#1A2027" : "#FFFFFF",
          backgroundImage: "none",
          padding: { xs: "16px", md: "24px" },
          overflow: "hidden",
        },
      }}
    >
      <IconButton
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 16,
          top: 16,
          zIndex: 1,
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent sx={{ p: 0 }}>
        <Grid container spacing={0}>
          {/* Image Section */}
          <Grid item xs={12} md={8} lg={9}>
            <Box
              sx={{
                height: "calc(100vh - 100px)",
                position: "relative",
                backgroundColor:
                  theme.palette.mode === "dark" ? "#111318" : "#F8F9FA",
                borderRadius: 4,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={selectedImage?.imageUrl}
                alt={selectedImage?.prompt}
                style={{
                  maxWidth: "80%",
                  maxHeight: "80%",
                  objectFit: "contain",
                  borderRadius: "8px",
                }}
              />
            </Box>
          </Grid>

          {/* Info Section */}
          <Grid item xs={12} md={4} lg={3}>
            <Paper
              elevation={0}
              sx={{
                height: "calc(100vh - 100px)",
                overflow: "auto",
                p: 3,
                backgroundColor:
                  theme.palette.mode === "dark" ? "#22303C" : "#FFFFFF",
                borderLeft: `1px solid ${theme.palette.mode === "dark" ? "rgba(255,255,255,0.1)" : "rgba(0,0,0,0.1)"}`,

                display: "flex", // Add this
                flexDirection: "column", // Add this
              }}
            >
              <Box display="flex" alignItems="center" gap={1.5}>
                <Box
                  component="img"
                  src="/img/AI.png"
                  alt="AI Information"
                  sx={{
                    width: 30,
                    height: 30,
                    objectFit: "contain",
                  }}
                />
                <Typography variant="h6" fontWeight="600">
                  Information
                </Typography>
              </Box>

              <Box sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <CalendarTodayIcon
                        sx={{ mr: 1.5, color: theme.palette.primary.main }}
                      />
                      <Box>
                        <Typography variant="caption" color="text.secondary">
                          Skapad
                        </Typography>
                        <Typography variant="body2">
                          {selectedImage?.createdAt.toDate
                            ? selectedImage.createdAt.toDate().toLocaleString()
                            : selectedImage?.createdAt.toLocaleString()}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <AspectRatioIcon
                        sx={{ mr: 1.5, color: theme.palette.primary.main }}
                      />
                      <Box>
                        <Typography variant="caption" color="text.secondary">
                          Storlek
                        </Typography>
                        <Typography variant="body2">
                          {selectedImage?.size}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sx={{ display: "none" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <CodeIcon
                        sx={{ mr: 1.5, color: theme.palette.primary.main }}
                      />
                      <Box>
                        <Typography variant="caption" color="text.secondary">
                          AI-modell
                        </Typography>
                        <Typography variant="body2">
                          {selectedImage?.model}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              <Divider sx={{ my: 3 }} />
              <Box sx={{ display: "none" }}>
                <Typography variant="subtitle1" gutterBottom fontWeight="600">
                  Prompt
                </Typography>
                <Typography variant="body2" sx={{ mt: 1, lineHeight: 1.6 }}>
                  {selectedImage?.prompt?.length > 100 ? (
                    <>
                      {promptExpanded
                        ? selectedImage.prompt
                        : `${selectedImage.prompt.slice(0, 100)}`}
                      <Button
                        onClick={() => setPromptExpanded(!promptExpanded)}
                        sx={{
                          minWidth: "auto",
                          p: 0,
                          ml: 0.5,
                          textTransform: "none",
                          color: theme.palette.primary.main,
                          "&:hover": {
                            background: "none",
                          },
                        }}
                      >
                        {promptExpanded ? "visa mindre" : "...läs mer"}
                      </Button>
                    </>
                  ) : (
                    selectedImage?.prompt
                  )}
                </Typography>
              </Box>
              {selectedImage?.revisedPrompt && (
                <>
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    sx={{ mt: 0 }}
                    fontWeight="600"
                  >
                    Din prompt omvandlades till
                  </Typography>
                  <Typography variant="body2" sx={{ mt: 1, lineHeight: 1.6 }}>
                    {selectedImage?.revisedPrompt?.length > 100 ? (
                      <>
                        {revisedPromptExpanded
                          ? selectedImage.revisedPrompt
                          : `${selectedImage.revisedPrompt.slice(0, 100)}`}
                        <Button
                          onClick={() =>
                            setRevisedPromptExpanded(!revisedPromptExpanded)
                          }
                          sx={{
                            minWidth: "auto",
                            p: 0,
                            ml: 0.5,
                            textTransform: "none",
                            color: theme.palette.primary.main,
                            "&:hover": {
                              background: "none",
                            },
                          }}
                        >
                          {revisedPromptExpanded ? "visa mindre" : "...läs mer"}
                        </Button>
                      </>
                    ) : (
                      selectedImage?.revisedPrompt
                    )}
                  </Typography>
                </>
              )}

              <Box
                sx={{
                  mt: "auto",
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                }}
              >
                <Button
                  fullWidth
                  startIcon={<DownloadIcon />}
                  onClick={onDownload}
                  variant="purp"
                  size="large"
                >
                  Ladda ned bilden
                </Button>
                <Button
                  fullWidth
                  startIcon={<CopyIcon />}
                  onClick={onCopyPrompt}
                  variant="print"
                  size="large"
                >
                  Kopiera prompt
                </Button>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ImageDialog;
