import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  Button,
  Card,
  IconButton,
  CardContent,
  Divider,
  Paper,
  TextField,
  Grid,
  Collapse,
  Dialog,
  CircularProgress,
  DialogContent,
  Alert,
  LinearProgress,
  Tooltip,
} from "@mui/material";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import Confetti from "react-confetti";
import LightbulbIcon from "@mui/icons-material/LightbulbOutlined";
import { useSnackbar } from "./SnackbarHandler";
import KeyIcon from "@mui/icons-material/Key";
import { lighten } from "@mui/material/styles";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { animated, useSpring, useTransition } from "@react-spring/web";
import { alpha } from "@mui/material/styles";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { callApi } from "./api";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import { AnimatedHint, AnimatedText } from "./Animations";
import ClearIcon from "@mui/icons-material/Clear";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import Logger from "./Logger";
const TOTAL_HINTS_ALLOWED = 10;

//

const AnimatedDialog = ({
  isOpen,
  onClose,
  onRestart,
  finalCode,
  conclusion,
  elapsedTime,
  hintsUsed,
  isTimeout,
  solvedQuestions,
  totalQuestions,
  validationHistory,
}) => {
  const dialogRef = useRef(null);
  const [dialogDimensions, setDialogDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [showValidationHistory, setShowValidationHistory] = useState(false);

  useEffect(() => {
    if (dialogRef.current) {
      setDialogDimensions({
        width: dialogRef.current.offsetWidth,
        height: dialogRef.current.offsetHeight,
      });
    }
  }, [isOpen]);

  const springs = useSpring({
    from: { opacity: 0, transform: "scale(0.9)" },
    to: {
      opacity: isOpen ? 1 : 0,
      transform: isOpen ? "scale(1)" : "scale(0.9)",
    },
    config: { tension: 280, friction: 20 },
  });

  const formatTime = (seconds) => {
    if (seconds < 60) {
      return `${seconds} sekunder`;
    }
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes} minuter och ${remainingSeconds} sekunder`;
  };

  const ValidationHistory = ({ history }) => {
    return (
      <Box sx={{ mt: 4, textAlign: "left" }}>
        <Typography variant="h6" sx={{ mb: 2, fontWeight: "600" }}>
          Bedömningshistorik
        </Typography>
        <Box sx={{ maxHeight: "300px", overflowY: "auto" }}>
          {history.map((entry, index) => (
            <Card
              key={index}
              sx={{
                mb: 2,
                backgroundColor: entry.isCorrect
                  ? alpha("#4caf50", 0.1)
                  : alpha("#f44336", 0.1),
                border: "1px solid",
                borderColor: entry.isCorrect
                  ? alpha("#4caf50", 0.3)
                  : alpha("#f44336", 0.3),
              }}
            >
              <CardContent>
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "600", mb: 1 }}
                >
                  Fråga {entry.questionIndex + 1}
                </Typography>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  <strong>Fråga:</strong> {entry.question}
                </Typography>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  <strong>Ditt svar:</strong> {entry.userAnswer}
                </Typography>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  <strong>Korrekt svar:</strong> {entry.correctAnswer}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    backgroundColor: alpha(
                      entry.isCorrect ? "#4caf50" : "#f44336",
                      0.05,
                    ),
                    p: 1,
                    borderRadius: 1,
                  }}
                >
                  <strong>Bedömning:</strong> {entry.explanation}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </Box>
      </Box>
    );
  };
  //

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        component: animated.div,
        style: springs,
        ref: dialogRef,
        sx: {
          overflowY: "visible", // Add this line
        },
      }}
    >
      <DialogContent
        sx={{
          textAlign: "center",
          py: 4,
          position: "relative",
          overflow: "hidden",
          maxHeight: "90vh", // Add this
          overflowY: "auto", // Add this
        }}
      >
        {!isTimeout && isOpen && (
          <Confetti
            width={window.innerWidth}
            height={window.innerHeight}
            recycle={false}
            numberOfPieces={400}
            gravity={0.3}
          />
        )}
        <Typography
          variant="h4"
          sx={{ mb: 2, fontWeight: "600", fontSize: "1.5rem" }}
        >
          {isTimeout ? "⏰ Tiden är ute!" : "🎉 Du knäckte koden!"}
        </Typography>

        {!isTimeout && (
          <>
            <Typography
              variant="h5"
              color="primary"
              sx={{ mt: 3, fontWeight: "600" }}
              gutterBottom
            >
              Koden: {finalCode}
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              {conclusion}
            </Typography>
          </>
        )}

        {isTimeout && (
          <Typography variant="h5" color="primary" sx={{ mb: 3 }}>
            Du hann lösa <strong>{solvedQuestions}</strong> av {totalQuestions}{" "}
            frågor
          </Typography>
        )}

        <Typography variant="body1" sx={{ mb: 1, mt: 4 }}>
          <strong>Förlupen tid:</strong> {formatTime(elapsedTime)}
        </Typography>

        <Typography variant="body1" sx={{ mb: 1 }}>
          <strong>Använda ledtrådar:</strong> {hintsUsed} av {10}
        </Typography>

        <Typography variant="body1" sx={{ mb: 1 }}>
          <strong>Tidstillägg för ledtrådar:</strong> {hintsUsed} ledtrådar × 30
          sekunder = {formatTime(hintsUsed * 30)}
        </Typography>

        <Divider sx={{ my: 2 }} />

        <Typography
          variant="body1"
          sx={{
            mb: 4,
            color: "primary.main",
            fontWeight: "500",
            fontSize: "1.1rem",
          }}
        >
          Slutgiltig tid:{" "}
          <strong>{formatTime(elapsedTime + hintsUsed * 30)}</strong>
        </Typography>
        <Box sx={{ display: "flex", gap: 2, justifyContent: "center" }}>
          <Button
            variant="purp"
            onClick={onRestart}
            startIcon={<RestartAltIcon />}
          >
            Börja om
          </Button>
          {!isTimeout && validationHistory?.length > 0 && (
            <Button
              variant="print"
              onClick={() => setShowValidationHistory(!showValidationHistory)}
              startIcon={
                showValidationHistory ? <KeyIcon /> : <CheckCircleOutlineIcon />
              }
            >
              {showValidationHistory ? "Dölj bedömningar" : "Visa bedömningar"}
            </Button>
          )}
        </Box>

        {!isTimeout && validationHistory?.length > 0 && (
          <Collapse in={showValidationHistory}>
            <Box sx={{ mt: 4, textAlign: "left" }}>
              <Typography variant="h6" sx={{ mb: 2, fontWeight: "600" }}>
                Bedömningshistorik
              </Typography>
              <Box
                sx={{
                  mb: 2,
                  pb: 2,
                }}
              >
                {validationHistory.map((entry, index) => (
                  <Card
                    key={index}
                    sx={{
                      mb: 2,
                      backgroundColor: entry.isCorrect
                        ? alpha("#4caf50", 0.1)
                        : alpha("#f44336", 0.1),
                      border: "1px solid",
                      borderColor: entry.isCorrect
                        ? alpha("#4caf50", 0.3)
                        : alpha("#f44336", 0.3),
                    }}
                  >
                    <CardContent>
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: "600", mb: 1 }}
                      >
                        Fråga {entry.questionIndex + 1}
                      </Typography>
                      <Typography variant="body2" sx={{ mb: 1 }}>
                        <strong>Fråga:</strong> {entry.question}
                      </Typography>
                      <Typography variant="body2" sx={{ mb: 1 }}>
                        <strong>Ditt svar:</strong> {entry.userAnswer}
                      </Typography>
                      <Typography variant="body2" sx={{ mb: 1 }}>
                        <strong>Korrekt svar:</strong> {entry.correctAnswer}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          backgroundColor: alpha(
                            entry.isCorrect ? "#4caf50" : "#f44336",
                            0.05,
                          ),
                          p: 1,
                          borderRadius: 1,
                        }}
                      >
                        <strong>Bedömning:</strong> {entry.explanation}
                      </Typography>
                    </CardContent>
                  </Card>
                ))}
              </Box>
            </Box>
          </Collapse>
        )}
      </DialogContent>
    </Dialog>
  );
};

//

const EscapeRoomGame = ({
  lessonData,
  styles,
  theme,
  duration,
  user,
  toggleViewMode,
  viewMode,
}) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [solvedCodes, setSolvedCodes] = useState([]);
  const [userAnswer, setUserAnswer] = useState("");
  const [feedback, setFeedback] = useState(null);
  const [timeUp, setTimeUp] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [animatingHintIndex, setAnimatingHintIndex] = useState(-1);
  const [animatingKeyIndex, setAnimatingKeyIndex] = useState(-1);
  const [revealedHints, setRevealedHints] = useState({});
  const [isCompleteDialogOpen, setIsCompleteDialogOpen] = useState(false);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [timerKey, setTimerKey] = useState(0);
  const [isTimeoutDialogOpen, setIsTimeoutDialogOpen] = useState(false); // Add this line
  const [isGameStarted, setIsGameStarted] = useState(false);
  const { showSnackbar } = useSnackbar();
  const [elapsedTime, setElapsedTime] = useState(0);
  const [timer, setTimer] = useState(null);
  const [isValidating, setIsValidating] = useState(false);
  const [validationHistory, setValidationHistory] = useState([]);

  const currentQuestion = lessonData?.questions[currentQuestionIndex];
  const totalQuestions = lessonData?.questions.length;

  const getTotalHintsUsed = () => {
    return Object.values(revealedHints).reduce(
      (total, hints) => total + hints.length,
      0,
    );
  };

  useEffect(() => {
    let timerInterval;
    if (isTimerRunning) {
      timerInterval = setInterval(() => {
        setElapsedTime((prev) => prev + 1);
      }, 1000);
      setTimer(timerInterval);
    }
    return () => {
      if (timerInterval) {
        clearInterval(timerInterval);
      }
    };
  }, [isTimerRunning]);

  useEffect(() => {
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [timer]);

  const handleStartGame = () => {
    if (!viewMode) {
      toggleViewMode();
    }
    setIsGameStarted(true);
    setIsTimerRunning(true);
    setTimerKey((prev) => prev + 1);
  };

  const handleStopGame = () => {
    setIsGameStarted(false);
    setIsTimerRunning(false);
    setCurrentQuestionIndex(0);
    setSolvedCodes([]);
    setRevealedHints({});
    setUserAnswer("");
    setFeedback(null);
    setTimeUp(false);
    setElapsedTime(0);
  };

  const validateAnswer = async (userAnswer, question) => {
    if (!userAnswer.trim()) {
      showSnackbar("Du måste skriva ett svar först!", "warning");
      return;
    }

    setIsValidating(true);
    setIsTimerRunning(false); // Pause timer during validation

    try {
      const prompt = `
        Du är en lärare som bedömer elevsvar. Analysera följande:
        
        Fråga: ${question.question}
        Korrekt svar: ${question.solution}
        Elevsvar: "${userAnswer}"
        
        Bedöm om elevsvaret är korrekt baserat på innehåll och koncept, inte exakt matchning.
        Svara i följande JSON-format:
        {
          "isCorrect": true/false,
          "explanation": "Kort förklaring av bedömningen"
        }
      `;

      const response = await callApi(
        [
          {
            role: "system",
            content:
              "Du är en expert på att bedöma elevsvar. Svara endast med JSON enligt det specificerade formatet.",
          },
          {
            role: "user",
            content: prompt,
          },
        ],
        6000,
        true,
        user.uid,
      );

      let result = response.data.choices[0].message.content.trim();
      if (result.startsWith("```json")) {
        result = result.slice(7, -3).trim();
      } else if (result.startsWith("```")) {
        result = result.slice(3, -3).trim();
      }

      const validation = JSON.parse(result);

      setValidationHistory((prev) => [
        ...prev,
        {
          questionIndex: currentQuestionIndex,
          question: question.question,
          userAnswer,
          correctAnswer: question.solution,
          isCorrect: validation.isCorrect,
          explanation: validation.explanation,
          timestamp: new Date().toISOString(),
        },
      ]);

      Logger.log("Validation: ", JSON.stringify(result, null, 2));

      return validation.isCorrect;
    } catch (error) {
      console.error("Error validating answer:", error);
      showSnackbar("Ett fel uppstod vid validering av svaret", "error");
      return false;
    } finally {
      setIsValidating(false);
      if (!timeUp) {
        setIsTimerRunning(true); // Resume timer if time hasn't run out
      }
    }
  };

  const handleCheckAnswer = async () => {
    if (isValidating) return; // Prevent multiple submissions while validating

    const isCorrect = await validateAnswer(userAnswer, currentQuestion);

    if (isCorrect) {
      setAnimatingKeyIndex(solvedCodes.length);
      setTimeout(() => {
        setAnimatingKeyIndex(-1);
      }, 3000);

      setSolvedCodes((prev) => [...prev, currentQuestion.codeFragment]);
      showSnackbar("Rätt svar! Nästa fråga!", "success");
      setUserAnswer("");
      setTimeUp(false);

      if (currentQuestionIndex < lessonData.questions.length - 1) {
        setTimeout(() => {
          setFeedback(null);
          setCurrentQuestionIndex((prev) => prev + 1);
        }, 500);
      } else {
        setIsTimerRunning(false);
        if (timer) {
          clearInterval(timer);
        }
        const finalTime = elapsedTime;
        setElapsedTime(finalTime);
        setIsCompleteDialogOpen(true);
      }
    } else {
      showSnackbar("Fel svar. Försök igen!", "error");
    }
  };
  const handleRestart = () => {
    setIsGameStarted(false);
    setCurrentQuestionIndex(0);
    setIsTimeoutDialogOpen(false);
    setSolvedCodes([]);
    setValidationHistory([]);
    setRevealedHints({});
    setIsCompleteDialogOpen(false);
    setUserAnswer("");

    setFeedback(null);
    setTimeUp(false);
    setElapsedTime(0);
    setIsTimerRunning(true);
    setTimerKey((prev) => prev + 1);
  };

  const handleShowHint = () => {
    const totalUsed = getTotalHintsUsed();
    if (totalUsed >= TOTAL_HINTS_ALLOWED) {
      showSnackbar("Du har använt alla dina ledtrådar", "info");
      return;
    }

    const currentRevealed = revealedHints[currentQuestionIndex] || [];
    if (
      currentQuestion.hints &&
      currentRevealed.length >= currentQuestion.hints.length
    ) {
      showSnackbar("Det finns inga fler ledtrådar för denna fråga.", "info");
      return;
    }

    setAnimatingHintIndex(totalUsed);
    setTimeout(() => setAnimatingHintIndex(-1), 500);

    setRevealedHints({
      ...revealedHints,
      [currentQuestionIndex]: [...currentRevealed, currentRevealed.length],
    });
  };

  if (!isGameStarted) {
    return (
      <Box
        sx={{
          padding: 4,
          margin: "auto",
          textAlign: "center",
          maxWidth: "800px",
          borderRadius: 2,
        }}
      >
        <Typography variant="h4" sx={{ mb: 4, fontWeight: "600" }}>
          {lessonData?.title}
        </Typography>

        <Paper elevation={3} sx={{ p: 4, borderRadius: 4 }}>
          <Typography
            variant="body1"
            sx={{ mb: 4, fontSize: "1.1rem", lineHeight: 1.8 }}
          >
            {lessonData?.story}
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Button
              variant="purp"
              onClick={handleStartGame}
              startIcon={<PlayCircleIcon />}
              sx={{ mt: 3 }}
            >
              Påbörja Escape Room nu
            </Button>
          </Box>
        </Paper>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        padding: 4,
        margin: "auto",
        textAlign: "center",

        borderRadius: 2,
        boxShadow: 3,

        position: "relative",
        overflow: "hidden",
        boxShadow: "none",
        width: { xs: "100%", md: "100%" },
        mt: 3.6,
        zIndex: 1,
      }}
    >
      {showConfetti && (
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          recycle={false}
          numberOfPieces={200}
          gravity={0.3}
        />
      )}

      <Box
        sx={{
          position: "absolute",
          top: user.accountType === "teacher" ? 40 : 0,
          right: 10,
        }}
      >
        <CountdownCircleTimer
          key={`${timerKey}-${currentQuestionIndex}`} // Change this line
          strokeWidth={5}
          duration={duration}
          isPlaying={isTimerRunning}
          colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
          colorsTime={[10, 6, 3, 0]}
          size={80}
          onComplete={() => {
            setTimeUp(true);
            setIsTimerRunning(false);
            if (timer) {
              clearInterval(timer);
            }
            setIsTimeoutDialogOpen(true);
          }}
        >
          {({ remainingTime }) => {
            const now = Date.now() / 1000;
            const decimal = now - Math.floor(now);
            return (
              <Typography variant="h6">
                {remainingTime > 30
                  ? Math.floor(remainingTime)
                  : `${Math.floor(remainingTime)}.${decimal.toFixed(1).slice(2)}`}
              </Typography>
            );
          }}
        </CountdownCircleTimer>
      </Box>

      <Box
        sx={{
          width: { xs: "100%", md: "70%" },
          margin: "0 auto",
        }}
      >
        <Box sx={{ position: "absolute", top: 20, left: 250 }}>
          <Typography
            variant="body2"
            color="primary"
            sx={{ display: "flex", alignItems: "center", gap: 1 }}
          >
            {Array.from({ length: totalQuestions }).map((_, index) => {
              const isUnlocked = index < solvedCodes.length;
              const elasticPop = {
                transform:
                  animatingKeyIndex === index
                    ? "scale(1.5) translateY(-10px)"
                    : "scale(1) translateY(0)",
                transition: "all 1.5s cubic-bezier(0.175, 0.885, 0.32, 1.275)",
              };

              return (
                <Tooltip
                  key={index}
                  title={
                    isUnlocked
                      ? `Kod ${index + 1}: ${solvedCodes[index]}`
                      : `Kod ${index + 1}: Olåst`
                  }
                >
                  <KeyIcon
                    sx={{
                      ...elasticPop,
                      opacity: isUnlocked ? 1 : 0.3,
                      color: isUnlocked
                        ? theme.palette.primary.main
                        : theme.palette.text.disabled,
                      fontSize: 30,
                    }}
                  />
                </Tooltip>
              );
            })}
          </Typography>
        </Box>
        <Tooltip
          title={`Du har ${TOTAL_HINTS_ALLOWED - getTotalHintsUsed()} ledtrådar kvar`}
        >
          <Box
            sx={{
              position: "absolute",
              top: 20,
              right: 250,
              display: "flex",
              gap: 1,
            }}
          >
            {Array.from({ length: TOTAL_HINTS_ALLOWED }).map((_, index) => {
              const totalUsed = getTotalHintsUsed();
              const elasticPop = {
                transform:
                  animatingHintIndex === index
                    ? "scale(1.5) translateY(-10px)"
                    : "scale(1) translateY(0)",
                opacity: totalUsed > index ? 0 : 1,
                transition: "all 1.5s cubic-bezier(0.175, 0.885, 0.32, 1.275)",
              };

              return (
                <Box
                  key={index}
                  sx={{
                    ...elasticPop,
                    color:
                      totalUsed > index
                        ? theme.palette.text.disabled
                        : lighten(theme.palette.text.main, 0.5),
                  }}
                >
                  <LightbulbIcon sx={{ fontSize: 25 }} />
                </Box>
              );
            })}
          </Box>
        </Tooltip>
        <Typography
          variant="h5"
          gutterBottom
          sx={{ mb: 2, fontWeight: "600", mt: 8 }}
        >
          {lessonData?.title}
        </Typography>
        <LinearProgress
          variant="determinate"
          value={
            ((currentQuestionIndex + 1) / lessonData?.questions.length) * 100
          }
          sx={{ borderRadius: "10px", width: "70%", margin: "0 auto", mb: 3 }}
        />
        <Typography
          variant="body1"
          color="textSecondary"
          sx={{ mb: 3, fontSize: "1.2rem", lineHeight: "1.9rem" }}
        >
          {lessonData?.story}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            mb: 2,
            fontSize: "1.2rem",
            lineHeight: "1.9rem",
            fontWeight: "500",
          }}
        >
          <AnimatedText key={currentQuestionIndex}>
            {currentQuestion.storyProgression}
          </AnimatedText>
        </Typography>
        <Box
          sx={{
            ...styles.freshBox,
            mt: 6,
            padding: "40px",
            position: "relative",
            boxShadow: "0 3px 28px rgba(12,8,51,.06)",
            borderRadius: 6,
          }}
        >
          <Typography sx={{ fontSize: "2rem", mb: 3, display: "block" }}>
            🕵️
          </Typography>

          <Typography
            variant="h6"
            gutterBottom
            sx={{ fontWeight: "400", mb: 4 }}
          >
            <AnimatedText key={currentQuestionIndex}>
              {currentQuestion.question}
            </AnimatedText>
          </Typography>
          {feedback && (
            <Alert severity={feedback.type} sx={{ mb: 2 }}>
              {feedback.message}
            </Alert>
          )}
          <Box>
            <TextField
              fullWidth
              variant="outlined"
              label="Skriv ditt svar"
              value={userAnswer}
              onChange={(e) => setUserAnswer(e.target.value)}
              sx={{ mb: 4, width: "60%" }}
              disabled={timeUp}
            />
          </Box>

          <Button
            variant="print"
            startIcon={<LightbulbIcon />}
            onClick={handleShowHint}
          >
            Visa ledtråd
          </Button>
          <Button
            variant="purp"
            onClick={handleCheckAnswer}
            sx={{ ml: 2 }}
            disabled={timeUp || isValidating}
            startIcon={
              isValidating ? (
                <CircularProgress size={16} sx={{ color: "white" }} />
              ) : (
                <CheckCircleOutlineIcon />
              )
            }
          >
            {isValidating ? "Validerar..." : "Skicka svar"}
          </Button>
          <Button
            variant="print"
            onClick={handleStopGame}
            sx={{ ml: 2 }}
            startIcon={<StopCircleIcon />}
          >
            Gå ur spelet
          </Button>
          {user.admin && (
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ mb: 2, mt: 2 }}
            >
              Rätt svar: {currentQuestion.solution}
            </Typography>
          )}
          {revealedHints[currentQuestionIndex]?.map((hintIndex, index) => {
            const isRevealed =
              revealedHints[currentQuestionIndex]?.includes(hintIndex);
            const isNextToReveal =
              hintIndex === revealedHints[currentQuestionIndex]?.length;

            if (!isRevealed && !isNextToReveal) return null;

            return (
              <Box key={index}>
                <AnimatedHint isRevealed={isRevealed}>
                  <Box
                    sx={{
                      p: 2,
                      mb: 2,
                      bgcolor: alpha(
                        theme.palette.primary.main,
                        theme.palette.mode === "dark"
                          ? 0.15 + index * 0.1
                          : 0.08 + index * 0.05,
                      ),
                      borderRadius: "8px",
                      border: `1px solid ${theme.palette.primary.main}40`,
                      display: "flex",
                      alignItems: "flex-start",
                      gap: 1.5,
                    }}
                  >
                    <CheckCircleIcon
                      sx={{
                        color: theme.palette.primary.main,
                        mt: "2px",
                      }}
                    />
                    <Typography
                      sx={{
                        color:
                          theme.palette.mode === "dark"
                            ? theme.palette.text.white
                            : theme.palette.text.primary,
                        flex: 1,
                        textAlign: "left",
                      }}
                    >
                      <Box
                        component="span"
                        sx={{ fontWeight: 700, mr: 1, textAlign: "left" }}
                      >
                        Ledtråd {index + 1}:
                      </Box>
                      {currentQuestion.hints[hintIndex]}
                    </Typography>
                  </Box>
                </AnimatedHint>
              </Box>
            );
          })}
          {timeUp && (
            <Typography variant="body2" color="error" sx={{ mt: 2 }}>
              Tiden är ute! Försök med nästa fråga.
            </Typography>
          )}
        </Box>
      </Box>

      <AnimatedDialog
        isOpen={isCompleteDialogOpen}
        onClose={handleRestart}
        onRestart={handleRestart}
        finalCode={lessonData.finalEscapeCode}
        conclusion={lessonData.conclusion}
        elapsedTime={elapsedTime}
        hintsUsed={getTotalHintsUsed()}
        isTimeout={false}
        validationHistory={validationHistory} // Add this
      />
      <AnimatedDialog
        isOpen={isTimeoutDialogOpen}
        onClose={handleRestart}
        onRestart={handleRestart}
        elapsedTime={elapsedTime}
        hintsUsed={getTotalHintsUsed()}
        isTimeout={true}
        solvedQuestions={solvedCodes.length}
        totalQuestions={lessonData.questions.length}
        validationHistory={validationHistory} // Add this
      />
    </Box>
  );
};

export default EscapeRoomGame;
