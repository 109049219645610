import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  Tooltip,
  FormControl,
  Select,
  MenuItem,
  Grid,
  TextField,
  FormControlLabel,
  Switch,
  Paper,
  Divider,
} from "@mui/material";

import GenerateIcon from "@mui/icons-material/AutoAwesome";

import PrintIcon from "@mui/icons-material/Print";
import AddonTimelineSimple from "./AddonTimelineSimple";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import HistoryIcon from "@mui/icons-material/History";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import FaceIcon from "@mui/icons-material/Face";
import Translate from "./Translate";
import PrintAsPdf from "./PrintAsPdf";
import ShareLink from "./ShareLink";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import TimelineDisplay from "./TimelineDisplay";
import TimelineSimpleDisplay from "./TimelineSimpleDisplay";
import SlideInPaper from "./SlideInPaper";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import AddonTimeline from "./AddonTimeline";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import { useParams } from "react-router-dom";
import DateRangeIcon from "@mui/icons-material/DateRange";
import PersonIcon from "@mui/icons-material/Person";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import AddIcon from "@mui/icons-material/Add";
import HistoricPersonDisplay from "./HistoricPersonDisplay";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import LessonQuizDisplay from "./LessonQuizDisplay";
import AddonQuiz from "./AddonQuiz";
import ReadingComprehensionDialog from "./ReadingComprehensionDialog";
import DashedPapers from "./DashedPapers";
import LessonExercisesDisplay from "./LessonExercisesDisplay";
import ImageIcon from "@mui/icons-material/Image";
import ImageGenerationModal from "./ImageGenerationModal";
import HoverableImage from "./HoverableImage";
import { callApi } from "./api";
import { db } from "./firebase";
import {
  collection,
  query,
  where,
  limit,
  addDoc,
  updateDoc,
  getDocs,
  serverTimestamp,
} from "firebase/firestore";

import Logger from "./Logger";
import LanguageSelector from "./LanguageSelector";

import { getThemes } from "./additionalThemes/HistoricPersonLesson";
import { ThemeSelectorFlow } from "./ThemeSelectorFlow";
import InfoBox from "./InfoBox";
import { handlePrintHistoricPerson } from "./printFunctions";

import { generateAndProcessImage } from "./functions";
import ShareDialog from "./ShareDialog";
import ShareIcon from "@mui/icons-material/Share";
import LoadingBackdrop from "./LoadingBackdrop";
import { useSnackbar } from "./SnackbarHandler";
import ProgressBar from "./ProgressBar";
import LessonDrawer from "./LessonDrawer";
import SaveLessonDialog from "./SaveLessonDialog";
import EditIcon from "@mui/icons-material/Edit";
import { DashedPaper } from "../StyledComponents";
import { getStyles } from "../index";
import { trackLesson, handleImageGenerationError } from "./functions";
import GradeSelector, { gradeInstructions } from "./GradeSelector";
import { useTheme } from "@mui/material/styles";
import { useSearchParams } from "react-router-dom";

import DiaryContent from "./DiaryContent";

const HistoricPersonLesson = ({ user }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);
  const lessonTitleRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageInstructions, setImageInstructions] = useState("");
  const [isGeneratingImages, setIsGeneratingImages] = useState(false);
  const [loadingImageIndex, setLoadingImageIndex] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [isSharing, setIsSharing] = useState(false);
  const quizSectionRef = useRef(null);
  const exercisesSectionRef = useRef(null);
  const timelineSectionRef = useRef(null);
  const timelineSimpleSectionRef = useRef(null);
  const [shareDialogOpenFriends, setShareDialogOpenFriends] = useState(false);
  // Basic settings
  const [lessonTheme, setLessonTheme] = useState("");
  const [language, setLanguage] = useState("svenska");
  const [grade, setGrade] = useState("åk9");
  const [numOfDiaryEntries, setNumOfDiaryEntries] = useState(5);
  const [diaryEntryLength, setDiaryEntryLength] = useState("Långa");

  // Generation states
  const [progress, setProgress] = useState(0);
  const [isGeneratingLesson, setIsGeneratingLesson] = useState(false);
  const [lessonData, setLessonData] = useState("");

  // References for content
  const [customTheme, setCustomTheme] = useState("");
  const [predefinedTheme, setPredefinedTheme] = useState(null);
  const subject = searchParams.get("subject") || null;
  const viewMode = searchParams.get("v") === "t";
  const additionalThemes = getThemes(subject);
  const { showSnackbar } = useSnackbar();
  const theme = useTheme();
  const styles = getStyles(theme);
  const urlContentRef = useRef("");
  const transcriptRef = useRef("");
  const uploadedFileRef = useRef("");
  const bookContentRef = useRef("");
  const wikipediaContentRef = useRef("");
  const randomizedContentRef = useRef("");
  const coursePlanContentRef = useRef("");

  // Add refs for the quiz and comprehension buttons
  const quizButtonRef = useRef(null);
  const timelineButtonRef = useRef(null);
  const timelineSimpleButtonRef = useRef(null);
  const comprehensionButtonRef = useRef(null);
  const translateButtonRef = useRef(null);
  const pdfButtonRef = useRef(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const shareLinkButtonRef = useRef(null);
  const [showControlPanel, setShowControlPanel] = useState(false);
  // Add click handlers for the DashedPaper components

  const handleTimelinePaperClick = () => {
    timelineButtonRef.current?.click();
  };

  const handleTimelineSimplePaperClick = () => {
    timelineSimpleButtonRef.current?.click();
  };

  const handleQuizPaperClick = () => {
    quizButtonRef.current?.click();
  };

  const handleComprehensionPaperClick = () => {
    comprehensionButtonRef.current?.click();
  };

  const { lessonId } = useParams();

  useEffect(() => {
    Logger.log("lessonData: ", JSON.stringify(lessonData, null, 2));
  }, [lessonData]);

  useEffect(() => {
    const loadLessonFromId = async () => {
      if (lessonId) {
        try {
          const q = query(
            collection(db, "lesson_usage"),
            where("__name__", ">=", lessonId),
            where("__name__", "<=", lessonId + "\uf8ff"),
            where("lessonType", "==", "HistoricPersonLesson.js"),
            limit(1),
          );

          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            const docData = querySnapshot.docs[0].data();

            if (!docData.hasOpened) {
              await updateDoc(querySnapshot.docs[0].ref, {
                hasOpened: true,
                openedAt: Date.now(),
              });
            }

            setLessonData(docData.data);

            // Scroll to lesson content
            setTimeout(() => {
              lessonTitleRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }, 100);

            showSnackbar("Lektionen har laddats in.", "success");
          } else {
            showSnackbar("Kunde inte hitta lektionen", "error");
          }
        } catch (error) {
          console.error("Error loading lesson:", error);
          showSnackbar("Ett fel uppstod när lektionen skulle laddas", "error");
        }
      }
    };

    loadLessonFromId();
  }, lessonId);

  useEffect(() => {
    Logger.log("lessonData: ", lessonData);
    const isDataValid =
      lessonData !== null &&
      lessonData !== undefined &&
      Object.keys(lessonData).length > 0 &&
      !Object.values(lessonData).includes("");

    if (isDataValid !== showControlPanel) {
      const timer = setTimeout(() => {
        setShowControlPanel(isDataValid);
      }, 100);

      return () => clearTimeout(timer);
    }
  }, [lessonData, showControlPanel]);

  const handleShare = async (
    lessonComment,
    lessonName,
    selectedImage,
    tags,
    shareData,
  ) => {
    setIsSharing(true);
    await new Promise((resolve) => setTimeout(resolve, 1500));
    // try {
    //   // Create a document for each student
    //   const sharePromises = shareData.studentIds.map((studentId) => {
    //     const documentData = {
    //       userId: user.uid,
    //       shared: true,
    //       sharedTo: studentId,
    //       comment: shareData.comment,
    //       sharedAt: Date.now(),
    //       fileUrl: shareData.fileUrl,
    //       lessonType: "HistoricPersonLesson.js",
    //       timestamp: serverTimestamp(),
    //       lessonComment,
    //       lessonName,
    //       coverImage: selectedImage,
    //       hasPrinted: true,
    //       data: lessonData,
    //     };
    //     Logger.log(
    //       "Adding document for student",
    //       studentId,
    //       ":",
    //       JSON.stringify(documentData, null, 2),
    //     );
    //     return addDoc(collection(db, "lesson_usage"), documentData);
    //   });
    //   await Promise.all(sharePromises);
    //   showSnackbar(`Du har nu delat lektionen!`, "success");
    // }

    try {
      // Create a document for each student and then create notifications with the doc IDs
      const sharePromises = shareData.studentIds.map(async (studentId) => {
        const documentData = {
          userId: user.uid,
          shared: true,
          sharedTo: studentId,
          comment: shareData.comment,
          sharedAt: Date.now(),
          fileUrl: shareData.fileUrl,
          lessonType: "HistoricPersonLesson.js",
          timestamp: serverTimestamp(),
          lessonComment,
          lessonName,
          coverImage: selectedImage,
          hasPrinted: true,
          data: lessonData,
        };
        // Create the lesson document and get its ID
        const lessonDoc = await addDoc(
          collection(db, "lesson_usage"),
          documentData,
        );
        Logger.log(
          "Adding document for student",
          studentId,
          ":",
          JSON.stringify(documentData, null, 2),
        );
        // Create notification with the lesson doc ID
        await addDoc(collection(db, "notifications"), {
          type: "lesson_shared",
          recipientId: studentId,
          senderId: user.uid,
          read: false,
          createdAt: serverTimestamp(),
          lessonData: {
            lessonId: lessonDoc.id,
            lessonType: "HistoricPersonLesson.js",
            lessonName: lessonName,
            fileUrl: shareData.fileUrl,
            comment: shareData.comment,
            coverImage: selectedImage,
          },
        });
        return lessonDoc;
      });
      await Promise.all(sharePromises);
      showSnackbar(`Du har nu delat lektionen!`, "success");
    } catch (error) {
      showSnackbar("Fel vid delande av lektionen: " + error.message, "error");
      console.error(error);
    } finally {
      setIsSharing(false);
    }
  };

  const handleSaveLessonClick = () => {
    setSaveDialogOpen(true);
  };

  const handleSaveWithComment = async (comment, name, coverImage, tags) => {
    await trackLesson(
      user.uid,
      "HistoricPersonLesson.js",
      lessonData,
      null,
      true,
      comment,
      name,
      coverImage,
      tags,
    );
    setDrawerOpen(true);
    showSnackbar("Lektionen sparad i din lektionsbank!", "success");
  };

  const toggleViewMode = () => {
    const currentParams = Object.fromEntries(searchParams.entries());
    if (currentParams.v === "t") {
      delete currentParams.v;
    } else {
      currentParams.v = "t";
    }
    setSearchParams(currentParams);
  };

  const simulateProgress = (startProgress, endProgress, duration) => {
    const stepSize = 0.5;
    const steps = (endProgress - startProgress) / stepSize;
    const stepDuration = duration / steps;
    let currentProgress = startProgress;

    const intervalId = setInterval(() => {
      currentProgress += stepSize;
      if (currentProgress >= endProgress) {
        clearInterval(intervalId);
      } else {
        setProgress(currentProgress);
      }
    }, stepDuration);

    return () => clearInterval(intervalId);
  };

  const handleGenerateLesson = async () => {
    setProgress(0);
    let stopProgressSimulation = null;
    setIsGeneratingLesson(true);
    const gradeInstructionsText = gradeInstructions[grade];
    setLessonData(null);

    try {
      let themeToUse;

      if (lessonTheme === "youtube") {
        themeToUse = transcriptRef.current;
      } else if (lessonTheme === "customTheme") {
        themeToUse = customTheme;
      } else if (lessonTheme === "predefined") {
        themeToUse = predefinedTheme;
      } else if (lessonTheme === "fileUpload") {
        themeToUse = uploadedFileRef.current;
      } else if (lessonTheme === "wikipedia") {
        themeToUse = wikipediaContentRef.current;
      } else if (lessonTheme === "randomizer") {
        themeToUse = randomizedContentRef.current;
      } else if (lessonTheme === "book") {
        themeToUse = bookContentRef.current;
      } else if (lessonTheme === "url") {
        themeToUse = urlContentRef.current;
      } else {
        themeToUse = coursePlanContentRef.current;
      }

      stopProgressSimulation = simulateProgress(0, 90, 15000);

      let response = await callApi(
        [
          {
            role: "system",
            content: `You are an expert historian and creative writer specializing in creating educational and engaging content about historical figures. 
            Your task is to generate detailed and accurate JSON data about a specified historical person, based on the provided theme (${themeToUse}). 
            Ensure historical accuracy and tailor the content to the specified grade level and language (${language}). 
            Adhere strictly to the JSON structure provided in the prompt, ensuring the output is consistent and well-organized.

            `,
          },
          {
            role: "user",
            content: `
            - Theme: "${themeToUse}"
            - Grade: "${gradeInstructionsText}"
            - Language: "${language}"
            - EXACTLY 5 achievements for each category
            - EXACTLY 5 obstacles
            - EXACTLY 5 controversies
            - EXACTLY 5 contemporaries
            - EXACTLY 5 interesting facts

            RESPOND IN THIS EXACT JSON FORMAT:
            {
              "name": "Leonardo da Vinci",
              "introduction": "Leonardo da Vinci was a Renaissance polymath known for his extraordinary contributions to art, science, and engineering.",
              "early_life": {
                "birth": "April 15, 1452, in Vinci, Italy",
                "family": "The illegitimate son of a notary, Ser Piero, and a peasant woman, Caterina. Raised by his father.",
                "education": "Apprenticed under Andrea del Verrocchio, learning painting, sculpture, and mechanical arts."
              },
              "achievements": {
                "konst": [
                  "Painted the Mona Lisa, one of the most famous artworks in history.",
                  "Created The Last Supper, a masterpiece of religious art.",
                  "Pioneered techniques like sfumato and perspective."
                ],
                "vetenskap och anatomi": [
                  "Conducted detailed anatomical studies by dissecting over 30 human bodies.",
                  "Produced accurate diagrams of muscles, bones, and internal organs."
                ],
                "teknik och uppfinningar": [
                  "Designed prototypes for flying machines, tanks, and hydraulic systems.",
                  "Filled notebooks with engineering designs centuries ahead of their time."
                ]
              },
              "challenges": {
                "obstacles_faced": [
                  "Often left projects unfinished, such as the giant bronze horse for the Duke of Milan.",
                  "Faced difficulties securing continuous patronage for his diverse interests."
                ],
                "controversies": [
                  "Accused of failing to deliver commissioned works on time.",
                  "His scientific ideas were sometimes dismissed during his lifetime."
                ]
              },
              "later_life_and_legacy": {
                "later_years": "Spent his final years in France under the patronage of King Francis I.",
                "death": "Died on May 2, 1519, in Amboise, France.",
                "legacy": "Considered one of the greatest geniuses of all time, his work continues to influence art, science, and engineering."
              },
              "contextual_information": {
                "historical_context": "Leonardo lived during the Renaissance, a period of immense cultural, artistic, and scientific growth in Europe.",
                "contemporaries": [
                  "Michelangelo (artist and sculptor)",
                  "Niccolò Machiavelli (philosopher and writer)"
                ]
              },
              "interesting_facts": [
                "Wrote in mirror script to protect his ideas.",
                "Sketched designs for a robot knight, one of the earliest concepts of robotics.",
                "His notebooks contain over 7,000 pages of notes and drawings."
              }
            }


              Notes:


            `,
          },
        ],
        6000,
        true,
        user.uid,
      );

      if (stopProgressSimulation) stopProgressSimulation();
      setProgress(95);

      let messageContent = response.data.choices[0].message.content.trim();
      if (messageContent.startsWith("```json")) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith("```")) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      const data = JSON.parse(messageContent);
      setProgress(100);
      setLessonData({
        ...data,
        imageUrl: null,
      });

      Logger.log("response: ", JSON.stringify(lessonData, null, 2));

      await trackLesson(user.uid, "HistoricPersonLesson.js", null, {
        language,
        lessonTheme,
      });

      showSnackbar("Din lektion har genererats!", "success");
    } catch (error) {
      console.error("Error generating lesson:", error);
      showSnackbar(
        "Ett fel uppstod när lektionen skulle genereras. Försök igen!",
        "error",
      );
    } finally {
      setIsGeneratingLesson(false);
    }
  };

  const handleGenerateImages = async () => {
    if (!lessonData) return;
    setIsGeneratingImages(true);
    handleCloseModal();

    try {
      const imagePromises = lessonData.diaryEntries.map((entry) => {
        const prompt = `Create a photorealistic image depicting a scene from ${entry.date}, based on this: ${entry.content.slice(0, 200)}. ${imageInstructions}`;
        return generateAndProcessImage(prompt, user.uid);
      });

      const imageUrls = await Promise.all(imagePromises);

      setLessonData((prevData) => ({
        ...prevData,
        diaryEntries: prevData.diaryEntries.map((entry, index) => ({
          ...entry,
          imageUrl: imageUrls[index],
        })),
      }));

      showSnackbar("Bilderna har genererats!", "success");
    } catch (error) {
      handleImageGenerationError(error, true, showSnackbar);
    } finally {
      setIsGeneratingImages(false);
      setLoadingImageIndex(null);
    }
  };

  const handleGenerateSpecificImage = async (index) => {
    setIsGeneratingImages(true);
    setLoadingImageIndex(index);
    handleCloseModal();

    try {
      const prompt = `Create a photorealistic image depicting : ${lessonData.name} - ${lessonData.description}. ${imageInstructions}`;

      const imageUrl = await generateAndProcessImage(prompt, user.uid);

      if (imageUrl) {
        setLessonData((prevData) => ({
          ...prevData,
          imageUrl: imageUrl,
        }));
        showSnackbar("Bilden har genererats!", "success");
      }
    } catch (error) {
      handleImageGenerationError(error, false, showSnackbar);
    } finally {
      setIsGeneratingImages(false);
      setLoadingImageIndex(null);
    }
  };

  const handleImageButtonClick = (index = null) => {
    setSelectedImageIndex(index);
    setShowImageModal(true);
  };

  const handleCloseModal = () => {
    setShowImageModal(false);
    setImageInstructions("");
  };

  const handleGenerateWithInstructions = async () => {
    if (selectedImageIndex !== null) {
      handleCloseModal();
      await handleGenerateSpecificImage(selectedImageIndex);
    } else {
      handleCloseModal();
      await handleGenerateImages();
    }
  };

  return (
    <Box sx={styles.container}>
      <Box
        sx={{
          ...styles.innerContainer,
          width: { xs: "100%", md: "100%" },
          margin: "0 auto",
        }}
      >
        {!viewMode && (
          <Typography
            variant="h1"
            gutterBottom
            sx={{
              fontWeight: "bold",
              fontSize: "1.8em",
              mb: 3,
              mt: 5,
              textAlign: "center",
            }}
          >
            Historisk person
          </Typography>
        )}
        <Box
          sx={{
            ...styles.buttonsContainer,
            ...styles.additionalButtonsContainer,
          }}
        >
          {lessonData && user.accountType === "teacher" && (
            <Button
              variant="purp"
              sx={{ ...styles.tightButton }}
              disableRipple
              onClick={() => {
                setTimeout(() => {
                  lessonTitleRef.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "start", // This ensures it scrolls to the top of the element
                  });
                }, 100);
                toggleViewMode();
              }}
              startIcon={
                searchParams.get("v") === "t" ? (
                  <FullscreenExitIcon />
                ) : (
                  <FullscreenIcon />
                )
              }
            >
              {searchParams.get("v") === "t" ? "Redigeringsläge" : "Elevläge"}
            </Button>
          )}

          {!viewMode && (
            <Button
              variant="purp"
              sx={{ ...styles.tightButton }}
              onClick={() => setDrawerOpen(true)}
              startIcon={<BookmarkBorderIcon />}
            >
              Sparat
            </Button>
          )}
        </Box>

        <LessonDrawer
          userId={user.uid}
          lessonType={"HistoricPersonLesson.js"}
          theme={theme}
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          lessonIcon={NewspaperIcon}
          onSelectLesson={(lesson) => {
            setLessonData(lesson.data);
            setDrawerOpen(false);
            setTimeout(() => {
              lessonTitleRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "start", // This ensures it scrolls to the top of the element
              });
            }, 100);
            showSnackbar(`Lektionen har laddats in.`, "success");
          }}
        />

        {!viewMode && (
          <>
            <InfoBox
              icon={FaceIcon}
              title="Historisk person"
              instructions={[
                "Generera information om en valfri historisk person",
                "Redigera innehållet, lägg till övningar, quiz, tidslinje (om du vill) utifrån egna behov",
                "Skriv ut, dela med elever, eller spara i din egen lektionsbank",
              ]}
              theme={theme}
              styles={styles}
            />

            <ThemeSelectorFlow
              key={subject}
              subject={subject}
              value={lessonTheme}
              onChange={(e) => setLessonTheme(e.target.value)}
              customTheme={customTheme}
              onCustomThemeChange={(e) => setCustomTheme(e.target.value)}
              onPredefinedThemeChange={(e) =>
                setPredefinedTheme(e.target.value)
              }
              onUrlContentChange={(content) => {
                Logger.log(
                  "Parent: URL content received",
                  content.substring(0, 300),
                );
                urlContentRef.current = content;
              }}
              onBookContentChange={(content) => {
                bookContentRef.current = content;
              }}
              onYoutubeTranscriptChange={(transcriptContent) => {
                transcriptRef.current = transcriptContent;
              }}
              onFileUpload={(content) => {
                uploadedFileRef.current = content;
              }}
              onWikipediaContentChange={(content) => {
                wikipediaContentRef.current = content;
              }}
              onRandomizedContentChange={(content) => {
                randomizedContentRef.current = content;
              }}
              onCoursePlanContentChange={(content) => {
                coursePlanContentRef.current = content;
              }}
              lessonType={"HistoricPersonLesson.js"}
              user={user}
              theme={theme}
              styles={styles}
              onLessonSelect={(lesson) => {
                Logger.log("onLessonSelect körs");
                setLessonData(lesson.data);
                setTimeout(() => {
                  lessonTitleRef.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "start", // This ensures it scrolls to the top of the element
                  });
                }, 100);
                showSnackbar("Lektionen har laddats in.", "success");
              }}
              additionalThemes={additionalThemes}
            />

            <Box sx={styles.headlineLessonBox}>
              <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
                2. Generera lektionen
              </Typography>
            </Box>

            <Box sx={{ ...styles.freshBox, padding: "40px" }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "500", mb: 1 }}
                  >
                    Årskurs:
                  </Typography>
                  <GradeSelector grade={grade} setGrade={setGrade} />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "500", mb: 1 }}
                  >
                    Språk:
                  </Typography>
                  <LanguageSelector
                    language={language}
                    setLanguage={setLanguage}
                  />
                </Grid>
              </Grid>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "12px",
                  width: "100%",
                }}
              >
                <Button
                  variant={
                    theme.palette.mode === "light" ? "purp" : "contained"
                  }
                  color="primary"
                  onClick={handleGenerateLesson}
                  disabled={isGeneratingLesson}
                  sx={{ mt: 7 }}
                  startIcon={
                    !isGeneratingLesson ? (
                      <GenerateIcon style={{ color: "#fff" }} />
                    ) : (
                      <CircularProgress size={16} style={{ color: "#fff" }} />
                    )
                  }
                >
                  {isGeneratingLesson ? "Genererar..." : "Generera lektion"}
                </Button>
              </div>
              {isGeneratingLesson && (
                <Box sx={{ mt: 6 }}>
                  <ProgressBar
                    progress={progress}
                    label="Genererar lektion — tar 30-60 sekunder..."
                  />
                </Box>
              )}
            </Box>
          </>
        )}

        {viewMode && lessonData && (
          <>
            <Box
              sx={{
                width: { xs: "100%", md: "70%" },
                margin: "0 auto",
                mt: 10,
              }}
            >
              <HistoricPersonDisplay
                lessonData={lessonData}
                setLessonData={setLessonData}
                styles={styles}
                theme={theme}
                viewMode={viewMode}
                user={user}
                handleImageButtonClick={handleImageButtonClick}
                isGeneratingImages={isGeneratingImages}
                loadingImageIndex={loadingImageIndex}
                editMode={isEditMode}
                showSnackbar={showSnackbar}
              />

              {lessonData?.timelinesSimple && (
                <Box
                  ref={timelineSimpleSectionRef}
                  sx={{ mt: 5, mb: 5, position: "relative" }}
                >
                  <TimelineSimpleDisplay
                    timelineData={lessonData.timelinesSimple}
                    onTimelineDataChange={(newData) =>
                      setLessonData({ ...lessonData, timelinesSimple: newData })
                    }
                    theme={theme}
                    styles={styles}
                    viewMode={viewMode}
                    user={user}
                  />
                </Box>
              )}

              {lessonData?.timelines && (
                <Box
                  ref={timelineSectionRef}
                  sx={{ mt: 5, mb: 5, position: "relative" }}
                >
                  <TimelineDisplay
                    timelineData={lessonData.timelines}
                    onTimelineDataChange={(newData) =>
                      setLessonData({ ...lessonData, timelines: newData })
                    }
                    theme={theme}
                    styles={styles}
                    withImages={true}
                    viewMode={viewMode}
                    user={user}
                    onDelete={
                      viewMode
                        ? undefined
                        : (indexToDelete) => {
                            setLessonData((prev) => ({
                              ...prev,
                              timelines: prev.timelines.filter(
                                (_, index) => index !== indexToDelete,
                              ),
                            }));
                            showSnackbar(
                              "Tidslinjen har tagits bort",
                              "success",
                            );
                          }
                    }
                  />
                </Box>
              )}
              {lessonData?.exercises && (
                <Box ref={exercisesSectionRef}>
                  <LessonExercisesDisplay
                    exercises={lessonData.exercises}
                    onUpdateLessonData={setLessonData}
                    viewMode={viewMode}
                    theme={theme}
                    styles={styles}
                  />
                </Box>
              )}
              {lessonData?.quiz && (
                <Box
                  ref={quizSectionRef}
                  sx={{ mt: 5, mb: 5, position: "relative" }}
                >
                  <LessonQuizDisplay
                    quizData={lessonData.quiz}
                    theme={theme}
                    styles={styles}
                    userId={user.uid}
                    viewMode={viewMode}
                    setLessonData={setLessonData}
                  />
                </Box>
              )}
            </Box>
          </>
        )}

        {lessonData && (
          <>
            {!viewMode && (
              <Box
                ref={lessonTitleRef}
                sx={{ ...styles.headlineLessonBox, mt: 10 }}
              >
                <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
                  3. Din lektion
                </Typography>
              </Box>
            )}
            <Box
              sx={{
                ...styles.freshBox,
                padding: "30px",

                display: viewMode ? "none" : undefined,
              }}
            >
              {!viewMode && (
                <Box sx={{ position: "relative" }}>
                  <Box
                    sx={{
                      ...styles.lessonControlPanel,
                      ...styles.lessonControlPanelNuevo,

                      "& .MuiButton-root": {
                        ...styles.controlPanelButtons,
                        ...styles.lessonControlPanelNewButtons,
                      },
                    }}
                  >
                    <Box sx={{ display: "none" }}>
                      <AddonQuiz
                        buttonRef={quizButtonRef}
                        lessonData={lessonData}
                        title={lessonData.title}
                        theme={theme}
                        user={user}
                        styles={styles}
                        showSnackbar={showSnackbar}
                        addQuizToLessonObject={(updatedData) => {
                          setLessonData(updatedData);
                          setTimeout(
                            () =>
                              quizSectionRef.current?.scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                              }),
                            100,
                          );
                        }}
                        buttonText="Lägg till interaktivt quiz"
                      />
                    </Box>
                    <Box sx={{ display: "none", ml: 2 }}>
                      <ReadingComprehensionDialog
                        buttonRef={comprehensionButtonRef}
                        key={lessonData?.title}
                        lessonData={lessonData}
                        user={user}
                        theme={theme}
                        styles={styles}
                        buttonText="Lägg till övningar"
                        addExercisesToLessonObject={(updateFunction) => {
                          setLessonData((prevLessonData) => ({
                            ...prevLessonData,
                            exercises: [
                              ...(prevLessonData.exercises || []),
                              ...updateFunction(prevLessonData).exercises,
                            ],
                          }));
                          setTimeout(
                            () =>
                              exercisesSectionRef.current?.scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                              }),
                            100,
                          );
                        }}
                      />
                    </Box>
                    <Box sx={{ display: "none", ml: 2 }}>
                      <AddonTimeline
                        buttonRef={timelineButtonRef}
                        lessonData={lessonData}
                        theme={theme}
                        user={user}
                        styles={styles}
                        showSnackbar={showSnackbar}
                        addTimelineToLessonObject={(updatedData) => {
                          setLessonData(updatedData);
                          setTimeout(
                            () =>
                              timelineSectionRef.current?.scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                              }),
                            100,
                          );
                        }}
                        buttonText="Lägg till interaktiv tidslinje"
                      />
                    </Box>

                    <Box sx={{ display: "none", ml: 2 }}>
                      <AddonTimelineSimple
                        buttonRef={timelineSimpleButtonRef}
                        lessonData={lessonData}
                        theme={theme}
                        user={user}
                        styles={styles}
                        showSnackbar={showSnackbar}
                        addTimelineToLessonObject={(updatedData) => {
                          setLessonData(updatedData);
                          setTimeout(
                            () =>
                              timelineSimpleSectionRef.current?.scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                              }),
                            100,
                          );
                        }}
                        buttonText="Lägg till enkel tidslinje"
                      />
                    </Box>

                    <Button
                      variant="purp"
                      sx={{
                        mt: 0,
                        mr: 0,

                        ...(isEditMode && {
                          color: "green !important",
                          "&.MuiButton-root": {
                            fontWeight: "700 !important",
                          },
                        }),
                      }}
                      onClick={() => {
                        if (isEditMode) {
                          // Show success message when saving
                          showSnackbar("Ändringarna sparade.", "success");
                        }
                        setIsEditMode(!isEditMode);
                      }}
                      startIcon={isEditMode ? <SaveAsIcon /> : <EditIcon />}
                    >
                      {isEditMode
                        ? "Spara ändringar"
                        : "Redigera historisk person"}
                    </Button>
                    <Button
                      variant="print"
                      startIcon={<SaveAsIcon />}
                      sx={{ mt: 2, ml: 2 }}
                      onClick={handleSaveLessonClick}
                    >
                      Spara i min lektionsbank
                    </Button>
                    <Button
                      variant="success"
                      sx={{}}
                      onClick={() => setShareDialogOpenFriends(true)}
                      startIcon={<ShareIcon />}
                    >
                      Dela med vänner
                    </Button>

                    <Button
                      variant="success"
                      sx={{
                        mt: 0,
                        ml: 2,
                      }}
                      onClick={() => setShareDialogOpen(true)}
                      startIcon={<ShareIcon />}
                    >
                      Dela med elever
                    </Button>

                    <ShareLink
                      buttonRef={shareLinkButtonRef}
                      key={lessonId}
                      lessonId={lessonId}
                      lessonData={lessonData}
                      user={user}
                      lessonType={"HistoricPersonLesson.js"}
                      onCopy={(message, severity) =>
                        showSnackbar(message, severity)
                      }
                      sx={{ mt: 0 }}
                    />

                    <Tooltip title="Hur lektionen ser ut för eleverna">
                      <Button
                        variant="success"
                        onClick={() => {
                          window.scrollTo({ top: 0, behavior: "smooth" });
                          toggleViewMode();
                        }}
                        sx={{ ml: 0, mt: 2 }}
                        startIcon={
                          searchParams.get("v") === "t" ? (
                            <FullscreenExitIcon />
                          ) : (
                            <FullscreenIcon />
                          )
                        }
                      >
                        {searchParams.get("v") === "t"
                          ? "Redigeringsläge"
                          : "Elevläge"}
                      </Button>
                    </Tooltip>

                    <Button
                      variant="print"
                      startIcon={<PrintIcon />}
                      onClick={() => handlePrintHistoricPerson(lessonData)}
                      sx={{ mt: 2, ml: 2 }}
                    >
                      Skriv ut
                    </Button>

                    <PrintAsPdf
                      buttonRef={pdfButtonRef}
                      onPrint={() => handlePrintHistoricPerson(lessonData)}
                      sx={{ ml: 2 }}
                    />
                    <Translate
                      buttonRef={translateButtonRef}
                      lessonData={lessonData}
                      onTranslated={setLessonData}
                      callApi={callApi}
                      user={user}
                      showSnackbar={showSnackbar}
                    />
                  </Box>
                  <Box
                    sx={{
                      ...styles.lessonControlPanelFade,
                    }}
                  />
                </Box>
              )}
              {!viewMode && (
                <HistoricPersonDisplay
                  lessonData={lessonData}
                  setLessonData={setLessonData}
                  styles={styles}
                  theme={theme}
                  viewMode={viewMode}
                  user={user}
                  handleImageButtonClick={handleImageButtonClick}
                  isGeneratingImages={isGeneratingImages}
                  loadingImageIndex={loadingImageIndex}
                  editMode={isEditMode}
                  showSnackbar={showSnackbar}
                />
              )}
              {isEditMode && (
                <>
                  <Box sx={{ textAlign: "center" }}>
                    <Button
                      variant="purp"
                      sx={{ mt: 2, ml: 2, mb: 4 }}
                      onClick={() => {
                        if (isEditMode) {
                          // Show success message when saving
                          showSnackbar("Ändringarna sparade.", "success");
                        }
                        setIsEditMode(!isEditMode);
                      }}
                      startIcon={isEditMode ? <SaveAsIcon /> : <EditIcon />}
                    >
                      {isEditMode
                        ? "Spara ändringar av historisk person"
                        : "Redigera historisk person"}
                    </Button>
                  </Box>
                </>
              )}

              {lessonData?.timelinesSimple && (
                <Box
                  ref={timelineSimpleSectionRef}
                  sx={{ mt: 5, mb: 5, position: "relative" }}
                >
                  <TimelineSimpleDisplay
                    timelineData={lessonData.timelinesSimple}
                    onTimelineDataChange={(newData) =>
                      setLessonData({ ...lessonData, timelinesSimple: newData })
                    }
                    theme={theme}
                    styles={styles}
                    viewMode={viewMode}
                    user={user}
                  />
                </Box>
              )}

              {lessonData?.timelines && (
                <Box
                  ref={timelineSectionRef}
                  sx={{ mt: 5, mb: 5, position: "relative" }}
                >
                  <TimelineDisplay
                    timelineData={lessonData.timelines}
                    onTimelineDataChange={(newData) =>
                      setLessonData({ ...lessonData, timelines: newData })
                    }
                    theme={theme}
                    styles={styles}
                    withImages={true}
                    viewMode={viewMode}
                    user={user}
                    onDelete={
                      viewMode
                        ? undefined
                        : (indexToDelete) => {
                            setLessonData((prev) => ({
                              ...prev,
                              timelines: prev.timelines.filter(
                                (_, index) => index !== indexToDelete,
                              ),
                            }));
                            showSnackbar(
                              "Tidslinjen har tagits bort",
                              "success",
                            );
                          }
                    }
                  />
                </Box>
              )}
              {lessonData?.exercises && (
                <Box ref={exercisesSectionRef}>
                  <LessonExercisesDisplay
                    exercises={lessonData.exercises}
                    onUpdateLessonData={setLessonData}
                    viewMode={viewMode}
                    theme={theme}
                    styles={styles}
                  />
                </Box>
              )}
              {lessonData?.quiz && (
                <Box
                  ref={quizSectionRef}
                  sx={{ mt: 5, mb: 5, position: "relative" }}
                >
                  <LessonQuizDisplay
                    quizData={lessonData.quiz}
                    theme={theme}
                    styles={styles}
                    userId={user.uid}
                    viewMode={viewMode}
                    setLessonData={setLessonData}
                  />
                </Box>
              )}
              {!viewMode && (
                <DashedPapers
                  handleQuizPaperClick={handleQuizPaperClick}
                  handleComprehensionPaperClick={handleComprehensionPaperClick}
                  handleTimelinePaperClick={handleTimelinePaperClick}
                  handleTimelineSimplePaperClick={
                    handleTimelineSimplePaperClick
                  }
                  quizSectionRef={quizSectionRef}
                  timelineSectionRef={timelineSectionRef}
                  timelineSimpleSectionRef={timelineSimpleSectionRef}
                  exercisesSectionRef={exercisesSectionRef}
                  lessonData={lessonData}
                  setLessonData={setLessonData}
                />
              )}
            </Box>

            <SaveLessonDialog
              key={lessonData?.name}
              open={saveDialogOpen}
              onClose={() => setSaveDialogOpen(false)}
              onSave={handleSaveWithComment}
              theme={theme}
              lessonName={lessonData?.name || ""}
              lessonObject={lessonData}
              lessonType={"HistoricPersonLesson.js"}
            />
            <ImageGenerationModal
              open={showImageModal}
              onClose={handleCloseModal}
              onGenerate={handleGenerateWithInstructions}
              isGenerating={isGeneratingImages}
              instructions={imageInstructions}
              setInstructions={setImageInstructions}
              theme={theme}
            />

            <SlideInPaper
              isExpanded={isExpanded}
              onExpandedChange={setIsExpanded}
              onEdit={(e) => {
                e.stopPropagation();
                if (isEditMode) {
                  showSnackbar("Ändringarna sparade.", "success");
                }
                setIsEditMode(!isEditMode);
                setIsExpanded(false);
              }}
              lessonTitleRef={lessonTitleRef}
              viewMode={viewMode}
              toggleViewMode={toggleViewMode}
              theme={theme}
              open={showControlPanel}
              user={user}
              onClick={() => handlePrintHistoricPerson(lessonData)}
              onTranslate={() => translateButtonRef.current?.click()}
              onSaveAsPdf={() => pdfButtonRef.current?.click()}
              onShareLink={() => shareLinkButtonRef.current?.click()}
              onShareWithStudents={() => setShareDialogOpen(true)}
              onShareWithFriends={() => setShareDialogOpenFriends(true)}
              onSaveToBank={() => setSaveDialogOpen(true)}
              onStudentMode={() => {
                window.scrollTo({ top: 0, behavior: "smooth" });
                toggleViewMode();
              }}
              onGenerateExercises={() => {
                comprehensionButtonRef.current?.click();
              }}
              onGenerateQuiz={() => {
                quizButtonRef.current?.click();
              }}
              onGenerateAssignments={() => {
                comprehensionButtonRef.current?.click();
              }}
              onGenerateTimeline={() => {
                timelineButtonRef.current?.click();
              }}
            />

            <ShareDialog
              open={shareDialogOpen}
              onClose={() => setShareDialogOpen(false)}
              onShare={handleShare}
              user={user}
              isLessonSharing={true}
              theme={theme}
              lessonName={lessonData?.name || ""}
              lessonObject={lessonData}
              lessonType={"HistoricPersonLesson.js"}
            />

            <ShareDialog
              open={shareDialogOpenFriends}
              onClose={() => setShareDialogOpenFriends(false)}
              onShare={handleShare}
              user={user}
              isLessonSharing={true}
              theme={theme}
              lessonName={lessonData?.name || ""}
              lessonObject={lessonData}
              lessonType={"HistoricPersonLesson.js"}
              shareWithFriends={true}
            />

            <LoadingBackdrop
              isOpen={isSharing}
              imagePath="/img/AI.png"
              loadingText={"Delar lektionen..."}
            />
          </>
        )}
      </Box>
    </Box>
  );
};

export default HistoricPersonLesson;
