import React, { useState, useEffect } from "react";
import { Box, Typography, IconButton } from "@mui/material";

import RestaurantIcon from "@mui/icons-material/Restaurant";

import PublicIcon from "@mui/icons-material/Public";
import MenuBookIcon from "@mui/icons-material/MenuBook";

import BiotechIcon from "@mui/icons-material/Biotech";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";

import HistoryIcon from "@mui/icons-material/History";

import RefreshIcon from "@mui/icons-material/Refresh";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import EmailDialog from "./EmailDialog";

import RoleplayDialog from "./RoleplayDialog";
import ExplainDialog from "./ExplainDialog";

import OpenInNewIcon from "@mui/icons-material/OpenInNew"; // Add this import
import Diversity3Icon from "@mui/icons-material/Diversity3";

import PeopleIcon from "@mui/icons-material/People";

import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import CategoryIcon from "@mui/icons-material/Category";
import NotesIcon from "@mui/icons-material/Notes";
import BoltIcon from "@mui/icons-material/Bolt";

import StorefrontIcon from "@mui/icons-material/Storefront";

import AnnouncementIcon from "@mui/icons-material/Announcement";
import SummarizeDialog from "./SummarizeDialog";
import SummarizeIcon from "@mui/icons-material/Summarize"; // Add this for the summarize icon
import CompareDialog from "./CompareDialog";
import DebateDialog from "./DebateDialog";
import VocabularyDialog from "./VocabularyDialog";
import GroupWorkDialog from "./GroupWorkDialog";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import CompareIcon from "@mui/icons-material/Compare";

import AutorenewIcon from "@mui/icons-material/Autorenew";

const PromptBox = ({ icon, text, onClick, theme, language = false }) => {
  const isDialogPrompt = [
    "Förklara för mig",
    "Sammanfatta det viktigaste",
    "Jämför och förklara skillnader",
    "Starta ett rollspel",
    "Starta en debatt",
    "Skapa begreppslista",
    "Skriv e-post",
  ].includes(text);

  const getRandomColorWithFixedSL = (saturation, lightness) => {
    const hue = Math.floor(Math.random() * 360); // Random hue between 0 and 359
    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  };

  const randomColor = React.useMemo(
    () => getRandomColorWithFixedSL(53, 44),
    [],
  );

  return (
    <Box
      sx={{
        position: "relative",
        width: "170px",
        height: language ? "100px" : "130px",
        backgroundColor:
          theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.05)" : "white",
        boxShadow:
          theme.palette.mode === "dark"
            ? "none"
            : "rgba(99, 99, 99, 0.11) 0px 2px 8px 0px",
        borderRadius: "15px",
        border:
          theme.palette.mode === "dark"
            ? "1px solid #323d48"
            : "1px solid #e2e8f0",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        padding: "12px",

        cursor: "pointer",
        "&:hover": {
          backgroundColor:
            theme.palette.mode === "dark"
              ? "rgba(255, 255, 255, 0.1)"
              : "#fdfdfd",
        },
      }}
      onClick={onClick}
    >
      <IconButton
        sx={{
          alignSelf: "flex-start",
          color: "white",
          fontSize: "10px",
          padding: "0px",
          "& .MuiSvgIcon-root": {
            fontSize: "20px",
            color: randomColor,
          },
        }}
      >
        {icon}
      </IconButton>
      <Typography
        variant="body2"
        sx={{
          color:
            theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.7)" : "gray",
          textAlign: "left",
          marginTop: "11px",
          fontWeight: "500",
          overflowWrap: "break-word",
        }}
      >
        {text}
      </Typography>

      {isDialogPrompt && (
        <OpenInNewIcon
          sx={{
            position: "absolute",
            bottom: "8px",
            right: "8px",
            fontSize: "16px",
            color:
              theme.palette.mode === "dark"
                ? "rgba(255, 255, 255, 0.4)"
                : "rgba(0, 0, 0, 0.4)",
          }}
        />
      )}
    </Box>
  );
};
//Hej
const PromptBoxes = ({
  handleMenuItemClick,
  theme,
  language = "",
  styles,
  user,
}) => {
  const [displayedPrompts, setDisplayedPrompts] = useState([]);
  const [explainDialogOpen, setExplainDialogOpen] = useState(false);
  const [displayedCategory, setDisplayedCategory] = useState(null);
  const [summarizeDialogOpen, setSummarizeDialogOpen] = useState(false);
  const [emailDialogOpen, setEmailDialogOpen] = useState(false);
  const [vocabularyDialogOpen, setVocabularyDialogOpen] = useState(false);
  const [groupWorkDialogOpen, setGroupWorkDialogOpen] = useState(false);

  const [roleplayDialogOpen, setRoleplayDialogOpen] = useState(false);
  const [compareDialogOpen, setCompareDialogOpen] = useState(false);
  const [debateDialogOpen, setDebateDialogOpen] = useState(false);

  useEffect(() => {
    // Check URL parameters when component mounts
    const params = new URLSearchParams(window.location.search);
    const dialogParam = params.get("p");

    // Open appropriate dialog based on parameter
    switch (dialogParam) {
      case "explain":
        setExplainDialogOpen(true);
        break;
      case "summarize":
        setSummarizeDialogOpen(true);
        break;
      case "compare":
        setCompareDialogOpen(true);
        break;
      case "roleplay":
        setRoleplayDialogOpen(true);
        break;
      case "debate":
        setDebateDialogOpen(true);
        break;
      case "vocabulary":
        setVocabularyDialogOpen(true);
        break;
      case "email":
        setEmailDialogOpen(true);
        break;
      case "groupwork":
        setEmailDialogOpen(true);
        break;
      default:
        break;
    }

    if (dialogParam) {
      // Remove the parameter without causing a page reload
      const newUrl = window.location.pathname + window.location.hash;
      window.history.replaceState({}, "", newUrl);
    }
  }, []);

  const handleExplanationSubmit = (prompt) => {
    handleMenuItemClick(prompt);
  };

  const categories = [
    {
      category: "Rollspel",
      icon: <QuestionAnswerIcon />,
      items: [
        { icon: <QuestionAnswerIcon />, text: "Småprata med en kompis" },
        { icon: <RestaurantIcon />, text: "Beställa mat på restaurang" },
        { icon: <LocalLibraryIcon />, text: "Handla i en klädbutik" },
        { icon: <AccountBalanceIcon />, text: "Boka ett hotellrum" },
        { icon: <BiotechIcon />, text: "Besök hos en läkare" },
        { icon: <PublicIcon />, text: "Fråga om vägen" },
        { icon: <StorefrontIcon />, text: "Besök på en marknad" },
      ],
    },
    {
      category: "Verb",
      icon: <CategoryIcon />,
      items: [
        { icon: <AccessTimeIcon />, text: "Presens (nutid)" },
        { icon: <HistoryIcon />, text: "Preteritum (dåtid)" },
        { icon: <HistoryIcon />, text: "Perfekt (dåtid)" },
        { icon: <HistoryIcon />, text: "Pluskvamperfekt (dåtid)" },
        { icon: <AccessTimeIcon />, text: "Futurum (framtid)" },
        { icon: <AnnouncementIcon />, text: "Imperativ (befallande form)" },
        { icon: <AutorenewIcon />, text: "Gerundium (pågående form)" },
        { icon: <CategoryIcon />, text: "Infinitiv (grundform)" },
        { icon: <HistoryIcon />, text: "Konjunktiv (dåtid)" },
        { icon: <AccessTimeIcon />, text: "Konjunktiv (presens)" },
        { icon: <AccessTimeIcon />, text: "Konjunktiv (futurum)" },
      ],
    },
    {
      category: "Läsförståelse",
      icon: <NotesIcon />,
      items: [
        { icon: <MenuBookIcon />, text: "Berättelse (presens)" },
        { icon: <MenuBookIcon />, text: "Berättelse (dåtid)" },
        { icon: <MenuBookIcon />, text: "Berättelse (futurum)" },
        { icon: <MenuBookIcon />, text: "Berättelse (blandade tempus)" },
      ],
    },
    {
      category: "Fyll _ ordet",
      icon: <BoltIcon />,
      items: [
        { icon: <BoltIcon />, text: "Enkla" },
        { icon: <BoltIcon />, text: "Medelsvåra" },
        { icon: <BoltIcon />, text: "Väldigt svåra" },
      ],
    },
  ];

  const handleCategoryClick = (category) => {
    setDisplayedCategory(category);
  };

  const handleBackToCategories = () => {
    setDisplayedCategory(null);
  };

  const getPromptForItem = (text) => {
    const languageMap = {
      spanish: "spanska",
      french: "franska",
      german: "tyska",
      english: "engelska",
      swedish: "svenska",
    };

    language = languageMap[language] || "spanska";

    switch (text) {
      case "Småprata med en kompis":
        return `Simulera en lång konversation på ${language} där du är barndomsvän som pratar om intressen, familj och vardag och jag är din bästa vän som vill småprata lite. Ge mig en fråga i taget och vänta på svar.`;
      case "Beställa mat på restaurang":
        return `Simulera en lång konversation på ${language} där du är en servitör och jag är en gäst som ska beställa mat. Ge mig en fråga i taget och vänta på svar.`;
      case "Handla i en klädbutik":
        return `Simulera en lång konversation på ${language} där du är en klädförsäljare som hjälper till med att hitta rätt storlek och stil och jag är en gäst som ska prova kläder. Ge mig en fråga i taget och vänta på svar.`;
      case "Boka ett hotellrum":
        return `Simulera en lång konversation på ${language} där du är en hotellreceptionist och jag är en gäst som ska sköta bokningen. Ge mig en fråga i taget och vänta på svar.`;
      case "Besök hos en läkare":
        return `Simulera en lång konversation på ${language} där du är en läkare som ställer frågor om symtom och ger råd och jag är en patient som beskriver sina symtom och följer råd. Ge mig en fråga i taget och vänta på svar.`;
      case "Fråga om vägen":
        return `Simulera en lång konversation på ${language} där du är en lokal invånare som ger vägbeskrivningar och jag är en turist som frågar om vägen till olika platser. Ge mig en fråga i taget och vänta på svar.`;
      case "Besök på en marknad":
        return `Simulera en lång konversation på ${language} där du är en enträgen marknadshandlare som säljer olika produkter och jag är en kund som prutar och köper frukt, grönsaker och andra varor. Ge mig en fråga i taget och vänta på svar.`;
      case "Presens (nutid)":
        return `Du ska ge mig meningar på svenska som jag ska översätta som involverar presens i ${language}. Ge mig en mening i taget.`;
      case "Preteritum (dåtid)":
        return `Du ska ge mig meningar på svenska som jag ska översätta som involverar preteritum i ${language}. Ge mig en mening i taget.`;
      case "Perfekt (dåtid)":
        return `Du ska ge mig meningar på svenska som jag ska översätta som involverar perfekt i ${language}. Ge mig en mening i taget.`;
      case "Pluskvamperfekt (dåtid)":
        return `Du ska ge mig meningar på svenska som jag ska översätta som involverar pluskvamperfekt i ${language}. Ge mig en mening i taget.`;
      case "Futurum (framtid)":
        return `Du ska ge mig meningar på svenska som jag ska översätta som involverar futurum i ${language}. Ge mig en mening i taget.`;
      case "Imperativ (befallande form)":
        return `Du ska ge mig meningar på svenska som jag ska översätta som involverar imperativ i ${language}. Ge mig en mening i taget.`;
      case "Gerundium (pågående form)":
        return `Du ska ge mig meningar på svenska som jag ska översätta som involverar gerundium i ${language}. Ge mig en mening i taget.`;
      case "Infinitiv (grundform)":
        return `Du ska ge mig meningar på svenska som jag ska översätta som involverar infinitiv i ${language}. Ge mig en mening i taget.`;
      case "Konjunktiv (dåtid)":
        return `Du ska ge mig meningar på svenska som jag ska översätta som involverar konjunktiv i dåtid i ${language}. Ge mig en mening i taget.`;
      case "Konjunktiv (presens)":
        return `Du ska ge mig meningar på svenska som jag ska översätta som involverar konjunktiv i presens i ${language}. Ge mig en mening i taget.`;
      case "Konjunktiv (futurum)":
        return `Du ska ge mig meningar på svenska som jag ska översätta som involverar konjunktiv i futurum i ${language}. Ge mig en mening i taget.`;
      case "Berättelse (presens)":
        return `Skriv en kort berättelse på ${language} i presens. Efteråt ska användaren förklara grundpoängen i berättelsen och du ska utvärdera svaret.`;
      case "Berättelse (dåtid)":
        return `Skriv en kort berättelse på ${language} i dåtid. Efteråt ska användaren förklara grundpoängen i berättelsen och du ska utvärdera svaret.`;
      case "Berättelse (futurum)":
        return `Skriv en kort berättelse på ${language} i futurum. Efteråt ska användaren förklara grundpoängen i berättelsen och du ska utvärdera svaret.`;
      case "Berättelse (blandade tempus)":
        return `Skriv en kort berättelse på ${language} med konjunktiv, infinitiv, pluskvamperfekt, gerundium och andra tempus. Efteråt ska användaren förklara grundpoängen i berättelsen och du ska utvärdera svaret.`;
      case "Enkla":
        return `Du ska ge mig meningar på ${language} där ett ord är uteslutet som jag ska gissa vilket det är. Ge mig en mening i taget. Meningen ska vara enkel.`;
      case "Medelsvåra":
        return `Du ska ge mig meningar på ${language} där ett ord är uteslutet som jag ska gissa vilket det är. Ge mig en mening i taget. Meningen ska vara svår.`;
      case "Väldigt svåra":
        return `Du ska ge mig meningar på ${language} där ett ord är uteslutet som jag ska gissa vilket det är. Ge mig en mening i taget. Meningen ska vara väldigt svår.`;
      default:
        return `Prompt for ${text} in ${language}`;
    }
  };

  const randomizePrompts = () => {
    const shuffled = [...prompts].sort(() => 0.5 - Math.random());
    // Ensure all four special prompts are always included
    const explainPrompt = prompts.find((p) => p.text === "Förklara för mig");
    const summarizePrompt = prompts.find(
      (p) => p.text === "Sammanfatta det viktigaste",
    );
    const comparePrompt = prompts.find(
      (p) => p.text === "Jämför och förklara skillnader",
    );
    const roleplayPrompt = prompts.find(
      (p) => p.text === "Starta ett rollspel",
    );
    const groupWorkPrompt = prompts.find(
      (p) => p.text === "Generera grupparbete",
    );

    const debatePrompt = prompts.find((p) => p.text === "Starta en debatt");

    const vocabularyPrompt = prompts.find(
      (p) => p.text === "Skapa begreppslista",
    );
    const emailPrompt = prompts.find((p) => p.text === "Skriv e-post");
    const otherPrompts = shuffled.filter(
      (p) =>
        p.text !== "Förklara för mig" &&
        p.text !== "Sammanfatta det viktigaste" &&
        p.text !== "Jämför och förklara skillnader" &&
        p.text !== "Starta ett rollspel" &&
        p.text !== "Starta en debatt" &&
        p.text !== "Vokabulär" &&
        p.text !== "Skriv e-post" &&
        p.text !== "Generera grupparbete",
    );
    setDisplayedPrompts([
      explainPrompt,
      summarizePrompt,
      comparePrompt,
      roleplayPrompt,
      debatePrompt,
      vocabularyPrompt,
      emailPrompt,
      groupWorkPrompt,
      ...otherPrompts.slice(0, 0), // Changed to 0 since we now have 4 fixed prompts
    ]);
  };

  useEffect(() => {
    randomizePrompts();
  }, []);

  const prompts = [
    {
      icon: <MenuBookIcon />,
      text: "Förklara för mig",
      prompt: "",
      onClick: () => setExplainDialogOpen(true),
      urlParam: "explain",
    },
    {
      icon: <SummarizeIcon />,
      text: "Sammanfatta det viktigaste",
      prompt: "",
      onClick: () => setSummarizeDialogOpen(true),
      urlParam: "summarize",
    },
    {
      icon: <CompareIcon />,
      text: "Jämför och förklara skillnader",
      prompt: "",
      onClick: () => setCompareDialogOpen(true),
      urlParam: "compare",
    },
    {
      icon: <PeopleIcon />,
      text: "Starta ett rollspel",
      prompt: "",
      onClick: () => setRoleplayDialogOpen(true),
      urlParam: "roleplay",
    },

    {
      icon: <QuestionAnswerIcon />,
      text: "Starta en debatt",
      prompt: "",
      onClick: () => setDebateDialogOpen(true),
      urlParam: "debate",
    },

    {
      icon: <NotesIcon />,
      text: "Skapa begreppslista",
      prompt: "",
      onClick: () => setVocabularyDialogOpen(true),
      urlParam: "vocabulary",
    },

    {
      icon: <AlternateEmailIcon />,
      text: "Skriv e-post",
      prompt: "",
      onClick: () => setEmailDialogOpen(true),
      urlParam: "email",
    },

    {
      icon: <Diversity3Icon />,
      text: "Generera grupparbete",
      prompt: "",
      onClick: () => setGroupWorkDialogOpen(true),
      urlParam: "groupwork",
    },
  ];

  return (
    <Box sx={{ position: "relative", marginTop: "0px", textAlign: "center" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "16px",
        }}
      >
        {displayedCategory !== null && (
          <IconButton
            onClick={handleBackToCategories}
            sx={{
              color:
                theme.palette.mode === "dark"
                  ? "rgba(255, 255, 255, 0.7)"
                  : "#c1cbd5",
              "& .MuiSvgIcon-root": {
                fontSize: "24px",
              },
            }}
          >
            <ArrowBackIcon />
          </IconButton>
        )}
        {language.length > 0 && (
          <Typography
            variant="subtitle"
            sx={{
              ...styles.softerText,
              fontWeight: "500",
              fontSize: "18px",
              textAlign: "center",
              display: "inline",
              marginLeft: displayedCategory !== null ? "8px" : "0",
            }}
          ></Typography>
        )}
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: "16px",
          flexWrap: "wrap",
        }}
      >
        {language.length > 0
          ? displayedCategory === null
            ? categories.map((category, index) => (
                <PromptBox
                  key={index}
                  icon={category.icon}
                  text={category.category}
                  theme={theme}
                  language={true}
                  onClick={() => handleCategoryClick(category.category)}
                />
              ))
            : categories
                .find((cat) => cat.category === displayedCategory)
                ?.items.map((item, index) => (
                  <PromptBox
                    key={index}
                    icon={item.icon}
                    text={item.text}
                    language={true}
                    theme={theme}
                    onClick={() =>
                      handleMenuItemClick(getPromptForItem(item.text))
                    }
                  />
                ))
          : displayedPrompts.map((prompt, index) => (
              <PromptBox
                key={index}
                icon={prompt.icon}
                text={prompt.text}
                theme={theme}
                onClick={() => {
                  if (prompt.onClick) {
                    prompt.onClick();
                  } else {
                    handleMenuItemClick(prompt.prompt);
                  }
                }}
              />
            ))}
      </Box>
      {!language.length > 0 && (
        <IconButton
          onClick={randomizePrompts}
          sx={{
            color:
              theme.palette.mode === "dark"
                ? "rgba(255, 255, 255, 0.7)"
                : "#c1cbd5",
            marginBottom: "10px",
            display: "none",
            "& .MuiSvgIcon-root": {
              fontSize: "14px",
            },
          }}
        >
          <RefreshIcon />
        </IconButton>
      )}

      <ExplainDialog
        open={explainDialogOpen}
        onClose={() => setExplainDialogOpen(false)}
        onSubmit={handleExplanationSubmit}
        theme={theme}
      />
      <CompareDialog
        open={compareDialogOpen}
        onClose={() => setCompareDialogOpen(false)}
        onSubmit={handleMenuItemClick}
        theme={theme}
      />
      <DebateDialog
        open={debateDialogOpen}
        onClose={() => setDebateDialogOpen(false)}
        onSubmit={handleMenuItemClick}
        theme={theme}
      />

      <RoleplayDialog
        open={roleplayDialogOpen}
        onClose={() => setRoleplayDialogOpen(false)}
        onSubmit={handleMenuItemClick}
        theme={theme}
      />
      <SummarizeDialog
        open={summarizeDialogOpen}
        onClose={() => setSummarizeDialogOpen(false)}
        onSubmit={handleMenuItemClick}
        theme={theme}
      />

      <VocabularyDialog
        open={vocabularyDialogOpen}
        onClose={() => setVocabularyDialogOpen(false)}
        onSubmit={handleMenuItemClick}
        theme={theme}
      />

      <EmailDialog
        open={emailDialogOpen}
        onClose={() => setEmailDialogOpen(false)}
        onSubmit={handleMenuItemClick}
        theme={theme}
      />

      <GroupWorkDialog
        open={groupWorkDialogOpen}
        onClose={() => setGroupWorkDialogOpen(false)}
        onSubmit={handleMenuItemClick}
        theme={theme}
      />
    </Box>
  );
};

export default PromptBoxes;
