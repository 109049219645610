import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  Paper,
  Grid,
  Dialog,
  DialogContent,
  IconButton,
  Tooltip,
  TextField,
} from "@mui/material";
import { motion, AnimatePresence } from "framer-motion";
import { animated, useSpring } from "@react-spring/web";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import LightbulbIcon from "@mui/icons-material/LightbulbOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddIcon from "@mui/icons-material/Add";
import { FourSquare } from "react-loading-indicators";

const AnimatedHint = ({ isRevealed, children }) => {
  const springs = useSpring({
    from: { opacity: 0, transform: "translateY(20px)" },
    to: {
      opacity: isRevealed ? 1 : 0,
      transform: isRevealed ? "translateY(0px)" : "translateY(20px)",
    },
    config: { tension: 280, friction: 20 },
  });

  return <animated.div style={springs}>{children}</animated.div>;
};

const JeopardyGame = ({
  lessonData,
  setLessonData,
  isFullscreen,
  theme,
  viewMode,
  showSnackbar,
  boardRef,
  isEditing,
  showEditTable,
  answeredQuestions, // Receive props from parent
  setAnsweredQuestions,
  revealedHints,
  setRevealedHints,
}) => {
  const [selectedCell, setSelectedCell] = useState(null);

  const [showAnswer, setShowAnswer] = useState(false);

  const handleCellClick = (categoryIndex, questionIndex) => {
    if (!answeredQuestions.has(`${categoryIndex}-${questionIndex}`)) {
      setSelectedCell(`${categoryIndex}-${questionIndex}`);
    }
  };

  const handleResetAnswers = () => {
    if (answeredQuestions.size === 0) {
      showSnackbar(
        "Du kan bara återställa svar om du har svarat på minst en fråga först.",
        "info",
      );
      return;
    }
    setAnsweredQuestions(new Set());
    setRevealedHints({});
    setSelectedCell(null);
    setShowAnswer(false);
    showSnackbar("Brädet återställt!", "success");
  };

  const dialogVariants = {
    hidden: {
      opacity: 0,
      scale: 0.5,
      y: 100,
    },
    visible: {
      opacity: 1,
      scale: 1,
      y: 0,
      transition: {
        type: "spring",
        duration: 0.7,
        bounce: 0.3,
      },
    },
    exit: {
      opacity: 0,
      scale: 0.8,
      y: -100,
      transition: {
        duration: 0.3,
      },
    },
  };

  const questionVariants = {
    hidden: {
      opacity: 0,
      scale: 0.8,
      rotateY: -90,
      x: -100,
    },
    visible: {
      opacity: 1,
      scale: 1,
      rotateY: 0,
      x: 0,
      transition: {
        type: "spring",
        duration: 0.8,
        bounce: 0.4,
        mass: 0.8,
        damping: 8,
      },
    },
    exit: {
      opacity: 0,
      scale: 0.8,
      rotateY: 90,
      x: 100,
      transition: {
        duration: 0.4,
      },
    },
  };

  const answerVariants = {
    hidden: {
      opacity: 0,
      scale: 1.5,
      y: 50,
      rotateX: 45,
    },
    visible: {
      opacity: 1,
      scale: 1,
      y: 0,
      rotateX: 0,
      transition: {
        type: "spring",
        duration: 0.6,
        bounce: 0.35,
        mass: 0.7,
        damping: 9,
      },
    },
  };

  const pointsVariants = {
    hidden: { scale: 0.5, opacity: 0, y: -20 },
    visible: {
      scale: [1.2, 1],
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        times: [0, 1],
        ease: "backOut",
      },
    },
  };

  const buttonVariants = {
    hidden: {
      opacity: 0,
      y: 20,
      scale: 0.8,
    },
    visible: {
      opacity: 1,
      y: 0,
      scale: 1,
      transition: {
        delay: 0.3,
        type: "spring",
        stiffness: 200,
        damping: 12,
      },
    },
    hover: {
      scale: 1.05,
      transition: {
        duration: 0.2,
      },
    },
    tap: {
      scale: 0.95,
    },
  };

  return (
    <Box ref={boardRef} sx={{ mt: isFullscreen ? 12 : 4, mb: 4 }}>
      {isFullscreen && (
        <>
          <Tooltip title="Stäng fullskärmsläge">
            <IconButton
              onClick={() => {
                document.exitFullscreen();
              }}
              sx={{
                position: "absolute",
                top: 16,
                right: 16,
                color: "rgba(255, 255, 255, 0.9)",
                bgcolor: "rgba(255, 255, 255, 0.1)",
                "&:hover": {
                  color: "rgba(255, 255, 255, 0.5)",
                  bgcolor: "rgba(255, 255, 255, 0.1)",
                },
              }}
              aria-label="Stäng fullskärmsläge"
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Tooltip>

          <Box
            sx={{
              position: "absolute",
              top: 20,
              left: 15,
              color: "white",
              fontWeight: "500",
              fontSize: "1rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              component="img"
              src="/img/AI.png"
              alt="Studera.AI Logo"
              sx={{ height: 20, marginRight: 1 }}
            />
            Studera.AI
          </Box>
        </>
      )}

      <Typography
        variant="h4"
        gutterBottom
        sx={{
          textAlign: "center",
          fontWeight: "bold",
          mb: 4,
          mt: 3,
          color: isFullscreen ? "white" : "inherit",
        }}
      >
        {lessonData.title}
      </Typography>

      {isEditing ? (
        showEditTable ? (
          <Box sx={{ mt: 4 }}>
            <TextField
              fullWidth
              label="Spelets titel"
              variant="outlined"
              value={lessonData.title}
              InputProps={{
                sx: {
                  "& input": {
                    textAlign: "center",
                    fontSize: "1.5rem",
                  },
                },
              }}
              onChange={(e) =>
                setLessonData((prev) => ({
                  ...prev,
                  title: e.target.value,
                }))
              }
              sx={{ mb: 4, textAlign: "center" }}
            />

            <Box
              sx={{
                border: 1,
                borderColor: "divider",
                borderRadius: 4,
              }}
            >
              <Grid container>
                {lessonData.categories.map((category, catIndex) => (
                  <Grid
                    item
                    xs={12 / 5}
                    key={`category-${catIndex}`}
                    sx={{
                      borderRight: catIndex < 4 ? 1 : 0,
                      borderColor: "divider",
                      p: 2,
                    }}
                  >
                    <TextField
                      fullWidth
                      label="Kategori"
                      variant="outlined"
                      value={category.name}
                      InputProps={{
                        sx: {
                          "& input": {
                            fontWeight: 600,
                          },
                        },
                      }}
                      onChange={(e) => {
                        const newData = { ...lessonData };
                        newData.categories[catIndex].name = e.target.value;
                        setLessonData(newData);
                      }}
                    />
                  </Grid>
                ))}
              </Grid>

              {[0, 1, 2, 3, 4].map((questionIndex) => (
                <Grid
                  container
                  key={`row-${questionIndex}`}
                  sx={{
                    borderTop: 1,
                    borderColor: "divider",
                  }}
                >
                  {lessonData.categories.map((category, catIndex) => (
                    <Grid
                      item
                      xs={12 / 5}
                      key={`cell-${catIndex}-${questionIndex}`}
                      sx={{
                        borderRight: catIndex < 4 ? 1 : 0,
                        borderColor: "divider",
                        p: 2,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        sx={{
                          mb: 5,
                          color: "primary.main",
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                      >
                        {(questionIndex + 1) * 100} poäng
                      </Typography>
                      <TextField
                        fullWidth
                        multiline
                        minRows={3}
                        label="Fråga"
                        value={category.questions[questionIndex].question}
                        onChange={(e) => {
                          const newData = { ...lessonData };
                          newData.categories[catIndex].questions[
                            questionIndex
                          ].question = e.target.value;
                          setLessonData(newData);
                        }}
                        sx={{ mb: 2 }}
                        InputProps={{
                          sx: {
                            "& .MuiInputBase-input": {
                              fontSize: "0.85rem",
                            },
                          },
                        }}
                      />
                      <TextField
                        fullWidth
                        multiline
                        minRows={3}
                        label="Svar"
                        value={category.questions[questionIndex].answer}
                        onChange={(e) => {
                          const newData = { ...lessonData };
                          newData.categories[catIndex].questions[
                            questionIndex
                          ].answer = e.target.value;
                          setLessonData(newData);
                        }}
                        sx={{ mt: 2 }}
                        InputProps={{
                          sx: {
                            "& .MuiInputBase-input": {
                              fontSize: "0.85rem",
                            },
                          },
                        }}
                      />
                      {category.questions[questionIndex].hints?.map(
                        (hint, hintIndex) => (
                          <TextField
                            key={hintIndex}
                            fullWidth
                            label={`Ledtråd ${hintIndex + 1}`}
                            value={hint}
                            onChange={(e) => {
                              const newData = { ...lessonData };
                              newData.categories[catIndex].questions[
                                questionIndex
                              ].hints[hintIndex] = e.target.value;
                              setLessonData(newData);
                            }}
                            sx={{ mt: 4 }}
                            InputProps={{
                              sx: {
                                "& .MuiInputBase-input": {
                                  fontSize: "0.85rem",
                                },
                              },
                            }}
                          />
                        ),
                      )}
                      <Button
                        variant="outlined"
                        onClick={() => {
                          const newData = { ...lessonData };
                          if (
                            !newData.categories[catIndex].questions[
                              questionIndex
                            ].hints
                          ) {
                            newData.categories[catIndex].questions[
                              questionIndex
                            ].hints = [];
                          }
                          newData.categories[catIndex].questions[
                            questionIndex
                          ].hints.push("");
                          setLessonData(newData);
                        }}
                        sx={{ mt: 2, fontSize: "0.8rem" }}
                        startIcon={<AddIcon />}
                      >
                        Lägg till ledtråd
                      </Button>
                    </Grid>
                  ))}
                </Grid>
              ))}
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              mt: 4,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "200px",
            }}
          >
            <FourSquare color="#8b5cf6" size="medium" text="" textColor="" />
          </Box>
        )
      ) : (
        <Grid container spacing={2}>
          {lessonData.categories.map((category, index) => (
            <Grid item xs={12 / 5} key={`category-${index}`}>
              <Paper
                sx={{
                  bgcolor: "primary.dark",
                  color: "white",
                  p: 2,
                  minHeight: isFullscreen ? 120 : 80,
                  fontSize: isFullscreen ? "1.5em" : "inherit",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                {category.name}
              </Paper>
            </Grid>
          ))}

          {[0, 1, 2, 3, 4].map((questionIndex) =>
            lessonData.categories.map((category, categoryIndex) => {
              const question = category.questions[questionIndex];
              const cellKey = `${categoryIndex}-${questionIndex}`;
              const isSelected = selectedCell === cellKey;
              const isAnswered = answeredQuestions.has(cellKey);

              return (
                <Grid item xs={12 / 5} key={cellKey}>
                  <Paper
                    onClick={() =>
                      handleCellClick(categoryIndex, questionIndex)
                    }
                    sx={{
                      bgcolor: isAnswered
                        ? "grey.800"
                        : isSelected
                          ? "primary.main"
                          : "primary.light",
                      color: isAnswered ? "grey.500" : "white",
                      p: 2,
                      minHeight: isFullscreen ? 120 : 95,
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                      transition: "background-color 0.2s",
                      "&:hover": {
                        bgcolor: isAnswered ? "grey.800" : "primary.main",
                      },
                    }}
                  >
                    <Typography variant="h5">{question.points}</Typography>
                  </Paper>
                </Grid>
              );
            }),
          )}
        </Grid>
      )}

      <AnimatePresence mode="wait">
        {selectedCell && (
          <Dialog
            open={selectedCell !== null}
            onClose={() => {
              // Mark as answered first, just like the close button does
              setAnsweredQuestions((prev) => new Set([...prev, selectedCell]));
              setSelectedCell(null);
              setShowAnswer(false);
            }}
            maxWidth="md"
            fullWidth
            container={document.fullscreenElement || undefined}
            sx={{ zIndex: 9999 }}
            PaperProps={{
              component: motion.div,
              variants: dialogVariants,
              initial: "hidden",
              animate: "visible",
              exit: "exit",
              sx: {
                bgcolor: "primary.main",
                color: "white",
                minHeight: "70vh",
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
                overflowX: "clip",
                perspective: "1000px",
                transformStyle: "preserve-3d",
              },
            }}
          >
            <DialogContent
              sx={{
                textAlign: "center",
                py: 6,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
                overflow: "hidden",
                perspective: "1000px",
                transformStyle: "preserve-3d",
              }}
            >
              <Box>
                <motion.div
                  variants={pointsVariants}
                  initial="hidden"
                  animate="visible"
                >
                  <Typography variant="h3" sx={{ color: "#FFD700", mb: 2 }}>
                    {(parseInt(selectedCell.split("-")[1]) + 1) * 100} poäng
                  </Typography>
                </motion.div>

                <AnimatePresence mode="wait">
                  {!showAnswer ? (
                    <motion.div
                      key="question"
                      variants={questionVariants}
                      initial="hidden"
                      animate="visible"
                      exit="exit"
                    >
                      <Typography
                        variant="h2"
                        sx={{ mb: 4, mt: 4, fontWeight: "600" }}
                      >
                        {
                          lessonData.categories[
                            parseInt(selectedCell.split("-")[0])
                          ].questions[parseInt(selectedCell.split("-")[1])]
                            .question
                        }
                      </Typography>
                    </motion.div>
                  ) : (
                    <motion.div
                      key="answer"
                      variants={answerVariants}
                      initial="hidden"
                      animate="visible"
                    >
                      <Typography
                        variant="h2"
                        sx={{ mb: 4, mt: 4, fontWeight: "600" }}
                      >
                        {
                          lessonData.categories[
                            parseInt(selectedCell.split("-")[0])
                          ].questions[parseInt(selectedCell.split("-")[1])]
                            .answer
                        }
                      </Typography>
                    </motion.div>
                  )}
                </AnimatePresence>
              </Box>

              {!showAnswer && (
                <Box sx={{ mt: 4, mb: 2 }}>
                  {lessonData.categories[
                    parseInt(selectedCell.split("-")[0])
                  ].questions[parseInt(selectedCell.split("-")[1])].hints?.map(
                    (hint, index) => {
                      const cellKey = `${selectedCell}`;
                      const isRevealed =
                        revealedHints[cellKey]?.includes(index);

                      if (isRevealed) {
                        return (
                          <motion.div
                            key={index}
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ tension: 280, friction: 20 }}
                          >
                            <Box
                              sx={{
                                p: 2,
                                mb: 2,
                                bgcolor: "rgb(193 175 234) !important",
                                borderRadius: "14px",
                                border: `1px solid #efe7ff40`,
                                display: "flex",
                                alignItems: "flex-start",
                                gap: 1.5,
                              }}
                            >
                              <CheckCircleIcon
                                sx={{ color: "primary.main", mt: "2px" }}
                              />
                              <Typography
                                sx={{
                                  color:
                                    theme.palette.mode === "dark"
                                      ? "#fff"
                                      : "text.primary",
                                  flex: 1,
                                  textAlign: "left",
                                }}
                              >
                                <Box
                                  component="span"
                                  sx={{
                                    fontWeight: 700,
                                    mr: 1,
                                    textAlign: "left",
                                  }}
                                >
                                  Ledtråd {index + 1}:
                                </Box>
                                {hint}
                              </Typography>
                            </Box>
                          </motion.div>
                        );
                      }

                      if (index === (revealedHints[cellKey]?.length || 0)) {
                        return (
                          <Box key={index} sx={{ textAlign: "center", mb: 2 }}>
                            <Button
                              variant="print"
                              onClick={() => {
                                const cellKey = `${selectedCell}`;
                                setRevealedHints((prev) => ({
                                  ...prev,
                                  [cellKey]: [...(prev[cellKey] || []), index],
                                }));
                              }}
                              startIcon={<LightbulbIcon />}
                            >
                              {index === 0
                                ? "Visa ledtråd"
                                : "Visa en ledtråd till"}
                            </Button>
                          </Box>
                        );
                      }

                      return null;
                    },
                  )}
                </Box>
              )}

              <motion.div
                variants={buttonVariants}
                initial="hidden"
                animate="visible"
                whileHover="hover"
                whileTap="tap"
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: 2,
                    justifyContent: "center",
                    mt: "auto",
                  }}
                >
                  {!showAnswer ? (
                    <>
                      <Button
                        variant="print"
                        onClick={() => {
                          setAnsweredQuestions(
                            (prev) => new Set([...prev, selectedCell]),
                          );
                          setSelectedCell(null);
                          setShowAnswer(false);
                        }}
                        startIcon={<CloseIcon />}
                      >
                        Stäng
                      </Button>

                      <Button
                        variant="success"
                        onClick={async () => {
                          const dialog =
                            document.querySelector(".MuiDialog-paper");
                          await dialog.animate(
                            [
                              { transform: "translateX(-12px)" },
                              { transform: "translateX(12px)" },
                              { transform: "translateX(-12px)" },
                              { transform: "translateX(12px)" },
                              { transform: "translateX(-10px)" },
                              { transform: "translateX(10px)" },
                              { transform: "translateX(-8px)" },
                              { transform: "translateX(8px)" },
                              { transform: "translateX(-5px)" },
                              { transform: "translateX(5px)" },
                              { transform: "translateX(-3px)" },
                              { transform: "translateX(3px)" },
                              { transform: "translateX(-1px)" },
                              { transform: "translateX(1px)" },
                              { transform: "translateX(0)" },
                            ],
                            {
                              duration: 800,
                              easing: "cubic-bezier(0.25, 0.46, 0.45, 0.94)",
                            },
                          ).finished;
                          setShowAnswer(true);
                        }}
                        startIcon={<VisibilityIcon />}
                      >
                        Visa svaret
                      </Button>
                    </>
                  ) : (
                    <Button
                      variant="print"
                      onClick={() => {
                        setAnsweredQuestions(
                          (prev) => new Set([...prev, selectedCell]),
                        );
                        setSelectedCell(null);
                        setShowAnswer(false);
                      }}
                      startIcon={<CloseIcon />}
                      sx={{ minWidth: "300px" }}
                    >
                      Stäng
                    </Button>
                  )}
                </Box>
              </motion.div>
            </DialogContent>
          </Dialog>
        )}
      </AnimatePresence>
    </Box>
  );
};

export default JeopardyGame;
