import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Stack,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { East as ArrowIcon } from "@mui/icons-material";
import { AutoFixHigh, RestartAlt } from "@mui/icons-material";

import LanguageSelector from "./LanguageSelector";

const DebateDialog = ({ open, onClose, onSubmit, theme }) => {
  const [debateFormat, setDebateFormat] = useState("formal");
  const [position, setPosition] = useState("for");
  const [topic, setTopic] = useState("");
  const [debateStyle, setDebateStyle] = useState("academic");
  const [complexity, setComplexity] = useState("medel");
  const [language, setLanguage] = useState("svenska");
  const [showExamples, setShowExamples] = useState(false);
  const [exampleType, setExampleType] = useState("topics");
  const dialogContentRef = React.useRef(null);

  const exampleTopics = [
    {
      category: "Miljö & Klimat",
      topics: [
        "Kärnkraft bör fasas ut till förmån för förnybara energikällor",
        "Klimatkrisen kräver ett omedelbart stopp för all ny fossilbränsleutvinning",
        "Konsumtionsbaserade utsläpp bör beskattas hårdare",
        "Privat bilism i städer bör förbjudas för klimatets skull",
      ],
    },
    {
      category: "Ekonomi & Samhälle",
      topics: [
        "Vinster i välfärden bör förbjudas",
        "En 30-timmars arbetsvecka bör införas med bibehållen lön",
        "Kapitalismen är oförenlig med klimatomställningen",
        "Banker och finansinstitut bör förstatligas",
        "Ett tak på vd-löner bör införas i relation till anställdas löner",
      ],
    },
    {
      category: "Social Rättvisa",
      topics: [
        "Bostaden är en mänsklig rättighet som bör garanteras av staten",
        "Gratis kollektivtrafik bör införas i hela landet",
        "Alla former av diskriminering bör vara straffbara",
        "Föräldraförsäkringen bör delas lika mellan föräldrarna",
        "Ett övre tak för hyreshöjningar bör införas",
      ],
    },
    {
      category: "Utbildning",
      topics: [
        "Friskolor med vinstintresse bör förbjudas",
        "Alla universitetsutbildningar bör vara avgiftsfria",
        "Studiemedel bör höjas och göras om till bidrag",
        "Alla elever bör ha rätt till modersmålsundervisning",
        "Skolsegregationen måste motverkas genom aktiv blandning av elever",
      ],
    },
  ];

  const historicalFigures = [
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/misc%2Fdebatter%2Feinstein.jpeg?alt=media&token=447086a8-39cb-4024-bf15-4296936bd561",
      title: "Albert Einstein",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/misc%2Fdebatter%2Fshakespear.jpeg?alt=media&token=9b723355-96a9-488f-b225-a36bd11d381c",
      title: "William Shakespeare",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/misc%2Fdebatter%2Fplato.jpeg?alt=media&token=cf287af0-97cd-4234-ae00-d82206aa633c",
      title: "Platon",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/misc%2Fdebatter%2Ftesla.jpeg?alt=media&token=d693fd14-dda7-4f36-a6c6-097df54bbe72",
      title: "Nikola Tesla",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/misc%2Fdebatter%2Fada%20lovelace.jpeg?alt=media&token=1d605332-bec4-46d8-a5b0-79bde5654a6c",
      title: "Ada Lovelace",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/misc%2Fdebatter%2Fdarwin.jpeg?alt=media&token=cbd4789b-24a9-4fe0-a79d-00deede0779e",
      title: "Charles Darwin",
    },
  ];

  const handleSelectExample = (selectedTopic) => {
    setTopic(selectedTopic);
    setShowExamples(false);
    if (dialogContentRef.current) {
      dialogContentRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const handleSelectPerson = (person) => {
    setTopic(`Debatt med ${person.title}`);
    setShowExamples(false);
    if (dialogContentRef.current) {
      dialogContentRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const loadExample = () => {
    setShowExamples(!showExamples);
    if (!showExamples) {
      setExampleType("topics");
    }
  };

  const handleSubmit = () => {
    if (!topic.trim()) return;

    const formatMap = {
      formal: "Formell debatt",
      panel: "Paneldebatt",
      argumentative: "Argumenterande samtal",
      classroom: "Klassrumsdebatt",
      open: "Öppen diskussion",
    };

    const styleMap = {
      academic: "akademisk",
      political: "politisk",
      professional: "professionell",
      casual: "informell",
      competitive: "tävlingsinriktad",
    };

    const positionMap = {
      for: "för",
      against: "emot",
    };

    let prompt;
    const isHistoricalPerson = topic.startsWith("Debatt med ");

    if (isHistoricalPerson) {
      const personName = topic.replace("Debatt med ", "");
      prompt = `Du är nu ${personName}. Använd din historiska personlighets karaktär, filosofi och världsbild. 
      Argumentera som ${personName} skulle ha gjort, baserat på vad vi vet om ${personName}s idéer och övertygelser.
      
      Vi ska ha en ${formatMap[debateFormat].toLowerCase()} där vi diskuterar ett ämne jag väljer.
      Använd en ${styleMap[debateStyle]} samtalston, men behåll alltid din roll som ${personName}.
      Komplexitetsnivå: ${complexity}.
       Språk: ${language}.
      Börja med att kort presentera dig själv som ${personName}, och låt mig sedan välja vilket ämne vi ska debattera.`;
    } else {
      prompt = `Vi ska ha en debatt i ${formatMap[debateFormat]} med ${styleMap[debateStyle]} ton. 
      Ämnet är: "${topic}". 
      Du argumenterar ${position === "for" ? "emot" : "för"} påståendet, medan jag argumenterar ${positionMap[position]}.
      Komplexitetsnivå: ${complexity}.
       Språk: ${language}.
      Börja med att presentera din position och ge ditt första argument.`;
    }

    onSubmit(prompt);
    onClose();
    setDebateFormat("formal");
    setPosition("for");
    setTopic("");
    setDebateStyle("academic");
    setComplexity("medel");
    setShowExamples(false);
  };

  const debateFormats = [
    { value: "formal", label: "Formell debatt" },
    { value: "panel", label: "Paneldebatt" },
    { value: "argumentative", label: "Argumenterande samtal" },
    { value: "classroom", label: "Klassrumsdebatt" },
    { value: "open", label: "Öppen diskussion" },
  ];

  const positions = [
    { value: "for", label: "För" },
    { value: "against", label: "Emot" },
  ];

  const debateStyles = [
    { value: "academic", label: "Akademisk" },
    { value: "political", label: "Politisk" },
    { value: "professional", label: "Professionell" },
    { value: "casual", label: "Informell" },
    { value: "competitive", label: "Tävlingsinriktad" },
  ];

  const complexityLevels = [
    { value: "basic", label: "Nybörjare" },
    { value: "medel", label: "Medel" },
    { value: "advanced", label: "Avancerad" },
    { value: "expert", label: "Expert" },
  ];

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: 900,
          backgroundColor:
            theme.palette.mode === "dark" ? "background.default" : "white",
          border:
            theme.palette.mode === "dark"
              ? "1px solid #323d48"
              : "1px solid #e2e8f0",
          p: 3,
        },
      }}
    >
      <IconButton
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 16,
          top: 16,
          color:
            theme.palette.mode === "dark"
              ? "rgba(255, 255, 255, 0.7)"
              : "inherit",
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 1,
          color:
            theme.palette.mode === "dark"
              ? "rgba(255, 255, 255, 0.7)"
              : "inherit",
          pt: 2,
        }}
      >
        <Typography variant="h5" fontWeight={700}>
          Skapa en debatt
        </Typography>
      </DialogTitle>

      <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
        <Button
          variant="purp"
          size="small"
          onClick={loadExample}
          startIcon={showExamples ? <RestartAlt /> : <AutoFixHigh />}
          sx={{
            minWidth: "unset",
            px: 2,
            py: 0.5,
            fontSize: "0.75rem",
          }}
        >
          {showExamples ? "Stäng exempel" : "Visa exempel"}
        </Button>
      </Box>

      <DialogContent
        ref={dialogContentRef}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: 4,
          pb: 4,
        }}
      >
        {showExamples ? (
          <Stack spacing={4} width="100%">
            <Box
              sx={{ display: "flex", justifyContent: "center", gap: 2, mb: 2 }}
            >
              <Button
                variant={exampleType === "topics" ? "save" : "print"}
                onClick={() => setExampleType("topics")}
                sx={{ minWidth: 120 }}
              >
                Ämnen
              </Button>
              <Button
                variant={exampleType === "people" ? "save" : "print"}
                onClick={() => setExampleType("people")}
                sx={{ minWidth: 120 }}
              >
                Personer
              </Button>
            </Box>

            {exampleType === "topics" ? (
              // Topics view
              exampleTopics.map((category, idx) => (
                <Box key={idx}>
                  <Typography
                    variant="h6"
                    fontWeight={600}
                    textAlign="center"
                    mb={2}
                  >
                    {category.category}
                  </Typography>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "repeat(2, 1fr)",
                      gap: 2,
                    }}
                  >
                    {category.topics.map((topic, topicIdx) => (
                      <Button
                        key={topicIdx}
                        variant="print"
                        onClick={() => handleSelectExample(topic)}
                        sx={{
                          height: "auto",
                          padding: 2,
                          textAlign: "left",
                          whiteSpace: "normal",
                          lineHeight: 1.4,
                        }}
                      >
                        {topic}
                      </Button>
                    ))}
                  </Box>
                </Box>
              ))
            ) : (
              // People view
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gap: 4,
                }}
              >
                {historicalFigures.map((person, idx) => (
                  <Button
                    key={idx}
                    variant="print"
                    onClick={() => handleSelectPerson(person)}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                      padding: 2,
                      height: "auto",
                      textAlign: "left",
                      justifyContent: "flex-start", // This aligns content to the left
                      width: "100%", // Ensures button takes full width
                    }}
                  >
                    <img
                      src={person.image}
                      alt={person.title}
                      style={{
                        width: 60,
                        height: 60,
                        borderRadius: "50%",
                        objectFit: "cover",
                      }}
                    />
                    <Typography variant="subtitle1" sx={{ fontWeight: "600" }}>
                      {person.title}
                    </Typography>
                  </Button>
                ))}
              </Box>
            )}
          </Stack>
        ) : (
          <Box sx={{ width: "100%" }}>
            <Typography
              variant="subtitle1"
              fontWeight={600}
              sx={{ textAlign: "center" }}
            >
              Ange debattämne:
            </Typography>
            <TextField
              fullWidth
              value={topic}
              onChange={(e) => setTopic(e.target.value)}
              label="Ämne/Resolution"
              placeholder="T.ex. Artificiell intelligens kommer att ha en övervägande positiv inverkan på samhället"
              sx={{ mt: 2, mb: 4 }}
            />

            <Stack spacing={2} width="100%" alignItems="center" sx={{ mb: 4 }}>
              <Typography variant="subtitle1" fontWeight={600}>
                Välj språk:
              </Typography>
              <LanguageSelector language={language} setLanguage={setLanguage} />
            </Stack>

            <Stack spacing={6} width="100%" alignItems="center">
              <Box width="100%" sx={{ textAlign: "center" }}>
                <Typography variant="subtitle1" fontWeight={600} sx={{ mb: 3 }}>
                  Välj format och position:
                </Typography>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: 2,
                    width: "100%",
                  }}
                >
                  {debateFormats.map(({ value, label }) => (
                    <Button
                      key={value}
                      variant={debateFormat === value ? "save" : "print"}
                      onClick={() => setDebateFormat(value)}
                      sx={{ height: 45 }}
                      color={debateFormat === value ? "primary" : "inherit"}
                      startIcon={
                        debateFormat === value ? <CheckCircleIcon /> : null
                      }
                    >
                      {label}
                    </Button>
                  ))}
                </Box>
              </Box>

              <Box width="100%" sx={{ textAlign: "center" }}>
                <Typography variant="subtitle1" fontWeight={600} sx={{ mb: 3 }}>
                  Vill du vara för eller emot i debatten?
                </Typography>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: 2,
                    width: "100%",
                  }}
                >
                  {positions.map(({ value, label }) => (
                    <Button
                      key={value}
                      variant={position === value ? "save" : "print"}
                      onClick={() => setPosition(value)}
                      sx={{ height: 45 }}
                      color={position === value ? "primary" : "inherit"}
                      startIcon={
                        position === value ? <CheckCircleIcon /> : null
                      }
                    >
                      {label}
                    </Button>
                  ))}
                </Box>
              </Box>
            </Stack>

            <Box sx={{ mt: 6 }}>
              <Stack spacing={3} width="100%" alignItems="center">
                <Box width="100%" sx={{ textAlign: "center" }}>
                  <Typography
                    variant="subtitle1"
                    fontWeight={600}
                    sx={{ mb: 3 }}
                  >
                    Välj stil:
                  </Typography>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "repeat(2, 1fr)",
                      gap: 2,
                      width: "100%",
                    }}
                  >
                    {debateStyles.map(({ value, label }) => (
                      <Button
                        key={value}
                        variant={debateStyle === value ? "save" : "print"}
                        onClick={() => setDebateStyle(value)}
                        sx={{ height: 45 }}
                        color={debateStyle === value ? "primary" : "inherit"}
                        startIcon={
                          debateStyle === value ? <CheckCircleIcon /> : null
                        }
                      >
                        {label}
                      </Button>
                    ))}
                  </Box>
                </Box>
              </Stack>
            </Box>

            <Box sx={{ mt: 6 }}>
              <Stack spacing={3} width="100%" alignItems="center">
                <Box width="100%" sx={{ textAlign: "center" }}>
                  <Typography
                    variant="subtitle1"
                    fontWeight={600}
                    sx={{ mb: 3 }}
                  >
                    Välj komplexitetsnivå:
                  </Typography>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "repeat(2, 1fr)",
                      gap: 2,
                      width: "100%",
                    }}
                  >
                    {complexityLevels.map(({ value, label }) => (
                      <Button
                        key={value}
                        variant={complexity === value ? "save" : "print"}
                        onClick={() => setComplexity(value)}
                        sx={{ height: 45 }}
                        color={complexity === value ? "primary" : "inherit"}
                        startIcon={
                          complexity === value ? <CheckCircleIcon /> : null
                        }
                      >
                        {label}
                      </Button>
                    ))}
                  </Box>
                </Box>
              </Stack>
            </Box>
          </Box>
        )}
      </DialogContent>

      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          onClick={handleSubmit}
          variant="purp"
          disabled={!topic.trim()}
          sx={{ width: 200, height: 45 }}
        >
          Starta debatt <ArrowIcon sx={{ ml: 1 }} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DebateDialog;
