import {
  Science as ScienceIcon,
  Castle as CastleIcon,
  Psychology as PsychologyIcon,
  Pets as PetsIcon,
  Public as PublicIcon,
  Brush as BrushIcon,
  Calculate as CalculateIcon,
  Language as LanguageIcon,
  EmojiObjects as EmojiObjectsIcon,
  LocalFlorist as LocalFloristIcon,
  WbSunny as WbSunnyIcon,
  Workspaces as WorkspacesIcon,
  Agriculture as AgricultureIcon,
  MonitorHeart as MonitorHeartIcon,
  Park as ParkIcon,
  Biotech as BiotechIcon,
  MenuBook as MenuBookIcon,
  EmojiEvents as EmojiEventsIcon,
  Museum as MuseumIcon,
  Nature as NatureIcon,
} from "@mui/icons-material";

// Default themes that show when no subject is selected
export const defaultThemes = [
  {
    type: "subheader",
    label: "Teman som lämpar sig extra bra för denna lektionstyp:",
  },
  {
    value: "selma",
    label: "Selma Lagerlöf",
    content: "Gör en lektion om selma lagerlöf",
    Icon: MenuBookIcon,
  },
  {
    value: "dronten",
    label: "Dronten",
    content: "Gör en lektion om den utdöda arten dronten",
    Icon: PetsIcon,
  },
  {
    value: "vikingar",
    label: "Vikingar",
    content: "Gör en lektion om vikingatiden",
    Icon: CastleIcon,
  },
  {
    value: "rymden",
    label: "Rymden",
    content: "Gör en lektion om vårt solsystem",
    Icon: PublicIcon,
  },
  {
    value: "dinosaurier",
    label: "Dinosaurier",
    content: "Gör en lektion om olika dinosaurier",
    Icon: NatureIcon,
  },
  {
    value: "nobel",
    label: "Nobelpriset",
    content: "Gör en lektion om nobelpriset och Alfred Nobel",
    Icon: EmojiEventsIcon,
  },
  {
    value: "uppfinnare",
    label: "Svenska uppfinnare",
    content: "Gör en lektion om kända svenska uppfinnare",
    Icon: ScienceIcon,
  },
  {
    value: "psychology",
    label: "Känslor",
    content: "Gör en lektion om olika känslor",
    Icon: PsychologyIcon,
  },
  {
    value: "konst",
    label: "Svensk konst",
    content: "Gör en lektion om svensk konsthistoria",
    Icon: MuseumIcon,
  },
];

// Subject-specific themes
export const subjectThemes = {
  biologi: [
    {
      type: "subheader",
      label: "Biologiteman som lämpar sig extra bra för denna lektionstyp:",
    },
    {
      value: "cellbiologi",
      label: "Cellbiologi",
      content: "Gör en lektion om cellens olika delar och funktioner",
      Icon: BiotechIcon,
    },
    {
      value: "fotosyntesen",
      label: "Fotosyntesen",
      content: "Gör en lektion om hur fotosyntesen fungerar",
      Icon: WbSunnyIcon,
    },

    {
      value: "evolution",
      label: "Evolution",
      content: "Gör en lektion om evolutionens mekanismer",
      Icon: WorkspacesIcon,
    },
  ],

  historia: [
    {
      type: "subheader",
      label: "Historieteman som lämpar sig extra bra för denna lektionstyp:",
    },
    {
      value: "industriella-revolutionen",
      label: "Industriella revolutionen",
      content: "Gör en lektion om den industriella revolutionen",
      Icon: CastleIcon,
    },
    {
      value: "franska-revolutionen",
      label: "Franska revolutionen",
      content: "Gör en lektion om franska revolutionen och dess orsaker",
      Icon: CastleIcon,
    },
    {
      value: "vikingatiden",
      label: "Vikingatiden",
      content: "Gör en lektion om vikingatiden och dess samhälle",
      Icon: CastleIcon,
    },
  ],

  fysik: [
    {
      type: "subheader",
      label: "Fysikteman som lämpar sig extra bra för denna lektionstyp:",
    },
    {
      value: "elektricitet",
      label: "Elektricitet",
      content: "Gör en lektion om elektriska kretsar och komponenter",
      Icon: EmojiObjectsIcon,
    },
    {
      value: "magnetism",
      label: "Magnetism",
      content: "Gör en lektion om magnetfält och magnetiska material",
      Icon: ScienceIcon,
    },
    {
      value: "optik",
      label: "Optik",
      content: "Gör en lektion om ljusets egenskaper och optiska instrument",
      Icon: ScienceIcon,
    },
  ],

  kemi: [
    {
      type: "subheader",
      label: "Kemiteman som lämpar sig extra bra för denna lektionstyp:",
    },
    {
      value: "periodiska-systemet",
      label: "Periodiska systemet",
      content: "Gör en lektion om grundämnenas periodiska system",
      Icon: ScienceIcon,
    },
    {
      value: "kemiska-bindningar",
      label: "Kemiska bindningar",
      content: "Gör en lektion om olika typer av kemiska bindningar",
      Icon: WorkspacesIcon,
    },
    {
      value: "syror-baser",
      label: "Syror och baser",
      content: "Gör en lektion om syror, baser och pH-skalan",
      Icon: ScienceIcon,
    },
  ],

  matematik: [
    {
      type: "subheader",
      label: "Matematikteman som lämpar sig extra bra för denna lektionstyp:",
    },
    {
      value: "pythagoras",
      label: "Pythagoras sats",
      content: "Gör en lektion om Pythagoras sats och dess tillämpningar",
      Icon: CalculateIcon,
    },
    {
      value: "matematikens-historia",
      label: "Matematikens historia",
      content: "Gör en lektion om viktiga matematiker genom historien",
      Icon: CalculateIcon,
    },
    {
      value: "geometriska-former",
      label: "Geometriska former",
      content:
        "Gör en lektion om olika geometriska former och deras egenskaper",
      Icon: CalculateIcon,
    },
  ],

  svenska: [
    {
      type: "subheader",
      label: "Svenskateman som lämpar sig extra bra för denna lektionstyp:",
    },
    {
      value: "svenska-forfattare",
      label: "Svenska författare",
      content: "Gör en lektion om viktiga svenska författare",
      Icon: LanguageIcon,
    },
    {
      value: "sprakhistoria",
      label: "Språkhistoria",
      content: "Gör en lektion om det svenska språkets utveckling",
      Icon: LanguageIcon,
    },
    {
      value: "litteraturepoker",
      label: "Litteraturepoker",
      content: "Gör en lektion om olika litterära epoker",
      Icon: LanguageIcon,
    },
  ],
};

// Helper function to get appropriate themes based on subject
export const getThemes = (subject) => {
  if (!subject) return defaultThemes;
  return subjectThemes[subject] || defaultThemes;
};

export default getThemes;
