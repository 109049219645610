import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Menu,
  MenuItem,
  Divider,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import SchoolIcon from "@mui/icons-material/School";
import { DashedPaper } from "../StyledComponents";
import GenerateIcon from "@mui/icons-material/AutoAwesome";
import { useTheme } from "@mui/material/styles";
import { getStyles } from "../index";

const DashedPapers = ({
  handleQuizPaperClick,
  handleComprehensionPaperClick,
  handleTimelinePaperClick,
  handleTimelineSimplePaperClick,
  quizSectionRef,
  exercisesSectionRef,
  timelineSectionRef,
  timelineSimpleSectionRef,
  lessonData,
  setLessonData,
}) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [exerciseMenuAnchorEl, setExerciseMenuAnchorEl] = useState(null);

  const [exerciseHover, setExerciseHover] = useState(false);
  const [quizHover, setQuizHover] = useState(false);
  const [timelineHover, setTimelineHover] = useState(false);
  const [timelineSimpleHover, setTimelineSimpleHover] = useState(false);

  const theme = useTheme();
  const styles = getStyles(theme);
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    setExerciseMenuAnchorEl(null);
  };

  const handleQuizTypeSelect = (type) => {
    switch (type) {
      case "boolean":
        setLessonData((prevData) => ({
          ...prevData,
          quiz: [
            ...(prevData.quiz || []),
            {
              question:
                "Ledde kolonialismen till ekonomisk exploatering av de koloniserade länderna?",
              hints: [
                "Tänk på hur resurser som guld och kryddor togs från kolonierna till moderländerna.",
                "Överväg hur lokal arbetskraft ofta användes utan rättvis kompensation.",
                "Det finns många exempel på hur koloniala ekonomiska strukturer fortfarande påverkar dagens länders ekonomi.",
              ],
              correct: "Sant",
              options: ["Sant", "Falskt"],
            },
            {
              hints: [
                "Reflektera över hur många inhemska traditioner och språk förlorades under kolonialt styre.",
                "Tänk på de kulturella konflikter som uppstod mellan inhemska och koloniala värderingar.",
                "Många samhällen kämpar fortfarande med identitetsfrågor som en följd av kolonialismen.",
              ],
              question:
                "Kulturell påverkan från kolonialismen var alltid positiv för de koloniserade folken.",
              correct: "Falskt",
              options: ["Sant", "Falskt"],
            },
          ],
        }));
        break;

      case "multiple-choice":
        setLessonData((prevData) => ({
          ...prevData,
          quiz: [
            ...(prevData.quiz || []),
            {
              options: ["20", "15", "25", "30"],
              revealedHints: [],
              isCorrect: false,
              question:
                "En lastbil har 4 hjul. Hur många hjul har 5 lastbilar totalt?",
              correct: "20",
              selectedOption: "30",
              hints: [
                "Tänk på hur många hjul en lastbil har och multiplicera det med antalet lastbilar.",
                "Om varje lastbil har lika många hjul, hur många hjul har du efter att ha räknat alla?",
                "Det är som att räkna antalet däck på en lastbilspark.",
              ],
            },
            {
              revealedHints: [],
              hints: [
                "Tänk på hur många lådor som lastas och deras vikt.",
                "Det handlar om att addera vikten av alla lådor.",
                "Om lastbilen är fylld till max, hur mycket kan den då bära?",
              ],
              options: ["800 kg", "1200 kg", "1000 kg", "600 kg"],
              isCorrect: true,
              question:
                "En lastbil kan lasta upp till 1200 kg. Om en lastbil är fylld med 3 lådor som väger 400 kg var, hur mycket vikt har den lastat totalt?",
              correct: "1200 kg",
              selectedOption: "1200 kg",
            },
          ],
        }));
        break;

      case "fill-in":
        setLessonData((prevData) => ({
          ...prevData,
          quiz: [
            ...(prevData.quiz || []),
            {
              correct: "y",
              hints: [
                "Denna koordinat representerar höjden på punkten i cirkeln.",
                "Sinus är direkt relaterat till den vertikala positionen på cirkeln.",
                "Tänk på hur värdena varierar när vinkeln ökar från 0 till 90 grader.",
              ],
              options: ["x", "y", "z", "r"],
              question:
                "I en enhetscirkel, är sinusvärdet av en vinkel _ lika med y-koordinaten på motsvarande punkt.",
            },
            {
              question:
                "En trigonometrisk identitet som beskriver förhållandet mellan sinus och cosinus är _.",
              options: [
                "Pythagoreiska identiteten",
                "Euler's identitet",
                "Tangens identiteten",
                "Koefficientidentiteten",
              ],
              hints: [
                "Denna identitet kan skrivas som en ekvation med tre termer.",
                "Den beskriver förhållandet mellan hypotenusan och kateterna i en rätvinklig triangel.",
                "Tänk på den berömda satsen som involverar a² + b² = c².",
              ],
              correct: "Pythagoreiska identiteten",
            },
          ],
        }));
        break;

      case "exam":
        setLessonData((prevData) => ({
          ...prevData,
          quiz: [
            ...(prevData.quiz || []),
            {
              question:
                "Beskriv de olika samhällsklasserna under medeltiden och deras roller i det feodala systemet.",
              answer:
                "Samhällsklasserna under medeltiden bestod främst av kungar, adelsmän, riddare, bönder och präster. Kungen var den högsta makten, adeln ägde mark och hade makt, riddarna skyddade landet och bönderna arbetade på jorden.",
              type: "exam",
              hints: [
                "Tänk på hur makt och resurser var fördelade i samhället.",
                "Fundera på vilken roll varje klass spelade i det dagliga livet och i krig.",
                "Reflektera över hur religion och ekonomi påverkade samhällsstrukturen.",
              ],
            },
            {
              type: "exam",
              hints: [
                "Tänk på kyrkans ekonomiska resurser och dess påverkan på feodalherrarna.",
                "Fundera på hur kyrkan kunde påverka lagar och normer i samhället.",
                "Reflektera över hur religiösa ritualer och tro formade människors liv och samhällsstrukturen.",
              ],
              answer:
                "Kyrkan hade en central roll i det medeltida samhället, både som religiös institution och som en politisk makt. Den ägde stora landområden och kunde utöva inflytande över både kungar och bönder.",
              question:
                "Vilken roll spelade kyrkan i det medeltida samhället och hur påverkade den feodalismen?",
            },
          ],
        }));
        break;
    }
    handleMenuClose();
    setTimeout(
      () =>
        quizSectionRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        }),
      100,
    );
  };

  const handleExerciseTypeSelect = (type) => {
    switch (type) {
      case "on-the-lines":
        setLessonData((prevData) => ({
          ...prevData,
          exercises: [
            ...(prevData.exercises || []),
            {
              type: "on-the-lines",
              title: "På raderna (svaret finns i texten)",
              content: [
                "När sjönk färjan Estonia?",
                "Hur många människor miste livet i katastrofen?",
                "Vad säger Lars Lindgren om händelsen?",
              ],
            },
          ],
        }));
        break;

      case "between-the-lines":
        setLessonData((prevData) => ({
          ...prevData,
          exercises: [
            ...(prevData.exercises || []),
            {
              type: "between-the-lines",
              title: "Mellan raderna (dra slutsatser från texten)",
              content: [
                "Vilken betydelse har minnesceremonierna för de drabbade familjerna?",
                "Hur kan förändringar i säkerhetsprotokoll påverka framtida sjöresor?",
                "Varför är det viktigt att den yngre generationen känner till Estonia-katastrofen?",
              ],
            },
          ],
        }));
        break;

      case "beyond-the-lines":
        setLessonData((prevData) => ({
          ...prevData,
          exercises: [
            ...(prevData.exercises || []),
            {
              type: "beyond-the-lines",
              title: "Bortom raderna (egna tankar och analys)",
              content: [
                "Vilka känslor väcker berättelserna från överlevande hos dig?",
                "Hur kan samhället bäst stödja de som drabbats av trauma efter en katastrof?",
                "Vad tror du att vi kan lära oss av denna tragiska händelse för framtiden?",
              ],
            },
          ],
        }));
        break;

      case "true-false":
        setLessonData((prevData) => ({
          ...prevData,
          exercises: [
            ...(prevData.exercises || []),
            {
              type: "true-false",
              title: "Sant eller falskt?",
              content: [
                "Estonia sjönk på grund av en storm.",
                "Det har hållits minnesceremonier varje år sedan katastrofen.",
                "Kalle Björk är en överlevande från Estonia-katastrofen. ",
                "Det har införts striktare säkerhetsregler efter händelsen. ",
                "Färjan Estonia sjönk i oktober 1994. ",
              ],
            },
          ],
        }));
        break;

      case "multiple-choice":
        setLessonData((prevData) => ({
          ...prevData,
          exercises: [
            ...(prevData.exercises || []),
            {
              type: "multiple-choice",
              title: "Flervalsfrågor",
              content: [
                {
                  question: "Vilket år sjönk Estonia?",
                  options: ["1992", "1994", "1996", "2000"],
                },
                {
                  question: "Hur många personer överlevde katastrofen?",
                  options: ["50", "100", "137", "852"],
                },
                {
                  question: "Vad har förändrats efter katastrofen?",
                  options: [
                    "Inga förändringar",
                    "Striktare sjösäkerhetsregler",
                    "Färjan Estonia återinvigdes",
                    "Ingen minnesceremoni",
                  ],
                },
                {
                  question: "Vem är Martin Johansson?",
                  options: [
                    "En överlevande",
                    "En sjösäkerhetsexpert",
                    "En journalist",
                    "En politiker",
                  ],
                },
              ],
            },
          ],
        }));
        break;

      case "open-ended":
        setLessonData((prevData) => ({
          ...prevData,
          exercises: [
            ...(prevData.exercises || []),
            {
              type: "open-ended",
              title: "Diskussionsfrågor",
              content: [
                "Diskutera hur minnen av katastrofer kan påverka en nations identitet.",
                "Vilka åtgärder anser du är viktigast för att säkerställa sjösäkerhet?",
                "Hur kan utbildning om historiska händelser som Estonia-katastrofen bidra till att förhindra framtida tragedier?",
              ],
            },
          ],
        }));
        break;
    }
    handleMenuClose();
    setTimeout(
      () =>
        exercisesSectionRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        }),
      100,
    );
  };

  const handleTimelinesExampleClick = () => {
    setLessonData((prevData) => ({
      ...prevData,
      timelines: [
        ...(prevData.timelines || []),
        {
          description: "Ordna händelserna i vattnets kretslopp i rätt ordning.",
          title: "Ordna händelserna i vattnets kretslopp i rätt ordning",

          timeline: [
            {
              hint: "Detta steg är slutpunkten för vattnets resa på ytan.",
              event: "Vattnet når havet",
              imageUrl:
                "https://storage.googleapis.com/studeraai.appspot.com/generated-images/Z6BLSMsm38Un5mgW0O6Z3TToHq12/1735924680465.png?GoogleAccessId=firebase-adminsdk-b1cmg%40studeraai.iam.gserviceaccount.com&Expires=16730319600&Signature=TFhTdwAakQYQfCel68qAu63OQcGRhMe39qAmzWvjNGu9fEdDiZNm7pJz3ubNoF8OofuLqmvBdwvKae2RoiU%2BtH503wQYjPHnOlXDmC5ivyd3GuDb3xBm7RJMgB2LelPXC8dEUi6CSBEblLf8wIOJncmyaCJ9ICsIiDepba%2B36W9Oaqp04skRwWMI9HbtiIfApKaiJmQv9aYTNUnS%2FfRF2dFBNdnzXiZpl8hOHBKsViskeP1qoc49YKjTjeMNS8vNBhVBQ%2FzeA7Y9jFHlYGQuB8Gw5qPD26voLy9iKAMyiWT1R%2BAwDudfsATBKKQBew7HSWhoUYd1edW30gUCYcOUCw%3D%3D",
              year: "6",
            },
            {
              hint: "Detta steg innebär att vatten faller tillbaka till jorden.",
              imageUrl:
                "https://storage.googleapis.com/studeraai.appspot.com/generated-images/Z6BLSMsm38Un5mgW0O6Z3TToHq12/1735924666862.png?GoogleAccessId=firebase-adminsdk-b1cmg%40studeraai.iam.gserviceaccount.com&Expires=16730319600&Signature=EICcPVjbp49hTdGCFUAzCZ9mZSfv0j7kpveAqQ3dYl9hbus8FgOiiaxtlSU5Ib%2BRX5wAz8DDlXHUORaAqG%2BznsqV7qCOgL2ZZe3sqr9aXml1oCLrIuLsqWtF82ADLnir8uVtKE4gjKdCe3amVpTQFj5qhDoj0PYGOimNm3yx%2FNJk4SA5sMfjhPUwbCoaMm05w5N3%2FLn2uqm1BBijyfEP%2BuYR8aI%2FDmCKGfSgWjksWqUP4Qu4qpLCUiQYXQPFIs62fu4JCVxHcZT2YG296kISOUPYI7F44Mi4KUCCB5ZzydO%2FfW2LHTQ6WHRBs6B2eB68ElNMMeXtCdJSl1u5hk6NEQ%3D%3D",
              event: "Nederbörd i form av regn eller snö",
              year: "3",
            },
            {
              hint: "Detta steg börjar med värme från solen.",
              event: "Avdunstning från hav och sjöar",
              year: "1",
              imageUrl:
                "https://storage.googleapis.com/studeraai.appspot.com/generated-images/Z6BLSMsm38Un5mgW0O6Z3TToHq12/1735924671170.png?GoogleAccessId=firebase-adminsdk-b1cmg%40studeraai.iam.gserviceaccount.com&Expires=16730319600&Signature=W%2BTdSU4JOYJH%2F%2Fhabt2qe6JFPsXkezyA9oHfeZPRL9auOfID92K70Jod3VtxEyMTx7SKgYecOiL%2FCYPJe9RYvdQsj%2FhnZwdTY391bopkRj%2F%2FtQw9TdcJkLgEAjYFA81E2pjojRAoWzKjsKgdvdkDHc3chugRxrTyo%2BXurL%2BeZNCk0sWRnxzKJermOYD2D3OTOuwprgt6%2FoJQOlEEC6BAKYM69wzn0ji2WSo1kr6FMy06C5EtiZup0%2BwfJvYzAjMKRuQ%2FWMye6CgEauh7RlbAvE%2B3DvpbxK%2FUqZuz1dOTd71AGQ3p%2BFd%2FQi2ZAqBg2BeLA%2FrZiw21KZtESTbOPUJCHA%3D%3D",
            },
            {
              hint: "Här börjar vattnet återvända till stora vattensamlingar.",
              event: "Vattnet samlas i floder och sjöar",
              imageUrl:
                "https://storage.googleapis.com/studeraai.appspot.com/generated-images/Z6BLSMsm38Un5mgW0O6Z3TToHq12/1735924666355.png?GoogleAccessId=firebase-adminsdk-b1cmg%40studeraai.iam.gserviceaccount.com&Expires=16730319600&Signature=koKHxpHAU0AInntis1kKo2%2BfkTA0PmvbQu4LXwL3je4ZPiUY4HN1EJ78s3vICte8xE8hUQwe87DkVwpD5yufrxI%2FhM9qY1BeytcgAplbEq2GPqb8ErgC2wcNDTMTjSqsfOmkampS65v1s%2BSyjQrgVT2yoaqkx%2Fx20nYLFHgBcYF5WI03emgrPbmfiEGdyBTjEZSHkCMOhFJE6eKXCJx1j5Zn4czz6s5Rlcjoli9JxGeR4M5x1yve2aAihOqXo2x4zKBzbZcjzoHRjkGrplBxGX7iW2OxsfihBzHU8vkkfcT9bflnpMOpv9x5Gs2dseAj8z7XANnpmRZU1%2B9cfPEuvw%3D%3D",
              year: "5",
            },
            {
              hint: "Molnen bildas när vattenånga kyls och kondenserar.",
              year: "2",
              imageUrl:
                "https://storage.googleapis.com/studeraai.appspot.com/generated-images/Z6BLSMsm38Un5mgW0O6Z3TToHq12/1735924669282.png?GoogleAccessId=firebase-adminsdk-b1cmg%40studeraai.iam.gserviceaccount.com&Expires=16730319600&Signature=AKEBE9DMyXe%2BkY0oYJSYFedsoneygYOAAKrqP6bP1g9jcxUvYWlq69AtlVwcVUtiTj%2BiXxpuMI8Xt%2FGX11MKyh6fNEHqT%2Femz0KRPGnWeuMayP9XEyWi1ZR6qjSN9zmKaPhdezoSDi5CyvkM3mW76lvRoLehXnA8bABoN3tVaS4qhoVgRaSeeVOuh0X4frItaCOl1H0r6yFDEDzBaMwM%2Fx1u%2BGfeZeQBCpwZOSd8fezyJjAK%2BbWAdhtkRNUCJjeufrBM5GnSiNCZiFjU31Sb6o%2FoxtDN45jZpR25JtMOt5kRS3EWQkM6L9bKIWqmRR2K%2F1mbWvFdXCVAGmPg0oU6xw%3D%3D",
              event: "Bildande av moln",
            },
            {
              hint: "Detta steg innebär att vatten faller tillbaka till jorden.",
              imageUrl:
                "https://storage.googleapis.com/studeraai.appspot.com/generated-images/Z6BLSMsm38Un5mgW0O6Z3TToHq12/1735924666862.png?GoogleAccessId=firebase-adminsdk-b1cmg%40studeraai.iam.gserviceaccount.com&Expires=16730319600&Signature=EICcPVjbp49hTdGCFUAzCZ9mZSfv0j7kpveAqQ3dYl9hbus8FgOiiaxtlSU5Ib%2BRX5wAz8DDlXHUORaAqG%2BznsqV7qCOgL2ZZe3sqr9aXml1oCLrIuLsqWtF82ADLnir8uVtKE4gjKdCe3amVpTQFj5qhDoj0PYGOimNm3yx%2FNJk4SA5sMfjhPUwbCoaMm05w5N3%2FLn2uqm1BBijyfEP%2BuYR8aI%2FDmCKGfSgWjksWqUP4Qu4qpLCUiQYXQPFIs62fu4JCVxHcZT2YG296kISOUPYI7F44Mi4KUCCB5ZzydO%2FfW2LHTQ6WHRBs6B2eB68ElNMMeXtCdJSl1u5hk6NEQ%3D%3D",
              event: "Nederbörd i form av regn eller snö",
              year: "3",
            },
            {
              event: "Vatten som rinner ner i marken",
              year: "4",
              hint: "Detta steg hjälper till att fylla på grundvattnet.",
              imageUrl:
                "https://storage.googleapis.com/studeraai.appspot.com/generated-images/Z6BLSMsm38Un5mgW0O6Z3TToHq12/1735924667304.png?GoogleAccessId=firebase-adminsdk-b1cmg%40studeraai.iam.gserviceaccount.com&Expires=16730319600&Signature=T6iDGYP%2Bp4ouqf1hxQyafJWjGV4Wz25asbg%2B3U20ZFpd25oWYVk9rsI7vi6FHlW88MGMYCOx6dnecVZCs2lTgG2lJiSIIQkouTGX5QnXIEDeHVNphlqc14z7Kh6wYAy8wm3PyF7dwv%2F2AA5WzNcPyODWqt2ZOZFvyFNksY6w%2FlcAoccezpxLwzrKUWjMkl4xBPvMOu4JnShMpVi3imHrLVpAkBwgxar2e9jPpnZ93HL%2FI96xBEWltP54V8VCuWrd6nZMKgww%2BWS2X%2BCYdGfY4BWHeHtdSUZPtd36yeh4AzF%2FXVaso6A6UQH3xFizgCBKkT%2BEVeFOxU7zHmuNLmCxRQ%3D%3D",
            },

            {
              imageUrl:
                "https://storage.googleapis.com/studeraai.appspot.com/generated-images/Z6BLSMsm38Un5mgW0O6Z3TToHq12/1735924668942.png?GoogleAccessId=firebase-adminsdk-b1cmg%40studeraai.iam.gserviceaccount.com&Expires=16730319600&Signature=ciOsX62jqxZMOnkKEzMQjAriPS7HzXelVBZmLeGzUDAIVbR2tW4Q%2BK97w5UWO0BL7fATp6V%2Fmi8B%2FCYE3dbYMyKKEeKHHsyAGMQMKbOnna5Tr0HIrmeNyAAl5pWb4GyNgQaWIWvAOfkf39NxXlijOurU23FfWxOPZqDjuxBFb6S7zrJjgJcrLqt1hzzyZjEwOb2%2FMqwWdU3LytoGgJGMeR%2F2hbdNtbjBTYfMKWdihxsANsNYwfKgTVZ68LjDB0jPSnXa3DnBpX%2BrSa7rC8vMp%2BwCwo5Umrmy0OHW5B0hJn%2FFVJElR9UfiT39VCJ%2Bh3GcCu%2FfOUpzS%2FiEqGDstikPNA%3D%3D",
              hint: "Processen startar igen efter att vattnet har återvänt till havet.",
              event: "Cykeln börjar om med avdunstning",
              year: "7",
            },
          ],
        },
      ],
    }));
  };

  const handleTimelinesSimpleExampleClick = () => {
    setLessonData((prevData) => ({
      ...prevData,
      timelinesSimple: [
        ...(prevData.timelines || []),
        {
          title: "Jämställdhetens historia i Sverige",
          description:
            "En tidslinje som visar viktiga milstolpar i Sveriges historia av jämställdhet, från tidigt 1800-tal till nutid, inklusive rösträtt, utbildning och arbetsrättsliga reformer.",
          timeline: [
            {
              event:
                "Kvinnor börjar få tillgång till utbildning, vilket lägger grunden för framtida jämställdhetsrörelser.",
              year: 1800,
              imageUrl:
                "https://storage.googleapis.com/studeraai.appspot.com/generated-images/Z6BLSMsm38Un5mgW0O6Z3TToHq12/1734545809027.png?GoogleAccessId=firebase-adminsdk-b1cmg%40studeraai.iam.gserviceaccount.com&Expires=16730319600&Signature=MZw7hDwTWv5fJlsaCzwiUiPfhoMGTXalKqo0WeYQefwAv6cpYGEXf38T3AzXP1UowcXwQiWTssWWi%2FV9bPyF4MI9UmnUXUW8tRrFEZYaMlhYAKFPj%2BMQAQFFL%2FQp8DJ4hpU4N7BxZt9mPTcsmx7I%2FuSrU6IlN8WLx6R19iGilwxzEU%2BQSv9vFgkzNuKHkfVv%2Fz%2BTlAgB8NWaAvVtG%2BShGWJ%2F7JrO5WeKGPujvRarn9xCzmW0EyipI1dqH7TkqpI226mnaoypQ6XAr8w8CkMbtNTZxzWdRd7eFF3oJ0V8dPU51BNBL11hC0r%2FgMSkWmuSsdAL7pv958JGTVDIQx%2FGGA%3D%3D",
            },
            {
              event:
                "Lag om föräldraledighet införs som ger både mödrar och fäder rätt till föräldraledighet.",
              year: 1980,
              imageUrl:
                "https://storage.googleapis.com/studeraai.appspot.com/generated-images/Z6BLSMsm38Un5mgW0O6Z3TToHq12/1734545821731.png?GoogleAccessId=firebase-adminsdk-b1cmg%40studeraai.iam.gserviceaccount.com&Expires=16730319600&Signature=Jdk3Fp90AuzRMJFv49vzYCb4wDTFsLZc%2B5XLj5rLsExz4ct5k6gy4bSJn%2Fd4xJP%2FzUynIrTYZrhhuTZ1yOv9u%2FWkONWan7YNyUDy4UdT67qsDgctnK1mPd3sq8wJ9cL8lHEo091Me8qtsj0dk9ZFnOVMAXxNloWFhXe5qx%2FjPT1buh95IWe5%2B7IWpFlOeqGaLajifrm5%2Bx%2B9Uz7eZzoIZuUDqSJUUa0XnFJNwvggdllA%2Bq75x5jCEOPCLh4FLGMgxEAs5e7iKYXX3jjEAKxwDxTLbv0W6dVT8I5glqcbkeYJDzp5nV4LcNqNsCAYzTcKgWjHoi5tiVi01xgA68uprw%3D%3D",
            },

            {
              event: "De första kvinnorna antas till universitet i Sverige.",
              year: 1864,
              imageUrl:
                "https://storage.googleapis.com/studeraai.appspot.com/generated-images/Z6BLSMsm38Un5mgW0O6Z3TToHq12/1734545807082.png?GoogleAccessId=firebase-adminsdk-b1cmg%40studeraai.iam.gserviceaccount.com&Expires=16730319600&Signature=L7Y0OiDWTcj5lvqHG3U29jn1roB7z7zkxfvzX2kYnKrCypzamIU1vFaAS9GHBPg%2BIT3GNVYhkt0rRsgrX1N%2Bw6XVoukVoMUf6c6PAU7LjVsPwqLxHEmX%2B%2BhozxQMseNaaV7%2FRpRIvcDoQ7leAp6BEQVvzR64epm9ezyEhUwT7aZ5NqHzaj1lKrgskHGBlZPEZNHmgQoPeBhNTj2vlR3f5RWgMPdCX9in%2FM3BUIz0ztcYQ6mS1cRPLhqsm7c7EfWZeR99AmXDxu0wSwFv9uXA1M0Zqpo0G2R0Ko%2FhTRC9Vv0XBYcmVoWz%2BZeuJlPVurEhPVeUwd9UoFGSGr%2FZXVz6YA%3D%3D",
            },
            {
              event:
                "Kvinnornas rösträttsförbund bildas för att kämpa för kvinnors rösträtt.",
              year: 1901,
              imageUrl:
                "https://storage.googleapis.com/studeraai.appspot.com/generated-images/Z6BLSMsm38Un5mgW0O6Z3TToHq12/1734545813794.png?GoogleAccessId=firebase-adminsdk-b1cmg%40studeraai.iam.gserviceaccount.com&Expires=16730319600&Signature=PFAJK%2B6c2bBVA4KvZQJxMHr%2BVdEdiREJQ2EpJWFIISJGfXD1rv5PzwfEWl9y6Wg0LrLIde3WElpX259yfrb0KPz2QBqzEUlntbo2DpcIlRyKG7odlatDJpFtZ6ZcFgZcPFdXPteq%2B3RMJGvgFaqLC%2BDJ%2B599QeaIgVo2SLgPo18IpWibvvbTNELAYK8q0f31lGT45OdmDVowvRhQaHwdVUAr5M1EksfDitDfPtYvs40tcUeLP%2FfWkJV0GvhjKxAqpP1F8Qb7APOIKb%2FBL9JRVXzOj4gnx4TPyVT2dCzkBSaKmucRrapKRY%2Fsp%2BJsY60AO%2F6NZCOeR1h8rgAFagul2g%3D%3D",
            },
            {
              event: "Kvinnor får fullständig rösträtt i Sverige.",
              year: 1919,
              imageUrl:
                "https://storage.googleapis.com/studeraai.appspot.com/generated-images/Z6BLSMsm38Un5mgW0O6Z3TToHq12/1734545812668.png?GoogleAccessId=firebase-adminsdk-b1cmg%40studeraai.iam.gserviceaccount.com&Expires=16730319600&Signature=EHxQgXl%2FSvFsKtWQUmXLVmQTcyeywHUi6kcfsaze9l7JnYCRgPhhZW7GDO4MdzIVSZJVGKeq3LJYtl5ueZHUNjIb%2BcEs5V%2FDzZQOAa8V%2BHbCSPUBsoHZqXFkOrLRdTDRpZR7c9J7URUoHmgbYY2he1JbmMMlTCV6XDW3ZUqRwzZ663SMfGpK9uI68UwC%2BZBSkhpyjtyKpR%2FOLmoAVyZrdajWMBtzKtfZE4YuB31SaNuuKx5Z5ddqPTJdXJhXUE8tRskLznKVMjZOwLjvCZL5Ontdfw%2B%2FOl2xeYTkvYpQW%2BIk4Q3NGrYOK8YNH4oHbDe3yvlLw36fro76xpSPpYaU1Q%3D%3D",
            },
            {
              event:
                "De första kvinnliga riksdagsledamöterna väljs in i Sveriges riksdag.",
              year: 1921,
              imageUrl:
                "https://storage.googleapis.com/studeraai.appspot.com/generated-images/Z6BLSMsm38Un5mgW0O6Z3TToHq12/1734545811573.png?GoogleAccessId=firebase-adminsdk-b1cmg%40studeraai.iam.gserviceaccount.com&Expires=16730319600&Signature=qaorPbTu%2B04uuANMIEnkicX1JhisNuS0sQlcGg53Sxf3Myc0U6XjDNAbTPXUg3cd0sFlpbmnhPhxw9MOsm2BWBblXpsb1e6gkvs25mnVBPz1FrBgHxTDrCqqo5b%2FMSGujzo1zB%2BJBSesp26OqaSbPEePboVN393dkTfkG5R5d%2FXQY53wf766MWf3vrtN8ZdoOs7vyK6NFEjvr%2FO%2FkkXynU9kbNfIFsl9x5cxt%2FoM4quRwah9ybfq%2B8K2OrFoVTfoeTc9bfgs66BkTJIm08obkYrGVMp205BGIMn4901AEYc2m9W%2F80o1MvfM7uYqBCok1a2m0r4bXttxWETnDUATkg%3D%3D",
            },
            {
              event: "Sverige inför lag om lika lön mellan kvinnor och män.",
              year: 1938,
              imageUrl:
                "https://storage.googleapis.com/studeraai.appspot.com/generated-images/Z6BLSMsm38Un5mgW0O6Z3TToHq12/1734545805247.png?GoogleAccessId=firebase-adminsdk-b1cmg%40studeraai.iam.gserviceaccount.com&Expires=16730319600&Signature=ILGQwbhbMp%2BuBos5lQM361LcLK5BQ0mnfiXfomlPmBplDtNhxrFJDy1gKAbck%2FN7Zw9oYBV%2BQykmMDt7v77DKDRKIHc9POzAtwuV%2BLiZdqyznP7xXOAGYkuX%2BMkD9o9m6u5sqZRMwdgwypxlJ96VdpYrttz2AIdb8GuCuf340T7HXBGUqv%2B5xljjlrsZQqhEQ0BS4mI3Uu5WynZVtpvL0NE6ClWCe3HnqLXBzlXWucavlrRuSa%2BchDsBJym50P8qlIQEFVBWExgyiYhv0NjfCWWLleZ5m3F4zHxKcmwCiZCtYNrA5YepbAgC3JdwV0D%2Bfw2Vx8Ub1tc2U%2Fw7eQpvYg%3D%3D",
            },
            {
              event:
                "Lagstiftning som säkerställer kvinnors rätt att arbeta införs.",
              year: 1944,
              imageUrl:
                "https://storage.googleapis.com/studeraai.appspot.com/generated-images/Z6BLSMsm38Un5mgW0O6Z3TToHq12/1734545805361.png?GoogleAccessId=firebase-adminsdk-b1cmg%40studeraai.iam.gserviceaccount.com&Expires=16730319600&Signature=MHAvh22uFp0%2BgUqU9z1rtV7%2FbmYh9AAAByFL87%2FNuxGmRCpxpuzF21FlUux9j7jMfoFZ4BvooGmzbP5MhvpqJ6V4%2BjFbKj1ShdIxWzfRdc0ElYftwwhMKphqqGGUVwmfYHJb2ORw1q%2FfvaZ2IYvKVVzw206dNvyXblHIYSraEKbnvgh3vNxYXlZcOC6inti3Vflrkh%2B6Lwx9wO3o088U1AdEpNCEG6bIGlAPa%2BVd3r94GohRS5pvEU%2BWiksxPnRxHbbngzM1g7%2B7hU%2Bxkx3b%2FEM9U6dt3Gc2rvR7ki1tuW9IFr96lf4Ytx6xm1LQLEFYovf6Hzk0ajCadk0V4ILUPQ%3D%3D",
            },
            {
              event: "En omfattande jämställdhetsutredning inleds.",
              year: 1965,
              imageUrl:
                "https://storage.googleapis.com/studeraai.appspot.com/generated-images/Z6BLSMsm38Un5mgW0O6Z3TToHq12/1734545818026.png?GoogleAccessId=firebase-adminsdk-b1cmg%40studeraai.iam.gserviceaccount.com&Expires=16730319600&Signature=Afiww17MIrGrfYsvyeKpg%2Bx2xrLRipzIWDRehfNZlBjrwtg%2B%2FZ5U4xtF3kgmtYZP7ygAJb%2FmPpMc7BJ%2FBk9Ead67y7cA3wE9ZSFvO6GVwrZXwXl89clfBosAl%2B2ul5byTAYXweF%2FvESOfSKsICmoNNL4z0xJreSbbB8cL7jmqUxFtuFOszLKGJ9%2FKMyVEZDe8cnHxFaaOXQnLWXFEcYzq6p99%2FeirF07jfN6ieBWl%2FkNfZ6fzV9WcTLVtr4mxAy5hRyQiOw0VVJVxBW12TWasXRtUQWtzrLoZrEbKsO%2B%2FV3%2B%2BaSV13Ud7zSdv30KJbsOB%2FGGC3FgleV2eAgqgI2%2FQQ%3D%3D",
            },
            {
              event: "Jämställdhetslagen",
              year: 1971,
              imageUrl:
                "https://storage.googleapis.com/studeraai.appspot.com/generated-images/Z6BLSMsm38Un5mgW0O6Z3TToHq12/1734545809027.png?GoogleAccessId=firebase-adminsdk-b1cmg%40studeraai.iam.gserviceaccount.com&Expires=16730319600&Signature=MZw7hDwTWv5fJlsaCzwiUiPfhoMGTXalKqo0WeYQefwAv6cpYGEXf38T3AzXP1UowcXwQiWTssWWi%2FV9bPyF4MI9UmnUXUW8tRrFEZYaMlhYAKFPj%2BMQAQFFL%2FQp8DJ4hpU4N7BxZt9mPTcsmx7I%2FuSrU6IlN8WLx6R19iGilwxzEU%2BQSv9vFgkzNuKHkfVv%2Fz%2BTlAgB8NWaAvVtG%2BShGWJ%2F7JrO5WeKGPujvRarn9xCzmW0EyipI1dqH7TkqpI226mnaoypQ6XAr8w8CkMbtNTZxzWdRd7eFF3oJ0V8dPU51BNBL11hC0r%2FgMSkWmuSsdAL7pv958JGTVDIQx%2FGGA%3D%3D",
            },

            {
              event: "Föräldraledighet införs",
              year: 1980,
              imageUrl:
                "https://storage.googleapis.com/studeraai.appspot.com/generated-images/Z6BLSMsm38Un5mgW0O6Z3TToHq12/1734545821731.png?GoogleAccessId=firebase-adminsdk-b1cmg%40studeraai.iam.gserviceaccount.com&Expires=16730319600&Signature=Jdk3Fp90AuzRMJFv49vzYCb4wDTFsLZc%2B5XLj5rLsExz4ct5k6gy4bSJn%2Fd4xJP%2FzUynIrTYZrhhuTZ1yOv9u%2FWkONWan7YNyUDy4UdT67qsDgctnK1mPd3sq8wJ9cL8lHEo091Me8qtsj0dk9ZFnOVMAXxNloWFhXe5qx%2FjPT1buh95IWe5%2B7IWpFlOeqGaLajifrm5%2Bx%2B9Uz7eZzoIZuUDqSJUUa0XnFJNwvggdllA%2Bq75x5jCEOPCLh4FLGMgxEAs5e7iKYXX3jjEAKxwDxTLbv0W6dVT8I5glqcbkeYJDzp5nV4LcNqNsCAYzTcKgWjHoi5tiVi01xgA68uprw%3D%3D",
            },

            {
              event:
                "Regeringen lanserade en handlingsplan för att öka andelen kvinnor i ledande positioner inom näringsliv och politik.",
              year: 1994,
              imageUrl:
                "https://storage.googleapis.com/studeraai.appspot.com/generated-images/Z6BLSMsm38Un5mgW0O6Z3TToHq12/1734545822798.png?GoogleAccessId=firebase-adminsdk-b1cmg%40studeraai.iam.gserviceaccount.com&Expires=16730319600&Signature=UCE7crdBiQvuRNKAm8qcMWyRdxnpTxjMJrrZIVSoMOQe2ewVkWYtErfDhpbZbUaKw9lu%2FhofAk3wqX4uyte61iRuwguB677irowbRyNEvoF0C9qt5XIaOZAqhEk%2FI6YXHS5BsWU6FpmRSN0bzLAPTFFKuHQ8tRzpPZGKAOiD3RG5%2B8%2FatzfzfnvNgJcQb16RteXGjtPqCAqTMAZASjiBJwBnLNFxzBcet%2BiZ6qU6VQsYoT1MHijFnDHiXlFqIcDvPYtsd99x9%2Bfsn2lAcoG03XxSWtR7N%2F16Vi7OJf%2BnHi5LXEFr3E%2FYbz%2FD1zpi4MKfmjNVl9q2uC0O7S%2BT135jdw%3D%3D",
            },

            {
              event: "Lag om våld i nära relationer",
              year: 2005,
              imageUrl:
                "https://storage.googleapis.com/studeraai.appspot.com/generated-images/Z6BLSMsm38Un5mgW0O6Z3TToHq12/1734545820170.png?GoogleAccessId=firebase-adminsdk-b1cmg%40studeraai.iam.gserviceaccount.com&Expires=16730319600&Signature=SQb9h1XxWpB98rngOzGxDBPIuNNeKcVecXtQpHwcWnrvJZuQCSJdu7dk521V8Rq%2FfSzWEao8LfgpfERtGE4pIbXLDdpuDWUXYrGEYNV%2BJH3GiscthDZUJLqVViyX5XnEIzlPsJyODaWEqmy8SvGlOKNRaYC17byhUVrwVjc4pws%2BkFolC%2F0kVBmwyy7hRxFi%2Bt%2Bnnw3%2F2NzTz876hNbdb3ZIkObfrNVJyK5iG%2BqjZWqt%2FhbFE1D9bLrHUKDkTViAYS%2B82lx6%2BumEP5j6d70rg0TiulEEFB0yRztwMtDa4bXPRiDM5%2BgoTHI33yV3aSazB%2BhzyJTMCq3LjQfMJW7Y5w%3D%3D",
            },

            {
              event: "Jämställdhetsstrategi",
              year: 2014,
              imageUrl:
                "https://storage.googleapis.com/studeraai.appspot.com/generated-images/Z6BLSMsm38Un5mgW0O6Z3TToHq12/1734545820080.png?GoogleAccessId=firebase-adminsdk-b1cmg%40studeraai.iam.gserviceaccount.com&Expires=16730319600&Signature=HQvodTS8QUpEG7q1U6d8q1kVn%2F4v73DmO1NiSoQY3V6Fa6pL04qJl7sICwAwJfFc5lFQMPrX9OqzJGNR0iTuNAWHQJLptZpxqgkJVjN6cNW7CmoH2Ik%2BzIcs1g5rR7l%2Be4R77VWZ0cuDHSnRLpux59EiEsZpx0QKmi%2FZxdH39QqriXlpR6dctSLkjl%2FxJhhnxWROmuLhK9dQ4SwJSTrFeiRAi82PH8LTFq8yg1XtEQWH21iER0sMNHA3YuD%2FXKV8GjgzPbEL8%2Fh9ZGFNjGrv5%2FYGFaTjyJ8kzKFqyxaQTjZY4E5Rb7rjKqzqJ5uCZt%2FoRaw%2Fb%2FhO%2FmKPbWyvIJo3kg%3D%3D",
            },

            {
              event: "Jämställdhetsmyndigheten inrättas",
              year: 2020,
              imageUrl:
                "https://storage.googleapis.com/studeraai.appspot.com/generated-images/Z6BLSMsm38Un5mgW0O6Z3TToHq12/1734545824036.png?GoogleAccessId=firebase-adminsdk-b1cmg%40studeraai.iam.gserviceaccount.com&Expires=16730319600&Signature=dDeHSBiWzsbjGjPOUwb4S37kyFb2EiSgwSKLMFBhuz6aefbk%2BI63rAHtnUWcA%2BIvV2L%2FCFk9OtSWqmkORTouRlTZzK54PPoRPPLiTrCy6oVaVjfuvQ17ifACvsCW98yo6bBTSsiYJ4jKVaZOJn220PEazGH5I%2BuEO98DG%2BoNxqfXwxEPnMokPcw6iWmbh5w6y0b3QGWKdVjq2QIWYEgLxUHThlvBK9lUHzGHuMXqQCjKwCkqhHaaPgYdBqJwkJqqC9M1KorYhxJtKvAOMCuJOD55%2BlciNw8PFcHWo3RooGo9vRF2gKIU3MW78f%2B9Us%2FD0XSE7nYK%2BfI%2Br4M%2FBunvgw%3D%3D",
            },
          ],
        },
      ],
    }));
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          gap: 4,
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <DashedPaper
          sx={{ mt: 4, flex: 1 }}
          onClick={(event) => {
            // If menu is open, don't trigger the paper click
            if (Boolean(exerciseMenuAnchorEl)) {
              return;
            }
            handleComprehensionPaperClick();
          }}
        >
          <Box sx={{ textAlign: "center", color: "text.secondary" }}>
            <AddIcon
              sx={{
                fontSize: 45,
                mb: 1,
                fontWeight: "bold",
                color: "primary.main",
              }}
            />
            <Typography sx={{ fontWeight: "600", fontSize: "1.5rem" }}>
              Lägg till övningar
            </Typography>

            {lessonData?.exercises ? (
              <>
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: "text.secondary",
                    fontSize: "0.9rem",
                    mt: 1,
                    fontWeight: "500",
                  }}
                >
                  Generera övningar baserat på lektionsinnehållet
                </Typography>
              </>
            ) : (
              <>
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: "text.secondary",
                    fontSize: "0.9rem",
                    mt: 1,
                    fontWeight: "500",
                  }}
                >
                  {exerciseHover
                    ? "Visa ett konkret exempel på hur det kan se ut"
                    : "Generera övningar baserat på lektionsinnehållet"}
                </Typography>
                <Button
                  onMouseEnter={() => setExerciseHover(true)}
                  onMouseLeave={() => setExerciseHover(false)}
                  onClick={(event) => {
                    event.stopPropagation();
                    setExerciseMenuAnchorEl(event.currentTarget);
                  }}
                  variant="print"
                  startIcon={<AutoFixHighIcon />}
                  sx={{ mt: 4, mr: 2 }}
                >
                  Exempel
                </Button>
                <Button
                  onClick={(event) => {
                    event.stopPropagation();
                    handleComprehensionPaperClick();
                  }}
                  variant="purp"
                  sx={{ mt: 4 }}
                  startIcon={<GenerateIcon />}
                >
                  Generera
                </Button>
                <Menu
                  anchorEl={exerciseMenuAnchorEl}
                  open={Boolean(exerciseMenuAnchorEl)}
                  onClose={handleMenuClose}
                  PaperProps={{
                    sx: { ...styles.roundMenu, borderRadius: "8px" },
                  }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <MenuItem
                    onClick={(event) => {
                      event.stopPropagation();
                      handleExerciseTypeSelect("on-the-lines");
                    }}
                    sx={styles.menuItemStyle}
                  >
                    <TextFieldsIcon
                      fontSize="small"
                      style={{ marginRight: 8, color: "#4CAF50" }}
                    />
                    På raderna
                  </MenuItem>
                  <MenuItem
                    onClick={(event) => {
                      event.stopPropagation();
                      handleExerciseTypeSelect("between-the-lines");
                    }}
                    sx={styles.menuItemStyle}
                  >
                    <TextFieldsIcon
                      fontSize="small"
                      style={{ marginRight: 8, color: "#FFA726" }}
                    />
                    Mellan raderna
                  </MenuItem>
                  <MenuItem
                    onClick={(event) => {
                      event.stopPropagation();
                      handleExerciseTypeSelect("beyond-the-lines");
                    }}
                    sx={styles.menuItemStyle}
                  >
                    <TextFieldsIcon
                      fontSize="small"
                      style={{ marginRight: 8, color: "#7B68EE" }}
                    />
                    Bortom raderna
                  </MenuItem>
                  <MenuItem
                    onClick={(event) => {
                      event.stopPropagation();
                      handleExerciseTypeSelect("true-false");
                    }}
                    sx={styles.menuItemStyle}
                  >
                    <CheckBoxIcon
                      fontSize="small"
                      style={{ marginRight: 8, color: "#F44336" }}
                    />
                    Sant eller falskt
                  </MenuItem>
                  <MenuItem
                    onClick={(event) => {
                      event.stopPropagation();
                      handleExerciseTypeSelect("multiple-choice");
                    }}
                    sx={styles.menuItemStyle}
                  >
                    <RadioButtonCheckedIcon
                      fontSize="small"
                      style={{ marginRight: 8, color: "#9C27B0" }}
                    />
                    Flervalsfrågor
                  </MenuItem>
                  <MenuItem
                    onClick={(event) => {
                      event.stopPropagation();
                      handleExerciseTypeSelect("open-ended");
                    }}
                    sx={styles.menuItemStyle}
                  >
                    <SchoolIcon
                      fontSize="small"
                      style={{ marginRight: 8, color: "#2196F3" }}
                    />
                    Diskussionsfrågor
                  </MenuItem>
                </Menu>
              </>
            )}
          </Box>
        </DashedPaper>

        <DashedPaper
          onClick={(event) => {
            // If menu is open, don't trigger the paper click
            if (Boolean(menuAnchorEl)) {
              return;
            }
            handleQuizPaperClick();
          }}
          sx={{ mt: 4, flex: 1 }}
        >
          <Box sx={{ textAlign: "center", color: "text.secondary" }}>
            <AddIcon
              sx={{
                fontSize: 45,
                mb: 1,
                fontWeight: "bold",
                color: "primary.main",
              }}
            />
            <Typography sx={{ fontWeight: "600", fontSize: "1.5rem" }}>
              Lägg till interaktivt prov/quiz
            </Typography>
            {lessonData?.quiz ? (
              <Typography
                variant="subtitle1"
                sx={{
                  color: "text.secondary",
                  fontSize: "0.9rem",
                  mt: 1,
                }}
              >
                (Kommer ersätta ditt nuvarande)
              </Typography>
            ) : (
              <>
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: "text.secondary",
                    fontSize: "0.9rem",
                    mt: 1,
                    fontWeight: "500",
                  }}
                >
                  {quizHover
                    ? "Klicka för att se exempel på olika prov/quiz-typer"
                    : "Generera prov/quiz baserat på lektionsinnehållet"}
                </Typography>
                <Button
                  onMouseEnter={() => setQuizHover(true)}
                  onMouseLeave={() => setQuizHover(false)}
                  onClick={(event) => {
                    event.stopPropagation();
                    setMenuAnchorEl(event.currentTarget);
                  }}
                  variant="print"
                  startIcon={<AutoFixHighIcon />}
                  sx={{ mt: 4, mr: 2 }}
                >
                  Exempel
                </Button>
                <Button
                  onClick={(event) => {
                    event.stopPropagation();
                    handleQuizPaperClick();
                  }}
                  variant="purp"
                  sx={{ mt: 4 }}
                  startIcon={<GenerateIcon />}
                >
                  Generera
                </Button>
              </>
            )}
          </Box>

          <Menu
            anchorEl={menuAnchorEl}
            open={Boolean(menuAnchorEl)}
            onClose={handleMenuClose}
            PaperProps={{
              sx: { ...styles.roundMenu, borderRadius: "8px" },
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <MenuItem
              onClick={(event) => {
                event.stopPropagation();
                handleQuizTypeSelect("boolean");
              }}
              sx={styles.menuItemStyle}
            >
              <CheckBoxIcon
                fontSize="small"
                style={{ marginRight: 8, color: "#4CAF50" }}
              />
              Sant eller falskt
            </MenuItem>
            <MenuItem
              onClick={(event) => {
                event.stopPropagation();
                handleQuizTypeSelect("multiple-choice");
              }}
              sx={styles.menuItemStyle}
            >
              <RadioButtonCheckedIcon
                fontSize="small"
                style={{ marginRight: 8, color: "#FFA726" }}
              />
              Flervalsfrågor
            </MenuItem>
            <MenuItem
              onClick={(event) => {
                event.stopPropagation();
                handleQuizTypeSelect("fill-in");
              }}
              sx={styles.menuItemStyle}
            >
              <TextFieldsIcon
                fontSize="small"
                style={{ marginRight: 8, color: "#7B68EE" }}
              />
              Fyll i luckan
            </MenuItem>
            <MenuItem
              onClick={(event) => {
                event.stopPropagation();
                handleQuizTypeSelect("exam");
              }}
              sx={styles.menuItemStyle}
            >
              <SchoolIcon
                fontSize="small"
                style={{ marginRight: 8, color: "#F44336" }}
              />
              Prov
            </MenuItem>
          </Menu>
        </DashedPaper>
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: 4,
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <DashedPaper onClick={handleTimelinePaperClick} sx={{ mt: 4, flex: 1 }}>
          <Box sx={{ textAlign: "center", color: "text.secondary" }}>
            <AddIcon
              sx={{
                fontSize: 45,
                mb: 1,
                fontWeight: "bold",
                color: "primary.main",
              }}
            />
            <Typography sx={{ fontWeight: "600", fontSize: "1.5rem" }}>
              Lägg till interaktiv tidslinje
            </Typography>
            {lessonData?.timelines ? (
              <Typography
                variant="subtitle1"
                sx={{
                  color: "text.secondary",
                  fontSize: "0.9rem",
                  mt: 1,
                }}
              >
                (Kommer ersätta din nuvarande)
              </Typography>
            ) : (
              <>
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: "text.secondary",
                    fontSize: "0.9rem",
                    mt: 1,
                    fontWeight: "500",
                  }}
                >
                  {timelineHover
                    ? "Klicka för att se exempel på tidslinje"
                    : "Generera tidslinje baserat på lektionsinnehållet"}
                </Typography>
                <Button
                  onMouseEnter={() => setTimelineHover(true)}
                  onMouseLeave={() => setTimelineHover(false)}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleTimelinesExampleClick();
                    setTimeout(
                      () =>
                        timelineSectionRef.current?.scrollIntoView({
                          behavior: "smooth",
                          block: "center",
                        }),
                      100,
                    );
                  }}
                  variant="print"
                  startIcon={<AutoFixHighIcon />}
                  sx={{ mt: 4, mr: 2 }}
                >
                  Exempel
                </Button>
                <Button
                  onClick={(event) => {
                    event.stopPropagation();
                    handleTimelinePaperClick();
                  }}
                  variant="purp"
                  sx={{ mt: 4 }}
                  startIcon={<GenerateIcon />}
                >
                  Generera
                </Button>
              </>
            )}
          </Box>
        </DashedPaper>

        <DashedPaper
          onClick={handleTimelineSimplePaperClick}
          sx={{ mt: 4, flex: 1 }}
        >
          <Box sx={{ textAlign: "center", color: "text.secondary" }}>
            <AddIcon
              sx={{
                fontSize: 45,
                mb: 1,
                fontWeight: "bold",
                color: "primary.main",
              }}
            />
            <Typography sx={{ fontWeight: "600", fontSize: "1.5rem" }}>
              Lägg till enkel tidslinje
            </Typography>
            {lessonData?.timelinesSimple ? (
              <Typography
                variant="subtitle1"
                sx={{
                  color: "text.secondary",
                  fontSize: "0.9rem",
                  mt: 1,
                }}
              >
                (Kommer ersätta din nuvarande)
              </Typography>
            ) : (
              <>
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: "text.secondary",
                    fontSize: "0.9rem",
                    mt: 1,
                    fontWeight: "500",
                  }}
                >
                  {timelineSimpleHover
                    ? "Klicka för att se exempel på tidslinje"
                    : "Generera tidslinje baserat på lektionsinnehållet"}
                </Typography>
                <Button
                  onMouseEnter={() => setTimelineSimpleHover(true)}
                  onMouseLeave={() => setTimelineSimpleHover(false)}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleTimelinesSimpleExampleClick();
                    setTimeout(
                      () =>
                        timelineSimpleSectionRef.current?.scrollIntoView({
                          behavior: "smooth",
                          block: "start",
                        }),
                      100,
                    );
                  }}
                  variant="print"
                  startIcon={<AutoFixHighIcon />}
                  sx={{ mt: 4, mr: 2 }}
                >
                  Exempel
                </Button>
                <Button
                  onClick={(event) => {
                    event.stopPropagation();
                    handleTimelineSimplePaperClick();
                  }}
                  variant="purp"
                  sx={{ mt: 4 }}
                  startIcon={<GenerateIcon />}
                >
                  Generera
                </Button>
              </>
            )}
          </Box>
        </DashedPaper>
      </Box>
    </>
  );
};

export default DashedPapers;
