import {
  History,
  Science,
  Language,
  Calculate,
  Public,
  Biotech,
  Psychology,
  EmojiEvents,
  Park,
  Agriculture,
  MonitorHeart,
  MenuBook,
} from '@mui/icons-material';

// Default themes that show when no subject is selected
export const defaultThemes = [
  { type: 'subheader', label: 'Teman som lämpar sig extra bra för tidslinjer:' },
  {
    value: 'sverige-historia',
    label: 'Sveriges historia',
    content: 'sveriges historia från så långt tillbaka som möjlig till idag',
    Icon: History,
  },
  {
    value: 'upptackter',
    label: 'Stora vetenskapliga upptäckter',
    content: 'Gör en lektion om viktiga vetenskapliga upptäckter genom historien',
    Icon: Science,
  },
  {
    value: 'datorer',
    label: 'Datorns utveckling',
    content: 'Gör en lektion om datorns utveckling från första datorn till dagens teknik',
    Icon: Public,
  },
  {
    value: 'olympiska-spel',
    label: 'Olympiska spelens historia',
    content: 'Gör en lektion om de olympiska spelens utveckling från antiken till nutid',
    Icon: EmojiEvents,
  },
  {
    value: 'konst-historia',
    label: 'Konstens historia',
    content: 'Gör en lektion om konstens utveckling genom olika epoker',
    Icon: MenuBook,
  },
  {
    value: 'musik-historia',
    label: 'Musikens historia',
    content: 'Gör en lektion om musikens utveckling från antiken till modern tid',
    Icon: MenuBook,
  },
];

// Subject-specific themes
export const subjectThemes = {
  biologi: [
    { type: 'subheader', label: 'Biologiteman som lämpar sig extra bra för tidslinjer:' },
    {
      value: 'manniskans-evolution',
      label: 'Människans evolution',
      content: 'Gör en lektion om människans utveckling från tidiga förfäder till homo sapiens',
      Icon: Biotech,
    },
    {
      value: 'dinosaurier-utveckling',
      label: 'Dinosauriernas tidsålder',
      content: 'Gör en lektion om dinosauriernas utveckling och utdöende',
      Icon: Science,
    },
    {
      value: 'klimatforandringar',
      label: 'Jordens klimatförändringar',
      content: 'Gör en lektion om jordens klimatförändringar genom historien',
      Icon: Public,
    },
    {
      value: 'medicin-historia',
      label: 'Medicinens historia',
      content: 'Gör en lektion om medicinens utveckling genom tiderna',
      Icon: MonitorHeart,
    },
    {
      value: 'jordbruk-utveckling',
      label: 'Jordbrukets utveckling',
      content: 'Gör en lektion om jordbrukets utveckling från stenålder till modern tid',
      Icon: Agriculture,
    },
    {
      value: 'arternas-utveckling',
      label: 'Arternas utveckling',
      content: 'Gör en lektion om hur olika arter har utvecklats och anpassats över tid',
      Icon: Park,
    },
  ],

  historia: [
    { type: 'subheader', label: 'Historieteman som lämpar sig extra bra för tidslinjer:' },
    {
      value: 'andra-varldskriget',
      label: 'Andra världskriget',
      content: 'Gör en lektion om andra världskrigets viktiga händelser och utveckling',
      Icon: History,
    },
    {
      value: 'antikens-civilisationer',
      label: 'Antikens civilisationer',
      content: 'Gör en lektion om de stora antika civilisationernas utveckling och fall',
      Icon: History,
    },
    {
      value: 'industriella-revolutionen',
      label: 'Industriella revolutionen',
      content: 'Gör en lektion om den industriella revolutionens faser och påverkan',
      Icon: History,
    },
    {
      value: 'kallakriget',
      label: 'Kalla kriget',
      content: 'Gör en lektion om kalla krigets utveckling och konsekvenser',
      Icon: History,
    },
    {
      value: 'medeltiden',
      label: 'Medeltiden i Europa',
      content: 'Gör en lektion om medeltidens olika perioder och samhällsutveckling',
      Icon: History,
    },
    {
      value: 'upptacktsresor',
      label: 'De stora upptäcktsresorna',
      content: 'Gör en lektion om de europeiska upptäcktsresornas kronologi',
      Icon: Public,
    },
  ],

  matematik: [
    {
      type: 'subheader',
      label: 'Matematikteman som lämpar sig extra bra för tidslinjer:',
    },
    {
      value: 'matematikens-historia',
      label: 'Matematikens historia',
      content: 'Gör en lektion om matematikens utveckling genom historien',
      Icon: Calculate,
    },
    {
      value: 'raknesatt-utveckling',
      label: 'Räknesättens utveckling',
      content: 'Gör en lektion om hur olika räknesätt och matematiska koncept utvecklats',
      Icon: Calculate,
    },
    {
      value: 'geometri-historia',
      label: 'Geometrins historia',
      content: 'Gör en lektion om geometrins utveckling från antiken till modern tid',
      Icon: Calculate,
    },
    {
      value: 'matematiker-tidslinje',
      label: 'Betydelsefulla matematiker',
      content: 'Gör en lektion om viktiga matematikers upptäckter genom historien',
      Icon: Calculate,
    },
    {
      value: 'talsystem-historia',
      label: 'Talsystemens historia',
      content: 'Gör en lektion om olika talsystems utveckling genom historien',
      Icon: Calculate,
    },
    {
      value: 'matematik-verktyg',
      label: 'Matematiska verktyg',
      content: 'Gör en lektion om utvecklingen av olika matematiska verktyg och hjälpmedel',
      Icon: Calculate,
    },
  ],

  svenska: [
    { type: 'subheader', label: 'Svenskateman som lämpar sig extra bra för tidslinjer:' },
    {
      value: 'sprakets-utveckling',
      label: 'Svenska språkets utveckling',
      content: 'Gör en lektion om det svenska språkets historiska utveckling',
      Icon: Language,
    },
    {
      value: 'litteraturepoker',
      label: 'Litterära epoker',
      content: 'Gör en lektion om de olika litterära epokernas utveckling i Sverige',
      Icon: Language,
    },
    {
      value: 'svenska-forfattare',
      label: 'Svenska författare',
      content: 'Gör en lektion om svenska författares verk genom tiderna',
      Icon: MenuBook,
    },
    {
      value: 'sprakreformer',
      label: 'Svenska språkreformer',
      content: 'Gör en lektion om viktiga språkreformer i svenskans historia',
      Icon: Language,
    },
    {
      value: 'boktryckarkonst',
      label: 'Bokens historia',
      content: 'Gör en lektion om bokens och boktryckarkonstens utveckling i Sverige',
      Icon: MenuBook,
    },
    {
      value: 'tidningshistoria',
      label: 'Svenska tidningar',
      content: 'Gör en lektion om den svenska tidningspressens utveckling',
      Icon: MenuBook,
    },
  ],
};

// Helper function to get appropriate themes based on subject
export const getThemes = subject => {
  if (!subject) return defaultThemes;
  return subjectThemes[subject] || defaultThemes;
};

export default getThemes;
