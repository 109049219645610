import {
  RestaurantOutlined as Restaurant,
  ParkOutlined as Park,
  PsychologyOutlined as Psychology,
  SportsBasketballOutlined as SportsBasketball,
  WorkOutlined as Work,
  MonitorHeartOutlined as MonitorHeart,
  FlightTakeoffOutlined as FlightTakeoff,
  ComputerOutlined as Computer,
  MenuBookOutlined as MenuBook,
  ScienceOutlined as Science,
  CastleOutlined as Castle,
  ArchitectureOutlined as Architecture,
  BiotechOutlined as Biotech,
  MedicationOutlined as Medication,
  PublicOutlined as Public,
  SchoolOutlined as School,
  LocalLibraryOutlined as LocalLibrary,
  ForumOutlined as Forum,
  BookOutlined as Book,
  EmojiObjectsOutlined as EmojiObjects,
  BusinessOutlined as Business,
  AgricultureOutlined as Agriculture,
  ForestOutlined as Forest,
  MuseumOutlined as Museum,
  MapOutlined as Map,
  LanguageOutlined as Language,
  ScienceOutlined as Chemistry, // Using Science icon as fallback for Chemistry
  NumbersOutlined as Numbers,
  CalculateOutlined as Calculate,
  BuildOutlined as Build,
  PetsOutlined as Pets,
  NatureOutlined as Nature,
  HistoryOutlined as History,
  TimelineOutlined as Timeline,
  AnchorOutlined as Anchor,
  ExtensionOutlined as Extension, // Added for logiklabyrint
} from "@mui/icons-material";

export const defaultThemes = [
  {
    type: "subheader",
    label: "Teman som lämpar sig extra bra för denna lektionstyp:",
  },
  {
    value: "rymdstation",
    label: "Rymdstationens gåta",
    content:
      "En övergiven rymdstation där vetenskapliga gåtor måste lösas för att rädda besättningen. Eleverna använder sina kunskaper för att navigera genom stationen, reparera kritiska system och avslöja mystiska händelser som ledde till stationens övergivande.",
    Icon: FlightTakeoff,
  },
  {
    value: "antikmuseum",
    label: "Mysteriet på museet",
    content:
      "Ett mystiskt museum där historiska artefakter har försvunnit. Eleverna måste använda sina historiska kunskaper och analytiska förmågor för att spåra föremålen genom tidsepoker och kulturer innan de försvinner för alltid.",
    Icon: Museum,
  },
  {
    value: "djungel",
    label: "Djungelns hemlighet",
    content:
      "En spännande expedition i djungeln där eleverna måste överleva med hjälp av naturkunskap. De behöver identifiera ätbara växter, förstå vädermönster och navigera genom farlig terräng för att hitta en försvunnen forskningsgrupp.",
    Icon: Forest,
  },
];

export const subjectThemes = {
  biologi: [
    {
      type: "subheader",
      label:
        "Biologiteman där eleverna får utforska livets mysterier genom spännande gåtor:",
    },
    {
      value: "cellaboratorium",
      label: "Det mikroskopiska laboratoriet",
      content:
        "Ett hemligt laboratorium där eleverna måste förstå cellens uppbyggnad för att rädda forskare som har krympts till mikroskopisk storlek. De navigerar genom cellstrukturer, löser pussel relaterade till cellorganeller och använder cellens funktioner för att rädda forskarna.",
      Icon: Biotech,
    },
    {
      value: "ekosystem",
      label: "Den hotade regnskogen",
      content:
        "Ett skadat ekosystem som måste återställas genom att lösa miljörelaterade pussel. Eleverna behöver förstå näringskedjor, symbios och ekologiska samband för att återställa balansen i ett hotat regnskogsområde.",
      Icon: Nature,
    },
    {
      value: "dnamysteriet",
      label: "Genernas kod",
      content:
        "Ett genetiskt laboratorium där DNA-koder måste knäckas för att stoppa en farlig mutation. Eleverna använder sina kunskaper om genetik, DNA-replikation och proteinsyntesen för att förhindra en genetisk katastrof.",
      Icon: Science,
    },
    {
      value: "kroppensresa",
      label: "Inuti människokroppen",
      content:
        "En fantastisk resa genom människokroppen där organsystem och fysiologi står i fokus. Eleverna måste navigera genom blodomloppet, nervsystemet och andra organ medan de löser medicinska mysterier.",
      Icon: MonitorHeart,
    },
    {
      value: "evolutionssparet",
      label: "Evolutionens tidslinje",
      content:
        "En fascinerande tidresa genom evolutionen där arter och anpassningar måste spåras. Eleverna undersöker fossil, studerar arternas utveckling och löser evolutionära pussel för att förstå livets historia.",
      Icon: Pets,
    },
    {
      value: "immunforsvaret",
      label: "Kroppens försvar",
      content:
        "Ett äventyr i immunförsvaret där eleverna måste hjälpa vita blodkroppar att bekämpa en invasion av patogener. De lär sig om immunsystemets komponenter och funktioner genom praktiska pussel.",
      Icon: Medication,
    },
    {
      value: "fotosynteslabyrint",
      label: "Den gröna labyrinten",
      content:
        "En magisk växthuslabyrint där fotosyntes och växters livscykler måste förstås. Eleverna manipulerar ljus, vatten och näringsämnen för att ta sig genom en levande labyrint av växter.",
      Icon: Park,
    },
    {
      value: "havetsdjup",
      label: "Djuphavets mysterier",
      content:
        "En spännande undervattensexpedition där marina ekosystem måste utforskas. Eleverna studerar havsströmmar, djuphavsvarelser och korallrev medan de löser oceanografiska gåtor.",
      Icon: Nature,
    },
    {
      value: "hjarnmysteriet",
      label: "Hjärnans korridorer",
      content:
        "Ett neurovetenskapligt centrum där hjärnans funktioner måste kartläggas. Eleverna utforskar olika hjärnregioner, löser kognitiva pussel och förstår nervsystemets komplexitet.",
      Icon: Psychology,
    },
    {
      value: "artbestamning",
      label: "Arternas katalog",
      content:
        "Ett naturhistoriskt museum där arter måste identifieras och klassificeras. Eleverna använder taxonomi och artbestämningsnycklar för att katalogisera och rädda utrotningshotade arter.",
      Icon: Pets,
    },
  ],

  historia: [
    {
      type: "subheader",
      label:
        "Historieteman där eleverna reser genom tiden för att lösa historiska mysterier:",
    },
    {
      value: "vikingaskepp",
      label: "Vikingaskeppets hemlighet",
      content:
        "Ett mystiskt vikingaskepp fullt med runor och fornnordiska gåtor. Eleverna måste tyda runor, förstå vikingatidens samhälle och navigation för att avslöja skeppets destination och uppdrag.",
      Icon: Anchor,
    },
    {
      value: "medeltidsborg",
      label: "Riddarborgens mysterium",
      content:
        "En medeltida borg där riddartidens hemligheter måste avslöjas. Eleverna utforskar medeltida liv, feodalsystem och riddarkoder medan de löser borgens gåtor.",
      Icon: Castle,
    },
    {
      value: "industrirevolution",
      label: "Den mekaniska revolutionen",
      content:
        "En viktoriansk fabrik där den industriella revolutionens uppfinningar måste användas. Eleverna löser pussel kopplade till industrialiseringen och dess påverkan på samhället.",
      Icon: Work,
    },
    {
      value: "antikengrekland",
      label: "Olympens prövningar",
      content:
        "Ett antikt grekiskt tempel där filosofiska gåtor och olympiska utmaningar väntar. Eleverna utforskar antikens Grekland genom dess filosofi, kultur och mytologi.",
      Icon: Architecture,
    },
    {
      value: "egyptiskapyramid",
      label: "Faraos gravkammare",
      content:
        "En mystisk pyramid där hieroglyfer och forntida matematik är nyckeln. Eleverna måste tyda hieroglyfer, förstå pyramidernas konstruktion och det gamla Egyptens kultur.",
      Icon: Architecture,
    },
    {
      value: "sidenvägen",
      label: "Karavanens väg",
      content:
        "En handelskaravan längs sidenvägen där kulturutbyten och handel är centrala. Eleverna upptäcker hur handel påverkade kulturspridning och teknologisk utveckling.",
      Icon: Map,
    },
    {
      value: "aztekertempel",
      label: "Det gyllene templet",
      content:
        "Ett aztekiskt tempel där kalendrar och astronomi måste förstås. Eleverna utforskar aztekernas vetenskap, matematik och religion genom praktiska pussel.",
      Icon: Public,
    },
    {
      value: "romarriket",
      label: "Senatorns villa",
      content:
        "En romersk villa där politik och intriger från antiken måste navigeras. Eleverna lär sig om romersk politik, kultur och vardagsliv genom spännande gåtor.",
      Icon: Castle,
    },
    {
      value: "upptacktsfard",
      label: "De stora upptäckterna",
      content:
        "Ett 1400-tals upptäcktsfartyg där navigation och kartkunskap är avgörande. Eleverna använder historiska navigationsmetoder och kartografi för att hitta nya länder.",
      Icon: Map,
    },
    {
      value: "kallakrig",
      label: "Spionernas högkvarter",
      content:
        "En hemlig bunker från kalla kriget där diplomatiska koder måste knäckas. Eleverna använder historiska dokument och koder för att förstå efterkrigstidens spänningar.",
      Icon: History,
    },
  ],

  fysik: [
    {
      type: "subheader",
      label:
        "Fysikteman där eleverna experimenterar med naturlagar för att lösa pussel:",
    },
    {
      value: "quantumlab",
      label: "Kvantfysikens gåtor",
      content:
        "Ett avancerat kvantfysiklaboratorium där partikelteori och vågfunktioner måste behärskas. Eleverna experimenterar med kvantmekanik och partikelfysik för att lösa mystiska fenomen.",
      Icon: Science,
    },
    {
      value: "optikrum",
      label: "Ljusets hemligheter",
      content:
        "Ett fascinerande rum fullt av speglar och linser där ljusets egenskaper måste förstås. Eleverna manipulerar ljusstrålar, bryter ljus och skapar optiska illusioner.",
      Icon: EmojiObjects,
    },
    {
      value: "rymdobservatorium",
      label: "Kosmiska mysterier",
      content:
        "Ett observatorium där astronomiska fenomen och gravitationskrafter spelar huvudrollen. Eleverna studerar planetrörelser, stjärnor och galaxer för att lösa rymdgåtor.",
      Icon: FlightTakeoff,
    },
    {
      value: "energifabrik",
      label: "Energins flöde",
      content:
        "En experimentell energianläggning där olika energiformer måste omvandlas. Eleverna arbetar med energiomvandlingar och energibevarande för att driva viktiga maskiner.",
      Icon: Build,
    },
    {
      value: "magnetlab",
      label: "Magnetfältens kraft",
      content:
        "Ett elektromagnetiskt laboratorium där magnetfält och elektricitet måste kontrolleras. Eleverna utforskar elektromagnetism och induktion genom praktiska experiment.",
      Icon: Science,
    },
    {
      value: "ljudstudio",
      label: "Akustikens värld",
      content:
        "En akustisk studio där ljudvågor och resonans måste manipuleras. Eleverna experimenterar med frekvenser, våglängder och ljudets egenskaper.",
      Icon: Science,
    },
    {
      value: "mekanikverkstad",
      label: "Mekanikens mästerverk",
      content:
        "En verkstad där mekanikens lagar och maskiner måste användas rätt. Eleverna bygger och använder olika mekaniska system för att lösa praktiska problem.",
      Icon: Build,
    },
    {
      value: "termodynamik",
      label: "Värmens mysterier",
      content:
        "Ett värmekraftverk där temperatur och tryck måste balanseras. Eleverna arbetar med termodynamikens lagar för att kontrollera energiflöden.",
      Icon: Science,
    },
    {
      value: "relativitet",
      label: "Tidens paradox",
      content:
        "Ett relativitetslaboratorium där tid och rum beter sig konstigt. Eleverna utforskar speciell relativitetsteori genom tiddilatation och längdkontraktion.",
      Icon: Timeline,
    },
    {
      value: "vagrorelse",
      label: "Vågfenomenens kammare",
      content:
        "Ett rum där olika typer av vågrörelser måste studeras och kontrolleras. Eleverna experimenterar med interferens, diffraktion och stående vågor.",
      Icon: Science,
    },
  ],

  kemi: [
    {
      type: "subheader",
      label:
        "Kemiteman där eleverna experimenterar med molekyler och reaktioner:",
    },
    {
      value: "alkemistverkstad",
      label: "Alkemistens verkstad",
      content:
        "En mystisk alkemists verkstad där kemiska reaktioner måste förstås. Eleverna experimenterar med olika ämnen och reaktioner för att skapa den perfekta formeln.",
      Icon: Chemistry,
    },
    {
      value: "atomfabrik",
      label: "Atomernas dans",
      content:
        "En atomfabrik där periodiska systemet är nyckeln till framgång. Eleverna utforskar grundämnenas egenskaper och elektronkonfigurationer.",
      Icon: Science,
    },
    {
      value: "syrabaslabb",
      label: "pH-Labyrinten",
      content:
        "Ett modernt laboratorium där pH-värden och syra-bas reaktioner måste balanseras. Eleverna arbetar med neutralisationer och buffertlösningar.",
      Icon: Chemistry,
    },
    {
      value: "materialcentrum",
      label: "Materialens mysterier",
      content:
        "Ett materialforskningscentrum där olika ämnens egenskaper måste undersökas. Eleverna studerar kristallstrukturer, legeringar och moderna material.",
      Icon: Build,
    },
    {
      value: "molekylbyggare",
      label: "Molekylära pussel",
      content:
        "En molekylär konstruktionsplats där kemiska bindningar måste skapas korrekt. Eleverna bygger molekyler och förstår kemiska bindningar.",
      Icon: Science,
    },
    {
      value: "gaslabb",
      label: "Gasernas värld",
      content:
        "Ett gaslaboratorium där aggregationsformer och gaslagar styr händelserna. Eleverna experimenterar med tryck, volym och temperatur för att lösa komplexa pussel.",
      Icon: Science,
    },
    {
      value: "reaktionsrum",
      label: "Reaktionernas rum",
      content:
        "Ett rum där kedjereaktioner och katalysatorer måste användas strategiskt. Eleverna planerar och genomför kemiska reaktioner för att låsa upp hemliga dörrar.",
      Icon: Chemistry,
    },
    {
      value: "organiskakemi",
      label: "Kolföreningarnas kod",
      content:
        "Ett biokemlabb där organiska molekyler och reaktionsmekanismer är i fokus. Eleverna syntetiserar olika organiska föreningar för att lösa biokemiska mysterier.",
      Icon: Biotech,
    },
    {
      value: "kristallgrotta",
      label: "Kristallernas kammare",
      content:
        "En kristallgrotta där mineralogi och kristallstruktur måste förstås. Eleverna studerar kristallbildning och använder kristallografiska principer för att hitta vägen ut.",
      Icon: Science,
    },
    {
      value: "miljolabb",
      label: "Miljökemistens utmaning",
      content:
        "Ett miljökemiskt laboratorium där föroreningar måste analyseras och åtgärdas. Eleverna använder analytisk kemi för att rena förorenat vatten och mark.",
      Icon: Nature,
    },
  ],

  matematik: [
    {
      type: "subheader",
      label: "Matematikteman där siffror och logik är vägen till frihet:",
    },
    {
      value: "pyramidgata",
      label: "Pyramidens proportioner",
      content:
        "En forntida pyramid där geometriska pussel och proportioner måste lösas. Eleverna använder geometri och trigonometri för att navigera genom pyramidens hemligheter.",
      Icon: Architecture,
    },
    {
      value: "kryptovalv",
      label: "Kodknäckarnas valv",
      content:
        "Ett högteknologiskt valv där matematiska koder och kryptering är nyckeln. Eleverna använder talteori och matematisk logik för att dekryptera hemliga meddelanden.",
      Icon: Calculate,
    },
    {
      value: "statistikcentral",
      label: "Statistikens centrum",
      content:
        "En datacentral där statistiska samband och sannolikheter måste analyseras. Eleverna arbetar med statistisk analys för att förutsäga och lösa problem.",
      Icon: Numbers,
    },
    {
      value: "algebrapalats",
      label: "Algebraiska palatset",
      content:
        "Ett mystiskt palats där algebraiska ekvationer gömmer sig i arkitekturen. Eleverna löser ekvationer och funktioner för att avslöja palatsests hemligheter.",
      Icon: Calculate,
    },
    {
      value: "geometripark",
      label: "Formernas trädgård",
      content:
        "En geometrisk skulpturpark där vinklar och former skapar mönster. Eleverna använder geometriska principer för att förstå och manipulera konstverk.",
      Icon: Architecture,
    },
    {
      value: "funktionslab",
      label: "Funktionernas fabrik",
      content:
        "Ett laboratorium där matematiska funktioner styr maskinerna. Eleverna analyserar grafer och funktioner för att kontrollera avancerad utrustning.",
      Icon: Build,
    },
    {
      value: "talteorimuseum",
      label: "Talens museum",
      content:
        "Ett museum dedikerat till talteori där primtal och fakulteter är ledtrådar. Eleverna utforskar talmönster och matematiska serier.",
      Icon: Numbers,
    },
    {
      value: "probabilitetsportal",
      label: "Sannolikhetens port",
      content:
        "En magisk portal där sannolikhetsberäkningar avgör utgången. Eleverna använder kombinatorik och sannolikhetslära för att välja rätt väg.",
      Icon: Calculate,
    },
    {
      value: "logiklabyrint",
      label: "Logikens labyrint",
      content:
        "En komplex labyrint där logiska resonemang och slutledning krävs för att hitta ut. Eleverna använder matematisk logik och problemlösning.",
      Icon: Extension,
    },
    {
      value: "matteverkstad",
      label: "Matematikerns verkstad",
      content:
        "En kreativ verkstad där praktisk matematik används för att lösa vardagliga problem. Eleverna tillämpar matematik i verkliga situationer.",
      Icon: Build,
    },
  ],

  svenska: [
    {
      type: "subheader",
      label: "Svenskateman där språkets kraft öppnar nya vägar:",
    },
    {
      value: "biblioteksmysteriet",
      label: "Det magiska biblioteket",
      content:
        "Ett förtrollat bibliotek där litterära referenser och bokcitat är ledtrådar. Eleverna utforskar olika litterära epoker och genrer för att lösa mysterier.",
      Icon: LocalLibrary,
    },
    {
      value: "poetverkstad",
      label: "Poetens kammare",
      content:
        "En poets hemliga arbetsrum där rim och meter måste behärskas. Eleverna skapar och analyserar poesi för att låsa upp hemliga meddelanden.",
      Icon: Book,
    },
    {
      value: "tidningsredaktion",
      label: "Pressens högkvarter",
      content:
        "En tidningsredaktion där journalistiska texter och källkritik är centralt. Eleverna skriver artiklar och granskar källor för att avslöja sanningen.",
      Icon: Forum,
    },
    {
      value: "sagoslottet",
      label: "Sagornas slott",
      content:
        "Ett förtrollat slott där sagor och berättarteknik vävs samman. Eleverna utforskar berättarstrukturer och sagomotiv för att bryta förtrollningen.",
      Icon: Castle,
    },
    {
      value: "ordverkstad",
      label: "Ordens verkstad",
      content:
        "En mystisk verkstad där etymologi och ordens ursprung måste spåras. Eleverna undersöker språkhistoria och ordens utveckling.",
      Icon: Language,
    },
    {
      value: "teatermysteriet",
      label: "Dramatikens scen",
      content:
        "En övergiven teater där dramatik och repliker gömmer ledtrådar. Eleverna arbetar med dialog och dramaturgi för att lösa teaterns gåta.",
      Icon: Forum,
    },
    {
      value: "grammatikborg",
      label: "Språkets fästning",
      content:
        "En borg där grammatiska regler och språkriktighet är nycklarna. Eleverna navigerar genom språkets struktur och grammatiska mysterier.",
      Icon: School,
    },
    {
      value: "retorikarenan",
      label: "Talarkonstrens arena",
      content:
        "En arena där retoriska knep och övertalningskonst är viktiga verktyg. Eleverna använder retorikens verktyg för att övertyga och påverka.",
      Icon: Public,
    },
    {
      value: "bokbinderi",
      label: "Det gamla bokbinderiet",
      content:
        "Ett traditionellt bokbinderi där textanalys och genrekunskap är viktigt. Eleverna undersöker olika texttyper och deras särdrag.",
      Icon: MenuBook,
    },
    {
      value: "mediecentrum",
      label: "Moderna mediehuset",
      content:
        "Ett modernt mediecenter där olika kommunikationsformer måste behärskas. Eleverna arbetar med olika medier och kommunikationssätt.",
      Icon: Computer,
    },
  ],
};

export const getThemes = (subject) => {
  if (!subject) return defaultThemes;
  return subjectThemes[subject] || defaultThemes;
};

export default getThemes;
