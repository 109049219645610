import React from "react";
import {
  Box,
  Typography,
  Paper,
  Divider,
  List,
  ListItem,
  ListItemText,
  Chip,
  Grid,
  Card,
  alpha,
  darken,
  CardContent,
} from "@mui/material";
import {
  Timeline,
  TimelineItem,
  TimelineContent,
  TimelineSeparator,
  TimelineConnector,
  TimelineDot,
  TimelineOppositeContent,
} from "@mui/lab";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import SchoolIcon from "@mui/icons-material/School";
import { InstructionItem } from "./functions";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import GroupIcon from "@mui/icons-material/Group";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import AssessmentIcon from "@mui/icons-material/Assessment";
import PsychologyIcon from "@mui/icons-material/Psychology";
import LinkIcon from "@mui/icons-material/Link";
import WarningIcon from "@mui/icons-material/Warning";

const SectionTitle = ({ children }) => (
  <Typography
    variant="h6"
    sx={{ fontWeight: 600, mb: 2, color: "primary.main" }}
  >
    {children}
  </Typography>
);

const TimeChip = ({ minutes }) => (
  <Chip
    icon={<AccessTimeIcon />}
    label={`${minutes} minuter`}
    size="small"
    color="primary"
    sx={{
      height: "27px",
      background: "rgba(39, 121, 43, 0.1)",
      color: "#27792B",
      ml: 2,
      paddingLeft: "5px",
      paddingRight: "5px",
      fontSize: "12px",
      fontWeight: 500,
      "& .MuiChip-icon": {
        fontSize: 16,
        color: "inherit",
      },
    }}
  />
);

const LessonPlanDisplay = ({ lessonData, styles, theme, viewMode }) => {
  if (!lessonData?.lektionsplan) return null;

  const plan = lessonData.lektionsplan;

  return (
    <Box sx={{ mb: 4, width: viewMode ? "76%" : "100%", margin: "0 auto" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start", // Changed to align-start for better text alignment
          gap: 4,
          mb: 6,
          mt: 10,
        }}
      >
        {/* Title and Subtitle Container */}
        <Box sx={{ flex: 1 }}>
          <Typography
            variant="h4"
            sx={{
              fontWeight: "600",
              mb: 2,
            }}
          >
            {plan.titel}
          </Typography>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            sx={{ fontSize: "1.1rem" }}
          >
            {plan.undertitel}
            {/* Add the actual subtitle field from your data */}
          </Typography>
        </Box>

        {lessonData.imageUrl && (
          <Box
            sx={{
              width: "200px", // Smaller, more compact size
              height: "200px",
              borderRadius: 2,
              overflow: "hidden",
              boxShadow: "0 3px 10px rgba(0,0,0,0.1)",
              border: "4px solid #fff",
              flexShrink: 0, // Prevent image from shrinking
            }}
          >
            <img
              src={lessonData.imageUrl}
              alt={plan.titel}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          </Box>
        )}
      </Box>

      {/* Rest of your code... */}

      {/* Mål och Syfte */}
      <Box sx={{ mb: 4 }}>
        <Grid container spacing={3} sx={{ mt: 3 }}>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: 600,
                fontSize: "1.2rem",
                mb: 2,
                color: "text.secondary",
              }}
            >
              <SchoolIcon
                sx={{
                  mr: 1,
                  verticalAlign: "middle",
                  color: "text.secondary",
                  display: "none",
                }}
              />
              Syfte
            </Typography>
            <Box
              sx={{
                ...styles.freshBox,
                ...styles.softBoxShadow,
                flex: 1,
                padding: "20px",
              }}
            >
              <Typography>{plan.mål.syfte}</Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{ display: "none", flexDirection: "column" }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: 600,
                fontSize: "1.2rem",
                mb: 2,
                color: "text.secondary",
              }}
            >
              <MenuBookIcon
                sx={{
                  mr: 1,
                  verticalAlign: "middle",
                  color: "text.secondary",
                  display: "none",
                }}
              />
              Koppling till kursplan
            </Typography>
            <Box
              sx={{
                ...styles.freshBox,
                ...styles.softBoxShadow,
                flex: 1,
                padding: "20px",
              }}
            >
              <Box>
                {plan.mål.kopplingTillKursplan.map((koppling, index) => (
                  <InstructionItem text={koppling} marginBottom={1.5} />
                ))}
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: 600,
                fontSize: "1.2rem",
                mb: 2,
                color: "text.secondary",
              }}
            >
              <CheckCircleIcon
                sx={{
                  mr: 1,
                  verticalAlign: "middle",
                  color: "text.secondary",
                  display: "none",
                }}
              />
              Lärandemål
            </Typography>
            <Box
              sx={{
                ...styles.freshBox,
                ...styles.softBoxShadow,
                flex: 1,
                padding: "20px",
              }}
            >
              <Box>
                {plan.mål.lärandemål.map((mål, index) => (
                  <InstructionItem
                    text={mål}
                    marginBottom={1.5}
                    simple={true}
                  />
                ))}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Divider sx={{ my: 8 }} />

      <Timeline position="alternate" sx={{ padding: "0", margin: "0" }}>
        {/* Uppvärmning */}
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot color="primary" />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Box
              sx={{
                ...styles.freshBox,
                ...styles.softBoxShadow,
                padding: "30px",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  mb: 2,
                  color: "text.secondary",
                  fontWeight: "600",
                  fontSize: "1.3rem",
                }}
              >
                Uppvärmning
                <TimeChip minutes={plan.uppvärmning.tidsåtgång} />
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                {plan.uppvärmning.aktivitet}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Koppling till tema: {plan.uppvärmning.kopplingTillTema}
              </Typography>
            </Box>
          </TimelineContent>
        </TimelineItem>

        {/* Inledning */}
        <TimelineItem sx={{ textAlign: "left" }}>
          <TimelineSeparator>
            <TimelineDot color="primary" />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Box
              sx={{
                ...styles.freshBox,
                ...styles.softBoxShadow,
                padding: "30px",
                textAlign: "left",
              }}
            >
              <Typography
                variant="h6"
                color="primary"
                sx={{
                  mb: 2,
                  color: "text.secondary",
                  fontWeight: "600",
                  fontSize: "1.3rem",
                }}
              >
                Inledning
                <TimeChip minutes={plan.inledning.tidsåtgång} />
              </Typography>
              <Typography variant="body1">
                {plan.inledning.beskrivning}
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ mt: 2, mb: 3, fontWeight: "600" }}
              >
                Diskussionsfrågor:
              </Typography>
              <Box>
                {plan.inledning.frågor.map((fråga, index) => (
                  <InstructionItem
                    text={fråga}
                    simple={true}
                    marginBottom={1.5}
                  />
                ))}
              </Box>
            </Box>
          </TimelineContent>
        </TimelineItem>

        {/* Huvudaktivitet */}
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot color="primary" />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Box
              sx={{
                ...styles.freshBox,
                ...styles.softBoxShadow,
                padding: "30px",
              }}
            >
              <Typography
                variant="h6"
                color="primary"
                sx={{
                  mb: 2,
                  color: "text.secondary",
                  fontWeight: "600",
                  fontSize: "1.3rem",
                }}
              >
                Huvudaktivitet
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ mt: 2, mb: 2, fontWeight: "600" }}
              >
                Instruktioner:
              </Typography>
              <Box>
                {plan.huvudaktivitet.instruktioner.map((instruction, index) => (
                  <InstructionItem
                    text={instruction}
                    simple={true}
                    marginBottom={1.5}
                  />
                ))}
              </Box>
              <Typography
                variant="subtitle1"
                sx={{ mt: 4, mb: 2, fontWeight: "600" }}
              >
                Metoder:
              </Typography>
              <Box>
                {plan.huvudaktivitet.metoder.map((metod, index) => (
                  <InstructionItem
                    text={metod}
                    simple={true}
                    marginBottom={1.5}
                  />
                ))}
              </Box>
              <Typography
                variant="subtitle1"
                sx={{ mt: 5, mb: 2, fontWeight: "600" }}
              >
                Steg:
              </Typography>
              {plan.huvudaktivitet.steg.map((steg, index) => (
                <Box key={index} sx={{ mb: 3 }}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: 500, mb: 1 }}
                  >
                    Steg {steg.stegNummer}
                    <TimeChip minutes={steg.tidsåtgång} />
                  </Typography>
                  <Typography>{steg.beskrivning}</Typography>
                </Box>
              ))}
              <Typography
                variant="subtitle1"
                sx={{ mt: 5, mb: 2, fontWeight: "600" }}
              >
                Förväntade resultat:
              </Typography>
              <Box>
                {plan.huvudaktivitet.förväntadeResultat.map(
                  (resultat, index) => (
                    <InstructionItem
                      text={resultat}
                      simple={true}
                      marginBottom={1.5}
                    />
                  ),
                )}
              </Box>
            </Box>
          </TimelineContent>
        </TimelineItem>

        {/* Avslutning */}
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot color="primary" />
          </TimelineSeparator>
          <TimelineContent>
            <Box
              sx={{
                ...styles.freshBox,
                ...styles.softBoxShadow,
                padding: "30px",
                textAlign: "left",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  mb: 2,
                  color: "text.secondary",
                  fontWeight: "600",
                  fontSize: "1.3rem",
                }}
              >
                Avslutning
                <TimeChip minutes={plan.avslutning.tidsåtgång} />
              </Typography>
              <Typography variant="body1">
                {plan.avslutning.sammanfattning}
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ mt: 3, mb: 2, fontWeight: "600" }}
              >
                Reflektionsfrågor:
              </Typography>
              <Box>
                {plan.avslutning.reflektion.map((ref, index) => (
                  <InstructionItem
                    text={ref}
                    simple={true}
                    marginBottom={1.5}
                  />
                ))}
              </Box>
              <Typography
                variant="subtitle1"
                sx={{ mt: 5, mb: 2, fontWeight: "600" }}
              >
                Förberedelse inför nästa lektion:
              </Typography>
              <Typography>
                {plan.avslutning.förberedelseInförNästaLektionen}
              </Typography>
            </Box>
          </TimelineContent>
        </TimelineItem>
      </Timeline>

      <Divider sx={{ my: 8 }} />

      {/* Material och Hemläxa */}
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Box
            sx={{
              ...styles.freshBox,
              ...styles.softBoxShadow,
              padding: "30px",
              textAlign: "left",
              flex: 1,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                mb: 4,
                color: "text.secondary",
                fontWeight: "600",
                fontSize: "1.3rem",
              }}
            >
              <AssignmentIcon sx={{ mr: 1, verticalAlign: "middle" }} />
              Material
            </Typography>
            <Box>
              {plan.material.map((item, index) => (
                <InstructionItem text={item} simple={true} marginBottom={1.5} />
              ))}
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Box
            sx={{
              ...styles.freshBox,
              ...styles.softBoxShadow,
              padding: "30px",
              textAlign: "left",
              flex: 1,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                mb: 2,
                color: "text.secondary",
                fontWeight: "600",
                fontSize: "1.3rem",
              }}
            >
              <AutoFixHighIcon sx={{ mr: 1, verticalAlign: "middle" }} />
              Hemläxa
            </Typography>
            <Typography variant="body1">{plan.hemläxa.beskrivning}</Typography>
            <Typography variant="body2" sx={{ mt: 1, color: "text.secondary" }}>
              Koppling till lektionen: {plan.hemläxa.kopplingTillLektionen}
            </Typography>
            <Typography variant="body2" sx={{ mt: 1, color: "text.secondary" }}>
              Förväntad tidsåtgång:{" "}
              <TimeChip minutes={plan.hemläxa.förväntadTidsåtgång} />
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Divider sx={{ my: 8 }} />

      {/* Utvärdering */}
      <Box sx={{ mb: 4, mt: 7 }}>
        <SectionTitle>
          <AssessmentIcon sx={{ mr: 1, verticalAlign: "middle" }} />
          Utvärdering
        </SectionTitle>
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <Box
              sx={{
                ...styles.freshBox,
                ...styles.softBoxShadow,
                padding: "30px",
                textAlign: "left",
                flex: 1,
              }}
            >
              <Typography variant="subtitle1" sx={{ mb: 3, fontWeight: 500 }}>
                Självutvärdering
              </Typography>
              <Typography>{plan.utvärdering.självutvärdering}</Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <Box
              sx={{
                ...styles.freshBox,
                ...styles.softBoxShadow,
                padding: "30px",
                textAlign: "left",
                flex: 1,
              }}
            >
              <Typography variant="subtitle1" sx={{ mb: 3, fontWeight: 500 }}>
                Lärarens bedömning
              </Typography>
              <Typography>{plan.utvärdering.lärarensBedömning}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Differentiering */}
      <Box sx={{ mb: 4, mt: 7 }}>
        <SectionTitle>
          <GroupIcon sx={{ mr: 1, verticalAlign: "middle" }} />
          Differentiering
        </SectionTitle>
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <Box
              sx={{
                ...styles.freshBox,
                ...styles.softBoxShadow,
                padding: "30px",
                textAlign: "left",
                flex: 1,
              }}
            >
              <Typography variant="subtitle1" sx={{ fontWeight: 500, mb: 4 }}>
                Anpassningar
              </Typography>
              <Box>
                {plan.differentiering.anpassningar.map((anpassning, index) => (
                  <InstructionItem
                    text={anpassning}
                    simple={true}
                    marginBottom={1.5}
                  />
                ))}
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <Box
              sx={{
                ...styles.freshBox,
                ...styles.softBoxShadow,
                padding: "30px",
                textAlign: "left",
                flex: 1,
              }}
            >
              <Typography variant="subtitle1" sx={{ mb: 4, fontWeight: 500 }}>
                Alternativa metoder
              </Typography>
              <Box>
                {plan.differentiering.alternativaMetoder.map((metod, index) => (
                  <InstructionItem
                    text={metod}
                    simple={true}
                    marginBottom={1.5}
                  />
                ))}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Koppling till tema */}
      <Box sx={{ mb: 4, mt: 7 }}>
        <SectionTitle>
          <LinkIcon sx={{ mr: 1, verticalAlign: "middle" }} />
          Koppling till tema
        </SectionTitle>
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <Box
              sx={{
                ...styles.freshBox,
                ...styles.softBoxShadow,
                padding: "30px",
                textAlign: "left",
                flex: 1,
              }}
            >
              <Typography variant="subtitle1" sx={{ mb: 3, fontWeight: 500 }}>
                Ämnesövergripande lärande
              </Typography>
              <Typography>
                {plan.kopplingTillTema.ämnesövergripandeLärande}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <Box
              sx={{
                ...styles.freshBox,
                ...styles.softBoxShadow,
                padding: "30px",
                textAlign: "left",
                flex: 1,
              }}
            >
              <Typography variant="subtitle1" sx={{ mb: 3, fontWeight: 500 }}>
                Koppling till vardagslivet
              </Typography>
              <Typography>
                {plan.kopplingTillTema.kopplingTillVardagslivet}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Förväntade utmaningar */}
      <Box sx={{ mb: 4, mt: 7 }}>
        <SectionTitle>
          <WarningIcon sx={{ mr: 1, verticalAlign: "middle" }} />
          Förväntade utmaningar
        </SectionTitle>
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <Box
              sx={{
                ...styles.freshBox,
                ...styles.softBoxShadow,
                padding: "30px",
                textAlign: "left",
                flex: 1,
              }}
            >
              <Typography variant="subtitle1" sx={{ mb: 3, fontWeight: 500 }}>
                Utmaningar
              </Typography>
              <Box>
                {plan.förväntadeUtmaningar.utmaningar.map((utmaning, index) => (
                  <InstructionItem
                    text={utmaning}
                    simple={true}
                    marginBottom={1.5}
                  />
                ))}
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <Box
              sx={{
                ...styles.freshBox,
                ...styles.softBoxShadow,
                padding: "30px",
                textAlign: "left",
                flex: 1,
              }}
            >
              <Typography variant="subtitle1" sx={{ mb: 3, fontWeight: 500 }}>
                Lösningar
              </Typography>
              <Box>
                {plan.förväntadeUtmaningar.lösningar.map((lösning, index) => (
                  <InstructionItem
                    text={lösning}
                    simple={true}
                    marginBottom={1.5}
                  />
                ))}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Tidsplanering */}
      <Box sx={{ mb: 4, mt: 7 }}>
        <SectionTitle>
          <AccessTimeIcon sx={{ mr: 1, verticalAlign: "middle" }} />
          Tidsplanering
        </SectionTitle>
        <Box
          sx={{
            ...styles.freshBox,
            ...styles.softBoxShadow,
            padding: "30px",
            textAlign: "left",
          }}
        >
          <Box sx={{ mt: 2 }}>
            {plan.tidsplanering.map((tid, index) => (
              <InstructionItem
                text={`${tid.sektion}: ${tid.tidsåtgång} minuter`}
              />
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LessonPlanDisplay;
