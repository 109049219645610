import React, { useState, useCallback } from "react";
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  Paper,
  Fade,
  IconButton,
  useTheme,
} from "@mui/material";
import {
  CloudUpload,
  CheckCircleOutline,
  Close as CloseIcon,
  FileUpload,
} from "@mui/icons-material";
import mammoth from "mammoth";

const FileUploader = ({
  onFileContent,
  styles,
  forQuiz,
  forFlashcards,
  forPowerPoint,
  forTextlabbet,
}) => {
  const [uploading, setUploading] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const [fileName, setFileName] = useState("");
  const [error, setError] = useState("");
  const [isDragging, setIsDragging] = useState(false);
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";

  const processFile = async (file) => {
    if (!file) return;

    setError("");
    setUploading(true);
    setFileName(file.name);

    try {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const arrayBuffer = e.target.result;
        let content = "";

        if (file.name.endsWith(".docx")) {
          const result = await mammoth.extractRawText({ arrayBuffer });
          content = result.value;
        } else {
          content = await new Promise((resolve) => {
            const textReader = new FileReader();
            textReader.onload = (e) => resolve(e.target.result);
            textReader.readAsText(file);
          });
        }

        onFileContent(content);
        setUploading(false);
        setUploaded(true);
      };

      reader.readAsArrayBuffer(file);
    } catch (error) {
      console.error("Error processing file:", error);
      setError("Det gick inte att läsa filen. Försök igen.");
      setUploading(false);
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    processFile(file);
  };

  const handleDragEnter = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  }, []);

  const handleDragLeave = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  }, []);

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const file = e.dataTransfer.files[0];
    if (file) {
      // Check if file type is supported
      const isSupported = [".docx", ".doc", ".txt"].some((ext) =>
        file.name.toLowerCase().endsWith(ext),
      );

      if (!isSupported) {
        setError("Filformatet stöds inte. Använd .docx, .doc, eller .txt");
        return;
      }

      // Check file size (e.g., 5MB limit)
      const maxSize = 5 * 1024 * 1024; // 5MB in bytes
      if (file.size > maxSize) {
        setError("Filen är för stor. Maximal storlek är 5MB", "error");
        return;
      }

      processFile(file);
    }
  }, []);

  const uploadBox = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "300px",
    width: "100%",
    borderRadius: "16px",
    bgcolor: isDragging
      ? isDark
        ? "rgba(255, 255, 255, 0.1)"
        : "rgba(0, 0, 0, 0.05)"
      : isDark
        ? "rgba(255, 255, 255, 0.05)"
        : "rgba(0, 0, 0, 0.02)",
    border: "2px dashed",
    borderColor: isDragging
      ? theme.palette.primary.main
      : isDark
        ? "rgba(255, 255, 255, 0.1)"
        : "rgba(0, 0, 0, 0.1)",
    transition: "all 0.3s ease-in-out",
    "&:hover": {
      borderColor: theme.palette.primary.main,
      bgcolor: isDark ? "rgba(255, 255, 255, 0.08)" : "rgba(0, 0, 0, 0.04)",
    },
  };

  const successBox = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "300px",
    width: "100%",
    borderRadius: "16px",
    bgcolor: isDark ? "rgba(46, 125, 50, 0.15)" : "rgba(46, 125, 50, 0.08)",
    transition: "all 0.3s ease-in-out",
  };

  return (
    <Box sx={{ width: "100%", maxWidth: "800px", mx: "auto", p: 3 }}>
      <input
        type="file"
        accept=".docx,.txt,.doc"
        onChange={handleFileUpload}
        style={{ display: "none" }}
        id="file-upload-input"
      />

      <Fade in={true}>
        <Box>
          {!uploaded ? (
            <label htmlFor="file-upload-input">
              <Box
                sx={uploadBox}
                onDragEnter={handleDragEnter}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
              >
                {uploading ? (
                  <>
                    <CircularProgress size={60} sx={{ mb: 3 }} />
                    <Typography variant="h6" sx={{ mb: 1, fontWeight: 500 }}>
                      Bearbetar fil...
                    </Typography>
                  </>
                ) : (
                  <>
                    <CloudUpload
                      sx={{
                        fontSize: 60,
                        color: isDragging
                          ? theme.palette.primary.main
                          : theme.palette.primary.main,
                        mb: 3,
                        transform: isDragging ? "scale(1.1)" : "scale(1)",
                        transition: "transform 0.2s ease-in-out",
                      }}
                    />
                    <Typography variant="h6" sx={{ mb: 1, fontWeight: 500 }}>
                      {isDragging ? "Släpp filen här" : "Dra och släpp fil här"}
                    </Typography>
                    <Typography color="text.secondary" sx={{ mb: 3 }}>
                      eller
                    </Typography>
                    <Button
                      variant="purp"
                      startIcon={<FileUpload />}
                      onClick={() =>
                        document.getElementById("file-upload-input").click()
                      }
                    >
                      Välj fil
                    </Button>
                  </>
                )}
              </Box>
            </label>
          ) : (
            <Box sx={styles.successBox}>
              <CheckCircleOutline
                sx={{ fontSize: 60, color: "success.main", mb: 3 }}
              />
              <Typography
                variant="h6"
                sx={{
                  color: "success.main",
                  fontWeight: 500,
                  textAlign: "center",
                  width: "80%",
                  margin: "0 auto",
                  mb: 2,
                }}
              >
                Du har nu laddat upp filen och kan fortsätta med att{" "}
                {forTextlabbet ? " bearbeta" : "generera"}{" "}
                {forQuiz
                  ? "ditt quiz"
                  : forFlashcards
                    ? "dina flashcards"
                    : forPowerPoint
                      ? "din PowerPoint"
                      : forTextlabbet
                        ? "din text"
                        : "din lektion"}{" "}
                nedan.
              </Typography>
              <Typography color="text.secondary" sx={{ mb: 3 }}>
                {fileName}
              </Typography>
              <Button
                variant="purp"
                onClick={() => {
                  setUploaded(false);
                  setFileName("");
                  document.getElementById("file-upload-input").click();
                }}
              >
                Ladda upp en annan fil
              </Button>
            </Box>
          )}

          {error && (
            <Typography
              color="error"
              sx={{
                display: "block",
                textAlign: "center",
                mt: 2,
              }}
            >
              {error}
            </Typography>
          )}

          <Typography
            variant="caption"
            color="text.secondary"
            sx={{
              display: "block",
              textAlign: "center",
              mt: 2,
            }}
          >
            Stödda filformat: .docx, .doc, .txt
          </Typography>
        </Box>
      </Fade>
    </Box>
  );
};

export default FileUploader;
