import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Divider,
  CircularProgress,
  Tooltip,
  FormControl,
  Select,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  MenuItem,
  Grid,
  TextField,
} from "@mui/material";

import EscapeRoomEditor from "./EscapeRoomEditor";
import PsychologyIcon from "@mui/icons-material/Psychology";
import SlideInPaper from "./SlideInPaper";
import GenerateIcon from "@mui/icons-material/AutoAwesome";
import ImageIcon from "@mui/icons-material/Image";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import PrintIcon from "@mui/icons-material/Print";
import ShareLink from "./ShareLink";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import EscapeRoomGame from "./EscapeRoomGame";
import Translate from "./Translate";
import { callApi } from "./api";

import { db } from "./firebase";
import {
  updateDoc,
  collection,
  query,
  where,
  limit,
  getDocs,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";

import Logger from "./Logger";

import TextFieldsIcon from "@mui/icons-material/TextFields";

import LanguageSelector from "./LanguageSelector";
import ClearIcon from "@mui/icons-material/Clear";
import { ThemeSelectorFlow } from "./ThemeSelectorFlow";
import InfoBox from "./InfoBox";
import { handlePrintWordCompLesson } from "./printFunctions";
import {
  generateAndProcessImage,
  handleImageGenerationError,
} from "./functions";
import { useSnackbar } from "./SnackbarHandler";
import ProgressBar from "./ProgressBar";

import ImageGenerationModal from "./ImageGenerationModal";
import GradeSelector, { gradeInstructions } from "./GradeSelector";
import { useTheme } from "@mui/material/styles";
import HistoryIcon from "@mui/icons-material/History";
import { getStyles } from "../index";
import { trackLesson } from "./functions";

import LessonDrawer from "./LessonDrawer";
import SaveLessonDialog from "./SaveLessonDialog";
import HoverableImage from "./HoverableImage";
import { getThemes } from "./additionalThemes/EscapeRoom";
import { useSearchParams, useParams } from "react-router-dom";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import IconButton from "@mui/material/IconButton";

import ShareDialog from "./ShareDialog";
import ShareIcon from "@mui/icons-material/Share";
import LoadingBackdrop from "./LoadingBackdrop";

const EscapeRoomLesson = ({ user }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);

  const lessonTitleRef = useRef(null); // Add this ref
  const [isExpanded, setIsExpanded] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const pdfButtonRef = useRef(null);
  const [showControlPanel, setShowControlPanel] = useState(false);
  const shareLinkButtonRef = useRef(null);
  const [lessonTheme, setLessonTheme] = useState("");
  const [language, setLanguage] = useState("svenska");
  const [duration, setDuration] = useState(60);
  const [amountOfQuestions, setAmountOfQuestions] = useState(5);

  const [grade, setGrade] = useState("åk6");
  const [progress, setProgress] = useState(0);
  const [isGeneratingLesson, setIsGeneratingLesson] = useState(false);
  const [isGeneratingImages, setIsGeneratingImages] = useState(false);

  const [customTheme, setCustomTheme] = useState("");
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageInstructions, setImageInstructions] = useState("");
  const [openAiInstructions, setOpenAiInstructions] = useState("");
  const urlContentRef = useRef("");
  const [loadingImageIndex, setLoadingImageIndex] = useState(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const [shareDialogOpen, setShareDialogOpen] = useState(false);

  const [soundActivated, setSoundActivated] = useState(null);
  const [audio] = useState(
    new Audio("https://sportkuriren.se/wp-content/uploads/horror.mp3"),
  );

  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [predefinedTheme, setPredefinedTheme] = useState(null);
  const [lessonData, setLessonData] = useState(null);
  const subject = searchParams.get("subject") || null;
  const viewMode = searchParams.get("v") === "t";
  const [isSharing, setIsSharing] = useState(false);
  const [shareDialogOpenFriends, setShareDialogOpenFriends] = useState(false);
  const additionalThemes = getThemes(subject);

  const { showSnackbar } = useSnackbar();
  const theme = useTheme();
  const styles = getStyles(theme);

  const transcriptRef = useRef("");
  const uploadedFileRef = useRef("");
  const translateButtonRef = useRef(null);
  const bookContentRef = useRef("");

  const wikipediaContentRef = useRef("");
  const randomizedContentRef = useRef("");
  const coursePlanContentRef = useRef("");

  useEffect(() => {
    Logger.log("subject: ", subject);
  }, [subject]);
  useEffect(() => {
    Logger.log("lessonTheme: ", lessonTheme);
  }, [lessonTheme]);

  useEffect(() => {
    Logger.log("predefinedTheme: ", predefinedTheme);
  }, [predefinedTheme]);

  useEffect(() => {
    Logger.log("customTheme: ", customTheme);
  }, [customTheme]);

  useEffect(() => {
    Logger.log("youtubeTranscript: ", transcriptRef.current);
  }, [transcriptRef.current]);

  useEffect(() => {
    Logger.log("wikipediaContentRef: ", wikipediaContentRef.current);
  }, [wikipediaContentRef.current]);
  useEffect(() => {
    Logger.log("uploadedFileRef: ", uploadedFileRef.current);
  }, [uploadedFileRef.current]);

  useEffect(() => {
    Logger.log("randomizedContentRef: ", randomizedContentRef.current);
  }, [randomizedContentRef.current]);

  useEffect(() => {
    Logger.log("coursePlanContentRef: ", coursePlanContentRef.current);
  }, [coursePlanContentRef.current]);

  const { lessonId } = useParams();
  useEffect(() => {
    const loadLessonFromId = async () => {
      if (lessonId) {
        try {
          const q = query(
            collection(db, "lesson_usage"),
            where("__name__", ">=", lessonId),
            where("__name__", "<=", lessonId + "\uf8ff"),
            where("lessonType", "==", "EscapeRoomLesson.js"),
            limit(1),
          );

          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            const docData = querySnapshot.docs[0].data();

            if (!docData.hasOpened) {
              await updateDoc(querySnapshot.docs[0].ref, {
                hasOpened: true,
                openedAt: Date.now(),
              });
            }

            setLessonData(docData.data);

            // Scroll to lesson content
            setTimeout(() => {
              lessonTitleRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }, 100);

            showSnackbar("Lektionen har laddats in.", "success");
          } else {
            showSnackbar("Kunde inte hitta lektionen", "error");
          }
        } catch (error) {
          console.error("Error loading lesson:", error);
          showSnackbar("Ett fel uppstod när lektionen skulle laddas", "error");
        }
      }
    };

    loadLessonFromId();
  }, lessonId);

  useEffect(() => {
    Logger.log("lessonData: ", lessonData);
    const isDataValid =
      lessonData !== null &&
      lessonData !== undefined &&
      Object.keys(lessonData).length > 0 &&
      !Object.values(lessonData).includes("");

    if (isDataValid !== showControlPanel) {
      const timer = setTimeout(() => {
        setShowControlPanel(isDataValid);
      }, 100);

      return () => clearTimeout(timer);
    }
  }, [lessonData, showControlPanel]);

  const toggleViewMode = () => {
    const currentParams = Object.fromEntries(searchParams.entries());
    if (currentParams.v === "t") {
      delete currentParams.v;
    } else {
      currentParams.v = "t";
    }
    setSearchParams(currentParams);
  };

  const handleActivateSound = () => {
    if (soundActivated) {
      audio.pause();
      audio.currentTime = 0;
    } else {
      audio.loop = true;
      audio.play();
    }
    setSoundActivated(!soundActivated);
  };

  const handleSaveLessonClick = () => {
    setSaveDialogOpen(true);
  };

  const handleSaveWithComment = async (comment, name, coverImage, tags) => {
    Logger.log(JSON.stringify(lessonData, null, 2));
    await trackLesson(
      user.uid,
      "EscapeRoomLesson.js",
      lessonData,
      null,
      true,
      comment,
      name,
      coverImage,
      tags,
    );
    setDrawerOpen(true);
    showSnackbar("Lektionen sparad i din lektionsbank!", "success");
  };

  const handleShare = async (
    lessonComment,
    lessonName,
    selectedImage,
    tags,
    shareData,
  ) => {
    setIsSharing(true);
    await new Promise((resolve) => setTimeout(resolve, 1500));
    // try {
    //   // Create a document for each student
    //   const sharePromises = shareData.studentIds.map((studentId) => {
    //     const documentData = {
    //       userId: user.uid,
    //       shared: true,
    //       sharedTo: studentId,
    //       comment: shareData.comment,
    //       sharedAt: Date.now(),
    //       fileUrl: shareData.fileUrl,
    //       lessonType: "EscapeRoomLesson.js",
    //       timestamp: serverTimestamp(),
    //       lessonComment,
    //       lessonName,
    //       coverImage: selectedImage,
    //       hasPrinted: true,
    //       data: lessonData,
    //     };
    //     Logger.log(
    //       "Adding document for student",
    //       studentId,
    //       ":",
    //       JSON.stringify(documentData, null, 2),
    //     );
    //     return addDoc(collection(db, "lesson_usage"), documentData);
    //   });
    //   await Promise.all(sharePromises);
    //   showSnackbar(`Du har nu delat lektionen!`, "success");
    // }
    try {
      // Create a document for each student and then create notifications with the doc IDs
      const sharePromises = shareData.studentIds.map(async (studentId) => {
        const documentData = {
          userId: user.uid,
          shared: true,
          sharedTo: studentId,
          comment: shareData.comment,
          sharedAt: Date.now(),
          fileUrl: shareData.fileUrl,
          lessonType: "EscapeRoomLesson.js", // Kept the original lesson type
          timestamp: serverTimestamp(),
          lessonComment,
          lessonName,
          coverImage: selectedImage,
          hasPrinted: true,
          data: lessonData,
        };
        // Create the lesson document and get its ID
        const lessonDoc = await addDoc(
          collection(db, "lesson_usage"),
          documentData,
        );
        Logger.log(
          "Adding document for student",
          studentId,
          ":",
          JSON.stringify(documentData, null, 2),
        );
        // Create notification with the lesson doc ID
        await addDoc(collection(db, "notifications"), {
          type: "lesson_shared",
          recipientId: studentId,
          senderId: user.uid,
          read: false,
          createdAt: serverTimestamp(),
          lessonData: {
            lessonId: lessonDoc.id,
            lessonType: documentData.lessonType,
            lessonName: documentData.lessonName,
            fileUrl: documentData.fileUrl,
            comment: documentData.comment,
            coverImage: selectedImage,
          },
        });
        return lessonDoc;
      });
      await Promise.all(sharePromises);
      showSnackbar(`Du har nu delat lektionen!`, "success");
    } catch (error) {
      showSnackbar("Fel vid delande av lektionen: " + error.message, "error");
      console.error(error);
    } finally {
      setIsSharing(false);
    }
  };

  const simulateProgress = (startProgress, endProgress, duration) => {
    const stepSize = 0.5;
    const steps = (endProgress - startProgress) / stepSize;
    const stepDuration = duration / steps;
    let currentProgress = startProgress;

    const intervalId = setInterval(() => {
      currentProgress += stepSize;
      if (currentProgress >= endProgress) {
        clearInterval(intervalId);
      } else {
        setProgress(currentProgress);
      }
    }, stepDuration);

    return () => clearInterval(intervalId);
  };

  const handleGenerateLesson = async () => {
    setProgress(0);
    let stopProgressSimulation = null;
    setIsGeneratingLesson(true);
    const gradeInstructionsText = gradeInstructions[grade];
    setLessonData(null);

    try {
      let themeToUse;

      if (lessonTheme === "youtube") {
        themeToUse = transcriptRef.current;
      } else if (lessonTheme === "customTheme") {
        themeToUse = customTheme;
      } else if (lessonTheme === "predefined") {
        themeToUse = predefinedTheme;
      } else if (lessonTheme === "fileUpload") {
        themeToUse = uploadedFileRef.current;
      } else if (lessonTheme === "wikipedia") {
        themeToUse = wikipediaContentRef.current;
      } else if (lessonTheme === "randomizer") {
        themeToUse = randomizedContentRef.current;
      } else if (lessonTheme === "book") {
        themeToUse = bookContentRef.current;
      } else if (lessonTheme === "url") {
        themeToUse = urlContentRef.current;
      } else {
        themeToUse = coursePlanContentRef.current;
      }

      if (!themeToUse.trim()) {
        showSnackbar("Du måste välja underlag i steg 1.", "warning");
        return;
      }

      Logger.log("themeToUse: ", themeToUse);

      //themeToUse = "Sannolikhetslära inom matematik. Årskurs 8.";

      stopProgressSimulation = simulateProgress(0, 90, 15000);

      const generateEscapeRoomMathStory = async (
        theme = "rymd",
        difficulty = "avancerad",
      ) => {
        const difficultyInstructions = {
          enkel:
            "Använd enkel matematik som addition eller subtraktion med små tal.",
          medel:
            "Inkludera multiplikation, division, eller enklare bråk. Lägg till minst två steg i beräkningen.",
          svår: "Använd decimaler, bråk, eller flera beräkningssteg. Inkludera logik.",
          avancerad: `
          Inkludera avancerad matematik som:
          - Geometri (beräkning av area, omkrets, eller volym).
          - Algebraiska uttryck (lös ekvationer eller optimera funktioner).
          - Sannolikhet (beräkna utfall av händelser).
          - Statistik (medelvärde, median, standardavvikelse).
          Gör berättelsen realistisk och använd ett dynamiskt tema (t.ex. konserter, sportevenemang, vetenskap, eller rymden).
        `,
        };

        const prompt = `
        Du är en expertlärare som skapar ett  escape room för elever. Följ dessa riktlinjer:
        - Tema: ${themeToUse}.
        
        - Berättelsen ska vara engagerande och inkludera frågor som elever måste svara rätt på för att låsa upp nästa kodfragment.
        - Varje fråga ska ha exakt tre tips (hints) och ett kodfragment kopplat till sig.
        - Varje fråga ska ha ett "storyProgression"-element som beskriver ett spännande scenario som gör att eleverna känner sig involverade i berättelsen.  Exempel: En händelse som förvärrar situationen, avslöjar en ny ledtråd eller tvingar dem att agera snabbt. Det ska skapa en känsla av nödvändighet att lösa nästa fråga.
        - Om alla frågor är lösta korrekt, ska en slutlig "escape code" presenteras.
        - Årskurs: "${gradeInstructionsText}".          
        - Antalet frågor ska vara EXAKT ${amountOfQuestions} stycken.
        - Lektionen ska vara på ${language}.

        Svara i följande JSON-format:
        {
          "title": "Berättelsens titel",
          "story": "En introduktion till escape room-historien.",
          "questions": [
            {
              "question": "Fråga 1",
              "storyProgression": "Ett engagerande scenario som gör berättelsen mer spännande och kopplar till frågan.",
              "solution": "Rätt svar för frågan",
              "codeFragment": "Kodfragment 1",
              "hints": ["Tips 1", "Tips 2", "Tips 3"]
            },
            {
              "question": "Fråga 2",
              "storyProgression": "Ett engagerande scenario som gör berättelsen mer spännande och kopplar till frågan.",
              "solution": "Rätt svar för frågan",
              "codeFragment": "Kodfragment 2",
              "hints": ["Tips 1", "Tips 2", "Tips 3"]
            }
          ],
          "finalEscapeCode": "Den slutliga koden eleverna behöver för att avsluta.",
          "conclusion": "Avslutningen på berättelsen när alla frågor är lösta."
        }
      `;

        return prompt;
      };

      let response = await callApi(
        [
          {
            role: "system",
            content: `You are an expert teacher creating engaging lessons in ${language}. Follow the exact JSON structure provided in the prompt.`,
          },
          {
            role: "user",
            content: await generateEscapeRoomMathStory(),
          },
        ],
        16000,
        true,
        user.uid,
      );

      if (stopProgressSimulation) stopProgressSimulation();
      setProgress(95);

      let messageContent = response.data.choices[0].message.content.trim();
      if (messageContent.startsWith("```json")) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith("```")) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      const data = JSON.parse(messageContent);
      setProgress(100);

      setLessonData({
        ...data,
        imageUrl: null,
        duration: duration,
      });

      await new Promise((resolve) => setTimeout(resolve, 800));
      Logger.log("data: ", JSON.stringify(data, null, 2));

      setTimeout(() => {
        lessonTitleRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, 100);

      await trackLesson(user.uid, "EscapeRoomLesson.js", null, {
        language: language,
        lessonTheme: lessonTheme,
      });

      showSnackbar("Din lektion har genererats!", "success");
    } catch (error) {
      console.error("Error generating lesson:", error);
      showSnackbar(
        "Ett fel uppstod när lektionen skulle genereras. Försök igen!",
        "error",
      );
    } finally {
      setIsGeneratingLesson(false);
    }
  };

  /// Image Generation code
  /// Image Generation code

  const handleGenerateImages = async () => {
    if (!lessonData) return;
    setIsGeneratingImages(true);
    handleCloseModal();

    try {
      const imagePrompt = `Create an image with the theme: "${lessonData.title}". ${imageInstructions}`;
      const imagePromises = lessonData.images.map(() =>
        generateAndProcessImage(imagePrompt, user.uid),
      );
      const imageUrls = await Promise.all(imagePromises);

      setLessonData((prevData) => ({
        ...prevData,
        images: imageUrls.map((imageUrl) => ({ imageUrl })),
      }));

      showSnackbar("Bilderna har genererats!", "success");
    } catch (error) {
      handleImageGenerationError(error, true, showSnackbar);
    } finally {
      setIsGeneratingImages(false);
      setLoadingImageIndex(null);
    }
  };

  const handleGenerateSpecificImage = async (index) => {
    setIsGeneratingImages(true);
    setLoadingImageIndex(index);
    handleCloseModal();

    try {
      const imagePrompt = `Create an image with the theme: "${lessonData.title}". ${imageInstructions}`;
      const imageUrl = await generateAndProcessImage(imagePrompt, user.uid);

      if (imageUrl) {
        setLessonData((prevData) => ({
          ...prevData,
          imageUrl: imageUrl,
        }));
        showSnackbar("Bilden har genererats!", "success");
      }
    } catch (error) {
      handleImageGenerationError(error, false, showSnackbar);
    } finally {
      setIsGeneratingImages(false);
      setLoadingImageIndex(null);
    }
  };

  const handleImageButtonClick = (index = null) => {
    setSelectedImageIndex(index);
    setShowImageModal(true);
  };
  const handleCloseModal = () => {
    setShowImageModal(false);
    setImageInstructions("");
  };

  const handleGenerateWithInstructions = async () => {
    if (selectedImageIndex !== null) {
      handleCloseModal();
      await handleGenerateSpecificImage(selectedImageIndex);
    } else {
      handleCloseModal();
      await handleGenerateImages();
    }
  };

  return (
    <Box sx={styles.container}>
      <Box
        sx={{
          ...styles.innerContainer,
          width: { xs: "100%", md: "100%" },
          margin: "0 auto",
        }}
      >
        {!viewMode && (
          <Typography
            variant="h1"
            gutterBottom
            sx={{
              fontWeight: "bold",
              fontSize: "1.8em",
              mb: 3,
              mt: 5,
              textAlign: "center",
            }}
          >
            Escape Room
          </Typography>
        )}
        {viewMode && (
          <Box sx={{ position: "absolute", top: 40, left: 45, zIndex: 99999 }}>
            <IconButton onClick={toggleViewMode}>
              <ClearIcon sx={{ fontSize: "30px" }} />
            </IconButton>

            <IconButton onClick={handleActivateSound}>
              {soundActivated ? <VolumeUpIcon /> : <VolumeOffIcon />}
            </IconButton>
          </Box>
        )}
        <Box
          sx={{
            ...styles.buttonsContainer,
            ...styles.additionalButtonsContainer,
          }}
        >
          {lessonData && user.accountType === "teacher" && (
            <Button
              variant="purp"
              sx={{ ...styles.tightButton, zIndex: 9999 }}
              disableRipple
              onClick={() => {
                setTimeout(() => {
                  lessonTitleRef.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "start", // This ensures it scrolls to the top of the element
                  });
                }, 100);
                toggleViewMode();
              }}
              startIcon={
                searchParams.get("v") === "t" ? (
                  <FullscreenExitIcon />
                ) : (
                  <FullscreenIcon />
                )
              }
            >
              {searchParams.get("v") === "t" ? "Redigeringsläge" : "Elevläge"}
            </Button>
          )}
          {!viewMode && (
            <Button
              variant="purp"
              sx={{ ...styles.tightButton }}
              onClick={() => setDrawerOpen(true)}
              startIcon={<BookmarkBorderIcon />}
            >
              Sparat
            </Button>
          )}
        </Box>
        <LessonDrawer
          userId={user.uid}
          lessonType={"EscapeRoomLesson.js"}
          theme={theme}
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          lessonIcon={TextFieldsIcon}
          onSelectLesson={(lesson) => {
            setLessonData(lesson.data);
            setDrawerOpen(false);
            setTimeout(() => {
              lessonTitleRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "start", // This ensures it scrolls to the top of the element
              });
            }, 100);
            showSnackbar(`Lektionen har laddats in.`, "success");
          }}
        />

        {!viewMode && (
          <>
            <InfoBox
              icon={PsychologyIcon}
              title="EscapeRoomLesson"
              instructions={[
                "Välj underlag för vad ditt Escape Room ska handla om",
                "Redigera frågor, svar och ledtrådar efter behov och önskad svårighetsgrad",
                "Dela med dina elever. Och se vilkens slutgiltiga tid som är bäst efter tidspålägg från använda ledtrådar",
              ]}
              theme={theme}
              styles={styles}
            />

            <ThemeSelectorFlow
              key={subject}
              subject={subject}
              value={lessonTheme}
              onChange={(e) => setLessonTheme(e.target.value)}
              customTheme={customTheme}
              onCustomThemeChange={(e) => setCustomTheme(e.target.value)}
              onPredefinedThemeChange={(e) =>
                setPredefinedTheme(e.target.value)
              }
              onUrlContentChange={(content) => {
                Logger.log(
                  "Parent: URL content received",
                  content.substring(0, 300),
                );
                urlContentRef.current = content;
              }}
              onBookContentChange={(content) => {
                Logger.log("onBookContentChange", content.substring(0, 100));
                bookContentRef.current = content;
              }}
              onYoutubeTranscriptChange={(transcriptContent) => {
                Logger.log(
                  "onYoutubeTranscriptChange körs med: ",
                  transcriptContent,
                );
                transcriptRef.current = transcriptContent;
              }}
              onFileUpload={(content) => {
                Logger.log("onFileUpload körs med: ", content);
                uploadedFileRef.current = content;
              }}
              onWikipediaContentChange={(content) => {
                Logger.log(
                  "Parent: Wikipedia content received",
                  content.substring(0, 100),
                );
                wikipediaContentRef.current = content;
              }}
              onRandomizedContentChange={(content) => {
                Logger.log("randomizedContentRef", content.substring(0, 100));
                randomizedContentRef.current = content;
              }}
              onCoursePlanContentChange={(content) => {
                Logger.log("onCoursePlanChange", content.substring(0, 100));
                coursePlanContentRef.current = content;
              }}
              lessonType={"EscapeRoomLesson.js"}
              user={user}
              theme={theme}
              styles={styles}
              onLessonSelect={(lesson) => {
                Logger.log("onLessonSelect körs");
                setLessonData(lesson.data);
                setTimeout(() => {
                  lessonTitleRef.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "start", // This ensures it scrolls to the top of the element
                  });
                }, 100);
                showSnackbar("Lektionen har laddats in.", "success");
              }}
              additionalThemes={additionalThemes}
            />

            <Box sx={styles.headlineLessonBox}>
              <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
                2. Generera lektionen
              </Typography>
            </Box>
            <Box sx={{ ...styles.freshBox, padding: "40px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "500", mb: 1 }}
                  >
                    Årskurs:
                  </Typography>
                  <Box sx={{ mt: 0.5, mb: 3 }}>
                    <GradeSelector grade={grade} setGrade={setGrade} />
                  </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle1" sx={{ fontWeight: "500" }}>
                    Språk:
                  </Typography>
                  <Box sx={{ mt: 1, mb: 3 }}>
                    <LanguageSelector
                      language={language}
                      setLanguage={setLanguage}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle1" sx={{ fontWeight: "500" }}>
                    Antal frågor
                  </Typography>
                  <Box sx={{ mt: 1, mb: 3 }}>
                    <FormControl fullWidth>
                      <Select
                        value={amountOfQuestions}
                        onChange={(e) => setAmountOfQuestions(e.target.value)}
                        variant="outlined"
                      >
                        <MenuItem value={1}>1 fråga</MenuItem>
                        <MenuItem value={5}>5 frågor</MenuItem>

                        <Tooltip title="Endast tillgängligt för premium" arrow>
                          <span>
                            <MenuItem value={10} disabled>
                              10 frågor
                            </MenuItem>
                            <MenuItem value={15} disabled>
                              15 frågor
                            </MenuItem>
                            <MenuItem value={20} disabled>
                              20 frågor
                            </MenuItem>
                            <MenuItem value={30} disabled>
                              30 frågor
                            </MenuItem>
                            <MenuItem value={40} disabled>
                              40 frågor
                            </MenuItem>
                            <MenuItem value={50} disabled>
                              50 frågor
                            </MenuItem>
                          </span>
                        </Tooltip>
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle1" sx={{ fontWeight: "500" }}>
                    Antal sekunder för varje fråga:
                  </Typography>
                  <Box sx={{ mt: 1, mb: 3 }}>
                    <FormControl fullWidth>
                      <Select
                        value={duration}
                        onChange={(e) => setDuration(e.target.value)}
                        variant="outlined"
                      >
                        <MenuItem value={30}>30 sekunder</MenuItem>
                        <MenuItem value={60}>60 sekunder</MenuItem>
                        <MenuItem value={120}>
                          120 sekunder (2 minuter)
                        </MenuItem>
                        <MenuItem value={240}>
                          240 sekunder (4 minuter)
                        </MenuItem>
                        <MenuItem value={300}>
                          300 sekunder (5 minuter)
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
              </Grid>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "12px",
                  width: "100%",
                }}
              >
                <Button
                  variant={
                    theme.palette.mode === "light" ? "purp" : "contained"
                  }
                  color="primary"
                  onClick={handleGenerateLesson}
                  disabled={isGeneratingLesson}
                  sx={{ mt: 4 }}
                  startIcon={
                    !isGeneratingLesson ? (
                      <GenerateIcon style={{ color: "#fff" }} />
                    ) : (
                      <CircularProgress style={{ color: "#fff" }} size={16} />
                    )
                  }
                >
                  {isGeneratingLesson
                    ? "Generera lektionen..."
                    : "Generera lektion"}
                </Button>
              </div>
              {isGeneratingLesson && (
                <Box sx={{ mt: 6 }}>
                  <ProgressBar
                    progress={progress}
                    label="Genererar lektion — tar 30-60 sekunder..."
                  />
                </Box>
              )}
            </Box>
          </>
        )}

        {viewMode && (
          <EscapeRoomGame
            lessonData={lessonData}
            styles={styles}
            theme={theme}
            duration={duration}
            user={user}
            viewMode={viewMode}
            toggleViewMode={toggleViewMode}
          />
        )}

        {lessonData && (
          <>
            {!viewMode && (
              <Box
                ref={lessonTitleRef}
                sx={{ ...styles.headlineLessonBox, mt: 10 }}
              >
                <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
                  3. Din lektion
                </Typography>
              </Box>
            )}
            {!viewMode && (
              <Box sx={{ ...styles.freshBox, padding: "30px" }}>
                <Box sx={{ position: "relative" }}>
                  <Box
                    sx={{
                      ...styles.lessonControlPanel,
                      ...styles.lessonControlPanelNuevo,

                      "& .MuiButton-root": {
                        ...styles.controlPanelButtons,
                        ...styles.lessonControlPanelNewButtons,
                      },
                    }}
                  >
                    <Button
                      variant="purp"
                      startIcon={<EditIcon />}
                      onClick={() => setIsEditorOpen(true)}
                    >
                      Redigera Escape Room
                    </Button>

                    <Button
                      variant="print"
                      startIcon={<SaveAsIcon />}
                      sx={{ ml: 2 }}
                      onClick={handleSaveLessonClick}
                    >
                      Spara i min lektionsbank
                    </Button>
                    <Button
                      variant="success"
                      sx={{}}
                      onClick={() => setShareDialogOpenFriends(true)}
                      startIcon={<ShareIcon />}
                    >
                      Dela med vänner
                    </Button>

                    <Button
                      variant="success"
                      sx={{
                        ml: 2,
                      }}
                      onClick={() => setShareDialogOpen(true)}
                      startIcon={<ShareIcon />}
                    >
                      Dela med elever
                    </Button>

                    <ShareLink
                      buttonRef={shareLinkButtonRef}
                      key={lessonId}
                      lessonId={lessonId}
                      lessonData={lessonData}
                      user={user}
                      lessonType={"EscapeRoomLesson.js"}
                      onCopy={(message, severity) =>
                        showSnackbar(message, severity)
                      }
                      sx={{ mt: 0 }}
                    />

                    <Translate
                      buttonRef={translateButtonRef}
                      lessonData={lessonData}
                      onTranslated={setLessonData}
                      callApi={callApi}
                      user={user}
                      showSnackbar={showSnackbar}
                    />

                    <Tooltip title="Hur lektionen ser ut för eleverna">
                      <Button
                        variant="success"
                        onClick={() => {
                          window.scrollTo({ top: 0, behavior: "smooth" });
                          toggleViewMode();
                        }}
                        sx={{ ml: 2 }}
                        startIcon={
                          searchParams.get("v") === "t" ? (
                            <FullscreenExitIcon />
                          ) : (
                            <FullscreenIcon />
                          )
                        }
                      >
                        {searchParams.get("v") === "t"
                          ? "Redigeringsläge"
                          : "Elevläge"}
                      </Button>
                    </Tooltip>
                    <EscapeRoomEditor
                      open={isEditorOpen}
                      onClose={() => setIsEditorOpen(false)}
                      lessonData={lessonData}
                      onSave={(editedData) => {
                        setLessonData(editedData);
                        showSnackbar("Ändringarna har sparats!", "success");
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      ...styles.lessonControlPanelFade,
                    }}
                  />
                </Box>

                <EscapeRoomGame
                  lessonData={lessonData}
                  styles={styles}
                  theme={theme}
                  duration={duration}
                  user={user}
                  viewMode={viewMode}
                  toggleViewMode={toggleViewMode}
                />

                {!viewMode && (
                  <>
                    <Divider sx={{ mt: 15, mb: 10 }} />

                    <Box sx={{ maxWidth: "400px", mx: "auto", mb: 4 }}>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontSize: "1rem",
                          color: theme.palette.text.secondary,
                          textAlign: "center",
                          mb: 2,
                        }}
                      >
                        Generera en lektionsbild som du kan använda när du
                        sparar din lektion för att enklare hitta den vid senare
                        tillfälle.
                      </Typography>

                      <HoverableImage
                        imageUrl={lessonData.imageUrl}
                        onGenerateClick={() => handleImageButtonClick(0)}
                        isLoading={isGeneratingImages}
                        loadingIndex={loadingImageIndex}
                        currentIndex={0}
                        userId={user.uid}
                        onImageUpdate={(url) =>
                          setLessonData((prev) => ({ ...prev, imageUrl: url }))
                        }
                        theme={theme}
                        showSnackbar={showSnackbar}
                      />
                    </Box>
                  </>
                )}

                <SaveLessonDialog
                  key={lessonData?.title}
                  open={saveDialogOpen}
                  onClose={() => setSaveDialogOpen(false)}
                  onSave={handleSaveWithComment}
                  theme={theme}
                  lessonName={lessonData?.title || ""}
                  lessonObject={lessonData}
                  lessonType={"EscapeRoomLesson.js"}
                />

                <SlideInPaper
                  isExpanded={isExpanded}
                  onExpandedChange={setIsExpanded}
                  lessonTitleRef={lessonTitleRef}
                  viewMode={viewMode}
                  toggleViewMode={toggleViewMode}
                  theme={theme}
                  open={showControlPanel}
                  user={user}
                  onTranslate={() => translateButtonRef.current?.click()}
                  onShareLink={() => shareLinkButtonRef.current?.click()}
                  onShareWithStudents={() => setShareDialogOpen(true)}
                  onShareWithFriends={() => setShareDialogOpenFriends(true)}
                  onEditDialog={() => setIsEditorOpen(true)}
                  onSaveToBank={() => setSaveDialogOpen(true)}
                  onStudentMode={() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                    toggleViewMode();
                  }}
                />

                <ShareDialog
                  open={shareDialogOpen}
                  onClose={() => setShareDialogOpen(false)}
                  onShare={handleShare}
                  user={user}
                  isLessonSharing={true}
                  theme={theme}
                  lessonName={lessonData?.title || ""}
                  lessonObject={lessonData}
                  lessonType={"EscapeRoomLesson.js"}
                />

                <ShareDialog
                  open={shareDialogOpenFriends}
                  onClose={() => setShareDialogOpenFriends(false)}
                  onShare={handleShare}
                  user={user}
                  isLessonSharing={true}
                  theme={theme}
                  lessonName={lessonData?.title || ""}
                  lessonObject={lessonData}
                  lessonType={"EscapeRoomLesson.js"}
                  shareWithFriends={true}
                />

                <LoadingBackdrop
                  isOpen={isSharing}
                  imagePath="/img/AI.png"
                  loadingText={"Delar lektionen..."}
                />

                <ImageGenerationModal
                  open={showImageModal}
                  onClose={handleCloseModal}
                  onGenerate={handleGenerateWithInstructions}
                  isGenerating={isGeneratingImages}
                  instructions={imageInstructions}
                  setInstructions={setImageInstructions}
                  theme={theme}
                />
              </Box>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default EscapeRoomLesson;
