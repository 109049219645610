import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Box,
  Typography,
  Chip,
  Tooltip,
} from "@mui/material";
import { shortcuts } from "./DashboardToolsShortcuts";
import ShortcutBox from "./ShortcutBox";
import { useSnackbar } from "./SnackbarHandler";
import { updateDoc, doc } from "firebase/firestore";
import { db } from "./firebase";
import GenerateIcon from "@mui/icons-material/AutoAwesome";

const ShortcutEditorDialog = ({
  open,
  onClose,
  room,
  onUpdate,
  user,
  styles,
}) => {
  const [selectedTools, setSelectedTools] = useState([]);
  const { showSnackbar } = useSnackbar();

  // Update selectedTools whenever room changes
  React.useEffect(() => {
    if (room?.shortcuts) {
      const toolsFromRoom = shortcuts.filter((shortcut) =>
        room.shortcuts.includes(shortcut.id),
      );
      setSelectedTools(toolsFromRoom);
    }
  }, [room]);
  const [selectedCategory, setSelectedCategory] = useState("Populära");

  const studentShortcuts = shortcuts.filter((shortcut) =>
    shortcut.roles.includes("student"),
  );

  const handleToolToggle = (shortcut) => {
    setSelectedTools((prev) => {
      const isSelected = prev.some((tool) => tool.id === shortcut.id);
      return isSelected
        ? prev.filter((tool) => tool.id !== shortcut.id)
        : [...prev, shortcut];
    });
  };

  const handleSave = async () => {
    if (!room || !room.id) return;

    try {
      const toolIds = selectedTools.map((tool) => tool.id);
      await updateDoc(doc(db, "rooms", room.id), {
        shortcuts: toolIds,
      });

      showSnackbar("Dina ändringar har sparats!", "success");

      onUpdate && onUpdate();
      onClose();
    } catch (error) {
      console.error("Error updating shortcuts:", error);
      showSnackbar("Nåt gick fel. Vänligen försök igen.", "error");
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: "8px",
          p: 2,
        },
      }}
    >
      <DialogTitle sx={{ pb: 1, fontWeight: "700", fontSize: "1.3rem" }}>
        Redigera tillgång till verktyg för eleverna i {room?.name}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          <Box sx={{ ...styles.headlineLessonBox, mt: 6 }}>
            <Typography
              variant="h6"
              sx={{ ...styles.headlineLessonBoxTitle, textAlign: "left" }}
            >
              Valda verktyg
            </Typography>
          </Box>
          <Grid container spacing={2} sx={{ mb: 4 }}>
            {selectedTools.length === 0 ? (
              <Grid item xs={12} sm={6} md={4}>
                <ShortcutBox
                  icon={<GenerateIcon />}
                  title="Inga verktyg valda"
                  subtitle="Välj verktyg nedan som ska vara tillgängliga i rummet."
                  isEmpty={true}
                />
              </Grid>
            ) : (
              selectedTools.map((tool) => (
                <Grid item xs={12} sm={6} md={4} key={tool.id}>
                  <ShortcutBox
                    {...tool}
                    user={user}
                    isRoomCreatingView={true}
                    isSelected={true}
                    onSelect={() => handleToolToggle(tool)}
                  />
                </Grid>
              ))
            )}
          </Grid>

          <Box sx={{ ...styles.headlineLessonBox, mt: 6 }}>
            <Typography
              variant="h6"
              sx={{ ...styles.headlineLessonBoxTitle, textAlign: "left" }}
            >
              Tillgängliga verktyg
            </Typography>
          </Box>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mb: 3 }}>
            {[
              "Populära",
              ...new Set(studentShortcuts.map((shortcut) => shortcut.category)),
            ]
              .filter(Boolean)
              .map((category) => (
                <Chip
                  key={category}
                  icon={category === "Populära" ? <GenerateIcon /> : null}
                  label={category}
                  onClick={() => setSelectedCategory(category)}
                  color={selectedCategory === category ? "primary" : "default"}
                  sx={{
                    fontWeight: "500",
                    padding: "7px",
                    paddingTop: "12px",
                    paddingBottom: "12px",
                    fontSize: "0.8rem",
                  }}
                />
              ))}
          </Box>

          <Grid container spacing={2}>
            {studentShortcuts
              .filter(
                (shortcut) =>
                  !selectedTools.some((tool) => tool.id === shortcut.id) &&
                  (selectedCategory === "Populära" ||
                    shortcut.category === selectedCategory),
              )
              .map((shortcut) => (
                <Grid item xs={12} sm={6} md={4} key={shortcut.id}>
                  <ShortcutBox
                    {...shortcut}
                    user={user}
                    isRoomCreatingView={true}
                    onSelect={() => handleToolToggle(shortcut)}
                  />
                </Grid>
              ))}
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions sx={{ px: 3, pb: 2 }}>
        <Button onClick={onClose} variant="print">
          Avbryt
        </Button>
        <Button
          onClick={handleSave}
          variant="purp"
          disabled={selectedTools.length === 0}
        >
          Spara ändringar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShortcutEditorDialog;
