// AskAI.js
import React, { useState, useEffect } from "react";
import { Typography, Box, Button, Tooltip, Avatar } from "@mui/material";

import Prompts from "./Prompts";
import MessageBubble from "./MessageBubble";
import TextInput from "./TextInput";
import AddIcon from "@mui/icons-material/Add";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import { Helmet } from "react-helmet-async";
import SegmentIcon from "@mui/icons-material/Segment";
import SkeletonLoader from "./SkeletonLoader";
import EqualizerIcon from "./EqualizerIcon";
import MutedEqualizerIcon from "./MutedEqualizerIcon";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import useGeneralChat from "./useGeneralChat";
import { useTheme } from "@mui/material/styles";
import PromptDialog from "./PromptDialog";
import { formatUpvotes, flagUrls } from "./functions";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import TeacherInfoDrawer from "./TeacherInfoDrawer";
import { useParams } from "react-router-dom";
import PromptBoxes from "./PromptBoxes";
import {
    doc,
    getDoc,
    setDoc,
    query,
    where,
    limit,
    getDocs,
    collection,
    deleteDoc,
    addDoc,
} from "firebase/firestore";
import { db } from "./firebase";
import { useSnackbar } from "./SnackbarHandler";
import ChatHistoryDrawer from "./ChatHistoryDrawer";

import CharacterDescriptionDrawer from "./CharacterDescriptionDrawer";
import { getStyles } from "../index";
import LanguagePrompts from "./LanguagePrompts";
import Logger from "./Logger";
import LoadingSpinner from "./LoadingSpinner";
import TeacherEditDrawer from "./TeacherEditDrawer";
import ExploreIcon from "@mui/icons-material/Explore";
import { Link } from "react-router-dom";
import { callApi, generateImage } from "./api";
import { sanitizeInput, formatUrlPart } from "./functions";
import SavedMessagesDrawer from "./SavedMessagesDrawer";
const TeacherChat = ({ user, isOpen, isDark }) => {
    const { teacherId } = useParams();
    const [convoType, setConvoType] = useState(teacherId);
    const navigate = useNavigate();
    const [teacher, setTeacher] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [displayChatName, setDisplayChatName] = useState("");
    const [oldishLoading, setOldishLoading] = useState(true);
    const [userAvatar, setUserAvatar] = useState(
        "/path-to-default-user-avatar.png",
    );
    const [teacherAvatar, setTeacherAvatar] = useState(
        "/path-to-default-ai-avatar.png",
    );
    const [infoDrawerOpen, setInfoDrawerOpen] = useState(false);
    const [isLanguageBot, setIsLanguageBot] = useState(false);
    const [dynamicTitle, setDynamicTitle] = useState("T");
    const [dynamicDescription, setDynamicDescription] = useState("T");
    const [descriptionDrawerOpen, setDescriptionDrawerOpen] = useState(false);
    const [savedMessagesDrawerOpen, setSavedMessagesDrawerOpen] =
        useState(false);
    const theme = useTheme();
    const styles = getStyles(theme);
    const [editDrawerOpen, setEditDrawerOpen] = useState(false);
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const { showSnackbar } = useSnackbar();

    const {
        input,
        messages,
        loading,
        loadingMessage,
        anchorEl,
        clientAnchorEl,
        selectedPrompt,
        messagesEndRef,
        inputRef,
        drawerOpen,
        chats,
        currentChatId,
        editingChat,
        loadingLatestChat,
        loadingChat,
        creatingNewChat,
        savingChatName,
        loadingOldChats,
        base64Image,
        handleImageUpload,
        setInput,
        setAnchorEl,
        setClientAnchorEl,
        setSelectedPrompt,
        setDrawerOpen,
        handleCreateNewChat,
        handleChatSelect,
        handleChatNameChange,
        handleSaveChatName,
        handleStartEditing,
        handleSendMessage,
        handleInputChange,
        handleDeleteAllMsgs,
        setLoadingMessage,
        setMessages,
        sendMessage,
        handleGenerateIdeas,
        optimisticUpdate,
        streamingMessage,
        handleStreamingMessage,
        handleStreamComplete,
        completedStreamingId,
        isLive,
        isStreaming,
        handleStopStreaming,
        currentChatName,
        prompts,
        promptDialogOpen,
        setPromptDialogOpen,
        addPrompt,
        deletePrompt,
    } = useGeneralChat(user, convoType, undefined, teacher);

    const [creator, setCreator] = useState(null);

    const [shareLink, setShareLink] = useState("");
    const [isCreating, setIsCreating] = useState(false);

    const handleShareConversation = () => {
        const link = `${window.location.origin}/app/delad-konversation/${currentChatId}`;
        setShareLink(link);
        // You can also copy the link to clipboard here if you want
        navigator.clipboard.writeText(link).then(() => {
            showSnackbar("Konversationen kopierad!", "success");
        });
    };

    const handleEditTeacher = () => {
        Logger.log("handleEditTeacher");
        Logger.log("teacherId: ", teacherId);
        navigate(`/app/larare/${teacherId}`);
    };

    const handleClientClick = (event) => {
        setClientAnchorEl(event.currentTarget);
    };

    useEffect(() => {
        if (user) {
            Logger.log("user.photoURL: ", user.photoURL);

            setUserAvatar(user.photoURL || "/path-to-default-user-avatar.png");
        }
    }, [user]);

    useEffect(() => {
        if (teacher) {
            Logger.log("Current Teacher: ", teacher);
            setTeacherAvatar(
                teacher.imageUrl || "/path-to-default-ai-avatar.png",
            );

            if (
                teacher.category === "xGEcygU8I6j5SJdF3ELR" &&
                teacher.languageToLearn
            ) {
                const languageMap = {
                    french: "franska",
                    spanish: "spanska",
                    german: "tyska",
                    swedish: "svenska",
                };
                const languageName =
                    languageMap[teacher.languageToLearn] ||
                    teacher.languageToLearn;

                setDynamicTitle(
                    `${getLanguageText(teacher.languageToLearn)} — Lär dig ${languageName} med AI — Studera.AI`,
                );
                setDynamicDescription(
                    `Förbättra dina ${languageName}-kunskaper genom interaktiva AI-drivna konversationer. Anpassad inlärning för alla nivåer.`,
                );
            } else {
                setDynamicTitle(
                    `${teacher.characterName} — Chatbot — Studera.AI`,
                );
                setDynamicDescription(`${teacher.description}`);
            }

            setIsLanguageBot(teacher.category === "xGEcygU8I6j5SJdF3ELR");
            Logger.log("teacher.languageToLearn: ", teacher.languageToLearn);
        }
    }, [teacher]);

    function getLanguageText(languageToLearn) {
        switch (languageToLearn) {
            case "spanish":
                return "Spansklärare";
            case "french":
                return "Fransklärare";
            case "german":
                return "Tysklärare";
            default:
                return "Språklärare";
        }
    }

    useEffect(() => {
        if (teacherId) {
            setConvoType(teacherId);

            // Moonshine: Ska den verkligen rensa här?
            setMessages([]); // Rensa befintliga meddelanden
        }
    }, [teacherId]);

    useEffect(() => {
        Logger.log("currentTeach: ", teacherId);

        const fetchTeacherAndCreator = async () => {
            setIsLoading(true);
            try {
                // First, check if the teacher exists for the logged-in user
                const userTeacherQuery = query(
                    collection(db, "teachers"),
                    where("__name__", "==", teacherId),
                    where("userId", "==", user.uid),
                    limit(1),
                );
                const userTeacherSnapshot = await getDocs(userTeacherQuery);
                console.log("1");
                if (!userTeacherSnapshot.empty) {
                    // Teacher exists for the user
                    const teacherDoc = userTeacherSnapshot.docs[0];
                    const teacherData = teacherDoc.data();
                    setTeacher({
                        id: teacherId,
                        ...teacherData,
                    });
                    setConvoType(teacherId);
                    // Fetch creator information
                    const creatorId =
                        teacherData.originalCreatorId || teacherData.userId;
                    Logger.log(
                        "teacherData.originalCreatorId:",
                        teacherData.originalCreatorId,
                    );
                    Logger.log("teacherData.userId:", teacherData.userId);
                    if (creatorId) {
                        const creatorDoc = await getDoc(
                            doc(db, "users", creatorId),
                        );
                        if (creatorDoc.exists()) {
                            setCreator(creatorDoc.data());
                        }
                    }
                } else {
                    console.log("2");
                    // Teacher doesn't exist for the user, check publicTeachers
                    const publicTeacherDoc = await getDoc(
                        doc(db, "publicTeachers", teacherId),
                    );
                    if (publicTeacherDoc.exists()) {
                        const publicTeacherData = publicTeacherDoc.data();
                        const newTeacherData = {
                            ...publicTeacherData,
                            userId: user.uid,
                            isPublicCopy: true,
                            originalCreatorId: publicTeacherData.userId, // Preserve the original creator
                        };

                        // Add the new teacher to the 'teachers' collection
                        await setDoc(
                            doc(db, "teachers", teacherId),
                            newTeacherData,
                        );

                        setTeacher({
                            id: teacherId,
                            ...newTeacherData,
                        });
                        setConvoType(teacherId);

                        // Fetch creator information for the public copy
                        if (publicTeacherData.userId) {
                            const creatorDoc = await getDoc(
                                doc(db, "users", publicTeacherData.userId),
                            );
                            if (creatorDoc.exists()) {
                                setCreator(creatorDoc.data());
                            }
                        }
                    } else {
                        Logger.log(
                            "No teacher found with this ID in public or private collections",
                        );
                    }
                }
            } catch (error) {
                Logger.log("Error fetching or creating teacher:", error);

                Logger.log("Error details:", {
                    message: error.message,
                    code: error.code,
                    name: error.name,
                    stack: error.stack,
                });
            } finally {
                setIsLoading(false);
            }
        };
        setConvoType(teacherId);
        setMessages([]); // Clear existing messages
        fetchTeacherAndCreator();
    }, [teacherId, setMessages, convoType, user.uid]);

    useEffect(() => {
        Logger.log("cccreator: ", creator);

        // const teacherId = 'nTB3pwXxBdLHbhar839H';
        // const simulateVotes = async () => {
        //     try {
        //         // Get all documents from the publicTeachers collection
        //         const querySnapshot = await getDocs(collection(db, 'publicTeachers'));

        //         // Update each document with a random number of upVotes
        //         const updatePromises = querySnapshot.docs.map(async document => {
        //             const randomVotes = Math.floor(Math.random() * (18000 - 2300 + 1) + 2300);
        //             await updateDoc(doc(db, 'publicTeachers', document.id), {
        //                 upvotes: randomVotes,
        //             });
        //         });

        //         // Wait for all updates to complete
        //         await Promise.all(updatePromises);

        //         console.log('Vote simulation completed successfully');
        //     } catch (error) {
        //         console.error('Error simulating votes:', error);
        //     }
        // };

        // simulateVotes();
    }, [creator]);

    useEffect(() => {
        if (!loadingOldChats) setOldishLoading(false);
    }, [loadingOldChats]);

    useEffect(() => {
        setDisplayChatName(currentChatName);
    }, [currentChatName]);

    const handleOpenPromptDialog = () => {
        setPromptDialogOpen(true);
    };

    const handleClientClose = () => {
        setClientAnchorEl(null);
    };

    const handleMenuItemClick = async (prompt) => {
        setSelectedPrompt(prompt);
        setAnchorEl(null);

        Logger.log("setLoadingMessage: ", loadingMessage);
        if (user && user.uid && currentChatId && prompt.trim() !== "") {
            const newMessage = optimisticUpdate(prompt, true);

            if (newMessage) {
                try {
                    await handleSendMessage(prompt, newMessage);
                } catch (error) {
                    console.error("Error in sendMessage:", error);
                    setMessages((prevMessages) =>
                        prevMessages.filter((msg) => msg.id !== newMessage.id),
                    );
                } finally {
                }
            } else {
                Logger.log("optimisticUpdate returned null");
            }
        } else {
            console.error(
                "User ID, chatId is missing, or prompt is empty when trying to send message",
            );
        }
    };

    const handleCreateNewTeacher = () => {
        setIsCreating(true);
        setEditDrawerOpen(true);
    };

    const handleTeacherUpdate = (updatedTeacherData, newTeacherId) => {
        if (isCreating) {
            // Handle new teacher creation
            setIsCreating(false);
            navigate(`/app/chat/${newTeacherId}`);
        } else {
            // Handle teacher update
            setTeacher((prevTeacher) => ({
                ...prevTeacher,
                ...updatedTeacherData,
                id: newTeacherId || prevTeacher.id,
            }));
            // You might want to refresh the chat or update other components here
        }
    };

    const generateQuiz = async () => {
        // Filter out system messages and extract content

        if (messages.length < 15) {
            showSnackbar(
                "Minst 15 meddelanden krävs för att skapa quiz.",
                "info",
            );
            return;
        }

        Logger.log("messages: ", messages);
        const chatContent = messages
            .filter((msg) => msg.role !== "system")
            .map((msg) => `${msg.role}: ${msg.content}`)
            .join("\n");

        Logger.log("chatContent: ", chatContent);

        // Prepare the API call to generate the quiz
        const instructions = `Generate a quiz with 10 questions based on the following chat conversation. ${isLanguageBot ? " Detta är en användare som försöker lära sig ett språk, så se till att skapa frågor om de ord, fraser, verb osv som konversationen gått igenom." : ""}. The quiz should be in Swedish. Each question should be a JSON object with a "question" field, an "options" field containing 4 options, and a "correct" field. Ensure all questions are returned in a single JSON array. Do not use any markdown formatting. Format example: [{ "question": "Who won the FIFA World Cup in 2018?", "options": ["Brazil", "France", "Germany", "Argentina"], "correct": "France" }]`;

        const apiMessages = [
            { role: "system", content: instructions },
            { role: "user", content: chatContent },
        ];

        try {
            let response = await callApi(apiMessages, 4000, true, user.uid);

            let content = response.data.choices[0].message.content.trim();

            // Remove code block markers if present
            if (content.startsWith("```json")) {
                content = content.slice(7, -3).trim();
            } else if (content.startsWith("```")) {
                content = content.slice(3, -3).trim();
            }

            // Parse the cleaned JSON content
            const quizContent = JSON.parse(content);

            const quizDoc = await addDoc(collection(db, "quiz"), {
                userId: user.uid,
                results: quizContent,
                quizName: `Quiz med ${teacher.characterName}`,
                createdAt: Date.now(),
                byAdmin: "No",
                isPublic: false,
                questionType: "multiple-choice",
                category: "TeacherChat",
            });

            // Navigate to the newly created quiz
            const formattedQuizName = formatUrlPart(
                `Quiz med ${teacher.characterName}`,
            );
            navigate(`/app/quiz/${quizDoc.id}/${formattedQuizName}`);
        } catch (error) {
            console.error("Error generating quiz:", error);
            showSnackbar(
                "Det uppstod ett fel vid generering av quizet. Vänligen försök igen.",
                "error",
            );
        }
    };

    if (isLoading) {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                }}
            >
                <LoadingSpinner />
            </Box>
        );
    }

    if (!teacher) {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                }}
            >
                <Typography>No teacher found with this ID.</Typography>
            </Box>
        );
    }

    return (
        <>
            <Helmet>
                <title>{dynamicTitle}</title>
                <meta name="description" content={dynamicDescription} />
                <meta property="og:image" content={teacher.imageUrl} />
            </Helmet>

            <Box
                sx={{
                    ...styles.container,
                    backgroundColor: isDark === true ? "#15202B" : "",
                }}
            >
                <Box sx={styles.innerContainer}>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            mt: 2,
                            justifyContent: { xs: "center", sm: "flex-start" },
                            width: "100%",
                        }}
                    >
                        <Box sx={{ position: "relative" }}>
                            <Avatar
                                src={teacher.imageUrl}
                                alt={teacher.characterName}
                                sx={{
                                    width: 45,
                                    height: 45,
                                    marginRight: 2,
                                    borderRadius: "100px",
                                    border: "2px solid white",
                                    boxShadow:
                                        theme.palette.mode === "dark"
                                            ? "0 2px 10px rgba(0,0,0,0.2)"
                                            : "none",
                                }}
                            />
                            {flagUrls[teacher.language] && (
                                <Box
                                    component="img"
                                    src={flagUrls[teacher.language]}
                                    alt={`${teacher.language} `}
                                    sx={{
                                        position: "absolute",
                                        top: 0,
                                        right: "11px",
                                        width: "20px",
                                        height: "20px",
                                        borderRadius: "50%",
                                        border: "1px solid white",
                                    }}
                                />
                            )}
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <Typography
                                variant="h1"
                                sx={{
                                    ...styles.header,
                                    borderBottom: "0px",
                                    fontWeight: "500",
                                    fontSize: "18px",
                                    marginTop: "0px",
                                    marginBottom: "2px",
                                    marginLeft: { xs: "0px", md: "inherit" },
                                }}
                            >
                                {sanitizeInput(teacher.characterName)}
                            </Typography>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "8px",
                                }}
                            >
                                <Box
                                    sx={{
                                        ...styles.grayText,
                                        mt: 0,
                                        fontSize: "13px",
                                        fontWeight: "500",
                                        display: "flex",
                                        alignItems: "center",
                                        display: "none",
                                    }}
                                >
                                    {creator ? (
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <PersonOutlineIcon
                                                sx={{
                                                    fontSize: "15px",
                                                    marginRight: "4px",
                                                }}
                                            />

                                            {creator.displayName || "Anonym"}
                                        </div>
                                    ) : (
                                        <>
                                            <PersonOutlineIcon
                                                sx={{
                                                    fontSize: "15px",
                                                    marginRight: "4px",
                                                }}
                                            />
                                            anonym
                                        </>
                                    )}
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "4px",
                                    }}
                                >
                                    <ThumbUpOffAltIcon
                                        sx={{
                                            ...styles.grayText,
                                            fontSize: "15px",
                                        }}
                                    />
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            ...styles.grayText,
                                            fontSize: "13px",
                                            lineHeight: 1,
                                            fontWeight: "500",
                                        }}
                                    >
                                        {formatUpvotes(teacher.upvotes)}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>

                    {/*
            <Button
                variant="outlined"
                onClick={handleClientClick}
                startIcon={<BoltIcon />}
                endIcon={<ArrowDropDownIcon />}
                sx={{...styles.smallerBtn,  display: {xs:'none', md:'flex'}, left:'auto !important', right:'260px', top:'23px'  } }
            >
                Studera AI
            </Button>

<Menu
    anchorEl={clientAnchorEl}
    open={Boolean(clientAnchorEl)}
    onClose={handleClientClose}

>



<Tooltip title="Stöd för fler AI-modeller kommer snart">
<div>
        <MenuItem selected sx={{...styles.menuItems, fontSize:'14px', fontWeight:'600'}} disabled={true}>
        <ListItemIcon>
                <img src="/img/AI.png" style={styles.roundImage} alt="Studera.AI"  />
        </ListItemIcon>
        Studera.AI
        </MenuItem>

        <MenuItem  sx={{...styles.menuItems, fontSize:'14px', fontWeight:'600'}} disabled={true}>
        <ListItemIcon>
                <img src="/img/ChatGPT.png" style={styles.roundImage} alt="ChatGPT 4o-mini"  />
        </ListItemIcon>
        ChatGPT 4o-mini
        </MenuItem>

        
<MenuItem disabled={true} sx={{...styles.menuItems, fontSize:'14px', fontWeight:'600'}}>
        <ListItemIcon>
                <img src="/img/GPT4o.png" style={styles.roundImage} alt="GPT 4o"  />
        </ListItemIcon>
        GPT 4o
        </MenuItem>  

<MenuItem disabled={true} sx={{...styles.menuItems, fontSize:'14px', fontWeight:'600'}}>
        <ListItemIcon>
                <img src="/img/Claude 3 Sonnet.png" style={styles.roundImage} alt="Claude 3 Sonett"  />
        </ListItemIcon>
        Claude 3 Sonnet
        </MenuItem>  



<MenuItem disabled={true} sx={{...styles.menuItems, fontSize:'14px', fontWeight:'600'}}>
        <ListItemIcon>
                <img src="/img/Claude 3.5 Sonnet.png" style={styles.roundImage} alt="Claude 3.5 Sonett"  />
        </ListItemIcon>
        Claude 3.5 Sonnet
        </MenuItem>  
<MenuItem disabled={true} sx={{...styles.menuItems, fontSize:'14px', fontWeight:'600'}}>
        <ListItemIcon>
                <img src="/img/Gemini Pro.png" style={styles.roundImage} alt="Gemini Pro"  />
        </ListItemIcon>
        Gemini Pro
        </MenuItem>  
<MenuItem disabled={true} sx={{...styles.menuItems, fontSize:'14px', fontWeight:'600'}}>
        <ListItemIcon>
                <img src="/img/Mistral Large.png" style={styles.roundImage} alt="Mistral Large"  />
        </ListItemIcon>
        Mistral Large
        </MenuItem>          
        </div>
</Tooltip>
</Menu>

*/}

                    <Box
                        sx={{
                            ...styles.buttonsContainer,
                            ...styles.additionalButtonsContainer,
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: "16px",
                                marginRight: "30px",
                                color: isDark ? "#b9b9b9" : "#6c6c6c",
                                display: { xs: "none", md: "inherit" },
                            }}
                        >
                            {/*

<TypeAnimation
key={displayChatName} 
  sequence={[
    displayChatName,
    1000,
  ]}
  speed={50}
  cursor={false}

  repeat={1}
/>
*/}
                        </Typography>
                        <Tooltip title="Ny chatt">
                            <Button
                                variant={
                                    theme.palette.mode === "light"
                                        ? "contained"
                                        : "clean"
                                }
                                color="primary"
                                disableRipple
                                onClick={handleCreateNewChat}
                                startIcon={<AddIcon />}
                                disabled={
                                    messages.length === 0 || creatingNewChat
                                }
                                sx={{
                                    padding: "8px",
                                    marginRight: "0px",
                                    paddingLeft: "14px",
                                    paddingRight: "3px",
                                    minWidth: "auto",
                                    textTransform: "none",
                                }}
                            />
                        </Tooltip>
                        <Button
                            variant={
                                theme.palette.mode === "light"
                                    ? "contained"
                                    : "clean"
                            }
                            color="primary"
                            disableRipple
                            onClick={() => setInfoDrawerOpen(true)}
                            startIcon={<MoreHorizIcon />}
                            sx={{
                                padding: "8px",
                                marginRight: "0px",
                                paddingLeft: "14px",
                                paddingRight: "3px",
                                minWidth: "auto",
                                textTransform: "none",
                            }}
                        />
                    </Box>

                    <TeacherInfoDrawer
                        open={infoDrawerOpen}
                        onClose={() => setInfoDrawerOpen(false)}
                        teacher={teacher}
                        user={creator}
                        loggedInUser={user}
                        styles={styles}
                        theme={theme}
                        handleCreateNewChat={handleCreateNewChat}
                        setDrawerOpen={setDrawerOpen}
                        setSavedMessagesDrawerOpen={setSavedMessagesDrawerOpen}
                        setEditDrawerOpen={setEditDrawerOpen}
                        setIsCreating={setIsCreating}
                        setDescriptionDrawerOpen={setDescriptionDrawerOpen}
                        handleShareConversation={handleShareConversation}
                        generateQuiz={generateQuiz}
                        currentChatId={currentChatId}
                    />

                    <TeacherEditDrawer
                        open={editDrawerOpen}
                        onClose={() => {
                            setEditDrawerOpen(false);
                            setIsCreating(false);
                        }}
                        teacherId={teacher.id}
                        user={user}
                        isDark={isDark}
                        onTeacherUpdate={handleTeacherUpdate}
                        isCreating={isCreating}
                    />

                    <CharacterDescriptionDrawer
                        teacher={teacher}
                        open={descriptionDrawerOpen}
                        onClose={() => setDescriptionDrawerOpen(false)}
                        theme={theme}
                    />

                    <SavedMessagesDrawer
                        user={user}
                        teacher={teacher}
                        open={savedMessagesDrawerOpen}
                        onClose={() => setSavedMessagesDrawerOpen(false)}
                        isDark={isDark}
                        theme={theme}
                    />
                    <ChatHistoryDrawer
                        drawerOpen={drawerOpen}
                        setDrawerOpen={setDrawerOpen}
                        oldishLoading={oldishLoading}
                        chats={chats}
                        editingChat={editingChat}
                        handleChatSelect={handleChatSelect}
                        handleChatNameChange={handleChatNameChange}
                        handleSaveChatName={handleSaveChatName}
                        handleStartEditing={handleStartEditing}
                        savingChatName={savingChatName}
                        handleEditTeacher={handleEditTeacher}
                        isDark={isDark}
                        styles={styles}
                    />

                    <style jsx="true" global="true">{`
                        html,
                        body {
                            overflow: hidden; /* Prevent body scroll */
                            height: 100%; /* Ensure body takes full height */
                            margin: 0; /* Remove default margin */
                        }
                    `}</style>

                    <Box
                        sx={{
                            height: "calc(100vh - 200px)", // Adjust this value based on your layout
                            display: "flex",
                            flexDirection: "column",
                            overflowY: "auto",
                            padding: { xs: "0px", md: "16px" },
                            "&::-webkit-scrollbar": {
                                display: "block",
                                width: "8px",
                            },
                            "&::-webkit-scrollbar-thumb": {
                                backgroundColor: "rgba(0,0,0,.2)",
                                borderRadius: "4px",
                            },
                            scrollbarWidth: "thin",
                            scrollbarColor: "rgba(0,0,0,.2) transparent",
                        }}
                    >
                        <Box
                            sx={{
                                flexGrow: 1,

                                width: { xs: "100%", md: "73%" },
                                margin: "0 auto",
                                marginTop: { xs: "20px", md: "inherit" },
                            }}
                        >
                            <Box
                                sx={{
                                    display: { xs: "none", md: "flex" },
                                    flexDirection: "column",
                                    alignItems: "center",
                                    pt: 8,
                                    pb: 4,
                                    maxWidth: "300px",
                                    margin: "0 auto",
                                }}
                            >
                                <Box
                                    sx={{
                                        position: "relative",
                                    }}
                                >
                                    <Avatar
                                        src={teacher.imageUrl}
                                        alt={teacher.characterName}
                                        sx={{
                                            width: 65,
                                            height: 65,
                                            marginBottom: "2px",
                                            borderRadius: "100px",
                                            border: "2px solid white",
                                            boxShadow:
                                                theme.palette.mode === "dark"
                                                    ? "0 2px 10px rgba(0,0,0,0.2)"
                                                    : "none",
                                        }}
                                    />
                                    {flagUrls[teacher.language] && (
                                        <Box
                                            component="img"
                                            src={flagUrls[teacher.language]}
                                            alt={`${teacher.language} `}
                                            sx={{
                                                position: "absolute",
                                                top: 0,
                                                right: "-5px",
                                                width: "25px",
                                                height: "25px",
                                                borderRadius: "50%",
                                                border: "1px solid white",
                                            }}
                                        />
                                    )}
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                    }}
                                >
                                    <Typography
                                        variant="h1"
                                        sx={{
                                            ...styles.header,
                                            borderBottom: "0px",
                                            fontWeight: "500",
                                            fontSize: "18px",
                                            marginTop: "6px",
                                            marginBottom: "6px",
                                            textAlign: "center",
                                        }}
                                    >
                                        {sanitizeInput(teacher.characterName)}
                                    </Typography>
                                    {teacher.tagline && (
                                        <Box
                                            sx={{
                                                ...styles.grayText,
                                                mt: 0,
                                                fontSize: "13px",
                                                fontWeight: "500",
                                                textAlign: "center",
                                            }}
                                        >
                                            {teacher.tagline}
                                        </Box>
                                    )}
                                    <Box
                                        sx={{
                                            ...styles.grayText,
                                            mt: 0,
                                            fontSize: "13px",
                                            fontWeight: "500",
                                            textAlign: "center",
                                            display: "none",
                                        }}
                                    >
                                        {creator
                                            ? `@${creator.displayName || "Anonym användare"}`
                                            : "@anonym"}
                                    </Box>
                                </Box>
                            </Box>

                            <Box
                                sx={{
                                    width: {
                                        xs: "100%",
                                        sm: isOpen ? "100%" : "70%",
                                    },
                                    margin: "0 auto",
                                }}
                            >
                                {loading ? (
                                    <SkeletonLoader
                                        width="100%"
                                        height="700px"
                                    />
                                ) : loadingLatestChat ? (
                                    <Box sx={styles.loaderContainer}>
                                        <LoadingSpinner noWrapper="true" />
                                        <Typography
                                            variant="body1"
                                            sx={{ marginLeft: 2 }}
                                        >
                                            Laddar din senaste chat...
                                        </Typography>
                                    </Box>
                                ) : loadingChat ? (
                                    <Box sx={styles.loaderContainer}>
                                        <LoadingSpinner noWrapper="true" />
                                        <Typography
                                            variant="body1"
                                            sx={{ marginLeft: 2 }}
                                        >
                                            Laddar in vald chat...
                                        </Typography>
                                    </Box>
                                ) : creatingNewChat ? (
                                    <Box sx={styles.loaderContainer}>
                                        <LoadingSpinner noWrapper="true" />
                                        <Typography
                                            variant="body1"
                                            sx={{ marginLeft: 2 }}
                                        >
                                            Skapar ny chat...
                                        </Typography>
                                    </Box>
                                ) : (
                                    <>
                                        {messages.length === 0 &&
                                        isLanguageBot ? (
                                            <>
                                                <PromptBoxes
                                                    handleMenuItemClick={
                                                        handleMenuItemClick
                                                    }
                                                    language={
                                                        teacher.languageToLearn
                                                    }
                                                    theme={theme}
                                                    styles={styles}
                                                    user={user}
                                                />
                                            </>
                                        ) : (
                                            <>
                                                <MessageBubble
                                                    role="bot"
                                                    content={sanitizeInput(
                                                        teacher.greeting,
                                                    )}
                                                    generateIdeas={
                                                        handleGenerateIdeas
                                                    }
                                                    isDark={isDark}
                                                    avatar={teacherAvatar}
                                                    userId={user.uid}
                                                    isLanguageBot={
                                                        isLanguageBot
                                                    }
                                                />
                                                {messages.map((msg) => {
                                                    return (
                                                        <MessageBubble
                                                            key={msg.id}
                                                            {...msg}
                                                            generateIdeas={
                                                                handleGenerateIdeas
                                                            }
                                                            currentChatId={
                                                                currentChatId
                                                            }
                                                            id={msg.id}
                                                            isSaved={
                                                                msg.isSaved ||
                                                                false
                                                            }
                                                            noteId={
                                                                msg.noteId ||
                                                                null
                                                            }
                                                            role={
                                                                msg.role ||
                                                                (msg.isUser
                                                                    ? "user"
                                                                    : "bot")
                                                            }
                                                            conversationType={
                                                                msg.conversationType
                                                            }
                                                            userId={user.uid}
                                                            latexActivated={
                                                                user.latexActivated
                                                            }
                                                            isDark={isDark}
                                                            avatar={
                                                                msg.role ===
                                                                "user"
                                                                    ? userAvatar
                                                                    : teacherAvatar
                                                            }
                                                            isStreaming={
                                                                msg.isStreaming
                                                            }
                                                            isLanguageBot={
                                                                isLanguageBot
                                                            }
                                                        />
                                                    );
                                                })}
                                            </>
                                        )}
                                    </>
                                )}

                                <div ref={messagesEndRef} />
                            </Box>

                            <Box
                                sx={{
                                    ...styles.inputContainer,
                                    width: {
                                        xs: "100%",
                                        md: isOpen
                                            ? "calc(100% - 23% - 7px)"
                                            : "75%",
                                    },
                                    left: {
                                        xs: 0,
                                        md: isOpen
                                            ? "440px"
                                            : "calc(23% + 7px)",
                                    },
                                }}
                            >
                                {isLanguageBot && (
                                    <LanguagePrompts
                                        onSendMessage={handleMenuItemClick}
                                        messages={messages}
                                        language={teacher.languageToLearn}
                                    />
                                )}

                                <Prompts
                                    anchorEl={anchorEl}
                                    setAnchorEl={setAnchorEl}
                                    handleMenuItemClick={handleMenuItemClick}
                                    onAddPrompt={handleOpenPromptDialog}
                                    userPrompts={prompts}
                                />

                                <PromptDialog
                                    open={promptDialogOpen}
                                    onClose={() => setPromptDialogOpen(false)}
                                    prompts={prompts}
                                    onAddPrompt={addPrompt}
                                    onDeletePrompt={deletePrompt}
                                    isDark={isDark}
                                />
                                <TextInput
                                    isTeacherChat={true}
                                    input={input}
                                    setInput={handleInputChange}
                                    sendMessage={handleSendMessage}
                                    currentChatId={currentChatId}
                                    onImageUpload={handleImageUpload}
                                    ref={inputRef}
                                    awaitingReply={loadingMessage}
                                    isStreaming={isStreaming}
                                    onStopStreaming={handleStopStreaming}
                                    isDark={isDark}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default TeacherChat;
