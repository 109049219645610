import React, { useState, useRef } from "react";
import {
  Box,
  Button,
  Typography,
  Divider,
  CircularProgress,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
} from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";
import GenerateIcon from "@mui/icons-material/AutoAwesome";
import ProgressBar from "./ProgressBar";
import { callApi } from "./api";
import LoadingBar from "react-top-loading-bar";
import LanguageSelector from "./LanguageSelector";
import PrintIcon from "@mui/icons-material/Print";
import InfoBox from "./InfoBox";
import { PiCursorTextFill } from "react-icons/pi";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import { useSnackbar } from "./SnackbarHandler";
import GradeSelector, { gradeInstructions } from "./GradeSelector";
import { useTheme } from "@mui/material/styles";
import { getStyles } from "../index";

const SuffixPrefixLesson = ({ user }) => {
  const [language, setLanguage] = useState("svenska");
  const [progress, setProgress] = useState(0);
  const [isGeneratingLesson, setIsGeneratingLesson] = useState(false);
  const [editorContent, setEditorContent] = useState("");
  const editorRef = useRef(null);
  const { showSnackbar } = useSnackbar();
  const loadingBarRef = useRef(null);
  const [grade, setGrade] = useState("åk6");
  const [practiceType, setPracticeType] = useState("suffix");

  const theme = useTheme();
  const styles = getStyles(theme);

  const simulateProgress = (startProgress, endProgress, duration) => {
    const stepSize = 0.5;
    const steps = (endProgress - startProgress) / stepSize;
    const stepDuration = duration / steps;
    let currentProgress = startProgress;

    const intervalId = setInterval(() => {
      currentProgress += stepSize;
      if (currentProgress >= endProgress) {
        clearInterval(intervalId);
      } else {
        setProgress(currentProgress);
      }
    }, stepDuration);

    return () => clearInterval(intervalId);
  };

  // ... (previous imports and initial component setup remain the same)

  const handleGenerateLesson = async () => {
    setProgress(0);
    setIsGeneratingLesson(true);
    const gradeInstructionsText = gradeInstructions[grade];
    let stopProgressSimulation = null;
    try {
      stopProgressSimulation = simulateProgress(0, 90, 15000, setProgress);

      const practiceTypePrompt = {
        suffix: "Focus on suffix exercises and examples",
        prefix: "Focus on prefix exercises and examples",
        both: "Include both suffix and prefix exercises and examples",
      }[practiceType];

      let response = await callApi(
        [
          {
            role: "system",
            content:
              "You are a language teacher creating exercises for learning suffixes and prefixes.",
          },
          {
            role: "user",
            content: `Create a lesson for practicing ${language} ${practiceType === "both" ? "suffixes and prefixes" : practiceType + "s"}. 
            Return the response in this exact JSON format:
            {
              "title": "Lesson title in ${language}",
              "explanations": [
                {
                  "type": "suffix/prefix",
                  "explanation": "Clear explanation of what it means",
                  "examples": ["example1", "example2", "example3"]
                }
              ],
              "exercises": [
                {
                  "type": "fill-in",
                  "instructions": "Instructions for the exercise",
                  "questions": [
                    {
                      "question": "Question text with ___ for filling in",
                      "answer": "correct answer"
                    },
                    // EXACTLY 10 QUESTIONS
                  ]
                },
                {
                  "type": "multiple-choice",
                  "instructions": "Instructions for exercise",
                  "questions": [
                    {
                      "question": "Question text",
                      "options": ["option1", "option2", "option3", "option4"],
                      "correct": "correct option"
                    },
                       // EXACTLY 10 QUESTIONS
                  ]
                },
                {
                  "type": "word-building",
                  "instructions": "Instructions for building words",
                  "words": [
                    {
                      "root": "base word",
                    
                      "hint": "hint for building",
                      "target": "target word to build",

                    },
                     // EXACTLY 10 WORDS
                  ]
                }
              ]
            }

            ${gradeInstructionsText}
            ${practiceTypePrompt}
            
            For grade ${grade}, focus on appropriate difficulty level.`,
          },
        ],
        6000,
        true,
        user.uid,
      );

      // Stop the progress simulation
      if (stopProgressSimulation) {
        stopProgressSimulation();
      }

      setProgress(95);
      let messageContent = response.data.choices[0].message.content.trim();

      if (messageContent.startsWith("```json")) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith("```")) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      const data = JSON.parse(messageContent);

      const formattedContent = `
        <h1 style="font-size: 28px; color: #2196F3; margin-bottom: 20px;">${data.title}</h1>
        
        <h2 style="font-size: 24px; margin-top: 30px;">Förklaringar och exempel</h2>
        ${data.explanations
          .map(
            (exp) => `
          <div style="padding: 20px; border-radius: 8px; margin: 20px 0; box-shadow: 0 2px 4px rgba(0,0,0,0.1);">
            <h3 style="color: #1976D2;">${exp.type}</h3>
            <p>${exp.explanation}</p>
            <div style="padding: 15px; border-radius: 8px; margin-top: 15px;">
              <p><strong>Exempel:</strong></p>
              <ul>
                ${exp.examples.map((example) => `<li>${example}</li>`).join("")}
              </ul>
            </div>
          </div>
        `,
          )
          .join("")}

        <h2 style="font-size: 24px; margin-top: 30px; display:none;">Övningar</h2>
        ${data.exercises
          .map((exercise, index) => {
            switch (exercise.type) {
              case "fill-in":
                return `
                <div style="margin-top: 50px;">
                  <h3 style="margin-top: 0; font-size: 24px;">Fyll i rätt ändelse/förstavelse</h3>
                  <p><strong>Instruktioner:</strong> ${exercise.instructions}</p>
                  ${exercise.questions
                    .map(
                      (q, i) => `
                    <div style="margin: 15px 0;">
                      <p>${i + 1}. ${q.question}</p>
                      <p style="color: #1976D2; margin-top: 10px;"><em>Svar: ${q.answer}</em></p>
                    </div>
                  `,
                    )
                    .join("")}
                </div>
              `;

              case "multiple-choice":
                return `
<div style="margin-top: 50px;">
                  <h3 style="margin-top: 0;font-size: 24px;">Flervalsuppgift</h3>
                  <p><strong>Instruktioner:</strong> ${exercise.instructions}</p>
                  ${exercise.questions
                    .map(
                      (q, i) => `
                    <div style="margin: 15px 0;">
                      <p>${i + 1}. ${q.question}</p>
                      <ul style="list-style-type: lower-alpha;">
                        ${q.options.map((option) => `<li>${option}</li>`).join("")}
                      </ul>
                      <p style="color: #1976D2; margin-top: 10px;"><em>Rätt svar: ${q.correct}</em></p>
                    </div>
                  `,
                    )
                    .join("")}
                </div>
              `;

              case "word-building":
                return `
<div style="margin-top: 50px;">
                  <h3 style="margin-top: 0;font-size: 24px;">Bygg nya ord</h3>
                  <p><strong>Instruktioner:</strong> ${exercise.instructions}</p>
                  ${exercise.words
                    .map(
                      (word, i) => `
                    <div style="margin: 15px 0;">
                      <p>${i + 1}. Grundord: <strong>${word.root}</strong></p>
                      <p style="color: #666;"><em>Tips: ${word.hint}</em></p>
                      <p style="color: #1976D2; margin-top: 10px;"><em>Rätt svar: ${word.target}</em></p>
                    </div>
                  `,
                    )
                    .join("")}
                </div>
              `;
            }
          })
          .join("")}
      `;

      setProgress(100);
      await new Promise((resolve) => setTimeout(resolve, 800)); // Longer delay for visibility

      setEditorContent(formattedContent);
    } catch (error) {
      console.error("Error generating lesson:", error);
      showSnackbar("Ett fel uppstod när lektionen skulle genereras", "error");
    } finally {
      setIsGeneratingLesson(false);
    }
  };

  // ... (rest of the component remains the same)

  const handleEditorChange = (content, editor) => {
    setEditorContent(content);
  };

  return (
    <Box sx={styles.container}>
      <Box
        sx={{
          ...styles.innerContainer,
          width: { xs: "100%", md: "87%" },
          margin: "0 auto",
        }}
      >
        <Typography
          variant="h1"
          gutterBottom
          sx={{
            fontWeight: "bold",
            fontSize: "1.8em",
            mb: 3,
            mt: 5,
            textAlign: "center",
          }}
        >
          Träna på förstavelser (prefix) och ändelser (suffix)
        </Typography>

        <InfoBox
          icon={PiCursorTextFill}
          title="Debatt Generator"
          instructions={[
            "Välj om eleverna ska öva på prefix, suffix eller bådadera",
            "Konfigurera vilka övningar som ska inkluderas",
            "Lägg till en illustrerande bild och skriv ut lektionen",
          ]}
          theme={theme}
          styles={styles}
        />
        <Box sx={{ ...styles.freshBox, padding: "30px" }}>
          <LoadingBar
            color={theme.palette.primary.main}
            ref={loadingBarRef}
            shadow={true}
          />

          <FormControl component="fieldset" sx={{ mb: 3 }}>
            <Typography
              variant="formLabelSinUnderline"
              sx={{ fontWeight: "500", mb: 1, display: "block" }}
            >
              Välj typer:
            </Typography>
            <RadioGroup
              row
              value={practiceType}
              onChange={(e) => setPracticeType(e.target.value)}
            >
              <FormControlLabel
                value="suffix"
                control={<Radio />}
                label="Ändelser"
              />
              <FormControlLabel
                value="prefix"
                control={<Radio />}
                label="Förstavelser"
              />
              <FormControlLabel
                value="both"
                control={<Radio />}
                label="Både och"
              />
            </RadioGroup>
          </FormControl>

          <Typography variant="subtitle1" sx={{ fontWeight: "500", mb: 1 }}>
            Språk:
          </Typography>
          <Box sx={{ mb: 3 }}>
            <LanguageSelector language={language} setLanguage={setLanguage} />
          </Box>

          <Typography variant="subtitle1" sx={{ fontWeight: "500", mb: 1 }}>
            Årskurs:
          </Typography>
          <Box sx={{ mb: 3 }}>
            <GradeSelector grade={grade} setGrade={setGrade} />
          </Box>

          <Button
            variant={theme.palette.mode === "light" ? "purp" : "contained"}
            color="primary"
            onClick={handleGenerateLesson}
            disabled={isGeneratingLesson}
            sx={{ mb: 4 }}
            startIcon={
              !isGeneratingLesson ? (
                <GenerateIcon style={{ color: "#fff" }} />
              ) : (
                <CircularProgress size={16} />
              )
            }
          >
            {isGeneratingLesson ? "Genererar..." : "Generera lektion"}
          </Button>

          {isGeneratingLesson && (
            <Box sx={{ mt: 6 }}>
              <ProgressBar
                progress={progress}
                label="Genererar lektion — tar 30-60 sekunder..."
              />
            </Box>
          )}

          {editorContent && (
            <>
              <Divider sx={{ mt: 3, mb: 3 }} />
              <Button
                variant="contained"
                startIcon={<PrintIcon />}
                onClick={() => {
                  if (editorRef.current) {
                    const content = editorRef.current.getContent();
                    const printWindow = window.open("", "_blank");
                    printWindow.document.write(`
                      <html>
                        <head>
                          <style>
                            body { 
                              font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 
                                         Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
                              line-height: 1.6;
                              padding: 20px;
                            }
                          </style>
                        </head>
                        <body>${content}</body>
                      </html>
                    `);
                    printWindow.document.close();
                    printWindow.print();
                  }
                }}
              >
                Skriv ut
              </Button>
            </>
          )}
          <Box sx={{ mt: 4 }}>
            <Editor
              apiKey="fxkb0ty514q6ak7t7lo34gojjloffnpw3n9fr8tdz3swjxi3"
              onInit={(evt, editor) => (editorRef.current = editor)}
              value={editorContent}
              onEditorChange={handleEditorChange}
              init={{
                height: 800,
                menubar: false,
                plugins: [
                  "advlist",
                  "autolink",
                  "lists",
                  "link",
                  "image",
                  "charmap",
                  "preview",
                  "anchor",
                  "searchreplace",
                  "visualblocks",
                  "code",
                  "fullscreen",
                  "insertdatetime",
                  "media",
                  "table",
                  "code",
                  "help",
                  "wordcount",
                ],
                toolbar:
                  "undo redo | blocks | bold italic forecolor | alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist outdent indent | removeformat | help",
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                skin: theme.palette.mode === "dark" ? "oxide-dark" : "oxide",
                content_css: theme.palette.mode === "dark" ? "dark" : "default",
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SuffixPrefixLesson;
