import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  IconButton,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Paper,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { alpha, lighten, darken } from "@mui/material/styles";

const EscapeRoomEditor = ({ open, onClose, lessonData, onSave }) => {
  const [editedData, setEditedData] = useState({
    ...lessonData, // This will preserve imageUrl and all other fields
  });

  const handleQuestionChange = (index, field, value) => {
    const newQuestions = [...editedData.questions];
    newQuestions[index] = {
      ...newQuestions[index],
      [field]: value,
    };
    setEditedData((prevData) => ({
      ...prevData, // Preserve all existing fields
      questions: newQuestions,
    }));
  };

  const handleTitleChange = (e) => {
    setEditedData((prevData) => ({
      ...prevData,
      title: e.target.value,
    }));
  };

  const handleHintChange = (questionIndex, hintIndex, value) => {
    const newQuestions = [...editedData.questions];
    const newHints = [...newQuestions[questionIndex].hints];
    newHints[hintIndex] = value;
    newQuestions[questionIndex] = {
      ...newQuestions[questionIndex],
      hints: newHints,
    };
    setEditedData({
      ...editedData,
      questions: newQuestions,
    });
  };

  const addQuestion = () => {
    const newQuestion = {
      question: "",
      storyProgression: "",
      solution: "",
      codeFragment: "",
      hints: ["", "", ""],
    };
    setEditedData({
      ...editedData,
      questions: [...editedData.questions, newQuestion],
    });
  };

  const removeQuestion = (index) => {
    const newQuestions = editedData.questions.filter((_, i) => i !== index);
    setEditedData({
      ...editedData,
      questions: newQuestions,
    });
  };

  const addHint = (questionIndex) => {
    const newQuestions = [...editedData.questions];
    newQuestions[questionIndex].hints.push("");
    setEditedData({
      ...editedData,
      questions: newQuestions,
    });
  };

  const removeHint = (questionIndex, hintIndex) => {
    const newQuestions = [...editedData.questions];
    newQuestions[questionIndex].hints = newQuestions[
      questionIndex
    ].hints.filter((_, i) => i !== hintIndex);
    setEditedData({
      ...editedData,
      questions: newQuestions,
    });
  };

  const handleSave = () => {
    onSave(editedData);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: { minHeight: "80vh" },
      }}
    >
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: "600" }}>
            Redigera Escape Room
          </Typography>
          <Box>
            <Button
              startIcon={<SaveIcon />}
              variant="purp"
              onClick={handleSave}
              sx={{ mr: 1 }}
            >
              Spara
            </Button>
            <Button
              startIcon={<CancelIcon />}
              variant="print"
              onClick={onClose}
            >
              Avbryt
            </Button>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Box sx={{ mb: 4 }}>
          <Typography variant="subtitle1" sx={{ fontWeight: "500", mb: 1 }}>
            Titel
          </Typography>

          <TextField
            fullWidth
            value={editedData.title}
            onChange={handleTitleChange}
            sx={{ mb: 2 }}
          />
          <Typography variant="subtitle1" sx={{ fontWeight: "500", mb: 1 }}>
            Inledande berättelse
          </Typography>
          <TextField
            fullWidth
            value={editedData.story}
            onChange={(e) =>
              setEditedData({ ...editedData, story: e.target.value })
            }
            multiline
            rows={3}
          />
        </Box>

        {editedData.questions.map((question, questionIndex) => (
          <Accordion
            key={questionIndex}
            sx={{
              mb: 2,
              backgroundColor: "background.paper",
              "&:hover": {
                backgroundColor: "background.paperHover",
              },
              "&.Mui-expanded": {
                backgroundColor: lighten("#6D31ED", 0.92),
              },
              // Border styling
              border: "1px solid",
              borderColor: alpha("#6D31ED", 0.12),
              borderRadius: 4,
              "&:hover": {
                borderColor: alpha("#6D31ED", 0.24),
              },
              "&.Mui-expanded": {
                borderColor: "#6D31ED",
              },
              // Shadow effects
              boxShadow: "0px 2px 4px rgba(109, 49, 237, 0.05)",
              "&:hover": {
                boxShadow: "0px 4px 8px rgba(109, 49, 237, 0.08)",
              },
              "&.Mui-expanded": {
                boxShadow: "0px 6px 12px rgba(109, 49, 237, 0.12)",
              },
              "&:before": {
                backgroundColor: "transparent",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                borderRadius: 4,
                "&.Mui-expanded": {
                  backgroundColor: lighten("#6D31ED", 0.94),
                },
                "&:hover": {
                  backgroundColor: lighten("#6D31ED", 0.96),
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Typography>Fråga {questionIndex + 1}</Typography>
                <IconButton
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    removeQuestion(questionIndex);
                  }}
                  sx={{ mr: 2 }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "500", mb: 0 }}
                >
                  Fråga
                </Typography>

                <TextField
                  fullWidth
                  value={question.question}
                  onChange={(e) =>
                    handleQuestionChange(
                      questionIndex,
                      "question",
                      e.target.value,
                    )
                  }
                />

                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "500", mb: 0, mt: 2 }}
                >
                  Berättelseprogression
                </Typography>

                <TextField
                  fullWidth
                  value={question.storyProgression}
                  onChange={(e) =>
                    handleQuestionChange(
                      questionIndex,
                      "storyProgression",
                      e.target.value,
                    )
                  }
                  multiline
                  rows={2}
                />

                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "500", mb: 0, mt: 2 }}
                >
                  Rätt svar
                </Typography>

                <TextField
                  fullWidth
                  value={question.solution}
                  onChange={(e) =>
                    handleQuestionChange(
                      questionIndex,
                      "solution",
                      e.target.value,
                    )
                  }
                />

                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "500", mb: 0, mt: 2 }}
                >
                  Kodfragment
                </Typography>

                <TextField
                  fullWidth
                  value={question.codeFragment}
                  onChange={(e) =>
                    handleQuestionChange(
                      questionIndex,
                      "codeFragment",
                      e.target.value,
                    )
                  }
                />

                <Paper variant="outlined" sx={{ p: 2, mt: 2, borderRadius: 2 }}>
                  <Typography
                    variant="subtitle1"
                    sx={{ mb: 5, fontWeight: "500" }}
                  >
                    Ledtrådar till fråga {questionIndex + 1}
                  </Typography>
                  {question.hints.map((hint, hintIndex) => (
                    <Box
                      key={hintIndex}
                      sx={{ display: "flex", gap: 1, mb: 2 }}
                    >
                      <TextField
                        fullWidth
                        label={`Ledtråd ${hintIndex + 1}`}
                        value={hint}
                        sx={{ mb: 2 }}
                        onChange={(e) =>
                          handleHintChange(
                            questionIndex,
                            hintIndex,
                            e.target.value,
                          )
                        }
                      />
                      <IconButton
                        color="error"
                        onClick={() => removeHint(questionIndex, hintIndex)}
                        sx={{ color: "text.secondary" }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  ))}
                  <Button
                    startIcon={<AddIcon />}
                    onClick={() => addHint(questionIndex)}
                    variant="print"
                    size="small"
                  >
                    Lägg till ledtråd
                  </Button>
                </Paper>
              </Box>
            </AccordionDetails>
          </Accordion>
        ))}

        <Button
          startIcon={<AddIcon />}
          onClick={addQuestion}
          variant="print"
          sx={{ mt: 2 }}
        >
          Lägg till fråga
        </Button>

        <Box sx={{ mt: 4 }}>
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: "500", mb: 1, mt: 2 }}
          >
            Slutkod
          </Typography>
          <TextField
            fullWidth
            value={editedData.finalEscapeCode}
            onChange={(e) =>
              setEditedData({ ...editedData, finalEscapeCode: e.target.value })
            }
            sx={{ mb: 2 }}
          />
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: "500", mb: 1, mt: 2 }}
          >
            Avslutande text
          </Typography>

          <TextField
            fullWidth
            value={editedData.conclusion}
            onChange={(e) =>
              setEditedData({ ...editedData, conclusion: e.target.value })
            }
            multiline
            rows={3}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default EscapeRoomEditor;
