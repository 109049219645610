import React from "react";
import { Box, Typography, Grid, Divider, List, ListItem } from "@mui/material";
import HoverableImage from "./HoverableImage";
import { InstructionItem } from "./functions";

const RolePlayDisplay = ({
  lessonData,
  onImageUpdate,
  userId,
  showSnackbar,
  theme,
  styles,
  isGeneratingImages,
  loadingImageIndex,
  handleImageButtonClick,
  viewMode,
}) => {
  const handleImageUpdate = (roleIndex, imageUrl) => {
    const updatedRoles = [...lessonData.roles];
    updatedRoles[roleIndex] = {
      ...updatedRoles[roleIndex],
      imageUrl: imageUrl,
    };
    onImageUpdate({ ...lessonData, roles: updatedRoles });
  };

  return (
    <Box
      sx={{
        mt: 4,
        width: viewMode ? "80%" : "100%",
        margin: viewMode ? "0 auto" : undefined,
      }}
    >
      <Typography
        variant="h4"
        sx={{
          display: "block",
          fontWeight: "bold",
          textAlign: "center",
          mb: 10,
          marginTop: "80px",
        }}
      >
        {lessonData?.title}
      </Typography>

      <Grid container spacing={4}>
        {lessonData?.roles.map((role, index) => (
          <Grid item xs={12} md={6} key={index} sx={{ display: "flex" }}>
            <Box
              sx={{
                ...styles.freshBox,
                display: "flex",
                flexDirection: "column",
                width: "100%", // Ensure full width
                boxShadow:
                  "rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px",
                padding: "0px",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "700",
                  fontSize: "1.3rem",
                  padding: "20px",

                  textAlign: "center",
                }}
              >
                {role.role}
              </Typography>
              <Box sx={{ backgroundColor: "#f9f9f9" }}>
                <Box sx={{ width: "270px", margin: "0 auto" }}>
                  <HoverableImage
                    imageUrl={role.imageUrl}
                    onImageUpdate={(url) => handleImageUpdate(index, url)}
                    userId={userId}
                    showSnackbar={showSnackbar}
                    onGenerateClick={() => handleImageButtonClick(index)}
                    theme={theme}
                    height="270px"
                    isLoading={isGeneratingImages}
                    loadingIndex={loadingImageIndex}
                    currentIndex={index}
                  />
                </Box>
              </Box>

              <Typography
                variant="body1"
                sx={{ mt: 4, mb: 2, paddingLeft: "20px", paddingRight: "20px" }}
              >
                {role.description}
              </Typography>

              <Typography
                variant="subtitle1"
                sx={{
                  mt: 1,
                  mb: 0,
                  fontWeight: "600",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  fontSize: "1.1rem",
                }}
              >
                Ansvarsområden:
              </Typography>
              <Box sx={{ paddingLeft: "20px", paddingRight: "20px" }}>
                <List sx={{ listStyleType: "disc", pl: 2 }}>
                  {role.responsibilities.map((resp, respIndex) => (
                    <ListItem
                      key={respIndex}
                      sx={{
                        display: "list-item",
                        p: 0,
                        mb: 0.7,
                        fontSize: "1rem",
                        "&:hover": {
                          backgroundColor: "transparent",
                          color: "inherit",
                        },
                      }}
                    >
                      {resp}
                    </ListItem>
                  ))}
                </List>
              </Box>

              <Divider sx={{ my: 2 }} />

              <Typography
                variant="body2"
                sx={{
                  fontStyle: "italic",
                  color: "text.secondary",
                  padding: "20px",
                  paddingTop: "0px",
                }}
              >
                {role.conviction}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>

      <Divider sx={{ my: 8 }} />

      <Typography
        variant="h6"
        sx={{ mb: 5, fontWeight: "700", fontSize: "1.5rem" }}
      >
        Debattstartare:
      </Typography>
      {/* Replace the existing List with this */}
      <Box sx={{ mb: 4 }}>
        {lessonData?.debate_starters.map((starter, index) => (
          <Box
            key={index}
            sx={{ display: "flex", alignItems: "center", mb: 2 }}
          >
            <Box
              sx={{
                ml: 2,
                width: 30,
                height: 30,
                bgcolor: "background.paper",
                border: "2px solid",
                borderColor: "text.main",
                color: "text.main",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mr: 2,
                borderRadius: 1,
                fontSize: "0.9rem",
                fontWeight: "bold",
                "&:hover": {
                  bgcolor: "text.main",
                  color: "white",
                },
              }}
            >
              {index + 1}
            </Box>
            <Typography variant="body1">{starter}</Typography>
          </Box>
        ))}
      </Box>

      <Divider sx={{ my: 8 }} />

      <Typography
        variant="h6"
        sx={{ mb: 0, fontWeight: "700", fontSize: "1.5rem" }}
      >
        Argument för varje roll:
      </Typography>
      {lessonData?.roles.map((role, index) => (
        <Box key={index} sx={{ mb: 4 }}>
          <Typography
            variant="subtitle1"
            sx={{ mb: 3, mt: 6, fontWeight: "600", fontSize: "1.3rem" }}
          >
            {role.role}
          </Typography>
          {role.arguments.map((arg, argIndex) => (
            <Box sx={{ ml: 2 }}>
              <InstructionItem
                key={argIndex}
                text={`${arg.headline}: ${arg.argument}`}
              />
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );
};

export default RolePlayDisplay;
