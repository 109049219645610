import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, Button, Chip } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import WikipediaIcon from "./WikipediaIcon";
import WordIcon from "./Icons/WordIcon";
import YouTubeIcon from "@mui/icons-material/YouTube";
import ShuffleIcon from "@mui/icons-material/Shuffle";
import SummarizeIcon from "@mui/icons-material/Summarize";
import SpellcheckIcon from "@mui/icons-material/Spellcheck";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import NotesIcon from "@mui/icons-material/Notes";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import PowerPointIcon from "./PowerPointIcon";
import { Grading as GradingIcon } from "@mui/icons-material";
import ViewStreamIcon from "@mui/icons-material/ViewStream";
import FaceIcon from "@mui/icons-material/Face";
import ViewTimelineIcon from "@mui/icons-material/ViewTimeline";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import DescriptionIcon from "@mui/icons-material/Description";
import SchoolIcon from "@mui/icons-material/School";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import GavelIcon from "@mui/icons-material/Gavel";
import ExploreIcon from "@mui/icons-material/Explore";
import { SiImagedotsc } from "react-icons/si";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";

import DoorSlidingIcon from "@mui/icons-material/DoorSliding";
import LinkIcon from "@mui/icons-material/Link";
import AssessmentIcon from "@mui/icons-material/Assessment";
import ImageIcon from "@mui/icons-material/Image";
import ViewQuiltIcon from "@mui/icons-material/ViewQuilt";
import CompareIcon from "@mui/icons-material/Compare";
import PeopleIcon from "@mui/icons-material/People";
import AssignmentIcon from "@mui/icons-material/Assignment";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import HubIcon from "@mui/icons-material/Hub";
import GridOnIcon from "@mui/icons-material/GridOn";
import ViewAgendaIcon from "@mui/icons-material/ViewAgenda";
import QuizIcon from "@mui/icons-material/Quiz";
import { RiFilePpt2Fill } from "react-icons/ri";
import { TbChartGridDotsFilled } from "react-icons/tb";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import PhotoFilterIcon from "@mui/icons-material/PhotoFilter";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import { GiMagicLamp } from "react-icons/gi";
import ScienceIcon from "@mui/icons-material/Science";
import { TableChart as TableChartIcon } from "@mui/icons-material";
import MenuOpenIcon from "@mui/icons-material/MenuBook";
import ClassIcon from "@mui/icons-material/Class";
import TopicIcon from "@mui/icons-material/Subject";
import RateReviewIcon from "@mui/icons-material/RateReview";
import WindowIcon from "@mui/icons-material/Window";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import EventNoteIcon from "@mui/icons-material/EventNote";
import EmojiObjectsIcon from "@mui/icons-material/EmojiObjects";
import StormIcon from "@mui/icons-material/Storm";
import Person3Icon from "@mui/icons-material/Person3";

import BarChartIcon from "@mui/icons-material/BarChart";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import TranslateIcon from "@mui/icons-material/Translate";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PsychologyIcon from "@mui/icons-material/Psychology";
import TimelineIcon from "@mui/icons-material/Timeline"; // Add this import
import CastForEducationIcon from "@mui/icons-material/CastForEducation";
import ChatIcon from "@mui/icons-material/Chat";
import GridViewIcon from "@mui/icons-material/GridView";
import CreateIcon from "@mui/icons-material/Create";
import SubjectIcon from "@mui/icons-material/Subject";
import { Palette as PaletteIcon } from "@mui/icons-material";
import { Abc as AbcIcon } from "@mui/icons-material";
import { EditNote as EditNoteIcon } from "@mui/icons-material";
import TrainIcon from "@mui/icons-material/Train";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import { GiTeacher } from "react-icons/gi";
import GenerateIcon from "@mui/icons-material/AutoAwesome";
import ShortcutBox from "./ShortcutBox"; // Adjust the path as necessary
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import DateRangeIcon from "@mui/icons-material/DateRange";
import StairsIcon from "@mui/icons-material/Stairs";

import MicExternalOnIcon from "@mui/icons-material/MicExternalOn";

import NewspaperIcon from "@mui/icons-material/Newspaper";
import {
  doc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  onSnapshot,
  getDoc,
} from "firebase/firestore";

import {
  collection,
  query,
  getDocs,
  where,
  Timestamp,
  orderBy,
  addDoc,
} from "firebase/firestore";
import { db } from "./firebase";

export const shortcuts = [
  // {
  //   icon: <GridViewIcon />,
  //   title: 'Hem',
  //   subtitle: '',
  //   path: '/app',
  //   label: 'NYTT',
  //   roles: ['teacher'],
  //   category: '',
  // },

  // {
  //   icon: <ChatIcon />,
  //   title: 'Fråga AI',
  //   subtitle: '',
  //   path: '/app/fraga-ai',
  //   roles: ['teacher'],
  //   category: '',
  // },

  {
    icon: <PersonAddAlt1Icon />,
    title: "Skapa konto",
    subtitle: "",
    path: "/app/skapa-konto",
    label: "NYTT",
    roles: ["teacher"],
    category: "",
  },

  {
    icon: <ChatIcon />,
    title: "Fråga AI",

    path: "/app/fraga-ai",
    subtitle: "Få hjälp med planering, idéer och svar på dina frågor",
    roles: ["teacher"],
    category: "Hjälpmedel",
    id: "hjalpmedel-fraga-ai",
    belongsTo: "Hjälpmedel",
  },

  {
    icon: <QuizIcon />,
    title: "Quiz",
    subtitle: "Skapa anpassade frågesporter för alla ämnen och nivåer",
    path: "/app/quiz",
    roles: ["teacher"],
    category: "Hjälpmedel",
    id: "hjalpmedel-quiz",
    belongsTo: "Hjälpmedel",
  },

  {
    icon: <ViewAgendaIcon />,
    title: "Flashcards",
    subtitle: "Skapa anpassade flashcards för alla ämnen och nivåer",
    path: "/app/flashcards",
    roles: ["teacher"],
    category: "Hjälpmedel",
    id: "hjalpmedel-flashcards",
    belongsTo: "Hjälpmedel",
  },

  {
    icon: <ImageSearchIcon />,
    title: "Bildanalys",
    subtitle: "Ladda upp en bild och låt vår AI analysera den.",
    path: "/app/vision",
    label: "NYTT",
    roles: ["teacher"],
    category: "Hjälpmedel",
    id: "hjalpmedel-vision",
    belongsTo: "Hjälpmedel",
  },

  {
    icon: <ImageIcon />,
    title: "Generera bilder",
    subtitle: "Skapa unika bilder för ditt undervisningsmaterial",
    path: "/app/generera-bild",
    label: "NYTT",
    roles: ["teacher"],
    category: "Hjälpmedel",
    id: "hjalpmedel-generera-bilder",
    belongsTo: "Hjälpmedel",
  },

  {
    icon: <CreateIcon />,
    title: "Textlabbet",
    subtitle: "Bearbeta din text precis hur du vill",
    path: "/app/textlabbet",

    roles: ["teacher"],
    category: "Hjälpmedel",
    id: "hjalpmedel-textlabbet",
    belongsTo: "Hjälpmedel",
  },

  {
    icon: <PeopleIcon />,
    title: "Rollspel",
    subtitle: "Låt en chatbot spela en anpassad roll som du väljer.",
    path: "/app/fraga-ai?p=roleplay",
    roles: ["teacher"],
    category: "Hjälpmedel",
    id: "hjalpmedel-rollspel",
    belongsTo: "Hjälpmedel",
  },
  {
    icon: <MenuBookIcon />,
    title: "Förklara för mig",
    subtitle: "Få vad som helst förklarat i önskat format.",
    path: "/app/fraga-ai?p=explain",
    roles: ["teacher"],
    category: "Hjälpmedel",
    id: "hjalpmedel-forklara",
    belongsTo: "Hjälpmedel",
  },

  {
    icon: <CompareIcon />,
    title: "Jämför koncept",
    subtitle: "Jämför och förklara skillnader mellan två ämnen/koncept.",
    path: "/app/fraga-ai?p=compare",
    roles: ["teacher"],
    category: "Hjälpmedel",
    id: "hjalpmedel-jamfor",
    belongsTo: "Hjälpmedel",
  },

  {
    icon: <QuestionAnswerIcon />,
    title: "Debatt",
    subtitle:
      "Simulera en debatt om valfritt ämne och välj position för eller emot.",
    path: "/app/fraga-ai?p=debate",
    roles: ["teacher"],
    category: "Hjälpmedel",
    id: "hjalpmedel-debatt",
    belongsTo: "Hjälpmedel",
  },

  {
    icon: <NotesIcon />,
    title: "Skapa begreppslista",
    subtitle: "Generera vokabulär inom valfritt ämne, i valfritt format.",
    path: "/app/fraga-ai?p=vocabulary",
    roles: ["teacher"],
    category: "Hjälpmedel",
    id: "hjalpmedel-begreppslista",
    belongsTo: "Hjälpmedel",
  },

  {
    icon: <AlternateEmailIcon />,
    title: "Skriv e-post",
    subtitle: "Generera ett email eller svara på ett email.",
    path: "/app/fraga-ai?p=email",
    roles: ["teacher"],
    category: "Hjälpmedel",
    id: "hjalpmedel-epost",
    belongsTo: "Hjälpmedel",
  },

  {
    icon: <AssignmentIndIcon />,
    title: "Rollkort",
    subtitle: "Låt elever spela olika roller för att utforska perspektiv.",
    path: "/app/lektioner/rollkort",
    label: "NYTT",
    roles: ["teacher"],
    category: "Lektioner",
    id: "lektioner-rollkort",
    belongsTo:
      "NO, Biologi, Svenska, SO, Historia, Engelska, Kemi, Samhällskunskap, Fysik, Geografi, Teknik, Idrott och hälsa, Religion, Moderna språk, Spanska, Franska, Tyska, Modersmål, Syslöjd, Trä och metallslöjd, Musik",
  },

  {
    icon: <AutoStoriesIcon />,
    title: "Fiktiva dagböcker",
    subtitle: "Fiktiva dagboksinlägg från historiska händelser eller personer",
    path: "/app/lektioner/fiktiva-dagbocker",
    label: "NYTT",
    roles: ["teacher"],
    category: "Lektioner",
    id: "lektioner-fiktiva-dagbocker",
    belongsTo:
      "NO, Biologi, Svenska, SO, Historia, Engelska, Kemi, Matematik, Samhällskunskap, Fysik, Geografi, Teknik, Idrott och hälsa, Religion, Moderna språk, Spanska, Franska, Tyska, Modersmål, Syslöjd, Trä och metallslöjd, Musik",
  },

  {
    icon: <FaceIcon />,
    title: "Historisk person",
    subtitle: "Generera lärorik information om valfri historisk person",
    path: "/app/lektioner/historisk-person",

    roles: ["teacher"],
    category: "Lektioner",
    id: "lektioner-historisk-person",
    belongsTo:
      "NO, Biologi, Svenska, SO, Historia, Engelska, Kemi, Matematik, Samhällskunskap, Fysik, Geografi, Teknik, Idrott och hälsa, Religion, Moderna språk, Spanska, Franska, Tyska, Modersmål, Syslöjd, Trä och metallslöjd, Musik",
  },

  {
    icon: <ForwardToInboxIcon />,
    title: "Fiktiva brev",
    subtitle: "Fiktiv brevväxling från historiska eller samtida personer",
    path: "/app/lektioner/fiktiva-brev",

    roles: ["teacher"],
    category: "Lektioner",
    id: "lektioner-fiktiva-brev",
    belongsTo:
      "NO, Biologi, Svenska, SO, Historia, Engelska, Kemi, Samhällskunskap, Fysik, Geografi, Teknik, Idrott och hälsa, Religion, Moderna språk, Spanska, Franska, Tyska, Modersmål, Syslöjd, Trä och metallslöjd, Musik",
  },

  {
    icon: <ClassIcon />,
    title: "Litteraturskolan",
    subtitle: "Litteraturskolan.",
    path: "/app/lektioner/litteraturskolan",
    label: "NYTT",
    roles: ["teacher"],
    category: "Lektioner",
    id: "lektioner-litteraturskolan",
    belongsTo:
      "NO, Biologi, Svenska, SO, Historia, Engelska, Kemi, Samhällskunskap, Fysik, Geografi, Teknik, Idrott och hälsa, Religion, Moderna språk, Spanska, Franska, Tyska, Modersmål",
  },

  {
    icon: <EmojiEventsIcon />,
    title: "Jeopardy",
    subtitle:
      "Generera interaktivt Jeopardy-spel baserat på vilket underlag som helst.",
    path: "/app/lektioner/jeopardy",
    label: "INTERAKTIV",
    roles: ["teacher"],
    category: "Lektioner",
    belongsTo:
      "NO, Biologi, Svenska, SO, Historia, Matematik, Engelska, Kemi, Samhällskunskap, Fysik, Geografi, Teknik, Idrott och hälsa, Religion, Moderna språk, Spanska, Franska, Tyska, Modersmål, Syslöjd, Trä och metallslöjd, Musik",
    id: "lektioner-jeopardy",
  },

  {
    icon: <PsychologyIcon />,
    title: "Escape Room",
    subtitle: "Interaktiva Escape Room med timer, bakgrundsljud & ledtrådar",
    path: "/app/lektioner/escape-room",
    label: "INTERAKTIV",
    roles: ["teacher"],
    category: "Lektioner",
    id: "lektioner-escape-room",
    belongsTo:
      "NO, Biologi, Svenska, SO, Historia, Matematik, Engelska, Kemi, Samhällskunskap, Fysik, Geografi, Teknik, Idrott och hälsa, Religion, Moderna språk, Spanska, Franska, Tyska, Modersmål, Syslöjd, Trä och metallslöjd, Musik",
  },

  {
    icon: <ViewTimelineIcon />,
    title: "Bygg tidslinjer",
    subtitle:
      "Låt eleverna ändra ordningen på en tidslinje och se hur många rätt de får",
    path: "/app/lektioner/bygg-tidslinjer",
    label: "INTERAKTIV",
    roles: ["teacher"],
    category: "Lektioner",
    id: "lektioner-bygg-tidslinjer",
    belongsTo:
      "NO, Biologi, Svenska, SO, Historia, Matematik, Engelska, Kemi, Samhällskunskap, Fysik, Geografi, Teknik, Idrott och hälsa, Religion, Moderna språk, Spanska, Franska, Tyska, Modersmål, Syslöjd, Trä och metallslöjd, Musik",
  },

  {
    icon: <NewspaperIcon />,
    title: "Fiktiva nyhetsredaktionen",
    subtitle: "Generera nyhetsartiklar med mängder av inställningar.",
    path: "/app/lektioner/fiktiva-nyhetsredaktionen",
    label: "POPULÄR",
    roles: ["teacher"],
    category: "Lektioner",
    id: "lektioner-fiktiva-nyhetsredaktionen",
    belongsTo:
      "NO, Biologi, Svenska, SO, Historia, Engelska, Kemi, Samhällskunskap, Fysik, Geografi, Teknik, Idrott och hälsa, Religion, Moderna språk, Spanska, Franska, Tyska, Modersmål, Syslöjd, Trä och metallslöjd, Musik",
  },

  {
    icon: <TimelineIcon />,
    title: "Tidslinjer",
    subtitle: "Generera tidslinjer över precis vad du vill.",
    path: "/app/lektioner/tidslinjer",

    roles: ["teacher"],
    category: "Lektioner",
    id: "lektioner-tidslinjer",
    belongsTo:
      "NO, Biologi, Svenska, SO, Historia, Engelska, Kemi, Samhällskunskap, Fysik, Geografi, Teknik, Idrott och hälsa, Religion, Moderna språk, Spanska, Franska, Tyska, Modersmål, Syslöjd, Trä och metallslöjd, Musik",
  },

  {
    icon: <HubIcon />,
    title: "Tankekartor",
    subtitle: "Generera tankekartor över precis vad du vill.",
    path: "/app/lektioner/tankekartor",

    roles: ["teacher"],
    category: "Lektioner",
    id: "lektioner-tankekartor",
    belongsTo:
      "NO, Biologi, Svenska, SO, Historia, Engelska, Kemi, Samhällskunskap, Fysik, Geografi, Teknik, Idrott och hälsa, Religion, Moderna språk, Spanska, Franska, Tyska, Modersmål, Syslöjd, Trä och metallslöjd, Musik",
  },

  {
    icon: <PowerPointIcon />,
    title: "Generera PowerPoint",
    subtitle: "Skapa engagerande presentationer på några sekunder",
    path: "/app/generera-powerpoint",

    roles: ["teacher"],
    category: "Hjälpmedel",
    id: "hjalpmedel-powerpoint",
    belongsTo:
      "Hjälpmedel,NO, Biologi, Svenska, Matematik SO, Historia, Engelska, Kemi, Samhällskunskap, Fysik, Geografi, Teknik, Idrott och hälsa, Religion, Moderna språk, Spanska, Franska, Tyska, Modersmål, Syslöjd, Trä och metallslöjd, Musik",
  },

  {
    icon: <ViewQuiltIcon />,
    title: "Grej of the day",
    subtitle: "Korta, engagerande kuriositeter om spännande ämnen.",
    path: "/app/lektioner/grej-of-the-day",

    roles: ["teacher"],
    category: "Lektioner",
    id: "lektioner-gotd",
    belongsTo:
      "NO, Biologi, Svenska, SO, Historia, Engelska, Kemi, Samhällskunskap, Fysik, Geografi, Teknik, Idrott och hälsa, Religion, Moderna språk, Spanska, Franska, Tyska, Modersmål, Syslöjd, Trä och metallslöjd, Musik",
  },

  {
    icon: <ChatIcon />,
    title: "Konversation",
    subtitle: "Generera en konversation om valfritt ämne.",
    path: "/app/lektioner/konversation",
    label: "NYTT",
    roles: ["teacher"],
    id: "lektioner-konversation",
    category: "Lektioner",
    belongsTo:
      "Svenska, Engelska, Moderna språk, Matematik, Spanska, Franska, Tyska, Modersmål, Syslöjd",
  },

  {
    icon: <GavelIcon />,
    title: "Argumentera",
    subtitle: "Generera argument för och emot inom ett valt ämne.",
    path: "/app/lektioner/argumentera",

    roles: ["teacher"],
    id: "lektioner-argumentera",
    category: "Lektioner",
    belongsTo:
      "NO, SO, Svenska, Biologi, Engelska, Kemi, Samhällskunskap, Fysik, Geografi, Teknik, Idrott och hälsa, Religion, Moderna språk, Spanska, Franska, Tyska, Modersmål, Syslöjd, Trä och metallslöjd, Musik",
  },

  {
    icon: <ScienceIcon />,
    title: "Faktatext",
    subtitle: "Faktatext med olika typer av förståelsefrågor",
    path: "/app/lektioner/lasforstaelse?type=factText",

    roles: ["teacher"],
    category: "Lektioner",
    id: "lektioner-faktatext",
    belongsTo:
      "NO, SO, Svenska, Biologi, Historia, Engelska, Kemi, Samhällskunskap, Fysik, Geografi, Teknik, Idrott och hälsa, Religion, Moderna språk, Spanska, Franska, Tyska, Modersmål, Syslöjd, Trä och metallslöjd, Musik",
  },

  {
    icon: <SubjectIcon />,
    title: "Instruerande text",
    subtitle: "Instruerande text med olika typer av förståelsefrågor",
    path: "/app/lektioner/lasforstaelse?type=instructingText",
    id: "lektioner-intruerande-text",
    roles: ["teacher"],
    category: "Lektioner",
    belongsTo:
      "NO, SO, Svenska, Biologi, Historia, Engelska, Kemi, Samhällskunskap, Fysik, Geografi, Teknik, Idrott och hälsa, Religion, Moderna språk, Spanska, Franska, Tyska, Modersmål, Syslöjd, Trä och metallslöjd, Musik",
  },

  {
    icon: <StairsIcon />,
    title: "Förklarande text",
    subtitle: "Förklarande text med olika typer av förståelsefrågor",
    path: "/app/lektioner/lasforstaelse?type=explainingText",
    id: "lektioner-forklarande-text",
    roles: ["teacher"],
    category: "Lektioner",

    belongsTo:
      "NO, SO, Svenska, Biologi, Historia, Engelska, Samhällskunskap, Fysik, Geografi, Teknik, Idrott och hälsa, Religion, Moderna språk, Spanska, Franska, Tyska, Modersmål, Syslöjd, Trä och metallslöjd, Musik",
  },

  {
    icon: <GavelIcon />,
    title: "Argumenterande text",
    subtitle: "Argumenterande text med olika typer av förståelsefrågor",
    path: "/app/lektioner/lasforstaelse?type=argumentativeText",
    id: "lektioner-argumenterande-text",
    roles: ["teacher"],
    category: "Lektioner",

    belongsTo:
      "NO, SO, Svenska, Biologi, Historia, Engelska, Samhällskunskap, Fysik, Geografi, Teknik, Idrott och hälsa, Religion, Moderna språk, Spanska, Franska, Tyska, Modersmål, Syslöjd, Trä och metallslöjd, Musik",
  },

  {
    icon: <DescriptionIcon />,
    title: "Beskrivande text",
    subtitle: "Beskrivande text med olika typer av förståelsefrågor",
    path: "/app/lektioner/lasforstaelse?type=descriptiveText",
    id: "lektioner-beskrivade-text",
    roles: ["teacher"],
    category: "Lektioner",

    belongsTo:
      "NO, SO, Svenska, Biologi, Historia, Engelska, Samhällskunskap, Fysik, Geografi, Teknik, Idrott och hälsa, Religion, Moderna språk, Spanska, Franska, Tyska, Modersmål, Syslöjd, Trä och metallslöjd, Musik",
  },

  {
    icon: <NewspaperIcon />,
    title: "Krönika",
    subtitle: "Krönika med olika typer av förståelsefrågor",
    path: "/app/lektioner/lasforstaelse?type=chronicle",
    id: "lektioner-kronika",
    roles: ["teacher"],
    category: "Lektioner",
    belongsTo:
      "Svenska, Engelska, Samhällskunskap, Geografi, Teknik, Idrott och hälsa, Religion, Moderna språk, Spanska, Franska, Tyska, Modersmål, Syslöjd, Musik",
  },
  {
    icon: <RateReviewIcon />,
    title: "Recension",
    subtitle: "Recension med olika typer av förståelsefrågor",
    path: "/app/lektioner/lasforstaelse?type=review",
    id: "lektioner-recension",
    roles: ["teacher"],
    category: "Lektioner",

    belongsTo:
      "NO, SO, Svenska, Biologi, Historia, Engelska, Kemi, Samhällskunskap, Fysik, Geografi, Teknik, Idrott och hälsa, Religion, Moderna språk, Spanska, Franska, Tyska, Modersmål, Syslöjd, Musik",
  },

  {
    icon: <MicExternalOnIcon />,
    title: "Intervju",
    subtitle: "Skapa en intervjusituation",
    path: "/app/lektioner/konversation?type=interview",
    id: "lektioner-intervju",
    roles: ["teacher"],
    category: "Lektioner",
    belongsTo:
      "SO, Svenska, Engelska, Historia, Samhällskunskap, Geografi, Teknik, Idrott och hälsa, Religion, Moderna språk, Spanska, Franska, Tyska, Modersmål, Syslöjd, Musik",
  },

  {
    icon: <MenuBookIcon />,
    title: "Läsförståelse",
    subtitle: "Text med olika typer av förståelsefrågor",
    path: "/app/lektioner/lasforstaelse",
    label: "POPULÄR",
    roles: ["teacher"],
    category: "Lektioner",
    id: "lektioner-lasforstaelse",
    belongsTo:
      "Svenska, Engelska, Moderna språk, Spanska, Franska, Tyska, Modersmål, Syslöjd",
  },

  {
    icon: <TrainIcon />,
    title: "På spåret",
    subtitle: "Låt eleverna gissa sig till det rätta svaret utifrån ledtrådar.",
    path: "/app/lektioner/pa-sparet",

    id: "lektioner-pa-sparet",
    roles: ["teacher"],
    category: "Lektioner",
    belongsTo:
      "Svenska, Historia, Biologi, Engelska, Geografi, Moderna språk, Spanska, Franska, Tyska, Modersmål, Syslöjd",
  },

  {
    icon: <AutoStoriesIcon />,
    title: "Forts. berättelsen",
    subtitle:
      "Kreativ skrivövning för att utveckla fantasin och berättarförmågan.",
    path: "/app/lektioner/fortsatt-berattelsen",
    label: "NYTT",
    roles: ["teacher"],
    id: "lektioner-fortsatt-berattelsen",
    category: "Lektioner",
    belongsTo:
      "Svenska, Engelska, Moderna språk, Spanska, Franska, Tyska, Modersmål, Syslöjd",
  },

  {
    icon: <HistoryEduIcon />,
    title: "Berättande text",
    subtitle:
      "Skapa och strukturera berättande texter med stöd för olika genrer.",
    path: "/app/lektioner/berattande-text",
    label: "NYTT",
    roles: ["teacher"],
    id: "lektioner-berattande-text",
    category: "Lektioner",
    belongsTo:
      "Svenska, Engelska, Moderna språk, Spanska, Franska, Tyska, Modersmål, Syslöjd",
  },

  {
    icon: <WindowIcon />,
    title: "Glosor i lådor",
    subtitle:
      "Elever lär sig ord och begrepp med bilder på ett engagerande sätt.",
    path: "/app/lektioner/glosor-i-lador",

    roles: ["teacher"],
    category: "Lektioner",
    id: "lektioner-glosor-i-lador",
    belongsTo:
      "Svenska, Engelska, Moderna språk, Spanska, Franska, Tyska, Modersmål, Syslöjd",
  },

  {
    icon: <DateRangeIcon />,
    title: "Veckans ord",
    subtitle: "Generera veckans ord med olika ljudmönster (t.ex. sje-ljud).",
    path: "/app/lektioner/veckans-ord",

    roles: ["teacher"],
    category: "Lektioner",
    id: "lektioner-veckans-ord",
    belongsTo:
      "NO, Svenska, SO, Engelska, Historia, Kemi, Samhällskunskap, Fysik, Geografi, Teknik, Religion, Moderna språk, Spanska, Franska, Tyska, Modersmål, Syslöjd",
  },

  {
    icon: <StormIcon />,
    title: "Tungvrickare",
    subtitle: 'Generera tungvrickare i stil med "Sex laxar i en laxask".',
    path: "/app/lektioner/tungvrickare",

    roles: ["teacher"],
    category: "Lektioner",
    id: "lektioner-tungvrickare",
    belongsTo:
      "Svenska, Engelska, Moderna språk, Spanska, Franska, Tyska, Modersmål, Syslöjd",
  },

  {
    icon: <MenuBookIcon />,
    title: "Grammatik",
    subtitle:
      "Generera meningar och berättelse baserat på grammatiska koncept.",
    path: "/app/lektioner/grammatik",

    roles: ["teacher"],
    category: "Lektioner",
    id: "lektioner-grammatik",
    belongsTo:
      "Svenska, Engelska, Moderna språk, Spanska, Franska, Tyska, Modersmål, Syslöjd",
  },

  {
    icon: <FormatQuoteIcon />,
    title: "Idiomatiska uttryck",
    subtitle: "Generera idiom med betydelse, exempelmeningar och berättelse.",
    path: "/app/lektioner/idiom",

    roles: ["teacher"],
    id: "lektioner-idiomatiska-uttryck",
    category: "Lektioner",
    belongsTo:
      "Svenska, Engelska, Moderna språk, Spanska, Franska, Tyska, Modersmål, Syslöjd",
  },

  {
    icon: <PaletteIcon />,
    title: "Målarbilder",
    subtitle: "Generera målarbilder som elever kan färglägga.",
    path: "/app/lektioner/malarbilder",
    id: "lektioner-malarbilder",
    roles: ["teacher"],
    category: "Lektioner",
    belongsTo:
      "Svenska, Engelska, Moderna språk, Spanska, Franska, Tyska, Modersmål, Syslöjd",
  },

  {
    icon: <DoorSlidingIcon />,
    title: "Mystiska bilden",
    subtitle: "Eleven skriver en historia om vad de ser på bilden",
    path: "/app/lektioner/mystiska-bilden",
    id: "lektioner-mystiska-bilden",
    roles: ["teacher"],
    category: "Lektioner",
    belongsTo:
      "Svenska, Engelska, Moderna språk, Spanska, Franska, Tyska, Modersmål, Syslöjd",
  },

  {
    icon: <GridOnIcon />,
    title: "Hitta orden",
    subtitle: "Hitta orden i ett rutnät fyllda av bokstäver.",
    path: "/app/lektioner/hitta-ordet",
    id: "lektioner-hitta-orden",
    roles: ["teacher"],
    category: "Lektioner",
    belongsTo:
      "NO, SO, Svenska, Engelska, Historia, Kemi, Samhällskunskap, Fysik, Geografi, Teknik, Religion, Moderna språk, Spanska, Franska, Tyska, Modersmål, Syslöjd",
  },

  {
    icon: <TbChartGridDotsFilled />,
    title: "Bilda ordet",
    subtitle: "Dra streck och bilda ordet på den genererade bilden.",
    path: "/app/lektioner/bokstaver",
    id: "lektioner-bilda-ordet",
    roles: ["teacher"],
    category: "Lektioner",
    belongsTo:
      "Svenska, Engelska, Moderna språk, Spanska, Franska, Tyska, Modersmål, Syslöjd",
  },

  {
    icon: <SubjectIcon />,
    title: "Skriv fint",
    subtitle: "Skrivövning med bilder. Justera antalet rader.",
    path: "/app/lektioner/skrivlektion",
    id: "lektioner-skriv-fint",
    roles: ["teacher"],
    category: "Lektioner",
    belongsTo:
      "Svenska, Engelska, Moderna språk, Spanska, Franska, Tyska, Modersmål, Syslöjd",
  },

  {
    icon: <MenuBookIcon />,
    title: "Läsförståelse",
    subtitle: "Generera text med olika typer av övningar.",
    path: "/app/lektioner/lasforstaelse",
    roles: ["teacher"],
    category: "Läsförståelse",
    id: "lektioner-grundl-lasforstaelse",
    belongsTo:
      "Svenska, Engelska, Moderna språk, Spanska, Franska, Tyska, Modersmål, Syslöjd",
  },
  // {
  //   icon: <GavelIcon />,
  //   title: "Läsdetektiven",
  //   subtitle: "Hitta ledtrådar och dra slutsatser från texten",
  //   path: "/app/lektioner/detektiv-lasforstaelse",
  //   roles: ["teacher"],
  //   id: "lektioner-lasdetektiven",
  //   category: "Läsförståelse",
  //   belongsTo:
  //     "Svenska, Engelska, Moderna språk, Spanska, Franska, Tyska, Modersmål, Syslöjd",
  // },
  // {
  //   icon: <CompareArrowsIcon />,
  //   title: "Jämförande läsning",
  //   subtitle: "Analysera två relaterade texter",
  //   path: "/app/lektioner/jamforande-lasforstaelse",
  //   id: "lektioner-jamforande-lasning",
  //   roles: ["teacher"],
  //   category: "Läsförståelse",
  //   belongsTo:
  //     "Svenska, Engelska, Moderna språk, Spanska, Franska, Tyska, Modersmål, Syslöjd",
  // },
  // {
  //   icon: <CreateIcon />,
  //   title: "Kreativ läsförståelse",
  //   subtitle: "Läs och skapa egen fortsättning",
  //   path: "/app/lektioner/kreativ-lasforstaelse",
  //   id: "lektioner-kreativ-lasforstaelse",
  //   roles: ["teacher"],
  //   category: "Läsförståelse",
  //   belongsTo:
  //     "Svenska, Engelska, Moderna språk, Spanska, Franska, Tyska, Modersmål, Syslöjd",
  // },
  // {
  //   icon: <TextFieldsIcon />,
  //   title: "Ordförståelse",
  //   subtitle: "Fokus på ordkunskap och språkutveckling",
  //   path: "/app/lektioner/ordforstaelse",
  //   id: "lektioner-ordforstaelse",
  //   roles: ["teacher"],
  //   category: "Läsförståelse",
  //   belongsTo:
  //     "Svenska, Engelska, Moderna språk, Spanska, Franska, Tyska, Modersmål, Syslöjd",
  // },

  {
    icon: <EventNoteIcon />,
    title: "Lektionsplan",
    subtitle:
      "Skapa en lektionsplan baserat på skolverkets kursplan eller läslistor.",
    path: "/app/lektioner/lektionsplan",
    id: "planering-lektionsplan",
    roles: ["teacher"],
    category: "Planering",
    belongsTo: "Planering",
  },

  {
    icon: <RateReviewIcon />,
    title: "Formativ bedömning",
    subtitle: "Bedöm en text baserat på vald bedömningsmatris.",
    path: "/app/bedomning/formativ-bedomning",
    roles: ["teacher"],
    category: "Bedömning",
    id: "bedomning-formativ",
    label: "Populär",
    belongsTo: "Bedömning",
  },

  {
    icon: <TableChartIcon />,
    title: "Bedömningsmatriser",
    subtitle: "Skapa avancerade bedömningsmatriser.",
    path: "/app/matris/ny",
    roles: ["teacher"],
    category: "Bedömning",
    id: "bedomning-bedomningsmatriser",
    belongsTo: "Bedömning",
  },

  {
    icon: <GradingIcon />,
    title: "Betygsätt en text",
    subtitle: "Betygsätt text baserat på vald bedömningsmatris.",
    path: "/app/uppsats/ny",
    roles: ["teacher"],
    category: "Bedömning",
    id: "bedomning-betygsatt-text",
    belongsTo: "Bedömning",
  },

  {
    icon: "https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/teacherImages%2FZ6BLSMsm38Un5mgW0O6Z3TToHq12%2Fimg-oTRPTuwic0Dfh30rq4hiU.jpg?alt=media&token=4adee3a3-909e-43f5-8f17-cc3fcd327b67",
    title: "Lärarassistent Max",
    subtitle: "Assisterar lärare med allt de kan tänkas behöva.",
    path: "/app/chat/MCkZkDEFiSKducNHuMvW",
    roles: ["teacher"],
    category: "Chatbotar",
    label: "Populär",
    id: "chatbotar-lararassistent-max",
    belongsTo: "Chatbotar",
  },
  // {
  //   icon: <PsychologyIcon />,
  //   title: 'Feedback på inlämningsuppgift',
  //   subtitle: 'Ge konstruktiv feedback på en inlämningsuppgift.',
  //   path: '/app/fraga-ai?prompt=feedback-inlamning',
  //   roles: ['teacher'],
  //   category: 'Lektionsplanering',
  // },

  // {
  //   icon: <AssignmentTurnedInIcon />,
  //   title: 'Skapa individuell studieplan',
  //   subtitle: 'Generera en anpassad studieplan för en elev',
  //   path: '/app/fraga-ai?prompt=individuell-studieplan',
  //   roles: ['teacher'],
  //   category: 'Planering',
  // },

  // {
  //   icon: <EventNoteIcon />,
  //   title: 'Planera tematiskt arbete',
  //   subtitle: 'Utforma ett ämnesövergripande tematiskt arbete',
  //   path: '/app/fraga-ai?prompt=tematiskt-arbete',
  //   roles: ['teacher'],
  //   category: 'Lektionsplanering',
  // },
  // {
  //   icon: <EmojiObjectsIcon />,
  //   title: 'Generera kreativa övningar',
  //   subtitle: 'Skapa engagerande och kreativa övningar för ett ämne',
  //   path: '/app/fraga-ai?prompt=kreativa-ovningar',
  //   roles: ['teacher'],
  //   category: 'Undervisningsaktiviteter',
  // },
  // {
  //   icon: <FormatQuoteIcon />,
  //   title: 'Skapa diskussionsfrågor',
  //   subtitle: 'Generera tankeväckande frågor för gruppdiskussioner',
  //   path: '/app/fraga-ai?prompt=diskussionsfragor',
  //   roles: ['teacher'],
  //   category: 'Undervisningsaktiviteter',
  // },

  {
    icon: "https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/misc%2Fnew%20batch%2FVirginia%20Woolf.png?alt=media&token=4002e5d0-d43f-40fe-b3d8-4b494920e901",
    title: "Virginia Woolf",
    subtitle:
      "Brittisk författare och essäist, central figur inom modernismen.",
    path: "/app/chat/jQOVP4WABFtWpIkHZWYD",
    roles: ["teacher"],
    category: "Chatbotar",
    id: "chatbotar-woolf",
    belongsTo: "Chatbotar",
  },
  {
    icon: "https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/misc%2Fnew%20batch%2FMary%20Wollstonecraft.png?alt=media&token=398b8b28-fd8f-4e86-b6c2-63f590fd31e9",
    title: "Mary Wollstonecraft",
    subtitle:
      "Brittisk författare, filosof och tidig förespråkare för kvinnors rättigheter.",
    path: "/app/chat/kuTk3RuecSRMXvximDsx",
    roles: ["teacher"],
    category: "Chatbotar",
    id: "chatbotar-wollstonecraft",
    belongsTo: "Chatbotar",
  },
  {
    icon: "https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/misc%2Fnew%20batch%2Fjesus.png?alt=media&token=9bf8d543-a13c-47bd-a20a-9a83aa8f801b",
    title: "Jesus",
    subtitle: "Central gestalt inom kristendomen, betraktad som frälsare av...",
    path: "/app/chat/dL1NVd9HMYxQvTL2j9VO",
    roles: ["teacher"],
    category: "Chatbotar",
    id: "chatbotar-jesus",
    belongsTo: "Chatbotar",
  },

  {
    icon: "https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/misc%2Fnew%20batch%2FSigmund%20Freud.png?alt=media&token=0c978d80-bd28-4634-842e-e53b13320b6d",
    title: "Sigmund Freud",
    subtitle: "Österrikisk neurolog och grundare av psykoanalysens fader...",
    path: "/app/chat/nHNQmF42laxU8ccC6DDg",
    roles: ["teacher"],
    category: "Chatbotar",
    id: "chatbotar-freud",
    belongsTo: "Chatbotar",
  },

  {
    icon: "https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/misc%2Feinstein.png?alt=media&token=a237fa40-6232-4c6b-bcd3-3574ed369219",
    title: "Albert Einstein",
    subtitle:
      "Tysk-schweizisk teoretisk fysiker, känd för relativitetsteorin...",
    path: "/app/chat/oAc5im2gfmlJXrF6XvPv",
    roles: ["teacher"],
    category: "Chatbotar",
    id: "chatbotar-einstein",
    belongsTo: "Chatbotar",
  },

  {
    icon: "https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/misc%2FNietzsche.png?alt=media&token=885b7a79-a20a-44d6-b8ff-5d94a8154ec4",
    title: "Friedrich Nietzsche",
    subtitle:
      "Tysk filosof, kulturkritiker och filolog, känd för sin skarpa kritik mot...",
    path: "/app/chat/pTJ2H7NtCijwQH40VGIV",
    roles: ["teacher"],
    category: "Chatbotar",
    id: "chatbotar-nietzsche",
    belongsTo: "Chatbotar",
  },
  {
    icon: "https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/misc%2Fplaton.png?alt=media&token=3cb1a62d-d524-4f17-a30d-ce21caa53053",
    title: "Platon",
    subtitle: "Antik grekisk filosof, grundare av Akademin i Aten...",
    path: "/app/chat/pqSK7pqWXsyEsOkxpUy0",
    roles: ["teacher"],
    category: "Chatbotar",
    id: "chatbotar-platon",
    belongsTo: "Chatbotar",
  },

  {
    icon: "https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/misc%2Fnew%20batch%2Faristoteles.png?alt=media&token=2425b91d-ec0f-48ed-a43f-cf3eb164f23a",
    title: "Aristoteles",
    subtitle: "Antik grekisk filosof, elev till Platon och läromästare till...",
    path: "/app/chat/kvZhgusQRykTBUmNA8dG",
    roles: ["teacher"],
    category: "Chatbotar",
    id: "chatbotar-aristoteles",
    belongsTo: "Chatbotar",
  },

  {
    icon: "https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/misc%2Fl%C3%A4rare%2Fspanskaa.png?alt=media&token=be8a3516-14a3-4eba-88bf-85018585cb47",
    title: "Spansklärare",
    subtitle: "Chatta eller prata med vår populära spansklärare.",
    path: "/app/chat/nTB3pwXxBdLHbhar839H",
    roles: ["teacher"],
    category: "Chatbotar",
    id: "chatbotar-spanska",
    belongsTo: "Chatbotar",
    popular: true,
  },

  {
    icon: "https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/misc%2Fl%C3%A4rare%2Ftyska.png?alt=media&token=9ed00390-34ef-427c-9a7c-a70e5f1bed0e",
    title: "Tysklärare",
    subtitle: "Plugga på din tyska tillsammans med Johan Bauer.",
    path: "/app/chat/14GRiHrsHh2f3YDKilDq",
    roles: ["teacher"],
    category: "Chatbotar",
    id: "chatbotar-tyska",
    belongsTo: "Chatbotar",
    popular: true,
  },

  {
    icon: "https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/misc%2Fl%C3%A4rare%2Ffranska.png?alt=media&token=5ab54912-a63a-405a-bafa-18f4acae8ea7",
    title: "Fransklärare",
    subtitle: "Plugga på din franska tillsammans med Marie Dubois.",
    path: "/app/chat/3dRmkg2iFEyDa5vPKTUy",
    roles: ["teacher"],
    category: "Chatbotar",
    id: "chatbotar-franska",
    belongsTo: "Chatbotar",
    popular: true,
  },

  {
    icon: "https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/teacherImages%2Fimg-gaj9xbqFt3IwTDtrjBhqd.jpg?alt=media&token=8c503b57-dccb-4896-915a-44e50fb71146",
    title: "Fysiklärare",
    subtitle: "Passionerad fysiklärare som vill göra vetensk...",
    path: "/app/chat/A5EEIaLgdLYKRAgEzZg6",
    roles: ["teacher"],
    category: "Chatbotar",
    id: "chatbotar-fysiklarare",
    belongsTo: "Chatbotar",
  },

  {
    icon: "https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/teacherImages%2Fimg-P3HIslmb1xtSYDiXeU2bD.jpg?alt=media&token=b4ea68c7-a1de-42f2-a1ac-e06f736f6943",
    title: "Biologilärare",
    subtitle:
      "En engagerad biologilärare som älskar att förklara biologiska...",
    path: "/app/chat/RuyzusXZzXDEPvfxMmY8",
    roles: ["teacher"],
    category: "Chatbotar",
    id: "chatbotar-biologi",
    belongsTo: "Chatbotar",
  },

  {
    icon: <Person3Icon />,
    title: "Skapa AI-karaktär",
    subtitle: "Skapa en karaktär som underlättar ditt arbete.",
    path: "/app/larare",
    roles: ["teacher"],
    category: "Chatbotar",
    id: "chatbotar-skapa-egen-karaktar",
    belongsTo: "Chatbotar",
  },

  // {
  //   icon: <SummarizeIcon />,
  //   title: "Summera text",
  //   subtitle: "Sammanfatta din text för att extrahera det viktigaste.",
  //   path: "/app/textlabbet?function=summera",
  //   roles: ["teacher"],
  //   category: "Textanalys",
  //   id: "textanalys-summera",
  //   belongsTo: "Textlabbet",
  // },

  // {
  //   icon: <SpellcheckIcon />,
  //   title: "Rätta text",
  //   subtitle: "Korrigera stavfel och grammatiska fel i din text.",
  //   path: "/app/textlabbet?function=ratta",
  //   roles: ["teacher"],
  //   category: "Textanalys",
  //   id: "textanalys-ratta",
  //   belongsTo: "Textlabbet",
  // },

  // {
  //   icon: <BarChartIcon />,
  //   title: "Analysera text",
  //   subtitle: "Analysera textens innehåll, struktur och budskap.",
  //   path: "/app/textlabbet?function=analysera",
  //   roles: ["teacher"],
  //   category: "Textanalys",
  //   id: "textanalys-analysera",
  //   belongsTo: "Textlabbet",
  // },
  // {
  //   icon: <BarChartIcon />,
  //   title: 'Utforma formativ bedömning',
  //   subtitle: 'Skapa strategier för löpande kunskapsutvärdering',
  //   path: '/app/fraga-ai?prompt=formativ-bedomning',
  //   roles: ['teacher'],
  //   category: 'Bedömning',
  // },
  // {
  //   icon: <TranslateIcon />,
  //   title: 'Språkinlärningsaktiviteter',
  //   subtitle: 'Generera övningar för att effektivisera språkinlärning.',
  //   path: '/app/fraga-ai?prompt=sprakinlarning',
  //   roles: ['teacher'],
  //   category: 'Ämnesspecifikt',
  // },
  // {
  //   icon: <MenuBookIcon />,
  //   title: 'Skapa läsförståelseuppgifter',
  //   subtitle: 'Generera uppgifter baserade på en text eller bok',
  //   path: '/app/fraga-ai?prompt=lasforstaelse',
  //   roles: ['teacher'],
  //   category: 'Ämnesspecifikt',
  // },
  // {
  //   icon: <CastForEducationIcon />,
  //   title: 'Planera digitalt lärande',
  //   subtitle: 'Utforma strategier för effektivt digitalt lärande',
  //   path: '/app/fraga-ai?prompt=digitalt-larande',
  //   roles: ['teacher'],
  //   category: 'Lektionsplanering',
  // },

  //// STUDENT SHORTCUTS

  {
    icon: <ChatIcon />,
    title: "Fråga AI",
    popular: true,
    path: "/app/fraga-ai",
    subtitle: "Få hjälp med läxor, idéer och svar på dina frågor",
    roles: ["student"],
    category: "Verktyg",
    id: "verktyg-fraga-ai",
    belongsTo: "Verktyg",
  },

  {
    icon: <QuizIcon />,
    title: "Quiz",
    subtitle: "Generera quiz som du kan anpassa hur du vill.",
    path: "/app/quiz",
    roles: ["student"],
    category: "Quiz",

    id: "quiz-quiz",
    belongsTo: "Quiz",
    popular: true,
  },

  {
    icon: <WindowIcon />,
    title: "Mina quiz",
    subtitle: "Mina quiz som jag skapat eller lärare delat med mig.",
    path: "/app/mina-quiz",
    roles: ["student"],
    category: "Quiz",
    id: "quiz-mina-quiz",
    belongsTo: "Quiz",
  },

  {
    icon: <ViewAgendaIcon />,
    title: "Flashcards",
    subtitle: "Skapa anpassade flashcards för alla ämnen och nivåer",
    path: "/app/flashcards",
    roles: ["student"],
    category: "Flashcards",
    id: "flashcards-flashcards",
    belongsTo: "Flashcards",
    popular: true,
  },

  {
    icon: <WindowIcon />,
    title: "Mina flashcards",
    subtitle: "Mina flashcards som jag skapat eller lärare delat med mig.",
    path: "/app/mina-flashcards",
    roles: ["student"],
    category: "Flashcards",
    id: "flashcards-mina-flashcards",
    belongsTo: "Flashcards",
  },

  {
    icon: <ImageSearchIcon />,
    title: "Bildanalys",
    subtitle: "Ladda upp en bild och låt vår AI analysera den.",
    path: "/app/vision",
    label: "NYTT",
    roles: ["student"],
    category: "Hjälpmedel",
    id: "hjalpmedel-vision",
    belongsTo: "Hjälpmedel",
    popular: true,
  },

  {
    icon: <CreateIcon />,
    title: "Textlabbet",
    subtitle: "Bearbeta din text precis hur du vill",
    path: "/app/textlabbet",

    roles: ["student"],
    category: "Textbearbetning",
    id: "textbearbetning-textlabbet",
    belongsTo: "Textbearbetning",
    popular: true,
  },

  {
    icon: <MenuBookIcon />,
    title: "Förklara för mig",
    subtitle: "Få vad som helst förklarat i önskat format.",
    path: "/app/fraga-ai?p=explain",
    roles: ["student"],
    category: "Övrigt",
    id: "ovrigt-forklara",
    belongsTo: "Övrigt",
    popular: true,
  },

  {
    icon: <GradingIcon />,
    title: "Betygsätt min text",
    subtitle: "Betygsätt din text utifrån en bedömningsmatris.",
    path: "/app/uppsats/ny",
    roles: ["student"],
    category: "Bedömning",

    id: "bedomning-betygsatt-min-text",
    belongsTo: "Bedömning",
    popular: true,
  },

  {
    icon: <PeopleIcon />,
    title: "Rollspel",
    subtitle: "Låt en chatbot spela en anpassad roll som du väljer.",
    path: "/app/fraga-ai?p=roleplay",
    roles: ["student"],
    category: "Övrigt",
    id: "ovrigt-rollspel",
    belongsTo: "Övrigt",
    popular: true,
  },

  {
    icon: <QuestionAnswerIcon />,
    title: "Debatt",
    subtitle:
      "Simulera en debatt om valfritt ämne och välj position för eller emot.",
    path: "/app/fraga-ai?p=debate",
    roles: ["student"],
    category: "Övrigt",
    id: "ovrigt-debatt",
    belongsTo: "Övrigt",
    popular: true,
  },

  {
    icon: <SchoolIcon />,
    title: "Gamla högskoleprov",
    subtitle: "Plugga på gamla högskoleprov eller skapa eget med AI.",
    path: "/app/hogskoleprov",
    roles: ["student"],
    category: "Övrigt",
    id: "hogskoleprov-gamla",
    belongsTo: "Övrigt",
    popular: true,
  },

  {
    icon: <CompareIcon />,
    title: "Jämför koncept",
    subtitle: "Jämför och förklara skillnader mellan två ämnen/koncept.",
    path: "/app/fraga-ai?p=compare",
    roles: ["student"],
    category: "Övrigt",
    id: "ovrigt-jamfor",
    belongsTo: "Övrigt",
    popular: true,
  },

  {
    icon: <RateReviewIcon />,
    title: "Formativ bedömning",
    subtitle: "Bedöm en text baserat utifrån vald bedömningsmatris.",
    path: "/app/bedomning/formativ-bedomning",
    roles: ["student"],
    category: "Bedömning",
    id: "bedomning-formativ-bedomning",

    belongsTo: "Bedömning",
  },

  {
    icon: <MenuOpenIcon />,
    title: "Skapa din egen kurs",
    subtitle: "Generera kurs om valfritt ämne & studera i egen takt.",
    path: "/app/kurser",
    roles: ["student"],
    category: "Kurser",

    id: "kurser-skapa-kurs",
    belongsTo: "Kurser",
    popular: true,
  },

  // {
  //   icon: <YouTubeIcon />,
  //   title: "Skapa quiz från YouTube-klipp",
  //   subtitle: "Generera ett quiz baserat på t.ex. en föreläsning från YT.",
  //   path: "/app/quiz?source=youtube",
  //   roles: ["student"],
  //   category: "Quiz",
  //   id: "quiz-youtube",
  //   belongsTo: "Hjälpmedel",
  // },

  // {
  //   icon: <TopicIcon />,
  //   title: "Skapa quiz från ämne",
  //   subtitle: "Generera ett quiz baserat på ett valfritt ämne.",
  //   path: "/app/quiz?source=topic",
  //   roles: ["student"],
  //   category: "Quiz",
  //   id: "quiz-amne",
  //   belongsTo: "Hjälpmedel",
  // },

  // {
  //   icon: <WikipediaIcon />,
  //   title: "Skapa quiz från Wiki-URL",
  //   subtitle: "Skriv in en Wikipedia-URL och få ett quiz.",
  //   path: "/app/quiz?source=wiki",
  //   roles: ["student"],
  //   category: "Quiz",
  //   id: "quiz-wiki",
  //   belongsTo: "Hjälpmedel",
  // },
  // {
  //   icon: <AttachFileIcon />,
  //   title: "Skapa quiz från Word/PDF-fil",
  //   subtitle: "Ladda upp din word/PDF-fil och generera ett quiz.",
  //   path: "/app/quiz?source=word",
  //   roles: ["student"],
  //   category: "Quiz",
  //   id: "quiz-word",
  //   belongsTo: "Hjälpmedel",
  // },

  // {
  //   icon: <YouTubeIcon />,
  //   title: "Skapa flashcards från YT-klipp",
  //   subtitle: "Generera en samling flashcards baserat på en YouTube-video.",
  //   path: "/app/flashcards?source=youtube",
  //   roles: ["student"],
  //   category: "Flashcards",
  //   id: "flashcards-youtube",
  //   belongsTo: "Hjälpmedel",
  // },

  // {
  //   icon: <TopicIcon />,
  //   title: "Skapa flashcards",
  //   subtitle: "Generera en samling flashcards baserat på ett valfritt ämne.",
  //   path: "/app/flashcards",
  //   roles: ["student"],
  //   category: "Flashcards",
  //   id: "flashcards-skapa",
  //   belongsTo: "Hjälpmedel",
  // },

  // {
  //   icon: <AttachFileIcon />,
  //   title: "Skapa flashcards från Word/PDF",
  //   subtitle: "Ladda upp din Word/PDF-fil och generera en samling flashcards.",
  //   path: "/app/flashcards?source=word",
  //   roles: ["student"],
  //   category: "Flashcards",
  //   id: "flashcards-word",
  //   belongsTo: "Hjälpmedel",
  // },

  // {
  //   icon: <WikipediaIcon />,
  //   title: "Skapa flashcards från Wiki-URL",
  //   subtitle: "Skriv in en Wikipedia-URL och få en samling flashcards.",
  //   path: "/app/flashcards?source=wiki",
  //   roles: ["student"],
  //   category: "Flashcards",
  //   id: "flashcards-wiki",
  //   belongsTo: "Hjälpmedel",
  // },

  {
    icon: <ImageIcon />,
    title: "Generera bilder",
    subtitle: "Skapa unika bilder för ditt undervisningsmaterial",
    path: "/app/generera-bild",

    roles: ["student"],
    category: "Verktyg",
    id: "verktyg-generera-bilder",
    belongsTo: "Verktyg",
    popular: true,
  },
  {
    icon: <PowerPointIcon />,
    title: "Generera PowerPoint",
    subtitle: "Skapa engagerande presentationer på några sekunder",
    path: "/app/generera-powerpoint",

    roles: ["student"],
    category: "Verktyg",
    id: "verktyg-powerpoint",
    belongsTo: "Verktyg",
    popular: true,
  },

  {
    icon: <SummarizeIcon />,
    title: "Summera text",
    subtitle: "Sammanfatta din text för att extrahera det viktigaste.",
    path: "/app/textlabbet?function=summera",
    roles: ["student"],
    category: "Textbearbetning",

    id: "textlabbet-summera",
    belongsTo: "Textlabbet",
  },
  {
    icon: <ShuffleIcon />,
    title: "Variera text",
    subtitle: "Omformulera din text utan att ändra innebörden.",
    path: "/app/textlabbet?function=variera",
    roles: ["student"],
    category: "Textbearbetning",
    id: "textlabbet-variera",
    belongsTo: "Textlabbet",
  },

  {
    icon: <SpellcheckIcon />,
    title: "Rätta text",
    subtitle: "Korrigera stavfel och grammatiska fel i din text.",
    path: "/app/textlabbet?function=ratta",
    roles: ["student"],
    category: "Textbearbetning",
    id: "textlabbet-ratta",
    belongsTo: "Textlabbet",
  },
  {
    icon: <FormatAlignLeftIcon />,
    title: "Formatera text",
    subtitle: "Förbättra textens struktur och läsbarhet.",
    path: "/app/textlabbet?function=formatera",
    roles: ["student"],
    category: "Textbearbetning",
    id: "textlabbet-formatera",
    belongsTo: "Textlabbet",
  },

  // {
  //   icon: <FactCheckIcon />,
  //   title: "Faktakolla text",
  //   subtitle:
  //     "Verifiera påståenden i din text och säkerställ att den är korrekt.",
  //   path: "/app/textlabbet?function=faktakolla",
  //   roles: ["student"],
  //   category: "Textanalys",
  //   id: "textlabbet-faktakolla",
  //   belongsTo: "Textlabbet",
  // },
  {
    icon: <FormatListBulletedIcon />,
    title: "Skapa punktlista",
    subtitle: "Omvandla din text till en tydlig punktlista.",
    path: "/app/textlabbet?function=punktlista",
    roles: ["student"],
    category: "Textbearbetning",
    id: "textlabbet-punktlista",
    belongsTo: "Textlabbet",
  },

  {
    icon: <TrendingUpIcon />,
    title: "Förbättra text",
    subtitle: "Förbättra textens flyt och ton, gör den mer engagerande.",
    path: "/app/textlabbet?function=forbattra",
    roles: ["student"],
    category: "Textbearbetning",
    id: "textlabbet-forbattra",
    belongsTo: "Textlabbet",
  },
  // {
  //   icon: <RateReviewIcon />,
  //   title: "Kritisera text",
  //   subtitle: "Ge konstruktiv kritik på textens innehåll och upplägg.",
  //   path: "/app/textlabbet?function=kritisera",
  //   roles: ["student"],
  //   category: "Textanalys",
  //   id: "textlabbet-kritisera",
  //   belongsTo: "Textlabbet",
  // },
  {
    icon: <AssessmentIcon />,
    title: "AI-detektor",
    subtitle: "Se om en text är skriven av en AI eller inte.",
    path: "/app/ai-detektor",
    roles: ["student"],
    category: "Verktyg",
    id: "verktyg-ai-detektor",
    belongsTo: "AI-Verktyg",
  },
  // {
  //   icon: <ViewStreamIcon />,
  //   title: "Omstrukturera text",
  //   subtitle: "Ändra textens struktur för bättre flöde.",
  //   path: "/app/textlabbet?function=omstrukturera",
  //   roles: ["student"],
  //   category: "Textbearbetning",
  //   id: "textlabbet-omstrukturera",
  //   belongsTo: "Textlabbet",
  // },
  // {
  //   icon: <GavelIcon />,
  //   title: "Argumentationsanalys",
  //   subtitle: "Identifiera och utvärdera argument som presenteras i texten.",
  //   path: "/app/textlabbet?function=argumentanalysera",
  //   roles: ["student"],
  //   category: "Textanalys",
  //   id: "textlabbet-argumentationsanalys",
  //   belongsTo: "Textlabbet",
  // },
  // {
  //   icon: <CompareArrowsIcon />,
  //   title: "Jämför koncept & idéer",
  //   subtitle: "Jämför olika koncept eller idéer i texten.",
  //   path: "/app/textlabbet?function=konceptideer",
  //   roles: ["student"],
  //   category: "Textanalys",
  //   id: "textlabbet-jamfor-koncept-och-ideer",
  //   belongsTo: "Textlabbet",
  // },
  // {
  //   icon: <LinkIcon />,
  //   title: "Intertextuell analys",
  //   subtitle: "Hitta kopplingar till andra texter eller verk.",
  //   path: "/app/textlabbet?function=intertextuellanalys",
  //   roles: ["student"],
  //   category: "Textanalys",
  //   id: "textlabbet-intertextuell-analys",
  //   belongsTo: "Textlabbet",
  // },
  // {
  //   icon: <AssignmentIcon />,
  //   title: "Generera studieuppgifter",
  //   subtitle: "Skapa uppgifter baserade på textens innehåll.",
  //   path: "/app/textlabbet?function=generera-studieuppgifter",
  //   roles: ["student"],
  //   category: "Studiehjälp",
  //   id: "textlabbet-studieuppgifter",
  //   belongsTo: "Textlabbet",
  // },
  // {
  //   icon: <QuizIcon />,
  //   title: 'Generera flervalsfrågor',
  //   subtitle: 'Skapa flervalsfrågor baserade på texten.',
  //   path: '/app/textlabbet?function=generera-flervalsfragor',
  //   roles: ['teacher'],
  //   category: 'Undervisningsaktiviteter',
  // },
  // {
  //   icon: <TextFieldsIcon />,
  //   title: 'Generera lucktext',
  //   subtitle: 'Skapa lucktexter baserade på innehållet.',
  //   path: '/app/textlabbet?function=generera-lucktext',
  //   roles: ['teacher'],
  //   category: 'Undervisningsaktiviteter',
  // },
  // {
  //   icon: <PsychologyIcon />,
  //   title: 'Metakognitiva reflektioner',
  //   subtitle: 'Skapa frågor för att utveckla metakognitiva förmågor',
  //   path: '/app/fraga-ai?prompt=metakognition',
  //   roles: ['teacher'],
  //   category: 'Undervisningsaktiviteter',
  // },

  // {
  //   icon: <AutoFixHighIcon />,
  //   title: "Skapa studieplan",
  //   subtitle: "Generera en personlig studieplan baserad på dina mål",
  //   path: "/app/fraga-ai?prompt=skapa-studieplan",
  //   roles: ["student"],
  //   category: "Studiehjälp",
  // },
  // {
  //   icon: <FormatListBulletedIcon />,
  //   title: "Sammanfattningsteknik",
  //   subtitle: "Lär dig effektiva metoder för att sammanfatta texter",
  //   path: "/app/fraga-ai?prompt=sammanfattningsteknik",
  //   roles: ["student"],
  //   category: "Studiehjälp",
  // },
  // {
  //   icon: <EmojiObjectsIcon />,
  //   title: "Minnestekniker",
  //   subtitle: "Upptäck kraftfulla minnestekniker för bättre inlärning",
  //   path: "/app/fraga-ai?prompt=minnestekniker",
  //   roles: ["student"],
  //   category: "Studiehjälp",
  // },
  // {
  //   icon: <AccessTimeIcon />,
  //   title: "Tidshantering",
  //   subtitle: "Få tips för effektiv tidshantering i dina studier",
  //   path: "/app/fraga-ai?prompt=tidshantering",
  //   roles: ["student"],
  //   category: "Studiehjälp",
  // },
  // {
  //   icon: <MenuBookIcon />,
  //   title: "Lästekniker",
  //   subtitle: "Lär dig effektiva tekniker för att läsa och förstå texter",
  //   path: "/app/fraga-ai?prompt=lastekniker",
  //   roles: ["student"],
  //   category: "Studiehjälp",
  // },
  // {
  //   icon: <PsychologyIcon />,
  //   title: "Koncentrationstekniker",
  //   subtitle: "Förbättra din koncentration och fokus under studier",
  //   path: "/app/fraga-ai?prompt=koncentrationstekniker",
  //   roles: ["student"],
  //   category: "Studiehjälp",
  // },

  // {
  //   icon: <AccessTimeIcon />,
  //   title: "Effektiv studieteknik",
  //   subtitle: "Få tips och strategier för effektivt lärande.",
  //   path: "/app/fraga-ai?prompt=studieteknik",
  //   roles: ["student"],
  //   category: "Studiehjälp",
  // },

  {
    icon: <ExploreIcon />,
    title: "Alla våra AI-karaktärer",
    subtitle: "Chatta med Platon, Virginia Woolf eller Leif GW Persson",
    path: "/app/personal",
    roles: ["student"],
    category: "Chatbotar",
    id: "chatbotar-upptack",
    belongsTo: "Chatbotar",
    popular: true,
  },

  {
    icon: <Person3Icon />,
    title: "Skapa din egen AI-karaktär",
    subtitle: "Bestäm exakt hur du vill att karaktären ska bete sig.",
    path: "/app/larare",
    roles: ["student"],
    category: "Chatbotar",
    id: "chatbotar-skapa-din-egen-karaktar",
    belongsTo: "Chatbotar",
    popular: true,
  },

  {
    icon: "https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/misc%2Fl%C3%A4rare%2Fspanskaa.png?alt=media&token=be8a3516-14a3-4eba-88bf-85018585cb47",
    title: "Spansklärare",
    subtitle: "Chatta eller prata med vår populära spansklärare.",
    path: "/app/chat/nTB3pwXxBdLHbhar839H",
    roles: ["student"],
    category: "Chatbotar",
    id: "chatbotar-spanska",
    belongsTo: "Chatbotar",
    popular: true,
  },

  {
    icon: "https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/misc%2Fl%C3%A4rare%2Ftyska.png?alt=media&token=9ed00390-34ef-427c-9a7c-a70e5f1bed0e",
    title: "Tysklärare",
    subtitle: "Plugga på din tyska tillsammans med Johan Bauer.",
    path: "/app/chat/14GRiHrsHh2f3YDKilDq",
    roles: ["student"],
    category: "Chatbotar",
    id: "chatbotar-tyska",
    belongsTo: "Chatbotar",
    popular: true,
  },

  {
    icon: "https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/misc%2Fl%C3%A4rare%2Ffranska.png?alt=media&token=5ab54912-a63a-405a-bafa-18f4acae8ea7",
    title: "Fransklärare",
    subtitle: "Plugga på din franska tillsammans med Marie Dubois.",
    path: "/app/chat/3dRmkg2iFEyDa5vPKTUy",
    roles: ["student"],
    category: "Chatbotar",
    id: "chatbotar-franska",
    belongsTo: "Chatbotar",
    popular: true,
  },

  {
    icon: "https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/teacherImages%2Fimg-gaj9xbqFt3IwTDtrjBhqd.jpg?alt=media&token=8c503b57-dccb-4896-915a-44e50fb71146",
    title: "Fysiklärare",
    subtitle: "Passionerad fysiklärare som vill göra vetensk...",
    path: "/app/chat/A5EEIaLgdLYKRAgEzZg6",
    roles: ["student"],
    category: "Chatbotar",
    id: "chatbotar-fysiklarare",
    belongsTo: "Chatbotar",
  },

  // Nya:

  {
    icon: "https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/teacherImages%2Fimg-P3HIslmb1xtSYDiXeU2bD.jpg?alt=media&token=b4ea68c7-a1de-42f2-a1ac-e06f736f6943",
    title: "Biologilärare",
    subtitle:
      "En engagerad biologilärare som älskar att förklara biologiska...",
    path: "/app/chat/RuyzusXZzXDEPvfxMmY8",
    roles: ["student"],
    category: "Chatbotar",
    id: "chatbotar-biologi",
    belongsTo: "Chatbotar",
  },

  {
    icon: "https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/misc%2Fnew%20batch%2FVirginia%20Woolf.png?alt=media&token=4002e5d0-d43f-40fe-b3d8-4b494920e901",
    title: "Virginia Woolf",
    subtitle:
      "Brittisk författare och essäist, central figur inom modernismen.",
    path: "/app/chat/jQOVP4WABFtWpIkHZWYD",
    roles: ["student"],
    category: "Chatbotar",
    id: "chatbotar-woolf",
    belongsTo: "Chatbotar",
  },
  {
    icon: "https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/misc%2Fnew%20batch%2FMary%20Wollstonecraft.png?alt=media&token=398b8b28-fd8f-4e86-b6c2-63f590fd31e9",
    title: "Mary Wollstonecraft",
    subtitle:
      "Brittisk författare, filosof och tidig förespråkare för kvinnors rättigheter.",
    path: "/app/chat/kuTk3RuecSRMXvximDsx",
    roles: ["student"],
    category: "Chatbotar",
    id: "chatbotar-wollstonecraft",
    belongsTo: "Chatbotar",
  },
  {
    icon: "https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/misc%2Fnew%20batch%2Fjesus.png?alt=media&token=9bf8d543-a13c-47bd-a20a-9a83aa8f801b",
    title: "Jesus",
    subtitle: "Central gestalt inom kristendomen, betraktad som frälsare av...",
    path: "/app/chat/dL1NVd9HMYxQvTL2j9VO",
    roles: ["student"],
    category: "Chatbotar",
    id: "chatbotar-jesus",
    belongsTo: "Chatbotar",
  },

  {
    icon: "https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/misc%2Fnew%20batch%2FSigmund%20Freud.png?alt=media&token=0c978d80-bd28-4634-842e-e53b13320b6d",
    title: "Sigmund Freud",
    subtitle: "Österrikisk neurolog och grundare av psykoanalysens fader...",
    path: "/app/chat/nHNQmF42laxU8ccC6DDg",
    roles: ["student"],
    category: "Chatbotar",
    id: "chatbotar-freud",
    belongsTo: "Chatbotar",
  },

  {
    icon: "https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/misc%2Feinstein.png?alt=media&token=a237fa40-6232-4c6b-bcd3-3574ed369219",
    title: "Albert Einstein",
    subtitle:
      "Tysk-schweizisk teoretisk fysiker, känd för relativitetsteorin...",
    path: "/app/chat/oAc5im2gfmlJXrF6XvPv",
    roles: ["student"],
    category: "Chatbotar",
    id: "chatbotar-einstein",
    belongsTo: "Chatbotar",
  },

  {
    icon: "https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/misc%2FNietzsche.png?alt=media&token=885b7a79-a20a-44d6-b8ff-5d94a8154ec4",
    title: "Friedrich Nietzsche",
    subtitle:
      "Tysk filosof, kulturkritiker och filolog, känd för sin skarpa kritik mot...",
    path: "/app/chat/pTJ2H7NtCijwQH40VGIV",
    roles: ["student"],
    category: "Chatbotar",
    id: "chatbotar-nietzsche",
    belongsTo: "Chatbotar",
  },
  {
    icon: "https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/misc%2Fplaton.png?alt=media&token=3cb1a62d-d524-4f17-a30d-ce21caa53053",
    title: "Platon",
    subtitle: "Antik grekisk filosof, grundare av Akademin i Aten...",
    path: "/app/chat/pqSK7pqWXsyEsOkxpUy0",
    roles: ["student"],
    category: "Chatbotar",
    id: "chatbotar-platon",
    belongsTo: "Chatbotar",
  },

  {
    icon: "https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/misc%2Fnew%20batch%2Faristoteles.png?alt=media&token=2425b91d-ec0f-48ed-a43f-cf3eb164f23a",
    title: "Aristoteles",
    subtitle: "Antik grekisk filosof, elev till Platon och läromästare till...",
    path: "/app/chat/kvZhgusQRykTBUmNA8dG",
    roles: ["student"],
    category: "Chatbotar",
    id: "chatbotar-aristoteles",
    belongsTo: "Chatbotar",
  },

  {
    icon: "https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/teacherImages%2Fimg-Fu6l0eyphTZ05J5z6Glqn.jpg?alt=media&token=a7b57577-19db-48d7-9dc8-148b0be1781c",
    title: "Studievägledare",
    subtitle: "Chatta eller prata med en studievägledare.",
    path: "/app/chat/FWtOI0nejxC8gEWqkI7v",
    roles: ["student"],
    category: "Chatbotar",
    id: "chatbotar-studievagledare",
    belongsTo: "Chatbotar",
  },

  {
    icon: "https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/teacherImages%2FZ6BLSMsm38Un5mgW0O6Z3TToHq12%2Fdr.jpg?alt=media&token=f27a8c83-0e72-42b8-a032-152875566258",
    title: "Terapeut — samtalspartner",
    subtitle: "Chatta eller prata med vår populära terapeut.",
    path: "/app/chat/xXhoDvHHb6QxG1H1lmul",
    roles: ["student"],
    category: "Chatbotar",
    id: "chatbotar-terapeut",
    belongsTo: "Chatbotar",
  },
  {
    icon: "https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/teacherImages%2Fimg-5pEWKA4Etn2t5V56FxRJF.jpg?alt=media&token=0bb45f0a-38eb-48da-8ac8-9eb21273c1f8",
    title: "Motiverade coach",
    subtitle: "Hjälper elever att sätta upp och nå sina mål.",
    path: "/app/chat/fSHkPlbq00VHgSK8carT",
    roles: ["student"],
    category: "Chatbotar",
    id: "chatbotar-coach",
    belongsTo: "Chatbotar",
  },

  {
    icon: "https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/teacherImages%2Fimg-EzrGzwpDbj6UV2yjXhCtR.jpg?alt=media&token=1cebd225-7332-43c1-95de-159cfaed0791",
    title: "Hälso- och sömnrådgivare",
    subtitle: "Optimera studierna genom god hälsa.",
    path: "/app/chat/IieasArTBXvs2HKID7pX",
    roles: ["student"],
    category: "Chatbotar",
    id: "chatbotar-halso-somnradgivare",
    belongsTo: "Chatbotar",
  },

  {
    icon: "https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/teacherImages%2FZ6BLSMsm38Un5mgW0O6Z3TToHq12%2Fastr.jpg?alt=media&token=7998469d-e220-422b-905f-7c430f68a099",
    title: "KBT-terapeut",
    subtitle: "Fokuserar på att förändra negativa tankemönster.",
    path: "/app/chat/qtlfShBM0ufub6oT09CT",
    roles: ["student"],
    category: "Chatbotar",

    id: "chatbotar-kbt",
    belongsTo: "Chatbotar",
  },
  {
    icon: <ExploreIcon />,
    title: "Alla våra AI-karaktärer",
    subtitle: "Chatta med Platon, Virginia Woolf eller Leif GW Persson",
    path: "/app/personal",
    roles: ["student"],
    category: "Chatbotar",
    id: "chatbotar-upptack",
    belongsTo: "Chatbotar",
    popular: true,
  },

  {
    icon: <Person3Icon />,
    title: "Skapa din egen AI-karaktär",
    subtitle: "Bestäm exakt hur du vill att karaktären ska bete sig.",
    path: "/app/larare",
    roles: ["student"],
    category: "Chatbotar",
    id: "chatbotar-skapa-din-egen-karaktar",
    belongsTo: "Chatbotar",
    popular: true,
  },

  //

  // {
  //   icon: <Spellcheck />,
  //   title: 'Högskoleprovet - ORD',
  //   subtitle: 'Träna dig på ORD-frågor från de senaste årens tester.',
  //   path: '/app/hp/F8CaAdYcKBH0K7X5bhZX',
  //   roles: ['student'],
  //   category: 'Högskoleprovet',
  // },

  // {
  //   icon: <AutoStories />,
  //   title: 'Högskoleprovet - LÄS',
  //   subtitle: 'Träna dig på LÄS-frågor från de senaste årens tester.',
  //   path: '/app/hp/F8CaAdYcKBH0K7X5bhZX',
  //   roles: ['student'],
  //   category: 'Högskoleprovet',
  // },
];

const DashboardToolsShortcuts = ({
  role,
  resetCategory,
  theme,
  user,
  onlyFetchFavorites,
  styles,
}) => {
  const [selectedCategory, setSelectedCategory] = useState("Populära");
  const [favoriteShortcuts, setFavoriteShortcuts] = useState([]);
  const [roomData, setRoomData] = useState(null);
  const [userRooms, setUserRooms] = useState([]);

  // Fetch both user data and room data
  useEffect(() => {
    if (!user?.uid) return;

    const userRef = doc(db, "users", user.uid);
    const unsubscribeUser = onSnapshot(
      userRef,
      async (docSnapshot) => {
        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          setFavoriteShortcuts(data.favoriteShortcuts || []);
          setUserRooms(data.rooms || []);

          // If user has rooms, fetch the first room's data
          if (data.rooms?.[0]) {
            const roomRef = doc(db, "rooms", data.rooms[0]);
            const roomSnap = await getDoc(roomRef);
            if (roomSnap.exists()) {
              setRoomData(roomSnap.data());
            }
          }
        }
      },
      (error) => {
        console.error("Error listening to user data:", error);
      },
    );

    return () => {
      unsubscribeUser();
    };
  }, [user]);

  useEffect(() => {
    setSelectedCategory("Populära");
  }, [role, resetCategory]);

  const filteredShortcuts = shortcuts
    .filter((shortcut) => {
      // First check if the user has access to this shortcut based on rooms
      const hasAccess =
        !userRooms?.length ||
        (role === "student"
          ? roomData?.shortcuts?.includes(shortcut.id)
          : true);

      // Then apply the existing filtering logic
      const meetsBasicCriteria = onlyFetchFavorites
        ? favoriteShortcuts.some((fav) => fav.id === shortcut.id)
        : (shortcut.roles.includes(role) || shortcut.roles.includes("both")) &&
          (selectedCategory === "Populära"
            ? shortcut.popular === true
            : shortcut.category === selectedCategory);

      const shouldInclude = hasAccess && meetsBasicCriteria;

      return shouldInclude;
    })
    .flatMap((shortcut) => {
      if (onlyFetchFavorites && shortcut.belongsTo) {
        const skipSubjectCategories = [
          "Hjälpmedel",
          "Läsförståelse",
          "Planering",
          "Bedömning",
          "Textanalys",
          "Textlabbet",
        ];

        const matchingFavorites = favoriteShortcuts.filter(
          (fav) => fav.id === shortcut.id,
        );

        return matchingFavorites.map((favorite) => ({
          ...shortcut,
          path: skipSubjectCategories.includes(shortcut.belongsTo)
            ? shortcut.path
            : `${shortcut.path}${
                shortcut.path.includes("?") ? "&" : "?"
              }subject=${encodeURIComponent(favorite.subject.toLowerCase())}`,
          favoriteSubject: favorite.subject,
        }));
      }
      return shortcut;
    });

  const limitedShortcuts =
    selectedCategory === "Populära"
      ? filteredShortcuts.slice(0, 30)
      : filteredShortcuts;

  const categories = [
    "Populära",
    ...(role === "student" ? ["Chatbotar"] : []),
    ...new Set(
      shortcuts
        .filter(
          (shortcut) =>
            shortcut.roles.includes(role) || shortcut.roles.includes("both"),
        )
        .map((shortcut) => shortcut.category)
        .filter(
          (category) =>
            category !== "Populära" &&
            (role !== "student" || category !== "Chatbotar"),
        ),
    ),
  ].filter(Boolean);

  return (
    <Box sx={{ mt: 0 }}>
      <Box
        sx={{
          display: onlyFetchFavorites ? "none" : "flex",
          flexWrap: "wrap",
          gap: 1,
          mb: 6,
        }}
      >
        {categories.map((category, index) => (
          <Chip
            key={index}
            icon={
              category === "Populära" ? (
                <GenerateIcon sx={{ fontSize: "19px" }} />
              ) : null
            }
            label={category}
            sx={{
              fontWeight: "500",
              padding: "7px",
              paddingTop: "12px",
              paddingBottom: "12px",
              fontSize: "0.8rem",
            }}
            onClick={() => setSelectedCategory(category)}
            color={selectedCategory === category ? "primary" : "default"}
          />
        ))}
      </Box>
      <Box
        sx={{
          mt: 8,
          mb: 2,
          display:
            onlyFetchFavorites && favoriteShortcuts.length > 0
              ? "block"
              : "none",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontWeight: "600",
            fontSize: "23px",
            textAlign: "center",
          }}
        >
          Mina favoriter
        </Typography>
      </Box>

      {onlyFetchFavorites && favoriteShortcuts.length > 0 ? (
        <Box sx={styles.freshBox}>
          <Grid container spacing={2}>
            {favoriteShortcuts.length === 0 ? (
              <Grid item xs={12} sm={6} md={4}>
                <ShortcutBox
                  icon={<GenerateIcon />}
                  title="Dina favoriter hamnar här!"
                  subtitle="Klicka på stjänan för valfritt verktyg/lektion för att favoritmarkera."
                  isEmpty={true}
                />
              </Grid>
            ) : (
              limitedShortcuts.map((shortcut, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <ShortcutBox
                    user={user}
                    {...shortcut}
                    currentSubject={shortcut.favoriteSubject}
                    subjectOrCategory={shortcut.favoriteSubject}
                    isFavoriteView={true}
                    isFavorite={favoriteShortcuts.some(
                      (fav) =>
                        fav.id === shortcut.id &&
                        fav.subject === shortcut.favoriteSubject,
                    )}
                    onFavoriteChange={(id, newIsFavorite) => {
                      // The state will be automatically updated by the onSnapshot listener
                    }}
                  />
                </Grid>
              ))
            )}
          </Grid>
        </Box>
      ) : (
        <Grid container spacing={2}>
          {limitedShortcuts.map((shortcut, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <ShortcutBox
                user={user}
                {...shortcut}
                currentSubject={selectedCategory}
                isFavorite={favoriteShortcuts.some(
                  (fav) =>
                    fav.id === shortcut.id && fav.subject === selectedCategory,
                )}
                onFavoriteChange={(id, newIsFavorite) => {
                  // The state will be automatically updated by the onSnapshot listener
                }}
              />
            </Grid>
          ))}
          {selectedCategory === "Chatbotar" && (
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                variant="purp"
                component={Link}
                to="/app/personal"
                sx={{
                  width: { xs: "100%", md: "21%" },
                  mt: 2,
                }}
              >
                Se alla chatbotar
              </Button>
            </Grid>
          )}
        </Grid>
      )}
    </Box>
  );
};

export default DashboardToolsShortcuts;
