import React, { useRef, useState, useEffect } from "react";
import {
    Link,
    useNavigate,
    useParams,
    useSearchParams,
} from "react-router-dom";
import { useAuth } from "./useAuth";
import { useSnackbar } from "./SnackbarHandler";
import { useGoogleLogin } from "@react-oauth/google";
import { GoogleLogin } from "@react-oauth/google";
import { useTheme } from "@mui/material/styles";
import HttpsIcon from "@mui/icons-material/Https";
import Logger from "./Logger";
import { Snackbar } from "@mui/material";
import GoogleGIcon from "./GoogleGIcon";
import {
    MarketingSide,
    TokenLimitMessage,
    translateFirebaseError,
} from "./AuthMarketingSide";
import {
    Box,
    Button,
    TextField,
    Typography,
    Paper,
    Alert,
    CircularProgress,
    useMediaQuery,
    Backdrop,
    Fade,
    IconButton,
} from "@mui/material";
import {
    Google as GoogleIcon,
    East as ArrowIcon,
    ArrowBack as BackIcon,
    AlternateEmail,
    Key,
} from "@mui/icons-material";
import TocDialog from "./TocDialog";
import {
    doc,
    updateDoc,
    increment,
    arrayUnion,
    getDoc,
} from "firebase/firestore";
import { db } from "./firebase";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    FormControlLabel,
    Checkbox,
} from "@mui/material";

const CreateAccountView = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const emailRef = useRef("");
    const passwordRef = useRef("");
    const confirmPasswordRef = useRef("");
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const { user, handleGoogleLogin, createUserWithEmailAndPassword } =
        useAuth();
    const { showSnackbar } = useSnackbar();
    const [error, setError] = useState("");
    const [hasExceededTokenLimit, setHasExceededTokenLimit] = useState(false);
    const [searchParams] = useSearchParams();

    const params = new URLSearchParams(window.location.search);
    const roomId = params.get("roomId");

    const [tocAccepted, setTocAccepted] = useState(false);
    const [tocDialogOpen, setTocDialogOpen] = useState(false);

    const [roomName, setRoomName] = useState("");

    useEffect(() => {
        const fetchRoomName = async () => {
            if (roomId) {
                const roomDoc = await getDoc(doc(db, "rooms", roomId));
                if (roomDoc.exists()) {
                    setRoomName(roomDoc.data().name);
                }
            }
        };
        fetchRoomName();
    }, [roomId]);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const tokensStatus = params.get("tokens");

        if (tokensStatus === "exceeded") {
            Logger.log(
                "Setting hasExceededTokenLimit to true from URL parameter",
            );
            setHasExceededTokenLimit(true);
        }
    }, []); // Empty dependency array since we only need to check URL params once on mount

    const googleLogin = useGoogleLogin({
        onSuccess: async (response) => {
            setIsLoading(true);
            const userData = await handleGoogleLogin(response);
            // Add this simple check and update
            if (roomId && userData) {
                await updateDoc(doc(db, "users", userData.uid), {
                    rooms: arrayUnion(roomId),
                });
                await updateDoc(doc(db, "rooms", roomId), {
                    studentCount: increment(1),
                });
            }
            window.location.href = "/app/";
        },
        onError: () => {
            Logger.log("Login Failed");
        },
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError("");

        if (!tocAccepted) {
            showSnackbar("Du måste acceptera användarvillkoren", "error");
            return;
        }

        setIsLoading(true);
        try {
            if (
                passwordRef.current.value !== confirmPasswordRef.current.value
            ) {
                showSnackbar("Lösenorden matchar inte", "info");
                return;
            }
            const userData = await createUserWithEmailAndPassword(
                emailRef.current.value,
                passwordRef.current.value,
            );
            // Add this simple check and update
            if (roomId && userData) {
                await updateDoc(doc(db, "users", userData.uid), {
                    rooms: arrayUnion(roomId),
                });
                await updateDoc(doc(db, "rooms", roomId), {
                    studentCount: increment(1),
                });
            }
            window.location.href = "/app/";
        } catch (error) {
            const swedishErrorMessage = translateFirebaseError(error);
            showSnackbar(swedishErrorMessage, "info");
        } finally {
            setIsLoading(false);
        }
    };

    // const googleLogin = useGoogleLogin({
    //     onSuccess: async (response) => {
    //         setIsLoading(true);
    //         await handleGoogleLogin(response);
    //         window.location.href = "/app/";
    //         //navigate('/app/');
    //     },
    //     onError: () => {
    //         Logger.log("Login Failed");
    //     },
    // });

    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     setError("");
    //     setIsLoading(true);
    //     try {
    //         if (
    //             passwordRef.current.value !== confirmPasswordRef.current.value
    //         ) {
    //             showSnackbar("Lösenorden matchar inte", "info");
    //             return;
    //         }
    //         await createUserWithEmailAndPassword(
    //             emailRef.current.value,
    //             passwordRef.current.value,
    //         );
    //         window.location.href = "/app/";
    //         //navigate('/app/');
    //     } catch (error) {
    //         const swedishErrorMessage = translateFirebaseError(error);
    //         showSnackbar(swedishErrorMessage, "info");
    //     } finally {
    //         setIsLoading(false);
    //     }
    // };

    return (
        <Box
            sx={{
                minHeight: "100vh",
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                position: "relative",
            }}
        >
            <IconButton
                onClick={() => {
                    const params = new URLSearchParams(window.location.search);
                    const ref = params.get("ref");
                    navigate(ref === "startpage" ? "/" : "/app");
                }}
                sx={{
                    position: "absolute",
                    top: 16,
                    left: 16,
                    zIndex: 1,
                    backgroundColor: (theme) => theme.palette.background.paper,
                    boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
                    "&:hover": {
                        backgroundColor: (theme) => theme.palette.action.hover,
                    },
                }}
                size="large"
            >
                <BackIcon />
            </IconButton>

            <Box
                sx={{
                    flex: "1 1 50%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    p: 3,
                }}
            >
                {/* {hasExceededTokenLimit && <TokenLimitMessage user={user} />}*/}
                {hasExceededTokenLimit && (
                    <Snackbar
                        open={true}
                        autoHideDuration={null}
                        anchorOrigin={{ vertical: "top", horizontal: "center" }}
                        onClose={() => setHasExceededTokenLimit(false)}
                        sx={{
                            width: "100%",
                            top: "10px !important",
                            "& .MuiSnackbarContent-root": {
                                width: "100%",
                                maxWidth: "600px",
                            },
                        }}
                    >
                        <Alert
                            severity="error"
                            variant="filled"
                            sx={{
                                width: { xs: "100%", md: "69%" },
                                borderRadius: "20px",
                                "& .MuiAlert-message": {
                                    width: "100%",
                                },
                                "@keyframes subtleBounce": {
                                    "0%, 100%": {
                                        transform: "translateY(0)",
                                    },
                                    "20%": {
                                        transform: "translateY(-5px)",
                                    },
                                    "40%": {
                                        transform: "translateY(0)",
                                    },
                                    "60%": {
                                        transform: "translateY(-5px)",
                                    },
                                    "80%": {
                                        transform: "translateY(0)",
                                    },
                                    "90%": {
                                        transform: "translateY(-5px)",
                                    },
                                },
                                animation: "subtleBounce 1s ease-in-out",
                            }}
                            onClose={() => setHasExceededTokenLimit(false)}
                        >
                            Du har använt alla dina tokens. Kontakta
                            info@studera.ai om du vill fortsätta använda
                            tjänsten helt gratis! :)
                        </Alert>
                    </Snackbar>
                )}
                <Fade in={true} timeout={800}>
                    <Paper
                        elevation={24}
                        sx={{
                            width: "100%",
                            maxWidth: "600px",
                            borderRadius: "24px",
                            overflow: "hidden",
                            backdropFilter: "blur(20px)",
                            backgroundColor: "transparent",
                            backgroundImage: "none",
                            boxShadow: "none",
                        }}
                    >
                        {roomId && roomName && (
                            <Alert
                                severity="info"
                                variant="filled"
                                sx={{
                                    mb: 1,
                                    width: "90%",
                                    margin: "0 auto",
                                    paddingBottom: "2px",
                                    paddingTop: "2px",
                                    "& .MuiAlert-message": {
                                        fontSize: "0.9rem",
                                        lineHeight: "1rem",
                                        paddingTop: "10px",
                                    },
                                    borderRadius: 4,
                                }}
                            >
                                Du håller just nu på att gå med i rummet{" "}
                                {roomName}
                            </Alert>
                        )}
                        <Box sx={{ p: { xs: 0, md: 4 }, pb: 2 }}>
                            <Typography
                                variant="h4"
                                gutterBottom
                                sx={{
                                    fontWeight: 800,
                                    textAlign: "center",
                                    backgroundClip: "text",
                                    textFillColor: "transparent",
                                    background:
                                        "linear-gradient(135deg, #43CBFF 10%, #9708CC 100%)",
                                    WebkitBackgroundClip: "text",
                                    WebkitTextFillColor: "transparent",
                                    mb: 1.5,
                                    fontSize: "2.7rem",
                                }}
                            >
                                Skapa ett konto
                            </Typography>
                            <Button
                                component={Link}
                                to="/app/logga-in"
                                sx={{
                                    mt: 0,
                                    mb: 4,
                                    padding: 0,
                                    fontWeight: "500",
                                    fontSize: { xs: "0.88rem", md: "1.1rem" },
                                    textTransform: "none",
                                    color:
                                        theme.palette.mode === "dark"
                                            ? "white"
                                            : "#666666",
                                    textAlign: "center",
                                    width: "100%",
                                    textDecoration: "none",
                                    "&:hover": {
                                        color: "primary.dark",
                                        backgroundColor: "transparent",
                                    },
                                }}
                            >
                                Har du redan ett konto?&nbsp;
                                <span style={{ fontWeight: 600 }}>
                                    Logga in här.
                                </span>
                            </Button>

                            <Box sx={{ mb: 3, mt: 3 }}>
                                <Typography
                                    variant="body2"
                                    align="center"
                                    sx={{
                                        fontSize: "20px",
                                        fontWeight: "600",
                                        position: "relative",
                                        color:
                                            theme.palette.mode === "dark"
                                                ? "#abadb0"
                                                : "#444",
                                        "&::before, &::after": {
                                            content: '""',
                                            position: "absolute",
                                            top: "50%",
                                            width: { xs: "0%", md: "23%" },
                                            height: "1px",
                                            backgroundColor: (theme) =>
                                                theme.palette.mode === "dark"
                                                    ? "grey.800"
                                                    : "grey.300",
                                        },
                                        "&::before": { left: 0 },
                                        "&::after": { right: 0 },
                                    }}
                                >
                                    Skapa konto med Google
                                </Typography>
                            </Box>

                            <Box sx={{ mb: 5, mt: 2 }}>
                                {/*
                                <GoogleLogin
                                    style={{ width: '100%' }}
                                    onSuccess={async response => {
                                        await handleGoogleLogin(response);
                                        navigate('/app/');
                                    }}
                                    onError={() => {
                                        Logger.log('Registration Failed');
                                    }}
                                />
                                
                                // Classic White Google Button
                                <Button
                                    fullWidth
                                    startIcon={<GoogleIcon />}
                                    onClick={() => googleLogin()}
                                    variant='outlined'
                                    sx={{
                                        py: 1.5,
                                        borderRadius: '12px',
                                        backgroundColor: '#ffffff',
                                        color: '#3c4043',
                                        border: '1px solid #dadce0',
                                        textTransform: 'none',
                                        fontWeight: 500,
                                        '&:hover': {
                                            backgroundColor: '#f8f9fa',
                                            borderColor: '#dadce0',
                                        },
                                    }}
                                >
                                    Continue with Google
                                </Button>
                                // Modern Dark Button
                                <Button
                                    fullWidth
                                    startIcon={<GoogleIcon />}
                                    onClick={() => googleLogin()}
                                    variant='contained'
                                    sx={{
                                        py: 1.5,
                                        borderRadius: '12px',
                                        backgroundColor: '#202124',
                                        color: '#ffffff',
                                        textTransform: 'none',
                                        fontWeight: 500,
                                        '&:hover': {
                                            backgroundColor: '#303134',
                                        },
                                        boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                                    }}
                                >
                                    Sign in with Google
                                </Button>
                                */}

                                <Button
                                    fullWidth
                                    startIcon={<GoogleGIcon />}
                                    onClick={() => googleLogin()}
                                    variant="contained"
                                    sx={{
                                        py: 1.5,
                                        borderRadius: "12px",
                                        background:
                                            "linear-gradient(to right, #ffffff, #f8f9fa)",
                                        color: "#3c4043",
                                        textTransform: "uppercase",
                                        fontWeight: 500,
                                        border: "1px solid #dadce0",
                                        mt: 1,
                                        mb: 2,
                                        boxShadow: "none",
                                        "&:hover": {
                                            background:
                                                "linear-gradient(to right, #f8f9fa, #f1f3f4)",
                                            boxShadow:
                                                "0 1px 3px rgba(0,0,0,0.08)",
                                        },
                                    }}
                                >
                                    Skapa konto med Google
                                </Button>
                            </Box>

                            <Box sx={{ mb: 3, mt: 5 }}>
                                <Typography
                                    variant="body2"
                                    align="center"
                                    sx={{
                                        fontSize: "20px",
                                        fontWeight: "600",
                                        position: "relative",
                                        color:
                                            theme.palette.mode === "dark"
                                                ? "#abadb0"
                                                : "#444",
                                        "&::before, &::after": {
                                            content: '""',
                                            position: "absolute",
                                            top: "50%",

                                            width: { xs: "0%", md: "23%" },
                                            height: "1px",
                                            backgroundColor: (theme) =>
                                                theme.palette.mode === "dark"
                                                    ? "grey.800"
                                                    : "grey.300",
                                        },
                                        "&::before": { left: 0 },
                                        "&::after": { right: 0 },
                                    }}
                                >
                                    Skapa konto med e-post
                                </Typography>
                            </Box>

                            <Box
                                component="form"
                                onSubmit={handleSubmit}
                                noValidate
                            >
                                <TextField
                                    margin="normal"
                                    required
                                    placeholder="E-post"
                                    fullWidth
                                    id="email"
                                    name="email"
                                    autoComplete="email"
                                    inputRef={emailRef}
                                    InputProps={{
                                        startAdornment: (
                                            <AlternateEmail sx={{ mr: 1 }} />
                                        ),
                                    }}
                                    sx={{
                                        mb: 1,
                                        backgroundColor:
                                            theme.palette.mode === "dark"
                                                ? undefined
                                                : "white",
                                        "& .MuiOutlinedInput-root": {
                                            borderRadius: "12px",
                                        },
                                    }}
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    placeholder="Lösenord"
                                    type="password"
                                    id="password"
                                    autoComplete="new-password"
                                    inputRef={passwordRef}
                                    InputProps={{
                                        startAdornment: (
                                            <HttpsIcon sx={{ mr: 1 }} />
                                        ),
                                    }}
                                    sx={{
                                        mb: 1,
                                        backgroundColor:
                                            theme.palette.mode === "dark"
                                                ? undefined
                                                : "white",
                                        "& .MuiOutlinedInput-root": {
                                            borderRadius: "12px",
                                        },
                                    }}
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="confirmPassword"
                                    placeholder="Bekräfta lösenord"
                                    type="password"
                                    id="confirmPassword"
                                    autoComplete="new-password"
                                    inputRef={confirmPasswordRef}
                                    InputProps={{
                                        startAdornment: (
                                            <HttpsIcon sx={{ mr: 1 }} />
                                        ),
                                    }}
                                    sx={{
                                        mb: 2,
                                        backgroundColor:
                                            theme.palette.mode === "dark"
                                                ? undefined
                                                : "white",
                                        "& .MuiOutlinedInput-root": {
                                            borderRadius: "12px",
                                        },
                                    }}
                                />

                                {error && (
                                    <Alert
                                        severity="error"
                                        sx={{ mb: 2, borderRadius: "12px" }}
                                    >
                                        {error}
                                    </Alert>
                                )}
                                <Box sx={{ mb: 0, mt: 0 }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={tocAccepted}
                                                onChange={(e) =>
                                                    setTocAccepted(
                                                        e.target.checked,
                                                    )
                                                }
                                                required
                                            />
                                        }
                                        label={
                                            <Typography
                                                variant="body2"
                                                component="span"
                                            >
                                                Jag accepterar{" "}
                                                <Button
                                                    onClick={() =>
                                                        setTocDialogOpen(true)
                                                    }
                                                    sx={{
                                                        p: 0,
                                                        textTransform: "none",
                                                        verticalAlign:
                                                            "baseline",
                                                        fontWeight: 600,
                                                        minWidth: 0,
                                                        "&:hover": {
                                                            backgroundColor:
                                                                "transparent",
                                                            textDecoration:
                                                                "underline",
                                                        },
                                                    }}
                                                >
                                                    användarvillkoren
                                                </Button>
                                            </Typography>
                                        }
                                    />
                                </Box>
                                <Button
                                    type="submit"
                                    fullWidth
                                    disabled={isLoading}
                                    sx={{
                                        mt: 2,
                                        mb: 0,
                                        py: 1.5,
                                        borderRadius: "12px",
                                        background:
                                            "linear-gradient(135deg, #6366f1 0%, #8b5cf6 100%)",
                                        color: "white",
                                        fontWeight: 600,
                                        fontSize: "1rem",
                                        textTransform: "none",
                                        "&:hover": {
                                            background:
                                                "linear-gradient(135deg, #4f46e5 0%, #7c3aed 100%)",
                                        },
                                        "&:disabled": {
                                            background: "grey.500",
                                        },
                                    }}
                                >
                                    {isLoading ? (
                                        <CircularProgress
                                            size={24}
                                            color="inherit"
                                        />
                                    ) : (
                                        <>
                                            Skapa konto
                                            <ArrowIcon sx={{ ml: 1 }} />
                                        </>
                                    )}
                                </Button>
                            </Box>
                        </Box>
                    </Paper>
                </Fade>
            </Box>

            <MarketingSide />

            <Backdrop
                transitionDuration={1000}
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 4,
                    bgcolor: "rgba(0, 0, 0, 0.85)",
                    backdropFilter: "blur(8px)",
                }}
                open={isLoading}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: 3,
                    }}
                >
                    <Box
                        sx={{
                            position: "relative",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            mb: 1,
                        }}
                    >
                        <Box
                            component="img"
                            src="/img/AI.png"
                            alt="AI Logo"
                            sx={{
                                width: 120,
                                height: 120,
                                animation: "pulse 1s infinite ease-in-out",
                                filter: "drop-shadow(0 0 20px rgba(255, 255, 255, 0.2))",
                            }}
                        />
                    </Box>
                    <Typography
                        variant="h6"
                        sx={{
                            fontWeight: 300,
                            letterSpacing: 1,
                            opacity: 0.9,
                            textAlign: "center",
                            fontSize: "1.55rem",
                            marginBottom: "20px",
                        }}
                    >
                        Skapar konto...
                    </Typography>
                    <CircularProgress sx={{ color: "#ffffff" }} size="30px" />
                </Box>
            </Backdrop>
            <TocDialog
                open={tocDialogOpen}
                onClose={() => setTocDialogOpen(false)}
            />
        </Box>
    );
};

export default CreateAccountView;
