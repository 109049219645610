import React, { useState } from 'react';
import { Box, Button, Typography, Paper } from '@mui/material';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const lessonIdeas = [
  {
    text: 'Människans anatomi och fysiologi',
    value:
      'Människokroppens olika system, organ och funktioner. Inkludera frågor om skelett, muskler, nervsystem, matsmältning, cirkulation och andning.',
  },
  {
    text: 'Evolutionsteori och naturligt urval',
    value:
      'Evolutionsteorins grundprinciper, inklusive naturligt urval, genetisk drift, mutation och anpassning. Täck in exempel på evolution hos olika arter och förklara begrepp som konvergent evolution och adaption.',
  },
  {
    text: 'Mikrobiologi och bakteriernas roll',
    value:
      'Utforska mikroorganismer, särskilt bakterier, och deras roll i miljön och människokroppen. Inkludera information om bakteriestrukturer, bakteriell tillväxt, antibiotikaresistens och bakteriers betydelse i olika ekosystem.',
  },
  {
    text: 'Immunförsvarets mekanismer',
    value:
      'Undersök immunförsvarets olika komponenter och funktioner. Täck medfödd och förvärvad immunitet, antikroppar, T-celler, B-celler och immunologiskt minne.',
  },
  {
    text: 'Miljöföroreningars påverkan på biologiska system',
    value:
      'Utforska hur olika typer av föroreningar påverkar ekosystem och organismer. Täck bioackumulation, eutrofiering, försurning och effekter på biologisk mångfald.',
  },
  {
    text: 'Bioteknologi och dess tillämpningar',
    value:
      'Granska moderna bioteknologiska metoder och deras tillämpningar inom medicin, jordbruk och miljö. Ta upp genmodifiering, kloning, stamcellsforskning och biobränslen.',
  },
  {
    text: 'Cellbiologi och cellernas funktioner',
    value:
      'Undersök cellens struktur och funktion. Täck cellorganeller, cellmembran, cellcykel, celldelning och intracellulär transport.',
  },
  {
    text: 'Photosyntes och växternas energiproduktion',
    value:
      'Granska fotosyntesens process och betydelse. Ta upp ljus- och mörkreaktion, klorofyll, CO2-fixering och faktorer som påverkar fotosyntesens effektivitet.',
  },
  {
    text: 'Genetisk variation och ärftlighet',
    value:
      'Undersök genetikens grunder och ärftlighetsmönster. Täck DNA-struktur, geners funktion, Mendels lagar, dominanta och recessiva alleler samt genetiska sjukdomar.',
  },
  {
    text: 'Neurobiologi och nervsystemets funktioner',
    value:
      'Utforska nervsystemets uppbyggnad och funktion. Ta upp neuroner, synaps, neurotransmittorer, reflexer och hjärnans olika delar.',
  },
  {
    text: 'Etologi och djurens beteenden',
    value:
      'Undersök djurens beteende och dess evolutionära grunder. Ta upp instinkter, inlärning, kommunikation, sociala strukturer och anpassningsbeteenden.',
  },
  {
    text: 'Populationsdynamik och ekologiska interaktioner',
    value:
      'Granska hur populationer växer, minskar och interagerar med varandra. Täck födokedjor, konkurrens, symbios, predation och populationsmodeller.',
  },
  {
    text: 'Hormonsystemet och endokrina körtlar',
    value:
      'Undersök hormoner och det endokrina systemet. Täck olika hormontyper, endokrina körtlar, hormonell reglering och feedback-mekanismer.',
  },
  {
    text: 'Biokemi och metabolism',
    value:
      'Granska de biokemiska processerna i levande organismer. Täck enzymkinetik, metaboliska vägar, ATP-produktion och cellulär respiration.',
  },
  {
    text: 'Mikrobiom och dess betydelse för hälsa',
    value:
      'Undersök mikroorganismerna som lever i och på människokroppen. Täck tarmflora, probiotika, mikrobiomet och immunförsvar samt dess roll i hälsa och sjukdom.',
  },
  {
    text: 'Reproduktion och utveckling hos olika organismer',
    value:
      'Granska reproduktiva strategier och utvecklingsprocesser hos olika organismer. Täck sexuell och asexuell förökning, embryoutveckling, metamorfos och livscykler.',
  },
  {
    text: 'Genomik och DNA-sekvensering',
    value:
      'Undersök genomik och moderna DNA-sekvenseringstekniker. Täck genomstruktur, sekvenseringmetoder, bioinformatik och genomikens tillämpningar.',
  },
  {
    text: 'Evolution av arter och makroevolution',
    value:
      'Granska evolutionära processer på artnivå och över längre tidsperioder. Täck artbildning, anpassning, evolutionära träddiagram och fossila bevis.',
  },
  {
    text: 'Molekylärbiologi och genreglering',
    value:
      'Undersök hur gener uttrycks och regleras på molekylär nivå. Täck transkription, translation, genreglering och epigenetiska mekanismer.',
  },
  {
    text: 'Växternas och djurens försvarsmekanismer',
    value:
      'Granska olika försvarsstrategier hos växter och djur. Täck kemiskt försvar, fysiska barriärer, immunförsvar och beteendemässiga anpassningar.',
  },
  {
    text: 'Biologisk mångfald och bevarande',
    value:
      'Undersök biodiversitet och dess betydelse. Täck artrikedom, ekosystemtjänster, utrotningshotade arter och bevarandestrategier.',
  },
  {
    text: 'Fysiologiska anpassningar hos extremofiler',
    value:
      'Granska organismer som lever i extrema miljöer. Täck termofiler, halofiler, psykrofiler och deras unika fysiologiska anpassningar.',
  },
  {
    text: 'Parasiter och deras livscykler',
    value:
      'Undersök parasiter och deras komplexa livscykler. Täck olika typer av parasiter, värd-parasit-interaktioner och parasitiska anpassningar.',
  },
  {
    text: 'Biologisk kontroll och integrerad skadedjursbekämpning',
    value:
      'Granska biologiska metoder för att kontrollera skadedjur och sjukdomar. Täck naturliga fiender, feromoner, resistenta grödor och ekologisk odling.',
  },
  {
    text: 'Marinbiologi och havets ekosystem',
    value:
      'Undersök livet i havet och marina ekosystem. Täck oceanografi, marina näringskedjor, korallrev, djuphavsliv och marin biodiversitet.',
  },
  {
    text: 'Fytoplankton och zooplankton',
    value:
      'Granska planktonorganismer och deras roll i akvatiska ekosystem. Täck olika planktontyper, deras betydelse för näringskedjor och påverkan på globala processer.',
  },
  {
    text: 'Symbios och mutualistiska förhållanden',
    value:
      'Undersök symbiotiska relationer mellan olika organismer. Täck mutualism, kommensalism, mykorrhiza och andra typer av symbios i naturen.',
  },
  {
    text: 'Antibiotika och resistensmekanismer',
    value:
      'Granska antibiotika och utvecklingen av antibiotikaresistens. Täck olika antibiotikatyper, verkningsmekanismer och strategier för att motverka resistens.',
  },
  {
    text: 'Genredigeringstekniker, såsom CRISPR',
    value:
      'Undersök moderna genredigeringstekniker och deras tillämpningar. Täck CRISPR-Cas9, genterapi, etiska aspekter och potentiella framtida användningsområden.',
  },
  {
    text: 'Växternas pollinering och fröspridning',
    value:
      'Granska olika strategier för pollinering och fröspridning hos växter. Täck insektspollinering, vindpollinering, frukter och olika spridningsmekanismer.',
  },
  {
    text: 'Bioinformatik och datavetenskap i biologi',
    value:
      'Undersök användningen av datavetenskap inom biologin. Täck sekvensanalys, strukturprediktion, databaser och beräkningsbiologi.',
  },
  {
    text: 'Kryobiologi och liv vid låga temperaturer',
    value:
      'Granska hur organismer överlever och anpassar sig till extremt låga temperaturer. Täck frysskydd, dvala, kryopreservering och arktiskt/antarktiskt liv.',
  },
  {
    text: 'Epigenetik och arv utan förändringar i DNA-sekvensen',
    value:
      'Undersök epigenetiska mekanismer och deras betydelse. Täck DNA-metylering, histonmodifieringar, genomisk prägling och transgenerationellt arv.',
  },
  {
    text: 'Biologiska reaktioner och enzymers funktioner',
    value:
      'Granska enzymer och deras roll i biologiska processer. Täck enzymkinetik, substrat-enzym-interaktioner, kofaktorer och enzymhämning.',
  },
  {
    text: 'Molekylär evolution och utveckling av biologiska system',
    value:
      'Undersök hur molekylära mekanismer har utvecklats över tid. Täck genduplikation, proteinevolution, regulatoriska nätverk och evolutionär utvecklingsbiologi.',
  },
  {
    text: 'Djurens migration och navigeringsmekanismer',
    value:
      'Granska djurens migrationsmönster och navigeringsförmåga. Täck långdistansflyttning, magnetisk orientering, stjärnnavigering och kemiska signaler.',
  },
  {
    text: 'Växternas fysiologi och tillväxtreglering',
    value:
      'Undersök växternas fysiologiska processer och tillväxt. Täck näringsupptag, vattentransport, växthormonreglering och tropismens betydelse i växter.',
  },
  {
    text: 'Evolution av immunförsvar hos olika organismer',
    value:
      'Granska hur immunförsvaret har utvecklats hos olika organismgrupper. Täck medfödd och adaptiv immunitet, evolutionära anpassningar och jämförelser mellan arter.',
  },
  {
    text: 'Klinisk biologi och laboratoriemedicin',
    value:
      'Undersök biologiska tekniker som används inom klinisk diagnostik. Täck blodprovsanalyser, mikrobiologiska tester, genetisk screening och bilddiagnostik.',
  },
  {
    text: 'Biologiska rytmer och cirkadianska cykler',
    value:
      'Granska biologiska rytmer och deras betydelse. Täck dygnsrytm, säsongsrytmer, molekylära klockor och rytmers påverkan på fysiologi och beteende.',
  },
  {
    text: 'Toxikologi och effekter av gifter på biologiska system',
    value:
      'Undersök olika typer av gifter och deras effekter på levande organismer. Täck toxiner, miljögifter, dos-responsförhållanden och avgiftningsmekanismer.',
  },
  {
    text: 'Utvecklingsbiologi och embryogenes',
    value:
      'Granska organismers utveckling från befruktning till vuxen individ. Täck gastrulation, organogenes, stamceller och utvecklingsreglering.',
  },
  {
    text: 'Biologi av stamceller och deras tillämpningar',
    value:
      'Undersök stamcellers egenskaper och potential inom medicin. Täck olika typer av stamceller, differentiering, regenerativ medicin och etiska aspekter.',
  },
  {
    text: 'Fysiologiska responser på stress och sjukdomar',
    value:
      'Granska hur organismer reagerar på olika typer av stress och sjukdomar. Täck stressresponser, inflammationer, febertillstånd och homeostas.',
  },
  {
    text: 'Biologiska effekter av strålning och radioaktivitet',
    value:
      'Undersök hur joniserande strålning påverkar levande organismer. Täck strålningstyper, DNA-skador, mutationer och strålningsresistens.',
  },
  {
    text: 'Växternas respons på miljöförändringar och stress',
    value:
      'Granska hur växter anpassar sig till olika miljöstressfaktorer. Täck torka, saltstress, extrema temperaturer och växternas försvarsmekanismer.',
  },
  {
    text: 'Djurens kommunikation och signalering',
    value:
      'Undersök olika former av kommunikation inom djurriket. Täck visuella signaler, ljud, feromoner, elektrisk kommunikation och taktil kommunikation.',
  },
  {
    text: 'Ekolgiska processer och energiflöden i naturen',
    value:
      'Granska energiflöden och näringsämneskretslopp i ekosystem. Täck fotosyntes, nedbrytning, näringskedjor och biogeokemiska cykler.',
  },
  {
    text: 'Biofysik och strukturell biologi',
    value:
      'Undersök fysikaliska principer i biologiska system och makromolekylers struktur. Täck proteinstruktur, membrantransport, biomekanik och röntgenkristallografi.',
  },
  {
    text: 'Läkemedelsutveckling och farmakologi',
    value:
      'Granska processen för att utveckla nya läkemedel och deras verkningsmekanismer. Täck målidentifiering, screening, kliniska prövningar och farmakokinetik.',
  },
  {
    text: 'Biologiska effekter av klimatförändringar',
    value:
      'Undersök hur klimatförändringar påverkar biologiska system och arter. Täck fenologiska förändringar, artutbredning, ekosystemtjänster och anpassningsstrategier.',
  },
  {
    text: 'Viktiga händelser under andra världskriget',
    value:
      'Utforska avgörande slag, politiska beslut och teknologiska framsteg under andra världskriget. Täck Pearl Harbor, D-dagen, förintelsen, atombomberna och krigets konsekvenser.',
  },
  {
    text: 'Antikens civilisationer',
    value:
      'Granska de stora antika civilisationerna som Egypten, Grekland, Rom och Mesopotamien. Fokusera på deras kulturer, styrelseskick, arkitektur och bidrag till världshistorien.',
  },
  {
    text: 'Medeltiden och feodalism',
    value:
      'Undersök det medeltida samhället i Europa, med fokus på feodalismen. Täck samhällsklasser, riddarväsendet, kyrkans roll och den medeltida ekonomin.',
  },
  {
    text: 'Industrialiseringen',
    value:
      'Granska industrialiseringens framväxt och effekter. Täck viktiga uppfinningar, sociala förändringar, urbanisering och hur den påverkade olika länder och samhällsklasser.',
  },
  {
    text: 'Kolonialismens effekter',
    value:
      'Undersök kolonialismens långsiktiga konsekvenser för både kolonisatörer och koloniserade. Täck ekonomisk exploatering, kulturell påverkan och avkolonialiseringen.',
  },
  {
    text: 'Renässansens kulturella uppsving',
    value:
      'Granska renässansens konstnärliga, vetenskapliga och filosofiska framsteg. Fokusera på viktiga personer, konstverk, idéer och hur de påverkade samhället.',
  },
  {
    text: 'Franska revolutionen',
    value:
      'Undersök orsakerna, händelseförloppet och konsekvenserna av franska revolutionen. Täck viktiga personer, politiska ideologier och revolutionens påverkan på Europa.',
  },
  {
    text: 'Kalla kriget och dess konsekvenser',
    value:
      'Granska kalla krigets olika aspekter, inklusive ideologiska skillnader, proxy-krig, kapprustningen och dess påverkan på global politik och kultur.',
  },
  {
    text: 'Världens stora imperier',
    value:
      'Undersök historiens mäktiga imperier som det romerska, det mongoliska, det ottomanska och det brittiska. Jämför deras styrelsesätt, expansion och arv.',
  },
  {
    text: 'De stora upptäcktsresorna',
    value:
      'Granska de europeiska upptäcktsresorna under 1400- och 1500-talen. Täck viktiga upptäcktsresande, nya handelsrutter och möten med olika kulturer.',
  },
  {
    text: 'Romarrikets uppgång och fall',
    value:
      'Undersök Romarrikets historia, från dess grundande till dess fall. Täck viktiga kejsare, erövringar, samhällsstruktur och orsakerna till rikets nedgång.',
  },
  {
    text: 'Den amerikanska revolutionen',
    value:
      'Granska den amerikanska revolutionen och bildandet av USA. Täck orsakerna till revolutionen, viktiga slag och dokument, samt dess påverkan på världen.',
  },
  {
    text: 'Stormaktstiden i Sverige',
    value:
      'Undersök Sveriges stormaktstid under 1600-talet. Fokusera på kungar som Gustav II Adolf, militära framgångar, och Sveriges roll i europeisk politik under denna period.',
  },
  {
    text: 'Den svenska medeltiden och Kalmarunionen',
    value:
      'Granska Sveriges medeltida historia och Kalmarunionen. Täck kristnandet, Birger Jarl, Magnus Ladulås och unionens bildande och upplösning.',
  },
  {
    text: 'Vikingatiden och nordisk expansion',
    value:
      'Undersök vikingatiden, dess samhälle, religion och expansion. Täck vikingafärder, bosättningar, handel och kulturell påverkan på andra delar av Europa.',
  },
  {
    text: 'Kriget mellan Nord- och Sydstaterna',
    value:
      'Granska det amerikanska inbördeskriget. Täck orsakerna till kriget, viktiga slag, ledare som Lincoln och Lee, samt krigets konsekvenser för USA.',
  },
  {
    text: 'Den franska revolutionens effekter på Europa',
    value:
      'Undersök hur den franska revolutionen påverkade resten av Europa. Fokusera på spridningen av revolutionära idéer, krig och politiska förändringar i andra länder.',
  },
  {
    text: 'Världshistoriens stora krig',
    value:
      'Granska historiens mest betydelsefulla krig och deras påverkan på världen. Täck orsaker, avgörande slag och långsiktiga konsekvenser.',
  },
  {
    text: 'Antika Greklands politiska system',
    value:
      'Undersök de politiska systemen i antikens Grekland, med fokus på Atens demokrati och Spartas oligarki. Täck medborgarskap, val och beslutsfattande.',
  },
  {
    text: 'Egyptens forntida samhälle och kultur',
    value:
      'Granska det forntida Egyptens civilisation. Täck faraoner, pyramider, religion, skrift och vardagsliv vid Nilen.',
  },
  {
    text: 'De stora asiatiska imperierna: Kina och Mongoliet',
    value:
      'Undersök Kinas dynastier och det mongoliska riket. Täck kulturella prestationer, expansioner och deras påverkan på världshistorien.',
  },
  {
    text: 'Den industriella revolutionens inverkan på samhället',
    value:
      'Granska hur industrialiseringen förändrade samhället. Fokusera på urbanisering, arbetarrörelsen, klasskillnader och nya sociala strukturer.',
  },
  {
    text: 'Reformationen och dess följder',
    value:
      'Undersök den protestantiska reformationen och dess effekter. Täck Martin Luther, olika protestantiska rörelser och religiösa konflikter.',
  },
  {
    text: 'Kolonialismens inverkan på ursprungsbefolkningar',
    value:
      'Granska hur kolonialismen påverkade ursprungsbefolkningar runt om i världen. Täck kulturell förstörelse, motstånd och långsiktiga konsekvenser.',
  },
  {
    text: 'Kvinnors roll genom historien',
    value:
      'Undersök kvinnors ställning och kamp för rättigheter genom historien. Täck viktiga kvinnliga ledare, rörelser och framsteg i olika kulturer och epoker.',
  },
  {
    text: 'Det bysantinska riket och dess arv',
    value:
      'Granska det bysantinska rikets historia, kultur och påverkan. Täck dess roll som bevarare av antikens kunskap och brygga mellan öst och väst.',
  },
  {
    text: 'Den engelska inbördeskriget',
    value:
      'Undersök det engelska inbördeskriget på 1600-talet. Täck orsaker, viktiga personer som Cromwell, och dess påverkan på det engelska styrelseskicket.',
  },
  {
    text: 'Imperialismens era och dess konsekvenser',
    value:
      'Granska den europeiska imperialismen under 1800- och tidigt 1900-tal. Täck motivationer, kolonier i olika världsdelar och långsiktiga effekter.',
  },
  {
    text: 'Slaveri och avskaffande av slaveri',
    value:
      'Undersök slaveriets historia och kampen för dess avskaffande. Täck den transatlantiska slavhandeln, abolitioniströrelsen och slaveriets arv.',
  },
  {
    text: 'Kulturella och vetenskapliga framsteg under upplysningstiden',
    value:
      'Granska upplysningstidens idéer och upptäckter. Täck filosofer, vetenskapsmän och hur deras tankar påverkade samhället och politiken.',
  },
  {
    text: 'Den stora depressionen och dess globala effekter',
    value:
      'Granska den stora depressionen på 1930-talet. Täck orsaker, ekonomiska och sociala konsekvenser, samt politiska reaktioner i olika länder.',
  },
  {
    text: 'Den industriella revolutionens uppfinningar',
    value:
      'Undersök de viktigaste uppfinningarna under den industriella revolutionen. Täck teknologier inom textil, transport, kommunikation och energi.',
  },
  {
    text: 'Gyllene eran i antikens Rom',
    value:
      'Granska Roms gyllene era under principatet. Täck kejsare som Augustus, kulturella prestationer, expansion och vardagslivet i det romerska riket.',
  },
  {
    text: 'Stora krig och konflikter i Asien',
    value:
      'Undersök betydelsefulla krig och konflikter i Asiens historia. Täck opiumkrigen, japansk expansion, Koreakriget och Vietnamkriget.',
  },
  {
    text: 'Latinamerikas självständighetsrörelser',
    value:
      'Granska självständighetskrigen i Latinamerika. Täck ledare som Bolívar, orsaker till upproren och bildandet av nya nationer.',
  },
  {
    text: 'Befrielsekrigen i Afrika',
    value:
      'Undersök avkolonialiseringen och befrielsekrigen i Afrika. Täck viktiga ledare, rörelser och utmaningar för de nybildade nationerna.',
  },
  {
    text: 'Medeltida klosterliv och utbildning',
    value:
      'Granska klosterväsendet och dess roll i medeltida utbildning och kultur. Täck olika ordnar, klosterregler och bevarandet av kunskap.',
  },
  {
    text: 'Historien om handeln och handelsvägar',
    value:
      'Undersök historiska handelsvägar och deras betydelse. Täck Sidenvägen, kryddhandeln, Hansan och triangelhandeln.',
  },
  {
    text: 'Världens första civilisationer',
    value:
      'Granska de tidigaste civilisationerna i Mesopotamien, Indusdalen och Kina. Täck skriftens uppkomst, stadsbildning och tidiga styrelseformer.',
  },
  {
    text: 'Stora kulturella och tekniska framsteg under antiken',
    value:
      'Undersök viktiga uppfinningar och kulturella landvinningar i antikens civilisationer. Täck arkitektur, filosofi, matematik och teknik.',
  },
  {
    text: 'De stora revolutionerna i Europa',
    value:
      'Granska de viktigaste revolutionerna i europeisk historia. Täck den franska, den industriella och de politiska revolutionerna 1848.',
  },
  {
    text: 'Utvecklingen av demokrati och rättigheter',
    value:
      'Undersök framväxten av demokratiska system och mänskliga rättigheter. Täck antikens Aten, upplysningstidens idéer och moderna demokratirörelser.',
  },
  {
    text: 'Den moderna vetenskapens födelse',
    value:
      'Granska den vetenskapliga revolutionen och framväxten av modern vetenskap. Täck nyckelgestalter som Galileo, Newton och deras upptäckter.',
  },
  {
    text: 'Kalla kriget och öst-västkonflikten',
    value:
      'Granska kalla krigets olika aspekter. Täck Berlinmuren, Kubakrisen, rymdkapplöpningen och konfliktens upplösning.',
  },
  {
    text: 'Den kulturella renässansen i Asien',
    value:
      'Undersök kulturella och intellektuella rörelser i Asien under olika historiska perioder. Täck litteratur, konst och filosofi i Kina, Japan och Indien.',
  },
  {
    text: 'Diktaturer och totalitära regimer genom historien',
    value:
      'Granska olika former av auktoritära regimer genom historien. Täck fascism, kommunism och militärdiktaturer i olika delar av världen.',
  },
  {
    text: 'Den spanska inkvisitionen och dess påverkan',
    value:
      'Undersök den spanska inkvisitionen. Täck orsaker, metoder, offer och långsiktiga konsekvenser för det spanska samhället.',
  },
  {
    text: 'Utforskningen och kolonialiseringen av Amerika',
    value:
      'Granska européernas upptäckt och kolonisering av Amerika. Täck Columbus, conquistadorerna och de första kolonierna.',
  },
  {
    text: 'Första världskrigets orsaker och följder',
    value:
      'Granska första världskriget. Täck orsaker, viktiga slag, nya teknologier och dess påverkan på världsordningen.',
  },
  {
    text: 'Europas medeltida och renässansiska kungar',
    value:
      'Undersök inflytelserika europeiska monarker under medeltiden och renässansen. Täck deras regeringstid, reformer och konflikter.',
  },
  {
    text: 'De sociala och politiska rörelserna under 1800-talet',
    value:
      'Granska 1800-talets viktiga sociala och politiska rörelser. Täck arbetarrörelsen, kvinnorörelsen och nationalistiska rörelser.',
  },
  {
    text: 'Kulturella och vetenskapliga framsteg under 1900-talet',
    value:
      'Undersök 1900-talets stora vetenskapliga upptäckter och kulturella innovationer. Täck relativitetsteorin, kvantmekanik, modernism och popkultur.',
  },
  {
    text: 'Den moderna tidsålderns framväxt och globalisering',
    value:
      'Granska övergången till det moderna samhället och globaliseringens effekter. Täck teknologisk utveckling, kulturutbyte och global ekonomi.',
  },
  {
    text: 'Politisk och social förändring under 1900-talet',
    value:
      'Undersök de stora politiska och sociala förändringarna under 1900-talet. Täck avkolonialisering, medborgarrättsrörelser och fall av apartheid.',
  },
  {
    text: 'Den teknologiska revolutionens historia',
    value:
      'Granska den teknologiska utvecklingen från industrialiseringen till informationsåldern. Täck viktiga uppfinningar, innovatörer och teknikens samhällspåverkan.',
  },
];

const Randomizer = ({ onSelect, theme, styles, forQuiz, forFlashcards, forPowerPoint }) => {
  const [currentIdea, setCurrentIdea] = useState(null);
  const [isAnimating, setIsAnimating] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const randomize = () => {
    setShowConfirmation(false);
    setIsAnimating(true);

    let shuffleCount = 0;
    const maxShuffles = 10;
    const shuffleInterval = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * lessonIdeas.length);
      setCurrentIdea(lessonIdeas[randomIndex]);
      shuffleCount++;

      if (shuffleCount >= maxShuffles) {
        clearInterval(shuffleInterval);
        const finalIndex = Math.floor(Math.random() * lessonIdeas.length);
        const finalIdea = lessonIdeas[finalIndex];
        setCurrentIdea(finalIdea);
        setTimeout(() => setIsAnimating(false), 500);
      }
    }, 200);
  };

  const handleSelect = () => {
    setShowConfirmation(true);
    onSelect(`${currentIdea.text}: ${currentIdea.value}`);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 4, p: 4 }}>
      {showConfirmation ? (
        <Box sx={{ ...styles.successBox }}>
          <CheckCircleIcon sx={{ fontSize: 60, color: 'success.main', mb: 3 }} />
          <Typography
            variant='h6'
            sx={{
              color: 'success.main',
              fontWeight: 500,
              textAlign: 'center',
              width: '80%',
              margin: '0 auto',
              mb: 3,
            }}
          >
            Ditt ämne är nu valt och du kan fortsätta med att generera{' '}
            {forQuiz
              ? 'ditt quiz'
              : forFlashcards
                ? 'dina flashcards'
                : forPowerPoint
                  ? 'din PowerPoint'
                  : 'din lektion'}{' '}
            nedan.
          </Typography>
          <Button variant='purp' onClick={randomize}>
            Välj annat ämne
          </Button>
        </Box>
      ) : (
        <>
          <Box
            sx={{
              minHeight: '200px',
              width: '100%',
              borderRadius: '16px',
              bgcolor:
                theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.02)',
              border: '1px solid',
              borderColor:
                theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
              '&:hover': {
                borderColor: theme.palette.primary.main,
                bgcolor:
                  theme.palette.mode === 'dark'
                    ? 'rgba(255, 255, 255, 0.08)'
                    : 'rgba(0, 0, 0, 0.04)',
              },
              p: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              transform: isAnimating ? 'scale(0.95)' : 'scale(1)',
              opacity: isAnimating ? 0.5 : 1,
              transition: 'all 0.2s ease-in-out',
            }}
          >
            {currentIdea ? (
              <Box sx={{ textAlign: 'center' }}>
                <Typography variant='h5' sx={{ mb: 2, fontWeight: 600 }}>
                  {currentIdea.text}
                </Typography>
                <Typography variant='body1' color='text.secondary'>
                  {currentIdea.value}
                </Typography>
              </Box>
            ) : (
              <Typography variant='body1' sx={{ color: 'text.secondary', fontStyle: 'italic' }}>
                Klicka på "Slumpa ämne" nedan för att få ett slumpmässigt ämne
              </Typography>
            )}
          </Box>

          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button
              variant='purp'
              onClick={randomize}
              disabled={isAnimating}
              startIcon={
                <ShuffleIcon
                  sx={{
                    animation: isAnimating ? 'spin 1s linear infinite' : 'none',
                    '@keyframes spin': {
                      '0%': { transform: 'rotate(0deg)' },
                      '100%': { transform: 'rotate(360deg)' },
                    },
                  }}
                />
              }
            >
              Slumpa ämne
            </Button>

            {currentIdea && (
              <Button
                variant='success'
                onClick={handleSelect}
                startIcon={<CheckCircleIcon />}
                disabled={isAnimating}
              >
                Jag är nöjd — Välj detta ämne
              </Button>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};
export default Randomizer;
