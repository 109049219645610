import React, { useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import Looks3Icon from "@mui/icons-material/Looks3";
import Looks4Icon from "@mui/icons-material/Looks4";
import GenerateIcon from "@mui/icons-material/AutoAwesome";
import { OptionButton } from "../StyledComponents";
import AIDialog from "./AIDialog";
import { UserProgressChart } from "./quizResultComponents";
import RuleIcon from "@mui/icons-material/Rule";
import WestIcon from "@mui/icons-material/West";
import { useNavigate } from "react-router-dom";
import Logger from "./Logger";

import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";

const QuizResults = ({
  results,
  currentQuizId,
  userId,
  theme,
  styles,
  setShowSummary,
  insideLessonQuiz,
}) => {
  const [openAIDialogs, setOpenAIDialogs] = useState({});
  const navigate = useNavigate();

  Logger.log("Aktivt quiz i QuizResults:", JSON.stringify(results, null, 2));

  const formatTime = (seconds) => {
    if (!seconds) return null;
    if (seconds < 60) return `${seconds} sekunder`;
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes} min${minutes > 1 ? "" : ""} ${remainingSeconds} sek`;
  };
  const formatTotalTime = (seconds) => {
    if (!seconds) return null;
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    if (minutes === 0) return `${remainingSeconds} sekunder`;
    return `${minutes} min${minutes > 1 ? "" : ""} ${remainingSeconds} sek`;
  };
  const handleOpenAIDialog = (index) => {
    setOpenAIDialogs((prev) => ({ ...prev, [index]: true }));
  };

  const handleCloseAIDialog = (index) => {
    setOpenAIDialogs((prev) => ({ ...prev, [index]: false }));
  };
  const handleBack = () => {
    setShowSummary(false);
    navigate("/app/quiz");
  };
  const isExam = results.length > 0 && results[0].type === "exam";
  const correctAnswers = results.filter((result) => result.isCorrect).length;
  const percentageCorrect = ((correctAnswers / results.length) * 100).toFixed(
    0,
  );

  return (
    <Box
      sx={{
        ...styles.container,
        textAlign: "left",
        minHeight: "100vh",
        height: insideLessonQuiz ? "auto" : "100vh",
        backgroundColor: insideLessonQuiz
          ? theme.palette.background.paperHover
          : undefined,
        padding: insideLessonQuiz ? "20px" : undefined,
        boxShadow: insideLessonQuiz
          ? "rgba(0, 0, 0, 0.24) 0px 3px 8px"
          : undefined,
        borderRadius: insideLessonQuiz ? 4 : 0,
      }}
    >
      <Box
        sx={{
          ...styles.innerContainer,
          width: { xs: "100%", md: insideLessonQuiz ? "100%" : "80%" },
          position: "relative",
          padding: { xs: "20px", md: insideLessonQuiz ? "0px" : "40px" },
          margin: "0 auto",
        }}
      >
        <Button
          variant="print"
          onClick={handleBack}
          sx={{ mb: 5, display: insideLessonQuiz ? "none" : undefined }}
        >
          <WestIcon sx={{ mr: 1 }} /> Tillbaka
        </Button>
        <Box sx={styles.headlineLessonBox}>
          <Typography
            variant="h6"
            sx={{
              ...styles.headlineLessonBoxTitle,

              fontWeight: "600",
              textAlign: "center",
            }}
          >
            Resultat
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: "center",
            gap: 1,
            justifyContent: "center",
            marginTop: 4,
          }}
        >
          <RuleIcon />
          <Typography
            variant="h5"
            sx={{ fontSize: "20px", textAlign: "center" }}
          >
            Du fick {correctAnswers} av {results.length} rätt!
          </Typography>

          {/* Add total time if it exists */}
          {results.some((r) => r.timeSpent) && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                color: theme.palette.text.secondary,
              }}
            >
              <TimerOutlinedIcon sx={{ ml: 3 }} />
              <Typography variant="h5" sx={{ fontSize: "20px" }}>
                Total tid:{" "}
                {formatTotalTime(
                  results.reduce((total, r) => total + (r.timeSpent || 0), 0),
                )}
              </Typography>
            </Box>
          )}
        </Box>

        <Box sx={{ marginTop: 4 }}>
          {results.map((result, index) => (
            <Box
              key={index}
              sx={{
                marginBottom: 4,
                padding: 5,
                backgroundColor: theme.palette.background.paper,
                borderRadius: "8px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%", // Added to ensure full width
                  mb: 2,
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    marginBottom: 0,
                    fontSize: "0.9rem",
                    color: "text.secondary",
                  }}
                >
                  Fråga {index + 1}:
                </Typography>
                <Box sx={{ flexGrow: 1 }} />{" "}
                {/* Added spacer to push time to right */}
                {result.timeSpent && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 0.5,
                      color: theme.palette.text.secondary,
                      fontSize: "0.9rem",
                    }}
                  >
                    <TimerOutlinedIcon sx={{ fontSize: "1rem" }} />
                    {formatTime(result.timeSpent)}
                  </Box>
                )}
              </Box>
              <Typography
                variant="body1"
                sx={{ marginBottom: 2, fontWeight: "500", fontSize: "1.2rem" }}
              >
                {result.question}
              </Typography>

              {result.type === "exam" ? (
                <Box sx={{ mt: 4, mb: 4 }}>
                  <Typography
                    variant="h6"
                    sx={{ mb: 1, fontSize: "1.1rem", color: "text.secondary" }}
                  >
                    Ditt svar:
                  </Typography>
                  <Typography sx={{ mb: 3, whiteSpace: "pre-wrap" }}>
                    {result.userAnswer}
                  </Typography>

                  <Box
                    sx={{
                      p: 2,
                      mb: 3,
                      borderRadius: 1,
                      backgroundColor: result.isCorrect
                        ? theme.palette.mode === "dark"
                          ? "rgba(52, 168, 83, 0.15)"
                          : "#e6f4ea"
                        : theme.palette.mode === "dark"
                          ? "rgba(234, 67, 53, 0.15)"
                          : "#fce8e6",
                      borderLeft: `4px solid ${result.isCorrect ? "#34a853" : "#ea4335"}`,
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        mb: 1,
                        fontSize: "1rem",
                        color: result.isCorrect ? "#34a853" : "#ea4335",
                      }}
                    >
                      {result.isCorrect ? "Korrekt svar!" : "Fel svar"}
                    </Typography>
                    <Typography sx={{ whiteSpace: "pre-wrap" }}>
                      {result.isCorrect
                        ? "Förklaring varför det var korrekt:"
                        : "Förklaring varför det var fel:"}
                    </Typography>
                    <Typography sx={{ mt: 1, whiteSpace: "pre-wrap" }}>
                      {result.explanation}
                    </Typography>
                  </Box>

                  <Typography
                    variant="h6"
                    sx={{ mb: 1, fontSize: "1.1rem", color: "text.secondary" }}
                  >
                    Korrekt svar:
                  </Typography>
                  <Typography sx={{ whiteSpace: "pre-wrap" }}>
                    {result.answer}
                  </Typography>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    mt: 4,
                    mb: 4,
                    flexDirection: { xs: "column", sm: "row" },
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    gap: 2,
                  }}
                >
                  {result.options.map((option, optIndex) => {
                    const isSelected = option === result.selectedOption;
                    const isCorrect = option === result.correct;

                    return (
                      <OptionButton
                        key={optIndex}
                        variant="outlined"
                        sx={{
                          backgroundColor:
                            isSelected && isCorrect
                              ? theme.palette.mode === "dark"
                                ? "rgba(52, 168, 83, 0.15)!important"
                                : "#e6f4ea!important"
                              : isSelected && !isCorrect
                                ? theme.palette.mode === "dark"
                                  ? "rgba(234, 67, 53, 0.15)!important"
                                  : "#fce8e6!important"
                                : isCorrect
                                  ? theme.palette.mode === "dark"
                                    ? "rgba(52, 168, 83, 0.15)!important"
                                    : "#e6f4ea!important"
                                  : "theme.palette.divider",
                          borderColor:
                            isSelected && isCorrect
                              ? "#34a853!important"
                              : isSelected && !isCorrect
                                ? "#ea4335!important"
                                : isCorrect
                                  ? "#34a853!important"
                                  : "theme.palette.divider",
                          color: theme.palette.text.primary,
                          opacity: "1!important",
                          pointerEvents: "none",
                        }}
                      >
                        {optIndex === 0 && (
                          <LooksOneIcon
                            sx={{
                              marginRight: "8px",
                              color: `${
                                option === result.correct
                                  ? "rgb(17 161 70)!important"
                                  : option === result.selectedOption
                                    ? "rgb(178 28 54) !important"
                                    : "#8b5cf6!important"
                              }`,
                            }}
                          />
                        )}
                        {optIndex === 1 && (
                          <LooksTwoIcon
                            sx={{
                              marginRight: "8px",
                              color: `${
                                option === result.correct
                                  ? "rgb(17 161 70) !important"
                                  : option === result.selectedOption
                                    ? "rgb(178 28 54) !important"
                                    : "#8b5cf6!important"
                              }`,
                            }}
                          />
                        )}
                        {optIndex === 2 && (
                          <Looks3Icon
                            sx={{
                              marginRight: "8px",
                              color: `${
                                option === result.correct
                                  ? "rgb(17 161 70) !important"
                                  : option === result.selectedOption
                                    ? "rgb(178 28 54) !important"
                                    : "#8b5cf6!important"
                              }`,
                            }}
                          />
                        )}
                        {optIndex === 3 && (
                          <Looks4Icon
                            sx={{
                              marginRight: "8px",
                              color: `${
                                option === result.correct
                                  ? "rgb(17 161 70) !important"
                                  : option === result.selectedOption
                                    ? "rgb(178 28 54) !important"
                                    : "#8b5cf6!important"
                              }`,
                            }}
                          />
                        )}
                        {option}
                      </OptionButton>
                    );
                  })}
                </Box>
              )}

              <Button
                variant={theme.palette.mode === "light" ? "purp" : "contained"}
                color="primary"
                onClick={() => handleOpenAIDialog(index)}
                sx={{
                  marginTop: 2,
                  display: insideLessonQuiz ? "none" : undefined,
                }}
                startIcon={<GenerateIcon />}
              >
                Diskutera med AI
              </Button>

              <AIDialog
                userId={userId}
                open={openAIDialogs[index] || false}
                onClose={() => handleCloseAIDialog(index)}
                initialQuestion={
                  result.type === "exam"
                    ? `Fråga: ${result.question}\nMitt svar: ${result.userAnswer}\nKorrekt svar: ${result.answer}\nFörklara denna fråga och hjälp mig förstå vad som var rätt eller fel. Skriv på svenska.`
                    : `Fråga: ${result.question}\nAlternativ: ${result.options.join(", ")}\nKorrekt svar: ${result.correct}\nValt svar: ${result.selectedOption}\nFörklara denna fråga och hjälp mig förstå svarsalternativen. Skriv på svenska.`
                }
                systemMessage="Du är en hjälpsam AI-assistent designad för att förklara och hjälpa till med quizfrågor. Ge en kort och enkel förklaring. Skriv på svenska."
                theme={theme}
              />
            </Box>
          ))}
        </Box>
        {!insideLessonQuiz && !isExam && (
          <>
            <Box sx={styles.headlineLessonBox}>
              <Typography
                variant="h6"
                sx={{
                  ...styles.headlineLessonBoxTitle,
                  textAlign: "left",
                  mt: 8,
                }}
              >
                Dina resultat över tid
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: 2 }}>
              <Box
                sx={{
                  ...styles.newBox,
                  padding: "20px",
                  flexGrow: 1,
                  paddingBottom: "50px",
                }}
              >
                <UserProgressChart userId={userId} quizId={currentQuizId} />
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default QuizResults;
