import {
  Psychology,
  Science,
  Language,
  Calculate,
  Public,
  Biotech,
  Park,
  Agriculture,
  MonitorHeart,
  MenuBook,
  Lightbulb,
  AccountBalance,
} from '@mui/icons-material';

// Default themes that show when no subject is selected
export const defaultThemes = [
  { type: 'subheader', label: 'Teman som lämpar sig extra bra för tankekartor:' },
  {
    value: 'energislag',
    label: 'Energislag',
    content: 'Skapa en mindmap om olika energikällor och deras påverkan',
    Icon: Lightbulb,
  },
  {
    value: 'miljopaverkan',
    label: 'Miljöpåverkan',
    content: 'Skapa en mindmap om olika miljöfrågor och lösningar',
    Icon: Park,
  },
  {
    value: 'samhallsstruktur',
    label: 'Samhällsstruktur',
    content: 'Skapa en mindmap om samhällets olika delar och funktioner',
    Icon: AccountBalance,
  },
  {
    value: 'vatten-kretslopp',
    label: 'Vattnets kretslopp',
    content: 'Skapa en mindmap om vattnets olika former och rörelser i naturen',
    Icon: Public,
  },
];

// Subject-specific themes
export const subjectThemes = {
  naturkunskap: [
    { type: 'subheader', label: 'Naturkunskapsteman som lämpar sig extra bra för mindmaps:' },
    {
      value: 'ekosystem',
      label: 'Ekosystem',
      content: 'Skapa en mindmap om olika ekosystem och deras samband',
      Icon: Park,
    },
    {
      value: 'materia',
      label: 'Materians uppbyggnad',
      content: 'Skapa en mindmap om atomernas och molekylernas värld',
      Icon: Science,
    },
    {
      value: 'klimatpaverkan',
      label: 'Klimatpåverkan',
      content: 'Skapa en mindmap om faktorer som påverkar klimatet',
      Icon: Public,
    },
    {
      value: 'kroppen',
      label: 'Människokroppen',
      content: 'Skapa en mindmap om kroppens olika system och funktioner',
      Icon: MonitorHeart,
    },
  ],

  religion: [
    { type: 'subheader', label: 'Religionsteman som lämpar sig extra bra för mindmaps:' },
    {
      value: 'islam',
      label: 'Islam',
      content: 'Skapa en mindmap om islams centrala begrepp och traditioner',
      Icon: Psychology,
    },
    {
      value: 'kristendom',
      label: 'Kristendom',
      content: 'Skapa en mindmap om kristendomens olika inriktningar och traditioner',
      Icon: Psychology,
    },
    {
      value: 'buddhism',
      label: 'Buddhism',
      content: 'Skapa en mindmap om buddhismens läror och koncept',
      Icon: Psychology,
    },
    {
      value: 'livsaskadningar',
      label: 'Livsåskådningar',
      content: 'Skapa en mindmap om olika livsåskådningar och filosofier',
      Icon: Psychology,
    },
  ],

  samhallskunskap: [
    { type: 'subheader', label: 'Samhällskunskapsteman som lämpar sig extra bra för mindmaps:' },
    {
      value: 'demokrati',
      label: 'Demokrati',
      content: 'Skapa en mindmap om demokratins grunder och funktioner',
      Icon: AccountBalance,
    },
    {
      value: 'statsformer',
      label: 'Statsformer',
      content: 'Skapa en mindmap om olika typer av statsskick och styrelsesätt',
      Icon: Public,
    },
    {
      value: 'ekonomi',
      label: 'Ekonomi',
      content: 'Skapa en mindmap om ekonomiska system och begrepp',
      Icon: Calculate,
    },
    {
      value: 'rattigheter',
      label: 'Mänskliga rättigheter',
      content: 'Skapa en mindmap om grundläggande rättigheter och skyldigheter',
      Icon: MenuBook,
    },
  ],

  svenska: [
    { type: 'subheader', label: 'Svenskateman som lämpar sig extra bra för mindmaps:' },
    {
      value: 'sprakuppbyggnad',
      label: 'Språkets uppbyggnad',
      content: 'Skapa en mindmap om språkets olika beståndsdelar',
      Icon: Language,
    },
    {
      value: 'texttyper',
      label: 'Texttyper',
      content: 'Skapa en mindmap om olika genrer och texttyper',
      Icon: MenuBook,
    },
    {
      value: 'berattarteknik',
      label: 'Berättarteknik',
      content: 'Skapa en mindmap om olika berättartekniker och stilgrepp',
      Icon: MenuBook,
    },
    {
      value: 'grammatik',
      label: 'Grammatik',
      content: 'Skapa en mindmap om grammatikens olika delar',
      Icon: Language,
    },
  ],
};

// Helper function to get appropriate themes based on subject
export const getThemes = subject => {
  if (!subject) return defaultThemes;
  return subjectThemes[subject] || defaultThemes;
};

export default getThemes;
