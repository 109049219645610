import React from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  Tooltip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import HoverableImage from "./HoverableImage";

const DiaryContent = ({
  lessonData,
  setLessonData,
  isEditMode,
  theme,
  isGeneratingImages,
  loadingImageIndex,
  handleImageButtonClick,
  user,
  showSnackbar,
  styles,
}) => {
  const handleTitleChange = (e) => {
    setLessonData((prev) => ({
      ...prev,
      title: e.target.value,
    }));
  };

  const handleEntryChange = (index, field, value) => {
    setLessonData((prev) => ({
      ...prev,
      diaryEntries: prev.diaryEntries.map((entry, i) =>
        i === index ? { ...entry, [field]: value } : entry,
      ),
    }));
  };

  const handleDeleteEntry = (index) => {
    setLessonData((prev) => ({
      ...prev,
      diaryEntries: prev.diaryEntries.filter((_, i) => i !== index),
    }));
  };

  const handleAddEntry = () => {
    setLessonData((prev) => ({
      ...prev,
      diaryEntries: [
        ...prev.diaryEntries,
        {
          date: new Date().toISOString().split("T")[0],
          content: "",
          imageUrl: "",
        },
      ],
    }));
  };

  return (
    <>
      {isEditMode ? (
        <TextField
          fullWidth
          value={lessonData.title}
          onChange={handleTitleChange}
          sx={{
            ...styles.editingTextField,
            mb: 6,
            "& .MuiInputBase-input": {
              textAlign: "center",
              fontSize: "2.5rem",
              fontFamily: "'Playfair Display', serif",
              color: theme.palette.mode === "light" ? "#5c4a42" : "#d4a373",
            },
          }}
        />
      ) : (
        <Typography
          variant="h2"
          sx={{
            textAlign: "center",
            mb: 6,
            fontSize: "2.5rem",
            fontFamily: "'Playfair Display', serif",
            color: theme.palette.mode === "light" ? "#5c4a42" : "#d4a373",
            borderBottom:
              theme.palette.mode === "light"
                ? "2px solid #d4a373"
                : "2px solid #666",
            pb: 2,
          }}
        >
          {lessonData.title}
        </Typography>
      )}

      {lessonData.diaryEntries.map((entry, index) => (
        <Box
          key={index}
          sx={{
            mb: 8,
            position: "relative",
            "&::after": {
              content: '""',
              position: "absolute",
              bottom: "-30px",
              left: "50%",
              transform: "translateX(-50%)",
              width: "70%",
              height: "1px",
              backgroundColor:
                theme.palette.mode === "light" ? "#d4a373" : "#666",
              opacity: 0.5,
            },
          }}
        >
          {isEditMode && (
            <Box
              sx={{
                position: "absolute",
                top: -20,
                right: -20,
                opacity: 0,
                transition: "opacity 0.2s",
                ".MuiBox-root:hover &": { opacity: 1 },
              }}
            >
              <Tooltip title="Ta bort dagboksinlägget">
                <IconButton onClick={() => handleDeleteEntry(index)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Box>
          )}

          {isEditMode ? (
            <TextField
              type="date"
              value={entry.date}
              onChange={(e) => handleEntryChange(index, "date", e.target.value)}
              sx={{
                ...styles.editingTextField,
                mb: 3,
                "& .MuiInputBase-input": {
                  fontSize: "1.1rem",
                  fontFamily: "'Playfair Display', serif",
                  color: theme.palette.mode === "light" ? "#5c4a42" : "#d4a373",
                  fontStyle: "italic",
                },
              }}
            />
          ) : (
            <Typography
              sx={{
                mb: 3,
                fontSize: "1.1rem",
                fontFamily: "'Playfair Display', serif",
                color: theme.palette.mode === "light" ? "#5c4a42" : "#d4a373",
                fontStyle: "italic",
              }}
            >
              {new Date(entry.date).toLocaleDateString("sv-SE", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
            </Typography>
          )}

          <Box
            sx={{
              display: "flex",
              gap: 4,
              flexDirection: {
                xs: "column",
                md: index % 2 === 0 ? "row" : "row-reverse",
              },
            }}
          >
            <Box sx={{ flex: "0 0 300px" }}>
              <Box
                sx={{
                  p: 2,
                  backgroundColor:
                    theme.palette.mode === "light" ? "white" : "#1a1a1a",
                  borderRadius: "7px",
                  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                  transform: index % 2 === 0 ? "rotate(-1deg)" : "rotate(1deg)",
                  transition: "transform 0.3s ease",
                  "&:hover": {
                    transform: "rotate(0deg)",
                  },
                }}
              >
                <HoverableImage
                  imageUrl={entry.imageUrl}
                  isLoading={isGeneratingImages}
                  loadingIndex={loadingImageIndex}
                  currentIndex={index}
                  onGenerateClick={() => handleImageButtonClick(index)}
                  onImageUpdate={(url) => {
                    setLessonData((prev) => ({
                      ...prev,
                      diaryEntries: prev.diaryEntries.map((e, i) =>
                        i === index ? { ...e, imageUrl: url } : e,
                      ),
                    }));
                  }}
                  userId={user.uid}
                  theme={theme}
                  showSnackbar={showSnackbar}
                  height="200px"
                />
              </Box>
            </Box>

            {isEditMode ? (
              <TextField
                multiline
                fullWidth
                minRows={4}
                value={entry.content}
                onChange={(e) =>
                  handleEntryChange(index, "content", e.target.value)
                }
                sx={{
                  ...styles.editingTextField,
                  flex: 1,
                  "& .MuiInputBase-input": {
                    whiteSpace: "pre-wrap",
                    lineHeight: 1.8,
                    fontSize: "1.1rem",
                    fontFamily: "'Crimson Text', serif",
                    color:
                      theme.palette.mode === "light" ? "#2d2d2d" : "#e0e0e0",
                  },
                }}
              />
            ) : (
              <Typography
                sx={{
                  flex: 1,
                  whiteSpace: "pre-wrap",
                  lineHeight: 1.8,
                  fontSize: "1.1rem",
                  fontFamily: "'Crimson Text', serif",
                  color: theme.palette.mode === "light" ? "#2d2d2d" : "#e0e0e0",
                  position: "relative",
                  pl: 4,
                  "&::before": {
                    content: '"\\201C"',
                    position: "absolute",
                    left: 0,
                    top: -10,
                    fontSize: "3rem",
                    color: theme.palette.mode === "light" ? "#d4a373" : "#666",
                    opacity: 0.3,
                  },
                }}
              >
                {entry.content}
              </Typography>
            )}
          </Box>
        </Box>
      ))}

      {isEditMode && (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4, mb: 6 }}>
          <Button
            variant="print"
            onClick={handleAddEntry}
            startIcon={<AddIcon />}
          >
            Lägg till dagboksinlägg
          </Button>
        </Box>
      )}
    </>
  );
};

export default DiaryContent;
