import React from "react";
import {
  AppBar,
  Toolbar,
  FormControl,
  InputLabel,
  Select,
  Button,
  MenuItem,
  Box,
  Checkbox,
  ListItemText,
  styled,
  Chip,
  Tooltip,
  Typography,
} from "@mui/material";
import { IconButton } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { lessonMapping } from "./functions";
import { RestartAlt } from "@mui/icons-material";

const StyledAppBar = styled(AppBar)({
  backgroundColor: "#6b46c1",
  //backgroundImage:'url("/img/hepp.svg"),radial-gradient(circle, rgb(106, 80, 243), rgb(62, 46, 141) 82%)',
  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
});

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  minWidth: 200,
  "& .MuiInputLabel-root": {
    color: "rgba(255, 255, 255, 0.7)",
    "&.Mui-focused": {
      color: "white",
    },
  },
  "& .MuiOutlinedInput-root": {
    color: "white",
    "& fieldset": {
      borderColor: "rgba(255, 255, 255, 0.3)",
    },
    "&:hover fieldset": {
      borderColor: "rgba(255, 255, 255, 0.5)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
  },
  "& .MuiSelect-icon": {
    color: "rgba(255, 255, 255, 0.7)",
  },
}));

const StyledChip = styled(Chip)({
  position: "absolute",
  bottom: "0px",
  left: "100px",
  transform: "rotate(-12deg)",
  backgroundColor: "#ffd700",
  color: "#000",
  fontWeight: 600,
  boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
  "&:hover": {
    backgroundColor: "#ffc700",
  },
});

const StyledMenuItem = styled(MenuItem)({
  "&.MuiMenuItem-root": {
    "& .MuiCheckbox-root": {
      color: "#6b46c1",
    },
  },
});

const LessonBankFilter = ({ onFilterChange, styles }) => {
  const [grades, setGrades] = React.useState([]);
  const [subjects, setSubjects] = React.useState([]);
  const [themes, setThemes] = React.useState([]);
  const [lessonTypes, setLessonTypes] = React.useState([]);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const isNationellaProv = searchParams.get("tema") === "nationella-provet";

  React.useEffect(() => {
    if (isNationellaProv) {
      setThemes(["Nationella provet"]);
      onFilterChange({
        grades,
        subjects,
        themes: ["Nationella provet"],
        lessonTypes,
      });
    }
  }, [isNationellaProv]);

  const gradeOptions = ["1-3", "4-6", "7-9", "Gymnasiet"];
  const subjectOptions = [
    "Svenska",
    "Historia",
    "Religion",
    "Geografi",
    "Samhällskunskap",

    "Biologi",
    "Matematik",
    "Engelska",

    "Naturkunskap",
    "Kemi",
    "Fysik",

    "Teknik",
    "Bild",
    "Musik",
    "Slöjd",
    "Modersmål",
    "Moderna språk",
    "Språk",
    "Idrott och hälsa",
    "Spanska",
    "Tyska",
    "Franska",
    "Hem- och konsumentkunskap",
    "Företagsekonomi",
    "Psykologi",
    "Filosofi",
  ];

  const themeOptions = ["Nationella provet"];
  const lessonTypeOptions = [
    "Läsförståelse",
    "Faktatext",
    "Instruerande text",
    "Förklarande text",
    "Argumenterande text",
    "Beskrivande text",
    "Jeopardy",

    "Tidslinjer",
    "Escape Room",
    "Bygg tidslinjer",
    "Rollkort",
    "Fiktiva dagböcker",
    "Lektionsplan",
    "Litteraturskolan",
    "Fiktiva brev",
    "Historisk person",

    "Krönika",
    "Recension",
    "På spåret",
    "Glosor i lådor",
    "Veckans ord",
    "Tungvrickare",
    "Grammatiklektion",
    "Idiomatiska uttr.",
    "Fiktiva nyhetsredaktionen",
    "Mystiska bilden",
    "Hitta orden",
    "Bilda ordet",
    "Skrivlektion",
    "Forts. berättelsen",
    "Berättande text",
    "Grej of the day",

    "Konversation",
    "Intervju",
    "Argumentera",

    "Generera PowerPoint",
    "Tankekartor",
  ];

  // REPLACE your existing handleChange function with this one
  const handleChange = (event, setterFunction, filterType) => {
    const {
      target: { value },
    } = event;
    setterFunction(typeof value === "string" ? value.split(",") : value);

    // Special handling for themes
    if (filterType === "themes") {
      const newThemes = typeof value === "string" ? value.split(",") : value;
      const hasNationellaProv = newThemes.includes("Nationella provet");

      if (hasNationellaProv) {
        setSearchParams({ tema: "nationella-provet" });
      } else {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.delete("tema");
        setSearchParams(newSearchParams);
      }
    }

    onFilterChange({
      grades: filterType === "grades" ? value : grades,
      subjects: filterType === "subjects" ? value : subjects,
      themes: filterType === "themes" ? value : themes,
      lessonTypes: filterType === "lessonTypes" ? value : lessonTypes,
    });
  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 8 + 8,
        width: 250,
      },
    },
  };

  return (
    <StyledAppBar position="sticky">
      <Toolbar
        sx={{
          justifyContent: "flex-start", // Changed from space-between
          p: 3,
          paddingBottom: "30px",
          gap: 3, // Adds consistent spacing
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton
            onClick={() => navigate("/app")}
            sx={{
              color: "white",
              mr: 2,
              bgcolor: "rgba(147, 120, 208, 0.3)",
              "&:hover": {
                bgcolor: "rgba(147, 120, 208, 0.5)",
              },
              padding: "8px",
              borderRadius: "50%",
            }}
          >
            <ArrowBack />
          </IconButton>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              position: "relative",
            }}
          >
            {isNationellaProv && (
              <StyledChip label="Nationella provet" size="small" />
            )}

            <Typography
              variant="h1"
              sx={{
                fontWeight: 600,
                color: "white",
                fontSize: "1.3rem",
                cursor: "pointer",
                mb: 0.1,
              }}
              onClick={() => {
                setGrades([]);
                setSubjects([]);
                setThemes([]);
                setLessonTypes([]);
                onFilterChange({
                  grades: [],
                  subjects: [],
                  themes: [],
                  lessonTypes: [],
                });
                window.scrollTo({ top: 0, behavior: "smooth" });
                const newSearchParams = new URLSearchParams(searchParams);
                newSearchParams.delete("tema");
                setSearchParams(newSearchParams);
              }}
            >
              Lektionsbanken
            </Typography>

            <Typography
              variant="subtitle2"
              sx={{ color: "rgba(255, 255, 255, 0.7)" }}
            >
              Filtrera och hitta lektioner
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", gap: 3, mx: "auto" }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
            <Typography
              variant="subtitle2"
              sx={{ color: "rgba(255, 255, 255, 0.9)" }}
            >
              Årskurs:
            </Typography>
            <StyledFormControl>
              <InputLabel>Årskurs</InputLabel>
              <Select
                multiple
                value={grades}
                onChange={(e) => handleChange(e, setGrades, "grades")}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
                label="Årskurs"
              >
                {gradeOptions.map((grade) => (
                  <StyledMenuItem key={grade} value={grade}>
                    <Checkbox checked={grades.indexOf(grade) > -1} />
                    <ListItemText primary={grade} />
                  </StyledMenuItem>
                ))}
              </Select>
            </StyledFormControl>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
            <Typography
              variant="subtitle2"
              sx={{ color: "rgba(255, 255, 255, 0.9)" }}
            >
              Ämne:
            </Typography>
            <StyledFormControl>
              <InputLabel>Ämne</InputLabel>
              <Select
                multiple
                value={subjects}
                onChange={(e) => handleChange(e, setSubjects, "subjects")}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
                label="Ämne"
              >
                {subjectOptions.map((subject) => (
                  <StyledMenuItem key={subject} value={subject}>
                    <Checkbox checked={subjects.indexOf(subject) > -1} />
                    <ListItemText primary={subject} />
                  </StyledMenuItem>
                ))}
              </Select>
            </StyledFormControl>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
            <Typography
              variant="subtitle2"
              sx={{ color: "rgba(255, 255, 255, 0.9)" }}
            >
              Lektionstyp:
            </Typography>
            <StyledFormControl>
              <InputLabel>Lektionstyp</InputLabel>
              <Select
                multiple
                value={lessonTypes}
                onChange={(e) => handleChange(e, setLessonTypes, "lessonTypes")}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
                label="Lektionstyp"
              >
                {lessonTypeOptions.map((type) => (
                  <StyledMenuItem key={type} value={type}>
                    <Checkbox checked={lessonTypes.indexOf(type) > -1} />
                    <ListItemText primary={type} />
                  </StyledMenuItem>
                ))}
              </Select>
            </StyledFormControl>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
            <Typography
              variant="subtitle2"
              sx={{ color: "rgba(255, 255, 255, 0.9)" }}
            >
              Tema:
            </Typography>
            <StyledFormControl>
              <InputLabel>Teman</InputLabel>
              <Select
                multiple
                value={themes}
                onChange={(e) => handleChange(e, setThemes, "themes")}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
                label="Teman"
              >
                {themeOptions.map((theme) => (
                  <StyledMenuItem key={theme} value={theme}>
                    <Checkbox checked={themes.indexOf(theme) > -1} />
                    <ListItemText primary={theme} />
                  </StyledMenuItem>
                ))}
              </Select>
            </StyledFormControl>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end", // Aligns with the bottom of the labels
              paddingTop: "24px", // Compensates for the label height
              marginLeft: "auto", // Pushes to the far right
            }}
          >
            <Tooltip title="Rensa alla filter">
              <Button
                variant="print"
                sx={{ ...styles.tightButton, alignSelf: "center" }}
                onClick={() => {
                  setGrades([]);
                  setSubjects([]);
                  setThemes([]);
                  setLessonTypes([]);
                  onFilterChange({
                    grades: [],
                    subjects: [],
                    themes: [],
                    lessonTypes: [],
                  });

                  window.scrollTo({ top: 0, behavior: "smooth" });
                  const newSearchParams = new URLSearchParams(searchParams);
                  newSearchParams.delete("tema");
                  setSearchParams(newSearchParams);
                }}
                startIcon={<RestartAlt />}
              >
                Rensa
              </Button>
            </Tooltip>
          </Box>
        </Box>
      </Toolbar>
    </StyledAppBar>
  );
};

export default LessonBankFilter;
