// additionalThemes/ConversationLesson.js
import RestaurantIcon from "@mui/icons-material/Restaurant";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PhoneIcon from "@mui/icons-material/Phone";
import HotelIcon from "@mui/icons-material/Hotel";
import LocalCafeIcon from "@mui/icons-material/LocalCafe";
import LibraryIcon from "@mui/icons-material/LocalLibrary";
import MovieIcon from "@mui/icons-material/Movie";
import FlightIcon from "@mui/icons-material/Flight";
import SportsIcon from "@mui/icons-material/Sports";
import SchoolIcon from "@mui/icons-material/School";
import StoreIcon from "@mui/icons-material/Store";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";
import ScienceIcon from "@mui/icons-material/Science";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import PaletteIcon from "@mui/icons-material/Palette";
import NatureIcon from "@mui/icons-material/Nature";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import ArchitectureIcon from "@mui/icons-material/Architecture";
import MusicNoteIcon from "@mui/icons-material/MusicNote";

export const getAdditionalThemes = (lessonType) => {
  switch (lessonType) {
    case "ConversationLessonVariantInterview.js":
      return [
        {
          type: "subheader",
          label: "Teman som lämpar sig extra bra för denna lektionstyp:",
        },
        {
          value: "football",
          label: "Intervju med fotbollsproffs",
          content: "Simulera en realistisk intervju med fotbollsproffs.",
          Icon: SportsSoccerIcon,
        },
        {
          value: "polis",
          label: "Intervju med polis",
          content: "Simulera en realistisk intervju med polis.",
          Icon: LocalPoliceIcon,
        },
        {
          value: "mariecurie",
          label: "Intervju med Marie Curie",
          content: "Simulera en realistisk intervju med Marie Curie.",
          Icon: ScienceIcon,
        },
        {
          value: "astronaut",
          label: "Intervju med astronaut",
          content:
            "Simulera en realistisk intervju med en astronaut som berättar om livet i rymden.",
          Icon: RocketLaunchIcon,
        },
        {
          value: "artist",
          label: "Intervju med konstnär",
          content:
            "Simulera en realistisk intervju med en framgångsrik konstnär om kreativitet och inspiration.",
          Icon: PaletteIcon,
        },
        {
          value: "chef",
          label: "Intervju med kock",
          content:
            "Simulera en realistisk intervju med en prisbelönt kock om matlagning och restaurangbranschen.",
          Icon: RestaurantIcon,
        },
        {
          value: "environmentalist",
          label: "Intervju med miljöaktivist",
          content:
            "Simulera en realistisk intervju med en miljöaktivist om klimatfrågor och hållbarhet.",
          Icon: NatureIcon,
        },
        {
          value: "teacher",
          label: "Intervju med lärare",
          content:
            "Simulera en realistisk intervju med en erfaren lärare om utbildning och pedagogik.",
          Icon: SchoolIcon,
        },
        {
          value: "author",
          label: "Intervju med författare",
          content:
            "Simulera en realistisk intervju med en känd författare om skrivande och litteratur.",
          Icon: MenuBookIcon,
        },
        {
          value: "doctor",
          label: "Intervju med läkare",
          content:
            "Simulera en realistisk intervju med en läkare om hälsovård och medicinska framsteg.",
          Icon: LocalHospitalIcon,
        },
        {
          value: "archaeologist",
          label: "Intervju med arkeolog",
          content:
            "Simulera en realistisk intervju med en arkeolog om historiska upptäckter.",
          Icon: ArchitectureIcon,
        },
        {
          value: "musician",
          label: "Intervju med musiker",
          content:
            "Simulera en realistisk intervju med en framgångsrik musiker om musik och kreativt skapande.",
          Icon: MusicNoteIcon,
        },
        {
          value: "pilot",
          label: "Intervju med pilot",
          content:
            "Simulera en realistisk intervju med en erfaren pilot om flygning och luftfart.",
          Icon: FlightIcon,
        },
      ];

    default:
      return [
        {
          type: "subheader",
          label: "Teman som lämpar sig extra bra för denna lektionstyp:",
        },
        {
          value: "restaurant",
          label: "Restaurangbesök",
          content:
            "Simulera en realistisk konversation på en restaurang. Den kan inkludera att beställa mat, fråga om menyn, specialkost, eller betala notan. Använd naturliga fraser som används i restaurangmiljö",
          Icon: RestaurantIcon,
        },
        {
          value: "hairdresser",
          label: "Frisör",
          content:
            "Simulera en konversation hos frisören. Den kan inkludera att boka tid, diskutera frisyrer, hårbehandlingar eller betalning",
          Icon: ContentCutIcon,
        },
        {
          value: "grocery",
          label: "I mataffären",
          content:
            "Simulera en konversation i mataffären. Den kan handla om att hitta varor, fråga om priser, erbjudanden, eller färskvaror. Gör dialogen praktisk och vardagsnära",
          Icon: LocalGroceryStoreIcon,
        },
        {
          value: "publicTransport",
          label: "Kollektivtrafik",
          content:
            "Simulera en konversation om kollektivtrafik. Det kan vara vid biljettköp, på bussen/tåget eller vid en hållplats. Inkludera vanliga frågor om avgångar, priser eller rutter",
          Icon: DirectionsBusIcon,
        },
        {
          value: "doctor",
          label: "Läkarbesök",
          content:
            "Simulera ett läkarbesök med fokus på vanliga symptom och enkel vård. Håll samtalet enkelt och undvik komplexa medicinska termer",
          Icon: LocalHospitalIcon,
        },
        {
          value: "shopping",
          label: "Klädbutik",
          content:
            "Simulera ett besök i en klädbutik. Inkludera konversationer om storlekar, priser, material eller att prova kläder. Håll dialogen praktisk och vardaglig",
          Icon: ShoppingCartIcon,
        },
        {
          value: "phoneCall",
          label: "Telefonsamtal",
          content:
            "Simulera ett telefonsamtal i vardaglig situation. Det kan vara att boka tid, beställa något eller prata med en vän. Fokusera på tydlig kommunikation",
          Icon: PhoneIcon,
        },
        {
          value: "hotel",
          label: "Hotellbokning",
          content:
            "Simulera en konversation vid hotellbokning eller incheckning. Inkludera frågor om rum, faciliteter, priser eller service",
          Icon: HotelIcon,
        },
        {
          value: "cafe",
          label: "På caféet",
          content:
            "Simulera ett cafébesök med beställning av dryck och fika. Inkludera vanliga fraser vid beställning, specialönskemål eller betalning",
          Icon: LocalCafeIcon,
        },
        {
          value: "library",
          label: "Biblioteket",
          content:
            "Simulera en konversation på ett bibliotek. Det kan handla om att låna/lämna böcker, hitta material eller få hjälp av bibliotekarien",
          Icon: LibraryIcon,
        },
        {
          value: "cinema",
          label: "Biograf",
          content:
            "Simulera en konversation på bio. Inkludera biljettköp, val av platser, snacks eller diskussion om filmer",
          Icon: MovieIcon,
        },
        {
          value: "airport",
          label: "På flygplatsen",
          content:
            "Simulera en slumpmässig konversation på en flygplats. Den kan utspela sig vid incheckningen, säkerhetskontrollen, gate-området eller bagagehämtningen. Fokusera på realistiska interaktioner som kan uppstå i dessa situationer, som att checka in bagage, visa boardingkort, fråga om förseningar eller hitta rätt gate",
          Icon: FlightIcon,
        },
        {
          value: "gym",
          label: "På gymmet",
          content:
            "Simulera en konversation på ett gym. Det kan handla om medlemskap, träningsråd eller användning av utrustning",
          Icon: SportsIcon,
        },
        {
          value: "school",
          label: "I klassrummet",
          content:
            "Simulera en konversation i skolmiljö. Fokusera på vardagliga situationer mellan elever eller mellan elev och lärare",
          Icon: SchoolIcon,
        },
        {
          value: "store",
          label: "I butiken",
          content:
            "Simulera en konversation i en butik. Inkludera kundbetjäning, produktfrågor eller köp/retur av varor",
          Icon: StoreIcon,
        },
      ];
  }
};
