import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";

export const getAdditionalThemes = (lessonType) => {
  const themeConfigs = {
    "BasicReadingCompLessonVariantInstructingText.js": {
      subheader: "Teman som lämpar sig extra bra för denna lektionstyp:",
      themes: [
        {
          value: "survival",
          label: "Överleva i vildmarken",
          content: "Instruerande text om överlevnadstekniker i olika miljöer",
          Icon: RadioButtonCheckedIcon,
        },
        {
          value: "recycling",
          label: "Återvinn som en pro",
          content: "Instruerande text om kreativ återvinning och upcycling",
          Icon: RadioButtonCheckedIcon,
        },
        {
          value: "mindfulness",
          label: "Mental träning för ungdomar",
          content: "Instruerande text om mindfulness och stresshantering",
          Icon: RadioButtonCheckedIcon,
        },
        {
          value: "urban_gardening",
          label: "Odla din egen mat i staden",
          content: "Instruerande text om stadsodling och vertikala trädgårdar",
          Icon: RadioButtonCheckedIcon,
        },
        {
          value: "digital_art",
          label: "Skapa digital konst",
          content: "Instruerande text om digitalt skapande och kreativitet",
          Icon: RadioButtonCheckedIcon,
        },
        {
          value: "debate_skills",
          label: "Konsten att debattera",
          content: "Instruerande text om debatteknik och argumentationskonst",
          Icon: RadioButtonCheckedIcon,
        },
      ],
    },

    "BasicReadingCompLessonVariantFactText.js": {
      subheader: "Teman som lämpar sig extra bra för denna lektionstyp:",
      themes: [
        {
          value: "future_cities",
          label: "Framtidens smarta städer",
          content: "Faktatext om hur städer utvecklas med AI och hållbarhet",
          Icon: RadioButtonCheckedIcon,
        },
        {
          value: "animal_intelligence",
          label: "Djurens dolda intelligens",
          content: "Faktatext om överraskande kognitiva förmågor hos djur",
          Icon: RadioButtonCheckedIcon,
        },
        {
          value: "space_tourism",
          label: "Rymdturismens framtid",
          content: "Faktatext om kommersiella rymdresor och dess påverkan",
          Icon: RadioButtonCheckedIcon,
        },
        {
          value: "extinct_languages",
          label: "Utdöda språks hemligheter",
          content: "Faktatext om försvunna språk och deras betydelse",
          Icon: RadioButtonCheckedIcon,
        },
        {
          value: "microbiome",
          label: "Mikrobernas värld inom oss",
          content: "Faktatext om tarmfloran och dess påverkan på hälsan",
          Icon: RadioButtonCheckedIcon,
        },
        {
          value: "sleep_science",
          label: "Sömnens mysterier",
          content: "Faktatext om sömnforskning och drömmar",
          Icon: RadioButtonCheckedIcon,
        },
      ],
    },

    "BasicReadingCompLessonVariantArgumentativeText.js": {
      subheader: "Teman som lämpar sig extra bra för denna lektionstyp:",
      themes: [
        {
          value: "ai_education",
          label: "AI i klassrummet",
          content: "Argumenterande text om AI:s roll i utbildning",
          Icon: RadioButtonCheckedIcon,
        },
        {
          value: "social_media_age",
          label: "Åldersgränser på sociala medier",
          content: "Argumenterande text om ungas närvaro online",
          Icon: RadioButtonCheckedIcon,
        },
        {
          value: "gaming_benefits",
          label: "Spelandes positiva effekter",
          content: "Argumenterande text om datorspelens fördelar",
          Icon: RadioButtonCheckedIcon,
        },
        {
          value: "universal_income",
          label: "Basinkomst för alla",
          content: "Argumenterande text om universell basinkomst",
          Icon: RadioButtonCheckedIcon,
        },
        {
          value: "lab_meat",
          label: "Framtidens mat från labb",
          content: "Argumenterande text om labodlat kött",
          Icon: RadioButtonCheckedIcon,
        },
        {
          value: "creative_education",
          label: "Kreativitet vs standardisering",
          content: "Argumenterande text om kreativ utbildning",
          Icon: RadioButtonCheckedIcon,
        },
      ],
    },

    "BasicReadingCompLessonVariantDescriptiveText.js": {
      subheader: "Teman som lämpar sig extra bra för denna lektionstyp:",
      themes: [
        {
          value: "underground_cities",
          label: "Livet under jorden",
          content: "Beskrivande text om underjordiska städer",
          Icon: RadioButtonCheckedIcon,
        },
        {
          value: "arctic_life",
          label: "Arktisk vardag",
          content: "Beskrivande text om livet i polarområdena",
          Icon: RadioButtonCheckedIcon,
        },
        {
          value: "bioluminescence",
          label: "Havens ljusspel",
          content: "Beskrivande text om självlysande havsvarelser",
          Icon: RadioButtonCheckedIcon,
        },
        {
          value: "street_art",
          label: "Gatukonst som berättar",
          content: "Beskrivande text om urban konst och dess betydelse",
          Icon: RadioButtonCheckedIcon,
        },
        {
          value: "nomad_life",
          label: "Moderna nomader",
          content: "Beskrivande text om digitala nomaders livsstil",
          Icon: RadioButtonCheckedIcon,
        },
        {
          value: "forgotten_places",
          label: "Övergivna platser",
          content: "Beskrivande text om övergivna städer och byggnader",
          Icon: RadioButtonCheckedIcon,
        },
      ],
    },

    "BasicReadingCompLessonVariantExplainingText.js": {
      subheader: "Teman som lämpar sig extra bra för denna lektionstyp:",
      themes: [
        {
          value: "quantum_kids",
          label: "Kvantfysik för unga",
          content: "Förklarande text om kvantmekanikens grunder",
          Icon: RadioButtonCheckedIcon,
        },
        {
          value: "meme_culture",
          label: "Memes som kommunikation",
          content: "Förklarande text om memers betydelse i modern kultur",
          Icon: RadioButtonCheckedIcon,
        },
        {
          value: "emotions_science",
          label: "Känslornas kemi",
          content: "Förklarande text om känslors biologiska grund",
          Icon: RadioButtonCheckedIcon,
        },
        {
          value: "crypto_basics",
          label: "Kryptovalutor förklarat",
          content: "Förklarande text om digital ekonomi",
          Icon: RadioButtonCheckedIcon,
        },
        {
          value: "music_brain",
          label: "Musikens kraft",
          content: "Förklarande text om musikens påverkan på hjärnan",
          Icon: RadioButtonCheckedIcon,
        },
        {
          value: "time_perception",
          label: "Tidens mysterium",
          content: "Förklarande text om hur vi upplever tid",
          Icon: RadioButtonCheckedIcon,
        },
      ],
    },

    "BasicReadingCompLessonVariantChronicle.js": {
      subheader: "Teman som lämpar sig extra bra för denna lektionstyp:",
      themes: [
        {
          value: "tech_ethics",
          label: "Teknikens moraliska dilemman",
          content: "Krönika om etiska utmaningar i teknikutvecklingen",
          Icon: RadioButtonCheckedIcon,
        },
        {
          value: "youth_activism",
          label: "Unga förändrar världen",
          content: "Krönika om ungdomars samhällsengagemang",
          Icon: RadioButtonCheckedIcon,
        },
        {
          value: "creativity_crisis",
          label: "Kreativitetens kris",
          content: "Krönika om kreativitet i digitalåldern",
          Icon: RadioButtonCheckedIcon,
        },
        {
          value: "future_work",
          label: "Framtidens arbetsliv",
          content: "Krönika om förändrade arbetsmönster",
          Icon: RadioButtonCheckedIcon,
        },
        {
          value: "digital_identity",
          label: "Vem är du online?",
          content: "Krönika om digital identitet och autenticitet",
          Icon: RadioButtonCheckedIcon,
        },
        {
          value: "global_community",
          label: "En sammankopplad värld",
          content: "Krönika om global gemenskap och kultur",
          Icon: RadioButtonCheckedIcon,
        },
      ],
    },

    "BasicReadingCompLessonVariantReview.js": {
      subheader: "Teman som lämpar sig extra bra för denna lektionstyp:",
      themes: [
        {
          value: "virtual_experience",
          label: "VR-upplevelser",
          content: "Recension av virtuella upplevelser och event",
          Icon: RadioButtonCheckedIcon,
        },
        {
          value: "eco_fashion",
          label: "Hållbart mode",
          content: "Recension av hållbara modekollektioner",
          Icon: RadioButtonCheckedIcon,
        },
        {
          value: "food_future",
          label: "Framtidens mat",
          content: "Recension av innovativa matupplevelser",
          Icon: RadioButtonCheckedIcon,
        },
        {
          value: "digital_art_exhibits",
          label: "Digital konstutställning",
          content: "Recension av digitala konstinstallationer",
          Icon: RadioButtonCheckedIcon,
        },
        {
          value: "edu_apps",
          label: "Lärappar",
          content: "Recension av utbildningsappar och plattformar",
          Icon: RadioButtonCheckedIcon,
        },
        {
          value: "podcast_series",
          label: "Poddserier",
          content: "Recension av pedagogiska podcastserier",
          Icon: RadioButtonCheckedIcon,
        },
      ],
    },
  };

  // Default themes if no specific lesson type matches
  const defaultThemes = {
    subheader: "Teman som lämpar sig extra bra för denna lektionstyp:",
    themes: [
      {
        value: "future_skills",
        label: "Framtidens kompetens",
        content: "Gör en lektion inom läsförståelse om framtidens kompetenser",
        Icon: RadioButtonCheckedIcon,
      },
      {
        value: "digital_ethics",
        label: "Digital etik",
        content: "Gör en lektion inom läsförståelse om etik i digitalåldern",
        Icon: RadioButtonCheckedIcon,
      },
      {
        value: "global_challenges",
        label: "Globala utmaningar",
        content: "Gör en lektion inom läsförståelse om världens utmaningar",
        Icon: RadioButtonCheckedIcon,
      },
      {
        value: "innovation_society",
        label: "Innovationssamhället",
        content:
          "Gör en lektion inom läsförståelse om innovation och utveckling",
        Icon: RadioButtonCheckedIcon,
      },
      {
        value: "cultural_exchange",
        label: "Kulturmöten",
        content:
          "Gör en lektion inom läsförståelse om interkulturell förståelse",
        Icon: RadioButtonCheckedIcon,
      },
      {
        value: "sustainable_future",
        label: "Hållbar framtid",
        content: "Gör en lektion inom läsförståelse om hållbar utveckling",
        Icon: RadioButtonCheckedIcon,
      },
    ],
  };

  const config = themeConfigs[lessonType] || defaultThemes;
  return [
    {
      type: "subheader",
      label: config.subheader,
    },
    ...config.themes,
  ];
};

export default getAdditionalThemes;
