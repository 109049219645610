import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  Box,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Typography,
  Divider,
  IconButton,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { East as ArrowIcon } from "@mui/icons-material";

import LanguageSelector from "./LanguageSelector";
import GradeSelector from "./GradeSelector";
import { AutoFixHigh, RestartAlt } from "@mui/icons-material";

const GroupWorkDialog = ({ open, onClose, onSubmit, theme }) => {
  const [isExampleLoaded, setIsExampleLoaded] = useState(false);

  const [formData, setFormData] = useState({
    grade: "åk6",
    language: "svenska",
    duration: "",
    subject: "",
    purpose: "",
    totalStudents: "",
    maxGroupSize: "",
    individualTasks: false,
  });

  const loadExample = () => {
    if (isExampleLoaded) {
      // Reset to defaults
      setFormData({
        grade: "åk6",
        language: "svenska",
        duration: "",
        subject: "",
        purpose: "",
        totalStudents: "",
        maxGroupSize: "",
        individualTasks: false,
      });
      setIsExampleLoaded(false);
    } else {
      // Load example
      setFormData({
        grade: "åk6",
        language: "svenska",
        duration: "40 minuter",
        subject: "andra världskriget",
        purpose: "förstå kvinnornas roll",
        totalStudents: "20",
        maxGroupSize: "4",
        individualTasks: true,
      });
      setIsExampleLoaded(true);
    }
  };

  const handleChange = (event) => {
    const { name, value, checked } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: name === "individualTasks" ? checked : value,
    }));
  };

  // Special handlers for grade and language
  const handleGradeChange = (newGrade) => {
    setFormData((prev) => ({
      ...prev,
      grade: newGrade,
    }));
  };

  const handleLanguageChange = (newLanguage) => {
    setFormData((prev) => ({
      ...prev,
      language: newLanguage,
    }));
  };

  const handleSubmit = () => {
    const prompt = `Skapa ett grupparbete för ${formData.grade} i ${
      formData.language
    } som tar ${formData.duration}. 
    Ämne: ${formData.subject}
    Syfte: ${formData.purpose}
    Antal elever: ${formData.totalStudents}
    Max gruppstorlek: ${formData.maxGroupSize}
    ${
      formData.individualTasks
        ? "Inkludera även individuella moment för varje gruppmedlem."
        : ""
    }`;

    onSubmit(prompt);
    onClose();
    setFormData({
      grade: "åk6",
      language: "svenska",
      duration: "",
      subject: "",
      purpose: "",
      totalStudents: "",
      maxGroupSize: "",
      individualTasks: false,
    });
  };

  const isFormValid = () => {
    return (
      formData.grade &&
      formData.language &&
      formData.duration &&
      formData.subject &&
      formData.purpose &&
      formData.totalStudents &&
      formData.maxGroupSize
    );
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: 800,
          backgroundColor:
            theme.palette.mode === "dark" ? "background.default" : "white",
          border:
            theme.palette.mode === "dark"
              ? "1px solid #323d48"
              : "1px solid #e2e8f0",
        },
      }}
    >
      <IconButton
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 16,
          top: 16,
          color:
            theme.palette.mode === "dark"
              ? "rgba(255, 255, 255, 0.7)"
              : "inherit",
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogTitle
        sx={{
          textAlign: "center",
          color:
            theme.palette.mode === "dark"
              ? "rgba(255, 255, 255, 0.7)"
              : "inherit",
          p: 4,
          pt: 4,
        }}
      >
        <Typography variant="h5" fontWeight={700}>
          Skapa grupparbete
        </Typography>
      </DialogTitle>
      <Divider />
      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        <Button
          variant="purp"
          size="small"
          onClick={loadExample}
          startIcon={isExampleLoaded ? <RestartAlt /> : <AutoFixHigh />}
          sx={{
            minWidth: "unset",
            px: 2,
            py: 0.5,
            fontSize: "0.75rem",
          }}
        >
          {isExampleLoaded ? "Nollställ" : "Visa exempel"}
        </Button>
      </Box>

      <DialogContent sx={{ p: 3 }}>
        <Grid container spacing={3} sx={{ mt: 1 }}>
          <Grid item xs={12} md={4}>
            <GradeSelector
              grade={formData.grade}
              setGrade={handleGradeChange}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <LanguageSelector
              language={formData.language}
              setLanguage={handleLanguageChange}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel>Tid för grupparbete</InputLabel>
              <Select
                name="duration"
                value={formData.duration}
                onChange={handleChange}
                label="Tid för grupparbete"
              >
                <MenuItem value="10 minuter">10 minuter</MenuItem>
                <MenuItem value="20 minuter">20 minuter</MenuItem>
                <MenuItem value="30 minuter">30 minuter</MenuItem>
                <MenuItem value="40 minuter">40 minuter</MenuItem>
                <MenuItem value="50 minuter">50 minuter</MenuItem>
                <MenuItem value="60 minuter">60 minuter</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Ämne"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              multiline
              rows={2}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Syfte"
              name="purpose"
              value={formData.purpose}
              onChange={handleChange}
              multiline
              rows={2}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel>Antal elever</InputLabel>
              <Select
                name="totalStudents"
                value={formData.totalStudents}
                onChange={handleChange}
                label="Antal elever"
              >
                {[...Array(30)].map((_, i) => (
                  <MenuItem key={i + 1} value={i + 1}>
                    {i + 1}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel>Max antal elever per grupp</InputLabel>
              <Select
                name="maxGroupSize"
                value={formData.maxGroupSize}
                onChange={handleChange}
                label="Max antal elever per grupp"
              >
                {[2, 3, 4, 5, 6].map((size) => (
                  <MenuItem key={size} value={size}>
                    {size}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.individualTasks}
                  onChange={handleChange}
                  name="individualTasks"
                />
              }
              label="Individuell insats inom gruppen"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button onClick={handleSubmit} variant="purp" disabled={!isFormValid()}>
          Skapa grupparbete <ArrowIcon sx={{ ml: 1 }} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GroupWorkDialog;
