import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Divider,
  CircularProgress,
  Tooltip,
  FormControl,
  Select,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  MenuItem,
  Grid,
  TextField,
} from "@mui/material";

import GenerateIcon from "@mui/icons-material/AutoAwesome";
import ImageIcon from "@mui/icons-material/Image";
import ReadingComprehensionDialog from "./ReadingComprehensionDialog";
import PrintIcon from "@mui/icons-material/Print";

import { callApi } from "./api";

import Logger from "./Logger";

import TextFieldsIcon from "@mui/icons-material/TextFields";

import { db } from "./firebase";
import { collection, query, where, limit, getDocs } from "firebase/firestore";

import LanguageSelector from "./LanguageSelector";
import ClearIcon from "@mui/icons-material/Clear";
import { ThemeSelectorFlow } from "./ThemeSelectorFlow";
import InfoBox from "./InfoBox";
import { handlePrintWordCompLesson } from "./printFunctions";
import {
  generateAndProcessImage,
  handleImageGenerationError,
} from "./functions";
import { useSnackbar } from "./SnackbarHandler";
import ProgressBar from "./ProgressBar";

import ImageGenerationModal from "./ImageGenerationModal";
import GradeSelector, { gradeInstructions } from "./GradeSelector";
import { useTheme } from "@mui/material/styles";
import HistoryIcon from "@mui/icons-material/History";
import { getStyles } from "../index";
import { trackLesson } from "./functions";

import LessonDrawer from "./LessonDrawer";
import SaveLessonDialog from "./SaveLessonDialog";
import HoverableImage from "./HoverableImage";
import { getThemes } from "./additionalThemes/WordCompLesson";
import { useSearchParams, useParams } from "react-router-dom";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import IconButton from "@mui/material/IconButton";

const WordCompLesson = ({ user }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);

  const lessonTitleRef = useRef(null); // Add this ref

  const [searchParams] = useSearchParams();

  const [lessonTheme, setLessonTheme] = useState("");
  const [language, setLanguage] = useState("svenska");

  const [grade, setGrade] = useState("åk6");
  const [progress, setProgress] = useState(0);
  const [isGeneratingLesson, setIsGeneratingLesson] = useState(false);
  const [isGeneratingImages, setIsGeneratingImages] = useState(false);

  const [customTheme, setCustomTheme] = useState("");
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageInstructions, setImageInstructions] = useState("");
  const [openAiInstructions, setOpenAiInstructions] = useState("");
  const [wordCount, setWordCount] = useState(5);
  const [loadingImageIndex, setLoadingImageIndex] = useState(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [wordType, setWordType] = useState("random");
  const [predefinedTheme, setPredefinedTheme] = useState(null);
  const [lessonData, setLessonData] = useState(null);
  const subject = searchParams.get("subject") || null;

  const additionalThemes = getThemes(subject);

  const bookContentRef = useRef("");

  const editorRef = useRef(null);
  const { showSnackbar } = useSnackbar();
  const theme = useTheme();
  const styles = getStyles(theme);

  const transcriptRef = useRef("");
  const uploadedFileRef = useRef("");

  const wikipediaContentRef = useRef("");
  const randomizedContentRef = useRef("");
  const coursePlanContentRef = useRef("");
  const urlContentRef = useRef("");

  useEffect(() => {
    Logger.log("subject: ", subject);
  }, [subject]);
  useEffect(() => {
    Logger.log("lessonTheme: ", lessonTheme);
  }, [lessonTheme]);

  useEffect(() => {
    Logger.log("predefinedTheme: ", predefinedTheme);
  }, [predefinedTheme]);

  useEffect(() => {
    Logger.log("customTheme: ", customTheme);
  }, [customTheme]);

  useEffect(() => {
    Logger.log("youtubeTranscript: ", transcriptRef.current);
  }, [transcriptRef.current]);

  useEffect(() => {
    Logger.log("wikipediaContentRef: ", wikipediaContentRef.current);
  }, [wikipediaContentRef.current]);
  useEffect(() => {
    Logger.log("uploadedFileRef: ", uploadedFileRef.current);
  }, [uploadedFileRef.current]);

  useEffect(() => {
    Logger.log("randomizedContentRef: ", randomizedContentRef.current);
  }, [randomizedContentRef.current]);

  useEffect(() => {
    Logger.log("coursePlanContentRef: ", coursePlanContentRef.current);
  }, [coursePlanContentRef.current]);

  const { lessonId } = useParams();
  useEffect(() => {
    const loadLessonFromId = async () => {
      if (lessonId) {
        try {
          const q = query(
            collection(db, "lesson_usage"),
            where("__name__", ">=", lessonId),
            where("__name__", "<=", lessonId + "\uf8ff"),
            where("lessonType", "==", "WordCompLesson.js"),
            limit(1),
          );

          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            const docData = querySnapshot.docs[0].data();
            setLessonData(docData.data);

            // Scroll to lesson content
            setTimeout(() => {
              lessonTitleRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }, 100);

            showSnackbar("Lektionen har laddats in.", "success");
          } else {
            showSnackbar("Kunde inte hitta lektionen", "error");
          }
        } catch (error) {
          console.error("Error loading lesson:", error);
          showSnackbar("Ett fel uppstod när lektionen skulle laddas", "error");
        }
      }
    };

    loadLessonFromId();
  }, lessonId);

  const [editingExerciseIndex, setEditingExerciseIndex] = useState(null);
  const [editedTitle, setEditedTitle] = useState("");
  const [editedContent, setEditedContent] = useState([]);

  const [isEditingMainContent, setIsEditingMainContent] = useState(false);
  const [editedMainContent, setEditedMainContent] = useState("");

  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [editedLessonTitle, setEditedLessonTitle] = useState("");

  const handleEditTitle = () => {
    setIsEditingTitle(true);
    setEditedLessonTitle(lessonData.title);
  };

  const handleSaveTitle = () => {
    const updatedLessonData = { ...lessonData };
    updatedLessonData.title = editedLessonTitle;
    setLessonData(updatedLessonData);
    setIsEditingTitle(false);
    showSnackbar("Ändringarna sparade!", "success");
  };

  const handleEditMainContent = () => {
    setIsEditingMainContent(true);
    setEditedMainContent(lessonData.content);
  };

  const handleSaveMainContent = () => {
    const updatedLessonData = { ...lessonData };
    updatedLessonData.content = editedMainContent;
    setLessonData(updatedLessonData);
    setIsEditingMainContent(false);
    showSnackbar("Ändringarna sparade!", "success");
  };

  const handleEditClick = (index, exercise) => {
    setEditingExerciseIndex(index);
    setEditedTitle(exercise.title);
    setEditedContent([...exercise.content]);
  };

  const handleSaveClick = (index) => {
    const updatedLessonData = { ...lessonData };
    updatedLessonData.exercises[index].title = editedTitle;
    updatedLessonData.exercises[index].content = editedContent;
    setLessonData(updatedLessonData);
    setEditingExerciseIndex(null);
    showSnackbar("Ändringarna sparade!", "success");
  };

  const handleDeleteClick = (index) => {
    const updatedLessonData = { ...lessonData };
    updatedLessonData.exercises.splice(index, 1);
    setLessonData(updatedLessonData);
    showSnackbar("Raderat!", "success");
  };

  const handleDeleteListItem = (exerciseIndex, itemIndex) => {
    const updatedContent = editedContent.filter(
      (_, index) => index !== itemIndex,
    );
    setEditedContent(updatedContent);
    showSnackbar("Raderat!", "success");
  };

  const handleSaveLessonClick = () => {
    setSaveDialogOpen(true);
  };

  const handleSaveWithComment = async (comment, name, coverImage, tags) => {
    Logger.log(JSON.stringify(lessonData, null, 2));
    await trackLesson(
      user.uid,
      "WordCompLesson.js",
      lessonData,
      null,
      true,
      comment,
      name,
      coverImage,
      tags,
    );
    setDrawerOpen(true);
    showSnackbar("Lektionen sparad i din lektionsbank!", "success");
  };

  const simulateProgress = (startProgress, endProgress, duration) => {
    const stepSize = 0.5;
    const steps = (endProgress - startProgress) / stepSize;
    const stepDuration = duration / steps;
    let currentProgress = startProgress;

    const intervalId = setInterval(() => {
      currentProgress += stepSize;
      if (currentProgress >= endProgress) {
        clearInterval(intervalId);
      } else {
        setProgress(currentProgress);
      }
    }, stepDuration);

    return () => clearInterval(intervalId);
  };

  const handleGenerateLesson = async () => {
    setProgress(0);
    let stopProgressSimulation = null;
    setIsGeneratingLesson(true);
    const gradeInstructionsText = gradeInstructions[grade];
    setLessonData(null);
    try {
      let themeToUse;

      if (lessonTheme === "youtube") {
        themeToUse = transcriptRef.current;
      } else if (lessonTheme === "customTheme") {
        themeToUse = customTheme;
      } else if (lessonTheme === "predefined") {
        themeToUse = predefinedTheme;
      } else if (lessonTheme === "fileUpload") {
        themeToUse = uploadedFileRef.current;
      } else if (lessonTheme === "wikipedia") {
        themeToUse = wikipediaContentRef.current;
      } else if (lessonTheme === "randomizer") {
        themeToUse = randomizedContentRef.current;
      } else if (lessonTheme === "book") {
        themeToUse = bookContentRef.current;
      } else if (lessonTheme === "url") {
        themeToUse = urlContentRef.current;
      } else {
        //
        themeToUse = coursePlanContentRef.current;
      }

      Logger.log("themeToUse: ", themeToUse);

      stopProgressSimulation = simulateProgress(0, 90, 15000);

      let response = await callApi(
        [
          {
            role: "system",
            content: `You are an expert teacher creating engaging reading comprehension lessons in ${language}. Follow the exact JSON structure provided in the prompt.`,
          },
          {
            role: "user",
            content: `Skapa en text mellan 50-60 meningar som är UPPDELAD I TYDLIGA STYCKEN med fokus på ordförståelse. 
          Använd '\\n\\n' för att separera styckena.
          Texten ska vara skriven på ${language}.

          
          FÖLJ DENNA STRUKTUR EXAKT:
          1. Välj först EXAKT ${wordCount} ord ${
            wordType === "verbs"
              ? "som alla ska vara verb (handlingsord, t.ex. springa, läsa, skriva)"
              : wordType === "adjectives"
                ? "som alla ska vara adjektiv (beskrivande ord, t.ex. glad, stor, vacker)"
                : wordType === "nouns"
                  ? "som alla ska vara substantiv (namn på saker/personer/platser, t.ex. bord, lärare, park)"
                  : wordType === "pronouns"
                    ? "som alla ska vara pronomen (ersättningsord, t.ex. han, hon, den, det, denna)"
                    : wordType === "adverbs"
                      ? "som alla ska vara adverb (beskriver hur/när/var något sker, t.ex. snabbt, ofta, här)"
                      : wordType === "prepositions"
                        ? "som alla ska vara prepositioner (lägesord, t.ex. på, under, bredvid, genom)"
                        : wordType === "conjunctions"
                          ? "som alla ska vara konjunktioner (bindeord, t.ex. och, men, eller, eftersom)"
                          : wordType === "interjections"
                            ? "som alla ska vara interjektioner (utrop, t.ex. oj, hej, aj)"
                            : wordType === "numerals"
                              ? "som alla ska vara räkneord (t.ex. tre, första, dussin)"
                              : "av blandade ordtyper"
          }

          I "selectedWords" ska du lista orden i EXAKT samma form som de förekommer i texten.
          
          Theme: "${themeToUse}".
          ${openAiInstructions}.
          ${gradeInstructionsText}

          SVARA I FÖLJANDE JSON-FORMAT:
          {
            "title": "Lektionens titel",
            "theme": "Ordtema",
            "selectedWords": ["ord1", "ord2", "ord3"],
            "content": "Text på ${language} här...",
            "exercises": [
              {
                "type": "definitions",
                "title": "Ordförklaringar",
                "content": [
                  {
                    "word": "ord1",
                    "definition": "Förklaring..."
                  }
                ]
              },
              {
                "type": "synonyms",
                "title": "Synonymer och motsatsord",
                "content": [
                  {
                    "word": "ord1",
                    "synonyms": ["synonym1", "synonym2"],
                    "antonyms": ["motsats1", "motsats2"]
                  }
                ]
              },
              {
                "type": "usage",
                "title": "Använd orden",
                "content": [
                  {
                    "task": "Uppgift..." (EXAKT 5 uppgifter)
                  }
                ]
              }
            ]
          }
          `,
          },
        ],
        6000,
        true,
        user.uid,
      );

      if (stopProgressSimulation) stopProgressSimulation();
      setProgress(95);

      let messageContent = response.data.choices[0].message.content.trim();
      if (messageContent.startsWith("```json")) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith("```")) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      const data = JSON.parse(messageContent);
      setProgress(100);

      setLessonData({
        ...data,
        images: [{ imageUrl: null }, { imageUrl: null }],
      });

      await new Promise((resolve) => setTimeout(resolve, 800));

      await trackLesson(user.uid, "WordCompLesson.js", null, {
        language: language,
        lessonTheme: lessonTheme,
      });

      showSnackbar("Din lektion har genererats!", "success");
    } catch (error) {
      console.error("Error generating lesson:", error);
      showSnackbar(
        "Ett fel uppstod när lektionen skulle genereras. Försök igen!",
        "error",
      );
    } finally {
      setIsGeneratingLesson(false);
    }
  };

  /// Image Generation code

  const handleGenerateImages = async () => {
    if (!lessonData) return;
    setIsGeneratingImages(true);
    handleCloseModal();

    try {
      const imagePrompt = `Create an image about a story with the theme: "${lessonData.title}". ${imageInstructions}`;
      const imagePromises = lessonData.images.map(() =>
        generateAndProcessImage(imagePrompt, user.uid),
      );
      const imageUrls = await Promise.all(imagePromises);

      setLessonData((prevData) => ({
        ...prevData,
        images: imageUrls.map((imageUrl) => ({ imageUrl })),
      }));

      showSnackbar("Bilderna har genererats!", "success");
    } catch (error) {
      handleImageGenerationError(error, true, showSnackbar);
    } finally {
      setIsGeneratingImages(false);
      setLoadingImageIndex(null);
    }
  };

  const handleGenerateSpecificImage = async (index) => {
    setIsGeneratingImages(true);
    setLoadingImageIndex(index);
    handleCloseModal();

    try {
      const imagePrompt = `Create an image about a story with the theme: "${lessonData.title}". ${imageInstructions}`;
      const imageUrl = await generateAndProcessImage(imagePrompt, user.uid);

      if (imageUrl) {
        setLessonData((prevData) => {
          const newImages = [...prevData.images];
          newImages[index] = { imageUrl };
          return {
            ...prevData,
            images: newImages,
          };
        });
        showSnackbar("Bilden har genererats!", "success");
      }
    } catch (error) {
      handleImageGenerationError(error, false, showSnackbar);
    } finally {
      setIsGeneratingImages(false);
      setLoadingImageIndex(null);
    }
  };

  const handleImageButtonClick = (index = null) => {
    setSelectedImageIndex(index);
    setShowImageModal(true);
  };
  const handleCloseModal = () => {
    setShowImageModal(false);
    setImageInstructions("");
  };

  const handleGenerateWithInstructions = async () => {
    if (selectedImageIndex !== null) {
      handleCloseModal();
      await handleGenerateSpecificImage(selectedImageIndex);
    } else {
      handleCloseModal();
      await handleGenerateImages();
    }
  };

  return (
    <Box sx={styles.container}>
      <Box
        sx={{
          ...styles.innerContainer,
          width: { xs: "100%", md: "87%" },
          margin: "0 auto",
        }}
      >
        <Typography
          variant="h1"
          gutterBottom
          sx={{
            fontWeight: "bold",
            fontSize: "1.8em",
            mb: 3,
            mt: 5,
            textAlign: "center",
          }}
        >
          Ordförståelse
        </Typography>
        <Box
          sx={{
            ...styles.buttonsContainer,
            ...styles.additionalButtonsContainer,
          }}
        >
          <Tooltip title="Dina sparade lektioner">
            <Button
              variant="contained"
              color="primary"
              disableRipple
              onClick={() => setDrawerOpen(true)}
              startIcon={<HistoryIcon />}
              sx={{
                padding: "8px",
                marginRight: "0px",
                paddingLeft: "14px",
                paddingRight: "3px",
                minWidth: "auto",
                textTransform: "none",
              }}
            />
          </Tooltip>
        </Box>
        <LessonDrawer
          userId={user.uid}
          lessonType={"WordCompLesson.js"}
          theme={theme}
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          lessonIcon={TextFieldsIcon}
          onSelectLesson={(lesson) => {
            setLessonData(lesson.data);
            setDrawerOpen(false);
            setTimeout(() => {
              lessonTitleRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "start", // This ensures it scrolls to the top of the element
              });
            }, 100);
            showSnackbar(`Lektionen har laddats in.`, "success");
          }}
        />
        <InfoBox
          icon={TextFieldsIcon}
          title="Ordförståelse"
          instructions={[
            "Välj antal ord och vilka ordklasser som ska fokuseras",
            "Få ordförklaringar, synonymer och exempel på användning",
            "Utveckla ordförrådet genom kontext och övningar",
          ]}
          theme={theme}
          styles={styles}
        />

        <ThemeSelectorFlow
          subject={subject}
          value={lessonTheme}
          onChange={(e) => setLessonTheme(e.target.value)}
          customTheme={customTheme}
          onCustomThemeChange={(e) => setCustomTheme(e.target.value)}
          onPredefinedThemeChange={(e) => setPredefinedTheme(e.target.value)}
          onBookContentChange={(content) => {
            Logger.log("onBookContentChange", content.substring(0, 100));
            bookContentRef.current = content;
          }}
          onYoutubeTranscriptChange={(transcriptContent) => {
            Logger.log(
              "onYoutubeTranscriptChange körs med: ",
              transcriptContent,
            );
            transcriptRef.current = transcriptContent;
          }}
          onUrlContentChange={(content) => {
            Logger.log(
              "Parent: URL content received",
              content.substring(0, 300),
            );
            urlContentRef.current = content;
          }}
          onFileUpload={(content) => {
            Logger.log("onFileUpload körs med: ", content);
            uploadedFileRef.current = content;
          }}
          onWikipediaContentChange={(content) => {
            Logger.log(
              "Parent: Wikipedia content received",
              content.substring(0, 100),
            );
            wikipediaContentRef.current = content;
          }}
          onRandomizedContentChange={(content) => {
            Logger.log("randomizedContentRef", content.substring(0, 100));
            randomizedContentRef.current = content;
          }}
          onCoursePlanContentChange={(content) => {
            Logger.log("onCoursePlanChange", content.substring(0, 100));
            coursePlanContentRef.current = content;
          }}
          lessonType={"WordCompLesson.js"}
          user={user}
          theme={theme}
          styles={styles}
          onLessonSelect={(lesson) => {
            Logger.log("onLessonSelect körs");
            setLessonData(lesson.data);
            setTimeout(() => {
              lessonTitleRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "start", // This ensures it scrolls to the top of the element
              });
            }, 100);
            showSnackbar("Lektionen har laddats in.", "success");
          }}
          additionalThemes={additionalThemes}
        />

        <Box sx={styles.headlineLessonBox}>
          <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
            2. Generera lektionen
          </Typography>
        </Box>
        <Box sx={{ ...styles.freshBox, padding: "30px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" sx={{ fontWeight: "500", mb: 1 }}>
                Årskurs:
              </Typography>
              <Box sx={{ mt: 0.5, mb: 3 }}>
                <GradeSelector grade={grade} setGrade={setGrade} />
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" sx={{ fontWeight: "500" }}>
                Språk:
              </Typography>
              <Box sx={{ mt: 1, mb: 3 }}>
                <LanguageSelector
                  language={language}
                  setLanguage={setLanguage}
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={12} sx={{ display: "none" }}>
              <Typography variant="subtitle1" sx={{ fontWeight: "500" }}>
                Specifika instruktioner:
              </Typography>
              <FormControl fullWidth margin="normal" sx={{ mb: 3, mt: 1 }}>
                <TextField
                  value={openAiInstructions}
                  onChange={(e) => setOpenAiInstructions(e.target.value)}
                  placeholder="T.ex. Inkludera lite svårare ord såsom defenestrera, kalibrera och frekventera."
                  variant="outlined"
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "500", mb: 1 }}
                >
                  Antal ord att markera:
                </Typography>
                <Select
                  value={wordCount}
                  onChange={(e) => setWordCount(e.target.value)}
                  variant="outlined"
                  sx={{ mb: 3 }}
                >
                  {[5, 7, 9, 11, 13, 15].map((num) => (
                    <MenuItem key={num} value={num}>
                      {num}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "500", mb: 1 }}
                >
                  Ordklasser:
                </Typography>
                <Select
                  value={wordType}
                  onChange={(e) => setWordType(e.target.value)}
                  variant="outlined"
                  sx={{ mb: 3 }}
                >
                  <MenuItem value="random">Blandade ordklasser</MenuItem>
                  <MenuItem value="nouns">
                    Substantiv (namn på saker/personer)
                  </MenuItem>
                  <MenuItem value="verbs">Verb (handlingsord)</MenuItem>
                  <MenuItem value="adjectives">
                    Adjektiv (beskrivande ord)
                  </MenuItem>
                  <MenuItem value="pronouns">
                    Pronomen (han, hon, den, det)
                  </MenuItem>
                  <MenuItem value="adverbs">Adverb (hur/när/var)</MenuItem>
                  <MenuItem value="prepositions">
                    Prepositioner (på, under, i)
                  </MenuItem>
                  <MenuItem value="conjunctions">
                    Konjunktioner (och, men, eller)
                  </MenuItem>
                  <MenuItem value="numerals">
                    Räkneord (tre, första, dussinet)
                  </MenuItem>
                  <MenuItem value="interjections">
                    Interjektioner (utrop)
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Button
            variant={theme.palette.mode === "light" ? "purp" : "contained"}
            color="primary"
            onClick={handleGenerateLesson}
            disabled={isGeneratingLesson}
            sx={{ mt: 4 }}
            startIcon={
              !isGeneratingLesson ? (
                <GenerateIcon style={{ color: "#fff" }} />
              ) : (
                <CircularProgress size={16} />
              )
            }
          >
            {isGeneratingLesson ? "Genererar..." : "Generera lektion"}
          </Button>
          {isGeneratingLesson && (
            <Box sx={{ mt: 6 }}>
              <ProgressBar
                progress={progress}
                label="Genererar lektion — tar 30-60 sekunder..."
              />
            </Box>
          )}
        </Box>
        {lessonData && (
          <>
            <Box
              ref={lessonTitleRef}
              sx={{ ...styles.headlineLessonBox, mt: 10 }}
            >
              <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
                3. Din lektion
              </Typography>
            </Box>

            <Box sx={{ ...styles.freshBox, padding: "30px" }}>
              <Box sx={styles.lessonControlPanel}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    px: 1,
                  }}
                >
                  <Box>
                    <Button
                      variant={
                        theme.palette.mode === "light" ? "purp" : "contained"
                      }
                      color="primary"
                      onClick={() => handleImageButtonClick(null)}
                      disabled={isGeneratingImages}
                      startIcon={
                        !isGeneratingImages ? (
                          <ImageIcon style={{ color: "#fff" }} />
                        ) : (
                          <CircularProgress size={16} />
                        )
                      }
                    >
                      {isGeneratingImages ? "Genererar..." : "Generera bilder"}
                    </Button>
                    <Box sx={{ display: "inline-block", ml: 2 }}>
                      <ReadingComprehensionDialog
                        key={lessonData?.title}
                        lessonData={lessonData}
                        user={user}
                        theme={theme}
                        styles={styles}
                        buttonText="Specifika övningar"
                        tooltip="Perfekt för att generera specfika övningar för enskilda elever"
                      />
                    </Box>
                  </Box>
                  <Box>
                    <Button
                      variant="print"
                      startIcon={<PrintIcon />}
                      sx={{ ml: 2 }}
                      onClick={() => handlePrintWordCompLesson(lessonData)}
                    >
                      Skriv ut
                    </Button>
                    <Button
                      variant="print"
                      startIcon={<SaveAsIcon />}
                      sx={{ ml: 2 }}
                      onClick={handleSaveLessonClick}
                    >
                      Spara i lektionsbank
                    </Button>
                  </Box>
                </Box>
              </Box>

              <SaveLessonDialog
                open={saveDialogOpen}
                onClose={() => setSaveDialogOpen(false)}
                onSave={handleSaveWithComment}
                theme={theme}
                lessonName={lessonData?.title || ""}
                lessonObject={lessonData}
                lessonType={"WordCompLesson.js"}
              />

              <ImageGenerationModal
                open={showImageModal}
                onClose={handleCloseModal}
                onGenerate={handleGenerateWithInstructions}
                isGenerating={isGeneratingImages}
                instructions={imageInstructions}
                setInstructions={setImageInstructions}
                theme={theme}
              />

              <Box sx={{ mt: 4 }}>
                {/* Title */}
                <Box
                  sx={{
                    position: "relative",
                    mb: 4,
                    mt: 4,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {isEditingTitle ? (
                    <TextField
                      value={editedLessonTitle}
                      onChange={(e) => setEditedLessonTitle(e.target.value)}
                      variant="outlined"
                      size="large"
                      sx={{
                        width: "80%",
                        "& .MuiInputBase-input": {
                          fontSize: "2rem",
                          fontWeight: "bold",
                          textAlign: "center",
                        },
                      }}
                    />
                  ) : (
                    <Typography
                      variant="h4"
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      {lessonData.title}
                    </Typography>
                  )}
                  <Tooltip
                    title={isEditingTitle ? "Spara ändringar" : "Redigera"}
                    placement="top"
                  >
                    <IconButton
                      sx={{ ml: 2 }}
                      onClick={() =>
                        isEditingTitle ? handleSaveTitle() : handleEditTitle()
                      }
                    >
                      {isEditingTitle ? <SaveAsIcon /> : <EditIcon />}
                    </IconButton>
                  </Tooltip>
                </Box>
                {/* Main content with highlighted words - only first occurrence */}
                <Box
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === "dark" ? "#15202b" : "#e0e0e029",
                    borderRadius: "10px",
                    padding: "20px",
                    mb: 4,
                    position: "relative",
                  }}
                >
                  <Box sx={{ position: "absolute", top: 10, right: 10 }}>
                    <Tooltip
                      title={
                        isEditingMainContent ? "Spara ändringar" : "Redigera"
                      }
                      placement="top"
                    >
                      <IconButton
                        onClick={() =>
                          isEditingMainContent
                            ? handleSaveMainContent()
                            : handleEditMainContent()
                        }
                      >
                        {isEditingMainContent ? <SaveAsIcon /> : <EditIcon />}
                      </IconButton>
                    </Tooltip>
                  </Box>

                  {isEditingMainContent ? (
                    <TextField
                      value={editedMainContent}
                      onChange={(e) => setEditedMainContent(e.target.value)}
                      multiline
                      fullWidth
                      variant="outlined"
                      minRows={12}
                      sx={{
                        ...styles.editingTextField,
                        mt: 1,
                        width: "96%",
                        lineHeight: 1.8,
                      }}
                    />
                  ) : (
                    <Typography
                      variant="body1"
                      sx={{
                        lineHeight: 1.8,
                        whiteSpace: "pre-wrap",
                        width: "96%",
                      }}
                    >
                      {(() => {
                        const markedWords = new Set();
                        return lessonData.content
                          .split(" ")
                          .map((word, index) => {
                            const wordWithoutPunctuation = word.replace(
                              /[.,!?]$/,
                              "",
                            );
                            const punctuation =
                              word.match(/[.,!?]$/)?.[0] || "";

                            if (
                              lessonData.selectedWords.includes(
                                wordWithoutPunctuation,
                              ) &&
                              !markedWords.has(wordWithoutPunctuation)
                            ) {
                              markedWords.add(wordWithoutPunctuation);
                              return (
                                <React.Fragment key={index}>
                                  <span
                                    style={{
                                      backgroundColor: theme.palette.text.main,
                                      color: "white",
                                      padding: "0 4px",
                                      borderRadius: "4px",
                                    }}
                                  >
                                    {wordWithoutPunctuation}
                                  </span>
                                  {punctuation + " "}
                                </React.Fragment>
                              );
                            }
                            return word + " ";
                          });
                      })()}
                    </Typography>
                  )}
                </Box>
                {/* Definition and Synonyms Tables */}
                {lessonData.exercises.map((exercise, index) => {
                  if (
                    exercise.type === "definitions" ||
                    exercise.type === "synonyms"
                  ) {
                    return (
                      <Box key={index} sx={{ mb: 4, position: "relative" }}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            mb: 2,
                            mt: 8,
                          }}
                        >
                          {editingExerciseIndex === index ? (
                            <TextField
                              value={editedTitle}
                              onChange={(e) => setEditedTitle(e.target.value)}
                              variant="outlined"
                              size="small"
                              sx={{ fontSize: "1.4rem" }}
                            />
                          ) : (
                            <Typography
                              variant="h6"
                              sx={{ fontWeight: "bold", fontSize: "1.4rem" }}
                            >
                              {exercise.title}
                            </Typography>
                          )}
                          <Box>
                            <Tooltip
                              title={
                                editingExerciseIndex === index
                                  ? "Spara ändringar"
                                  : "Redigera"
                              }
                              placement="top"
                            >
                              <IconButton
                                onClick={() =>
                                  editingExerciseIndex === index
                                    ? handleSaveClick(index)
                                    : handleEditClick(index, exercise)
                                }
                              >
                                {editingExerciseIndex === index ? (
                                  <SaveAsIcon />
                                ) : (
                                  <EditIcon />
                                )}
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Ta bort" placement="top">
                              <IconButton
                                onClick={() => handleDeleteClick(index)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Box>

                        <TableContainer
                          component={Paper}
                          sx={{ borderRadius: "10px" }}
                        >
                          <Table
                            sx={{
                              "& .MuiTableCell-root": { fontSize: "16px" },
                            }}
                          >
                            <TableHead>
                              <TableRow sx={{ backgroundColor: "#f9f9f9" }}>
                                <TableCell>
                                  <strong>Ord</strong>
                                </TableCell>
                                {exercise.type === "definitions" ? (
                                  <TableCell>
                                    <strong>Definition</strong>
                                  </TableCell>
                                ) : (
                                  <>
                                    <TableCell>
                                      <strong>Synonymer</strong>
                                    </TableCell>
                                    <TableCell>
                                      <strong>Motsatsord</strong>
                                    </TableCell>
                                  </>
                                )}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {(editingExerciseIndex === index
                                ? editedContent
                                : exercise.content
                              ).map((item, i) => (
                                <TableRow key={i}>
                                  {editingExerciseIndex === index ? (
                                    <>
                                      <TableCell>
                                        <TextField
                                          value={item.word}
                                          onChange={(e) => {
                                            const newContent = [
                                              ...editedContent,
                                            ];
                                            newContent[i] = {
                                              ...item,
                                              word: e.target.value,
                                            };
                                            setEditedContent(newContent);
                                          }}
                                          variant="outlined"
                                          size="small"
                                          fullWidth
                                        />
                                      </TableCell>
                                      {exercise.type === "definitions" ? (
                                        <TableCell>
                                          <TextField
                                            value={item.definition}
                                            onChange={(e) => {
                                              const newContent = [
                                                ...editedContent,
                                              ];
                                              newContent[i] = {
                                                ...item,
                                                definition: e.target.value,
                                              };
                                              setEditedContent(newContent);
                                            }}
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                          />
                                        </TableCell>
                                      ) : (
                                        <>
                                          <TableCell>
                                            <TextField
                                              value={item.synonyms.join(", ")}
                                              onChange={(e) => {
                                                const newContent = [
                                                  ...editedContent,
                                                ];
                                                newContent[i] = {
                                                  ...item,
                                                  synonyms:
                                                    e.target.value.split(", "),
                                                };
                                                setEditedContent(newContent);
                                              }}
                                              variant="outlined"
                                              size="small"
                                              fullWidth
                                            />
                                          </TableCell>
                                          <TableCell>
                                            <TextField
                                              value={item.antonyms.join(", ")}
                                              onChange={(e) => {
                                                const newContent = [
                                                  ...editedContent,
                                                ];
                                                newContent[i] = {
                                                  ...item,
                                                  antonyms:
                                                    e.target.value.split(", "),
                                                };
                                                setEditedContent(newContent);
                                              }}
                                              variant="outlined"
                                              size="small"
                                              fullWidth
                                            />
                                          </TableCell>
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      <TableCell>{item.word}</TableCell>
                                      {exercise.type === "definitions" ? (
                                        <TableCell>{item.definition}</TableCell>
                                      ) : (
                                        <>
                                          <TableCell>
                                            {item.synonyms.join(", ")}
                                          </TableCell>
                                          <TableCell>
                                            {item.antonyms.join(", ")}
                                          </TableCell>
                                        </>
                                      )}
                                    </>
                                  )}
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    );
                  }

                  // Usage exercises as bulleted list
                  if (exercise.type === "usage") {
                    return (
                      <Box key={index} sx={{ mb: 4 }}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            mb: 2,
                            mt: 8,
                          }}
                        >
                          {editingExerciseIndex === index ? (
                            <TextField
                              value={editedTitle}
                              onChange={(e) => setEditedTitle(e.target.value)}
                              variant="outlined"
                              size="small"
                              sx={{ fontSize: "1.4rem" }}
                            />
                          ) : (
                            <Typography
                              variant="h6"
                              sx={{ fontWeight: "bold", fontSize: "1.4rem" }}
                            >
                              {exercise.title}
                            </Typography>
                          )}
                          <Box>
                            <Tooltip
                              title={
                                editingExerciseIndex === index
                                  ? "Spara ändringar"
                                  : "Redigera"
                              }
                              placement="top"
                            >
                              <IconButton
                                onClick={() =>
                                  editingExerciseIndex === index
                                    ? handleSaveClick(index)
                                    : handleEditClick(index, exercise)
                                }
                              >
                                {editingExerciseIndex === index ? (
                                  <SaveAsIcon />
                                ) : (
                                  <EditIcon />
                                )}
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Ta bort" placement="top">
                              <IconButton
                                onClick={() => handleDeleteClick(index)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Box>

                        <Box
                          sx={{
                            backgroundColor: (theme) =>
                              theme.palette.mode === "dark"
                                ? "#15202b"
                                : "#e0e0e029",
                            borderRadius: "10px",
                            padding: "20px",
                          }}
                        >
                          {editingExerciseIndex === index ? (
                            <Box
                              component="ul"
                              sx={{ listStyle: "none", pl: 0 }}
                            >
                              {editedContent.map((item, i) => (
                                <Box
                                  key={i}
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    mb: 2,
                                    "&:last-child": { mb: 0 },
                                  }}
                                >
                                  <Box
                                    component="li"
                                    sx={{
                                      flex: 1,
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography sx={{ mr: 2 }}>•</Typography>
                                    <TextField
                                      value={item.task}
                                      onChange={(e) => {
                                        const newContent = [...editedContent];
                                        newContent[i] = {
                                          task: e.target.value,
                                        };
                                        setEditedContent(newContent);
                                      }}
                                      variant="outlined"
                                      size="small"
                                      sx={{ ...styles.editingTextField }}
                                      fullWidth
                                      multiline
                                    />
                                  </Box>
                                  <Tooltip title="Ta bort" placement="top">
                                    <IconButton
                                      size="small"
                                      onClick={() =>
                                        handleDeleteListItem(index, i)
                                      }
                                      sx={{ ml: 1 }}
                                    >
                                      <ClearIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                              ))}
                            </Box>
                          ) : (
                            <ul>
                              {exercise.content.map((item, i) => (
                                <li key={i}>
                                  <Typography variant="body1" sx={{ mb: 1 }}>
                                    {item.task}
                                  </Typography>
                                </li>
                              ))}
                            </ul>
                          )}
                        </Box>
                      </Box>
                    );
                  }

                  return null;
                })}
                {/* Images Grid */}
                <Grid
                  container
                  spacing={2}
                  sx={{ mb: 4, justifyContent: "center" }}
                >
                  {lessonData.images.map((image, index) => (
                    <Grid item xs={12} md={6} key={index}>
                      <HoverableImage
                        imageUrl={image.imageUrl}
                        isLoading={isGeneratingImages}
                        loadingIndex={loadingImageIndex}
                        currentIndex={index}
                        userId={user.uid}
                        theme={theme}
                        showSnackbar={showSnackbar}
                        onGenerateClick={() => handleImageButtonClick(index)}
                        onImageUpdate={(newImageUrl) => {
                          setLessonData((prev) => {
                            const newImages = [...prev.images];
                            newImages[index] = { imageUrl: newImageUrl };
                            return { ...prev, images: newImages };
                          });
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default WordCompLesson;
