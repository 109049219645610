import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDropzone } from "react-dropzone";
import {
  Box,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  Dialog,
  Tooltip,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  IconButton,
  CircularProgress,
  Divider,
  Tabs,
  Tab,
} from "@mui/material";
import { CheckCircleOutline } from "@mui/icons-material";
import ExportButton from "./ExportButton";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import {
  doc,
  getDoc,
  updateDoc,
  addDoc,
  collection,
  query,
  where,
  getDocs,
  orderBy,
} from "firebase/firestore";
import { trackLesson } from "./functions";
import VisibilityIcon from "@mui/icons-material/Visibility";

import Quill from "./Quill";
import GradeSelector, { assessmentInstructions } from "./GradeSelector";
import { db } from "./firebase";
import { useSnackbar } from "./SnackbarHandler";
import { TableChart as TableChartIcon } from "@mui/icons-material";
import LanguageSelector from "./LanguageSelector";
import InfoBox from "./InfoBox";
import { callApi } from "./api";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import SaveIcon from "@mui/icons-material/Save";
import { getStyles } from "../index";
import RubricsDialog from "./RubricsDialog";
import { useTheme } from "@mui/material/styles";
import { Grading as GradingIcon } from "@mui/icons-material";
import mammoth from "mammoth";
import GenerateIcon from "@mui/icons-material/AutoAwesome";
import SegmentIcon from "@mui/icons-material/Segment";
import { useNavigate, Link } from "react-router-dom";
import Logger from "./Logger";
import AddIcon from "@mui/icons-material/Add";
import AssessmentIcon from "@mui/icons-material/Assessment";

import { marked } from "marked";
import EditIcon from "@mui/icons-material/Edit";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PrintIcon from "@mui/icons-material/Print";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SchoolIcon from "@mui/icons-material/School";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import LanguageIcon from "@mui/icons-material/Language";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

import ThemeFlowBox from "./ThemeFlowBox";

import { FileUpload } from "@mui/icons-material";

import ProgressBar from "./ProgressBar";
import CustomInputSwitch from "./CustomInputSwitch";

const FormativeComments = ({ user }) => {
  const [educationLevel, setEducationLevel] = useState("Gymnasium");
  const [selectedRubric, setSelectedRubric] = useState("");
  const [language, setLanguage] = useState("svenska");
  const [essayText, setEssayText] = useState("");
  const [isRubricsDialogOpen, setIsRubricsDialogOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [rubrics, setRubrics] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isManualEntry, setIsManualEntry] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [matrisTab, setMatrisTab] = useState(1);
  const [grade, setGrade] = useState("åk9");
  const { showSnackbar } = useSnackbar();
  const [essayName, setEssayName] = useState("");

  const [gradingResults, setGradingResults] = useState(null);
  const [editing, setEditing] = useState(false);
  const [editedResults, setEditedResults] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [reviewed, setReviewed] = useState(false);

  const [viewingRubric, setViewingRubric] = useState(null);
  const [userRubrics, setUserRubrics] = useState([]);
  const [standardRubrics, setStandardRubrics] = useState([]);

  const navigate = useNavigate();
  const theme = useTheme();
  const styles = getStyles(theme);
  const [progress, setProgress] = useState(0);

  const lessonTitleRef = useRef(null);
  const [assessmentText, setAssessmentText] = useState(
    assessmentInstructions[grade],
  );

  useEffect(() => {
    Logger.log("grade: ", grade);
    setAssessmentText(assessmentInstructions[grade]);
  }, [grade]);
  useEffect(() => {
    Logger.log("selectedRubric: ", selectedRubric);
  }, [selectedRubric]);

  const [quillContent, setQuillContent] = useState("");

  // 2. Add this useEffect to handle content updates
  useEffect(() => {
    if (gradingResults?.results) {
      setQuillContent(gradingResults.results);
    }
  }, [gradingResults?.results]);
  const handleMatrisTabChange = (event, newValue) => {
    setMatrisTab(newValue);
  };
  const fetchRubrics = async () => {
    try {
      const rubricsCollection = collection(db, "rubrics");
      // Fetch user-specific rubrics
      const userRubricsQuery = query(
        rubricsCollection,
        where("formativeComments", "==", true),
        where("userId", "==", user.uid),
      );
      const userRubricsSnapshot = await getDocs(userRubricsQuery);
      const userRubricsList = userRubricsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setUserRubrics(userRubricsList);
      // Fetch standard rubrics
      const standardRubricsQuery = query(
        rubricsCollection,
        where("custom", "==", true),
      );
      const standardRubricsSnapshot = await getDocs(standardRubricsQuery);
      const standardRubricsList = standardRubricsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setStandardRubrics(standardRubricsList);
      // Set first rubric as selected if there are any rubrics
      if (userRubricsList.length > 0) {
        setSelectedRubric(userRubricsList[0].id);
      } else if (standardRubricsList.length > 0) {
        setSelectedRubric(standardRubricsList[0].id);
      }
    } catch (error) {
      console.error("Error fetching rubrics:", error);
      showSnackbar(
        "Ett fel uppstod vid hämtning av bedömningsmatriser",
        "error",
      );
    }
  };

  // Use it in useEffect
  useEffect(() => {
    fetchRubrics();
  }, [user.uid, showSnackbar]);

  // Helper function to get the selected rubric object
  const getSelectedRubric = useCallback(() => {
    return (
      userRubrics.find((r) => r.id === selectedRubric) ||
      standardRubrics.find((r) => r.id === selectedRubric)
    );
  }, [selectedRubric, userRubrics, standardRubrics]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (!file) return;

      const isSupported = [".docx", ".doc"].some((ext) =>
        file.name.toLowerCase().endsWith(ext),
      );

      if (!isSupported) {
        showSnackbar(
          "Filformatet stöds inte. Använd .docx eller .doc",
          "error",
        );
        return;
      }

      // Check file size (e.g., 5MB limit)
      const maxSize = 5 * 1024 * 1024; // 5MB in bytes
      if (file.size > maxSize) {
        showSnackbar("Filen är för stor. Maximal storlek är 5MB", "error");
        return;
      }

      setFile(file);
    },
    [showSnackbar],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
    },
    multiple: false,
  });

  const handlePrint = () => {
    const printWindow = window.open("", "_blank");
    // Create a sanitized version of the Quill content
    const contentToPrint = editedResults || gradingResults.results || "";

    printWindow.document.write(`
      <html>
        <head>
          <title>Bedömning av text från Studera.ai</title>
          <style>
    @page {
      size: A4;
      margin: 0cm 0cm 2cm 0cm;
      marks: none;
    }

          html {
      padding: 0cm 2cm 2cm 2cm !important;
      margin: 0 !important;
      margin-top: 2cm !important;
    }

    body { 
      margin: 0 !important;
      -webkit-print-color-adjust: exact !important;
      print-color-adjust: exact !important;
    }
      
            body { font-family: Arial, sans-serif; line-height: 1.6; color: #333; padding: 20px; }
            h1 { color: #2c3e50; }
            .ql-editor { padding: 0; }
            .ql-editor * { font-family: Arial, sans-serif; }
          </style>
        </head>
        <body>
          <h1>Bedömningsresultat</h1>
          <div class="ql-editor">
            ${contentToPrint}
          </div>
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };

  const simulateProgress = (startProgress, endProgress, duration) => {
    const stepSize = 0.5;
    const steps = (endProgress - startProgress) / stepSize;
    const stepDuration = duration / steps;
    let currentProgress = startProgress;

    const intervalId = setInterval(() => {
      currentProgress += stepSize;
      if (currentProgress >= endProgress) {
        clearInterval(intervalId);
      } else {
        setProgress(currentProgress);
      }
    }, stepDuration);

    return () => clearInterval(intervalId);
  };

  const extractTextFromWordDocument = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async (event) => {
        try {
          const arrayBuffer = event.target.result;
          const result = await mammoth.extractRawText({ arrayBuffer });
          resolve(result.value);
        } catch (error) {
          reject(error);
        }
      };
      reader.onerror = (error) => reject(error);
      reader.readAsArrayBuffer(file);
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setProgress(0);
    let content = "";
    let fileName = "";

    try {
      if (isManualEntry) {
        if (!essayText) {
          throw new Error("Vänligen ange text");
        }
        if (!essayName) {
          throw new Error("Vänligen ange namn på text");
        }
        content = essayText;
        fileName = essayName;
      } else {
        if (!file) {
          throw new Error("Vänligen ladda upp en fil");
        }
        content = await extractTextFromWordDocument(file);
        fileName = file.name;
      }

      const selectedRubricObject = [...userRubrics, ...standardRubrics].find(
        (rubric) => rubric.id === selectedRubric,
      );

      if (!selectedRubricObject) {
        throw new Error("Vänligen välj en bedömningsmatris");
      }

      const prompt = `Ge formativa bedömningar till en elev baserat på följande text baserat på den angivna bedömningsmatrisen. 
      Skriv bedömningen på ${language}. 
      Bedömningskriterier för denna årskurs: ${assessmentText}
      Ge en analys för varje kriterium, förslag på förbättringar och ett betyg. Formatera svaret som HTML med följande struktur:


<h2>Kriterieanalys</h2>

<h3>[Kriteriumets namn]</h3>
<p><strong>Analys:</strong> [Analys av detta kriterium på 3-4 meningar]</p>
<p><strong>Förbättringsförslag:</strong> [Förslag på förbättring på 3-4 meningar]</p>
[Upprepa för varje kriterium]

<hr/>

<h2>Bedömningsresultat</h2>

<h3>Övergripande analys</h3>
<p>[Ange övergripande analys av texten här]</p>

<h3>Positiv feedback</h3>
<p>[Ange positiv feedback om textens styrkor här]</p>

<h3>Förbättringsplan</h3>
<ul>
  <li>[Förbättringsåtgärd 1]</li>
  <li>[Förbättringsåtgärd 2]</li>
  <li>[Förbättringsåtgärd 3]</li>
  <li>[Förbättringsåtgärd 4]</li>
  <li>[Förbättringsåtgärd 5]</li>
</ul>

<hr/>

<h2>Felrapport</h2>

<h3>Grammatikfel</h3>
<p>[Lista med grammatikfel och förslag på korrigeringar]</p>

<h3>Meningsbyggnadsfel</h3>
<p>[Beskrivning av problem med meningsbyggnad]</p>

<h3>Sammanhangsproblem</h3>
<p>[Identifierade problem med textens sammanhang]</p>

<h3>Tydlighetsproblem</h3>
<p>[Områden där texten kan förbättras för ökad tydlighet]</p>

<h3>Logiska inkonsekvenser</h3>
<p>[Identifierade logiska problem eller motsägelser i texten]</p>

<h3>Stavfel</h3>
<p>[Lista med stavfel och förslag på korrigeringar]</p>

Bedömningsmatris:
${JSON.stringify(selectedRubricObject)}

Text:
${content}`;

      const stopSimulation = simulateProgress(0, 90, 15000);

      let response = await callApi(
        [
          {
            role: "system",
            content:
              "Du är en hjälpsam AI-assistent som bedömer texter och ger formativa omdömen till elever.",
          },
          { role: "user", content: prompt },
        ],
        10000,
        true,
        user.uid,
      );

      stopSimulation();
      setProgress(95);

      Logger.log(
        "Response in FormativeComments: ",
        JSON.stringify(response, null, 2),
      );
      let messageContent = response.data.choices[0].message.content.trim();

      if (messageContent.startsWith("```html")) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith("```")) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      Logger.log(messageContent);

      // Create the essay data object
      const essayData = {
        userId: user.uid,
        content: content,
        results: messageContent,
        fileName: fileName,
        educationLevel: grade,
        language: language,
        rubricId: selectedRubric,
        rubricName: selectedRubricObject.name,
        createdAt: new Date(),
      };

      // Save the results to the database
      const docRef = await addDoc(collection(db, "essays"), essayData);
      setProgress(100);
      showSnackbar("Texten har bedömts och sparats", "success");

      await trackLesson(user.uid, "FormativeComments.js", null, null);

      // Set the grading results
      setGradingResults({
        ...essayData,
        id: docRef.id,
      });
      setEditedResults(messageContent);

      setTimeout(() => {
        lessonTitleRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, 100);
    } catch (error) {
      console.error("Error grading essay:", error);
      showSnackbar(
        error.message || "Ett oväntat fel uppstod. Vänligen försök igen.",
        "error",
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleEdit = () => {
    setEditing(true);
  };

  const handleSave = async () => {
    try {
      const docRef = doc(db, "essays", gradingResults.id);
      await updateDoc(docRef, { results: editedResults });
      setGradingResults({ ...gradingResults, results: editedResults });
      setEditing(false);
      showSnackbar("Ändringar sparade.", "success");
    } catch (error) {
      console.error("Error updating essay data:", error);
    }
  };

  const handleCancel = () => {
    setEditedResults(gradingResults.results);
    setEditing(false);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleCopy = async () => {
    const plainText = gradingResults.results;
    // Convert to HTML if needed, assuming gradingResults contains markdown
    const htmlContent = marked.parse(plainText);

    try {
      // Try to copy with rich text formatting first
      await navigator.clipboard.write([
        new ClipboardItem({
          "text/plain": new Blob([plainText], { type: "text/plain" }),
          "text/html": new Blob([htmlContent], { type: "text/html" }),
        }),
      ]);
      showSnackbar("Kopierad till urklipp!", "success");
    } catch (err) {
      console.error("Fel vid formaterad kopiering: ", err);

      // Fallback to plain text if rich copy fails
      try {
        await navigator.clipboard.writeText(plainText);
        showSnackbar("Kopierad som vanlig text!", "success");
      } catch (fallbackErr) {
        console.error("Fel vid vanlig kopiering: ", fallbackErr);
        showSnackbar("Kunde inte kopiera texten", "error");
      }
    }
  };
  const handleReviewToggle = async () => {
    try {
      const docRef = doc(db, "essays", gradingResults.id);
      const newReviewedState = !reviewed;
      await updateDoc(docRef, { reviewed: newReviewedState });
      setReviewed(newReviewedState);

      if (newReviewedState) {
        showSnackbar("Omdöme markerat som granskat.", "success");
      } else {
        showSnackbar("Omdöme markerat som icke granskat.", "info");
      }
    } catch (error) {
      console.error("Error updating review status:", error);
      showSnackbar(
        "Ett fel uppstod vid uppdatering av granskningsstatus.",
        "error",
      );
    }
  };

  const formatDate = (date) => {
    if (date instanceof Date) {
      return date.toLocaleString();
    } else if (date && typeof date.toDate === "function") {
      return date.toDate().toLocaleString();
    } else {
      return "Invalid Date";
    }
  };

  return (
    <>
      <Box sx={styles.container}>
        <Box
          sx={{
            ...styles.innerContainer,
            width: { xs: "100%", md: "100%" },
            margin: "0 auto",
          }}
        >
          <Box
            sx={{
              ...styles.buttonsContainer,
              ...styles.additionalButtonsContainer,
            }}
          >
            <Button
              variant="purp"
              sx={{ ...styles.tightButton }}
              component={Link}
              startIcon={<AddIcon />}
              to="/app/matris/ny"
              sx={{ display: "none" }}
            >
              Ny matris
            </Button>

            <Button
              variant="purp"
              sx={{ ...styles.tightButton }}
              component={Link}
              startIcon={<SegmentIcon />}
              to="/app/uppsatser"
            >
              Dina bedömningar
            </Button>
          </Box>

          <Typography
            variant="h1"
            gutterBottom
            sx={{
              fontWeight: "bold",
              fontSize: "1.8em",
              mb: 3,
              mt: 5,
              textAlign: "center",
            }}
          >
            Formativ bedömning
          </Typography>
          <InfoBox
            icon={GradingIcon}
            title="Powerpoint Instructions"
            instructions={[
              "Skapa eller välj en fördefinierad bedömningsmatris",
              "Ladda upp eller skriv in valfri text",
              "Granska analysen och få tillgång till ditt eget arkiv",
            ]}
            theme={theme}
            styles={styles}
          />
          <Box sx={{ ...styles.headlineLessonBox, mt: 8 }}>
            <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
              1. Välj bedömningsmatris
            </Typography>
          </Box>
          <Box
            sx={{
              ...styles.freshBox,
              padding: "0px",
            }}
          >
            <Box sx={{ width: "100%", mb: 4 }}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  background:
                    theme.palette.mode === "dark" ? "#22303c" : "#f9f9fa",
                  borderTopLeftRadius: "9px",
                  borderTopRightRadius: "9px",
                }}
              >
                <Tabs
                  value={matrisTab}
                  onChange={handleMatrisTabChange}
                  sx={{
                    "& .MuiTab-root": {
                      padding: "20px",
                      textTransform: "none",
                      fontSize: "1rem",
                    },
                  }}
                >
                  <Tab
                    icon={<TableChartIcon />}
                    iconPosition="start"
                    label="Mina matriser"
                  />
                  <Tab
                    icon={<CorporateFareIcon />}
                    iconPosition="start"
                    label="Standard matriser"
                  />
                </Tabs>
              </Box>
              <Box sx={{ padding: "30px" }}>
                {matrisTab === 0 && userRubrics.length > 0 && (
                  <Grid container spacing={3}>
                    {userRubrics.map((rubric) => (
                      <Grid item xs={12} md={4} key={rubric.id}>
                        <Box
                          sx={{
                            position: "relative",
                            "&:hover .visibility-icon": {
                              opacity: 1,
                            },
                          }}
                        >
                          <ThemeFlowBox
                            icon={RadioButtonUncheckedIcon}
                            title={rubric.name}
                            isSelected={selectedRubric === rubric.id}
                            onClick={() => setSelectedRubric(rubric.id)}
                            diffView={true}
                          />
                          <Tooltip title="Visa matris" placement="top">
                            <IconButton
                              size="small"
                              className="visibility-icon"
                              onClick={(e) => {
                                e.stopPropagation();
                                setViewingRubric(rubric);
                              }}
                              sx={{
                                position: "absolute",
                                top: 5,
                                right: 5,
                                color:
                                  theme.palette.mode === "dark"
                                    ? "rgba(255, 255, 255, 0.7)"
                                    : "rgba(0, 0, 0, 0.54)",
                                opacity: 0,
                                transition: "opacity 0.2s ease-in-out",
                                "&:hover": {
                                  color: "#8b5cf6",
                                },
                              }}
                            >
                              <VisibilityIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                )}

                {matrisTab === 1 && standardRubrics.length > 0 && (
                  <Grid container spacing={3}>
                    {standardRubrics.map((rubric) => {
                      // Map rubric names to subtitles
                      const subtitles = {
                        Förklarande:
                          "Förklara varför något händer eller hur något fungerar",
                        Beskrivande:
                          "Ge en detaljerad beskrivning av ämnet eller fenomenet",
                        Argumenterande:
                          "Övertyga läsaren med argument och bevis",
                        Berättande:
                          "Berätta en historia eller återge en händelse",
                        Instruerande: "Visa hur man gör något steg för steg",
                        Informerande:
                          "Presentera fakta och information om ett ämne",
                      };

                      return (
                        <Grid item xs={12} md={4} key={rubric.id}>
                          <Box
                            sx={{
                              position: "relative",
                              "&:hover .visibility-icon": {
                                opacity: 1,
                              },
                            }}
                          >
                            <ThemeFlowBox
                              icon={RadioButtonUncheckedIcon}
                              title={rubric.name}
                              subtitle={subtitles[rubric.name] || ""}
                              isSelected={selectedRubric === rubric.id}
                              onClick={() => setSelectedRubric(rubric.id)}
                              diffView={true}
                            />
                            <Tooltip title="Visa matris" placement="top">
                              <IconButton
                                size="small"
                                className="visibility-icon"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setViewingRubric(rubric);
                                }}
                                sx={{
                                  position: "absolute",
                                  top: 5,
                                  right: 5,
                                  color:
                                    theme.palette.mode === "dark"
                                      ? "rgba(255, 255, 255, 0.7)"
                                      : "rgba(0, 0, 0, 0.54)",
                                  opacity: 0,
                                  transition: "opacity 0.2s ease-in-out",
                                  "&:hover": {
                                    color: "#8b5cf6",
                                  },
                                }}
                              >
                                <VisibilityIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Grid>
                      );
                    })}
                  </Grid>
                )}
              </Box>{" "}
            </Box>

            <Typography
              variant="formLabelSinUnderline"
              sx={{
                fontWeight: "500",
                display: "block",
                mb: 1,
                mt: 4,
                textAlign: "center",
              }}
            >
              Eller skapa en egen:
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "12px",
                width: "100%",
              }}
            >
              <Button
                variant="purp"
                color="primary"
                startIcon={<AddIcon />}
                onClick={() => setIsRubricsDialogOpen(true)}
                sx={{ mt: 2, mb: 8 }}
              >
                Skapa bedömningsmatris
              </Button>

              <RubricsDialog
                open={isRubricsDialogOpen}
                onClose={() => setIsRubricsDialogOpen(false)}
                user={user}
                onRubricsUpdated={fetchRubrics}
              />

              <RubricsDialog
                open={Boolean(viewingRubric)}
                onClose={() => setViewingRubric(null)}
                user={user}
                onRubricsUpdated={fetchRubrics}
                initialData={viewingRubric}
              />
            </div>
          </Box>

          <Box sx={{ ...styles.headlineLessonBox, mt: 12 }}>
            <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
              2. Specifika bedömningsinstruktioner
            </Typography>
          </Box>
          <Box sx={{ ...styles.freshBox, padding: "40px" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "500", mt: 0 }}
                >
                  Jag vill ha bedömningen på:
                </Typography>
                <Box sx={{ mt: 1, mb: 2 }}>
                  <LanguageSelector
                    language={language}
                    setLanguage={setLanguage}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "500", mb: 1, mt: 0 }}
                >
                  Årskurs:
                </Typography>
                <Box sx={{ mt: 0.5, mb: 2 }}>
                  <GradeSelector
                    grade={grade}
                    setGrade={setGrade}
                    showHigherEducation={true}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} md={12}>
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "500", mb: 1, mt: 2 }}
                >
                  Texten kommer bedömas utefter (anpassa utifrån behov):
                </Typography>
                <TextField
                  multiline
                  rows={4}
                  fullWidth
                  value={assessmentText}
                  onChange={(e) => setAssessmentText(e.target.value)}
                  sx={{ mt: 0.5 }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ ...styles.headlineLessonBox, mt: 12 }}>
            <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
              3. Ladda upp dokument eller klistra in text
            </Typography>
          </Box>
          <Box sx={{ ...styles.freshBox, padding: "30px" }}>
            <form onSubmit={handleSubmit}>
              <Grid
                container
                spacing={2}
                sx={{
                  justifyContent: "center", // Centers horizontally
                  alignItems: "center", // Centers vertically
                }}
              >
                <Grid item xs={12} sm={9}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      mb: 4,
                      mt: 2,
                    }}
                  >
                    <CustomInputSwitch
                      selectedIndex={isManualEntry ? 1 : 0}
                      onIndexChange={(index) => setIsManualEntry(index === 1)}
                      options={["Ladda upp fil", "Skriv in text"]}
                      theme={theme}
                    />
                  </Box>

                  {isManualEntry ? (
                    <>
                      <TextField
                        multiline
                        rows={10}
                        fullWidth
                        value={essayText}
                        onChange={(e) => setEssayText(e.target.value)}
                        placeholder="Skriv eller klistra in texten här"
                        sx={{ mb: 2 }}
                      />
                      <Box>
                        <Typography
                          variant="formLabelSinUnderline"
                          sx={{ fontWeight: "500", mb: 1 }}
                        >
                          Namn:
                        </Typography>
                      </Box>
                      <TextField
                        fullWidth
                        value={essayName}
                        onChange={(e) => setEssayName(e.target.value)}
                        placeholder="Namn på texten"
                        required
                        sx={{ mb: 2, mt: 1 }}
                      />
                    </>
                  ) : (
                    <Box {...getRootProps()}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",

                          minHeight: "300px",
                          width: "100%",
                          borderRadius: "16px",
                          bgcolor: isDragActive
                            ? theme.palette.mode === "dark"
                              ? "rgba(255, 255, 255, 0.1)"
                              : "rgba(0, 0, 0, 0.05)"
                            : theme.palette.mode === "dark"
                              ? "rgba(255, 255, 255, 0.05)"
                              : "rgba(0, 0, 0, 0.02)",
                          border: "2px dashed",
                          borderColor: isDragActive
                            ? "#8b5cf6"
                            : theme.palette.mode === "dark"
                              ? "rgba(255, 255, 255, 0.1)"
                              : "rgba(0, 0, 0, 0.1)",
                          transition: "all 0.3s ease-in-out",
                          "&:hover": {
                            borderColor: "#8b5cf6",
                            bgcolor:
                              theme.palette.mode === "dark"
                                ? "rgba(255, 255, 255, 0.08)"
                                : "rgba(0, 0, 0, 0.04)",
                          },
                        }}
                      >
                        <input {...getInputProps()} />

                        <CloudUploadIcon
                          sx={{
                            fontSize: 60,
                            color: "#8b5cf6",
                            mb: 3,
                            transform: isDragActive ? "scale(1.1)" : "scale(1)",
                            transition: "transform 0.2s ease-in-out",
                          }}
                        />
                        <Typography
                          variant="h6"
                          sx={{ mb: 1, fontWeight: 500 }}
                        >
                          {isDragActive
                            ? "Släpp filen här"
                            : "Dra och släpp fil här"}
                        </Typography>
                        <Typography color="text.secondary" sx={{ mb: 3 }}>
                          eller
                        </Typography>
                        <Button
                          variant="print"
                          startIcon={<FileUpload />}
                          onClick={(e) => {
                            e.stopPropagation();
                            const fileInput =
                              document.querySelector('input[type="file"]');
                            if (fileInput) fileInput.click();
                          }}
                        >
                          Välj fil
                        </Button>
                      </Box>
                    </Box>
                  )}

                  {file && (
                    <Box
                      sx={{ ...styles.successBox, minHeight: "180px", mt: 4 }}
                    >
                      <CheckCircleOutline
                        sx={{ fontSize: 60, color: "success.main", mb: 3 }}
                      />
                      <Typography
                        variant="h6"
                        sx={{
                          color: "success.main",
                          fontWeight: 500,
                          textAlign: "center",
                          width: "80%",
                          margin: "0 auto",
                          mb: 2,
                        }}
                      >
                        Vald fil: {file.name}
                      </Typography>
                    </Box>
                  )}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "12px",
                      width: "100%",
                    }}
                  >
                    <Button
                      type="submit"
                      variant={
                        theme.palette.mode === "light" ? "purp" : "contained"
                      }
                      color="primary"
                      sx={{ mt: 7, mb: 3 }}
                      disabled={isLoading}
                      startIcon={
                        !isLoading ? (
                          <GenerateIcon style={{ color: "#fff" }} />
                        ) : (
                          <CircularProgress size={16} sx={{ color: "#fff" }} />
                        )
                      }
                    >
                      {isLoading ? "Analyserar texten..." : "Analysera text"}
                    </Button>
                  </div>

                  {isLoading && (
                    <Grid item xs={12} sx={{ mt: 6 }}>
                      <ProgressBar
                        progress={progress}
                        label="Analyserar text  — tar 30-60 sekunder..."
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </form>
          </Box>

          {gradingResults && (
            <>
              <Box
                ref={lessonTitleRef}
                sx={{ ...styles.headlineLessonBox, mt: 12 }}
              >
                <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
                  3. Granska resultat
                </Typography>
              </Box>
              <Box sx={{ ...styles.freshBox, padding: "30px" }}>
                <Box sx={styles.lessonControlPanel}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      px: 1,
                    }}
                  >
                    <Box>
                      <Button
                        variant="print"
                        startIcon={<EditIcon />}
                        onClick={handleEdit}
                        sx={{ ...styles.softerBtn, display: "none" }}
                      >
                        Redigera
                      </Button>
                      <Box sx={{ display: "inline-block" }}>
                        <ExportButton
                          content={editedResults}
                          styles={styles}
                          bigButton={true}
                        />
                      </Box>
                      <Button
                        variant="print"
                        color="primary"
                        onClick={handlePrint}
                        startIcon={<PrintIcon />}
                        sx={{ ml: 2 }}
                      >
                        Skriv ut
                      </Button>

                      <Button
                        variant="print"
                        startIcon={<ContentCopyIcon />}
                        onClick={handleCopy}
                        sx={{ ml: 2 }}
                      >
                        Kopiera
                      </Button>
                    </Box>
                    <Box>
                      <Button
                        variant={reviewed ? "success" : "print"}
                        startIcon={
                          <CheckCircleIcon
                            sx={{
                              color: reviewed ? "white" : "text.secondary",
                            }}
                          />
                        }
                        onClick={handleReviewToggle}
                        sx={{ ml: 2 }}
                      >
                        {reviewed ? "Granskad" : "Markera som granskad"}
                      </Button>
                    </Box>
                  </Box>
                </Box>

                <Box sx={{}}>
                  <Box sx={{}}>
                    <Quill
                      value={editedResults}
                      onChange={(newContent) => {
                        setEditedResults(newContent);
                      }}
                      theme={theme}
                    />
                  </Box>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default FormativeComments;
