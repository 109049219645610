import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  IconButton,
  Tooltip,
  Paper,
} from "@mui/material";
import { OptionButton } from "../StyledComponents";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import Looks3Icon from "@mui/icons-material/Looks3";
import Looks4Icon from "@mui/icons-material/Looks4";
import LightbulbIcon from "@mui/icons-material/LightbulbOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import CheckCircleIcon from "@mui/icons-material/Check";
import { alpha, lighten } from "@mui/material/styles";
import { AnimatedHint, AnimatedQuestion } from "./Animations";
import QuizResults from "./QuizResults";

import { TextField, CircularProgress } from "@mui/material";
import { East as ArrowIcon } from "@mui/icons-material";
import { callApi } from "./api";

import Logger from "./Logger";

const LessonQuizDisplay = ({
  quizData,
  theme,
  styles,
  userId,
  setLessonData,
  viewMode,
}) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [results, setResults] = useState([]);
  const [showSummary, setShowSummary] = useState(false);
  const [showQuestion, setShowQuestion] = useState(true);
  const [revealedHints, setRevealedHints] = useState({});
  const [animatingHintIndex, setAnimatingHintIndex] = useState(-1);
  const [isValidating, setIsValidating] = useState(false);
  const [answer, setAnswer] = useState("");

  const allowedHints = 3;

  const currentQuestion = quizData[currentQuestionIndex];

  const [showDeleteIcon, setShowDeleteIcon] = useState(false);

  Logger.log("LessonQuizDisplay Quiz: ", JSON.stringify(quizData, null, 2));

  const handleDeleteQuiz = () => {
    setLessonData((prev) => ({
      ...prev,
      quiz: null,
    }));
  };

  const getTotalHintsUsed = () => {
    return Object.values(revealedHints).reduce(
      (total, hints) => total + hints.length,
      0,
    );
  };

  const handleRevealHint = (hintIndex) => {
    const totalUsed = getTotalHintsUsed();
    if (totalUsed >= allowedHints) {
      return;
    }

    if (currentQuestion.hints && hintIndex >= currentQuestion.hints.length) {
      return;
    }

    setAnimatingHintIndex(hintIndex);
    setTimeout(() => setAnimatingHintIndex(-1), 500);

    const currentRevealed = revealedHints[currentQuestionIndex] || [];
    setRevealedHints({
      ...revealedHints,
      [currentQuestionIndex]: [...currentRevealed, hintIndex],
    });
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    const isCorrect = option === currentQuestion.correct;
    const updatedResults = [...results];
    updatedResults[currentQuestionIndex] = {
      ...currentQuestion,
      selectedOption: option,
      isCorrect,
      revealedHints: revealedHints[currentQuestionIndex] || [],
    };
    setResults(updatedResults);
  };

  const handleNextQuestion = async () => {
    if (currentQuestion.type === "exam") {
      if (!answer.trim()) {
        return;
      }
      setIsValidating(true);
      try {
        const prompt = `
        Du är en lärare som bedömer elevsvar. Analysera följande:
        
        Fråga: ${currentQuestion.question}
        Korrekt svar: ${currentQuestion.answer}
        Elevsvar: "${answer}"
        
        Bedöm om elevsvaret är korrekt baserat på innehåll och koncept, inte exakt matchning.
        Svara i följande JSON-format:
        {
          "isCorrect": true/false,
          "explanation": "Kort förklaring av bedömningen"
        }
      `;
        const response = await callApi(
          [
            {
              role: "system",
              content:
                "Du är en expert på att bedöma elevsvar. Svara endast med JSON enligt det specificerade formatet.",
            },
            {
              role: "user",
              content: prompt,
            },
          ],
          6000,
          true,
          userId,
        );

        let result = response.data.choices[0].message.content.trim();
        if (result.startsWith("```json")) {
          result = result.slice(7, -3).trim();
        } else if (result.startsWith("```")) {
          result = result.slice(3, -3).trim();
        }

        const validation = JSON.parse(result);
        proceedToNextQuestion(validation.isCorrect, validation.explanation);
      } catch (error) {
        console.error("Error validating answer:", error);
      } finally {
        setIsValidating(false);
      }
    } else {
      if (!selectedOption) {
        return;
      }
      setSelectedOption(null);
      setShowQuestion(false);
      setTimeout(() => {
        if (currentQuestionIndex < quizData.length - 1) {
          setCurrentQuestionIndex((prev) => prev + 1);
          setShowQuestion(true);
        } else {
          setShowSummary(true);
        }
      }, 300);
    }
  };

  const proceedToNextQuestion = (isCorrect, explanation) => {
    setShowQuestion(false);
    setSelectedOption(null);

    setTimeout(() => {
      if (currentQuestionIndex < quizData.length - 1) {
        const updatedResults = [...results];
        updatedResults[currentQuestionIndex] = {
          ...quizData[currentQuestionIndex],
          userAnswer: answer,
          isCorrect,
          explanation,
          revealedHints: revealedHints[currentQuestionIndex] || [],
        };
        setResults(updatedResults);

        setCurrentQuestionIndex((prev) => prev + 1);
        setShowQuestion(true);
        setAnswer("");
      } else {
        const finalResults = [...results];
        finalResults[currentQuestionIndex] = {
          ...quizData[currentQuestionIndex],
          userAnswer: answer,
          isCorrect,
          explanation,
          revealedHints: revealedHints[currentQuestionIndex] || [],
        };

        setResults(finalResults);
        setShowSummary(true);
      }
    }, 300);
  };

  const handleRestart = () => {
    setCurrentQuestionIndex(0);
    setSelectedOption(null);
    setResults([]);
    setShowSummary(false);
    setRevealedHints({});
    setShowQuestion(true);
  };

  if (showSummary) {
    return (
      <Box sx={{ mt: 4, mb: 4 }}>
        <QuizResults
          results={results}
          userId={userId}
          theme={theme}
          styles={styles}
          setShowSummary={setShowSummary}
          insideLessonQuiz={true}
        />
        <Box sx={{ width: "300px", margin: "0 auto" }}>
          <Button
            variant="purp"
            onClick={handleRestart}
            sx={{ mt: 6, width: "100%", mb: 6 }}
          >
            Börja om quiz
          </Button>
        </Box>
      </Box>
    );
  }

  return (
    <Paper
      sx={{
        p: 4,
        mb: 10,
        borderRadius: 4,
        position: "relative",
        backgroundColor: theme.palette.background.paperHover,
        backgroundImage: "none",
      }}
      onMouseEnter={() => setShowDeleteIcon(true)}
      onMouseLeave={() => setShowDeleteIcon(false)}
    >
      {showDeleteIcon && !viewMode && (
        <Tooltip title="Ta bort quiz">
          <IconButton
            onClick={handleDeleteQuiz}
            sx={{
              position: "absolute",
              top: 12,
              right: 12,
              zIndex: 2,
            }}
          >
            <DeleteOutlineIcon />
          </IconButton>
        </Tooltip>
      )}

      <Tooltip
        title={`Du har ${allowedHints - getTotalHintsUsed()} ledtrådar kvar`}
      >
        <Box
          sx={{
            position: "absolute",
            top: 20,
            right: 20,

            gap: 1,
            display: showDeleteIcon && !viewMode ? "none" : "flex",
          }}
        >
          {Array.from({ length: allowedHints }).map((_, index) => {
            const totalUsed = getTotalHintsUsed();
            const elasticPop = {
              transform:
                animatingHintIndex === index
                  ? "scale(1.5) translateY(-10px)"
                  : "scale(1) translateY(0)",
              opacity: totalUsed > index ? 0.3 : 1,
              transition: "all 1.5s cubic-bezier(0.175, 0.885, 0.32, 1.275)",
            };

            return (
              <Box
                key={index}
                sx={{
                  ...elasticPop,
                  color:
                    totalUsed > index
                      ? theme.palette.text.disabled
                      : lighten(theme.palette.text.main, 0.5),
                }}
              >
                <LightbulbIcon sx={{ fontSize: 18 }} />
              </Box>
            );
          })}
        </Box>
      </Tooltip>

      <Typography
        variant="h5"
        sx={{ mb: 3, fontSize: "1rem" }}
        color="text.secondary"
      >
        Fråga {currentQuestionIndex + 1} av {quizData.length}
      </Typography>

      <AnimatedQuestion show={showQuestion}>
        <Typography variant="h6" sx={{ mb: 4, fontWeight: "600" }}>
          {currentQuestion.question}
        </Typography>

        <Box sx={{}}>
          {currentQuestion.type === "exam" ? (
            <Box sx={{ width: "100%", mt: 1, mb: 1 }}>
              <TextField
                fullWidth
                multiline
                rows={4}
                value={answer}
                onChange={(e) => setAnswer(e.target.value)}
                placeholder="Skriv ditt svar här..."
                variant="outlined"
                sx={{
                  "& .MuiInputBase-root": {
                    fontWeight: "500",
                    ...styles.editingTextField?.["& .MuiInputBase-root"],
                  },
                }}
              />
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                mt: 4,
                mb: 8,
                flexDirection: { xs: "column", sm: "row" },
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              {currentQuestion.options?.map((option, index) => (
                <OptionButton
                  key={index}
                  variant={selectedOption === option ? "contained" : "outlined"}
                  onClick={() => handleOptionSelect(option)}
                >
                  {index === 0 && (
                    <LooksOneIcon
                      sx={{ marginRight: "8px", color: "#8b5cf6" }}
                    />
                  )}
                  {index === 1 && (
                    <LooksTwoIcon
                      sx={{ marginRight: "8px", color: "#8b5cf6" }}
                    />
                  )}
                  {index === 2 && (
                    <Looks3Icon sx={{ marginRight: "8px", color: "#8b5cf6" }} />
                  )}
                  {index === 3 && (
                    <Looks4Icon sx={{ marginRight: "8px", color: "#8b5cf6" }} />
                  )}
                  {option}
                </OptionButton>
              ))}
            </Box>
          )}
        </Box>
      </AnimatedQuestion>

      {currentQuestion.hints && currentQuestion.hints.length > 0 && (
        <Box sx={{ mt: 4, mb: 2 }}>
          {currentQuestion.hints.map((hint, index) => {
            const revealedCount =
              revealedHints[currentQuestionIndex]?.length || 0;
            const isRevealed =
              revealedHints[currentQuestionIndex]?.includes(index);
            const isNextToReveal = index === revealedCount;

            if (!isRevealed && !isNextToReveal) return null;

            return (
              <Box key={index}>
                {isRevealed && (
                  <AnimatedHint isRevealed={isRevealed}>
                    <Box
                      sx={{
                        p: 2,
                        mb: 2,
                        bgcolor: alpha(
                          theme.palette.primary.main,
                          theme.palette.mode === "dark"
                            ? 0.15 + index * 0.1
                            : 0.08 + index * 0.05,
                        ),
                        borderRadius: "8px",
                        border: `1px solid ${theme.palette.primary.main}40`,
                        display: "flex",
                        alignItems: "flex-start",
                        gap: 1.5,
                      }}
                    >
                      <CheckCircleIcon
                        sx={{ color: theme.palette.primary.main, mt: "2px" }}
                      />
                      <Typography sx={{ flex: 1 }}>
                        <Box component="span" sx={{ fontWeight: 700, mr: 1 }}>
                          Ledtråd {index + 1}:
                        </Box>
                        {hint}
                      </Typography>
                    </Box>
                  </AnimatedHint>
                )}
              </Box>
            );
          })}
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 4,
        }}
      >
        {currentQuestion.hints && currentQuestion.hints.length > 0 && (
          <Button
            variant="print"
            onClick={() =>
              handleRevealHint(revealedHints[currentQuestionIndex]?.length || 0)
            }
            startIcon={<LightbulbIcon />}
            disabled={getTotalHintsUsed() >= allowedHints}
          >
            {!revealedHints[currentQuestionIndex] ||
            revealedHints[currentQuestionIndex]?.length === 0
              ? "Visa ledtråd"
              : "Visa en till"}
          </Button>
        )}

        <Button
          variant={
            currentQuestionIndex === quizData.length - 1 ? "success" : "purp"
          }
          onClick={handleNextQuestion}
          disabled={
            isValidating ||
            (currentQuestion.type === "exam" ? !answer.trim() : !selectedOption)
          }
          endIcon={
            isValidating ? (
              <CircularProgress size={16} sx={{ color: "white" }} />
            ) : currentQuestion.type === "exam" ? (
              <ArrowIcon />
            ) : null
          }
        >
          {currentQuestionIndex === quizData.length - 1
            ? "Visa resultat"
            : currentQuestion.type === "exam"
              ? "Lämna in svar"
              : "Nästa fråga"}
        </Button>
      </Box>
    </Paper>
  );
};

export default LessonQuizDisplay;
