import {
  Newspaper,
  Science,
  History,
  Language,
  Calculate,
  Public,
  Biotech,
  Psychology,
  MenuBook,
  Campaign,
  Business,
  LocalHospital,
  School,
} from "@mui/icons-material";

// Default themes that show when no subject is selected
export const defaultThemes = [
  { type: "subheader", label: "Spännande nyhetsartiklar för olika ämnen:" },
  {
    value: "framtidens-skola",
    label: "Robotläraren i klassrummet",
    content:
      "Skapa en artikel om hur Sveriges första AI-lärarassistent har börjat jobba i en grundskola",
    Icon: School,
  },
  {
    value: "digital-demokrati",
    label: "Rösta via hjärnchip",
    content:
      "Skapa en artikel om experiment där människor röstat i lokalval genom tankestyrda gränssnitt",
    Icon: Public,
  },
  {
    value: "virtuell-historia",
    label: "Vikingar i VR",
    content:
      "Skapa en artikel om hur arkeologer återskapar hela vikingabyar i virtual reality",
    Icon: History,
  },
  {
    value: "matresor",
    label: "Första restaurangen på Mars",
    content:
      "Skapa en artikel om hur rymdturister ska få uppleva fine dining i marskolonin",
    Icon: Science,
  },
  {
    value: "djurkommunikation",
    label: "Delfinernas ordbok",
    content:
      "Skapa en artikel om forskare som knäckt koden för delfiners språk genom AI-analys",
    Icon: Campaign,
  },
];

// Subject-specific themes
export const subjectThemes = {
  filosofi: [
    { type: "subheader", label: "Filosofiska nyhetsteman:" },
    {
      value: "ai-medvetande",
      label: "AI kräver mänskliga rättigheter",
      content:
        "Skapa en artikel om världens första AI som hävdar att den är medveten och kräver rättigheter",
      Icon: Psychology,
    },
    {
      value: "digitala-tvillingar",
      label: "Evigt liv som avatar",
      content:
        "Skapa en artikel om människor som laddar upp sina minnen till digitala kopior före döden",
      Icon: Public,
    },
    {
      value: "alternativa-världar",
      label: "Parallella universums påverkan",
      content:
        "Skapa en artikel om kvantfysiker som hittat bevis för parallella universum som påverkar vårt",
      Icon: Science,
    },
    {
      value: "tidresedebatt",
      label: "Moraliska regler för tidsresor",
      content:
        "Skapa en artikel om etiska riktlinjer som tas fram inför första tidsreseexperimenten",
      Icon: Campaign,
    },
  ],

  biologi: [
    { type: "subheader", label: "Biologiska nyhetsteman:" },
    {
      value: "utdoda-arter",
      label: "Mammutens återkomst",
      content:
        "Skapa en artikel om första levande mammutkalven skapad genom genmodifiering",
      Icon: Science,
    },
    {
      value: "tradkommunikation",
      label: "Trädens internet",
      content:
        "Skapa en artikel om upptäckten av träds avancerade kommunikationsnätverk via svamptrådar",
      Icon: Campaign,
    },
    {
      value: "mikroskopiska-städer",
      label: "Civilisationer i vattendroppen",
      content:
        "Skapa en artikel om nyupptäckta mikroorganismer som bygger komplexa samhällen",
      Icon: Biotech,
    },
    {
      value: "manniska-djur",
      label: "Människoapor i parlamentet",
      content:
        "Skapa en artikel om förslag att ge människoapor medborgarskap och rösträtt",
      Icon: Psychology,
    },
  ],

  historia: [
    { type: "subheader", label: "Historiska nyhetsteman:" },
    {
      value: "dna-faraoner",
      label: "Faraos DNA-hemlighet",
      content:
        "Skapa en artikel om DNA-analys som avslöjar egyptiska kungafamiljers okända ursprung",
      Icon: History,
    },
    {
      value: "framtida-arkeologi",
      label: "Arkeologer från år 4000",
      content:
        "Skapa en artikel skriven av framtida arkeologer som hittat vår tids sociala medier",
      Icon: Science,
    },
    {
      value: "vikingasatellit",
      label: "Vikingar i rymden",
      content:
        "Skapa en artikel om upptäckten av att vikingar använde primitiv astronomi för navigation",
      Icon: Public,
    },
    {
      value: "forntida-teknik",
      label: "Antikens superdata",
      content:
        "Skapa en artikel om upptäckten av en forntida analog dator äldre än Antikythera",
      Icon: Calculate,
    },
  ],

  matematik: [
    { type: "subheader", label: "Matematiska nyhetsteman:" },
    {
      value: "pi-slut",
      label: "Pi:s sista siffra",
      content:
        "Skapa en artikel om matematiker som hävdar att de hittat ett mönster i pi:s decimaler",
      Icon: Calculate,
    },
    {
      value: "matematisk-musik",
      label: "Universums matematik sjunger",
      content:
        "Skapa en artikel om matematiska mönster som översatts till cosmic jazz",
      Icon: Campaign,
    },
    {
      value: "levande-matematik",
      label: "Matematiska varelser",
      content:
        "Skapa en artikel om forskare som hittat levande organismer som följer fibonacci-sekvensen",
      Icon: Science,
    },
    {
      value: "oandlighetsproblem",
      label: "Oändligheten krymper",
      content:
        "Skapa en artikel om nya bevis som visar att matematisk oändlighet har gränser",
      Icon: Calculate,
    },
  ],

  svenska: [
    { type: "subheader", label: "Svenska språkets nyhetsteman:" },
    {
      value: "tankesprak",
      label: "Tankarnas grammatik",
      content:
        "Skapa en artikel om forskare som kartlagt hjärnans universella grammatik",
      Icon: Language,
    },
    {
      value: "emoji-evolution",
      label: "Emojis blir officiellt språk",
      content:
        "Skapa en artikel om hur emoji-språket får officiell status i digital kommunikation",
      Icon: Campaign,
    },
    {
      value: "diktrobotar",
      label: "AI vinner Nobelpriset",
      content:
        "Skapa en artikel om första AI-författaren som nominerats till Nobelpriset i litteratur",
      Icon: MenuBook,
    },
    {
      value: "telepati-sprak",
      label: "Ordlös kommunikation",
      content:
        "Skapa en artikel om experiment med telepati som hotar skriftspråkets framtid",
      Icon: Psychology,
    },
  ],
};

// Helper function to get appropriate themes based on subject
export const getThemes = (subject) => {
  if (!subject) return defaultThemes;
  return subjectThemes[subject] || defaultThemes;
};

export default getThemes;
