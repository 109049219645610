import React, { useRef, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from './useAuth';
import { useSnackbar } from './SnackbarHandler';
import { useGoogleLogin } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import HttpsIcon from '@mui/icons-material/Https';
import GoogleGIcon from './GoogleGIcon';
import { useTheme } from '@mui/material/styles';
import Logger from './Logger';
import { MarketingSide, translateFirebaseError } from './AuthMarketingSide';
import {
    Box,
    Button,
    TextField,
    Typography,
    Paper,
    Alert,
    CircularProgress,
    useMediaQuery,
    Backdrop,
    Fade,
    IconButton,
} from '@mui/material';

import {
    Google as GoogleIcon,
    East as ArrowIcon,
    ArrowBack as BackIcon,
    AlternateEmail,
    Key,
} from '@mui/icons-material';

const LoginView = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const emailRef = useRef('');
    const passwordRef = useRef('');
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const { handleGoogleLogin, signIn } = useAuth();
    const { showSnackbar } = useSnackbar();
    const [error, setError] = useState('');

    const googleLogin = useGoogleLogin({
        onSuccess: async response => {
            setIsLoading(true);
            await handleGoogleLogin(response);
            window.location.href = '/app/';
            //navigate('/app/');
        },
        onError: () => {
            Logger.log('Login Failed');
        },
    });

    const handleSubmit = async e => {
        e.preventDefault();
        setError('');
        setIsLoading(true);
        try {
            await signIn(emailRef.current.value, passwordRef.current.value);
            window.location.href = '/app/';
            //navigate('/app/');
        } catch (error) {
            const swedishErrorMessage = translateFirebaseError(error);
            showSnackbar(swedishErrorMessage, 'info');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Box
            sx={{
                minHeight: '100vh',
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                position: 'relative',
            }}
        >
            <IconButton
                onClick={() => {
                    const params = new URLSearchParams(window.location.search);
                    const ref = params.get('ref');
                    navigate(ref === 'startpage' ? '/' : '/app');
                }}
                sx={{
                    position: 'absolute',
                    top: 16,
                    left: 16,
                    zIndex: 1,
                    backgroundColor: theme => theme.palette.background.paper,
                    boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                    '&:hover': {
                        backgroundColor: theme => theme.palette.action.hover,
                    },
                }}
                size='large'
            >
                <BackIcon />
            </IconButton>

            <Box
                sx={{
                    flex: '1 1 50%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    p: 3,
                }}
            >

                    <Paper
                        elevation={24}
                        sx={{
                            width: '100%',
                            maxWidth: '600px',
                            borderRadius: '24px',
                            overflow: 'hidden',
                            backdropFilter: 'blur(20px)',
                            backgroundColor: 'transparent',
                            backgroundImage: 'none',
                            boxShadow: 'none',
                        }}
                    >
                        <Box sx={{ p: 4, pb: 2 }}>
                            <Typography
                                variant='h4'
                                gutterBottom
                                sx={{
                                    fontWeight: 800,
                                    textAlign: 'center',
                                    backgroundClip: 'text',
                                    textFillColor: 'transparent',
                                    background:
                                        'linear-gradient(135deg, #43CBFF 10%, #9708CC 100%)',
                                    WebkitBackgroundClip: 'text',
                                    WebkitTextFillColor: 'transparent',
                                    mb: 1.5,
                                    fontSize: '2.7rem',
                                }}
                            >
                                Logga in på Studera.AI
                            </Typography>

                            <Button
                                component={Link}
                                to='/app/skapa-konto'
                                sx={{
                                    mt: 0,
                                    mb: 4,
                                    padding: 0,
                                    fontWeight: '500',
                                    fontSize: { xs: '0.81rem', md: '1.1rem' },
                                    textTransform: 'none',
                                    color: theme.palette.mode === 'dark' ? 'white' : '#666666',
                                    textAlign: 'center',
                                    width: '100%',
                                    textDecoration: 'none',
                                    '&:hover': {
                                        color: 'primary.dark',
                                        backgroundColor: 'transparent',
                                    },
                                }}
                            >
                                Har du inget konto?&nbsp;
                                <span style={{ fontWeight: 600 }}>Skapa ett konto här.</span>
                            </Button>

                            <Box sx={{ mb: 3, mt: 5 }}>
                                <Typography
                                    variant='body2'
                                    align='center'
                                    sx={{
                                        fontSize: '20px',
                                        fontWeight: '600',
                                        position: 'relative',
                                        color: theme.palette.mode === 'dark' ? '#abadb0' : '#444',
                                        '&::before, &::after': {
                                            content: '""',
                                            position: 'absolute',
                                            top: '50%',

                                            width: { xs: '0%', md: '28%' },
                                            height: '1px',
                                            backgroundColor: theme =>
                                                theme.palette.mode === 'dark'
                                                    ? 'grey.800'
                                                    : 'grey.300',
                                        },
                                        '&::before': { left: 0 },
                                        '&::after': { right: 0 },
                                    }}
                                >
                                    Logga in med Google
                                </Typography>
                            </Box>

                            <Box sx={{ mb: 3, mt: 3 }}>
                                {/*
                                <GoogleLogin
                                    style={{ width: '100%' }}
                                    onSuccess={async response => {
                                        await handleGoogleLogin(response);
                                        navigate('/app/'); // Add this navigation
                                    }}
                                    onError={() => {
                                        Logger.log('Login Failed');
                                    }}
                                />
                                */}

                                <Button
                                    fullWidth
                                    startIcon={<GoogleGIcon />}
                                    onClick={() => googleLogin()}
                                    variant='contained'
                                    sx={{
                                        py: 1.5,
                                        borderRadius: '12px',
                                        background: 'linear-gradient(to right, #ffffff, #f8f9fa)',
                                        color: '#3c4043',
                                        textTransform: 'uppercase',
                                        fontWeight: 500,
                                        border: '1px solid #dadce0',
                                        mt: 1,
                                        mb: 2,
                                        boxShadow: 'none',
                                        '&:hover': {
                                            background:
                                                'linear-gradient(to right, #f8f9fa, #f1f3f4)',
                                            boxShadow: '0 1px 3px rgba(0,0,0,0.08)',
                                        },
                                    }}
                                >
                                    Logga in med Google
                                </Button>
                            </Box>

                            <Box sx={{ mb: 3, mt: 6 }}>
                                <Typography
                                    variant='body2'
                                    align='center'
                                    sx={{
                                        fontSize: '20px',
                                        fontWeight: '600',
                                        position: 'relative',
                                        '&::before, &::after': {
                                            content: '""',
                                            position: 'absolute',
                                            top: '50%',
                                            width: { xs: '0%', md: '28%' },
                                            height: '1px',
                                            backgroundColor: theme =>
                                                theme.palette.mode === 'dark'
                                                    ? 'grey.800'
                                                    : 'grey.300',
                                        },
                                        '&::before': { left: 0 },
                                        '&::after': { right: 0 },
                                    }}
                                >
                                    Logga in med e-post
                                </Typography>
                            </Box>

                            <Box component='form' onSubmit={handleSubmit} noValidate>
                                <TextField
                                    margin='normal'
                                    required
                                    placeholder='E-post'
                                    fullWidth
                                    id='email'
                                    name='email'
                                    autoComplete='email'
                                    inputRef={emailRef}
                                    InputProps={{
                                        startAdornment: <AlternateEmail sx={{ mr: 1 }} />,
                                    }}
                                    sx={{
                                        mb: 1,
                                        backgroundColor:
                                            theme.palette.mode === 'dark' ? undefined : 'white',
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: '12px',
                                        },
                                    }}
                                />
                                <TextField
                                    margin='normal'
                                    required
                                    fullWidth
                                    name='password'
                                    placeholder='Lösenord'
                                    type='password'
                                    id='password'
                                    autoComplete='current-password'
                                    inputRef={passwordRef}
                                    InputProps={{
                                        startAdornment: <HttpsIcon sx={{ mr: 1 }} />,
                                    }}
                                    sx={{
                                        mb: 2,
                                        backgroundColor:
                                            theme.palette.mode === 'dark' ? undefined : 'white',
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: '12px',
                                        },
                                    }}
                                />

                                {error && (
                                    <Alert severity='error' sx={{ mb: 2, borderRadius: '12px' }}>
                                        {error}
                                    </Alert>
                                )}

                                <Button
                                    type='submit'
                                    fullWidth
                                    disabled={isLoading}
                                    sx={{
                                        mt: 2,
                                        mb: 2,
                                        py: 1.5,
                                        borderRadius: '12px',
                                        background:
                                            'linear-gradient(135deg, #6366f1 0%, #8b5cf6 100%)',
                                        color: 'white',
                                        fontWeight: 600,
                                        fontSize: '1rem',
                                        textTransform: 'none',
                                        '&:hover': {
                                            background:
                                                'linear-gradient(135deg, #4f46e5 0%, #7c3aed 100%)',
                                        },
                                        '&:disabled': {
                                            background: 'grey.500',
                                        },
                                    }}
                                >
                                    {isLoading ? (
                                        <CircularProgress size={24} color='inherit' />
                                    ) : (
                                        <>
                                            Logga in
                                            <ArrowIcon sx={{ ml: 1 }} />
                                        </>
                                    )}
                                </Button>
                            </Box>
                        </Box>
                    </Paper>

            </Box>

            <MarketingSide />

            <Backdrop
                transitionDuration={1000}
                sx={{
                    color: '#fff',
                    zIndex: theme => theme.zIndex.drawer + 1,
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 4,
                    bgcolor: 'rgba(0, 0, 0, 0.85)',
                    backdropFilter: 'blur(8px)',
                }}
                open={isLoading}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 3,
                    }}
                >
                    <Box
                        sx={{
                            position: 'relative',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            mb: 1,
                        }}
                    >
                        <Box
                            component='img'
                            src='/img/AI.png'
                            alt='AI Logo'
                            sx={{
                                width: 120,
                                height: 120,
                                animation: 'pulse 1s infinite ease-in-out',
                                filter: 'drop-shadow(0 0 20px rgba(255, 255, 255, 0.2))',
                            }}
                        />
                    </Box>
                    <Typography
                        variant='h6'
                        sx={{
                            fontWeight: 300,
                            letterSpacing: 1,
                            opacity: 0.9,
                            textAlign: 'center',
                            fontSize: '1.55rem',
                            marginBottom: '20px',
                        }}
                    >
                        Loggar in...
                    </Typography>
                    <CircularProgress sx={{ color: '#ffffff' }} size='30px' />
                </Box>
            </Backdrop>
        </Box>
    );
};

export default LoginView;
