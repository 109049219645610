import React, { useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Tooltip,
  Typography,
  TextField,
  Grid,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ImageIcon from "@mui/icons-material/Image";
import HoverableImage from "./HoverableImage";

const PowerPointDisplay = ({
  lessonData,
  isGeneratingTopicImage,
  isGeneratingFactImages,
  loadingImageIndex,
  handleImageButtonClick,
  handleImageUpdate,
  user,
  theme,
  showSnackbar,
  setLessonData,
  themeColor = "#000000",
  styles,
}) => {
  const [isEditingTopic, setIsEditingTopic] = useState(false);
  const [editingSections, setEditingSections] = useState({});
  const [editedTitles, setEditedTitles] = useState({});

  const handleSectionDelete = (factKey) => {
    setLessonData((prev) => {
      const newData = { ...prev };
      const newHighLevelFacts = { ...prev.highLevelFacts };
      delete newHighLevelFacts[factKey];
      newData.highLevelFacts = newHighLevelFacts;
      return newData;
    });
    showSnackbar("Slide har tagits bort", "success");
  };

  const handleSectionEdit = (factKey) => {
    setEditingSections((prev) => ({
      ...prev,
      [factKey]: true,
    }));
  };

  const handleSectionSave = (factKey) => {
    const newTitle = editedTitles[factKey] || factKey;

    setLessonData((prev) => {
      const newData = { ...prev };
      const orderedHighLevelFacts = {};

      Object.entries(prev.highLevelFacts).forEach(([key, value]) => {
        if (key === factKey) {
          orderedHighLevelFacts[newTitle] = value;
        } else {
          orderedHighLevelFacts[key] = value;
        }
      });

      newData.highLevelFacts = orderedHighLevelFacts;
      return newData;
    });

    setEditingSections((prev) => ({
      ...prev,
      [factKey]: false,
    }));

    setEditedTitles((prev) => {
      const newTitles = { ...prev };
      delete newTitles[factKey];
      return newTitles;
    });

    showSnackbar("Slide har uppdaterats", "success");
  };

  if (!lessonData) return null;

  return (
    <Box sx={{ mt: 4 }}>
      <Typography
        variant="h3"
        sx={{
          fontWeight: "bold",
          textAlign: "center",
          mb: 4,
          mt: 8,
          display: "none",
        }}
      >
        Dina slides:
      </Typography>

      <Grid container spacing={2}>
        <Box sx={{ width: "100%" }}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: "500",
              marginBottom: "20px",
              mt: 5,
              fontSize: "25px",
              display: "block",
              color: "text.secondary",
            }}
          >
            Titel-slide:
          </Typography>
        </Box>

        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: "60px",
              paddingBottom: "60px",
              flexDirection: "column",
              backgroundColor:
                theme.palette.mode === "dark" ? "#15202b" : "#f5f5f5",
              borderRadius: "10px",
            }}
          >
            {isEditingTopic ? (
              <TextField
                value={lessonData.topic}
                onChange={(e) =>
                  setLessonData((prev) => ({
                    ...prev,
                    topic: e.target.value,
                  }))
                }
                onBlur={() => {
                  setIsEditingTopic(false);
                  showSnackbar(
                    "Titeln på första sliden har uppdaterats",
                    "success",
                  );
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setIsEditingTopic(false);
                    showSnackbar(
                      "Titeln på första sliden har uppdaterats",
                      "success",
                    );
                  }
                }}
                autoFocus
                fullWidth
                variant="standard"
                sx={{
                  ...styles.editingTextField,
                  width: "90%",
                  margin: "0 auto",
                  "& .MuiInputBase-input": {
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: "3rem",
                    fontFamily: "inherit",
                  },
                }}
                InputProps={{
                  disableUnderline: true,
                }}
              />
            ) : (
              <Box
                sx={{
                  position: "relative",
                  display: "inline-block",
                  width: "90%",
                  margin: "0 auto",
                  textAlign: "center",
                  "&:hover .edit-icon": {
                    opacity: 1,
                  },
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="h3"
                    sx={{
                      fontWeight: "bold",
                      display: "inline-flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 1,
                      width: "90%",
                    }}
                  >
                    {lessonData.topic}
                    <Box
                      component="span"
                      className="edit-icon"
                      sx={{
                        opacity: 0,
                        transition: "opacity 0.2s",
                        display: "inline-flex",
                        ml: 1,
                      }}
                    >
                      <Tooltip title="Redigera" placement="top">
                        <IconButton onClick={() => setIsEditingTopic(true)}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Typography>
                </Box>
              </Box>
            )}

            <Box sx={{ position: "relative", mt: 7 }}>
              <HoverableImage
                imageUrl={lessonData.topicImage}
                isLoading={isGeneratingTopicImage}
                userId={user.uid}
                theme={theme}
                showSnackbar={showSnackbar}
                onGenerateClick={() => handleImageButtonClick("topic")}
                onImageUpdate={(newImageUrl) =>
                  handleImageUpdate(newImageUrl, true)
                }
                height="500px"
              />
            </Box>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            mt: 8,
            "& p": { lineHeight: "27px" },
            "& li": {
              marginBottom: "12px",
              lineHeight: "25px",
              "& strong": { fontWeight: "600" },
            },
            "& h1": { color: themeColor },
            "& h2": {
              marginTop: "60px",
              marginBottom: "0px",
              color: themeColor,
            },
            "& h3": {
              marginTop: "40px",
              marginBottom: "0px",
              color: themeColor,
            },
            "& hr": {
              height: "1px",
              border: "none",
              backgroundColor: (theme) => theme.palette.divider,
              marginTop: "50px",
              marginBottom: "50px",
            },
            "& ul, & ol": {
              "& li::marker": {
                color: themeColor,
              },
            },
          }}
        >
          <Grid container spacing={4}>
            {Object.entries(lessonData.highLevelFacts).map(
              ([section, data], index) => (
                <Grid item xs={12} key={index}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "500",
                      marginBottom: "20px",
                      mt: 5,
                      fontSize: "25px",
                      display: "block",
                      color: "text.secondary",
                    }}
                  >
                    Slide {index + 1}:
                  </Typography>

                  <Box
                    sx={{
                      position: "relative",
                      "&:hover .section-controls": {
                        opacity: 1,
                        visibility: "visible",
                      },
                      backgroundColor:
                        theme.palette.mode === "dark" ? "#15202b" : "#f5f5f5",
                      borderRadius: "10px",
                      p: 3,
                    }}
                  >
                    <Box
                      className="section-controls"
                      sx={{
                        position: "absolute",
                        top: 16,
                        right: 16,
                        opacity: 0,
                        visibility: "hidden",
                        transition: "all 0.2s ease",
                        display: "flex",
                        gap: 1,
                        zIndex: 1,
                      }}
                    >
                      <Tooltip
                        title={
                          editingSections[section]
                            ? "Spara ändringar"
                            : "Redigera slide"
                        }
                        placement="top"
                      >
                        <IconButton
                          onClick={() =>
                            editingSections[section]
                              ? handleSectionSave(section)
                              : handleSectionEdit(section)
                          }
                        >
                          {editingSections[section] ? (
                            <SaveAsIcon />
                          ) : (
                            <EditIcon />
                          )}
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Ta bort slide" placement="top">
                        <IconButton
                          onClick={() => handleSectionDelete(section)}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>

                    {editingSections[section] ? (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 2,
                        }}
                      >
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={6}>
                            <TextField
                              value={editedTitles[section] || section}
                              onChange={(e) => {
                                setEditedTitles((prev) => ({
                                  ...prev,
                                  [section]: e.target.value,
                                }));
                              }}
                              sx={{
                                ...styles.editingTextField,
                                width: "100%",
                                mb: 2,
                                "& .MuiInputBase-input": {
                                  fontSize: "1.25rem",
                                  fontWeight: "bold",
                                },
                              }}
                            />
                            <HoverableImage
                              imageUrl={
                                lessonData.highLevelFacts[section].image
                              }
                              isLoading={
                                isGeneratingFactImages &&
                                loadingImageIndex === section
                              }
                              loadingIndex={loadingImageIndex}
                              currentIndex={section}
                              userId={user.uid}
                              theme={theme}
                              showSnackbar={showSnackbar}
                              onGenerateClick={() =>
                                handleImageButtonClick("facts", section)
                              }
                              onImageUpdate={(newImageUrl) =>
                                handleImageUpdate(newImageUrl, false, section)
                              }
                              height="400px"
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              minHeight: "400px", // Match the height of the image
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 2,
                              }}
                            >
                              {data.facts.map((fact, idx) => (
                                <TextField
                                  key={idx}
                                  fullWidth
                                  multiline
                                  value={fact}
                                  onChange={(e) => {
                                    const newFacts = [...data.facts];
                                    newFacts[idx] = e.target.value;
                                    setLessonData((prev) => ({
                                      ...prev,
                                      highLevelFacts: {
                                        ...prev.highLevelFacts,
                                        [section]: {
                                          ...prev.highLevelFacts[section],
                                          facts: newFacts,
                                        },
                                      },
                                    }));
                                  }}
                                  sx={{ ...styles.editingTextField }}
                                />
                              ))}
                              <Button
                                variant={
                                  theme.palette.mode === "light"
                                    ? "purp"
                                    : "contained"
                                }
                                color="primary"
                                onClick={() => handleSectionSave(section)}
                                startIcon={<SaveAsIcon />}
                                sx={{ mt: 2 }}
                              >
                                Spara ändringar
                              </Button>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    ) : (
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                          <Typography
                            variant="h6"
                            sx={{
                              fontWeight: "bold",
                              mb: 4,
                              fontSize: "1.8rem",
                            }}
                          >
                            {section}
                          </Typography>
                          <HoverableImage
                            imageUrl={lessonData.highLevelFacts[section].image}
                            isLoading={
                              isGeneratingFactImages &&
                              loadingImageIndex === section
                            }
                            loadingIndex={loadingImageIndex}
                            currentIndex={section}
                            userId={user.uid}
                            theme={theme}
                            showSnackbar={showSnackbar}
                            onGenerateClick={() =>
                              handleImageButtonClick("facts", section)
                            }
                            onImageUpdate={(newImageUrl) =>
                              handleImageUpdate(newImageUrl, false, section)
                            }
                            height="400px"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            minHeight: "400px", // Match the height of the image
                          }}
                        >
                          <ul style={{ margin: 0 }}>
                            {data.facts.map((fact, idx) => (
                              <li key={idx}>
                                <Typography variant="body1">{fact}</Typography>
                              </li>
                            ))}
                          </ul>
                        </Grid>
                      </Grid>
                    )}
                  </Box>
                </Grid>
              ),
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PowerPointDisplay;
