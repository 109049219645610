import React, { useState } from "react";
import { Box, Paper, Typography, IconButton, Tooltip } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import ReadingComprehensionDialog from "./ReadingComprehensionDialog";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableContainer,
  TableCell,
} from "@mui/material";
import { InstructionItem } from "./functions";

const LessonExercisesDisplay = ({
  exercises,
  onUpdateLessonData,
  viewMode,
  theme,
  lessonData,
  styles,
}) => {
  const [hoveredExercise, setHoveredExercise] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedExercise, setSelectedExercise] = useState(null);

  if (!exercises || exercises.length === 0) {
    return null;
  }

  const handleDeleteExercise = (exerciseIndex) => {
    const updatedExercises = exercises.filter(
      (_, index) => index !== exerciseIndex,
    );
    onUpdateLessonData((prevData) => ({
      ...prevData,
      exercises: updatedExercises.length > 0 ? updatedExercises : null,
    }));
  };
  const handleEditExercise = (exercise, index) => {
    setSelectedExercise({ ...exercise, index });
    setEditDialogOpen(true);
  };

  return (
    <Box sx={{ mt: 4, mb: 4 }}>
      {exercises.map((exercise, index) => (
        <Box
          key={index}
          sx={{ mb: 4, position: "relative" }}
          onMouseEnter={() => setHoveredExercise(index)}
          onMouseLeave={() => setHoveredExercise(null)}
        >
          <Paper
            sx={{
              p: 4,
              mb: 5,
              borderRadius: 4,
              backgroundColor: theme.palette.background.paperHover,
              backgroundImage: "none",
            }}
          >
            {hoveredExercise === index && !viewMode && (
              <Box
                sx={{
                  position: "absolute",
                  top: 8,
                  right: 8,
                  display: "flex",
                  gap: 1,
                }}
              >
                <Tooltip title={`Redigera ${exercise.title}`}>
                  <IconButton
                    size="small"
                    onClick={() => handleEditExercise(exercise, index)}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={`Ta bort ${exercise.title}`}>
                  <IconButton
                    size="small"
                    onClick={() => handleDeleteExercise(index)}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
            <Typography
              variant="h5"
              sx={{
                mb: 5,
                fontWeight: "600",
                fontSize: "1.3rem",
              }}
            >
              {exercise.title}
            </Typography>
            {exercise.type === "multiple-choice" ? (
              exercise.content.map((item, i) => (
                <Box key={i} sx={{ mb: 3 }}>
                  <Typography sx={{ fontWeight: "500", mb: 2, mt: 6 }}>
                    {item.question}
                  </Typography>
                  <Box sx={{ pl: 3 }}>
                    {item.options?.map((option, optionIndex) => (
                      <Box
                        key={optionIndex}
                        sx={{ display: "flex", alignItems: "center", mb: 1 }}
                      >
                        <Box
                          sx={{
                            ml: 2,
                            width: 30,
                            height: 30,
                            bgcolor: "background.paper",
                            border: "2px solid",
                            borderColor: "text.main",
                            color: "text.main",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            mr: 2,
                            borderRadius: 5,
                            fontSize: "0.9rem",
                            fontWeight: "bold",
                            "&:hover": {
                              bgcolor: "text.main",
                              color: "white",
                            },
                          }}
                        >
                          {String.fromCharCode(65 + optionIndex)}
                        </Box>
                        <Typography>{option}</Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
              ))
            ) : exercise.type === "word-comprehension" ? (
              <>
                {/* Ordförklaringar Table */}
                <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
                  Ordförklaringar
                </Typography>
                <TableContainer component={Paper} sx={{ borderRadius: "10px" }}>
                  <Table
                    sx={{
                      "& .MuiTableCell-root": { fontSize: "16px" },
                    }}
                  >
                    <TableHead>
                      <TableRow sx={{ backgroundColor: "#f9f9f9" }}>
                        <TableCell sx={{ fontWeight: "bold", width: "30%" }}>
                          Ord
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Definition
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {exercise.content.map((item, i) => (
                        <TableRow key={i}>
                          <TableCell sx={{ fontWeight: "500" }}>
                            {item.word}
                          </TableCell>
                          <TableCell>{item.definition}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {/* Synonyms and Antonyms Table */}
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", mb: 2, mt: 4 }}
                >
                  Synonymer och antonymer
                </Typography>

                <TableContainer component={Paper} sx={{ borderRadius: "10px" }}>
                  <Table
                    sx={{
                      "& .MuiTableCell-root": { fontSize: "16px" },
                    }}
                  >
                    <TableHead>
                      <TableRow sx={{ backgroundColor: "#f9f9f9" }}>
                        <TableCell sx={{ fontWeight: "bold", width: "30%" }}>
                          Ord
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold", width: "35%" }}>
                          Synonymer
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Antonymer
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {exercise.content.map((item, i) => (
                        <TableRow key={i}>
                          <TableCell sx={{ fontWeight: "500" }}>
                            {item.word}
                          </TableCell>
                          <TableCell>{item.synonyms?.join(", ")}</TableCell>
                          <TableCell>{item.antonyms?.join(", ")}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {/* Use The Words Section */}
                {exercise.content.some(
                  (item) => item.useTheWords?.length > 0,
                ) && (
                  <>
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: "bold", mb: 2, mt: 4 }}
                    >
                      Använd orden
                    </Typography>
                    <Box>
                      {exercise.content.flatMap(
                        (item, i) =>
                          item.useTheWords?.map((task, j) => (
                            <Box
                              key={`${i}-${j}`}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                mb: 2,
                              }}
                            >
                              <Box
                                sx={{
                                  ml: 2,
                                  width: 30,
                                  height: 30,
                                  bgcolor: "background.paper",
                                  border: "2px solid",
                                  borderColor: "text.main",
                                  color: "text.main",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  mr: 2,
                                  borderRadius: 1,
                                  fontSize: "0.9rem",
                                  fontWeight: "bold",
                                  "&:hover": {
                                    bgcolor: "text.main",
                                    color: "white",
                                  },
                                }}
                              >
                                {i + j + 1}
                              </Box>
                              <Typography sx={{ flex: 1 }}>{task}</Typography>
                            </Box>
                          )) || [],
                      )}
                    </Box>
                  </>
                )}
              </>
            ) : (
              <Box>
                {exercise.content.map((item, i) => (
                  <Box
                    key={i}
                    sx={{ display: "flex", alignItems: "center", mb: 2 }}
                  >
                    <Box
                      sx={{
                        ml: 2,
                        width: 30,
                        height: 30,
                        bgcolor: "background.paper",
                        border: "2px solid",
                        borderColor: "text.main",
                        color: "text.main",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        mr: 2,
                        borderRadius: 1,
                        fontSize: "0.9rem",
                        fontWeight: "bold",
                        "&:hover": {
                          bgcolor: "text.main",
                          color: "white",
                        },
                      }}
                    >
                      {i + 1}
                    </Box>
                    <Typography sx={{ flex: 1 }}>
                      {typeof item === "string" ? item : item.question}
                    </Typography>
                  </Box>
                ))}
              </Box>
            )}
          </Paper>
        </Box>
      ))}

      {editDialogOpen && selectedExercise && (
        <ReadingComprehensionDialog
          lessonData={lessonData}
          open={editDialogOpen}
          initialStep={2}
          initialEditMode={true}
          initialExercise={selectedExercise}
          onClose={() => {
            setEditDialogOpen(false);
            setSelectedExercise(null);
          }}
          onUpdateExercise={(updatedExercise) => {
            const updatedExercises = [...exercises];
            updatedExercises[selectedExercise.index] = updatedExercise;
            onUpdateLessonData((prevData) => ({
              ...prevData,
              exercises: updatedExercises,
            }));
          }}
          theme={theme}
          styles={styles}
        />
      )}
    </Box>
  );
};

export default LessonExercisesDisplay;
