// ExportButton.js
import React, { useState } from "react";
import {
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Button,
  Typography,
} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import WordIcon2019 from "./WordIcon2019";
import { marked } from "marked";
import jsPDF from "jspdf";
import "jspdf-autotable";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";

const ExportButton = ({ content, styles, bigButton, markdown }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleExportClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleExportClose = () => {
    setAnchorEl(null);
  };

  const exportMarkdownToPDF = async () => {
    const doc = new jsPDF();
    let yPos = 20;

    // Helper function to handle bold text rendering AND proper wrapping
    const renderBoldText = (text, xStart, maxWidth) => {
      if (!text.includes("**")) {
        const wrappedText = doc.splitTextToSize(text, maxWidth);
        doc.text(wrappedText, xStart, yPos);
        return wrappedText.length * 7; // Return total height used
      }

      const parts = text.split(/\*\*/);
      const wrappedParts = [];
      let combinedText = "";

      parts.forEach((part, index) => {
        if (index % 2 === 1) {
          // Bold part
          doc.setFont("helvetica", "bold");
        } else {
          doc.setFont("helvetica", "normal");
        }
        combinedText += part;
      });

      const wrappedText = doc.splitTextToSize(combinedText, maxWidth);
      let xPos = xStart;

      // Now render each line with proper bold sections
      wrappedText.forEach((line) => {
        xPos = xStart;
        parts.forEach((part, index) => {
          if (index % 2 === 1) {
            doc.setFont("helvetica", "bold");
          } else {
            doc.setFont("helvetica", "normal");
          }
          doc.text(part, xPos, yPos);
          xPos +=
            (doc.getStringUnitWidth(part) * doc.internal.getFontSize()) / 2.5;
        });
        yPos += 7;
      });

      return wrappedText.length * 7;
    };

    const lines = content.split("\n");

    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];

      // Handle Headers
      if (line.startsWith("#")) {
        const level = line.match(/^#+/)[0].length;
        const text = line.replace(/^#+\s/, "");
        doc.setFontSize(24 - (level - 1) * 4);
        doc.setFont("helvetica", "bold");
        yPos += 10;
        const wrappedText = doc.splitTextToSize(text, 170);
        doc.text(wrappedText, 20, yPos);
        yPos += wrappedText.length * 10;
        doc.setFont("helvetica", "normal");
        doc.setFontSize(12);
        continue;
      }

      // Handle bullet lists
      if (line.match(/^\s*[\-\*]\s/)) {
        const text = line.replace(/^\s*[\-\*]\s/, "");
        doc.text("•", 20, yPos);
        const wrappedText = doc.splitTextToSize(text, 155);
        if (text.includes("**")) {
          renderBoldText(text, 25, 155);
        } else {
          doc.text(wrappedText, 25, yPos);
          yPos += wrappedText.length * 7;
        }
        continue;
      }

      // Handle numbered lists
      if (line.match(/^\s*\d+\.\s/)) {
        const number = line.match(/^\s*\d+/)[0];
        const text = line.replace(/^\s*\d+\.\s/, "");
        doc.text(number + ".", 20, yPos);
        const wrappedText = doc.splitTextToSize(text, 155);
        if (text.includes("**")) {
          renderBoldText(text, 25, 155);
        } else {
          doc.text(wrappedText, 25, yPos);
          yPos += wrappedText.length * 7;
        }
        continue;
      }

      // Handle regular text (with potential bold)
      if (line.trim()) {
        if (line.includes("**")) {
          renderBoldText(line, 20, 170);
        } else {
          const wrappedText = doc.splitTextToSize(line, 170);
          doc.text(wrappedText, 20, yPos);
          yPos += wrappedText.length * 7;
        }
      } else {
        yPos += 7; // Empty line
      }

      // New page if needed
      if (yPos > doc.internal.pageSize.height - 20) {
        doc.addPage();
        yPos = 20;
      }
    }

    doc.save("Fråga_AI_Studera_AI.pdf");
    handleExportClose();
  };

  const exportToPDF = async () => {
    const parsedHtml = marked.parse(content);
    const doc = new jsPDF();

    // Create temporary div to hold content
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = parsedHtml;

    // Set initial y position
    let yPos = 20;

    // Set default font
    doc.setFont("helvetica");
    doc.setFontSize(12);

    // Function to add text with word wrap
    const addWrappedText = (text, x, y, maxWidth) => {
      const lines = doc.splitTextToSize(text, maxWidth);
      doc.text(lines, x, y);
      return y + lines.length * 7;
    };

    // Process content
    const processNode = (node, isTableChild = false) => {
      // Skip processing text nodes that are children of tables
      if (node.nodeType === 3 && isTableChild) {
        return;
      }

      if (node.nodeType === 3) {
        // Text node
        if (node.textContent.trim()) {
          yPos = addWrappedText(node.textContent.trim(), 20, yPos, 170);
          yPos += 5;
        }
        return;
      }

      if (node.nodeType !== 1) return; // Skip if not element node

      const tagName = node.tagName.toLowerCase();

      switch (tagName) {
        case "h1":
        case "h2":
        case "h3":
          const level = parseInt(tagName[1]);
          const fontSize = 20 - (level - 1) * 2; // H1: 20pt, H2: 18pt, H3: 16pt
          doc.setFontSize(fontSize);
          doc.setFont("helvetica", "bold");
          yPos = addWrappedText(node.textContent, 20, yPos, 170);
          doc.setFontSize(12);
          doc.setFont("helvetica", "normal");
          yPos += 10;
          break;

        case "table":
          if (node.querySelectorAll("tr").length > 0) {
            const tableData = [];
            const tableHeaders = [];

            // Get headers
            const thead = node.querySelector("thead");
            if (thead) {
              const headerRow = thead.rows[0];
              Array.from(headerRow.cells).forEach((cell) => {
                tableHeaders.push(cell.textContent.trim());
              });
            }

            // Get data
            const tbody = node.querySelector("tbody");
            if (tbody) {
              Array.from(tbody.rows).forEach((row) => {
                const rowData = [];
                Array.from(row.cells).forEach((cell) => {
                  rowData.push(cell.textContent.trim());
                });
                if (rowData.some((cell) => cell !== "")) {
                  tableData.push(rowData);
                }
              });
            }

            if (tableData.length > 0) {
              doc.autoTable({
                head: tableHeaders.length ? [tableHeaders] : null,
                body: tableData,
                startY: yPos,
                margin: { top: 20, right: 20, bottom: 20, left: 20 },
                styles: { fontSize: 10, cellPadding: 5 },
                headStyles: { fillColor: [123, 104, 238] },
              });

              yPos = doc.previousAutoTable.finalY + 10;
            }
          }
          break;

        case "p":
          yPos = addWrappedText(node.textContent, 20, yPos, 170);
          yPos += 7;
          break;

        case "ul":
        case "ol":
          Array.from(node.children).forEach((li, index) => {
            const bullet = tagName === "ul" ? "•" : `${index + 1}.`;
            const indent = tagName === "ul" ? 25 : 30;
            doc.setFont("helvetica", "normal");
            yPos = addWrappedText(
              `${bullet} ${li.textContent}`,
              indent,
              yPos,
              160,
            );
            yPos += 5;
          });
          yPos += 5;
          break;

        default:
          if (node.textContent.trim() && !node.children.length) {
            yPos = addWrappedText(node.textContent, 20, yPos, 170);
            yPos += 5;
          }
      }

      // Check if we need a new page
      if (yPos > doc.internal.pageSize.height - 20) {
        doc.addPage();
        yPos = 20;
      }

      // Process children if not already handled
      if (!["p", "h1", "h2", "h3", "table"].includes(tagName)) {
        Array.from(node.childNodes).forEach((child) =>
          processNode(child, tagName === "table"),
        );
      }
    };

    // Process the content
    Array.from(tempDiv.childNodes).forEach((node) => processNode(node, false));

    // Save the PDF
    doc.save("Fråga_AI_Studera_AI.pdf");
    handleExportClose();
  };

  const exportToWord = async () => {
    const parsedHtml = marked.parse(content);

    const logoUrl = "https://studera.ai/printlogo.png";

    // Create proper Word HTML format
    const htmlContent = `
      <html xmlns:o='urn:schemas-microsoft-com:office:office' 
            xmlns:w='urn:schemas-microsoft-com:office:word'
            xmlns='http://www.w3.org/TR/REC-html40'>
      <head>
        <meta charset='utf-8'>
        <title>Export</title>
        <!--[if gte mso 9]>
        <xml>
          <w:WordDocument>
            <w:View>Print</w:View>
            <w:Zoom>90</w:Zoom>
            <w:DoNotOptimizeForBrowser/>
          </w:WordDocument>
        </xml>
        <![endif]-->
        <style>

  body, p, div, table, td, th {
            font-family: Calibri, sans-serif;
            font-size: 12pt;
          }

          /* Add any specific Word styling here */
          table { border-collapse: collapse; width: 100%; }
          td, th { border: 1px solid black; padding: 8px; }

    /* Remove absolute positioning */
        .header-logo {
          float: right;
          width: 197px;
          height: 48px;
          margin-right: 10px;
          margin-top: 10px;
        }
        /* Add a container div to handle spacing */
        .header-container {
          width: 100%;
          overflow: hidden;
          margin-bottom: 20px;
      display:none;
        }
        </style>
      </head>
      <body>
 <div class="header-container">
        <img src="${logoUrl}" class="header-logo" />
      </div>


        ${parsedHtml}
      </body>
      </html>
    `;

    // Create blob with Word-specific HTML
    const blob = new Blob([htmlContent], {
      type: "application/vnd.ms-word;charset=utf-8",
    });

    // Create download link
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "Fråga_AI_Studera_AI.doc"; // Note: changed to .doc instead of .docx

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
    handleExportClose();
  };

  return (
    <>
      {bigButton ? (
        <Button
          variant="purp"
          startIcon={<DownloadForOfflineIcon />}
          onClick={handleExportClick}
        >
          Exportera
        </Button>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            marginRight: 10,
          }}
          onClick={handleExportClick}
        >
          <IconButton size="small">
            <DownloadForOfflineIcon
              fontSize="small"
              sx={{ fontSize: "20px" }}
            />
          </IconButton>
          <Typography
            sx={{
              color: "text.secondary",
              fontSize: "0.85rem",

              marginLeft: "2px",
            }}
          >
            Exportera
          </Typography>
        </div>
      )}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleExportClose}
        PaperProps={{
          sx: {
            ...styles.roundMenu,
            borderRadius: "8px",
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <MenuItem onClick={exportToWord} sx={styles.menuItemStyle}>
          <WordIcon2019
            fontSize="small"
            style={{ marginRight: 8, color: "#7B68EE", fontSize: "18px" }}
          />
          Exportera som Word
        </MenuItem>
        <MenuItem
          onClick={markdown ? exportMarkdownToPDF : exportToPDF}
          sx={styles.menuItemStyle}
        >
          <PictureAsPdfIcon
            fontSize="small"
            style={{ marginRight: 8, color: "#7B68EE" }}
          />
          Exportera som PDF
        </MenuItem>
      </Menu>
    </>
  );
};

export default ExportButton;
