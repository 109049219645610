import React, { useState, useEffect, useRef } from "react";

import { db } from "./firebase";
import {
  collection,
  addDoc,
  doc,
  getDoc,
  serverTimestamp,
  query,
  where,
  limit,
  getDocs,
} from "firebase/firestore";

import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import LanguageSelector from "./LanguageSelector";
import Typography from "@mui/material/Typography";

import ShareDialog from "./ShareDialog";

import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import ProgressBar from "./ProgressBar";

import QuestionMarkIcon from "@mui/icons-material/Help";
import LoadingBackdrop from "./LoadingBackdrop";

import { trackLesson } from "./functions";

import { updateUserTokens } from "./functions";
import ListAltIcon from "@mui/icons-material/ListAlt";

import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { addNote } from "./NotesView";
import { handlePrintQuiz, handlePrint1X2Quiz } from "./printFunctions";
import ExploreIcon from "@mui/icons-material/Explore";
import SettingsIcon from "@mui/icons-material/Settings";
import Logger from "./Logger";
import QuizResults from "./QuizResults";
import QuizIcon from "@mui/icons-material/Quiz";
import InfoBox from "./InfoBox";

import { Helmet } from "react-helmet-async";
import DrawerComponent from "./quiz/DrawerComponent";

import QuestionTypeSelector from "./quiz/QuestionTypeSelector";

import { Link as LinkIcon } from "@mui/icons-material";

import LoadingSpinner from "./LoadingSpinner";

import TeacherQuizDisplay from "./TeacherQuizDisplay";

import { Fade, Grid } from "@mui/material";

import SegmentIcon from "@mui/icons-material/Segment";

import AddIcon from "@mui/icons-material/Add";

import { Tooltip, Switch, Select, MenuItem, FormControl } from "@mui/material";
import { callApi, callClaude } from "./api";

import { useSnackbar } from "./SnackbarHandler";

import { sanitizeInput } from "./functions";
import {
  useParams,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";

import { useTheme } from "@mui/material/styles";
import { getStyles } from "../index";
import { useMediaQuery, Collapse } from "@mui/material";

import { ThemeSelectorFlow } from "./ThemeSelectorFlow";

import QuizInProgress from "./QuizInProgress";

import MyQuizzes from "./quiz/MyQuizzes";

import AdditionalFields from "./AdditionalFields"; // Regular import instead of lazy
import { getThemes } from "./additionalThemes/Quiz";

const QuizView = ({ user }) => {
  const [quiz, setQuiz] = useState([]);

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [creatorInfo, setCreatorInfo] = useState(null);
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [viewIsLoading, setViewIsLoading] = useState(true);

  const [results, setResults] = useState([]);
  const [showSummary, setShowSummary] = useState(false);
  const [inputType, setInputType] = useState("topic");
  const [inputValue, setInputValue] = useState("");
  /// Moonshine: Set back to 10!
  const [numQuestions, setNumQuestions] = useState(5);
  const [quizName, setQuizName] = useState("");
  const [myQuizzesReloadTrigger, setMyQuizzesReloadTrigger] = useState(0);
  const [isGeneratingQuestionsInDialog, setIsGeneratingQuestionsInDialog] =
    useState(false);

  const [quizInProgress, setQuizInProgress] = useState(false);
  const [currentQuizId, setCurrentQuizId] = useState(null);
  const [isSharing, setIsSharing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [loadingQuiz, setLoadingQuiz] = useState(false); // State for loading quiz

  const [loadingQuestions, setLoadingQuestions] = useState(false);

  const [shareDialogOpen, setShareDialogOpen] = useState(false);

  const [selectedValue, setSelectedValue] = useState("topic");
  const [shareDialogOpenFriends, setShareDialogOpenFriends] = useState(false);
  const [currentQuestionNumber, setCurrentQuestionNumber] = useState(1);

  const [isPublic, setIsPublic] = useState(false);
  const [searchParams] = useSearchParams();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const [customTheme, setCustomTheme] = useState("");
  const [lessonTheme, setLessonTheme] = useState("");
  const [predefinedTheme, setPredefinedTheme] = useState(null);

  const transcriptRef = useRef("");
  const uploadedFileRef = useRef("");

  const wikipediaContentRef = useRef("");
  const randomizedContentRef = useRef("");
  const coursePlanContentRef = useRef("");
  const [includeHints, setIncludeHints] = useState(true);
  const [includeTimer, setIncludeTimer] = useState(false);
  const [originalQuizId, setOriginalQuizId] = useState("");

  const [dynamicTitle, setDynamicTitle] = useState(
    "Quiz — Skapa quiz med AI — Studera.AI",
  );
  const [dynamicDescription, setDynamicDescription] = useState(
    "Skapa quiz med AI. Du kan generera ett quiz med AI genom att skriva in ett ämne eller en längre textmassa.",
  );

  const [questionType, setQuestionType] = useState("multiple-choice");

  const currentQuestion = quiz[currentQuestionIndex];
  const [quizCreatedAt, setQuizCreatedAt] = useState([]);
  const [openAIDialogs, setOpenAIDialogs] = useState({});
  const theme = useTheme();
  const styles = getStyles(theme);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { showSnackbar } = useSnackbar();

  const { quizId, urlQuizName } = useParams();
  const navigate = useNavigate();

  const [showCreateQuizForm, setShowCreateQuizForm] = useState(true);
  const teacherQuizDisplayRef = useRef(null);

  const [selectedLanguage, setSelectedLanguage] = useState("svenska");
  const [selectedModel, setSelectedModel] = useState("Studera.AI");
  const selectedSuggestionRef = useRef("");

  const [progress, setProgress] = useState(0);

  const [category, setCategory] = useState("Välj kategori");
  const [difficulty, setDifficulty] = useState("Medel");
  const [aiInstructions, setAiInstructions] = useState("");

  const bookContentRef = useRef("");
  const [duration, setDuration] = useState(60);

  const [bookmarks, setBookmarks] = useState({});

  const location = useLocation();

  const subject = searchParams.get("subject") || null;
  const urlContentRef = useRef("");
  const additionalThemes = getThemes(subject);

  useEffect(() => {
    if (questionType === "current-events") {
      setNumQuestions(7);
    }
  }, [questionType]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const sourceParam = params.get("source");

    switch (sourceParam) {
      case "youtube":
        setInputType("youtube");
        break;
      case "wiki":
        setInputType("wikipedia");
        break;
      case "word":
        setInputType("text");
        break;
      case "topic":
        setInputType("topic");
        break;
      default:
        setInputType("topic");
        break;
    }

    // Optionally, update the URL to remove the query parameter
    // navigate('/app/quiz', { replace: true });
  }, [location, navigate]);

  const handleSave = async () => {
    setIsSaving(true);
    await new Promise((resolve) => setTimeout(resolve, 1800));

    try {
      const documentData = {
        results: quiz,
        userId: user.uid,
        quizName: quizName,
        createdAt: Date.now(),
        byAdmin: "No",
        isPublic: isPublic,
        questionType: questionType,
        category: category,
        difficulty: difficulty,
        aiInstructions: aiInstructions,
        lastTrainingSession: null,
        language: selectedLanguage,
        examResults: null,
        aiModel: selectedModel,
        upvotes: 0,
        downvotes: 0,
        userVotes: {},
        includeTimer,
        duration,
      };

      // Wait for the document to be added
      await addDoc(collection(db, "quiz"), documentData);

      // If you want to log the saved data
      Logger.log("Sparar quiz:", JSON.stringify(documentData, null, 2));

      showSnackbar(
        "Du har nu sparat quizzet och kan hitta det i 'Mina quiz'.",
        "success",
      );
    } catch (error) {
      Logger.error("Error sharing quiz:", error);
      showSnackbar("Ett fel uppstod när quizzet skulle sparas.", "error");
    } finally {
      setIsSaving(false);
    }
  };

  const handleShare = async ({ roomId, studentIds, comment, fileUrl }) => {
    // Handle the share logic here

    Logger.log("Sharing quiz:", JSON.stringify(quiz, null, 2));
    Logger.log("With room:", roomId);
    Logger.log("To students:", studentIds);
    Logger.log("Comment:", comment);
    setIsSharing(true);

    await new Promise((resolve) => setTimeout(resolve, 1800));

    // try {
    //   // Create a document for each student
    //   const sharePromises = studentIds.map((studentId) => {
    //     const documentData = {
    //       sharedTo: studentId,
    //       comment: comment,
    //       fileUrl: fileUrl,
    //       results: quiz,
    //       shared: true,
    //       userId: user.uid,
    //       quizName: quizName,
    //       createdAt: Date.now(),
    //       byAdmin: "No",
    //       isPublic: isPublic,
    //       questionType: questionType,
    //       category: category,
    //       difficulty: difficulty,

    //       aiInstructions: aiInstructions,
    //       lastTrainingSession: null,
    //       language: selectedLanguage,
    //       aiModel: selectedModel,
    //       examResults: null,
    //       upvotes: 0,
    //       downvotes: 0,
    //       userVotes: {},
    //     };

    //     Logger.log(
    //       "Adding document for student",
    //       studentId,
    //       ":",
    //       JSON.stringify(documentData, null, 2),
    //     );

    //     return addDoc(collection(db, "quiz"), documentData);
    //   });

    //   await Promise.all(sharePromises);
    //   Logger.log("Quiz shared saved to DB");
    //   showSnackbar("Du har nu delat quizzet med dina elever!", "success");
    //   // Close the share dialog
    //   setShareDialogOpen(false);
    // }

    try {
      // Create a document for each student
      const sharePromises = studentIds.map(async (studentId) => {
        const documentData = {
          sharedTo: studentId,
          comment: comment,
          fileUrl: fileUrl,
          results: quiz,
          shared: true,
          userId: user.uid,
          quizName: quizName,
          createdAt: Date.now(),
          byAdmin: "No",
          isPublic: isPublic,
          questionType: questionType,
          category: category,
          difficulty: difficulty,
          aiInstructions: aiInstructions,
          lastTrainingSession: null,
          language: selectedLanguage,
          aiModel: selectedModel,
          examResults: null,
          upvotes: 0,
          downvotes: 0,
          userVotes: {},
        };

        // Create the quiz document and get its ID
        const quizDoc = await addDoc(collection(db, "quiz"), documentData);

        Logger.log(
          "Adding document for student",
          studentId,
          ":",
          JSON.stringify(documentData, null, 2),
        );

        // Create notification with the quiz doc ID
        await addDoc(collection(db, "notifications"), {
          type: "quiz_shared",
          recipientId: studentId,
          senderId: user.uid,
          read: false,
          createdAt: serverTimestamp(),
          quizData: {
            quizId: quizDoc.id,
            quizName: documentData.quizName,
            fileUrl: documentData.fileUrl,
            comment: documentData.comment,
            category: documentData.category,
            difficulty: documentData.difficulty,
          },
        });

        return quizDoc;
      });

      await Promise.all(sharePromises);
      Logger.log("Quiz shared saved to DB");
      showSnackbar("Du har nu delat quizzet!", "success");
      // Close the share dialog
      setShareDialogOpen(false);
    } catch (error) {
      Logger.error("Error sharing quiz:", error);
      showSnackbar("Ett fel uppstod när quizzet skulle delas.", "error");
    } finally {
      setIsSharing(false);
    }
  };

  const formatUrlPart = (str) => {
    return str
      .replace(/\s+/g, "-")
      .replace(/[åä]/g, "a")
      .replace(/[ö]/g, "o")
      .replace(/[^a-z0-9-]/gi, "")
      .toLowerCase();
  };

  const handleNewQuiz = () => {
    selectedSuggestionRef.current = "";

    setShowCreateQuizForm(true);
    setShowSummary(false);
    setQuiz([]);
    setQuizInProgress(false);
    setCurrentQuizId(null); // Lägg till denna rad
    setResults([]); // Lägg till denna rad

    // återställ alla fält

    setInputType("topic");
    setInputValue("");
    setOriginalQuizId("");
    setNumQuestions(5);
    setQuizName("");
    setSelectedLanguage("svenska");
    setSelectedModel("Studera.AI");
    setQuestionType("multiple-choice");
    setIncludeTimer(false);
    setIsPublic(false);
    setCurrentQuestionIndex(0);
    setSelectedOption(null);

    navigate(`/app/quiz`);
  };

  const simulateProgress = (startProgress, endProgress, duration) => {
    const stepSize = 0.5;
    const steps = (endProgress - startProgress) / stepSize;
    const stepDuration = duration / steps;
    let currentProgress = startProgress;

    const intervalId = setInterval(() => {
      currentProgress += stepSize;
      if (currentProgress >= endProgress) {
        clearInterval(intervalId);
      } else {
        setProgress(currentProgress);
      }
    }, stepDuration);

    return () => clearInterval(intervalId);
  };

  const fetchQuiz = async (
    inputType,
    content,
    numQuestions,
    category,
    difficulty,
    aiInstructions,
    includeHints = false,
  ) => {
    setProgress(0);
    if (content.trim() === "") {
      content =
        "Ge mig ett quiz med blandade frågor som har med nåt som kräver åtminstone lite bildning.";
    }

    // Base format examples for different question types with hints
    const formatExamples = {
      default: `[{
      "question": "Who won the FIFA World Cup in 2018?",
      "options": ["Brazil", "France", "Germany", "Argentina"],
      "correct": "France",
      ${
        includeHints
          ? `"hints": [
        "This team also won their first World Cup in 1998",
        "Their star player Kylian Mbappé was only 19 during this tournament",
        "They defeated Croatia in the final"
      ]`
          : ""
      }
    }]`,
      "fill-in-the-blank": `[{
      "question": "En sekvens av _ baser bestämmer genetisk information",
      "answer": "Kvävehaltiga",
      "options": ["Kvävehaltiga", "Guld", "Brom", "Gas"],
      ${
        includeHints
          ? `"hints": [
        "Denna typ av bas är en viktig del av DNA-strukturen",
        "Det finns fyra olika typer av dessa baser i DNA",
        "Dessa baser innehåller grundämnet N"
      ]`
          : ""
      }
    }]`,
      "true-or-false": `[{
      "question": "Is the Earth flat?",
      "options": ["Sant", "Falskt"],
      "correct": "Falskt",
      ${
        includeHints
          ? `"hints": [
        "Tänk på vad som händer när ett skepp seglar mot horisonten",
        "Fundera på månens form och andra himlakroppar",
        "Detta bevisades vetenskapligt redan på 1500-talet"
      ]`
          : ""
      }
    }]`,

      exam: `[{
      "question": "Förklara hur fotosyntesen fungerar och dess betydelse för livet på jorden",
      "type": "exam",
      "answer": "Fotosyntesen är den process där växter omvandlar solenergi till kemisk energi. ",
      ${
        includeHints
          ? `"hints": [
        "Tänk på vilka grundämnen som ingår i processen",
        "Fundera på vad som krävs för att processen ska fungera",
        "Reflektera över processens slutprodukter"
      ]`
          : ""
      }
    }]`,

      "current-events": `[{
      "question": "Who won the FIFA World Cup in 2018?",
      "options": ["Brazil", "France", "Germany"],
      "correct": "France",
      ${
        includeHints
          ? `"hints": [
        "This team also won their first World Cup in 1998",
        "Their star player Kylian Mbappé was only 19 during this tournament",
        "They defeated Croatia in the final"
      ]`
          : ""
      }
    }]`,
    };

    let instructions = "";
    const hintInstruction = includeHints
      ? `Each question should include a 'hints' array with exactly 3 progressively more specific hints that help lead to the correct answer. The hints should be challenging enough that students need to think and connect information, but not so obvious that they give away the answer immediately. Make the hints creative and thought-provoking.`
      : "";

    if (questionType === "fill-in-the-blank") {
      instructions = `Generate a fill in the blanks quiz with ${numQuestions} questions based on the provided text. Everything should be in ${selectedLanguage}. Each question should be a JSON object with a 'question' field containing the text with a blank (_), an 'options' field containing 4 options, and a 'correct' field. ${hintInstruction} Ensure all questions are returned in a single JSON array. Format example: ${formatExamples["fill-in-the-blank"]}`;
    } else if (questionType === "true-or-false") {
      instructions = `Generate a true or false quiz with ${numQuestions} questions. The quiz should be in ${selectedLanguage}. Each question should be a JSON object with a "question" field, an "options" field containing only "True" and "False" as options, and a "correct" field. ${hintInstruction} Ensure all questions are returned in a single JSON array. Do not use any markdown formatting. Ensure that the JSON keys 'question', 'options', and 'correct' are kept in English, even when the content is in another language. Format example: ${formatExamples["true-or-false"]}`;
    } else if (questionType === "exam") {
      instructions = `Generate an exam quiz with ${numQuestions} questions. The quiz should be in ${selectedLanguage}. Each question should be a JSON object with a "question" field, a "type" field set to "exam", and an "answer" field containing the model answer. ${hintInstruction} Ensure all questions are returned in a single JSON array. Format example: ${formatExamples["exam"]} Ensure all questions are returned in a single JSON array. The questions should be open-ended and require written answers. Do not use any markdown formatting.`;
    } else if (questionType === "current-events") {
      instructions = `Generate a quiz with ${numQuestions} questions. Each question should be EXACTLY 4 sentences long and worded in a engaging way. Se till att svarsalternativen är balanserade och realistiska för att göra frågan mer utmanande. The quiz should be in ${selectedLanguage}. Each question should be a JSON object with:
- A "question" field 
- An "options" field containing exactly 3 options, each consisting of EXACTLY 1 OR MAXIMUM 2 words
- A "correct" field with the right answer
${hintInstruction}
Ensure all questions are returned in a single JSON array.`;
    } else {
      instructions = `Generate a quiz with ${numQuestions} questions. The quiz should be in ${selectedLanguage}. Each question should be a JSON object with a "question" field, an "options" field containing 4 options, and a "correct" field. ${hintInstruction} Ensure all questions are returned in a single JSON array. Do not use any markdown formatting. Ensure that the JSON keys 'question', 'options', and 'correct' are kept in English, even when the content is in another language. Format example: ${formatExamples["default"]}`;
    }

    if (category && category !== "Välj kategori") {
      instructions += ` The quiz should be focused on the category: ${category}.`;
    }
    if (difficulty) {
      instructions += ` The difficulty level should be ${difficulty}.`;
    }
    if (aiInstructions) {
      instructions += ` Additional instructions: ${aiInstructions}`;
    }

    let messages = "";
    if (selectedModel === "Claude") {
      messages = [
        { role: "user", content: `${instructions}\n\nTopic: ${content}` },
      ];
    } else {
      messages = [
        { role: "system", content: instructions },
        { role: "user", content: `Topic: ${content}` },
      ];
    }

    Logger.log("message to api: ", messages);

    let stopProgressSimulation = null;

    try {
      stopProgressSimulation = simulateProgress(0, 90, 15000);
      let response = "";

      if (selectedModel === "Claude")
        response = await callApi(messages, 4000, true, user.uid);
      else response = await callApi(messages, 4000, true, user.uid);

      Logger.log("response: ", response);

      let messageContent = "";
      // if (selectedModel === 'Claude')
      //   messageContent = response.data.content[0].text;
      // else
      messageContent = response.data.choices[0].message.content.trim();

      Logger.log("API response content: ", messageContent);

      if (messageContent.startsWith("```json")) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith("```")) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      // Escapea dubbla citattecken inom strängar
      messageContent = messageContent.replace(
        /(:\s*")(.+?)(?=")/g,
        function (match, p1, p2) {
          return p1 + p2.replace(/"/g, '\\"');
        },
      );

      let parsedResponse;
      try {
        parsedResponse = JSON.parse(messageContent);

        Logger.log("Total tokens for quiz:", response.data.usage.total_tokens);
        updateUserTokens(user.uid, response.data.usage.total_tokens);
      } catch (parseError) {
        console.error("Error parsing JSON response:", parseError);
        return [];
      }

      Logger.log("Parsed Response: ", JSON.stringify(parsedResponse, null, 2));

      if (stopProgressSimulation) {
        stopProgressSimulation();
      }

      setProgress(100);

      return parsedResponse;
    } catch (error) {
      console.error("Error fetching quiz:", error);

      showSnackbar("Knakar i fogarna: Försök generera quizzet igen.", "info");
      handleNewQuiz();
      return [];
    } finally {
      //updateProgress(0);
    }
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const handlePublicChange = (event) => {
    setIsPublic(event.target.checked);
  };
  useEffect(() => {
    Logger.log("questionType: ", questionType);
  }, [questionType]);
  useEffect(() => {
    setCurrentQuestionNumber(currentQuestionIndex + 1);
  }, [currentQuestionIndex]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "inputType") {
      setInputType(value);
    } else if (name === "quizName") {
      setQuizName(value);
    }
    setSelectedValue(e.target.value);
  };

  const generateRandomQuizName = () => {
    // const prefix = selectedSuggestionRef.current.slice(0, 10);
    // const randomId = Math.floor(1000 + Math.random() * 9000);
    // return `${prefix}-${randomId}`;
    return selectedSuggestionRef.current;
  };

  const loadQuiz = async (generateQuestionsInDialog = false) => {
    if (!quizName.trim()) {
      showSnackbar("Vänligen ange ett quiz-namn.", "warning");
      return;
    }

    let content;

    if (lessonTheme === "youtube") {
      content = transcriptRef.current;
    } else if (lessonTheme === "customTheme") {
      content = customTheme;
    } else if (lessonTheme === "predefined") {
      content = predefinedTheme;
    } else if (lessonTheme === "fileUpload") {
      content = uploadedFileRef.current;
    } else if (lessonTheme === "wikipedia") {
      content = wikipediaContentRef.current;
    } else if (lessonTheme === "randomizer") {
      content = randomizedContentRef.current;
    } else if (lessonTheme === "book") {
      content = bookContentRef.current;
    } else if (lessonTheme === "url") {
      content = urlContentRef.current;
    } else {
      //
      content = coursePlanContentRef.current;
    }

    Logger.log("themeToUse: ", content);
    if (!content?.trim()) {
      showSnackbar("Du måste välja underlag i steg 1.", "warning");
      return;
    }
    if (generateQuestionsInDialog) {
      setLoadingQuestions(true);
    } else {
      setLoadingQuiz(true);
    }

    setCurrentQuizId(null); // Reset currentQuizId before loading a new quiz

    try {
      setTimeout(() => {
        const progressBar = document.querySelector(".progress-bar-container");
        if (progressBar) {
          progressBar.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      }, 500);

      await trackLesson(user.uid, "QuizView.js", null, {
        lessonTheme: lessonTheme,
      });

      const fetchedQuiz = await fetchQuiz(
        inputType,
        content,
        numQuestions,
        category,
        difficulty,
        aiInstructions,
        includeHints,
      );
      Logger.log("user.accountType: ", user.accountType);
      if (generateQuestionsInDialog) {
        setIsGeneratingQuestionsInDialog(true);

        setQuiz(fetchedQuiz);

        showSnackbar("Quiz genererat!", "success");
      } else {
        // Generate a quiz name if it's not provided
        let quizNameToSave = quizName || generateRandomQuizName();
        const sanitizedQuizName = sanitizeInput(
          quizName || generateRandomQuizName(),
        );
        // Save the quiz to the database
        const docRef = await addDoc(collection(db, "quiz"), {
          userId: user.uid,
          results: fetchedQuiz,
          quizName: sanitizedQuizName,
          createdAt: Date.now(),
          byAdmin: "No",
          isPublic: isPublic,
          questionType: questionType,
          examResults: null,
          category,
          difficulty,
          aiInstructions,
          lastTrainingSession: Date.now(),
          language: selectedLanguage,
          aiModel: selectedModel,
          upvotes: 0,
          downvotes: 0,
          userVotes: {},
          includeTimer: includeTimer,
          duration: duration,
        });

        Logger.log("Quiz saved with ID: ", docRef.id);

        setQuiz(fetchedQuiz);
        setQuizInProgress(true);
        setCurrentQuizId(docRef.id);

        // Navigate to the new quiz URL
        const formattedQuizName = formatUrlPart(sanitizedQuizName);
        navigate(`/app/quiz/${docRef.id}/${formattedQuizName}`);
      }
    } catch (error) {
      console.error("Error generating and saving quiz:", error);
      showSnackbar("Det uppstod ett fel vid generering av quizet.", "error");
    } finally {
      setLoadingQuiz(false);
      setLoadingQuestions(false);
      setProgress(0);
      if (!isGeneratingQuestionsInDialog) {
        setTimeout(() => {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }, 500);
      }
    }
  };

  const loadQuizFromParams = async (id) => {
    try {
      let docSnap;
      const docRef = doc(db, "quiz", id);
      docSnap = await getDoc(docRef);

      // If exact match fails, try partial match
      if (!docSnap.exists()) {
        const q = query(
          collection(db, "quiz"),
          where("__name__", ">=", id),
          where("__name__", "<=", id + "\uf8ff"),
          limit(1),
        );

        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          docSnap = querySnapshot.docs[0];
        }
      }

      if (docSnap?.exists()) {
        const quizData = docSnap.data();

        const includeTimerValue = quizData.includeTimer || false;
        setIncludeTimer(includeTimerValue);

        Logger.log("quizData: ", JSON.stringify(quizData, null, 2));

        if (!quizData.results || quizData.results.length === 0) {
          showSnackbar(
            "Något gick fel. Försök igen eller generera nytt.",
            "info",
          );
          handleNewQuiz();
          return;
        }

        //Moonshine: ej säker på denna raden jag la till ,kanske ta bort
        setQuiz(quizData);

        if (quiz) Logger.log("quizzz: ", quizData.createdAt);
        setQuizCreatedAt(quizData.createdAt);

        handleQuizLoad({ id, ...quizData });

        setViewIsLoading(false);

        setDynamicTitle(`${quizData.quizName} — Quiz — Studera.AI`);
        setDynamicDescription(
          `Testa din kunskap i vårt quiz om ${quizData.quizName}. Svårighetsgraden är ${quizData.difficulty}.`,
        );

        if (quizData.originalQuizId) {
          setOriginalQuizId(quizData.originalQuizId);
        } else {
          setOriginalQuizId(quizId);
        }

        // Fetch creator info
        if (quizData.userId) {
          const userIdToFetch =
            quizData.isCopied && quizData.originalUserId
              ? quizData.originalUserId
              : quizData.userId;

          const userDoc = await getDoc(doc(db, "users", userIdToFetch));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setCreatorInfo({
              displayName: userData.displayName || userData.email || "Unknown",
              avatarUrl: userData.photoURL || null,
              upvotes: quizData.upvotes || 0,
            });
          }
        }

        // Update URL if the name doesn't match
        const formattedQuizName = formatUrlPart(quizData.quizName);
        if (formattedQuizName !== urlQuizName) {
          navigate(`/app/quiz/${id}/${formattedQuizName}`, { replace: true });
        }
      } else {
        showSnackbar("No such quiz!", "info");
        // Optionally, navigate away or show a different view
      }
    } catch (error) {
      console.error("Error loading quiz from params:", error);
      showSnackbar("Error loading quiz. Please try again.", "error");
      // Optionally, navigate away or show a different view
    }
  };

  useEffect(() => {
    //loadQuizzes();
    if (quizId) {
      loadQuizFromParams(quizId);
    } else {
      setViewIsLoading(false);
    }
  }, [user, quizId]);

  useEffect(() => {
    if (quiz.length > 0) {
      setCurrentQuestionIndex(0);
      setSelectedOption(null);
      setResults(
        quiz.map((q) => ({ ...q, selectedOption: null, isCorrect: false })),
      );
      setShowSummary(false);
    }
  }, [quiz]);

  useEffect(() => {
    Logger.log(`selectedOption updated: ${selectedOption}`);
  }, [selectedOption]);

  const handleQuizUpdate = (updatedQuiz) => {
    setQuiz(updatedQuiz);
  };

  const handleQuizLoad = (quiz) => {
    toggleDrawer(false)();

    Logger.log("Loading quiz: ", quiz);
    setQuiz(quiz.results);
    setQuizName(quiz.quizName);
    setCurrentQuizId(quiz.id);
    setCurrentQuestionIndex(0);
    setSelectedOption(null);
    setResults([]);
    setShowSummary(false);
    setQuizInProgress(true);

    const formattedQuizName = formatUrlPart(quiz.quizName);
    navigate(`/app/quiz/${quiz.id}/${formattedQuizName}`);
  };

  if (viewIsLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Helmet>
        <title>{dynamicTitle}</title>
        <meta name="description" content={dynamicDescription} />
      </Helmet>

      <Fade in={!viewIsLoading}>
        <Box sx={{ ...styles.container, textAlign: "left" }}>
          <Box
            sx={{
              ...styles.innerContainer,
            }}
          >
            <DrawerComponent
              user={user}
              theme={theme}
              open={drawerOpen}
              onClose={toggleDrawer(false)}
            />

            <Box
              sx={{
                ...styles.buttonsContainer,
                ...styles.additionalButtonsContainer,
              }}
            >
              {isMobile ? (
                <Button
                  variant="contained"
                  color="primary"
                  disableRipple
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/app/alla-quiz");
                  }}
                  startIcon={
                    <ExploreIcon sx={{ ...styles.btnContainerIconColor }} />
                  }
                  sx={styles.shrinkButton}
                />
              ) : (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/app/alla-quiz");
                    }}
                    startIcon={
                      <ExploreIcon sx={{ ...styles.btnContainerIconColor }} />
                    }
                    sx={{ display: "none" }}
                  >
                    {" "}
                    Alla quiz{" "}
                  </Button>
                </>
              )}

              {quizInProgress ||
                (showSummary &&
                  (isMobile ? (
                    <Button
                      variant="contained"
                      color="primary"
                      disableRipple
                      onClick={handleNewQuiz}
                      startIcon={<AddIcon />}
                      sx={styles.shrinkButton}
                    />
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNewQuiz}
                      startIcon={<AddIcon />}
                      sx={{ display: "none" }}
                    >
                      {" "}
                      Nytt quiz{" "}
                    </Button>
                  )))}

              <Button
                variant="contained"
                color="primary"
                disableRipple
                onClick={toggleDrawer(true)}
                startIcon={<SegmentIcon />}
                sx={{
                  padding: "8px",
                  marginRight: "0px",
                  paddingLeft: "14px",
                  paddingRight: "3px",
                  minWidth: "auto",
                  textTransform: "none",
                  display: "none",
                }}
              />
            </Box>

            {showSummary && (
              <QuizResults
                results={results}
                currentQuizId={currentQuizId}
                userId={user.uid}
                theme={theme}
                styles={styles}
                setShowSummary={setShowSummary}
              />
            )}

            {!quizInProgress && !showSummary && showCreateQuizForm && (
              <>
                <Box
                  sx={{ width: { xs: "100%", md: "100%" }, margin: "0 auto" }}
                >
                  <Typography
                    variant="h1"
                    gutterBottom
                    sx={{
                      fontWeight: "bold",
                      fontSize: "1.8em",
                      mb: 3,
                      mt: 6,
                      textAlign: "center",
                    }}
                  >
                    Quiz
                  </Typography>

                  <InfoBox
                    icon={QuizIcon}
                    title="Quiz"
                    instructions={[
                      "Välj underlag till ditt quiz, eller skriv in manuella instruktioner",
                      "Välj att generera frågor, redigera & anpassa, dela med elever, spara eller skriv ut",
                      "Se din historik över resultat, senaste träningstillfället och mycket mer",
                    ]}
                    theme={theme}
                    styles={styles}
                  />

                  <ThemeSelectorFlow
                    value={lessonTheme}
                    onChange={(e) => setLessonTheme(e.target.value)}
                    customTheme={customTheme}
                    onCustomThemeChange={(e) => setCustomTheme(e.target.value)}
                    onBookContentChange={(content) => {
                      Logger.log(
                        "onBookContentChange",
                        content.substring(0, 100),
                      );
                      bookContentRef.current = content;
                    }}
                    onUrlContentChange={(content) => {
                      Logger.log(
                        "Parent: URL content received",
                        content.substring(0, 300),
                      );
                      urlContentRef.current = content;
                    }}
                    onPredefinedThemeChange={(e) =>
                      setPredefinedTheme(e.target.value)
                    }
                    onYoutubeTranscriptChange={(transcriptContent) => {
                      Logger.log(
                        "onYoutubeTranscriptChange körs med: ",
                        transcriptContent,
                      );
                      transcriptRef.current = transcriptContent;
                    }}
                    onFileUpload={(content) => {
                      Logger.log("onFileUpload körs med: ", content);
                      uploadedFileRef.current = content;
                    }}
                    onWikipediaContentChange={(content) => {
                      Logger.log(
                        "Parent: Wikipedia content received",
                        content.substring(0, 100),
                      );
                      wikipediaContentRef.current = content;
                    }}
                    onRandomizedContentChange={(content) => {
                      Logger.log(
                        "randomizedContentRef",
                        content.substring(0, 100),
                      );
                      randomizedContentRef.current = content;
                    }}
                    onCoursePlanContentChange={(content) => {
                      Logger.log(
                        "onCoursePlanChange",
                        content.substring(0, 100),
                      );
                      coursePlanContentRef.current = content;
                    }}
                    lessonType="Quiz"
                    user={user}
                    theme={theme}
                    styles={styles}
                    additionalThemes={additionalThemes}
                    forQuiz={true}
                  />
                </Box>

                <Box sx={styles.headlineLessonBox}>
                  <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
                    2. Välj typ av frågor
                  </Typography>
                </Box>
                <Box
                  sx={{
                    ...styles.freshBox,
                    padding: { xs: "10px", md: "30px" },
                    position: "relative",
                  }}
                >
                  <Box>
                    <QuestionTypeSelector
                      selected={questionType}
                      setQuestionType={setQuestionType}
                      disableTrueFalse={true}
                      theme={theme}
                      user={user}
                    />
                  </Box>
                </Box>
                <Box sx={{ ...styles.headlineLessonBox, mt: 8 }}>
                  <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
                    3. Generera quiz
                  </Typography>
                </Box>
                <Box
                  sx={{
                    ...styles.freshBox,
                    padding: { xs: "10px", md: "50px" },
                    position: "relative",
                  }}
                >
                  <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                      <Typography
                        variant="formLabelSinUnderline"
                        sx={{ mt: 4, fontWeight: "500", display: "block" }}
                      >
                        Namn på ditt quiz
                      </Typography>

                      <TextField
                        placeholder="T.ex. Bröderna Karamazov av Dostojevskij"
                        name="quizName"
                        value={quizName}
                        onChange={handleInputChange}
                        variant="outlined"
                        sx={{ mt: 1, width: "100%", mb: 4 }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Typography
                        variant="formLabelSinUnderline"
                        sx={{ fontWeight: "500", mt: 4, display: "block" }}
                      >
                        Språk
                      </Typography>
                      <Box sx={{ mt: 1 }}>
                        <LanguageSelector
                          language={selectedLanguage}
                          setLanguage={setSelectedLanguage}
                        />
                      </Box>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={6}
                      sx={{ mt: 0, paddingTop: "0px !important" }}
                    >
                      <FormControl fullWidth>
                        <Typography
                          variant="formLabelSinUnderline"
                          sx={{ fontWeight: "500", mb: 0 }}
                        >
                          Antal frågor
                          {questionType === "current-events" && (
                            <Tooltip
                              title="För 1X2-frågor är 7 frågor standard"
                              arrow
                            >
                              <QuestionMarkIcon
                                sx={{
                                  fontSize: 16,
                                  ml: 1,
                                  verticalAlign: "middle",
                                }}
                              />
                            </Tooltip>
                          )}
                        </Typography>
                        <Select
                          labelId="num-questions-label"
                          id="num-questions-select"
                          value={numQuestions}
                          sx={{ mt: 1 }}
                          onChange={(e) => setNumQuestions(e.target.value)}
                          disabled={questionType === "current-events"}
                        >
                          <MenuItem value={2} selected>
                            2
                          </MenuItem>
                          <MenuItem value={5}>5</MenuItem>
                          <MenuItem value={7}>7</MenuItem>
                          <MenuItem value={10}>10</MenuItem>

                          <Tooltip
                            title="Endast tillgängligt för premium"
                            arrow
                          >
                            <span>
                              <MenuItem value={15} disabled>
                                15
                              </MenuItem>
                              <MenuItem value={20} disabled>
                                20
                              </MenuItem>

                              <MenuItem value={25} disabled>
                                25
                              </MenuItem>
                              <MenuItem value={30} disabled>
                                30
                              </MenuItem>
                              <MenuItem value={40} disabled>
                                40
                              </MenuItem>
                              <MenuItem value={50} disabled>
                                50
                              </MenuItem>
                            </span>
                          </Tooltip>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={6}
                      sx={{ mt: 0, paddingTop: "0px !important" }}
                    >
                      <Typography
                        variant="formLabelSinUnderline"
                        sx={{ fontWeight: "500", mb: 1, display: "block" }}
                      >
                        Inkludera ledtrådar
                      </Typography>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          backgroundColor:
                            theme.palette.mode === "dark"
                              ? "#394653"
                              : "#f5f5f5",
                          borderRadius: "8px",
                          mb: 4,
                          mt: 0,
                          padding: 1,
                          paddingLeft: "15px",
                        }}
                      >
                        <Typography variant="formLabelSinUnderline">
                          {includeHints
                            ? "3 ledtrådar per fråga"
                            : "Nej, tack. Inga ledtrådar."}
                        </Typography>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Switch
                            checked={includeHints}
                            onChange={(e) => setIncludeHints(e.target.checked)} // This is the correct way
                            inputProps={{ "aria-label": "include hints" }}
                          />
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>

                  <Collapse in={showMoreOptions}>
                    <AdditionalFields
                      aiInstructions={aiInstructions}
                      setAiInstructions={setAiInstructions}
                      numQuestions={numQuestions}
                      setNumQuestions={setNumQuestions}
                      category={category}
                      setCategory={setCategory}
                      difficulty={difficulty}
                      setDifficulty={setDifficulty}
                      questionType={questionType}
                      setQuestionType={setQuestionType}
                      selectedLanguage={selectedLanguage}
                      setSelectedLanguage={setSelectedLanguage}
                      isPublic={isPublic}
                      handlePublicChange={handlePublicChange}
                      theme={theme}
                      selectedModel={selectedModel}
                      setSelectedModel={setSelectedModel}
                      includeHints={includeHints}
                      setIncludeHints={setIncludeHints}
                      duration={duration}
                      setDuration={setDuration}
                      includeTimer={includeTimer}
                      setIncludeTimer={setIncludeTimer}
                    />
                  </Collapse>
                  <Box className="progress-bar-container">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "12px",
                        width: "100%",
                      }}
                    >
                      <Tooltip title="Starta ett interaktivt quiz.">
                        <Button
                          variant={
                            theme.palette.mode === "light"
                              ? "purp"
                              : "contained"
                          }
                          color="primary"
                          onClick={() => loadQuiz(false)}
                          sx={{ mt: 5 }}
                          disabled={loadingQuiz || loadingQuestions}
                          startIcon={
                            loadingQuiz ? (
                              <CircularProgress
                                sx={{ color: "white" }}
                                size={18}
                              />
                            ) : (
                              <PlayArrowIcon />
                            )
                          }
                        >
                          {loadingQuiz ? "Genererar quiz..." : "Quizza mig"}
                        </Button>
                      </Tooltip>
                      <Typography
                        sx={{
                          mt: 5,
                          mx: 4,
                          display: "flex",
                          alignItems: "center",
                          position: "relative",
                          fontWeight: "500",
                          fontSize: "0.9rem",
                          "&::before, &::after": {
                            content: '""',
                            position: "absolute",
                            top: "50%",
                            width: { xs: "0%", md: "40%" },
                            height: "1px",
                            backgroundColor: (theme) =>
                              theme.palette.mode === "dark"
                                ? "grey.800"
                                : "grey.500",
                          },
                          "&::before": { left: "43px" },
                          "&::after": { right: "43px" },
                        }}
                      >
                        ELLER
                      </Typography>
                      <Tooltip title="Generera frågor som du kan redigera utifrån dina specifika behov, spara, skriva ut eller dela med elever.">
                        <Button
                          variant={
                            theme.palette.mode === "light"
                              ? "success"
                              : "contained"
                          }
                          color="success"
                          onClick={() => loadQuiz(true)}
                          sx={{ mt: 5 }}
                          disabled={loadingQuiz || loadingQuestions}
                          startIcon={
                            loadingQuestions ? (
                              <CircularProgress
                                sx={{ color: "white" }}
                                size={18}
                              />
                            ) : (
                              <ListAltIcon />
                            )
                          }
                        >
                          {loadingQuestions
                            ? "Genererar frågor..."
                            : "Generera & redigera"}
                        </Button>
                      </Tooltip>

                      <Button
                        variant="print"
                        disableRipple
                        startIcon={
                          showMoreOptions ? (
                            <ExpandLessIcon />
                          ) : (
                            <SettingsIcon />
                          )
                        }
                        onClick={() => setShowMoreOptions(!showMoreOptions)}
                        sx={{
                          position: "absolute",
                          right: "30px",
                          bottom: "30px",

                          marginRight: "0px",
                          paddingLeft: "20px",
                          paddingRight: "8px",
                          paddingTop: "14px",
                          paddingBottom: "14px",
                          minWidth: "auto",
                          textTransform: "none",
                        }}
                      ></Button>
                    </div>

                    {/* After your generate quiz button */}
                    {isGeneratingQuestionsInDialog &&
                      quiz.length > 0 &&
                      !loadingQuestions && (
                        <div ref={teacherQuizDisplayRef}>
                          <TeacherQuizDisplay
                            includeTimer={includeTimer}
                            duration={duration}
                            user={user}
                            quiz={quiz}
                            quizId={currentQuizId}
                            onQuizUpdate={handleQuizUpdate}
                            quizName={quizName}
                            questionType={questionType}
                            styles={styles}
                            category={category}
                            difficulty={difficulty}
                            aiInstructions={aiInstructions}
                            selectedLanguage={selectedLanguage}
                            selectedModel={selectedModel}
                            onSave={handleSave}
                            reloadMyQuizzes={() =>
                              setMyQuizzesReloadTrigger((prev) => prev + 1)
                            }
                            onShare={() => setShareDialogOpen(true)}
                            onShareWithFriends={() =>
                              setShareDialogOpenFriends(true)
                            }
                            onPrint={() =>
                              questionType === "current-events"
                                ? handlePrint1X2Quiz(
                                    quiz,
                                    quizName,
                                    questionType,
                                    theme.palette.primary.main,
                                  )
                                : handlePrintQuiz(
                                    quiz,
                                    quizName,
                                    questionType,
                                    theme.palette.primary.main,
                                  )
                            }
                          />
                        </div>
                      )}

                    {(loadingQuiz || loadingQuestions) && (
                      <>
                        <Box sx={{ mt: 6 }}>
                          <ProgressBar
                            progress={progress}
                            label="Genererar quiz — tar 30-60 sekunder..."
                          />
                        </Box>
                      </>
                    )}
                  </Box>
                </Box>

                <Box
                  sx={{
                    width: { xs: "100%", md: "100%" },
                    margin: { xs: undefined, md: "0 auto" },
                  }}
                >
                  <Box sx={styles.headlineLessonBox}>
                    <Typography
                      variant="h6"
                      sx={{ ...styles.headlineLessonBoxTitle, mt: 15 }}
                    >
                      Mina quiz
                    </Typography>
                  </Box>

                  <Box sx={{ ...styles.freshBox, padding: "0px" }}>
                    <MyQuizzes
                      user={user}
                      theme={theme}
                      styles={styles}
                      reloadTrigger={myQuizzesReloadTrigger}
                    />
                  </Box>
                </Box>
                {/*
                <QuizExplanation theme={theme} />
                */}
              </>
            )}

            {quiz.length > 0 && quizInProgress && (
              <QuizInProgress
                user={user}
                quiz={quiz}
                setQuiz={setQuiz}
                quizName={quizName}
                currentQuestionIndex={currentQuestionIndex}
                setCurrentQuestionIndex={setCurrentQuestionIndex}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                results={results}
                setResults={setResults}
                setShowSummary={setShowSummary}
                setQuizInProgress={setQuizInProgress}
                bookmarks={bookmarks}
                setBookmarks={setBookmarks}
                currentQuizId={currentQuizId}
                creatorInfo={creatorInfo}
                quizCreatedAt={quizCreatedAt}
                originalQuizId={originalQuizId}
                theme={theme}
                styles={styles}
                includeTimer={includeTimer}
                setIncludeTimer={setIncludeTimer}
                duration={duration}
              />
            )}
          </Box>
        </Box>
      </Fade>
      <>
        <LoadingBackdrop
          isOpen={isSaving}
          imagePath="/img/AI.png"
          loadingText="Sparar ditt quiz..."
        />
        <LoadingBackdrop
          isOpen={isSharing}
          imagePath="/img/AI.png"
          loadingText="Delar quiz..."
        />
      </>
      <ShareDialog
        open={shareDialogOpen}
        onClose={() => setShareDialogOpen(false)}
        onShare={handleShare}
        user={user}
      />

      <ShareDialog
        open={shareDialogOpenFriends}
        onClose={() => setShareDialogOpenFriends(false)}
        onShare={handleShare}
        user={user}
        theme={theme}
        shareWithFriends={true}
      />
    </>
  );
};

export default QuizView;
