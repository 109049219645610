import React, { useState, useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import MyFlashcards from "./flashcards/MyFlashcards";
import { Link, useNavigate } from "react-router-dom";

const MyOwnFlashcards = ({ user, theme, styles }) => {
  const navigate = useNavigate();

  return (
    <>
      <Box sx={styles.container}>
        <Box sx={{ ...styles.innerContainer }}>
          <Box sx={styles.headlineLessonBox}>
            <Typography
              variant="h6"
              sx={{
                ...styles.headlineLessonBoxTitle,
                mt: 6,
                textAlign: "left",
              }}
            >
              Mina flashcards
            </Typography>
          </Box>
          <Box sx={{ ...styles.freshBox, padding: "0px" }}>
            <MyFlashcards
              user={user}
              theme={theme}
              styles={styles}
              showSharedFlashcards={true}
            />
          </Box>

          <Box
            sx={{
              ...styles.buttonsContainer,
              ...styles.additionalButtonsContainer,
            }}
          >
            <Button
              variant="purp"
              onClick={(e) => {
                e.preventDefault();
                navigate("/app/flashcards");
              }}
              startIcon={<AddIcon />}
            >
              Generera flashcards
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default MyOwnFlashcards;
