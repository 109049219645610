import React, { useState, useEffect, useRef } from "react";
import {
  Fab,
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  Alert,
  Collapse,
  IconButton,
  Zoom,
  CircularProgress,
} from "@mui/material";
import {
  Message as MessageIcon,
  Close as CloseIcon,
  Send as SendIcon,
  Email as EmailIcon,
} from "@mui/icons-material";

const API_URL =
  process.env.NODE_ENV === "production" ? "/api" : "http://localhost:3001/api";

const SupportWidget = ({ showSnackbar }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const widgetRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (widgetRef.current && !widgetRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!name || !email || !message) {
      showSnackbar("Vänligen fyll i alla fält", "warning");
      return;
    }

    if (!/\S+@\S+\.\S+/.test(email)) {
      showSnackbar("Vänligen ange en giltig e-postadress", "warning");
      return;
    }

    try {
      setIsSubmitting(true);
      const response = await fetch(`${API_URL}/send-email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          email,
          message,
        }),
      });

      if (response.ok) {
        showSnackbar("Tack för ditt meddelande!", "success");
        setMessage("");
        setEmail("");
        setName("");
        setIsOpen(false);
      } else {
        throw new Error("Failed to send");
      }
    } catch (error) {
      showSnackbar("Något gick fel. Försök igen senare.", "error");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box
      ref={widgetRef}
      sx={{
        position: "fixed",
        bottom: 24,
        right: 24,
        zIndex: 1000,
        display: { xs: "none", md: "block" },
      }}
    >
      <Zoom in={!isOpen}>
        <Fab
          color="primary"
          onClick={() => setIsOpen(true)}
          sx={{ display: isOpen ? "none" : "flex" }}
        >
          <MessageIcon />
        </Fab>
      </Zoom>

      <Collapse in={isOpen} timeout="auto">
        <Paper
          elevation={3}
          sx={{
            position: "absolute",
            bottom: 0,
            right: 0,
            width: 320,
            maxHeight: 500,
            borderRadius: 2,
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              bgcolor: "primary.main",
              color: "primary.contrastText",
              p: 2,
              position: "relative",
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: "600" }}>
              Kontakta oss
            </Typography>
            <Typography variant="body2" sx={{ opacity: 0.9 }}>
              Lämna ett meddelande till oss
            </Typography>
            <IconButton
              size="small"
              onClick={() => setIsOpen(false)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: "inherit",
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          <Box sx={{ p: 2 }}>
            <form onSubmit={handleSubmit}>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <TextField
                  fullWidth
                  placeholder="Namn"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                  size="small"
                  inputProps={{
                    sx: {
                      "&::placeholder": {
                        fontSize: "0.9rem",
                      },
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: 2,
                    },
                  }}
                />
                <TextField
                  fullWidth
                  placeholder="E-post"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  size="small"
                  inputProps={{
                    sx: {
                      "&::placeholder": {
                        fontSize: "0.9rem",
                      },
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: 2,
                    },
                  }}
                />
                <TextField
                  fullWidth
                  placeholder="Meddelande"
                  multiline
                  rows={3}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  inputProps={{
                    sx: {
                      "&::placeholder": {
                        fontSize: "0.9rem",
                      },
                    },
                  }}
                  required
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: 2,
                    },
                  }}
                />
                <Button
                  type="submit"
                  variant="purp"
                  fullWidth
                  disabled={isSubmitting}
                  endIcon={
                    isSubmitting ? (
                      <CircularProgress size={16} color="inherit" />
                    ) : (
                      <SendIcon />
                    )
                  }
                >
                  Skicka meddelande
                </Button>
              </Box>
            </form>
          </Box>

          <Box
            sx={{
              borderTop: 1,
              borderColor: "divider",
              p: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 1,
            }}
          >
            <EmailIcon fontSize="small" color="action" />
            <Typography variant="body2" color="text.secondary">
              Måndag–fredag kl. 08–17
            </Typography>
          </Box>
        </Paper>
      </Collapse>
    </Box>
  );
};

export default SupportWidget;
