import React from "react";
import { Box, Typography, Chip } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { lighten, darken } from "@mui/material/styles";
import WordIcon2019 from "./WordIcon2019";
import WikipediaIcon from "./WikipediaIcon";
const ThemeFlowBox = ({
  icon: Icon,
  title,
  subtitle,
  onClick,
  diffView = false,
  isSelected = false,
  // Add new prop for image
  iconImage,
  clickable = true,
  label,
}) => {
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";
  const isAllakando = theme.palette.themeType === "allakando";

  const getIconSize = (Icon, diffView) => {
    switch (Icon) {
      case WordIcon2019:
        return diffView ? 35 : 20;
      case WikipediaIcon:
        return diffView ? 48 : 28;
      default:
        return diffView ? 40 : 24;
    }
  };

  const getLabelStyle = (labelText) => {
    if (isAllakando) {
      return {
        backgroundColor: theme.palette.label.background,
        color: theme.palette.label.text,
      };
    }

    if (labelText === "NYTT") {
      return {
        // backgroundColor: isDark ? "#2B4C2A" : "#C5F2B8",
        // color: isDark ? "#3bbd28" : "#249314",

        backgroundColor: isDark ? "#300000" : "#FFCDD2",
        color: isDark ? "#FF5252" : "#B71C1C",
      };
    }

    if (labelText === "UPPDATERAD") {
      return {
        backgroundColor: isDark ? "#4C2B4C" : "#F2B8F2",
        color: isDark ? "#bd28bd" : "#931493",
      };
    }

    if (labelText === "INTERAKTIV") {
      return {
        backgroundColor: isDark ? "#300000" : "#FFCDD2",
        color: isDark ? "#FF5252" : "#B71C1C",
      };
    }
    return {
      backgroundColor: isDark ? "#2a3b4c" : "#e0e7ff",
      color: isDark ? "#60a5fa" : "#3b82f6",
    };
  };

  // Create IconComponent that handles both image and icon cases
  const IconComponent = () => {
    if (isSelected) {
      return (
        <CheckCircleIcon
          className="icon"
          sx={{
            fontSize: diffView ? 40 : 24,
            color: "#26b035",
            marginRight: diffView ? 0 : 2,
            marginBottom: diffView ? 1 : 0,
            transition: "color 0.3s ease",
          }}
        />
      );
    }

    if (iconImage) {
      return (
        <Box
          component="img"
          src={iconImage}
          alt={title}
          className="icon"
          sx={{
            width: diffView ? 37 : 20,
            height: diffView ? 37 : 20,
            objectFit: "contain",
            marginRight: diffView ? 0 : 2,
            marginBottom: diffView ? 1 : 0,
            borderRadius:
              title === "Google Classroom" ||
              title === "Kulturrådets boklista" ||
              title === "SO-rummet"
                ? "7px"
                : "100%",
          }}
        />
      );
    }

    return (
      <Icon
        className="icon"
        sx={{
          fontSize: getIconSize(Icon, diffView),
          color: (theme) => theme.palette.text.main,
          marginRight: diffView ? 0 : 2,
          marginBottom: diffView ? 1 : 0,
          transition: "color 0.3s ease",
        }}
      />
    );
  };

  return (
    <Box
      onClick={onClick}
      sx={{
        backgroundColor: isSelected
          ? isDark
            ? "rgba(139, 92, 246, 0.15)"
            : "rgba(38, 176, 53, 0.12)"
          : isDark
            ? "rgba(255, 255, 255, 0.05)"
            : "rgba(223, 222, 225, 0.05)",
        borderRadius: "12px",
        padding: "16px",

        height: diffView ? "185px" : "60px",
        position: "relative",

        cursor: clickable ? "pointer" : "not-allowed",
        filter: !clickable
          ? "blur(2px) brightness(0.95) saturate(0.8)"
          : "none",
        pointerEvents: !clickable ? "none" : "auto",

        border: isSelected
          ? "1px solid #26b035"
          : isAllakando
            ? "1px solid #e0e0e080"
            : isDark
              ? undefined
              : "1px solid #e0e0e080",
        "&:hover": {
          backgroundColor: isSelected
            ? "rgba(38, 176, 53, 0.12)"
            : isDark
              ? "rgba(255, 255, 255, 0.1)"
              : "rgb(251, 251, 251)",
          border: (theme) =>
            isSelected
              ? "1px solid #26b035"
              : isAllakando
                ? `1px solid ${theme.palette.text.main}`
                : isDark
                  ? undefined
                  : `1px solid ${theme.palette.text.main}`,
          "& .icon": {
            color: (theme) =>
              isSelected
                ? "#26b035"
                : isAllakando
                  ? `${darken(theme.palette.text.main, 0.2)}`
                  : isDark
                    ? "rgb(16, 151, 218)"
                    : `${darken(theme.palette.text.main, 0.2)}`,
          },
        },
      }}
    >
      {label && (
        <Chip
          label={label}
          size="small"
          sx={{
            position: "absolute",
            top: "8px",
            right: "8px",
            fontWeight: "600",
            fontSize: isAllakando ? "0.8rem" : "0.7rem",
            height: "20px",
            ...getLabelStyle(label),
          }}
        />
      )}
      <Box
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: diffView ? "column" : "row",
          alignItems: "center",
          justifyContent: diffView ? "center" : "flex-start",
        }}
      >
        <IconComponent />
        <Box
          sx={{
            textAlign: diffView ? "center" : "left",
            display: "flex",
            flexDirection: "column",
            alignItems: diffView ? "center" : "flex-start",
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{
              color: isSelected
                ? "#198e26"
                : isDark
                  ? "rgba(255, 255, 255, 0.7)"
                  : "#2c2f33",
              fontWeight: 600,
              fontSize: diffView ? 19 : 15,
              marginTop: diffView ? "5px" : undefined,
            }}
          >
            {title}
          </Typography>
          {subtitle && (
            <Typography
              variant="body2"
              sx={{
                color: isDark ? "rgba(255, 255, 255, 0.5)" : "#718096",
                fontSize: 14.5,
                fontWeight: "500",
                marginTop: diffView ? "10px" : undefined,
                width: diffView ? "80%" : undefined,
              }}
            >
              {subtitle}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ThemeFlowBox;
