import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Avatar,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
  IconButton,
  TextField,
  Tooltip,
  useTheme,
  Slider,
  Divider,
} from "@mui/material";
import GenerateIcon from "@mui/icons-material/AutoAwesome";
import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import ImageIcon from "@mui/icons-material/Image";
import ImageLoadingDialog from "./ImageLoadingDialog";
import PrintAsPdf from "./PrintAsPdf";
import PrintIcon from "@mui/icons-material/Print";
import WritingLessonDisplay from "./WritingLessonDisplay";
import ExercisesDialog from "./ExercisesDialog";
import { useSearchParams, useParams } from "react-router-dom";
import SlideInPaper from "./SlideInPaper";
import CustomInputSwitch from "./CustomInputSwitch";
import ShareDialog from "./ShareDialog";
import { handlePrintWritingLesson } from "./printFunctions";
import ShareLink from "./ShareLink";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import ArticleIcon from "@mui/icons-material/Article";
import ThemeFlowBox from "./ThemeFlowBox";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import Translate from "./Translate";
import { OrbitProgress } from "react-loading-indicators";
import { callApi } from "./api";
import { generateAndProcessImage, trackLesson } from "./functions";
import { useSnackbar } from "./SnackbarHandler";
import GradeSelector, { gradeInstructions } from "./GradeSelector";
import EditIcon from "@mui/icons-material/Edit";
import LanguageSelector from "./LanguageSelector";
import ShareIcon from "@mui/icons-material/Share";
import LoadingBackdrop from "./LoadingBackdrop";
import SubjectIcon from "@mui/icons-material/Subject";
import ImageGenerationModal from "./ImageGenerationModal";
import { getStyles } from "../index";
import { ThemeSelector } from "./ThemeSelector";
import { Mosaic } from "react-loading-indicators";
import InfoBox from "./InfoBox";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import Logger from "./Logger";
import LessonDrawer from "./LessonDrawer";
import SaveLessonDialog from "./SaveLessonDialog";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import HistoryIcon from "@mui/icons-material/History";
import HoverableImage from "./HoverableImage";

import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import {
  collection,
  query,
  where,
  limit,
  addDoc,
  updateDoc,
  getDocs,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "./firebase";
import { ThemeSelectorFlow } from "./ThemeSelectorFlow";

import {
  Science as ScienceIcon,
  Castle as CastleIcon,
  Psychology as PsychologyIcon,
  Pets as PetsIcon,
  Public as PublicIcon,
  EmojiEvents as EmojiEventsIcon,
  Museum as MuseumIcon,
  Nature as NatureIcon,
} from "@mui/icons-material";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import WritingTypeSelector from "./WritingTypeSelector";

const WritingLesson = ({ user }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);
  const [selectedWritingTypes, setSelectedWritingTypes] = useState({
    rhyme: false,
    poem: false,
    story: false,
  });

  const [words, setWords] = useState([]);
  const [isGeneratingLesson, setIsGeneratingLesson] = useState(false);
  const [isGeneratingImages, setIsGeneratingImages] = useState(false);
  const [generatedImages, setGeneratedImages] = useState(new Set());
  const [themeColor, setThemeColor] = useState("#8a59f4");
  const { showSnackbar } = useSnackbar();
  const [showThemeSelector, setShowThemeSelector] = useState(false);
  const [manualWords, setManualWords] = useState("");
  const [lessonData, setLessonData] = useState();
  const [themeType, setThemeType] = useState("generateWithAi");
  const [customTheme, setCustomTheme] = useState("");
  const [wordCount, setWordCount] = useState(5);
  const [lineCount, setLineCount] = useState(4);
  const [lineHeight, setLineHeight] = useState(50); // New state for line height (in pixels)
  const [grade, setGrade] = useState("åk6");
  const [language, setLanguage] = useState("svenska");

  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [isEditingSubtitle, setIsEditingSubtitle] = useState(false);
  const [titleText, setTitleText] = useState("Skrivlektion");

  const theme = useTheme();
  const styles = getStyles(theme);

  const [showImageModal, setShowImageModal] = useState(false);
  const [imageInstructions, setImageInstructions] = useState("");
  const [showControlPanel, setShowControlPanel] = useState(false);
  const bookContentRef = useRef("");
  const [isSharing, setIsSharing] = useState(false);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [shareDialogOpenFriends, setShareDialogOpenFriends] = useState(false);
  const [imageGenerationType, setImageGenerationType] = useState(null);

  const [generatingImageIndex, setGeneratingImageIndex] = useState(null);
  const [selectedWordIndex, setSelectedWordIndex] = useState(null);

  const [editingWordIndex, setEditingWordIndex] = useState(null);
  const [editedWord, setEditedWord] = useState("");

  const [lessonTheme, setLessonTheme] = useState("generateWithAi");
  const [predefinedTheme, setPredefinedTheme] = useState(null);
  const lessonTitleRef = useRef(null); // Add this ref
  const transcriptRef = useRef("");
  const uploadedFileRef = useRef("");

  const wikipediaContentRef = useRef("");
  const randomizedContentRef = useRef("");
  const coursePlanContentRef = useRef("");
  const urlContentRef = useRef("");
  const additionalThemes = [
    {
      type: "subheader",
      label: "Teman som lämpar sig extra bra för denna lektionstyp:",
    },
    {
      value: "selma",
      label: "Selma Lagerlöf",
      content: "Gör en lektion om selma lagerlöf",
      Icon: MenuBookIcon,
    },
    {
      value: "dronten",
      label: "Dronten",
      content: "Gör en lektion om den utdöda arten dronten",
      Icon: PetsIcon,
    },
    {
      value: "vikingar",
      label: "Vikingar",
      content: "Gör en lektion om vikingatiden",
      Icon: CastleIcon,
    },
    {
      value: "rymden",
      label: "Rymden",
      content: "Gör en lektion om vårt solsystem",
      Icon: PublicIcon,
    },
    {
      value: "dinosaurier",
      label: "Dinosaurier",
      content: "Gör en lektion om olika dinosaurier",
      Icon: NatureIcon,
    },
    {
      value: "nobel",
      label: "Nobelpriset",
      content: "Gör en lektion om nobelpriset och Alfred Nobel",
      Icon: EmojiEventsIcon,
    },
    {
      value: "uppfinnare",
      label: "Svenska uppfinnare",
      content: "Gör en lektion om kända svenska uppfinnare",
      Icon: ScienceIcon,
    },
    {
      value: "psychology",
      label: "Känslor",
      content: "Gör en lektion om olika känslor",
      Icon: PsychologyIcon,
    },
    {
      value: "konst",
      label: "Svensk konst",
      content: "Gör en lektion om svensk konsthistoria",
      Icon: MuseumIcon,
    },
  ];

  const [searchParams, setSearchParams] = useSearchParams();
  const subject = searchParams.get("subject") || null;
  const viewMode = searchParams.get("v") === "t";

  const handleEditTitle = () => {
    setIsEditingTitle(true);
  };
  const { lessonId } = useParams();

  useEffect(() => {
    const loadLessonFromId = async () => {
      if (lessonId) {
        try {
          const q = query(
            collection(db, "lesson_usage"),
            where("__name__", ">=", lessonId),
            where("__name__", "<=", lessonId + "\uf8ff"),
            where("lessonType", "==", "WritingLesson.js"),
            limit(1),
          );

          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            const docData = querySnapshot.docs[0].data();

            if (!docData.hasOpened) {
              await updateDoc(querySnapshot.docs[0].ref, {
                hasOpened: true,
                openedAt: Date.now(),
              });
            }

            setLessonData(docData.data);

            // Scroll to lesson content
            setTimeout(() => {
              lessonTitleRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }, 100);

            showSnackbar("Lektionen har laddats in.", "success");
          } else {
            showSnackbar("Kunde inte hitta lektionen", "error");
          }
        } catch (error) {
          console.error("Error loading lesson:", error);
          showSnackbar("Ett fel uppstod när lektionen skulle laddas", "error");
        }
      }
    };

    loadLessonFromId();
  }, [lessonId]);

  // useEffect(() => {
  //   const searchLessons = async () => {
  //     try {
  //       const lessonsRef = collection(db, "users");
  //       const snapshot = await getDocs(lessonsRef);

  //       const results = [];
  //       const searchTerm = "omniglot";

  //       snapshot.forEach((doc) => {
  //         const data = doc.data();

  //         if (
  //           data.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //           data.school?.toLowerCase().includes(searchTerm.toLowerCase())
  //         ) {
  //           const lastLogin = data.lastLogin
  //             ? new Date(data.lastLogin.seconds * 1000).toLocaleString("sv-SE")
  //             : "Never logged in";

  //           // Spara originalvärdet för seconds för sortering
  //           const lastLoginSeconds = data.lastLogin?.seconds || 0;

  //           const cleanResult = {
  //             displayName: data.displayName || "No display name",
  //             email: data.email || "No email",
  //             lastLogin: lastLogin,
  //             lastLoginSeconds: lastLoginSeconds, // För sortering
  //             school: data.school || "No school",
  //           };

  //           results.push(cleanResult);
  //         }
  //       });

  //       // Sortera på lastLoginSeconds (senast först)
  //       results.sort((a, b) => b.lastLoginSeconds - a.lastLoginSeconds);

  //       // Ta bort lastLoginSeconds från output
  //       const cleanResults = results.map(
  //         ({ lastLoginSeconds, ...rest }) => rest,
  //       );

  //       console.log(
  //         "Matches för sökordet '" +
  //           searchTerm +
  //           "' (sorterat efter senaste login):",
  //       );
  //       console.log(JSON.stringify(cleanResults, null, 2));
  //     } catch (error) {
  //       console.error("Error searching lessons:", error);
  //       showSnackbar("Ett fel uppstod vid sökningen", "error");
  //     }
  //   };

  //   searchLessons();
  // }, []);
  const handleShare = async (
    lessonComment,
    lessonName,
    selectedImage,
    tags,
    shareData,
  ) => {
    setIsSharing(true);
    await new Promise((resolve) => setTimeout(resolve, 1500));

    try {
      // Create a document for each student and then create notifications with the doc IDs
      const sharePromises = shareData.studentIds.map(async (studentId) => {
        const documentData = {
          userId: user.uid,
          shared: true,
          sharedTo: studentId,
          comment: shareData.comment,
          sharedAt: Date.now(),
          fileUrl: shareData.fileUrl,
          lessonType: "WritingLesson.js",
          timestamp: serverTimestamp(),
          lessonComment,
          lessonName,
          coverImage: selectedImage,
          hasPrinted: true,
          data: lessonData,
        };

        // Create the lesson document and get its ID
        const lessonDoc = await addDoc(
          collection(db, "lesson_usage"),
          documentData,
        );

        Logger.log(
          "Adding document for student",
          studentId,
          ":",
          JSON.stringify(documentData, null, 2),
        );

        // Create notification with the lesson doc ID
        await addDoc(collection(db, "notifications"), {
          type: "lesson_shared",
          recipientId: studentId,
          senderId: user.uid,
          read: false,
          createdAt: serverTimestamp(),
          lessonData: {
            lessonId: lessonDoc.id,
            lessonType: documentData.lessonType,
            lessonName: documentData.lessonName,
            fileUrl: documentData.fileUrl,
            comment: documentData.comment,
            coverImage: selectedImage,
          },
        });

        return lessonDoc;
      });

      await Promise.all(sharePromises);
      showSnackbar(`Du har nu delat lektionen!`, "success");
    } catch (error) {
      showSnackbar("Fel vid delande av lektionen: " + error.message, "error");
      console.error(error);
    } finally {
      setIsSharing(false);
    }
  };

  useEffect(() => {
    Logger.log("subject: ", subject);
  }, [subject]);

  useEffect(() => {
    Logger.log("lessonTheme: ", lessonTheme);
  }, [lessonTheme]);

  useEffect(() => {
    Logger.log("predefinedTheme: ", predefinedTheme);
  }, [predefinedTheme]);

  useEffect(() => {
    Logger.log("customTheme: ", customTheme);
  }, [customTheme]);

  useEffect(() => {
    Logger.log("youtubeTranscript: ", transcriptRef.current);
  }, [transcriptRef.current]);

  useEffect(() => {
    Logger.log("wikipediaContentRef: ", wikipediaContentRef.current);
  }, [wikipediaContentRef.current]);
  useEffect(() => {
    Logger.log("uploadedFileRef: ", uploadedFileRef.current);
  }, [uploadedFileRef.current]);

  useEffect(() => {
    Logger.log("randomizedContentRef: ", randomizedContentRef.current);
  }, [randomizedContentRef.current]);

  useEffect(() => {
    Logger.log("coursePlanContentRef: ", coursePlanContentRef.current);
  }, [coursePlanContentRef.current]);

  useEffect(() => {
    Logger.log("lessonData: ", JSON.stringify(lessonData, null, 2));
    const isDataValid =
      lessonData !== null &&
      lessonData !== undefined &&
      Object.keys(lessonData).length > 0 &&
      !Object.values(lessonData).includes("");

    if (isDataValid !== showControlPanel) {
      const timer = setTimeout(() => {
        setShowControlPanel(isDataValid);
      }, 100);

      return () => clearTimeout(timer);
    }
  }, [lessonData, showControlPanel]);

  const quizButtonRef = useRef(null);
  const timelineButtonRef = useRef(null);
  const timelineSimpleButtonRef = useRef(null);
  const comprehensionButtonRef = useRef(null);
  const panelRef = useRef();
  const translateButtonRef = useRef(null);
  const pdfButtonRef = useRef(null);
  const exerciseDialogButtonRef = useRef(null);

  const shareLinkButtonRef = useRef(null);
  const [isExpanded, setIsExpanded] = useState(false);

  // Add click handlers for the DashedPaper components
  const handleQuizPaperClick = () => {
    quizButtonRef.current?.click();
  };

  const handleTimelineSimplePaperClick = () => {
    timelineSimpleButtonRef.current?.click();
  };

  const handleComprehensionPaperClick = () => {
    comprehensionButtonRef.current?.click();
  };

  const handleTimelinePaperClick = () => {
    timelineButtonRef.current?.click();
  };

  const toggleViewMode = () => {
    const currentParams = Object.fromEntries(searchParams.entries());
    if (currentParams.v === "t") {
      delete currentParams.v;
    } else {
      currentParams.v = "t";
    }
    setSearchParams(currentParams);
  };

  const handleEditWord = (index) => {
    if (lessonData?.words?.[index]) {
      setEditingWordIndex(index);
      setEditedWord(lessonData.words[index].word);
    }
  };

  // In handleSaveWord
  const handleSaveWord = (index) => {
    if (lessonData?.words?.[index]) {
      const updatedWords = [...lessonData.words];
      updatedWords[index] = {
        ...updatedWords[index],
        word: editedWord,
      };
      setLessonData({
        ...lessonData,
        words: updatedWords,
      });
      setEditingWordIndex(null);
      setEditedWord("");
      showSnackbar("Ordet har uppdaterats", "success");
    }
  };

  // In handleDeleteWord
  const handleDeleteWord = (index) => {
    if (lessonData?.words) {
      const updatedWords = lessonData.words.filter((_, i) => i !== index);
      setLessonData({
        ...lessonData,
        words: updatedWords,
      });
      showSnackbar("Ordet har tagits bort", "success");
    }
  };
  const handleSaveLessonClick = () => {
    setSaveDialogOpen(true);
  };

  const handleSaveWithComment = async (comment, name, coverImage, tags) => {
    await trackLesson(
      user.uid,
      "WritingLesson.js",
      lessonData,
      null,
      true,
      comment,
      name,
      coverImage,
      tags,
    );
    setDrawerOpen(true);
    showSnackbar("Lektionen sparad i din lektionsbank!", "success");
  };

  const handleImageButtonClick = (type) => {
    setImageGenerationType(type);
    setShowImageModal(true);
  };

  const handleCloseModal = () => {
    setShowImageModal(false);
    setImageInstructions("");
  };

  const handleGenerateWithInstructions = async () => {
    handleCloseModal();

    if (imageGenerationType === "single") {
      await handleGenerateSingleImage(selectedWordIndex);
    } else {
      await handleGenerateImages();
    }
  };

  // Handler for line count slider
  const handleLineCountChange = (event, newValue) => {
    setLineCount(newValue);
  };

  const LINE_HEIGHT_SCALE = 5; // Each slider unit represents 5 pixels

  // Convert actual pixels to slider value
  const pixelsToSliderValue = (pixels) =>
    Math.round(pixels / LINE_HEIGHT_SCALE);

  // Convert slider value to actual pixels
  const sliderValueToPixels = (value) => value * LINE_HEIGHT_SCALE;

  // Updated handler for line height slider
  const handleLineHeightChange = (event, newValue) => {
    // Convert slider value to actual pixels before storing
    setLineHeight(sliderValueToPixels(newValue));
  };

  const handleGenerateLesson = async () => {
    setLessonData(null);
    // const hasSelectedTypes = Object.values(selectedWritingTypes).some(
    //   (value) => value === true,
    // );

    // if (!hasSelectedTypes) {
    //   showSnackbar("Du måste välja minst en presentationsform.", "info");
    //   return;
    // }
    setIsGeneratingLesson(true);
    try {
      let themeToUse;
      if (lessonTheme === "youtube") {
        themeToUse = transcriptRef.current;
      } else if (lessonTheme === "customTheme") {
        themeToUse = customTheme;
      } else if (lessonTheme === "predefined") {
        themeToUse = predefinedTheme;
      } else if (lessonTheme === "fileUpload") {
        themeToUse = uploadedFileRef.current;
      } else if (lessonTheme === "wikipedia") {
        themeToUse = wikipediaContentRef.current;
      } else if (lessonTheme === "randomizer") {
        themeToUse = randomizedContentRef.current;
      } else if (lessonTheme === "book") {
        themeToUse = bookContentRef.current;
      } else if (lessonTheme === "url") {
        themeToUse = urlContentRef.current;
      } else {
        themeToUse = coursePlanContentRef.current;
      }

      const response = await callApi(
        [
          {
            role: "system",
            content:
              "Du är en assistent som genererar ord och skrivövningar. Follow the exact JSON structure provided in the prompt.",
          },
          {
            role: "user",
            content: `Generera JSON med exakt ${wordCount} ord på ${language} relaterade till temat "${themeToUse}".
        ${gradeInstructions[grade]}
            - VIKTIGT: Använd \\n för radbrytningar i dikter och ramsor. ANVÄND INTE snedstreck.

        
        Svara ALLTID i följande JSON-format:
        {
          "words": [
            { "word": "ord1" },
            { "word": "ord2" }
          ],
          "exercises": [
            ${
              selectedWritingTypes.rhyme
                ? `{
                "type": "rhyme",
                "title": "Ramsa",
                "content": "En rolig ramsa som använder alla orden"
              },`
                : ""
            }
            ${
              selectedWritingTypes.poem
                ? `{
                "type": "poem",
                "title": "Dikt",
                "content": "En dikt som använder alla orden"
              },`
                : ""
            }
            ${
              selectedWritingTypes.story
                ? `{
                "type": "story",
                "title": "Berättelse",
                "content": "En kort berättelse som använder alla orden"
              }`
                : ""
            }
          ]
        }`,
          },
        ],
        4000,
        true,
        user.uid,
      );

      let messageContent = response.data.choices[0].message.content.trim();
      if (messageContent.startsWith("```json")) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith("```")) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      const data = JSON.parse(messageContent);
      const wordsWithImages = data.words.map((wordObj) => ({
        ...wordObj,
        image: null,
      }));

      setLessonData({
        words: wordsWithImages,
        titleText: titleText,
        exercises: data.exercises,
      });

      setWords(wordsWithImages);
      showSnackbar("Nya ord har genererats", "success");

      setTimeout(() => {
        lessonTitleRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, 100);

      await trackLesson(user.uid, "WritingLesson.js", null, {
        wordCount: wordCount,
        language: language,
        lessonTheme: lessonTheme,
      });
    } catch (error) {
      console.error("Error generating lesson:", error);
      showSnackbar("Kunde inte generera nya ord", "error");
    } finally {
      setIsGeneratingLesson(false);
    }
  };

  const handleGenerateSingleImage = async (index) => {
    if (!lessonData?.words?.[index]) {
      console.error("No word found at index:", index);
      showSnackbar("Kunde inte hitta ordet för att generera bild", "error");
      return;
    }

    const word = lessonData.words[index];
    setGeneratingImageIndex(index);
    setIsGeneratingImages(true);

    try {
      const prompt = `Create a simple, clear image representing the ${language} word "${word.word}". No text in the image. ${imageInstructions}`;

      const imageUrl = await generateAndProcessImage(prompt, user.uid, {
        size: "1024x1024",
        model: "dall-e-3",
        quality: "standard",
      });

      const updatedWords = [...lessonData.words];
      updatedWords[index] = {
        ...updatedWords[index],
        image: imageUrl,
      };
      setLessonData({ ...lessonData, words: updatedWords });
      showSnackbar(`Bild genererad för "${word.word}"`, "success");
    } catch (error) {
      console.error(`Error generating image for word "${word.word}":`, error);
      showSnackbar(`Kunde inte generera bild för "${word.word}"`, "error");
    } finally {
      setIsGeneratingImages(false);
      setGeneratingImageIndex(null);
    }
  };

  // Update handleGenerateImages to use the new index tracking
  const handleGenerateImages = async () => {
    setIsGeneratingImages(true);

    try {
      const updatedWords = [...lessonData.words];

      for (const [index, word] of lessonData.words.entries()) {
        setGeneratingImageIndex(index);
        try {
          const prompt = `Create a simple, clear image representing the ${language} word "${word.word}". No text in the image. ${imageInstructions}`;

          const imageUrl = await generateAndProcessImage(prompt, user.uid, {
            size: "1024x1024",
            model: "dall-e-3",
            quality: "standard",
          });

          updatedWords[index] = {
            ...updatedWords[index],
            image: imageUrl,
          };

          setLessonData({
            ...lessonData,
            words: updatedWords,
          });
          showSnackbar(
            `Genererat bild ${index + 1} av ${lessonData.words.length}`,
            "info",
          );
        } catch (error) {
          console.error(
            `Error generating image for word "${word.word}":`,
            error,
          );
          showSnackbar(`Kunde inte generera bild för "${word.word}"`, "error");
          continue;
        }
      }
      showSnackbar("Alla bilder har genererats!", "success");
    } catch (error) {
      console.error("Error in image generation process:", error);
      showSnackbar("Ett fel uppstod när bilderna skulle genereras", "error");
    } finally {
      setIsGeneratingImages(false);
      setGeneratingImageIndex(null);
    }
  };

  return (
    <Box sx={styles.container}>
      <Box
        sx={{
          ...styles.innerContainer,
          width: { xs: "100%", md: "100%" },
          margin: "0 auto",
        }}
      >
        {!viewMode && (
          <Typography
            variant="h1"
            gutterBottom
            sx={{
              fontWeight: "bold",
              fontSize: "1.8em",
              mb: 3,
              mt: 5,
              textAlign: "center",
            }}
          >
            Lär dig orden
          </Typography>
        )}
        <Box
          sx={{
            ...styles.buttonsContainer,
            ...styles.additionalButtonsContainer,
          }}
        >
          {lessonData && user.accountType === "teacher" && (
            <Button
              variant="purp"
              sx={{ ...styles.tightButton }}
              disableRipple
              onClick={() => {
                setTimeout(() => {
                  lessonTitleRef.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "start", // This ensures it scrolls to the top of the element
                  });
                }, 100);
                toggleViewMode();
              }}
              startIcon={
                searchParams.get("v") === "t" ? (
                  <FullscreenExitIcon />
                ) : (
                  <FullscreenIcon />
                )
              }
            >
              {searchParams.get("v") === "t" ? "Redigeringsläge" : "Elevläge"}
            </Button>
          )}
          {!viewMode && (
            <Button
              variant="purp"
              sx={{ ...styles.tightButton }}
              onClick={() => setDrawerOpen(true)}
              startIcon={<BookmarkBorderIcon />}
            >
              Sparat
            </Button>
          )}
        </Box>
        <LessonDrawer
          userId={user.uid}
          lessonType="WritingLesson.js"
          theme={theme}
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          lessonIcon={SubjectIcon}
          onSelectLesson={(lesson) => {
            setLessonData(lesson);
            setDrawerOpen(false);
            showSnackbar(`Lektionen har laddats in.`, "success");
            setTimeout(() => {
              lessonTitleRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "start", // This ensures it scrolls to the top of the element
              });
            }, 100);
          }}
        />
        {!viewMode && (
          <>
            <Box sx={{ display: "none" }}>
              <InfoBox
                icon={SubjectIcon}
                title="Powerpoint Instructions"
                instructions={[
                  "Generera skrivövningar med kontroll över antal rader och radavstånd",
                  "Lägg till AI-genererade bilder för varje ord",
                  "Skriv ut lektionen",
                ]}
                theme={theme}
                styles={styles}
              />
            </Box>
            <Box
              sx={{
                width: "100%",
                mb: 4,
                mt: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box sx={{ mb: 4 }}>
                <CustomInputSwitch
                  selectedIndex={showThemeSelector ? 1 : 0}
                  onIndexChange={(index) => setShowThemeSelector(index === 1)}
                  options={["Skriv in ord själv", "Generera ord"]}
                  width="600px"
                  nonActiveColor="#ffffff"
                />
              </Box>

              {!showThemeSelector ? (
                <Box sx={{ width: "100%", maxWidth: 600, mt: 2 }}>
                  <Box
                    sx={{
                      ...styles.freshBox,
                      ...styles.softBoxShadow,
                      padding: "30px",
                    }}
                  >
                    <Typography variant="body1" sx={{ mb: 2 }}>
                      Skriv in ord separerade med kommatecken eller ny rad:
                    </Typography>
                    <TextField
                      multiline
                      rows={4}
                      fullWidth
                      value={manualWords}
                      onChange={(e) => setManualWords(e.target.value)}
                      placeholder="t.ex. ord1, ord2, ord3&#10;ord4&#10;ord5"
                      sx={{ mb: 2 }}
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "12px",
                        width: "100%",
                      }}
                    >
                      <Button
                        variant="purp"
                        onClick={() => {
                          const wordsArray = manualWords
                            .split(/[,\n]/)
                            .map((word) => word.trim())
                            .filter((word) => word !== "");

                          setLessonData({
                            words: wordsArray.map((word) => ({
                              word: word,
                              image: null,
                            })),
                            exercises: [],
                            titleText: "Skrivlektion",
                          });

                          setTimeout(() => {
                            lessonTitleRef.current?.scrollIntoView({
                              behavior: "smooth",
                              block: "start",
                            });
                          }, 100);
                        }}
                        disabled={isGeneratingLesson || !manualWords.trim()}
                        startIcon={
                          !isGeneratingLesson ? (
                            <GenerateIcon />
                          ) : (
                            <CircularProgress size={20} />
                          )
                        }
                      >
                        Anpassa lektion
                      </Button>
                    </div>
                  </Box>
                </Box>
              ) : (
                <ThemeSelectorFlow
                  subject={subject}
                  value={lessonTheme}
                  onChange={(e) => setLessonTheme(e.target.value)}
                  customTheme={customTheme}
                  onCustomThemeChange={(e) => setCustomTheme(e.target.value)}
                  onPredefinedThemeChange={(e) =>
                    setPredefinedTheme(e.target.value)
                  }
                  onBookContentChange={(content) => {
                    Logger.log(
                      "onBookContentChange",
                      content.substring(0, 100),
                    );
                    bookContentRef.current = content;
                  }}
                  onUrlContentChange={(content) => {
                    Logger.log(
                      "Parent: URL content received",
                      content.substring(0, 300),
                    );
                    urlContentRef.current = content;
                  }}
                  onYoutubeTranscriptChange={(transcriptContent) => {
                    Logger.log(
                      "onYoutubeTranscriptChange körs med: ",
                      transcriptContent,
                    );
                    transcriptRef.current = transcriptContent;
                  }}
                  onFileUpload={(content) => {
                    Logger.log("onFileUpload körs med: ", content);
                    uploadedFileRef.current = content;
                  }}
                  onWikipediaContentChange={(content) => {
                    Logger.log(
                      "Parent: Wikipedia content received",
                      content.substring(0, 100),
                    );
                    wikipediaContentRef.current = content;
                  }}
                  onRandomizedContentChange={(content) => {
                    Logger.log(
                      "randomizedContentRef",
                      content.substring(0, 100),
                    );
                    randomizedContentRef.current = content;
                  }}
                  onCoursePlanContentChange={(content) => {
                    Logger.log("onCoursePlanChange", content.substring(0, 100));
                    coursePlanContentRef.current = content;
                  }}
                  lessonType="WritingLesson.js"
                  user={user}
                  theme={theme}
                  styles={styles}
                  onLessonSelect={(lesson) => {
                    Logger.log("onLessonSelect körs");
                    setLessonData(lesson);

                    setTimeout(() => {
                      lessonTitleRef.current?.scrollIntoView({
                        behavior: "smooth",
                        block: "start", // This ensures it scrolls to the top of the element
                      });
                    }, 100);
                    showSnackbar("Lektionen har laddats in.", "success");
                  }}
                  additionalThemes={additionalThemes}
                />
              )}
            </Box>

            {showThemeSelector && (
              <>
                <Box sx={{ ...styles.headlineLessonBox }}>
                  <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
                    2. Lägg till övning (valfritt)
                  </Typography>
                </Box>

                <Box sx={{ ...styles.freshBox, padding: "30px" }}>
                  <Grid container spacing={2}>
                    {[
                      {
                        key: "rhyme",
                        title: "Ramsa",
                        subtitle: "Lär dig orden genom roliga ramsor",
                        icon: MusicNoteIcon,
                      },
                      {
                        key: "poem",
                        title: "Dikt",
                        subtitle: "Skapa en dikt med de valda orden",
                        icon: ArticleIcon,
                      },
                      {
                        key: "story",
                        title: "Berättelse",
                        subtitle: "Skriv en kort berättelse med orden",
                        icon: MenuBookIcon,
                      },
                    ].map(({ key, title, subtitle, icon: Icon }) => (
                      <Grid item xs={12} sm={4} key={key}>
                        <ThemeFlowBox
                          title={title}
                          subtitle={subtitle}
                          icon={Icon}
                          isSelected={selectedWritingTypes[key]}
                          diffView={true}
                          onClick={() =>
                            setSelectedWritingTypes((prev) => ({
                              ...prev,
                              [key]: !prev[key],
                            }))
                          }
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>

                <Box sx={{ ...styles.headlineLessonBox, mt: 10 }}>
                  <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
                    3. Generera lektionen
                  </Typography>
                </Box>

                <Box sx={{ ...styles.freshBox, padding: "40px" }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <Typography
                        variant="formLabelSinUnderline"
                        sx={{ fontWeight: "500", mb: 0 }}
                      >
                        Språk:
                      </Typography>
                      <Box sx={{ mt: 1 }}>
                        <LanguageSelector
                          language={language}
                          setLanguage={setLanguage}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Typography
                        variant="formLabelSinUnderline"
                        sx={{
                          fontWeight: "500",
                          display: "block",
                          mb: 0,
                          mt: 2,
                        }}
                      >
                        Årskurs:
                      </Typography>
                      <Box sx={{ mt: 1, mb: 3 }}>
                        <GradeSelector grade={grade} setGrade={setGrade} />
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Typography
                        variant="formLabelSinUnderline"
                        sx={{
                          fontWeight: "500",
                          display: "block",
                          mb: 0,
                          mt: 2,
                        }}
                      >
                        Antal ord:
                      </Typography>
                      <FormControl
                        fullWidth
                        margin="normal"
                        sx={{ mb: 3, mt: 1 }}
                      >
                        <Select
                          value={wordCount}
                          onChange={(e) => setWordCount(e.target.value)}
                        >
                          <MenuItem value={3}>3 ord</MenuItem>
                          <MenuItem value={4}>4 ord</MenuItem>
                          <MenuItem value={5}>5 ord</MenuItem>
                          <MenuItem value={6}>6 ord</MenuItem>
                          <MenuItem value={8}>8 ord</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Typography
                        variant="formLabelSinUnderline"
                        sx={{ fontWeight: "500", mb: 0 }}
                      >
                        Antal rader per ord:
                      </Typography>
                      <Box sx={{ px: 2, mt: 2 }}>
                        <Slider
                          value={lineCount}
                          onChange={handleLineCountChange}
                          step={1}
                          marks
                          min={1}
                          max={8}
                          valueLabelDisplay="auto"
                          sx={{
                            color:
                              theme.palette.mode === "light"
                                ? "#8b5cf6"
                                : "primary",
                            "& .MuiSlider-markLabel": {
                              color: theme.palette.text.primary,
                            },
                          }}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Typography
                        variant="formLabelSinUnderline"
                        sx={{ fontWeight: "500", mb: 0 }}
                      >
                        Radavstånd (pixlar):
                      </Typography>
                      <Box sx={{ px: 2, mt: 2 }}>
                        <Slider
                          // Convert stored pixels back to slider value
                          value={pixelsToSliderValue(lineHeight)}
                          onChange={handleLineHeightChange}
                          step={1}
                          marks
                          min={0}
                          max={15} // Max value of 10 will represent 50 pixels
                          valueLabelDisplay="auto"
                          // Add custom value label to show actual pixels
                          valueLabelFormat={(value) =>
                            `${sliderValueToPixels(value)}px`
                          }
                          sx={{
                            color:
                              theme.palette.mode === "light"
                                ? "#8b5cf6"
                                : "primary",
                            "& .MuiSlider-markLabel": {
                              color: theme.palette.text.primary,
                            },
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "12px",
                      width: "100%",
                    }}
                  >
                    <Button
                      variant={
                        theme.palette.mode === "light" ? "purp" : "contained"
                      }
                      color="primary"
                      onClick={handleGenerateLesson}
                      disabled={isGeneratingLesson}
                      startIcon={
                        !isGeneratingLesson ? (
                          <GenerateIcon />
                        ) : (
                          <CircularProgress sx={{ color: "white" }} size={18} />
                        )
                      }
                      sx={{ mr: 2, mt: 8 }}
                    >
                      {isGeneratingLesson ? "Genererar..." : "Generera lektion"}
                    </Button>
                  </div>
                </Box>
              </>
            )}
          </>
        )}

        {lessonData?.words?.length > 0 && (
          <>
            {!viewMode && (
              <Box
                ref={lessonTitleRef}
                sx={{ ...styles.headlineLessonBox, mt: 10 }}
              >
                <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
                  Din lektion
                </Typography>
              </Box>
            )}
            <Box
              sx={{
                ...styles.freshBox,
                padding: "30px",

                margin: viewMode ? "0 auto" : undefined,
                marginTop: viewMode ? "0px" : undefined,
                ...(viewMode && { backgroundColor: "transparent" }),
                ...(viewMode && { borderColor: "transparent" }),
              }}
            >
              {!viewMode && (
                <Box sx={{ position: "relative" }}>
                  <Box
                    sx={{
                      ...styles.lessonControlPanel,
                      ...styles.lessonControlPanelNuevo,

                      "& .MuiButton-root": {
                        ...styles.controlPanelButtons,
                        ...styles.lessonControlPanelNewButtons,
                      },
                    }}
                  >
                    <ExercisesDialog
                      buttonRef={exerciseDialogButtonRef}
                      lessonData={lessonData}
                      user={user}
                      theme={theme}
                      styles={styles}
                      addExercisesToLessonObject={setLessonData}
                      buttonText="Generera ramsa, dikt eller berättelse"
                      tooltip="Skapa nya övningar baserade på orden"
                      showSnackbar={showSnackbar}
                      callApi={callApi}
                    />

                    <Button
                      variant="print"
                      startIcon={<SaveAsIcon />}
                      sx={{ ml: 2 }}
                      onClick={handleSaveLessonClick}
                    >
                      Spara i lektionsbank
                    </Button>

                    <Translate
                      buttonRef={translateButtonRef}
                      lessonData={lessonData}
                      onTranslated={setLessonData}
                      callApi={callApi}
                      user={user}
                      showSnackbar={showSnackbar}
                    />
                    <Button
                      variant="print"
                      color="primary"
                      onClick={() =>
                        handlePrintWritingLesson(
                          lessonData.words,
                          lineCount,
                          lineHeight,
                          themeColor,
                          lessonData.titleText,
                          lessonData,
                        )
                      }
                      startIcon={<PrintIcon />}
                      sx={{}}
                    >
                      Skriv ut
                    </Button>

                    <PrintAsPdf
                      buttonRef={pdfButtonRef}
                      onPrint={() =>
                        handlePrintWritingLesson(
                          lessonData.words,
                          lineCount,
                          lineHeight,
                          themeColor,
                          lessonData.titleText,
                          lessonData,
                        )
                      }
                      sx={{ ml: 2 }}
                    />
                    <Button
                      variant="success"
                      sx={{}}
                      onClick={() => setShareDialogOpenFriends(true)}
                      startIcon={<ShareIcon />}
                    >
                      Dela med vänner
                    </Button>

                    <Button
                      variant="success"
                      sx={{
                        mt: 2,
                        ml: 2,
                      }}
                      onClick={() => setShareDialogOpen(true)}
                      startIcon={<ShareIcon />}
                    >
                      Dela med elever
                    </Button>
                    <ShareLink
                      buttonRef={shareLinkButtonRef}
                      key={lessonId}
                      lessonId={lessonId}
                      lessonData={lessonData}
                      user={user}
                      lessonType={"WritingLesson.js"}
                      onCopy={(message, severity) =>
                        showSnackbar(message, severity)
                      }
                    />

                    <Tooltip title="Hur lektionen ser ut för eleverna">
                      <Button
                        variant="success"
                        onClick={() => {
                          window.scrollTo({ top: 0, behavior: "smooth" });
                          toggleViewMode();
                        }}
                        sx={{ ml: 2, mt: 2 }}
                        startIcon={
                          searchParams.get("v") === "t" ? (
                            <FullscreenExitIcon />
                          ) : (
                            <FullscreenIcon />
                          )
                        }
                      >
                        {searchParams.get("v") === "t"
                          ? "Redigeringsläge"
                          : "Elevläge"}
                      </Button>
                    </Tooltip>

                    <Button
                      variant={
                        theme.palette.mode === "light" ? "purp" : "contained"
                      }
                      color="primary"
                      onClick={() => handleImageButtonClick("facts")}
                      disabled={isGeneratingImages}
                      startIcon={
                        !isGeneratingImages ? (
                          <ImageIcon />
                        ) : (
                          <CircularProgress size={20} />
                        )
                      }
                    >
                      {isGeneratingImages
                        ? "Genererar bilder..."
                        : "Generera bilder"}
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      ...styles.lessonControlPanelFade,
                    }}
                  />
                </Box>
              )}

              <WritingLessonDisplay
                lessonData={lessonData}
                setLessonData={setLessonData}
                viewMode={viewMode}
                lineCount={lineCount}
                lineHeight={lineHeight}
                isGeneratingImages={isGeneratingImages}
                generatingImageIndex={generatingImageIndex}
                handleImageButtonClick={handleImageButtonClick}
                setImageGenerationType={setImageGenerationType}
                setSelectedWordIndex={setSelectedWordIndex}
                setShowImageModal={setShowImageModal}
                user={user}
                showSnackbar={showSnackbar}
                styles={styles}
              />
            </Box>
          </>
        )}
      </Box>

      <SaveLessonDialog
        key={lessonData?.titleText || ""}
        open={saveDialogOpen}
        onClose={() => setSaveDialogOpen(false)}
        onSave={handleSaveWithComment}
        theme={theme}
        lessonObject={lessonData}
        lessonName={lessonData?.titleText || ""}
        lessonType={"WritingLesson.js"}
      />

      <SlideInPaper
        isExpanded={isExpanded}
        onExpandedChange={setIsExpanded}
        lessonTitleRef={lessonTitleRef}
        viewMode={viewMode}
        toggleViewMode={toggleViewMode}
        theme={theme}
        open={showControlPanel}
        user={user}
        onPrint={() =>
          handlePrintWritingLesson(
            lessonData.words,
            lineCount,
            lineHeight,
            themeColor,
            lessonData.titleText,
            lessonData,
          )
        }
        onTranslate={() => translateButtonRef.current?.click()}
        onGenerateRhymePoemStory={() =>
          exerciseDialogButtonRef.current?.click()
        }
        onSaveAsPdf={() => pdfButtonRef.current?.click()}
        onShareLink={() => shareLinkButtonRef.current?.click()}
        onShareWithStudents={() => setShareDialogOpen(true)}
        onShareWithFriends={() => setShareDialogOpenFriends(true)}
        onSaveToBank={() => setSaveDialogOpen(true)}
        onStudentMode={() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
          toggleViewMode();
        }}
      />

      <ShareDialog
        open={shareDialogOpen}
        onClose={() => setShareDialogOpen(false)}
        onShare={handleShare}
        user={user}
        isLessonSharing={true}
        theme={theme}
        lessonName={lessonData?.titleText || ""}
        lessonObject={lessonData}
        lessonType={"WritingLesson.js"}
      />
      <ShareDialog
        open={shareDialogOpenFriends}
        onClose={() => setShareDialogOpenFriends(false)}
        onShare={handleShare}
        user={user}
        isLessonSharing={true}
        theme={theme}
        lessonName={lessonData?.titleText || ""}
        lessonObject={lessonData}
        lessonType={"WritingLesson.js"}
        shareWithFriends={true}
      />

      <ImageLoadingDialog
        open={isGeneratingImages}
        message="Genererar bilder... Tar 30-60 sekunder."
      />

      <LoadingBackdrop
        isOpen={isSharing}
        imagePath="/img/AI.png"
        loadingText={"Delar lektionen..."}
      />

      <ImageGenerationModal
        open={showImageModal}
        onClose={handleCloseModal}
        onGenerate={handleGenerateWithInstructions}
        isGenerating={
          imageGenerationType === "topic"
            ? isGeneratingImages
            : isGeneratingImages
        }
        instructions={imageInstructions}
        setInstructions={setImageInstructions}
        generationType={imageGenerationType}
        theme={theme}
      />
    </Box>
  );
};

export default WritingLesson;
