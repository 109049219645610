import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  Grid,
  Tooltip,
  IconButton,
  Menu,
  Paper,
  FormControl,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";

import Quill from "./Quill";
import ShareLink from "./ShareLink";
import { Popper } from "@mui/material";
import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import GenerateIcon from "@mui/icons-material/AutoAwesome";
import SlideInPaper from "./SlideInPaper";
import TeacherSearch from "./TeacherSearch";
import DescriptionIcon from "@mui/icons-material/Description";
import ImageLoadingDialog from "./ImageLoadingDialog";
import PrintAsPdf from "./PrintAsPdf";

import AssignmentIcon from "@mui/icons-material/Assignment";
import SettingsIcon from "@mui/icons-material/Settings";
import Translate from "./Translate";
import {
  collection,
  query,
  where,
  limit,
  addDoc,
  updateDoc,
  getDocs,
  serverTimestamp,
} from "firebase/firestore";
import ThemeFlowBox from "./ThemeFlowBox";
import { getAdditionalThemes } from "./additionalThemes/BasicReadingCompLesson";
import ImageIcon from "@mui/icons-material/Image";
import PrintIcon from "@mui/icons-material/Print";
import GavelIcon from "@mui/icons-material/Gavel";
import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import HistoryIcon from "@mui/icons-material/History";
import TimelineIcon from "@mui/icons-material/Timeline";
import ShareDialog from "./ShareDialog";
import TimelineDisplay from "./TimelineDisplay";

import { alpha } from "@mui/material/styles";
import AddonTimeline from "./AddonTimeline";
import ShareIcon from "@mui/icons-material/Share";
import LoadingBackdrop from "./LoadingBackdrop";
import AddonTimelineSimple from "./AddonTimelineSimple";
import TimelineSimpleDisplay from "./TimelineSimpleDisplay";

import TextFieldsIcon from "@mui/icons-material/TextFields";
import InsightsIcon from "@mui/icons-material/Psychology";
import PsychologyIcon from "@mui/icons-material/Psychology";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import QuizIcon from "@mui/icons-material/Quiz";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import { useSearchParams, useParams } from "react-router-dom";

import LessonQuizDisplay from "./LessonQuizDisplay";
import AddonQuiz from "./AddonQuiz";
import ReadingComprehensionDialog from "./ReadingComprehensionDialog";
import DashedPapers from "./DashedPapers";
import LessonExercisesDisplay from "./LessonExercisesDisplay";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import { callApi } from "./api";
import InfoBox from "./InfoBox";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { handlePrintReadingComp } from "./printFunctions";
import MenuBookIcon from "@mui/icons-material/MenuBook";

import { generateAndProcessImage } from "./functions";
import { useSnackbar } from "./SnackbarHandler";
import ProgressBar from "./ProgressBar";

import EditIcon from "@mui/icons-material/Edit";

import { getAnalytics, logEvent } from "firebase/analytics";
import { ThemeSelectorFlow } from "./ThemeSelectorFlow";

import ImageGenerationModal from "./ImageGenerationModal";
import GradeSelector, { gradeInstructions } from "./GradeSelector";
import LanguageSelector from "./LanguageSelector";
import { useTheme } from "@mui/material/styles";
import { getStyles } from "../index";
import { trackLesson, handleImageGenerationError } from "./functions";
import Logger from "./Logger";
import SaveAsIcon from "@mui/icons-material/SaveAs";

import LessonDrawer from "./LessonDrawer";
import SaveLessonDialog from "./SaveLessonDialog";
import HoverableImage from "./HoverableImage";

import ScienceIcon from "@mui/icons-material/Science";

import NewspaperIcon from "@mui/icons-material/Newspaper";

import RateReviewIcon from "@mui/icons-material/RateReview";
import RegenerateDialog from "./RegenerateDialog";
import { db } from "./firebase";

const BasicReadingCompLesson = ({ user }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);

  const [language, setLanguage] = useState("svenska");
  const [lessonTheme, setLessonTheme] = useState("generateWithAi");
  const [customTheme, setCustomTheme] = useState("");
  const [titleText, setTitleText] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [previewType, setPreviewType] = useState(null);
  const [popperPlacement, setPopperPlacement] = useState("right-start");
  const [grade, setGrade] = useState("åk6");
  const [progress, setProgress] = useState(0);
  const [regenerateDialogOpen, setRegenerateDialogOpen] = useState(false);
  const [showControlPanel, setShowControlPanel] = useState(false);
  const [wordCount, setWordCount] = useState(600);

  const [perspective, setPerspective] = useState("omniscient");
  const urlContentRef = useRef("");
  const [sentenceStructure, setSentenceStructure] = useState("compound");

  const [isGeneratingLesson, setIsGeneratingLesson] = useState(false);
  const quizSectionRef = useRef(null);
  const exercisesSectionRef = useRef(null);
  const timelineSectionRef = useRef(null);
  const [isGeneratingImages, setIsGeneratingImages] = useState(false);
  const [editorContent, setEditorContent] = useState("");
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageInstructions, setImageInstructions] = useState("");
  const [openAiInstructions, setOpenAiInstructions] = useState("");
  const timelineSimpleSectionRef = useRef(null);
  const editorRef = useRef(null);
  const { showSnackbar } = useSnackbar();
  const theme = useTheme();
  // Add refs for the quiz and comprehension buttons
  const quizButtonRef = useRef(null);
  const timelineButtonRef = useRef(null);
  const timelineSimpleButtonRef = useRef(null);
  const comprehensionButtonRef = useRef(null);
  const panelRef = useRef();
  const translateButtonRef = useRef(null);
  const pdfButtonRef = useRef(null);
  const shareLinkButtonRef = useRef(null);
  const [isExpanded, setIsExpanded] = useState(false);

  // Add click handlers for the DashedPaper components
  const handleQuizPaperClick = () => {
    quizButtonRef.current?.click();
  };

  const handleTimelineSimplePaperClick = () => {
    timelineSimpleButtonRef.current?.click();
  };

  const handleComprehensionPaperClick = () => {
    comprehensionButtonRef.current?.click();
  };

  const handleTimelinePaperClick = () => {
    timelineButtonRef.current?.click();
  };

  const styles = getStyles(theme);
  const [lessonData, setLessonData] = useState();
  const [isAdapting, setIsAdapting] = useState(false);

  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [isEditingContent, setIsEditingContent] = useState(false);
  const [loadingImageIndex, setLoadingImageIndex] = useState(null);
  const [editingSections, setEditingSections] = useState({});

  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const bookContentRef = useRef("");
  const [selectedExercises, setSelectedExercises] = useState({
    "on-the-lines": false,
    "between-the-lines": false,
    "beyond-the-lines": false,
    "true-false": false,
    "multiple-choice": false,
    "open-ended": false,
    assignments: false,
  });

  const [showForm, setShowForm] = useState(true);

  const [predefinedTheme, setPredefinedTheme] = useState(null);
  const lessonTitleRef = useRef(null); // Add this ref
  const transcriptRef = useRef("");
  const uploadedFileRef = useRef("");

  const [isSharing, setIsSharing] = useState(false);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [shareDialogOpenFriends, setShareDialogOpenFriends] = useState(false);

  const wikipediaContentRef = useRef("");
  const randomizedContentRef = useRef("");
  const coursePlanContentRef = useRef("");

  const topicSectionRef = useRef(null);
  const factsSectionRef = useRef(null);
  const handleMouseEnter = (event, option) => {
    const rect = event.currentTarget.getBoundingClientRect();
    const viewportWidth = window.innerWidth;
    const placement =
      rect.right > viewportWidth / 2 ? "left-start" : "right-start";
    setPopperPlacement(placement);
    setAnchorEl(event.currentTarget);
    setPreviewType(option);
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
    setPreviewType(null);
  };

  const [content, setContent] = useState(lessonData?.content || "");

  useEffect(() => {
    Logger.log("lessonData: ", lessonData);
    const isDataValid =
      lessonData !== null &&
      lessonData !== undefined &&
      Object.keys(lessonData).length > 0 &&
      !Object.values(lessonData).includes("");

    if (isDataValid !== showControlPanel) {
      const timer = setTimeout(() => {
        setShowControlPanel(isDataValid);
      }, 100);

      return () => clearTimeout(timer);
    }
  }, [lessonData, showControlPanel]);

  useEffect(() => {
    Logger.log("content: ", lessonData?.content);
    if (lessonData?.content) {
      setContent(lessonData.content);
    }
  }, [lessonData?.content]);

  const LESSON_VARIANTS = {
    default: {
      title: "Grundläggande läsförståelse",
      lessonType: "BasicReadingCompLesson.js",
      icon: MenuBookIcon,
    },
    factText: {
      title: "Faktatext",
      lessonType: "BasicReadingCompLessonVariantFactText.js",
      icon: ScienceIcon,
    },
    instructingText: {
      title: "Instruerande text",
      lessonType: "BasicReadingCompLessonVariantInstructingText.js",
      icon: ScienceIcon,
    },
    explainingText: {
      title: "Förklarande text",
      lessonType: "BasicReadingCompLessonVariantExplainingText.js",
      icon: ScienceIcon,
    },

    argumentativeText: {
      title: "Argumenterande text",
      lessonType: "BasicReadingCompLessonVariantArgumentativeText.js",
      icon: GavelIcon,
    },

    descriptiveText: {
      title: "Beskrivande text",
      lessonType: "BasicReadingCompLessonVariantDescriptiveText.js",
      icon: DescriptionIcon,
    },

    chronicle: {
      title: "Krönika",
      lessonType: "BasicReadingCompLessonVariantChronicle.js",
      icon: NewspaperIcon,
    },

    review: {
      title: "Recension",
      lessonType: "BasicReadingCompLessonVariantReview.js",
      icon: RateReviewIcon,
    },
  };

  const [searchParams, setSearchParams] = useSearchParams();

  const { lessonId } = useParams();
  const variant = searchParams.get("type") || "default";

  useEffect(() => {
    Logger.log("variant: ", variant);
  }, [variant]);

  const [textType, setTextType] = useState("berattande");

  useEffect(() => {
    Logger.log("variant: ", variant);
    if (variant === "factText") setTextType("informerande");
    else if (variant === "instructingText") setTextType("instruerande");
    else if (variant === "explainingText") setTextType("forklarande");
    else if (variant === "argumentativeText") setTextType("argumenterande");
    else if (variant === "descriptiveText") setTextType("beskrivande");
    else if (variant === "informativeText") setTextType("informerande");
    else if (variant === "narrativeText") setTextType("berattande");
    else if (variant === "chronicle") setTextType("kronika");
    else if (variant === "review") setTextType("recension");
    else setTextType("berattande");
  }, [variant]);

  const subject = searchParams.get("subject") || null;
  const viewMode = searchParams.get("v") === "t";

  const lessonConfig = LESSON_VARIANTS[variant] || LESSON_VARIANTS.default;
  const additionalThemes = getAdditionalThemes(lessonConfig.lessonType);

  useEffect(() => {
    const loadLessonFromId = async () => {
      if (lessonId) {
        try {
          const q = query(
            collection(db, "lesson_usage"),
            where("__name__", ">=", lessonId),
            where("__name__", "<=", lessonId + "\uf8ff"),
            where("lessonType", "==", lessonConfig.lessonType),
            limit(1),
          );

          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            const docData = querySnapshot.docs[0].data();

            if (!docData.hasOpened) {
              await updateDoc(querySnapshot.docs[0].ref, {
                hasOpened: true,
                openedAt: Date.now(),
              });
            }
            setLessonData(docData.data);
            setTitleText(docData.data.title || "");

            // Scroll to lesson content
            setTimeout(() => {
              lessonTitleRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }, 100);

            showSnackbar("Lektionen har laddats in.", "success");
          } else {
            showSnackbar("Kunde inte hitta lektionen", "error");
          }
        } catch (error) {
          console.error("Error loading lesson:", error);
          showSnackbar("Ett fel uppstod när lektionen skulle laddas", "error");
        }
      }
    };

    loadLessonFromId();
  }, [lessonId, lessonConfig.lessonType]);

  const handleShare = async (
    lessonComment,
    lessonName,
    selectedImage,
    tags,
    shareData,
  ) => {
    setIsSharing(true);
    await new Promise((resolve) => setTimeout(resolve, 1500));

    try {
      // Create a document for each student and then create notifications with the doc IDs
      const sharePromises = shareData.studentIds.map(async (studentId) => {
        const documentData = {
          userId: user.uid,
          shared: true,
          sharedTo: studentId,
          comment: shareData.comment,
          sharedAt: Date.now(),
          fileUrl: shareData.fileUrl,
          lessonType: lessonConfig.lessonType,
          timestamp: serverTimestamp(),
          lessonComment,
          lessonName,
          coverImage: selectedImage,
          hasPrinted: true,
          data: lessonData,
        };

        // Create the lesson document and get its ID
        const lessonDoc = await addDoc(
          collection(db, "lesson_usage"),
          documentData,
        );

        Logger.log(
          "Adding document for student",
          studentId,
          ":",
          JSON.stringify(documentData, null, 2),
        );

        // Create notification with the lesson doc ID
        await addDoc(collection(db, "notifications"), {
          type: "lesson_shared",
          recipientId: studentId,
          senderId: user.uid,
          read: false,
          createdAt: serverTimestamp(),
          lessonData: {
            lessonId: lessonDoc.id,
            lessonType: documentData.lessonType,
            lessonName: documentData.lessonName,
            fileUrl: documentData.fileUrl,
            comment: documentData.comment,
            coverImage: selectedImage,
          },
        });

        return lessonDoc;
      });

      await Promise.all(sharePromises);
      showSnackbar(`Du har nu delat lektionen!`, "success");
    } catch (error) {
      showSnackbar("Fel vid delande av lektionen: " + error.message, "error");
      console.error(error);
    } finally {
      setIsSharing(false);
    }
  };

  const handlePrintClick = async () => {
    if (
      lessonData &&
      lessonData.images &&
      lessonData.images.every((image) => image.imageUrl)
    ) {
      Logger.log("Saving lesson since all images have been generated");
      Logger.log(JSON.stringify(lessonData, null, 2));
      await trackLesson(
        user.uid,
        lessonConfig.lessonType,
        lessonData,
        null,
        true,
      );
    }

    try {
      const analytics = getAnalytics();
      logEvent(analytics, "button_click", {
        button_name: "Print",
        page: "BasicReadingCompLesson",
      });
    } catch (error) {
      Logger.log(
        "Nåt fel skedde med logEvent analytics i BasicReadingCompLesson.",
      );
    }

    handlePrintReadingComp(lessonData, theme);
  };

  const handleSaveLessonClick = () => {
    setSaveDialogOpen(true);
  };

  const handleSaveWithComment = async (comment, name, coverImage, tags) => {
    Logger.log(JSON.stringify(lessonData, null, 2));
    await trackLesson(
      user.uid,
      lessonConfig.lessonType,
      lessonData,
      null,
      true,
      comment,
      name,
      coverImage,
      tags,
    );
    setDrawerOpen(true);
    showSnackbar("Lektionen sparad i din lektionsbank!", "success");
  };

  const handleSectionSave = (section, index) => {
    setEditingSections((prev) => ({
      ...prev,
      [`${section}-${index}`]: false,
    }));
    showSnackbar("Sektionen har uppdaterats", "success");
  };

  const toggleViewMode = () => {
    const currentParams = Object.fromEntries(searchParams.entries());
    if (currentParams.v === "t") {
      delete currentParams.v;
    } else {
      currentParams.v = "t";
    }
    setSearchParams(currentParams);
  };

  const handleKeyDown = (e, section, index) => {
    if (e.key === "Enter" && (e.ctrlKey || e.metaKey)) {
      handleSectionSave(section, index);
    }
  };

  const simulateProgress = (startProgress, endProgress, duration) => {
    const stepSize = 0.5;
    const steps = (endProgress - startProgress) / stepSize;
    const stepDuration = duration / steps;
    let currentProgress = startProgress;

    const intervalId = setInterval(() => {
      currentProgress += stepSize;
      if (currentProgress >= endProgress) {
        clearInterval(intervalId);
      } else {
        setProgress(currentProgress);
      }
    }, stepDuration);

    return () => clearInterval(intervalId);
  };

  const getGridPrompt = ({
    wordCount,
    textType,
    perspective,
    sentenceStructure,
  }) => {
    const textTypeInstructions = {
      berattande:
        "Skapa en engagerande berättelse med en tydlig handling, början, mitt och slut",
      argumenterande:
        "Presentera en tydlig tes med välgrundade argument och motargument",
      forklarande:
        "Förklara ett koncept eller fenomen på ett tydligt och strukturerat sätt",
      beskrivande:
        "Använd rika beskrivningar och målande språk för att skildra en plats, person eller händelse",
      instruerande: "Ge tydliga steg-för-steg instruktioner i logisk ordning",
      informerande:
        "Presentera fakta och information på ett objektivt och strukturerat sätt",
      kronika: "Skriv i personlig ton med stark egen röst och kåserande stil",
      recension:
        "Börja med kort sammanfattning, följt av analys och avslutas med tydligt omdöme",

      poetisk: "Använd poetiska element som bildspråk, rytm och rim",
    };

    const perspectiveInstructions = {
      "first-person":
        "Skriv i första person (jag/vi) med direkt tillgång till berättarens tankar och känslor",
      "third-person":
        "Skriv i tredje person (han/hon/hen) med fokus på observerbara handlingar och dialoger",
      omniscient:
        "Skriv med en allvetande berättare som har insyn i alla karaktärers tankar och känslor",
    };

    const structureInstructions = {
      simple:
        "Använd huvudsakligen enkla meningar. Undvik långa bisatser. Fokusera på tydlighet.",
      compound:
        'Kombinera enkla och sammansatta meningar. Använd bindeord som "och", "men", "eller".',
      complex:
        "Använd avancerade meningsstrukturer med bisatser och varierande längd för ett mer nyanserat språk.",
    };

    return `

1. Längd: EXAKT ${wordCount} ord

2. Texttyp: ${textTypeInstructions[textType]}

3. Berättarperspektiv: ${perspectiveInstructions[perspective]}

4. Meningsstruktur: ${structureInstructions[sentenceStructure]}
`;
  };

  const handleGenerateLesson = async () => {
    const hasSelectedExercises = Object.values(selectedExercises).some(
      (value) => value === true,
    );

    if (!hasSelectedExercises) {
      showSnackbar("Du måste välja minst en övningstyp.", "info");
      return;
    }
    setLessonData(null);
    setProgress(0);
    let stopProgressSimulation = null;
    setIsGeneratingLesson(true);
    const gradeInstructionsText = gradeInstructions[grade];

    try {
      let themeToUse;

      if (lessonTheme === "youtube") {
        themeToUse = transcriptRef.current;
      } else if (lessonTheme === "customTheme") {
        themeToUse = customTheme;
      } else if (lessonTheme === "predefined") {
        themeToUse = predefinedTheme;
      } else if (lessonTheme === "fileUpload") {
        themeToUse = uploadedFileRef.current;
      } else if (lessonTheme === "wikipedia") {
        themeToUse = wikipediaContentRef.current;
      } else if (lessonTheme === "randomizer") {
        themeToUse = randomizedContentRef.current;
      } else if (lessonTheme === "book") {
        themeToUse = bookContentRef.current;
      } else if (lessonTheme === "url") {
        themeToUse = urlContentRef.current;
      } else {
        //
        themeToUse = coursePlanContentRef.current;
      }

      Logger.log("themeToUse: ", themeToUse);

      if (!themeToUse.trim()) {
        showSnackbar("Du måste välja underlag i steg 1.", "warning");
        return;
      }
      stopProgressSimulation = simulateProgress(0, 90, 15000);

      let response = await callApi(
        [
          {
            role: "system",
            content: `You are an expert teacher creating engaging reading comprehension lessons in ${language}. Follow the exact JSON structure provided in the prompt.`,
          },
          {
            role: "user",
            content: `Generera en text skriven på ${language} uppdelad i tydliga stycken och underrurbiker på nivå för ${grade}.

            ${getGridPrompt({ wordCount, textType, perspective, sentenceStructure })}

            - Följande frågetyper:





${selectedExercises["true-false"] ? "- 5 sant/falskt påståenden\n" : ""}
${selectedExercises["multiple-choice"] ? "- 5 flervalsfrågor med 3 alternativ var\n" : ""}
${selectedExercises["open-ended"] ? "- 3 öppna frågor som testar djupare förståelse\n" : ""}
${selectedExercises["assignments"] ? "- 5 praktiska uppgifter för eleverna att slutföra\n" : ""}            
${selectedExercises["word-comprehension"] ? "- 4 essential words from the text with synonyms, antonyms, and example sentences. all these 4 words HAS to exist in the content provided. EXACTLY 1 task per word in useTheWords.  \n" : ""}
Texten ska vara engagerande och åldersanpassad.

Theme: "${themeToUse}".

${openAiInstructions}.
${gradeInstructionsText}

Svara ALLTID i följande JSON-format:
{
  "title": "Lektionens titel",
  "theme": "Lektionens tema",
  "content": "Return the main text content with HTML formatting for paragraphs (<p>), headings (<h1>, <h2>), and basic text formatting (<strong>, <em>). Do not use advanced HTML elements or styles.",

  "exercises": [
    ${
      selectedExercises["on-the-lines"]
        ? `{
        "type": "on-the-lines",
        "title": "På raderna (svaret finns i texten)",
        "content": ["Fråga som kan besvaras direkt från texten 1", "Fråga som kan besvaras direkt från texten 2", "Fråga som kan besvaras direkt från texten 3"]
      },`
        : ""
    }
    ${
      selectedExercises["between-the-lines"]
        ? `{
        "type": "between-the-lines",
        "title": "Mellan raderna (dra slutsatser från texten)",
        "content": ["Fråga som kräver att man drar slutsatser 1", "Fråga som kräver att man drar slutsatser 2", "Fråga som kräver att man drar slutsatser 3"]
      },`
        : ""
    }
    ${
      selectedExercises["beyond-the-lines"]
        ? `{
        "type": "beyond-the-lines",
        "title": "Bortom raderna (egna tankar och analys)",
        "content": ["Analytisk fråga som kräver eget tänkande 1", "Analytisk fråga som kräver eget tänkande 2", "Analytisk fråga som kräver eget tänkande 3"]
      },`
        : ""
    }
    ${
      selectedExercises["true-false"]
        ? `{
        "type": "true-false",
        "title": "Sant eller falskt?",
        "content": ["Påstående 1", "Påstående 2", "Påstående 3", "Påstående 4", "Påstående 5"]
      },`
        : ""
    }

${
  selectedExercises["assignments"]
    ? `{
  "type": "assignments",
  "title": "Uppgifter",
  "content": ["Uppgift 1", "Uppgift 2", "Uppgift 3", "Uppgift 4", "Uppgift 5"]
},`
    : ""
}
        
${
  selectedExercises["multiple-choice"]
    ? `{
    "type": "multiple-choice",
    "title": "Flervalsfrågor",
    "content": [
      {
        "question": "Fråga 1",
        "options": ["Alt 1", "Alt 2", "Alt 3"]
      },
      {
        "question": "Fråga 2",
        "options": ["Alt 1", "Alt 2", "Alt 3"]
      },
      {
        "question": "Fråga 3",
        "options": ["Alt 1", "Alt 2", "Alt 3"]
      },
      {
        "question": "Fråga 4",
        "options": ["Alt 1", "Alt 2", "Alt 3"]
      },
      {
        "question": "Fråga 5",
        "options": ["Alt 1", "Alt 2", "Alt 3"]
      }
    ]
  },`
    : ""
}
    ${
      selectedExercises["open-ended"]
        ? `{
        "type": "open-ended",
        "title": "Diskussionsfrågor",
        "content": ["Öppen fråga 1", "Öppen fråga 2", "Öppen fråga 3"]
      }`
        : ""
    }


    ${
      selectedExercises["word-comprehension"]
        ? `{
            "type": "word-comprehension",
            "title": "Ord med synonymer, antonymer och exempelmeningar",
            "content": [
              {
                "word": "Word 1",
                "definition":"Definition of Word 1",
                "synonyms": ["Synonym 1", "Synonym 2", "Synonym 3"],
                "antonyms": ["Antonym 1", "Antonym 2", "Antonym 3"],
                "examples": [
                  "Example sentence 1",
                  "Example sentence 2",
                  "Example sentence 3"
                ]
               "useTheWords": [
                  "Task with Word 1",
                ]
              }
            ]
          },`
        : ""
    }

  ]
}
        Observera:

          - Lektionen ska vara på: "${language}".


        `,
          },
        ],
        10000,
        true,
        user.uid,
      );
      Logger.log(
        "Response in BasicReadingCompLesson: ",
        JSON.stringify(response, null, 2),
      );
      if (stopProgressSimulation) {
        stopProgressSimulation();
      }

      setProgress(95);
      let messageContent = response.data.choices[0].message.content.trim();

      if (messageContent.startsWith("```json")) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith("```")) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      const data = JSON.parse(messageContent);

      setLessonData({
        ...data,
        images: [{ imageUrl: null }, { imageUrl: null }],
      });

      setProgress(100);
      Logger.log(JSON.stringify(data, null, 2));
      setTitleText(data.title);
      await new Promise((resolve) => setTimeout(resolve, 800));
      setShowForm(false);

      showSnackbar("Lektionen har blivit genererad.", "success");

      setTimeout(() => {
        lessonTitleRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, 100);

      await trackLesson(user.uid, lessonConfig.lessonType, null, {
        language: language,
        lessonTheme: lessonTheme,
      });
    } catch (error) {
      console.error("Error generating lesson:", error);
      showSnackbar(
        "Ett fel uppstod när lektionen skulle genereras. Försök igen!",
        "error",
      );
    } finally {
      setIsGeneratingLesson(false);
    }
  };

  const handleGenerateImages = async () => {
    if (!lessonData) return;
    setIsGeneratingImages(true);
    handleCloseModal();

    try {
      const imagePrompt = `Create an image about a story with the theme: "${titleText}". ${imageInstructions}`;
      const imagePromises = lessonData.images.map(() =>
        generateAndProcessImage(imagePrompt, user.uid),
      );
      const imageUrls = await Promise.all(imagePromises);

      setLessonData((prevData) => ({
        ...prevData,
        images: imageUrls.map((imageUrl) => ({ imageUrl })),
      }));

      showSnackbar("Bilderna har genererats!", "success");
    } catch (error) {
      handleImageGenerationError(error, true, showSnackbar);
    } finally {
      setIsGeneratingImages(false);
      setLoadingImageIndex(null);
    }
  };

  const handleGenerateSpecificImage = async (index) => {
    setIsGeneratingImages(true);
    setLoadingImageIndex(index);
    handleCloseModal();

    try {
      const imagePrompt = `Create an image about a story with the theme: "${titleText}". ${imageInstructions}`;
      const imageUrl = await generateAndProcessImage(imagePrompt, user.uid);

      if (imageUrl) {
        setLessonData((prevData) => {
          const newImages = [...prevData.images];
          newImages[index] = { imageUrl };
          return {
            ...prevData,
            images: newImages,
          };
        });
        showSnackbar("Bilden har genererats!", "success");
      }
    } catch (error) {
      handleImageGenerationError(error, false, showSnackbar);
    } finally {
      setIsGeneratingImages(false);
      setLoadingImageIndex(null);
    }
  };

  const handleImageButtonClick = (index = null) => {
    setSelectedImageIndex(index);
    setShowImageModal(true);
  };

  const handleCloseModal = () => {
    setShowImageModal(false);
    setImageInstructions("");
  };

  const handleGenerateWithInstructions = async () => {
    if (selectedImageIndex !== null) {
      handleCloseModal();
      await handleGenerateSpecificImage(selectedImageIndex);
    } else {
      handleCloseModal();
      await handleGenerateImages();
    }
  };

  return (
    <Box sx={styles.container}>
      <Box
        sx={{
          ...styles.innerContainer,
          width: { xs: "100%", md: "100%" },
          margin: "0 auto",
        }}
      >
        {!viewMode && (
          <Typography
            variant="h1"
            gutterBottom
            sx={{
              fontWeight: "bold",
              fontSize: "1.8em",
              mb: 3,
              mt: 5,
              textAlign: "center",
            }}
          >
            {lessonConfig.title}
          </Typography>
        )}
        <Box
          sx={{
            ...styles.buttonsContainer,
            ...styles.additionalButtonsContainer,
          }}
        >
          {lessonData && user.accountType === "teacher" && (
            <Button
              variant="purp"
              sx={{ ...styles.tightButton }}
              disableRipple
              onClick={() => {
                setTimeout(() => {
                  lessonTitleRef.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "start", // This ensures it scrolls to the top of the element
                  });
                }, 100);
                toggleViewMode();
              }}
              startIcon={
                searchParams.get("v") === "t" ? (
                  <FullscreenExitIcon />
                ) : (
                  <FullscreenIcon />
                )
              }
            >
              {searchParams.get("v") === "t" ? "Redigeringsläge" : "Elevläge"}
            </Button>
          )}
          {!viewMode && (
            <Button
              variant="purp"
              sx={{ ...styles.tightButton }}
              onClick={() => setDrawerOpen(true)}
              startIcon={<BookmarkBorderIcon />}
            >
              Sparat
            </Button>
          )}
        </Box>

        <LessonDrawer
          userId={user.uid}
          lessonType={lessonConfig.lessonType}
          theme={theme}
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          lessonIcon={MenuBookIcon}
          onSelectLesson={(lesson) => {
            setLessonData(lesson.data);
            setDrawerOpen(false);
            setTimeout(() => {
              lessonTitleRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "start", // This ensures it scrolls to the top of the element
              });
            }, 100);
            showSnackbar(`Lektionen har laddats in.`, "success");
          }}
        />
        {!viewMode && (
          <>
            <InfoBox
              icon={lessonConfig.icon}
              title="Grundläggande läsförståelse"
              instructions={[
                "Välj underlag till texten, t.ex. från kursplanen eller helt egna instruktioner",
                "Lägg till övningstyper, t.ex. i texten, på raderna, mellan raderna ",
                "Redigera lektionen utifrån behov och generera/ladda upp bilder",
                "Skriv ut, spara i din egen lektionsbank eller dela lektionen med eleverna",
              ]}
              theme={theme}
              styles={styles}
            />
            <ThemeSelectorFlow
              key={variant}
              subject={subject}
              value={lessonTheme}
              onChange={(e) => setLessonTheme(e.target.value)}
              customTheme={customTheme}
              onCustomThemeChange={(e) => setCustomTheme(e.target.value)}
              onPredefinedThemeChange={(e) =>
                setPredefinedTheme(e.target.value)
              }
              onUrlContentChange={(content) => {
                Logger.log(
                  "Parent: URL content received",
                  content.substring(0, 300),
                );
                urlContentRef.current = content;
              }}
              onBookContentChange={(content) => {
                Logger.log("onBookContentChange", content.substring(0, 100));
                bookContentRef.current = content;
              }}
              onYoutubeTranscriptChange={(transcriptContent) => {
                Logger.log(
                  "onYoutubeTranscriptChange körs med: ",
                  transcriptContent,
                );
                transcriptRef.current = transcriptContent;
              }}
              onFileUpload={(content) => {
                Logger.log("onFileUpload körs med: ", content);
                uploadedFileRef.current = content;
              }}
              onWikipediaContentChange={(content) => {
                Logger.log(
                  "Parent: Wikipedia content received",
                  content.substring(0, 100),
                );
                wikipediaContentRef.current = content;
              }}
              onRandomizedContentChange={(content) => {
                Logger.log("randomizedContentRef", content.substring(0, 100));
                randomizedContentRef.current = content;
              }}
              onCoursePlanContentChange={(content) => {
                Logger.log("onCoursePlanChange", content.substring(0, 100));
                coursePlanContentRef.current = content;
              }}
              lessonType={lessonConfig.lessonType}
              user={user}
              theme={theme}
              styles={styles}
              onLessonSelect={(lesson) => {
                Logger.log("onLessonSelect körs");
                setLessonData(lesson.data);
                setTimeout(() => {
                  lessonTitleRef.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "start", // This ensures it scrolls to the top of the element
                  });
                }, 100);
                showSnackbar("Lektionen har laddats in.", "success");
              }}
              additionalThemes={additionalThemes}
            />
            <Box sx={styles.headlineLessonBox}>
              <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
                2. Välj övningstyper
              </Typography>
            </Box>

            <Box
              sx={{
                ...styles.freshBox,
                padding: "30px",
              }}
            >
              <Grid container spacing={2}>
                {[
                  [
                    "on-the-lines",
                    "I texten",
                    "Frågor där svaren finns direkt i texten",
                    TextFieldsIcon,
                    "/img/i.png",
                  ],
                  [
                    "between-the-lines",
                    "Mellan raderna",
                    "Frågor som kräver att du drar slutsatser",
                    InsightsIcon,
                    "/img/mellan.png",
                  ],
                  [
                    "beyond-the-lines",
                    "Bortom raderna",
                    "Frågor som kräver analys och egna tankar",
                    PsychologyIcon,
                    "/img/bortom.png",
                  ],
                  [
                    "true-false",
                    "Sant eller falskt",
                    "Påståenden att bedöma",
                    FactCheckIcon,
                    "/img/sant.png",
                  ],
                  [
                    "multiple-choice",
                    "Flervalsfrågor",
                    "Frågor med flera svarsalternativ",
                    QuizIcon,
                    "/img/flerval2.png",
                  ],
                  [
                    "open-ended",
                    "Diskussionsfrågor",
                    "Öppna frågor för djupare förståelse",
                    QuestionAnswerIcon,
                    "/img/diskussion.png",
                  ],
                  [
                    "word-comprehension",
                    "Ordförståelse",
                    "Ord med definitioner, synonymer, antonymer och uppgifter",
                    MenuBookIcon,
                    "/img/ordfork.png",
                  ],
                  [
                    "assignments",
                    "Uppgifter",
                    "Praktiska uppgifter för eleven att genomföra",
                    AssignmentIcon,
                    "/img/assignment.png",
                  ],
                ].map(([key, title, subtitle, Icon, previewImage]) => (
                  <Grid item xs={12} sm={3} key={key}>
                    <Box sx={{ position: "relative" }}>
                      <ThemeFlowBox
                        key={key}
                        title={title}
                        subtitle={subtitle}
                        icon={Icon}
                        label={
                          key === "word-comprehension" || key === "assignments"
                            ? "NYTT"
                            : undefined
                        }
                        isSelected={selectedExercises[key]}
                        diffView={true}
                        onClick={() => {
                          const currentSelectedCount =
                            Object.values(selectedExercises).filter(
                              Boolean,
                            ).length;
                          const isCurrentlySelected = selectedExercises[key];

                          if (
                            !isCurrentlySelected &&
                            currentSelectedCount >= 3
                          ) {
                            showSnackbar(
                              "Du kan max välja 3 övningar.",
                              "info",
                            );
                            return;
                          }

                          setSelectedExercises((prev) => ({
                            ...prev,
                            [key]: !prev[key],
                          }));
                        }}

                        // onClick={() =>
                        //   setSelectedExercises((prev) => ({
                        //     ...prev,
                        //     [key]: !prev[key],
                        //   }))
                        // }
                      />
                      <IconButton
                        sx={{ position: "absolute", top: 2, left: 2 }}
                        onMouseEnter={(e) =>
                          handleMouseEnter(e, {
                            key,
                            title,
                            previewImage: previewImage, // Now using the unique image per item
                          })
                        }
                        onMouseLeave={handleMouseLeave}
                      >
                        <CenterFocusStrongIcon
                          sx={{
                            fontSize: "15px",
                            color: (theme) =>
                              alpha(theme.palette.text.secondary, 0.3),
                          }}
                        />
                      </IconButton>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>

            <Popper
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              placement={popperPlacement}
              sx={{ zIndex: 1300 }}
            >
              <Paper
                elevation={3}
                sx={{
                  maxWidth: 400,
                  backgroundColor: "background.paper",
                  ml: popperPlacement === "right-start" ? 2 : undefined,
                  mr: popperPlacement === "left-start" ? 2 : undefined,
                  borderRadius: 2,
                  p: 1,
                  pb: 0.2,
                }}
              >
                {previewType && (
                  <Box
                    component="img"
                    src={previewType.previewImage}
                    alt={`${previewType.title} preview`}
                    sx={{
                      width: "100%",
                      height: "auto",
                      borderRadius: 2,
                    }}
                  />
                )}
              </Paper>
            </Popper>

            <Box sx={{ ...styles.headlineLessonBox, mt: 8 }}>
              <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
                3. Generera lektionen
              </Typography>
            </Box>
            <Box
              sx={{
                ...styles.freshBox,
                padding: "40px",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "500", mb: 1, mt: 0 }}
                  >
                    Årskurs:
                  </Typography>
                  <Box sx={{ mt: 0.5, mb: 2 }}>
                    <GradeSelector grade={grade} setGrade={setGrade} />
                  </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "500", mt: 0 }}
                  >
                    Språk:
                  </Typography>
                  <Box sx={{ mt: 1, mb: 2 }}>
                    <LanguageSelector
                      language={language}
                      setLanguage={setLanguage}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <Typography
                      variant="subtitle1"
                      sx={{ fontWeight: "500", mb: 1 }}
                    >
                      Textlängd
                    </Typography>
                    <Select
                      labelId="num-questions-label"
                      id="num-questions-select"
                      value={wordCount}
                      onChange={(e) => setWordCount(e.target.value)}
                    >
                      <MenuItem value={300} selected>
                        300 ord
                      </MenuItem>
                      <MenuItem value={600}>600 ord</MenuItem>
                      <MenuItem value={1000}>1000 ord</MenuItem>

                      <Tooltip title="Endast tillgängligt för premium" arrow>
                        <span>
                          <MenuItem value={2000} disabled>
                            2000 ord
                          </MenuItem>
                          <MenuItem value={3000} disabled>
                            3000 ord
                          </MenuItem>
                          <MenuItem value={4000} disabled>
                            4000 ord
                          </MenuItem>
                          <MenuItem value={5000} disabled>
                            5000 ord
                          </MenuItem>
                          <MenuItem value={10000} disabled>
                            10000 ord
                          </MenuItem>
                          <MenuItem value={20000} disabled>
                            200000 ord
                          </MenuItem>
                        </span>
                      </Tooltip>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "500", mb: 1 }}
                  >
                    Typ:
                  </Typography>
                  <FormControl fullWidth>
                    <Select
                      key={textType}
                      value={textType}
                      onChange={(e) => setTextType(e.target.value)}
                    >
                      <MenuItem value="berattande">Berättande text</MenuItem>
                      <MenuItem value="argumenterande">
                        Argumenterande text
                      </MenuItem>
                      <MenuItem value="forklarande">Förklarande text</MenuItem>
                      <MenuItem value="beskrivande">Beskrivande text</MenuItem>
                      <MenuItem value="instruerande">
                        Instruerande text
                      </MenuItem>
                      <MenuItem value="informerande">
                        Informerande text (faktatext)
                      </MenuItem>
                      <MenuItem value="kronika">Krönika</MenuItem>
                      <MenuItem value="recension">Recension</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "500", mb: 1, mt: 2 }}
                  >
                    Berättarperspektiv:
                  </Typography>
                  <FormControl fullWidth>
                    <Select
                      value={perspective}
                      onChange={(e) => setPerspective(e.target.value)}
                    >
                      <MenuItem value="first-person">Första person</MenuItem>
                      <MenuItem value="third-person">Tredje person</MenuItem>
                      <MenuItem value="omniscient">Allvetande</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "500", mb: 1, mt: 2 }}
                  >
                    Meningsstruktur:
                  </Typography>
                  <FormControl fullWidth>
                    <Select
                      value={sentenceStructure}
                      onChange={(e) => setSentenceStructure(e.target.value)}
                    >
                      <MenuItem value="simple">Enkla meningar</MenuItem>
                      <MenuItem value="compound">Sammansatta meningar</MenuItem>
                      <MenuItem value="complex">Komplexa meningar</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={12} sx={{ display: "none" }}>
                  <Typography variant="subtitle1" sx={{ fontWeight: "500" }}>
                    Specifika instruktioner:
                  </Typography>
                  <FormControl fullWidth margin="normal" sx={{ mb: 3, mt: 1 }}>
                    <TextField
                      value={openAiInstructions}
                      onChange={(e) => setOpenAiInstructions(e.target.value)}
                      placeholder="T.ex. Inkludera lite svårare ord såsom defenestrera, kalibrera och frekventera."
                      variant="outlined"
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "12px",
                  width: "100%",
                }}
              >
                <Button
                  variant={
                    theme.palette.mode === "light" ? "purp" : "contained"
                  }
                  color="primary"
                  onClick={handleGenerateLesson}
                  disabled={isGeneratingLesson}
                  sx={{ mt: 7 }}
                  startIcon={
                    !isGeneratingLesson ? (
                      <GenerateIcon style={{ color: "#fff" }} />
                    ) : (
                      <CircularProgress style={{ color: "#fff" }} size={16} />
                    )
                  }
                >
                  {isGeneratingLesson ? "Genererar..." : "Generera lektion"}
                </Button>
              </div>
              {isGeneratingLesson && (
                <Box sx={{ mt: 6 }}>
                  <ProgressBar
                    progress={progress}
                    label="Genererar lektion — tar 30-60 sekunder..."
                  />
                </Box>
              )}
            </Box>
          </>
        )}
        {lessonData && (
          <>
            {!viewMode && (
              <Box
                id="lesson-content-container"
                data-clarity-unmask
                ref={lessonTitleRef}
                sx={{ ...styles.headlineLessonBox, mt: 10 }}
              >
                <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
                  4. Din lektion
                </Typography>
              </Box>
            )}

            <Box
              id="lesson-content-container-test"
              data-clarity-unmask
              sx={{
                ...styles.freshBox,
                padding: "30px",

                width: {
                  xs: "100%",
                  ...(viewMode && { sm: "70%" }),
                },
                margin: viewMode ? "0 auto" : undefined,
                marginTop: viewMode ? "100px" : undefined,
                ...(viewMode && { backgroundColor: "transparent" }),
                ...(viewMode && { borderColor: "transparent" }),
              }}
            >
              <SaveLessonDialog
                key={lessonData?.title || ""}
                open={saveDialogOpen}
                onClose={() => setSaveDialogOpen(false)}
                onSave={handleSaveWithComment}
                theme={theme}
                lessonName={lessonData?.title || ""}
                lessonObject={lessonData}
                lessonType={lessonConfig.lessonType}
              />
              <ImageGenerationModal
                open={showImageModal}
                onClose={handleCloseModal}
                onGenerate={handleGenerateWithInstructions}
                isGenerating={isGeneratingImages}
                instructions={imageInstructions}
                setInstructions={setImageInstructions}
                theme={theme}
              />

              {/*

 <Button
                      variant="print"
                      startIcon={<SettingsIcon />}
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsExpanded((prev) => !prev);
                      }}
                      sx={{
                        p: 5,
                        width: "100%",
                        height: "100%",

                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textTransform: "none",
                        fontSize: "1.1rem !important",
                        fontWeight: "500 !important",
                        color: "text.secondary",
                        background: "none",
                        "&:hover": {
                          background: "rgba(176, 180, 186, 0.08)",
                          color:
                            theme.palette.mode === "dark" ? "white" : "black",
                        },
                      }}
                    >
                      Visa kontrollpanelen
                    </Button>
  */}

              <Box sx={{ mt: 0 }}>
                {!viewMode && (
                  <Box sx={{ position: "relative" }}>
                    <Box
                      sx={{
                        ...styles.lessonControlPanel,
                        ...styles.lessonControlPanelNuevo,

                        "& .MuiButton-root": {
                          ...styles.controlPanelButtons,
                          ...styles.lessonControlPanelNewButtons,
                        },
                      }}
                    >
                      <Button
                        variant="print"
                        startIcon={<SaveAsIcon />}
                        sx={{ mt: 2, ml: 2 }}
                        onClick={handleSaveLessonClick}
                      >
                        Spara i lektionsbank
                      </Button>
                      <Button
                        variant="print"
                        startIcon={<PrintIcon />}
                        sx={{ mt: 2, ml: 2 }}
                        onClick={handlePrintClick}
                      >
                        Skriv ut
                      </Button>

                      <Button
                        variant="purp"
                        startIcon={<AutoFixHighIcon />}
                        onClick={() => setRegenerateDialogOpen(true)}
                        sx={{ mt: 2, ml: 2 }}
                      >
                        Anpassa svårighetsgrad
                      </Button>

                      <Translate
                        buttonRef={translateButtonRef}
                        lessonData={lessonData}
                        onTranslated={setLessonData}
                        callApi={callApi}
                        user={user}
                        showSnackbar={showSnackbar}
                      />

                      <PrintAsPdf
                        buttonRef={pdfButtonRef}
                        onPrint={() => handlePrintClick()}
                        sx={{ ml: 2 }}
                      />
                      <Button
                        variant="success"
                        sx={{}}
                        onClick={() => setShareDialogOpenFriends(true)}
                        startIcon={<ShareIcon />}
                      >
                        Dela med vänner
                      </Button>

                      <Button
                        variant="success"
                        sx={{
                          mt: 2,
                          ml: 2,
                        }}
                        onClick={() => setShareDialogOpen(true)}
                        startIcon={<ShareIcon />}
                      >
                        Dela med elever
                      </Button>
                      <ShareLink
                        buttonRef={shareLinkButtonRef}
                        key={lessonId}
                        lessonId={lessonId}
                        lessonData={lessonData}
                        user={user}
                        lessonType={lessonConfig.lessonType}
                        onCopy={(message, severity) =>
                          showSnackbar(message, severity)
                        }
                      />

                      <Tooltip title="Hur lektionen ser ut för eleverna">
                        <Button
                          variant="success"
                          onClick={() => {
                            window.scrollTo({ top: 0, behavior: "smooth" });
                            toggleViewMode();
                          }}
                          sx={{ ml: 2, mt: 2 }}
                          startIcon={
                            searchParams.get("v") === "t" ? (
                              <FullscreenExitIcon />
                            ) : (
                              <FullscreenIcon />
                            )
                          }
                        >
                          {searchParams.get("v") === "t"
                            ? "Redigeringsläge"
                            : "Elevläge"}
                        </Button>
                      </Tooltip>

                      <Button
                        variant={
                          theme.palette.mode === "light" ? "purp" : "contained"
                        }
                        color="primary"
                        onClick={() => handleImageButtonClick(null)}
                        disabled={isGeneratingImages}
                        startIcon={
                          !isGeneratingImages ? (
                            <ImageIcon />
                          ) : (
                            <CircularProgress size={16} />
                          )
                        }
                        sx={{ mt: 2 }}
                      >
                        {isGeneratingImages
                          ? "Genererar..."
                          : "Generera bilder"}
                      </Button>
                      <Box sx={{ display: "inline-block" }}>
                        <AddonQuiz
                          buttonRef={quizButtonRef}
                          lessonData={lessonData}
                          title={lessonData.title}
                          theme={theme}
                          user={user}
                          styles={styles}
                          showSnackbar={showSnackbar}
                          addQuizToLessonObject={(updatedData) => {
                            setLessonData(updatedData);
                            setTimeout(
                              () =>
                                quizSectionRef.current?.scrollIntoView({
                                  behavior: "smooth",
                                  block: "center",
                                }),
                              100,
                            );
                          }}
                          buttonText="Lägg till interaktivt quiz"
                        />
                      </Box>
                      <Box sx={{ display: "inline-block" }}>
                        <ReadingComprehensionDialog
                          buttonRef={comprehensionButtonRef}
                          key={lessonData?.title}
                          lessonData={lessonData}
                          user={user}
                          theme={theme}
                          styles={styles}
                          buttonText="Lägg till övningar"
                          addExercisesToLessonObject={(updateFunction) => {
                            setLessonData((prevLessonData) => ({
                              ...prevLessonData,
                              exercises: [
                                ...(prevLessonData.exercises || []),
                                ...updateFunction(prevLessonData).exercises,
                              ],
                            }));
                            setTimeout(
                              () =>
                                exercisesSectionRef.current?.scrollIntoView({
                                  behavior: "smooth",
                                  block: "center",
                                }),
                              100,
                            );
                          }}
                        />
                      </Box>

                      <Box sx={{ display: "inline-block" }}>
                        <AddonTimeline
                          buttonRef={timelineButtonRef}
                          lessonData={lessonData}
                          theme={theme}
                          user={user}
                          styles={styles}
                          showSnackbar={showSnackbar}
                          addTimelineToLessonObject={(updatedData) => {
                            setLessonData(updatedData);
                            setTimeout(
                              () =>
                                timelineSectionRef.current?.scrollIntoView({
                                  behavior: "smooth",
                                  block: "center",
                                }),
                              100,
                            );
                          }}
                          buttonText="Lägg till interaktiv tidslinje"
                        />
                      </Box>
                      <Box sx={{ display: "inline-block" }}>
                        <AddonTimelineSimple
                          buttonRef={timelineSimpleButtonRef}
                          lessonData={lessonData}
                          theme={theme}
                          user={user}
                          styles={styles}
                          showSnackbar={showSnackbar}
                          addTimelineToLessonObject={(updatedData) => {
                            setLessonData(updatedData);
                            setTimeout(
                              () =>
                                timelineSimpleSectionRef.current?.scrollIntoView(
                                  {
                                    behavior: "smooth",
                                    block: "center",
                                  },
                                ),
                              100,
                            );
                          }}
                          buttonText="Lägg till enkel tidslinje"
                        />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        ...styles.lessonControlPanelFade,
                      }}
                    />
                  </Box>
                )}

                {/* Title Section */}
                {isEditingTitle ? (
                  <TextField
                    value={lessonData.title}
                    onChange={(e) =>
                      setLessonData((prev) => ({
                        ...prev,
                        title: e.target.value,
                      }))
                    }
                    onBlur={() => {
                      setIsEditingTitle(false);
                      showSnackbar("Titeln har uppdaterats", "success");
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        setIsEditingTitle(false);
                        showSnackbar("Titeln har uppdaterats", "success");
                      }
                    }}
                    autoFocus
                    fullWidth
                    variant="standard"
                    sx={{
                      backgroundColor: "transparent",
                      "& .MuiInputBase-input": {
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "2.6em",
                        marginBottom: "1.2rem",
                        marginTop: "1.5rem",
                      },
                    }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                ) : (
                  <Box
                    sx={{
                      position: "relative",
                      display: "inline-block",
                      margin: "0 auto",
                      width: "100%",
                      mb: 3,
                      mt: viewMode ? 0 : 3,
                      ":hover .edit-icon": {
                        opacity: 1,
                      },
                    }}
                  >
                    <Typography
                      variant="h1"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "2.6em",

                        textAlign: "center",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 2,
                      }}
                    >
                      {lessonData.title || " "}
                      <Box
                        component="span"
                        sx={{
                          opacity: 0,
                          transition: "opacity 0.2s",
                          display: viewMode ? "none" : "inline-flex",
                          gap: 0.5,
                        }}
                        className="edit-icon"
                      >
                        <Tooltip title="Redigera" placement="top">
                          <IconButton
                            size="small"
                            onClick={() => setIsEditingTitle(true)}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Typography>
                  </Box>
                )}

                {/* Content Section */}
                <Box
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === "dark" ? "#15202b" : "#fff",
                    borderRadius: "10px",
                    padding: "20px",
                    mb: 4,
                    mt: 4,
                  }}
                >
                  <Box
                    sx={{
                      "&:hover .controls": {
                        opacity: 1,
                      },
                      display: "flex",
                      alignItems: "flex-start",
                      gap: 1,
                    }}
                  >
                    {isEditingContent ? (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                          width: "100%",
                        }}
                      >
                        <TextField
                          fullWidth
                          multiline
                          rows={15}
                          value={lessonData.content}
                          onChange={(e) =>
                            setLessonData((prev) => ({
                              ...prev,
                              content: e.target.value,
                            }))
                          }
                          onBlur={() => {
                            setIsEditingContent(false);
                            showSnackbar(
                              "Innehållet har uppdaterats",
                              "success",
                            );
                          }}
                          autoFocus
                          sx={{
                            ...styles.editingTextField,
                            "& .MuiInputBase-input": {
                              lineHeight: 1.8,
                            },
                          }}
                        />

                        <Tooltip title="Spara ändringar" placement="top">
                          <IconButton
                            onClick={() => {
                              setIsEditingContent(false);
                              showSnackbar(
                                "Innehållet har uppdaterats",
                                "success",
                              );
                            }}
                          >
                            <SaveAsIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    ) : (
                      <>
                        {viewMode ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: `
                              <style>
                                p {
                                  line-height: 29px !important;
                                }
                                h2,h3,h4 {
                                  margin-top:50px !important;
                                }
                              </style>
                              ${lessonData.content || " "}
                            `,
                            }}
                          />
                        ) : (
                          <Quill
                            value={content}
                            onChange={(newContent) => {
                              setContent(newContent);
                              setLessonData((prev) => ({
                                ...prev,
                                content: newContent,
                              }));
                            }}
                            theme={theme}
                          />
                        )}
                        <Typography
                          variant="body1"
                          sx={{
                            lineHeight: 1.8,
                            flex: 1,
                            display: "flex",
                            alignItems: "flex-start",
                            gap: 1,
                            display: "none",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          {lessonData.content || " "}
                          <Box
                            component="span"
                            className="controls"
                            sx={{
                              opacity: 0,
                              transition: "opacity 0.2s",
                              display: "inline-flex",
                              gap: 0.5,
                              ml: 1,
                              mt: 0.5,
                            }}
                          >
                            <Tooltip title="Redigera" placement="top">
                              <IconButton
                                size="small"
                                onClick={() => setIsEditingContent(true)}
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Typography>
                      </>
                    )}
                  </Box>
                </Box>

                {/* Images Grid */}
                <Grid
                  container
                  spacing={2}
                  sx={{ mb: 4, justifyContent: "center" }}
                >
                  {lessonData.images.map((image, index) => (
                    <Grid item xs={12} md={6} key={index}>
                      <HoverableImage
                        imageUrl={image.imageUrl}
                        isLoading={isGeneratingImages}
                        loadingIndex={loadingImageIndex}
                        currentIndex={index}
                        userId={user.uid}
                        theme={theme}
                        showSnackbar={showSnackbar}
                        onGenerateClick={() => handleImageButtonClick(index)}
                        onImageUpdate={(newImageUrl) => {
                          setLessonData((prev) => {
                            const newImages = [...prev.images];
                            newImages[index] = { imageUrl: newImageUrl };
                            return { ...prev, images: newImages };
                          });
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>

                {lessonData?.exercises && (
                  <Box ref={exercisesSectionRef}>
                    <LessonExercisesDisplay
                      exercises={lessonData.exercises}
                      onUpdateLessonData={setLessonData}
                      viewMode={viewMode}
                      theme={theme}
                      styles={styles}
                    />
                  </Box>
                )}

                {lessonData?.quiz && (
                  <Box
                    ref={quizSectionRef}
                    sx={{ mt: 5, mb: 5, position: "relative" }}
                  >
                    <LessonQuizDisplay
                      quizData={lessonData.quiz}
                      theme={theme}
                      styles={styles}
                      userId={user.uid}
                      viewMode={viewMode}
                      setLessonData={setLessonData}
                    />
                  </Box>
                )}

                {lessonData?.timelinesSimple && (
                  <Box
                    ref={timelineSimpleSectionRef}
                    sx={{ mt: 5, mb: 5, position: "relative" }}
                  >
                    <TimelineSimpleDisplay
                      timelineData={lessonData.timelinesSimple}
                      onTimelineDataChange={(newData) =>
                        setLessonData({
                          ...lessonData,
                          timelinesSimple: newData,
                        })
                      }
                      theme={theme}
                      styles={styles}
                      viewMode={viewMode}
                      user={user}
                    />
                  </Box>
                )}

                {lessonData?.timelines && (
                  <Box
                    ref={timelineSectionRef}
                    sx={{ mt: 5, mb: 5, position: "relative" }}
                  >
                    <TimelineDisplay
                      timelineData={lessonData.timelines}
                      onTimelineDataChange={(newData) =>
                        setLessonData({ ...lessonData, timelines: newData })
                      }
                      theme={theme}
                      styles={styles}
                      withImages={true}
                      viewMode={viewMode}
                      user={user}
                      onDelete={
                        viewMode
                          ? undefined
                          : (indexToDelete) => {
                              setLessonData((prev) => ({
                                ...prev,
                                timelines: prev.timelines.filter(
                                  (_, index) => index !== indexToDelete,
                                ),
                              }));
                              showSnackbar(
                                "Tidslinjen har tagits bort",
                                "success",
                              );
                            }
                      }
                    />
                  </Box>
                )}

                {!viewMode && (
                  <DashedPapers
                    handleQuizPaperClick={handleQuizPaperClick}
                    handleComprehensionPaperClick={
                      handleComprehensionPaperClick
                    }
                    handleTimelinePaperClick={handleTimelinePaperClick}
                    handleTimelineSimplePaperClick={
                      handleTimelineSimplePaperClick
                    }
                    quizSectionRef={quizSectionRef}
                    timelineSectionRef={timelineSectionRef}
                    timelineSimpleSectionRef={timelineSimpleSectionRef}
                    exercisesSectionRef={exercisesSectionRef}
                    lessonData={lessonData}
                    setLessonData={setLessonData}
                  />
                )}
              </Box>
            </Box>

            <RegenerateDialog
              open={regenerateDialogOpen}
              onClose={() => setRegenerateDialogOpen(false)}
              originalContent={lessonData.content}
              defaultLanguage={language}
              callApi={callApi}
              user={user}
              selectedWordCount={wordCount}
              showSnackbar={showSnackbar}
              theme={theme}
              lessonData={lessonData}
              onUpdateContent={(newContent) => {
                setLessonData((prev) => ({
                  ...prev,
                  content: newContent,
                }));
              }}
            />

            <SlideInPaper
              isExpanded={isExpanded}
              onExpandedChange={setIsExpanded}
              lessonTitleRef={lessonTitleRef}
              viewMode={viewMode}
              toggleViewMode={toggleViewMode}
              theme={theme}
              open={showControlPanel}
              user={user}
              onPrint={handlePrintClick}
              onAdjustDifficulty={() => setRegenerateDialogOpen(true)}
              onTranslate={() => translateButtonRef.current?.click()}
              onSaveAsPdf={() => pdfButtonRef.current?.click()}
              onShareLink={() => shareLinkButtonRef.current?.click()}
              onShareWithStudents={() => setShareDialogOpen(true)}
              onShareWithFriends={() => setShareDialogOpenFriends(true)}
              onSaveToBank={() => setSaveDialogOpen(true)}
              onStudentMode={() => {
                window.scrollTo({ top: 0, behavior: "smooth" });
                toggleViewMode();
              }}
              onGenerateExercises={() => {
                comprehensionButtonRef.current?.click();
              }}
              onGenerateQuiz={() => {
                quizButtonRef.current?.click();
              }}
              onGenerateAssignments={() => {
                comprehensionButtonRef.current?.click();
              }}
              onGenerateImages={() => handleImageButtonClick(null)}
              onGenerateTimeline={() => {
                timelineButtonRef.current?.click();
              }}
            />

            <ShareDialog
              open={shareDialogOpen}
              onClose={() => setShareDialogOpen(false)}
              onShare={handleShare}
              user={user}
              isLessonSharing={true}
              theme={theme}
              lessonName={lessonData?.title || ""}
              lessonObject={lessonData}
              lessonType={lessonConfig.lessonType}
            />
            <ShareDialog
              open={shareDialogOpenFriends}
              onClose={() => setShareDialogOpenFriends(false)}
              onShare={handleShare}
              user={user}
              isLessonSharing={true}
              theme={theme}
              lessonName={lessonData?.title || ""}
              lessonObject={lessonData}
              lessonType={lessonConfig.lessonType}
              shareWithFriends={true}
            />

            <ImageLoadingDialog
              open={isGeneratingImages}
              message="Genererar bilder... Tar 30-60 sekunder."
            />

            <LoadingBackdrop
              isOpen={isSharing}
              imagePath="/img/AI.png"
              loadingText={"Delar lektionen..."}
            />
          </>
        )}
      </Box>
    </Box>
  );
};

export default BasicReadingCompLesson;
