import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    Divider,
    Button,
} from "@mui/material";
import { InstructionItem } from "./functions";

const WhatsNewDialog = ({ open, onClose }) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle
                sx={{
                    fontSize: "1.5rem",
                    fontWeight: 600,
                    borderBottom: "1px solid",
                    borderColor: "divider",
                }}
            >
                Vad som är nytt på studera.ai
            </DialogTitle>
            <DialogContent
                sx={{
                    mt: 2,
                }}
            >
                <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ mt: 2, fontWeight: 600 }}
                >
                    Senaste uppdaterningarna
                </Typography>
                <Typography paragraph>
                    Vi jobbar på detta. Titta in snart igen :)
                </Typography>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ p: 0 }}>
                <Button onClick={onClose} variant="print">
                    Stäng
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default WhatsNewDialog;
