import React, { useState, useEffect } from "react";
import { animated, useSpring, useTransition } from "@react-spring/web";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

export const TimelineTransitionFlip = ({ children, timelineIndex }) => {
  const transitions = useTransition(
    { children, key: timelineIndex },
    {
      keys: (item) => item.key,
      from: {
        opacity: 0,
        transform: "perspective(1200px) rotateX(90deg)",
        position: "absolute",
        width: "100%",
        height: "100%",
        transformOrigin: "50% 0%",
      },
      enter: {
        opacity: 1,
        transform: "perspective(1200px) rotateX(0deg)",
        position: "relative",
      },
      leave: {
        opacity: 0,
        transform: "perspective(1200px) rotateX(-90deg)",
        position: "absolute",
      },
      config: {
        tension: 220,
        friction: 30,
        duration: 800,
      },
    },
  );

  return (
    <div className="relative overflow-hidden">
      {transitions((styles, item) => (
        <animated.div style={styles}>{item.children}</animated.div>
      ))}
    </div>
  );
};

export const TimelineTransition = ({ children, timelineIndex }) => {
  const transitions = useTransition(
    { children, key: timelineIndex },
    {
      keys: (item) => item.key,
      from: {
        opacity: 0,
        transform: "translate3d(100%,0,0)",
        position: "absolute",
        width: "100%",
        height: "100%",
      },
      enter: {
        opacity: 1,
        transform: "translate3d(0%,0,0)",
        position: "relative",
      },
      leave: {
        opacity: 0,
        transform: "translate3d(-100%,0,0)",
        position: "absolute",
      },
      config: {
        tension: 280,
        friction: 60,
        duration: 600,
      },
    },
  );

  return (
    <div className="relative overflow-hidden">
      {transitions((styles, item) => (
        <animated.div style={styles}>{item.children}</animated.div>
      ))}
    </div>
  );
};

export const AnimatedQuestion = ({ show, children }) => {
  const transitions = useTransition(show, {
    from: { opacity: 0, transform: "translateY(10px)" },
    enter: { opacity: 1, transform: "translateY(0px)" },
    leave: { opacity: 0, transform: "translateY(-10px)" },
    config: {
      tension: 400,
      friction: 14,
      mass: 1,
    },
  });
  return transitions((styles, item) =>
    item ? <animated.div style={styles}>{children}</animated.div> : null,
  );
};

// const AnimatedHint = ({ isRevealed, children }) => {
//   const springs = useSpring({
//     from: { opacity: 0, transform: "translateY(20px)" },
//     to: {
//       opacity: isRevealed ? 1 : 0,
//       transform: isRevealed ? "translateY(0px)" : "translateY(20px)",
//     },
//     config: { tension: 280, friction: 20 },
//   });

//   return <animated.div style={springs}>{children}</animated.div>;
// };

//

export const AnimatedHint = ({ isRevealed, children }) => {
  const springs = useSpring({
    from: { opacity: 0, transform: "translateY(20px)" },
    to: {
      opacity: isRevealed ? 1 : 0,
      transform: isRevealed ? "translateY(0px)" : "translateY(20px)",
    },
    config: { tension: 280, friction: 20 },
  });

  return <animated.div style={springs}>{children}</animated.div>;
};

export const SmoothTransitionHint = ({ isRevealed, children }) => {
  const [mountedChildren, setMountedChildren] = useState(children);

  useEffect(() => {
    if (isRevealed) {
      setMountedChildren(children);
    }
  }, [children, isRevealed]);

  const transitions = useTransition(isRevealed ? mountedChildren : null, {
    from: {
      opacity: 0,
      transform: "translateY(20px)",
      position: "absolute",
      width: "100%",
    },
    enter: {
      opacity: 1,
      transform: "translateY(0px)",
      position: "relative",
    },
    leave: {
      opacity: 0,
      transform: "translateY(-20px)",
    },
    config: {
      tension: 280,
      friction: 20,
      duration: 300,
    },
  });

  return (
    <div className="relative">
      {transitions((styles, item) =>
        item ? <animated.div style={styles}>{item}</animated.div> : null,
      )}
    </div>
  );
};

export const AnimatedText = ({ children }) => {
  const [content, setContent] = useState(children);

  useEffect(() => {
    if (children !== content) {
      setContent(children);
    }
  }, [children]);

  const springs = useSpring({
    from: {
      opacity: 0,
      filter: "blur(8px)",
      transform: "scale(1)",
    },
    to: {
      opacity: 1,
      filter: "blur(0px)",
      transform: "scale(1)",
    },
    reset: children !== content,
    config: {
      tension: 100,
      friction: 20,
      duration: 1000,
    },
  });

  return (
    <animated.div
      style={{
        ...springs,
        willChange: "transform, opacity, filter",
      }}
    >
      {children}
    </animated.div>
  );
};
