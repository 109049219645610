import React, { useRef, useEffect } from "react";
import {
  Paper,
  Button,
  Slide,
  Box,
  Typography,
  Collapse,
  IconButton,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import TranslateIcon from "@mui/icons-material/Translate";
import GenerateIcon from "@mui/icons-material/AutoAwesome";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import SettingsIcon from "@mui/icons-material/Settings";
import { darken } from "@mui/material/styles";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import SaveAsIcon from "@mui/icons-material/SaveAs";

import ClearIcon from "@mui/icons-material/Clear";
import PrintIcon from "@mui/icons-material/Print";
import TimelineIcon from "@mui/icons-material/Timeline";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import EditIcon from "@mui/icons-material/Edit";
import PowerPointIcon from "./PowerPointIcon";
import QuizIcon from "@mui/icons-material/Quiz";
import ShareIcon from "@mui/icons-material/Share";
import { useNavigate } from "react-router-dom";

const SlideInPaper = ({
  open,
  isExpanded,
  onExpandedChange,
  user,
  onPrint,
  onAdjustDifficulty,
  onTranslate,
  onShareLink,
  onShareWithStudents,
  onShareWithFriends,
  onSaveToBank,
  onEdit,
  onEditTimelineAndArticle,
  onEditLessonPlan,
  onGenerateExercises,
  onGenerateQuiz,
  onGenerateAssignments,
  onGenerateImages,
  onGenerateTimeline,
  onGenerateTopicImage,
  onGenerateParticipantImages,
  onTranslatePPTX,
  onEditDialog,
  onGenerateRhymePoemStory,
  onSaveAsPdf,
  onStudentMode,
  onResetAnswers,
  onFullscreen,
  theme,
  viewMode,
  toggleViewMode,
  onDownloadPPTX,
  lessonTitleRef,
}) => {
  const navigate = useNavigate();
  const paperRef = useRef(null);

  useEffect(() => {
    if (open && user?.guest) {
      // Moonshine: Ska den visas automatisk för gäster?
      // const timer = setTimeout(() => {
      //   onExpandedChange(true);
      // }, 1500);
      return () => clearTimeout(timer);
    }
  }, [open, user?.guest, onExpandedChange]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (paperRef.current && !paperRef.current.contains(event.target)) {
        onExpandedChange(false);
      }
    };

    if (open && isExpanded) {
      document.addEventListener("click", handleClickOutside); // CHANGED THIS
    }

    return () => {
      document.removeEventListener("click", handleClickOutside); // AND THIS
    };
  }, [open, isExpanded, onExpandedChange]);

  const handleGuestClick = (e) => {
    e.preventDefault();
    navigate("/app/skapa-konto?ref=startpage");
  };

  const handleExpandClick = (e) => {
    e.stopPropagation();
    if (viewMode) {
      setTimeout(() => {
        lessonTitleRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, 100);
      toggleViewMode();
    }
    onExpandedChange(true); // This still keeps the expand functionality
  };

  const handleCloseClick = (e) => {
    console.log("handleCloseClick called");
    e.stopPropagation();
    onExpandedChange(false);
  };

  const getButtonStyle = (isPrintButton) => {
    if (user?.guest && !isPrintButton) {
      return {};
    }
    return {};
  };

  return (
    <Slide direction="up" in={open} mountOnEnter unmountOnExit>
      <div style={{ position: "relative" }}>
        <Paper
          ref={paperRef}
          className="slide-in-paper"
          onClick={handleExpandClick}
          elevation={6}
          sx={{
            position: "fixed",
            bottom: -13,
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 1300,
            boxShadow:
              "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
            p: 0,
            borderRadius: "16px",
            overflow: "hidden",
            backgroundColor: isExpanded
              ? (theme) => darken(theme.palette.text.main, 0)
              : (theme) => darken(theme.palette.text.main, 0),
            backgroundImage: "none",
            maxWidth: !isExpanded ? "400px" : "650px",
            width: "calc(100% - 32px)",
            "&:hover": !isExpanded
              ? {
                  cursor: "pointer",
                  borderColor: (theme) => darken(theme.palette.grey[300], 0.02),
                  backgroundColor: isExpanded
                    ? (theme) => darken(theme.palette.text.main, 0.3)
                    : (theme) => darken(theme.palette.text.main, 0.3),
                }
              : {},
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              p: isExpanded ? 3 : 1,
              pb: isExpanded ? 3 : 2.3,
              borderBottom: isExpanded
                ? "1px solid rgba(145, 158, 171, 0.12)"
                : "none",
              bgcolor: "rgba(109, 49, 237, 0.04)",
              transition: "background-color 0.3s ease",
            }}
          >
            {isExpanded && (
              <>
                <SettingsIcon sx={{ fontSize: 28, mr: 2, color: "white" }} />
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 600,
                    flex: 1,
                    fontSize: "1.3rem",
                    color: "white",
                  }}
                >
                  Anpassa, redigera och dela lektionen
                </Typography>
              </>
            )}
            {isExpanded ? (
              <IconButton
                onClick={handleCloseClick}
                sx={{
                  color: "white",
                }}
              >
                <CloseIcon />
              </IconButton>
            ) : (
              <Button
                variant="print"
                endIcon={<KeyboardArrowDownIcon />}
                startIcon={<SettingsIcon style={{ fontSize: "1.6rem" }} />}
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderBottom: "0px",
                  textTransform: "none",
                  fontSize: "1.3rem !important",
                  fontWeight: "600 !important",
                  color: "white",
                  background: "none",
                  "&:hover": {
                    background: "none",
                  },
                }}
              >
                Kontrollpanelen
              </Button>
            )}
          </Box>

          <Collapse in={isExpanded} timeout={300}>
            <Box
              sx={{
                backgroundColor: (theme) =>
                  darken(theme.palette.background.paper, 0),
                mb: 1.6,
              }}
            >
              <Grid
                container
                sx={{
                  "& .MuiButton-root": {
                    borderRadius: 0,
                    display: "inline-flex",
                    p: 2,
                    textAlign: "left",
                    textTransform: "none",
                    fontSize: "1.1rem !important",
                    fontWeight: "500 !important",
                    color: "text.secondary",
                    width: "50%",

                    background:
                      theme.palette.mode === "dark"
                        ? "#1d2e3d"
                        : "linear-gradient(180deg, #ffffff 0%, #f7f7f9 100%)",

                    borderBottom:
                      theme.palette.mode === "dark"
                        ? "1px solid #263442"
                        : "1px solid #ededed",
                    borderRight:
                      theme.palette.mode === "dark"
                        ? "1px solid #263442"
                        : "1px solid #ededed",
                    transition: "none",
                    "&:hover": {
                      transition: "none",
                      color:
                        theme.palette.mode === "dark" ? "#8b5cf6" : "white",
                      background:
                        theme.palette.mode === "dark"
                          ? "rgba(139, 92, 246, 0.1) !important"
                          : (theme) => darken(theme.palette.text.main, 0.3),
                    },
                  },
                }}
              >
                <Grid item xs={12}>
                  {(user.guest || onPrint) && (
                    <Button
                      fullWidth
                      startIcon={<PrintIcon />}
                      onClick={user.guest ? onPrint : onPrint}
                      sx={getButtonStyle(true)}
                    >
                      Skriv ut
                    </Button>
                  )}

                  {(user.guest || onSaveAsPdf) && (
                    <Button
                      fullWidth
                      startIcon={<PictureAsPdfIcon />}
                      onClick={user.guest ? onSaveAsPdf : onSaveAsPdf}
                      sx={getButtonStyle(true)}
                    >
                      Spara som PDF
                    </Button>
                  )}
                  {(user.guest || onAdjustDifficulty) && (
                    <Button
                      fullWidth
                      startIcon={<AutoFixHighIcon />}
                      onClick={
                        user.guest ? handleGuestClick : onAdjustDifficulty
                      }
                      sx={getButtonStyle(false)}
                    >
                      Anpassa svårighetsgrad
                    </Button>
                  )}

                  {onDownloadPPTX && (
                    <Button
                      fullWidth
                      startIcon={<PowerPointIcon />}
                      onClick={user.guest ? handleGuestClick : onDownloadPPTX}
                      sx={getButtonStyle(false)}
                    >
                      Ladda ned PowerPoint
                    </Button>
                  )}
                  {onTranslatePPTX && (
                    <Button
                      fullWidth
                      startIcon={<TranslateIcon />}
                      onClick={user.guest ? handleGuestClick : onTranslatePPTX}
                      sx={getButtonStyle(false)}
                    >
                      Översätt presentationen
                    </Button>
                  )}

                  {(user.guest || onTranslate) && (
                    <Button
                      fullWidth
                      startIcon={<TranslateIcon />}
                      onClick={user.guest ? handleGuestClick : onTranslate}
                      sx={getButtonStyle(false)}
                    >
                      Översätt lektionen
                    </Button>
                  )}
                  {onEditDialog && (
                    <Button
                      fullWidth
                      startIcon={<TranslateIcon />}
                      onClick={user.guest ? handleGuestClick : onEditDialog}
                      sx={getButtonStyle(false)}
                    >
                      Redigera Escape Room
                    </Button>
                  )}
                  {onEditTimelineAndArticle && (
                    <Button
                      fullWidth
                      startIcon={<TranslateIcon />}
                      onClick={
                        user.guest ? handleGuestClick : onEditTimelineAndArticle
                      }
                      sx={getButtonStyle(false)}
                    >
                      Redigera tidslinjer & artikeln
                    </Button>
                  )}

                  {onEditLessonPlan && (
                    <Button
                      fullWidth
                      startIcon={<TranslateIcon />}
                      onClick={user.guest ? handleGuestClick : onEditLessonPlan}
                      sx={getButtonStyle(false)}
                    >
                      Redigera lektionsplanen
                    </Button>
                  )}

                  {(user.guest || onShareWithFriends) && (
                    <Button
                      fullWidth
                      startIcon={<ShareIcon />}
                      onClick={
                        user.guest ? handleGuestClick : onShareWithFriends
                      }
                      sx={getButtonStyle(false)}
                    >
                      Dela med vän
                    </Button>
                  )}
                  {(user.guest || onShareLink) && (
                    <Button
                      fullWidth
                      startIcon={<InsertLinkIcon />}
                      onClick={user.guest ? handleGuestClick : onShareLink}
                      sx={getButtonStyle(false)}
                    >
                      Dela länk till lektionen
                    </Button>
                  )}
                  {(user.guest || onShareWithStudents) && (
                    <Button
                      fullWidth
                      startIcon={<ShareIcon />}
                      onClick={
                        user.guest ? handleGuestClick : onShareWithStudents
                      }
                      sx={getButtonStyle(false)}
                    >
                      Dela med mina elever
                    </Button>
                  )}

                  {(user.guest || onSaveToBank) && (
                    <Button
                      fullWidth
                      startIcon={<SaveAsIcon />}
                      onClick={user.guest ? handleGuestClick : onSaveToBank}
                      sx={getButtonStyle(false)}
                    >
                      Spara i min lektionsbank
                    </Button>
                  )}

                  {(user.guest || onEdit) && (
                    <Button
                      fullWidth
                      startIcon={<EditIcon />}
                      onClick={user.guest ? handleGuestClick : onEdit}
                      sx={getButtonStyle(false)}
                    >
                      Redigera
                    </Button>
                  )}
                  {(user.guest || onGenerateExercises) && (
                    <Button
                      fullWidth
                      startIcon={<GenerateIcon />}
                      onClick={
                        user.guest ? handleGuestClick : onGenerateExercises
                      }
                      sx={getButtonStyle(false)}
                    >
                      Generera övningar
                    </Button>
                  )}

                  {onGenerateRhymePoemStory && (
                    <Button
                      fullWidth
                      startIcon={<GenerateIcon />}
                      onClick={
                        user.guest ? handleGuestClick : onGenerateRhymePoemStory
                      }
                      sx={getButtonStyle(false)}
                    >
                      Generera ramsa/berättelse
                    </Button>
                  )}

                  {(user.guest || onGenerateQuiz) && (
                    <Button
                      fullWidth
                      startIcon={<QuizIcon />}
                      onClick={user.guest ? handleGuestClick : onGenerateQuiz}
                      sx={getButtonStyle(false)}
                    >
                      Generera prov/quiz
                    </Button>
                  )}
                  {(user.guest || onGenerateAssignments) && (
                    <Button
                      fullWidth
                      startIcon={<GenerateIcon />}
                      onClick={
                        user.guest ? handleGuestClick : onGenerateAssignments
                      }
                      sx={getButtonStyle(false)}
                    >
                      Generera uppgifter
                    </Button>
                  )}
                  {(user.guest || onGenerateImages) && (
                    <Button
                      fullWidth
                      startIcon={<AddPhotoAlternateIcon />}
                      onClick={user.guest ? handleGuestClick : onGenerateImages}
                      sx={getButtonStyle(false)}
                    >
                      Generera nya bilder
                    </Button>
                  )}
                  {(user.guest || onGenerateTimeline) && (
                    <Button
                      fullWidth
                      startIcon={<TimelineIcon />}
                      onClick={
                        user.guest ? handleGuestClick : onGenerateTimeline
                      }
                      sx={getButtonStyle(false)}
                    >
                      Generera tidslinje
                    </Button>
                  )}
                  {(user.guest || onStudentMode) && (
                    <Button
                      fullWidth
                      startIcon={<FullscreenIcon />}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (user.guest) {
                          handleGuestClick(e);
                        } else {
                          onStudentMode();
                          onExpandedChange(false);
                        }
                      }}
                      sx={getButtonStyle(false)}
                    >
                      Visa elevläge
                    </Button>
                  )}

                  {/* Konversation */}

                  {onGenerateTopicImage && (
                    <Button
                      fullWidth
                      startIcon={<AddPhotoAlternateIcon />}
                      onClick={
                        user.guest ? handleGuestClick : onGenerateTopicImage
                      }
                      sx={getButtonStyle(false)}
                    >
                      Generera ämnesbild
                    </Button>
                  )}

                  {onGenerateParticipantImages && (
                    <Button
                      fullWidth
                      startIcon={<AddPhotoAlternateIcon />}
                      onClick={
                        user.guest
                          ? handleGuestClick
                          : onGenerateParticipantImages
                      }
                      sx={getButtonStyle(false)}
                    >
                      Generera deltagarbilder
                    </Button>
                  )}

                  {/* Jeopardy */}
                  {onResetAnswers && (
                    <Button
                      fullWidth
                      startIcon={<ClearIcon />}
                      onClick={user.guest ? handleGuestClick : onResetAnswers}
                      sx={getButtonStyle(false)}
                    >
                      Återställ svar
                    </Button>
                  )}

                  {onFullscreen && (
                    <Button
                      fullWidth
                      startIcon={<FullscreenIcon />}
                      onClick={user.guest ? handleGuestClick : onFullscreen}
                      sx={getButtonStyle(false)}
                    >
                      Fullskärm
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Collapse>
        </Paper>
      </div>
    </Slide>
  );
};

export default SlideInPaper;
