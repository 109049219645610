import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  Select,
  IconButton,
  Tooltip,
  MenuItem,
  Typography,
  Divider,
  TextField,
  CircularProgress,
  Grid,
  Avatar,
} from "@mui/material";
import GenerateIcon from "@mui/icons-material/AutoAwesome";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import InfoBox from "./InfoBox";
import { GiTeacher } from "react-icons/gi";
import LanguageSelector from "./LanguageSelector";
import { handlePrintQuiz } from "./printFunctions";
import TeacherQuizDisplay from "./TeacherQuizDisplay";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import LoadingBackdrop from "./LoadingBackdrop";

import ShareDialog from "./ShareDialog";
import ShareIcon from "@mui/icons-material/Share";
import Translate from "./Translate";
import SlideInPaper from "./SlideInPaper";
import ShareLink from "./ShareLink";

import MenuBookIcon from "@mui/icons-material/MenuBook";
import PowerPointIcon from "./PowerPointIcon";
import ImageLoadingDialog from "./ImageLoadingDialog";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import HoverableImage from "./HoverableImage";
import { callApi } from "./api"; // Adjust import path as needed
import GradeSelector, { gradeInstructions } from "./GradeSelector";
import { useSnackbar } from "./SnackbarHandler";

import ProgressBar from "./ProgressBar"; // Import the ProgressBar component
import PowerPointDisplay from "./PowerPointDisplay";
import ImageGenerationModal from "./ImageGenerationModal";

import Logger from "./Logger";
import { FourSquare } from "react-loading-indicators";

import PptxGenerator from "./PptxGenerator";

import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import QuestionTypeSelector from "./quiz/QuestionTypeSelector";
import ImageIcon from "@mui/icons-material/Image";

import QuizIcon from "@mui/icons-material/Quiz";
import EditIcon from "@mui/icons-material/Edit";
import {
  generateAndProcessImage,
  trackLesson,
  handleImageGenerationError,
} from "./functions";

import { useSearchParams, useParams } from "react-router-dom";
import { ThemeSelector } from "./ThemeSelector";
import { ThemeSelectorFlow } from "./ThemeSelectorFlow";
import PrintIcon from "@mui/icons-material/Print";

import LessonDrawer from "./LessonDrawer";
import SaveLessonDialog from "./SaveLessonDialog";

import HistoryIcon from "@mui/icons-material/History";

import { db } from "./firebase";
import {
  collection,
  updateDoc,
  query,
  where,
  limit,
  serverTimestamp,
  addDoc,
  getDocs,
} from "firebase/firestore";

import {
  Science as ScienceIcon,
  BugReport as BugIcon,
  WaterDrop as WaterIcon,
  Bolt as EnergyIcon,
  Park as TreeIcon,
  Visibility as EyeIcon,
  Restaurant as FoodIcon,
  Construction as ToolIcon,
  Air as WeatherIcon,
  Pets as AnimalIcon,
} from "@mui/icons-material";

const PowerPointGenerator = ({ user, theme, styles }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);

  const [searchParams] = useSearchParams();

  const urlToTheme = {
    jul: "christmas",
    host: "fall",
    var: "spring",
    vinter: "winter",
    sommar: "summer",
    pask: "easter",
    halloween: "halloween",
    nyar: "newYear",
  };

  const [lessonTheme, setLessonTheme] = useState(() => {
    const urlTheme = searchParams.get("tema");
    return urlToTheme[urlTheme] || urlTheme || "generateWithAi";
  });

  const [language, setLanguage] = useState("svenska");
  const [highLevelFactCount, setHighLevelFactCount] = useState(4);
  const [triviaPerFactCount, setTriviaPerFactCount] = useState(3);
  const [isGeneratingLesson, setIsGeneratingLesson] = useState(false);
  const [lessonData, setLessonData] = useState(null);
  const [themeColor, setThemeColor] = useState("#000000");
  const [isGeneratingTopicImage, setIsGeneratingTopicImage] = useState(false);
  const [isGeneratingFactImages, setIsGeneratingFactImages] = useState(false);
  const [progress, setProgress] = useState(0);
  const [lessonProgress, setLessonProgress] = useState(0); // Add this new state
  const [questionType, setQuestionType] = useState("multipleChoice");
  const [isGeneratingQuiz, setIsGeneratingQuiz] = useState(false);
  const [quizData, setQuizData] = useState(null);
  const [generatedFactImages, setGeneratedFactImages] = useState(new Set());
  const { showSnackbar } = useSnackbar();
  const [grade, setGrade] = useState("åk6");
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageInstructions, setImageInstructions] = useState("");
  const [imageGenerationType, setImageGenerationType] = useState(null);
  const [loadingImageIndex, setLoadingImageIndex] = useState(null);
  const [openAiInstructions, setOpenAiInstructions] = useState("");
  const [selectedFactKey, setSelectedFactKey] = useState(null);
  const [customTheme, setCustomTheme] = useState("");
  const [isGeneratingImages, setIsGeneratingImages] = useState(false);
  const [isEditingClue, setIsEditingClue] = useState(false);
  const [isEditingTopic, setIsEditingTopic] = useState(false);

  // Add these new state variables at the top with other state
  const [editingSections, setEditingSections] = useState({});
  const [editingFactKey, setEditingFactKey] = useState(null);
  const [editedTitles, setEditedTitles] = useState({});
  const [youtubeTranscript, setYoutubeTranscript] = useState("");
  const [predefinedTheme, setPredefinedTheme] = useState(null);
  const lessonTitleRef = useRef(null); // Add this ref
  const transcriptRef = useRef("");
  const uploadedFileRef = useRef("");
  const [showControlPanel, setShowControlPanel] = useState(false);

  const [shareDialogOpenFriends, setShareDialogOpenFriends] = useState(false);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [isSharing, setIsSharing] = useState(false);
  const pdfButtonRef = useRef(null);
  const translateButtonRef = useRef(null);
  const pptxButtonRef = useRef(null);
  const shareLinkButtonRef = useRef(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const wikipediaContentRef = useRef("");
  const randomizedContentRef = useRef("");
  const coursePlanContentRef = useRef("");
  const bookContentRef = useRef("");

  const topicSectionRef = useRef(null);
  const factsSectionRef = useRef(null);
  const urlContentRef = useRef("");
  useEffect(() => {
    Logger.log("lessonTheme: ", lessonTheme);
  }, [lessonTheme]);

  useEffect(() => {
    Logger.log("predefinedTheme: ", predefinedTheme);
  }, [predefinedTheme]);

  useEffect(() => {
    Logger.log("customTheme: ", customTheme);
  }, [customTheme]);

  useEffect(() => {
    Logger.log("youtubeTranscript: ", transcriptRef.current);
  }, [transcriptRef.current]);

  useEffect(() => {
    Logger.log("wikipediaContentRef: ", wikipediaContentRef.current);
  }, [wikipediaContentRef.current]);
  useEffect(() => {
    Logger.log("uploadedFileRef: ", uploadedFileRef.current);
  }, [uploadedFileRef.current]);

  useEffect(() => {
    Logger.log("randomizedContentRef: ", randomizedContentRef.current);
  }, [randomizedContentRef.current]);

  useEffect(() => {
    Logger.log("coursePlanContentRef: ", coursePlanContentRef.current);
  }, [coursePlanContentRef.current]);

  const additionalThemes = [
    {
      type: "subheader",
      label: "Specifika ämnen som passar perfekt för presentationer",
    },
    {
      value: "photosynthesis",
      label: "Fotosyntesen",
      content: "Hur fotosyntesen fungerar i detalj",
      Icon: TreeIcon,
    },
    {
      value: "honeybees",
      label: "Honungsbin",
      content: "Honungsbinas liv och betydelse",
      Icon: BugIcon,
    },
    {
      value: "watercycle",
      label: "Vattnets kretslopp",
      content: "Vattnets olika faser och kretslopp",
      Icon: WaterIcon,
    },
    {
      value: "static-electricity",
      label: "Statisk elektricitet",
      content: "Hur statisk elektricitet uppstår och fungerar",
      Icon: EnergyIcon,
    },
    {
      value: "human-eye",
      label: "Ögat",
      content: "Ögats olika delar och hur synen fungerar",
      Icon: EyeIcon,
    },
    {
      value: "digestion",
      label: "Matspjälkningen",
      content: "Matspjälkningssystemet steg för steg",
      Icon: FoodIcon,
    },
    {
      value: "simple-machines",
      label: "Enkla maskiner",
      content: "De sex enkla maskinerna och hur de fungerar",
      Icon: ToolIcon,
    },
    {
      value: "weather-types",
      label: "Vädertyper",
      content: "Olika typer av väder och hur de uppstår",
      Icon: WeatherIcon,
    },
    {
      value: "wolf-pack",
      label: "Vargflocken",
      content: "Vargens liv och sociala struktur",
      Icon: AnimalIcon,
    },
    {
      value: "chemical-reactions",
      label: "Kemiska reaktioner",
      content: "Vanliga kemiska reaktioner i vardagen",
      Icon: ScienceIcon,
    },
  ];

  const { lessonId } = useParams();

  const subject = searchParams.get("subject") || null;

  useEffect(() => {
    const loadLessonFromId = async () => {
      if (lessonId) {
        try {
          const q = query(
            collection(db, "lesson_usage"),
            where("__name__", ">=", lessonId),
            where("__name__", "<=", lessonId + "\uf8ff"),
            where("lessonType", "==", "PowerPointGenerator.js"),
            limit(1),
          );

          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            const docData = querySnapshot.docs[0].data();

            if (!docData.hasOpened) {
              await updateDoc(querySnapshot.docs[0].ref, {
                hasOpened: true,
                openedAt: Date.now(),
              });
            }

            setLessonData(docData.data);

            // Scroll to lesson content
            setTimeout(() => {
              lessonTitleRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }, 100);

            showSnackbar("Presentationen har laddats in.", "success");
          } else {
            showSnackbar("Kunde inte hitta presentationen", "error");
          }
        } catch (error) {
          console.error("Error loading lesson:", error);
          showSnackbar(
            "Ett fel uppstod när presentationen skulle laddas",
            "error",
          );
        }
      }
    };

    loadLessonFromId();
  }, lessonId);

  useEffect(() => {
    Logger.log("lessonData: ", lessonData);
    const isDataValid =
      lessonData !== null &&
      lessonData !== undefined &&
      Object.keys(lessonData).length > 0 &&
      !Object.values(lessonData).includes("");

    if (isDataValid !== showControlPanel) {
      const timer = setTimeout(() => {
        setShowControlPanel(isDataValid);
      }, 100);

      return () => clearTimeout(timer);
    }
  }, [lessonData, showControlPanel]);

  const handleSaveLessonClick = () => {
    setSaveDialogOpen(true);
  };

  const handleSaveWithComment = async (comment, name, coverImage, tags) => {
    Logger.log("handleSaveWithComment: ", JSON.stringify(lessonData, null, 2));
    await trackLesson(
      user.uid,
      "PowerPointGenerator.js",
      lessonData,
      null,
      true,
      comment,
      name,
      coverImage,
      tags,
    );
    setDrawerOpen(true);
    showSnackbar("Presentationen sparad i din lektionsbank!", "success");
  };

  const handleShare = async (
    lessonComment,
    lessonName,
    selectedImage,
    tags,
    shareData,
  ) => {
    setIsSharing(true);
    await new Promise((resolve) => setTimeout(resolve, 1500));

    try {
      // Create a document for each student and then create notifications with the doc IDs
      const sharePromises = shareData.studentIds.map(async (studentId) => {
        const documentData = {
          userId: user.uid,
          shared: true,
          sharedTo: studentId,
          comment: shareData.comment,
          sharedAt: Date.now(),
          fileUrl: shareData.fileUrl,
          lessonType: "PowerPointGenerator.js",
          timestamp: serverTimestamp(),
          lessonComment,
          lessonName,
          coverImage: selectedImage,
          hasPrinted: true,
          data: lessonData,
        };

        // Create the lesson document and get its ID
        const lessonDoc = await addDoc(
          collection(db, "lesson_usage"),
          documentData,
        );

        Logger.log(
          "Adding document for student",
          studentId,
          ":",
          JSON.stringify(documentData, null, 2),
        );

        // Create notification with the lesson doc ID
        await addDoc(collection(db, "notifications"), {
          type: "lesson_shared",
          recipientId: studentId,
          senderId: user.uid,
          read: false,
          createdAt: serverTimestamp(),
          lessonData: {
            lessonId: lessonDoc.id,
            lessonType: documentData.lessonType,
            lessonName: documentData.lessonName,
            fileUrl: documentData.fileUrl,
            comment: documentData.comment,
            coverImage: selectedImage,
          },
        });

        return lessonDoc;
      });

      await Promise.all(sharePromises);
      showSnackbar(`Du har nu delat presentationen!`, "success");
    } catch (error) {
      showSnackbar(
        "Fel vid delande av presentationen: " + error.message,
        "error",
      );
      console.error(error);
    } finally {
      setIsSharing(false);
    }
  };

  // Add these new handler functions with other functions
  const handleSectionDelete = (factKey) => {
    setLessonData((prev) => {
      const newData = { ...prev };
      const newHighLevelFacts = { ...prev.highLevelFacts };
      delete newHighLevelFacts[factKey];
      newData.highLevelFacts = newHighLevelFacts;
      return newData;
    });
    showSnackbar("Slide har tagits bort", "success");
  };

  const handleSectionEdit = (factKey) => {
    setEditingFactKey(factKey);
    setEditingSections((prev) => ({
      ...prev,
      [factKey]: true,
    }));
  };

  const handleSectionSave = (factKey) => {
    const newTitle = editedTitles[factKey] || factKey;

    setLessonData((prev) => {
      const newData = { ...prev };
      // Create a new ordered object to maintain section order
      const orderedHighLevelFacts = {};

      // First, copy all existing facts in their current order
      Object.entries(prev.highLevelFacts).forEach(([key, value]) => {
        if (key === factKey) {
          // For the edited section, use the new title
          orderedHighLevelFacts[newTitle] = value;
        } else {
          orderedHighLevelFacts[key] = value;
        }
      });

      newData.highLevelFacts = orderedHighLevelFacts;
      return newData;
    });

    setEditingSections((prev) => ({
      ...prev,
      [factKey]: false,
    }));

    setEditedTitles((prev) => {
      const newTitles = { ...prev };
      delete newTitles[factKey];
      return newTitles;
    });

    showSnackbar("Slide har uppdaterats", "success");
  };

  const handleFactUpdate = (factKey, newTitle, newFacts) => {
    setLessonData((prev) => {
      const newData = { ...prev };
      const newHighLevelFacts = { ...prev.highLevelFacts };
      const oldFactData = newHighLevelFacts[factKey];

      if (factKey !== newTitle && newTitle.trim() !== "") {
        delete newHighLevelFacts[factKey];
        newHighLevelFacts[newTitle] = {
          ...oldFactData,
          facts: newFacts,
        };
      } else {
        newHighLevelFacts[factKey] = {
          ...oldFactData,
          facts: newFacts,
        };
      }

      newData.highLevelFacts = newHighLevelFacts;
      return newData;
    });
  };

  const handleImageUpdate = (imageUrl, isTopicImage, factKey = null) => {
    if (isTopicImage) {
      setLessonData((prevData) => ({
        ...prevData,
        topicImage: imageUrl,
      }));
    } else if (factKey) {
      setLessonData((prevData) => ({
        ...prevData,
        highLevelFacts: {
          ...prevData.highLevelFacts,
          [factKey]: {
            ...prevData.highLevelFacts[factKey],
            image: imageUrl,
          },
        },
      }));
      setGeneratedFactImages((prev) => new Set([...prev, factKey]));
    }
  };
  const handleImageButtonClick = (type, factKey = null) => {
    setImageGenerationType(type);
    setSelectedFactKey(factKey);
    setShowImageModal(true);
  };
  const handleCloseModal = () => {
    setShowImageModal(false);
    setImageInstructions("");
  };

  const handleGenerateWithInstructions = async () => {
    handleCloseModal();

    if (imageGenerationType === "topic") {
      await handleGenerateTopicImage();
    } else if (imageGenerationType === "facts") {
      if (selectedFactKey) {
        await handleGenerateFactImage(selectedFactKey);
      } else {
        await handleGenerateFactImages();
      }
    }
  };

  const scrollToSection = (ref) => {
    ref.current?.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  const simulateProgress = (
    startProgress,
    endProgress,
    duration,
    setProgressFn,
  ) => {
    const stepSize = 0.5;
    const steps = (endProgress - startProgress) / stepSize;
    const stepDuration = duration / steps;
    let currentProgress = startProgress;

    const intervalId = setInterval(() => {
      currentProgress += stepSize;
      if (currentProgress >= endProgress) {
        clearInterval(intervalId);
      } else {
        setProgressFn(currentProgress);
      }
    }, stepDuration);

    // Return a cleanup function that can be called to stop the simulation
    return () => {
      clearInterval(intervalId);
    };
  };
  const handleGenerateTopicImage = async () => {
    setIsGeneratingTopicImage(true);
    setIsGeneratingImages(true);
    //scrollToSection(topicSectionRef);
    try {
      const prompt = `Create a image of ${lessonData.topic}. Do not add any text inside the image. ${imageInstructions}`;

      const imageUrl = await generateAndProcessImage(prompt, user.uid, {
        size: "1024x1024",
        model: "dall-e-3",
        quality: "standard",
      });

      setLessonData((prevData) => ({
        ...prevData,
        topicImage: imageUrl,
      }));

      showSnackbar("Bild för temat är nu genererad.", "success");
    } catch (error) {
      handleImageGenerationError(error, true, showSnackbar);
    } finally {
      setIsGeneratingTopicImage(false);
      setIsGeneratingImages(false);
    }
  };

  const handleGenerateFactImages = async () => {
    setIsGeneratingFactImages(true);
    setIsGeneratingImages(true);
    setGeneratedFactImages(new Set());
    //scrollToSection(factsSectionRef);

    try {
      const entries = Object.entries(lessonData.highLevelFacts);
      const totalCount = entries.length;

      // Create array of promises for all image generations
      const imagePromises = entries.map(async ([key]) => {
        try {
          setLoadingImageIndex(key);
          const prompt = `Create an image for the fact: "${key}" within the topic of "${lessonData.topic}". Do not add any text inside the image. ${imageInstructions}`;

          const imageUrl = await generateAndProcessImage(prompt, user.uid, {
            size: "1024x1024",
            model: "dall-e-3",
            quality: "standard",
          });

          setLessonData((prevData) => ({
            ...prevData,
            highLevelFacts: {
              ...prevData.highLevelFacts,
              [key]: {
                ...prevData.highLevelFacts[key],
                image: imageUrl,
              },
            },
          }));

          setGeneratedFactImages((prev) => new Set([...prev, key]));
          showSnackbar(`Genererat bild för "${key}"`, "info");

          return { key, success: true };
        } catch (error) {
          handleImageGenerationError(error, true, showSnackbar);
          return { key, success: false, error };
        }
      });

      // Wait for all promises to resolve
      const results = await Promise.all(imagePromises);

      // Show final status
      const successCount = results.filter((r) => r.success).length;
      if (successCount === totalCount) {
        showSnackbar("Alla bilder har genererats!", "success");
      } else {
        showSnackbar(
          `${successCount} av ${totalCount} bilder genererades`,
          "info",
        );
      }
    } catch (error) {
      console.error("Error in image generation process:", error);
      showSnackbar("Ett fel uppstod i bildgenereringsprocessen", "error");
    } finally {
      setIsGeneratingFactImages(false);
      setLoadingImageIndex(null);
      setIsGeneratingImages(false);
    }
  };

  const handleGenerateFactImage = async (factKey) => {
    setIsGeneratingFactImages(true);
    setLoadingImageIndex(factKey);
    try {
      const prompt = `Create an image for the fact: "${factKey}" within the topic of "${lessonData.topic}". Do not add any text inside the image. ${imageInstructions}`;

      const imageUrl = await generateAndProcessImage(prompt, user.uid, {
        size: "1024x1024",
        model: "dall-e-3",
        quality: "standard",
      });

      handleImageUpdate(imageUrl, false, factKey);
      showSnackbar("Bilden har genererats!", "success");
    } catch (error) {
      handleImageGenerationError(error, true, showSnackbar);
    } finally {
      setIsGeneratingFactImages(false);
      setLoadingImageIndex(null);
    }
  };

  const handleGenerateLesson = async () => {
    setIsGeneratingLesson(true);
    setLessonProgress(0);

    setLessonData(null);

    let stopProgressSimulation = null;
    try {
      const generateTheme = lessonTheme === "generateWithAi";
      let themeToUse;

      if (lessonTheme === "youtube") {
        themeToUse = transcriptRef.current;
      } else if (lessonTheme === "customTheme") {
        themeToUse = customTheme;
      } else if (lessonTheme === "predefined") {
        themeToUse = predefinedTheme;
      } else if (lessonTheme === "fileUpload") {
        themeToUse = uploadedFileRef.current;
      } else if (lessonTheme === "wikipedia") {
        themeToUse = wikipediaContentRef.current;
      } else if (lessonTheme === "randomizer") {
        themeToUse = randomizedContentRef.current;
      } else if (lessonTheme === "book") {
        themeToUse = bookContentRef.current;
      } else if (lessonTheme === "url") {
        themeToUse = urlContentRef.current;
      } else {
        //
        themeToUse = coursePlanContentRef.current;
      }

      Logger.log("themeToUse: ", themeToUse);

      if (!themeToUse?.trim()) {
        showSnackbar("Du måste välja underlag i steg 1.", "warning");
        return;
      }

      stopProgressSimulation = simulateProgress(
        0,
        90,
        15000,
        setLessonProgress,
      );

      const response = await callApi(
        [
          {
            role: "system",
            content: `You are an assistant that generates interesting school lessons. ${openAiInstructions}`,
          },
          {
            role: "user",
            content: `Generate a JSON object with a school lesson to learn new facts about a topic in "${language}". 
            It should be exactly ${triviaPerFactCount} few and short interesting facts per high-level fact about the topic. 
            The amount of high-level facts should be exactly ${highLevelFactCount}. 
            Include empty image placeholders (null) for "topicImage" and "image" fields in each high-level fact. 
            Prioritize novelty and uniqueness in each generation. Do NOT repeat themes from previous responses. ${
              generateTheme
                ? "Generate a COMPLETELY UNIQUE and RANDOM theme."
                : lessonTheme === "customTheme"
                  ? `Generate content about the specific topic "${themeToUse}".`
                  : `Generate a specific and UNIQUE topic WITHIN the chosen domain "${themeToUse}".`
            } Example response:
{
  "topic": "Gravitation",
  "clue": "En kraft som håller oss kvar på jorden",
  "topicImage": null,
  "highLevelFacts": {
    "Tyngdkraft på jorden": {
      "image": null,
      "facts": [
        "fact 1",
        "fact 2",
        "fact 3",
        "fact 4",
        "fact 5"
      ]
    },
    "Gravitation i rymden": {
      "image": null,
      "facts": [
        "fact 1",
        "fact 2",
        "fact 3",
        "fact 4",
        "fact 5"
      ]
    },
    "Hur gravitation upptäcktes": {
      "image": null,
      "facts": [
        "fact 1",
        "fact 2",
        "fact 3",
        "fact 4",
        "fact 5"
      ]
    }
  }
}`,
          },
        ],
        8000,
        true,
        user.uid,
      );

      // Stop the progress simulation
      if (stopProgressSimulation) {
        stopProgressSimulation();
      }

      setLessonProgress(95);

      let messageContent = response.data.choices[0].message.content.trim();

      if (messageContent.startsWith("```json")) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith("```")) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      const data = JSON.parse(messageContent);

      setLessonProgress(100);
      await new Promise((resolve) => setTimeout(resolve, 800)); // Longer delay for visibility

      setLessonData(data);

      showSnackbar(
        "Slides har blivit genererade. Nu kan du anpassa och spara!",
        "success",
      );

      setTimeout(() => {
        lessonTitleRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, 100);

      await trackLesson(user.uid, "PowerPointGenerator.js", null, {
        language: language,
        lessonTheme: lessonTheme,
      });
    } catch (error) {
      console.error("Error generating lesson:", error);
      showSnackbar("Nåt gick fel. Vänligen försök igen.", "error");
    } finally {
      setIsGeneratingLesson(false);
    }
  };

  const handleQuestionTypeChange = (event) => {
    setQuestionType(event.target.value);
  };

  const handleGenerateQuiz = async () => {
    setIsGeneratingQuiz(true);
    setProgress(0);
    let stopProgressSimulation = null;

    setQuizData(null);

    try {
      stopProgressSimulation = simulateProgress(0, 90, 15000, setProgress);

      // Format instructions based on question type, similar to Quiz.js
      let instructions = "";
      if (questionType === "fill-in-the-blank") {
        instructions = `Generate a fill in the blanks quiz with 10 questions based on the provided text. Everything should be in ${language}. Each question should be a JSON object with a 'question' field containing the text with a blank (_), an 'options' field containing 4 options, and a 'correct' field. Ensure all questions are returned in a single JSON array.`;
      } else if (questionType === "true-or-false") {
        instructions = `Generate a true or false quiz with 10 questions based on the provided text. The quiz should be in ${language}. Each question should be a JSON object with a "question" field, an "options" field containing only "Sant" and "Falskt" as options, and a "correct" field. Ensure all questions are returned in a single JSON array.`;
      } else {
        instructions = `Generate a quiz with 10 questions based on the provided text. The quiz should be in ${language}. Each question should be a JSON object with a "question" field, an "options" field containing 4 options, and a "correct" field. Ensure all questions are returned in a single JSON array. Do not use any markdown formatting.`;
      }

      const response = await callApi(
        [
          {
            role: "system",
            content: instructions,
          },
          {
            role: "user",
            content: `Generate questions based on this lesson content:

Topic: ${lessonData.topic}
Clue: ${lessonData.clue}

High-Level Facts:
${Object.entries(lessonData.highLevelFacts)
  .map(
    ([section, data]) => `
${section}:
${data.facts.join("\n")}`,
  )
  .join("\n")}

Format the response as a JSON array of questions. For example:
[
  {
    "question": "Question text here",
    "options": ${
      questionType === "true-or-false"
        ? '["Sant", "Falskt"]'
        : '["Option A", "Option B", "Option C", "Option D"]'
    },
    "correct": "Correct answer here"
  }
]`,
          },
        ],
        4000,
        true,
        user.uid,
      );

      if (stopProgressSimulation) {
        stopProgressSimulation();
      }

      setProgress(95);

      let messageContent = response.data.choices[0].message.content.trim();
      if (messageContent.startsWith("```json")) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith("```")) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      const questions = JSON.parse(messageContent);
      setProgress(100);
      await new Promise((resolve) => setTimeout(resolve, 800));

      setQuizData(questions); // This will now be an array of questions, matching Quiz.js format

      showSnackbar("Quiz har genererats!", "success");
    } catch (error) {
      console.error("Error generating quiz:", error);
      showSnackbar("Ett fel uppstod när quizet skulle genereras.", "error");
    } finally {
      setIsGeneratingQuiz(false);
    }
  };
  const isGeneratingAnyImages =
    isGeneratingTopicImage || isGeneratingFactImages;

  return (
    <>
      <Box sx={styles.container}>
        <Box
          sx={{
            ...styles.innerContainer,
            width: { xs: "100%", md: "100%" },
            margin: "0 auto",
          }}
        >
          <Typography
            variant="h1"
            gutterBottom
            sx={{
              fontWeight: "bold",
              fontSize: "1.8em",
              mb: 3,
              mt: 5,
              textAlign: "center",
            }}
          >
            Generera PowerPoint
          </Typography>

          <LessonDrawer
            userId={user.uid}
            lessonType="PowerPointGenerator.js"
            theme={theme}
            open={drawerOpen}
            onClose={() => setDrawerOpen(false)}
            lessonIcon={PowerPointIcon}
            onSelectLesson={(lesson) => {
              setLessonData(lesson.data);

              setDrawerOpen(false);

              showSnackbar(`Presentationen har laddats in.`, "success");
              setTimeout(() => {
                lessonTitleRef.current?.scrollIntoView({
                  behavior: "smooth",
                  block: "start", // This ensures it scrolls to the top of the element
                });
              }, 100);
            }}
          />

          <Box
            sx={{
              ...styles.buttonsContainer,
              ...styles.additionalButtonsContainer,
            }}
          >
            <Button
              variant="purp"
              sx={{ ...styles.tightButton }}
              onClick={() => setDrawerOpen(true)}
              startIcon={<BookmarkBorderIcon />}
            >
              Sparat
            </Button>
          </Box>

          <InfoBox
            icon={PowerPointIcon}
            title="Powerpoint Instructions"
            instructions={[
              "Välj underlag för presentationen som t.ex. ämne från Skolverkets kursplan, artikel från SO-rummet eller helt egna instruktioner",
              "Generera innehållet, redigera text och generera bilder utifrån dina behov",
              "Ladda ned som PowerPoint, eller spara i din egen lektionsbank.",
            ]}
            theme={theme}
            styles={styles}
          />
          <ThemeSelectorFlow
            subject={subject}
            value={lessonTheme}
            onChange={(e) => setLessonTheme(e.target.value)}
            customTheme={customTheme}
            onCustomThemeChange={(e) => setCustomTheme(e.target.value)}
            onPredefinedThemeChange={(e) => setPredefinedTheme(e.target.value)}
            onBookContentChange={(content) => {
              Logger.log("onBookContentChange", content.substring(0, 100));
              bookContentRef.current = content;
            }}
            onYoutubeTranscriptChange={(transcriptContent) => {
              Logger.log(
                "onYoutubeTranscriptChange körs med: ",
                transcriptContent,
              );
              transcriptRef.current = transcriptContent;
            }}
            onUrlContentChange={(content) => {
              Logger.log(
                "Parent: URL content received",
                content.substring(0, 300),
              );
              urlContentRef.current = content;
            }}
            onFileUpload={(content) => {
              Logger.log("onFileUpload körs med: ", content);
              uploadedFileRef.current = content;
            }}
            onWikipediaContentChange={(content) => {
              Logger.log(
                "Parent: Wikipedia content received",
                content.substring(0, 100),
              );
              wikipediaContentRef.current = content;
            }}
            onRandomizedContentChange={(content) => {
              Logger.log("randomizedContentRef", content.substring(0, 100));
              randomizedContentRef.current = content;
            }}
            onCoursePlanContentChange={(content) => {
              Logger.log("onCoursePlanChange", content.substring(0, 100));
              coursePlanContentRef.current = content;
            }}
            lessonType="PowerPointGenerator.js"
            user={user}
            theme={theme}
            styles={styles}
            onLessonSelect={(lesson) => {
              Logger.log("onLessonSelect körs");
              setLessonData(lesson.data);
              setTimeout(() => {
                lessonTitleRef.current?.scrollIntoView({
                  behavior: "smooth",
                  block: "start", // This ensures it scrolls to the top of the element
                });
              }, 100);
              showSnackbar("Presentationen har laddats in.", "success");
            }}
            additionalThemes={additionalThemes}
            forPowerPoint={true}
          />
          <Box sx={styles.headlineLessonBox}>
            <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
              2. Generera presentationen
            </Typography>
          </Box>
          <Box sx={{ ...styles.freshBox, padding: "40px" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "500", mt: 0, mb: 1 }}
                >
                  Språk:
                </Typography>
                <Box sx={{ mt: 0.5 }}>
                  <LanguageSelector
                    language={language}
                    setLanguage={setLanguage}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} md={4}>
                <Typography variant="subtitle1" sx={{ fontWeight: "500" }}>
                  Antal slides:
                </Typography>
                <FormControl fullWidth margin="normal" sx={{ mb: 3, mt: 1 }}>
                  <Select
                    value={highLevelFactCount}
                    onChange={(e) => setHighLevelFactCount(e.target.value)}
                  >
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={6}>6</MenuItem>

                    <Tooltip title="Endast tillgängligt för premium" arrow>
                      <span>
                        <MenuItem value={8} disabled>
                          8
                        </MenuItem>
                        <MenuItem value={12} disabled>
                          12
                        </MenuItem>

                        <MenuItem value={16} disabled>
                          16
                        </MenuItem>
                        <MenuItem value={20} disabled>
                          20
                        </MenuItem>
                        <MenuItem value={24} disabled>
                          24
                        </MenuItem>
                        <MenuItem value={28} disabled>
                          28
                        </MenuItem>
                      </span>
                    </Tooltip>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <Typography variant="subtitle1" sx={{ fontWeight: "500" }}>
                  Antal punkter per slide:
                </Typography>
                <FormControl fullWidth margin="normal" sx={{ mb: 3, mt: 1 }}>
                  <Select
                    value={triviaPerFactCount}
                    onChange={(e) => setTriviaPerFactCount(e.target.value)}
                  >
                    <MenuItem value={3}>3</MenuItem>
                    <Tooltip title="Endast tillgängligt för premium" arrow>
                      <span>
                        <MenuItem value={4} disabled>
                          4
                        </MenuItem>
                        <MenuItem value={5} disabled>
                          5
                        </MenuItem>
                        <MenuItem value={6} disabled>
                          6
                        </MenuItem>
                        <MenuItem value={7} disabled>
                          7
                        </MenuItem>
                        <MenuItem value={8} disabled>
                          8
                        </MenuItem>
                        <MenuItem value={9} disabled>
                          9
                        </MenuItem>
                        <MenuItem value={10} disabled>
                          10
                        </MenuItem>
                      </span>
                    </Tooltip>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sx={{ display: "none" }}>
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "500", mt: 1 }}
                >
                  Specifika instruktioner:
                </Typography>
                <FormControl fullWidth margin="normal" sx={{ mb: 3, mt: 1 }}>
                  <TextField
                    value={openAiInstructions}
                    onChange={(e) => setOpenAiInstructions(e.target.value)}
                    placeholder="T.ex. Ta endast med information mellan åren 1933-1936."
                    variant="outlined"
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Box>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "12px",
                  width: "100%",
                }}
              >
                <Button
                  variant={
                    theme.palette.mode === "light" ? "purp" : "contained"
                  }
                  color="primary"
                  onClick={handleGenerateLesson}
                  disabled={isGeneratingLesson || isGeneratingAnyImages}
                  sx={{ mt: 6 }}
                  startIcon={
                    !isGeneratingLesson ? (
                      <GenerateIcon style={{ color: "#fff" }} />
                    ) : (
                      <CircularProgress style={{ color: "#fff" }} size={16} />
                    )
                  }
                >
                  {isGeneratingLesson
                    ? "Genererar PowerPoint-slides..."
                    : "Generera PowerPoint"}
                </Button>
              </div>
            </Box>

            {isGeneratingLesson && (
              <Box sx={{ mt: 7 }}>
                <ProgressBar
                  progress={lessonProgress}
                  label="Genererar PowerPoint — tar 30-60 sekunder..."
                />
              </Box>
            )}
          </Box>

          {lessonData && (
            <>
              <Box
                ref={lessonTitleRef}
                sx={{ ...styles.headlineLessonBox, mt: 10 }}
              >
                <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
                  3. Anpassa och ladda ned
                </Typography>
              </Box>
              <Box sx={{ ...styles.freshBox, padding: "30px" }}>
                <Box sx={{ position: "relative" }}>
                  <Box
                    sx={{
                      ...styles.lessonControlPanel,
                      ...styles.lessonControlPanelNuevo,

                      "& .MuiButton-root": {
                        ...styles.controlPanelButtons,
                        ...styles.lessonControlPanelNewButtons,
                      },
                    }}
                  >
                    <PptxGenerator
                      buttonRef={pptxButtonRef}
                      lessonData={{
                        ...lessonData,
                        quizData: quizData,
                      }}
                      fromPowerPointGenerator={true}
                      buttonText="Ladda ned som PowerPoint"
                      loadingText="Genererar..."
                      theme={theme}
                      disabled={isGeneratingAnyImages}
                      variant="print"
                      sx={{ ml: 0 }}
                    />
                    <Button
                      variant="success"
                      startIcon={<SaveAsIcon />}
                      sx={{ mt: 2 }}
                      onClick={handleSaveLessonClick}
                    >
                      Spara i min lektionsbank
                    </Button>
                    <Button
                      variant="success"
                      sx={{}}
                      onClick={() => setShareDialogOpenFriends(true)}
                      startIcon={<ShareIcon />}
                    >
                      Dela med vänner
                    </Button>
                    <Button
                      variant="success"
                      sx={{
                        mt: 2,
                        ml: 2,
                      }}
                      onClick={() => setShareDialogOpen(true)}
                      startIcon={<ShareIcon />}
                    >
                      Dela med elever
                    </Button>

                    <Translate
                      buttonRef={translateButtonRef}
                      lessonData={lessonData}
                      onTranslated={setLessonData}
                      callApi={callApi}
                      user={user}
                      showSnackbar={showSnackbar}
                    />

                    <ShareLink
                      buttonRef={shareLinkButtonRef}
                      key={lessonId}
                      lessonId={lessonId}
                      lessonData={lessonData}
                      user={user}
                      lessonType={"PowerPointGenerator.js"}
                      onCopy={(message, severity) =>
                        showSnackbar(message, severity)
                      }
                      sx={{ mt: 2 }}
                    />

                    <Button
                      variant={
                        theme.palette.mode === "light" ? "purp" : "contained"
                      }
                      color="primary"
                      onClick={() => handleImageButtonClick("topic")}
                      disabled={
                        isGeneratingTopicImage || isGeneratingFactImages
                      }
                      sx={{ ml: 2 }}
                      startIcon={
                        !isGeneratingTopicImage ? (
                          <ImageIcon />
                        ) : (
                          <CircularProgress size={16} />
                        )
                      }
                    >
                      {isGeneratingTopicImage
                        ? "Genererar..."
                        : "Lägg till bild på titel-slide"}
                    </Button>

                    <Button
                      variant={
                        theme.palette.mode === "light" ? "purp" : "contained"
                      }
                      color="primary"
                      onClick={() => handleImageButtonClick("facts")}
                      disabled={
                        isGeneratingTopicImage || isGeneratingFactImages
                      }
                      sx={{ ml: 2 }}
                      startIcon={
                        !isGeneratingFactImages ? (
                          <ImageIcon />
                        ) : (
                          <CircularProgress size={16} />
                        )
                      }
                    >
                      {isGeneratingFactImages
                        ? "Genererar..."
                        : "Lägg till slide-bilder"}
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      ...styles.lessonControlPanelFade,
                    }}
                  />
                </Box>

                <SaveLessonDialog
                  key={lessonData?.topic || ""}
                  open={saveDialogOpen}
                  onClose={() => setSaveDialogOpen(false)}
                  onSave={handleSaveWithComment}
                  theme={theme}
                  lessonName={lessonData?.topic || ""}
                  lessonObject={lessonData}
                  lessonType="PowerPointGenerator.js"
                />

                <ImageGenerationModal
                  open={showImageModal}
                  onClose={handleCloseModal}
                  onGenerate={handleGenerateWithInstructions}
                  isGenerating={
                    imageGenerationType === "topic"
                      ? isGeneratingTopicImage
                      : isGeneratingFactImages
                  }
                  instructions={imageInstructions}
                  setInstructions={setImageInstructions}
                  generationType={imageGenerationType}
                  theme={theme}
                />

                <PowerPointDisplay
                  lessonData={lessonData}
                  isGeneratingTopicImage={isGeneratingTopicImage}
                  isGeneratingFactImages={isGeneratingFactImages}
                  loadingImageIndex={loadingImageIndex}
                  handleImageButtonClick={handleImageButtonClick}
                  handleImageUpdate={handleImageUpdate}
                  user={user}
                  theme={theme}
                  showSnackbar={showSnackbar}
                  setLessonData={setLessonData}
                  themeColor={themeColor}
                  styles={styles}
                />

                <SlideInPaper
                  isExpanded={isExpanded}
                  onExpandedChange={setIsExpanded}
                  lessonTitleRef={lessonTitleRef}
                  viewMode={false}
                  theme={theme}
                  open={showControlPanel}
                  user={user}
                  onTranslatePPTX={() => translateButtonRef.current?.click()}
                  onShareLink={() => shareLinkButtonRef.current?.click()}
                  onDownloadPPTX={() => pptxButtonRef.current?.click()}
                  onShareWithStudents={() => setShareDialogOpen(true)}
                  onShareWithFriends={() => setShareDialogOpenFriends(true)}
                  onSaveToBank={() => setSaveDialogOpen(true)}
                />

                <ShareDialog
                  open={shareDialogOpen}
                  onClose={() => setShareDialogOpen(false)}
                  onShare={handleShare}
                  user={user}
                  isLessonSharing={true}
                  theme={theme}
                  lessonName={lessonData?.topic || ""}
                  lessonObject={lessonData}
                  lessonType={"PowerPointGenerator.js"}
                />

                <ShareDialog
                  open={shareDialogOpenFriends}
                  onClose={() => setShareDialogOpenFriends(false)}
                  onShare={handleShare}
                  user={user}
                  isLessonSharing={true}
                  theme={theme}
                  lessonName={lessonData?.topic || ""}
                  lessonObject={lessonData}
                  lessonType={"PowerPointGenerator.js"}
                  shareWithFriends={true}
                />
                <LoadingBackdrop
                  isOpen={isSharing}
                  imagePath="/img/AI.png"
                  loadingText={"Delar presentationen..."}
                />

                <ImageLoadingDialog
                  open={isGeneratingImages}
                  message="Genererar bilder... Tar 30-60 sekunder."
                />
              </Box>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default PowerPointGenerator;
