import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Paper,
  Divider,
  Grid,
  Tooltip,
  IconButton,
} from "@mui/material";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import KeyIcon from "@mui/icons-material/Key";
import ClearIcon from "@mui/icons-material/Clear";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { East as ArrowIcon } from "@mui/icons-material";
import Confetti from "react-confetti";
import { lighten, alpha } from "@mui/material/styles";
import HoverableImage from "./HoverableImage";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import { Chip } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CircleIcon from "@mui/icons-material/Circle";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import ResultsView from "./TimelineBuilderGameResultView";

import { TimelineTransition, TimelineTransitionFlip } from "./Animations";

const TimelineSlot = ({ index, event, onMove, isActive }) => {
  const [{ isDragging }, drag] = useDrag({
    type: "event",
    item: { type: "event", index, event },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: !!event && isActive,
  });

  const [{ isOver }, drop] = useDrop({
    accept: "event",
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
    hover: (item, monitor) => {
      if (!monitor.isOver({ shallow: true })) return;
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) return;
      onMove(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const animationStyles = {
    transform: isOver ? "scale(1.02)" : "scale(1)",
    transition: "transform 0.2s ease, background-color 0.2s ease",
  };

  return (
    <Paper
      ref={(node) => drag(drop(node))}
      sx={{
        minWidth: 191,
        height: 220,
        textAlign: "center",
        opacity: isDragging ? 0 : 1,
        bgcolor: isOver ? "action.hover" : "background.paper",
        border: (theme) =>
          `1px dashed ${lighten(theme.palette.text.secondary, 0.3)}`,
        cursor: event && isActive ? "grab" : "default",
        borderRadius: 2,
        boxShadow: "none",
        position: "relative",
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...animationStyles,
        "&:hover": (theme) => ({
          background: `linear-gradient(to right, white, white) padding-box,
               linear-gradient(to right, transparent, transparent) border-box`,
          border: `2px dashed ${theme.palette.primary.main}`,
          padding: "1px",
        }),
        "&:active": (theme) => ({
          cursor: "grabbing",
          border: `2px dashed ${theme.palette.primary.main}`,
        }),
      }}
    >
      {event?.imageUrl && (
        <Box
          component="img"
          src={event.imageUrl}
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      )}
      {event && (
        <Box
          sx={{
            position: "relative",
            zIndex: 1,
            width: "100%",
            height: "100%",
            background: event.imageUrl
              ? `linear-gradient(rgba(0,0,0,0.45), rgba(0,0,0,0.45)),
                 linear-gradient(to top, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.2) 100%)`
              : `linear-gradient(135deg, #667eea 0%, #764ba2 100%)`,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "5px",
            opacity: isDragging ? 0 : 1,
            borderRadius: 2,
          }}
        >
          <Typography
            sx={{
              fontWeight: "500",
              color: "white",
              wordBreak: "break-word",
            }}
          >
            {event.event}
          </Typography>
        </Box>
      )}
    </Paper>
  );
};
//
const TimelineBuilderGame = ({
  lessonData,
  setLessonData,
  duration,
  theme,
  viewMode,
  toggleViewMode,
  user,
  isGeneratingImages,
  loadingImageIndex,
  handleImageButtonClick,
  showSnackbar,
}) => {
  const [timelineEvents, setTimelineEvents] = useState([]);
  const [currentTimelineIndex, setCurrentTimelineIndex] = useState(0);
  const [isGameStarted, setIsGameStarted] = useState(false);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [timerKey, setTimerKey] = useState(0);
  const [timeUp, setTimeUp] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [timer, setTimer] = useState(null);
  const [showResults, setShowResults] = useState(false);
  const [results, setResults] = useState([]);

  useEffect(() => {
    if (lessonData.timelines[currentTimelineIndex]?.timeline) {
      const shuffled = [
        ...lessonData.timelines[currentTimelineIndex].timeline,
      ].sort(() => Math.random() - 0.5);
      setTimelineEvents(shuffled);
    }
  }, [currentTimelineIndex, lessonData]);

  useEffect(() => {
    let timerInterval;
    if (isTimerRunning) {
      timerInterval = setInterval(() => {
        setElapsedTime((prev) => prev + 1);
      }, 1000);
      setTimer(timerInterval);
    }
    return () => {
      if (timerInterval) {
        clearInterval(timerInterval);
      }
    };
  }, [isTimerRunning]);

  useEffect(() => {
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [timer]);

  const handleStartGame = () => {
    if (!viewMode) {
      toggleViewMode();
    }

    setIsGameStarted(true);
    setIsTimerRunning(true);
    setTimerKey((prev) => prev + 1);
    setResults([]);
    setCurrentTimelineIndex(0);
    setShowResults(false);
  };

  const handleStopGame = () => {
    setIsGameStarted(false);
    setIsTimerRunning(false);
    setCurrentTimelineIndex(0);
    setTimeUp(false);
    setElapsedTime(0);

    if (timer) {
      clearInterval(timer);
    }
    const shuffled = [...lessonData.timelines[0].timeline].sort(
      () => Math.random() - 0.5,
    );
    setTimelineEvents(shuffled);
    setResults([]);
    setShowResults(false);
  };

  const moveItem = (dragIndex, hoverIndex) => {
    setTimelineEvents((prevEvents) => {
      const newEvents = [...prevEvents];
      const draggedEvent = newEvents[dragIndex];
      newEvents.splice(dragIndex, 1);
      newEvents.splice(hoverIndex, 0, draggedEvent);
      return newEvents;
    });
  };

  const checkAnswers = () => {
    // Save current timeline results
    setResults((prev) => [...prev, timelineEvents]);

    if (currentTimelineIndex < lessonData.timelines.length - 1) {
      // Move to next timeline
      setCurrentTimelineIndex((prev) => prev + 1);
      const nextTimeline = [
        ...lessonData.timelines[currentTimelineIndex + 1].timeline,
      ].sort(() => Math.random() - 0.5);
      setTimelineEvents(nextTimeline);
      setTimerKey((prev) => prev + 1);
      setIsTimerRunning(true);
    } else {
      // Show results view
      setShowResults(true);
      setIsTimerRunning(false);

      if (timer) {
        clearInterval(timer);
      }
    }
  };

  if (showResults) {
    return (
      <ResultsView
        results={results}
        timelines={lessonData.timelines}
        onRestart={handleStopGame}
        toggleViewMode={toggleViewMode}
        viewMode={viewMode}
        elapsedTime={elapsedTime}
      />
    );
  }

  if (!isGameStarted) {
    return (
      <Box
        sx={{
          padding: 4,
          margin: "0 auto",
          maxWidth: viewMode ? "70%" : "100%",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: "500",
            textAlign: "center",
            mb: 7,
            fontSize: "1rem",
            color: "#dc143c",
          }}
        >
          Börja med att läsa artikeln. Klicka sedan på "Bygg tidslinjen" och se
          hur många rätt du får!
        </Typography>

        <Typography
          variant="h4"
          sx={{ mb: 4, fontWeight: "600", textAlign: "center" }}
        >
          {lessonData.timelines[0]?.title}
        </Typography>

        {lessonData.timelines[0].articleSections && (
          <Grid container spacing={4} sx={{ mt: 9 }}>
            <Grid item xs={12} md={6}>
              {lessonData.timelines[0].articleSections
                .slice(0, 4)
                .map((section, index) => (
                  <Box key={index} sx={{ mb: 4 }}>
                    <Typography
                      variant="h5"
                      sx={{
                        mb: 2,
                        fontWeight: 600,
                        fontFamily: "Plantin, Georgia, serif",
                      }}
                    >
                      {section.subheadline}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        mb: 3,
                        lineHeight: 1.8,
                        fontSize: "1.1rem",
                        fontFamily: "Plantin, Georgia, serif",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      {section.content}
                    </Typography>
                    {index !== 3 && <Divider sx={{ my: 4 }} />}
                  </Box>
                ))}
            </Grid>

            <Grid item xs={12} md={6}>
              <Box sx={{ mb: 4 }}>
                <HoverableImage
                  imageUrl={lessonData?.timelines[0].articleImageUrl}
                  onImageUpdate={(newUrl) => {
                    setLessonData((prev) => ({
                      ...prev,
                      timelines: prev.timelines.map((timeline, idx) =>
                        idx === 0
                          ? { ...timeline, articleImageUrl: newUrl }
                          : timeline,
                      ),
                    }));
                  }}
                  userId={user.uid}
                  showSnackbar={showSnackbar}
                  theme={theme}
                  isLoading={isGeneratingImages && loadingImageIndex === 999}
                  loadingIndex={loadingImageIndex}
                  currentIndex={999}
                  onGenerateClick={() => handleImageButtonClick(999)}
                />
              </Box>
              {lessonData.timelines[0].articleSections
                .slice(4)
                .map((section, index) => (
                  <Box key={index} sx={{ mb: 4 }}>
                    <Typography
                      variant="h5"
                      sx={{
                        mb: 2,
                        fontWeight: 600,
                        fontFamily: "Plantin, Georgia, serif",
                      }}
                    >
                      {section.subheadline}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        mb: 3,
                        lineHeight: 1.8,
                        fontSize: "1.1rem",
                        fontFamily: "Plantin, Georgia, serif",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      {section.content}
                    </Typography>
                    {index !== 3 && <Divider sx={{ my: 4 }} />}
                  </Box>
                ))}
            </Grid>
          </Grid>
        )}
        <Box sx={{ display: "flex", justifyContent: "center", mt: 6 }}>
          <Button
            variant="purp"
            onClick={handleStartGame}
            startIcon={<PlayCircleIcon />}
            sx={{ px: 4, py: 1.5, fontSize: "1.1rem" }}
          >
            Bygg tidslinjen
          </Button>
        </Box>
      </Box>
    );
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <Box sx={{ mb: 6, position: "relative" }}>
        <Box
          sx={{
            position: "absolute",
            top: user.accountType === "teacher" ? 30 : 0,
            right: 40,
          }}
        >
          <CountdownCircleTimer
            key={timerKey}
            strokeWidth={5}
            duration={duration}
            isPlaying={isTimerRunning}
            colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
            colorsTime={[10, 6, 3, 0]}
            size={80}
            onComplete={checkAnswers}
          >
            {({ remainingTime }) => {
              const now = Date.now() / 1000;
              const decimal = now - Math.floor(now);
              return (
                <Typography variant="h6">
                  {remainingTime > 30
                    ? Math.floor(remainingTime)
                    : `${Math.floor(remainingTime)}.${decimal.toFixed(1).slice(2)}`}
                </Typography>
              );
            }}
          </CountdownCircleTimer>
        </Box>

        <TimelineTransitionFlip timelineIndex={currentTimelineIndex}>
          <>
            <Typography
              variant="subtitle1"
              sx={{
                textAlign: "center",
                mt: 5,
                mb: 2,
                textTransform: "uppercase",
                fontWeight: "500",
                fontSize: "0.9rem",
              }}
            >
              Tidslinje {currentTimelineIndex + 1}/{lessonData.timelines.length}
            </Typography>

            <Typography
              variant="h5"
              gutterBottom
              sx={{
                textAlign: "center",
                fontWeight: "600",
                color: "#dc143c",
                fontFamily: "Plantin, Georgia, calibri",
                textTransform: "uppercase",
                fontSize: "2.4rem",
                mb: 2,
              }}
            >
              {lessonData.timelines[currentTimelineIndex]?.title}
            </Typography>

            <Typography
              variant="body1"
              sx={{
                mb: 10,
                mt: 2,
                textAlign: "center",
                fontWeight: "500",
                fontSize: "1.2rem",
              }}
            >
              {lessonData.timelines[currentTimelineIndex]?.description}
            </Typography>

            <Box sx={{ mb: 4 }}>
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                  overflowX: "auto",
                  p: 2,
                  bgcolor:
                    theme.palette.mode === "dark"
                      ? "background.default"
                      : "white",
                  borderRadius: 1,
                  minHeight: 100,
                  border: "1px dashed",
                  borderColor: "divider",
                  "&::-webkit-scrollbar": {
                    height: 8,
                  },
                  "&::-webkit-scrollbar-track": {
                    backgroundColor: "background.paper",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "primary.light",
                    borderRadius: 4,
                  },
                }}
              >
                {timelineEvents.map((event, index) => (
                  <TimelineSlot
                    key={event.year}
                    index={index}
                    event={event}
                    onMove={moveItem}
                    isActive={true}
                  />
                ))}
              </Box>
            </Box>
          </>
        </TimelineTransitionFlip>
        {/* Outer fixed container */}
        {viewMode && (
          <Box
            sx={{
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
              backgroundColor: (theme) => theme.palette.background.default,
              borderTop: (theme) => `1px solid ${theme.palette.divider}`,
              zIndex: 1000,
            }}
          >
            {/* Inner width-constrained container */}
            <Box
              sx={{
                width: { xs: "100%", md: "66%" },
                margin: "0 auto",
                paddingTop: "50px",
                paddingBottom: "50px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {/* Left side buttons */}
              <Box sx={{ display: "flex", gap: 2 }}>
                <Button
                  variant="print"
                  onClick={handleStopGame}
                  startIcon={<StopCircleIcon />}
                  sx={{ minWidth: 120 }}
                >
                  Låt mig läsa texten igen
                </Button>
              </Box>

              {/* Right side button */}
              <Button
                variant={
                  currentTimelineIndex < lessonData.timelines.length - 1
                    ? "purp"
                    : "success"
                }
                onClick={checkAnswers}
                sx={{ minWidth: 120 }}
                disabled={timeUp}
              >
                {currentTimelineIndex < lessonData.timelines.length - 1
                  ? "Jag är nöjd — visa nästa tidslinje"
                  : "Se resultat"}
                <ArrowIcon sx={{ ml: 1 }} />
              </Button>
            </Box>
          </Box>
        )}

        {!viewMode && (
          <Box sx={{ display: "flex", gap: 2, justifyContent: "center" }}>
            <Button
              variant="purp"
              onClick={checkAnswers}
              sx={{ minWidth: 120 }}
              disabled={timeUp}
            >
              {currentTimelineIndex < lessonData.timelines.length - 1
                ? "Jag är nöjd — visa nästa tidslinje"
                : "Se resultat"}
            </Button>
            <Button
              variant="print"
              onClick={handleStopGame}
              startIcon={<StopCircleIcon />}
              sx={{ minWidth: 120 }}
            >
              Stoppa spelet
            </Button>
          </Box>
        )}
      </Box>
    </DndProvider>
  );
};

export default TimelineBuilderGame;
