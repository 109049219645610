// QuizInProgress.js
import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  LinearProgress,
  Button,
  IconButton,
  Avatar,
  Divider,
  Tooltip,
  TextField,
} from "@mui/material";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import Looks3Icon from "@mui/icons-material/Looks3";
import Looks4Icon from "@mui/icons-material/Looks4";
import { animated, useSpring, useTransition } from "@react-spring/web";

import { callApi } from "./api"; // Make sure this import exists

import SpaceBarIcon from "@mui/icons-material/SpaceBar";
import BookmarkIcon from "@mui/icons-material/BookmarkBorder";
import CheckCircleIcon from "@mui/icons-material/Check";
import GenerateIcon from "@mui/icons-material/AutoAwesome";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import LooksThreeIcon from "@mui/icons-material/Looks3";
import WestIcon from "@mui/icons-material/West";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import LooksFourIcon from "@mui/icons-material/Looks4";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUp";
import { formatDate } from "./functions";
import { East as ArrowIcon } from "@mui/icons-material";
import LightbulbIcon from "@mui/icons-material/LightbulbOutlined";
import { Howl } from "howler";
import selectSoundFile from "./sounds/select.mp3";
import select2 from "./sounds/select2.mp3";
import done from "./sounds/done.mp3";
import { saveQuizResult } from "./quizFunctions";
import { addNote } from "./NotesView";

import TimerIcon from "@mui/icons-material/Timer";
import { useSnackbar } from "./SnackbarHandler";

import { useNavigate } from "react-router-dom";
import Hint from "./Hint";
import RecommendationComponent from "./RecommendationComponent";
import { alpha } from "@mui/material/styles";
import ClearIcon from "@mui/icons-material/Clear";
import AIDialog from "./AIDialog";

import LocalCafeIcon from "@mui/icons-material/LocalCafe";
import { Menu, MenuItem, ListItemIcon, ListItemText } from "@mui/material";

import { lighten, darken } from "@mui/material/styles";

import { OptionButton } from "../StyledComponents";
import { AnimatedHint, AnimatedQuestion } from "./Animations";

import Logger from "./Logger";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { CircularProgress } from "@mui/material";

const selectSound = new Howl({ src: [selectSoundFile], volume: 0.1 });
const nextSound = new Howl({ src: [select2], volume: 0.6 });
const doneSound = new Howl({ src: [done], volume: 0.2 });

import NatureIcon from "@mui/icons-material/Nature"; // New icon for third option

const QuizInProgress = ({
  user,
  quiz,
  setQuiz,
  quizName,
  currentQuestionIndex,
  setCurrentQuestionIndex,
  selectedOption,
  setSelectedOption,
  results,
  setResults,
  setShowSummary,
  setQuizInProgress,
  bookmarks,
  setBookmarks,
  currentQuizId,
  creatorInfo,
  quizCreatedAt,
  originalQuizId,
  theme,
  styles,
  allowedHints = 10,
  includeTimer,
  setIncludeTimer,
  duration,
}) => {
  const [aiDialogOpen, setAiDialogOpen] = useState(false);
  const { showSnackbar } = useSnackbar();
  const currentQuestion = quiz[currentQuestionIndex];
  const [revealedHints, setRevealedHints] = useState({});
  const [animatingHintIndex, setAnimatingHintIndex] = useState(-1);
  const navigate = useNavigate();
  const [totalHintsUsed, setTotalHintsUsed] = useState(0);

  // Replace the existing audio states with:
  const [soundActivated, setSoundActivated] = useState(null);
  const [currentAudioType, setCurrentAudioType] = useState("timer");
  const [audio] = useState(
    new Audio("https://sportkuriren.se/wp-content/uploads/klocka-langsam.mp3"),
  );
  const [audioTwo] = useState(
    new Audio("https://sportkuriren.se/wp-content/uploads/kafe.mp3"),
  );
  const [audioThree] = useState(
    new Audio("https://sportkuriren.se/wp-content/uploads/klotter.mp3"),
  );

  const [anchorEl, setAnchorEl] = useState(null);

  const [showQuestion, setShowQuestion] = useState(true);

  const [isValidating, setIsValidating] = useState(false);
  const [answer, setAnswer] = useState("");
  const [questionStartTime, setQuestionStartTime] = useState(Date.now());
  const [isTimerRunning, setIsTimerRunning] = useState(includeTimer === true);
  const [timerKey, setTimerKey] = useState(0);
  const [timeUp, setTimeUp] = useState(false);

  const getTotalHintsUsed = () => {
    return Object.values(revealedHints).reduce(
      (total, hints) => total + hints.length,
      0,
    );
  };
  useEffect(() => {
    Logger.log("Aktivt quiz i QuizInProgress:", JSON.stringify(quiz, null, 2));
  }, [quiz]);
  useEffect(() => {
    if (includeTimer) {
      setQuestionStartTime(Date.now());
      setTimerKey((prev) => prev + 1);
    }
  }, [currentQuestionIndex, includeTimer]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (currentQuestion.type === "exam") return;
      if (aiDialogOpen) return;

      switch (event.key) {
        case "1":
        case "2":
        case "3":
        case "4":
          const optionIndex = parseInt(event.key) - 1;
          if (
            optionIndex >= 0 &&
            optionIndex < currentQuestion.options.length
          ) {
            handleOptionSelect(currentQuestion.options[optionIndex]);
          }
          break;
        case " ":
        case "Enter":
          event.preventDefault();
          if (selectedOption === null) {
            showSnackbar("Välj ett svarsalternativ.", "info");
          } else {
            handleNextQuestion();
          }
          break;
        case "F":
        case "f":
          handleAskAI();
          break;
        default:
          break;
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [currentQuestionIndex, quiz, aiDialogOpen, selectedOption]);

  // Add these new functions:
  const handleSoundChange = (type, event) => {
    stopAudio();
    setCurrentAudioType(type);
    const currentAudio =
      type === "timer" ? audio : type === "cafe" ? audioTwo : audioThree;
    currentAudio.loop = true;
    currentAudio.play();
    setSoundActivated(true);
    setAnchorEl(null);
  };

  // Update the existing stopAudio function:
  const stopAudio = () => {
    audio.pause();
    audio.currentTime = 0;
    audioTwo.pause();
    audioTwo.currentTime = 0;
    audioThree.pause();
    audioThree.currentTime = 0;
    setSoundActivated(false);
    setAnchorEl(null);
  };

  // Update the existing handleActivateSound function:
  const handleActivateSound = (event) => {
    if (soundActivated) {
      stopAudio();
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleRevealHint = (hintIndex) => {
    const totalUsed = getTotalHintsUsed();
    if (totalUsed >= allowedHints) {
      showSnackbar("Du har använt alla dina ledtrådar.", "warning");
      return;
    }

    // Add check for if there are no more hints available
    if (currentQuestion.hints && hintIndex >= currentQuestion.hints.length) {
      showSnackbar("Det finns inga fler ledtrådar för denna fråga.", "info");
      return;
    }

    setAnimatingHintIndex(hintIndex);
    setTimeout(() => setAnimatingHintIndex(-1), 500);

    const currentRevealed = revealedHints[currentQuestionIndex] || [];
    setRevealedHints({
      ...revealedHints,
      [currentQuestionIndex]: [...currentRevealed, hintIndex],
    });

    const updatedResults = [...results];
    updatedResults[currentQuestionIndex] = {
      ...updatedResults[currentQuestionIndex],
      revealedHints: [...currentRevealed, hintIndex],
    };
    setResults(updatedResults);
  };

  const handleOptionSelect = (option) => {
    nextSound.play();
    setSelectedOption(option);

    const isCorrect = option === currentQuestion.correct;
    const updatedResults = [...results];
    updatedResults[currentQuestionIndex] = {
      ...currentQuestion,
      selectedOption: option,
      isCorrect,
      revealedHints: revealedHints[currentQuestionIndex] || [],
    };
    setResults(updatedResults);
  };

  // const handleNextQuestion = async () => {
  //   if (currentQuestion.type === "exam") {
  //     if (!answer.trim()) {
  //       showSnackbar("Du måste skriva ett svar först.", "warning");
  //       return;
  //     }
  //     setIsValidating(true);
  //     try {
  //       const prompt = `
  //       Du är en lärare som bedömer elevsvar. Analysera följande:

  //       Fråga: ${currentQuestion.question}
  //       Korrekt svar: ${currentQuestion.answer}
  //       Elevsvar: "${answer}"

  //       Bedöm om elevsvaret är korrekt baserat på innehåll och koncept, inte exakt matchning.
  //       Svara i följande JSON-format:
  //       {
  //         "isCorrect": true/false,
  //         "explanation": "Kort förklaring av bedömningen"
  //       }
  //     `;
  //       const response = await callApi(
  //         [
  //           {
  //             role: "system",
  //             content:
  //               "Du är en expert på att bedöma elevsvar. Svara endast med JSON enligt det specificerade formatet.",
  //           },
  //           {
  //             role: "user",
  //             content: prompt,
  //           },
  //         ],
  //         6000,
  //         true,
  //         user.uid,
  //       );

  //       let result = response.data.choices[0].message.content.trim();
  //       if (result.startsWith("```json")) {
  //         result = result.slice(7, -3).trim();
  //       } else if (result.startsWith("```")) {
  //         result = result.slice(3, -3).trim();
  //       }

  //       const validation = JSON.parse(result);
  //       proceedToNextQuestion(validation.isCorrect, validation.explanation);
  //     } catch (error) {
  //       console.error("Error validating answer:", error);
  //       showSnackbar("Ett fel uppstod vid validering av svaret.", "error");
  //     } finally {
  //       setIsValidating(false);
  //     }
  //   } else {
  //     // Original multiple choice logic
  //     if (!selectedOption) {
  //       showSnackbar("Du måste välja ett alternativ först.", "warning");
  //       return;
  //     }
  //     selectSound.play();

  //     if (!quiz || quiz.length === 0 || currentQuestionIndex >= quiz.length) {
  //       return;
  //     }

  //     setShowQuestion(false); // Start exit animation
  //     setSelectedOption(null);

  //     setTimeout(async () => {
  //       if (currentQuestionIndex < quiz.length - 1) {
  //         // Update results for current question
  //         const updatedResults = [...results];
  //         updatedResults[currentQuestionIndex] = {
  //           ...quiz[currentQuestionIndex],
  //           selectedOption,
  //           isCorrect: selectedOption === quiz[currentQuestionIndex].correct,
  //           revealedHints: revealedHints[currentQuestionIndex] || [],
  //         };
  //         setResults(updatedResults);

  //         // Move to next question
  //         setCurrentQuestionIndex((prev) => prev + 1);
  //         setShowQuestion(true); // Start entrance animation
  //       } else {
  //         // Handle quiz completion
  //         doneSound.play();

  //         const finalResults = [...results];
  //         finalResults[currentQuestionIndex] = {
  //           ...quiz[currentQuestionIndex],
  //           selectedOption,
  //           isCorrect: selectedOption === quiz[currentQuestionIndex].correct,
  //           revealedHints: revealedHints[currentQuestionIndex] || [],
  //         };

  //         setResults(finalResults);
  //         setShowSummary(true);
  //         setCurrentQuestionIndex(0);
  //         setQuizInProgress(false);

  //         if (currentQuizId) {
  //           await saveQuizResult(currentQuizId, finalResults, user);
  //         }
  //       }
  //     }, 300); // Time to match animation duration
  //   }
  // };

  const handleNextQuestion = async () => {
    if (currentQuestion.type === "exam") {
      if (!answer.trim()) {
        showSnackbar("Du måste skriva ett svar först.", "warning");
        return;
      }
      setIsValidating(true);
      setIsTimerRunning(false); // Pause timer during validation
      const timeSpent = Math.floor((Date.now() - questionStartTime) / 1000);

      try {
        const prompt = `
        Du är en lärare som bedömer elevsvar. Analysera följande:
        
        Fråga: ${currentQuestion.question}
        Korrekt svar: ${currentQuestion.answer}
        Elevsvar: "${answer}"
        
        Bedöm om elevsvaret är korrekt baserat på innehåll och koncept, inte exakt matchning.
        Svara i följande JSON-format:
        {
          "isCorrect": true/false,
          "explanation": "Kort förklaring av bedömningen"
        }
      `;
        const response = await callApi(
          [
            {
              role: "system",
              content:
                "Du är en expert på att bedöma elevsvar. Svara endast med JSON enligt det specificerade formatet.",
            },
            {
              role: "user",
              content: prompt,
            },
          ],
          6000,
          true,
          user.uid,
        );

        let result = response.data.choices[0].message.content.trim();
        if (result.startsWith("```json")) {
          result = result.slice(7, -3).trim();
        } else if (result.startsWith("```")) {
          result = result.slice(3, -3).trim();
        }

        const validation = JSON.parse(result);
        proceedToNextQuestion(
          validation.isCorrect,
          validation.explanation,
          timeSpent,
        );
      } catch (error) {
        console.error("Error validating answer:", error);
        showSnackbar("Ett fel uppstod vid validering av svaret.", "error");
      } finally {
        setIsValidating(false);
        if (!timeUp) {
          setIsTimerRunning(true);
        }
      }
    } else {
      // Original multiple choice logic
      if (!selectedOption) {
        showSnackbar("Du måste välja ett alternativ först.", "warning");
        return;
      }
      selectSound.play();
      const timeSpent = Math.floor((Date.now() - questionStartTime) / 1000);

      if (!quiz || quiz.length === 0 || currentQuestionIndex >= quiz.length) {
        return;
      }

      setShowQuestion(false); // Start exit animation
      setSelectedOption(null);
      setIsTimerRunning(false);

      setTimeout(async () => {
        if (currentQuestionIndex < quiz.length - 1) {
          // Update results for current question
          const updatedResults = [...results];
          updatedResults[currentQuestionIndex] = {
            ...quiz[currentQuestionIndex],
            selectedOption,
            isCorrect: selectedOption === quiz[currentQuestionIndex].correct,
            revealedHints: revealedHints[currentQuestionIndex] || [],
            timeSpent,
          };
          setResults(updatedResults);

          // Move to next question
          setTimeUp(false);
          setCurrentQuestionIndex((prev) => prev + 1);

          setShowQuestion(true); // Start entrance animation
          setIsTimerRunning(true);
        } else {
          // Handle quiz completion

          stopAudio();

          doneSound.play();

          const finalResults = [...results];
          finalResults[currentQuestionIndex] = {
            ...quiz[currentQuestionIndex],
            selectedOption,
            isCorrect: selectedOption === quiz[currentQuestionIndex].correct,
            revealedHints: revealedHints[currentQuestionIndex] || [],
            timeSpent,
          };

          setResults(finalResults);
          setShowSummary(true);
          setCurrentQuestionIndex(0);
          setQuizInProgress(false);

          if (currentQuizId) {
            await saveQuizResult(currentQuizId, finalResults, user);
          }
        }
      }, 300); // Time to match animation duration
    }
  };

  const proceedToNextQuestion = (isCorrect, explanation, timeSpent) => {
    setShowQuestion(false);
    setSelectedOption(null);

    setTimeout(async () => {
      if (currentQuestionIndex < quiz.length - 1) {
        // Update results for current question
        const updatedResults = [...results];
        updatedResults[currentQuestionIndex] = {
          ...quiz[currentQuestionIndex],
          userAnswer: answer,
          isCorrect,
          explanation,
          revealedHints: revealedHints[currentQuestionIndex] || [],
          timeSpent,
        };
        setResults(updatedResults);

        // Move to next question
        setTimeUp(false);
        setCurrentQuestionIndex((prev) => prev + 1);
        setShowQuestion(true);
        setAnswer("");
        setIsTimerRunning(true);
      } else {
        // Handle quiz completion
        stopAudio();
        doneSound.play();

        const finalResults = [...results];
        finalResults[currentQuestionIndex] = {
          ...quiz[currentQuestionIndex],
          userAnswer: answer,
          isCorrect,
          explanation,
          revealedHints: revealedHints[currentQuestionIndex] || [],
          timeSpent,
        };

        setResults(finalResults);
        setShowSummary(true);
        setCurrentQuestionIndex(0);
        setQuizInProgress(false);

        if (currentQuizId) {
          await saveQuizResult(currentQuizId, finalResults, user);
        }
      }
    }, 300);
  };

  // Helper function to handle the transition to next question for exam type
  // const proceedToNextQuestion = (isCorrect, explanation) => {
  //   setShowQuestion(false);
  //   setSelectedOption(null);

  //   setTimeout(async () => {
  //     if (currentQuestionIndex < quiz.length - 1) {
  //       // Update results for current question
  //       const updatedResults = [...results];
  //       updatedResults[currentQuestionIndex] = {
  //         ...quiz[currentQuestionIndex],
  //         userAnswer: answer,
  //         isCorrect,
  //         explanation,
  //         revealedHints: revealedHints[currentQuestionIndex] || [],
  //       };
  //       setResults(updatedResults);

  //       // Move to next question
  //       setCurrentQuestionIndex((prev) => prev + 1);
  //       setShowQuestion(true);
  //       setAnswer("");
  //     } else {
  //       // Handle quiz completion
  //       doneSound.play();

  //       const finalResults = [...results];
  //       finalResults[currentQuestionIndex] = {
  //         ...quiz[currentQuestionIndex],
  //         userAnswer: answer,
  //         isCorrect,
  //         explanation,
  //         revealedHints: revealedHints[currentQuestionIndex] || [],
  //       };

  //       setResults(finalResults);
  //       setShowSummary(true);
  //       setCurrentQuestionIndex(0);
  //       setQuizInProgress(false);

  //       if (currentQuizId) {
  //         await saveQuizResult(currentQuizId, finalResults, user);
  //       }
  //     }
  //   }, 300);
  // };
  const handleToggleBookmark = async () => {
    const content = `<strong>Fråga:</strong><br /> ${currentQuestion.question}\n <br /><br /><strong>Alternativ:</strong><br /> ${currentQuestion.options.join(", ")}\n <br /><br />Rätt svar: ${currentQuestion.correct}`;
    const conversationType = `quiz-${currentQuizId}`;

    if (bookmarks[currentQuestionIndex]) {
      setBookmarks((prev) => {
        const newBookmarks = { ...prev };
        delete newBookmarks[currentQuestionIndex];
        return newBookmarks;
      });
    } else {
      const noteId = await addNote(user.uid, content, null, conversationType);
      setBookmarks((prev) => ({ ...prev, [currentQuestionIndex]: noteId }));
    }
  };

  const handleAskAI = () => {
    setAiDialogOpen(true);
  };
  const handleBack = () => {
    setQuizInProgress(false);
    setIncludeTimer(false);
    navigate("/app/quiz");
  };
  return (
    <>
      <Box
        sx={{
          width: { xs: "100%", md: "80%" },
          position: "relative",
          padding: { xs: "20px", md: "40px" },
          margin: "0 auto",
          mt: 3,
        }}
      >
        {includeTimer === true && (
          <Box sx={{ position: "absolute", top: 12, right: -130 }}>
            <CountdownCircleTimer
              key={`${timerKey}-${currentQuestionIndex}`}
              strokeWidth={7}
              size={80}
              duration={duration}
              isPlaying={isTimerRunning}
              colors={[
                theme.palette.primary.main,
                theme.palette.warning.main,
                theme.palette.error.main,
                theme.palette.error.dark,
              ]}
              colorsTime={[10, 6, 3, 0]}
              onComplete={() => {
                setTimeUp(true);
                // When timer runs out, save the full duration as time spent
                const updatedResults = [...results];
                updatedResults[currentQuestionIndex] = {
                  ...quiz[currentQuestionIndex],
                  selectedOption: selectedOption || null,
                  isCorrect:
                    selectedOption === quiz[currentQuestionIndex].correct,
                  revealedHints: revealedHints[currentQuestionIndex] || [],
                  timeSpent: duration,
                };
                setResults(updatedResults);
                handleNextQuestion();
              }}
            >
              {({ remainingTime }) => {
                const now = Date.now() / 1000;
                const decimal = now - Math.floor(now);
                return (
                  <Typography variant="h6" sx={{ color: "text.secondary" }}>
                    {remainingTime > 30
                      ? Math.floor(remainingTime)
                      : `${Math.floor(remainingTime)}.${decimal.toFixed(1).slice(2)}`}
                  </Typography>
                );
              }}
            </CountdownCircleTimer>
          </Box>
        )}

        <Tooltip
          title={`Du har ${allowedHints - getTotalHintsUsed()} ledtrådar kvar`}
        >
          <Box
            sx={{
              position: "absolute",
              top: 20,
              right: 102,
              display: "flex",
              gap: 1,
            }}
          >
            {Array.from({ length: allowedHints || 3 }).map((_, index) => {
              const totalUsed = getTotalHintsUsed();

              // 3. Elastic Pop
              const elasticPop = {
                transform:
                  animatingHintIndex === index
                    ? "scale(1.5) translateY(-10px)"
                    : "scale(1) translateY(0)",
                opacity: totalUsed > index ? 0 : 1,
                transition: "all 1.5s cubic-bezier(0.175, 0.885, 0.32, 1.275)",
              };

              return (
                <Box
                  key={index}
                  sx={{
                    ...elasticPop,
                    color:
                      totalUsed > index
                        ? theme.palette.text.disabled
                        : lighten(theme.palette.text.main, 0.5),
                  }}
                >
                  <LightbulbIcon sx={{ fontSize: 18 }} />
                </Box>
              );
            })}
          </Box>
        </Tooltip>
        <Box
          sx={{
            position: "relative",
            mb: 3,
            mt: 1,
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          <IconButton onClick={handleBack}>
            <ClearIcon sx={{ fontSize: "30px" }} />
          </IconButton>

          {includeTimer && (
            <>
              <IconButton sx={{ ml: -2 }} onClick={handleActivateSound}>
                {soundActivated ? <VolumeUpIcon /> : <VolumeOffIcon />}
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                PaperProps={{
                  sx: {
                    ...styles.roundMenu,
                    borderRadius: "8px",
                  },
                }}
              >
                <MenuItem
                  onClick={(e) => handleSoundChange("timer", e)}
                  selected={currentAudioType === "timer"}
                  sx={styles.menuItemStyle}
                >
                  <ListItemIcon>
                    <TimerIcon fontSize="small" sx={{ color: "#7B68EE" }} />
                  </ListItemIcon>
                  <ListItemText primary="Väggklocka" />
                </MenuItem>
                <MenuItem
                  onClick={(e) => handleSoundChange("cafe", e)}
                  selected={currentAudioType === "cafe"}
                  sx={styles.menuItemStyle}
                >
                  <ListItemIcon>
                    <LocalCafeIcon fontSize="small" sx={{ color: "#FFA726" }} />
                  </ListItemIcon>
                  <ListItemText primary="Café" />
                </MenuItem>

                <MenuItem
                  onClick={(e) => handleSoundChange("nature", e)}
                  selected={currentAudioType === "nature"}
                  sx={styles.menuItemStyle}
                >
                  <ListItemIcon>
                    <NatureIcon fontSize="small" sx={{ color: "#F44336" }} />
                  </ListItemIcon>
                  <ListItemText primary="Klotter" />
                </MenuItem>
              </Menu>
            </>
          )}

          <LinearProgress
            variant="determinate"
            value={(currentQuestionIndex / quiz.length) * 100}
            sx={{
              height: 10,
              borderRadius: 5,
              flex: 1,
              "& .MuiLinearProgress-bar": {
                borderRadius: 20,
                backgroundColor: (theme) => theme.palette.text.main,
                transition: "transform 0.5s ease-in-out",
              },
            }}
          />
          <Box
            sx={{
              borderRadius: "16px",
              padding: "4px 12px",

              fontSize: "1.1rem",
              flexShrink: 0,
              fontWeight: "600",
              color: (theme) => theme.palette.text.secondary,
            }}
          >
            {currentQuestionIndex + 1}/{quiz.length}
          </Box>
        </Box>
        <Box
          sx={{
            width: { xs: "100%", md: "90%" },
            margin: "0 auto",
            mt: 8,
            marginBottom: "80px",
          }}
        >
          <Typography style={styles.h2} sx={{ display: "none" }}>
            {quizName}
          </Typography>

          <AnimatedQuestion show={showQuestion}>
            <Typography
              style={{ ...styles.h3, fontSize: "1.6rem", fontWeight: "500" }}
            >
              {currentQuestion.question}
            </Typography>

            {currentQuestion.type === "exam" ? (
              <Box sx={{ width: "100%", mt: 10, mb: 8 }}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  value={answer}
                  onChange={(e) => setAnswer(e.target.value)}
                  placeholder="Skriv ditt svar här..."
                  variant="outlined"
                  autoFocus
                  sx={{
                    "& .MuiInputBase-root": {
                      fontWeight: "500",
                      ...styles.editingTextField["& .MuiInputBase-root"],
                    },
                  }}
                />
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  mt: 4,
                  mb: 8,
                  flexDirection: { xs: "column", sm: "row" },
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                }}
              >
                {currentQuestion.options?.map((option, index) => (
                  <OptionButton
                    key={index}
                    variant={
                      selectedOption === option ? "contained" : "outlined"
                    }
                    onClick={() => handleOptionSelect(option)}
                  >
                    {index === 0 && (
                      <LooksOneIcon
                        sx={{ marginRight: "8px", color: "#8b5cf6" }}
                      />
                    )}
                    {index === 1 && (
                      <LooksTwoIcon
                        sx={{ marginRight: "8px", color: "#8b5cf6" }}
                      />
                    )}
                    {index === 2 && (
                      <LooksThreeIcon
                        sx={{ marginRight: "8px", color: "#8b5cf6" }}
                      />
                    )}
                    {index === 3 && (
                      <LooksFourIcon
                        sx={{ marginRight: "8px", color: "#8b5cf6" }}
                      />
                    )}
                    {option}
                  </OptionButton>
                ))}
              </Box>
            )}
          </AnimatedQuestion>

          {currentQuestion.hints && currentQuestion.hints.length < 1 && (
            <Box sx={{ position: "absolute", bottom: 15, left: 15, zIndex: 1 }}>
              <Hint
                question={currentQuestion.question}
                answer={currentQuestion.options.join(", ")}
                user={user}
                theme={theme}
              />
            </Box>
          )}

          {/* Outer fixed container */}
          <Box
            sx={{
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
              backgroundColor: (theme) => theme.palette.background.default,
              borderTop: (theme) => `1px solid ${theme.palette.divider}`,
              zIndex: 1000,
            }}
          >
            {/* Inner width-constrained container */}
            <Box
              sx={{
                width: { xs: "100%", md: "66%" },
                margin: "0 auto",
                paddingTop: "50px",
                paddingBottom: "50px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {/* Left side buttons */}
              <Box sx={{ display: "flex", gap: 2 }}>
                {currentQuestion.type !== "exam" && (
                  <Button
                    variant="print"
                    onClick={handleAskAI}
                    startIcon={<GenerateIcon />}
                  >
                    Fråga AI
                  </Button>
                )}
                {currentQuestion.hints && currentQuestion.hints.length > 0 && (
                  <Button
                    variant="print"
                    onClick={() =>
                      handleRevealHint(
                        revealedHints[currentQuestionIndex]?.length || 0,
                      )
                    }
                    startIcon={<LightbulbIcon />}
                  >
                    {!revealedHints[currentQuestionIndex] ||
                    revealedHints[currentQuestionIndex]?.length === 0
                      ? "Visa ledtråd"
                      : "Visa en till"}
                  </Button>
                )}
              </Box>
              {/* Right side button */}
              <Button
                variant="purp"
                onClick={handleNextQuestion}
                disabled={isValidating}
                endIcon={
                  isValidating ? (
                    <CircularProgress size={16} sx={{ color: "white" }} />
                  ) : currentQuestion.type === "exam" ? (
                    <ArrowIcon />
                  ) : (
                    <SpaceBarIcon />
                  )
                }
              >
                {currentQuestion.type === "exam"
                  ? "Lämna in svar — nästa fråga"
                  : "Nästa"}
              </Button>
            </Box>
          </Box>
          {currentQuestion.hints && currentQuestion.hints.length > 0 && (
            <Box sx={{ mt: 4, mb: 2 }}>
              {currentQuestion.hints.map((hint, index) => {
                const revealedCount =
                  revealedHints[currentQuestionIndex]?.length || 0;
                const isRevealed =
                  revealedHints[currentQuestionIndex]?.includes(index);
                const isNextToReveal = index === revealedCount;

                if (!isRevealed && !isNextToReveal) return null;

                return (
                  <Box key={index}>
                    {isRevealed ? (
                      <AnimatedHint isRevealed={isRevealed}>
                        <Box
                          sx={{
                            p: 2,
                            mb: 2,
                            bgcolor: alpha(
                              theme.palette.primary.main,
                              theme.palette.mode === "dark"
                                ? 0.15 + index * 0.1
                                : 0.08 + index * 0.05,
                            ),
                            borderRadius: "8px",
                            border: `1px solid ${theme.palette.primary.main}40`,
                            display: "flex",
                            alignItems: "flex-start",
                            gap: 1.5,
                          }}
                        >
                          <CheckCircleIcon
                            sx={{
                              color: theme.palette.primary.main,
                              mt: "2px",
                            }}
                          />
                          <Typography
                            sx={{
                              color:
                                theme.palette.mode === "dark"
                                  ? theme.palette.text.white
                                  : theme.palette.text.primary,
                              flex: 1,
                            }}
                          >
                            <Box
                              component="span"
                              sx={{ fontWeight: 700, mr: 1 }}
                            >
                              Ledtråd {index + 1}:
                            </Box>
                            {hint}
                          </Typography>
                        </Box>
                      </AnimatedHint>
                    ) : (
                      <Box sx={{ textAlign: "center", mb: 2 }}></Box>
                    )}
                  </Box>
                );
              })}
            </Box>
          )}

          {currentQuestion.type !== "exam" && (
            <Typography
              variant="subtitle1"
              sx={{
                textAlign: "center",
                paddingBottom: "15px",
                color: "text.secondary",
                fontSize: "0.8rem",
              }}
            >
              Använd tangentbordet: 1, 2, 3, 4 för svarsalterantiv och
              mellanslag för att gå vidare.
            </Typography>
          )}

          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              left: 50,
              zIndex: 1,
              display: "none",
            }}
          >
            <Tooltip
              title={bookmarks[currentQuestionIndex] ? "Sparat" : "Spara"}
            >
              <IconButton
                onClick={handleToggleBookmark}
                sx={{
                  color: bookmarks[currentQuestionIndex]
                    ? "green"
                    : theme.palette.text.primary,
                }}
              >
                {bookmarks[currentQuestionIndex] ? (
                  <CheckCircleIcon />
                ) : (
                  <BookmarkIcon />
                )}
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Box>
      {/* Creator info box */}
      {creatorInfo && (
        <Box
          sx={{
            ...styles.smallBox,
            display: "none",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor:
              theme.palette.mode === "dark" ? "#2a3b4c57" : "#fff",
            borderRadius: "10px",
            padding: "10px",
            marginTop: { xs: "30px", md: 0 },
            width: { xs: "100%", md: "fit-content" },
          }}
        >
          <Avatar
            src={creatorInfo.avatarUrl}
            sx={{ width: 40, height: 40, mr: 2 }}
          >
            {creatorInfo.displayName[0].toUpperCase()}
          </Avatar>
          <Box>
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              {creatorInfo.displayName}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", mt: 0 }}>
              <ThumbUpOutlinedIcon
                sx={{
                  fontSize: 16,
                  mr: 0.5,
                  color: theme.palette.text.secondary,
                }}
              />
              <Typography
                variant="body2"
                sx={{ color: theme.palette.text.secondary, fontWeight: "bold" }}
              >
                {creatorInfo.upvotes}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: theme.palette.text.secondary,
                  fontSize: "0.8rem",
                  ml: 1,
                }}
              >
                · &nbsp; {formatDate(quizCreatedAt)}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
      <Box sx={{ width: { xs: "100%", md: "auto" }, display: "none" }}>
        <RecommendationComponent
          itemId={originalQuizId}
          userId={user.uid}
          collectionName="quiz"
          questionText="Skulle du rekommendera detta quiz till andra användare?"
          largerStyle={true}
        />
      </Box>

      <AIDialog
        userId={user.uid}
        open={aiDialogOpen}
        onClose={() => setAiDialogOpen(false)}
        initialQuestion={
          currentQuestion && currentQuestion.question
            ? currentQuestion.type === "exam"
              ? `Question: ${currentQuestion.question}\nCorrect Answer: ${currentQuestion.answer}\nPlease explain this question and help me understand how to approach it. The name of the quiz is ${quizName}. Skriv på svenska.`
              : `Question: ${currentQuestion.question}\nOptions: ${currentQuestion.options.join(", ")}\nPlease explain this question and help me understand the answer options, the name of the quiz is ${quizName}. Skriv på svenska.`
            : "No question available"
        }
        systemMessage="You are a helpful AI assistant designed to explain and assist with the quiz question. Provide a short and simple explanation. Skriv på svenska"
        theme={theme}
      />
    </>
  );
};

export default QuizInProgress;
