import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Divider,
  CircularProgress,
  FormControl,
  IconButton,
  Tooltip,
  Avatar,
  TextField,
  Grid,
} from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";
import GenerateIcon from "@mui/icons-material/AutoAwesome";
import { useSearchParams, useParams } from "react-router-dom";

import ImageIcon from "@mui/icons-material/Image";
import PrintIcon from "@mui/icons-material/Print";
import { callApi } from "./api";
import LoadingBar from "react-top-loading-bar";

import LanguageSelector from "./LanguageSelector";
import InfoBox from "./InfoBox";
import ReadingComprehensionDialog from "./ReadingComprehensionDialog";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import { GiBookshelf } from "react-icons/gi";
import { handlePrintComparativeLesson } from "./printFunctions";
import { generateAndProcessImage } from "./functions";
import { useSnackbar } from "./SnackbarHandler";
import ProgressBar from "./ProgressBar";
import { ThemeSelector } from "./ThemeSelector";
import ImageGenerationModal from "./ImageGenerationModal";
import GradeSelector, { gradeInstructions } from "./GradeSelector";
import { useTheme } from "@mui/material/styles";
import { getStyles } from "../index";
import { trackLesson, handleImageGenerationError } from "./functions";

import SaveAsIcon from "@mui/icons-material/SaveAs";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import { FourSquare } from "react-loading-indicators";
import Logger from "./Logger";

import LessonDrawer from "./LessonDrawer";
import SaveLessonDialog from "./SaveLessonDialog";
import HoverableImage from "./HoverableImage";
import HistoryIcon from "@mui/icons-material/History";

import { ThemeSelectorFlow } from "./ThemeSelectorFlow";

import {
  Science as ScienceIcon,
  Castle as CastleIcon,
  Psychology as PsychologyIcon,
  Pets as PetsIcon,
  Public as PublicIcon,
  EmojiEvents as EmojiEventsIcon,
  Museum as MuseumIcon,
  Nature as NatureIcon,
} from "@mui/icons-material";
import MenuBookIcon from "@mui/icons-material/MenuBook";

const ComparativeReadingCompLesson = ({ user }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);

  const [language, setLanguage] = useState("svenska");
  const [titleText, setTitleText] = useState("");
  const [grade, setGrade] = useState("åk6");
  const [progress, setProgress] = useState(0);
  const [isGeneratingLesson, setIsGeneratingLesson] = useState(false);
  const [isGeneratingImages, setIsGeneratingImages] = useState(false);
  const [customTheme, setCustomTheme] = useState("");
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageInstructions, setImageInstructions] = useState("");
  const [openAiInstructions, setOpenAiInstructions] = useState("");
  const [lessonData, setLessonData] = useState(null);

  const [editingSections, setEditingSections] = useState({});
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [isEditingContent, setIsEditingContent] = useState({});
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [loadingImageIndex, setLoadingImageIndex] = useState(null);

  const editorRef = useRef(null);
  const { showSnackbar } = useSnackbar();
  const theme = useTheme();
  const styles = getStyles(theme);
  const bookContentRef = useRef("");
  const [lessonTheme, setLessonTheme] = useState("generateWithAi");
  const [predefinedTheme, setPredefinedTheme] = useState(null);
  const lessonTitleRef = useRef(null); // Add this ref
  const transcriptRef = useRef("");
  const uploadedFileRef = useRef("");
  const urlContentRef = useRef("");
  const wikipediaContentRef = useRef("");
  const randomizedContentRef = useRef("");
  const coursePlanContentRef = useRef("");

  const additionalThemes = [
    {
      type: "subheader",
      label: "Teman som lämpar sig extra bra för denna lektionstyp:",
    },
    {
      value: "selma",
      label: "Selma Lagerlöf",
      content: "Gör en lektion om selma lagerlöf",
      Icon: MenuBookIcon,
    },
    {
      value: "dronten",
      label: "Dronten",
      content: "Gör en lektion om den utdöda arten dronten",
      Icon: PetsIcon,
    },
    {
      value: "vikingar",
      label: "Vikingar",
      content: "Gör en lektion om vikingatiden",
      Icon: CastleIcon,
    },
    {
      value: "rymden",
      label: "Rymden",
      content: "Gör en lektion om vårt solsystem",
      Icon: PublicIcon,
    },
    {
      value: "dinosaurier",
      label: "Dinosaurier",
      content: "Gör en lektion om olika dinosaurier",
      Icon: NatureIcon,
    },
    {
      value: "nobel",
      label: "Nobelpriset",
      content: "Gör en lektion om nobelpriset och Alfred Nobel",
      Icon: EmojiEventsIcon,
    },
    {
      value: "uppfinnare",
      label: "Svenska uppfinnare",
      content: "Gör en lektion om kända svenska uppfinnare",
      Icon: ScienceIcon,
    },
    {
      value: "psychology",
      label: "Känslor",
      content: "Gör en lektion om olika känslor",
      Icon: PsychologyIcon,
    },
    {
      value: "konst",
      label: "Svensk konst",
      content: "Gör en lektion om svensk konsthistoria",
      Icon: MuseumIcon,
    },
  ];

  const [searchParams] = useSearchParams();
  const subject = searchParams.get("subject") || null;

  useEffect(() => {
    Logger.log("subject: ", subject);
  }, [subject]);

  useEffect(() => {
    Logger.log("lessonTheme: ", lessonTheme);
  }, [lessonTheme]);

  useEffect(() => {
    Logger.log("predefinedTheme: ", predefinedTheme);
  }, [predefinedTheme]);

  useEffect(() => {
    Logger.log("customTheme: ", customTheme);
  }, [customTheme]);

  useEffect(() => {
    Logger.log("youtubeTranscript: ", transcriptRef.current);
  }, [transcriptRef.current]);

  useEffect(() => {
    Logger.log("wikipediaContentRef: ", wikipediaContentRef.current);
  }, [wikipediaContentRef.current]);
  useEffect(() => {
    Logger.log("uploadedFileRef: ", uploadedFileRef.current);
  }, [uploadedFileRef.current]);

  useEffect(() => {
    Logger.log("randomizedContentRef: ", randomizedContentRef.current);
  }, [randomizedContentRef.current]);

  useEffect(() => {
    Logger.log("coursePlanContentRef: ", coursePlanContentRef.current);
  }, [coursePlanContentRef.current]);

  const handlePrintClick = async () => {
    // Check if lessonData exists and all images have been generated
    if (
      lessonData &&
      lessonData.images &&
      lessonData.images.every((image) => image.imageUrl)
    ) {
      Logger.log("Saving lesson since all images have been generated");
      await trackLesson(
        user.uid,
        "ComparativeReadingCompLesson.js",
        lessonData,
        null,
        true,
      );
    }

    handlePrintComparativeLesson(lessonData, theme);
  };

  const handleSaveLessonClick = () => {
    setSaveDialogOpen(true);
  };

  const handleSaveWithComment = async (comment, name, coverImage, tags) => {
    Logger.log(JSON.stringify(lessonData, null, 2));
    await trackLesson(
      user.uid,
      "ComparativeReadingCompLesson.js",
      lessonData,
      null,
      true,
      comment,
      name,
      coverImage,
      tags,
    );
    setDrawerOpen(true);
    showSnackbar("Lektionen sparad i din lektionsbank!", "success");
  };

  const simulateProgress = (startProgress, endProgress, duration) => {
    const stepSize = 0.5;
    const steps = (endProgress - startProgress) / stepSize;
    const stepDuration = duration / steps;
    let currentProgress = startProgress;

    const intervalId = setInterval(() => {
      currentProgress += stepSize;
      if (currentProgress >= endProgress) {
        clearInterval(intervalId);
      } else {
        setProgress(currentProgress);
      }
    }, stepDuration);

    return () => clearInterval(intervalId);
  };

  const handleExerciseUpdate = (index, key, value) => {
    setLessonData((prev) => {
      const newExercises = [...prev.exercises];
      newExercises[index] = {
        ...newExercises[index],
        [key]: value,
      };
      return {
        ...prev,
        exercises: newExercises,
      };
    });
  };

  const handleSectionEdit = (section, index) => {
    setEditingSections((prev) => ({
      ...prev,
      [`${section}-${index}`]: true,
    }));
  };

  const handleSectionToggle = (section, index) => {
    setEditingSections((prev) => {
      const key = `${section}-${index}`;
      const isCurrentlyEditing = prev[key];

      // If we're currently editing and toggling, save the changes
      if (isCurrentlyEditing) {
        showSnackbar("Sektionen har uppdaterats", "success");
      }

      return {
        ...prev,
        [key]: !isCurrentlyEditing,
      };
    });
  };

  const handleGenerateLesson = async () => {
    setProgress(0);
    let stopProgressSimulation = null;
    setIsGeneratingLesson(true);
    const gradeInstructionsText = gradeInstructions[grade];

    try {
      let themeToUse;

      if (lessonTheme === "youtube") {
        themeToUse = transcriptRef.current;
      } else if (lessonTheme === "customTheme") {
        themeToUse = customTheme;
      } else if (lessonTheme === "predefined") {
        themeToUse = predefinedTheme;
      } else if (lessonTheme === "fileUpload") {
        themeToUse = uploadedFileRef.current;
      } else if (lessonTheme === "wikipedia") {
        themeToUse = wikipediaContentRef.current;
      } else if (lessonTheme === "randomizer") {
        themeToUse = randomizedContentRef.current;
      } else if (lessonTheme === "book") {
        themeToUse = bookContentRef.current;
      } else if (lessonTheme === "url") {
        themeToUse = urlContentRef.current;
      } else {
        //
        themeToUse = coursePlanContentRef.current;
      }

      Logger.log("themeToUse: ", themeToUse);
      stopProgressSimulation = simulateProgress(0, 90, 15000, setProgress);

      let response = await callApi(
        [
          {
            role: "system",
            content: `You are an expert teacher creating engaging reading comprehension lessons 
          in ${language}. Follow the exact JSON structure provided in the prompt.`,
          },
          {
            role: "user",
            content: `Skapa två texter om samma ämne från olika perspektiv. Texten ska vara skriven på ${language}.
      
      Svara ALLTID i följande JSON-format:
      {
        "title": "Lektionens titel",
        "theme": "Jämförelsetema",
 "content": [
    {
      "header": "Text 1",
      "text": "Text 1 här"
    },
    {
      "header": "Text 2", 
      "text": "Text 2 här"
    }
  ],
        "exercises": [
          {
            "type": "analysis",
            "title": "Analysera perspektiven",
            "content": ["Analysfråga 1", "Analysfråga 2", "Analysfråga 3"]
          },
          {
            "type": "reading-comp",
            "title": "Läsförståelse",
            "content":  ["Vad är den viktigaste skillnaden mellan text 1 och text 2?", "Vilken av texterna ger mest fakta för sina argument?", "Hur skiljer sig språket åt mellan texterna?", "Vilka olika slutsatser drar texterna och varför?"]

          },
          {
            "type": "argument-comp",
            "title": "Jämför argumenten",
            "content": ["Vilket argument i text 1 tycker du är starkast? Förklara varför.", "Finns det några svagheter i argumenten i text 2?", "Hittar du argument som går emot varandra i texterna? Ge exempel.", "Vilken text övertygar dig mest? Motivera ditt svar."]

          },
          {
            "type": "analysis",
            "title": "Fördjupning",
            "content": ["Finns det viktiga saker som båda texterna är överens om?", "Vad tror du är orsaken till att författarna har olika syn på saken?", "Saknas det någon viktig information i någon av texterna?", "Om du fick ställa en fråga till varje författare, vad skulle du fråga?"]

          }
        ]
      }
      Theme: "${themeToUse}".
      ${openAiInstructions}.
      ${gradeInstructionsText}`,
          },
        ],
        6000,
        true,
        user.uid,
      );

      if (stopProgressSimulation) {
        stopProgressSimulation();
      }

      setProgress(95);
      let messageContent = response.data.choices[0].message.content.trim();

      if (messageContent.startsWith("```json")) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith("```")) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      const data = JSON.parse(messageContent);

      setLessonData({
        ...data,
        images: [{ imageUrl: null }, { imageUrl: null }],
      });
      setProgress(100);

      setTitleText(data.title);
      await new Promise((resolve) => setTimeout(resolve, 800));

      await trackLesson(user.uid, "ComparativeReadingCompLesson.js", null, {
        language: language,
        lessonTheme: lessonTheme,
      });
    } catch (error) {
      console.error("Error generating lesson:", error);
      showSnackbar(
        "Ett fel uppstod när lektionen skulle genereras. Försök igen!",
        "error",
      );
    } finally {
      setIsGeneratingLesson(false);
    }
  };

  const handleImageButtonClick = (index = null) => {
    setShowImageModal(true);
    setSelectedImageIndex(index);
  };

  const handleCloseModal = () => {
    setShowImageModal(false);
    setImageInstructions("");
  };

  const handleGenerateWithInstructions = async () => {
    if (selectedImageIndex !== null) {
      handleCloseModal();
      await handleGenerateSpecificImage(selectedImageIndex);
    } else {
      handleCloseModal();
      await handleGenerateAllImages();
    }
  };

  const handleGenerateSpecificImage = async (index) => {
    setLoadingImageIndex(index);
    setIsGeneratingImages(true);
    handleCloseModal();

    try {
      // Use the corresponding text content based on the index
      const text = lessonData.content[index].text;
      const imagePrompt = `Create a scene that illustrates the following text: "${text}". ${imageInstructions}`;
      const imageUrl = await generateAndProcessImage(imagePrompt, user.uid);

      if (imageUrl) {
        setLessonData((prev) => {
          const newImages = [...prev.images];
          if (index !== null && index < newImages.length) {
            newImages[index] = { imageUrl };
          } else {
            const emptyIndex = newImages.findIndex((img) => !img.imageUrl);
            const updateIndex = emptyIndex >= 0 ? emptyIndex : 0;
            newImages[updateIndex] = { imageUrl };
          }
          return { ...prev, images: newImages };
        });
        showSnackbar("Bilden har genererats!", "success");
      }
    } catch (error) {
      handleImageGenerationError(error, false, showSnackbar);
    } finally {
      setLoadingImageIndex(null);
      setIsGeneratingImages(false);
    }
  };

  const handleGenerateAllImages = async () => {
    setIsGeneratingImages(true);
    setLoadingImageIndex(null);

    try {
      // Create prompts based on the actual text content
      const prompts = lessonData.content.map(
        (contentItem, index) =>
          `Create a scene that illustrates the following text from perspective ${index + 1}: "${contentItem.text}".`,
      );

      prompts.forEach((prompt, index) => {
        Logger.log(`Prompt ${index + 1}:`, prompt);
      });
      const imagePromises = prompts.map((prompt) =>
        generateAndProcessImage(prompt, user.uid),
      );
      const imageUrls = await Promise.all(imagePromises);

      setLessonData((prev) => ({
        ...prev,
        images: imageUrls.map((imageUrl) => ({ imageUrl })),
      }));

      showSnackbar("Alla bilder har genererats!", "success");
    } catch (error) {
      handleImageGenerationError(error, true, showSnackbar);
    } finally {
      setIsGeneratingImages(false);
      setLoadingImageIndex(null);
    }
  };

  return (
    <Box sx={styles.container}>
      <Box
        sx={{
          ...styles.innerContainer,
          width: { xs: "100%", md: "87%" },
          margin: "0 auto",
        }}
      >
        <Typography
          variant="h1"
          gutterBottom
          sx={{
            fontWeight: "bold",
            fontSize: "1.8em",
            mb: 3,
            mt: 5,
            textAlign: "center",
          }}
        >
          Jämförande läsning
        </Typography>

        <Box
          sx={{
            ...styles.buttonsContainer,
            ...styles.additionalButtonsContainer,
          }}
        >
          <Tooltip title="Dina sparade lektioner">
            <Button
              variant="contained"
              color="primary"
              disableRipple
              onClick={() => setDrawerOpen(true)}
              startIcon={<HistoryIcon />}
              sx={{
                padding: "8px",
                marginRight: "0px",
                paddingLeft: "14px",
                paddingRight: "3px",
                minWidth: "auto",
                textTransform: "none",
              }}
            />
          </Tooltip>
        </Box>

        <LessonDrawer
          userId={user.uid}
          lessonType="ComparativeReadingCompLesson.js"
          theme={theme}
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          lessonIcon={CompareArrowsIcon}
          onSelectLesson={(lesson) => {
            setLessonData(lesson.data);
            setDrawerOpen(false);

            showSnackbar(`Lektionen har laddats in.`, "success");
            setTimeout(() => {
              lessonTitleRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "start", // This ensures it scrolls to the top of the element
              });
            }, 100);
          }}
        />

        <InfoBox
          icon={CompareArrowsIcon}
          title="Jämförande läsning"
          instructions={[
            "Få två texter om samma ämne från olika perspektiv",
            "Analysera skillnader och likheter mellan texterna",
            "Utveckla kritiskt tänkande genom textjämförelse",
          ]}
          theme={theme}
          styles={styles}
        />

        <ThemeSelectorFlow
          subject={subject}
          value={lessonTheme}
          onChange={(e) => setLessonTheme(e.target.value)}
          customTheme={customTheme}
          onCustomThemeChange={(e) => setCustomTheme(e.target.value)}
          onPredefinedThemeChange={(e) => setPredefinedTheme(e.target.value)}
          onBookContentChange={(content) => {
            Logger.log("onBookContentChange", content.substring(0, 100));
            bookContentRef.current = content;
          }}
          onUrlContentChange={(content) => {
            Logger.log(
              "Parent: URL content received",
              content.substring(0, 300),
            );
            urlContentRef.current = content;
          }}
          onYoutubeTranscriptChange={(transcriptContent) => {
            Logger.log(
              "onYoutubeTranscriptChange körs med: ",
              transcriptContent,
            );
            transcriptRef.current = transcriptContent;
          }}
          onFileUpload={(content) => {
            Logger.log("onFileUpload körs med: ", content);
            uploadedFileRef.current = content;
          }}
          onWikipediaContentChange={(content) => {
            Logger.log(
              "Parent: Wikipedia content received",
              content.substring(0, 100),
            );
            wikipediaContentRef.current = content;
          }}
          onRandomizedContentChange={(content) => {
            Logger.log("randomizedContentRef", content.substring(0, 100));
            randomizedContentRef.current = content;
          }}
          onCoursePlanContentChange={(content) => {
            Logger.log("onCoursePlanChange", content.substring(0, 100));
            coursePlanContentRef.current = content;
          }}
          lessonType="ComparativeReadingCompLesson.js"
          user={user}
          theme={theme}
          styles={styles}
          onLessonSelect={(lesson) => {
            Logger.log("onLessonSelect körs");
            setLessonData(lesson.data);
            setTimeout(() => {
              lessonTitleRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "start", // This ensures it scrolls to the top of the element
              });
            }, 100);
            showSnackbar("Lektionen har laddats in.", "success");
          }}
          additionalThemes={additionalThemes}
        />
        <Box sx={styles.headlineLessonBox}>
          <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
            2. Generera lektionen
          </Typography>
        </Box>

        <Box sx={{ ...styles.freshBox, padding: "30px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: "500", mb: 1, mt: 0 }}
              >
                Årskurs:
              </Typography>
              <Box sx={{ mt: 0.5, mb: 3 }}>
                <GradeSelector grade={grade} setGrade={setGrade} />
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" sx={{ fontWeight: "500", mt: 0 }}>
                Språk:
              </Typography>
              <Box sx={{ mt: 1, mb: 3 }}>
                <LanguageSelector
                  language={language}
                  setLanguage={setLanguage}
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography variant="subtitle1" sx={{ fontWeight: "500" }}>
                Specifika instruktioner:
              </Typography>
              <FormControl fullWidth margin="normal" sx={{ mb: 3, mt: 1 }}>
                <TextField
                  value={openAiInstructions}
                  onChange={(e) => setOpenAiInstructions(e.target.value)}
                  placeholder="T.ex. Jämför fördelar och nackdelar med olika energikällor."
                  variant="outlined"
                />
              </FormControl>
            </Grid>
          </Grid>

          <Button
            variant={theme.palette.mode === "light" ? "purp" : "contained"}
            color="primary"
            onClick={handleGenerateLesson}
            disabled={isGeneratingLesson}
            sx={{ mt: 4 }}
            startIcon={
              !isGeneratingLesson ? (
                <GenerateIcon style={{ color: "#fff" }} />
              ) : (
                <CircularProgress size={16} />
              )
            }
          >
            {isGeneratingLesson ? "Genererar..." : "Generera lektion"}
          </Button>

          {isGeneratingLesson && (
            <Box sx={{ mt: 6 }}>
              <ProgressBar
                progress={progress}
                label="Genererar lektion — tar 30-60 sekunder..."
              />
            </Box>
          )}
        </Box>

        {lessonData && (
          <>
            <SaveLessonDialog
              open={saveDialogOpen}
              onClose={() => setSaveDialogOpen(false)}
              onSave={handleSaveWithComment}
              theme={theme}
              lessonObject={lessonData}
              lessonName={lessonData?.title || ""}
            />

            <Box
              ref={lessonTitleRef}
              sx={{ ...styles.headlineLessonBox, mt: 10 }}
            >
              <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
                3. Din lektion
              </Typography>
            </Box>
            <Box sx={{ ...styles.freshBox, padding: "30px" }}>
              <Box sx={styles.lessonControlPanel}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    px: 1,
                  }}
                >
                  <Box>
                    <Button
                      variant={
                        theme.palette.mode === "light" ? "purp" : "contained"
                      }
                      color="primary"
                      onClick={() => handleImageButtonClick(null)}
                      disabled={isGeneratingImages}
                      startIcon={
                        !isGeneratingImages ? (
                          <ImageIcon style={{ color: "#fff" }} />
                        ) : (
                          <CircularProgress size={16} />
                        )
                      }
                    >
                      {isGeneratingImages
                        ? "Genererar..."
                        : "Generera alla bilder"}
                    </Button>
                    <Box sx={{ display: "inline-block", ml: 2 }}>
                      <ReadingComprehensionDialog
                        key={lessonData?.title}
                        lessonData={lessonData}
                        user={user}
                        theme={theme}
                        styles={styles}
                        buttonText="Specifika övningar"
                        tooltip="Perfekt för att generera specfika övningar för enskilda elever"
                      />
                    </Box>
                  </Box>
                  <Box>
                    <Button
                      variant="print"
                      startIcon={<PrintIcon />}
                      sx={{ ml: 2 }}
                      onClick={handlePrintClick}
                    >
                      Skriv ut
                    </Button>

                    <Button
                      variant="print"
                      startIcon={<SaveAsIcon />}
                      sx={{ ml: 2 }}
                      onClick={handleSaveLessonClick}
                    >
                      Spara i lektionsbank
                    </Button>

                    <ImageGenerationModal
                      open={showImageModal}
                      onClose={handleCloseModal}
                      onGenerate={handleGenerateWithInstructions}
                      isGenerating={isGeneratingImages}
                      instructions={imageInstructions}
                      setInstructions={setImageInstructions}
                      theme={theme}
                    />
                  </Box>
                </Box>
              </Box>
              <Box sx={{ mt: 4 }}>
                {/* Title Section */}
                {isEditingTitle ? (
                  <TextField
                    value={lessonData.title}
                    onChange={(e) =>
                      setLessonData((prev) => ({
                        ...prev,
                        title: e.target.value,
                      }))
                    }
                    onBlur={() => {
                      setIsEditingTitle(false);
                      showSnackbar("Titeln har uppdaterats", "success");
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        setIsEditingTitle(false);
                        showSnackbar("Titeln har uppdaterats", "success");
                      }
                    }}
                    autoFocus
                    fullWidth
                    variant="standard"
                    sx={{
                      backgroundColor: "transparent",
                      "& .MuiInputBase-input": {
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "2.6em",
                        marginBottom: "1.2rem",
                        marginTop: "1.5rem",
                      },
                    }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                ) : (
                  <Box
                    sx={{
                      position: "relative",
                      display: "inline-block",
                      margin: "0 auto",
                      width: "100%",
                      mb: 3,
                      mt: 5,
                      ":hover .edit-icon": {
                        opacity: 1,
                      },
                    }}
                  >
                    <Typography
                      variant="h1"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "2.6em",
                        textAlign: "center",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 2,
                      }}
                    >
                      {lessonData.title || " "}
                      <Box
                        component="span"
                        sx={{
                          opacity: 0,
                          transition: "opacity 0.2s",
                          display: "inline-flex",
                          gap: 0.5,
                        }}
                        className="edit-icon"
                      >
                        <Tooltip title="Redigera" placement="top">
                          <IconButton
                            size="small"
                            onClick={() => setIsEditingTitle(true)}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Typography>
                  </Box>
                )}

                {/* Content Sections */}
                {/* Content Sections */}
                {lessonData.content.map((content, index) => (
                  <Box key={index}>
                    <Box
                      sx={{
                        position: "relative",
                        marginTop: 7,
                        "&:hover": {
                          "& .section-controls": {
                            opacity: 1,
                            visibility: "visible",
                          },
                        },
                      }}
                    >
                      <Box
                        className="section-controls"
                        sx={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          opacity: 0,
                          visibility: "hidden",
                          transition: "all 0.2s ease",
                          display: "flex",
                          gap: 1,
                          zIndex: 1,
                        }}
                      >
                        <Tooltip
                          title={
                            editingSections[`content-${index}`]
                              ? "Spara ändringar"
                              : "Redigera sektion"
                          }
                          placement="top"
                        >
                          <IconButton
                            size="small"
                            onClick={() =>
                              handleSectionToggle("content", index)
                            }
                          >
                            {editingSections[`content-${index}`] ? (
                              <SaveAsIcon fontSize="small" />
                            ) : (
                              <EditIcon fontSize="small" />
                            )}
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Ta bort sektion" placement="top">
                          <IconButton
                            size="small"
                            onClick={() => {
                              const updatedContent = lessonData.content.filter(
                                (_, i) => i !== index,
                              );
                              setLessonData((prev) => ({
                                ...prev,
                                content: updatedContent,
                              }));
                              showSnackbar(
                                "Sektionen har tagits bort",
                                "success",
                              );
                            }}
                          >
                            <DeleteOutlineIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Box>

                      {editingSections[`content-${index}`] ? (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 2,
                          }}
                        >
                          <TextField
                            value={content.header}
                            onChange={(e) => {
                              const newContent = [...lessonData.content];
                              newContent[index] = {
                                ...newContent[index],
                                header: e.target.value,
                              };
                              setLessonData((prev) => ({
                                ...prev,
                                content: newContent,
                              }));
                            }}
                            sx={{ width: "80%" }}
                            InputProps={{
                              sx: {
                                fontSize: "1.5em",
                                fontWeight: "bold",
                              },
                            }}
                          />
                          <Box
                            sx={{
                              backgroundColor: (theme) =>
                                theme.palette.mode === "dark"
                                  ? "#15202b"
                                  : "#e0e0e029",
                              borderRadius: "10px",
                              padding: "20px",
                            }}
                          >
                            <TextField
                              fullWidth
                              multiline
                              rows={10}
                              value={content.text}
                              onChange={(e) => {
                                const newContent = [...lessonData.content];
                                newContent[index] = {
                                  ...newContent[index],
                                  text: e.target.value,
                                };
                                setLessonData((prev) => ({
                                  ...prev,
                                  content: newContent,
                                }));
                              }}
                              sx={{
                                mb: 2,
                                "& .MuiInputBase-root": {
                                  fontSize: "1rem",
                                  lineHeight: "1.8",
                                  ...styles.editingTextField[
                                    "& .MuiInputBase-root"
                                  ],
                                },
                              }}
                            />
                            <Button
                              variant={
                                theme.palette.mode === "light"
                                  ? "purp"
                                  : "contained"
                              }
                              color="primary"
                              startIcon={<SaveAsIcon />}
                              onClick={() =>
                                handleSectionToggle("content", index)
                              }
                              sx={{ mt: 2, textTransform: "none" }}
                            >
                              Spara ändringar
                            </Button>
                          </Box>
                        </Box>
                      ) : (
                        <>
                          <Typography
                            variant="h6"
                            sx={{
                              fontWeight: "bold",
                              mb: 2,
                              fontSize: "1.5em",
                            }}
                          >
                            {content.header}
                          </Typography>
                          <Box
                            sx={{
                              backgroundColor: (theme) =>
                                theme.palette.mode === "dark"
                                  ? "#15202b"
                                  : "#e0e0e029",
                              borderRadius: "10px",
                              padding: "20px",
                            }}
                          >
                            <Typography
                              variant="body1"
                              sx={{ lineHeight: 1.8, whiteSpace: "pre-wrap" }}
                            >
                              {content.text}
                            </Typography>
                          </Box>
                        </>
                      )}
                    </Box>
                  </Box>
                ))}

                {/* Images Grid */}
                {/* Images Grid */}
                <Grid
                  container
                  spacing={2}
                  sx={{ mb: 4, justifyContent: "center", mt: 4 }}
                >
                  {lessonData.images?.map((image, index) => (
                    <Grid item xs={12} md={6} key={index}>
                      <Box sx={{ position: "relative" }}>
                        <Avatar
                          variant="rounded"
                          src={
                            image.imageUrl ||
                            "https://placehold.co/500x400?text=Lägg%20till%20bild&font=Raleway"
                          }
                          alt={`Story image ${index + 1}`}
                          sx={{
                            width: "100%",
                            height: "400px",
                            borderRadius: "8px",
                          }}
                        />

                        {/* Loading overlay */}
                        {isGeneratingImages &&
                          (loadingImageIndex === null ||
                            loadingImageIndex === index) && (
                            <Box
                              sx={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: "rgba(215, 215, 215, 0.9)",
                                borderRadius: "8px",
                              }}
                            >
                              <FourSquare
                                color="#8b5cf6"
                                size="medium"
                                text=""
                                textColor=""
                              />
                            </Box>
                          )}

                        {/* Hover overlay */}
                        <Box
                          sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: "rgba(0, 0, 0, 0.7)",
                            borderRadius: "8px",
                            opacity: 0,
                            transition: "opacity 0.2s",
                            cursor: "pointer",
                            "&:hover": {
                              opacity: 1,
                            },
                            pointerEvents:
                              isGeneratingImages && loadingImageIndex === index
                                ? "none"
                                : "auto",
                            visibility: isGeneratingImages
                              ? "hidden"
                              : "visible",
                          }}
                          onClick={() => handleImageButtonClick(index)}
                        >
                          <ImageIcon
                            sx={{ color: "white", fontSize: "2rem", mb: 1 }}
                          />
                          <Typography
                            sx={{ color: "white", fontSize: "0.9rem" }}
                          >
                            {image.imageUrl
                              ? "Generera ny bild"
                              : "Generera bild"}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  ))}
                </Grid>

                {/* Exercises Sections */}
                {lessonData.exercises.map((exercise, index) => (
                  <Box key={index}>
                    <Box
                      sx={{
                        position: "relative",
                        marginTop: 7,
                        "&:hover": {
                          "& .section-controls": {
                            opacity: 1,
                            visibility: "visible",
                          },
                        },
                      }}
                    >
                      <Box
                        className="section-controls"
                        sx={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          opacity: 0,
                          visibility: "hidden",
                          transition: "all 0.2s ease",
                          display: "flex",
                          gap: 1,
                          zIndex: 1,
                        }}
                      >
                        <Tooltip
                          title={
                            editingSections[`${exercise.type}-${index}`]
                              ? "Spara ändringar"
                              : "Redigera sektion"
                          }
                          placement="top"
                        >
                          <IconButton
                            size="small"
                            onClick={() =>
                              handleSectionToggle(exercise.type, index)
                            }
                          >
                            {editingSections[`${exercise.type}-${index}`] ? (
                              <SaveAsIcon fontSize="small" />
                            ) : (
                              <EditIcon fontSize="small" />
                            )}
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Ta bort sektion" placement="top">
                          <IconButton
                            size="small"
                            onClick={() => {
                              const updatedExercises =
                                lessonData.exercises.filter(
                                  (_, i) => i !== index,
                                );
                              setLessonData((prev) => ({
                                ...prev,
                                exercises: updatedExercises,
                              }));
                              showSnackbar(
                                "Sektionen har tagits bort",
                                "success",
                              );
                            }}
                          >
                            <DeleteOutlineIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Box>

                      {editingSections[`${exercise.type}-${index}`] ? (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 2,
                          }}
                        >
                          <TextField
                            value={exercise.title}
                            onChange={(e) =>
                              handleExerciseUpdate(
                                index,
                                "title",
                                e.target.value,
                              )
                            }
                            sx={{ width: "80%" }}
                            InputProps={{
                              sx: {
                                fontSize: "1.5em",
                                fontWeight: "bold",
                              },
                            }}
                          />
                          <Box
                            sx={{
                              backgroundColor: (theme) =>
                                theme.palette.mode === "dark"
                                  ? "#15202b"
                                  : "#e0e0e029",
                              borderRadius: "10px",
                              padding: "20px",
                            }}
                          >
                            {exercise.content.map((item, i) => (
                              <TextField
                                key={i}
                                fullWidth
                                multiline
                                value={item}
                                onChange={(e) => {
                                  const newItems = [...exercise.content];
                                  newItems[i] = e.target.value;
                                  handleExerciseUpdate(
                                    index,
                                    "content",
                                    newItems,
                                  );
                                }}
                                sx={{
                                  mb: 2,
                                  "& .MuiInputBase-root": {
                                    fontSize: "1rem",
                                    lineHeight: "1.5",
                                  },
                                  ...styles.editingTextField,
                                }}
                              />
                            ))}
                            <Button
                              variant={
                                theme.palette.mode === "light"
                                  ? "purp"
                                  : "contained"
                              }
                              color="primary"
                              startIcon={<SaveAsIcon />}
                              onClick={() =>
                                handleSectionToggle(exercise.type, index)
                              }
                              sx={{ mt: 2, textTransform: "none" }}
                            >
                              Spara ändringar
                            </Button>
                          </Box>
                        </Box>
                      ) : (
                        <>
                          <Typography
                            variant="h6"
                            sx={{
                              fontWeight: "bold",
                              mb: 2,
                              fontSize: "1.5em",
                            }}
                          >
                            {exercise.title || ""}
                          </Typography>
                          <Box
                            sx={{
                              backgroundColor: (theme) =>
                                theme.palette.mode === "dark"
                                  ? "#15202b"
                                  : "#e0e0e029",
                              borderRadius: "10px",
                              padding: "20px",
                              "& li": {
                                marginBottom: "12px",
                                lineHeight: "25px",
                              },
                            }}
                          >
                            <ul>
                              {exercise.content.map((item, i) => (
                                <li key={i}>
                                  <Typography variant="body1">
                                    {item}
                                  </Typography>
                                </li>
                              ))}
                            </ul>
                          </Box>
                        </>
                      )}
                    </Box>
                  </Box>
                ))}
              </Box>{" "}
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default ComparativeReadingCompLesson;
