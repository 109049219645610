import React, { useState, useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  FormControl,
  Select,
  MenuItem,
  IconButton,
  Box,
  Divider,
  TextField,
  Tooltip,
  Grid,
  CircularProgress,
} from "@mui/material";
import ViewTimelineIcon from "@mui/icons-material/ViewTimeline";
import GenerateIcon from "@mui/icons-material/AutoAwesome";
import LanguageSelector from "./LanguageSelector";
import GradeSelector from "./GradeSelector";
import { callApi } from "./api";
import ClearIcon from "@mui/icons-material/Clear";
import ProgressBar from "./ProgressBar";
import CloseIcon from "@mui/icons-material/Close";
const AddonTimeline = ({
  buttonRef,
  lessonData,
  theme,
  user,
  styles,
  showSnackbar,
  addTimelineToLessonObject,
  buttonText = "Lägg till tidslinje",
}) => {
  const [open, setOpen] = useState(false);
  const [grade, setGrade] = useState("åk6");
  const [language, setLanguage] = useState("svenska");
  const [aiInstructions, setAiInstructions] = useState("");
  const [customTheme, setCustomTheme] = useState("");
  const [isGenerating, setIsGenerating] = useState(false);
  const [progress, setProgress] = useState(0);
  const [amountOfEvents, setAmountOfEvents] = useState(7);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setCustomTheme("");
    setProgress(0);
  };

  const simulateProgress = (startProgress, endProgress, duration) => {
    const stepSize = 0.5;
    const steps = (endProgress - startProgress) / stepSize;
    const stepDuration = duration / steps;
    let currentProgress = startProgress;

    const intervalId = setInterval(() => {
      currentProgress += stepSize;
      if (currentProgress >= endProgress) {
        clearInterval(intervalId);
      } else {
        setProgress(currentProgress);
      }
    }, stepDuration);

    return () => clearInterval(intervalId);
  };

  const handleGenerateTimeline = async () => {
    setProgress(0);
    let stopProgressSimulation = null;
    setIsGenerating(true);

    try {
      stopProgressSimulation = simulateProgress(0, 90, 15000);

      const response = await callApi(
        [
          {
            role: "system",
            content: `You are an expert teacher creating engaging timeline lessons in ${language}. Create a timeline with Include EXACTLY ${amountOfEvents} events that students will arrange chronologically. Each event must include a year, description, and helpful hint.`,
          },
          {
            role: "user",
            content: `
            Create a timeline lesson that fits with this existing lesson: ${JSON.stringify(lessonData)}
            Requirements:
            -  ${aiInstructions}
            - Include EXACTLY ${amountOfEvents} significant events
            - Each event must have an exact year
            - Write clear, concise descriptions
            - Include helpful hints that don't directly give away the answer
            - Events should span a reasonable time period
            - Content should be appropriate for grade level: ${grade}

            - Content should be in ${language}

            RESPOND IN THIS EXACT JSON FORMAT:
            {
              "title": "New Timeline Title",
              "description": "Timeline description",
              "timeline": [
                {
                  "event": "Clear description of the event",
                  "year": 1938
                }
              ],
      
            }


              Notes:

              - If the event isn't a year, for example in the case of hydrological cycle, use numbers to indicate the order like 1, 2, 3 etc.
              - If the event took place a long time ago, for example millions of years ago, like the big bang, the year should be the amount years ago like  13800000.

            `,
          },
        ],
        6000,
        true,
        user.uid,
      );

      if (stopProgressSimulation) stopProgressSimulation();
      setProgress(95);

      let messageContent = response.data.choices[0].message.content.trim();
      if (messageContent.startsWith("```json")) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith("```")) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      const newTimeline = JSON.parse(messageContent);
      const modifiedTimeline = {
        ...newTimeline,
        timeline: newTimeline.timeline.map((event) => ({
          ...event,
          imageUrl: null,
        })),
      };

      // Create updated lesson data
      const updatedLessonData = {
        ...lessonData,
        timelines: lessonData.timelines
          ? [...lessonData.timelines, modifiedTimeline]
          : [modifiedTimeline],
      };

      addTimelineToLessonObject(updatedLessonData);
      setProgress(100);
      await new Promise((resolve) => setTimeout(resolve, 800));
      handleClose();
      showSnackbar("Tidslinjen har lagts till i lektionen!", "success");
    } catch (error) {
      console.error("Error generating timeline:", error);
      showSnackbar("Ett fel uppstod när tidslinjen skulle genereras", "error");
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <>
      <Button
        ref={buttonRef}
        variant="purp"
        onClick={handleOpen}
        startIcon={<ViewTimelineIcon />}
      >
        {buttonText}
      </Button>

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle sx={{ padding: "20px" }}>
          <Box sx={{ position: "relative" }}>
            <Typography
              variant="h6"
              sx={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "1.6rem",
                padding: "5px",
              }}
            >
              Generera en interaktiv tidslinje
            </Typography>
            <IconButton
              onClick={handleClose}
              sx={{ position: "absolute", top: 0, right: 0 }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ padding: 5, paddingBottom: 0 }}>
          <Box sx={{ mt: 2 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "500", mb: 1 }}
                >
                  Årskurs:
                </Typography>
                <GradeSelector grade={grade} setGrade={setGrade} />
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "500", mb: 1 }}
                >
                  Språk:
                </Typography>
                <LanguageSelector
                  language={language}
                  setLanguage={setLanguage}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "500", mt: 2 }}
                >
                  Antal händelser i tidslinjen
                </Typography>
                <Box sx={{ mt: 1, mb: 3 }}>
                  <FormControl fullWidth>
                    <Select
                      value={amountOfEvents}
                      onChange={(e) => setAmountOfEvents(e.target.value)}
                      variant="outlined"
                    >
                      <MenuItem value={7}>7 händelser</MenuItem>

                      <Tooltip title="Endast tillgängligt för premium" arrow>
                        <span>
                          <MenuItem value={10} disabled>
                            10 händelser
                          </MenuItem>
                          <MenuItem value={15} disabled>
                            15 händelser
                          </MenuItem>
                          <MenuItem value={20} disabled>
                            20 händelser
                          </MenuItem>
                          <MenuItem value={25} disabled>
                            25 händelser
                          </MenuItem>
                          <MenuItem value={30} disabled>
                            30 händelser
                          </MenuItem>
                          <MenuItem value={40} disabled>
                            40 händelser
                          </MenuItem>
                          <MenuItem value={50} disabled>
                            50 händelser
                          </MenuItem>
                        </span>
                      </Tooltip>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography
                  variant="formLabelSinUnderline"
                  sx={{ display: "block", mt: 2, fontWeight: "500" }}
                >
                  Kompletterande instruktioner (valfritt):
                </Typography>

                <TextField
                  fullWidth
                  sx={{ mt: 1 }}
                  margin="normal"
                  placeholder="T.ex. eleverna ska placera händelserna från texten i rätt ordning"
                  value={aiInstructions}
                  onChange={(e) => setAiInstructions(e.target.value)}
                  inputProps={{ maxLength: 100 }}
                />
              </Grid>
            </Grid>

            {isGenerating && (
              <Box sx={{ mt: 4 }}>
                <ProgressBar
                  progress={progress}
                  label="Genererar tidslinje..."
                />
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "12px",
              width: "100%",
            }}
          >
            <Button
              variant="purp"
              onClick={handleGenerateTimeline}
              disabled={isGenerating}
              sx={{ mb: 2 }}
              startIcon={
                !isGenerating ? (
                  <GenerateIcon />
                ) : (
                  <CircularProgress
                    sx={{ color: "white" }}
                    size={16}
                    color="inherit"
                  />
                )
              }
            >
              {isGenerating ? "Genererar..." : "Generera tidslinje"}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddonTimeline;
