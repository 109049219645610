import React, { useState, useRef, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  FormControl,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  Tooltip,
  TextField,
  CircularProgress,
  Grid,
  RadioGroup,
  Checkbox,
  Divider,
} from "@mui/material";

import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import PrintIcon from "@mui/icons-material/Print";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import ProgressBar from "./ProgressBar";
import { handlePrintReadingComp } from "./printFunctions";
import ConfirmationDialog from "./ConfirmationDialog"; // Adjust path as needed
import { trackLesson } from "./functions";
import LanguageSelector from "./LanguageSelector";
import CustomInputSwitch from "./CustomInputSwitch";
import ThemeFlowBox from "./ThemeFlowBox";
import LanguageIcon from "@mui/icons-material/Language";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import PsychologyIcon from "@mui/icons-material/Psychology";
import HelpIcon from "@mui/icons-material/Help";
import Quill from "./Quill";

const RegenerateDialog = ({
  open,
  onClose,
  originalContent,
  callApi,
  showSnackbar,
  theme,
  lessonData,
  onUpdateContent,
  user,
  defaultLanguage,
  selectedWordCount,
}) => {
  const [newContent, setNewContent] = useState("");
  const [isGenerating, setIsGenerating] = useState(false);
  const [progress, setProgress] = useState(0);
  const [direction, setDirection] = useState("simplify");
  const [wordCount, setWordCount] = useState(selectedWordCount);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const lessonTitleRef = useRef(null); // Add this ref
  const [additionalInstructions, setAdditionalInstructions] = useState("");

  const [language, setLanguage] = useState(defaultLanguage);
  const [adaptations, setAdaptations] = useState({
    language: true,
    sentences: false,
    difficulty: false,
    explanations: false,
  });

  useEffect(() => {
    setWordCount(selectedWordCount);
  }, [selectedWordCount]);

  const handlePrint = () => {
    if (!newContent || !lessonData) return;

    const updatedLessonData = {
      ...lessonData,
      content: newContent,
    };

    handlePrintReadingComp(updatedLessonData, theme);
  };

  const handleApplyChanges = () => {
    if (!newContent) return;
    setShowConfirmation(true);
  };

  // Add this function to handle the actual save
  const handleConfirmedSave = () => {
    onUpdateContent(newContent);
    showSnackbar("Ändringarna har sparats", "success");
    setShowConfirmation(false);
    onClose();
  };

  const getPrompt = () => {
    const isSimplifying = direction === "simplify";

    const adaptationsText = Object.entries(adaptations)
      .filter(([_, enabled]) => enabled)
      .map(([key, _]) => {
        switch (key) {
          case "language":
            return isSimplifying
              ? "Använd enklare ord och uttryck."
              : "Använd mer avancerat språk och akademiska uttryck.";
          case "sentences":
            return isSimplifying
              ? "Använd kortare meningar med tydlig struktur."
              : "Använd längre, mer komplexa meningar med bisatser.";
          case "difficulty":
            return isSimplifying
              ? "Minska komplexiteten i resonemangen."
              : "Öka komplexiteten och lägg till fler nyanser i resonemangen.";
          case "explanations":
            return isSimplifying
              ? "Lägg till förklaringar av svåra ord och koncept."
              : "Ta bort grundläggande förklaringar för en mer koncis text.";
          default:
            return "";
        }
      })
      .join(" ");

    return `${isSimplifying ? "Förenkla" : "Gör mer avancerad"} denna text enligt följande instruktioner: ${adaptationsText}. 
    Skriv texten på ${language}. 
    Längd på text ska vara EXAKT ${wordCount} ord.
    Behåll HTML-formatering och textens grundläggande struktur och budskap.`;
  };

  const handleRegenerate = async () => {
    if (!Object.values(adaptations).some((v) => v)) {
      showSnackbar("Välj minst en typ av anpassning", "warning");
      return;
    }

    setIsGenerating(true);

    try {
      const response = await callApi(
        [
          {
            role: "system",
            content: `Du är en expert på att ${direction === "simplify" ? "förenkla" : "göra mer avancerade"} texter för elever med olika behov.`,
          },
          {
            role: "user",
            content: `${getPrompt()}
Ytterligare instruktioner: ${additionalInstructions}
Original text:
${originalContent}`,
          },
        ],
        10000,
        true,
      );

      let messageContent = response.data.choices[0].message.content.trim();
      if (messageContent.startsWith("```json")) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith("```")) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      await trackLesson(user.uid, "RegenerateDialog.js", null, {
        language: language,
      });

      setNewContent(messageContent);

      setTimeout(() => {
        lessonTitleRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, 100);

      showSnackbar("Ny version har genererats!", "success");
    } catch (error) {
      console.error("Error generating content:", error);
      showSnackbar("Ett fel uppstod när texten skulle genereras", "error");
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: { minHeight: "90vh", minWidth: 1200 },
        }}
      >
        <DialogTitle
          sx={{
            textAlign: "center",
            fontWeight: "700",
            padding: "25px",
            fontSize: "1.5rem",
          }}
        >
          <Box
            sx={{ display: "flex", width: "100%", justifyContent: "flex-end" }}
          >
            <IconButton
              onClick={onClose}
              size="small"
              sx={{ position: "absolute" }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          Anpassa svårighetsgrad för enskilda elever
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mb: 4,
                mt: 2,
              }}
            >
              <CustomInputSwitch
                selectedIndex={direction === "simplify" ? 0 : 1}
                onIndexChange={(index) => {
                  setDirection(index === 0 ? "simplify" : "advanced");
                  setAdaptations({
                    language: false,
                    sentences: false,
                    difficulty: false,
                    explanations: false,
                  });
                }}
                options={["Förenkla", "Försvåra"]}
                theme={theme}
              />
            </Box>

            <Grid container spacing={2} sx={{ mt: 6 }}>
              <Grid item xs={12} md={3}>
                <ThemeFlowBox
                  title={
                    direction === "simplify"
                      ? "Förenkla språket"
                      : "Mer avancerat språk"
                  }
                  subtitle="Anpassa ordval och uttryck"
                  icon={LanguageIcon}
                  isSelected={adaptations.language}
                  diffView={true}
                  onClick={() =>
                    setAdaptations((prev) => ({
                      ...prev,
                      language: !prev.language,
                    }))
                  }
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <ThemeFlowBox
                  title={
                    direction === "simplify"
                      ? "Kortare meningar"
                      : "Längre, mer komplexa meningar"
                  }
                  subtitle="Anpassa meningsstruktur"
                  icon={TextFieldsIcon}
                  isSelected={adaptations.sentences}
                  diffView={true}
                  onClick={() =>
                    setAdaptations((prev) => ({
                      ...prev,
                      sentences: !prev.sentences,
                    }))
                  }
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <ThemeFlowBox
                  title={
                    direction === "simplify"
                      ? "Minska komplexiteten"
                      : "Öka komplexiteten"
                  }
                  subtitle="Anpassa resonemang"
                  icon={PsychologyIcon}
                  isSelected={adaptations.difficulty}
                  diffView={true}
                  onClick={() =>
                    setAdaptations((prev) => ({
                      ...prev,
                      difficulty: !prev.difficulty,
                    }))
                  }
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <ThemeFlowBox
                  title={
                    direction === "simplify"
                      ? "Lägg till förklaringar"
                      : "Ta bort grundläggande förklaringar"
                  }
                  subtitle="Anpassa förklaringsnivå"
                  icon={HelpIcon}
                  isSelected={adaptations.explanations}
                  diffView={true}
                  onClick={() =>
                    setAdaptations((prev) => ({
                      ...prev,
                      explanations: !prev.explanations,
                    }))
                  }
                />
              </Grid>

              <Grid item xs={12} md={4} sx={{ mt: 3 }}>
                <FormControl fullWidth>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "500", display: "block", mb: 0.5, mt: 2 }}
                  >
                    Textlängd:
                  </Typography>
                  <Select
                    labelId="num-questions-label"
                    id="num-questions-select"
                    value={wordCount}
                    onChange={(e) => setWordCount(e.target.value)}
                  >
                    <MenuItem value={300} selected>
                      300 ord
                    </MenuItem>
                    <MenuItem value={600}>600 ord</MenuItem>
                    <MenuItem value={1000}>1000 ord</MenuItem>

                    <Tooltip title="Endast tillgängligt för premium" arrow>
                      <span>
                        <MenuItem value={2000} disabled>
                          2000 ord
                        </MenuItem>
                        <MenuItem value={3000} disabled>
                          3000 ord
                        </MenuItem>
                        <MenuItem value={4000} disabled>
                          4000 ord
                        </MenuItem>
                        <MenuItem value={5000} disabled>
                          5000 ord
                        </MenuItem>
                        <MenuItem value={10000} disabled>
                          10000 ord
                        </MenuItem>
                        <MenuItem value={20000} disabled>
                          200000 ord
                        </MenuItem>
                      </span>
                    </Tooltip>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4} sx={{ mt: 3 }}>
                <Typography
                  variant="formLabelSinUnderline"
                  sx={{
                    fontWeight: "500",

                    display: "block",
                    mb: 1,
                    mt: 2,
                  }}
                >
                  Språk:
                </Typography>

                <LanguageSelector
                  language={language}
                  setLanguage={setLanguage}
                />
              </Grid>

              <Grid item xs={12} md={4} sx={{ mt: 3 }}>
                <Typography
                  variant="formLabelSinUnderline"
                  sx={{
                    fontWeight: "500",

                    display: "block",
                    mb: 1,
                    mt: 2,
                  }}
                >
                  Ytterligare instruktioner (valfritt):
                </Typography>

                <TextField
                  multiline
                  rows={1}
                  fullWidth
                  placeholder="T.ex. jag vill endast ha verbformer i presens"
                  value={additionalInstructions}
                  onChange={(e) => setAdditionalInstructions(e.target.value)}
                />
              </Grid>
            </Grid>

            {newContent && !isGenerating && (
              <Box ref={lessonTitleRef} sx={{ mt: 4 }}>
                <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 500 }}>
                  Ny version:
                </Typography>
                <Quill
                  value={newContent}
                  onChange={(newContent) => {
                    setNewContent(newContent);
                  }}
                  theme={theme}
                />
              </Box>
            )}
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <div>
            <Button onClick={onClose} variant="print" startIcon={<CloseIcon />}>
              Avbryt
            </Button>
          </div>
          <div>
            <Button
              onClick={handlePrint}
              disabled={!newContent || isGenerating}
              startIcon={<PrintIcon />}
              variant="purp"
              sx={{ mr: 2 }}
            >
              Skriv ut ny version
            </Button>
            <Button
              onClick={handleApplyChanges}
              disabled={!newContent || isGenerating}
              variant="success"
              startIcon={<SaveIcon />}
            >
              Ersätt urspr. version
            </Button>

            <Button
              variant={theme.palette.mode === "light" ? "purp" : "contained"}
              onClick={handleRegenerate}
              disabled={isGenerating}
              startIcon={
                isGenerating ? (
                  <CircularProgress size={16} sx={{ color: "white" }} />
                ) : (
                  <AutoFixHighIcon />
                )
              }
              sx={{ ml: 2 }}
              disabled={
                isGenerating || !Object.values(adaptations).some((v) => v)
              }
            >
              {isGenerating ? "Genererar ny version..." : "Generera ny version"}
            </Button>
          </div>
        </DialogActions>
      </Dialog>

      <ConfirmationDialog
        open={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        onConfirm={handleConfirmedSave}
        title="Bekräfta ändringar"
        message="Detta kommer att ersätta din ursprungliga text i lektionen du genererade tidigare. Är du säker på att du vill spara ändringarna? "
        confirmText="Spara"
        cancelText="Avbryt"
      />
    </>
  );
};

export default RegenerateDialog;
