import React, { useState, useEffect, forwardRef } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Menu,
  Divider,
  MenuItem,
  Typography,
  CircularProgress,
  Box,
  IconButton,
  Tooltip,
  Stepper,
  Step,
  StepLabel,
  TextField,
  Paper,
  Backdrop,
} from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import GenerateIcon from "@mui/icons-material/AutoAwesome";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import AddIcon from "@mui/icons-material/Add";
import { Popper } from "@mui/material";
import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong";
import { handlePrintReadingComp } from "./printFunctions";
import AssignmentIcon from "@mui/icons-material/Assignment";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import GradeSelector, { gradeInstructions } from "./GradeSelector";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import { useSnackbar } from "./SnackbarHandler";
import { School, Person, ArrowBack } from "@mui/icons-material";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import InsightsIcon from "@mui/icons-material/Psychology";
import { East as ArrowIcon } from "@mui/icons-material";
import PsychologyIcon from "@mui/icons-material/Psychology";
import Logger from "./Logger";
import ClearIcon from "@mui/icons-material/Clear";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import QuizIcon from "@mui/icons-material/Quiz";
import { alpha } from "@mui/material/styles";
import LanguageSelector from "./LanguageSelector";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import ThemeFlowBox from "./ThemeFlowBox";
import { callApi } from "./api";

const ReadingComprehensionDialog = ({
  lessonData,
  user,
  theme,
  styles,
  buttonText = "Generera läsförståelseövningar",
  tooltip,
  addExercisesToLessonObject,
  buttonRef,
  // New props
  open: externalOpen,
  initialStep = 0,
  initialEditMode = false,
  initialExercise = null,
  onClose,
  onUpdateExercise,
}) => {
  const [internalOpen, setInternalOpen] = useState(false);
  const open = externalOpen !== undefined ? externalOpen : internalOpen;

  // Update useEffect to handle initial state
  useEffect(() => {
    if (initialExercise && initialEditMode) {
      setActiveStep(2);
      setIsEditing(true);
      setGeneratedData({
        title: "Redigera övning",
        exercises: [initialExercise],
      });
      setEditedData({
        title: "Redigera övning",
        exercises: [initialExercise],
      });
    }
  }, [initialExercise, initialEditMode]);

  const [isGenerating, setIsGenerating] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [language, setLanguage] = useState("svenska");
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [additionalInstructions, setAdditionalInstructions] = useState("");
  const { showSnackbar } = useSnackbar();
  const [activeStep, setActiveStep] = useState(0);
  const [grade, setGrade] = useState("åk9");
  const [generatedData, setGeneratedData] = useState(null);
  const [editedData, setEditedData] = useState(null);
  const [selectedExercises, setSelectedExercises] = useState({
    "on-the-lines": false,
    "between-the-lines": false,
    "beyond-the-lines": false,
    "true-false": false,
    "multiple-choice": false,
    "open-ended": false,
    assignments: false,
  });

  useEffect(() => {
    Logger.log("lessonData in ReadingComprehensionDialog: ", lessonData);
  }, [lessonData]);

  const handleOpen = () => setInternalOpen(true);

  const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      setInternalOpen(false);
    }
    resetDialog();
  };

  const resetDialog = () => {
    setActiveStep(0);
    setIsGenerating(false);
    setIsEditing(false);
    setAdditionalInstructions("");

    setLanguage("svenska");
    setGrade("åk9");
    setGeneratedData(null);
    setEditedData(null);
    setSelectedExercises({
      "on-the-lines": false,
      "between-the-lines": false,
      "beyond-the-lines": false,
      "true-false": false,
      "multiple-choice": false,
      "open-ended": false,
      assignments: false,
    });
  };

  const steps = [
    "Välj övningar",
    "Inställningar",
    addExercisesToLessonObject
      ? "Redigera och lägg till i lektion"
      : initialEditMode
        ? "Redigera övning"
        : "Redigera och skriv ut",
  ];

  const handleAddQuestion = (exerciseIndex) => {
    const newData = { ...editedData };
    const exercise = newData.exercises[exerciseIndex];

    if (exercise.type === "multiple-choice") {
      exercise.content.push({
        question: "",
        options: ["", "", ""],
      });
    } else {
      exercise.content.push("");
    }

    setEditedData(newData);
  };

  const handleDeleteQuestion = (exerciseIndex, questionIndex) => {
    const newData = { ...editedData };
    newData.exercises[exerciseIndex].content.splice(questionIndex, 1);
    setEditedData(newData);
  };

  const handleDeleteOption = (exerciseIndex, questionIndex, optionIndex) => {
    const newData = { ...editedData };
    newData.exercises[exerciseIndex].content[questionIndex].options.splice(
      optionIndex,
      1,
    );
    setEditedData(newData);
  };

  const handleNext = () => {
    if (activeStep === 1) {
      handleGenerate();
    }
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
    // Reset generated data when going back
    setGeneratedData(null);
    setIsGenerating(false);
    setIsEditing(false);
    setEditedData(null);
  };

  const handleStartEditing = () => {
    setIsEditing(true);
    setEditedData(JSON.parse(JSON.stringify(generatedData)));
  };

  const handleSaveEdits = () => {
    setGeneratedData(editedData);
    setIsEditing(false);

    if (onUpdateExercise && initialExercise) {
      // If we're editing a single exercise, pass back just that exercise
      onUpdateExercise(editedData.exercises[0]);
      handleClose();
    }
  };

  const visibleSteps = initialExercise ? ["Redigera övning"] : steps;

  const handleUpdateExercise = (exerciseIndex, field, value, questionIndex) => {
    const newData = { ...editedData };
    if (questionIndex !== undefined) {
      // For multiple choice questions
      if (field.startsWith("option")) {
        const optionIndex = parseInt(field.slice(-1)) - 1;
        newData.exercises[exerciseIndex].content[questionIndex].options[
          optionIndex
        ] = value;
      } else {
        newData.exercises[exerciseIndex].content[questionIndex].question =
          value;
      }
    } else {
      // For regular questions
      newData.exercises[exerciseIndex].content[field] = value;
    }
    setEditedData(newData);
  };

  const handleDeleteExercise = (index) => {
    const newData = { ...editedData };
    newData.exercises.splice(index, 1);
    setEditedData(newData);
  };

  const handleAddExercise = (type) => {
    const newData = { ...editedData };
    const newExercise = {
      type,
      title: getDefaultTitle(type),
      content:
        type === "word-comprehension"
          ? [
              {
                word: "",
                synonyms: ["", "", ""],
                antonyms: ["", "", ""],
                examples: ["", "", ""],
              },
            ]
          : type === "multiple-choice"
            ? [{ question: "", options: ["", "", "", ""] }]
            : [""],
    };
    newData.exercises.push(newExercise);
    setEditedData(newData);
    setMenuAnchorEl(null); // Close menu after selection
  };

  const getDefaultTitle = (type) => {
    const titles = {
      "on-the-lines": "I texten",
      "between-the-lines": "Mellan raderna",
      "beyond-the-lines": "Bortom raderna",
      "true-false": "Sant eller falskt",
      "multiple-choice": "Flervalsfrågor",
      "open-ended": "Diskussionsfrågor",
      "word-comprehension": "Ordförståelse",
      assignments: "Uppgifter",
    };
    return titles[type] || "Ny övning";
  };
  const handlePrintClick = async () => {
    try {
      const analytics = getAnalytics();
      logEvent(analytics, "button_click", {
        button_name: "Print",
        page: "ReadingComprehensionDialog",
      });
    } catch (error) {
      console.error("Analytics error:", error);
    }

    handlePrintReadingComp(generatedData, theme);
  };

  const handleGenerate = async () => {
    if (!Object.values(selectedExercises).some((value) => value)) {
      alert("Please select at least one exercise type");
      return;
    }

    setIsGenerating(true);

    const gradeInstructionsText = gradeInstructions[grade];

    try {
      const response = await callApi(
        [
          {
            role: "system",
            content:
              "You are an expert teacher creating engaging reading comprehension exercises. Follow the exact JSON structure provided in the prompt.",
          },
          {
            role: "user",
            content: `Generate reading comprehension exercises for the following content: "${JSON.stringify(lessonData)}"

- The exercises and titles should be written in ${language}
- Grade instructions: ${gradeInstructionsText}
${additionalInstructions ? `\n${additionalInstructions}` : ""}


Include the following types:
${selectedExercises["on-the-lines"] ? "- 3 questions directly from the text\n" : ""}
${selectedExercises["between-the-lines"] ? "- 3 questions requiring conclusions\n" : ""}
${selectedExercises["beyond-the-lines"] ? "- 3 questions requiring analysis and own thoughts\n" : ""}
${selectedExercises["assignments"] ? "- 5 practical assignments for students to complete\n" : ""}
${selectedExercises["true-false"] ? "- 5 true/false statements\n" : ""}
${selectedExercises["multiple-choice"] ? "- 5 multiple choice questions with 4 options each\n" : ""}
${selectedExercises["open-ended"] ? "- 5 open-ended questions testing deeper understanding\n" : ""}
${selectedExercises["word-comprehension"] ? "- 4 essential words from the text with synonyms, antonyms, and example sentences. all these 4 words HAS to exist in the content provided. EXACTLY 1 task per word in useTheWords.  \n" : ""}


Response must be in this JSON format:
{
  "title": "Title",
  "exercises": [
    ${
      selectedExercises["on-the-lines"]
        ? `{
        "type": "on-the-lines",
        "title": "På raderna (svaret finns i texten)",
        "content": ["Question directly from text 1", "Question directly from text 2", "Question directly from text 3"]
      },`
        : ""
    }
    ${
      selectedExercises["between-the-lines"]
        ? `{
        "type": "between-the-lines",
        "title": "Mellan raderna (dra slutsatser från texten)",
        "content": ["Question requiring conclusions 1", "Question requiring conclusions 2", "Question requiring conclusions 3"]
      },`
        : ""
    }
    ${
      selectedExercises["beyond-the-lines"]
        ? `{
        "type": "beyond-the-lines",
        "title": "Bortom raderna (egna tankar och analys)",
        "content": ["Analytical question 1", "Analytical question 2", "Analytical question 3"]
      },`
        : ""
    }
    ${
      selectedExercises["true-false"]
        ? `{
        "type": "true-false",
        "title": "Sant eller falskt?",
        "content": ["Statement 1", "Statement 2", "Statement 3", "Statement 4", "Statement 5"]
      },`
        : ""
    }

${
  selectedExercises["assignments"]
    ? `{
  "type": "assignments",
  "title": "Uppgifter",
  "content": ["Uppgift 1", "Uppgift 2", "Uppgift 3", "Uppgift 4", "Uppgift 5"]
},`
    : ""
}
        
    ${
      selectedExercises["multiple-choice"]
        ? `{
        "type": "multiple-choice",
        "title": "Flervalsfrågor",
        "content": [
          {
            "question": "Question 1",
            "options": ["Option 1", "Option 2", "Option 3"]
          },
          {
            "question": "Question 2",
            "options": ["Option 1", "Option 2", "Option 3"]
          },
          {
            "question": "Question 3",
            "options": ["Option 1", "Option 2", "Option 3"]
          },
          {
            "question": "Question 4",
            "options": ["Option 1", "Option 2", "Option 3"]
          },
          {
            "question": "Question 5",
            "options": ["Option 1", "Option 2", "Option 3"]
          }
        ]
      },`
        : ""
    }
    ${
      selectedExercises["open-ended"]
        ? `{
        "type": "open-ended",
        "title": "Discussion Questions",
        "content": ["Open question 1", "Open question 2", "Open question 3"]
      }`
        : ""
    }


    ${
      selectedExercises["word-comprehension"]
        ? `{
            "type": "word-comprehension",
            "title": "Ord med synonymer, antonymer och exempelmeningar",
            "content": [
              {
                "word": "Word 1",
                "definition":"Definition of Word 1",
                "synonyms": ["Synonym 1", "Synonym 2", "Synonym 3"],
                "antonyms": ["Antonym 1", "Antonym 2", "Antonym 3"],
                "examples": [
                  "Example sentence 1",
                  "Example sentence 2",
                  "Example sentence 3"
                ]
               "useTheWords": [
                  "Task with Word 1",
                ]
              }
            ]
          },`
        : ""
    }
        
  ]
}


Notes:


        - ALL exercises and titles should be written in ${language}

        `,
          },
        ],
        10000,
        true,
        user.uid,
      );

      let messageContent = response.data.choices[0].message.content.trim();
      if (messageContent.startsWith("```json")) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith("```")) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      const data = JSON.parse(messageContent);

      Logger.log(
        "data in ReadingComprehensionDialog: ",
        JSON.stringify(data, null, 2),
      );

      setGeneratedData(data);
    } catch (error) {
      console.error("Error generating exercises:", error);
      alert("An error occurred while generating exercises. Please try again.");
    } finally {
      setIsGenerating(false);
    }
  };

  const renderStepContent = (step) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [previewType, setPreviewType] = useState(null);
    const [popperPlacement, setPopperPlacement] = useState("right-start");

    const handleMouseEnter = (event, option) => {
      const rect = event.currentTarget.getBoundingClientRect();
      const viewportWidth = window.innerWidth;
      const placement =
        rect.right > viewportWidth / 2 ? "left-start" : "right-start";
      setPopperPlacement(placement);
      setAnchorEl(event.currentTarget);
      setPreviewType(option);
    };

    const handleMouseLeave = () => {
      setAnchorEl(null);
      setPreviewType(null);
    };

    switch (step) {
      case 0:
        return (
          <Box sx={{ mt: 1 }}>
            <Grid container spacing={2}>
              {[
                [
                  "on-the-lines",
                  "I texten",
                  "Frågor där svaren finns direkt i texten",
                  TextFieldsIcon,
                  "/img/i.png",
                ],
                [
                  "between-the-lines",
                  "Mellan raderna",
                  "Frågor som kräver att du drar slutsatser",
                  InsightsIcon,
                  "/img/mellan.png",
                ],
                [
                  "beyond-the-lines",
                  "Bortom raderna",
                  "Frågor som kräver analys och egna tankar",
                  PsychologyIcon,
                  "/img/bortom.png",
                ],
                [
                  "true-false",
                  "Sant eller falskt",
                  "Påståenden att bedöma",
                  FactCheckIcon,
                  "/img/sant.png",
                ],
                [
                  "multiple-choice",
                  "Flervalsfrågor",
                  "Frågor med flera svarsalternativ",
                  QuizIcon,
                  "/img/flerval2.png",
                ],
                [
                  "open-ended",
                  "Diskussionsfrågor",
                  "Öppna frågor för djupare förståelse",
                  QuestionAnswerIcon,
                  "/img/diskussion.png",
                ],
                [
                  "word-comprehension",
                  "Ordförståelse",
                  "Ord med definitioner, synonymer, antonymer och uppgifter",
                  MenuBookIcon,
                  "/img/ordfork.png",
                ],
                [
                  "assignments",
                  "Uppgifter",
                  "Praktiska uppgifter för eleven att genomföra",
                  AssignmentIcon,
                  "/img/assignment.png",
                ],
              ].map(([key, title, subtitle, Icon, previewImage]) => (
                <Grid item xs={12} sm={3} key={key}>
                  <Box sx={{ position: "relative" }}>
                    <ThemeFlowBox
                      key={key}
                      title={title}
                      subtitle={subtitle}
                      icon={Icon}
                      label={
                        key === "word-comprehension" || key === "assignments"
                          ? "NYTT"
                          : undefined
                      }
                      isSelected={selectedExercises[key]}
                      diffView={true}
                      onClick={() =>
                        setSelectedExercises((prev) => ({
                          ...prev,
                          [key]: !prev[key],
                        }))
                      }
                    />
                    <IconButton
                      sx={{ position: "absolute", top: 2, left: 2 }}
                      onMouseEnter={(e) =>
                        handleMouseEnter(e, {
                          key,
                          title,
                          previewImage: previewImage,
                        })
                      }
                      onMouseLeave={handleMouseLeave}
                    >
                      <CenterFocusStrongIcon
                        sx={{
                          fontSize: "15px",
                          color: (theme) =>
                            alpha(theme.palette.text.secondary, 0.3),
                        }}
                      />
                    </IconButton>
                  </Box>
                </Grid>
              ))}
            </Grid>

            <Popper
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              placement={popperPlacement}
              sx={{ zIndex: 1300 }}
            >
              <Paper
                elevation={3}
                sx={{
                  maxWidth: 400,
                  backgroundColor: "background.paper",
                  ml: popperPlacement === "right-start" ? 2 : undefined,
                  mr: popperPlacement === "left-start" ? 2 : undefined,
                  borderRadius: 2,
                  p: 1,
                  pb: 0.2,
                }}
              >
                {previewType && (
                  <Box
                    component="img"
                    src={previewType.previewImage}
                    alt={`${previewType.title} preview`}
                    sx={{
                      width: "100%",
                      height: "auto",
                      borderRadius: 2,
                    }}
                  />
                )}
              </Paper>
            </Popper>
          </Box>
        );

      case 1:
        return (
          <Grid
            container
            spacing={3}
            sx={{ width: { xs: "100%", md: "60%" }, margin: "0 auto", mt: 1 }}
          >
            <Grid item xs={12} md={12}>
              <Typography variant="subtitle1" sx={{ fontWeight: "500", mb: 1 }}>
                Årskurs:
              </Typography>
              <GradeSelector grade={grade} setGrade={setGrade} />
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: "500", mb: 1, mt: 1 }}
              >
                Språk:
              </Typography>
              <LanguageSelector language={language} setLanguage={setLanguage} />
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: "500", mb: 1, mt: 1 }}
              >
                Kompletterande instruktioner (valfritt):
              </Typography>
              <TextField
                fullWidth
                multiline
                rows={3}
                placeholder="T.ex. övningarna bör ha ett fokus på X, och får gärna nämna Y, men nämn inte Z."
                value={additionalInstructions}
                onChange={(e) => setAdditionalInstructions(e.target.value)}
              />
            </Grid>
          </Grid>
        );

      case 2:
        return (
          <Box sx={{ mt: 2 }}>
            {generatedData && (
              <>
                {isEditing ? (
                  <Box>
                    <TextField
                      fullWidth
                      value={editedData.title}
                      onChange={(e) =>
                        setEditedData({
                          ...editedData,
                          title: e.target.value,
                        })
                      }
                      sx={{
                        mb: 6,
                        textAlign: "center",
                        display: initialExercise ? "none" : "block",
                      }}
                      InputProps={{
                        sx: {
                          "& input": {
                            fontWeight: 600,
                            textAlign: "center",
                          },
                        },
                      }}
                    />

                    {editedData.exercises.map((exercise, exerciseIndex) => (
                      <Paper
                        key={exerciseIndex}
                        sx={{
                          ...styles.freshBox,
                          p: 3,
                          mb: 6,
                          boxShadow: "0 3px 28px rgba(12,8,51,.06)",
                          borderRadius: 4,
                          backgroundColor:
                            theme.palette.mode === "dark"
                              ? "#15202b"
                              : "#e0e0e029",
                          "&:hover": {
                            border: (theme) =>
                              `1px solid ${theme.palette.primary.main}`,
                          },
                        }}
                      >
                        <Box>
                          <Typography
                            variant="subtitle1"
                            sx={{ fontWeight: "500", mb: 1 }}
                          >
                            Rubrik för övningen
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              mb: 2,
                            }}
                          >
                            <TextField
                              value={exercise.title}
                              onChange={(e) => {
                                const newData = { ...editedData };
                                newData.exercises[exerciseIndex].title =
                                  e.target.value;
                                setEditedData(newData);
                              }}
                              sx={{ width: "70%", mb: 2 }}
                              InputProps={{
                                sx: {
                                  "& input": {
                                    fontWeight: 600,
                                  },
                                },
                              }}
                            />
                            <Box>
                              <Tooltip
                                title={`Ta bort hela övningen "${exercise.title}"`}
                              >
                                <IconButton
                                  color="text.secondary"
                                  onClick={() =>
                                    handleDeleteExercise(exerciseIndex)
                                  }
                                  size="small"
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </Box>
                        </Box>

                        {exercise.type === "multiple-choice"
                          ? exercise.content.map((item, questionIndex) => (
                              <Box
                                key={questionIndex}
                                sx={{ mb: 3, position: "relative" }}
                              >
                                <Box>
                                  <Typography
                                    variant="subtitle1"
                                    sx={{ fontWeight: "500", mb: 1 }}
                                  >
                                    {`Fråga ${questionIndex + 1}`}
                                  </Typography>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      mb: 1,
                                    }}
                                  >
                                    <TextField
                                      fullWidth
                                      value={item.question}
                                      onChange={(e) =>
                                        handleUpdateExercise(
                                          exerciseIndex,
                                          "question",
                                          e.target.value,
                                          questionIndex,
                                        )
                                      }
                                    />
                                    <Tooltip title={`Ta bort frågan`}>
                                      <IconButton
                                        color="text.secondary"
                                        onClick={() => {
                                          const newData = { ...editedData };
                                          newData.exercises[
                                            exerciseIndex
                                          ].content.splice(questionIndex, 1);
                                          setEditedData(newData);
                                        }}
                                        sx={{ ml: 1 }}
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </Box>
                                </Box>
                                <Box sx={{ pl: 2 }}>
                                  {item.options.map((option, optionIndex) => (
                                    <Box key={optionIndex}>
                                      <Typography
                                        variant="subtitle1"
                                        sx={{ fontWeight: "500", mb: 1, mt: 2 }}
                                      >
                                        {`Svarsalternativ ${optionIndex + 1}`}
                                      </Typography>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          gap: 1,
                                        }}
                                      >
                                        <TextField
                                          fullWidth
                                          value={option}
                                          onChange={(e) =>
                                            handleUpdateExercise(
                                              exerciseIndex,
                                              `option${optionIndex + 1}`,
                                              e.target.value,
                                              questionIndex,
                                            )
                                          }
                                          sx={{ mb: 1 }}
                                        />
                                        <Tooltip title="Ta bort alternativ">
                                          <IconButton
                                            color="text.secondary"
                                            onClick={() =>
                                              handleDeleteOption(
                                                exerciseIndex,
                                                questionIndex,
                                                optionIndex,
                                              )
                                            }
                                            size="small"
                                          >
                                            <DeleteIcon />
                                          </IconButton>
                                        </Tooltip>
                                      </Box>
                                    </Box>
                                  ))}
                                </Box>
                              </Box>
                            ))
                          : exercise.type === "word-comprehension"
                            ? exercise.content.map((item, wordIndex) => (
                                <Box key={wordIndex} sx={{ mb: 4 }}>
                                  <Typography
                                    variant="subtitle1"
                                    sx={{ fontWeight: "500", mb: 1 }}
                                  >
                                    {`Ord ${wordIndex + 1}`}
                                  </Typography>
                                  <TextField
                                    fullWidth
                                    value={item.word}
                                    onChange={(e) => {
                                      const newData = { ...editedData };
                                      newData.exercises[exerciseIndex].content[
                                        wordIndex
                                      ].word = e.target.value;
                                      setEditedData(newData);
                                    }}
                                    sx={{ mb: 2 }}
                                  />

                                  <Typography
                                    variant="subtitle1"
                                    sx={{
                                      fontWeight: "500",
                                      mb: 1,
                                      mt: 2,
                                      ml: 2,
                                    }}
                                  >
                                    Definition
                                  </Typography>
                                  <TextField
                                    fullWidth
                                    multiline
                                    value={item.definition || ""}
                                    onChange={(e) => {
                                      const newData = { ...editedData };
                                      newData.exercises[exerciseIndex].content[
                                        wordIndex
                                      ].definition = e.target.value;
                                      setEditedData(newData);
                                    }}
                                    sx={{ ml: 2, mb: 2 }}
                                  />

                                  {[
                                    "synonyms",
                                    "antonyms",
                                    "examples",
                                    "useTheWords",
                                  ].map((field) => (
                                    <Box key={field}>
                                      <Typography
                                        variant="subtitle1"
                                        sx={{
                                          fontWeight: "500",
                                          mb: 1,
                                          mt: 2,
                                          ml: 2,
                                        }}
                                      >
                                        {field === "synonyms"
                                          ? "Synonymer"
                                          : field === "antonyms"
                                            ? "Antonymer"
                                            : field === "examples"
                                              ? "Exempelmeningar"
                                              : "Övningar med ordet"}
                                        {field !== "useTheWords" &&
                                          ` för ordet "${item.word}"`}
                                      </Typography>
                                      {item[field]?.map((value, valueIndex) => (
                                        <Box
                                          key={valueIndex}
                                          sx={{
                                            display: "flex",
                                            gap: 1,
                                            mb: 1,
                                          }}
                                        >
                                          <TextField
                                            fullWidth
                                            value={value}
                                            onChange={(e) => {
                                              const newData = { ...editedData };
                                              if (
                                                !newData.exercises[
                                                  exerciseIndex
                                                ].content[wordIndex][field]
                                              ) {
                                                newData.exercises[
                                                  exerciseIndex
                                                ].content[wordIndex][field] =
                                                  [];
                                              }
                                              newData.exercises[
                                                exerciseIndex
                                              ].content[wordIndex][field][
                                                valueIndex
                                              ] = e.target.value;
                                              setEditedData(newData);
                                            }}
                                            sx={{ ml: 2 }}
                                          />
                                          <Tooltip title="Ta bort">
                                            <IconButton
                                              onClick={() => {
                                                const newData = {
                                                  ...editedData,
                                                };
                                                newData.exercises[
                                                  exerciseIndex
                                                ].content[wordIndex][
                                                  field
                                                ].splice(valueIndex, 1);
                                                setEditedData(newData);
                                              }}
                                            >
                                              <DeleteIcon />
                                            </IconButton>
                                          </Tooltip>
                                        </Box>
                                      ))}
                                      <Button
                                        startIcon={<AddIcon />}
                                        onClick={() => {
                                          const newData = { ...editedData };
                                          if (
                                            !newData.exercises[exerciseIndex]
                                              .content[wordIndex][field]
                                          ) {
                                            newData.exercises[
                                              exerciseIndex
                                            ].content[wordIndex][field] = [];
                                          }
                                          newData.exercises[
                                            exerciseIndex
                                          ].content[wordIndex][field].push("");
                                          setEditedData(newData);
                                        }}
                                        variant="print"
                                        size="small"
                                        sx={{ mt: 1, mb: 3, ml: 2 }}
                                      >
                                        Lägg till{" "}
                                        {field === "synonyms"
                                          ? "synonym"
                                          : field === "antonyms"
                                            ? "antonym"
                                            : field === "examples"
                                              ? "exempel"
                                              : "övning"}
                                      </Button>
                                    </Box>
                                  ))}
                                </Box>
                              ))
                            : exercise.content.map((item, contentIndex) => (
                                <Box key={contentIndex}>
                                  <Typography
                                    variant="subtitle1"
                                    sx={{ fontWeight: "500", mb: 1, ml: 2 }}
                                  >
                                    {`Fråga ${contentIndex + 1}`}
                                  </Typography>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      mb: 2,
                                    }}
                                  >
                                    <TextField
                                      fullWidth
                                      multiline
                                      sx={{ ml: 2 }}
                                      value={item}
                                      onChange={(e) =>
                                        handleUpdateExercise(
                                          exerciseIndex,
                                          contentIndex,
                                          e.target.value,
                                        )
                                      }
                                    />
                                    <Tooltip title={`Ta bort`}>
                                      <IconButton
                                        color="text.secondary"
                                        onClick={() => {
                                          const newData = { ...editedData };
                                          newData.exercises[
                                            exerciseIndex
                                          ].content.splice(contentIndex, 1);
                                          setEditedData(newData);
                                        }}
                                        sx={{ ml: 1 }}
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </Box>
                                </Box>
                              ))}

                        <Button
                          startIcon={<AddIcon />}
                          onClick={() => {
                            const newData = { ...editedData };
                            const exercise = newData.exercises[exerciseIndex];

                            if (exercise.type === "multiple-choice") {
                              exercise.content.push({
                                question: "",
                                options: ["", "", ""],
                              });
                            } else {
                              exercise.content.push("");
                            }

                            setEditedData(newData);
                          }}
                          sx={{ mr: 1, mt: 2 }}
                          variant="print"
                        >
                          Lägg till fråga
                        </Button>
                      </Paper>
                    ))}

                    <Box sx={{ mt: 2, mb: 2 }}>
                      <Button
                        variant="print"
                        startIcon={<AddIcon />}
                        onClick={(event) =>
                          setMenuAnchorEl(event.currentTarget)
                        }
                        sx={{ display: initialExercise ? "none" : undefined }}
                      >
                        Lägg till övning
                      </Button>
                      <Menu
                        anchorEl={menuAnchorEl}
                        open={Boolean(menuAnchorEl)}
                        onClose={() => setMenuAnchorEl(null)}
                        PaperProps={{
                          sx: {
                            ...styles.roundMenu,
                            borderRadius: "8px",
                          },
                        }}
                      >
                        {[
                          ["on-the-lines", "I texten", TextFieldsIcon],
                          ["between-the-lines", "Mellan raderna", InsightsIcon],
                          [
                            "beyond-the-lines",
                            "Bortom raderna",
                            PsychologyIcon,
                          ],
                          ["true-false", "Sant eller falskt", FactCheckIcon],
                          ["assignments", "Uppgifter", AssignmentIcon],

                          ["multiple-choice", "Flervalsfrågor", QuizIcon],
                          [
                            "open-ended",
                            "Diskussionsfrågor",
                            QuestionAnswerIcon,
                          ],
                        ].map(([type, label, Icon]) => (
                          <MenuItem
                            key={type}
                            onClick={() => handleAddExercise(type)}
                            sx={styles.menuItemStyle}
                          >
                            <Icon
                              fontSize="small"
                              sx={{ mr: 1, color: "primary.main" }}
                            />
                            {label}
                          </MenuItem>
                        ))}
                      </Menu>
                    </Box>
                  </Box>
                ) : (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mb: 3,
                      }}
                    >
                      <Typography
                        variant="h5"
                        sx={{
                          fontWeight: 600,
                          textAlign: "center",
                          width: "100%",
                          display: "none",
                        }}
                      >
                        {generatedData.title}
                      </Typography>
                    </Box>
                    {generatedData.exercises.map((exercise, index) => (
                      <Box key={index}>{renderExerciseSection(exercise)}</Box>
                    ))}
                  </>
                )}
              </>
            )}
          </Box>
        );
      default:
        return null;
    }
  };

  const renderExerciseSection = (exercise) => {
    const isMultipleChoice = exercise.type === "multiple-choice";

    if (exercise.type === "word-comprehension") {
      return (
        <Box sx={{ mt: 4 }}>
          <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
            {exercise.title}
          </Typography>
          <Box
            sx={{
              backgroundColor:
                theme.palette.mode === "dark" ? "#15202b" : "#e0e0e029",
              borderRadius: "10px",
              padding: "20px",
            }}
          >
            {exercise.content.map((item, wordIndex) => (
              <Box key={wordIndex} sx={{ mb: 4 }}>
                <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
                  {item.word}
                </Typography>

                {item.definition && (
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "500", mb: 1 }}
                  >
                    Definition:{" "}
                    <Typography component="span" sx={{ fontWeight: "400" }}>
                      {item.definition}
                    </Typography>
                  </Typography>
                )}

                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "500", mb: 1 }}
                >
                  Synonymer:{" "}
                  <Typography component="span" sx={{ fontWeight: "400" }}>
                    {item.synonyms?.join(", ")}
                  </Typography>
                </Typography>

                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "500", mb: 1 }}
                >
                  Antonymer:{" "}
                  <Typography component="span" sx={{ fontWeight: "400" }}>
                    {item.antonyms?.join(", ")}
                  </Typography>
                </Typography>

                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "500", mb: 1 }}
                >
                  Exempelmeningar:
                </Typography>
                <ul>
                  {item.examples?.map((example, i) => (
                    <li key={i}>
                      <Typography>{example}</Typography>
                    </li>
                  ))}
                </ul>

                {item.useTheWords && item.useTheWords.length > 0 && (
                  <>
                    <Typography
                      variant="subtitle1"
                      sx={{ fontWeight: "500", mb: 1, mt: 3 }}
                    >
                      Övningar med ordet:
                    </Typography>
                    <ul>
                      {item.useTheWords.map((task, i) => (
                        <li key={i}>
                          <Typography>{task}</Typography>
                        </li>
                      ))}
                    </ul>
                  </>
                )}

                <Divider sx={{ mb: 6, mt: 6 }} />
              </Box>
            ))}
          </Box>
        </Box>
      );
    }

    return (
      <Box sx={{ mt: 4 }}>
        <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
          {exercise.title}
        </Typography>
        <Box
          sx={{
            backgroundColor:
              theme.palette.mode === "dark" ? "#15202b" : "#e0e0e029",
            borderRadius: "10px",
            padding: "20px",
          }}
        >
          <ul>
            {isMultipleChoice
              ? exercise.content.map((item, i) => (
                  <li key={i}>
                    <Typography
                      variant="body1"
                      sx={{ mb: 1, mt: 2, fontWeight: "medium" }}
                    >
                      {item.question}
                    </Typography>
                    <Box sx={{ pl: 3 }}>
                      {item.options.map((option, optionIndex) => (
                        <Typography
                          key={optionIndex}
                          variant="body1"
                          sx={{ mb: 0.5 }}
                        >
                          {String.fromCharCode(65 + optionIndex)}) {option}
                        </Typography>
                      ))}
                    </Box>
                  </li>
                ))
              : exercise.content.map((item, i) => (
                  <li key={i}>
                    <Typography variant="body1" sx={{ mb: 2 }}>
                      {item}
                    </Typography>
                  </li>
                ))}
          </ul>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Tooltip title={tooltip} placement="top">
        <Button
          ref={buttonRef}
          variant={theme.palette.mode === "light" ? "purp" : "contained"}
          onClick={handleOpen}
          startIcon={<GenerateIcon />}
        >
          {buttonText}
        </Button>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: { position: "relative", minWidth: "1200px" },
        }}
      >
        <Backdrop
          open={isGenerating}
          sx={{
            position: "absolute",
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor:
              theme.palette.mode === "dark"
                ? "rgba(0, 0, 0, 0.85)"
                : "rgba(0, 0, 0, 0.8)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CircularProgress sx={{ color: "white" }} size={40} />
            <Typography variant="h6" sx={{ mt: 2, color: "white" }}>
              Genererar ditt material...
            </Typography>
          </Box>
        </Backdrop>
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            sx={{
              textAlign: "center",
              fontWeight: "600",
              mt: 3,

              fontSize: "1.5rem",
            }}
          >
            {activeStep === 0
              ? "Välj övningar"
              : activeStep === 1
                ? "Inställningar"
                : activeStep === 2
                  ? addExercisesToLessonObject
                    ? "Redigera och lägg till i lektion"
                    : initialExercise
                      ? "Redigera och spara"
                      : "Redigera och skriv ut"
                  : ""}
          </Typography>

          <Box sx={{ mt: 4, mb: 4, position: "relative" }}>
            {activeStep > 0 && (
              <IconButton
                onClick={handleBack}
                sx={{
                  position: "absolute",
                  top: -7,
                  left: 0,
                  zIndex: 10,
                  display: initialExercise ? "none" : undefined,
                }}
              >
                <ArrowBack />
              </IconButton>
            )}
            <Stepper
              activeStep={initialExercise ? 0 : activeStep}
              sx={{
                mb: 4,

                width: { xs: "100%", md: "60%" },
                margin: "0 auto",
                display: initialExercise ? "none" : undefined,
              }}
            >
              {visibleSteps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        </DialogTitle>

        <DialogContent sx={{ position: "relative", minHeight: "440px" }}>
          {renderStepContent(activeStep)}
        </DialogContent>
        <Divider />
        <DialogActions sx={{ p: 2, justifyContent: "space-between" }}>
          {activeStep === 2 ? (
            <>
              <div>
                {isEditing ? (
                  <Button
                    onClick={handleSaveEdits}
                    variant="purp"
                    sx={{ mr: 2 }}
                    startIcon={<SaveAsIcon />}
                  >
                    Spara ändringar
                  </Button>
                ) : (
                  <Button
                    startIcon={<EditIcon />}
                    variant="purp"
                    onClick={handleStartEditing}
                    sx={{ mr: 2 }}
                  >
                    Redigera övningar
                  </Button>
                )}

                {addExercisesToLessonObject && (
                  <Button
                    variant="success"
                    onClick={() => {
                      addExercisesToLessonObject((prevLessonData) => {
                        // Filter out exercises whose type already exists in prevLessonData
                        const newExercises = generatedData.exercises.filter(
                          (newExercise) =>
                            !(prevLessonData.exercises || []).some(
                              (existingExercise) =>
                                existingExercise.type === newExercise.type,
                            ),
                        );

                        return {
                          ...prevLessonData,
                          exercises: newExercises,
                        };
                      });
                      showSnackbar(
                        "Övningarna tillagda under din lektion!",
                        "success",
                      );
                      handleClose();
                    }}
                    sx={{ mr: 2 }}
                    startIcon={<GenerateIcon />}
                  >
                    Lägg till i din lektion
                  </Button>
                )}
              </div>
              <div>
                <Button
                  startIcon={<PrintIcon />}
                  onClick={handlePrintClick}
                  variant="print"
                  sx={{ display: initialExercise ? "none" : undefined }}
                >
                  Skriv ut
                </Button>
              </div>
            </>
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <Button
                  disabled={
                    activeStep === 0 &&
                    !Object.values(selectedExercises).some((value) => value)
                  }
                  onClick={handleNext}
                  variant="purp"
                  startIcon={
                    activeStep === 1 && isGenerating ? (
                      <CircularProgress size={20} />
                    ) : null
                  }
                >
                  {activeStep === steps.length - 1 ? "Slutför" : "Nästa"}{" "}
                  <ArrowIcon sx={{ ml: 1 }} />
                </Button>
              </Box>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ReadingComprehensionDialog;
