import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  Dialog,
  DialogTitle,
  Grid,
  Chip,
  DialogContent,
  Tooltip,
  DialogActions,
  Button,
  CircularProgress,
  IconButton,
  useTheme,
} from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import { useSnackbar } from "./SnackbarHandler";
import EditIcon from "@mui/icons-material/Edit";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { useNavigate } from "react-router-dom";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import Logger from "./Logger";
import ShareIcon from "@mui/icons-material/Share";

import QuizDifficultyDialog from "./QuizDifficultyDialog";
import SaveIcon from "@mui/icons-material/SaveAs";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import GenerateIcon from "@mui/icons-material/AutoAwesome";
import { Link } from "react-router-dom";
import { createUrlSlug } from "./functions";

import AutorenewIcon from "@mui/icons-material/Autorenew"; // Using Autorenew as Generate icon
import SaveAsIcon from "@mui/icons-material/SaveAs";
import AddIcon from "@mui/icons-material/Add";

import {
  collection,
  getDocs,
  query,
  where,
  documentId,
  deleteDoc,
  addDoc,
  updateDoc,
  doc,
  limit,
} from "firebase/firestore";
import { db } from "./firebase";

import QuestionDisplay from "./QuestionDisplay";

// hej
const TeacherQuizDisplay = ({
  quiz,
  quizId,
  quizName,
  questionType,
  onPrint,
  onQuizUpdate,
  onNameChange,
  styles,
  onShare,
  onShareWithFriends,
  onSave,
  onClose,
  editMode = false,
  user,
  category,
  difficulty,
  aiInstructions,
  selectedLanguage,
  selectedModel,
  addQuizToLessonObject,
  selectedQuiz,
  reloadMyQuizzes,
  includeTimer,
  duration,
}) => {
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [quizData, setQuizData] = useState(quiz);
  const [isAnimating, setIsAnimating] = useState(false);
  const [difficultyDialogOpen, setDifficultyDialogOpen] = useState(false);
  const { showSnackbar } = useSnackbar();

  const commonTags = [
    "Matematik",
    "Svenska",
    "Engelska",
    "Samhällskunskap",
    "Naturkunskap",
    "Kemi",
    "Fysik",
    "Biologi",
    "Historia",
    "Religion",
    "Geografi",

    "Teknik",
    "Bild",
    "Musik",
    "Slöjd",
    "Modersmål",
    "Moderna språk",
    "Språk",
    "Idrott och hälsa",
    "Spanska",
    "Tyska",
    "Franska",
    "Hem- och konsumentkunskap",
    "Företagsekonomi",
    "Psykologi",
    "Filosofi",
    "1-3",
    "4-6",
    "7-9",
    "Gymnasiet",
    "Nationella provet",
    "Påsk",
    "Alla hjärtans dag",
  ];

  const [tags, setTags] = useState(selectedQuiz?.tags || []);

  const [tagInput, setTagInput] = useState("");
  console.log("TeacherQuizDisplay quiz: ", quiz);
  console.log("TeacherQuizDisplay quizId: ", quizId);
  Logger.log("addQuizToLessonObject: ", addQuizToLessonObject);
  //

  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(false);
  const [isSavingWithoutInteractiveMode, setIsSavingWithoutInteractiveMode] =
    useState(false);

  useEffect(() => {
    if (selectedQuiz?.tags) {
      setTags(selectedQuiz.tags);
    }
  }, [selectedQuiz]);
  const handleAnimateEditAndDeleteIcon = () => {
    setIsAnimating(true);
    setTimeout(() => setIsAnimating(false), 2000);
  };

  const handleDifficultyUpdate = (newQuizData) => {
    setQuizData(newQuizData);
    onQuizUpdate(newQuizData);
  };

  const getDefaultQuestion = (type) => {
    if (type === "exam") {
      return {
        question: "",
        type: "exam",
      };
    }
    return {
      question: "",
      options: ["", "", "", ""],
      correct: "",
    };
  };

  const handleAddQuestionAndScroll = () => {
    const newQuizData = [...quizData, getDefaultQuestion(questionType)];
    setQuizData(newQuizData);
    onQuizUpdate(newQuizData);

    setTimeout(() => {
      const questions = document.querySelectorAll(".question-card");
      const lastQuestion = questions[questions.length - 1];
      lastQuestion?.scrollIntoView({ behavior: "smooth", block: "start" });
    }, 100);
  };

  const handleClose = () => {
    setOpen(false);
    onClose?.();
  };

  const handleQuizNavigation = async () => {
    if (!quizId) {
      // If no quizId exists, we need to save first
      setIsSaving(true);
      try {
        const docRef = await addDoc(collection(db, "quiz"), {
          userId: user.uid,
          results: quizData,
          quizName: quizName,
          createdAt: Date.now(),
          byAdmin: "No",
          isPublic: true,
          questionType: questionType,
          category,
          difficulty,
          aiInstructions,
          lastTrainingSession: Date.now(),
          language: selectedLanguage,
          aiModel: selectedModel,
          upvotes: 0,
          downvotes: 0,
          userVotes: {},
          includeTimer: includeTimer,
          duration: duration,
        });

        // Navigate to the new quiz using the newly created ID
        const formattedQuizName = createUrlSlug(quizName);
        navigate(`/app/quiz/${docRef.id}/${formattedQuizName}`);
      } catch (error) {
        console.error("Error saving quiz:", error);
        showSnackbar("Ett fel uppstod när quizzet skulle sparas.", "error");
      } finally {
        setIsSaving(false);
      }
    } else {
      // If quizId exists, navigate directly
      navigate(
        `/app/quiz/${quizId.substring(0, 10)}/${createUrlSlug(quizName)}`,
      );
    }
  };

  const handleSave = async () => {
    if (editMode) {
      // Update existing quiz
      try {
        setIsSavingWithoutInteractiveMode(true);

        const quizRef = doc(db, "quiz", quizId);
        const updateData = {
          results: quizData,
          quizName: quizName,
          updatedAt: Date.now(),

          tags: tags,
          includeTimer: includeTimer,
          duration: duration,
        };

        await updateDoc(quizRef, updateData);
        showSnackbar("Quizzet har uppdaterats!", "success");
        // Only call reloadMyQuizzes if it exists
        if (typeof reloadMyQuizzes === "function") {
          reloadMyQuizzes();
        }
        setIsSavingWithoutInteractiveMode(false);
        //onSave();
      } catch (error) {
        console.error("Error updating quiz:", error);
        showSnackbar("Ett fel uppstod när quizzet skulle uppdateras.", "error");
        setIsSavingWithoutInteractiveMode(false);
      }
    } else {
      // Create new quiz - use existing save logic
      // Moonshine gå tillbaka till bara onsave om problem uppstår
      //onSave();

      try {
        setIsSavingWithoutInteractiveMode(true);
        const docRef = await addDoc(collection(db, "quiz"), {
          userId: user.uid,
          results: quizData,
          quizName: quizName,
          createdAt: Date.now(),
          byAdmin: "No",
          isPublic: true,
          questionType: questionType,
          category,
          difficulty,
          aiInstructions,
          lastTrainingSession: Date.now(),
          language: selectedLanguage,
          aiModel: selectedModel,
          upvotes: 0,
          downvotes: 0,
          userVotes: {},
          tags: tags,
          includeTimer: includeTimer,
          duration: duration,
        });
        showSnackbar(
          "Du har nu sparat quizzet och kan hitta det i 'Mina quiz'",
          "success",
        );
        // Only call reloadMyQuizzes if it exists
        if (typeof reloadMyQuizzes === "function") {
          reloadMyQuizzes();
        }
        setIsSavingWithoutInteractiveMode(false);
      } catch (error) {
        console.error("Error saving quiz:", error);
        showSnackbar("Ett fel uppstod när quizzet skulle sparas.", "error");
        setIsSavingWithoutInteractiveMode(false);
      }
    }
  };

  const handleShare = () => {
    //handleClose();
    onShare();
  };

  const handleShareWithFriends = () => {
    //handleClose();
    onShareWithFriends();
  };

  const handleUpdateQuestion = (index, updatedQuestion) => {
    const newQuizData = [...quizData];
    newQuizData[index] = updatedQuestion;
    setQuizData(newQuizData);
    onQuizUpdate(newQuizData);
  };

  const handleDeleteQuestion = (index) => {
    const newQuizData = quizData.filter((_, idx) => idx !== index);
    setQuizData(newQuizData);
    onQuizUpdate(newQuizData);
  };

  const handleAddQuestion = () => {
    const newQuizData = [...quizData, getDefaultQuestion(questionType)];
    setQuizData(newQuizData);
    onQuizUpdate(newQuizData);
  };

  // const handleAddQuestion = () => {
  //   const newQuizData = [
  //     ...quizData,
  //     {
  //       question: "",
  //       options: ["", "", "", ""],
  //       correct: "",
  //       hints: [],
  //     },
  //   ];
  //   setQuizData(newQuizData);
  //   onQuizUpdate(newQuizData); // Notify parent of the update
  // };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xl"
      fullWidth
      scroll="paper"
      PaperProps={{
        sx: {
          width: "100%",

          borderRadius: "20px",
          backgroundColor: theme.palette.mode === "dark" ? "#22303C" : "white",
          backgroundImage: theme.palette.mode === "dark" ? "none" : "white",
          padding: { xs: "0px", md: "20px" },
        },
      }}
    >
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {editMode ? (
          <TextField
            value={quizName}
            onChange={(e) => onNameChange?.(e.target.value)}
            sx={{ width: "100%", fontWeight: "700" }}
          />
        ) : (
          <Typography variant="h6" sx={{ fontWeight: "600" }}>
            {quizName}
          </Typography>
        )}
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Box sx={{ width: "100%" }}>
          <Grid container spacing={3} sx={{ width: "100%" }}>
            {quizData.map((question, index) => (
              <QuestionDisplay
                key={index}
                question={question}
                index={index}
                type={questionType}
                onUpdateQuestion={handleUpdateQuestion}
                onDeleteQuestion={handleDeleteQuestion}
                styles={styles}
                className="question-card"
                isAnimating={isAnimating}
              />
            ))}
          </Grid>

          <Box sx={{ display: "flex", justifyContent: "center", mb: 4, mt: 4 }}>
            <Button
              variant="print"
              startIcon={<AddIcon />}
              onClick={handleAddQuestion}
            >
              Lägg till ny fråga
            </Button>
          </Box>

          <Card
            elevation={0}
            sx={{
              mt: 10,
              backgroundColor: theme.palette.background.paperHover,
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: "15px",
              padding: "20px",
            }}
          >
            <CardContent>
              <Typography variant="h6" gutterBottom sx={{ fontWeight: "600" }}>
                Facit
              </Typography>
              {quizData.map((question, index) => (
                <Box key={index} sx={{ mb: 2, mt: 4 }}>
                  <Typography>
                    Fråga {index + 1}: {question.correct}
                  </Typography>
                </Box>
              ))}
            </CardContent>
          </Card>
        </Box>

        <Box sx={{ mt: 4, mb: 4 }}>
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            Taggar (valfritt):
          </Typography>
          <Box sx={{ mb: 2 }}>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
              Vanliga taggar:
            </Typography>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mb: 2 }}>
              {commonTags.map((tag) => (
                <Button
                  key={tag}
                  variant={
                    tag === "1-3" ||
                    tag === "4-6" ||
                    tag === "7-9" ||
                    tag === "Gymnasiet"
                      ? "success"
                      : tag === "Nationella provet"
                        ? "purp"
                        : "print"
                  }
                  startIcon={
                    tags.includes(tag) ? (
                      <CheckCircleIcon sx={{ color: "green" }} />
                    ) : (
                      ""
                    )
                  }
                  onClick={() => {
                    if (tags.includes(tag)) {
                      setTags(tags.filter((t) => t !== tag));
                    } else {
                      setTags([...tags, tag]);
                    }
                  }}
                  sx={{
                    ...styles.tightButton,
                  }}
                >
                  {tag}
                </Button>
              ))}
            </Box>
          </Box>
          <Box sx={{ mb: 3 }}>
            <TextField
              fullWidth
              value={tagInput}
              onChange={(e) => setTagInput(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter" && tagInput.trim()) {
                  setTags([...new Set([...tags, tagInput.trim()])]);
                  setTagInput("");
                }
              }}
              placeholder="Skriv och tryck enter för att lägga till tagg"
              variant="outlined"
            />
            <Box sx={{ mt: 1, display: "flex", flexWrap: "wrap", gap: 1 }}>
              {tags.map((tag) => (
                <Chip
                  key={tag}
                  label={tag}
                  onDelete={() => setTags(tags.filter((t) => t !== tag))}
                  sx={{ m: 0.5 }}
                />
              ))}
            </Box>
          </Box>
        </Box>
      </DialogContent>

      <DialogActions sx={{ p: 2, justifyContent: "space-between" }}>
        <div>
          <Button
            variant="print"
            startIcon={<AddIcon />}
            onClick={handleAddQuestionAndScroll}
            sx={{ display: "none" }}
          >
            Lägg till fråga
          </Button>
          <Button
            variant="print"
            startIcon={<EditIcon />}
            sx={{ ml: 2 }}
            onClick={handleAnimateEditAndDeleteIcon}
            sx={{ display: "none" }}
          >
            Redigera quiz
          </Button>

          {addQuizToLessonObject && (
            <Button
              variant="success"
              onClick={() => {
                addQuizToLessonObject((prevLessonData) => ({
                  ...prevLessonData,
                  quiz: quizData,
                }));
                handleClose();
              }}
              sx={{ mr: 2, ml: 2 }}
              startIcon={<GenerateIcon />}
            >
              Lägg till quiz i din lektion
            </Button>
          )}
        </div>
        <div>
          {!addQuizToLessonObject && (
            <>
              <Tooltip
                title={
                  editMode
                    ? ""
                    : "Spara ditt quiz om du vill redigera det vid senare tillfälle, eller dela med fler elever vid senare tidpunkt."
                }
              >
                <Button
                  variant={editMode ? "success" : "success"}
                  onClick={handleSave}
                  sx={{ mr: 2 }}
                  disabled={isSavingWithoutInteractiveMode}
                  startIcon={
                    isSavingWithoutInteractiveMode ? (
                      <CircularProgress size={18} sx={{ color: "white" }} />
                    ) : (
                      <SaveAsIcon />
                    )
                  }
                >
                  {editMode ? "Spara ändringar" : "Spara"}
                </Button>
              </Tooltip>

              <Button
                variant="success"
                onClick={() => setDifficultyDialogOpen(true)}
                sx={{ mr: 2 }}
                startIcon={<AutoFixHighIcon />}
              >
                Anpassa svårighetsgrad
              </Button>

              {questionType !== "current-events" && (
                <Tooltip title="Starta ett interaktivt quiz!">
                  <Button
                    variant="purp"
                    onClick={handleQuizNavigation}
                    sx={{ mr: 2 }}
                    disabled={isSaving}
                    startIcon={
                      isSaving ? (
                        <CircularProgress size={18} sx={{ color: "white" }} />
                      ) : (
                        <PlayArrowIcon />
                      )
                    }
                  >
                    {isSaving ? "Startar quiz..." : "Quizza mig"}
                  </Button>
                </Tooltip>
              )}
            </>
          )}

          {user.accountType === "teacher" && (
            <>
              {questionType !== "current-events" && (
                <>
                  {onShareWithFriends && (
                    <Tooltip title="Dela med vänner">
                      <Button
                        variant="purp"
                        onClick={handleShareWithFriends}
                        sx={{ mr: 2 }}
                        startIcon={<ShareIcon />}
                      >
                        Dela med vän
                      </Button>
                    </Tooltip>
                  )}
                  {onShare && (
                    <Tooltip title="Dela med dina elever">
                      <Button
                        variant="purp"
                        onClick={handleShare}
                        sx={{ mr: 2 }}
                        startIcon={<ShareIcon />}
                      >
                        Dela med elev
                      </Button>
                    </Tooltip>
                  )}
                </>
              )}
            </>
          )}
          <Button
            variant="purp"
            onClick={() => onPrint(quizData)}
            startIcon={<PrintIcon />}
          >
            Skriv ut
          </Button>
        </div>
      </DialogActions>
      <QuizDifficultyDialog
        open={difficultyDialogOpen}
        onClose={() => setDifficultyDialogOpen(false)}
        quiz={quizData}
        onUpdateQuiz={handleDifficultyUpdate}
        showSnackbar={showSnackbar}
        theme={theme}
      />
    </Dialog>
  );
};

export default TeacherQuizDisplay;
