import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Divider,
  CircularProgress,
  Tooltip,
  FormControl,
  Select,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  MenuItem,
  Grid,
  TextField,
} from "@mui/material";

import RolePlayEditor from "./RolePlayEditor";
import SlideInPaper from "./SlideInPaper";
import { db } from "./firebase";
import Translate from "./Translate";
import ShareLink from "./ShareLink";
import ImageLoadingDialog from "./ImageLoadingDialog";
import PrintAsPdf from "./PrintAsPdf";
import {
  collection,
  query,
  where,
  limit,
  addDoc,
  updateDoc,
  getDocs,
  serverTimestamp,
} from "firebase/firestore";
import GenerateIcon from "@mui/icons-material/AutoAwesome";
import ImageIcon from "@mui/icons-material/Image";
import ShareDialog from "./ShareDialog";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import ShareIcon from "@mui/icons-material/Share";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import LoadingBackdrop from "./LoadingBackdrop";
import PrintIcon from "@mui/icons-material/Print";
import RolePlayDisplay from "./RolePlayDisplay";

import { callApi } from "./api";

import Logger from "./Logger";

import TextFieldsIcon from "@mui/icons-material/TextFields";

import LanguageSelector from "./LanguageSelector";
import ClearIcon from "@mui/icons-material/Clear";
import { ThemeSelectorFlow } from "./ThemeSelectorFlow";
import InfoBox from "./InfoBox";
import { handlePrintRoleplayLesson } from "./printFunctions";
import {
  generateAndProcessImage,
  handleImageGenerationError,
} from "./functions";
import { useSnackbar } from "./SnackbarHandler";
import ProgressBar from "./ProgressBar";

import ImageGenerationModal from "./ImageGenerationModal";
import GradeSelector, { gradeInstructions } from "./GradeSelector";
import { useTheme } from "@mui/material/styles";
import HistoryIcon from "@mui/icons-material/History";
import { getStyles } from "../index";
import { trackLesson } from "./functions";

import LessonDrawer from "./LessonDrawer";
import SaveLessonDialog from "./SaveLessonDialog";
import HoverableImage from "./HoverableImage";
import { getThemes } from "./additionalThemes/RolePlayLesson";
import { useSearchParams, useParams } from "react-router-dom";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import IconButton from "@mui/material/IconButton";

const RolePlayLesson = ({ user }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);
  const [open, setOpen] = useState(false);

  const lessonTitleRef = useRef(null); // Add this ref

  const [searchParams, setSearchParams] = useSearchParams();

  const [lessonTheme, setLessonTheme] = useState("");
  const [language, setLanguage] = useState("svenska");
  const [shareDialogOpenFriends, setShareDialogOpenFriends] = useState(false);
  const [grade, setGrade] = useState("åk6");
  const [progress, setProgress] = useState(0);
  const [isGeneratingLesson, setIsGeneratingLesson] = useState(false);
  const [isGeneratingImages, setIsGeneratingImages] = useState(false);

  const [customTheme, setCustomTheme] = useState("");
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageInstructions, setImageInstructions] = useState("");
  const [openAiInstructions, setOpenAiInstructions] = useState("");
  const [showLessonNotification, setShowLessonNotification] = useState(false); // New specific state

  const [loadingImageIndex, setLoadingImageIndex] = useState(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [isSharing, setIsSharing] = useState(false);
  const [predefinedTheme, setPredefinedTheme] = useState(null);
  const [lessonData, setLessonData] = useState("");
  const subject = searchParams.get("subject") || null;
  const viewMode = searchParams.get("v") === "t";
  const additionalThemes = getThemes(subject);

  const { showSnackbar } = useSnackbar();
  const theme = useTheme();
  const styles = getStyles(theme);

  const transcriptRef = useRef("");
  const uploadedFileRef = useRef("");

  const bookContentRef = useRef("");

  const [showControlPanel, setShowControlPanel] = useState(false);
  const translateButtonRef = useRef(null);
  const pdfButtonRef = useRef(null);
  const shareLinkButtonRef = useRef(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const wikipediaContentRef = useRef("");
  const urlContentRef = useRef("");
  const randomizedContentRef = useRef("");
  const coursePlanContentRef = useRef("");

  const t = {
    roleCards: user.language === "Svenska" ? "Rollkort" : "Role Cards",
    editMode: user.language === "Svenska" ? "Redigeringsläge" : "Edit Mode",
    studentMode: user.language === "Svenska" ? "Elevläge" : "Student Mode",
    saved: user.language === "Svenska" ? "Sparat" : "Saved",
    generateLesson:
      user.language === "Svenska" ? "Generera lektion" : "Generate lesson",
    generating: user.language === "Svenska" ? "Genererar..." : "Generating...",
    generatingProgress:
      user.language === "Svenska"
        ? "Genererar lektion — tar 30-60 sekunder..."
        : "Generating lesson — takes 30-60 seconds...",
    grade: user.language === "Svenska" ? "Årskurs:" : "Grade:",
    selectedLanguage: user.language === "Svenska" ? "Språk:" : "Language:",
    edit: user.language === "Svenska" ? "Redigera lektionen" : "Edit lesson",
    print: user.language === "Svenska" ? "Skriv ut" : "Print",
    saveToBank:
      user.language === "Svenska"
        ? "Spara i min lektionsbank"
        : "Save to my lesson bank",
    shareWithStudents:
      user.language === "Svenska" ? "Dela med elever" : "Share with students",
    generateImages:
      user.language === "Svenska" ? "Generera bilder" : "Generate images",
    lessonLoaded:
      user.language === "Svenska"
        ? "Lektionen har laddats in."
        : "Lektionen har laddats in.",
    lessonSaved:
      user.language === "Svenska"
        ? "Lektionen sparad i din lektionsbank!"
        : "Lesson saved in your lesson bank!",
    imagesGenerated:
      user.language === "Svenska"
        ? "Bilderna har genererats!"
        : "Images have been generated!",
    sharingLesson:
      user.language === "Svenska" ? "Delar lektionen..." : "Sharing lesson...",
    errorGenerating:
      user.language === "Svenska"
        ? "Ett fel uppstod när lektionen skulle genereras. Försök igen!"
        : "An error occurred while generating the lesson. Try again!",
  };

  useEffect(() => {
    Logger.log("lessonData: ", lessonData);
    const isDataValid =
      lessonData !== null &&
      lessonData !== undefined &&
      Object.keys(lessonData).length > 0 &&
      !Object.values(lessonData).includes("");

    if (isDataValid !== showControlPanel) {
      const timer = setTimeout(() => {
        setShowControlPanel(isDataValid);
      }, 100);

      return () => clearTimeout(timer);
    }
  }, [lessonData, showControlPanel]);

  useEffect(() => {
    Logger.log("lLANG: ", user.language);
  }, [user]);

  useEffect(() => {
    Logger.log("subject: ", subject);
  }, [subject]);
  useEffect(() => {
    Logger.log("lessonTheme: ", lessonTheme);
  }, [lessonTheme]);

  useEffect(() => {
    Logger.log("predefinedTheme: ", predefinedTheme);
  }, [predefinedTheme]);

  useEffect(() => {
    Logger.log("customTheme: ", customTheme);
  }, [customTheme]);

  useEffect(() => {
    Logger.log("youtubeTranscript: ", transcriptRef.current);
  }, [transcriptRef.current]);

  useEffect(() => {
    Logger.log("wikipediaContentRef: ", wikipediaContentRef.current);
  }, [wikipediaContentRef.current]);

  useEffect(() => {
    Logger.log("urlContentRef: ", urlContentRef.current);
  }, [urlContentRef.current]);

  useEffect(() => {
    Logger.log("uploadedFileRef: ", uploadedFileRef.current);
  }, [uploadedFileRef.current]);

  useEffect(() => {
    Logger.log("randomizedContentRef: ", randomizedContentRef.current);
  }, [randomizedContentRef.current]);

  useEffect(() => {
    Logger.log("coursePlanContentRef: ", coursePlanContentRef.current);
  }, [coursePlanContentRef.current]);

  const { lessonId } = useParams();
  useEffect(() => {
    const loadLessonFromId = async () => {
      if (lessonId) {
        try {
          const q = query(
            collection(db, "lesson_usage"),
            where("__name__", ">=", lessonId),
            where("__name__", "<=", lessonId + "\uf8ff"),
            where("lessonType", "==", "RolePlayLesson.js"),
            limit(1),
          );
          //In your loadLessonFromId function:
          // if (user.guest) {
          //   setTimeout(() => {
          //     setShowLessonNotification(true);
          //   }, 2000);
          // }
          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            const docData = querySnapshot.docs[0].data();

            if (!docData.hasOpened) {
              await updateDoc(querySnapshot.docs[0].ref, {
                hasOpened: true,
                openedAt: Date.now(),
              });
            }

            setLessonData(docData.data);

            // Scroll to lesson content
            setTimeout(() => {
              lessonTitleRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }, 100);

            showSnackbar(t.lessonLoaded, "success");
          } else {
            showSnackbar("Kunde inte hitta lektionen", "error");
          }
        } catch (error) {
          console.error("Error loading lesson:", error);
          showSnackbar("Ett fel uppstod när lektionen skulle laddas", "error");
        }
      }
    };

    loadLessonFromId();
  }, lessonId);

  const handleShare = async (
    lessonComment,
    lessonName,
    selectedImage,
    tags,
    shareData,
  ) => {
    setIsSharing(true);
    await new Promise((resolve) => setTimeout(resolve, 1500));
    // try {
    //   // Create a document for each student
    //   const sharePromises = shareData.studentIds.map((studentId) => {
    //     const documentData = {
    //       userId: user.uid,
    //       shared: true,
    //       sharedTo: studentId,
    //       comment: shareData.comment,
    //       sharedAt: Date.now(),
    //       fileUrl: shareData.fileUrl,
    //       lessonType: "RolePlayLesson.js",
    //       timestamp: serverTimestamp(),
    //       lessonComment,
    //       lessonName,
    //       coverImage: selectedImage,
    //       hasPrinted: true,
    //       data: lessonData,
    //     };
    //     Logger.log(
    //       "Adding document for student",
    //       studentId,
    //       ":",
    //       JSON.stringify(documentData, null, 2),
    //     );
    //     return addDoc(collection(db, "lesson_usage"), documentData);
    //   });
    //   await Promise.all(sharePromises);
    //   showSnackbar(`Du har nu delat lektionen!`, "success");
    // }

    try {
      // Create a document for each student and then create notifications
      const sharePromises = shareData.studentIds.map(async (studentId) => {
        const documentData = {
          userId: user.uid,
          shared: true,
          sharedTo: studentId,
          comment: shareData.comment,
          sharedAt: Date.now(),
          fileUrl: shareData.fileUrl,
          lessonType: "RolePlayLesson.js",
          timestamp: serverTimestamp(),
          lessonComment,
          lessonName,
          coverImage: selectedImage,
          hasPrinted: true,
          data: lessonData,
        };
        // Create lesson document and get ID
        const lessonDoc = await addDoc(
          collection(db, "lesson_usage"),
          documentData,
        );
        Logger.log(
          "Adding document for student",
          studentId,
          ":",
          JSON.stringify(documentData, null, 2),
        );
        // Create notification with lesson doc ID
        await addDoc(collection(db, "notifications"), {
          type: "lesson_shared",
          recipientId: studentId,
          senderId: user.uid,
          read: false,
          createdAt: serverTimestamp(),
          lessonData: {
            lessonId: lessonDoc.id,
            lessonType: documentData.lessonType,
            lessonName: documentData.lessonName,
            fileUrl: documentData.fileUrl,
            comment: documentData.comment,
            coverImage: selectedImage,
          },
        });
        return lessonDoc;
      });
      await Promise.all(sharePromises);
      showSnackbar(`Du har nu delat lektionen!`, "success");
    } catch (error) {
      showSnackbar("Fel vid delande av lektionen: " + error.message, "error");
      console.error(error);
    } finally {
      setIsSharing(false);
    }
  };

  const handleSaveLessonClick = () => {
    setSaveDialogOpen(true);
  };

  const handleSaveWithComment = async (comment, name, coverImage, tags) => {
    Logger.log(JSON.stringify(lessonData, null, 2));
    await trackLesson(
      user.uid,
      "RolePlayLesson.js",
      lessonData,
      null,
      true,
      comment,
      name,
      coverImage,
      tags,
    );
    setDrawerOpen(true);
    showSnackbar("Lektionen sparad i din lektionsbank!", "success");
  };

  const simulateProgress = (startProgress, endProgress, duration) => {
    const stepSize = 0.5;
    const steps = (endProgress - startProgress) / stepSize;
    const stepDuration = duration / steps;
    let currentProgress = startProgress;

    const intervalId = setInterval(() => {
      currentProgress += stepSize;
      if (currentProgress >= endProgress) {
        clearInterval(intervalId);
      } else {
        setProgress(currentProgress);
      }
    }, stepDuration);

    return () => clearInterval(intervalId);
  };

  const toggleViewMode = () => {
    const currentParams = Object.fromEntries(searchParams.entries());
    if (currentParams.v === "t") {
      delete currentParams.v;
    } else {
      currentParams.v = "t";
    }
    setSearchParams(currentParams);
  };

  const handleGenerateLesson = async () => {
    setProgress(0);
    let stopProgressSimulation = null;
    setIsGeneratingLesson(true);
    const gradeInstructionsText = gradeInstructions[grade];
    setLessonData(null);
    try {
      let themeToUse;

      if (lessonTheme === "youtube") {
        themeToUse = transcriptRef.current;
      } else if (lessonTheme === "customTheme") {
        themeToUse = customTheme;
      } else if (lessonTheme === "predefined") {
        themeToUse = predefinedTheme;
      } else if (lessonTheme === "fileUpload") {
        themeToUse = uploadedFileRef.current;
      } else if (lessonTheme === "wikipedia") {
        themeToUse = wikipediaContentRef.current;
      } else if (lessonTheme === "randomizer") {
        themeToUse = randomizedContentRef.current;
      } else if (lessonTheme === "book") {
        themeToUse = bookContentRef.current;
      } else if (lessonTheme === "url") {
        themeToUse = urlContentRef.current;
      } else {
        //
        themeToUse = coursePlanContentRef.current;
      }

      Logger.log("themeToUse: ", themeToUse);

      if (!themeToUse?.trim()) {
        showSnackbar("Du måste välja underlag i steg 1.", "warning");
        return;
      }

      stopProgressSimulation = simulateProgress(0, 90, 15000);

      let response = await callApi(
        [
          {
            role: "system",
            content: `You are an expert teacher creating role-play debate lessons in ${language}. Generate 4 distinct role cards and debate starters for classroom discussions. Follow the exact JSON structure provided in the prompt.`,
          },
          {
            role: "user",
            content: `
        - Theme: "${themeToUse}".
        - Amount of roleCards: EXACTLY 4 role cards with exciting role titles
        - Language: ${language}
        - Grade instructions: ${gradeInstructionsText}

        RESPOND IN THE FOLLOWING JSON FORMAT:
                
        {
          "title": "Title of the debate",
          "description": "description of the debate",
          "roles": [
            {
              "role": "{Role}",
              "description": "{A brief description of the role}",
              "responsibilities": [
                "{Responsibility 1}",
                "{Responsibility 2}",
                "{Responsibility 3}",
                "{Responsibility 4}"
              ],
              "arguments": [
                {
                  "headline": "{Argument 1 headline}",
                  "argument": "{Detailed argument 1}"
                },
                {
                  "headline": "{Argument 2 headline}",
                  "argument": "{Detailed argument 2}"
                },
                {
                  "headline": "{Argument 3 headline}",
                  "argument": "{Detailed argument 3}"
                }
              ],
              "conviction": "{One sentence about the conviction of the role}"
            },

          ],
          "debate_starters": [
            "{Debate starter question 1}",
            "{Debate starter question 2}",
            "{Debate starter question 3}",
            "{Debate starter question 4}",
            "{Debate starter question 5}",
          ]



        }
              `,
          },
        ],
        6000,
        true,
        user.uid,
      );

      if (stopProgressSimulation) stopProgressSimulation();
      setProgress(95);

      let messageContent = response.data.choices[0].message.content.trim();
      if (messageContent.startsWith("```json")) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith("```")) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      const data = JSON.parse(messageContent);

      const updatedData = {
        ...data,
        roles: data.roles.map((card, index) => ({
          ...card,
          imageUrl: null,
        })),
      };

      Logger.log("updatedData: ", JSON.stringify(updatedData, null, 2));
      setProgress(100);

      await new Promise((resolve) => setTimeout(resolve, 800));

      setLessonData(updatedData);

      await trackLesson(user.uid, "RolePlayLesson.js", null, {
        language: language,
        lessonTheme: lessonTheme,
      });

      showSnackbar("Din lektion har genererats!", "success");
    } catch (error) {
      console.error("Error generating lesson:", error);
      showSnackbar(
        "Ett fel uppstod när lektionen skulle genereras. Försök igen!",
        "error",
      );
    } finally {
      setIsGeneratingLesson(false);
    }
  };

  /// Image Generation code

  const handleGenerateImages = async () => {
    if (!lessonData) return;
    setIsGeneratingImages(true);
    handleCloseModal();
    try {
      const imagePromises = lessonData.roles.map((role) => {
        const imagePrompt = `Create an image showing a character who is a "${role.role}". The character should embody: "${role.description}". Context: This is for a debate about "${lessonData.title}". ${imageInstructions}`;
        return generateAndProcessImage(imagePrompt, user.uid);
      });
      const imageUrls = await Promise.all(imagePromises);
      setLessonData((prevData) => ({
        ...prevData,
        roles: prevData.roles.map((card, index) => ({
          ...card,
          imageUrl: imageUrls[index],
        })),
      }));
      showSnackbar("Bilderna har genererats!", "success");
    } catch (error) {
      handleImageGenerationError(error, true, showSnackbar);
    } finally {
      setIsGeneratingImages(false);
      setLoadingImageIndex(null);
    }
  };

  const handleGenerateSpecificImage = async (index) => {
    setIsGeneratingImages(true);
    setLoadingImageIndex(index);
    handleCloseModal();
    try {
      const role = lessonData.roles[index];
      const imagePrompt = `Create an image showing a character who is a "${role.role}". The character should embody: "${role.description}". Context: This is for a debate about "${lessonData.title}". ${imageInstructions}`;
      const imageUrl = await generateAndProcessImage(imagePrompt, user.uid);
      if (imageUrl) {
        setLessonData((prevData) => {
          const newRoles = [...prevData.roles];
          newRoles[index] = {
            ...newRoles[index],
            imageUrl: imageUrl,
          };
          return {
            ...prevData,
            roles: newRoles,
          };
        });
        showSnackbar("Bilden har genererats!", "success");
      }
    } catch (error) {
      handleImageGenerationError(error, false, showSnackbar);
    } finally {
      setIsGeneratingImages(false);
      setLoadingImageIndex(null);
    }
  };

  const handleImageButtonClick = (index = null) => {
    setSelectedImageIndex(index);
    setShowImageModal(true);
  };
  const handleCloseModal = () => {
    setShowImageModal(false);
    setImageInstructions("");
  };

  const handleGenerateWithInstructions = async () => {
    if (selectedImageIndex !== null) {
      handleCloseModal();
      await handleGenerateSpecificImage(selectedImageIndex);
    } else {
      handleCloseModal();
      await handleGenerateImages();
    }
  };

  return (
    <Box sx={styles.container}>
      <Box
        sx={{
          ...styles.innerContainer,
          width: { xs: "100%", md: "100%" },
          margin: "0 auto",
        }}
      >
        {!viewMode && (
          <Typography
            variant="h1"
            gutterBottom
            sx={{
              fontWeight: "bold",
              fontSize: "1.8em",
              mb: 3,
              mt: 5,
              textAlign: "center",
            }}
          >
            Rollkort
          </Typography>
        )}
        <Box
          sx={{
            ...styles.buttonsContainer,
            ...styles.additionalButtonsContainer,
          }}
        >
          {lessonData && user.accountType === "teacher" && (
            <Button
              variant="purp"
              sx={{ ...styles.tightButton }}
              disableRipple
              onClick={() => {
                setTimeout(() => {
                  lessonTitleRef.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "start", // This ensures it scrolls to the top of the element
                  });
                }, 100);
                toggleViewMode();
              }}
              startIcon={
                searchParams.get("v") === "t" ? (
                  <FullscreenExitIcon />
                ) : (
                  <FullscreenIcon />
                )
              }
            >
              {searchParams.get("v") === "t" ? "Redigeringsläge" : "Elevläge"}
            </Button>
          )}

          {!viewMode && (
            <Button
              variant="purp"
              sx={{ ...styles.tightButton }}
              onClick={() => setDrawerOpen(true)}
              startIcon={<BookmarkBorderIcon />}
            >
              Sparat
            </Button>
          )}
        </Box>
        <LessonDrawer
          userId={user.uid}
          lessonType={"RolePlayLesson.js"}
          theme={theme}
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          lessonIcon={TextFieldsIcon}
          onSelectLesson={(lesson) => {
            setLessonData(lesson.data);
            setDrawerOpen(false);
            setTimeout(() => {
              lessonTitleRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "start", // This ensures it scrolls to the top of the element
              });
            }, 100);
            showSnackbar(`Lektionen har laddats in.`, "success");
          }}
        />

        {!viewMode && (
          <>
            <InfoBox
              icon={AssignmentIndIcon}
              title="Rollkort"
              instructions={[
                "Välj underlag till rollkorten, t.ex. socialism vs kapitalism, förnybar energi vs fossila bränslen",
                "Redigera lektionen utifrån dina behov",
                "Dela med elever, spara eller skriv ut",
              ]}
              theme={theme}
              styles={styles}
            />

            <ThemeSelectorFlow
              key={subject}
              subject={subject}
              value={lessonTheme}
              onChange={(e) => setLessonTheme(e.target.value)}
              customTheme={customTheme}
              onCustomThemeChange={(e) => setCustomTheme(e.target.value)}
              onPredefinedThemeChange={(e) =>
                setPredefinedTheme(e.target.value)
              }
              onBookContentChange={(content) => {
                Logger.log("onBookContentChange", content.substring(0, 100));
                bookContentRef.current = content;
              }}
              onYoutubeTranscriptChange={(transcriptContent) => {
                Logger.log(
                  "onYoutubeTranscriptChange körs med: ",
                  transcriptContent,
                );
                transcriptRef.current = transcriptContent;
              }}
              onFileUpload={(content) => {
                Logger.log("onFileUpload körs med: ", content);
                uploadedFileRef.current = content;
              }}
              onWikipediaContentChange={(content) => {
                Logger.log(
                  "Parent: Wikipedia content received",
                  content.substring(0, 300),
                );
                wikipediaContentRef.current = content;
              }}
              onUrlContentChange={(content) => {
                Logger.log(
                  "Parent: URL content received",
                  content.substring(0, 400),
                );
                urlContentRef.current = content;
              }}
              onRandomizedContentChange={(content) => {
                Logger.log("randomizedContentRef", content.substring(0, 100));
                randomizedContentRef.current = content;
              }}
              onCoursePlanContentChange={(content) => {
                Logger.log("onCoursePlanChange", content.substring(0, 100));
                coursePlanContentRef.current = content;
              }}
              lessonType={"RolePlayLesson.js"}
              user={user}
              theme={theme}
              styles={styles}
              onLessonSelect={(lesson) => {
                Logger.log("onLessonSelect körs");
                setLessonData(lesson.data);
                setTimeout(() => {
                  lessonTitleRef.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "start", // This ensures it scrolls to the top of the element
                  });
                }, 100);
                showSnackbar("Lektionen har laddats in.", "success");
              }}
              additionalThemes={additionalThemes}
            />

            <Box sx={styles.headlineLessonBox}>
              <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
                2. Generera lektionen
              </Typography>
            </Box>
            <Box sx={{ ...styles.freshBox, padding: "40px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "500", mb: 1 }}
                  >
                    Årskurs:
                  </Typography>
                  <Box sx={{ mt: 0.5, mb: 3 }}>
                    <GradeSelector grade={grade} setGrade={setGrade} />
                  </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle1" sx={{ fontWeight: "500" }}>
                    Språk:
                  </Typography>
                  <Box sx={{ mt: 1, mb: 3 }}>
                    <LanguageSelector
                      language={language}
                      setLanguage={setLanguage}
                    />
                  </Box>
                </Grid>
              </Grid>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "12px",
                  width: "100%",
                }}
              >
                <Button
                  variant={
                    theme.palette.mode === "light" ? "purp" : "contained"
                  }
                  color="primary"
                  onClick={handleGenerateLesson}
                  disabled={isGeneratingLesson}
                  sx={{ mt: 4 }}
                  startIcon={
                    !isGeneratingLesson ? (
                      <GenerateIcon style={{ color: "#fff" }} />
                    ) : (
                      <CircularProgress style={{ color: "#fff" }} size={16} />
                    )
                  }
                >
                  {isGeneratingLesson ? "Genererar..." : "Generera lektion"}
                </Button>
              </div>
              {isGeneratingLesson && (
                <Box sx={{ mt: 6 }}>
                  <ProgressBar
                    progress={progress}
                    label="Genererar lektion — tar 30-60 sekunder..."
                  />
                </Box>
              )}
            </Box>
          </>
        )}
        {viewMode && lessonData && (
          <RolePlayDisplay
            lessonData={lessonData}
            onImageUpdate={(updatedData) => {
              setLessonData((prevData) => ({
                ...prevData,
                ...updatedData,
              }));
            }}
            userId={user.uid}
            showSnackbar={showSnackbar}
            theme={theme}
            styles={styles}
            isGeneratingImages={isGeneratingImages}
            loadingImageIndex={loadingImageIndex}
            handleImageButtonClick={handleImageButtonClick}
            viewMode={viewMode}
          />
        )}
        {lessonData && (
          <>
            {!viewMode && (
              <Box
                ref={lessonTitleRef}
                sx={{ ...styles.headlineLessonBox, mt: 10 }}
              >
                <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
                  3. Din lektion
                </Typography>
              </Box>
            )}

            <Box sx={{ ...styles.freshBox, padding: "30px" }}>
              {!viewMode && (
                <Box sx={{ position: "relative" }}>
                  <Box
                    sx={{
                      ...styles.lessonControlPanel,
                      ...styles.lessonControlPanelNuevo,

                      "& .MuiButton-root": {
                        ...styles.controlPanelButtons,
                        ...styles.lessonControlPanelNewButtons,
                      },
                    }}
                  >
                    <Button
                      variant="purp"
                      startIcon={<EditIcon />}
                      onClick={() => setOpen(true)}
                      sx={{ ml: 2 }}
                    >
                      Redigera lektionen
                    </Button>
                    <Button
                      variant="print"
                      startIcon={<SaveAsIcon />}
                      sx={{ mt: 2, ml: 2 }}
                      onClick={handleSaveLessonClick}
                    >
                      Spara i min lektionsbank
                    </Button>
                    <Button
                      variant="print"
                      startIcon={<PrintIcon />}
                      onClick={() => handlePrintRoleplayLesson(lessonData)}
                      sx={{ mt: 2 }}
                    >
                      Skriv ut
                    </Button>
                    <Button
                      variant="success"
                      sx={{}}
                      onClick={() => setShareDialogOpenFriends(true)}
                      startIcon={<ShareIcon />}
                    >
                      Dela med vänner
                    </Button>

                    <Button
                      variant="success"
                      sx={{
                        ml: 2,
                      }}
                      onClick={() => setShareDialogOpen(true)}
                      startIcon={<ShareIcon />}
                    >
                      Dela med elever
                    </Button>
                    <ShareLink
                      buttonRef={shareLinkButtonRef}
                      key={lessonId}
                      lessonId={lessonId}
                      lessonData={lessonData}
                      user={user}
                      lessonType={"RolePlayLesson.js"}
                      onCopy={(message, severity) =>
                        showSnackbar(message, severity)
                      }
                      sx={{ mt: 0 }}
                    />

                    <Translate
                      buttonRef={translateButtonRef}
                      lessonData={lessonData}
                      onTranslated={setLessonData}
                      callApi={callApi}
                      user={user}
                      showSnackbar={showSnackbar}
                    />

                    <PrintAsPdf
                      buttonRef={pdfButtonRef}
                      onPrint={() => handlePrintRoleplayLesson(lessonData)}
                      sx={{ ml: 2 }}
                    />

                    <Tooltip title="Hur lektionen ser ut för eleverna">
                      <Button
                        variant="success"
                        onClick={() => {
                          window.scrollTo({ top: 0, behavior: "smooth" });
                          toggleViewMode();
                        }}
                        sx={{ ml: 2 }}
                        startIcon={
                          searchParams.get("v") === "t" ? (
                            <FullscreenExitIcon />
                          ) : (
                            <FullscreenIcon />
                          )
                        }
                      >
                        {searchParams.get("v") === "t"
                          ? "Redigeringsläge"
                          : "Elevläge"}
                      </Button>
                    </Tooltip>

                    <Button
                      variant={
                        theme.palette.mode === "light" ? "purp" : "contained"
                      }
                      color="primary"
                      onClick={() => handleImageButtonClick(null)}
                      disabled={isGeneratingImages}
                      startIcon={
                        !isGeneratingImages ? (
                          <ImageIcon />
                        ) : (
                          <CircularProgress size={16} />
                        )
                      }
                    >
                      {isGeneratingImages ? "Genererar..." : "Generera bilder"}
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      ...styles.lessonControlPanelFade,
                    }}
                  />
                </Box>
              )}
              {!viewMode && (
                <RolePlayDisplay
                  lessonData={lessonData}
                  onImageUpdate={(updatedData) => {
                    setLessonData((prevData) => ({
                      ...prevData,
                      ...updatedData,
                    }));
                  }}
                  userId={user.uid}
                  showSnackbar={showSnackbar}
                  theme={theme}
                  styles={styles}
                  isGeneratingImages={isGeneratingImages}
                  loadingImageIndex={loadingImageIndex}
                  handleImageButtonClick={handleImageButtonClick}
                  viewMode={viewMode}
                />
              )}

              <RolePlayEditor
                open={open}
                onClose={() => setOpen(false)}
                lessonData={lessonData}
                onSave={(updatedData) => {
                  // Handle the save action with the updated data
                  setLessonData(updatedData);
                  setOpen(false);
                  showSnackbar("Ändringarna har sparats!", "success");
                }}
                userId={user.uid}
                showSnackbar={showSnackbar}
                theme={theme}
                isGeneratingImages={isGeneratingImages}
                loadingImageIndex={loadingImageIndex}
                handleImageButtonClick={handleImageButtonClick}
              />
              <SaveLessonDialog
                key={lessonData?.title}
                open={saveDialogOpen}
                onClose={() => setSaveDialogOpen(false)}
                onSave={handleSaveWithComment}
                theme={theme}
                lessonName={lessonData?.title || ""}
                lessonObject={lessonData}
                lessonType={"RolePlayLesson.js"}
              />

              <ImageGenerationModal
                open={showImageModal}
                onClose={handleCloseModal}
                onGenerate={handleGenerateWithInstructions}
                isGenerating={isGeneratingImages}
                instructions={imageInstructions}
                setInstructions={setImageInstructions}
                theme={theme}
              />

              <SlideInPaper
                isExpanded={isExpanded}
                onExpandedChange={setIsExpanded}
                lessonTitleRef={lessonTitleRef}
                viewMode={viewMode}
                onEdit={() => setOpen(true)}
                toggleViewMode={toggleViewMode}
                theme={theme}
                open={showControlPanel}
                user={user}
                onPrint={() => handlePrintRoleplayLesson(lessonData)}
                onTranslate={() => translateButtonRef.current?.click()}
                onSaveAsPdf={() => pdfButtonRef.current?.click()}
                onShareLink={() => shareLinkButtonRef.current?.click()}
                onShareWithStudents={() => setShareDialogOpen(true)}
                onShareWithFriends={() => setShareDialogOpenFriends(true)}
                onSaveToBank={() => setSaveDialogOpen(true)}
                onStudentMode={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                  toggleViewMode();
                }}
              />

              <ShareDialog
                open={shareDialogOpenFriends}
                onClose={() => setShareDialogOpenFriends(false)}
                onShare={handleShare}
                user={user}
                isLessonSharing={true}
                theme={theme}
                lessonName={lessonData?.title || ""}
                lessonObject={lessonData}
                lessonType={"RolePlayLesson.js"}
                shareWithFriends={true}
              />

              <ShareDialog
                open={shareDialogOpen}
                onClose={() => setShareDialogOpen(false)}
                onShare={handleShare}
                user={user}
                isLessonSharing={true}
                theme={theme}
                lessonName={lessonData?.title || ""}
                lessonObject={lessonData}
                lessonType={"RolePlayLesson.js"}
              />
              <LoadingBackdrop
                isOpen={isSharing}
                imagePath="/img/AI.png"
                loadingText={"Delar lektionen..."}
              />
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default RolePlayLesson;
