import React from "react";
import { Drawer, Box, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const OutputHistory = ({ open, onClose, student }) => {
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { width: { xs: "100%", sm: 600 } },
      }}
    >
      <Box sx={{ p: 3 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 3,
          }}
        >
          <Typography variant="h6" component="h2">
            Historik för genererat innehåll för {student?.displayName}
          </Typography>
          <IconButton onClick={onClose} edge="end">
            <CloseIcon />
          </IconButton>
        </Box>

        <Box>
          <Typography
            variant="body1"
            sx={{
              textAlign: "left",
              color: "text.secondary",
            }}
          >
            Vi jobbar på att du som lärare ska kunna se alla interaktioner från
            varje elev. Ha tålamod. Funktionen kommer strax! :)
          </Typography>
        </Box>
      </Box>
    </Drawer>
  );
};

export default OutputHistory;
