import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  ListItem,
  Button,
  ListItemText,
  IconButton,
  Card,
  CardContent,
  Paper,
  Chip,
  ListItemIcon, // Ensure ListItemIcon is imported if used
} from "@mui/material";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CustomInputSwitch from "./app/CustomInputSwitch"; // Add this import at the top with other imports
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import StartPageFAQSection from "./StartPageFAQSection";
import { East as ArrowIcon } from "@mui/icons-material";
import StarIcon from "@mui/icons-material/Star";
import CheckIcon from "@mui/icons-material/Check";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import { Helmet } from "react-helmet-async";
import { Link as MuiLink } from "@mui/material";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
// **Newly Added Icon Imports**
import { features } from "./app/functions";

import { Link } from "react-router-dom";
import { TypeAnimation } from "react-type-animation";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { motion } from "framer-motion";
import { getAIModelImage } from "./app/TestComponents2";

import UniversitySection from "./UniversitySection";
import MediaSection from "./MediaSection";
import FAQSection from "./FAQSection";
import Logger from "./app/Logger";
import TestimonialsSection from "./TestimonialsSection";
import {
  GradientBackground,
  StyledButton,
  FeatureSection,
  FeatureList,
  ResponsiveVideo,
} from "./StyledComponents";

import ContactForm from "./ContactForm";

import { getStyles } from "./index";

import Header from "./Header";
import Footer from "./Footer";

const instructionItems = [
  "Generera interaktiva prov/quiz",
  "Generera flashcards",
  "Låt AI bedöma din text",
  "Lär dig språk med AI",
  "Stöd för över 50 språk",
  "Trygg och säker hantering av personlig integritet och GDPR",
];

const InstructionItem = ({ text }) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      mb: 1.5,
    }}
  >
    <Box
      sx={{
        width: 24,
        height: 24,
        borderRadius: "50%",
        backgroundColor: "rgba(255, 255, 255, 0.1)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        mr: 2,
      }}
    >
      <CheckIcon sx={{ fontSize: 16, color: "white" }} />
    </Box>
    <Typography
      sx={{
        color: "rgba(255, 255, 255, 0.8)",
        fontSize: "1.1rem",
        lineHeight: 1.5,
        fontWeight: 500,
      }}
    >
      {text}
    </Typography>
  </Box>
);

const StudentsPage = ({ toggleDarkMode, isDark }) => {
  const theme = useTheme();
  const styles = getStyles(theme);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedUserType, setSelectedUserType] = useState(1);

  const contactFormRef = useRef(null); // Add ref for ContactForm

  const scrollToContact = (e) => {
    e.preventDefault();
    contactFormRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <Helmet>
        <title>{`AI-verktyg för elever | Studera.ai`}</title>
        <meta
          name="description"
          content="Hitta alla AI-verktyg för elever. Spara oceaner av tid genom att använda AI i skolan."
        />
      </Helmet>

      <Header />

      <GradientBackground className="gradient-background">
        <Container
          maxWidth="xl"
          sx={{ pt: { xs: 10, md: 20 }, pb: 15, position: "relative" }}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={6}>
              <Box sx={{ pl: { xs: 0, md: 4 } }}>
                <motion.div
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8 }}
                >
                  <Box sx={{ display: "flex", mb: 2 }}>
                    <Chip
                      label={
                        <span>
                          Studera.ai{" "}
                          <span style={{ opacity: 0.7 }}>
                            &nbsp;•&nbsp; Revolutionerar lärande med AI
                          </span>
                        </span>
                      }
                      sx={{
                        backgroundColor: "rgba(255, 255, 255, 0.1)",
                        color: "white",
                        fontWeight: "600",
                        fontSize: "0.8rem",
                        padding: "2px 14px",

                        borderRadius: "16px",
                        "& .MuiChip-label": {
                          padding: "0 4px",
                        },
                      }}
                    />
                  </Box>

                  <Typography
                    variant="h2"
                    component="h1"
                    gutterBottom
                    sx={{
                      fontWeight: 900,
                      letterSpacing: -1,
                      fontSize: { xs: "2.5rem", md: "3.35rem" },
                      mb: 5,
                    }}
                  >
                    AI-verktyg för elever
                  </Typography>
                  <Box sx={{ mt: 4, mb: 0 }}>
                    {instructionItems.map((item, index) => (
                      <InstructionItem key={index} text={item} />
                    ))}
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      gap: 2,
                      flexDirection: { xs: "column", md: "row" },
                    }}
                  >
                    <Link
                      to="/app/skapa-konto?ref=startpage"
                      style={{ textDecoration: "none" }}
                    >
                      <StyledButton
                        variant="blue"
                        color="primary"
                        size="large"
                        sx={{
                          borderRadius: "12px",
                          padding: "13px 50px",
                          fontSize: "20px",
                          width: "100%",
                          mt: 4,
                        }}
                      >
                        Skapa konto gratis <ArrowIcon sx={{ ml: 1 }} />
                      </StyledButton>
                    </Link>

                    <Link
                      to="/app/logga-in?ref=startpage"
                      style={{ textDecoration: "none" }}
                    >
                      <StyledButton
                        variant="blue"
                        color="primary"
                        size="large"
                        sx={{
                          borderRadius: "12px",
                          padding: "13px 50px",
                          fontSize: "20px",
                          width: "100%",
                          mt: { xs: 0, md: 4 },
                        }}
                      >
                        Logga in <ArrowIcon sx={{ ml: 1 }} />
                      </StyledButton>
                    </Link>
                  </Box>
                </motion.div>
              </Box>
            </Grid>

            {/* Image on the right */}
            <Grid
              item
              xs={12}
              md={6}
              sx={{ position: "relative" }}
              sx={{ display: { xs: "none", md: "block" } }}
            >
              <motion.div
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.8, delay: 0.2 }}
              >
                <Link
                  to="/app/skapa-konto?ref=startpage"
                  style={{ textDecoration: "none" }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      width: "calc(100% + 61px)",
                      padding: "25px",
                      background:
                        "linear-gradient(135deg, rgba(85, 64, 196, 0.7), rgba(64, 132, 196, 0.7))", // Added opacity of 0.7
                      borderRadius: "23px",
                    }}
                  >
                    <Box
                      component="img"
                      src={
                        theme.palette.mode === "dark"
                          ? "/img/hero-student.png"
                          : "/img/hero-student.png"
                      }
                      alt="Studera med AI"
                      sx={{
                        width: "100%",
                        height: "auto",
                        borderRadius: "15px",
                        display: "block",
                      }}
                    />
                  </Box>
                </Link>
              </motion.div>
            </Grid>
          </Grid>
        </Container>
      </GradientBackground>

      {/*
      <GradientBackground className='gradient-background'>
        <Container
          sx={{ pt: { xs: 10, md: 20 }, pb: 15, position: 'relative', textAlign: 'center' }}
        >
          <Grid container spacing={6} alignItems='center'>
            <Grid item xs={12} md={12} sx={{ textAlign: 'center' }}>
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                  <Chip
                    label={
                      <span>
                        Studera.ai{' '}
                        <span style={{ opacity: 0.7 }}>
                          &nbsp;•&nbsp; Revolutionerar lärande med AI
                        </span>
                      </span>
                    }
                    sx={{
                      backgroundColor: 'rgba(255, 255, 255, 0.1)',
                      color: 'white',
                      fontWeight: '600',
                      fontSize: '0.8rem',
                      padding: '2px 14px',

                      borderRadius: '16px',
                      '& .MuiChip-label': {
                        padding: '0 4px',
                      },
                    }}
                  />
                </Box>

                <Typography
                  variant='h2'
                  component='h1'
                  gutterBottom
                  sx={{
                    fontWeight: 900,
                    letterSpacing: -1,
                    fontSize: { xs: '2.5rem', md: '3.35rem' },
                    width: { xs: '100%', md: '70%' },
                    textAlign: 'center',
                    display: 'block',
                    margin: '0 auto',
                  }}
                >
                  AI-verktyg för elever
                </Typography>
                <Typography
                  variant='h5'
                  sx={{
                    mb: 4,
                    opacity: 0.8,
                    fontSize: '1.3rem',
                    width: { xs: '100%', md: '70%' },
                    margin: '0 auto',
                    fontWeight: '500',
                    lineHeight: '31px',
                    display: 'block',
                    mt: 4,
                  }}
                >
                  Med Studera.ai får du tillgång till en helt ny värld av AI-verktyg som är
                  anpassade för att hjälpa elever att maximera sina studieresultat. Vårt mål är att
                  ge dig stöd genom hela din utbildning, från grundläggande frågor till komplex
                  problemlösning. <br />
                  <br />
                  Vi vet att utbildning handlar om mer än att bara minnas fakta – det handlar om att
                  förstå och kunna tillämpa kunskap. Därför erbjuder vi AI-lösningar som hjälper dig
                  att analysera, förklara och bygga upp förståelse på ett engagerande och
                  strukturerat sätt.
                </Typography>

                <Box sx={{ textAlign: 'center', mt: 7 }}>
                  <Link to='/app' style={{ textDecoration: 'none' }}>
                    <StyledButton
                      variant='contained'
                      color='primary'
                      size='large'
                      endIcon={<KeyboardDoubleArrowRightIcon />}
                      sx={{ borderRadius: '28px', padding: '13px 50px', fontSize: '20px' }}
                    >
                      Testa Studera.ai nu
                    </StyledButton>
                  </Link>
                </Box>
              </motion.div>
            </Grid>
          </Grid>
        </Container>
      </GradientBackground>
*/}

      <Box
        sx={{ bgcolor: "background.default", py: 8, mt: 8, display: "none" }}
      >
        <Container maxWidth="lg">
          <Grid
            container
            spacing={10}
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              item
              xs={12}
              md={10}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h3"
                align="center"
                sx={{
                  fontWeight: "bold",
                  fontSize: "2.3em",
                  mb: 2,
                }}
              >
                Vanliga frågor och svar
              </Typography>

              <Typography
                variant="h6"
                paragraph
                color="text.secondary"
                sx={{ textAlign: "center", mb: 3 }}
              >
                Om du har några andra frågor — vänligen{" "}
                <MuiLink
                  onClick={scrollToContact}
                  sx={{
                    color: "text.secondary",
                    textDecoration: "none",
                    cursor: "pointer",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  kontakta oss
                </MuiLink>
              </Typography>

              <Box sx={{ width: "100%" }}>
                <StartPageFAQSection student={true} />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box
        sx={{
          bgcolor: "background.paper",
          py: 8,
          paddingTop: "100px",
          position: "relative",
          display: "none",
        }}
      >
        <Container maxWidth="lg">
          <Typography
            variant="h2"
            align="center"
            sx={{ fontWeight: "bold", fontSize: "2.3em", mb: 2 }}
          >
            Upptäck möjligheterna med Studera.ai
          </Typography>
          <Typography
            variant="subtitle1"
            align="center"
            color="text.secondary"
            sx={{ mb: 4 }}
          >
            Utforska några av de populäraste funktionerna
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <KeyboardDoubleArrowDownIcon
              sx={{
                fontSize: "4rem",
                color: "#6424c1",
                animation: "bounce 2s infinite",
              }}
            />
          </Box>
        </Container>

        <style>
          {`
      @keyframes bounce {
        0%, 20%, 50%, 80%, 100% {
          transform: translateY(0);
        }
        40% {
          transform: translateY(-30px);
        }
        60% {
          transform: translateY(-15px);
        }
      }
    `}
        </style>
      </Box>

      {features
        .filter(
          (feature) =>
            !feature.hideOnStartpage &&
            (feature.showFor
              ? feature.showFor.includes(
                  selectedUserType === 0 ? "teacher" : "student",
                )
              : selectedUserType === 0
                ? feature.teacher
                : !feature.teacher), // fallback to old logic if showFor doesn't exist
        )
        .map((feature, index) => {
          const hasModels = Boolean(
            feature.models && feature.models.length > 0,
          );
          const isImageRight = index % 2 === 0;

          return (
            <FeatureSection
              key={index}
              id={feature.title.toLowerCase().replace(/ /g, "-")}
              sx={{
                bgcolor:
                  index % 2 === 0 ? "background.default" : "background.paper",
              }}
            >
              <Container maxWidth="xl" sx={{ overflow: "hidden" }}>
                <Grid
                  container
                  spacing={0}
                  alignItems="center"
                  direction={isImageRight ? "row" : "row-reverse"}
                  sx={{
                    paddingTop: "80px",
                    paddingBottom: "80px",
                    paddingLeft: isImageRight ? "30px" : "0px",
                  }}
                >
                  {/* Text Content */}
                  <Grid
                    item
                    xs={12}
                    md={hasModels ? 12 : 6}
                    sx={{
                      textAlign: hasModels ? "center" : "left",
                    }}
                  >
                    <Typography
                      variant="h3"
                      gutterBottom
                      sx={{
                        fontWeight: "bold",
                        fontSize: { xs: "1.6em", md: "2.3em" },
                        textAlign: hasModels ? "center" : "left",
                      }}
                    >
                      {feature.title}
                    </Typography>
                    <Typography
                      variant="h6"
                      paragraph
                      color="text.secondary"
                      sx={{
                        textAlign: hasModels ? "center" : "left",
                      }}
                    >
                      {feature.description}
                    </Typography>

                    {hasModels ? (
                      // If feature has models, render them
                      <Grid
                        container
                        spacing={2}
                        justifyContent="center"
                        sx={{ marginTop: hasModels ? "20px" : "inherit" }}
                      >
                        {feature.models.map((model, idx) => (
                          <Grid item xs={12} sm={6} md={4} key={idx}>
                            <Card
                              sx={{
                                height: "100%",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                padding: 2,
                                boxShadow: "none",
                                transition: "transform 0.3s, box-shadow 0.3s",
                                "&:hover": {
                                  transform: "translateY(-10px)",
                                },
                              }}
                            >
                              <CardContent sx={{ textAlign: "center" }}>
                                <Box
                                  component="img"
                                  src={getAIModelImage(model.name)}
                                  alt={model.name}
                                  sx={{
                                    width: 60,
                                    height: 60,
                                    mb: 2,
                                    borderRadius: "9px",
                                  }}
                                />
                                <Typography variant="h6" gutterBottom>
                                  {model.name}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  {model.description}
                                </Typography>
                              </CardContent>
                            </Card>
                          </Grid>
                        ))}
                      </Grid>
                    ) : (
                      // If feature has details, render them
                      <FeatureList
                        sx={{
                          backgroundColor:
                            theme.palette.mode === "dark"
                              ? "transparent"
                              : "inherit",
                        }}
                      >
                        {feature.details
                          .filter((detail) => detail.showInFeaturePage !== true)
                          .map((detail, idx) => (
                            <ListItem
                              key={idx}
                              alignItems="flex-start"
                              sx={{ marginLeft: "0px", paddingLeft: "0px" }}
                            >
                              <ListItemIcon
                                sx={{
                                  "& > svg": {
                                    fontSize: "34px",
                                    color:
                                      theme.palette.mode === "dark"
                                        ? "#9575F7"
                                        : "#6424c1",
                                  },
                                  minWidth: "40px",
                                  marginRight: "15px",
                                  marginLeft: "0px",
                                }}
                              >
                                {detail.icon}
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  <Typography
                                    sx={{
                                      fontWeight: "600",
                                      fontSize: "1.2rem",
                                    }}
                                  >
                                    {detail.text.split(":")[0]}
                                  </Typography>
                                }
                                secondary={
                                  <Typography
                                    sx={{
                                      fontSize: "1rem",
                                      fontWeight: "500",
                                      mt: 0.5,
                                      color:
                                        theme.palette.mode === "dark"
                                          ? "#8899a6"
                                          : "gray",
                                    }}
                                  >
                                    {detail.text.split(":")[1]}
                                  </Typography>
                                }
                              />
                            </ListItem>
                          ))}
                      </FeatureList>
                    )}

                    <Box
                      mt={4}
                      display="flex"
                      justifyContent={hasModels ? "center" : "flex-start"}
                      sx={{ display: "none" }}
                    >
                      <Link
                        to={
                          feature.goDirectlyToApp
                            ? "/app/skapa-konto?ref=startpage"
                            : feature.customLink
                              ? `/funktioner/${feature.customLink}`
                              : `/funktioner/${feature.title.toLowerCase().replace(/ /g, "-").replace(/å/g, "a").replace(/ä/g, "a").replace(/ö/g, "o")}`
                        }
                        style={{ textDecoration: "none" }}
                      >
                        <Button variant="purp" endIcon={<ArrowForwardIcon />}>
                          Läs mer
                        </Button>
                      </Link>
                    </Box>
                  </Grid>

                  {/* Image or Video Content */}
                  {!hasModels && (
                    <Grid
                      item
                      xs={12}
                      md={6}
                      sx={{
                        position: "relative",
                        display: { xs: "none", md: "block" },
                      }}
                    >
                      {feature.video ? (
                        // If feature has a video, render the video
                        <Paper
                          elevation={3}
                          sx={{
                            borderRadius: 2,
                            overflow: "hidden",
                            background: "none",
                            backgroundImage: "none",
                            boxShadow: "none",
                          }}
                        >
                          <ResponsiveVideo
                            light={feature.video.light}
                            dark={feature.video.dark}
                            theme={theme}
                            alt={`${feature.title} demonstration`}
                          />
                        </Paper>
                      ) : feature.img ? (
                        // If feature has an image, render the image
                        <motion.div
                          initial={{ opacity: 0, scale: 0.8 }}
                          animate={{ opacity: 1, scale: 1 }}
                          transition={{ duration: 0.8, delay: 0.2 }}
                        >
                          <Link
                            to="/app/skapa-konto?ref=startpage"
                            style={{ textDecoration: "none" }}
                          >
                            <Box
                              sx={{
                                position: "relative",
                                width: "calc(100% + 35px)",
                                padding: "25px", // This creates space for our border

                                background:
                                  "linear-gradient(135deg, rgba(85, 64, 196, 0.6), rgba(64, 132, 196, 0.6))",
                                borderRadius: "23px",
                                ...(isImageRight
                                  ? { right: "-58px", mr: "-35px" }
                                  : { left: "-58px", ml: "-35px" }),
                              }}
                            >
                              <Box
                                component="img"
                                src={
                                  theme.palette.mode === "dark"
                                    ? feature.img.dark
                                    : feature.img.light
                                }
                                alt={`${feature.title}`}
                                sx={{
                                  width: "100%",
                                  height: "auto",
                                  borderRadius: "15px",
                                  display: "block",
                                }}
                              />
                            </Box>
                          </Link>
                        </motion.div>
                      ) : null}
                    </Grid>
                  )}
                </Grid>
              </Container>
            </FeatureSection>
          );
        })}

      <Container
        maxWidth="xl"
        sx={{
          paddingTop: 22,
          paddingBottom: 22,
          textAlign: "center",

          backgroundImage:
            'url("/img/hepp.svg"), radial-gradient(circle, rgb(106, 80, 243), rgb(62, 46, 141) 82%)',
        }}
      >
        <Typography
          variant="h3"
          gutterBottom
          sx={{
            fontWeight: "bold",
            fontSize: "2.6em",
            color: "white",
            display: "block",
            mb: 3,
          }}
        >
          Använd helt gratis
        </Typography>
        <Typography variant="h6" sx={{ mb: 4, color: "#E7E7E8" }}>
          Anslut dig till 3000+ lärare och elever som använder Studera.ai
        </Typography>
        <Link
          to="/app/skapa-konto?ref=startpage"
          style={{ textDecoration: "none" }}
        >
          <StyledButton
            variant="blue"
            color="primary"
            size="large"
            endIcon={<KeyboardDoubleArrowRightIcon />}
            sx={{
              borderRadius: "12px",
              padding: "13px 50px",
              fontSize: "20px",
            }}
          >
            Skapa konto helt gratis
          </StyledButton>
        </Link>
      </Container>
      <div ref={contactFormRef}>
        <ContactForm />
      </div>

      <TestimonialsSection />
      <Box sx={{ bgcolor: "background.default", py: 8, mt: 8 }}>
        <Container maxWidth="lg">
          <Grid
            container
            spacing={10}
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              item
              xs={12}
              md={10}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h3"
                align="center"
                sx={{
                  fontWeight: "bold",
                  fontSize: "2.3em",
                  mb: 2,
                }}
              >
                Vanliga frågor och svar
              </Typography>

              <Typography
                variant="h6"
                paragraph
                color="text.secondary"
                sx={{ textAlign: "center", mb: 3 }}
              >
                Om du har några andra frågor — vänligen{" "}
                <MuiLink
                  onClick={scrollToContact}
                  sx={{
                    color: "text.secondary",
                    textDecoration: "none",
                    cursor: "pointer",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  kontakta oss
                </MuiLink>
              </Typography>

              <Box sx={{ width: "100%" }}>
                <StartPageFAQSection student={true} />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <TestimonialsSection />

      <UniversitySection bgcolor="background.default" showHeadline={true} />

      <MediaSection bgcolor="background.default" />

      <Footer />

      {!isMobile && (
        <Box
          sx={{
            position: "fixed",
            bottom: 16,
            right: 16,
            zIndex: 9999,
          }}
        >
          <IconButton onClick={toggleDarkMode}>
            {isDark ? <LightModeIcon /> : <DarkModeIcon />}
          </IconButton>
        </Box>
      )}
    </>
  );
};

export default StudentsPage;
