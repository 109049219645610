import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  LinearProgress,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ShuffleIcon from "@mui/icons-material/Shuffle";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { lighten } from "@mui/material/styles";

const Match = ({ flashcards, onComplete, isDark }) => {
  const theme = useTheme();
  const [fronts, setFronts] = useState([]);
  const [backs, setBacks] = useState([]);
  const [selectedFront, setSelectedFront] = useState(null);
  const [selectedBack, setSelectedBack] = useState(null);
  const [matchedPairs, setMatchedPairs] = useState([]);
  const [showCompleteDialog, setShowCompleteDialog] = useState(false);
  const [moves, setMoves] = useState(0);
  const [gameStartTime, setGameStartTime] = useState(null);
  const [gameEndTime, setGameEndTime] = useState(null);

  useEffect(() => {
    initializeGame();
  }, [flashcards]);

  const initializeGame = () => {
    const shuffledFronts = [...flashcards].sort(() => Math.random() - 0.5);
    const shuffledBacks = [...flashcards].sort(() => Math.random() - 0.5);

    setFronts(shuffledFronts);
    setBacks(shuffledBacks);
    setSelectedFront(null);
    setSelectedBack(null);
    setMatchedPairs([]);
    setMoves(0);
    setGameStartTime(Date.now());
    setGameEndTime(null);
  };

  const handleCardClick = (card, isFront) => {
    if (isFront) {
      if (selectedFront?.front === card.front) {
        setSelectedFront(null);
      } else {
        setSelectedFront(card);
      }
    } else {
      if (selectedBack?.back === card.back) {
        setSelectedBack(null);
      } else {
        setSelectedBack(card);
      }
    }
  };

  useEffect(() => {
    if (selectedFront && selectedBack) {
      setMoves(moves + 1);

      if (
        selectedFront.front ===
        flashcards.find((f) => f.back === selectedBack.back)?.front
      ) {
        // Match found
        setMatchedPairs([...matchedPairs, selectedFront.front]);
      }

      // Reset selections after a brief delay
      setTimeout(() => {
        setSelectedFront(null);
        setSelectedBack(null);
      }, 1000);
    }
  }, [selectedFront, selectedBack]);

  useEffect(() => {
    if (matchedPairs.length === flashcards.length) {
      setGameEndTime(Date.now());
      setShowCompleteDialog(true);
      if (onComplete) {
        onComplete({ moves, time: (Date.now() - gameStartTime) / 1000 });
      }
    }
  }, [matchedPairs]);

  const formatTime = (ms) => {
    const seconds = Math.floor(ms / 1000);
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  const getCardStyle = (card, isSelected, isMatched, isFront) => ({
    height: "100%",
    cursor: isMatched ? "default" : "pointer",
    backgroundColor: isMatched
      ? isDark
        ? "#1a2e3d"
        : "#e8f5e9"
      : isSelected
        ? isDark
          ? "#2a3b4c"
          : "#e3f2fd"
        : isDark
          ? "#15202B"
          : "#fff",
    border: isMatched
      ? `2px solid ${theme.palette.success.main}`
      : isSelected
        ? `2px solid ${theme.palette.primary.main}`
        : `1px solid ${theme.palette.divider}`,
    transition: "all 0.3s ease",
    "&:hover": {
      transform: isMatched ? "none" : "translateY(-2px)",
      boxShadow: isMatched ? "none" : "0 4px 8px rgba(0,0,0,0.1)",
    },
  });

  return (
    <Box sx={{ width: "100%", p: 2 }}>
      <Box
        sx={{
          mb: 3,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: 600 }}>
          Matching Game
        </Typography>
        <Box>
          <Button
            variant="outlined"
            startIcon={<ShuffleIcon />}
            onClick={initializeGame}
            sx={{ mr: 1 }}
          >
            Shuffle
          </Button>
          <Button
            variant="outlined"
            startIcon={<RestartAltIcon />}
            onClick={initializeGame}
          >
            Reset
          </Button>
        </Box>
      </Box>

      <Box sx={{ mb: 2 }}>
        <LinearProgress
          variant="determinate"
          value={(matchedPairs.length / flashcards.length) * 100}
          sx={{
            height: 8,
            borderRadius: 5,
            bgcolor: (theme) => lighten(theme.palette.primary.main, 0.8),
            "& .MuiLinearProgress-bar": {
              borderRadius: 5,
            },
          }}
        />
      </Box>

      <Box sx={{ mb: 2, display: "flex", justifyContent: "space-between" }}>
        <Typography variant="body1">Moves: {moves}</Typography>
        <Typography variant="body1">
          Time:{" "}
          {formatTime(
            gameEndTime
              ? gameEndTime - gameStartTime
              : Date.now() - gameStartTime,
          )}
        </Typography>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography
            variant="h6"
            sx={{ mb: 2, color: theme.palette.primary.main }}
          >
            Questions
          </Typography>
          <Grid container spacing={2}>
            {fronts.map((card, index) => (
              <Grid item xs={12} key={index}>
                <Card
                  sx={getCardStyle(
                    card,
                    selectedFront?.front === card.front,
                    matchedPairs.includes(card.front),
                    true,
                  )}
                  onClick={() =>
                    !matchedPairs.includes(card.front) &&
                    handleCardClick(card, true)
                  }
                >
                  <CardContent>
                    <Typography variant="body1">
                      {card.front}
                      {matchedPairs.includes(card.front) && (
                        <CheckCircleIcon
                          sx={{ ml: 1, color: theme.palette.success.main }}
                        />
                      )}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography
            variant="h6"
            sx={{ mb: 2, color: theme.palette.secondary.main }}
          >
            Answers
          </Typography>
          <Grid container spacing={2}>
            {backs.map((card, index) => (
              <Grid item xs={12} key={index}>
                <Card
                  sx={getCardStyle(
                    card,
                    selectedBack?.back === card.back,
                    matchedPairs.includes(
                      flashcards.find((f) => f.back === card.back)?.front,
                    ),
                    false,
                  )}
                  onClick={() =>
                    !matchedPairs.includes(
                      flashcards.find((f) => f.back === card.back)?.front,
                    ) && handleCardClick(card, false)
                  }
                >
                  <CardContent>
                    <Typography variant="body1">
                      {card.back}
                      {matchedPairs.includes(
                        flashcards.find((f) => f.back === card.back)?.front,
                      ) && (
                        <CheckCircleIcon
                          sx={{ ml: 1, color: theme.palette.success.main }}
                        />
                      )}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>

      <Dialog
        open={showCompleteDialog}
        onClose={() => setShowCompleteDialog(false)}
      >
        <DialogTitle>Congratulations! 🎉</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            You completed the matching game in:
          </Typography>
          <Typography variant="h6" sx={{ mt: 1 }}>
            {moves} moves and {formatTime(gameEndTime - gameStartTime)}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowCompleteDialog(false)}>Close</Button>
          <Button onClick={initializeGame} variant="contained" color="primary">
            Play Again
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Match;
