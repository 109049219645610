import React from "react";
import {
  Box,
  Container,
  Typography,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import { Card, CardContent, CardHeader, Avatar } from "@mui/material";
import QuoteIcon from "@mui/icons-material/FormatQuote";

const TestimonialsSection = ({ source = "" }) => {
  const theme = useTheme();

  const grejTestimonials = [
    {
      name: "Maria Andersson",
      role: "Lärare i Historia",
      feedback:
        "Att skapa en ny Grej of the day på 2 minuter med AI har förändrat mitt sätt att introducera nya historiska ämnen. Eleverna är helt fascinerade!",
      avatar: "/avatars/maria.jpg",
    },
    {
      name: "Peter Nilsson",
      role: "Grundskollärare",
      feedback:
        'AI-genererade presentationer sparar mig timmar av förberedelser. Mina elever ser fram emot varje ny "Grej" vi utforskar tillsammans.',
      avatar: "/avatars/peter.jpg",
    },
    {
      name: "Sofia Berg",
      role: "Specialpedagog",
      feedback:
        "Grej of the Day-formatet är perfekt för att fånga uppmärksamheten hos elever med olika inlärningsstilar. Bilderna och berättelserna gör lärandet levande.",
      avatar: "/avatars/sofia.jpg",
    },
  ];

  const teacherTestimonials = [
    {
      name: "Anna Ekström",
      role: "Lärare i Historia",
      feedback:
        "Att kunna skapa lektioner på 60 sekunder med Studera.ai har förändrat mitt arbetssätt drastiskt. Jag har nu tid över att lägga på individuella elever!",
      avatar: "/avatars/maria.jpg",
    },
    {
      name: "Stefan Fredriksson",
      role: "Grundskollärare",
      feedback:
        "AI-genererade presentationer sparar mig timmar av förberedelser, och att man snabbt kan skapa målarbilder är fantastiskt.",
      avatar: "/avatars/peter.jpg",
    },
    {
      name: "Sofia Andersson",
      role: "Pedagog",
      feedback:
        "Att man kan generera över 70 olika lektioner med AI är makalöst! När jag har idétorka för lektioner så är Studera.ai räddaren i nöden.",
      avatar: "/avatars/sofia.jpg",
    },
  ];

  const defaultTestimonials = [
    {
      name: "Anna Svensson",
      role: "Student",
      feedback:
        "Studera.AI har förvandlat mitt sätt att förbereda mig för tentor. AI-genererade prov och flashcards är perfekt!",
      avatar: "/avatars/anna.jpg",
    },
    {
      name: "Adnan  Hussein",
      role: "Student",
      feedback:
        "Jag kan få vad som helst förklarat i form av en engagerande berättelse om vilket tema som helst - anpassat för mig!",
      avatar: "/avatars/lars.jpg",
    },
    {
      name: "Emma Karlsson",
      role: "Student",
      feedback:
        "Jag får min text analyserad med förbättringsförslag vilket får mig att tänka själv.",
      avatar: "/avatars/emma.jpg",
    },
  ];

  const getTestimonials = () => {
    switch (source) {
      case "grejOfTheDay":
        return grejTestimonials;
      case "teacher":
        return teacherTestimonials;
      default:
        return defaultTestimonials;
    }
  };

  const getHeading = () => {
    switch (source) {
      case "grejOfTheDay":
        return "Vad lärare säger";
      case "teacher":
        return "Vad lärare & pedagoger tycker";
      default:
        return "Vad elever och studenter tycker";
    }
  };

  const getSubheading = () => {
    switch (source) {
      case "grejOfTheDay":
        return "Upptäck hur lärare använder Grej of the Day med Studera.AI";
      case "teacher":
        return "Se vad lärare & pedagoger säger om att använda Studera.ai i sin undervisning";
      default:
        return "Läs vad elever och studenter tycker om Studera.AI";
    }
  };

  return (
    <Box sx={{ bgcolor: "background.paper", py: 10 }}>
      <Container>
        <Typography
          variant="h3"
          align="center"
          sx={{
            fontWeight: "bold",
            fontSize: "2.3em",
            mb: 2,
          }}
        >
          {getHeading()}
        </Typography>

        <Typography
          variant="h6"
          paragraph
          color="text.secondary"
          sx={{ mb: 6, textAlign: "center" }}
        >
          {getSubheading()}
        </Typography>
        <Grid container spacing={4}>
          {getTestimonials().map((testimonial, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                sx={{
                  borderRadius: 3,
                  boxShadow: "none",
                  bgcolor: "background.paper",
                  backgroundImage: "none",
                }}
              >
                <CardContent>
                  <QuoteIcon
                    color="primary"
                    sx={{
                      fontSize: 40,
                      mb: 2,
                      color:
                        theme.palette.mode === "dark" ? "#4e6479" : "#c4c8cc",
                    }}
                  />
                  <Typography
                    variant="body1"
                    color="text.primary"
                    sx={{ fontStyle: "italic", mb: 2 }}
                  >
                    "{testimonial.feedback}"
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Avatar
                      src={testimonial.avatar}
                      alt={testimonial.name}
                      sx={{ mr: 2 }}
                    />
                    <Box>
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: "600" }}
                      >
                        {testimonial.name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {testimonial.role}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default TestimonialsSection;
