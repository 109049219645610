import React from 'react';
import { SvgIcon } from '@mui/material';

const PowerPointIcon = React.forwardRef((props, ref) => {
  return (
    <SvgIcon ref={ref} viewBox='0 0 172.02174 156.45262' {...props}>
      <g transform='translate(71.436985,24.831782)'>
        {/* White background */}
        <g transform='matrix(0.70582253,0,0,0.70582253,1.0185879,-406.66344)'>
          <rect x='0' y='569.896' width='138.075' height='159.75' fill='#ffffff' />
        </g>
        {/* Border frame */}
        <g transform='matrix(0.70582253,0,0,0.70582253,-8.3166021,-403.76462)'>
          <path
            d='m 9.48,560.69 c -5.25,0 -9.48,3.99 -9.48,8.96 v 151.04 c 0,4.96 4.23,8.96 9.48,8.96 h 135.33 c 5.25,0 9.48,-4 9.48,-8.96 V 569.65 c 0,-4.97 -4.23,-8.96 -9.48,-8.96 z m -1.65,7.94 H 146.46 V 721.52 L 7.29,721.7 7.58,568.11 Z'
            fill='currentColor'
          />
        </g>
        {/* Rectangles */}
        <g transform='matrix(0.70582253,0,0,0.70582253,5.8937379,-439.18349)'>
          <rect x='0' y='719.53802' width='97.416397' height='10.1084' fill='currentColor' />
        </g>
        <g transform='matrix(0.70582253,0,0,0.70582253,5.8937379,-422.36588)'>
          <rect x='0' y='719.53802' width='97.416397' height='10.1084' fill='currentColor' />
        </g>
        {/* Pie chart */}
        <g transform='matrix(0.70582253,0,0,0.70582253,17.622378,-455.71458)'>
          <g transform='translate(4.64154,-4.08684)'>
            <path
              d='M 32.66,696.99 H 65.32 A 32.6587,32.6587 -180 0 0 32.66,664.33 Z'
              fill='currentColor'
            />
          </g>
          <path
            d='m 32.66,696.99 v -32.66 a 32.6587,32.6587 -180 1 0 32.66,32.66 z'
            fill='currentColor'
          />
        </g>
        {/* Gradient background */}
        <path
          d='M 22.846789,131.62084 V -24.831782 L -71.436985,-9.1907547 V 115.97275 Z'
          fill='currentColor'
        />
        {/* White P letter */}
        <g transform='matrix(0.70582253,0,0,0.70582253,-40.292597,-434.48174)'>
          <path
            d='m 0,728.82 v -74.27 l 25.71,-1.43 c 8.321412,-0.26803 12.2,2.55414 16.04,8.04414 3.35,4.8 4.35,11.70586 3.83,17.74586 -1.11,12.76 -9,23.78 -20.87,24.38 H 13.88 v -13.71783 h 7.22 c 5.174747,-0.22918 9.054542,-5.87121 8.806764,-11.73162 -0.222529,-6.60973 -3.69728,-10.52969 -8.400814,-10.69541 L 13.88,667.19 l 0.01,62.46 z'
            fill='#ffffff'
          />
        </g>
      </g>
    </SvgIcon>
  );
});

PowerPointIcon.displayName = 'PowerPointIcon';
export default PowerPointIcon;
