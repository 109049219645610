import React, { useState } from "react";
import {
  Timeline,
  TimelineItem,
  TimelineContent,
  TimelineSeparator,
  TimelineConnector,
  TimelineDot,
  TimelineOppositeContent,
} from "@mui/lab";
import { Typography, Box, Tooltip, IconButton, Paper } from "@mui/material";
import TimeBuilderEditDialog from "./TimeBuilderEditDialog";
import EditIcon from "@mui/icons-material/Edit";
import ImageGenerationModal from "./ImageGenerationModal";
import Logger from "./Logger";
import { useSnackbar } from "./SnackbarHandler";
import {
  generateAndProcessImage,
  handleImageGenerationError,
} from "./functions";

import { DeleteOutline } from "@mui/icons-material";

const TimelineSimpleDisplay = ({
  timelineData,
  onTimelineDataChange,
  theme,
  styles,
  viewMode,
  user,
}) => {
  if (!timelineData || !timelineData.length || !timelineData[0]?.timeline) {
    return null;
  }

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isGeneratingImages, setIsGeneratingImages] = useState(false);
  const [loadingImageIndex, setLoadingImageIndex] = useState(null);
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageInstructions, setImageInstructions] = useState("");
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const { showSnackbar } = useSnackbar();

  const handleImageButtonClick = (index = null) => {
    setSelectedImageIndex(index);
    setShowImageModal(true);
  };

  const handleCloseModal = () => {
    setShowImageModal(false);
    setImageInstructions("");
  };

  const handleGenerateSpecificImage = async (index) => {
    setIsGeneratingImages(true);
    setLoadingImageIndex(index);
    handleCloseModal();

    try {
      const timelineIndex = 0; // Since we're working with a single timeline
      const event = timelineData[timelineIndex].timeline[index];

      const imagePrompt = `Create a historical image depicting: "${event.event}" from ${event.year}. ${imageInstructions}`;
      const imageUrl = await generateAndProcessImage(imagePrompt, user.uid);

      if (imageUrl) {
        const newData = [...timelineData];
        newData[timelineIndex].timeline[index].imageUrl = imageUrl;
        onTimelineDataChange(newData);
        showSnackbar("Bilden har genererats!", "success");
      }
    } catch (error) {
      handleImageGenerationError(error, false, showSnackbar);
    } finally {
      setIsGeneratingImages(false);
      setLoadingImageIndex(null);
    }
  };

  const handleGenerateWithInstructions = async () => {
    if (selectedImageIndex !== null) {
      handleCloseModal();
      await handleGenerateSpecificImage(selectedImageIndex);
    }
  };

  const handleEditClick = () => {
    setIsEditDialogOpen(true);
  };

  const handleEditClose = () => {
    setIsEditDialogOpen(false);
  };

  const handleDeleteTimeline = () => {
    // Show confirmation dialog using the MUI Dialog component

    // Filter out the current timeline
    onTimelineDataChange(null);

    // Show success message
    showSnackbar("Tidslinjen raderad!", "success");
  };

  const handleEditSave = (editedData) => {
    Logger.log(
      "editedData.timelinesSimple: ",
      JSON.stringify(editedData.timelinesSimple, null, 2),
    );
    onTimelineDataChange(editedData.timelinesSimple);
    setIsEditDialogOpen(false);
    showSnackbar("Ändringar sparade!", "success");
  };

  // Sort events by year
  const sortedEvents = [...timelineData[0].timeline].sort(
    (a, b) => a.year - b.year,
  );

  return (
    <Box
      sx={{
        position: "relative",
        maxWidth: "900px",
        margin: "0 auto",
        mt: 10,
        mb: 4,
        "&:hover .timeline-actions": {
          opacity: 1,
        },
      }}
    >
      <Typography
        variant="h6"
        sx={{
          fontWeight: 600,
          textAlign: "center",
          fontSize: "1.6rem",
          color: "primary.secondary",
          mb: 4,
        }}
      >
        {timelineData[0].title}
      </Typography>

      <Box
        className="timeline-actions"
        sx={{
          position: "absolute",
          right: 0,
          top: 0,
          display: "flex",
          gap: 1,
          opacity: 0,
          transition: "opacity 200ms ease-in-out",
        }}
      >
        <Tooltip title="Redigera tidslinjen" placement="top">
          <IconButton size="medium" onClick={handleEditClick}>
            <EditIcon fontSize="medium" />
          </IconButton>
        </Tooltip>
        <Tooltip placement="top" title="Ta bort tidslinjen">
          <IconButton size="medium" onClick={handleDeleteTimeline}>
            <DeleteOutline fontSize="medium" />
          </IconButton>
        </Tooltip>
      </Box>
      <Timeline position="alternate">
        {sortedEvents.map((event, index) => (
          <TimelineItem key={index}>
            <TimelineOppositeContent color="text.secondary">
              <Typography variant="body2" fontWeight={500}>
                {event.year}
              </Typography>
            </TimelineOppositeContent>

            <TimelineSeparator>
              <TimelineDot color="primary" />
              {index < sortedEvents.length - 1 && <TimelineConnector />}
            </TimelineSeparator>

            <TimelineContent>
              <Paper
                elevation={3}
                sx={{
                  padding: 2,
                  backgroundColor: theme.palette.background.paper,
                  borderRadius: theme.shape.borderRadius,
                  "&:hover": {
                    backgroundColor: theme.palette.background.default,
                    transform: "scale(1.02)",
                    transition: "all 0.3s ease-in-out",
                  },
                  [theme.breakpoints.down("sm")]: {
                    padding: 1.5,
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: index % 2 === 0 ? "row" : "row-reverse",
                    gap: 3,
                    alignItems: "center",
                    [theme.breakpoints.down("sm")]: {
                      flexDirection: "column",
                      alignItems: "center",
                      gap: 2,
                    },
                  }}
                >
                  {event.imageUrl && (
                    <Box
                      sx={{
                        position: "relative",
                        flex: "none",
                        width: 120,
                        height: 120,
                      }}
                    >
                      <img
                        src={event.imageUrl}
                        alt={event.event}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          borderRadius: theme.shape.borderRadius,
                        }}
                      />
                    </Box>
                  )}

                  <Box sx={{ flex: 1 }}>
                    <Typography
                      sx={{
                        color:
                          theme.palette.mode === "dark" ? "white" : "black",
                        fontSize: "1.2rem",
                        fontWeight: "600",
                        [theme.breakpoints.down("sm")]: {
                          fontSize: "0.9rem",
                          textAlign: "center",
                          fontWeight: "600",
                        },
                      }}
                    >
                      {event.event}
                    </Typography>
                  </Box>
                </Box>
              </Paper>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>

      <TimeBuilderEditDialog
        open={isEditDialogOpen}
        onClose={handleEditClose}
        lessonData={{ timelinesSimple: timelineData }}
        onSave={handleEditSave}
        isGeneratingImages={isGeneratingImages}
        loadingImageIndex={loadingImageIndex}
        onGenerateSpecificImage={handleGenerateSpecificImage}
        onGenerateAllImages={() => handleImageButtonClick(null)}
        user={user}
        theme={theme}
        showSnackbar={showSnackbar}
        handleImageButtonClick={handleImageButtonClick}
        fromLessonTimelineDisplay={true}
      />

      <ImageGenerationModal
        open={showImageModal}
        onClose={handleCloseModal}
        onGenerate={handleGenerateWithInstructions}
        isGenerating={isGeneratingImages}
        instructions={imageInstructions}
        setInstructions={setImageInstructions}
        theme={theme}
      />
    </Box>
  );
};

export default TimelineSimpleDisplay;
