import React, { useState, useRef } from "react";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  CircularProgress,
  Typography,
  useTheme,
} from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import CollectionsIcon from "@mui/icons-material/Collections";
import UploadIcon from "@mui/icons-material/Upload";
import ImageGalleryDialog from "../ImageGalleryDialog";
import { ref, uploadBytes, getDownloadURL } from "../firebase";
import GenerateIcon from "@mui/icons-material/AutoAwesome";
import { db, storage } from "../firebase";
import { collection, addDoc } from "firebase/firestore";
import { FourSquare } from "react-loading-indicators";

const FlashcardImageUpload = ({
  userId,
  imageUrl,
  onImageUpdate,
  showSnackbar,
  maxFileSize = 5 * 1024 * 1024,
  acceptedFileTypes = ["image/jpeg", "image/png", "image/webp", "image/avif"],
  styles,
}) => {
  const theme = useTheme();
  const [isDragging, setIsDragging] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [showGallery, setShowGallery] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const fileInputRef = useRef(null);

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const validateFile = (file) => {
    if (!file) return { isValid: false, error: "Ingen fil vald." };

    if (!acceptedFileTypes.includes(file.type)) {
      return {
        isValid: false,
        error: `Ogiltigt filformat. Endast ${acceptedFileTypes.map((type) => type.split("/")[1].toUpperCase()).join(", ")} accepteras.`,
      };
    }

    if (file.size > maxFileSize) {
      return {
        isValid: false,
        error: `Filen är för stor. Max storlek är ${maxFileSize / 1024 / 1024}MB.`,
      };
    }

    return { isValid: true };
  };

  const handleFileUpload = async (file) => {
    const validation = validateFile(file);

    if (!validation.isValid) {
      showSnackbar(validation.error, "error");
      return;
    }

    try {
      setIsUploading(true);

      const imageRef = ref(
        storage,
        `flashcardImages/${userId}/${Date.now()}_${file.name}`,
      );
      await uploadBytes(imageRef, file);
      const downloadURL = await getDownloadURL(imageRef);

      await addDoc(collection(db, "images"), {
        userId,
        imageUrl: downloadURL,
        createdAt: new Date(),
        uploadedByUser: true,
        fileName: file.name,
        fileSize: file.size,
        fileType: file.type,
        category: "flashcard",
      });

      onImageUpdate(downloadURL);
      showSnackbar("Bilden har laddats upp!", "success");
    } catch (error) {
      console.error("Error uploading file:", error);
      showSnackbar("Ett fel uppstod vid uppladdningen. Försök igen.", "error");
    } finally {
      setIsUploading(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const file = e.dataTransfer.files[0];
    if (file) {
      await handleFileUpload(file);
    }
  };

  const handleFileInput = async (event) => {
    const file = event.target.files?.[0];
    if (file) {
      await handleFileUpload(file);
    }
  };

  const handleMenuOpen = (event) => {
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
  };

  const handleComingSoon = () => {
    showSnackbar(
      "Denna funktion är på väg. Just nu kan du ladda upp bild eller välja från galleri.",
      "info",
    );
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleGallerySelect = (selectedImage) => {
    onImageUpdate(selectedImage.imageUrl);
    setShowGallery(false);
  };

  const handleContainerClick = (event) => {
    // Stop propagation to prevent interference with the Menu's clickaway listener
    event.stopPropagation();
    // Only open the menu if we clicked the container itself, not its children
    if (event.target === event.currentTarget) {
      handleMenuOpen(event);
    }
  };

  return (
    <>
      <Box
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onClick={handleContainerClick}
        sx={{
          width: "100px",
          height: "100px",
          marginTop: "11px",
          border: `2px dashed ${isDragging ? theme.palette.primary.main : theme.palette.divider}`,
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          transition: "all 0.2s",
          backgroundColor: isDragging ? "rgba(0, 0, 0, 0.04)" : "transparent",
          position: "relative",
          "& > *": {
            pointerEvents: "none", // This prevents children from intercepting drag events
          },
          "& .MuiMenu-root, & input": {
            pointerEvents: "auto",
          },
          "&:hover": {
            borderColor: theme.palette.primary.main,
            backgroundColor: "rgba(0, 0, 0, 0.04)",
          },
        }}
      >
        {isDragging && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.7)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "6px",
              zIndex: 1,
            }}
          >
            <Typography
              color="white"
              sx={{ fontWeight: "bold", textAlign: "center" }}
            >
              Släpp fil här
            </Typography>
          </Box>
        )}

        {isUploading ? (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              background: "rgba(225, 225, 225, 0.92)",
              backdropFilter: "blur(10px)",
              borderRadius: "8px",
              overflow: "hidden",
              "&::before": {
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                height: "2px",
                background:
                  "linear-gradient(90deg, transparent, #8b5cf6, transparent)",
                animation: "loadingBar 2s ease-in-out infinite",
              },
              "@keyframes loadingBar": {
                "0%": {
                  transform: "translateX(-100%)",
                },
                "100%": {
                  transform: "translateX(100%)",
                },
              },
            }}
          >
            <Box
              sx={{
                position: "relative",

                alignItems: "center",
                justifyContent: "center",
                p: 2,
                "&::after": {
                  content: '""',
                  position: "absolute",
                  inset: -8,
                },
                "@keyframes rotate": {
                  "0%": {
                    transform: "rotate(0deg)",
                  },
                  "100%": {
                    transform: "rotate(360deg)",
                  },
                },
              }}
            >
              <Box sx={{ textAlign: "center" }}>
                <CircularProgress size={25} />
              </Box>
              <Typography variant="caption" display="block">
                Laddar...
              </Typography>
            </Box>
          </Box>
        ) : imageUrl ? (
          <Box
            component="img"
            src={imageUrl}
            alt="Flashcard image"
            sx={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              // objectFit: "cover",
              borderRadius: "6px",
            }}
          />
        ) : (
          <Box onClick={handleMenuOpen} sx={{ textAlign: "center" }}>
            <IconButton size="small">
              <ImageIcon sx={{ fontSize: "26px" }} />
            </IconButton>
            <Typography
              variant="caption"
              display="block"
              sx={{
                fontSize: "0.9rem",
                fontWeight: "500",
                color: "text.secondary",
              }}
            >
              Bild
            </Typography>
          </Box>
        )}

        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileInput}
          accept={acceptedFileTypes.join(",")}
          style={{ display: "none" }}
        />

        <Menu
          anchorEl={menuAnchorEl}
          open={Boolean(menuAnchorEl)}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          PaperProps={{
            sx: {
              ...styles.roundMenu,
              borderRadius: "8px",
            },
          }}
        >
          <MenuItem
            onClick={() => {
              handleMenuClose();
              fileInputRef.current?.click();
            }}
            sx={styles.menuItemStyle}
          >
            <UploadIcon
              fontSize="small"
              style={{ marginRight: 8, color: "#7B68EE" }}
            />
            Ladda upp fil
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleMenuClose();
              setShowGallery(true);
            }}
            sx={styles.menuItemStyle}
          >
            <CollectionsIcon
              fontSize="small"
              style={{ marginRight: 8, color: "#7B68EE" }}
            />
            Välj från galleri
          </MenuItem>

          <MenuItem
            sx={{ ...styles.menuItemStyle, display: "none" }}
            onClick={handleComingSoon}
          >
            <GenerateIcon
              fontSize="small"
              style={{ marginRight: 8, color: "#7B68EE" }}
            />
            Generera bild
          </MenuItem>
        </Menu>

        <ImageGalleryDialog
          open={showGallery}
          onClose={() => setShowGallery(false)}
          onSelect={handleGallerySelect}
          userId={userId}
          theme={theme}
        />
      </Box>
    </>
  );
};

export default FlashcardImageUpload;
