import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  TextField,
  Typography,
  CircularProgress,
  FormControl,
  Chip,
  InputLabel,
  Select,
  MenuItem,
  ImageList,
  Fade,
  ImageListItem,
  Box,
  Skeleton,
  FormGroup,
  FormControlLabel,
  Checkbox,
  DialogTitle,
  Dialog,
  DialogContent,
  IconButton,
  Collapse,
  Tooltip,
  Divider,
} from "@mui/material";
import { generateImage } from "./api";
import GenerateIcon from "@mui/icons-material/AutoAwesome";
import DownloadIcon from "@mui/icons-material/GetApp";
import CopyIcon from "@mui/icons-material/FileCopy";
import { useSnackbar } from "./SnackbarHandler";
import ImageDialog from "./ImageDialog";

import Logger from "./Logger";
import CloseIcon from "@mui/icons-material/Close";
import ProgressBar from "./ProgressBar";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ImageIcon from "@mui/icons-material/Image"; // Add this import
import {
  CustomFormControlLabel,
  CustomFormControlLabel2,
  DialogTransition,
  trackLesson,
} from "./functions";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import CodeIcon from "@mui/icons-material/Code";
import { useTheme } from "@mui/material/styles";
import { getStyles } from "../index";
import Suggestions from "./Suggestions";
import LinearProgress from "@mui/material/LinearProgress";
import { Mosaic } from "react-loading-indicators";
import { db, storage } from "./firebase";
import {
  collection,
  addDoc,
  query,
  where,
  getDocs,
  orderBy,
  limit,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {
  generateAndProcessImage,
  generateAndProcessImageWithRevision,
} from "./functions";

import { Tabs, Tab, Badge } from "@mui/material";
import PaletteIcon from "@mui/icons-material/Palette"; // Style
import SettingsIcon from "@mui/icons-material/Settings"; // Add this import
import RestartAltIcon from "@mui/icons-material/RestartAlt"; // Add this for reset icon

import ColorLensIcon from "@mui/icons-material/ColorLens"; // Color
import MoodIcon from "@mui/icons-material/Mood"; // Mood
import WbSunnyIcon from "@mui/icons-material/WbSunny"; // Weather & Time

const ImageGenerator = ({ user }) => {
  const [prompt, setPrompt] = useState("");
  const [size, setSize] = useState("1024x1024");
  const [model, setModel] = useState("dall-e-3");
  const [numImages, setNumImages] = useState(1);
  const [quality, setQuality] = useState("standard");
  const [responseFormat, setResponseFormat] = useState("url");
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [generatedImages, setGeneratedImages] = useState([]);
  const [error, setError] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { showSnackbar } = useSnackbar();
  const theme = useTheme();
  const styles = getStyles(theme);
  const [previewPrompt, setPreviewPrompt] = useState("");

  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    fetchImages();
  }, [user.uid]);

  const getSelectedCount = (category) => {
    return Object.values(formOptions[category]).filter(Boolean).length;
  };

  const getTranslatedLabel = (category, key) => {
    const translations = {
      style: {
        photo: "Fotografisk",
        painting: "Målning",
        sketch: "Skiss",
        digital: "Digital konst",
        anime: "Anime",
        minimal: "Minimalistisk",
        impressionism: "Impressionism",
        cubism: "Kubism",
        watercolor: "Akvarell",
      },
      era: {
        modern: "Modern tid",
        viking: "Vikingatiden",
        medieval: "Medeltiden",
        renaissance: "Renässansen",
        future: "Framtid",
        ancient: "Antiken",
        1800: "1800-talet",
        prehistoric: "Förhistorisk tid",
      },
      color: {
        color: "Färg",
        blackwhite: "Svartvitt",
        sepia: "Sepia",
        muted: "Dämpade färger",
      },
      mood: {
        happy: "Glad",
        dramatic: "Dramatisk",
        calm: "Lugn",
        mysterious: "Mystisk",
        energetic: "Energisk",
        nostalgic: "Nostalgisk",
        dystopic: "Dystopisk",
        romantic: "Romantisk",
      },
      weather: {
        sunny: "Soligt",
        rainy: "Regnigt",
        stormy: "Stormigt",
        snowy: "Snöigt",
        foggy: "Dimmigt",
      },
      timeOfDay: {
        day: "Dag",
        night: "Natt",
        sunrise: "Soluppgång",
        sunset: "Solnedgång",
        twilight: "Skymning",
      },
    };

    return translations[category]?.[key] || key;
  };

  const [formOptions, setFormOptions] = useState({
    style: {
      photo: false, // Fotografisk
      painting: false, // Målning
      sketch: false, // Skiss
      digital: false, // Digital konst
      anime: false, // Anime
      minimal: false, // Minimalistisk
      impressionism: false, // Impressionism
      cubism: false, // Kubism
      watercolor: false, // Akvarell
    },
    era: {
      modern: false, // Modern tid
      viking: false, // Vikingatiden
      medieval: false, // Medeltiden
      renaissance: false, // Renässansen
      future: false, // Framtid
      ancient: false, // Antiken
      1800: false, // 1800-talet
      prehistoric: false, // Förhistorisk tid
    },
    color: {
      color: false, // Färg
      blackwhite: false, // Svartvitt
      sepia: false, // Sepia
      muted: false, // Dämpade färger
    },
    mood: {
      happy: false, // Glad
      dramatic: false, // Dramatisk
      calm: false, // Lugn
      mysterious: false, // Mystisk
      energetic: false, // Energisk
      nostalgic: false, // Nostalgisk
      dystopic: false, // Dystopisk
      romantic: false, // Romantisk
    },
    weather: {
      sunny: false, // Soligt
      rainy: false, // Regnigt
      stormy: false, // Stormigt
      snowy: false, // Snöigt
      foggy: false, // Dimmigt
    },
    timeOfDay: {
      day: false, // Dag
      night: false, // Natt
      sunrise: false, // Soluppgång
      sunset: false, // Solnedgång
      twilight: false, // Skymning
    },
  });

  const presetOptions = {
    standard: {
      style: {
        photo: true,
        painting: false,
        sketch: false,
        digital: false,
        anime: false,
        minimal: false,
        impressionism: false,
        cubism: false,
        watercolor: false,
      },
      era: {
        modern: true,
        viking: false,
        medieval: false,
        renaissance: false,
        future: false,
        ancient: false,
        1800: false,
        prehistoric: false,
      },
      color: {
        color: true,
        blackwhite: false,
        sepia: false,
        muted: false,
      },
      mood: {
        happy: false,
        dramatic: false,
        calm: true,
        mysterious: false,
        energetic: false,
        nostalgic: false,
        dystopic: false,
        romantic: false,
      },
      weather: {
        sunny: true,
        rainy: false,
        stormy: false,
        snowy: false,
        foggy: false,
      },
      timeOfDay: {
        day: true,
        night: false,
        sunrise: false,
        sunset: false,
        twilight: false,
      },
    },
    scary: {
      style: {
        photo: false,
        painting: false,
        sketch: false,
        digital: true,
        anime: false,
        minimal: false,
        impressionism: false,
        cubism: false,
        watercolor: false,
      },
      era: {
        modern: false,
        viking: false,
        medieval: true,
        renaissance: false,
        future: false,
        ancient: false,
        1800: false,
        prehistoric: false,
      },
      color: {
        color: false,
        blackwhite: true,
        sepia: false,
        muted: true,
      },
      mood: {
        happy: false,
        dramatic: true,
        calm: false,
        mysterious: true,
        energetic: false,
        nostalgic: false,
        dystopic: true,
        romantic: false,
      },
      weather: {
        sunny: false,
        rainy: false,
        stormy: true,
        snowy: false,
        foggy: true,
      },
      timeOfDay: {
        day: false,
        night: true,
        sunrise: false,
        sunset: false,
        twilight: true,
      },
    },
    dreamy: {
      style: {
        photo: false,
        painting: true,
        sketch: false,
        digital: false,
        anime: false,
        minimal: false,
        impressionism: true,
        cubism: false,
        watercolor: true,
      },
      era: {
        modern: false,
        viking: false,
        medieval: false,
        renaissance: false,
        future: false,
        ancient: false,
        1800: false,
        prehistoric: false,
      },
      color: {
        color: true,
        blackwhite: false,
        sepia: false,
        muted: true,
      },
      mood: {
        happy: false,
        dramatic: false,
        calm: true,
        mysterious: false,
        energetic: false,
        nostalgic: true,
        dystopic: false,
        romantic: true,
      },
      weather: {
        sunny: false,
        rainy: false,
        stormy: false,
        snowy: false,
        foggy: true,
      },
      timeOfDay: {
        day: false,
        night: false,
        sunrise: true,
        sunset: true,
        twilight: true,
      },
    },
    futuristic: {
      style: {
        photo: false,
        painting: false,
        sketch: false,
        digital: true,
        anime: false,
        minimal: true,
        impressionism: false,
        cubism: false,
        watercolor: false,
      },
      era: {
        modern: false,
        viking: false,
        medieval: false,
        renaissance: false,
        future: true,
        ancient: false,
        1800: false,
        prehistoric: false,
      },
      color: {
        color: true,
        blackwhite: false,
        sepia: false,
        muted: false,
      },
      mood: {
        happy: false,
        dramatic: true,
        calm: false,
        mysterious: true,
        energetic: true,
        nostalgic: false,
        dystopic: false,
        romantic: false,
      },
      weather: {
        sunny: true,
        rainy: false,
        stormy: false,
        snowy: false,
        foggy: false,
      },
      timeOfDay: {
        day: false,
        night: true,
        sunrise: false,
        sunset: false,
        twilight: false,
      },
    },
    vintage: {
      style: {
        photo: true,
        painting: false,
        sketch: false,
        digital: false,
        anime: false,
        minimal: false,
        impressionism: false,
        cubism: false,
        watercolor: false,
      },
      era: {
        modern: false,
        viking: false,
        medieval: false,
        renaissance: false,
        future: false,
        ancient: false,
        1800: true,
        prehistoric: false,
      },
      color: {
        color: false,
        blackwhite: false,
        sepia: true,
        muted: true,
      },
      mood: {
        happy: false,
        dramatic: false,
        calm: true,
        mysterious: false,
        energetic: false,
        nostalgic: true,
        dystopic: false,
        romantic: true,
      },
      weather: {
        sunny: true,
        rainy: false,
        stormy: false,
        snowy: false,
        foggy: false,
      },
      timeOfDay: {
        day: true,
        night: false,
        sunrise: false,
        sunset: true,
        twilight: false,
      },
    },
  };

  const simulateProgress = (startProgress, endProgress, duration) => {
    const stepSize = 0.5;
    const steps = (endProgress - startProgress) / stepSize;
    const stepDuration = duration / steps;
    let currentProgress = startProgress;

    const intervalId = setInterval(() => {
      currentProgress += stepSize;
      if (currentProgress >= endProgress) {
        clearInterval(intervalId);
      } else {
        setProgress(currentProgress);
      }
    }, stepDuration);

    return () => clearInterval(intervalId);
  };

  const handlePresetSelect = (preset) => {
    if (preset === "reset") {
      setFormOptions({
        style: Object.keys(formOptions.style).reduce(
          (acc, key) => ({ ...acc, [key]: false }),
          {},
        ),
        era: Object.keys(formOptions.era).reduce(
          (acc, key) => ({ ...acc, [key]: false }),
          {},
        ),
        color: Object.keys(formOptions.color).reduce(
          (acc, key) => ({ ...acc, [key]: false }),
          {},
        ),
        mood: Object.keys(formOptions.mood).reduce(
          (acc, key) => ({ ...acc, [key]: false }),
          {},
        ),
        weather: Object.keys(formOptions.weather).reduce(
          (acc, key) => ({ ...acc, [key]: false }),
          {},
        ),
        timeOfDay: Object.keys(formOptions.timeOfDay).reduce(
          (acc, key) => ({ ...acc, [key]: false }),
          {},
        ),
      });
    } else {
      setFormOptions(presetOptions[preset]);
    }
  };

  const generateStyleString = () => {
    let styleString = "";

    // Stil
    const selectedStyles = Object.entries(formOptions.style)
      .filter(([_, selected]) => selected)
      .map(([style, _]) => {
        const styles = {
          photo: "fotografisk stil",
          painting: "målning",
          sketch: "skiss",
          digital: "digital konst",
          anime: "anime stil",
          minimal: "minimalistisk",
          impressionism: "impressionistisk stil",
          cubism: "kubistisk stil",
          watercolor: "akvarell",
        };
        return styles[style];
      });
    if (selectedStyles.length)
      styleString += `i ${selectedStyles.join(" och ")} `;

    // Tidsepok
    const selectedEras = Object.entries(formOptions.era)
      .filter(([_, selected]) => selected)
      .map(([era, _]) => {
        const eras = {
          modern: "modern tid",
          viking: "vikingatiden",
          medieval: "medeltiden",
          renaissance: "renässansen",
          future: "framtida",
          ancient: "antiken",
          1800: "1800-talet",
          prehistoric: "förhistorisk tid",
        };
        return eras[era];
      });
    if (selectedEras.length)
      styleString += `från ${selectedEras.join(" och ")} `;

    // Färg
    const selectedColors = Object.entries(formOptions.color)
      .filter(([_, selected]) => selected)
      .map(([color, _]) => {
        const colors = {
          blackwhite: "svartvitt",
          sepia: "sepia",
          muted: "dämpade färger",
        };
        return colors[color];
      });
    if (selectedColors.length)
      styleString += `i ${selectedColors.join(" och ")} `;

    // Stämning
    const selectedMoods = Object.entries(formOptions.mood)
      .filter(([_, selected]) => selected)
      .map(([mood, _]) => {
        const moods = {
          happy: "glad",
          dramatic: "dramatisk",
          calm: "lugn",
          mysterious: "mystisk",
          energetic: "energisk",
          nostalgic: "nostalgisk",
          dystopic: "dystopisk",
          romantic: "romantisk",
        };
        return moods[mood];
      });
    if (selectedMoods.length)
      styleString += `med ${selectedMoods.join(" och ")} stämning `;

    // Väder
    const selectedWeather = Object.entries(formOptions.weather)
      .filter(([_, selected]) => selected)
      .map(([weather, _]) => {
        const weathers = {
          sunny: "soligt",
          rainy: "regnigt",
          stormy: "stormigt",
          snowy: "snöigt",
          foggy: "dimmigt",
        };
        return weathers[weather];
      });
    if (selectedWeather.length)
      styleString += `med ${selectedWeather.join(" och ")} väder `;

    // Tid på dygnet
    const selectedTime = Object.entries(formOptions.timeOfDay)
      .filter(([_, selected]) => selected)
      .map(([time, _]) => {
        const times = {
          day: "dagtid",
          night: "natt",
          sunrise: "soluppgång",
          sunset: "solnedgång",
          twilight: "skymning",
        };
        return times[time];
      });
    if (selectedTime.length)
      styleString += `under ${selectedTime.join(" och ")} `;

    return styleString.trim();
  };

  const handleOptionChange = (category, option) => {
    setFormOptions((prev) => ({
      ...prev,
      [category]: {
        ...prev[category],
        [option]: !prev[category][option],
      },
    }));
  };

  const fetchImages = async () => {
    try {
      const imagesRef = collection(db, "images");
      const q = query(
        imagesRef,
        where("userId", "==", user.uid),
        orderBy("createdAt", "desc"),
        limit(18),
      );
      const querySnapshot = await getDocs(q);
      const images = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setGeneratedImages(images);
    } catch (error) {
      console.error("Fel vid hämtning av bilder:", error);
      setError("Kunde inte hämta bilder. Vänligen försök igen.");
    }
  };

  const handleImageClick = (image) => {
    Logger.log("image: ", image);
    setSelectedImage(image);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const downloadImage = async (imageUrl, filename) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      showSnackbar("Bild nedladdad!", "success");
    } catch (error) {
      console.error("Error downloading image:", error);
      showSnackbar("Kunde inte ladda ner bilden", "error");
    }
  };

  const handleDownload = () => {
    if (selectedImage) {
      downloadImage(
        selectedImage.imageUrl,
        `studera-ai-${selectedImage.id}.png`,
      );
    }
  };

  const handleCopyPrompt = () => {
    if (selectedImage) {
      navigator.clipboard.writeText(selectedImage?.revisedPrompt);

      showSnackbar("Prompt kopierad!", "success");
    }
  };

  const handleSelect = (selected) => {
    setPrompt(selected.value);
  };

  const handleGenerate = async () => {
    setIsLoading(true);
    setError(null);

    setProgress(0);
    let stopProgressSimulation = null;

    const styleString = generateStyleString();
    const enhancedPrompt = `${prompt}${styleString ? ". " + styleString : ""}`;

    setPreviewPrompt(enhancedPrompt);

    try {
      stopProgressSimulation = simulateProgress(0, 90, 15000);
      const { imageUrl, revisedPrompt } =
        await generateAndProcessImageWithRevision(enhancedPrompt, user.uid, {
          size,
          model,
          quality,
          responseFormat: "url",
        });

      if (stopProgressSimulation) {
        stopProgressSimulation();
      }

      setProgress(95);

      // Fetch updated images after all generations are complete
      showSnackbar("Bild genererad!", "success");
      setProgress(100);

      setSelectedImage({
        imageUrl,
        prompt: enhancedPrompt,
        revisedPrompt,
        createdAt: { toDate: () => new Date() },
        size,
        model,
      });
      setDialogOpen(true);

      await trackLesson(user.uid, "ImageGenerator.js", null, null);

      await fetchImages();
    } catch (error) {
      console.error("Error generating or processing image:", error);
      const errorMessage = error.response?.data?.details || error.message;
      showSnackbar(
        "Bild kunde ej genereras. Försök igen. Felmeddelande: ",
        errorMessage,
        "error",
      );
    } finally {
      setIsLoading(false);
    }
  };

  const renderImageOrSkeleton = (item, index) => {
    if (isLoading && index < numImages) {
      return (
        <Fade in={true} timeout={2000}>
          <Box
            sx={{
              position: "relative",
              width: "100%",
              height: "100%",
            }}
          >
            <Skeleton
              variant="rectangular"
              width="100%"
              height="100%"
              animation="wave"
              sx={{ borderRadius: "7px" }}
            />
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Mosaic
                color={["#33CCCC", "#33CC36", "#B8CC33", "#1097DA"]}
                size="small"
              />
            </Box>
          </Box>
        </Fade>
      );
    }
    return (
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: "100%",
          "&:hover .hover-actions": {
            opacity: 1,
          },
        }}
      >
        <img
          src={item.imageUrl}
          alt={item.prompt}
          loading="lazy"
          style={{
            objectFit: "cover",
            width: "100%",
            height: "100%",
            borderRadius: "4px",
            aspectRatio: "1",
          }}
        />
        <Box
          className="hover-actions"
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            display: "flex",
            justifyContent: "flex-end",
            padding: "8px",
            opacity: 0,
            transition: "opacity 0.2s ease-in-out",
            background:
              "linear-gradient(180deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 100%)",
            borderTopLeftRadius: "7px",
            borderTopRightRadius: "7px",
          }}
        >
          <Tooltip title="Ladda ned" placement="top">
            <IconButton
              sx={{
                backgroundColor: "rgba(255, 255, 255, 0.3)",
                backdropFilter: "blur(4px)",
                marginRight: "4px",
                padding: "4px",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.5)",
                },
              }}
              onClick={(e) => {
                e.stopPropagation();
                downloadImage(item.imageUrl, `generated-image-${item.id}.png`);
              }}
            >
              <DownloadIcon sx={{ fontSize: 12, color: "white" }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Kopiera prompt" placement="top">
            <IconButton
              size="small"
              sx={{
                backgroundColor: "rgba(255, 255, 255, 0.3)",
                backdropFilter: "blur(4px)",
                padding: "4px",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.5)",
                },
              }}
              onClick={(e) => {
                e.stopPropagation();
                navigator.clipboard.writeText(item.prompt);
                showSnackbar("Prompt kopierad!", "success");
              }}
            >
              <CopyIcon sx={{ fontSize: 16, color: "white" }} />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    );
  };

  return (
    <Box sx={{ ...styles.container }}>
      <Box
        sx={{
          ...styles.innerContainer,
          width: { xs: "100%", md: "90%" },
          display: "block",

          margin: "0 auto",
        }}
      >
        <Box sx={{ ...styles.headlineLessonBox, mt: 8 }}>
          <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
            1. Beskriv bilden du vill generera
          </Typography>
        </Box>
        <Box sx={{ ...styles.freshBox, padding: "40px", mt: 2 }}>
          <Grid container spacing={3}>
            {/* Text Input */}
            <Grid item xs={12}>
              <TextField
                placeholder="T.ex. Stockholm, Gösta Berlings saga, Syrien eller Eiffeltornet"
                value={prompt}
                fullWidth
                onChange={(e) => setPrompt(e.target.value)}
                multiline
                rows={3}
                InputProps={{
                  style: {
                    fontWeight: "400",
                    fontSize: "0.9rem",
                  },
                  endAdornment: (
                    <Tooltip title="Exempel">
                      <IconButton
                        sx={{
                          position: "absolute",
                          top: "8px",
                          right: "8px",
                          padding: "4px",
                          "& img": {
                            animation: "none",
                            "&.spinning": {
                              animation: "spin 0.3s ease-in-out 1",
                            },
                          },
                          "@keyframes spin": {
                            from: {
                              transform: "rotate(0deg)",
                            },
                            to: {
                              transform: "rotate(360deg)",
                            },
                          },
                        }}
                        onClick={(e) => {
                          const img = e.currentTarget.querySelector("img");
                          img.classList.add("spinning");
                          setTimeout(() => {
                            img.classList.remove("spinning");
                            const randomInstructions = [
                              "Skapa en fotorealistisk bild av en medelålders kaukasisk kvinnlig författare från tidigt 1900-tal. Hon har en smal kroppsbyggnad, kort mörkt hår, framträdande höga kindknotor och bär runda glasögon samt en elegant, åtsittande hatt och traditionella brittiska kläder. Hon sitter vid ett mahognybord, försjunken i djupa tankar medan hon skriver på papper med en liten, gammaldags bläckpenna.",
                              "Generera en fotorealistisk bild som uttrycker de känslor som förmedlas i en tankfull dialog. Bilden representerar ett engagerat samtal om perspektiv på det undermedvetna kontra fri vilja som de dominerande krafterna i människans existens. Tonen är eftertänksam, nyanserad och något frågande. Kontexten är en kontemplativ diskurs mellan forskare.",
                              "Skapa en drömlik bild av en magisk skog på natten, där träden lyser i olika färger och en liten älva flyger med ett gnistrande spår av ljus bakom sig. I bakgrunden syns en stjärnklar himmel med en gigantisk måne som reflekteras i en lugn sjö.",
                              "Generera en bild av en futuristisk stad där alla byggnader svävar i luften och är sammankopplade med lysande broar. Gatorna nedanför är fyllda av självkörande fordon, och en stor digital skärm visar en holografisk nyhetssändning.",
                              "Skildra ett ögonblick i en historisk fantasyvärld där en riddare i guldglänsande rustning står vid en gigantisk drake. De ser ut över ett vidsträckt kungarike från en klippa, medan solen går ner i horisonten.",
                              "Skapa en bild av ett futuristiskt kök där en AI-robot lagar mat. Robotens armar är fullt mekaniska, men den har en ansiktsskärm som visar ett vänligt leende. Köket är fullt av högteknologiska apparater och ingredienser som svävar i luften.",
                              "Måla en apokalyptisk scen där en övergiven stad har övertagits av naturen. Höga byggnader är täckta av mossa och växter, och en flock hjortar står mitt på en övervuxen motorväg. Solens strålar bryter genom molnen och skapar en känsla av hopp.",
                              "Illustrera en surrealistisk scen där en person sitter på en flygande bok bland molnen. Andra böcker svävar omkring och skapar en sorts magisk bibliotekskänsla i himlen. Molnen är färgade i pastellfärger och solen syns som ett mjukt ljus i bakgrunden.",
                              "Skapa en varm och nostalgisk bild av en gammaldags kaffebutik från 1950-talet. Butiken är fylld med färgglada skyltar, en jukebox i hörnet och människor som sitter vid små runda bord, dricker kaffe och skrattar.",
                              "Skildra en vinterlandskapsscen där en ensam skidåkare åker genom en djup skog. Träden är täckta av snö, och det enda ljuset kommer från skidåkarens pannlampa, som lyser upp de frostiga omgivningarna.",
                            ];
                            if (randomInstructions.length > 0) {
                              const randomIndex = Math.floor(
                                Math.random() * randomInstructions.length,
                              );
                              setPrompt(randomInstructions[randomIndex]);
                            }
                          }, 300); // 300ms * 3 spins
                        }}
                      >
                        <img
                          src="/img/dice.png"
                          alt="Random"
                          width="24"
                          height="24"
                        />
                      </IconButton>
                    </Tooltip>
                  ),
                }}
              />
              <Box sx={{ display: "none" }}>
                <Suggestions
                  listType="generateImageIdeas"
                  maxSuggestions={6}
                  onSelect={handleSelect}
                  randomize={true}
                />
              </Box>
            </Grid>

            <Grid item xs={6} sx={{ display: "none" }}>
              <Typography
                variant="formLabelSinUnderline"
                sx={{ fontWeight: "500" }}
              >
                Storlek
              </Typography>
              <FormControl fullWidth sx={{ mt: 1 }}>
                <Select value={size} onChange={(e) => setSize(e.target.value)}>
                  <MenuItem value="256x256">256x256 (kvadrat)</MenuItem>
                  <MenuItem value="512x512">512x512 (kvadrat)</MenuItem>
                  <MenuItem value="1024x1024">1024x1024 (kvadrat)</MenuItem>
                  <MenuItem value="1024x1792">1024x1792 (porträtt)</MenuItem>
                  <MenuItem value="1792x1024">1792x1024 (landskap)</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} sx={{ display: "none" }}>
              <Typography
                variant="formLabelSinUnderline"
                sx={{ fontWeight: "500" }}
              >
                Modell
              </Typography>
              <FormControl fullWidth sx={{ mt: 1, mb: 2 }}>
                <Select
                  value={model}
                  onChange={(e) => setModel(e.target.value)}
                >
                  <MenuItem value="dall-e-3">DALL-E 3</MenuItem>
                  <MenuItem value="dall-e-2">DALL-E 2</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} sx={{ display: "none" }}>
              <Typography
                variant="formLabelSinUnderline"
                sx={{ fontWeight: "500" }}
              >
                Antal bilder
              </Typography>
              <TextField
                type="number"
                placeholder="1-10"
                disabled={true}
                value={numImages}
                fullWidth
                sx={{ mt: 1, mb: 2 }}
                onChange={(e) =>
                  setNumImages(
                    Math.max(1, Math.min(10, parseInt(e.target.value) || 1)),
                  )
                }
                inputProps={{ min: 1, max: 10 }}
              />
            </Grid>
            <Grid item xs={6} sx={{ display: "none" }}>
              <Typography
                variant="formLabelSinUnderline"
                sx={{ fontWeight: "500" }}
              >
                Kvalitet
              </Typography>
              <FormControl disabled={true} fullWidth sx={{ mt: 1 }}>
                <Select
                  disabled={true}
                  value={quality}
                  onChange={(e) => setQuality(e.target.value)}
                >
                  <MenuItem value="standard">Standard</MenuItem>
                  <MenuItem value="hd">HD</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ ...styles.headlineLessonBox, mt: 8 }}>
          <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
            2. Välj inställningar & generera bild
          </Typography>
        </Box>
        <Box
          sx={{
            ...styles.freshBox,
            padding: "0px",
          }}
        >
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              background: theme.palette.mode === "dark" ? "#22303c" : "#f9f9fa",
              borderTopLeftRadius: "7px",
              borderTopRightRadius: "7px",
            }}
          >
            <Tabs
              value={activeTab}
              onChange={(e, newValue) => setActiveTab(newValue)}
              variant="scrollable"
              scrollButtons="auto"
              sx={{
                "& .MuiBadge-badge": {
                  backgroundColor: theme.palette.text.main,
                },
              }}
            >
              <Tab
                label="Förinställningar"
                iconPosition="start"
                icon={<SettingsIcon />}
              />{" "}
              <Tab
                label="Stil"
                iconPosition="start"
                icon={
                  <Badge
                    badgeContent={getSelectedCount("style")}
                    color="primary"
                  >
                    <PaletteIcon />
                  </Badge>
                }
              />
              <Tab
                label="Tidsepok"
                iconPosition="start"
                icon={
                  <Badge badgeContent={getSelectedCount("era")} color="primary">
                    <CalendarTodayIcon />
                  </Badge>
                }
              />
              <Tab
                label="Färg"
                iconPosition="start"
                icon={
                  <Badge
                    badgeContent={getSelectedCount("color")}
                    color="primary"
                  >
                    <ColorLensIcon />
                  </Badge>
                }
              />
              <Tab
                label="Stämning"
                iconPosition="start"
                icon={
                  <Badge
                    badgeContent={getSelectedCount("mood")}
                    color="primary"
                  >
                    <MoodIcon />
                  </Badge>
                }
              />
              <Tab
                label="Väder & Tid"
                iconPosition="start"
                icon={
                  <Badge
                    badgeContent={
                      getSelectedCount("weather") +
                      getSelectedCount("timeOfDay")
                    }
                    color="primary"
                  >
                    <WbSunnyIcon />
                  </Badge>
                }
              />
            </Tabs>
          </Box>
          <Box sx={{ padding: "30px", paddingBottom: "0px" }}>
            {activeTab === 0 && (
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  mt: 3,
                  mb: 3,
                }}
              >
                <Typography
                  variant="subtitle"
                  gutterBottom
                  sx={{ fontWeight: "500", display: "block", mb: 2 }}
                >
                  Välj bland våra förinställningar eller konfigurera själv:
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: 2,
                    flexWrap: "wrap",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant="success"
                    onClick={() => handlePresetSelect("standard")}
                    sx={{ minWidth: "120px" }}
                  >
                    Standard
                  </Button>
                  <Button
                    variant="success"
                    onClick={() => handlePresetSelect("scary")}
                    sx={{ minWidth: "120px" }}
                  >
                    Läskig
                  </Button>
                  <Button
                    variant="success"
                    onClick={() => handlePresetSelect("dreamy")}
                    sx={{ minWidth: "120px" }}
                  >
                    Drömsk
                  </Button>
                  <Button
                    variant="success"
                    onClick={() => handlePresetSelect("futuristic")}
                    sx={{ minWidth: "120px" }}
                  >
                    Futuristisk
                  </Button>
                  <Button
                    variant="success"
                    onClick={() => handlePresetSelect("vintage")}
                    sx={{ minWidth: "120px" }}
                  >
                    Vintage
                  </Button>
                  <Button
                    variant="print"
                    onClick={() => handlePresetSelect("reset")}
                    startIcon={<RestartAltIcon />}
                    sx={{ minWidth: "120px" }}
                  >
                    Nollställ
                  </Button>
                </Box>
                <Divider sx={{ width: "100%", mt: 3, mb: 1 }} />
              </Box>
            )}

            {activeTab === 1 && (
              <FormGroup row>
                {Object.entries(formOptions.style).map(([key, value]) => (
                  <CustomFormControlLabel
                    theme={theme}
                    key={key}
                    control={<Checkbox />}
                    label={
                      <Typography variant="body2">
                        {key === "photo"
                          ? "Fotografisk"
                          : key === "painting"
                            ? "Målning"
                            : key === "sketch"
                              ? "Skiss"
                              : key === "digital"
                                ? "Digital konst"
                                : key === "anime"
                                  ? "Anime"
                                  : key === "minimal"
                                    ? "Minimalistisk"
                                    : key === "impressionism"
                                      ? "Impressionism"
                                      : key === "cubism"
                                        ? "Kubism"
                                        : "Akvarell"}
                      </Typography>
                    }
                    checked={value}
                    onChange={() => handleOptionChange("style", key)}
                    sx={{ width: "200px", mb: 1 }}
                  />
                ))}
              </FormGroup>
            )}

            {activeTab === 2 && (
              <FormGroup row>
                {Object.entries(formOptions.era).map(([key, value]) => (
                  <CustomFormControlLabel
                    key={key}
                    control={<Checkbox />}
                    label={
                      <Typography variant="body2">
                        {key === "modern"
                          ? "Modern tid"
                          : key === "viking"
                            ? "Vikingatiden"
                            : key === "medieval"
                              ? "Medeltiden"
                              : key === "renaissance"
                                ? "Renässansen"
                                : key === "future"
                                  ? "Framtid"
                                  : key === "ancient"
                                    ? "Antiken"
                                    : key === "1800"
                                      ? "1800-talet"
                                      : "Förhistorisk tid"}
                      </Typography>
                    }
                    checked={value}
                    onChange={() => handleOptionChange("era", key)}
                    sx={{ width: "200px", mb: 1 }}
                  />
                ))}
              </FormGroup>
            )}

            {activeTab === 3 && (
              <FormGroup row>
                {Object.entries(formOptions.color).map(([key, value]) => (
                  <CustomFormControlLabel
                    key={key}
                    control={<Checkbox />}
                    label={
                      <Typography variant="body2">
                        {key === "color"
                          ? "Färg"
                          : key === "blackwhite"
                            ? "Svartvitt"
                            : key === "sepia"
                              ? "Sepia"
                              : "Dämpade färger"}
                      </Typography>
                    }
                    checked={value}
                    onChange={() => handleOptionChange("color", key)}
                    sx={{ width: "200px", mb: 1 }}
                  />
                ))}
              </FormGroup>
            )}

            {activeTab === 4 && (
              <FormGroup row>
                {Object.entries(formOptions.mood).map(([key, value]) => (
                  <CustomFormControlLabel
                    key={key}
                    control={<Checkbox />}
                    label={
                      <Typography variant="body2">
                        {key === "happy"
                          ? "Glad"
                          : key === "dramatic"
                            ? "Dramatisk"
                            : key === "calm"
                              ? "Lugn"
                              : key === "mysterious"
                                ? "Mystisk"
                                : key === "energetic"
                                  ? "Energisk"
                                  : key === "nostalgic"
                                    ? "Nostalgisk"
                                    : key === "dystopic"
                                      ? "Dystopisk"
                                      : "Romantisk"}
                      </Typography>
                    }
                    checked={value}
                    onChange={() => handleOptionChange("mood", key)}
                    sx={{ width: "200px", mb: 1 }}
                  />
                ))}
              </FormGroup>
            )}

            {activeTab === 5 && (
              <>
                <Typography
                  variant="body2"
                  sx={{ mt: 1, mb: 1, fontWeight: "medium" }}
                >
                  Väder:
                </Typography>
                <FormGroup row>
                  {Object.entries(formOptions.weather).map(([key, value]) => (
                    <CustomFormControlLabel
                      key={key}
                      control={<Checkbox />}
                      label={
                        <Typography variant="body2">
                          {key === "sunny"
                            ? "Soligt"
                            : key === "rainy"
                              ? "Regnigt"
                              : key === "stormy"
                                ? "Stormigt"
                                : key === "snowy"
                                  ? "Snöigt"
                                  : "Dimmigt"}
                        </Typography>
                      }
                      checked={value}
                      onChange={() => handleOptionChange("weather", key)}
                      sx={{ width: "200px", mb: 1 }}
                    />
                  ))}
                </FormGroup>

                <Typography
                  variant="body2"
                  sx={{ mt: 2, mb: 1, fontWeight: "medium" }}
                >
                  Tid på dygnet:
                </Typography>
                <FormGroup row>
                  {Object.entries(formOptions.timeOfDay).map(([key, value]) => (
                    <CustomFormControlLabel
                      key={key}
                      control={<Checkbox />}
                      label={
                        <Typography variant="body2">
                          {key === "day"
                            ? "Dag"
                            : key === "night"
                              ? "Natt"
                              : key === "sunrise"
                                ? "Soluppgång"
                                : key === "sunset"
                                  ? "Solnedgång"
                                  : "Skymning"}
                        </Typography>
                      }
                      checked={value}
                      onChange={() => handleOptionChange("timeOfDay", key)}
                      sx={{ width: "200px", mb: 1 }}
                    />
                  ))}
                </FormGroup>
              </>
            )}
          </Box>

          <Box sx={{ mt: 2, padding: "30px", textAlign: "center" }}>
            {Object.values(formOptions).some((category) =>
              Object.values(category).some(Boolean),
            ) && (
              <>
                <Typography
                  variant="subtitle"
                  gutterBottom
                  sx={{ fontWeight: "600", display: "block", mb: 2 }}
                >
                  Valda alternativ:
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    gap: 1,
                    mb: 3,
                  }}
                >
                  {Object.entries(formOptions).flatMap(([category, options]) =>
                    Object.entries(options)
                      .filter(([_, selected]) => selected)
                      .map(([key, _]) => (
                        <Chip
                          key={`${category}-${key}`}
                          label={getTranslatedLabel(category, key)}
                          onDelete={() => handleOptionChange(category, key)}
                          size="large"
                          sx={{
                            mr: 1,
                            mb: 1,
                          }}
                        />
                      )),
                  )}
                </Box>
              </>
            )}

            <Typography
              variant="subtitle"
              gutterBottom
              sx={{
                fontWeight: "600",
                display: "block",
                mb: 2,
                display: "none",
              }}
            >
              {previewPrompt}
            </Typography>

            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="purp"
                onClick={handleGenerate}
                disabled={isLoading}
                startIcon={
                  !isLoading ? (
                    <GenerateIcon />
                  ) : (
                    <CircularProgress sx={{ color: "white" }} size={16} />
                  )
                }
              >
                {isLoading ? "Genererar bild..." : "Generera bild"}
              </Button>
            </Box>
            {isLoading && (
              <>
                <Box sx={{ mt: 6 }}>
                  <ProgressBar progress={progress} label="Genererar bild..." />
                </Box>
              </>
            )}
          </Box>
        </Box>
        <Box sx={{ ...styles.headlineLessonBox, mt: 8 }}>
          <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
            3. Dina bilder
          </Typography>
        </Box>

        <ImageList
          sx={{ width: "100%", gap: "8px !important", overflow: "hidden" }}
          cols={4}
        >
          {isLoading &&
            Array.from(new Array(numImages)).map((_, index) => (
              <ImageListItem
                key={`skeleton-${index}`}
                sx={{ width: "auto", borderRadius: "3px" }}
              >
                {renderImageOrSkeleton(null, index)}
              </ImageListItem>
            ))}
          {generatedImages.map((item, index) => (
            <ImageListItem
              onClick={() => handleImageClick(item)}
              key={item.id}
              sx={{
                width: "auto",
                borderRadius: "3px",
                "&:hover": {
                  cursor: "pointer",
                },
              }}
            >
              {renderImageOrSkeleton(item, index + numImages)}
            </ImageListItem>
          ))}
        </ImageList>

        <ImageDialog
          open={dialogOpen}
          onClose={handleCloseDialog}
          selectedImage={selectedImage}
          onDownload={() => {
            if (selectedImage) {
              downloadImage(
                selectedImage.imageUrl,
                `studera-ai-${selectedImage.id}.png`,
              );
            }
          }}
          onCopyPrompt={handleCopyPrompt}
          showSnackbar={showSnackbar}
        />
      </Box>
    </Box>
  );
};

export default ImageGenerator;
