import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Box,
  DialogActions,
  Divider,
  Alert,
  CircularProgress,
} from "@mui/material";
import TranslateIcon from "@mui/icons-material/Translate";
import LanguageSelector from "./LanguageSelector";
import Logger from "./Logger";
import CloseIcon from "@mui/icons-material/Close";
import { trackLesson } from "./functions";
import GenerateIcon from "@mui/icons-material/AutoAwesome";

// Fields that should never be translated
const EXCLUDED_FIELDS = new Set([
  "type",
  "date",
  "imageUrl",
  "generatedImage",
  "image",
  "id",
  "uid",
  "created",
  "updated",
]);

const Translate = ({
  lessonData,
  onTranslated,
  callApi,
  user,
  showSnackbar,
  sx = {},
  buttonRef,
  excludedFields = [],
}) => {
  const [open, setOpen] = useState(false);
  const [isTranslating, setIsTranslating] = useState(false);
  const [targetLanguage, setTargetLanguage] = useState("arabiska");

  const allExcludedFields = new Set([...EXCLUDED_FIELDS, ...excludedFields]);

  const processForTranslation = (obj) => {
    // Store original values and their paths
    const excluded = new Map();

    const processObject = (input, currentPath = "") => {
      if (!input || typeof input !== "object") return input;

      // Handle arrays
      if (Array.isArray(input)) {
        return input.map((item, index) => {
          const newPath = `${currentPath}[${index}]`;
          return processObject(item, newPath);
        });
      }

      // Create a new object to avoid mutations
      const processed = { ...input };

      for (const [key, value] of Object.entries(processed)) {
        const newPath = currentPath ? `${currentPath}.${key}` : key;

        if (allExcludedFields.has(key)) {
          // Store the original value with its exact path
          excluded.set(newPath, value);
          // Keep the field but set to null for translation
          processed[key] = null;
        } else if (value && typeof value === "object") {
          processed[key] = processObject(value, newPath);
        }
      }

      return processed;
    };

    const processed = processObject(obj);
    return { processed, excluded };
  };

  const restoreExcludedFields = (translatedData, excluded) => {
    const restoreObject = (obj, currentPath = "") => {
      if (!obj || typeof obj !== "object") return obj;

      // Handle arrays
      if (Array.isArray(obj)) {
        return obj.map((item, index) => {
          const newPath = `${currentPath}[${index}]`;
          return restoreObject(item, newPath);
        });
      }

      // Create a new object to avoid mutations
      const restored = { ...obj };

      for (const key of Object.keys(restored)) {
        const newPath = currentPath ? `${currentPath}.${key}` : key;

        // Check if we have an excluded value for this path
        const excludedValue = excluded.get(newPath);
        if (excludedValue !== undefined) {
          restored[key] = excludedValue;
        } else if (restored[key] && typeof restored[key] === "object") {
          restored[key] = restoreObject(restored[key], newPath);
        }
      }

      return restored;
    };

    return restoreObject(translatedData);
  };

  const handleTranslate = async () => {
    setIsTranslating(true);
    try {
      // Process data, preserving excluded fields
      const { processed, excluded } = processForTranslation(
        JSON.parse(JSON.stringify(lessonData)),
      );

      Logger.log(
        "Data prepared for translation:",
        JSON.stringify(processed, null, 2),
      );

      const response = await callApi(
        [
          {
            role: "system",
            content: `You are a professional translator. Translate the entire lesson content to ${targetLanguage}. 
            Maintain all HTML formatting, keeping tags exactly as they are. 
            Do not translate technical fields, dates, or identifiers.
            Return the complete translated lesson object in the same structure.
            Keep null values as null without modifying them.`,
          },
          {
            role: "user",
            content: `Translate this lesson object to ${targetLanguage}. 
            Maintain the exact same JSON structure and HTML formatting.
            Do not modify null values or structure:
            ${JSON.stringify(processed, null, 2)}`,
          },
        ],
        16380,
        true,
        user.uid,
      );

      let translatedContent = response.data.choices[0].message.content.trim();

      // Clean up response formatting
      if (translatedContent.startsWith("```json")) {
        translatedContent = translatedContent.slice(7, -3).trim();
      } else if (translatedContent.startsWith("```")) {
        translatedContent = translatedContent.slice(3, -3).trim();
      }

      // Parse and restore excluded fields
      const translatedData = JSON.parse(translatedContent);
      const finalData = restoreExcludedFields(translatedData, excluded);

      Logger.log(
        "Translation complete with restored fields:",
        JSON.stringify(finalData, null, 2),
      );

      // Track the translation
      await trackLesson(user.uid, "Translate.js", null, {
        language: targetLanguage,
      });

      onTranslated(finalData);
      showSnackbar("Lektionen har översatts!", "success");
      setOpen(false);
    } catch (error) {
      console.error("Translation error:", error);
      showSnackbar("Ett fel uppstod vid översättningen", "error");
    } finally {
      setIsTranslating(false);
    }
  };

  return (
    <>
      <Button
        ref={buttonRef}
        variant="purp"
        startIcon={<TranslateIcon />}
        onClick={() => setOpen(true)}
        sx={{ mt: 2, ml: 2, ...sx }}
      >
        Översätt
      </Button>

      <Dialog
        open={open}
        onClose={() => !isTranslating && setOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle
          sx={{
            textAlign: "center",
            fontWeight: "700",
            padding: "25px",
            fontSize: "1.5rem",
          }}
        >
          <Box
            sx={{ display: "flex", width: "100%", justifyContent: "flex-end" }}
          >
            <IconButton
              onClick={() => setOpen(false)}
              size="small"
              sx={{ position: "absolute" }}
              disabled={isTranslating}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          Välj språk för översättning
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ p: 6 }}>
          <LanguageSelector
            language={targetLanguage}
            setLanguage={setTargetLanguage}
          />

          <Alert
            severity="warning"
            sx={{
              margin: "0 auto",

              mt: 5,
              borderRadius: 4,
              "& .MuiAlert-message": {
                display: "flex",
                alignItems: "center",
                gap: 2,
                width: "100%",
                fontWeight: "500",
              },
              "& .MuiAlert-icon": {
                display: "flex",
                alignItems: "center",
                marginRight: 1,
                marginTop: 0,
              },
            }}
          >
            <span>Observera: Vi jobbar på att förbättra denna funktion!</span>
          </Alert>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            variant="print"
            onClick={() => setOpen(false)}
            disabled={isTranslating}
            startIcon={<CloseIcon />}
            sx={{ mr: 2 }}
          >
            Avbryt
          </Button>
          <Button
            variant="purp"
            onClick={handleTranslate}
            disabled={isTranslating}
            sx={{ ml: 2 }}
            startIcon={
              isTranslating ? (
                <CircularProgress size={16} sx={{ color: "white" }} />
              ) : (
                <GenerateIcon />
              )
            }
          >
            {isTranslating ? "Översätter — tar 1 minut..." : "Översätt"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Translate;
