import {
  Science as ScienceIcon,
  Castle as CastleIcon,
  Psychology as PsychologyIcon,
  Public as PublicIcon,
  EmojiObjects as EmojiObjectsIcon,
  MenuBook as MenuBookIcon,
  EmojiEvents as EmojiEventsIcon,
  AccountBalance as AccountBalanceIcon,
  Science as DNAIcon,
  Public as GlobeIcon,
} from "@mui/icons-material";

// Default themes that show when no subject is selected
export const defaultThemes = [
  {
    type: "subheader",
    label: "Framstående historiska personer:",
  },
  {
    value: "marie-curie",
    label: "Marie Curie",
    content: "Marie Curie",
    Icon: ScienceIcon,
  },
  {
    value: "emmy-noether",
    label: "Emmy Noether",
    content: "Emmy Noether",
    Icon: EmojiObjectsIcon,
  },
  {
    value: "charles-darwin",
    label: "Charles Darwin",
    content: "Charles Darwin",
    Icon: DNAIcon,
  },
  {
    value: "albert-einstein",
    label: "Albert Einstein",
    content: "Albert Einstein",
    Icon: EmojiObjectsIcon,
  },
  {
    value: "virginia-woolf",
    label: "Virginia Woolf",
    content: "Virginia Woolf",
    Icon: MenuBookIcon,
  },
  {
    value: "leonardo-da-vinci",
    label: "Leonardo da Vinci",
    content: "Leonardo da Vinci",
    Icon: EmojiEventsIcon,
  },
];

export const subjectThemes = {
  biologi: [
    { type: "subheader", label: "Historiska personer inom biologi:" },
    {
      value: "charles-darwin",
      label: "Charles Darwin",
      content: "Charles Darwin",
      Icon: DNAIcon,
    },
    {
      value: "rosalind-franklin",
      label: "Rosalind Franklin",
      content: "Rosalind Franklin",
      Icon: ScienceIcon,
    },
    {
      value: "gregor-mendel",
      label: "Gregor Mendel",
      content: "Gregor Mendel",
      Icon: ScienceIcon,
    },
    {
      value: "barbara-mcclintock",
      label: "Barbara McClintock",
      content: "Barbara McClintock",
      Icon: ScienceIcon,
    },
    {
      value: "alexander-fleming",
      label: "Alexander Fleming",
      content: "Alexander Fleming",
      Icon: ScienceIcon,
    },
    {
      value: "rachel-carson",
      label: "Rachel Carson",
      content: "Rachel Carson",
      Icon: GlobeIcon,
    },
  ],

  historia: [
    {
      type: "subheader",
      label: "Historiska personer inom politik och samhälle:",
    },
    {
      value: "eleanor-roosevelt",
      label: "Eleanor Roosevelt",
      content: "Eleanor Roosevelt",
      Icon: AccountBalanceIcon,
    },
    {
      value: "winston-churchill",
      label: "Winston Churchill",
      content: "Winston Churchill",
      Icon: CastleIcon,
    },
    {
      value: "catherine-great",
      label: "Katarina den stora",
      content: "Katarina den stora",
      Icon: CastleIcon,
    },
    {
      value: "mahatma-gandhi",
      label: "Mahatma Gandhi",
      content: "Mahatma Gandhi",
      Icon: PublicIcon,
    },
    {
      value: "cleopatra",
      label: "Kleopatra",
      content: "Kleopatra",
      Icon: CastleIcon,
    },
    {
      value: "nelson-mandela",
      label: "Nelson Mandela",
      content: "Nelson Mandela",
      Icon: PublicIcon,
    },
  ],

  fysik: [
    { type: "subheader", label: "Historiska personer inom fysik:" },
    {
      value: "albert-einstein",
      label: "Albert Einstein",
      content: "Albert Einstein",
      Icon: EmojiObjectsIcon,
    },
    {
      value: "lise-meitner",
      label: "Lise Meitner",
      content: "Lise Meitner",
      Icon: ScienceIcon,
    },
    {
      value: "niels-bohr",
      label: "Niels Bohr",
      content: "Niels Bohr",
      Icon: ScienceIcon,
    },
    {
      value: "chien-wu",
      label: "Chien-Shiung Wu",
      content: "Chien-Shiung Wu",
      Icon: ScienceIcon,
    },
    {
      value: "isaac-newton",
      label: "Isaac Newton",
      content: "Isaac Newton",
      Icon: EmojiObjectsIcon,
    },
    {
      value: "emmy-noether",
      label: "Emmy Noether",
      content: "Emmy Noether",
      Icon: EmojiObjectsIcon,
    },
  ],

  kemi: [
    { type: "subheader", label: "Historiska personer inom kemi:" },
    {
      value: "marie-curie",
      label: "Marie Curie",
      content: "Marie Curie",
      Icon: ScienceIcon,
    },
    {
      value: "dmitri-mendeleev",
      label: "Dmitri Mendeleev",
      content: "Dmitri Mendeleev",
      Icon: ScienceIcon,
    },
    {
      value: "dorothy-hodgkin",
      label: "Dorothy Hodgkin",
      content: "Dorothy Hodgkin",
      Icon: ScienceIcon,
    },
    {
      value: "antoine-lavoisier",
      label: "Antoine Lavoisier",
      content: "Antoine Lavoisier",
      Icon: ScienceIcon,
    },
    {
      value: "ida-tacke",
      label: "Ida Noddack",
      content: "Ida Noddack",
      Icon: ScienceIcon,
    },
    {
      value: "svante-arrhenius",
      label: "Svante Arrhenius",
      content: "Svante Arrhenius",
      Icon: ScienceIcon,
    },
  ],

  matematik: [
    { type: "subheader", label: "Historiska personer inom matematik:" },
    {
      value: "ada-lovelace",
      label: "Ada Lovelace",
      content: "Ada Lovelace",
      Icon: EmojiObjectsIcon,
    },
    {
      value: "carl-gauss",
      label: "Carl Friedrich Gauss",
      content: "Carl Friedrich Gauss",
      Icon: EmojiObjectsIcon,
    },
    {
      value: "sophie-germain",
      label: "Sophie Germain",
      content: "Sophie Germain",
      Icon: EmojiObjectsIcon,
    },
    {
      value: "leonhard-euler",
      label: "Leonhard Euler",
      content: "Leonhard Euler",
      Icon: EmojiObjectsIcon,
    },
    {
      value: "hypatia",
      label: "Hypatia",
      content: "Hypatia",
      Icon: EmojiObjectsIcon,
    },
    {
      value: "alan-turing",
      label: "Alan Turing",
      content: "Alan Turing",
      Icon: EmojiObjectsIcon,
    },
  ],

  litteratur: [
    { type: "subheader", label: "Historiska personer inom litteratur:" },
    {
      value: "virginia-woolf",
      label: "Virginia Woolf",
      content: "Virginia Woolf",
      Icon: MenuBookIcon,
    },
    {
      value: "william-shakespeare",
      label: "William Shakespeare",
      content: "William Shakespeare",
      Icon: MenuBookIcon,
    },
    {
      value: "jane-austen",
      label: "Jane Austen",
      content: "Jane Austen",
      Icon: MenuBookIcon,
    },
    {
      value: "johann-goethe",
      label: "Johann Wolfgang von Goethe",
      content: "Johann Wolfgang von Goethe",
      Icon: MenuBookIcon,
    },
    {
      value: "emily-dickinson",
      label: "Emily Dickinson",
      content: "Emily Dickinson",
      Icon: MenuBookIcon,
    },
    {
      value: "fyodor-dostoevsky",
      label: "Fyodor Dostoevsky",
      content: "Fyodor Dostoevsky",
      Icon: MenuBookIcon,
    },
  ],
};

export const getThemes = (subject) => {
  if (!subject) return defaultThemes;
  return subjectThemes[subject] || defaultThemes;
};

export default getThemes;
