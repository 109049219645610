import React from "react";
import { Link } from "react-router-dom";
import {
  Drawer,
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  IconButton,
  Button,
  Tooltip,
} from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import SchoolIcon from "@mui/icons-material/School";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import { ChevronRight } from "@mui/icons-material";
import GenerateIcon from "@mui/icons-material/AutoAwesome";
import { getStyles } from "../index";
import DeleteIcon from "@mui/icons-material/Delete";

import { deleteDoc, doc } from "firebase/firestore";
import { db } from "./firebase";
const EssayListDrawer = ({
  historicEssays,
  theme,
  open,
  onClose,
  onDelete,
}) => {
  const styles = getStyles(theme);
  const handleDelete = async (e, essayId) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      await deleteDoc(doc(db, "essays", essayId));
      onDelete(essayId);
    } catch (error) {
      console.error("Error deleting essay:", error);
    }
  };
  return (
    <Drawer
      onClose={onClose}
      anchor="right"
      open={open}
      sx={{ width: { xs: "75%", sm: "50%", md: "33%" }, paddingTop: "0px" }}
      PaperProps={{
        sx: {
          width: { xs: "75%", sm: "50%", md: "28%" },
          boxShadow: "none",
          borderLeft:
            theme.palette.mode === "dark"
              ? "1px solid #34414c"
              : "1px solid #e0e0e0",
          backgroundImage: "none",
        },
      }}
    >
      <Box sx={{ p: 3 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center", // This aligns children vertically
          }}
        >
          <IconButton
            onClick={() => onClose()}
            color="inherit"
            sx={{
              display: "flex",
              alignItems: "center", // This ensures the icon is centered within the button
              justifyContent: "center",
            }}
          >
            <ChevronRight />
          </IconButton>
          <Typography
            variant="h6"
            sx={{
              fontSize: "17px",
              ml: 2,
              display: "flex",
              alignItems: "center",
            }}
          >
            Dina bedömningar
          </Typography>
        </Box>
        <List sx={{ backgroundColor: "transparent", mt: 4 }}>
          {historicEssays.map((essay) => (
            <ListItem
              key={essay.id}
              button
              className="lightBg"
              component={Link}
              to={`/app/uppsats/${essay.id}`}
              onClick={onClose} // Add this line
              sx={{
                "&:hover": { backgroundColor: theme.palette.action.hover },
                marginBottom: "10px",
              }}
            >
              <ListItemIcon>
                {essay.reviewed ? (
                  <Tooltip title="Granskad">
                    <CheckCircleIcon
                      sx={{
                        color:
                          theme.palette.mode === "dark" ? "#63e363" : "green",
                      }}
                    />
                  </Tooltip>
                ) : (
                  <DescriptionIcon />
                )}
              </ListItemIcon>
              <ListItemText
                primary={
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography variant="body1">{essay.fileName}</Typography>
                  </Box>
                }
                secondary={
                  <React.Fragment>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "4px",
                      }}
                    >
                      <SchoolIcon
                        sx={{ fontSize: "16px", marginRight: "4px" }}
                      />
                      <Typography variant="body2" sx={styles.grayText}>
                        {essay.educationLevel}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "4px",
                      }}
                    >
                      <HistoryEduIcon
                        sx={{ fontSize: "16px", marginRight: "4px" }}
                      />
                      <Typography variant="body2" sx={styles.grayText}>
                        {essay.rubricName}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "4px",
                      }}
                    >
                      <CalendarTodayIcon
                        sx={{ fontSize: "16px", marginRight: "4px" }}
                      />
                      <Typography variant="body2" sx={styles.grayText}>
                        {essay.createdAt.toDate().toLocaleDateString()}
                      </Typography>
                    </Box>
                  </React.Fragment>
                }
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  onClick={(e) => handleDelete(e, essay.id)}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>

        <Box sx={{ textAlign: "center", marginTop: "30px" }}>
          <Button
            variant={theme.palette.mode === "light" ? "purp" : "contained"}
            color="primary"
            component={Link}
            startIcon={<GenerateIcon style={{ color: "#fff" }} />}
            to="/app/uppsats/ny"
            sx={{ borderRadius: "20px" }}
          >
            Lägg till ny text
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default EssayListDrawer;
