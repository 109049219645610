import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Avatar,
  CircularProgress,
  FormControl,
  Divider,
  Select,
  IconButton,
  TextField,
  MenuItem,
  useTheme,
  Slider,
  Tooltip,
} from "@mui/material";
import GenerateIcon from "@mui/icons-material/AutoAwesome";
import ImageIcon from "@mui/icons-material/Image";

import PrintIcon from "@mui/icons-material/Print";
import { handleMysticImageWritingLesson } from "./printFunctions";
import { Mosaic } from "react-loading-indicators";
import { useSearchParams, useParams } from "react-router-dom";

import { callApi } from "./api";
import { generateAndProcessImage, trackLesson } from "./functions";
import { useSnackbar } from "./SnackbarHandler";
import GradeSelector, { gradeInstructions } from "./GradeSelector";
import LanguageSelector from "./LanguageSelector";
import ImageGenerationModal from "./ImageGenerationModal";
import { getStyles } from "../index";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import { ThemeSelector } from "./ThemeSelector";
import { GiMagicLamp } from "react-icons/gi";

import InfoBox from "./InfoBox";
import EditIcon from "@mui/icons-material/Edit";

import HoverableImage from "./HoverableImage";
import Logger from "./Logger";
import LessonDrawer from "./LessonDrawer";
import SaveLessonDialog from "./SaveLessonDialog";
import SaveAsIcon from "@mui/icons-material/SaveAs";

import ImageLoadingDialog from "./ImageLoadingDialog";
import HistoryIcon from "@mui/icons-material/History";

import { ThemeSelectorFlow } from "./ThemeSelectorFlow";

import PrintAsPdf from "./PrintAsPdf";
import SlideInPaper from "./SlideInPaper";
import Translate from "./Translate";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import ShareLink from "./ShareLink";
import ShareDialog from "./ShareDialog";
import ShareIcon from "@mui/icons-material/Share";
import LoadingBackdrop from "./LoadingBackdrop";

import { db } from "./firebase";
import {
  collection,
  query,
  where,
  limit,
  addDoc,
  updateDoc,
  getDocs,
  serverTimestamp,
} from "firebase/firestore";
import {
  Science as ScienceIcon,
  Castle as CastleIcon,
  Psychology as PsychologyIcon,
  Pets as PetsIcon,
  Public as PublicIcon,
  Brush as BrushIcon,
  EmojiEvents as EmojiEventsIcon,
  Museum as MuseumIcon,
  Nature as NatureIcon,
} from "@mui/icons-material";
import MenuBookIcon from "@mui/icons-material/MenuBook";

const MysticImageLesson = ({ user }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);

  const [mysteryText, setMysteryText] = useState(null);
  const [isGeneratingLesson, setIsGeneratingLesson] = useState(false);
  const [isGeneratingImage, setIsGeneratingImage] = useState(false);
  const [generatedImage, setGeneratedImage] = useState(null);
  const [themeColor, setThemeColor] = useState("#8a59f4");
  const [isEditingScenario, setIsEditingScenario] = useState(false);
  const { showSnackbar } = useSnackbar();

  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [isEditingSubtitle, setIsEditingSubtitle] = useState(false);
  const [titleText, setTitleText] = useState("Skriv om den mystiska bilden");

  const [themeType, setThemeType] = useState("generateWithAi");
  const [customTheme, setCustomTheme] = useState("");
  const [lineCount, setLineCount] = useState(9); // Default to 15 lines
  const [lineHeight, setLineHeight] = useState(50);
  const [grade, setGrade] = useState("åk6");
  const [language, setLanguage] = useState("svenska");

  const theme = useTheme();
  const styles = getStyles(theme);

  const [showImageModal, setShowImageModal] = useState(false);
  const [imageInstructions, setImageInstructions] = useState("");
  const [imageGenerationType, setImageGenerationType] = useState(null);

  const translateButtonRef = useRef(null);
  const pdfButtonRef = useRef(null);
  const shareLinkButtonRef = useRef(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showControlPanel, setShowControlPanel] = useState(false);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [isSharing, setIsSharing] = useState(false);
  const [shareDialogOpenFriends, setShareDialogOpenFriends] = useState(false);

  const [lessonTheme, setLessonTheme] = useState("generateWithAi");
  const [predefinedTheme, setPredefinedTheme] = useState(null);
  const lessonTitleRef = useRef(null); // Add this ref
  const transcriptRef = useRef("");
  const uploadedFileRef = useRef("");

  const wikipediaContentRef = useRef("");
  const bookContentRef = useRef("");

  const randomizedContentRef = useRef("");
  const coursePlanContentRef = useRef("");
  const urlContentRef = useRef("");
  const additionalThemes = [
    {
      type: "subheader",
      label: "Teman som lämpar sig extra bra för denna lektionstyp:",
    },
    {
      value: "selma",
      label: "Selma Lagerlöf",
      content: "Gör en lektion om selma lagerlöf",
      Icon: MenuBookIcon,
    },
    {
      value: "dronten",
      label: "Dronten",
      content: "Gör en lektion om den utdöda arten dronten",
      Icon: PetsIcon,
    },
    {
      value: "vikingar",
      label: "Vikingar",
      content: "Gör en lektion om vikingatiden",
      Icon: CastleIcon,
    },
    {
      value: "rymden",
      label: "Rymden",
      content: "Gör en lektion om vårt solsystem",
      Icon: PublicIcon,
    },
    {
      value: "dinosaurier",
      label: "Dinosaurier",
      content: "Gör en lektion om olika dinosaurier",
      Icon: NatureIcon,
    },
    {
      value: "nobel",
      label: "Nobelpriset",
      content: "Gör en lektion om nobelpriset och Alfred Nobel",
      Icon: EmojiEventsIcon,
    },
    {
      value: "uppfinnare",
      label: "Svenska uppfinnare",
      content: "Gör en lektion om kända svenska uppfinnare",
      Icon: ScienceIcon,
    },
    {
      value: "psychology",
      label: "Känslor",
      content: "Gör en lektion om olika känslor",
      Icon: PsychologyIcon,
    },
    {
      value: "konst",
      label: "Svensk konst",
      content: "Gör en lektion om svensk konsthistoria",
      Icon: MuseumIcon,
    },
  ];

  const [searchParams, setSearchParams] = useSearchParams();
  const subject = searchParams.get("subject") || null;
  const { lessonId } = useParams();
  const viewMode = searchParams.get("v") === "t";
  useEffect(() => {
    Logger.log("subject: ", subject);
  }, [subject]);

  useEffect(() => {
    Logger.log("lessonTheme: ", lessonTheme);
  }, [lessonTheme]);

  useEffect(() => {
    Logger.log("predefinedTheme: ", predefinedTheme);
  }, [predefinedTheme]);

  useEffect(() => {
    Logger.log("customTheme: ", customTheme);
  }, [customTheme]);

  useEffect(() => {
    Logger.log("youtubeTranscript: ", transcriptRef.current);
  }, [transcriptRef.current]);

  useEffect(() => {
    Logger.log("wikipediaContentRef: ", wikipediaContentRef.current);
  }, [wikipediaContentRef.current]);
  useEffect(() => {
    Logger.log("uploadedFileRef: ", uploadedFileRef.current);
  }, [uploadedFileRef.current]);

  useEffect(() => {
    Logger.log("randomizedContentRef: ", randomizedContentRef.current);
  }, [randomizedContentRef.current]);

  useEffect(() => {
    Logger.log("coursePlanContentRef: ", coursePlanContentRef.current);
  }, [coursePlanContentRef.current]);

  const handleSaveLessonClick = () => {
    setSaveDialogOpen(true);
  };

  const toggleViewMode = () => {
    const currentParams = Object.fromEntries(searchParams.entries());
    if (currentParams.v === "t") {
      delete currentParams.v;
    } else {
      currentParams.v = "t";
    }
    setSearchParams(currentParams);
  };
  useEffect(() => {
    const loadLessonFromId = async () => {
      if (lessonId) {
        try {
          const q = query(
            collection(db, "lesson_usage"),
            where("__name__", ">=", lessonId),
            where("__name__", "<=", lessonId + "\uf8ff"),
            where("lessonType", "==", "MysticImageLesson.js"),
            limit(1),
          );

          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            const docData = querySnapshot.docs[0].data();

            if (!docData.hasOpened) {
              await updateDoc(querySnapshot.docs[0].ref, {
                hasOpened: true,
                openedAt: Date.now(),
              });
            }

            setMysteryText(docData.data.mysteryText);
            setGeneratedImage(docData.data.generatedImage);
            setTitleText(docData.lessonName);

            // Scroll to lesson content
            setTimeout(() => {
              lessonTitleRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }, 100);

            showSnackbar("Lektionen har laddats in.", "success");
          } else {
            showSnackbar("Kunde inte hitta lektionen", "error");
          }
        } catch (error) {
          console.error("Error loading lesson:", error);
          showSnackbar("Ett fel uppstod när lektionen skulle laddas", "error");
        }
      }
    };

    loadLessonFromId();
  }, lessonId);

  useEffect(() => {
    Logger.log("mysteryText: ", mysteryText);
    const isDataValid =
      mysteryText !== null &&
      mysteryText !== undefined &&
      Object.keys(mysteryText).length > 0;

    if (isDataValid !== showControlPanel) {
      const timer = setTimeout(() => {
        setShowControlPanel(isDataValid);
      }, 100);

      return () => clearTimeout(timer);
    }
  }, [mysteryText, showControlPanel]);

  const handleShare = async (
    lessonComment,
    lessonName,
    selectedImage,
    tags,
    shareData,
  ) => {
    setIsSharing(true);
    await new Promise((resolve) => setTimeout(resolve, 1500));
    // try {
    //   // Create a document for each student
    //   const sharePromises = shareData.studentIds.map((studentId) => {
    //     const documentData = {
    //       userId: user.uid,
    //       shared: true,
    //       sharedTo: studentId,
    //       comment: shareData.comment,
    //       sharedAt: Date.now(),
    //       fileUrl: shareData.fileUrl,
    //       lessonType: "LettersLesson.js",
    //       timestamp: serverTimestamp(),
    //       lessonComment,
    //       lessonName,
    //       coverImage: selectedImage,
    //       hasPrinted: true,
    //       data: lessonData,
    //     };
    //     Logger.log(
    //       "Adding document for student",
    //       studentId,
    //       ":",
    //       JSON.stringify(documentData, null, 2),
    //     );
    //     return addDoc(collection(db, "lesson_usage"), documentData);
    //   });
    //   await Promise.all(sharePromises);
    //   showSnackbar(`Du har nu delat lektionen!`, "success");
    // }

    try {
      const lessonData = {
        mysteryText,
        generatedImage,
        titleText,
      };

      // Create a document for each student and then create notifications
      const sharePromises = shareData.studentIds.map(async (studentId) => {
        const documentData = {
          userId: user.uid,
          shared: true,
          sharedTo: studentId,
          comment: shareData.comment,
          sharedAt: Date.now(),
          fileUrl: shareData.fileUrl,
          lessonType: "MysticImageLesson.js",
          timestamp: serverTimestamp(),
          lessonComment,
          lessonName,
          coverImage: selectedImage,
          hasPrinted: true,
          data: lessonData,
        };
        // Create lesson document and get ID
        const lessonDoc = await addDoc(
          collection(db, "lesson_usage"),
          documentData,
        );
        Logger.log(
          "Adding document for student",
          studentId,
          ":",
          JSON.stringify(documentData, null, 2),
        );
        // Create notification with lesson doc ID
        await addDoc(collection(db, "notifications"), {
          type: "lesson_shared",
          recipientId: studentId,
          senderId: user.uid,
          read: false,
          createdAt: serverTimestamp(),
          lessonData: {
            lessonId: lessonDoc.id,
            lessonType: documentData.lessonType,
            lessonName: documentData.lessonName,
            fileUrl: documentData.fileUrl,
            comment: documentData.comment,
            coverImage: selectedImage,
          },
        });
        return lessonDoc;
      });
      await Promise.all(sharePromises);
      showSnackbar(`Du har nu delat lektionen!`, "success");
    } catch (error) {
      showSnackbar("Fel vid delande av lektionen: " + error.message, "error");
      console.error(error);
    } finally {
      setIsSharing(false);
    }
  };

  const handleSaveWithComment = async (comment, name, coverImage, tags) => {
    const lessonData = {
      mysteryText,
      generatedImage,
      titleText,
    };

    Logger.log(JSON.stringify(lessonData, null, 2));
    await trackLesson(
      user.uid,
      "MysticImageLesson.js",
      lessonData,
      null,
      true,
      comment,
      name,
      coverImage,
      tags,
    );
    setDrawerOpen(true);
    showSnackbar("Lektionen sparad i din lektionsbank!", "success");
  };

  const handleEditScenario = (newText) => {
    setMysteryText((prev) => ({
      ...prev,
      description: newText,
    }));
    setIsEditingScenario(false);
    showSnackbar("Scenariot har uppdaterats", "success");
  };

  const handleImageButtonClick = (type) => {
    setImageGenerationType(type);
    setShowImageModal(true);
  };

  const handleCloseModal = () => {
    setShowImageModal(false);
    setImageInstructions("");
  };

  const handleGenerateWithInstructions = async () => {
    handleCloseModal();

    if (imageGenerationType === "topic") {
      await handleGenerateImage();
    } else if (imageGenerationType === "facts") {
      await handleGenerateImage();
    }
  };

  const handleLineCountChange = (event, newValue) => {
    setLineCount(newValue);
  };

  const LINE_HEIGHT_SCALE = 5;
  const pixelsToSliderValue = (pixels) =>
    Math.round(pixels / LINE_HEIGHT_SCALE);
  const sliderValueToPixels = (value) => value * LINE_HEIGHT_SCALE;

  const handleLineHeightChange = (event, newValue) => {
    setLineHeight(sliderValueToPixels(newValue));
  };

  const handleGenerateLesson = async () => {
    setIsGeneratingLesson(true);
    try {
      let themeToUse;

      if (lessonTheme === "youtube") {
        themeToUse = transcriptRef.current;
      } else if (lessonTheme === "customTheme") {
        themeToUse = customTheme;
      } else if (lessonTheme === "predefined") {
        themeToUse = predefinedTheme;
      } else if (lessonTheme === "fileUpload") {
        themeToUse = uploadedFileRef.current;
      } else if (lessonTheme === "wikipedia") {
        themeToUse = wikipediaContentRef.current;
      } else if (lessonTheme === "randomizer") {
        themeToUse = randomizedContentRef.current;
      } else if (lessonTheme === "book") {
        themeToUse = bookContentRef.current;
      } else if (lessonTheme === "url") {
        themeToUse = urlContentRef.current;
      } else {
        //
        themeToUse = coursePlanContentRef.current;
      }

      Logger.log("themeToUse: ", themeToUse);
      const response = await callApi(
        [
          {
            role: "system",
            content:
              "Du är en assistent som genererar mystiska och fantasifulla scenarier.",
          },
          {
            role: "user",
            content: `Generera JSON med ett mystiskt scenario på ${language} relaterat till temat "${themeToUse}"
            
            ${gradeInstructions[grade]}
            
            Exempel på svar:
            {
              "scenario": {
                "description": "I den förtrollade skogen står en urgammal portal gjord av glödande kristaller. Månskenet dansar över dess yta och skapar mystiska mönster på marken.",
                "prompt": "A magical ancient crystal portal in an enchanted forest, with moonlight creating mysterious patterns, mystical atmosphere, ethereal lighting"
              }
            }`,
          },
        ],
        8000,
        true,
        user.uid,
      );

      let messageContent = response.data.choices[0].message.content.trim();
      if (messageContent.startsWith("```json")) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith("```")) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      const data = JSON.parse(messageContent);
      setMysteryText(data.scenario);
      showSnackbar("Nytt mystiskt scenario har genererats", "success");
      Logger.log(JSON.stringify(data, null, 2));

      setTimeout(() => {
        lessonTitleRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, 100);

      await trackLesson(user.uid, "MysticImageLesson.js", null, {
        language: language,
        lessonTheme: lessonTheme,
      });
    } catch (error) {
      console.error("Error generating lesson:", error);
      showSnackbar("Kunde inte generera nytt scenario", "error");
    } finally {
      setIsGeneratingLesson(false);
    }
  };

  const handleGenerateImage = async () => {
    if (!mysteryText) {
      showSnackbar("Generera ett scenario först", "warning");
      return;
    }

    // Combine the original prompt with any additional instructions
    const enhancedPrompt = imageInstructions
      ? `${mysteryText.prompt}. Använd INTE text på bilden. Fler instruktioner: ${imageInstructions}`
      : mysteryText.prompt;

    setIsGeneratingImage(true);
    try {
      const imageUrl = await generateAndProcessImage(enhancedPrompt, user.uid, {
        size: "1024x1024",
        model: "dall-e-3",
        quality: "standard",
      });

      setGeneratedImage(imageUrl);
      showSnackbar("Bild har genererats!", "success");
    } catch (error) {
      console.error("Error generating image:", error);
      showSnackbar("Kunde inte generera bild", "error");
    } finally {
      setIsGeneratingImage(false);
    }
  };

  return (
    <Box sx={styles.container}>
      <Box
        sx={{
          ...styles.innerContainer,
          width: { xs: "100%", md: "100%" },
          margin: "0 auto",
        }}
      >
        {!viewMode && (
          <Typography
            variant="h1"
            gutterBottom
            sx={{
              fontWeight: "bold",
              fontSize: "1.8em",
              mb: 3,
              mt: 5,
              textAlign: "center",
            }}
          >
            Skriv om den mystiska bilden
          </Typography>
        )}
        <Box
          sx={{
            ...styles.buttonsContainer,
            ...styles.additionalButtonsContainer,
          }}
        >
          {mysteryText && user.accountType === "teacher" && (
            <Button
              variant="purp"
              sx={{ ...styles.tightButton }}
              disableRipple
              onClick={() => {
                setTimeout(() => {
                  lessonTitleRef.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "start", // This ensures it scrolls to the top of the element
                  });
                }, 100);
                toggleViewMode();
              }}
              startIcon={
                searchParams.get("v") === "t" ? (
                  <FullscreenExitIcon />
                ) : (
                  <FullscreenIcon />
                )
              }
            >
              {searchParams.get("v") === "t" ? "Redigeringsläge" : "Elevläge"}
            </Button>
          )}

          {!viewMode && (
            <Button
              variant="purp"
              sx={{ ...styles.tightButton }}
              onClick={() => setDrawerOpen(true)}
              startIcon={<BookmarkBorderIcon />}
            >
              Sparat
            </Button>
          )}
        </Box>
        {!viewMode && (
          <>
            <InfoBox
              icon={GiMagicLamp}
              title="Powerpoint Instructions"
              instructions={[
                "Eleven beskriver vad som händer på bilden eller skriver en berättelse om den",
                "Lägg till fantasieggande AI-genererade bilder",
                "Skriv ut lektionen",
              ]}
              theme={theme}
              styles={styles}
            />

            <ThemeSelectorFlow
              subject={subject}
              value={lessonTheme}
              onChange={(e) => setLessonTheme(e.target.value)}
              customTheme={customTheme}
              onCustomThemeChange={(e) => setCustomTheme(e.target.value)}
              onPredefinedThemeChange={(e) =>
                setPredefinedTheme(e.target.value)
              }
              onBookContentChange={(content) => {
                Logger.log("onBookContentChange", content.substring(0, 100));
                bookContentRef.current = content;
              }}
              onYoutubeTranscriptChange={(transcriptContent) => {
                Logger.log(
                  "onYoutubeTranscriptChange körs med: ",
                  transcriptContent,
                );
                transcriptRef.current = transcriptContent;
              }}
              onUrlContentChange={(content) => {
                Logger.log(
                  "Parent: URL content received",
                  content.substring(0, 300),
                );
                urlContentRef.current = content;
              }}
              onFileUpload={(content) => {
                Logger.log("onFileUpload körs med: ", content);
                uploadedFileRef.current = content;
              }}
              onWikipediaContentChange={(content) => {
                Logger.log(
                  "Parent: Wikipedia content received",
                  content.substring(0, 100),
                );
                wikipediaContentRef.current = content;
              }}
              onRandomizedContentChange={(content) => {
                Logger.log("randomizedContentRef", content.substring(0, 100));
                randomizedContentRef.current = content;
              }}
              onCoursePlanContentChange={(content) => {
                Logger.log("onCoursePlanChange", content.substring(0, 100));
                coursePlanContentRef.current = content;
              }}
              lessonType="MysticImageLesson.js"
              user={user}
              theme={theme}
              styles={styles}
              onLessonSelect={(lesson) => {
                Logger.log("onLessonSelect körs");
                setMysteryText(lesson.data.mysteryText);
                setGeneratedImage(lesson.data.generatedImage);
                setTitleText(lesson.lessonName);
                setTimeout(() => {
                  lessonTitleRef.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "start", // This ensures it scrolls to the top of the element
                  });
                }, 100);
                showSnackbar("Lektionen har laddats in.", "success");
              }}
              additionalThemes={additionalThemes}
            />
            <Box sx={styles.headlineLessonBox}>
              <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
                2. Generera lektionen
              </Typography>
            </Box>

            <Box sx={{ ...styles.freshBox, padding: "30px" }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Typography
                    variant="formLabelSinUnderline"
                    sx={{ fontWeight: "500", mb: 0 }}
                  >
                    Språk:
                  </Typography>
                  <Box sx={{ mt: 1, mb: 3 }}>
                    <LanguageSelector
                      language={language}
                      setLanguage={setLanguage}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Typography
                    variant="formLabelSinUnderline"
                    sx={{ fontWeight: "500", mb: 0 }}
                  >
                    Årskurs:
                  </Typography>
                  <Box sx={{ mt: 1, mb: 3 }}>
                    <GradeSelector grade={grade} setGrade={setGrade} />
                  </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Typography
                    variant="formLabelSinUnderline"
                    sx={{ fontWeight: "500", mb: 0 }}
                  >
                    Antal rader:
                  </Typography>
                  <Box sx={{ px: 2, mt: 2 }}>
                    <Slider
                      value={lineCount}
                      onChange={handleLineCountChange}
                      step={1}
                      marks
                      min={1}
                      max={20}
                      valueLabelDisplay="auto"
                      sx={{
                        color:
                          theme.palette.mode === "light"
                            ? "#8b5cf6"
                            : "primary",
                        "& .MuiSlider-markLabel": {
                          color: theme.palette.text.primary,
                        },
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Typography
                    variant="formLabelSinUnderline"
                    sx={{ fontWeight: "500", mb: 0 }}
                  >
                    Radavstånd (pixlar):
                  </Typography>
                  <Box sx={{ px: 2, mt: 2 }}>
                    <Slider
                      value={pixelsToSliderValue(lineHeight)}
                      onChange={handleLineHeightChange}
                      step={1}
                      marks
                      min={0}
                      max={15}
                      valueLabelDisplay="auto"
                      valueLabelFormat={(value) =>
                        `${sliderValueToPixels(value)}px`
                      }
                      sx={{
                        color:
                          theme.palette.mode === "light"
                            ? "#8b5cf6"
                            : "primary",
                        "& .MuiSlider-markLabel": {
                          color: theme.palette.text.primary,
                        },
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "12px",
                  width: "100%",
                }}
              >
                <Box sx={{ mt: 4, mb: 2 }}>
                  <Button
                    variant={
                      theme.palette.mode === "light" ? "purp" : "contained"
                    }
                    color="primary"
                    onClick={handleGenerateLesson}
                    disabled={isGeneratingLesson}
                    startIcon={
                      !isGeneratingLesson ? (
                        <GenerateIcon />
                      ) : (
                        <CircularProgress size={20} />
                      )
                    }
                    sx={{ mr: 2, mt: 6 }}
                  >
                    {isGeneratingLesson
                      ? "Genererar..."
                      : "Generera nytt scenario"}
                  </Button>
                </Box>
              </div>
            </Box>
          </>
        )}
        {mysteryText && (
          <>
            {!viewMode && (
              <Box
                ref={lessonTitleRef}
                sx={{ ...styles.headlineLessonBox, mt: 10 }}
              >
                <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
                  3. Din lektion
                </Typography>
              </Box>
            )}
            <Box
              sx={{
                ...styles.freshBox,
                padding: "30px",

                width: {
                  xs: "100%",
                  sm: viewMode ? "70%" : undefined,
                },
                margin: viewMode ? "0 auto" : undefined,
                marginTop: viewMode ? "0px" : undefined,
                ...(viewMode && { backgroundColor: "transparent" }),
                ...(viewMode && { borderColor: "transparent" }),
              }}
            >
              {!viewMode && (
                <Box sx={{ position: "relative" }}>
                  <Box
                    sx={{
                      ...styles.lessonControlPanel,
                      ...styles.lessonControlPanelNuevo,

                      "& .MuiButton-root": {
                        ...styles.controlPanelButtons,
                        ...styles.lessonControlPanelNewButtons,
                      },
                    }}
                  >
                    <Button
                      variant="print"
                      startIcon={<SaveAsIcon />}
                      sx={{ ml: 2 }}
                      onClick={handleSaveLessonClick}
                    >
                      Spara i lektionsbank
                    </Button>

                    <Button
                      variant="print"
                      color="primary"
                      onClick={() =>
                        handleMysticImageWritingLesson(
                          [{ image: generatedImage }],
                          lineCount,
                          lineHeight,
                          themeColor,

                          mysteryText?.description,
                          titleText,
                        )
                      }
                      startIcon={<PrintIcon />}
                    >
                      Skriv ut
                    </Button>

                    <Button
                      variant="success"
                      sx={{}}
                      onClick={() => setShareDialogOpenFriends(true)}
                      startIcon={<ShareIcon />}
                    >
                      Dela med vänner
                    </Button>
                    <Button
                      variant="success"
                      sx={{
                        mt: 2,
                        ml: 2,
                      }}
                      onClick={() => setShareDialogOpen(true)}
                      startIcon={<ShareIcon />}
                    >
                      Dela med elever
                    </Button>

                    <ShareLink
                      buttonRef={shareLinkButtonRef}
                      key={lessonId}
                      lessonId={lessonId}
                      lessonData={{
                        mysteryText,
                        generatedImage,
                        titleText,
                      }}
                      user={user}
                      lessonType={"MysticImageLesson.js"}
                      onCopy={(message, severity) =>
                        showSnackbar(message, severity)
                      }
                      sx={{ mt: 2 }}
                    />

                    <Tooltip title="Hur lektionen ser ut för eleverna">
                      <Button
                        variant="success"
                        onClick={() => {
                          window.scrollTo({ top: 0, behavior: "smooth" });
                          toggleViewMode();
                        }}
                        sx={{ ml: 2, mt: 2 }}
                        startIcon={
                          searchParams.get("v") === "t" ? (
                            <FullscreenExitIcon />
                          ) : (
                            <FullscreenIcon />
                          )
                        }
                      >
                        {searchParams.get("v") === "t"
                          ? "Redigeringsläge"
                          : "Elevläge"}
                      </Button>
                    </Tooltip>

                    <PrintAsPdf
                      buttonRef={pdfButtonRef}
                      onPrint={() =>
                        handleMysticImageWritingLesson(
                          [{ image: generatedImage }],
                          lineCount,
                          lineHeight,
                          themeColor,

                          mysteryText?.description,
                          titleText,
                        )
                      }
                      sx={{ ml: 2 }}
                    />

                    <Translate
                      buttonRef={translateButtonRef}
                      lessonData={{
                        mysteryText,
                        generatedImage,
                        titleText,
                      }}
                      onTranslated={(data) => {
                        setMysteryText({
                          prompt: data.mysteryText.prompt,
                          description: data.mysteryText.description,
                        });

                        setTitleText(data.titleText);
                      }}
                      callApi={callApi}
                      user={user}
                      showSnackbar={showSnackbar}
                    />

                    <Button
                      variant={
                        theme.palette.mode === "light" ? "purp" : "contained"
                      }
                      color="primary"
                      onClick={() => handleImageButtonClick("topic")}
                      disabled={isGeneratingImage}
                      startIcon={
                        !isGeneratingImage ? (
                          <ImageIcon />
                        ) : (
                          <CircularProgress size={20} />
                        )
                      }
                      sx={{ mr: 2 }}
                    >
                      {isGeneratingImage
                        ? "Genererar bild..."
                        : "Generera bild"}
                    </Button>
                  </Box>
                  <Box>
                    <ImageGenerationModal
                      open={showImageModal}
                      onClose={handleCloseModal}
                      onGenerate={handleGenerateWithInstructions}
                      isGenerating={
                        imageGenerationType === "topic"
                          ? isGeneratingImage
                          : isGeneratingImage
                      }
                      instructions={imageInstructions}
                      setInstructions={setImageInstructions}
                      generationType={imageGenerationType}
                      theme={theme}
                    />
                  </Box>

                  <Box
                    sx={{
                      ...styles.lessonControlPanelFade,
                    }}
                  />
                </Box>
              )}

              <SaveLessonDialog
                key={titleText}
                open={saveDialogOpen}
                onClose={() => setSaveDialogOpen(false)}
                onSave={handleSaveWithComment}
                theme={theme}
                lessonObject={generatedImage}
                lessonName={titleText}
                lessonType="MysticImageLesson.js"
              />

              <LessonDrawer
                userId={user.uid}
                lessonType="MysticImageLesson.js"
                theme={theme}
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                lessonIcon={GiMagicLamp}
                onSelectLesson={(lesson) => {
                  setMysteryText(lesson.data.mysteryText);
                  setGeneratedImage(lesson.data.generatedImage);
                  setTitleText(lesson.lessonName);
                  setDrawerOpen(false);

                  showSnackbar(`Lektionen har laddats in.`, "success");
                  setTimeout(() => {
                    lessonTitleRef.current?.scrollIntoView({
                      behavior: "smooth",
                      block: "start", // This ensures it scrolls to the top of the element
                    });
                  }, 100);
                }}
              />

              <Box sx={{ mt: 8 }}>
                {isEditingTitle ? (
                  <TextField
                    value={titleText}
                    onChange={(e) => setTitleText(e.target.value)}
                    onBlur={() => {
                      setIsEditingTitle(false);
                      showSnackbar("Titeln har uppdaterats", "success");
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        setIsEditingTitle(false);
                        showSnackbar("Titeln har uppdaterats", "success");
                      }
                    }}
                    autoFocus
                    fullWidth
                    variant="standard"
                    sx={{
                      backgroundColor: "transparent",
                      "& .MuiInputBase-input": {
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "2.6em",
                        marginBottom: "1.2rem",
                        marginTop: "1.5rem",
                      },
                    }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                ) : (
                  <Box
                    sx={{
                      position: "relative",
                      display: "inline-block",
                      margin: "0 auto",
                      width: "100%",
                      mb: 3,
                      mt: 5,
                      ":hover .edit-icon": {
                        opacity: 1,
                      },
                    }}
                  >
                    <Typography
                      variant="h1"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "2.6em",
                        cursor: "pointer",
                        textAlign: "center",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 2,
                      }}
                    >
                      {titleText || " "}
                      <Box
                        component="span"
                        sx={{
                          opacity: 0,
                          transition: "opacity 0.2s",
                          display: viewMode ? "none" : "inline-flex",
                          gap: 0.5,
                        }}
                        className="edit-icon"
                      >
                        <Tooltip title="Redigera" placement="top">
                          <IconButton
                            size="small"
                            onClick={() => setIsEditingTitle(true)}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Typography>
                  </Box>
                )}

                <Box
                  sx={{
                    ...styles.freshBox,
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 3,
                    borderRadius: "13px",
                    backgroundColor:
                      theme.palette.mode === "dark"
                        ? "#1a2027"
                        : viewMode
                          ? "#ffffff"
                          : "#f9f9f94f",
                  }}
                >
                  {isEditingScenario ? (
                    <TextField
                      fullWidth
                      multiline
                      defaultValue={mysteryText.description}
                      onBlur={(e) => handleEditScenario(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" && !e.shiftKey) {
                          e.preventDefault();
                          handleEditScenario(e.target.value);
                        }
                      }}
                      autoFocus
                      sx={{
                        mb: 3,
                        "& .MuiInputBase-input": {
                          fontSize: "20px",
                          fontWeight: "500",

                          color:
                            theme.palette.mode === "dark" ? "white" : "#555",
                          lineHeight: "normal",
                        },
                      }}
                    />
                  ) : (
                    <Box
                      sx={{
                        "&:hover .controls": {
                          opacity: 1,
                        },
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        mb: 3,
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "500",
                          fontSize: "20px",

                          color:
                            theme.palette.mode === "dark" ? "white" : "#555",
                          flex: 1,
                          padding: "20px",
                          lineHeight: "33px",
                        }}
                      >
                        {mysteryText.description}
                      </Typography>
                      <Box
                        className="controls"
                        sx={{
                          opacity: 0,
                          transition: "opacity 0.2s",
                          display: viewMode ? "none" : "inline-flex",
                          gap: 0.5,
                        }}
                      >
                        <Tooltip title="Redigera" placement="top">
                          <IconButton
                            size="small"
                            onClick={() => setIsEditingScenario(true)}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Box>
                  )}

                  <Box
                    sx={{
                      position: "relative",
                      width: 500,
                      height: 500,
                      mt: 5,
                    }}
                  >
                    <HoverableImage
                      imageUrl={generatedImage}
                      isLoading={isGeneratingImage}
                      onGenerateClick={() => handleImageButtonClick("topic")}
                      userId={user.uid}
                      onImageUpdate={(url) => setGeneratedImage(url)}
                      height="500px"
                      theme={theme}
                      showSnackbar={showSnackbar}
                    />
                  </Box>

                  <Box sx={{ width: "100%", mt: 8 }}>
                    {Array(lineCount)
                      .fill(0)
                      .map((_, lineIndex) => (
                        <Box
                          key={lineIndex}
                          sx={{
                            borderBottom: `2px solid black`,
                            mb: `${lineHeight}px`,
                            position: "relative",
                            "&::after": {
                              // content: '""',
                              // position: 'absolute',
                              // top: -10,
                              // left: 0,
                              // right: 0,
                              // height: '1px',
                              // backgroundColor: 'rgba(0,0,0,0.2)',
                            },
                          }}
                        />
                      ))}
                  </Box>
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Box>

      <SlideInPaper
        isExpanded={isExpanded}
        onExpandedChange={setIsExpanded}
        lessonTitleRef={lessonTitleRef}
        viewMode={viewMode}
        toggleViewMode={toggleViewMode}
        theme={theme}
        open={showControlPanel}
        user={user}
        onPrint={() =>
          handleMysticImageWritingLesson(
            [{ image: generatedImage }],
            lineCount,
            lineHeight,
            themeColor,

            mysteryText?.description,
            titleText,
          )
        }
        onSaveAsPdf={() => pdfButtonRef.current?.click()}
        onShareLink={() => shareLinkButtonRef.current?.click()}
        onTranslate={() => translateButtonRef.current?.click()}
        onShareWithStudents={() => setShareDialogOpen(true)}
        onShareWithFriends={() => setShareDialogOpenFriends(true)}
        onSaveToBank={() => setSaveDialogOpen(true)}
        onStudentMode={() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
          toggleViewMode();
        }}
      />

      <ShareDialog
        open={shareDialogOpen}
        onClose={() => setShareDialogOpen(false)}
        onShare={handleShare}
        user={user}
        isLessonSharing={true}
        theme={theme}
        lessonName={titleText}
        lessonObject={{
          mysteryText,
          generatedImage,
          titleText,
        }}
        lessonType={"MysticImageLesson.js"}
      />

      <ShareDialog
        open={shareDialogOpenFriends}
        onClose={() => setShareDialogOpenFriends(false)}
        onShare={handleShare}
        user={user}
        isLessonSharing={true}
        theme={theme}
        lessonName={titleText}
        lessonObject={{
          mysteryText,
          generatedImage,
          titleText,
        }}
        lessonType={"MysticImageLesson.js"}
        shareWithFriends={true}
      />
      <LoadingBackdrop
        isOpen={isSharing}
        imagePath="/img/AI.png"
        loadingText={"Delar lektionen..."}
      />
      <ImageLoadingDialog
        open={isGeneratingImage}
        message="Genererar bilder... Tar 30-60 sekunder."
      />
    </Box>
  );
};

export default MysticImageLesson;
