import React, { useState, useCallback, useEffect } from "react";
import { createRoot } from "react-dom/client";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import App from "./App";
import { SnackbarProvider } from "./app/SnackbarHandler";
import { useAuth } from "./app/useAuth";

// Base configuration for shared properties across themes
const baseThemeConfig = {
  typography: {
    fontFamily:
      "Poppins, Open Sans, -apple-system, system-ui, ui-sans-serif, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, Helvetica, Poppins, sans-serif",
    formLabel: {
      paddingBottom: "1px",
      display: "inline-block",
    },
  },
  components: {
    MuiMenu: {
      defaultProps: {
        TransitionProps: { timeout: 0 },
      },
    },
    MuiTooltip: {
      defaultProps: {
        enterDelay: 0,
        leaveDelay: 0,
        enterTouchDelay: 0,
        arrow: true,
      },
      styleOverrides: {
        tooltip: {
          fontSize: "13px",
          padding: "8px",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          "& ul, & ol": {
            margin: "0 !important",
            padding: "0",
          },
          "& ul": {
            paddingLeft: "20px",
            paddingTop: "10px",
          },
          "& ol": {
            paddingLeft: "20px",
          },
          "& li": {
            margin: "0px",
            paddingTop: "5px",
            "& p": {
              margin: "0",
              padding: "0",
            },
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: "12px",
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          margin: "20px",
        },
      },
    },
  },
};

const themeConfigs = {
  light: {
    palette: {
      mode: "light",

      primary: {
        main: "#6D31ED",
        light: "#8F5EF1",
        dark: "#5A28C4",
      },
      secondary: {
        main: "#403838",
      },

      background: {
        default: "#f1f5f9",
        paper: "#ffffff",
        paperHover: "#f9f9f9",
      },
      text: {
        primary: "#333333",
        main: "#8b5cf6",

        inverse: "#000000",
        marked: "#32117b",
        white: "#ffffff",
      },
      grayClean: "#8F9398",
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            backgroundColor: "#f1f5f9",
          },
        },
      },

      MuiTab: {
        styleOverrides: {
          root: {
            padding: "20px",
            textTransform: "none",
            fontSize: "1rem",

            "&.Mui-selected": {
              color: "#333", // Change this to your desired color
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          indicator: {
            // This is the key change - use 'indicator' instead of targeting it with CSS
            backgroundColor: "#8b5cf6",
            height: 2,
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              color: "#8b5cf6",
              backgroundColor: "#eee9ff",
            },
            "&.Mui-selected:hover": {
              color: "#8b5cf6",
              backgroundColor: "#eee9ff",
            },
            "&:hover": {
              color: "#8b5cf6",
              backgroundColor: "#eee9ff",
              borderRadius: "0px !important",
            },
            "&:hover .MuiListItemIcon-root": {
              color: "#8b5cf6",
              backgroundColor: "#eee9ff",
            },
            "&.tight": {
              py: 0,
              paddingTop: "3px",
              paddingBottom: "3px",
              "&:not(:last-child)": {
                mb: "3px",
              },
              borderRadius: "7px",
              "&:hover": {
                borderRadius: "7px",
                "& .MuiListItemIcon-root": {
                  color: "#8b5cf6",
                },
                "& .MuiTypography-root": {
                  color: "#000",
                  fontWeight: "400",
                },
              },
            },
            "&.rounded": {
              borderRadius: "20px !important",
              "&:hover": {
                borderRadius: "20px !important",
              },
              "&.Mui-selected, &.Mui-selected:hover": {
                borderRadius: "20px !important",
              },
            },
            "&.lightBg": {
              backgroundColor: "rgba(139, 92, 246, 0.05)",
              marginBottom: "7px",
              borderRadius: "20px !important",
              "&:hover": {
                borderRadius: "20px !important",
                backgroundColor: "rgba(139, 92, 246, 0.1)",
              },
              "&.Mui-selected, &.Mui-selected:hover": {
                borderRadius: "20px !important",
              },
            },
          },
        },
        variants: [
          {
            props: { variant: "compactList" },
            style: {
              borderRadius: "20px",
              "& .MuiListItemIcon-root": {
                minWidth: "32px",
                paddingRight: "8px",
              },
              "& .MuiSvgIcon-root": {
                fontSize: "17px",
              },
              "& .MuiListItemText-primary": {
                fontSize: "15px",
              },
              "& .MuiTypography-body2": {
                marginLeft: "auto",
              },
            },
          },
        ],
      },
      MuiButton: {
        styleOverrides: {
          turquoise: {
            backgroundColor: "#20B2AA",
            borderBottom: "3px solid rgba(37, 37, 37, 0.3);",
            color: "#FFFFFF",
            "&:hover": {
              backgroundColor: "#25CDC4",
            },
            "&:active": {
              borderBottom: "0px solid rgba(37, 37, 37, 0.3);",
            },
            "&:disabled": {
              backgroundColor: "rgba(32, 178, 170, 0.1)",
              color: "#7FA9A6",
            },
          },
          yellow: {
            backgroundColor: "#FFB700",
            borderBottom: "3px solid rgba(37, 37, 37, 0.3);",
            color: "#000000",
            "&:hover": {
              backgroundColor: "rgb(255, 165, 0)",
            },
            "&:active": {
              borderBottom: "0px solid rgba(37, 37, 37, 0.3);",
            },
            "&:disabled": {
              backgroundColor: "#FFE0A3",
              color: "#8C7355",
            },
          },
          blue: {
            backgroundColor: "#0B70A2",
            borderBottom: "3px solid rgba(37, 37, 37, 0.3);",
            color: "#FFFFFF",
            "&:hover": {
              backgroundColor: "#1A91DA",
            },
            "&:active": {
              borderBottom: "0px solid rgba(37, 37, 37, 0.3);",
            },
            "&:disabled": {
              backgroundColor: "#38444D",
              color: "#8899A6",
            },
          },
          containedPrimary: {
            color: "#32324d",
            backgroundColor: "white",
            border: "1px solid #e2e8f0",
            borderRadius: "8px",
            boxShadow: "none",
            "&:hover": {
              borderColor: "#8d37ff",
              color: "#8d37ff",
              backgroundColor: "white",
              boxShadow: "none",
            },
          },
          containedSecondary: {
            color: "#d32f2f",
            backgroundColor: "white",
            border: "1px solid #e2e8f0",
            borderRadius: "8px",
            boxShadow: "none",
            "&:hover": {
              borderColor: "#d32f2f",
              color: "#d32f2f",
              backgroundColor: "white",
              boxShadow: "none",
            },
          },
          outlined: {
            color: "#32324d",
            backgroundColor: "#f9f9f9",
            border: "1px solid #e2e8f0",
            borderRadius: "8px",
            boxShadow: "none",
            "&:hover": {
              borderColor: "#8d37ff",
              color: "#8d37ff",
              backgroundColor: "white",
              boxShadow: "none",
            },
          },
        },
        variants: [
          // {
          //   props: { variant: 'purp' },
          //   style: {
          //     backgroundColor: '#8b5cf6',
          //     color: 'white',
          //     border: '1px solid transparent',
          //     borderRadius: '8px',
          //     boxShadow: 'none',
          //     '&:hover': {
          //       backgroundColor: '#6029dd',
          //     },
          //     '&:disabled': {
          //       backgroundColor: '#c4b5fd',
          //       color: 'rgba(255, 255, 255, 0.7)',
          //     },
          //   },
          // },

          {
            props: { variant: "purp" },
            style: {
              borderRadius: "12px",
              padding: "10px 22px",
              background: "linear-gradient(135deg, #6366f1 0%, #8b5cf6 100%)",
              color: "white",
              fontWeight: 600,
              borderBottom: "3px solid rgba(37, 37, 37, 0.3);",
              fontSize: "1rem",
              textTransform: "none",
              "&:active": {
                borderBottom: "0px solid rgba(37, 37, 37, 0.3);",
              },
              "&:hover": {
                background: "linear-gradient(135deg, #4f46e5 0%, #7c3aed 100%)",
              },
              "&:disabled": {
                background: "#9e9e9e",
                color: "#f2f2f2",
              },
            },
          },

          {
            props: { variant: "error-gradient" },
            style: {
              borderRadius: "12px",
              padding: "10px 22px",
              background: "linear-gradient(135deg, #ff6b6b 0%, #FF4D4D 100%)",
              color: "white",
              fontWeight: 600,
              borderBottom: "3px solid rgba(37, 37, 37, 0.3);",
              fontSize: "1rem",
              textTransform: "none",
              "&:active": {
                borderBottom: "0px solid rgba(37, 37, 37, 0.3);",
              },
              "&:hover": {
                background: "linear-gradient(135deg, #ff5252 0%, #e60000 100%)",
              },
              "&:disabled": {
                background: "#9e9e9e",
                color: "#f2f2f2",
              },
            },
          },
          {
            props: { variant: "warning-gradient" },
            style: {
              borderRadius: "12px",
              padding: "10px 22px",
              background: "linear-gradient(135deg, #ffd700 0%, #CC8400 100%)",
              color: "white",
              fontWeight: 600,
              fontSize: "1rem",
              borderBottom: "3px solid rgba(37, 37, 37, 0.3);",
              textTransform: "none",
              "&:active": {
                borderBottom: "0px solid rgba(37, 37, 37, 0.3);",
              },
              "&:hover": {
                background: "linear-gradient(135deg, #ffcc00 0%, #b37300 100%)",
              },
              "&:disabled": {
                background: "#9e9e9e",
                color: "#f2f2f2",
              },
            },
          },
          {
            props: { variant: "info-gradient" },
            style: {
              borderRadius: "12px",
              padding: "10px 22px",
              background: "linear-gradient(135deg, #29b6f6 0%, #0077AA 100%)",
              color: "white",
              fontWeight: 600,
              fontSize: "1rem",
              borderBottom: "3px solid rgba(37, 37, 37, 0.3);",
              textTransform: "none",
              "&:active": {
                borderBottom: "0px solid rgba(37, 37, 37, 0.3);",
              },
              "&:hover": {
                background: "linear-gradient(135deg, #0288d1 0%, #005577 100%)",
              },
              "&:disabled": {
                background: "#9e9e9e",
                color: "#f2f2f2",
              },
            },
          },
          {
            props: { variant: "success-gradient" },
            style: {
              borderRadius: "12px",
              padding: "10px 22px",
              background: "linear-gradient(135deg, #2e7d32 0%, #007700 100%)",
              color: "white",
              fontWeight: 600,
              fontSize: "1rem",
              borderBottom: "3px solid rgba(37, 37, 37, 0.3);",
              textTransform: "none",
              "&:active": {
                borderBottom: "0px solid rgba(37, 37, 37, 0.3);",
              },
              "&:hover": {
                background: "linear-gradient(135deg, #1b5e20 0%, #004400 100%)",
              },
              "&:disabled": {
                background: "#9e9e9e",
                color: "#f2f2f2",
              },
            },
          },

          {
            props: { variant: "print" },
            style: {
              borderRadius: "12px",
              padding: "10px 22px",
              background:
                "linear-gradient(to right, rgb(229 231 235) 0%, rgb(209 213 219) 50%, rgb(229 231 235) 100%)",
              color: "rgb(17 24 39)", // text-gray-900
              fontWeight: 600,
              fontSize: "1rem",
              borderBottom: "3px solid rgba(37, 37, 37, 0.3);",
              textTransform: "none",
              "&:active": {
                borderBottom: "0px solid rgba(37, 37, 37, 0.3);",
              },
              "&:hover": {
                background:
                  "linear-gradient(to right, rgb(209 213 219) 0%, rgb(176 180 186) 50%, rgb(209 213 219) 100%)",
              },
              "&:disabled": {
                background: "#9e9e9e",
                color: "#f2f2f2",
              },
            },
          },
          {
            props: { variant: "save" },
            style: {
              borderRadius: "12px",
              padding: "10px 22px",
              background: "linear-gradient(135deg, #0ea5e9 0%, #0284c7 100%)", // sky blue colors
              color: "white",
              fontWeight: 600,
              fontSize: "1rem",
              textTransform: "none",
              borderBottom: "3px solid rgba(37, 37, 37, 0.3);",
              "&:hover": {
                background: "linear-gradient(135deg, #0369a1 0%, #0c4a6e 100%)",
              },
              "&:disabled": {
                background: "#9e9e9e",
                color: "#f2f2f2",
              },
            },
          },

          {
            props: { variant: "success" },
            style: {
              borderRadius: "12px",
              padding: "10px 22px",
              background: "linear-gradient(135deg, #10b981 0%, #059669 100%)", // emerald colors
              color: "white",
              fontWeight: 600,
              fontSize: "1rem",
              borderBottom: "3px solid rgba(37, 37, 37, 0.3);",
              textTransform: "none",
              "&:hover": {
                background: "linear-gradient(135deg, #059669 0%, #047857 100%)",
              },
              "&:disabled": {
                background: "#9e9e9e",
                color: "#f2f2f2",
              },
            },
          },
        ],
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            backgroundColor: "#f0f0f03d",
            borderRadius: "15px",
            "& .MuiInputBase-root": {
              color: "#333",
            },
            "& .MuiInputLabel-root": {
              color: "#333",
            },
            "& .MuiOutlinedInput-root": {
              borderRadius: "15px",
              "& .MuiOutlinedInput-notchedOutline": {
                borderWidth: "1px",
                borderColor: "#ededed",
                borderRadius: "15px",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderWidth: "1px",
                borderColor: "#d9d9d9",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderWidth: "1px",
                borderColor: "#ededed",
              },
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            backgroundColor: "#f0f0f03d",
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#ededed",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#80808069",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#80808069",
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: "15px",
          },
          notchedOutline: {
            borderWidth: "1px",
            borderRadius: "15px",
          },
        },
      },
    },
  },
  dark: {
    palette: {
      mode: "dark",

      background: {
        default: "#15202B",
        //paper: '#2c3e4f',
        paper: "#1d2e3d",
        paperHover: "#374451",
      },
      primary: {
        main: "#1DA1F2",
        light: "#4DB5F5",
        dark: "#1781C2",
      },
      text: {
        primary: "#FFFFFF",
        main: "#8b5cf6",
        secondary: "#8899A6",
        white: "#ffffff",
        inverse: "#e0e0e0",
        marked: "#0B70A2",
      },
      grayClean: "#8F9398",
    },
    typography: {
      formLabel: {
        borderBottom: "1px dotted white",
        paddingBottom: "1px",
        display: "inline-block",
      },
      formLabelSinUnderlined: {
        paddingBottom: "1px",
        display: "inline-block",
      },
    },
    components: {
      MuiAlert: {
        styleOverrides: {
          root: {
            borderRadius: 8,
            fontSize: "0.875rem",
            boxShadow:
              "0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)",
          },
          filledSuccess: {
            backgroundColor: "#33882b",
            color: "#fff !important",
          },
          filledError: {
            backgroundColor: "#d32f2f",
            color: "#fff",
          },
          filledWarning: {
            backgroundColor: "#ed6c02",
            color: "#fff",
          },
          filledInfo: {
            backgroundColor: "#0288d1",
            color: "#fff",
          },
        },
      },

      MuiDialog: {
        styleOverrides: {
          paper: {
            backgroundImage: "none",
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            backgroundImage: "none",
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            backgroundColor: "#1c262f",
            borderRadius: "15px",
            "& .MuiInputBase-root": {
              color: "#FFFFFF",
            },
            "& .MuiInputLabel-root": {
              color: "#8899A6",
            },
            "& .MuiOutlinedInput-root": {
              borderRadius: "15px",
              "& .MuiOutlinedInput-notchedOutline": {
                borderWidth: "1px",
                borderColor: "#3b4651",
                borderRadius: "15px",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderWidth: "1px",
                borderColor: "#515C68",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderWidth: "1px",
                borderColor: "#515C68",
              },
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            backgroundColor: "#1c262f",
            borderRadius: "15px",
            "& .MuiInputBase-root": {
              color: "#FFFFFF",
            },
            "& .MuiInputLabel-root": {
              color: "#8899A6",
            },
            "& .MuiOutlinedInput-root": {
              borderRadius: "15px",
              "& .MuiOutlinedInput-notchedOutline": {
                borderWidth: "1px",
                borderColor: "#3b4651",
                borderRadius: "15px",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderWidth: "1px",
                borderColor: "#1DA1F2",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderWidth: "1px",
                borderColor: "#1DA1F2",
              },
            },
          },
          icon: {
            color: "#FFFFFF",
          },
          selectMenu: {
            backgroundColor: "#1c262f",
            color: "#FFFFFF",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: "5px",
            textTransform: "none",
            fontWeight: 600,
            transition: "background-color 0.3s",
          },
          turquoise: {
            backgroundColor: "#20B2AA",
            borderBottom: "3px solid rgba(37, 37, 37, 0.3);",
            color: "#FFFFFF",
            "&:hover": {
              backgroundColor: "#25CDC4",
            },
            "&:active": {
              borderBottom: "0px solid rgba(37, 37, 37, 0.3);",
            },
            "&:disabled": {
              backgroundColor: "rgba(32, 178, 170, 0.1)",
              color: "#7FA9A6",
            },
          },
          yellow: {
            backgroundColor: "#FFB700",
            borderBottom: "3px solid rgba(37, 37, 37, 0.3);",
            color: "#000000",
            "&:hover": {
              backgroundColor: "rgb(255, 165, 0)",
            },
            "&:active": {
              borderBottom: "0px solid rgba(37, 37, 37, 0.3);",
            },
            "&:disabled": {
              backgroundColor: "#FFE0A3",
              color: "#8C7355",
            },
          },
          blue: {
            backgroundColor: "#0B70A2",
            borderBottom: "3px solid rgba(37, 37, 37, 0.3);",
            color: "#FFFFFF",
            "&:hover": {
              backgroundColor: "#1A91DA",
            },
            "&:active": {
              borderBottom: "0px solid rgba(37, 37, 37, 0.3);",
            },
            "&:disabled": {
              backgroundColor: "#38444D",
              color: "#8899A6",
            },
          },
          contained: {
            backgroundColor: "#0B70A2",
            borderBottom: "3px solid rgba(37, 37, 37, 0.3);",
            color: "#FFFFFF",
            "&:hover": {
              backgroundColor: "#1A91DA",
            },
            "&:active": {
              borderBottom: "0px solid rgba(37, 37, 37, 0.3);",
            },
            "&:disabled": {
              backgroundColor: "#38444D",
              color: "#8899A6",
            },
          },
          clean: {
            backgroundColor: "#1f2e3c",
            border: "1px solid #243240",
            color: "#d9d6d6",
            "&:hover": {
              backgroundColor: "#263442",
            },
            "&:active": {},
            "&:disabled": {
              backgroundColor: "#38444D",
              color: "#8899A6",
            },
          },
          outlined: {
            backgroundColor: "#3a454f",
            borderColor: "transparent",
            borderBottom: "3px solid #4f5b66",
            color: "#FFFFFF",
            "&:hover": {
              backgroundColor: "#3a454f",
              borderColor: "transparent",
              borderBottom: "3px solid #4f5b66",
            },
            "&:active": {
              backgroundColor: "#3a454f",
            },
            "&:disabled": {
              backgroundColor: "#384755",
              color: "#8899A6",
            },
          },
          startIcon: {
            marginRight: "8px",
          },
        },

        // Inside themeConfigs.dark.components.MuiButton:

        variants: [
          {
            props: { variant: "contained" },
            style: {
              borderRadius: "12px",
              padding: "10px 22px",
              background: "linear-gradient(135deg, #6366f1 0%, #8b5cf6 100%)",
              color: "white",
              fontWeight: 600,
              fontSize: "1rem",
              textTransform: "none",
              "&:hover": {
                background: "linear-gradient(135deg, #4f46e5 0%, #7c3aed 100%)",
              },
              "&:disabled": {
                background: "#9e9e9e",
                color: "#f2f2f2",
              },
            },
          },

          {
            props: { variant: "purp" },
            style: {
              borderRadius: "12px",
              padding: "10px 22px",
              background: "linear-gradient(135deg, #6366f1 0%, #8b5cf6 100%)",
              color: "white",
              fontWeight: 600,
              fontSize: "1rem",
              textTransform: "none",
              "&:hover": {
                background: "linear-gradient(135deg, #4f46e5 0%, #7c3aed 100%)",
              },
              "&:disabled": {
                background: "#9e9e9e",
                color: "#f2f2f2",
              },
            },
          },

          {
            props: { variant: "error-gradient" },
            style: {
              borderRadius: "12px",
              padding: "10px 22px",
              background: "linear-gradient(135deg, #ff6b6b 0%, #FF4D4D 100%)",
              color: "white",
              fontWeight: 600,
              fontSize: "1rem",
              textTransform: "none",
              "&:hover": {
                background: "linear-gradient(135deg, #ff5252 0%, #e60000 100%)",
              },
              "&:disabled": {
                background: "#9e9e9e",
                color: "#f2f2f2",
              },
            },
          },
          {
            props: { variant: "warning-gradient" },
            style: {
              borderRadius: "12px",
              padding: "10px 22px",
              background: "linear-gradient(135deg, #ffd700 0%, #CC8400 100%)",
              color: "white",
              fontWeight: 600,
              fontSize: "1rem",
              textTransform: "none",
              "&:hover": {
                background: "linear-gradient(135deg, #ffcc00 0%, #b37300 100%)",
              },
              "&:disabled": {
                background: "#9e9e9e",
                color: "#f2f2f2",
              },
            },
          },
          {
            props: { variant: "info-gradient" },
            style: {
              borderRadius: "12px",
              padding: "10px 22px",
              background: "linear-gradient(135deg, #29b6f6 0%, #0077AA 100%)",
              color: "white",
              fontWeight: 600,
              fontSize: "1rem",
              textTransform: "none",
              "&:hover": {
                background: "linear-gradient(135deg, #0288d1 0%, #005577 100%)",
              },
              "&:disabled": {
                background: "#9e9e9e",
                color: "#f2f2f2",
              },
            },
          },
          {
            props: { variant: "success-gradient" },
            style: {
              borderRadius: "12px",
              padding: "10px 22px",
              background: "linear-gradient(135deg, #2e7d32 0%, #007700 100%)",
              color: "white",
              fontWeight: 600,
              fontSize: "1rem",
              textTransform: "none",
              "&:hover": {
                background: "linear-gradient(135deg, #1b5e20 0%, #004400 100%)",
              },
              "&:disabled": {
                background: "#9e9e9e",
                color: "#f2f2f2",
              },
            },
          },
          {
            props: { variant: "save" },
            style: {
              borderRadius: "12px",
              padding: "10px 22px",
              background: "linear-gradient(135deg, #0ea5e9 0%, #0284c7 100%)", // sky blue colors
              color: "white",
              fontWeight: 600,
              fontSize: "1rem",
              textTransform: "none",
              "&:hover": {
                background: "linear-gradient(135deg, #0369a1 0%, #0c4a6e 100%)",
              },
              "&:disabled": {
                background: "#9e9e9e",
              },
            },
          },

          {
            props: { variant: "print" },
            style: {
              borderRadius: "12px",
              padding: "10px 22px",
              background:
                "linear-gradient(to right, rgb(229 231 235) 0%, rgb(209 213 219) 50%, rgb(229 231 235) 100%)",
              color: "rgb(17 24 39)", // text-gray-900
              fontWeight: 600,
              fontSize: "1rem",
              textTransform: "none",
              "&:hover": {
                background:
                  "linear-gradient(to right, rgb(209 213 219) 0%, rgb(176 180 186) 50%, rgb(209 213 219) 100%)",
              },
              "&:disabled": {
                background: "#9e9e9e",
                color: "#f2f2f2",
              },
            },
          },

          {
            props: { variant: "success" },
            style: {
              borderRadius: "12px",
              padding: "10px 22px",
              background: "linear-gradient(135deg, #10b981 0%, #059669 100%)", // emerald colors
              color: "white",
              fontWeight: 600,
              fontSize: "1rem",
              textTransform: "none",
              "&:hover": {
                background: "linear-gradient(135deg, #059669 0%, #047857 100%)",
              },
              "&:disabled": {
                background: "#9e9e9e",
                color: "#f2f2f2",
              },
            },
          },
        ],
      },

      MuiTooltip: {
        styleOverrides: {
          paper: {
            backgroundImage: "none",
          },
          tooltip: {
            fontSize: "13px",
            padding: "10px",
            backgroundColor: "rgba(97, 97, 97, 1)",
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            padding: 0,
            backgroundColor: "#192734",
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            padding: "12px 16px",
            transition: "background-color 0.2s",
            "&:hover": {
              backgroundColor: "#22303C",
              "& .MuiListItemText-primary, & .MuiListItemText-secondary": {
                color: "#fff",
              },
            },
            "&.tight": {
              py: 0,
              paddingTop: "3px",
              paddingBottom: "3px",
              "&:not(:last-child)": {
                mb: "3px",
              },
              borderRadius: "7px",
              "&:hover": {
                borderRadius: "7px !important",
                "& .MuiListItemIcon-root": {
                  color: "#8b5cf6",
                },
                "& .MuiTypography-root": {
                  color: "#fff",
                  fontWeight: "400",
                },
              },
            },
            "&.rounded": {
              borderRadius: "20px !important",
              "&:hover": {
                borderRadius: "20px !important",
              },
              "&.Mui-selected, &.Mui-selected:hover": {
                borderRadius: "20px !important",
              },
            },
            "&.lightBg": {
              backgroundColor: "rgba(255, 255, 255, 0.05)",
              marginBottom: "7px",
              borderRadius: "20px !important",
              "&:hover": {
                borderRadius: "20px !important",
                backgroundColor: "rgba(255, 255, 255, 0.1)",
              },
              "&.Mui-selected, &.Mui-selected:hover": {
                borderRadius: "20px !important",
              },
            },
          },
        },
        variants: [
          {
            props: { variant: "compactList" },
            style: {
              borderRadius: "20px",
              "& .MuiListItemIcon-root": {
                minWidth: "32px",
                paddingRight: "8px",
              },
              "& .MuiSvgIcon-root": {
                fontSize: "17px",
              },
              "& .MuiListItemText-primary": {
                fontSize: "15px",
              },
              "& .MuiTypography-body2": {
                marginLeft: "auto",
              },
            },
          },
        ],
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            color: "#1DA1F2",
            minWidth: "40px",
          },
        },
      },
      MuiListItemText: {
        styleOverrides: {
          primary: {
            color: "#FFFFFF",
          },
          secondary: {
            color: "#8899A6",
          },
        },
      },
    },
  },
  allakando: {
    palette: {
      mode: "light",
      themeType: "allakando",

      primary: {
        main: "#96c933",

        light: "#AED55C",
        dark: "#78A129",

        hover: "#7ba329",

        paper: "#f9f9f9",
        paperHover: "#ffffff",
      },
      background: {
        default: "#f6f6f6",
        paper: "#ffffff",
        card: "#f4faeb",
        cardHover: "rgba(150, 201, 51, 0.1)",
      },
      text: {
        primary: "#535353",
        secondary: "#64748b",
        main: "#96c933",
        inverse: "#000000",
        marked: "#4a6d06",
        white: "#ffffff",
      },
      label: {
        background: "#d4eab5",
        text: "#7b9c2b",
      },
      action: {
        hover: "rgba(0, 0, 0, 0.04)",
        selected: "rgba(0, 0, 0, 0.08)",
        disabled: "rgba(0, 0, 0, 0.26)",
        disabledBackground: "rgba(0, 0, 0, 0.12)",
      },
      grayClean: "#8F9398",
    },

    typography: {
      fontFamily:
        '"Roboto Condensed", Poppins, Open Sans, -apple-system, system-ui, ui-sans-serif, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, Helvetica, Poppins, sans-serif',
    },

    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            backgroundColor: "#f6f6f6",
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              color: "#96c933",
              backgroundColor: "#f4faeb",
            },
            "&.Mui-selected:hover": {
              color: "#96c933",
              backgroundColor: "#f4faeb",
            },
            "&:hover": {
              color: "#96c933",
              backgroundColor: "#f4faeb",
              borderRadius: "0px !important",
            },
            "&:hover .MuiListItemIcon-root": {
              color: "#96c933",
              backgroundColor: "#f4faeb",
            },
            "&.tight": {
              py: 0,
              paddingTop: "3px",
              paddingBottom: "3px",
              "&:not(:last-child)": {
                mb: "3px",
              },
              borderRadius: "7px",
              "&:hover": {
                borderRadius: "7px",
                "& .MuiListItemIcon-root": {
                  color: "#96c933",
                },
                "& .MuiTypography-root": {
                  color: "#000",
                  fontWeight: "400",
                },
              },
            },
            "&.rounded": {
              borderRadius: "20px !important",
              "&:hover": {
                borderRadius: "20px !important",
              },
              "&.Mui-selected, &.Mui-selected:hover": {
                borderRadius: "20px !important",
              },
            },
            "&.lightBg": {
              backgroundColor: "rgba(139, 92, 246, 0.05)",
              marginBottom: "7px",
              borderRadius: "20px !important",
              "&:hover": {
                borderRadius: "20px !important",
                backgroundColor: "rgba(139, 92, 246, 0.1)",
              },
              "&.Mui-selected, &.Mui-selected:hover": {
                borderRadius: "20px !important",
              },
            },
          },
        },
        variants: [
          {
            props: { variant: "compactList" },
            style: {
              borderRadius: "20px",
              "& .MuiListItemIcon-root": {
                minWidth: "32px",
                paddingRight: "8px",
              },
              "& .MuiSvgIcon-root": {
                fontSize: "17px",
              },
              "& .MuiListItemText-primary": {
                fontSize: "15px",
              },
              "& .MuiTypography-body2": {
                marginLeft: "auto",
              },
            },
          },
        ],
      },
      MuiButton: {
        styleOverrides: {
          turquoise: {
            backgroundColor: "#20B2AA",
            borderBottom: "3px solid rgba(37, 37, 37, 0.3);",
            color: "#FFFFFF",
            "&:hover": {
              backgroundColor: "#25CDC4",
            },
            "&:active": {
              borderBottom: "0px solid rgba(37, 37, 37, 0.3);",
            },
            "&:disabled": {
              backgroundColor: "rgba(32, 178, 170, 0.1)",
              color: "#7FA9A6",
            },
          },
          yellow: {
            backgroundColor: "#FFB700",
            borderBottom: "3px solid rgba(37, 37, 37, 0.3);",
            color: "#000000",
            "&:hover": {
              backgroundColor: "rgb(255, 165, 0)",
            },
            "&:active": {
              borderBottom: "0px solid rgba(37, 37, 37, 0.3);",
            },
            "&:disabled": {
              backgroundColor: "#FFE0A3",
              color: "#8C7355",
            },
          },
          blue: {
            backgroundColor: "#0B70A2",
            borderBottom: "3px solid rgba(37, 37, 37, 0.3);",
            color: "#FFFFFF",
            "&:hover": {
              backgroundColor: "#1A91DA",
            },
            "&:active": {
              borderBottom: "0px solid rgba(37, 37, 37, 0.3);",
            },
            "&:disabled": {
              backgroundColor: "#38444D",
              color: "#8899A6",
            },
          },
          containedPrimary: {
            color: "#32324d",
            backgroundColor: "white",
            border: "1px solid #e2e8f0",
            borderRadius: "8px",
            boxShadow: "none",
            "&:hover": {
              borderColor: "#8d37ff",
              color: "#8d37ff",
              backgroundColor: "white",
              boxShadow: "none",
            },
          },
          containedSecondary: {
            color: "#d32f2f",
            backgroundColor: "white",
            border: "1px solid #e2e8f0",
            borderRadius: "8px",
            boxShadow: "none",
            "&:hover": {
              borderColor: "#d32f2f",
              color: "#d32f2f",
              backgroundColor: "white",
              boxShadow: "none",
            },
          },
          outlined: {
            color: "#32324d",
            backgroundColor: "#f9f9f9",
            border: "1px solid #e2e8f0",
            borderRadius: "8px",
            boxShadow: "none",
            "&:hover": {
              borderColor: "#8d37ff",
              color: "#8d37ff",
              backgroundColor: "white",
              boxShadow: "none",
            },
          },
        },
        variants: [
          {
            props: { variant: "purp" },
            style: {
              backgroundColor: "#96c933",

              border: "1px solid transparent",
              borderRadius: "12px",
              padding: "10px 22px",

              boxShadow: "none",

              color: "white",
              fontWeight: 600,
              fontSize: "1rem",
              textTransform: "none",

              "&:hover": {
                backgroundColor: "#709f14",
              },
              "&:disabled": {
                backgroundColor: "#c4b5fd",
                color: "rgba(255, 255, 255, 0.7)",
              },
            },
          },
          {
            props: { variant: "print" },
            style: {
              borderRadius: "12px",
              padding: "10px 22px",
              background:
                "linear-gradient(to right, rgb(229 231 235) 0%, rgb(209 213 219) 50%, rgb(229 231 235) 100%)",
              color: "rgb(17 24 39)", // text-gray-900
              fontWeight: 600,
              fontSize: "1rem",
              textTransform: "none",
              "&:hover": {
                background:
                  "linear-gradient(to right, rgb(209 213 219) 0%, rgb(176 180 186) 50%, rgb(209 213 219) 100%)",
              },
              "&:disabled": {
                background: "#9e9e9e",
                color: "#f2f2f2",
              },
            },
          },
          {
            props: { variant: "error-gradient" },
            style: {
              borderRadius: "12px",
              padding: "10px 22px",
              background: "linear-gradient(135deg, #ff6b6b 0%, #FF4D4D 100%)",
              color: "white",
              fontWeight: 600,
              fontSize: "1rem",
              textTransform: "none",
              "&:hover": {
                background: "linear-gradient(135deg, #ff5252 0%, #e60000 100%)",
              },
              "&:disabled": {
                background: "#9e9e9e",
                color: "#f2f2f2",
              },
            },
          },
          {
            props: { variant: "warning-gradient" },
            style: {
              borderRadius: "12px",
              padding: "10px 22px",
              background: "linear-gradient(135deg, #ffd700 0%, #CC8400 100%)",
              color: "white",
              fontWeight: 600,
              fontSize: "1rem",
              textTransform: "none",
              "&:hover": {
                background: "linear-gradient(135deg, #ffcc00 0%, #b37300 100%)",
              },
              "&:disabled": {
                background: "#9e9e9e",
                color: "#f2f2f2",
              },
            },
          },
          {
            props: { variant: "info-gradient" },
            style: {
              borderRadius: "12px",
              padding: "10px 22px",
              background: "linear-gradient(135deg, #29b6f6 0%, #0077AA 100%)",
              color: "white",
              fontWeight: 600,
              fontSize: "1rem",
              textTransform: "none",
              "&:hover": {
                background: "linear-gradient(135deg, #0288d1 0%, #005577 100%)",
              },
              "&:disabled": {
                background: "#9e9e9e",
                color: "#f2f2f2",
              },
            },
          },
          {
            props: { variant: "success-gradient" },
            style: {
              borderRadius: "12px",
              padding: "10px 22px",
              background: "linear-gradient(135deg, #2e7d32 0%, #007700 100%)",
              color: "white",
              fontWeight: 600,
              fontSize: "1rem",
              textTransform: "none",
              "&:hover": {
                background: "linear-gradient(135deg, #1b5e20 0%, #004400 100%)",
              },
              "&:disabled": {
                background: "#9e9e9e",
                color: "#f2f2f2",
              },
            },
          },

          {
            props: { variant: "save" },
            style: {
              borderRadius: "12px",
              padding: "10px 22px",
              background: "linear-gradient(135deg, #0ea5e9 0%, #0284c7 100%)", // sky blue colors
              color: "white",
              fontWeight: 600,
              fontSize: "1rem",
              textTransform: "none",
              "&:hover": {
                background: "linear-gradient(135deg, #0369a1 0%, #0c4a6e 100%)",
              },
              "&:disabled": {
                background: "#9e9e9e",
                color: "#f2f2f2",
              },
            },
          },

          {
            props: { variant: "success" },
            style: {
              borderRadius: "12px",
              padding: "10px 22px",
              background: "linear-gradient(135deg, #10b981 0%, #059669 100%)", // emerald colors
              color: "white",
              fontWeight: 600,
              fontSize: "1rem",
              textTransform: "none",
              "&:hover": {
                background: "linear-gradient(135deg, #059669 0%, #047857 100%)",
              },
              "&:disabled": {
                background: "#9e9e9e",
                color: "#f2f2f2",
              },
            },
          },
        ],
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            backgroundColor: "#f0f0f03d",
            borderRadius: "15px",
            "& .MuiInputBase-root": {
              color: "#333",
            },
            "& .MuiInputLabel-root": {
              color: "#333",
            },
            "& .MuiOutlinedInput-root": {
              borderRadius: "15px",
              "& .MuiOutlinedInput-notchedOutline": {
                borderWidth: "1px",
                borderColor: "#ededed",
                borderRadius: "15px",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderWidth: "1px",
                borderColor: "#d9d9d9",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderWidth: "1px",
                borderColor: "#ededed",
              },
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            backgroundColor: "#f0f0f03d",
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#ededed",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#80808069",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#80808069",
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: "15px",
          },
          notchedOutline: {
            borderWidth: "1px",
            borderRadius: "15px",
          },
        },
      },
    },
  },
};

// Create the themes
const theme = createTheme(deepMerge(baseThemeConfig, themeConfigs.light));
const darkTheme = createTheme(deepMerge(baseThemeConfig, themeConfigs.dark));
const allakandoTheme = createTheme(
  deepMerge(baseThemeConfig, themeConfigs.allakando),
);

// Helper function for deep merging objects
function deepMerge(target, source) {
  const output = { ...target };
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key) => {
      if (isObject(source[key])) {
        if (!(key in target)) {
          Object.assign(output, { [key]: source[key] });
        } else {
          output[key] = deepMerge(target[key], source[key]);
        }
      } else {
        Object.assign(output, { [key]: source[key] });
      }
    });
  }
  return output;
}

function isObject(item) {
  return item && typeof item === "object" && !Array.isArray(item);
}

const createStyles = (theme) => {
  const getThemeValue = (lightVal, darkVal, allakandoVal) => {
    const { mode, themeType } = theme.palette;

    if (themeType === "allakando") {
      return allakandoVal;
    }

    switch (mode) {
      case "dark":
        return darkVal;
      case "light":
      default:
        return lightVal;
    }
  };

  return {
    lessonControlPanelNewButtons: {
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      width: "auto",
      p: 5,
      mt: "0 !important",
      ml: "0 !important",
      borderRadius: "0",
      textTransform: "none",
      fontSize: "1.1rem !important",

      fontWeight: "500 !important",
      color: "text.secondary",
      background: getThemeValue(
        "linear-gradient(180deg, #ffffff 0%, #f7f7f9 100%)",
        "#1d2e3d",
        "linear-gradient(180deg, #ffffff 0%, #f7f7f9 100%)",
      ),

      borderRight: getThemeValue(
        "1px solid #e0e0e0",
        "1px solid #263442",
        "1px solid #e0e0e0",
      ),

      transition: "none",
      "&:hover": {
        transition: "none",
        background: "rgba(176, 180, 186, 0.08)",
        color: getThemeValue("black", "white", "black"),
      },
    },

    lessonControlPanelNuevo: {
      padding: "0px",

      overflowX: "auto",
      overflowY: "hidden",
      height: "89px",
      whiteSpace: "nowrap",
      display: "block",
      minWidth: 0,
      position: "relative",
      "&::-webkit-scrollbar": {
        display: "block",
        height: "10px",
      },
      "&::-webkit-scrollbar-track": {
        background: getThemeValue("#f7f7f9", "#2f3235", "#f7f7f9"),

        border: getThemeValue(
          "1px solid #ededed",
          "1px solid #263442",
          "1px solid #ededed",
        ),

        borderRadius: "4px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: getThemeValue("#c1c1c1", "#4a4d51", "#c1c1c1"),

        borderRadius: "4px",
        border: getThemeValue(
          "1px solid #ededed",
          "1px solid #263442",
          "1px solid #ededed",
        ),
      },
    },

    lessonControlPanelFade: {
      position: "absolute",
      top: 0,
      right: "-30px",
      width: "100px",
      height: "89px",
      background: getThemeValue(
        "linear-gradient(90deg, transparent 0%, #ffffff 100%)",
        "linear-gradient(90deg, transparent 0%, #2a2d30 100%)",
        "linear-gradient(90deg, transparent 0%, #ffffff 100%)",
      ),

      pointerEvents: "none",
      zIndex: 10,
    },

    pageBg: {
      height: "100vh",
      backgroundColor: getThemeValue("#f1f5f9", "#15202B", "#f6f6f6"),
    },
    beautyBtnDos: {
      fontWeight: "normal",
      fontSize: "13px",
      backgroundColor: getThemeValue("#f9f9f9", "#373c44", "#f8fafc"),
      border: getThemeValue(
        "1px solid #eee",
        "1px solid #3b4651",
        "1px solid #e2e8f0",
      ),
      paddingTop: "2px",
      paddingBottom: "2px",
      fontWeight: "500",
      color: getThemeValue("#333", "#ffffff", "#1e293b"),
      "&:hover, &:focus, &:active, &.Mui-focusVisible": {
        border: getThemeValue(
          "1px solid #f9f9f9",
          "1px solid #3b4651",
          "1px solid #e2e8f0",
        ),
        backgroundColor: getThemeValue("#eee", "#424a54", "#f1f5f9"),
      },
    },

    sectionHeader: {
      marginBottom: 2,
      fontWeight: "bold",
    },

    listItem: {
      backgroundColor: getThemeValue("#f9f9f9", "#22303C", "#f8fafc"),
      borderRadius: 4,
      marginBottom: 1,
    },

    listItemIcon: {
      minWidth: "30px",
      marginRight: "8px",
    },

    iconContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },

    icon: {
      fontSize: 80,
    },

    typographyBody: {
      variant: "body2",
      textAlign: "center",
      fontWeight: "bold",
      marginTop: "7px",
    },

    typographyCaption: {
      variant: "caption",
      textAlign: "center",
      fontSize: "13px",
      color: getThemeValue("#444", "#8899A6", "#64748b"),
      marginTop: "7px",
    },

    hover: {
      backgroundColor: getThemeValue("#eee9ff", "#1f2a37", "#f1f5f9"),
      color: "#8b5cf6",
      "& svg": {
        color: "#8b5cf6",
      },
    },

    tightButton: {
      height: "36px",
      fontSize: "0.875rem",
      borderBottom: "0px",
      textTransform: "none",
    },

    successBox: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "300px",
      width: "100%",
      borderRadius: "16px",
      bgcolor:
        theme.palette.mode === "dark"
          ? "rgba(46, 125, 50, 0.15)"
          : "rgba(46, 125, 50, 0.08)",
      //transition: 'all 0.3s ease-in-out',
    },

    backButton: {
      zIndex: 1,
      bgcolor: "rgb(139, 92, 246)",
      px: 1.5,
      py: 0.5,
      borderRadius: "9px",
      display: "flex",
      alignItems: "center",
      gap: 1,
      cursor: "pointer",
      width: "fit-content",
      transition: "all 0.2s ease",
      "& .MuiSvgIcon-root": {
        color: "#ffffff",
      },
      "& .MuiTypography-root": {
        color: "#ffffff",
      },
      "&:hover": {
        bgcolor: "rgb(67, 20, 174)",
        "& .MuiSvgIcon-root": {
          color: "#fff",
        },
        "& .MuiTypography-root": {
          color: "#fff",
        },
      },
    },

    active: {
      backgroundColor: getThemeValue("#ddd6fe", "#22303c", "#f1f5f9"),
      color: getThemeValue("#7c3aed", "#1da1f2", "#6d28d9"),
      "& svg": {
        color: getThemeValue("#7c3aed", "#1da1f2", "#6d28d9"),
      },
    },

    formControlLabel: {
      "& .Mui-checked": {
        backgroundColor: "red",
        color: "red",
        "& .MuiSvgIcon-root": {
          color: "red",
        },
      },
    },

    editingTextField: {
      "& .MuiInputBase-root": {
        backgroundColor: getThemeValue("#fff", "#212c36", "#fff"),
      },
    },

    collapsableLessonFormOverlay: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      opacity: 0,
      transition: "opacity 0.2s",
      zIndex: 2,
      cursor: "pointer",
      borderRadius: "8px",
    },

    catBtn: {
      fontWeight: "normal",
      fontSize: "15px",
      backgroundColor: getThemeValue("#fff", "#2f3d4a", "#f8fafc"),
      border: `1px solid ${getThemeValue("#ededed", "#5c666f", "#e2e8f0")}`,
      paddingTop: { xs: "2px", md: "8px" },
      paddingBottom: { xs: "2px", md: "8px" },
      borderRadius: { xs: "30px", md: "5px" },
      fontWeight: "500",
      color: getThemeValue("#555", "#ffffff", "#64748b"),
      "&:hover, &:focus, &:active, &.Mui-focusVisible": {
        border: `1px solid ${getThemeValue("#f9f9f9", "#5c666f", "#e2e8f0")}`,
        backgroundColor: getThemeValue("#ededed", "#384755", "#f1f5f9"),
        color: getThemeValue("#333", "#ffffff", "#1e293b"),
      },
    },

    rowBg: {
      borderBottom: `1px solid ${getThemeValue("#e0e0e0", "#384959", "#e2e8f0")}`,
      background: getThemeValue("#f9f9f9", "#25323f", "#f8fafc"),
      marginBottom: "2px",
      borderRadius: "0px",
      paddingLeft: "10px",
      paddingRight: "10px",
      paddingTop: "10px",
      paddingBottom: "10px",
    },

    smallBox: {
      backgroundColor: getThemeValue("#fff", "#2a3b4c57", "#f8fafc"),
      borderRadius: "10px",
      width: "100%",
      padding: "10px",
      marginTop: "40px",
      marginBottom: "40px",
      border: `1px solid ${getThemeValue("#ededed", "#263442", "#e2e8f0")}`,
    },

    toggleButtonGroups: {
      marginBottom: "0px",
      borderRadius: "13px",
      overflow: "hidden",
    },

    firstToggle: {
      paddingLeft: { xs: "8px", md: "20px" },
      paddingRight: { xs: "8px", md: "20px" },
      borderTopLeftRadius: "13px",
      borderBottomLeftRadius: "13px",
    },

    lastToggle: {
      borderTopRightRadius: "13px",
      borderBottomRightRadius: "13px",
      paddingRight: { xs: "8px", md: "20px" },
      paddingLeft: { xs: "8px", md: "20px" },
    },

    lessonControlPanel: {
      padding: "20px",
      margin: "-30px",
      borderBottom:
        theme.palette.mode === "dark"
          ? "1px solid #263442"
          : "1px solid #ededed",
      backgroundColor: theme.palette.mode === "dark" ? "#22303c" : "#f9f9f9",
      borderTopLeftRadius: "7px",
      borderTopRightRadius: "7px",
      marginBottom: "50px",
    },

    controlPanelButtons: {
      height: "36px",
      fontSize: "0.875rem",
      borderBottom: "0px",
      textTransform: "none",
    },

    toggles: {
      paddingRight: { xs: "8px", md: "20px" },
      paddingLeft: { xs: "8px", md: "20px" },
      backgroundColor: getThemeValue("ddd6fe", "#15202b", "#f8fafc"),
      color: getThemeValue("white", "#8899a6", "#64748b"),
      "&:not(:first-child)": {
        borderLeft: `1px solid ${getThemeValue("#dbdbdb", "#38444d", "#e2e8f0")}`,
      },
      "&:not(:last-child)": {
        borderRight: `1px solid ${getThemeValue("#dbdbdb", "#38444d", "#e2e8f0")}`,
      },
      "& svg": {
        marginRight: "5px",
      },
      "&.Mui-root": {
        backgroundColor: getThemeValue("#ddd6fe", "#192734", "#f8fafc"),
        color: getThemeValue("#7c3aed", "#1da1f2", "#6d28d9"),
        "& svg": {
          color: getThemeValue("#7c3aed", "#1da1f2", "#6d28d9"),
          marginRight: "5px",
        },
      },
      "&.Mui-selected": {
        backgroundColor: getThemeValue("#eee9ff", "#22303c", "#f1f5f9"),
        color: getThemeValue("#6d28d9", "#1da1f2", "#7ba329"),
        "&:hover": {
          backgroundColor: getThemeValue("#eee9ff", "#22303c", "#f1f5f9"),
          color: getThemeValue("#6d28d9", "#1da1f2", "#7ba329"),
          "& svg": {
            color: getThemeValue("#6d28d9", "#1da1f2", "#7ba329"),
          },
        },
        "& svg": {
          color: getThemeValue("#6d28d9", "#1da1f2", "#7ba329"),
        },
      },
      "&:not(.Mui-selected)": {
        backgroundColor: getThemeValue("#f9f9f9", "#313539", "#f8fafc"),
        color: getThemeValue("#555", "#8899a6", "#64748b"),
        "& svg": {
          color: getThemeValue("#555", "#8899a6", "#64748b"),
        },
      },
      "&:hover": {
        backgroundColor: getThemeValue("#eee9ff", "#22303c", "#f1f5f9"),
        color: getThemeValue("#6d28d9", "#1da1f2", "#7ba329"),
        "& svg": {
          color: getThemeValue("#6d28d9", "#1da1f2", "#7ba329"),
        },
      },
    },

    beautyBtnTres: {
      color: getThemeValue("#666", "#ddd", "#64748b"),
      textTransform: "none",
      backgroundColor: getThemeValue("#f1f1f1", "#22303c", "#f8fafc"),
      border: "none",
      borderRadius: 10,
      padding: "4px 17px",
      display: "flex",
      alignItems: "center",
      "& .MuiButton-startIcon": {
        marginRight: "4px",
      },
      "& .MuiButton-endIcon": {
        marginLeft: "2px",
      },
      "&:hover": {
        backgroundColor: getThemeValue("#f1f1f1", "#1d252e", "#f1f5f9"),
        color: getThemeValue("black", "#bbb", "#1e293b"),
        border: "none",
        boxShadow: "none",
        "& .MuiSvgIcon-root": {
          color: getThemeValue("#505050", "#bbb", "#64748b"),
        },
      },
      "&:focus": {
        outline: "none",
        border: "none",
        boxShadow: "none",
      },
    },

    greenChip: {
      height: "27px",
      background: "rgba(39, 121, 43, 0.1)",
      color: "#27792B",
      ml: 2,
      paddingLeft: "2px",
      paddingRight: "2px",
      fontSize: "12px",
      fontWeight: 500,
      "& .MuiChip-icon": {
        fontSize: 16,
        color: "inherit",
      },
    },

    buttonsContainer: {
      display: "flex",
      gap: 1,
      flexWrap: "wrap",
      paddingBottom: 2,
      borderBottom: getThemeValue(
        "1px solid #e0e0e0",
        "1px solid #3b4651",
        "1px solid #e2e8f0",
      ),
    },

    headlineLessonBox: {
      mb: 2,
      height: "40px",
    },

    headlineLessonBoxTitle: {
      fontWeight: "600",
      fontSize: "1.45rem",
      textAlign: "center",
    },

    additionalButtonsContainer: {
      marginTop: { xs: "2px", md: "15px" },
      marginBottom: "0px",
      position: "absolute",
      right: { xs: "6px", md: "18px" },
      top: "5px",
      borderBottom: "0px",
      display: "flex",
      alignItems: "center",
    },

    textarea: {
      width: "100%",
    },

    deleteButton: {
      position: "absolute",
      right: "20px",
      top: "19px",
      right: "90px",
      minWidth: "auto",
    },

    smallerBtn: {
      color: getThemeValue("#333", "#8899a6", "#64748b"),
      textTransform: "none",
      backgroundColor: getThemeValue("white", "#313539", "#f8fafc"),
      border: "none",
      borderRadius: 10,
      padding: "4px 14px",
      display: "flex",
      alignItems: "center",
      position: "absolute",
      top: "22px",
      left: "130px",
      "&:hover": {
        backgroundColor: getThemeValue("#f1f1f1", "#22303c", "#f1f5f9"),
        color: getThemeValue("#505050", "#ffffff", "#1e293b"),
        border: "none",
        boxShadow: "none",
        "& .MuiSvgIcon-root": {
          color: getThemeValue("#505050", "#ffffff", "#64748b"),
        },
      },
      "&:focus": {
        outline: "none",
        border: "none",
        boxShadow: "none",
      },
    },

    purpleBtn: {
      color: getThemeValue("#fff", "#fff", "#fff"),
      textTransform: "none",
      backgroundColor: getThemeValue("white", "#3b4651", "#f8fafc"),
      border: "none",
      borderRadius: 10,
      display: "flex",
      alignItems: "center",
      display: "inline-block",
      margin: "5px",
      background: getThemeValue("#340d8c", "#3b4651", "#6d28d9"),
      fontSize: "15px",
      borderRadius: "20px",
      padding: "10px",
      paddingLeft: "25px",
      paddingRight: "25px",
      "& .MuiButton-startIcon": {
        marginRight: "4px",
      },
      "& .MuiButton-endIcon": {
        marginLeft: "2px",
      },
      "&:hover": {
        backgroundColor: getThemeValue("#8b5cf6", "#505050", "#5b21b6"),
        color: "#fff",
        border: "none",
        boxShadow: "none",
        "& .MuiSvgIcon-root": {
          color: getThemeValue("#505050", "#15202b", "#4c1d95"),
        },
      },
      "&:focus": {
        outline: "none",
        border: "none",
        boxShadow: "none",
      },
    },

    exampleBtn: {
      fontWeight: "500",
      fontSize: "15px",
      backgroundColor: getThemeValue("#f9f9f9", "#373c44", "#f8fafc"),
      border: getThemeValue(
        "1px solid #845ddb",
        "1px solid #845ddb",
        "1px solid #6d28d9",
      ),
      paddingTop: "4px",
      paddingBottom: "4px",
      color: getThemeValue("#333", "#ffffff", "#1e293b"),
      "&:hover, &:focus, &:active, &.Mui-focusVisible": {
        border: getThemeValue(
          "1px solid #eee",
          "1px solid #3b4651",
          "1px solid #e2e8f0",
        ),
        backgroundColor: getThemeValue("#f9f9f9", "#424a54", "#f1f5f9"),
      },
    },

    shrinkButton: {
      padding: "8px",
      marginRight: "0px",
      paddingLeft: "15px",
      paddingRight: "3px",
      minWidth: "auto",
      textTransform: "none",
    },

    bottomCtaTitle: {
      marginBottom: 2,
      fontWeight: "bold",
      fontSize: "30px",
    },

    bottomCtaBody: {
      marginBottom: 3,
      maxWidth: "600px",
      margin: "0 auto",
    },

    chooseToggle: {
      backgroundColor: getThemeValue(
        "white !important",
        "#15202b !important",
        "#f8fafc !important",
      ),
    },

    flexContainerSidebar: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
    },

    filterButton: {
      marginRight: "6px",
      textTransform: "normal",
    },

    whiteBg: {
      background: getThemeValue("white", undefined, "#f8fafc"),
    },

    unfamiliar: {
      backgroundColor: getThemeValue("#FFEBEB", "#661919", "#fef2f2"),
      color: getThemeValue("#FF4D4D", "#FF4D4D", "#dc2626"),
      padding: "20px",
      borderRadius: "5px",
      display: "flex",
      alignItems: "center",
      marginBottom: "15px",
      fontWeight: "bold",
    },

    stillLearning: {
      backgroundColor: getThemeValue("#FFEFD5", "#664511", "#fff7ed"),
      color: getThemeValue("#FFA500", "#FFA500", "#ea580c"),
      padding: "20px",
      borderRadius: "5px",
      display: "flex",
      alignItems: "center",
      marginBottom: "15px",
      fontWeight: "bold",
    },

    familiar: {
      backgroundColor: getThemeValue("#E0F7FA", "#204E5B", "#f0fdfa"),
      color: getThemeValue("#00BFFF", "#00BFFF", "#0d9488"),
      padding: "20px",
      borderRadius: "5px",
      display: "flex",
      alignItems: "center",
      marginBottom: "15px",
      fontWeight: "bold",
    },

    mastered: {
      backgroundColor: getThemeValue("#E0FFE0", "#204D20", "#f0fdf4"),
      color: getThemeValue("#00FF00", "#00FF00", "#16a34a"),
      padding: "20px",
      borderRadius: "5px",
      display: "flex",
      alignItems: "center",
      marginBottom: "15px",
      fontWeight: "bold",
    },

    container: {
      display: "flex",
      flexDirection: "column",
      height: "100vh",
      position: "relative",
    },

    innerContainer: {
      paddingRight: { xs: "10px", md: "24px" },
      paddingLeft: { xs: "10px", md: "0px" },
      paddingBottom: "50px",
    },

    flashcardFrontBackInput: {
      backgroundColor: "white",
      "& .MuiInputBase-input": {
        paddingTop: "35px",
        paddingBottom: "0px",
        fontWeight: "bold",
        textAlign: "center",
      },
    },

    menuItemStyle: {
      fontSize: "14px",
      "& .MuiListItemText-primary": {
        fontSize: "14px",
      },
      "& .MuiListItemIcon-root": {
        minWidth: "30px",
      },
      "& .MuiSvgIcon-root": {
        fontSize: "20px",
      },
    },

    softerBtn: {
      borderRadius: "30px",
      textTransform: "none",
      fontWeight: "400",
      borderBottom: getThemeValue(undefined, "none", undefined),
      "&:hover": {
        borderBottom: getThemeValue(undefined, "none", undefined),
      },
    },

    header: {
      marginBottom: 2,
      fontWeight: "bold",
      fontSize: { xs: "18px", md: "25px" },
      marginTop: { xs: 2.2, md: 3 },
      marginLeft: { xs: 6, md: 0 },
    },

    dashboardHeader: {
      fontSize: "18px",
      lineHeight: 1,
      margin: 0,
      padding: 0,
      marginTop: "0px",
      display: "flex",
      alignItems: "center",
      height: "100%",
      marginLeft: "0px",
    },

    headerAboveBox: {
      marginBottom: 1,
      fontWeight: "bold",
      fontSize: { xs: "18px", md: "25px" },
      marginTop: { xs: 2.2, md: 3 },
      marginLeft: 0,
    },

    headerChat: {
      fontWeight: "bold",
      fontSize: "25px",
      marginTop: 3,
      paddingBottom: 3,
      borderBottom: getThemeValue(
        "1px solid #e0e0e0",
        "1px solid #3b4651",
        "1px solid #e2e8f0",
      ),
    },

    h2: {
      fontSize: "16px",
      color: getThemeValue("#555", "rgb(219, 219, 219)", "#1e293b"),
      marginBottom: "10px",
    },

    h3: {
      fontSize: "22px",
      fontWeight: "600",
      marginBottom: "00px",
    },

    h4: {
      fontSize: "14px",
      color: getThemeValue("#444", "rgb(219, 219, 219)", "#1e293b"),
      marginBottom: "10px",
    },

    h5: {
      fontSize: "14px",
      color: getThemeValue("#444", "rgb(219, 219, 219)", "#1e293b"),
      marginBottom: "10px",
    },

    grayText: {
      color: getThemeValue("#7b7b7b", "#cecece", "#64748b"),
    },

    softerText: {
      color: getThemeValue("#4a5568", "rgba(255, 255, 255, 0.7)", "#64748b"),
    },

    softerBorder: {
      border: getThemeValue(
        "1px solid #eee",
        "1px solid #35414d",
        "1px solid #e2e8f0",
      ),
    },

    cardText: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100%",
      padding: "20px",
      boxSizing: "border-box",
      border: getThemeValue(
        "1px solid #cccccc69",
        "1px solid #3b4651",
        "1px solid #e2e8f0",
      ),
      borderRadius: "10px",
      backgroundColor: getThemeValue("#fff", "#1b2735", "#f8fafc"),
      boxShadow: getThemeValue(
        "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        "",
        "rgba(0, 0, 0, 0.05) 0px 1px 3px",
      ),
    },

    inputContainer: {
      padding: 2,
      display: "flex",
      gap: 1,
      position: "fixed",
      bottom: 0,
      left: "calc(19% + 7px)",
      width: "calc(100% - 19% - 7px)",
      zIndex: 10,
    },

    messageContainer: {
      flex: 1,
      overflowY: "auto",
      marginBottom: "120px",
      marginTop: "0px",
    },

    loaderContainer: {
      marginTop: 2,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },

    buttonWrapper: {
      display: "flex",
      justifyContent: "flex-start",
      gap: "8px",
    },

    exportButton: {
      marginRight: 1,
      minWidth: "120px",
      height: "42px",
      padding: "8px 16px",
    },

    deleteButtonNotes: {
      marginBottom: 2,
      minWidth: "180px",
      padding: "8px 16px",
    },

    paper: {
      marginBottom: 3,
      padding: 2,
    },

    card: {
      width: 250,
      height: 200,
      padding: 2,
      display: "flex",
      borderRadius: "10px",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: 2,
      backgroundColor: getThemeValue("#ffffff", "#15202b", "#f8fafc"),
      color: getThemeValue("#000000", "#ffffff", "#1e293b"),
      borderColor: getThemeValue("black", "#444", "#e2e8f0"),
      "& svg": {
        color: getThemeValue("#666", "#bbb", "#64748b"),
      },
      transition: "background-color 0.1s, color 0.1s",

      selected: {
        width: 250,
        height: 200,
        padding: 2,
        display: "flex",
        backgroundColor: getThemeValue("#eee9ff", "#3c3f41", "#f1f5f9"),
        color: "#8b5cf6",
        borderColor: getThemeValue("black", "#555", "#e2e8f0"),
        borderRadius: "10px",
        border: "2px solid #777",
        boxShadow: "none",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 2,
      },

      "&:hover": {
        backgroundColor: getThemeValue("#eee9ff", "#1f2a37", "#f1f5f9"),
        color: "#8b5cf6",
        "& svg": {
          color: "#8b5cf6",
        },
      },
      "&:focus": {
        backgroundColor: getThemeValue("#eee9ff", "#1f2a37", "#f1f5f9"),
        color: "#8b5cf6",
        "& svg": {
          color: "#8b5cf6",
        },
      },
      "&:focus-within": {
        backgroundColor: getThemeValue("black", "#1b2735", "#f1f5f9"),
      },
    },

    cardClicked: {
      backgroundColor: getThemeValue("black", "#1b2735", "#f1f5f9"),
      fontSize: "10px",
    },

    roundMenu: {
      borderRadius: "20px",
    },

    roundMenuItem: {
      "&:hover": {
        backgroundColor: getThemeValue("#eee9ff", "black", "#f1f5f9"),
        color: "#6d28d9",
      },
    },

    beautyBtn: {
      color: getThemeValue("#666", "#ddd", "#64748b"),
      textTransform: "none",
      backgroundColor: getThemeValue("#f1f1f1", "#2a2f38", "#f8fafc"),
      border: "none",
      borderRadius: 10,
      padding: "4px 17px",
      display: "flex",
      alignItems: "center",
      "& .MuiButton-startIcon": {
        marginRight: "4px",
      },
      "& .MuiButton-endIcon": {
        marginLeft: "2px",
      },
      "&:hover": {
        backgroundColor: getThemeValue("#f1f1f1", "#1d252e", "#f1f5f9"),
        color: getThemeValue("black", "#bbb", "#1e293b"),
        border: "none",
        boxShadow: "none",
        "& .MuiSvgIcon-root": {
          color: getThemeValue("#505050", "#bbb", "#64748b"),
        },
      },
      "&:focus": {
        outline: "none",
        border: "none",
        boxShadow: "none",
      },
    },

    beautyBtnBox: {
      fontWeight: "normal",
      fontSize: { xs: "12px", md: "15px" },
      backgroundColor: getThemeValue("#f9f9f9", "#2f3d4a", "#f8fafc"),
      border: getThemeValue(
        "1px solid #eee",
        "1px solid #5c666f",
        "1px solid #e2e8f0",
      ),
      paddingTop: { xs: "2px", md: "8px" },
      paddingBottom: { xs: "2px", md: "8px" },
      borderRadius: { xs: "30px", md: "8px" },
      fontWeight: "500",
      boxShadow: "none",
      color: getThemeValue("#333", "#ffffff", "#1e293b"),
      "&:hover, &:focus, &:active, &.Mui-focusVisible": {
        border: getThemeValue(
          "1px solid #f9f9f9",
          "1px solid #5c666f",
          "1px solid #e2e8f0",
        ),
        backgroundColor: getThemeValue("#eee", "#384755", "#f1f5f9"),
        color: getThemeValue("#333", "#ffffff", "#1e293b"),
      },
    },

    promptBtn: {
      color: getThemeValue("#606266", "white", "#535353"),
      textTransform: "none",
      backgroundColor: getThemeValue("white", "#22303c", "#f8fafc"),
      border: "none",
      borderRadius: 10,
      padding: "4px 14px",
      display: "flex",
      alignItems: "center",
      position: "absolute",
      bottom: "80px",
      left: "25px",
      "&:hover": {
        backgroundColor: getThemeValue("#f1f1f1", "#3b4651", "#f1f5f9"),
        color: getThemeValue("#505050", "white", "#4c1d95"),
        border: "none",
        boxShadow: "none",
        "& .MuiSvgIcon-root": {
          color: getThemeValue("#505050", "white", "#4c1d95"),
        },
      },
      "&:focus": {
        outline: "none",
        border: "none",
        boxShadow: "none",
      },
    },

    dashboardBox: {
      boxShadow: "none",
      width: "100%",
      margin: "0px",
      padding: "0px",
      transition: "background-color 0.3s",
      border: "none",
      backgroundColor: getThemeValue("#f9f9fa", "#2c3740", "#f8fafc"),
      "&:hover": {
        backgroundColor: getThemeValue(
          theme.palette.action.hover,
          "#35414d",
          "#f1f5f9",
        ),
      },
    },

    iconBox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: 34,
      height: 34,
      borderRadius: "8px",
      backgroundColor: getThemeValue("#f1f5f9", "#15202b", "#f9f9f9"),
      marginRight: 1.2,
      flexShrink: 0,
    },

    iconBoxColor: {
      color: getThemeValue("#b6c8db", "#b6c8db", "#9d9d9d"),
    },

    btnContainerIconColor: {
      color: getThemeValue("#8b5cf6", "#ffffff", "#97ca2b"),
    },

    newBox: {
      borderRadius: "10px",
      padding: "15px",
      width: { xs: "100%", md: "90%" },
      marginTop: "20px",
      marginBottom: "20px",
      backgroundColor: getThemeValue("white", "#212c36", "white"),
      border: getThemeValue(
        "1px solid #e0e0e091",
        "1px solid #263442",
        "0px solid #e2e8f0",
      ),
    },

    darkBorder: {
      borderRadius: "10px",
      border: "2px solid #8b5cf6",
      padding: "15px",
      backgroundColor: "white",
      width: { xs: "100%", md: "90%" },
      marginTop: "20px",
      marginBottom: "20px",
    },

    freshBox: {
      width: "100%",
      padding: "15px",
      backgroundColor: getThemeValue("white", "#212c36", "white"),
      border: getThemeValue(
        "1px solid #e0e0e091",
        "1px solid #263442",
        "1px solid #e2e8f0",
      ),
      borderRadius: { xs: "0px", md: "8px" },
      //boxShadow: "0 3px 28px rgba(12,8,51,.06)",
    },

    softBoxShadow: {
      boxShadow: "0 3px 28px rgba(12,8,51,.06)",
    },

    roundImage: {
      width: "28px",
      height: "28px",
      borderRadius: "50%",
    },

    menuItems: {
      "&:hover": {
        color: getThemeValue("#8b5cf6", "#fff", "#6d28d9"),
        backgroundColor: getThemeValue("#f1ebff", "black", "#f8fafc"),
      },
    },

    bottomCta: {
      textAlign: "center",
      padding: 4,
      marginTop: 8,
      marginBottom: 4,
      borderRadius: 2,
      backgroundColor: getThemeValue(
        "rgb(253, 253, 253)",
        "rgba(255, 255, 255, 0.05)",
        "#f8fafc",
      ),
      boxShadow: getThemeValue(
        "0 4px 6px rgba(0, 0, 0, 0.1)",
        "0 4px 6px rgba(0, 0, 0, 0.2)",
        "0 4px 6px rgba(0, 0, 0, 0.05)",
      ),
    },

    toggleButtonGroupsTight: {
      marginBottom: "0px",
      marginTop: "0px",
      borderRadius: "26px",
      overflow: "hidden",
      border: "none",
    },

    togglesTight: {
      backgroundColor: getThemeValue("white", "#15202b", "#f8fafc"),
      color: getThemeValue("white", "#8899a6", "#64748b"),
      paddingTop: "5px",
      paddingBottom: "5px",
      "&:not(:first-child)": {
        borderLeft: `1px solid ${getThemeValue("#dbdbdb", "#38444d", "#e2e8f0")}`,
      },
      "&:not(:last-child)": {
        borderRight: `1px solid ${getThemeValue("#dbdbdb", "#38444d", "#e2e8f0")}`,
      },
      "& svg": {
        marginRight: "5px",
      },
      "&.Mui-root": {
        backgroundColor: getThemeValue("#340d8c", "#192734", "#f8fafc"),
        color: getThemeValue("white", "#1da1f2", "#97ca2b"),
        border: "none",
        "& svg": {
          color: getThemeValue("#7c3aed", "#1da1f2", "#97ca2b"),
          marginRight: "5px",
        },
      },
      "&.Mui-selected": {
        backgroundColor: getThemeValue("#eee9ff", "#22303c", "#f1f5f9"),
        color: getThemeValue("#6d28d9", "#1da1f2", "#97ca2b"),
        "&:hover": {
          backgroundColor: getThemeValue("#eee9ff", "#22303c", "#f1f5f9"),
          color: getThemeValue("#6d28d9", "#1da1f2", "#97ca2b"),
          "& svg": {
            color: getThemeValue("#6d28d9", "#1da1f2", "#97ca2b"),
          },
        },
        "& svg": {
          color: getThemeValue("#6d28d9", "#1da1f2", "#97ca2b"),
        },
      },
    },
  };
};

const ThemedApp = () => {
  const [overrideTheme, setOverrideTheme] = useState(() => {
    // Check for SSO params on initial load
    const params = new URLSearchParams(window.location.search);
    return params.get("userData") ? "allakando" : null;
  });
  const { user, loading } = useAuth();

  const getPreferredTheme = useCallback(() => {
    if (overrideTheme !== null) {
      return overrideTheme;
    }

    if (user?.preferredTheme && user.preferredTheme !== "system") {
      return user.preferredTheme;
    }

    const currentHour = new Date().getHours();
    return currentHour >= 20 || currentHour < 6 ? "dark" : "light";
  }, [overrideTheme, user?.preferredTheme]);

  const [currentThemeMode, setCurrentThemeMode] = useState(getPreferredTheme());

  useEffect(() => {
    const updateTheme = () => {
      setCurrentThemeMode(getPreferredTheme());
    };

    updateTheme();
    const interval = setInterval(updateTheme, 60000);
    return () => clearInterval(interval);
  }, [getPreferredTheme]);

  const toggleDarkMode = useCallback(() => {
    setOverrideTheme((prevOverride) => {
      // If currently dark, switch to light, otherwise switch to dark
      return prevOverride === "dark" ||
        (prevOverride === null && currentThemeMode === "dark")
        ? "light"
        : "dark";
    });
  }, [currentThemeMode]);

  const getTheme = () => {
    const faviconLink = document.querySelector("link[rel~='icon']");
    if (!faviconLink) {
      const link = document.createElement("link");
      link.rel = "icon";
      document.head.appendChild(link);
    }
    faviconLink?.setAttribute(
      "href",
      currentThemeMode === "allakando" ? "/allakando-fav.png" : "/ai.png",
    );

    switch (currentThemeMode) {
      case "dark":
        return darkTheme;
      case "allakando":
        return allakandoTheme;
      default:
        return theme;
    }
  };

  return (
    <ThemeProvider theme={getTheme()}>
      <CssBaseline />
      <SnackbarProvider>
        <App
          isDark={currentThemeMode === "dark"}
          isAllakando={currentThemeMode === "allakando"}
          toggleDarkMode={toggleDarkMode} // Changed back to toggleDarkMode
          setOverrideTheme={setOverrideTheme}
        />
      </SnackbarProvider>
      <style>
        {`

@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 0.8;
    }
    50% {
        transform: scale(1.05);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 0.8;
    }
}


        
          @keyframes wiggle {
            0%, 100% {
              transform: rotate(-1deg);
            }
            50% {
              transform: rotate(1deg);
            }
          }

          .fade-enter {
            opacity: 0;
          }
          .fade-enter-active {
            opacity: 1;
            transition: opacity 2000ms ease-in;
          }
          .fade-exit {
            opacity: 1;
          }
          .fade-exit-active {
            opacity: 0;
            transition: opacity 500ms ease-out;
          }

          .wiggle {
            animation: wiggle 0.3s infinite;
          }

          @keyframes ellipsis-dot {
            0% { content: '.'; }
            33% { content: '..'; }
            66% { content: '...'; }
          }

          @keyframes fadeIn {
            from { opacity: 0; transform: translateY(10px); }
            to { opacity: 1; transform: translateY(0); }
          }

          @keyframes blink {
            0% { opacity: 1; }
            50% { opacity: 0; }
            100% { opacity: 1; }
          }

          .cursor {
            display: inline-block;
            width: 10px;
            height: 20px;
            background-color: currentColor;
            margin-left: 2px;
            animation: blink 0.7s infinite;
          }

          [contenteditable] {
            padding: 12px;
          }

          [data-placeholder]:empty:before {
            content: attr(data-placeholder);
            color: #888;
          }

          p, ul li, ol li {
            line-height: 26px;
          }

          .focus-toggle {
            transition: opacity 0.3s ease-in-out;
          }

          .focus-toggle.focus-active {
            opacity: 0.05;
          }

          .focus-toggle.focus-active:hover {
            opacity: 1;
          }

          .pulsating-dot {
            display: inline-block;
            width: 12px;
            height: 12px;
            background-color: currentColor;
            border-radius: 50%;
            margin-left: 2px;
            vertical-align: middle;
            animation: pulsate 1s ease-in-out infinite;
          }

          .blinking-cursor::after {
            content: '|';
            animation: blink 1s step-start infinite;
            margin-left: 2px;
          }
        `}
      </style>
    </ThemeProvider>
  );
};

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<ThemedApp />);

export { theme, darkTheme, allakandoTheme, createStyles as getStyles };
