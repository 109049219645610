import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Stack,
  Typography,
  Box,
  IconButton,
  FormControl,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Stepper,
  Step,
  StepLabel,
  Paper,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import ReplyIcon from "@mui/icons-material/Reply";
import { East as ArrowIcon } from "@mui/icons-material";
import LanguageSelector from "./LanguageSelector";
import ThemeFlowBox from "./ThemeFlowBox";

const EmailDialog = ({ open, onClose, onSubmit, theme }) => {
  // States remain the same
  const [flowType, setFlowType] = useState(null);
  const [originalEmail, setOriginalEmail] = useState("");
  const [sender, setSender] = useState("");
  const [recipient, setRecipient] = useState("");
  const [tone, setTone] = useState("formal");
  const [content, setContent] = useState("");
  const [language, setLanguage] = useState("svenska");
  const [urgency, setUrgency] = useState("normal");
  const [purpose, setPurpose] = useState([]);
  const [length, setLength] = useState("medium");

  const handleFlowSelect = (type) => {
    setFlowType(type);
  };

  const handlePurposeChange = (event) => {
    const value = event.target.value;
    setPurpose(typeof value === "string" ? value.split(",") : value);
  };

  const handleSubmit = () => {
    const prompt = `Skriv ett ${tone} ${flowType === "reply" ? "svar" : ""} mail 
    ${flowType === "reply" ? `som svarar på: "${originalEmail}"` : ""}
    från ${sender} 
    till ${recipient}
    med följande innehåll: ${content}
    på ${language} 
    med ${length} längd
    med ${urgency} prioritet`;

    onSubmit(prompt); // Just send the prompt string directly
    handleReset();
    onClose();
  };
  // Reset all state when dialog closes
  React.useEffect(() => {
    if (!open) {
      setFlowType(null);
      setOriginalEmail("");
      setSender("");
      setRecipient("");
      setTone("formal");
      setContent("");
      setLanguage("svenska");
      setUrgency("normal");
      setPurpose([]);
      setLength("medium");
    }
  }, [open]);

  const handleReset = () => {
    setFlowType(null);
    setOriginalEmail("");
    setSender("");
    setRecipient("");
    setTone("formal");
    setContent("");
    setLanguage("svenska");
    setUrgency("normal");
    setPurpose([]);
    setLength("medium");
  };

  const purposeOptions = [
    "Information",
    "Förfrågan",
    "Uppföljning",
    "Mötesinbjudan",
    "Feedback",
    "Presentation",
    "Projektuppdatering",
  ];

  const isValid = () => {
    if (!flowType) return false;
    if (flowType === "reply" && !originalEmail.trim()) return false;
    return sender.trim() && recipient.trim() && content.trim();
  };

  const getActiveStep = () => {
    if (!flowType) return 0;
    if (!sender || !recipient) return 1;
    return 2;
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        elevation: 0,
        sx: {
          backgroundColor:
            theme.palette.mode === "dark" ? "background.paper" : "white",
          borderRadius: 2,
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 1,
          color:
            theme.palette.mode === "dark"
              ? "rgba(255, 255, 255, 0.7)"
              : "inherit",
          pt: 2,
          padding: "30px",
        }}
      >
        <Typography variant="h5" fontWeight={700}>
          Skriv eller svara på e-post
        </Typography>
      </DialogTitle>

      <DialogContent sx={{ padding: "40px" }}>
        {!flowType ? (
          <Stack spacing={3} alignItems="center">
            <Typography variant="h6" color="text.secondary">
              Vad vill du göra?
            </Typography>

            <Grid
              container
              spacing={3}
              sx={{ width: { xs: "100%", md: "80%" }, margin: "0 auto" }}
            >
              <Grid item xs={12} md={6}>
                <ThemeFlowBox
                  icon={EditIcon}
                  title="Skriva ett nytt mail"
                  subtitle="Skriv ett mail, välj ton och vad du vill kommunicera."
                  onClick={() => handleFlowSelect("new")}
                  diffView={true}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <ThemeFlowBox
                  icon={ReplyIcon}
                  title="Svara på ett mail"
                  subtitle={`Klistra in mailet du vill svara på och välj vad du vill kommunicera.`}
                  onClick={() => handleFlowSelect("reply")}
                  diffView={true}
                />
              </Grid>
            </Grid>
          </Stack>
        ) : (
          <Box>
            {flowType === "reply" && (
              <>
                <Typography
                  variant="subtitle1"
                  fontWeight={500}
                  sx={{ mb: 1, mt: 2 }}
                >
                  Mailet du vill svara på
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  variant="outlined"
                  value={originalEmail}
                  onChange={(e) => setOriginalEmail(e.target.value)}
                  placeholder="Klistra in mailet du vill svara på här..."
                  size="small"
                />
              </>
            )}

            <Box sx={{ display: "flex", gap: 2 }}>
              <Box sx={{ flex: 1 }}>
                <Typography
                  variant="subtitle1"
                  fontWeight={500}
                  sx={{ mb: 1, mt: 2 }}
                >
                  Namn på avsändare
                </Typography>
                <TextField
                  fullWidth
                  value={sender}
                  placeholder="Namn på avsändare"
                  onChange={(e) => setSender(e.target.value)}
                />
              </Box>
              <Box sx={{ flex: 1 }}>
                <Typography
                  variant="subtitle1"
                  fontWeight={500}
                  sx={{ mb: 1, mt: 2 }}
                >
                  Namn på mottagare
                </Typography>
                <TextField
                  fullWidth
                  placeholder="Namn på mottagare"
                  value={recipient}
                  onChange={(e) => setRecipient(e.target.value)}
                />
              </Box>
            </Box>

            <Box sx={{ display: "flex", gap: 2, mb: 3 }}>
              <FormControl fullWidth>
                <Typography
                  variant="subtitle1"
                  fontWeight={500}
                  sx={{ mb: 1, mt: 2 }}
                >
                  Ton
                </Typography>

                <Select value={tone} onChange={(e) => setTone(e.target.value)}>
                  <MenuItem value="formal">Formell</MenuItem>
                  <MenuItem value="semiformal">Semi-formell</MenuItem>
                  <MenuItem value="informal">Informell</MenuItem>
                  <MenuItem value="friendly">Vänskaplig</MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <Typography
                  variant="subtitle1"
                  fontWeight={500}
                  sx={{ mb: 1, mt: 2 }}
                >
                  Längd
                </Typography>

                <Select
                  value={length}
                  onChange={(e) => setLength(e.target.value)}
                >
                  <MenuItem value="short">Kort</MenuItem>
                  <MenuItem value="medium">Medium</MenuItem>
                  <MenuItem value="long">Lång</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Typography variant="subtitle1" fontWeight={500} sx={{ mb: 1 }}>
              Vad vill du kommunicera i mailet?
            </Typography>

            <TextField
              fullWidth
              multiline
              rows={4}
              value={content}
              onChange={(e) => setContent(e.target.value)}
              placeholder="Vad vill du inkludera i mailet?"
              sx={{ mb: 3 }}
            />

            <Typography variant="subtitle1" fontWeight={500} sx={{ mb: 1 }}>
              Språk
            </Typography>
            <LanguageSelector language={language} setLanguage={setLanguage} />
          </Box>
        )}
      </DialogContent>

      <Divider />
      <DialogActions
        sx={{ px: 3, py: 2, display: "flex", justifyContent: "space-between" }}
      >
        <Box>
          {flowType && (
            <Button
              onClick={handleReset}
              variant="print"
              startIcon={<ArrowIcon sx={{ transform: "rotate(180deg)" }} />}
            >
              Tillbaka
            </Button>
          )}
        </Box>
        <Box>
          <Button onClick={onClose} variant="print" sx={{ mr: 1 }}>
            Avbryt
          </Button>
          {flowType && (
            <Button
              onClick={handleSubmit}
              variant="purp"
              disabled={!isValid()}
              endIcon={<ArrowIcon />}
            >
              Generera mail
            </Button>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default EmailDialog;
