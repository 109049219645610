import React, { useState, useEffect } from "react";
import { useAuth } from "./useAuth";
import { useSnackbar } from "./SnackbarHandler";
import { ref, uploadBytes, getDownloadURL } from "./firebase";
import { storage } from "./firebase";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  IconButton,
  Switch,
  TextField,
  useTheme,
  styled,
  Avatar,
  Button,
  DialogActions,
  CircularProgress,
  Divider,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SettingsIcon from "@mui/icons-material/Settings";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import SettingsBrightnessIcon from "@mui/icons-material/SettingsBrightness";
import CheckIcon from "@mui/icons-material/Check";
import CustomInputSwitch from "./CustomInputSwitch";
import BlurryBackdrop from "./BlurryBackdrop";
import { sanitizeInput } from "./functions";
import Logger from "./Logger";
import { getStyles } from "../index";

import AddCircleIcon from "@mui/icons-material/AddCircle";

const SettingBox = styled(Box)(({ theme }) => ({
  background: theme.palette.mode === "dark" ? "#2a3742" : "#f9f9f9",
  borderRadius: "10px",
  padding: "12px 15px",
  marginBottom: "12px",
}));

const SettingsDialog = ({ open, onClose, onUserUpdate, setOverrideTheme }) => {
  const theme = useTheme();
  const styles = getStyles(theme);
  const { user, updateUser } = useAuth();
  const { showSnackbar } = useSnackbar();

  const [selectedTab, setSelectedTab] = useState(1);
  const [notifications, setNotifications] = useState(true);
  const [privacyMode, setPrivacyMode] = useState(false);
  const [language, setLanguage] = useState("Svenska");
  const [avatarImage, setAvatarImage] = useState(null);
  const [avatarPreviewUrl, setAvatarPreviewUrl] = useState(
    user.photoURL || null,
  );
  const [displayName, setDisplayName] = useState(user.displayName || "");
  const [preferredTheme, setPreferredTheme] = useState("system");
  const [isSaving, setIsSaving] = useState(false);
  const [latexActivated, setLatexActivated] = useState(
    user.latexActivated || false,
  );

  useEffect(() => {
    setDisplayName(user.displayName || "");
    setAvatarPreviewUrl(user.photoURL || null);
    setPreferredTheme(user.preferredTheme || "system");
    setLanguage(user.language || "Svenska");
    setLatexActivated(user.latexActivated || false);
  }, [user]);

  const handleAvatarChange = (e) => {
    if (e.target.files[0]) {
      setAvatarImage(e.target.files[0]);
      setAvatarPreviewUrl(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleSaveAllSettings = async () => {
    if (!user) return;

    if (displayName.trim().length < 3) {
      showSnackbar("Namnet måste vara minst 3 tecken långt.", "error");
      return;
    }

    setIsSaving(true);
    try {
      const sanitizedDisplayName = sanitizeInput(displayName);
      let updates = {
        displayName: sanitizedDisplayName,
        notifications,
        privacyMode,
        language,
        preferredTheme,
        latexActivated,
      };

      if (avatarImage) {
        const imageRef = ref(
          storage,
          `avatars/${user.uid}/${avatarImage.name}`,
        );
        await uploadBytes(imageRef, avatarImage);
        const downloadURL = await getDownloadURL(imageRef);
        updates.photoURL = downloadURL;
      }

      await onUserUpdate(updates);
      setOverrideTheme(updates.preferredTheme);
      Logger.log("Settings updated, new theme:", updates.preferredTheme);
      showSnackbar("Inställningar sparade.", "success");
      setIsSaving(false);
      onClose();
    } catch (error) {
      console.error("Error updating settings: ", error);
      showSnackbar("Knak i fogarna. Försök igen.", "error");
      setIsSaving(false);
    }
  };

  const renderProfileContent = () => (
    <Box sx={{ mt: 7 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mb: 4,
        }}
      >
        <input
          accept="image/*"
          type="file"
          onChange={handleAvatarChange}
          style={{ display: "none" }}
          id="avatar-upload"
        />
        <label htmlFor="avatar-upload">
          <Box sx={{ position: "relative" }}>
            <AddCircleIcon
              sx={{
                position: "absolute",
                zIndex: 9999,
                right: 0,
                backgroundColor: "white",
                borderRadius: "50px",
                fontSize: "1.8rem",
              }}
            />
            <Avatar
              sx={{
                width: 100,
                height: 100,
                cursor: "pointer",
                mb: 2,
                bgcolor: theme.palette.primary.main,
              }}
            >
              {avatarPreviewUrl ? (
                <img
                  src={avatarPreviewUrl}
                  alt="Avatar"
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              ) : (
                <AddAPhotoIcon sx={{ fontSize: 40 }} />
              )}
            </Avatar>
          </Box>
        </label>
      </Box>

      <TextField
        label="Namn"
        value={displayName}
        onChange={(e) => setDisplayName(e.target.value)}
        variant="outlined"
        fullWidth
        sx={{ mb: 3 }}
      />
    </Box>
  );

  const renderGeneralContent = () => (
    <Box sx={{ mt: 4 }}>
      <Typography
        variant="h6"
        sx={{
          mb: 1,
          fontWeight: "600",
          fontSize: "1rem",
        }}
      >
        Språk
      </Typography>
      <TextField
        select
        value={language}
        onChange={(e) => setLanguage(e.target.value)}
        SelectProps={{ native: true }}
        variant="outlined"
        fullWidth
        sx={{ mb: 4 }}
      >
        <option value="Svenska">Svenska</option>
        {user.admin && <option value="Engelska">Engelska</option>}
        <option value="Norska" disabled>
          Norska
        </option>
        <option value="Finska" disabled>
          Finska
        </option>
        <option value="Danska" disabled>
          Danska
        </option>
        <option value="Arabiska" disabled>
          Arabiska
        </option>
      </TextField>

      <Typography
        variant="h6"
        sx={{
          mb: 1,
          fontWeight: "600",
          fontSize: "1rem",
        }}
      >
        Tema
      </Typography>
      <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap", mb: 4 }}>
        <Tooltip title="Ljust tema oavsett tid på dygnet">
          <Button
            startIcon={
              preferredTheme === "light" ? <CheckIcon /> : <Brightness7Icon />
            }
            onClick={() => setPreferredTheme("light")}
            variant={preferredTheme === "light" ? "success-gradient" : "print"}
            size="small"
            sx={{ ...styles.tightButton }}
          >
            Ljus
          </Button>
        </Tooltip>
        <Tooltip title="Mörkt tema oavsett tid på dygnet">
          <Button
            startIcon={
              preferredTheme === "dark" ? <CheckIcon /> : <Brightness4Icon />
            }
            onClick={() => setPreferredTheme("dark")}
            variant={preferredTheme === "dark" ? "success-gradient" : "print"}
            size="small"
            sx={{ ...styles.tightButton }}
          >
            Mörk
          </Button>
        </Tooltip>
        <Tooltip title="Ändras automatiskt baserat på tid på dygnet">
          <Button
            startIcon={
              preferredTheme === "system" ? (
                <CheckIcon />
              ) : (
                <SettingsBrightnessIcon />
              )
            }
            onClick={() => setPreferredTheme("system")}
            variant={preferredTheme === "system" ? "success-gradient" : "print"}
            size="small"
            sx={{ ...styles.tightButton }}
          >
            System
          </Button>
        </Tooltip>
        {user.admin && (
          <Tooltip title="Allakando tema">
            <Button
              startIcon={
                preferredTheme === "allakando" ? (
                  <CheckIcon />
                ) : (
                  <SettingsBrightnessIcon />
                )
              }
              onClick={() => setPreferredTheme("allakando")}
              variant={
                preferredTheme === "allakando" ? "success-gradient" : "print"
              }
              size="small"
              sx={{ ...styles.tightButton }}
            >
              Allakando
            </Button>
          </Tooltip>
        )}
      </Box>

      <Typography
        variant="h6"
        sx={{
          mb: 1,
          fontWeight: "600",
          fontSize: "1rem",
        }}
      >
        Funktioner
      </Typography>
      <Box
        sx={{
          p: 2,
          borderRadius: 3,
          border: 1,
          borderColor: "divider",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography variant="subtitle1">LaTeX-formler</Typography>
            <Typography variant="caption" color="text.secondary">
              Aktivera stöd för rendering av matematiska formler i "Fråga AI".
            </Typography>
          </Box>
          <Switch
            checked={latexActivated}
            onChange={(e) => setLatexActivated(e.target.checked)}
            color="primary"
          />
        </Box>
      </Box>
    </Box>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      BackdropComponent={BlurryBackdrop}
    >
      <DialogTitle
        sx={{
          fontWeight: "700",
        }}
      >
        Inställningar
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 12,
            color: theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Box sx={{ display: "flex", justifyContent: "center", mb: 4 }}>
          <CustomInputSwitch
            selectedIndex={selectedTab}
            onIndexChange={setSelectedTab}
            options={["Generellt", "Profil"]}
            optionIcons={[<SettingsIcon />, <AccountCircleIcon />]}
            width="300px"
          />
        </Box>

        {selectedTab === 0 ? renderGeneralContent() : renderProfileContent()}
      </DialogContent>
      <Divider />
      <DialogActions sx={{}}>
        <Button
          onClick={handleSaveAllSettings}
          color="primary"
          disabled={isSaving}
          variant={theme.palette.mode === "light" ? "purp" : "contained"}
          startIcon={
            isSaving ? (
              <CircularProgress sx={{ color: "white" }} size={16} />
            ) : (
              <SaveAsIcon />
            )
          }
        >
          {isSaving ? "Sparar inställningarna..." : "Spara inställningar"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SettingsDialog;
