import React, { useState, useRef, useEffect } from "react";
import {
  Typography,
  Grid,
  Box,
  Card,
  CardContent,
  Stepper,
  Step,
  StepLabel,
  Container,
  Button,
  TextField,
  Dialog,
  Alert,
  DialogContent,
  DialogTitle,
  Tooltip,
  IconButton,
} from "@mui/material";
import { East as ArrowIcon } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { CheckCircleOutline } from "@mui/icons-material";
import booksData from "./booksData";

export const BookPlanSelector = ({ onBookSelect, styles, theme }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [selectedLevel, setSelectedLevel] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedBook, setSelectedBook] = useState(null);
  const [containerHeight, setContainerHeight] = useState("auto");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [titleText, setTitleText] = useState("");
  const [authorText, setAuthorText] = useState("");
  const [yearText, setYearText] = useState("");
  const [descriptionText, setDescriptionText] = useState("");
  const [additionalText, setAdditionalText] = useState("");

  const levelViewRef = useRef(null);
  const categoryViewRef = useRef(null);
  const bookViewRef = useRef(null);
  const confirmationViewRef = useRef(null);

  const isDark = theme.palette.mode === "dark";

  useEffect(() => {
    let height = 0;
    if (activeStep === 0 && levelViewRef.current) {
      height = 240;
    } else if (activeStep === 1 && categoryViewRef.current) {
      height = 240;
    } else if (activeStep === 2 && bookViewRef.current) {
      height = bookViewRef.current.offsetHeight;
    } else if (activeStep === 3 && confirmationViewRef.current) {
      height = isSuccess ? 330 : 700;
    }
    setContainerHeight(height);
  }, [activeStep, selectedLevel, selectedCategory, selectedBook, isSuccess]);

  const steps = ["Välj nivå", "Välj kategori", "Välj bok", "Bekräfta val"];

  const handleStepClick = (stepIndex) => {
    if (stepIndex < activeStep) {
      setActiveStep(stepIndex);
      if (stepIndex === 0) {
        setSelectedLevel(null);
        setSelectedCategory(null);
        setSelectedBook(null);
        setIsSuccess(false);
      } else if (stepIndex === 1) {
        setSelectedCategory(null);
        setSelectedBook(null);
        setIsSuccess(false);
      } else if (stepIndex === 2) {
        setSelectedBook(null);
        setIsSuccess(false);
      }
    }
  };

  const handleConfirmBook = () => {
    const combinedText = `Titel: ${titleText}\n\nFörfattare: ${authorText}\n\nÅr: ${yearText}\n\nBeskrivning: ${descriptionText}\n\nFler instruktioner: ${additionalText}`;
    onBookSelect(combinedText);
    setIsSuccess(true);
  };

  const handleLevelSelect = (level) => {
    setSelectedLevel(level);
    setActiveStep(1);
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setActiveStep(2);
  };

  const handleBookSelect = (book) => {
    setSelectedBook(book);
    setTitleText(book.title);
    setAuthorText(book.author);
    setYearText(book.year.toString());
    setDescriptionText(book.description);
    setActiveStep(3);
  };

  const getBackgroundColor = () => {
    return isDark ? "rgba(255, 255, 255, 0.05)" : "rgba(223, 222, 225, 0.19)";
  };

  const getHoverBackgroundColor = () => {
    return isDark ? "rgba(255, 255, 255, 0.1)" : "rgba(139, 92, 246, 0.1)";
  };

  const levels = Object.keys(booksData);

  return (
    <Container
      maxWidth="md"
      sx={{
        paddingTop: "24px",
        paddingBottom: "24px",
        backgroundColor: isDark
          ? "rgba(255, 255, 255, 0.05)"
          : "rgba(252, 252, 252, 0.17)",
        border: isDark ? "1px solid #51585f" : "1px solid #ededed69",
        borderRadius: "10px",
        position: "relative",
        height: "auto",
        overflow: "hidden",
      }}
    >
      <Box sx={{ width: "100%", mt: 4 }}>
        <Box sx={{ display: "flex", alignItems: "center", mb: 5 }}>
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            sx={{
              flex: 1,
              "& .MuiStepIcon-root": {
                color: "rgba(0, 0, 0, 0.38)",
              },
              "& .MuiStepIcon-root.Mui-active": {
                color: "#7b68eebf",
              },
              "& .MuiStepIcon-root.Mui-completed": {
                color: "#7b68ee",
              },
            }}
          >
            {steps.map((label, index) => (
              <Step
                key={label}
                onClick={() => handleStepClick(index)}
                sx={{
                  cursor: index < activeStep ? "pointer" : "default",
                  "& .MuiStepLabel-label:hover": {
                    textDecoration: index < activeStep ? "underline" : "none",
                  },
                }}
              >
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>

        <Box
          sx={{
            position: "relative",
            height: containerHeight,
            transition: "height 300ms ease",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              display: "flex",
              transition: "transform 0.4s cubic-bezier(0.4, 0, 0.2, 1)",
              transform: `translateX(-${activeStep * 100}%)`,
            }}
          >
            {/* Level Selection */}
            <Box ref={levelViewRef} sx={{ minWidth: "100%", p: 0 }}>
              <Grid container spacing={3}>
                {levels.map((level) => (
                  <Grid item xs={12} sm={6} key={level}>
                    <Card
                      onClick={() => handleLevelSelect(level)}
                      sx={{
                        cursor: "pointer",
                        height: "100px",
                        backgroundColor: getBackgroundColor(),
                        borderRadius: "12px",
                        border: isDark ? "none" : "1px solid #e0e0e080",
                        transition: "all 0.3s ease",
                        position: "relative",
                        textAlign: "center",
                        justifyContent: "center",
                        boxShadow: "none",
                        "&:hover": {
                          backgroundColor: getHoverBackgroundColor(),
                        },
                      }}
                    >
                      <CardContent
                        sx={{
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          paddingBottom: "16px !important",
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{
                            color: isDark
                              ? "rgba(255, 255, 255, 0.7)"
                              : "#2c2f33",
                            fontWeight: 600,
                            fontSize: 18,
                          }}
                        >
                          {level.charAt(0).toUpperCase() + level.slice(1)}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>

            {/* Category Selection */}
            <Box ref={categoryViewRef} sx={{ minWidth: "100%", p: 0 }}>
              <Grid container spacing={3}>
                {selectedLevel &&
                  Object.keys(booksData[selectedLevel]).map((category) => (
                    <Grid item xs={12} sm={6} key={category}>
                      <Card
                        onClick={() => handleCategorySelect(category)}
                        sx={{
                          cursor: "pointer",
                          height: "100px",
                          backgroundColor: getBackgroundColor(),
                          borderRadius: "12px",
                          border: isDark ? "none" : "1px solid #e0e0e080",
                          transition: "all 0.3s ease",
                          position: "relative",
                          textAlign: "center",
                          justifyContent: "center",
                          boxShadow: "none",
                          "&:hover": {
                            backgroundColor: getHoverBackgroundColor(),
                          },
                        }}
                      >
                        <CardContent
                          sx={{
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            paddingBottom: "16px !important",
                          }}
                        >
                          <Typography
                            variant="subtitle1"
                            sx={{
                              color: isDark
                                ? "rgba(255, 255, 255, 0.7)"
                                : "#2c2f33",
                              fontWeight: 600,
                              fontSize: 18,
                            }}
                          >
                            {category.charAt(0).toUpperCase() +
                              category.slice(1)}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
              </Grid>
            </Box>

            {/* Book Selection */}
            <Box ref={bookViewRef} sx={{ minWidth: "100%", p: 0 }}>
              <Grid container spacing={3}>
                {selectedLevel &&
                  selectedCategory &&
                  booksData[selectedLevel][selectedCategory].map((book) => (
                    <Grid item xs={12} md={6} key={book.title}>
                      <Card
                        onClick={() => handleBookSelect(book)}
                        sx={{
                          cursor: "pointer",
                          height: "auto",
                          backgroundColor: getBackgroundColor(),
                          borderRadius: "12px",
                          border: isDark ? "none" : "1px solid #e0e0e080",
                          transition: "all 0.3s ease",
                          position: "relative",
                          textAlign: "left",
                          justifyContent: "center",
                          boxShadow: "none",
                          "&:hover": {
                            backgroundColor: getHoverBackgroundColor(),
                          },
                        }}
                      >
                        <CardContent>
                          <Typography
                            variant="h6"
                            sx={{
                              color: isDark
                                ? "rgba(255, 255, 255, 0.9)"
                                : "#2c2f33",
                              fontWeight: 600,
                              mb: 1,
                            }}
                          >
                            {book.title}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              color: isDark
                                ? "rgba(255, 255, 255, 0.7)"
                                : "#666",
                            }}
                          >
                            {book.author} ({book.year})
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              color: isDark
                                ? "rgba(255, 255, 255, 0.6)"
                                : "#666",
                              mt: 1,
                            }}
                          >
                            {book.description}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
              </Grid>
            </Box>

            {/* Confirmation Step */}
            <Box ref={confirmationViewRef} sx={{ minWidth: "100%", p: 0 }}>
              <Box sx={{ textAlign: "center", p: 3 }}>
                {!isSuccess ? (
                  <>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography
                          variant="subtitle2"
                          sx={{ mb: 1, textAlign: "left" }}
                        >
                          Titel:
                        </Typography>
                        <TextField
                          fullWidth
                          value={titleText}
                          onChange={(e) => setTitleText(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          variant="subtitle2"
                          sx={{ mb: 1, textAlign: "left" }}
                        >
                          Författare:
                        </Typography>
                        <TextField
                          fullWidth
                          value={authorText}
                          onChange={(e) => setAuthorText(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} sx={{ display: "none" }}>
                        <Typography
                          variant="subtitle2"
                          sx={{ mb: 1, mt: 2, textAlign: "left" }}
                        >
                          År:
                        </Typography>
                        <TextField
                          fullWidth
                          value={yearText}
                          onChange={(e) => setYearText(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          variant="subtitle2"
                          sx={{ mb: 1, mt: 2, textAlign: "left" }}
                        >
                          Beskrivning:
                        </Typography>
                        <TextField
                          fullWidth
                          multiline
                          rows={5}
                          value={descriptionText}
                          onChange={(e) => setDescriptionText(e.target.value)}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Alert
                          severity="info"
                          variant="filled"
                          sx={{
                            mt: 2,
                            textAlign: "left",
                            "& .MuiAlert-message": {
                              fontSize: "1rem",
                              lineHeight: "1.4rem",
                            },
                            borderRadius: 4,
                          }}
                        >
                          I fältet "Fler instruktioner" nedan behöver du
                          instruera precis vad du vill generera baserat på den
                          valda boken. Ju tydligare du är desto bättre resultat.
                          Var inte rädd för att experimentera.
                        </Alert>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          variant="subtitle2"
                          sx={{ mb: 1, mt: 2, textAlign: "left" }}
                        >
                          Fler instruktioner:
                        </Typography>
                        <TextField
                          fullWidth
                          multiline
                          rows={3}
                          placeholder="T.ex. Ta med information om Selma Lagerlöfs uppväxt, nobelpris och tidiga familjeförhållanden."
                          value={additionalText}
                          onChange={(e) => setAdditionalText(e.target.value)}
                        />
                      </Grid>
                    </Grid>
                    <Button
                      variant="purp"
                      onClick={handleConfirmBook}
                      sx={{ mt: 4, minWidth: "235px" }}
                    >
                      Bekräfta <ArrowIcon sx={{ ml: 1 }} />
                    </Button>
                  </>
                ) : (
                  <Box sx={{ ...styles.successBox }}>
                    <CheckCircleOutline
                      sx={{ fontSize: 60, color: "success.main", mb: 3 }}
                    />
                    <Typography
                      variant="h6"
                      sx={{
                        color: "success.main",
                        fontWeight: 500,
                        textAlign: "center",
                        width: "80%",
                        margin: "0 auto",
                        mb: 3,
                      }}
                    >
                      Boken är nu vald och du kan fortsätta med nästa steg.
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>

        <Tooltip
          title="Läs mer på Kulturrådets hemsida — Öppnas i ny flik"
          arrow
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: 1,
              mt: 6,
              cursor: "pointer",
              width: "200px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Typography
              variant="body2"
              sx={{
                textTransform: "uppercase",
                textAlign: "center",
                fontSize: "0.8rem",
                fontWeight: "500",
              }}
            >
              Läslistor framtagna av:
            </Typography>

            <Box
              component="a"
              href="https://www.kulturradet.se/i-fokus/laslistor/"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                "&:hover": {
                  opacity: 0.8,
                },
              }}
            >
              <img
                src="/img/skolverket-gron.png"
                width="240px"
                style={{ borderRadius: "10px" }}
                alt="Läslista framtagen av Skolverket och Kulturrådet"
              />
            </Box>

            <Typography
              variant="body2"
              sx={{
                textTransform: "uppercase",
                textAlign: "center",
                fontSize: "0.8rem",
                fontWeight: "500",
                mt: 2,
              }}
            >
              I samarbete med:
            </Typography>

            <Box
              component="a"
              href="https://www.kulturradet.se/i-fokus/laslistor/"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                "&:hover": {
                  opacity: 0.8,
                },
              }}
            >
              <img
                src="/img/kulturradet.png"
                //src="/img/kulturradet-modern.png"
                width="240px"
                alt="Läslista framtagen av Skolverket och Kulturrådet"
              />
            </Box>
          </Box>
        </Tooltip>
      </Box>

      {/* Book Details Dialog */}
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <IconButton
            onClick={() => setDialogOpen(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {selectedBook && (
            <>
              <Typography variant="h4" sx={{ mb: 2 }}>
                {selectedBook.title}
              </Typography>
              <Typography variant="h6" sx={{ mb: 3 }}>
                av {selectedBook.author} ({selectedBook.year})
              </Typography>
              <Typography variant="body1">
                {selectedBook.description}
              </Typography>
            </>
          )}
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default BookPlanSelector;
