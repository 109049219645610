import React, { useState, useRef, useEffect } from "react";

import {
  Box,
  Button,
  TextField,
  IconButton,
  Tooltip,
  Grid,
  Avatar,
  Table,
  TableBody,
  TableCell,
  FormControl,
  Divider,
  Checkbox,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  MenuItem,
  Select,
  Slider,
  Typography,
  CircularProgress,
} from "@mui/material";
import GenerateIcon from "@mui/icons-material/AutoAwesome";
import ImageIcon from "@mui/icons-material/Image";

import { handlePrintArgumentation } from "./printFunctions";
import { useSearchParams, useParams } from "react-router-dom";

import HoverableImage from "./HoverableImage";
import PrintIcon from "@mui/icons-material/Print";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import GavelIcon from "@mui/icons-material/Gavel";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";

import ProgressBar from "./ProgressBar";
import LoadingBar from "react-top-loading-bar";
import LanguageSelector from "./LanguageSelector";
import GradeSelector from "./GradeSelector";
import Logger from "./Logger";
import InfoBox from "./InfoBox";
import ImageGenerationModal from "./ImageGenerationModal";
import { callApi } from "./api";
import {
  generateAndProcessImage,
  trackLesson,
  handleImageGenerationError,
} from "./functions";
import { useSnackbar } from "./SnackbarHandler";
import { useTheme } from "@mui/material/styles";
import { getStyles } from "../index";
import EditIcon from "@mui/icons-material/Edit";
import SaveAsIcon from "@mui/icons-material/SaveAs";

import SaveIcon from "@mui/icons-material/Save";

import AddIcon from "@mui/icons-material/Add";

import { FourSquare } from "react-loading-indicators";

import { CustomFormControlLabel } from "./functions";

import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";

import { ThemeSelectorFlow } from "./ThemeSelectorFlow";

import LessonDrawer from "./LessonDrawer";
import SaveLessonDialog from "./SaveLessonDialog";

import HistoryIcon from "@mui/icons-material/History";

import {
  AutoStories,
  MenuBook,
  School,
  Lightbulb,
  CheckCircle,
  Psychology,
  Quiz,
  EmojiObjects,
  Architecture,
  Biotech,
  Science,
} from "@mui/icons-material";

import {
  List as ListIcon,
  AutoAwesome as AutoAwesomeIcon,
  Science as ScienceIcon,
  Psychology as PsychologyIcon,
  Pets as PetsIcon,
  BiotechOutlined as BiotechIcon,
  SmartToy as SmartToyIcon,
  MedicalServices as MedicalServicesIcon,
  ElectricBolt as ElectricBoltIcon,
  Public as PublicIcon,
} from "@mui/icons-material";

import {
  AccountBalance as AccountBalanceIcon,
  School as SchoolIcon,
  Nature as NatureIcon,
  Language as LanguageIcon,
  Home as HomeIcon,
  Diversity3 as Diversity3Icon,
  MonetizationOn as MonetizationOnIcon,
  LocalPolice as LocalPoliceIcon,
  SportsEsports as SportsEsportsIcon,
  Newspaper as NewspaperIcon,
  Medication as MedicationIcon,
  Factory as FactoryIcon,
  Forest as ForestIcon,
} from "@mui/icons-material";

import { db } from "./firebase";
import {
  collection,
  query,
  where,
  limit,
  addDoc,
  updateDoc,
  getDocs,
  serverTimestamp,
} from "firebase/firestore";

// Predefined debate themes in Swedish
const DEBATE_THEMES = [
  "Dödsstraff",
  "Aborträtt",
  "Vapenkontroll",
  "Basinkomst",
  "Kärnkraft",
  "Sociala medier-reglering",
  "Djurförsök",
  "Genmodifiering",
  "AI-utveckling",
  "Obligatorisk vaccination",
  "Socialism vs Kapitalism",
  "Privatisering av skolan",
  "Klimatkompensation",
  "Engelska som officiellt språk",
  "Hyresreglering",
  "Migration och integration",
  "Skattereform",
  "Polisens befogenheter",
  "E-sport som skolämne",
  "Pressfrihet",
  "Läkemedelspatent",
  "Industrialisering vs miljö",
];

// Tone options in Swedish
const TONE_OPTIONS = [
  "Övertygande",
  "Mild",
  "Akademisk",
  "Känslomässig",
  "Faktabaserad",
  "Professionell",
  "Passionerad",
  "Satirisk",

  "Sarkastisk",
  "Drastisk",
  "Provocerande",
  "Humoristisk",
];

// Add new options for educational content
const EDUCATIONAL_OPTIONS = [
  {
    id: "exercises",
    label: "Reflekterande uppgifter",
    tooltip: "Förslag på reflekterande uppgifter som eleverna kan göra.",
  },
  {
    id: "reflectionQuestions",
    label: "Reflekterande frågor",
    tooltip:
      'Frågor för självreflektion, t.ex. "Hur har dina åsikter förändrats?" eller "Vad har du lärt dig om ämnet?"',
  },
  {
    id: "sentenceStarters",
    label: "Meningsstartare",
    tooltip: "Påbörjade meningar som elever kan slutföra",
  },
  {
    id: "ethicalDilemmas",
    label: "Etiska dilemman",
    tooltip: "Situationer för etisk diskussion",
  },
  {
    id: "rolePlay",
    label: "Rollspelsscenarier",
    tooltip: "Scenarier för rollspel och debatt",
  },
  //{ id: 'vocabulary', label: 'Ämnesspecifikt ordförråd', tooltip: 'Viktiga begrepp och termer' },

  // {
  //   id: 'historicalContext',
  //   label: 'Historisk kontext',
  //   tooltip: 'Hur debatten har utvecklats genom tiden',
  // },
  // {
  //   id: 'statistics',
  //   label: 'Statistik och fakta',
  //   tooltip: 'Relevant statistik och forskningsresultat',
  // },
  // { id: 'realExamples', label: 'Verkliga exempel', tooltip: 'Konkreta exempel från verkligheten' },

  // { id: 'mediaAnalysis', label: 'Medieanalys', tooltip: 'Hur ämnet framställs i olika medier' },
  // {
  //   id: 'celebrities',
  //   label: 'Kända personers åsikter',
  //   tooltip: 'Inkludera citat och åsikter från kända personer med källor',
  // },

  {
    id: "whatIf",
    label: "Tänk om-scenarier",
    tooltip: "Hypotetiska scenarios för diskussion",
  },
  // {
  //   id: 'futureImpact',
  //   label: 'Framtidsperspektiv',
  //   tooltip: 'Långsiktiga konsekvenser och möjliga utvecklingar',
  // },
  // {
  //   id: 'factOpinion',
  //   label: 'Fakta vs åsikter',
  //   tooltip: 'Tydlig uppdelning mellan fakta och åsikter i tabellformat',
  // },
];

const ArgumentationLesson = ({ user }) => {
  // Theme state
  const [lessonTheme, setLessonTheme] = useState("");
  const [debateTheme, setDebateTheme] = useState(DEBATE_THEMES[0]);
  const [customTheme, setCustomTheme] = useState("");

  // Language and grade state
  const [language, setLanguage] = useState("svenska");
  const [grade, setGrade] = useState("åk6");

  const theme = useTheme();
  const styles = getStyles(theme);

  // Arguments configuration
  const [forConfig, setForConfig] = useState({
    tone: "Övertygande",
    numArguments: 2,
    argumentLength: [1, 1],
  });

  const [againstConfig, setAgainstConfig] = useState({
    tone: "Övertygande",
    numArguments: 2,
    argumentLength: [1, 1],
  });

  // UI state
  const [isGenerating, setIsGenerating] = useState(false);
  const [isGeneratingImages, setIsGeneratingImages] = useState(false);
  const [progress, setProgress] = useState(0);
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageInstructions, setImageInstructions] = useState("");

  const [editingSections, setEditingSections] = useState({});
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [lessonData, setLessonData] = useState(null);

  const [loadingImageIndex, setLoadingImageIndex] = useState(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const editorRef = useRef(null);
  const loadingBarRef = useRef(null);
  const { showSnackbar } = useSnackbar();

  // Single state for educational content options
  const [educationalOptions, setEducationalOptions] = useState({});
  const urlContentRef = useRef("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);
  const [predefinedTheme, setPredefinedTheme] = useState(null);
  const lessonTitleRef = useRef(null); // Add this ref
  const transcriptRef = useRef("");
  const uploadedFileRef = useRef("");

  const wikipediaContentRef = useRef("");
  const randomizedContentRef = useRef("");
  const coursePlanContentRef = useRef("");
  const bookContentRef = useRef("");
  const topicSectionRef = useRef(null);
  const factsSectionRef = useRef(null);

  const [searchParams] = useSearchParams();
  const subject = searchParams.get("subject") || null;

  useEffect(() => {
    Logger.log("subject: ", subject);
  }, [subject]);

  useEffect(() => {
    Logger.log("lessonTheme: ", lessonTheme);
  }, [lessonTheme]);

  useEffect(() => {
    Logger.log("predefinedTheme: ", predefinedTheme);
  }, [predefinedTheme]);

  useEffect(() => {
    Logger.log("customTheme: ", customTheme);
  }, [customTheme]);

  useEffect(() => {
    Logger.log("youtubeTranscript: ", transcriptRef.current);
  }, [transcriptRef.current]);

  useEffect(() => {
    Logger.log("wikipediaContentRef: ", wikipediaContentRef.current);
  }, [wikipediaContentRef.current]);
  useEffect(() => {
    Logger.log("uploadedFileRef: ", uploadedFileRef.current);
  }, [uploadedFileRef.current]);

  useEffect(() => {
    Logger.log("randomizedContentRef: ", randomizedContentRef.current);
  }, [randomizedContentRef.current]);

  useEffect(() => {
    Logger.log("coursePlanContentRef: ", coursePlanContentRef.current);
  }, [coursePlanContentRef.current]);

  const { lessonId } = useParams();

  useEffect(() => {
    Logger.log("lessonData: ", JSON.stringify(lessonData, null, 2));
  }, [lessonData]);

  useEffect(() => {
    const loadLessonFromId = async () => {
      if (lessonId) {
        try {
          const q = query(
            collection(db, "lesson_usage"),
            where("__name__", ">=", lessonId),
            where("__name__", "<=", lessonId + "\uf8ff"),
            where("lessonType", "==", "ArgumentationLesson.js"),
            limit(1),
          );

          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            const docData = querySnapshot.docs[0].data();

            if (!docData.hasOpened) {
              await updateDoc(querySnapshot.docs[0].ref, {
                hasOpened: true,
                openedAt: Date.now(),
              });
            }

            setLessonData(docData.data);

            // Scroll to lesson content
            setTimeout(() => {
              lessonTitleRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }, 100);

            showSnackbar("Lektionen har laddats in.", "success");
          } else {
            showSnackbar("Kunde inte hitta lektionen", "error");
          }
        } catch (error) {
          console.error("Error loading lesson:", error);
          showSnackbar("Ett fel uppstod när lektionen skulle laddas", "error");
        }
      }
    };

    loadLessonFromId();
  }, lessonId);

  const additionalThemes = [
    {
      type: "subheader",
      label: "Teman som lämpar sig extra bra för denna lektionstyp:",
    },
    {
      value: "dodsstraff",
      label: "Dödsstraff",
      content: "Dödsstraff",
      Icon: GavelIcon,
    },
    {
      value: "abortratt",
      label: "Aborträtt",
      content: "Aborträtt",
      Icon: MedicalServicesIcon,
    },
    {
      value: "vapenkontroll",
      label: "Vapenkontroll",
      content: "Vapenkontroll",
      Icon: GavelIcon,
    },
    {
      value: "basinkomst",
      label: "Basinkomst",
      content: "Basinkomst",
      Icon: PublicIcon,
    },
    {
      value: "karnkraft",
      label: "Kärnkraft",
      content: "Kärnkraft",
      Icon: ElectricBoltIcon,
    },
    {
      value: "sociala-medier",
      label: "Sociala medier-reglering",
      content: "Sociala medier-reglering",
      Icon: PublicIcon,
    },
    {
      value: "djurforsok",
      label: "Djurförsök",
      content: "Djurförsök",
      Icon: PetsIcon,
    },
    {
      value: "genmodifiering",
      label: "Genmodifiering",
      content: "Genmodifiering",
      Icon: BiotechIcon,
    },
    {
      value: "ai-utveckling",
      label: "AI-utveckling",
      content: "AI-utveckling",
      Icon: SmartToyIcon,
    },
    {
      value: "vaccination",
      label: "Obligatorisk vaccination",
      content: "Obligatorisk vaccination",
      Icon: MedicationIcon,
    },
    {
      value: "socialism-kapitalism",
      label: "Socialism vs Kapitalism",
      content: "Socialism vs Kapitalism",
      Icon: MonetizationOnIcon,
    },
    {
      value: "privatisering-skolan",
      label: "Privatisering av skolan",
      content: "Privatisering av skolan",
      Icon: SchoolIcon,
    },
    {
      value: "klimatkompensation",
      label: "Klimatkompensation",
      content: "Klimatkompensation",
      Icon: NatureIcon,
    },
    {
      value: "hyresreglering",
      label: "Hyresreglering",
      content: "Hyresreglering",
      Icon: HomeIcon,
    },
    {
      value: "migration",
      label: "Migration och integration",
      content: "Migration och integration",
      Icon: Diversity3Icon,
    },
    {
      value: "skattereform",
      label: "Skattereform",
      content: "Skattereform",
      Icon: AccountBalanceIcon,
    },
    {
      value: "polisbefogenheter",
      label: "Polisens befogenheter",
      content: "Polisens befogenheter",
      Icon: LocalPoliceIcon,
    },
    {
      value: "esport",
      label: "E-sport som skolämne",
      content: "E-sport som skolämne",
      Icon: SportsEsportsIcon,
    },
    {
      value: "pressfrihet",
      label: "Pressfrihet",
      content: "Pressfrihet",
      Icon: NewspaperIcon,
    },
    {
      value: "lakemedelspatent",
      label: "Läkemedelspatent",
      content: "Läkemedelspatent",
      Icon: MedicationIcon,
    },
    {
      value: "industrialisering-miljo",
      label: "Industrialisering vs miljö",
      content: "Industrialisering vs miljö",
      Icon: FactoryIcon,
    },
  ];

  const handleSaveLessonClick = () => {
    setSaveDialogOpen(true);
  };

  const handleSaveWithComment = async (comment, name, coverImage, tags) => {
    Logger.log("handleSaveWithComment: ", JSON.stringify(lessonData, null, 2));
    await trackLesson(
      user.uid,
      "ArgumentationLesson.js",
      lessonData,
      null,
      true,
      comment,
      name,
      coverImage,
      tags,
    );
    setDrawerOpen(true);
    showSnackbar("Lektionen sparad i din lektionsbank!", "success");
  };

  const renderEditableSection = (
    section,
    titleKey,
    contentKey,
    index,
    exercise,
  ) => {
    Logger.log("contentKey: ", section);
    if (section === "sentenceStarters") return null;

    const isEditing = editingSections[`${section}-${index}`];

    if (section === "forArguments" || section === "againstArguments") {
      const color = section === "forArguments" ? "#197b19" : "#9b1e1e";
      const title =
        section === "forArguments" ? "Argument för" : "Argument emot";
      const currentArgs =
        section === "forArguments"
          ? lessonData.forArguments
          : lessonData.againstArguments;

      return (
        <Box
          sx={{ position: "relative", "&:hover .edit-buttons": { opacity: 1 } }}
        >
          <Typography
            variant="h6"
            sx={{ color: color, fontWeight: "bold", mb: 2 }}
          >
            {title}
          </Typography>
          <Box
            className="edit-buttons"
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              display: "flex",
              gap: 1,
              opacity: 0,
              transition: "opacity 0.2s",
            }}
          >
            <Tooltip title="Redigera" placement="top">
              <IconButton
                size="small"
                onClick={() => handleSectionEdit(section, index)}
              >
                <EditIcon sx={{ fontSize: 18 }} />
              </IconButton>
            </Tooltip>
          </Box>

          <Box
            sx={{
              backgroundColor:
                theme.palette.mode === "dark" ? "#15202b" : "#e0e0e029",
              borderRadius: "10px",
              padding: "20px",
              height: "100%",
            }}
          >
            {isEditing ? (
              <Box>
                {currentArgs.map((arg, i) => (
                  <Box key={i} sx={{ display: "flex", gap: 1, mb: 2 }}>
                    <TextField
                      fullWidth
                      multiline
                      value={arg}
                      sx={{
                        "& .MuiInputBase-root": {
                          fontSize: "1rem",
                          lineHeight: "1.8",
                          ...styles.editingTextField["& .MuiInputBase-root"],
                        },
                      }}
                      onChange={(e) => {
                        const newArgs = [...currentArgs];
                        newArgs[i] = e.target.value;
                        setLessonData((prev) => ({
                          ...prev,
                          [section]: newArgs,
                        }));
                      }}
                    />
                    <Tooltip title="Ta bort argument" placement="top">
                      <IconButton
                        onClick={() => {
                          const newArgs = currentArgs.filter(
                            (_, idx) => idx !== i,
                          );
                          setLessonData((prev) => ({
                            ...prev,
                            [section]: newArgs,
                          }));
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                ))}
                <Button
                  variant="outlined"
                  startIcon={<AddIcon />}
                  onClick={() => {
                    setLessonData((prev) => ({
                      ...prev,
                      [section]: [...currentArgs, ""],
                    }));
                  }}
                  sx={{ mb: 2, textTransform: "none" }}
                >
                  Lägg till argument
                </Button>
                <Box sx={{ display: "flex", gap: 1 }}>
                  <Button
                    variant={
                      theme.palette.mode === "light" ? "purp" : "contained"
                    }
                    color="primary"
                    startIcon={<SaveAsIcon />}
                    onClick={() => handleSectionSave(section, index)}
                    sx={{ mt: 2, textTransform: "none" }}
                  >
                    Spara ändringar
                  </Button>
                </Box>
              </Box>
            ) : (
              <ul>
                {currentArgs.map((arg, i) => (
                  <li key={i}>
                    <Typography
                      variant="body1"
                      sx={{
                        mb: 1,

                        lineHeight: "1.8",
                      }}
                    >
                      {arg}
                    </Typography>
                  </li>
                ))}
              </ul>
            )}
          </Box>
        </Box>
      );
    }

    return (
      <Box
        sx={{
          mt: 4,
          position: "relative",
          "&:hover .edit-buttons": { opacity: 1 },
        }}
      >
        <Typography
          variant="h6"
          sx={{ mb: 2, fontWeight: "bold", lineHeight: 1.5 }}
        >
          {exercise[titleKey]}
        </Typography>
        <Box
          className="edit-buttons"
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            display: "flex",
            gap: 1,
            opacity: 0,
            transition: "opacity 0.2s",
          }}
        >
          <Tooltip title={isEditing ? "Spara" : "Redigera"}>
            <IconButton
              size="small"
              onClick={() =>
                isEditing
                  ? handleSectionSave(section, index)
                  : handleSectionEdit(section, index)
              }
            >
              {isEditing ? (
                <SaveAsIcon sx={{ fontSize: 18 }} />
              ) : (
                <EditIcon sx={{ fontSize: 18 }} />
              )}
            </IconButton>
          </Tooltip>
          <Tooltip title="Ta bort">
            <IconButton
              size="small"
              onClick={() => handleSectionDelete(section, index)}
            >
              <DeleteIcon sx={{ fontSize: 18 }} />
            </IconButton>
          </Tooltip>
        </Box>
        <Box
          sx={{
            backgroundColor:
              theme.palette.mode === "dark" ? "#15202b" : "#e0e0e029",
            borderRadius: "10px",
            padding: "20px",
            height: "100%",
          }}
        >
          {isEditing ? (
            <Box>
              {Array.isArray(exercise[contentKey]) ? (
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                  {exercise[contentKey].map((item, i) => (
                    <Box
                      key={i}
                      sx={{ display: "flex", gap: 1, alignItems: "flex-start" }}
                    >
                      <TextField
                        fullWidth
                        multiline
                        value={item}
                        onChange={(e) => {
                          const newContent = [...exercise[contentKey]];
                          newContent[i] = e.target.value;
                          handleExerciseUpdate(index, contentKey, newContent);
                        }}
                        sx={{
                          "& .MuiInputBase-root": {
                            lineHeight: 1.8,
                            ...styles.editingTextField["& .MuiInputBase-root"],
                          },
                        }}
                      />
                      <IconButton
                        onClick={() =>
                          handleRemoveListItem(index, contentKey, i)
                        }
                        sx={{ mt: 1 }}
                      >
                        <DeleteIcon sx={{ fontSize: 18 }} />
                      </IconButton>
                    </Box>
                  ))}
                  <Button
                    onClick={() => handleAddListItem(index, contentKey)}
                    startIcon={<AddIcon />}
                    size="small"
                    sx={{ alignSelf: "flex-start", textTransform: "none" }}
                  >
                    Lägg till punkt
                  </Button>
                </Box>
              ) : (
                <TextField
                  fullWidth
                  multiline
                  minRows={4}
                  value={exercise[contentKey]}
                  onChange={(e) =>
                    handleExerciseUpdate(index, contentKey, e.target.value)
                  }
                  sx={{
                    "& .MuiInputBase-root": {
                      lineHeight: 1.8,
                      ...styles.editingTextField["& .MuiInputBase-root"],
                    },
                  }}
                />
              )}
            </Box>
          ) : (
            <Box
              sx={{
                backgroundColor:
                  theme.palette.mode === "dark" ? "#15202b" : "transparent",
                borderRadius: "10px",
                padding: "20px",
              }}
            >
              {Array.isArray(exercise[contentKey]) ? (
                <ul style={{ margin: 0, paddingInlineStart: "20px" }}>
                  {exercise[contentKey].map((item, i) => (
                    <li key={i}>
                      <Typography
                        variant="body1"
                        sx={{ mb: 1, lineHeight: 1.6 }}
                      >
                        {item}
                      </Typography>
                    </li>
                  ))}
                </ul>
              ) : (
                <Typography
                  variant="body1"
                  sx={{ whiteSpace: "pre-wrap", lineHeight: 1.8 }}
                >
                  {exercise[contentKey]}
                </Typography>
              )}
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  // Add this function to handle adding new sentence starters
  const handleSentenceStarterEdit = (type) => {
    setEditingSections((prev) => ({
      ...prev,
      [`sentenceStarters-${type}`]: true,
    }));
  };

  const handleSentenceStarterSave = (type) => {
    setEditingSections((prev) => ({
      ...prev,
      [`sentenceStarters-${type}`]: false,
    }));
    showSnackbar("Meningsstartare har uppdaterats", "success");
  };

  const updateSentenceStarter = (type, index, value) => {
    setLessonData((prev) => ({
      ...prev,
      educationalContent: {
        ...prev.educationalContent,
        sentenceStarters: {
          ...prev.educationalContent.sentenceStarters,
          [type]: prev.educationalContent.sentenceStarters[type].map(
            (starter, i) => (i === index ? value : starter),
          ),
        },
      },
    }));
  };

  // Moonshine: Lägg till knappen rbedivd varje textfält
  const handleDeleteSentenceStarter = (type, index) => {
    setLessonData((prev) => ({
      ...prev,
      educationalContent: {
        ...prev.educationalContent,
        sentenceStarters: {
          ...prev.educationalContent.sentenceStarters,
          [type]: prev.educationalContent.sentenceStarters[type].filter(
            (_, i) => i !== index,
          ),
        },
      },
    }));
  };

  const handleRemoveListItem = (index, contentKey, itemIndex) => {
    setLessonData((prev) => {
      const newData = { ...prev };
      if (newData.educationalContent && newData.educationalContent.exercises) {
        const newExercises = [...newData.educationalContent.exercises];
        newExercises.splice(itemIndex, 1);
        newData.educationalContent.exercises = newExercises;
      }
      return newData;
    });
  };

  const handleSentenceStarterSectionDelete = (type) => {
    setLessonData((prev) => {
      const newData = { ...prev };
      if (newData.educationalContent?.sentenceStarters) {
        delete newData.educationalContent.sentenceStarters[type];

        if (
          Object.keys(newData.educationalContent.sentenceStarters).length === 0
        ) {
          delete newData.educationalContent.sentenceStarters;
        }

        if (Object.keys(newData.educationalContent).length === 0) {
          delete newData.educationalContent;
        }
      }
      return newData;
    });

    showSnackbar("Sektionen har tagits bort", "success");
  };

  const handleAddListItem = (index, contentKey) => {
    setLessonData((prev) => {
      const newData = { ...prev };
      if (!newData.educationalContent) {
        newData.educationalContent = { exercises: [] };
      }
      if (!newData.educationalContent.exercises) {
        newData.educationalContent.exercises = [];
      }
      newData.educationalContent.exercises.push("");
      return newData;
    });
  };

  const handleSectionDelete = (section, index) => {
    setLessonData((prev) => {
      const newData = { ...prev };

      if (section === "introduction") {
        // Remove the introduction property entirely
        delete newData.introduction;
      } else if (
        newData.educationalContent &&
        newData.educationalContent[section]
      ) {
        delete newData.educationalContent[section];

        if (Object.keys(newData.educationalContent).length === 0) {
          delete newData.educationalContent;
        }
      } else if (section === "exercises") {
        newData.exercises = newData.exercises.filter((_, i) => i !== index);
      }

      return newData;
    });

    setEditingSections((prev) => ({
      ...prev,
      [`${section}-${index}`]: false,
    }));

    showSnackbar("Sektionen har tagits bort", "success");
  };

  const handleSectionEdit = (section, index) => {
    setEditingSections((prev) => ({
      ...prev,
      [`${section}-${index}`]: true,
    }));
  };

  const handleSectionSave = (section, index) => {
    setEditingSections((prev) => ({
      ...prev,
      [`${section}-${index}`]: false,
    }));
    showSnackbar("Sektionen har uppdaterats", "success");
  };

  const handleExerciseUpdate = (index, key, value) => {
    setLessonData((prev) => {
      const newExercises = [...prev.exercises];
      newExercises[index] = {
        ...newExercises[index],
        [key]: value,
      };
      return {
        ...prev,
        exercises: newExercises,
      };
    });
  };

  const simulateProgress = (startProgress, endProgress, duration) => {
    const stepSize = 0.5;
    const steps = (endProgress - startProgress) / stepSize;
    const stepDuration = duration / steps;
    let currentProgress = startProgress;

    const intervalId = setInterval(() => {
      currentProgress += stepSize;
      if (currentProgress >= endProgress) {
        clearInterval(intervalId);
      } else {
        setProgress(currentProgress);
      }
    }, stepDuration);

    return () => clearInterval(intervalId);
  };

  // Initialize checkboxes
  React.useEffect(() => {
    const initialOptions = EDUCATIONAL_OPTIONS.reduce((acc, option) => {
      acc[option.id] = false;
      return acc;
    }, {});
    setEducationalOptions(initialOptions);
  }, []);

  const handleGenerateDebate = async () => {
    setProgress(0);
    setIsGenerating(true);
    let stopProgressSimulation = null;
    try {
      let themeToUse;

      if (lessonTheme === "youtube") {
        themeToUse = transcriptRef.current;
      } else if (lessonTheme === "customTheme") {
        themeToUse = customTheme;
      } else if (lessonTheme === "predefined") {
        themeToUse = predefinedTheme;
      } else if (lessonTheme === "fileUpload") {
        themeToUse = uploadedFileRef.current;
      } else if (lessonTheme === "wikipedia") {
        themeToUse = wikipediaContentRef.current;
      } else if (lessonTheme === "randomizer") {
        themeToUse = randomizedContentRef.current;
      } else if (lessonTheme === "book") {
        themeToUse = bookContentRef.current;
      } else if (lessonTheme === "url") {
        themeToUse = urlContentRef.current;
      } else {
        //
        themeToUse = coursePlanContentRef.current;
      }

      Logger.log("themeToUse: ", themeToUse);

      stopProgressSimulation = simulateProgress(0, 90, 15000, setProgress);

      const response = await callApi(
        [
          {
            role: "system",
            content:
              "You are an assistant that generates balanced debate content.",
          },
          {
            role: "user",
            content: `Generate a debate package in ${language} about "${themeToUse}" with:
            1. An introductory background text (5 sentences) that spurs excitement in the students to learn more.
            2. ${forConfig.numArguments} arguments FOR with tone: ${forConfig.tone} (${forConfig.argumentLength[0]}-${forConfig.argumentLength[1]} sentences each)
            3. ${againstConfig.numArguments} arguments AGAINST with tone: ${againstConfig.tone} (${againstConfig.argumentLength[0]}-${againstConfig.argumentLength[1]} sentences each)
4. Additional educational content (EXACTLY 10 items for each) based on selected options:
               ${Object.entries(educationalOptions)
                 .filter(([_, value]) => value)
                 .map(([key]) => `- ${key}`)
                 .join("\n")}
            
            ALWAYS RETURN IN Format as JSON:
             {
              "title":"title of the lesson",
            "introduction": "text...",
            "forArguments": ["arg1", "arg2"...],
            "againstArguments": ["arg1", "arg2"...]
       ${
         Object.values(educationalOptions).some((value) => value)
           ? `,
     "educationalContent": {
         ${educationalOptions.celebrities ? `"celebrities": [{"name": "", "quote": "", "source": "", "stance": "for/against/neutral"}] (EXACTLY 10 ITEMS),` : ""}
         ${educationalOptions.exercises ? `"exercises": ["question1", "question2", "..."] (EXACTLY 10 ITEMS),` : ""}
         ${
           educationalOptions.sentenceStarters
             ? `
         "sentenceStarters": {
             "general": ["start1", "start2", "..."] (EXACTLY 10 ITEMS),
             "for": ["for_start1", "for_start2", "..."] (EXACTLY 10 ITEMS),
             "against": ["against_start1", "against_start2", "..."] (EXACTLY 10 ITEMS)
         },`
             : ""
         }
         ${educationalOptions.historicalContext ? `"historicalContext": "text..." (EXACTLY 10 SENTENCES),` : ""}
         ${educationalOptions.statistics ? `"statistics": [{"fact": "", "sourceUrl": ""}] (EXACTLY 10 ITEMS),` : ""}
         ${educationalOptions.realExamples ? `"realExamples": ["example1", "example2", "..."] (EXACTLY 10 ITEMS),` : ""}
         ${educationalOptions.ethicalDilemmas ? `"ethicalDilemmas": ["dilemma1", "dilemma2", "..."] (EXACTLY 10 ITEMS),` : ""}
         ${educationalOptions.rolePlay ? `"rolePlay": ["scenario1", "scenario2", "..."] (EXACTLY 10 ITEMS),` : ""}
         ${educationalOptions.vocabulary ? `"vocabulary": [{"term": "", "definition": ""}] (EXACTLY 10 ITEMS),` : ""}
         ${educationalOptions.mediaAnalysis ? `"mediaAnalysis": ["point1", "point2", "..."] (EXACTLY 10 ITEMS),` : ""}
         ${educationalOptions.whatIf ? `"whatIf": ["scenario1", "scenario2", "..."] (EXACTLY 10 ITEMS),` : ""}
         ${educationalOptions.futureImpact ? `"futureImpact": ["impact1", "impact2", "..."] (EXACTLY 10 ITEMS),` : ""}
         ${educationalOptions.factOpinion ? `"factOpinion": [{"fact": "fact1", "opinion": "opinion1"}] (EXACTLY 10 ITEMS),` : ""}
         ${educationalOptions.reflectionQuestions ? `"reflectionQuestions": ["reflectionQuestion1", "reflectionQuestion2", "..."] (EXACTLY 10 ITEMS),` : ""}
     }`
           : ""
       }
            }`,
          },
        ],
        10000,
        true,
        user.uid,
      );

      let messageContent = response.data.choices[0].message.content.trim();

      if (messageContent.startsWith("```json")) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith("```")) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      // Stop the progress simulation
      if (stopProgressSimulation) {
        stopProgressSimulation();
      }

      setProgress(95);
      const data = JSON.parse(messageContent);

      Logger.log(
        "response Educational Options:",
        Object.keys(educationalOptions).filter(
          (key) => educationalOptions[key],
        ),
      );
      Logger.log("response: ", JSON.stringify(data, null, 2));

      setProgress(100);

      setLessonData({
        ...data,

        images: [{ imageUrl: null }, { imageUrl: null }],
      });

      await new Promise((resolve) => setTimeout(resolve, 800)); // Longer delay for visibility

      showSnackbar("Lektionen är genererad!", "success");

      await trackLesson(user.uid, "ArgumentationLesson.js", null, {
        language: language,
        lessonTheme: lessonTheme,
      });
    } catch (error) {
      console.error("Error generating debate:", error);
      showSnackbar(
        "Ett fel uppstod när lektionen skulle genereras. Försök igen.",
        "error",
      );
    } finally {
      setIsGenerating(false);
    }
  };

  const handleImageButtonClick = (index = null) => {
    setSelectedImageIndex(index);
    setShowImageModal(true);
  };

  const handleCloseModal = () => {
    setShowImageModal(false);
    setImageInstructions("");
  };

  const handleGenerateImages = async () => {
    if (!lessonData) return;
    setIsGeneratingImages(true);
    handleCloseModal();

    try {
      const imagePrompt = `Create an image about a debate with the theme: "${lessonData.title}". ${imageInstructions}`;
      const imagePromises = lessonData.images.map(() =>
        generateAndProcessImage(imagePrompt, user.uid),
      );
      const imageUrls = await Promise.all(imagePromises);

      setLessonData((prevData) => ({
        ...prevData,
        images: imageUrls.map((imageUrl) => ({ imageUrl })),
      }));

      showSnackbar("Bilderna har genererats!", "success");
    } catch (error) {
      handleImageGenerationError(error, true, showSnackbar);
    } finally {
      setIsGeneratingImages(false);
      setLoadingImageIndex(null);
    }
  };

  const handleGenerateSpecificImage = async (index) => {
    setIsGeneratingImages(true);
    setLoadingImageIndex(index);
    handleCloseModal();

    try {
      const imagePrompt = `Create an image about a debate with the theme: "${lessonData.title}". ${imageInstructions}`;
      const imageUrl = await generateAndProcessImage(imagePrompt, user.uid);

      if (imageUrl) {
        setLessonData((prevData) => {
          const newImages = [...prevData.images];
          newImages[index] = { imageUrl };
          return {
            ...prevData,
            images: newImages,
          };
        });
        showSnackbar("Bilden har genererats!", "success");
      }
    } catch (error) {
      handleImageGenerationError(error, false, showSnackbar);
    } finally {
      setIsGeneratingImages(false);
      setLoadingImageIndex(null);
    }
  };

  const handleGenerateWithInstructions = async () => {
    if (selectedImageIndex !== null) {
      await handleGenerateSpecificImage(selectedImageIndex);
    } else {
      await handleGenerateImages();
    }
  };

  // Add the missing function to map educational content types to Swedish titles
  const getEducationalSectionTitle = (key) => {
    const titles = {
      celebrities: "Kända personers åsikter",
      exercises: "Övningar för elever",
      sentenceStarters: "Meningsstartare",
      historicalContext: "Historisk kontext",
      statistics: "Statistik och fakta",
      realExamples: "Verkliga exempel",
      ethicalDilemmas: "Etiska dilemman",
      rolePlay: "Rollspelsscenarier",
      vocabulary: "Ämnesspecifikt ordförråd",
      mediaAnalysis: "Medieanalys",
      reflectionQuestions: "Reflektionsfrågor efter debatten",
      whatIf: "Tänk om-scenarier",
      futureImpact: "Framtidsperspektiv",
      factOpinion: "Fakta vs åsikter",
    };
    return titles[key] || key;
  };

  // Arguments Configuration section fix
  const ArgumentConfig = ({ config, setConfig, title, color }) => (
    <>
      <Typography variant="h6" sx={{ mb: 4, color, fontWeight: "600" }}>
        {title}
      </Typography>

      <Typography sx={{ mb: 1, fontWeight: "500" }}>Ton:</Typography>
      <Select
        fullWidth
        value={config.tone}
        onChange={(e) => setConfig({ ...config, tone: e.target.value })}
        sx={{ mb: 4 }}
      >
        {TONE_OPTIONS.map((tone) => (
          <MenuItem key={tone} value={tone}>
            {tone}
          </MenuItem>
        ))}
      </Select>

      <Typography sx={{ mb: 1, fontWeight: "500" }}>Antal argument:</Typography>
      <Select
        fullWidth
        value={config.numArguments}
        onChange={(e) => setConfig({ ...config, numArguments: e.target.value })}
        sx={{ mb: 4 }}
      >
        {[...Array(11)].map((_, i) => (
          <MenuItem key={i} value={i}>
            {i}
          </MenuItem>
        ))}
      </Select>

      <Typography sx={{ fontWeight: "500" }}>
        Längd per argument (meningar):
      </Typography>
      <Slider
        sx={{ mt: 3 }}
        value={config.argumentLength}
        onChange={(e, newValue) =>
          setConfig({ ...config, argumentLength: newValue })
        }
        valueLabelDisplay="auto"
        min={1}
        max={5}
      />
    </>
  );

  return (
    <Box sx={styles.container}>
      <Box
        sx={{
          ...styles.innerContainer,
          width: { xs: "100%", md: "87%" },
          margin: "0 auto",
        }}
      >
        <Typography
          variant="h1"
          gutterBottom
          sx={{
            fontWeight: "bold",
            fontSize: "1.8em",
            mb: 3,
            mt: 5,
            textAlign: "center",
          }}
        >
          Argumentera
        </Typography>

        <LessonDrawer
          userId={user.uid}
          lessonType="ArgumentationLesson.js"
          theme={theme}
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          lessonIcon={GavelIcon}
          onSelectLesson={(lesson) => {
            setLessonData(lesson.data);

            setDrawerOpen(false);

            showSnackbar(`Lektionen har laddats in.`, "success");
            setTimeout(() => {
              lessonTitleRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "start", // This ensures it scrolls to the top of the element
              });
            }, 100);
          }}
        />

        <Box
          sx={{
            ...styles.buttonsContainer,
            ...styles.additionalButtonsContainer,
          }}
        >
          <Tooltip title="Dina sparade lektioner">
            <Button
              variant="contained"
              color="primary"
              disableRipple
              onClick={() => setDrawerOpen(true)}
              startIcon={<HistoryIcon />}
              sx={{
                padding: "8px",
                marginRight: "0px",
                paddingLeft: "14px",
                paddingRight: "3px",
                minWidth: "auto",
                textTransform: "none",
              }}
            />
          </Tooltip>
        </Box>

        <InfoBox
          icon={GavelIcon}
          title="Debatt Generator"
          instructions={[
            "Välj ett debattämne eller skapa ett eget",
            "Konfigurera argument för båda sidor",
            "Lägg till en illustrerande bild",
            "Skriv ut lektionen",
          ]}
          theme={theme}
          styles={styles}
          user={user}
        />

        <ThemeSelectorFlow
          key={subject}
          subject={subject}
          value={lessonTheme}
          onChange={(e) => setLessonTheme(e.target.value)}
          customTheme={customTheme}
          onCustomThemeChange={(e) => setCustomTheme(e.target.value)}
          onPredefinedThemeChange={(e) => setPredefinedTheme(e.target.value)}
          onYoutubeTranscriptChange={(transcriptContent) => {
            Logger.log(
              "onYoutubeTranscriptChange körs med: ",
              transcriptContent,
            );
            transcriptRef.current = transcriptContent;
          }}
          onUrlContentChange={(content) => {
            Logger.log(
              "Parent: URL content received",
              content.substring(0, 300),
            );
            urlContentRef.current = content;
          }}
          onFileUpload={(content) => {
            Logger.log("onFileUpload körs med: ", content);
            uploadedFileRef.current = content;
          }}
          onWikipediaContentChange={(content) => {
            Logger.log(
              "Parent: Wikipedia content received",
              content.substring(0, 100),
            );
            wikipediaContentRef.current = content;
          }}
          onRandomizedContentChange={(content) => {
            Logger.log("randomizedContentRef", content.substring(0, 100));
            randomizedContentRef.current = content;
          }}
          onCoursePlanContentChange={(content) => {
            Logger.log("onCoursePlanChange", content.substring(0, 100));
            coursePlanContentRef.current = content;
          }}
          onBookContentChange={(content) => {
            Logger.log("onBookContentChange", content.substring(0, 100));
            bookContentRef.current = content;
          }}
          lessonType="ArgumentationLesson.js"
          user={user}
          theme={theme}
          styles={styles}
          onLessonSelect={(lesson) => {
            Logger.log("onLessonSelect körs");
            setLessonData(lesson.data);
            setTimeout(() => {
              lessonTitleRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "start", // This ensures it scrolls to the top of the element
              });
            }, 100);
            showSnackbar("Lektionen har laddats in.", "success");
          }}
          additionalThemes={additionalThemes}
        />
        <Box sx={styles.headlineLessonBox}>
          <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
            2. Generera lektionen
          </Typography>
        </Box>

        <Box sx={{ ...styles.freshBox, padding: "30px" }}>
          <LoadingBar
            color={theme.palette.primary.main}
            ref={loadingBarRef}
            shadow={true}
          />

          {/* Language and Grade Selection */}
          <Typography variant="subtitle1" sx={{ fontWeight: "500" }}>
            Språk:
          </Typography>
          <Box sx={{ mt: 1, mb: 3 }}>
            <LanguageSelector language={language} setLanguage={setLanguage} />
          </Box>

          <Typography variant="subtitle1" sx={{ fontWeight: "500" }}>
            Årskurs:
          </Typography>
          <Box sx={{ mt: 1, mb: 4 }}>
            <GradeSelector grade={grade} setGrade={setGrade} />
          </Box>

          <Divider sx={{ my: 3 }} />

          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <ArgumentConfig
                config={forConfig}
                setConfig={setForConfig}
                title="Argument för"
                color="#197b19"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ArgumentConfig
                config={againstConfig}
                setConfig={setAgainstConfig}
                title="Argument emot"
                color="#9b1e1e"
              />
            </Grid>
          </Grid>
          {/* Educational Options Section */}
          <Box sx={{ mt: 4 }}>
            <Divider sx={{ mb: 3 }} />
            <Typography variant="h6" sx={{ mb: 2, fontWeight: "600" }}>
              Inkludera läromaterial
            </Typography>

            <Grid container spacing={1}>
              {EDUCATIONAL_OPTIONS.map((option) => (
                <Grid item xs={12} sm={6} md={4} key={option.id}>
                  <CustomFormControlLabel
                    control={<Checkbox />}
                    label={option.label}
                    checked={educationalOptions[option.id]}
                    onChange={(e) =>
                      setEducationalOptions({
                        ...educationalOptions,
                        [option.id]: e.target.checked,
                      })
                    }
                    theme={theme}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>

          {/* Generate Buttons */}

          <Box sx={{ mt: 6 }}>
            <Button
              variant={theme.palette.mode === "light" ? "purp" : "contained"}
              color="primary"
              onClick={handleGenerateDebate}
              disabled={isGenerating}
              startIcon={
                !isGenerating ? (
                  <GenerateIcon style={{ color: "#fff" }} />
                ) : (
                  <CircularProgress size={16} />
                )
              }
            >
              {isGenerating ? "Genererar..." : "Generera lektion"}
            </Button>
          </Box>

          {isGenerating && (
            <Box sx={{ mt: 6 }}>
              <ProgressBar
                progress={progress}
                label="Genererar lektion — tar 30-60 sekunder..."
              />
            </Box>
          )}
        </Box>

        <Box sx={{ mt: 4 }}>
          {lessonData && (
            <>
              <Box
                ref={lessonTitleRef}
                sx={{ ...styles.headlineLessonBox, mt: 10 }}
              >
                <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
                  3. Din lektion
                </Typography>
              </Box>
              <Box sx={{ ...styles.freshBox, padding: "30px" }}>
                <Box sx={styles.lessonControlPanel}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      px: 1,
                    }}
                  >
                    <Box>
                      <Button
                        variant={
                          theme.palette.mode === "light" ? "purp" : "contained"
                        }
                        color="primary"
                        onClick={() => handleImageButtonClick(null)}
                        disabled={isGeneratingImages}
                        sx={{ mt: 3, display: "none" }}
                        startIcon={
                          !isGeneratingImages ? (
                            <ImageIcon style={{ color: "#fff" }} />
                          ) : (
                            <CircularProgress size={16} />
                          )
                        }
                      >
                        {isGeneratingImages
                          ? "Genererar..."
                          : "Generera bilder"}
                      </Button>

                      <SaveLessonDialog
                        open={saveDialogOpen}
                        onClose={() => setSaveDialogOpen(false)}
                        onSave={handleSaveWithComment}
                        theme={theme}
                        lessonName={lessonData?.title || ""}
                        lessonObject={lessonData}
                        lessonType="ArgumentationLesson.js"
                      />

                      <ImageGenerationModal
                        open={showImageModal}
                        onClose={handleCloseModal}
                        onGenerate={handleGenerateWithInstructions}
                        isGenerating={isGeneratingImages}
                        instructions={imageInstructions}
                        setInstructions={setImageInstructions}
                        theme={theme}
                      />

                      <Button
                        variant="print"
                        startIcon={<PrintIcon />}
                        sx={{ ml: 2 }}
                        onClick={() =>
                          handlePrintArgumentation(lessonData, null)
                        }
                      >
                        Skriv ut
                      </Button>
                      <Button
                        variant="print"
                        startIcon={<SaveAsIcon />}
                        sx={{ ml: 2 }}
                        onClick={handleSaveLessonClick}
                      >
                        Spara i lektionsbank
                      </Button>
                    </Box>
                  </Box>
                </Box>

                <Box sx={{ mt: 0 }}>
                  {/* Title Section */}
                  {isEditingTitle ? (
                    <TextField
                      value={lessonData.title}
                      onChange={(e) =>
                        setLessonData((prev) => ({
                          ...prev,
                          title: e.target.value,
                        }))
                      }
                      onBlur={() => {
                        setIsEditingTitle(false);
                        showSnackbar("Titeln har uppdaterats", "success");
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          setIsEditingTitle(false);
                          showSnackbar("Titeln har uppdaterats", "success");
                        }
                      }}
                      autoFocus
                      fullWidth
                      variant="standard"
                      sx={{
                        backgroundColor: "transparent",
                        mb: 7,
                        "& .MuiInputBase-input": {
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "2.6em",
                          marginBottom: "1.2rem",
                          marginTop: "1.5rem",
                        },
                      }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                    />
                  ) : (
                    <Box
                      sx={{
                        position: "relative",
                        display: "inline-block",
                        margin: "0 auto",
                        width: "100%",
                        mb: 3,
                        mt: 5,
                        ":hover .edit-icon": {
                          opacity: 1,
                        },
                      }}
                    >
                      <Typography
                        variant="h1"
                        sx={{
                          fontWeight: "bold",
                          fontSize: "2.6em",
                          textAlign: "center",
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: 2,
                          mb: 7,
                        }}
                      >
                        {lessonData.title || " "}
                        <Box
                          component="span"
                          sx={{
                            opacity: 0,
                            transition: "opacity 0.2s",
                            display: "inline-flex",
                            gap: 0.5,
                          }}
                          className="edit-icon"
                        >
                          <Tooltip title="Redigera" placement="top">
                            <IconButton
                              size="small"
                              onClick={() => setIsEditingTitle(true)}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Typography>
                    </Box>
                  )}

                  {/* Introduction */}
                  {lessonData.introduction !== null &&
                    lessonData.introduction !== undefined &&
                    renderEditableSection(
                      "introduction",
                      "title",
                      "content",
                      0,
                      {
                        type: "introduction",
                        title: "Introduktion",
                        content: lessonData.introduction,
                      },
                    )}
                  {/* Arguments Grid */}
                  <Grid container spacing={4} sx={{ mb: 4, mt: 4 }}>
                    <Grid item xs={12} md={6}>
                      {renderEditableSection("forArguments", null, null, 0, {})}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      {renderEditableSection(
                        "againstArguments",
                        null,
                        null,
                        1,
                        {},
                      )}
                    </Grid>
                  </Grid>
                  {/* Images Grid */}
                  <Grid
                    container
                    spacing={2}
                    sx={{ mb: 4, mt: 4, justifyContent: "center" }}
                  >
                    {lessonData.images.map((image, index) => (
                      <Grid item xs={12} md={6} key={index}>
                        <HoverableImage
                          imageUrl={image.imageUrl}
                          isLoading={isGeneratingImages}
                          loadingIndex={loadingImageIndex}
                          currentIndex={index}
                          onGenerateClick={() => handleImageButtonClick(index)}
                          userId={user.uid}
                          onImageUpdate={(newImageUrl) => {
                            setLessonData((prevData) => {
                              const newImages = [...prevData.images];
                              newImages[index] = { imageUrl: newImageUrl };
                              return {
                                ...prevData,
                                images: newImages,
                              };
                            });
                          }}
                          height="400px"
                          theme={theme}
                          showSnackbar={showSnackbar}
                        />
                      </Grid>
                    ))}
                  </Grid>

                  {/* Sentence Starters Section */}
                  {lessonData?.educationalContent?.sentenceStarters && (
                    <Box sx={{ mt: 4 }}>
                      {/* General Sentence Starters */}
                      {lessonData.educationalContent.sentenceStarters
                        .general && (
                        <Box
                          sx={{
                            mt: 4,
                            position: "relative",
                            "&:hover .edit-buttons": { opacity: 1 },
                          }}
                        >
                          <Typography
                            variant="h6"
                            sx={{ mb: 2, fontWeight: "bold" }}
                          >
                            Generella meningsstartare
                          </Typography>
                          <Box
                            className="edit-buttons"
                            sx={{
                              position: "absolute",
                              top: 8,
                              right: 8,
                              display: "flex",
                              gap: 1,
                              opacity: 0,
                              transition: "opacity 0.2s",
                            }}
                          >
                            <Tooltip
                              title={
                                editingSections["sentenceStarters-general"]
                                  ? "Spara"
                                  : "Redigera"
                              }
                              placement="top"
                            >
                              <IconButton
                                size="small"
                                onClick={() =>
                                  editingSections["sentenceStarters-general"]
                                    ? handleSentenceStarterSave("general")
                                    : handleSentenceStarterEdit("general")
                                }
                              >
                                {editingSections["sentenceStarters-general"] ? (
                                  <SaveAsIcon sx={{ fontSize: 18 }} />
                                ) : (
                                  <EditIcon sx={{ fontSize: 18 }} />
                                )}
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Ta bort" placement="top">
                              <IconButton
                                size="small"
                                onClick={() =>
                                  handleSentenceStarterSectionDelete("general")
                                }
                              >
                                <DeleteIcon sx={{ fontSize: 18 }} />
                              </IconButton>
                            </Tooltip>
                          </Box>

                          <Box
                            sx={{
                              backgroundColor:
                                theme.palette.mode === "dark"
                                  ? "#15202b"
                                  : "#e0e0e029",
                              borderRadius: "10px",
                              padding: "20px",
                              height: "100%",
                            }}
                          >
                            {editingSections["sentenceStarters-general"] ? (
                              <Box>
                                {lessonData.educationalContent.sentenceStarters.general.map(
                                  (starter, i) => (
                                    <Box
                                      key={i}
                                      sx={{ display: "flex", gap: 1, mb: 2 }}
                                    >
                                      <TextField
                                        fullWidth
                                        multiline
                                        value={starter}
                                        onChange={(e) =>
                                          updateSentenceStarter(
                                            "general",
                                            i,
                                            e.target.value,
                                          )
                                        }
                                        sx={{
                                          "& .MuiInputBase-root": {
                                            lineHeight: 1.8,
                                            ...styles.editingTextField[
                                              "& .MuiInputBase-root"
                                            ],
                                          },
                                        }}
                                      />
                                    </Box>
                                  ),
                                )}
                                <Button
                                  variant={
                                    theme.palette.mode === "light"
                                      ? "purp"
                                      : "contained"
                                  }
                                  color="primary"
                                  startIcon={<SaveAsIcon />}
                                  onClick={() =>
                                    handleSectionSave(
                                      "sentenceStarters-general",
                                    )
                                  }
                                  sx={{ mt: 2, textTransform: "none" }}
                                >
                                  Spara ändringar
                                </Button>
                              </Box>
                            ) : (
                              <ul>
                                {lessonData.educationalContent.sentenceStarters.general.map(
                                  (starter, i) => (
                                    <li key={i}>
                                      <Typography
                                        variant="body1"
                                        sx={{ mb: 1, lineHeight: 1.6 }}
                                      >
                                        {starter}
                                      </Typography>
                                    </li>
                                  ),
                                )}
                              </ul>
                            )}
                          </Box>
                        </Box>
                      )}
                      {/* For Arguments Sentence Starters */}
                      {lessonData.educationalContent.sentenceStarters.for && (
                        <Box
                          sx={{
                            mt: 4,
                            position: "relative",
                            "&:hover .edit-buttons": { opacity: 1 },
                          }}
                        >
                          <Typography
                            variant="h6"
                            sx={{ mb: 2, fontWeight: "bold" }}
                          >
                            Meningsstartare för argument för
                          </Typography>
                          <Box
                            className="edit-buttons"
                            sx={{
                              position: "absolute",
                              top: 8,
                              right: 8,
                              display: "flex",
                              gap: 1,
                              opacity: 0,
                              transition: "opacity 0.2s",
                            }}
                          >
                            <Tooltip
                              title={
                                editingSections["sentenceStarters-for"]
                                  ? "Spara"
                                  : "Redigera"
                              }
                              placement="top"
                            >
                              <IconButton
                                size="small"
                                onClick={() =>
                                  editingSections["sentenceStarters-for"]
                                    ? handleSentenceStarterSave("for")
                                    : handleSentenceStarterEdit("for")
                                }
                              >
                                {editingSections["sentenceStarters-for"] ? (
                                  <SaveAsIcon sx={{ fontSize: 18 }} />
                                ) : (
                                  <EditIcon sx={{ fontSize: 18 }} />
                                )}
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Ta bort" placement="top">
                              <IconButton
                                size="small"
                                onClick={() =>
                                  handleSentenceStarterSectionDelete("for")
                                }
                              >
                                <DeleteIcon sx={{ fontSize: 18 }} />
                              </IconButton>
                            </Tooltip>
                          </Box>

                          <Box
                            sx={{
                              backgroundColor:
                                theme.palette.mode === "dark"
                                  ? "#15202b"
                                  : "#e0e0e029",
                              borderRadius: "10px",
                              padding: "20px",
                              height: "100%",
                            }}
                          >
                            {editingSections["sentenceStarters-for"] ? (
                              <Box>
                                {lessonData.educationalContent.sentenceStarters.for.map(
                                  (starter, i) => (
                                    <Box
                                      key={i}
                                      sx={{ display: "flex", gap: 1, mb: 2 }}
                                    >
                                      <TextField
                                        fullWidth
                                        multiline
                                        value={starter}
                                        onChange={(e) =>
                                          updateSentenceStarter(
                                            "for",
                                            i,
                                            e.target.value,
                                          )
                                        }
                                        sx={{
                                          "& .MuiInputBase-root": {
                                            lineHeight: 1.8,
                                            ...styles.editingTextField[
                                              "& .MuiInputBase-root"
                                            ],
                                          },
                                        }}
                                      />
                                    </Box>
                                  ),
                                )}
                                <Button
                                  variant={
                                    theme.palette.mode === "light"
                                      ? "purp"
                                      : "contained"
                                  }
                                  color="primary"
                                  startIcon={<SaveAsIcon />}
                                  onClick={() =>
                                    handleSectionSave("sentenceStarters-for")
                                  }
                                  sx={{ mt: 2, textTransform: "none" }}
                                >
                                  Spara ändringar
                                </Button>
                              </Box>
                            ) : (
                              <ul>
                                {lessonData.educationalContent.sentenceStarters.for.map(
                                  (starter, i) => (
                                    <li key={i}>
                                      <Typography
                                        variant="body1"
                                        sx={{ mb: 1, lineHeight: 1.6 }}
                                      >
                                        {starter}
                                      </Typography>
                                    </li>
                                  ),
                                )}
                              </ul>
                            )}
                          </Box>
                        </Box>
                      )}

                      {/* Against Arguments Sentence Starters */}
                      {lessonData.educationalContent.sentenceStarters
                        .against && (
                        <Box
                          sx={{
                            mt: 4,
                            position: "relative",
                            "&:hover .edit-buttons": { opacity: 1 },
                          }}
                        >
                          <Typography
                            variant="h6"
                            sx={{ mb: 2, fontWeight: "bold" }}
                          >
                            Meningsstartare för argument emot
                          </Typography>
                          <Box
                            className="edit-buttons"
                            sx={{
                              position: "absolute",
                              top: 8,
                              right: 8,
                              display: "flex",
                              gap: 1,
                              opacity: 0,
                              transition: "opacity 0.2s",
                            }}
                          >
                            <Tooltip
                              title={
                                editingSections["sentenceStarters-against"]
                                  ? "Spara"
                                  : "Redigera"
                              }
                              placement="top"
                            >
                              <IconButton
                                size="small"
                                onClick={() =>
                                  editingSections["sentenceStarters-against"]
                                    ? handleSentenceStarterSave("against")
                                    : handleSentenceStarterEdit("against")
                                }
                              >
                                {editingSections["sentenceStarters-against"] ? (
                                  <SaveAsIcon sx={{ fontSize: 18 }} />
                                ) : (
                                  <EditIcon sx={{ fontSize: 18 }} />
                                )}
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Ta bort" placement="top">
                              <IconButton
                                size="small"
                                onClick={() =>
                                  handleSentenceStarterSectionDelete("against")
                                }
                              >
                                <DeleteIcon sx={{ fontSize: 18 }} />
                              </IconButton>
                            </Tooltip>
                          </Box>

                          <Box
                            sx={{
                              backgroundColor:
                                theme.palette.mode === "dark"
                                  ? "#15202b"
                                  : "#e0e0e029",
                              borderRadius: "10px",
                              padding: "20px",
                              height: "100%",
                            }}
                          >
                            {editingSections["sentenceStarters-against"] ? (
                              <Box>
                                {lessonData.educationalContent.sentenceStarters.against.map(
                                  (starter, i) => (
                                    <Box
                                      key={i}
                                      sx={{
                                        display: "flex",
                                        gap: 1,
                                        mb: 2,
                                        alignItems: "flex-start",
                                      }}
                                    >
                                      <TextField
                                        fullWidth
                                        multiline
                                        value={starter}
                                        onChange={(e) =>
                                          updateSentenceStarter(
                                            "against",
                                            i,
                                            e.target.value,
                                          )
                                        }
                                        sx={{
                                          "& .MuiInputBase-root": {
                                            lineHeight: 1.8,
                                            ...styles.editingTextField[
                                              "& .MuiInputBase-root"
                                            ],
                                          },
                                        }}
                                      />
                                      <Tooltip
                                        title="Ta bort meningsstartare"
                                        placement="top"
                                      >
                                        <IconButton
                                          onClick={() =>
                                            handleDeleteSentenceStarter(
                                              "against",
                                              i,
                                            )
                                          }
                                          sx={{ mt: 1 }}
                                        >
                                          <DeleteIcon sx={{ fontSize: 20 }} />
                                        </IconButton>
                                      </Tooltip>
                                    </Box>
                                  ),
                                )}
                                <Button
                                  variant={
                                    theme.palette.mode === "light"
                                      ? "purp"
                                      : "contained"
                                  }
                                  color="primary"
                                  startIcon={<SaveAsIcon />}
                                  onClick={() =>
                                    handleSectionSave(
                                      "sentenceStarters-against",
                                    )
                                  }
                                  sx={{ mt: 2, textTransform: "none" }}
                                >
                                  Spara ändringar
                                </Button>
                              </Box>
                            ) : (
                              <ul>
                                {lessonData.educationalContent.sentenceStarters.against.map(
                                  (starter, i) => (
                                    <li key={i}>
                                      <Typography
                                        variant="body1"
                                        sx={{ mb: 1, lineHeight: 1.6 }}
                                      >
                                        {starter}
                                      </Typography>
                                    </li>
                                  ),
                                )}
                              </ul>
                            )}
                          </Box>
                        </Box>
                      )}
                    </Box>
                  )}

                  {/* Vocabulary Table */}
                  {lessonData.educationalContent?.vocabulary &&
                    renderEditableSection("vocabulary", "title", "content", 2, {
                      type: "vocabulary",
                      title: "Ämnesspecifikt ordförråd",
                      content: lessonData.educationalContent.vocabulary,
                    })}

                  {/* Facts vs Opinions Table */}
                  {lessonData.educationalContent?.factOpinion &&
                    renderEditableSection(
                      "factOpinion",
                      "title",
                      "content",
                      3,
                      {
                        type: "factOpinion",
                        title: "Fakta vs åsikter",
                        content: lessonData.educationalContent.factOpinion,
                      },
                    )}

                  {/* Exercises Sections - render all other educational content */}
                  {Object.entries(lessonData.educationalContent || {}).map(
                    ([key, content], index) => {
                      if (key !== "vocabulary" && key !== "factOpinion") {
                        return renderEditableSection(
                          key,
                          "title",
                          "content",
                          index + 4,
                          {
                            type: key,
                            title: getEducationalSectionTitle(key),
                            content: content,
                          },
                        );
                      }
                      return null;
                    },
                  )}
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ArgumentationLesson;
