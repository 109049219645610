import React, { useState } from "react";
import {
  Timeline,
  TimelineItem,
  TimelineContent,
  TimelineSeparator,
  TimelineConnector,
  TimelineDot,
  TimelineOppositeContent,
} from "@mui/lab";
import {
  Paper,
  Typography,
  Box,
  TextField,
  IconButton,
  Tooltip,
} from "@mui/material";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import HoverableImage from "./HoverableImage";

const CustomTimeline = ({
  events,
  userId,
  onImageUpdate,
  onImageClick,
  generatingItemIndex,
  showSnackbar,
  theme,
  onEventUpdate,
  viewMode,
}) => {
  const [editingIndex, setEditingIndex] = useState(null);
  const [editedEvent, setEditedEvent] = useState(null);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const imageSize = 80;

  const handleDeleteClick = (index) => {
    const newEvents = events.filter((_, i) => i !== index);
    onEventUpdate(newEvents);
    showSnackbar("Händelsen har tagits bort!", "success");
  };

  const getTimelineDotColor = (category) => {
    return category === "major"
      ? theme.palette.text.main
      : theme.palette.secondary.main;
  };

  const handleItemImageClick = (index) => {
    if (onImageClick) {
      onImageClick(index);
    }
  };

  return (
    <Timeline position="alternate">
      {events.map((event, index) => (
        <TimelineItem key={index}>
          <TimelineOppositeContent>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                fontWeight: "500",
                [theme.breakpoints.down("sm")]: {
                  fontSize: "0.8rem",
                },
              }}
            >
              {event.date}
            </Typography>
          </TimelineOppositeContent>

          <TimelineSeparator>
            <TimelineDot
              sx={{
                bgcolor: getTimelineDotColor(event.category),
                boxShadow: theme.shadows[2],
              }}
            />
            {index !== events.length - 1 && (
              <TimelineConnector sx={{ bgcolor: theme.palette.divider }} />
            )}
          </TimelineSeparator>

          <TimelineContent>
            <Paper
              elevation={3}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
              sx={{
                padding: 2,
                backgroundColor: theme.palette.background.paper,
                borderRadius: theme.shape.borderRadius,
                position: "relative",
                "&:hover": {
                  backgroundColor: theme.palette.background.default,
                  transform: "scale(1.02)",
                  transition: "all 0.3s ease-in-out",
                },
                [theme.breakpoints.down("sm")]: {
                  padding: 1.5,
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: index % 2 === 0 ? "row" : "row-reverse",
                  gap: 3,
                  alignItems: "center",
                  [theme.breakpoints.down("sm")]: {
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 2,
                  },
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    flex: "none",
                  }}
                >
                  <HoverableImage
                    imageUrl={event.imageUrl}
                    isLoading={
                      generatingItemIndex === index ||
                      generatingItemIndex === -1
                    }
                    loadingIndex={index}
                    currentIndex={index}
                    onGenerateClick={() => handleItemImageClick(index)}
                    userId={userId}
                    onImageUpdate={(newUrl) =>
                      onImageUpdate("event", index, newUrl)
                    }
                    showSnackbar={showSnackbar}
                    theme={theme}
                    tinyMode={true}
                    size={120}
                  />
                </Box>

                <Box sx={{ flex: 1 }}>
                  <Typography
                    sx={{
                      color: theme.palette.mode === "dark" ? "white" : "black",
                      fontSize: "1.2rem",
                      fontWeight: "600",
                      [theme.breakpoints.down("sm")]: {
                        fontSize: "0.9rem",
                        textAlign: "center",
                        fontWeight: "600",
                      },
                    }}
                  >
                    {event.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      mt: 1,
                      color: theme.palette.text.secondary,
                      [theme.breakpoints.down("sm")]: {
                        fontSize: "0.8rem",
                        textAlign: "center",
                      },
                    }}
                  >
                    {event.description}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

export default CustomTimeline;
