import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Button,
  Typography,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import UsageBox from "./UsageBox";

import PricesSection from "../PricesSection";

const LimitDialog = ({
  open,
  onClose,
  userId,
  isImageLimit,
  isLessonLimit,
  resetTime, // Add resetTime prop
  styles,
  user,
}) => {
  const getMessage = () => {
    if (isImageLimit && isLessonLimit) {
      return "Du har nu nått den dagliga gränsen för både lektioner och bilder.";
    } else if (isImageLimit) {
      return "Du har nu nått den dagliga gränsen för bilder.";
    } else if (isLessonLimit) {
      return "Du har nu nått den dagliga gränsen för lektioner.";
    }
    return "";
  };

  // Calculate time remaining from resetTime
  const getTimeRemaining = () => {
    if (!resetTime) return "";

    const now = Date.now();
    const diffMs = resetTime - now;
    const diffHours = Math.floor(diffMs / (1000 * 60 * 60));
    const diffMinutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));

    if (diffHours >= 1) {
      return `om ${diffHours} ${diffHours === 1 ? "timme" : "timmar"}${
        diffMinutes > 0
          ? ` och ${diffMinutes} ${diffMinutes === 1 ? "minut" : "minuter"}`
          : ""
      }`;
    }
    return `om ${diffMinutes} ${diffMinutes === 1 ? "minut" : "minuter"}`;
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 4,
          maxWidth: 1300,
          height: {
            xs: 500, // Mobile
            sm: 600, // Tablet
            md: 800, // Desktop
          },
          position: "relative",
        },
      }}
    >
      <DialogTitle>
        <Box
          sx={{ position: "absolute", right: 12, top: 12, cursor: "pointer" }}
          onClick={onClose}
        >
          <CloseIcon />
        </Box>
        <Typography
          variant="h6"
          component="div"
          sx={{
            fontWeight: 700,
            fontSize: "2rem",
            textAlign: "center",
            backgroundClip: "text",
            textFillColor: "transparent",
            background: "linear-gradient(135deg, #43CBFF 10%, #9708CC 100%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          Uppgradera ditt konto
        </Typography>

        <Typography
          variant="body1"
          gutterBottom
          sx={{ textAlign: "center", fontWeight: "600", mt: 1 }}
        >
          {getMessage()}
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",

            mb: 4,
            mt: 2,
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: -4,
              left: 0,
              width: 300,
            }}
          >
            <UsageBox userId={userId} styles={styles} compactMode={true} />
          </Box>

          <Box sx={{ width: "100%", mt: -2 }}>
            <PricesSection hideBasic={false} strikeThrough={true} user={user} />
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default LimitDialog;
