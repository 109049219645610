// listStyles.js
const getListStyles = (theme, isDark) => {
  const listItemStyles = {
    py: 0,
    paddingTop: "5px",
    paddingBottom: "5px",
    "&:not(:last-child)": {
      mb: "3px",
    },
    borderRadius: "7px",
    fontSize: "15px",
    "&:hover": {
      borderRadius: "7px !important",
      "& .MuiListItemIcon-root": {
        color:
          theme.palette.themeType === "allakando"
            ? theme.palette.primary.hover
            : isDark
              ? "rgb(16, 151, 218)"
              : "#8b5cf6",
      },
      "& .MuiListItemText-root .MuiTypography-root": {
        color: isDark ? "#fff" : "#000",
      },
      "& .chevron-icon": {
        color:
          theme.palette.themeType === "allakando"
            ? theme.palette.primary.hover
            : isDark
              ? "rgb(16, 151, 218)"
              : "#8b5cf6",
      },
    },
  };

  const listItemIconStyles = {
    minWidth: "20px",
    fontSize: "24px",
    paddingRight: "0px",
    color: "#94a3b8",
    "& .MuiSvgIcon-root": {
      fontSize: theme.palette.themeType === "allakando" ? "26px" : "24px",
    },
  };

  const listItemTextStyles = {
    marginLeft: "10px",
    "& .MuiTypography-root": {
      color: isDark ? "#b0b4b8" : "#4a5568",
      fontSize: theme.palette.themeType === "allakando" ? "16px" : "14px",
      fontWeight: "500",
    },
  };

  const chevronIcon = {
    fontSize: "20px",
    color: "#94a3b8",
    marginLeft: "auto",
    display: "none",
  };

  const activeStyles = {
    color:
      theme.palette.themeType === "allakando"
        ? theme.palette.primary.hover
        : isDark
          ? "#fff"
          : "#8b5cf6",
    backgroundColor:
      theme.palette.themeType === "allakando"
        ? theme.palette.background.card
        : isDark
          ? "#15202B"
          : "#eee9ff",
    borderRadius: "7px",
  };

  return {
    listItemStyles,
    listItemIconStyles,
    listItemTextStyles,
    chevronIcon,
    activeStyles,
  };
};

export default getListStyles;
