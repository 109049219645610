import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  Box,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  Grid,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import TableChartIcon from "@mui/icons-material/TableChart";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { East as ArrowIcon } from "@mui/icons-material";
import { AutoFixHigh, RestartAlt } from "@mui/icons-material";

const VocabularyDialog = ({ open, onClose, onSubmit, theme }) => {
  const [topic, setTopic] = useState("");
  const [format, setFormat] = useState("table");
  const [wordCount, setWordCount] = useState(10);
  const [showExamples, setShowExamples] = useState(false);
  const [includeQuiz, setIncludeQuiz] = useState(false);
  const dialogContentRef = React.useRef(null);
  const [answerOptions, setAnswerOptions] = useState(4);
  const answerOptionChoices = [2, 3, 4, 5, 6];

  const wordCountOptions = [5, 7, 10, 15, 20];
  const formatButtons = [
    { value: "bullets", label: "Punktlista", icon: FormatListBulletedIcon },
    { value: "table", label: "Tabell", icon: TableChartIcon },
    { value: "text", label: "Löpande text", icon: TextFieldsIcon },
  ];

  const exampleTopics = [
    {
      category: "Litterära termer",
      topics: [
        "Metaforer och liknelser i modern litteratur",
        "Berättarperspektiv och dess effekter",
        "Stilfigurer i poesi",
        "Genre och subgenrer i litteratur",
        "Dramaturgiska begrepp",
      ],
    },
    {
      category: "Biologiska termer",
      topics: [
        "Cellens uppbyggnad och funktion",
        "Genetik och ärftlighet",
        "Fotosyntesens process",
        "Människokroppens organ",
        "Ekosystem och näringskedjor",
      ],
    },
    // ... other example topics remain the same
  ];

  const handleSelectExample = (selectedTopic) => {
    setTopic(selectedTopic);
    setShowExamples(false);
    if (dialogContentRef.current) {
      dialogContentRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const handleSubmit = () => {
    if (!topic.trim()) return;
    const formatMap = {
      bullets: "Punktlista",
      table: "Tabell",
      text: "Löpande text",
    };
    const quizAddition = includeQuiz
      ? ` Inkludera även ett quiz där begreppet är frågan och eleven ska välja rätt definition bland ${answerOptions} svarsalternativ.`
      : "";
    const prompt = `Skapa en begreppslista med ${wordCount} viktiga ord relaterade till ämnet: ${topic}. Format: ${formatMap[format]}.${quizAddition}`;
    onSubmit(prompt);
    onClose();
    setTopic("");
    setFormat("table");
    setAnswerOptions(4);
    setWordCount(10);
    setShowExamples(false);
    setIncludeQuiz(false);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "100%",

          maxWidth: 680,
          backgroundColor:
            theme.palette.mode === "dark" ? "background.default" : "white",
          border:
            theme.palette.mode === "dark"
              ? "1px solid #323d48"
              : "1px solid #e2e8f0",
        },
      }}
    >
      <IconButton
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 16,
          top: 16,
          color:
            theme.palette.mode === "dark"
              ? "rgba(255, 255, 255, 0.7)"
              : "inherit",
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 1,
          color:
            theme.palette.mode === "dark"
              ? "rgba(255, 255, 255, 0.7)"
              : "inherit",
          p: 3,
        }}
      >
        <Typography variant="h5" fontWeight={700}>
          Skapa begreppslista
        </Typography>
      </DialogTitle>
      <Divider />
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <Button
          variant="purp"
          size="small"
          onClick={() => setShowExamples(!showExamples)}
          startIcon={showExamples ? <RestartAlt /> : <AutoFixHigh />}
          sx={{
            minWidth: "unset",
            px: 2,
            py: 0.5,
            fontSize: "0.75rem",
          }}
        >
          {showExamples ? "Stäng exempel" : "Visa exempel"}
        </Button>
      </Box>

      <DialogContent ref={dialogContentRef} sx={{ p: 5 }}>
        {!showExamples && (
          <Box sx={{ width: "100%" }}>
            {/* First row: Topic and Word Count */}
            <Grid container spacing={2} sx={{ mb: 4 }}>
              <Grid item xs={8}>
                <Typography variant="subtitle1" fontWeight={600} mb={1}>
                  Ämne:
                </Typography>
                <TextField
                  autoFocus
                  fullWidth
                  value={topic}
                  onChange={(e) => setTopic(e.target.value)}
                  placeholder="Skriv ämnet här... T.ex. Fotosyntesens process"
                  inputProps={{ maxLength: 1000 }}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle1" fontWeight={600} mb={1}>
                  Antal ord:
                </Typography>
                <FormControl fullWidth>
                  <Select
                    value={wordCount}
                    onChange={(e) => setWordCount(e.target.value)}
                  >
                    {wordCountOptions.map((count) => (
                      <MenuItem key={count} value={count}>
                        {count} ord
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            {/* Second row: Format */}
            <Grid container sx={{ mb: 4 }}>
              <Grid item xs={12}>
                <Typography variant="subtitle1" fontWeight={600} mb={1}>
                  Välj format:
                </Typography>
                <Grid container spacing={2}>
                  {formatButtons.map(({ value, label, icon: Icon }) => (
                    <Grid item xs={4} key={value}>
                      <Button
                        fullWidth
                        variant={format === value ? "save" : "print"}
                        onClick={() => setFormat(value)}
                        sx={{ height: 45 }}
                        color={format === value ? "primary" : "inherit"}
                        startIcon={
                          format === value ? <CheckCircleIcon /> : <Icon />
                        }
                      >
                        {label}
                      </Button>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>

            {/* Third row: Quiz option */}
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="subtitle1" fontWeight={600} mb={1}>
                  Inkludera quiz?
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      variant={includeQuiz ? "save" : "print"}
                      onClick={() => setIncludeQuiz(true)}
                      sx={{ height: 45 }}
                      color={includeQuiz ? "primary" : "inherit"}
                      startIcon={includeQuiz ? <CheckCircleIcon /> : null}
                    >
                      Ja
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      variant={!includeQuiz ? "save" : "print"}
                      onClick={() => setIncludeQuiz(false)}
                      sx={{ height: 45 }}
                      color={!includeQuiz ? "primary" : "inherit"}
                      startIcon={!includeQuiz ? <CheckCircleIcon /> : null}
                    >
                      Nej
                    </Button>
                  </Grid>

                  {includeQuiz && (
                    <Grid item xs={12}>
                      <Typography variant="subtitle1" fontWeight={600} mb={1}>
                        Antal svarsalternativ:
                      </Typography>
                      <FormControl fullWidth>
                        <Select
                          value={answerOptions}
                          onChange={(e) => setAnswerOptions(e.target.value)}
                        >
                          {answerOptionChoices.map((count) => (
                            <MenuItem key={count} value={count}>
                              {count} alternativ
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        )}

        {showExamples && (
          <Grid container spacing={4}>
            {exampleTopics.map((category, idx) => (
              <Grid item xs={12} key={idx}>
                <Typography
                  variant="h6"
                  fontWeight={600}
                  textAlign="center"
                  mb={2}
                >
                  {category.category}
                </Typography>
                <Grid container spacing={2}>
                  {category.topics.map((topic, topicIdx) => (
                    <Grid item xs={6} key={topicIdx}>
                      <Button
                        fullWidth
                        variant="print"
                        onClick={() => handleSelectExample(topic)}
                        sx={{
                          height: "auto",
                          padding: 2,
                          textAlign: "left",
                          whiteSpace: "normal",
                          lineHeight: 1.4,
                        }}
                      >
                        {topic}
                      </Button>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            ))}
          </Grid>
        )}
      </DialogContent>
      <Divider />
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: 2,
        }}
      >
        <Button
          onClick={handleSubmit}
          variant="purp"
          disabled={!topic.trim()}
          sx={{ width: 250 }}
        >
          Skapa begreppslista <ArrowIcon sx={{ ml: 1 }} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VocabularyDialog;
