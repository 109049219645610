import React, { useState, useEffect, useRef } from "react";
import {
  Dialog,
  DialogContent,
  Box,
  Typography,
  TextField,
  IconButton,
  Button,
  LinearProgress,
  useTheme,
  Autocomplete,
} from "@mui/material";
import { School, Person, ArrowBack } from "@mui/icons-material";
import { useSnackbar } from "./SnackbarHandler";
import { motion, AnimatePresence } from "framer-motion";
import { East as ArrowIcon } from "@mui/icons-material";
import { CheckCircle } from "@mui/icons-material";
import { InstructionItem } from "./functions";
import BlurryBackdrop from "./BlurryBackdrop";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check"; // Add this import at the top
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Confetti from "react-confetti";

import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
const swedishMunicipalities = [
  "Ale",
  "Alingsås",
  "Alvesta",
  "Aneby",
  "Arboga",
  "Arjeplog",
  "Arvidsjaur",
  "Arvika",
  "Askersund",
  "Avesta",
  "Bengtsfors",
  "Berg",
  "Bjurholm",
  "Bjuv",
  "Boden",
  "Bollebygd",
  "Bollnäs",
  "Borgholm",
  "Borlänge",
  "Borås",
  "Botkyrka",
  "Boxholm",
  "Bramhult",
  "Bräcke",
  "Burlöv",
  "Båstad",
  "Dals-Ed",
  "Danderyd",
  "Degerfors",
  "Dorotea",
  "Eda",
  "Ekerö",
  "Eksjö",
  "Emmaboda",
  "Enköping",
  "Eskilstuna",
  "Eslöv",
  "Essunga",
  "Fagersta",
  "Falkenberg",
  "Falköping",
  "Falun",
  "Filipstad",
  "Finspång",
  "Flen",
  "Forshaga",
  "Färgelanda",
  "Gagnef",
  "Gislaved",
  "Gnesta",
  "Gnosjö",
  "Gotland",
  "Grums",
  "Grästorp",
  "Gullspång",
  "Gällivare",
  "Gävle",
  "Göteborg",
  "Götene",
  "Habo",
  "Hagfors",
  "Hallsberg",
  "Hallstahammar",
  "Halmstad",
  "Hammarö",
  "Haninge",
  "Haparanda",
  "Heby",
  "Hedemora",
  "Helsingborg",
  "Herrljunga",
  "Hjo",
  "Hofors",
  "Huddinge",
  "Hudiksvall",
  "Hultsfred",
  "Hylte",
  "Håbo",
  "Hällefors",
  "Härjedalen",
  "Härnösand",
  "Härryda",
  "Hässleholm",
  "Höganäs",
  "Högsby",
  "Hörby",
  "Höör",
  "Jokkmokk",
  "Järfälla",
  "Jönköping",
  "Kalix",
  "Kalmar",
  "Karlsborg",
  "Karlshamn",
  "Karlskoga",
  "Karlskrona",
  "Karlstad",
  "Katrineholm",
  "Kil",
  "Kinda",
  "Kiruna",
  "Klippan",
  "Knivsta",
  "Kramfors",
  "Kristianstad",
  "Kristinehamn",
  "Krokom",
  "Kumla",
  "Kungsbacka",
  "Kungsör",
  "Kungälv",
  "Kävlinge",
  "Köping",
  "Laholm",
  "Landskrona",
  "Laxå",
  "Lekeberg",
  "Leksand",
  "Lerum",
  "Lessebo",
  "Lidingö",
  "Lidköping",
  "Lilla Edet",
  "Lindesberg",
  "Linköping",
  "Ljungby",
  "Ljusdal",
  "Ljusnarsberg",
  "Lomma",
  "Ludvika",
  "Luleå",
  "Lund",
  "Lycksele",
  "Lysekil",
  "Malmö",
  "Malung-Sälen",
  "Malå",
  "Mariestad",
  "Mark",
  "Markaryd",
  "Mellerud",
  "Mjölby",
  "Mora",
  "Motala",
  "Mullsjö",
  "Munkedal",
  "Munkfors",
  "Mölndal",
  "Mönsterås",
  "Mörbylånga",
  "Nacka",
  "Nora",
  "Norberg",
  "Nordanstig",
  "Nordmaling",
  "Norrköping",
  "Norrtälje",
  "Norsjö",
  "Nybro",
  "Nykvarn",
  "Nyköping",
  "Nynäshamn",
  "Nässjö",
  "Ockelbo",
  "Olofström",
  "Orsa",
  "Orust",
  "Osby",
  "Oskarshamn",
  "Ovanåker",
  "Oxelösund",
  "Pajala",
  "Partille",
  "Perstorp",
  "Piteå",
  "Ragunda",
  "Robertsfors",
  "Ronneby",
  "Rättvik",
  "Sala",
  "Salem",
  "Sandviken",
  "Sigtuna",
  "Simrishamn",
  "Sjöbo",
  "Skara",
  "Skellefteå",
  "Skinnskatteberg",
  "Skurup",
  "Skövde",
  "Smedjebacken",
  "Sollefteå",
  "Sollentuna",
  "Solna",
  "Sorsele",
  "Sotenäs",
  "Staffanstorp",
  "Stenungsund",
  "Stockholm",
  "Storfors",
  "Storuman",
  "Strängnäs",
  "Strömstad",
  "Strömsund",
  "Sundbyberg",
  "Sundsvall",
  "Sunne",
  "Surahammar",
  "Svalöv",
  "Svedala",
  "Svenljunga",
  "Säffle",
  "Säter",
  "Sävsjö",
  "Söderhamn",
  "Söderköping",
  "Södertälje",
  "Sölvesborg",
  "Tanum",
  "Tibro",
  "Tidaholm",
  "Tierp",
  "Timrå",
  "Tingsryd",
  "Tjörn",
  "Tomelilla",
  "Torsby",
  "Torsås",
  "Tranemo",
  "Tranås",
  "Trelleborg",
  "Trollhättan",
  "Trosa",
  "Tyresö",
  "Täby",
  "Töreboda",
  "Uddevalla",
  "Ulricehamn",
  "Umeå",
  "Upplands Väsby",
  "Upplands-Bro",
  "Uppsala",
  "Uppvidinge",
  "Vadstena",
  "Vaggeryd",
  "Valdemarsvik",
  "Vallentuna",
  "Vansbro",
  "Vara",
  "Varberg",
  "Vaxholm",
  "Vellinge",
  "Vetlanda",
  "Vilhelmina",
  "Vimmerby",
  "Vindeln",
  "Vingåker",
  "Vårgårda",
  "Vänersborg",
  "Vännäs",
  "Värmdö",
  "Värnamo",
  "Västervik",
  "Västerås",
  "Växjö",
  "Ydre",
  "Ystad",
  "Åmål",
  "Ånge",
  "Åre",
  "Årjäng",
  "Åsele",
  "Åstorp",
  "Åtvidaberg",
  "Älmhult",
  "Älvdalen",
  "Älvkarleby",
  "Älvsbyn",
  "Ängelholm",
  "Öckerö",
  "Ödeshög",
  "Örebro",
  "Örkelljunga",
  "Örnsköldsvik",
  "Östersund",
  "Österåker",
  "Östhammar",
  "Östra Göinge",
  "Överkalix",
  "Övertorneå",
];

const teachingLevels = [
  "Årskurs 1-3",
  "Årskurs 4-6",
  "Årskurs 7-9",
  "Gymnasiet",
  "Universitet/Högskola",
  "Vuxenutbildning",
];

const subjects = [
  "Svenska",
  "Matematik",
  "Engelska",
  "SO",
  "NO",
  "Idrott och hälsa",
  "Bild",
  "Musik",
  "Slöjd",
  "Hem- och konsumentkunskap",
  "Moderna språk",
  "Modersmål",
  "Teknik",
  "Biologi",
  "Fysik",
  "Kemi",
  "Historia",
  "Religion",
  "Samhällskunskap",
  "Geografi",
];

const SuccessView = ({ onBack }) => {
  const [confetti, setConfetti] = useState([]);
  const [rotation, setRotation] = useState(0);

  return (
    <Box
      sx={{
        minWidth: "100%",
        p: 4,
        minHeight: "600px",
        display: "flex",
        flexDirection: "column",

        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <IconButton
        onClick={onBack}
        sx={{
          position: "absolute",
          top: 32,
          left: 32,
          zIndex: 1000,
        }}
      >
        <ArrowBack />
      </IconButton>

      <Confetti
        width={window.innerWidth}
        height={window.innerHeight}
        recycle={false}
        numberOfPieces={400}
        gravity={0.3}
      />

      <motion.div
        initial={{ scale: 0, rotate: -180 }}
        animate={{
          scale: 1,
          rotate: 0,
          y: [0, -20, 0],
        }}
        transition={{
          type: "spring",
          stiffness: 260,
          damping: 20,
          duration: 1.5,
        }}
      >
        <CheckCircle
          sx={{
            fontSize: 120,
            background: "linear-gradient(135deg, #43CBFF 10%, #9708CC 100%)",
            borderRadius: "50%",
            color: "white",
          }}
        />
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5 }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: 600,
            textAlign: "center",
            mt: 4,
            background: "linear-gradient(135deg, #43CBFF 10%, #9708CC 100%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          Registrering slutförd!
        </Typography>
        <Typography
          variant="h6"
          color="text.secondary"
          sx={{
            textAlign: "center",
            mt: 2,
          }}
        >
          Välkommen till Studera.AI
        </Typography>
      </motion.div>
    </Box>
  );
};

const AccountTypeDialog = ({ open, onSave, onClose }) => {
  const { showSnackbar } = useSnackbar();
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";

  const [step, setStep] = useState(1);
  const [accountType, setAccountType] = useState("");
  const [municipality, setMunicipality] = useState("");
  const [school, setSchool] = useState("");
  const [teachingLevel, setTeachingLevel] = useState([]); // Changed from "" to []

  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [showCustomInput, setShowCustomInput] = useState(false);
  const [customSubject, setCustomSubject] = useState("");
  const schoolInputRef = useRef();

  const handleSuccessBack = () => {
    setIsSuccess(false);
    setStep(accountType === "teacher" ? 5 : 2);
  };

  const getCardStyle = (variant) => {
    return {
      gradient: "linear-gradient(135deg, #8B00FF 10%, #6A0DB7 100%)",
      iconColor: "white",
      textColor: "white",
      textShadow: "2px 2px 4px rgba(0,0,0,0.2)",
    };
  };

  const handleTypeSelect = (type) => {
    setAccountType(type);
    setStep(2);
  };

  const handleBack = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const handleNext = () => {
    if (accountType === "student") {
      if (step === 2 && (!municipality || !school)) {
        showSnackbar("Välj kommun och skola.", "error");
        return;
      }
      setIsSuccess(true);
      onSave({
        accountType,
        municipality,
        school,
      });
    } else {
      // Teacher flow

      if (step === 2 && teachingLevel.length === 0) {
        showSnackbar("Välj din årskurs/nivå", "error");
        return;
      }
      if (step === 3 && selectedSubjects.length === 0) {
        showSnackbar("Välj minst ett ämne", "error");
        return;
      }
      if (step === 4 && (!municipality || !school)) {
        showSnackbar("Välj kommun och skola.", "error");
        return;
      }

      if (step === 5) {
        setIsSuccess(true);
        onSave({
          accountType,
          municipality,
          school,
          teachingLevel,
          subjects: selectedSubjects,
        });
        return;
      }
      setStep(step + 1);
    }
  };

  const AccountTypeCard = ({ type, Icon, title }) => {
    const style = getCardStyle();

    return (
      <Box
        onClick={() => handleTypeSelect(type)}
        sx={{
          width: 240,
          height: 240,
          borderRadius: "24px",
          position: "relative",
          cursor: "pointer",
          background: style.gradient,
          transition: "all 0.4s cubic-bezier(0.4, 0, 0.2, 1)",
          boxShadow: isDark
            ? "0 8px 16px rgba(0,0,0,0.1)"
            : "0 8px 16px rgba(0,0,0,0.05)",
          "&:hover": {
            transform: "scale(1.05)",
            boxShadow: isDark
              ? "0 12px 24px rgba(0,0,0,0.15)"
              : "0 12px 24px rgba(0,0,0,0.1)",
          },
        }}
      >
        <Box
          sx={{
            position: "absolute",
            inset: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Icon
            sx={{
              fontSize: 64,
              color: style.iconColor,
              mb: 2,
              filter: isDark
                ? "drop-shadow(2px 2px 2px rgba(0,0,0,0.2))"
                : "none",
            }}
          />
          <Typography
            variant="h5"
            sx={{
              color: style.textColor,
              fontWeight: 600,
              textShadow: style.textShadow,
            }}
          >
            {title}
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason === "backdropClick" || reason === "escapeKeyDown") {
          showSnackbar(
            "Vänligen slutför registreringen. Det tar 20 sekunder.",
            "info",
          );
          return;
        }
      }}
      disableEscapeKeyDown
      BackdropComponent={BlurryBackdrop}
      PaperProps={{
        sx: {
          borderRadius: 4,
          bgcolor: isDark ? "background.default" : "background.paper",
          backgroundImage: "none",
          overflow: "hidden",
          maxWidth: "800px",
          width: "100%",
        },
      }}
    >
      <DialogContent
        sx={{
          p: 0,
        }}
        ref={(node) => {
          if (node && step === 4) {
            node.scrollTop = 0;
          }
        }}
      >
        <Box sx={{ position: "relative", width: "100%", overflow: "hidden" }}>
          {/* Progress Bar */}

          <Box
            sx={{
              position: "absolute",
              top: step === 5 ? 24 : 49,
              left: "50%",
              transform: "translateX(-50%)",
              width: "80%",
              display: isSuccess ? "none" : "block",
              zIndex: 10,
            }}
          >
            {step === 5 ? (
              <Box
                component="img"
                src="/img/AI.png"
                alt="AI"
                sx={{
                  display: "block",
                  width: "70px",
                  height: "70px",
                  margin: "0 auto",
                }}
              />
            ) : (
              <LinearProgress
                variant="determinate"
                value={(step / (accountType === "teacher" ? 4 : 2)) * 100}
                sx={{
                  height: 5,
                  borderRadius: 4,
                  width: "40%",
                  margin: "0 auto",
                }}
              />
            )}
          </Box>
          {/* Progress Steps */}
          <Box
            sx={{
              position: "absolute",
              top: 32,
              left: "50%",
              transform: "translateX(-50%)",
              display: isSuccess ? "none" : "flex",
              gap: 2,
              zIndex: 10,
              display: "none",
            }}
          >
            {[1, 2, ...(accountType === "teacher" ? [3, 4, 5] : [])].map(
              (s) => (
                <Box
                  key={s}
                  sx={{
                    width: 40,
                    height: 40,
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    transition: "all 0.4s cubic-bezier(0.4, 0, 0.2, 1)",
                    bgcolor:
                      step >= s
                        ? theme.palette.primary.main
                        : isDark
                          ? "rgba(255,255,255,0.1)"
                          : "rgba(0,0,0,0.1)",
                    color: step >= s ? "#fff" : "text.secondary",
                    fontWeight: 600,
                  }}
                >
                  {s}
                </Box>
              ),
            )}
          </Box>
          {/* Back Button */}
          {step > 1 && !isSuccess && (
            <IconButton
              onClick={handleBack}
              sx={{
                position: "absolute",
                top: 32,
                left: 32,
                zIndex: 10,
              }}
            >
              <ArrowBack />
            </IconButton>
          )}
          <AnimatePresence>
            {!isSuccess ? (
              <Box
                sx={{
                  display: "flex",
                  transition: "transform 0.4s cubic-bezier(0.4, 0, 0.2, 1)",
                  transform: `translateX(-${(step - 1) * 100}%)`,
                }}
              >
                {/* Step 1 - Account Type Selection */}
                <Box
                  sx={{
                    minWidth: "100%",
                    p: 4,
                    minHeight: "600px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      mt: 10,
                      flex: 1,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{ fontWeight: 600, textAlign: "center" }}
                    >
                      I vilken roll använder du Studera.AI?
                    </Typography>

                    <Typography
                      variant="h6"
                      align="center"
                      sx={{
                        mt: 2,
                        color: "text.secondary",
                        fontWeight: 500,
                        fontSize: "1.155rem",
                        mb: 3,
                      }}
                    >
                      Låt oss anpassa tjänsten utifrån dina behov.
                    </Typography>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        gap: 4,
                        mt: 6,
                        flex: 1,
                      }}
                    >
                      <AccountTypeCard
                        type="teacher"
                        Icon={School}
                        title="Lärare"
                      />
                      <AccountTypeCard
                        type="student"
                        Icon={Person}
                        title="Elev"
                      />
                    </Box>
                  </Box>
                </Box>

                {/* Step 2 - Teaching Level (Teachers only) */}
                {accountType === "teacher" && (
                  <Box
                    sx={{
                      minWidth: "100%",
                      p: 4,
                      minHeight: "600px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Box
                      sx={{
                        mt: 10,
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        variant="h5"
                        sx={{ fontWeight: 600, textAlign: "center" }}
                      >
                        Inom vilken utbildningsnivå är du verksam?
                      </Typography>
                      <Typography
                        variant="h6"
                        align="center"
                        sx={{
                          mt: 2,
                          color: "text.secondary",
                          fontWeight: 500,
                          fontSize: "1.155rem",
                          mb: 6,
                        }}
                      >
                        Vi erbjuders åldersspecifika verktyg för alla nivåer.
                      </Typography>
                      <Box
                        sx={{
                          gap: 2,
                          justifyContent: "center",
                          textAlign: "center",
                          overflowY: "auto",
                        }}
                      >
                        {teachingLevels.map((level) => (
                          <Button
                            key={level}
                            sx={{
                              m: 1,
                              borderRadius: "20px",
                              textTransform: "none",
                              fontSize: "1rem",
                            }}
                            variant={
                              teachingLevel === level ? "outlined" : "outlined"
                            }
                            onClick={() => {
                              setTeachingLevel((prev) =>
                                prev.includes(level)
                                  ? prev.filter((l) => l !== level)
                                  : [...prev, level],
                              );
                            }}
                            startIcon={
                              teachingLevel.includes(level) ? (
                                <CheckCircleIcon
                                  sx={{ color: "primary.main" }}
                                />
                              ) : (
                                <RadioButtonUncheckedIcon />
                              )
                            }
                          >
                            {level}
                          </Button>
                        ))}
                      </Box>

                      <Button
                        variant="purp"
                        color="primary"
                        size="large"
                        onClick={handleNext}
                        sx={{
                          margin: "0 auto",
                          mt: "auto",
                          width: { xs: "100%", md: "30%" },
                        }}
                      >
                        Nästa <ArrowIcon sx={{ ml: 1 }} />
                      </Button>
                    </Box>
                  </Box>
                )}

                {/* Step 3 - Subject Selection (Teachers only) */}
                {accountType === "teacher" && (
                  <Box
                    sx={{
                      minWidth: "100%",
                      p: 4,
                      minHeight: "600px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Box
                      sx={{
                        mt: 10,
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        variant="h5"
                        sx={{ fontWeight: 600, mb: 3, textAlign: "center" }}
                      >
                        Vilka ämnen undervisar du i?
                      </Typography>

                      <Box
                        sx={{
                          justifyContent: "center",
                          textAlign: "center",
                          gap: 2,
                          overflowY: "auto",
                        }}
                      >
                        {[
                          ...subjects,
                          ...selectedSubjects.filter(
                            (subject) => !subjects.includes(subject),
                          ),
                        ].map((subject) => (
                          <Button
                            key={subject}
                            sx={{
                              m: 1,
                              borderRadius: "20px",
                              textTransform: "none",
                              fontSize: "1rem",
                            }}
                            variant={
                              selectedSubjects.includes(subject)
                                ? "outlined"
                                : "outlined"
                            }
                            startIcon={
                              selectedSubjects.includes(subject) ? (
                                <CheckCircleIcon
                                  sx={{ color: "primary.main" }}
                                />
                              ) : (
                                <RadioButtonUncheckedIcon />
                              )
                            }
                            onClick={() => {
                              setSelectedSubjects((prev) =>
                                prev.includes(subject)
                                  ? prev.filter((s) => s !== subject)
                                  : [...prev, subject],
                              );
                            }}
                          >
                            {subject}
                          </Button>
                        ))}

                        {/* Add this right after the existing subjects map */}
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            mt: 2,
                          }}
                        >
                          {!showCustomInput ? (
                            <Button
                              sx={{
                                mt: 0,
                                mb: 4,
                                padding: 0,
                                fontWeight: "500",
                                fontSize: { xs: "0.81rem", md: "1rem" },
                                textTransform: "none",
                                color:
                                  theme.palette.mode === "dark"
                                    ? "white"
                                    : "#666666",
                                textAlign: "center",
                                width: "auto",
                                "&:hover": {
                                  color: "primary.dark",
                                  backgroundColor: "transparent",
                                },
                              }}
                              onClick={() => setShowCustomInput(true)}
                            >
                              Jag ser inte mitt ämne
                            </Button>
                          ) : (
                            <Box
                              sx={{
                                display: "flex",
                                gap: 1,
                                alignItems: "center",
                                width: "100%",
                                maxWidth: 400,
                                mb: 3,
                              }}
                            >
                              <TextField
                                size="small"
                                value={customSubject}
                                onChange={(e) =>
                                  setCustomSubject(e.target.value)
                                }
                                placeholder="Skriv ditt ämne"
                                fullWidth
                              />
                              <Button
                                variant="contained"
                                onClick={() => {
                                  if (customSubject.trim()) {
                                    setSelectedSubjects((prev) => [
                                      ...prev,
                                      customSubject.trim(),
                                    ]);
                                    setCustomSubject("");
                                    setShowCustomInput(false);
                                  }
                                }}
                                sx={{
                                  whiteSpace: "nowrap",
                                  minWidth: "fit-content",
                                }}
                                startIcon={<AddIcon />}
                              >
                                Lägg till
                              </Button>
                            </Box>
                          )}
                        </Box>
                      </Box>

                      <Button
                        variant="purp"
                        color="primary"
                        size="large"
                        onClick={handleNext}
                        sx={{
                          margin: "0 auto",
                          mt: "auto",
                          width: { xs: "100%", md: "30%" },
                        }}
                      >
                        Nästa <ArrowIcon sx={{ ml: 1 }} />
                      </Button>
                    </Box>
                  </Box>
                )}

                {/* Step 4 - Municipality & School */}
                <Box
                  sx={{
                    minWidth: "100%",
                    p: 4,
                    minHeight: "600px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      mt: 12,
                      flex: 1,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{ fontWeight: 600, textAlign: "center" }}
                    >
                      Välj kommun & skola
                    </Typography>
                    <Typography
                      variant="h6"
                      align="center"
                      sx={{
                        mt: 2,
                        color: "text.secondary",
                        fontWeight: 500,
                        fontSize: "1.155rem",
                        mb: 8,
                      }}
                    ></Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 3,
                        flex: 1,
                        width: "70%",
                        margin: "0 auto",
                      }}
                    >
                      <Autocomplete
                        fullWidth
                        options={swedishMunicipalities}
                        value={municipality}
                        onChange={(event, newValue) =>
                          setMunicipality(newValue)
                        }
                        renderInput={(params) => (
                          <TextField {...params} label="Kommun" />
                        )}
                      />
                      <TextField
                        fullWidth
                        label="Skola"
                        value={school}
                        onChange={(e) => setSchool(e.target.value)}
                        inputRef={schoolInputRef}
                      />
                    </Box>

                    <Button
                      variant="purp"
                      color="primary"
                      size="large"
                      onClick={handleNext}
                      sx={{
                        margin: "0 auto",
                        mt: "auto",
                        width: { xs: "100%", md: "30%" },
                      }}
                    >
                      Nästa <ArrowIcon sx={{ ml: 1 }} />
                    </Button>
                  </Box>
                </Box>

                {/* Step 5 - Guidelines (Teachers only) */}
                {accountType === "teacher" && (
                  <Box
                    sx={{
                      minWidth: "100%",
                      p: 4,
                      minHeight: "600px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Box
                      sx={{
                        mt: 12,
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        variant="h5"
                        sx={{ fontWeight: 600, mb: 4, textAlign: "center" }}
                      >
                        Bästa praxis för användning av AI
                      </Typography>
                      <Box sx={{ flex: 1, overflowY: "auto" }}>
                        <InstructionItem text="80-20-principen: Låt AI ta hand om grunden och det inledande arbetet, men se till att de sista 20% får din personliga prägel för att skapa innehåll som är både relevant och kontextanpassat." />

                        <InstructionItem text="Säkerställ mångfald och inkludering: Vår AI är redan programmerad att ta hänsyn till mångfald och inkludering när den skapar innehåll. Men som lärare är det viktigt att ändå granska och säkerställa att materialet speglar olika perspektiv, bakgrunder och erfarenheter. Detta bidrar till en inkluderande lärmiljö som reflekterar samhällets mångfald på ett rättvist sätt." />
                        <InstructionItem text="AI som stöd, inte som ersättning: Använd AI som stöd och följ alltid din skolas riktlinjer." />
                      </Box>

                      <Button
                        variant="purp"
                        size="large"
                        onClick={handleNext}
                        endIcon={<CheckIcon />}
                        sx={{
                          margin: "0 auto",
                          mt: "auto",
                          width: { xs: "100%", md: "30%" },
                        }}
                      >
                        Jag förstår
                      </Button>
                    </Box>
                  </Box>
                )}
              </Box>
            ) : (
              <SuccessView onBack={handleSuccessBack} />
            )}
          </AnimatePresence>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AccountTypeDialog;
