import React from "react";
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";

const CustomInputSwitch = ({
  selectedIndex = 0,
  onIndexChange,
  options = [],
  optionSubtitles = [],
  optionIcons = [],
  backgroundColor,
  activeColor = "#8b5cf6",
  nonActiveColor,
  theme,
  width = "300px",
  mobileWidth = "100%",
  height = "46px",
  fontSize = "1rem",
  subtitleFontSize = "0.75rem",
  fontWeight = "600",
  subtitleFontWeight = "400",
  iconSize = "1.2em",
  iconSpacing = "8px",
}) => {
  const muiTheme = useTheme();
  const isMobile = useMediaQuery(muiTheme.breakpoints.down("sm"));

  const handleClick = (index) => {
    if (onIndexChange) {
      onIndexChange(index);
    }
  };

  const defaultNonActiveColor =
    theme?.palette.mode === "dark" ? "#3a454f" : "#f0f0f0";

  const currentWidth = isMobile ? mobileWidth : width;

  return (
    <Box
      sx={{
        borderRadius: "38px",
        backgroundColor:
          nonActiveColor || backgroundColor || defaultNonActiveColor,
        padding: "4px",
        width: currentWidth,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          height,
          borderRadius: "18px",
          backgroundColor:
            nonActiveColor || backgroundColor || defaultNonActiveColor,
          cursor: "pointer",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            width: `${100 / options.length}%`,
            height: "100%",
            borderRadius: "38px",
            backgroundColor: activeColor,
            transition: "transform 0.3s ease",
            transform: `translateX(${selectedIndex * 100}%)`,
          }}
        />
        {options.map((option, index) => (
          <Box
            key={index}
            onClick={() => handleClick(index)}
            sx={{
              flex: 1,
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              position: "relative",
              zIndex: 1,
              padding: "8px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: iconSpacing,
              }}
            >
              {optionIcons[index] && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: fontSize,
                  }}
                >
                  {React.cloneElement(optionIcons[index], {
                    sx: {
                      color:
                        selectedIndex === index
                          ? "#fff"
                          : theme?.palette.mode === "dark"
                            ? "#fff"
                            : "text.secondary",
                      transition: "color 0.3s ease",
                      fontSize: iconSize,
                      width: iconSize,
                      height: iconSize,
                    },
                  })}
                </Box>
              )}
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  variant="body2"
                  sx={{
                    color:
                      selectedIndex === index
                        ? "#fff"
                        : theme?.palette.mode === "dark"
                          ? "#fff"
                          : "#000",
                    transition: "color 0.3s ease",
                    fontWeight,
                    fontSize,
                    userSelect: "none",
                    lineHeight: optionSubtitles[index] ? "1.2" : "normal",
                  }}
                >
                  {option}
                </Typography>
                {optionSubtitles[index] && (
                  <Typography
                    variant="caption"
                    sx={{
                      color:
                        selectedIndex === index
                          ? "rgba(255, 255, 255, 0.8)"
                          : theme?.palette.mode === "dark"
                            ? "rgba(255, 255, 255, 0.7)"
                            : "rgba(0, 0, 0, 0.6)",
                      transition: "color 0.3s ease",
                      fontWeight: subtitleFontWeight,
                      fontSize: subtitleFontSize,
                      userSelect: "none",
                      lineHeight: "1.2",
                    }}
                  >
                    {optionSubtitles[index]}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default CustomInputSwitch;
