import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  lessonUrlMapping,
  getLessonTypeParam,
  lessonMapping,
} from "./functions";
import {
  Badge,
  IconButton,
  Menu,
  MenuItem,
  List,
  ListItem,
  Tooltip,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Chip,
  Button,
  Typography,
  Box,
  alpha,
  Divider,
} from "@mui/material";

import ViewAgendaIcon from "@mui/icons-material/ViewAgenda";
import QuizIcon from "@mui/icons-material/Quiz";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import { shortcuts } from "./DashboardToolsShortcuts";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import NotificationsIcon from "@mui/icons-material/Notifications";

import ImageSearchIcon from "@mui/icons-material/ImageSearch";
//import NotificationsIcon from "@mui/icons-material/NotificationsNone";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ClearIcon from "@mui/icons-material/Clear";
import { formatDate, createUrlSlug } from "./functions";
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
  deleteDoc,
  orderBy,
  onSnapshot,
  addDoc,
  serverTimestamp,
  or,
  and,
} from "firebase/firestore";
import { db } from "./firebase";

const NotificationsComponent = ({
  currentUserId,
  styles,
  theme,
  showSnackbar,
}) => {
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const notificationStyles = {
    vision_analysis_shared: {
      borderLeft: "4px solid #9C27B0",
      bgcolor: alpha("#9C27B0", 0.04),
      transition: "all 0.2s",
      "&:hover": {
        bgcolor: alpha("#9C27B0", 0.1),
      },
      button: {
        "&.MuiButton-root": {
          background: `linear-gradient(135deg, #9C27B0 0%, #7B1FA2 100%)`,
          "&:hover": {
            background: `linear-gradient(135deg, #7B1FA2 0%, #6A1B9A 100%)`,
          },
        },
      },
    },

    friend_request: {
      borderLeft: "4px solid #7C4DFF",
      bgcolor: alpha("#7C4DFF", 0.04),
      transition: "all 0.2s",
      "&:hover": {
        bgcolor: alpha("#7C4DFF", 0.1),
      },
      button: {
        "&.MuiButton-root": {
          background: `linear-gradient(135deg, #7C4DFF 0%, #6C3EEF 100%)`,
          color: "white !important",
          "&:hover": {
            background: `linear-gradient(135deg, #6C3EEF 0%, #5C2EDF 100%)`,
          },
        },
      },
    },
    conversation_shared: {
      borderLeft: "4px solid #2196F3",
      bgcolor: alpha("#2196F3", 0.04),
      transition: "all 0.2s",
      "&:hover": {
        bgcolor: alpha("#2196F3", 0.1),
      },
      button: {
        "&.MuiButton-root": {
          background: `linear-gradient(135deg, #2196F3 0%, #1976D2 100%)`,
          "&:hover": {
            background: `linear-gradient(135deg, #1976D2 0%, #1565C0 100%)`,
          },
        },
      },
    },
    teacher_shared: {
      borderLeft: "4px solid #00BCD4",
      bgcolor: alpha("#00BCD4", 0.04),
      transition: "all 0.2s",
      "&:hover": {
        bgcolor: alpha("#00BCD4", 0.1),
      },
      button: {
        "&.MuiButton-root": {
          background: `linear-gradient(135deg, #00BCD4 0%, #00ACC1 100%)`,
          "&:hover": {
            background: `linear-gradient(135deg, #00ACC1 0%, #0097A7 100%)`,
          },
        },
      },
    },
    file_shared: {
      borderLeft: "4px solid #607D8B",
      bgcolor: alpha("#607D8B", 0.04),
      transition: "all 0.2s",
      "&:hover": {
        bgcolor: alpha("#607D8B", 0.1),
      },
      button: {
        "&.MuiButton-root": {
          background: `linear-gradient(135deg, #607D8B 0%, #546E7A 100%)`,
          color: "white !important",
          "&:hover": {
            background: `linear-gradient(135deg, #546E7A 0%, #455A64 100%)`,
          },
        },
      },
    },
    flashcards_shared: {
      borderLeft: "4px solid #FF9800",
      bgcolor: alpha("#FF9800", 0.04),
      transition: "all 0.2s",
      "&:hover": {
        bgcolor: alpha("#FF9800", 0.1),
      },
      button: {
        "&.MuiButton-root": {
          background: `linear-gradient(135deg, #FF9800 0%, #F57C00 100%)`,
          "&:hover": {
            background: `linear-gradient(135deg, #F57C00 0%, #EF6C00 100%)`,
          },
        },
      },
    },
    quiz_shared: {
      borderLeft: "4px solid #4CAF50",
      bgcolor: alpha("#4CAF50", 0.04),
      transition: "all 0.2s",
      "&:hover": {
        bgcolor: alpha("#4CAF50", 0.1),
      },
      button: {
        "&.MuiButton-root": {
          background: `linear-gradient(135deg, #4CAF50 0%, #388E3C 100%)`,
          "&:hover": {
            background: `linear-gradient(135deg, #388E3C 0%, #2E7D32 100%)`,
          },
        },
      },
    },
    lesson_shared: {
      borderLeft: "4px solid #1976D2",
      bgcolor: alpha("#1976D2", 0.04),
      transition: "all 0.2s",
      "&:hover": {
        bgcolor: alpha("#1976D2", 0.1),
      },
      button: {
        "&.MuiButton-root": {
          background: `linear-gradient(135deg, #1976D2 0%, #1565C0 100%)`,
          "&:hover": {
            background: `linear-gradient(135deg, #1565C0 0%, #0D47A1 100%)`,
          },
        },
      },
    },
    friend_request_accepted: {
      borderLeft: "4px solid #9C27B0",
      bgcolor: alpha("#9C27B0", 0.04),
      transition: "all 0.2s",
      "&:hover": {
        bgcolor: alpha("#9C27B0", 0.1),
      },
      button: {
        "&.MuiButton-root": {
          background: `linear-gradient(135deg, #9C27B0 0%, #7B1FA2 100%)`,
          "&:hover": {
            background: `linear-gradient(135deg, #7B1FA2 0%, #6A1B9A 100%)`,
          },
        },
      },
    },
  };

  useEffect(() => {
    if (!currentUserId) return;

    // Query notifications where:
    // 1. User is recipient of a friend request (type: friend_request)
    // 2. User is recipient of any other notification type (type: friend_request_accepted, etc)
    const notificationsQuery = query(
      collection(db, "notifications"),
      where("recipientId", "==", currentUserId),
      orderBy("createdAt", "desc"),
    );

    const unsubscribe = onSnapshot(notificationsQuery, async (snapshot) => {
      const notificationsList = [];
      const userCache = new Map(); // Cache user data to avoid duplicate queries

      for (const doc of snapshot.docs) {
        const notification = { id: doc.id, ...doc.data() };
        let userData;

        // Get user data if not in cache
        if (!userCache.has(notification.senderId)) {
          const userQuery = await getDocs(
            query(
              collection(db, "users"),
              where("__name__", "==", notification.senderId),
            ),
          );
          if (!userQuery.empty) {
            userData = userQuery.docs[0].data();
            userCache.set(notification.senderId, userData);
          }
        } else {
          userData = userCache.get(notification.senderId);
        }

        notificationsList.push({
          ...notification,
          senderName: userData?.displayName,
          senderPhoto: userData?.photoURL,
        });
      }

      setNotifications(notificationsList);

      setUnreadCount(notificationsList.filter((n) => !n.read).length);
    });

    return () => unsubscribe();
  }, [currentUserId]);

  const getLessonIcon = (type) => {
    const shortcut = shortcuts.find((s) => s.title === type);
    return shortcut?.icon;
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAcceptRequest = async (requestId, senderId) => {
    try {
      // Delete the friend request notification entirely
      await deleteDoc(doc(db, "notifications", requestId));

      // Create friend connections for both users
      const friendsCollection = collection(db, "friends");

      // Add friend connection for current user
      await addDoc(friendsCollection, {
        userId: currentUserId,
        friendId: senderId,
        createdAt: serverTimestamp(),
      });

      // Add friend connection for sender
      await addDoc(friendsCollection, {
        userId: senderId,
        friendId: currentUserId,
        createdAt: serverTimestamp(),
      });

      // Create acceptance notification for sender
      await addDoc(collection(db, "notifications"), {
        recipientId: senderId,
        senderId: currentUserId,
        type: "friend_request_accepted",
        read: false,
        createdAt: serverTimestamp(),
      });

      showSnackbar(
        "Förfrågan godkänd! Ni kan nu dela material med varandra.",
        "success",
      );
    } catch (error) {
      console.error("Error accepting friend request:", error);
    }
  };

  const handleRejectRequest = async (requestId) => {
    try {
      await deleteDoc(doc(db, "notifications", requestId));
      setNotifications((prev) => prev.filter((n) => n.id !== requestId));
    } catch (error) {
      console.error("Error rejecting friend request:", error);
    }
  };

  const markAsRead = async (notificationId) => {
    try {
      const notificationRef = doc(db, "notifications", notificationId);
      await updateDoc(notificationRef, {
        read: true,
      });
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };

  const renderNotificationContent = (notification) => {
    console.log("Notification data:", {
      type: notification.type,
      data: notification,
      quizData: notification.quizData,
    });

    switch (notification.type) {
      case "friend_request":
        return (
          <>
            <ListItemText
              primary={`${notification.senderName} vill bli vän med dig`}
              primaryTypographyProps={{
                fontWeight: 600,
              }}
              secondary={
                <Box sx={{ display: "flex", gap: 1, mt: 1 }}>
                  <Button
                    variant="purp"
                    sx={{
                      ...styles.tightButton,
                    }}
                    onClick={() =>
                      handleAcceptRequest(
                        notification.id,
                        notification.senderId,
                      )
                    }
                    startIcon={<TaskAltIcon />}
                  >
                    Godkänn
                  </Button>
                  <Button
                    variant="print"
                    startIcon={<ClearIcon />}
                    sx={{ ...styles.tightButton }}
                    onClick={() => handleRejectRequest(notification.id)}
                  >
                    Neka
                  </Button>
                  <Tooltip title="Blir man vänner så kan man enkelt dela pedagogiskt material med varandra.">
                    <IconButton size="small" sx={{ ml: 0.5 }}>
                      <HelpOutlineIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Box>
              }
            />

            {notification.senderId === "Z6BLSMsm38Un5mgW0O6Z3TToHq12" && (
              <Chip
                size="small"
                label="Administratör"
                sx={{
                  position: "absolute",
                  right: 10,
                  top: 10,
                  height: "24px",
                  background: "rgba(255, 77, 77, 0.1)",
                  color: "#FF4D4D",

                  fontSize: "11px",
                  fontWeight: 500,
                  "& .MuiChip-icon": {
                    fontSize: 14,
                    color: "inherit",
                  },
                }}
              />
            )}
          </>
        );

      // Add this new case inside the switch statement in renderNotificationContent:

      case "vision_analysis_shared":
        return (
          <ListItemText
            primary={`${notification.senderName} delade en bildanalys med dig`}
            primaryTypographyProps={{
              fontWeight: notification.read ? 400 : 600,
            }}
            secondary={
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: 1,
                  mt: 1,
                }}
              >
                {notification.lessonData.comment && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 0.6,
                      color: theme.palette.text.secondary,
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        color: theme.palette.text.secondary,
                        fontSize: "0.9rem",
                      }}
                    >
                      {notification.lessonData.comment}
                    </Typography>
                  </Box>
                )}

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 0.6,
                    color: theme.palette.text.secondary,
                  }}
                >
                  <TimerOutlinedIcon sx={{ fontSize: "1rem" }} />
                  <Typography
                    variant="body2"
                    sx={{
                      color: theme.palette.text.secondary,
                      fontSize: "0.8rem",
                    }}
                  >
                    {formatDate(notification.createdAt)}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="success"
                    sx={{
                      ...styles.tightButton,
                      ...notificationStyles[notification.type]?.button,
                    }}
                    component={RouterLink}
                    startIcon={<ImageSearchIcon />}
                    to={`/app/vision/${notification.lessonData.lessonId.substring(
                      0,
                      10,
                    )}`}
                    onClick={() => markAsRead(notification.id)}
                  >
                    Visa bildanalys
                  </Button>
                </Box>
              </Box>
            }
          />
        );

      case "conversation_shared":
        return (
          <ListItemText
            primary={`${notification.senderName} delade en konversation med ${notification.conversationData.teacherName}`}
            primaryTypographyProps={{
              fontWeight: notification.read ? 400 : 600,
            }}
            secondary={
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: 1,
                  mt: 1,
                }}
              >
                {notification.conversationData.comment && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 0.6,
                      color: theme.palette.text.secondary,
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        color: theme.palette.text.secondary,
                        fontSize: "0.9rem",
                      }}
                    >
                      {notification.conversationData.comment}
                    </Typography>
                  </Box>
                )}

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 0.6,
                    color: theme.palette.text.secondary,
                  }}
                >
                  <TimerOutlinedIcon sx={{ fontSize: "1rem" }} />
                  <Typography
                    variant="body2"
                    sx={{
                      color: theme.palette.text.secondary,
                      fontSize: "0.8rem",
                    }}
                  >
                    {formatDate(notification.createdAt)}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="success"
                    sx={{
                      ...styles.tightButton,
                      ...notificationStyles[notification.type]?.button,
                    }}
                    component={RouterLink}
                    startIcon={<MailOutlineIcon />}
                    to={`/app/delad-konversation/${notification.conversationData.conversationId}`}
                    onClick={() => markAsRead(notification.id)}
                  >
                    Visa konversation
                  </Button>
                </Box>
              </Box>
            }
          />
        );

      case "teacher_shared":
        return (
          <ListItemText
            primary={`${notification.senderName} delade karaktären "${notification.teacherData.teacherName}"`}
            primaryTypographyProps={{
              fontWeight: notification.read ? 400 : 600,
            }}
            secondary={
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: 1,
                  mt: 1,
                }}
              >
                {notification.teacherData.comment && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 0.6,
                      color: theme.palette.text.secondary,
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        color: theme.palette.text.secondary,
                        fontSize: "0.9rem",
                      }}
                    >
                      {notification.teacherData.comment}
                    </Typography>
                  </Box>
                )}

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 0.6,
                    color: theme.palette.text.secondary,
                  }}
                >
                  <TimerOutlinedIcon sx={{ fontSize: "1rem" }} />
                  <Typography
                    variant="body2"
                    sx={{
                      color: theme.palette.text.secondary,
                      fontSize: "0.8rem",
                    }}
                  >
                    {formatDate(notification.createdAt)}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="success"
                    sx={{
                      ...styles.tightButton,
                      ...notificationStyles[notification.type]?.button,
                    }}
                    component={RouterLink}
                    startIcon={<MailOutlineIcon />}
                    to={`/app/chat/${notification.teacherData.teacherId}`}
                    onClick={() => markAsRead(notification.id)}
                  >
                    Starta chat
                  </Button>
                </Box>
              </Box>
            }
          />
        );

      case "file_shared":
        const handleFileDownload = async (url, fileId, e) => {
          e.preventDefault();
          e.stopPropagation();
          try {
            const response = await fetch(url);
            const blob = await response.blob();
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = downloadUrl;
            const fileName = url
              .substring(url.lastIndexOf("/") + 1)
              .split("?")[0];
            link.setAttribute("download", decodeURIComponent(fileName));
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(downloadUrl);
            // Update the downloaded status in Firestore
            await updateDoc(doc(db, "files", fileId), {
              downloaded: true,
              downloadedAt: Date.now(),
            });
            await markAsRead(notification.id);
          } catch (error) {
            console.error("Download failed:", error);
            showSnackbar("Kunde inte ladda ner filen", "error");
          }
        };

        return (
          <ListItemText
            primary={`${notification.senderName} delade filen "${notification.fileData.fileName}"`}
            primaryTypographyProps={{
              fontWeight: notification.read ? 400 : 600,
            }}
            secondary={
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: 1,
                  mt: 1,
                }}
              >
                {notification.fileData.comment && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 0.6,
                      color: theme.palette.text.secondary,
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        color: theme.palette.text.secondary,
                        fontSize: "0.9rem",
                      }}
                    >
                      {notification.fileData.comment}
                    </Typography>
                  </Box>
                )}

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 0.6,
                    color: theme.palette.text.secondary,
                  }}
                >
                  <TimerOutlinedIcon sx={{ fontSize: "1rem" }} />
                  <Typography
                    variant="body2"
                    sx={{
                      color: theme.palette.text.secondary,
                      fontSize: "0.8rem",
                    }}
                  >
                    {formatDate(notification.createdAt)}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="print"
                    sx={{
                      ...styles.tightButton,
                    }}
                    startIcon={<DownloadForOfflineIcon />}
                    onClick={(e) =>
                      handleFileDownload(
                        notification.fileData.fileUrl,
                        notification.fileData.fileId,
                        e,
                      )
                    }
                  >
                    Ladda ned
                  </Button>
                  <Chip
                    label={notification.fileData.fileSize}
                    size="small"
                    sx={{
                      height: "24px",
                      background: alpha(theme.palette.primary.main, 0.1),
                      color: theme.palette.text.secondary,
                      fontSize: "11px",
                      fontWeight: 500,
                    }}
                  />
                </Box>
              </Box>
            }
          />
        );

      case "flashcards_shared":
        return (
          <ListItemText
            primary={`${notification.senderName} delade flashcards "${notification.flashcardData.collectionName}"`}
            primaryTypographyProps={{
              fontWeight: notification.read ? 400 : 600,
            }}
            secondary={
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: 1,
                  mt: 1,
                }}
              >
                {notification.flashcardData.comment && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 0.6,
                      color: theme.palette.text.secondary,
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        color: theme.palette.text.secondary,
                        fontSize: "0.9rem",
                      }}
                    >
                      {notification.flashcardData.comment}
                    </Typography>
                  </Box>
                )}

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 0.6,
                    color: theme.palette.text.secondary,
                  }}
                >
                  <TimerOutlinedIcon sx={{ fontSize: "1rem" }} />
                  <Typography
                    variant="body2"
                    sx={{
                      color: theme.palette.text.secondary,
                      fontSize: "0.8rem",
                    }}
                  >
                    {formatDate(notification.createdAt)}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="success"
                    sx={{
                      ...styles.tightButton,
                      ...notificationStyles[notification.type]?.button,
                    }}
                    startIcon={<ViewAgendaIcon />}
                    component={RouterLink}
                    to={`/app/flashcards/${notification.flashcardData.collectionId}/${createUrlSlug(notification.flashcardData.collectionName)}`}
                    onClick={() => markAsRead(notification.id)}
                  >
                    Visa flashcards
                  </Button>
                  <Chip
                    label={`${notification.flashcardData.numFlashcards} kort`}
                    size="small"
                    sx={{
                      height: "24px",
                      background: alpha(theme.palette.primary.main, 0.1),
                      color: theme.palette.text.secondary,
                      fontSize: "11px",
                      fontWeight: 500,
                    }}
                  />
                </Box>
              </Box>
            }
          />
        );

      case "quiz_shared":
        return (
          <ListItemText
            primary={`${notification.senderName} delade quizzet "${notification.quizData.quizName}"`}
            primaryTypographyProps={{
              fontWeight: notification.read ? 400 : 600,
            }}
            secondary={
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: 1,
                  mt: 1,
                }}
              >
                {notification.quizData.comment && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 0.6,
                      color: theme.palette.text.secondary,
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        color: theme.palette.text.secondary,
                        fontSize: "0.9rem",
                      }}
                    >
                      {notification.quizData.comment}
                    </Typography>
                  </Box>
                )}

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 0.6,
                    color: theme.palette.text.secondary,
                  }}
                >
                  <TimerOutlinedIcon sx={{ fontSize: "1rem" }} />
                  <Typography
                    variant="body2"
                    sx={{
                      color: theme.palette.text.secondary,
                      fontSize: "0.8rem",
                    }}
                  >
                    {formatDate(notification.createdAt)}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="success"
                    sx={{
                      ...styles.tightButton,
                      ...notificationStyles[notification.type]?.button,
                    }}
                    component={RouterLink}
                    startIcon={<QuizIcon />}
                    to={`/app/quiz/${notification.quizData.quizId}/${createUrlSlug(notification.quizData.quizName)}`}
                    onClick={() => markAsRead(notification.id)}
                  >
                    Visa quiz
                  </Button>
                </Box>
              </Box>
            }
          />
        );

      case "lesson_shared":
        console.log(notification.lessonData);
        const lessonUrl = `/app${
          notification.lessonData.lessonType !== "PowerPointGenerator.js"
            ? "/lektioner"
            : ""
        }/${lessonUrlMapping(notification.lessonData.lessonType)}/${notification.lessonData.lessonId.substring(
          0,
          10,
        )}${
          getLessonTypeParam(notification.lessonData.lessonType) === "default"
            ? "?v=t"
            : `?type=${getLessonTypeParam(notification.lessonData.lessonType)}&v=t`
        }`;

        return (
          <ListItemText
            primary={`${notification.senderName} delade lektionen "${notification.lessonData.lessonName}"`}
            primaryTypographyProps={{
              fontWeight: notification.read ? 400 : 600,
            }}
            secondary={
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: 1,
                  mt: 1,
                }}
              >
                {notification.lessonData.comment && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 0.6,
                      color: theme.palette.text.secondary,
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        color: theme.palette.text.secondary,
                        fontSize: "0.9rem",
                      }}
                    >
                      {notification.lessonData.comment}
                    </Typography>
                  </Box>
                )}

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 0.6,
                    color: theme.palette.text.secondary,
                  }}
                >
                  <TimerOutlinedIcon sx={{ fontSize: "1rem" }} />
                  <Typography
                    variant="body2"
                    sx={{
                      color: theme.palette.text.secondary,
                      fontSize: "0.8rem",
                    }}
                  >
                    {formatDate(notification.createdAt)}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="purp"
                    sx={{
                      ...styles.tightButton,
                      ...notificationStyles[notification.type]?.button,
                    }}
                    component={RouterLink}
                    to={lessonUrl}
                    onClick={() => markAsRead(notification.id)}
                    startIcon={getLessonIcon(
                      lessonMapping(notification.lessonData.lessonType),
                    )}
                  >
                    Visa lektion
                  </Button>
                  <Chip
                    label={
                      lessonMapping(notification.lessonData.lessonType) ||
                      "Ingen typ"
                    }
                    size="small"
                    sx={{
                      height: "24px",
                      background: alpha(theme.palette.primary.main, 0.1),
                      color: theme.palette.text.secondary,
                      fontSize: "11px",
                      fontWeight: 500,
                    }}
                  />
                </Box>
              </Box>
            }
          />
        );

      case "friend_request_accepted":
        return (
          <ListItemText
            primary={`${notification.senderName} accepterade din vänförfrågan`}
            primaryTypographyProps={{
              fontWeight: notification.read ? 400 : 600,
              color: "text.secondary",
            }}
            onClick={() => markAsRead(notification.id)}
          />
        );
      // Add more cases for different notification types here
      default:
        return (
          <ListItemText
            primary={notification.message || "Ny avisering"}
            primaryTypographyProps={{
              fontWeight: notification.read ? 400 : 600,
            }}
          />
        );
    }
  };

  return (
    <>
      <IconButton
        onClick={handleClick}
        aria-label="show notifications"
        color="inherit"
        sx={{
          height: "43px",
          width: "43px",
          backgroundColor: "rgba(0, 0, 0, 0.09)",
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.16)",
          },
        }}
      >
        <Badge badgeContent={unreadCount} color="error">
          <NotificationsIcon sx={{ color: "text.secondary" }} />
        </Badge>
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            width: 480,
            maxHeight: 400,
            ...styles.roundMenu,
            borderRadius: 3,
            boxShadow:
              "0 26px 58px 0 rgba(0, 0, 0, .22), 0 5px 14px 0 rgba(0, 0, 0, .18) !important",
          },
        }}
        MenuListProps={{
          sx: {
            pt: 0,
            pb: 0,
          },
        }}
      >
        <Box
          sx={{
            ...styles.freshBox,
            ...styles.softBoxShadow,
            padding: "15px",
            border: "0px",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontSize: "1.1rem",
              fontWeight: "600",
              mb: 0,
              color: theme.palette.mode === "dark" ? "white" : "black",
            }}
          >
            Aviseringar
          </Typography>
        </Box>
        <Divider />

        {notifications.length === 0 ? (
          <MenuItem
            sx={{
              ...styles.menuItemStyle,
              flexDirection: "column", // This makes the items stack vertically
              alignItems: "center", // Centers items horizontally
            }}
          >
            <Box sx={{ mb: 2, mt: 3 }}>
              {" "}
              {/* Added margin bottom for spacing */}
              <img
                src={"/img/bell.svg"}
                alt="studera.ai"
                style={{
                  height: "200px",
                  width: "auto",
                }}
              />
            </Box>
            <Box>
              <Typography
                color="textSecondary"
                sx={{
                  p: 1,
                  whiteSpace: "pre-wrap",
                  textAlign: "center",
                  display: "block",
                  mb: 4,
                }}
              >
                När andra användare delar lektioner, prov, quiz & flashcards med
                dig så kommer de visas här.
              </Typography>
            </Box>
          </MenuItem>
        ) : (
          <List sx={{ width: "100%", p: 0 }}>
            {notifications.map((notification) => (
              <ListItem
                key={notification.id}
                sx={{
                  px: 2,
                  py: 1,
                  mb: 0.3,
                  cursor:
                    notification.type === "friend_request_accepted"
                      ? "pointer"
                      : "default",
                  ...notificationStyles[notification.type],
                }}
                onClick={
                  notification.type === "friend_request_accepted"
                    ? () => markAsRead(notification.id)
                    : undefined
                }
              >
                <ListItemAvatar>
                  <Avatar
                    src={notification.senderPhoto}
                    alt={notification.senderName}
                  >
                    {notification.senderName?.[0]}
                  </Avatar>
                </ListItemAvatar>
                {renderNotificationContent(notification)}
                {!notification.read && (
                  <Box
                    sx={{
                      width: 12,
                      height: 12,
                      borderRadius: "50%",
                      bgcolor: "#0064d1",
                      ml: "auto",
                      flexShrink: 0,
                    }}
                  />
                )}
              </ListItem>
            ))}
          </List>
        )}
      </Menu>
    </>
  );
};

export default NotificationsComponent;
