import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  Tooltip,
  FormControl,
  Select,
  MenuItem,
  Grid,
  TextField,
  FormControlLabel,
  Switch,
  Paper,
  Divider,
} from "@mui/material";

import GenerateIcon from "@mui/icons-material/AutoAwesome";
import LessonPlanEditor from "./LessonPlanEditor";
import PrintIcon from "@mui/icons-material/Print";
import AddonTimelineSimple from "./AddonTimelineSimple";
import TimelineSimpleDisplay from "./TimelineSimpleDisplay";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import EventNoteIcon from "@mui/icons-material/EventNote";
import HistoryIcon from "@mui/icons-material/History";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SlideInPaper from "./SlideInPaper";
import TimelineDisplay from "./TimelineDisplay";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import AddonTimeline from "./AddonTimeline";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import { useParams } from "react-router-dom";
import DateRangeIcon from "@mui/icons-material/DateRange";
import LessonPlanDisplay from "./LessonPlanDisplay";
import PersonIcon from "@mui/icons-material/Person";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import ShareLink from "./ShareLink";
import AddIcon from "@mui/icons-material/Add";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import LessonQuizDisplay from "./LessonQuizDisplay";
import AddonQuiz from "./AddonQuiz";
import ReadingComprehensionDialog from "./ReadingComprehensionDialog";
import DashedPapers from "./DashedPapers";
import LessonExercisesDisplay from "./LessonExercisesDisplay";
import ImageIcon from "@mui/icons-material/Image";
import ImageGenerationModal from "./ImageGenerationModal";
import HoverableImage from "./HoverableImage";
import { callApi } from "./api";
import { db } from "./firebase";
import {
  collection,
  query,
  where,
  limit,
  addDoc,
  updateDoc,
  getDocs,
  serverTimestamp,
} from "firebase/firestore";

import Logger from "./Logger";
import LanguageSelector from "./LanguageSelector";

import { getThemes } from "./additionalThemes/DiaryLesson";
import { ThemeSelectorFlow } from "./ThemeSelectorFlow";
import InfoBox from "./InfoBox";
import { handlePrintDiaryLesson } from "./printFunctions";

import { generateAndProcessImage } from "./functions";
import ShareDialog from "./ShareDialog";
import ShareIcon from "@mui/icons-material/Share";
import LoadingBackdrop from "./LoadingBackdrop";
import { useSnackbar } from "./SnackbarHandler";
import ProgressBar from "./ProgressBar";
import LessonDrawer from "./LessonDrawer";
import SaveLessonDialog from "./SaveLessonDialog";
import EditIcon from "@mui/icons-material/Edit";
import { DashedPaper } from "../StyledComponents";
import { getStyles } from "../index";
import { trackLesson, handleImageGenerationError } from "./functions";
import GradeSelector, { gradeInstructions } from "./GradeSelector";
import { useTheme } from "@mui/material/styles";
import { useSearchParams } from "react-router-dom";

import DiaryContent from "./DiaryContent";

const LessonPlanRevamp = ({ user }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);
  const lessonTitleRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageInstructions, setImageInstructions] = useState("");
  const [isGeneratingImages, setIsGeneratingImages] = useState(false);
  const [lessonTime, setLessonTime] = useState("45 min");
  const lessonTimes = ["30 min", "45 min", "60 min", "75 min", "90 min"];
  const [loadingImageIndex, setLoadingImageIndex] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [isSharing, setIsSharing] = useState(false);
  const quizSectionRef = useRef(null);
  const exercisesSectionRef = useRef(null);
  const timelineSectionRef = useRef(null);
  const [editorOpen, setEditorOpen] = useState(false);
  const timelineSimpleSectionRef = useRef(null);
  const timelineSimpleButtonRef = useRef(null);

  // Basic settings
  const [lessonTheme, setLessonTheme] = useState("");
  const [language, setLanguage] = useState("svenska");
  const [grade, setGrade] = useState("åk9");
  const [numOfDiaryEntries, setNumOfDiaryEntries] = useState(5);
  const [diaryEntryLength, setDiaryEntryLength] = useState("Långa");
  const [showControlPanel, setShowControlPanel] = useState(false);

  // Generation states
  const [progress, setProgress] = useState(0);
  const [isGeneratingLesson, setIsGeneratingLesson] = useState(false);
  const [lessonData, setLessonData] = useState("");

  // References for content
  const [customTheme, setCustomTheme] = useState("");
  const [predefinedTheme, setPredefinedTheme] = useState(null);
  const subject = searchParams.get("subject") || null;
  const viewMode = searchParams.get("v") === "t";
  const additionalThemes = getThemes(subject);
  const { showSnackbar } = useSnackbar();
  const theme = useTheme();
  const styles = getStyles(theme);
  const urlContentRef = useRef("");
  const transcriptRef = useRef("");
  const uploadedFileRef = useRef("");
  const bookContentRef = useRef("");
  const wikipediaContentRef = useRef("");
  const randomizedContentRef = useRef("");
  const coursePlanContentRef = useRef("");

  // Add refs for the quiz and comprehension buttons
  const quizButtonRef = useRef(null);
  const timelineButtonRef = useRef(null);
  const comprehensionButtonRef = useRef(null);

  const [shareDialogOpenFriends, setShareDialogOpenFriends] = useState(false);

  const pdfButtonRef = useRef(null);
  const translateButtonRef = useRef(null);
  const shareLinkButtonRef = useRef(null);
  const [isExpanded, setIsExpanded] = useState(false);
  // Add click handlers for the DashedPaper components

  const handleTimelinePaperClick = () => {
    timelineButtonRef.current?.click();
  };

  const handleTimelineSimplePaperClick = () => {
    timelineSimpleButtonRef.current?.click();
  };

  const handleQuizPaperClick = () => {
    quizButtonRef.current?.click();
  };

  const handleComprehensionPaperClick = () => {
    comprehensionButtonRef.current?.click();
  };

  const { lessonId } = useParams();

  useEffect(() => {
    Logger.log("lessonData: ", JSON.stringify(lessonData, null, 2));
  }, [lessonData]);

  useEffect(() => {
    const loadLessonFromId = async () => {
      if (lessonId) {
        try {
          const q = query(
            collection(db, "lesson_usage"),
            where("__name__", ">=", lessonId),
            where("__name__", "<=", lessonId + "\uf8ff"),
            where("lessonType", "==", "LessonPlanRevamp.js"),
            limit(1),
          );

          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            const docData = querySnapshot.docs[0].data();

            if (!docData.hasOpened) {
              await updateDoc(querySnapshot.docs[0].ref, {
                hasOpened: true,
                openedAt: Date.now(),
              });
            }

            setLessonData(docData.data);

            // Scroll to lesson content
            setTimeout(() => {
              lessonTitleRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }, 100);

            showSnackbar("Lektionen har laddats in.", "success");
          } else {
            showSnackbar("Kunde inte hitta lektionen", "error");
          }
        } catch (error) {
          console.error("Error loading lesson:", error);
          showSnackbar("Ett fel uppstod när lektionen skulle laddas", "error");
        }
      }
    };

    loadLessonFromId();
  }, lessonId);

  useEffect(() => {
    Logger.log("lessonData: ", lessonData);
    const isDataValid =
      lessonData !== null &&
      lessonData !== undefined &&
      Object.keys(lessonData).length > 0 &&
      !Object.values(lessonData).includes("");

    if (isDataValid !== showControlPanel) {
      const timer = setTimeout(() => {
        setShowControlPanel(isDataValid);
      }, 100);

      return () => clearTimeout(timer);
    }
  }, [lessonData, showControlPanel]);

  const handleShare = async (
    lessonComment,
    lessonName,
    selectedImage,
    tags,
    shareData,
  ) => {
    setIsSharing(true);
    await new Promise((resolve) => setTimeout(resolve, 1500));
    // try {
    //   // Create a document for each student
    //   const sharePromises = shareData.studentIds.map((studentId) => {
    //     const documentData = {
    //       userId: user.uid,
    //       shared: true,
    //       sharedTo: studentId,
    //       comment: shareData.comment,
    //       sharedAt: Date.now(),
    //       fileUrl: shareData.fileUrl,
    //       lessonType: "LessonPlanRevamp.js",
    //       timestamp: serverTimestamp(),
    //       lessonComment,
    //       lessonName,
    //       coverImage: selectedImage,
    //       hasPrinted: true,
    //       data: lessonData,
    //     };
    //     Logger.log(
    //       "Adding document for student",
    //       studentId,
    //       ":",
    //       JSON.stringify(documentData, null, 2),
    //     );
    //     return addDoc(collection(db, "lesson_usage"), documentData);
    //   });
    //   await Promise.all(sharePromises);
    //   showSnackbar(`Du har nu delat lektionen!`, "success");
    // }

    try {
      // Create a document for each student and then create notifications
      const sharePromises = shareData.studentIds.map(async (studentId) => {
        const documentData = {
          userId: user.uid,
          shared: true,
          sharedTo: studentId,
          comment: shareData.comment,
          sharedAt: Date.now(),
          fileUrl: shareData.fileUrl,
          lessonType: "LessonPlanRevamp.js",
          timestamp: serverTimestamp(),
          lessonComment,
          lessonName,
          coverImage: selectedImage,
          hasPrinted: true,
          data: lessonData,
        };
        // Create lesson document and get ID
        const lessonDoc = await addDoc(
          collection(db, "lesson_usage"),
          documentData,
        );
        Logger.log(
          "Adding document for student",
          studentId,
          ":",
          JSON.stringify(documentData, null, 2),
        );
        // Create notification with lesson doc ID
        await addDoc(collection(db, "notifications"), {
          type: "lesson_shared",
          recipientId: studentId,
          senderId: user.uid,
          read: false,
          createdAt: serverTimestamp(),
          lessonData: {
            lessonId: lessonDoc.id,
            lessonType: documentData.lessonType,
            lessonName: documentData.lessonName,
            fileUrl: documentData.fileUrl,
            comment: documentData.comment,
            coverImage: selectedImage,
          },
        });
        return lessonDoc;
      });
      await Promise.all(sharePromises);
      showSnackbar(`Du har nu delat lektionen!`, "success");
    } catch (error) {
      showSnackbar("Fel vid delande av lektionen: " + error.message, "error");
      console.error(error);
    } finally {
      setIsSharing(false);
    }
  };

  const handleSaveLessonClick = () => {
    setSaveDialogOpen(true);
  };

  const handleSaveWithComment = async (comment, name, coverImage, tags) => {
    await trackLesson(
      user.uid,
      "LessonPlanRevamp.js",
      lessonData,
      null,
      true,
      comment,
      name,
      coverImage,
      tags,
    );
    setDrawerOpen(true);
    showSnackbar("Lektionen sparad i din lektionsbank!", "success");
  };

  const toggleViewMode = () => {
    const currentParams = Object.fromEntries(searchParams.entries());
    if (currentParams.v === "t") {
      delete currentParams.v;
    } else {
      currentParams.v = "t";
    }
    setSearchParams(currentParams);
  };

  const simulateProgress = (startProgress, endProgress, duration) => {
    const stepSize = 0.5;
    const steps = (endProgress - startProgress) / stepSize;
    const stepDuration = duration / steps;
    let currentProgress = startProgress;

    const intervalId = setInterval(() => {
      currentProgress += stepSize;
      if (currentProgress >= endProgress) {
        clearInterval(intervalId);
      } else {
        setProgress(currentProgress);
      }
    }, stepDuration);

    return () => clearInterval(intervalId);
  };

  const handleGenerateLesson = async () => {
    setProgress(0);
    let stopProgressSimulation = null;
    setIsGeneratingLesson(true);
    const gradeInstructionsText = gradeInstructions[grade];
    setLessonData(null);

    try {
      let themeToUse;

      if (lessonTheme === "youtube") {
        themeToUse = transcriptRef.current;
      } else if (lessonTheme === "customTheme") {
        themeToUse = customTheme;
      } else if (lessonTheme === "predefined") {
        themeToUse = predefinedTheme;
      } else if (lessonTheme === "fileUpload") {
        themeToUse = uploadedFileRef.current;
      } else if (lessonTheme === "wikipedia") {
        themeToUse = wikipediaContentRef.current;
      } else if (lessonTheme === "randomizer") {
        themeToUse = randomizedContentRef.current;
      } else if (lessonTheme === "book") {
        themeToUse = bookContentRef.current;
      } else if (lessonTheme === "url") {
        themeToUse = urlContentRef.current;
      } else {
        themeToUse = coursePlanContentRef.current;
      }

      if (!themeToUse.trim()) {
        showSnackbar("Du måste välja underlag i steg 1.", "warning");
        return;
      }

      stopProgressSimulation = simulateProgress(0, 90, 15000);

      let response = await callApi(
        [
          {
            role: "system",
            content: `Du är en expert på pedagogisk planering och lektionsdesign, med specialisering på att skapa strukturerade och effektiva lektionsplaner på svenska. 
          Skapa en detaljerad och pedagogiskt genomtänkt lektionsplan baserad på användarens input. Följ exakt den JSON-struktur som anges i prompten och säkerställ att planen följer den svenska läroplanen (LGR22).`,
          },
          {
            role: "user",
            content: `
            - Theme: "${themeToUse}"
            - Lektionsplan för årskurs: "${gradeInstructionsText}"
            - Språk: "${language}"
            - Lektionstid: "${lessonTime}"


            RESPOND IN THIS EXACT JSON FORMAT:
            {
              "lektionsplan": {
                "titel": "Lektionsplan för [ämne/titel]",
                "undertitel": "Undertitel",
                "mål": {
                  "syfte": "string",
                  "kopplingTillKursplan": ["string1", "string2", "string3"],
                  "lärandemål": ["lärandemål1", "lärandemål2", "lärandemål3"]
                },
                "uppvärmning": {
                  "aktivitet": "string",
                  "kopplingTillTema": "string",
                  "tidsåtgång": "number (minuter)"
                },
                "inledning": {
                  "beskrivning": "string",
                  "frågor": ["fråga1", "fråga2", "fråga3"],
                  "tidsåtgång": "number (minuter)"
                },
                "huvudaktivitet": {
                  "instruktioner": ["instruktion1", "instruktion2", "instruktion3"],
                  "metoder": ["metod1", "metod2", "metod3"],
                  "steg": [
                    {
                      "stegNummer": "number",
                      "beskrivning": "string",
                      "tidsåtgång": "number (minuter)"
                    }
                  ],
                  "förväntadeResultat": ["resultat1", "resultat2", "resultat3"]
                },
                "avslutning": {
                  "sammanfattning": "string",
                  "reflektion": ["reflektion1", "reflektion2", "reflektion3"],
                  "förberedelseInförNästaLektionen": "string",
                  "tidsåtgång": "number (minuter)"
                },
                "material": ["material1", "material2", "material3"],
                "hemläxa": {
                  "beskrivning": "string",
                  "kopplingTillLektionen": "string",
                  "förväntadTidsåtgång": "number (minuter)"
                },
                "utvärdering": {
                  "självutvärdering": "string",
                  "lärarensBedömning": "string"
                },
                "differentiering": {
                  "anpassningar": ["anpassning1", "anpassning2", "anpassning3"],
                  "alternativaMetoder": ["alternativMetod1", "alternativMetod2", "alternativMetod3"]
                },
                "lärarensReflektion": {
                  "framgångar": "string",
                  "förbättringsområden": "string",
                  "anteckningarFörFramtidaLektioner": "string"
                },
                "kopplingTillTema": {
                  "ämnesövergripandeLärande": "string",
                  "kopplingTillVardagslivet": "string"
                },
                "förväntadeUtmaningar": {
                  "utmaningar": ["utmaning1", "utmaning2", "utmaning3"],
                  "lösningar": ["lösning1", "lösning2", "lösning3"]
                },
                "tidsplanering": [
                  {
                    "sektion": "string",
                    "tidsåtgång": "number (minuter)"
                  },
                  {
                    "sektion": "string",
                    "tidsåtgång": "number (minuter)"
                  }
                ]
              }
            }
            `,
          },
        ],
        6000,
        true,
        user.uid,
      );

      Logger.log("response2: ", JSON.stringify(response, null, 2));
      if (stopProgressSimulation) stopProgressSimulation();
      setProgress(95);

      let messageContent = response.data.choices[0].message.content.trim();
      if (messageContent.startsWith("```json")) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith("```")) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      const data = JSON.parse(messageContent);
      setProgress(100);

      setLessonData({
        ...data,
        imageUrl: null,
      });

      Logger.log("response: ", JSON.stringify(lessonData, null, 2));

      setTimeout(() => {
        lessonTitleRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, 100);

      await trackLesson(user.uid, "LessonPlanRevamp.js", null, {
        language,
        lessonTheme,
      });

      showSnackbar("Din lektionplan har genererats!", "success");
    } catch (error) {
      console.error("Error generating lesson:", error);
      showSnackbar(
        "Ett fel uppstod när lektionen skulle genereras. Försök igen!",
        "error",
      );
    } finally {
      setIsGeneratingLesson(false);
    }
  };

  const handleGenerateImages = async () => {
    if (!lessonData) return;
    setIsGeneratingImages(true);
    handleCloseModal();

    try {
      const imagePromises = lessonData.diaryEntries.map((entry) => {
        const prompt = `Create a photorealistic image depicting a scene from ${entry.date}, based on this: ${entry.content.slice(0, 200)}. ${imageInstructions}`;
        return generateAndProcessImage(prompt, user.uid);
      });

      const imageUrls = await Promise.all(imagePromises);

      setLessonData((prevData) => ({
        ...prevData,
        diaryEntries: prevData.diaryEntries.map((entry, index) => ({
          ...entry,
          imageUrl: imageUrls[index],
        })),
      }));

      showSnackbar("Bilderna har genererats!", "success");
    } catch (error) {
      handleImageGenerationError(error, true, showSnackbar);
    } finally {
      setIsGeneratingImages(false);
      setLoadingImageIndex(null);
    }
  };

  const handleGenerateSpecificImage = async (index) => {
    setIsGeneratingImages(true);
    setLoadingImageIndex(index);
    handleCloseModal();

    try {
      const prompt = `Create a photorealistic image depicting a scene from this: ${lessonData.lektionsplan.titel} - ${lessonData.lektionsplan.undertitel}. ${imageInstructions}`;

      const imageUrl = await generateAndProcessImage(prompt, user.uid);

      if (imageUrl) {
        setLessonData((prevData) => ({
          ...prevData,
          imageUrl: imageUrl,
        }));
        showSnackbar("Bilden har genererats!", "success");
      }
    } catch (error) {
      handleImageGenerationError(error, false, showSnackbar);
    } finally {
      setIsGeneratingImages(false);
      setLoadingImageIndex(null);
    }
  };

  const handleImageButtonClick = (index = null) => {
    setSelectedImageIndex(index);
    setShowImageModal(true);
  };

  const handleCloseModal = () => {
    setShowImageModal(false);
    setImageInstructions("");
  };

  const handleGenerateWithInstructions = async () => {
    if (selectedImageIndex !== null) {
      handleCloseModal();
      await handleGenerateSpecificImage(selectedImageIndex);
    } else {
      handleCloseModal();
      await handleGenerateImages();
    }
  };

  return (
    <Box sx={styles.container}>
      <Box
        sx={{
          ...styles.innerContainer,
          width: { xs: "100%", md: "100%" },
          margin: "0 auto",
        }}
      >
        {!viewMode && (
          <Typography
            variant="h1"
            gutterBottom
            sx={{
              fontWeight: "bold",
              fontSize: "1.8em",
              mb: 3,
              mt: 5,
              textAlign: "center",
            }}
          >
            Generera lektionsplan
          </Typography>
        )}
        <Box
          sx={{
            ...styles.buttonsContainer,
            ...styles.additionalButtonsContainer,
          }}
        >
          {lessonData && user.accountType === "teacher" && (
            <Button
              variant="purp"
              sx={{ ...styles.tightButton }}
              disableRipple
              onClick={() => {
                setTimeout(() => {
                  lessonTitleRef.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "start", // This ensures it scrolls to the top of the element
                  });
                }, 100);
                toggleViewMode();
              }}
              startIcon={
                searchParams.get("v") === "t" ? (
                  <FullscreenExitIcon />
                ) : (
                  <FullscreenIcon />
                )
              }
            >
              {searchParams.get("v") === "t" ? "Redigeringsläge" : "Elevläge"}
            </Button>
          )}

          {!viewMode && (
            <Button
              variant="purp"
              sx={{ ...styles.tightButton }}
              onClick={() => setDrawerOpen(true)}
              startIcon={<BookmarkBorderIcon />}
            >
              Sparat
            </Button>
          )}
        </Box>

        <LessonDrawer
          userId={user.uid}
          lessonType={"LessonPlanRevamp.js"}
          theme={theme}
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          lessonIcon={NewspaperIcon}
          onSelectLesson={(lesson) => {
            setLessonData(lesson.data);
            setDrawerOpen(false);
            setTimeout(() => {
              lessonTitleRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "start", // This ensures it scrolls to the top of the element
              });
            }, 100);
            showSnackbar(`Lektionen har laddats in.`, "success");
          }}
        />

        {!viewMode && (
          <>
            <InfoBox
              icon={EventNoteIcon}
              title="Powerpoint Instructions"
              instructions={[
                "Generera lektionsplan baserat skolverkets kursplaner, kulturrådets läslistor eller valfritt ämne",
                "Skräddarsy vilka sektioner du vill inkludera",
                "Skriv ut, spara och dela lektionsplanen",
              ]}
              theme={theme}
              styles={styles}
            />

            <ThemeSelectorFlow
              key={subject}
              subject={subject}
              value={lessonTheme}
              onChange={(e) => setLessonTheme(e.target.value)}
              customTheme={customTheme}
              onCustomThemeChange={(e) => setCustomTheme(e.target.value)}
              onPredefinedThemeChange={(e) =>
                setPredefinedTheme(e.target.value)
              }
              onBookContentChange={(content) => {
                bookContentRef.current = content;
              }}
              onYoutubeTranscriptChange={(transcriptContent) => {
                transcriptRef.current = transcriptContent;
              }}
              onUrlContentChange={(content) => {
                Logger.log(
                  "Parent: URL content received",
                  content.substring(0, 300),
                );
                urlContentRef.current = content;
              }}
              onFileUpload={(content) => {
                uploadedFileRef.current = content;
              }}
              onWikipediaContentChange={(content) => {
                wikipediaContentRef.current = content;
              }}
              onRandomizedContentChange={(content) => {
                randomizedContentRef.current = content;
              }}
              onCoursePlanContentChange={(content) => {
                coursePlanContentRef.current = content;
              }}
              lessonType={"LessonPlanRevamp.js"}
              user={user}
              theme={theme}
              styles={styles}
              onLessonSelect={(lesson) => {
                Logger.log("onLessonSelect körs");
                setLessonData(lesson.data);
                setTimeout(() => {
                  lessonTitleRef.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "start", // This ensures it scrolls to the top of the element
                  });
                }, 100);
                showSnackbar("Lektionen har laddats in.", "success");
              }}
              additionalThemes={additionalThemes}
              forLessonPlan={true}
            />

            <Box sx={styles.headlineLessonBox}>
              <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
                2. Generera lektionsplanen
              </Typography>
            </Box>

            <Box sx={{ ...styles.freshBox, padding: "40px" }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "500", mb: 1 }}
                  >
                    Årskurs:
                  </Typography>
                  <GradeSelector grade={grade} setGrade={setGrade} />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "500", mb: 1 }}
                  >
                    Språk:
                  </Typography>
                  <LanguageSelector
                    language={language}
                    setLanguage={setLanguage}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Typography
                    variant="formLabelSinUnderline"
                    sx={{ fontWeight: "500" }}
                  >
                    Lektionstid:
                  </Typography>
                  <TextField
                    select
                    fullWidth
                    value={lessonTime}
                    onChange={(e) => setLessonTime(e.target.value)}
                    sx={{ mb: 2, mt: 1 }}
                  >
                    {lessonTimes.map((time) => (
                      <MenuItem key={time} value={time}>
                        {time}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "12px",
                  width: "100%",
                }}
              >
                <Button
                  variant={
                    theme.palette.mode === "light" ? "purp" : "contained"
                  }
                  color="primary"
                  onClick={handleGenerateLesson}
                  disabled={isGeneratingLesson}
                  sx={{ mt: 7 }}
                  startIcon={
                    !isGeneratingLesson ? (
                      <GenerateIcon style={{ color: "#fff" }} />
                    ) : (
                      <CircularProgress size={16} style={{ color: "#fff" }} />
                    )
                  }
                >
                  {isGeneratingLesson ? "Genererar..." : "Generera lektion"}
                </Button>
              </div>
              {isGeneratingLesson && (
                <Box sx={{ mt: 6 }}>
                  <ProgressBar
                    progress={progress}
                    label="Genererar lektionplan — tar 30-60 sekunder..."
                  />
                </Box>
              )}
            </Box>
          </>
        )}

        {lessonData && viewMode && (
          <LessonPlanDisplay
            lessonData={lessonData}
            styles={styles}
            theme={theme}
            viewMode={viewMode}
          />
        )}

        {lessonData && (
          <>
            {!viewMode && (
              <Box
                ref={lessonTitleRef}
                sx={{ ...styles.headlineLessonBox, mt: 10 }}
              >
                <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
                  3. Din lektionsplan
                </Typography>
              </Box>
            )}
            <Box
              sx={{
                ...styles.freshBox,
                padding: "30px",

                width: viewMode ? "70%" : undefined,
                margin: viewMode ? "0 auto" : undefined,
                marginTop: viewMode ? "100px" : undefined,
              }}
            >
              {!viewMode && (
                <Box sx={{ position: "relative" }}>
                  <Box
                    sx={{
                      ...styles.lessonControlPanel,
                      ...styles.lessonControlPanelNuevo,

                      "& .MuiButton-root": {
                        ...styles.controlPanelButtons,
                        ...styles.lessonControlPanelNewButtons,
                      },
                    }}
                  >
                    <Button
                      variant="success"
                      sx={{ ml: 2 }}
                      onClick={() => setEditorOpen(true)}
                      startIcon={isEditMode ? <SaveAsIcon /> : <EditIcon />}
                    >
                      {isEditMode ? "Spara ändringar" : "Redigera lektionsplan"}
                    </Button>
                    <Button
                      variant="print"
                      startIcon={<SaveAsIcon />}
                      sx={{ ml: 2, mt: 2 }}
                      onClick={handleSaveLessonClick}
                    >
                      Spara i min lektionsbank
                    </Button>

                    <Box sx={{ display: "none" }}>
                      <AddonQuiz
                        buttonRef={quizButtonRef}
                        lessonData={lessonData}
                        title={lessonData.title}
                        theme={theme}
                        user={user}
                        styles={styles}
                        showSnackbar={showSnackbar}
                        addQuizToLessonObject={(updatedData) => {
                          setLessonData(updatedData);
                          setTimeout(
                            () =>
                              quizSectionRef.current?.scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                              }),
                            100,
                          );
                        }}
                        buttonText="Lägg till interaktivt quiz"
                      />
                    </Box>
                    <Box sx={{ display: "none", ml: 2 }}>
                      <ReadingComprehensionDialog
                        buttonRef={comprehensionButtonRef}
                        key={lessonData?.title}
                        lessonData={lessonData}
                        user={user}
                        theme={theme}
                        styles={styles}
                        buttonText="Lägg till övningar"
                        addExercisesToLessonObject={(updateFunction) => {
                          setLessonData((prevLessonData) => ({
                            ...prevLessonData,
                            exercises: [
                              ...(prevLessonData.exercises || []),
                              ...updateFunction(prevLessonData).exercises,
                            ],
                          }));
                          setTimeout(
                            () =>
                              exercisesSectionRef.current?.scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                              }),
                            100,
                          );
                        }}
                      />

                      <Box sx={{ display: "none", ml: 2 }}>
                        <AddonTimelineSimple
                          buttonRef={timelineSimpleButtonRef}
                          lessonData={lessonData}
                          theme={theme}
                          user={user}
                          styles={styles}
                          showSnackbar={showSnackbar}
                          addTimelineToLessonObject={(updatedData) => {
                            setLessonData(updatedData);
                            setTimeout(
                              () =>
                                timelineSimpleSectionRef.current?.scrollIntoView(
                                  {
                                    behavior: "smooth",
                                    block: "center",
                                  },
                                ),
                              100,
                            );
                          }}
                          buttonText="Lägg till enkel tidslinje"
                        />
                      </Box>
                    </Box>
                    <Box sx={{ display: "none", ml: 2 }}>
                      <AddonTimeline
                        buttonRef={timelineButtonRef}
                        lessonData={lessonData}
                        theme={theme}
                        user={user}
                        styles={styles}
                        showSnackbar={showSnackbar}
                        addTimelineToLessonObject={(updatedData) => {
                          setLessonData(updatedData);
                          setTimeout(
                            () =>
                              timelineSectionRef.current?.scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                              }),
                            100,
                          );
                        }}
                        buttonText="Lägg till tidslinje"
                      />
                    </Box>

                    <Button
                      variant="success"
                      sx={{}}
                      onClick={() => setShareDialogOpenFriends(true)}
                      startIcon={<ShareIcon />}
                    >
                      Dela med vänner
                    </Button>

                    <ShareLink
                      buttonRef={shareLinkButtonRef}
                      key={lessonId}
                      lessonId={lessonId}
                      lessonData={lessonData}
                      user={user}
                      lessonType={"LessonPlanRevamp.js"}
                      onCopy={(message, severity) =>
                        showSnackbar(message, severity)
                      }
                      sx={{ mt: 0, mr: 2 }}
                    />

                    <Button
                      variant="success"
                      sx={{}}
                      onClick={() => setShareDialogOpen(true)}
                      startIcon={<ShareIcon />}
                    >
                      Dela med elever
                    </Button>

                    <Button
                      variant="purp"
                      onClick={() => {
                        window.scrollTo({ top: 0, behavior: "smooth" });
                        toggleViewMode();
                      }}
                      sx={{ ml: 2 }}
                      startIcon={
                        searchParams.get("v") === "t" ? (
                          <FullscreenExitIcon />
                        ) : (
                          <FullscreenIcon />
                        )
                      }
                    >
                      {searchParams.get("v") === "t"
                        ? "Redigeringsläge"
                        : "Förhandsgranska"}
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      ...styles.lessonControlPanelFade,
                    }}
                  />
                </Box>
              )}

              {!viewMode && (
                <LessonPlanDisplay
                  lessonData={lessonData}
                  styles={styles}
                  theme={theme}
                  viewMode={viewMode}
                />
              )}

              <LessonPlanEditor
                open={editorOpen}
                onClose={() => setEditorOpen(false)}
                lessonData={lessonData}
                onUpdate={setLessonData}
                onSave={() => {
                  showSnackbar("Ändringar sparade", "success");
                }}
              />

              {!viewMode && (
                <>
                  <Divider sx={{ mt: 15, mb: 10 }} />

                  <Box sx={{ maxWidth: "400px", mx: "auto", mb: 4 }}>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontSize: "1rem",
                        color: theme.palette.text.secondary,
                        textAlign: "center",
                        mb: 2,
                      }}
                    >
                      Generera en bild som du kan använda när du sparar din
                      lektionsplanering som dels gör det enklare hitta den vid
                      senare tillfälle, dels gör det lite trevligare.
                    </Typography>

                    <HoverableImage
                      imageUrl={lessonData.imageUrl}
                      onGenerateClick={() => handleImageButtonClick(0)}
                      isLoading={isGeneratingImages}
                      loadingIndex={loadingImageIndex}
                      currentIndex={0}
                      userId={user.uid}
                      onImageUpdate={(url) =>
                        setLessonData((prev) => ({ ...prev, imageUrl: url }))
                      }
                      theme={theme}
                      showSnackbar={showSnackbar}
                    />
                  </Box>
                </>
              )}

              {lessonData?.timelinesSimple && (
                <Box
                  ref={timelineSimpleSectionRef}
                  sx={{ mt: 5, mb: 5, position: "relative" }}
                >
                  <TimelineSimpleDisplay
                    timelineData={lessonData.timelinesSimple}
                    onTimelineDataChange={(newData) =>
                      setLessonData({ ...lessonData, timelinesSimple: newData })
                    }
                    theme={theme}
                    styles={styles}
                    viewMode={viewMode}
                    user={user}
                  />
                </Box>
              )}

              {/* Add this where you want to display the timeline */}
              {lessonData?.timelines && (
                <Box
                  ref={timelineSectionRef}
                  sx={{ mt: 5, mb: 5, position: "relative" }}
                >
                  <TimelineDisplay
                    timelineData={lessonData.timelines}
                    onTimelineDataChange={(newData) =>
                      setLessonData({ ...lessonData, timelines: newData })
                    }
                    theme={theme}
                    styles={styles}
                    withImages={true}
                    viewMode={viewMode}
                    user={user}
                    onDelete={
                      viewMode
                        ? undefined
                        : (indexToDelete) => {
                            setLessonData((prev) => ({
                              ...prev,
                              timelines: prev.timelines.filter(
                                (_, index) => index !== indexToDelete,
                              ),
                            }));
                            showSnackbar(
                              "Tidslinjen har tagits bort",
                              "success",
                            );
                          }
                    }
                  />
                </Box>
              )}
              {lessonData?.exercises && (
                <Box ref={exercisesSectionRef}>
                  <LessonExercisesDisplay
                    exercises={lessonData.exercises}
                    onUpdateLessonData={setLessonData}
                    viewMode={viewMode}
                    theme={theme}
                    styles={styles}
                  />
                </Box>
              )}
              {lessonData?.quiz && (
                <Box
                  ref={quizSectionRef}
                  sx={{ mt: 5, mb: 5, position: "relative" }}
                >
                  <LessonQuizDisplay
                    quizData={lessonData.quiz}
                    theme={theme}
                    styles={styles}
                    userId={user.uid}
                    viewMode={viewMode}
                    setLessonData={setLessonData}
                  />
                </Box>
              )}
              {!viewMode && (
                <DashedPapers
                  handleQuizPaperClick={handleQuizPaperClick}
                  handleComprehensionPaperClick={handleComprehensionPaperClick}
                  handleTimelinePaperClick={handleTimelinePaperClick}
                  handleTimelineSimplePaperClick={
                    handleTimelineSimplePaperClick
                  }
                  quizSectionRef={quizSectionRef}
                  timelineSectionRef={timelineSectionRef}
                  timelineSimpleSectionRef={timelineSimpleSectionRef}
                  exercisesSectionRef={exercisesSectionRef}
                  lessonData={lessonData}
                  setLessonData={setLessonData}
                />
              )}

              <SaveLessonDialog
                key={lessonData?.lektionsplan.titel}
                open={saveDialogOpen}
                onClose={() => setSaveDialogOpen(false)}
                onSave={handleSaveWithComment}
                theme={theme}
                lessonName={lessonData?.lektionsplan.titel || ""}
                lessonObject={lessonData}
                lessonType={"LessonPlanRevamp.js"}
              />
              <ImageGenerationModal
                open={showImageModal}
                onClose={handleCloseModal}
                onGenerate={handleGenerateWithInstructions}
                isGenerating={isGeneratingImages}
                instructions={imageInstructions}
                setInstructions={setImageInstructions}
                theme={theme}
              />
            </Box>

            <SlideInPaper
              isExpanded={isExpanded}
              onExpandedChange={setIsExpanded}
              lessonTitleRef={lessonTitleRef}
              viewMode={viewMode}
              onEditLessonPlan={() => setEditorOpen(true)}
              toggleViewMode={toggleViewMode}
              onEdit={(e) => {
                e.stopPropagation();
                setIsEditMode(!isEditMode);
                setIsExpanded(false);
              }}
              theme={theme}
              open={showControlPanel}
              user={user}
              onShareLink={() => shareLinkButtonRef.current?.click()}
              onShareWithStudents={() => setShareDialogOpen(true)}
              onShareWithFriends={() => setShareDialogOpenFriends(true)}
              onSaveToBank={() => setSaveDialogOpen(true)}
              onStudentMode={() => {
                window.scrollTo({ top: 0, behavior: "smooth" });
                toggleViewMode();
              }}
              onGenerateExercises={() => {
                comprehensionButtonRef.current?.click();
              }}
              onGenerateQuiz={() => {
                quizButtonRef.current?.click();
              }}
              onGenerateAssignments={() => {
                comprehensionButtonRef.current?.click();
              }}
              onGenerateTimeline={() => {
                timelineButtonRef.current?.click();
              }}
            />

            <ShareDialog
              open={shareDialogOpen}
              onClose={() => setShareDialogOpen(false)}
              onShare={handleShare}
              user={user}
              isLessonSharing={true}
              theme={theme}
              lessonName={lessonData?.lektionsplan.titel || ""}
              lessonObject={lessonData}
              lessonType={"LessonPlanRevamp.js"}
            />

            <ShareDialog
              open={shareDialogOpenFriends}
              onClose={() => setShareDialogOpenFriends(false)}
              onShare={handleShare}
              user={user}
              isLessonSharing={true}
              theme={theme}
              lessonName={lessonData?.lektionsplan.titel || ""}
              lessonObject={lessonData}
              lessonType={"LessonPlanRevamp.js"}
              shareWithFriends={true}
            />

            <LoadingBackdrop
              isOpen={isSharing}
              imagePath="/img/AI.png"
              loadingText={"Delar lektionsplan..."}
            />
          </>
        )}
      </Box>
    </Box>
  );
};

export default LessonPlanRevamp;
