import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  Tooltip,
  FormControl,
  Select,
  MenuItem,
  Grid,
  TextField,
  FormControlLabel,
  Switch,
  Paper,
  Divider,
} from "@mui/material";

import GenerateIcon from "@mui/icons-material/AutoAwesome";

import PrintIcon from "@mui/icons-material/Print";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import HistoryIcon from "@mui/icons-material/History";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AddonTimelineSimple from "./AddonTimelineSimple";
import Translate from "./Translate";
import SlideInPaper from "./SlideInPaper";
import TimelineSimpleDisplay from "./TimelineSimpleDisplay";
import TimelineDisplay from "./TimelineDisplay";
import ShareLink from "./ShareLink";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import AddonTimeline from "./AddonTimeline";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import { useParams } from "react-router-dom";
import DateRangeIcon from "@mui/icons-material/DateRange";
import PersonIcon from "@mui/icons-material/Person";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import AddIcon from "@mui/icons-material/Add";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import LessonQuizDisplay from "./LessonQuizDisplay";
import AddonQuiz from "./AddonQuiz";
import ReadingComprehensionDialog from "./ReadingComprehensionDialog";
import DashedPapers from "./DashedPapers";
import LessonExercisesDisplay from "./LessonExercisesDisplay";
import ImageIcon from "@mui/icons-material/Image";
import ImageGenerationModal from "./ImageGenerationModal";
import HoverableImage from "./HoverableImage";
import ImageLoadingDialog from "./ImageLoadingDialog";
import PrintAsPdf from "./PrintAsPdf";
import { callApi } from "./api";
import { db } from "./firebase";
import {
  collection,
  query,
  where,
  limit,
  addDoc,
  updateDoc,
  getDocs,
  serverTimestamp,
} from "firebase/firestore";

import Logger from "./Logger";
import LanguageSelector from "./LanguageSelector";

import { getThemes } from "./additionalThemes/DiaryLesson";
import { ThemeSelectorFlow } from "./ThemeSelectorFlow";
import InfoBox from "./InfoBox";
import { handlePrintDiaryLesson } from "./printFunctions";

import { generateAndProcessImage } from "./functions";
import ShareDialog from "./ShareDialog";
import ShareIcon from "@mui/icons-material/Share";
import LoadingBackdrop from "./LoadingBackdrop";
import { useSnackbar } from "./SnackbarHandler";
import ProgressBar from "./ProgressBar";
import LessonDrawer from "./LessonDrawer";
import SaveLessonDialog from "./SaveLessonDialog";
import EditIcon from "@mui/icons-material/Edit";
import { DashedPaper } from "../StyledComponents";
import { getStyles } from "../index";
import { trackLesson, handleImageGenerationError } from "./functions";
import GradeSelector, { gradeInstructions } from "./GradeSelector";
import { useTheme } from "@mui/material/styles";
import { useSearchParams } from "react-router-dom";

import DiaryContent from "./DiaryContent";

const DiaryLesson = ({ user }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);
  const lessonTitleRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageInstructions, setImageInstructions] = useState("");
  const [isGeneratingImages, setIsGeneratingImages] = useState(false);
  const [loadingImageIndex, setLoadingImageIndex] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [isSharing, setIsSharing] = useState(false);
  const quizSectionRef = useRef(null);
  const exercisesSectionRef = useRef(null);
  const timelineSectionRef = useRef(null);
  const timelineSimpleSectionRef = useRef(null);
  const [shareDialogOpenFriends, setShareDialogOpenFriends] = useState(false);
  const translateButtonRef = useRef(null);
  const pdfButtonRef = useRef(null);
  const shareLinkButtonRef = useRef(null);
  const [isExpanded, setIsExpanded] = useState(false);
  // Basic settings
  const [lessonTheme, setLessonTheme] = useState("");
  const [language, setLanguage] = useState("svenska");
  const [grade, setGrade] = useState("åk9");
  const [numOfDiaryEntries, setNumOfDiaryEntries] = useState(5);
  const [diaryEntryLength, setDiaryEntryLength] = useState("Långa");
  const timelineSimpleButtonRef = useRef(null);
  const urlContentRef = useRef("");
  // Generation states
  const [showControlPanel, setShowControlPanel] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isGeneratingLesson, setIsGeneratingLesson] = useState(false);
  const [lessonData, setLessonData] = useState("");
  // const [lessonData, setLessonData] = useState({
  //   title: "Dagbok från ett östtyskt hjärta: Berlinmurens fall 1989",
  //   quiz: [
  //     {
  //       question:
  //         "Vad var den centrala känslan i dagboksinläggen från november 1989?",
  //       options: ["Rädsla", "Glädje", "Osäkerhet", "Frihet"],
  //       hints: [
  //         "Många människor kände en blandning av olika känslor efter murens fall.",
  //         "Det fanns en oro över framtiden och hur livet skulle förändras.",
  //         "Författaren reflekterade över både glädje och sorg i sin nya verklighet.",
  //       ],
  //       correct: "Osäkerhet",
  //     },
  //     {
  //       hints: [
  //         "Det var en händelse som många hade drömt om i många år.",
  //         "Det var ett ögonblick av stor symbolik och förändring.",
  //         "Många människor samlades för att fira denna historiska händelse.",
  //       ],
  //       options: [
  //         "En stor demonstration",
  //         "Muren föll",
  //         "En ny regering bildades",
  //         "Folket röstade",
  //       ],
  //       correct: "Muren föll",
  //       question: "Vilket viktigt evenemang skedde den 9 november 1989?",
  //     },
  //     {
  //       question:
  //         "Hur kände dagboksförfattaren när de besökte Västberlin för första gången?",
  //       correct: "Förvirrad",
  //       hints: [
  //         "Författaren upplevde en stark känsla av kontrast mellan öst och väst.",
  //         "Trots de nya upplevelserna fanns det en känsla av saknad.",
  //         "Det kändes som att kliva in i en annan värld.",
  //       ],
  //       options: ["Lycklig", "Förvirrad", "Skrämd", "Besviken"],
  //     },
  //     {
  //       options: [
  //         "Resor till väst",
  //         "Repressalier",
  //         "Demonstrationer",
  //         "Politisk aktivism",
  //       ],
  //       question:
  //         "Vad diskuterade författaren med sina vänner efter murens fall?",
  //       correct: "Politisk aktivism",
  //       hints: [
  //         "Det fanns en önskan att skapa förändring i samhället.",
  //         "Möten och diskussioner blev viktiga i den nya verkligheten.",
  //         "Det fanns en känsla av att vara delaktig i något större.",
  //       ],
  //     },
  //     {
  //       hints: [
  //         "Människor samlades för att uttrycka sina önskningar om demokrati.",
  //         "Det var en känsla av gemenskap bland demonstranterna.",
  //         "Trots oroligheterna fanns det också hopp och glädje.",
  //       ],
  //       options: ["Rädsla", "Enighet", "Magisk stämning", "Desperation"],
  //       question:
  //         "Vilken känsla genomsyrade demonstrationerna efter murens fall?",
  //       correct: "Magisk stämning",
  //     },
  //   ],
  //   diaryEntries: [
  //     {
  //       date: "1989-11-01",
  //       content:
  //         "Kära dagbok, idag har jag vaknat med en känsla av oro. Det har viskats om förändringar i luften, och rykten har spridit sig som en löpeld. Människor på gatorna pratar om att de vill ha mer frihet, men jag är rädd för vad det kan innebära. Jag har alltid lärt mig att lita på staten, men nu börjar jag ifrågasätta allt. Jag ser på mina grannar, som alltid har varit så tysta, och jag undrar vad de verkligen tänker. Kanske är det dags för oss att börja tala ut.",
  //       imageUrl:
  //         "https://storage.googleapis.com/studeraai.appspot.com/generated-images/Z6BLSMsm38Un5mgW0O6Z3TToHq12/1736010525432.png?GoogleAccessId=firebase-adminsdk-b1cmg%40studeraai.iam.gserviceaccount.com&Expires=16730319600&Signature=Pu9qQo0lpWwn5ZgvUmxsh6aPdkDnsghjA5qFc70MBDlJo%2F3QbkoL%2BYqYS71lI%2Bse%2BwgM9YmwpGgdoHGiaFID4a5Dck2keE5I%2BJ7rXsHCuM%2FJytjOK%2FgTkpBfXAr6uzk%2FJ3RLiSKrQ2wXgdJPK%2BaP9i7H8jC4cCyM2MsAbhiXwkuMFIHKFwCeXDB8foslZQ4jOc%2BX6ztSZ98WziLQges8obeLdAyEecDyRRkCDa9Q4rN9H7e3TGCXNNiG6tygCIbiHXda7JhNzQxOkFos48SQ35tM5k9dCRpV1x8bbb5l6%2BBHqFQIVFiWRpcyIvOtTMYKYDcAOlV2JyV0hxTYBzUEIw%3D%3D",
  //     },
  //     {
  //       imageUrl:
  //         "https://storage.googleapis.com/studeraai.appspot.com/generated-images/Z6BLSMsm38Un5mgW0O6Z3TToHq12/1736010602156.png?GoogleAccessId=firebase-adminsdk-b1cmg%40studeraai.iam.gserviceaccount.com&Expires=16730319600&Signature=MkCq19eRf42sk1oSufaIwnafCDSfA2cmmD5f6xpIbNq2IITmXUsAate%2FkgMs1SYvtedexGyqTm376tkyW3gBBugQQq4J2uztrYmzK3aimpeYRLBMhhkV%2BGAOBJeLzsRjwoPLqNFzf29jF9g9EUARYtRRYS6KxMX%2FfZOSXtSMCRLVhzgXiRYgWMmuSnk8zjmUuKgS0F%2F%2Fs%2B9HnUuN9sYnE0UCMI%2BkRgzeYaV44M1c%2F4CQw8oXNgeEWFDegbn9IdLrxPfv6p75kjujBBv8FMoRHt3ekZZmtuJwmEpMzj5umvxzf1%2By3%2F2nh78Z2kXJWdSuWqP%2FRCrgWF%2FsvlLE6ZIRRg%3D%3D",
  //       content:
  //         "Idag har jag sett något som jag aldrig trodde jag skulle få uppleva. På gatan utanför har människor samlats för att protestera. De har plakat och ropar för frihet och förändring. Jag stod där, gömd bakom ett hörn, med hjärtat bultande i bröstet. Tanken på att gå ut och delta skrämmer mig, men samtidigt känns det som att jag inte kan stå tyst längre. De säger att det är möjligt att muren kan falla, att vi kan få se våra släktingar i väst igen. Jag vill tro på det, men rädslan för repressalier är så stor.",
  //       date: "1989-11-05",
  //     },
  //     {
  //       imageUrl:
  //         "https://storage.googleapis.com/studeraai.appspot.com/generated-images/Z6BLSMsm38Un5mgW0O6Z3TToHq12/1736010597023.png?GoogleAccessId=firebase-adminsdk-b1cmg%40studeraai.iam.gserviceaccount.com&Expires=16730319600&Signature=aVr44DgPlT9K26TlO%2Br3Di6zFQo4q%2FPITsyM4dtiT0%2FMz8n3dpZWnFlfbV2O3BAozpk24dfT4tSMYTNqiWC6xAxXgKQ5uRHYi9O2ZuRj8onRvzdtLvpgJdsfIIL3SBoqSUzxQcnA3kh85LuTK8lnBa69XuGwprcDEH%2Ft3ts20pudGVT9GipdZEJUDufECkO%2BUmysjqaaPcaXX%2FCxpmcOnpnkxM2f5SOegERBmGI1fa7j9aK4yAhsJBnOl3Zlv9sSSgcgavmFRtBgZRV%2B%2FNM26imCGkei67WUJSpoE3WNImrOXVFfOai2L1K8n40qIFMUgthwk7KyY11L%2F48Ni8UEqw%3D%3D",
  //       content:
  //         "Vilken dag det har varit! Jag kan fortfarande inte förstå vad som just har hänt. Nyheterna kom som en chock: Muren ska öppnas. Jag kan knappt tänka klart. Jag och mina vänner bestämde oss för att gå till muren, för att se med egna ögon. Stämningen var elektrisk, människor kramade varandra, grät och skrattade. Det var som om vi alla var en del av en stor dröm. När jag stod där och såg hur folk klättrade över muren, kände jag hur tårarna började rinna. Jag har drömt om detta ögonblick i så många år.",
  //       date: "1989-11-09",
  //     },
  //     {
  //       content:
  //         "Igår var jag på andra sidan, i Västberlin, för första gången. Det var som att kliva in i en annan värld. Jag såg skyltfönster fulla av kläder och leksaker, allt var så färgglatt och livligt. Jag kände mig som en turist i mitt eget liv. Men mitt hjärta kändes tungt. Jag saknade mina vänner som fortfarande är kvar i Östberlin, och jag vet att allt inte är perfekt där borta. Jag har hört berättelser om hur svåra det kan vara att anpassa sig till den nya verkligheten. Det gör mig ledsen att tänka på alla som kanske inte kan dela denna glädje.",
  //       date: "1989-11-10",
  //       imageUrl:
  //         "https://storage.googleapis.com/studeraai.appspot.com/generated-images/Z6BLSMsm38Un5mgW0O6Z3TToHq12/1736010608025.png?GoogleAccessId=firebase-adminsdk-b1cmg%40studeraai.iam.gserviceaccount.com&Expires=16730319600&Signature=qQ%2B92IKMWF8BXvQcfaaFO9%2FuF8Id1xNJNsGr5IufkUAC0rxUMlVaf5uRbewROlmFDCm7rjVqSb9d1zltg92MuuujmVIfp3YA1ugEijGLGd02H1ulbwHBTaxYIVfkGc6etY4YhVckMKcD3bqrUydf8gUsLYA7BUNSSz2%2FgVLgBUUdBJ8PIE7aHp98bLJ1Mu%2FBGhroMOQRzSNuUlRaM3v7M75FE9oTF59An0Qto6i0YRk9Cy1pJXo4XOiqehxGLTnA%2Bp47akB9zvFvG9pnVzbHEDVKQdV1%2FBftD0Mi5zYhgYZ1My63xBHbPh86Wv6HJZEQysBB58EeC%2BMueNRevGJQYQ%3D%3D",
  //     },
  //     {
  //       date: "1989-11-15",
  //       content:
  //         "Det har gått en vecka sedan muren föll, men jag kan fortfarande inte smälta allt som har hänt. Det är så många känslor som svävar i luften; glädje, sorg, förvirring. Jag har träffat människor från väst och de är så vänliga. Men det finns också en känsla av osäkerhet. Vad betyder det här för vår framtid? Kommer saker att bli bättre eller kommer vi att förlora vår identitet? Jag har pratat med min familj om att besöka väst igen, men jag är osäker på vad de tänker. Det är så mycket som vi måste bearbeta.",
  //       imageUrl:
  //         "https://storage.googleapis.com/studeraai.appspot.com/generated-images/Z6BLSMsm38Un5mgW0O6Z3TToHq12/1736010602337.png?GoogleAccessId=firebase-adminsdk-b1cmg%40studeraai.iam.gserviceaccount.com&Expires=16730319600&Signature=Y6BruuTTk1yK3hbGuSOSH9Z6WnYLzaAKUlcnMAd9YWfzZyLCR1get2aQNzhRyFOEjSxscuVDYLG%2F1LvbGChq4AkROjZCBx2fv0OI3jcF9gxRM7KlgILZZDpxVmZqqnuTTz582S5Qp5OEcPwtvDllmofrBQ%2B0npOKwDcCpkEvACZABOtohl71qNw2RHRWiq%2FsCSpdzCmabH3wxIeJne17uKrKEwFz6Bb6LZO2cfv4ceuo8s9%2Frs%2BR%2B%2FbPI1dthA5Xa9WW6U%2F%2BC0vmc0yw7nj3Q4B8zaVzFXu3PUIMvKOTeMGE%2Bpivh91OAb7%2Bmq1UtUKWsOGouBceJK719TDOZ8d%2FzQ%3D%3D",
  //     },
  //     {
  //       imageUrl:
  //         "https://storage.googleapis.com/studeraai.appspot.com/generated-images/Z6BLSMsm38Un5mgW0O6Z3TToHq12/1736010600163.png?GoogleAccessId=firebase-adminsdk-b1cmg%40studeraai.iam.gserviceaccount.com&Expires=16730319600&Signature=DQYZ%2FVWuKyDKcyhqy3mOjcEYlD27%2BkCVVn5GBChPGkOdkITkOb%2FYP3ze4wJnQBS%2Fa4iOswAWacsFywxmfGkDlPRJ6T2y2wnc1TP%2FQTvvR5oAbFQjAR%2BYrX8pPuPq%2BoyBC0gjZQFGe%2BS2I3RxFWf86KexsmHM3%2Ffs4PwN5oBmBB4SyGF3OMbzViQohvmVgCc1Hu%2Bm2GKTteDX5oPWqDUKw5jrrumw1BlDM9d69lNxWzqJSoxJ%2FsMh%2FukNuxW%2BTsB%2FnWLM0ODJznWoQu6CzuNUj0XFbqvpS2K8V9qlIVPd12bkX5zwsmsmBT%2BfPYsbDKJiTbCbDbJvFUI%2FekYo1ETefQ%3D%3D",
  //       content:
  //         "Jag har börjat se förändringar i mitt kvarter. Fler och fler människor vågar uttrycka sina åsikter, och det känns som att vi har fått en ny röst. Men samtidigt finns det en del som fortfarande är rädda. Jag träffade en gammal vän idag som berättade om hur hennes familj har det svårt att anpassa sig. Jag förstår henne; det är inte lätt att plötsligt behöva omvärdera allt man trott varit sant. Jag känner att vi är på väg mot något nytt, men jag är inte säker på om vi är redo för det.",
  //       date: "1989-11-20",
  //     },
  //     {
  //       date: "1989-11-25",
  //       content:
  //         "Mina tankar kretsar fortfarande kring framtiden. Jag har sett hur många av mina vänner har blivit aktiva i olika grupper för att skapa förändring, och jag vill också göra något. Jag har börjat delta i möten där vi diskuterar vad vi vill för vår stad och vårt land. Det är så inspirerande att höra andras idéer, men jag är också rädd för att vi kanske inte är enade. Det finns så många olika åsikter om hur vi ska gå framåt. Ibland känns det som om vi är mer splittrade nu än vi var innan.",
  //       imageUrl:
  //         "https://storage.googleapis.com/studeraai.appspot.com/generated-images/Z6BLSMsm38Un5mgW0O6Z3TToHq12/1736010606014.png?GoogleAccessId=firebase-adminsdk-b1cmg%40studeraai.iam.gserviceaccount.com&Expires=16730319600&Signature=Gu6NqKlFl6c9YpoiJhoeaWh9tUDpUtbDfYwO0dkQwqK0TJUCm6UwIZTfmrYR49cE6TUjUV9k6ndE0RyHXkuCjiL2rl3R%2Fm3pDQiK6wINugnYz4bfju004JBIEyEOnyQqTpZ6Uvbg0wtYXlbo7MOZPAu%2FXonqqrMph%2FSviNmkqxkpzyrwbJMzTa5Jy%2BvWEX9i2cKC3dvIpXSlBSXBoTQ8Icf9KE5azSkghyLcwUp19d4%2F7YKtCBMGgv69RWZQDQAbcyUv92GISqIDbcsNTyBnb0EcABANHPMvm9yjx2LBFr%2B%2BfCO1F7uWCcSFpT%2F06Uu7k%2BeZhHNpdycX5F2uO%2FnghQ%3D%3D",
  //     },
  //     {
  //       imageUrl:
  //         "https://storage.googleapis.com/studeraai.appspot.com/generated-images/Z6BLSMsm38Un5mgW0O6Z3TToHq12/1736010607533.png?GoogleAccessId=firebase-adminsdk-b1cmg%40studeraai.iam.gserviceaccount.com&Expires=16730319600&Signature=mp0BvV7eIs9N5%2B2ivJctdmxlMq4hBAoovkc4NUI5CnghNFg1qAuG1Tfy84BDRJRrQVyKYelRalLuDpw%2FoapYroNpz6lNcpdH1oRzjDAS40%2BnQq32XSZrSTM8gid%2FLrIYdistl8ESXq8gySVL%2FpICqM9AichZ1hZGVTibLG8NDBPe9wRSXlhV%2BUzQmmFql411yC6OwodnYEdoOmCGydIxil4atXOi3TJzcnOfqs3WBohqhoNnR0mCPCxXRK0DIZqHv2NPZO7y3NJyaH1B8dIErXlfAb2QFuT9Jd8ZwLyNRQgjflsQP5UC7kV2isYeOfKazjBIswWdthkbCXv2txewCA%3D%3D",
  //       date: "1989-12-01",
  //       content:
  //         "Det har varit ett par veckor av intensiva förändringar. Idag har jag deltagit i en stor demonstration i centrala Berlin. Det var otroligt att se så många människor samlas för en gemensam sak. Vi ropade för demokrati och frihet, och stämningen var magisk. Men jag kan inte låta bli att tänka på vad som kan hända om vi inte är försiktiga. Det finns fortfarande de som är emot förändringen och som vill återgå till det gamla. Jag har en känsla av att vi bara har sett början på något större.",
  //     },
  //     {
  //       content:
  //         "Jag har nu deltagit i flera möten och demonstrationer. Det känns som om vi bygger en ny framtid, men jag är också medveten om att vägen framåt kommer att vara fylld av utmaningar. Jag ser hur människor kämpar för att återfå sina rättigheter, men jag känner också att vi behöver vara försiktiga med hur vi går fram. Det är så lätt att tappa riktningen när allt förändras så snabbt. Men en sak är säker: vi kommer aldrig att ge upp kampen för vår frihet. Jag är stolt över att vara en del av denna rörelse.",
  //       date: "1989-12-10",
  //       imageUrl:
  //         "https://storage.googleapis.com/studeraai.appspot.com/generated-images/Z6BLSMsm38Un5mgW0O6Z3TToHq12/1736010600118.png?GoogleAccessId=firebase-adminsdk-b1cmg%40studeraai.iam.gserviceaccount.com&Expires=16730319600&Signature=dRsdEpO1fa6ZfdTzMjrt%2BrOcLypcMHPBzmI1QXlQUJiouD2%2B6PW2j5j1Lkp0N%2BxvgMoLE2obCpAehkOU4kYqpzGwg6SvQIKCL8q2CLRpU9f1nXwMKIOQsI8xfmda7rIJmHegZKZrra20eMcrZ2zOxSWUt%2B2199BfLo%2FscL%2FEhscsRqisWrtoJqpOpMap3ajOGfyG3ZTH%2BKUSVh%2Be0lguYlqw8cWaJnqj7ikmCH2McZS6tJm2CeW1eaNguaAkaxPCASRjoCWUZASbMLEv4dDHLhUgteyTN07at7ZkRXXUufwAHnIyvkVSPdS22aJocrXxA2ad5CMQHFkkNJ7Hb7YBOA%3D%3D",
  //     },
  //   ],
  //   timelines: [
  //     {
  //       title: "Viktiga händelser under Berlinmurens fall",
  //       description:
  //         "Denna tidslinje visar fyra avgörande händelser som ledde fram till och följde efter Berlinmurens fall 1989. Arrangera dem i kronologisk ordning.",
  //       timeline: [
  //         {
  //           event:
  //             "Människor i Östtyskland började organisera stora demonstrationer för frihet och reformer.",
  //           year: 1989,
  //           imageUrl: null,
  //         },
  //         {
  //           event:
  //             "Den 9 november 1989 föll Berlinmuren, vilket blev en symbol för slutet på det kalla kriget.",
  //           year: 1989,
  //           imageUrl: null,
  //         },
  //         {
  //           event:
  //             "Många östtyskar besökte Västberlin för första gången och upplevde ett nytt liv.",
  //           year: 1989,
  //           imageUrl: null,
  //         },
  //         {
  //           event:
  //             "Demonstrationer för demokrati och mänskliga rättigheter fortsatte att spridas över hela Östtyskland.",
  //           year: 1990,
  //           imageUrl: null,
  //         },
  //       ],
  //     },
  //   ],
  // });

  // References for content
  const [customTheme, setCustomTheme] = useState("");
  const [predefinedTheme, setPredefinedTheme] = useState(null);
  const subject = searchParams.get("subject") || null;
  const viewMode = searchParams.get("v") === "t";
  const additionalThemes = getThemes(subject);
  const { showSnackbar } = useSnackbar();
  const theme = useTheme();
  const styles = getStyles(theme);

  const transcriptRef = useRef("");
  const uploadedFileRef = useRef("");
  const bookContentRef = useRef("");
  const wikipediaContentRef = useRef("");
  const randomizedContentRef = useRef("");
  const coursePlanContentRef = useRef("");

  // Add refs for the quiz and comprehension buttons
  const quizButtonRef = useRef(null);
  const timelineButtonRef = useRef(null);
  const comprehensionButtonRef = useRef(null);

  // Add click handlers for the DashedPaper components

  const handleTimelinePaperClick = () => {
    timelineButtonRef.current?.click();
  };

  const handleTimelineSimplePaperClick = () => {
    timelineSimpleButtonRef.current?.click();
  };

  const handleQuizPaperClick = () => {
    quizButtonRef.current?.click();
  };

  const handleComprehensionPaperClick = () => {
    comprehensionButtonRef.current?.click();
  };

  const { lessonId } = useParams();

  useEffect(() => {
    Logger.log("lessonData: ", JSON.stringify(lessonData, null, 2));
  }, [lessonData]);

  useEffect(() => {
    const loadLessonFromId = async () => {
      if (lessonId) {
        try {
          const q = query(
            collection(db, "lesson_usage"),
            where("__name__", ">=", lessonId),
            where("__name__", "<=", lessonId + "\uf8ff"),
            where("lessonType", "==", "DiaryLesson.js"),
            limit(1),
          );

          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            const docData = querySnapshot.docs[0].data();

            if (!docData.hasOpened) {
              await updateDoc(querySnapshot.docs[0].ref, {
                hasOpened: true,
                openedAt: Date.now(),
              });
            }

            setLessonData(docData.data);

            // Scroll to lesson content
            setTimeout(() => {
              lessonTitleRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }, 100);

            showSnackbar("Lektionen har laddats in.", "success");
          } else {
            showSnackbar("Kunde inte hitta lektionen", "error");
          }
        } catch (error) {
          console.error("Error loading lesson:", error);
          showSnackbar("Ett fel uppstod när lektionen skulle laddas", "error");
        }
      }
    };

    loadLessonFromId();
  }, lessonId);

  useEffect(() => {
    Logger.log("lessonData: ", lessonData);
    const isDataValid =
      lessonData !== null &&
      lessonData !== undefined &&
      Object.keys(lessonData).length > 0 &&
      !Object.values(lessonData).includes("");

    if (isDataValid !== showControlPanel) {
      const timer = setTimeout(() => {
        setShowControlPanel(isDataValid);
      }, 100);

      return () => clearTimeout(timer);
    }
  }, [lessonData, showControlPanel]);

  const handleShare = async (
    lessonComment,
    lessonName,
    selectedImage,
    tags,
    shareData,
  ) => {
    setIsSharing(true);
    await new Promise((resolve) => setTimeout(resolve, 1500));
    // try {
    //   // Create a document for each student
    //   const sharePromises = shareData.studentIds.map((studentId) => {
    //     const documentData = {
    //       userId: user.uid,
    //       shared: true,
    //       sharedTo: studentId,
    //       comment: shareData.comment,
    //       sharedAt: Date.now(),
    //       fileUrl: shareData.fileUrl,
    //       lessonType: "DiaryLesson.js",
    //       timestamp: serverTimestamp(),
    //       lessonComment,
    //       lessonName,
    //       coverImage: selectedImage,
    //       hasPrinted: true,
    //       data: lessonData,
    //     };
    //     Logger.log(
    //       "Adding document for student",
    //       studentId,
    //       ":",
    //       JSON.stringify(documentData, null, 2),
    //     );
    //     return addDoc(collection(db, "lesson_usage"), documentData);
    //   });
    //   await Promise.all(sharePromises);
    //   showSnackbar(`Du har nu delat lektionen!`, "success");
    // }
    try {
      // Create a document for each student and then create notifications with the doc IDs
      const sharePromises = shareData.studentIds.map(async (studentId) => {
        const documentData = {
          userId: user.uid,
          shared: true,
          sharedTo: studentId,
          comment: shareData.comment,
          sharedAt: Date.now(),
          fileUrl: shareData.fileUrl,
          lessonType: "DiaryLesson.js",
          timestamp: serverTimestamp(),
          lessonComment,
          lessonName,
          coverImage: selectedImage,
          hasPrinted: true,
          data: lessonData,
        };

        // Create the lesson document and get its ID
        const lessonDoc = await addDoc(
          collection(db, "lesson_usage"),
          documentData,
        );

        Logger.log(
          "Adding document for student",
          studentId,
          ":",
          JSON.stringify(documentData, null, 2),
        );

        // Create notification with the lesson doc ID
        await addDoc(collection(db, "notifications"), {
          type: "lesson_shared",
          recipientId: studentId,
          senderId: user.uid,
          read: false,
          createdAt: serverTimestamp(),
          lessonData: {
            lessonId: lessonDoc.id,
            lessonType: documentData.lessonType,
            lessonName: documentData.lessonName,
            fileUrl: documentData.fileUrl,
            comment: documentData.comment,
            coverImage: selectedImage,
          },
        });

        return lessonDoc;
      });

      await Promise.all(sharePromises);
      showSnackbar(`Du har nu delat lektionen!`, "success");
    } catch (error) {
      showSnackbar("Fel vid delande av lektionen: " + error.message, "error");
      console.error(error);
    } finally {
      setIsSharing(false);
    }
  };

  const handleSaveLessonClick = () => {
    setSaveDialogOpen(true);
  };

  const handleSaveWithComment = async (comment, name, coverImage, tags) => {
    await trackLesson(
      user.uid,
      "DiaryLesson.js",
      lessonData,
      null,
      true,
      comment,
      name,
      coverImage,
      tags,
    );
    setDrawerOpen(true);
    showSnackbar("Lektionen sparad i din lektionsbank!", "success");
  };

  const toggleViewMode = () => {
    const currentParams = Object.fromEntries(searchParams.entries());
    if (currentParams.v === "t") {
      delete currentParams.v;
    } else {
      currentParams.v = "t";
    }
    setSearchParams(currentParams);
  };

  const simulateProgress = (startProgress, endProgress, duration) => {
    const stepSize = 0.5;
    const steps = (endProgress - startProgress) / stepSize;
    const stepDuration = duration / steps;
    let currentProgress = startProgress;

    const intervalId = setInterval(() => {
      currentProgress += stepSize;
      if (currentProgress >= endProgress) {
        clearInterval(intervalId);
      } else {
        setProgress(currentProgress);
      }
    }, stepDuration);

    return () => clearInterval(intervalId);
  };

  const handleGenerateLesson = async () => {
    setProgress(0);
    let stopProgressSimulation = null;
    setIsGeneratingLesson(true);
    const gradeInstructionsText = gradeInstructions[grade];
    setLessonData(null);

    try {
      let themeToUse;

      if (lessonTheme === "youtube") {
        themeToUse = transcriptRef.current;
      } else if (lessonTheme === "customTheme") {
        themeToUse = customTheme;
      } else if (lessonTheme === "predefined") {
        themeToUse = predefinedTheme;
      } else if (lessonTheme === "fileUpload") {
        themeToUse = uploadedFileRef.current;
      } else if (lessonTheme === "wikipedia") {
        themeToUse = wikipediaContentRef.current;
      } else if (lessonTheme === "randomizer") {
        themeToUse = randomizedContentRef.current;
      } else if (lessonTheme === "book") {
        themeToUse = bookContentRef.current;
      } else if (lessonTheme === "url") {
        themeToUse = urlContentRef.current;
      } else {
        themeToUse = coursePlanContentRef.current;
      }

      stopProgressSimulation = simulateProgress(0, 90, 15000);

      let response = await callApi(
        [
          {
            role: "system",
            content: `You are an expert historian and creative writer specializing in creating authentic-feeling diary entries in ${language}. 
            Create a series of fictitious diary entries that feel personal and engaging while maintaining historical accuracy. Follow the exact JSON structure provided in the prompt.`,
          },
          {
            role: "user",
            content: `
            - Theme: "${themeToUse}"
            - Grade: "${gradeInstructionsText}"
            - Amount of diary entries: Exactly ${numOfDiaryEntries} diary entries
            - Length of each diary entry: ${diaryEntryLength}
            - Language: ${language}


            RESPOND IN THIS EXACT JSON FORMAT:
            {
              "title": "title of diary",
              "diaryEntries": [
                {
                  "date": "YYYY-MM-DD",
                  "content": "string",
                }
              ],
            }
            `,
          },
        ],
        6000,
        true,
        user.uid,
      );

      if (stopProgressSimulation) stopProgressSimulation();
      setProgress(95);

      let messageContent = response.data.choices[0].message.content.trim();
      if (messageContent.startsWith("```json")) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith("```")) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      const data = JSON.parse(messageContent);
      setProgress(100);
      setLessonData({
        ...data,
        diaryEntries: data.diaryEntries.map((entry) => ({
          ...entry,
          imageUrl: null,
        })),
      });

      Logger.log("response: ", JSON.stringify(lessonData, null, 2));

      await trackLesson(user.uid, "DiaryLesson.js", null, {
        language,
        lessonTheme,
      });

      setTimeout(() => {
        lessonTitleRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, 100);

      showSnackbar("Din lektion har genererats!", "success");
    } catch (error) {
      console.error("Error generating lesson:", error);
      showSnackbar(
        "Ett fel uppstod när lektionen skulle genereras. Försök igen!",
        "error",
      );
    } finally {
      setIsGeneratingLesson(false);
    }
  };

  const handleGenerateImages = async () => {
    if (!lessonData) return;
    setIsGeneratingImages(true);
    handleCloseModal();

    try {
      const imagePromises = lessonData.diaryEntries.map((entry) => {
        const prompt = `Create a photorealistic image depicting a scene from ${entry.date}, based on this: ${entry.content.slice(0, 200)}. ${imageInstructions}`;
        return generateAndProcessImage(prompt, user.uid);
      });

      const imageUrls = await Promise.all(imagePromises);

      setLessonData((prevData) => ({
        ...prevData,
        diaryEntries: prevData.diaryEntries.map((entry, index) => ({
          ...entry,
          imageUrl: imageUrls[index],
        })),
      }));

      showSnackbar("Bilderna har genererats!", "success");
    } catch (error) {
      handleImageGenerationError(error, true, showSnackbar);
    } finally {
      setIsGeneratingImages(false);
      setLoadingImageIndex(null);
    }
  };

  const handleGenerateSpecificImage = async (index) => {
    if (!lessonData?.diaryEntries[index]) return;

    setIsGeneratingImages(true);
    setLoadingImageIndex(index);
    handleCloseModal();

    try {
      const entry = lessonData.diaryEntries[index];
      const prompt = `Create a photorealistic image depicting a scene from this: ${entry.content.slice(0, 200)}. ${imageInstructions}`;

      const imageUrl = await generateAndProcessImage(prompt, user.uid);

      if (imageUrl) {
        setLessonData((prevData) => ({
          ...prevData,
          diaryEntries: prevData.diaryEntries.map((entry, i) =>
            i === index ? { ...entry, imageUrl } : entry,
          ),
        }));
        showSnackbar("Bilden har genererats!", "success");
      }
    } catch (error) {
      handleImageGenerationError(error, false, showSnackbar);
    } finally {
      setIsGeneratingImages(false);
      setLoadingImageIndex(null);
    }
  };

  const handleImageButtonClick = (index = null) => {
    setSelectedImageIndex(index);
    setShowImageModal(true);
  };

  const handleCloseModal = () => {
    setShowImageModal(false);
    setImageInstructions("");
  };

  const handleGenerateWithInstructions = async () => {
    if (selectedImageIndex !== null) {
      handleCloseModal();
      await handleGenerateSpecificImage(selectedImageIndex);
    } else {
      handleCloseModal();
      await handleGenerateImages();
    }
  };

  return (
    <Box sx={styles.container}>
      <Box
        sx={{
          ...styles.innerContainer,
          width: { xs: "100%", md: "100%" },
          margin: "0 auto",
        }}
      >
        {!viewMode && (
          <Typography
            variant="h1"
            gutterBottom
            sx={{
              fontWeight: "bold",
              fontSize: "1.8em",
              mb: 3,
              mt: 5,
              textAlign: "center",
            }}
          >
            Fiktiva dagböcker
          </Typography>
        )}
        <Box
          sx={{
            ...styles.buttonsContainer,
            ...styles.additionalButtonsContainer,
          }}
        >
          {lessonData && user.accountType === "teacher" && (
            <Button
              variant="purp"
              sx={{ ...styles.tightButton }}
              disableRipple
              onClick={() => {
                setTimeout(() => {
                  lessonTitleRef.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "start", // This ensures it scrolls to the top of the element
                  });
                }, 100);
                toggleViewMode();
              }}
              startIcon={
                searchParams.get("v") === "t" ? (
                  <FullscreenExitIcon />
                ) : (
                  <FullscreenIcon />
                )
              }
            >
              {searchParams.get("v") === "t" ? "Redigeringsläge" : "Elevläge"}
            </Button>
          )}

          {!viewMode && (
            <Button
              variant="purp"
              sx={{ ...styles.tightButton }}
              onClick={() => setDrawerOpen(true)}
              startIcon={<BookmarkBorderIcon />}
            >
              Sparat
            </Button>
          )}
        </Box>

        <LessonDrawer
          userId={user.uid}
          lessonType={"DiaryLesson.js"}
          theme={theme}
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          lessonIcon={NewspaperIcon}
          onSelectLesson={(lesson) => {
            setLessonData(lesson.data);
            setDrawerOpen(false);
            setTimeout(() => {
              lessonTitleRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "start", // This ensures it scrolls to the top of the element
              });
            }, 100);
            showSnackbar(`Lektionen har laddats in.`, "success");
          }}
        />

        {!viewMode && (
          <>
            <InfoBox
              icon={AutoStoriesIcon}
              title="Fiktiva dagböcker"
              instructions={[
                "Generera fiktiva dagboksinlägg från t.ex. en historisk händelse eller historisk person",
                "Redigera, generera bilder, lägg till övningar efter eget behov",
                "Dela med elever, spara eller skriv ut",
              ]}
              theme={theme}
              styles={styles}
            />

            <ThemeSelectorFlow
              key={subject}
              subject={subject}
              value={lessonTheme}
              onChange={(e) => setLessonTheme(e.target.value)}
              customTheme={customTheme}
              onCustomThemeChange={(e) => setCustomTheme(e.target.value)}
              onPredefinedThemeChange={(e) =>
                setPredefinedTheme(e.target.value)
              }
              onUrlContentChange={(content) => {
                Logger.log(
                  "Parent: URL content received",
                  content.substring(0, 300),
                );
                urlContentRef.current = content;
              }}
              onBookContentChange={(content) => {
                bookContentRef.current = content;
              }}
              onYoutubeTranscriptChange={(transcriptContent) => {
                transcriptRef.current = transcriptContent;
              }}
              onFileUpload={(content) => {
                uploadedFileRef.current = content;
              }}
              onWikipediaContentChange={(content) => {
                wikipediaContentRef.current = content;
              }}
              onRandomizedContentChange={(content) => {
                randomizedContentRef.current = content;
              }}
              onCoursePlanContentChange={(content) => {
                coursePlanContentRef.current = content;
              }}
              lessonType={"DiaryLesson.js"}
              user={user}
              theme={theme}
              styles={styles}
              onLessonSelect={(lesson) => {
                Logger.log("onLessonSelect körs");
                setLessonData(lesson.data);
                setTimeout(() => {
                  lessonTitleRef.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "start", // This ensures it scrolls to the top of the element
                  });
                }, 100);
                showSnackbar("Lektionen har laddats in.", "success");
              }}
              additionalThemes={additionalThemes}
            />

            <Box sx={styles.headlineLessonBox}>
              <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
                2. Generera lektionen
              </Typography>
            </Box>

            <Box sx={{ ...styles.freshBox, padding: "40px" }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "500", mb: 1 }}
                  >
                    Årskurs:
                  </Typography>
                  <GradeSelector grade={grade} setGrade={setGrade} />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "500", mb: 1 }}
                  >
                    Språk:
                  </Typography>
                  <LanguageSelector
                    language={language}
                    setLanguage={setLanguage}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <Typography
                      variant="formLabelSinUnderline"
                      sx={{ fontWeight: "500", mt: 1 }}
                    >
                      Antal dagboksinlägg
                    </Typography>
                    <Select
                      labelId="num-questions-label"
                      id="num-questions-select"
                      value={numOfDiaryEntries}
                      sx={{ mt: 1 }}
                      onChange={(e) => setNumOfDiaryEntries(e.target.value)}
                    >
                      <MenuItem value={2} selected>
                        2
                      </MenuItem>
                      <MenuItem value={5}>5</MenuItem>
                      {user.admin && <MenuItem value={10}>10</MenuItem>}
                      <Tooltip title="Endast tillgängligt för premium" arrow>
                        <span>
                          <MenuItem value={15} disabled>
                            15
                          </MenuItem>
                          <MenuItem value={20} disabled>
                            20
                          </MenuItem>
                          <MenuItem value={30} disabled>
                            30
                          </MenuItem>
                          <MenuItem value={40} disabled>
                            40
                          </MenuItem>
                          <MenuItem value={50} disabled>
                            50
                          </MenuItem>
                        </span>
                      </Tooltip>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl fullWidth sx={{ mt: 1 }}>
                    <Typography
                      variant="formLabelSinUnderline"
                      sx={{ fontWeight: "500" }}
                    >
                      Längd på dagboksinläggen
                    </Typography>
                    <Select
                      sx={{ mt: 1 }}
                      value={diaryEntryLength}
                      onChange={(e) => setDiaryEntryLength(e.target.value)}
                    >
                      <MenuItem value="Korta">Korta</MenuItem>
                      <MenuItem value="Medellånga">Medellånga</MenuItem>
                      <MenuItem value="Långa">Långa</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "12px",
                  width: "100%",
                }}
              >
                <Button
                  variant={
                    theme.palette.mode === "light" ? "purp" : "contained"
                  }
                  color="primary"
                  onClick={handleGenerateLesson}
                  disabled={isGeneratingLesson}
                  sx={{ mt: 7 }}
                  startIcon={
                    !isGeneratingLesson ? (
                      <GenerateIcon style={{ color: "#fff" }} />
                    ) : (
                      <CircularProgress size={16} style={{ color: "#fff" }} />
                    )
                  }
                >
                  {isGeneratingLesson ? "Genererar..." : "Generera lektion"}
                </Button>
              </div>
              {isGeneratingLesson && (
                <Box sx={{ mt: 6 }}>
                  <ProgressBar
                    progress={progress}
                    label="Genererar lektion — tar 30-60 sekunder..."
                  />
                </Box>
              )}
            </Box>
          </>
        )}

        {lessonData && (
          <>
            {!viewMode && (
              <Box
                ref={lessonTitleRef}
                sx={{ ...styles.headlineLessonBox, mt: 10 }}
              >
                <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
                  3. Din lektion
                </Typography>
              </Box>
            )}
            <Box
              sx={{
                ...styles.freshBox,
                padding: { xs: "0px", md: "30px" },

                width: {
                  xs: "100%", // Full width on mobile (extra small devices)
                  sm: viewMode ? "70%" : undefined, // Original width from sm breakpoint up
                },
                margin: viewMode ? "0 auto" : undefined,
                marginTop: viewMode ? "100px" : undefined,
              }}
            >
              {!viewMode && (
                <Box sx={{ position: "relative" }}>
                  <Box
                    sx={{
                      ...styles.lessonControlPanel,
                      ...styles.lessonControlPanelNuevo,

                      "& .MuiButton-root": {
                        ...styles.controlPanelButtons,
                        ...styles.lessonControlPanelNewButtons,
                      },
                    }}
                  >
                    <Button
                      variant="success"
                      sx={{
                        mt: 2,
                        ml: 2,

                        ...(isEditMode && {
                          color: "green !important",
                          "&.MuiButton-root": {
                            fontWeight: "700 !important",
                          },
                        }),
                      }}
                      onClick={() => setIsEditMode(!isEditMode)}
                      startIcon={isEditMode ? <SaveAsIcon /> : <EditIcon />}
                    >
                      {isEditMode ? "Spara ändringar" : "Redigera dagboken"}
                    </Button>
                    <Button
                      variant="print"
                      startIcon={<SaveAsIcon />}
                      sx={{ mt: 2, ml: 2 }}
                      onClick={handleSaveLessonClick}
                    >
                      Spara i min lektionsbank
                    </Button>
                    <Button
                      variant="print"
                      startIcon={<PrintIcon />}
                      onClick={() => handlePrintDiaryLesson(lessonData)}
                      sx={{ mt: 2, ml: 0 }}
                    >
                      Skriv ut
                    </Button>

                    <PrintAsPdf
                      buttonRef={pdfButtonRef}
                      onPrint={() => handlePrintDiaryLesson(lessonData)}
                      sx={{ ml: 2 }}
                    />
                    <Button
                      variant="success"
                      sx={{}}
                      onClick={() => setShareDialogOpenFriends(true)}
                      startIcon={<ShareIcon />}
                    >
                      Dela med vänner
                    </Button>
                    <Button
                      variant="success"
                      sx={{
                        mt: 2,
                        ml: 2,
                      }}
                      onClick={() => setShareDialogOpen(true)}
                      startIcon={<ShareIcon />}
                    >
                      Dela med elever
                    </Button>

                    <Translate
                      buttonRef={translateButtonRef}
                      lessonData={lessonData}
                      onTranslated={setLessonData}
                      callApi={callApi}
                      user={user}
                      showSnackbar={showSnackbar}
                    />

                    <ShareLink
                      buttonRef={shareLinkButtonRef}
                      key={lessonId}
                      lessonId={lessonId}
                      lessonData={lessonData}
                      user={user}
                      lessonType={"DiaryLesson.js"}
                      onCopy={(message, severity) =>
                        showSnackbar(message, severity)
                      }
                      sx={{ mt: 2 }}
                    />

                    <Tooltip title="Hur lektionen ser ut för eleverna">
                      <Button
                        variant="success"
                        onClick={() => {
                          window.scrollTo({ top: 0, behavior: "smooth" });
                          toggleViewMode();
                        }}
                        sx={{ ml: 2, mt: 2 }}
                        startIcon={
                          searchParams.get("v") === "t" ? (
                            <FullscreenExitIcon />
                          ) : (
                            <FullscreenIcon />
                          )
                        }
                      >
                        {searchParams.get("v") === "t"
                          ? "Redigeringsläge"
                          : "Elevläge"}
                      </Button>
                    </Tooltip>

                    <Box sx={{ display: "inline-block" }}>
                      <AddonQuiz
                        buttonRef={quizButtonRef}
                        lessonData={lessonData}
                        title={lessonData.title}
                        theme={theme}
                        user={user}
                        styles={styles}
                        showSnackbar={showSnackbar}
                        addQuizToLessonObject={(updatedData) => {
                          setLessonData(updatedData);
                          setTimeout(
                            () =>
                              quizSectionRef.current?.scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                              }),
                            100,
                          );
                        }}
                        buttonText="Lägg till interaktivt quiz"
                      />
                    </Box>
                    <Box sx={{ display: "inline-block", ml: 0 }}>
                      <ReadingComprehensionDialog
                        buttonRef={comprehensionButtonRef}
                        key={lessonData?.title}
                        lessonData={lessonData}
                        user={user}
                        theme={theme}
                        styles={styles}
                        buttonText="Lägg till övningar"
                        addExercisesToLessonObject={(updateFunction) => {
                          setLessonData((prevLessonData) => ({
                            ...prevLessonData,
                            exercises: [
                              ...(prevLessonData.exercises || []),
                              ...updateFunction(prevLessonData).exercises,
                            ],
                          }));
                          setTimeout(
                            () =>
                              exercisesSectionRef.current?.scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                              }),
                            100,
                          );
                        }}
                      />
                    </Box>
                    <Box sx={{ display: "inline-block", ml: 0 }}>
                      <AddonTimeline
                        buttonRef={timelineButtonRef}
                        lessonData={lessonData}
                        theme={theme}
                        user={user}
                        styles={styles}
                        showSnackbar={showSnackbar}
                        addTimelineToLessonObject={(updatedData) => {
                          setLessonData(updatedData);
                          setTimeout(
                            () =>
                              timelineSectionRef.current?.scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                              }),
                            100,
                          );
                        }}
                        buttonText="Lägg till interaktiv tidslinje"
                      />
                    </Box>
                    <Box sx={{ display: "inline-block", ml: 0 }}>
                      <AddonTimelineSimple
                        buttonRef={timelineSimpleButtonRef}
                        lessonData={lessonData}
                        theme={theme}
                        user={user}
                        styles={styles}
                        showSnackbar={showSnackbar}
                        addTimelineToLessonObject={(updatedData) => {
                          setLessonData(updatedData);
                          setTimeout(
                            () =>
                              timelineSimpleSectionRef.current?.scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                              }),
                            100,
                          );
                        }}
                        buttonText="Lägg till enkel tidslinje"
                      />
                    </Box>
                    <Button
                      variant={
                        theme.palette.mode === "light" ? "purp" : "contained"
                      }
                      color="primary"
                      onClick={() => handleImageButtonClick(null)}
                      disabled={isGeneratingImages}
                      startIcon={
                        !isGeneratingImages ? (
                          <ImageIcon />
                        ) : (
                          <CircularProgress size={16} />
                        )
                      }
                      sx={{ mt: 2 }}
                    >
                      {isGeneratingImages
                        ? "Genererar bilder..."
                        : "Generera bilder"}
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      ...styles.lessonControlPanelFade,
                    }}
                  />
                </Box>
              )}

              <Box
                sx={{
                  position: "relative",

                  margin: "0 auto",
                  padding: "40px",
                  backgroundColor:
                    theme.palette.mode === "light" ? "#fff9e6" : "#2d2d2d",
                  borderRadius: "8px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  minHeight: "100%",
                  overflow: "visible",
                }}
              >
                <DiaryContent
                  lessonData={lessonData}
                  setLessonData={setLessonData}
                  isEditMode={isEditMode}
                  theme={theme}
                  styles={styles}
                  isGeneratingImages={isGeneratingImages}
                  loadingImageIndex={loadingImageIndex}
                  handleImageButtonClick={handleImageButtonClick}
                  user={user}
                  showSnackbar={showSnackbar}
                />

                {lessonData?.timelinesSimple && (
                  <Box
                    ref={timelineSimpleSectionRef}
                    sx={{ mt: 5, mb: 5, position: "relative" }}
                  >
                    <TimelineSimpleDisplay
                      timelineData={lessonData.timelinesSimple}
                      onTimelineDataChange={(newData) =>
                        setLessonData({
                          ...lessonData,
                          timelinesSimple: newData,
                        })
                      }
                      theme={theme}
                      styles={styles}
                      viewMode={viewMode}
                      user={user}
                    />
                  </Box>
                )}

                {/* Add this where you want to display the timeline */}
                {lessonData?.timelines && (
                  <Box
                    ref={timelineSectionRef}
                    sx={{ mt: 5, mb: 5, position: "relative" }}
                  >
                    <TimelineDisplay
                      timelineData={lessonData.timelines}
                      onTimelineDataChange={(newData) =>
                        setLessonData({ ...lessonData, timelines: newData })
                      }
                      theme={theme}
                      styles={styles}
                      withImages={true}
                      viewMode={viewMode}
                      user={user}
                      onDelete={
                        viewMode
                          ? undefined
                          : (indexToDelete) => {
                              setLessonData((prev) => ({
                                ...prev,
                                timelines: prev.timelines.filter(
                                  (_, index) => index !== indexToDelete,
                                ),
                              }));
                              showSnackbar(
                                "Tidslinjen har tagits bort",
                                "success",
                              );
                            }
                      }
                    />
                  </Box>
                )}
                {lessonData?.exercises && (
                  <Box ref={exercisesSectionRef}>
                    <LessonExercisesDisplay
                      exercises={lessonData.exercises}
                      onUpdateLessonData={setLessonData}
                      viewMode={viewMode}
                      theme={theme}
                      styles={styles}
                    />
                  </Box>
                )}
                {lessonData?.quiz && (
                  <Box
                    ref={quizSectionRef}
                    sx={{ mt: 5, mb: 5, position: "relative" }}
                  >
                    <LessonQuizDisplay
                      quizData={lessonData.quiz}
                      theme={theme}
                      styles={styles}
                      userId={user.uid}
                      viewMode={viewMode}
                      setLessonData={setLessonData}
                    />
                  </Box>
                )}
                {!viewMode && (
                  <DashedPapers
                    handleQuizPaperClick={handleQuizPaperClick}
                    handleComprehensionPaperClick={
                      handleComprehensionPaperClick
                    }
                    handleTimelinePaperClick={handleTimelinePaperClick}
                    handleTimelineSimplePaperClick={
                      handleTimelineSimplePaperClick
                    }
                    quizSectionRef={quizSectionRef}
                    timelineSectionRef={timelineSectionRef}
                    timelineSimpleSectionRef={timelineSimpleSectionRef}
                    exercisesSectionRef={exercisesSectionRef}
                    lessonData={lessonData}
                    setLessonData={setLessonData}
                  />
                )}
              </Box>

              <SaveLessonDialog
                key={lessonData?.title}
                open={saveDialogOpen}
                onClose={() => setSaveDialogOpen(false)}
                onSave={handleSaveWithComment}
                theme={theme}
                lessonName={lessonData?.title || ""}
                lessonObject={lessonData}
                lessonType={"DiaryLesson.js"}
              />
              <ImageGenerationModal
                open={showImageModal}
                onClose={handleCloseModal}
                onGenerate={handleGenerateWithInstructions}
                isGenerating={isGeneratingImages}
                instructions={imageInstructions}
                setInstructions={setImageInstructions}
                theme={theme}
              />
            </Box>

            <SlideInPaper
              isExpanded={isExpanded}
              onExpandedChange={setIsExpanded}
              lessonTitleRef={lessonTitleRef}
              viewMode={viewMode}
              onEdit={(e) => {
                e.stopPropagation();
                setIsEditMode(!isEditMode);
                setIsExpanded(false);
              }}
              toggleViewMode={toggleViewMode}
              theme={theme}
              open={showControlPanel}
              user={user}
              onClick={() => handlePrintDiaryLesson(lessonData)}
              onTranslate={() => translateButtonRef.current?.click()}
              onSaveAsPdf={() => pdfButtonRef.current?.click()}
              onShareLink={() => shareLinkButtonRef.current?.click()}
              onShareWithStudents={() => setShareDialogOpen(true)}
              onShareWithFriends={() => setShareDialogOpenFriends(true)}
              onSaveToBank={() => setSaveDialogOpen(true)}
              onStudentMode={() => {
                window.scrollTo({ top: 0, behavior: "smooth" });
                toggleViewMode();
              }}
              onGenerateExercises={() => {
                comprehensionButtonRef.current?.click();
              }}
              onGenerateQuiz={() => {
                quizButtonRef.current?.click();
              }}
              onGenerateAssignments={() => {
                comprehensionButtonRef.current?.click();
              }}
              onGenerateTimeline={() => {
                timelineButtonRef.current?.click();
              }}
            />

            <ShareDialog
              open={shareDialogOpen}
              onClose={() => setShareDialogOpen(false)}
              onShare={handleShare}
              user={user}
              isLessonSharing={true}
              theme={theme}
              lessonName={lessonData?.title || ""}
              lessonObject={lessonData}
              lessonType={"DiaryLesson.js"}
            />

            <ShareDialog
              open={shareDialogOpenFriends}
              onClose={() => setShareDialogOpenFriends(false)}
              onShare={handleShare}
              user={user}
              isLessonSharing={true}
              theme={theme}
              lessonName={lessonData?.title || ""}
              lessonObject={lessonData}
              lessonType={"DiaryLesson.js"}
              shareWithFriends={true}
            />
            <ImageLoadingDialog
              open={isGeneratingImages}
              message="Genererar bilder... Tar 30-60 sekunder."
            />
            <LoadingBackdrop
              isOpen={isSharing}
              imagePath="/img/AI.png"
              loadingText={"Delar lektionen..."}
            />
          </>
        )}
      </Box>
    </Box>
  );
};

export default DiaryLesson;
