import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stepper,
  Step,
  StepLabel,
  Box,
  Typography,
  RadioGroup,
  Radio,
  CircularProgress,
  FormControlLabel,
  TextField,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ImageList,
  ImageListItem,
  Checkbox,
  Avatar,
  Divider,
} from "@mui/material";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";
import { db } from "./firebase";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import TeacherSearch from "./TeacherSearch";
import ClearIcon from "@mui/icons-material/Clear";
import GroupAddIcon from "@mui/icons-material/GroupAdd"; // For the "create room" icon

import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { CheckCircleOutline } from "@mui/icons-material";
import { storage } from "./firebase";
import { Grid } from "@mui/material";
import { CloudUpload } from "@mui/icons-material";

import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";

import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import LockIcon from "@mui/icons-material/Lock";
import GenerateIcon from "@mui/icons-material/AutoAwesome";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";
import { useTheme } from "@mui/material/styles";
import { getStyles } from "../index";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import LessonCard from "./LessonCard";
const getSteps = (isLessonSharing, shareWithFriends) => {
  if (shareWithFriends) {
    return isLessonSharing
      ? ["Välj vänner", "Skapa lektionskort", "Lägg till kommentar"]
      : ["Välj vänner", "Lägg till kommentar"];
  }
  return isLessonSharing
    ? ["Välj rum", "Välj elever", "Skapa lektionskort", "Lägg till kommentar"]
    : ["Välj rum", "Välj elever", "Lägg till kommentar"];
};

const ShareDialog = ({
  open,
  onClose,
  onShare,
  user,
  isLessonSharing,

  lessonName: initialLessonName = "",
  lessonObject = null,
  lessonType,
  shareWithFriends = false,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [rooms, setRooms] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [students, setStudents] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [selectAllStudents, setSelectAllStudents] = useState(false);
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const styles = getStyles(theme);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [fileUrl, setFileUrl] = useState("");
  const [uploading, setUploading] = useState(false);
  const [fileError, setFileError] = useState("");
  const [isDragging, setIsDragging] = useState(false);

  const [lessonName, setLessonName] = useState(initialLessonName);
  const [lessonComment, setLessonComment] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [imageUrls, setImageUrls] = useState([]);

  const [friends, setFriends] = useState([]);
  const [selectedFriends, setSelectedFriends] = useState([]);
  const [selectAllFriends, setSelectAllFriends] = useState(false);

  const steps = getSteps(isLessonSharing, shareWithFriends);

  useEffect(() => {
    if (open && shareWithFriends) {
      fetchFriends();
    }
  }, [open, user, shareWithFriends]);

  const fetchFriends = async () => {
    setLoading(true);
    try {
      const friendsRef = collection(db, "friends");
      const q = query(friendsRef, where("userId", "==", user.uid));
      const querySnapshot = await getDocs(q);

      // Fetch friend details
      const friendPromises = querySnapshot.docs.map(async (docSnapshot) => {
        const friendData = docSnapshot.data();
        const userDocRef = doc(db, "users", friendData.friendId);
        const userDocSnapshot = await getDoc(userDocRef);
        return {
          id: friendData.friendId,
          ...userDocSnapshot.data(),
          createdAt: friendData.createdAt,
        };
      });

      const friendsList = await Promise.all(friendPromises);
      setFriends(friendsList);
    } catch (error) {
      console.error("Error fetching friends:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSelectAllFriends = (event) => {
    setSelectAllFriends(event.target.checked);
    if (event.target.checked) {
      setSelectedFriends(friends.map((friend) => friend.id));
    } else {
      setSelectedFriends([]);
    }
  };

  const handleFriendToggle = (friendId) => {
    setSelectedFriends((prev) => {
      const currentIndex = prev.indexOf(friendId);
      if (currentIndex === -1) {
        return [...prev, friendId];
      }
      return prev.filter((id) => id !== friendId);
    });
  };

  useEffect(() => {
    if (open && isLessonSharing && lessonObject) {
      // Set lesson name from prop
      setLessonName(initialLessonName);

      // Find and set image URLs from lessonObject
      const foundUrls = findImageUrls(lessonObject);
      setImageUrls(foundUrls);

      // Set first image as selected if available
      if (foundUrls.length > 0) {
        setSelectedImage(foundUrls[0]);
      }
    }
  }, [open, isLessonSharing, lessonObject, initialLessonName]);

  const findImageUrls = (obj) => {
    let urls = [];
    const search = (item) => {
      if (typeof item === "string" && item.startsWith("http")) {
        urls.push(item);
      } else if (typeof item === "object" && item !== null) {
        Object.values(item).forEach((value) => search(value));
      }
    };
    search(obj);
    return urls;
  };

  const getUserStatus = (userId) => {
    if (!selectedRoom) return "active";

    if (selectedRoom.activeUsers?.includes(userId)) return "active";
    if (selectedRoom.pausedUsers?.includes(userId)) return "paused";
    if (selectedRoom.lockedUsers?.includes(userId)) return "locked";
    return "active"; // Default status
  };

  useEffect(() => {
    if (open) {
      fetchRooms();
    }
  }, [open, user]);

  useEffect(() => {
    if (selectedRoom) {
      fetchStudents(selectedRoom.id);
    }
  }, [selectedRoom]);

  // Add these functions to handle drag and drop and file validation
  const validateAndUploadFile = async (file) => {
    setFileError("");

    // Validate file type
    const isSupported = [".docx", ".doc", ".txt"].some((ext) =>
      file.name.toLowerCase().endsWith(ext),
    );
    if (!isSupported) {
      setFileError("Filformatet stöds inte. Använd .docx, .doc, eller .txt");
      return;
    }

    // Validate file size (5MB limit)
    const maxSize = 5 * 1024 * 1024;
    if (file.size > maxSize) {
      setFileError("Filen är för stor. Maximal storlek är 5MB");
      return;
    }

    // Upload file if validation passes
    setUploading(true);
    try {
      const storageRef = ref(storage, `shared-files/${user.uid}/${file.name}`);
      const snapshot = await uploadBytes(storageRef, file);
      const url = await getDownloadURL(snapshot.ref);
      setFileUrl(url);
      setUploadedFile(file);
      setFileError("");
    } catch (error) {
      console.error("Error uploading file:", error);
      setFileError("Det gick inte att ladda upp filen. Försök igen.");
    } finally {
      setUploading(false);
    }
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const file = e.dataTransfer.files[0];
    if (file) {
      validateAndUploadFile(file);
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      validateAndUploadFile(file);
    }
  };

  const fetchRooms = async () => {
    setLoading(true);
    try {
      const roomsRef = collection(db, "rooms");
      const q = query(roomsRef, where("userId", "==", user.uid));
      const querySnapshot = await getDocs(q);
      const roomsList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setRooms(roomsList);
    } catch (error) {
      console.error("Error fetching rooms:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchStudents = async (roomId) => {
    try {
      const roomDoc = await getDoc(doc(db, "rooms", roomId));
      if (roomDoc.exists()) {
        const usersQuery = query(
          collection(db, "users"),
          where("rooms", "array-contains", roomId),
        );
        const usersSnapshot = await getDocs(usersQuery);
        const users = usersSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setStudents(users);
      }
    } catch (error) {
      console.error("Error fetching students:", error);
    }
  };

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleSelectAllStudents = (event) => {
    setSelectAllStudents(event.target.checked);
    if (event.target.checked) {
      setSelectedStudents(students.map((student) => student.id));
    } else {
      setSelectedStudents([]);
    }
  };

  const handleStudentToggle = (studentId) => {
    setSelectedStudents((prev) => {
      const currentIndex = prev.indexOf(studentId);
      if (currentIndex === -1) {
        return [...prev, studentId];
      }
      return prev.filter((id) => id !== studentId);
    });
  };

  const handleShare = () => {
    const shareData = {
      roomId: shareWithFriends ? null : selectedRoom.id,
      studentIds: shareWithFriends ? selectedFriends : selectedStudents,
      comment: comment.trim(),
      fileUrl: fileUrl,
      fileName: uploadedFile?.name,
      fileSize: uploadedFile?.size,
      fileType: uploadedFile?.type,
      fileLastModified: uploadedFile?.lastModified,
      fileFormattedSize: uploadedFile
        ? `${(uploadedFile.size / (1024 * 1024)).toFixed(2)} MB`
        : null,
    };

    if (isLessonSharing) {
      onShare(lessonComment, lessonName, selectedImage, [], shareData);
    } else {
      onShare(shareData);
    }
    handleClose();
  };

  const handleClose = () => {
    setActiveStep(0);
    setSelectedRoom(null);
    setSelectedStudents([]);
    setSelectAllStudents(false);
    setComment("");
    setLessonName("");
    setLessonComment("");
    setSelectedImage("");
    setImageUrls([]);
    setUploadedFile(null);
    setFileUrl("");
    onClose();
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case "active":
        return <RadioButtonCheckedIcon sx={{ color: "#4CAF50" }} />;
      case "paused":
        return <PauseCircleIcon sx={{ color: "#FFA726" }} />;
      case "locked":
        return <LockIcon sx={{ color: "#F44336" }} />;
      default:
        return null;
    }
  };

  const getGradeDisplay = (grade) => {
    const gradeMap = {
      åk1: "Åk 1",
      åk2: "Åk 2",
      åk3: "Åk 3",
      åk4: "Åk 4",
      åk5: "Åk 5",
      åk6: "Åk 6",
      åk7: "Åk 7",
      åk8: "Åk 8",
      åk9: "Åk 9",
      firstRing: "1:an",
      secondRing: "2:an",
      thirdRing: "3:an",
    };
    return gradeMap[grade] || grade;
  };

  const getStepContent = (step) => {
    // Friend selection step
    if (shareWithFriends && step === 0) {
      return (
        <Box sx={{ mt: 2 }}>
          {friends.length === 0 ? (
            // Empty state UI
            <Box
              sx={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 2,
                mb: 4,
              }}
            >
              <GroupAddIcon
                sx={{
                  fontSize: 80,
                  color: "primary.main",
                  opacity: 0.7,
                  mb: 2,
                  display: "none",
                }}
              />

              <Typography
                variant="h6"
                sx={{ fontWeight: 600, mt: 2, color: "text.secondary" }}
              >
                Lägg till en vän:
              </Typography>
              <Box sx={{ minWidth: 300, ml: 1 }}>
                <TeacherSearch
                  currentUserId={user.uid}
                  styles={styles}
                  theme={theme}
                />
              </Box>
              <Typography
                sx={{
                  color: "text.secondary",
                  maxWidth: 450,
                  mb: 3,
                }}
              >
                Sök efter andra lärare i textfältet ovan för att dela och
                samarbeta kring lektioner och material.
              </Typography>
            </Box>
          ) : (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectAllFriends}
                    onChange={handleSelectAllFriends}
                  />
                }
                label="Välj alla vänner"
                sx={{
                  mb: 2,
                  "& .MuiFormControlLabel-label": {
                    fontSize: "0.9rem",
                  },
                }}
              />
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={false}
                          sx={{ visibility: "hidden" }}
                        />
                      </TableCell>
                      <TableCell>Namn</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {friends.map((friend) => (
                      <TableRow
                        key={friend.id}
                        hover
                        onClick={() => handleFriendToggle(friend.id)}
                        selected={selectedFriends.includes(friend.id)}
                        sx={{ cursor: "pointer" }}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={selectedFriends.includes(friend.id)}
                            onChange={() => handleFriendToggle(friend.id)}
                          />
                        </TableCell>
                        <TableCell>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                            }}
                          >
                            <Avatar
                              sx={{
                                width: 22,
                                height: 22,
                                fontSize: "0.775rem",
                              }}
                              src={friend.photoURL}
                            >
                              {friend.displayName?.charAt(0)}
                            </Avatar>
                            <span>{friend.displayName}</span>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </Box>
      );
    }

    const finalStep = steps.length - 1;

    switch (step) {
      case 0:
        if (shareWithFriends) return null;
        return (
          <Box sx={{ mt: 2 }}>
            {loading ? (
              <Box sx={{ display: "flex", justifyContent: "center", p: 4 }}>
                <CircularProgress size={25} />
              </Box>
            ) : rooms.length === 0 ? (
              <Box
                sx={{
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <PeopleOutlineIcon
                  sx={{
                    fontSize: 80,
                    color: "primary.main",
                    opacity: 0.7,
                    mb: 2,
                  }}
                />
                <Typography variant="h6" sx={{ fontWeight: 600 }}>
                  Dags att skapa ditt första rum!
                </Typography>
                <Typography
                  sx={{
                    color: "text.secondary",
                    maxWidth: 450,
                    mb: 3,
                  }}
                >
                  Du har inte skapat ett rum med elever ännu. Skapa ett rum för
                  att enkelt kunna dela och samarbeta med dina elever. Bjud in
                  eleverna via Google Classroom, länk, eller QR-kod.
                </Typography>
                <Button
                  variant="purp"
                  onClick={() => navigate("/app/rum")}
                  startIcon={<AddIcon />}
                >
                  Skapa ditt första rum
                </Button>
              </Box>
            ) : (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Radio checked={false} sx={{ visibility: "hidden" }} />
                      </TableCell>
                      <TableCell>Namn på rummet</TableCell>
                      <TableCell>
                        <Tooltip title="Antal elever i rummet" placement="top">
                          <PeopleOutlineIcon />
                        </Tooltip>
                      </TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Årskurs</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rooms.map((room) => (
                      <TableRow
                        key={room.id}
                        hover
                        onClick={() => setSelectedRoom(room)}
                        selected={selectedRoom?.id === room.id}
                        sx={{ cursor: "pointer" }}
                      >
                        <TableCell padding="checkbox">
                          <Radio
                            checked={selectedRoom?.id === room.id}
                            onChange={() => setSelectedRoom(room)}
                          />
                        </TableCell>
                        <TableCell>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                            }}
                          >
                            <Avatar
                              sx={{
                                width: 22,
                                height: 22,
                                fontSize: "0.775rem",
                                bgcolor: "primary.main",
                              }}
                            >
                              {room.name.charAt(0).toUpperCase()}
                            </Avatar>
                            <span>{room.name}</span>
                          </Box>
                        </TableCell>
                        <TableCell>{room.studentCount}</TableCell>
                        <TableCell>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                            }}
                          >
                            {getStatusIcon(room.status)}
                            <Typography sx={{ fontSize: "0.9rem" }}>
                              {room.status === "active"
                                ? "Aktiv"
                                : room.status === "paused"
                                  ? "Pausad"
                                  : "Låst"}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell>{getGradeDisplay(room.grade)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Box>
        );

      case 1:
        if (shareWithFriends && isLessonSharing) {
          // Show lesson card UI for friend sharing
          return (
            <Box sx={{ mt: 2 }}>
              <Box sx={{ display: "flex", gap: 8 }}>
                {/* Left column - Form */}
                <Box sx={{ flex: 1 }}>
                  <Typography variant="subtitle2" sx={{ mb: 1, mt: 0 }}>
                    Lektionsnamn:
                  </Typography>
                  <TextField
                    autoFocus
                    fullWidth
                    value={lessonName}
                    placeholder="Namn på lektionen"
                    onChange={(e) => setLessonName(e.target.value)}
                    variant="outlined"
                    inputProps={{
                      maxLength: 50,
                    }}
                    sx={{
                      mb: 3,
                    }}
                  />
                  <Typography variant="subtitle2" sx={{ mb: 1, mt: 0 }}>
                    Beskrivning:
                  </Typography>
                  <TextField
                    fullWidth
                    multiline
                    rows={2}
                    value={lessonComment}
                    onChange={(e) => setLessonComment(e.target.value)}
                    placeholder="T.ex. förberedelse inför nationella provet..."
                    variant="outlined"
                    sx={{
                      mb: 3,
                    }}
                  />

                  <Typography
                    variant="subtitle2"
                    sx={{
                      mb: 0,
                      mt: 0,
                      display: selectedImage ? "block" : "none",
                    }}
                  >
                    Omslagsbild:
                  </Typography>
                  <ImageList
                    sx={{ maxHeight: 70, mt: 0.2 }}
                    cols={4}
                    rowHeight={70}
                  >
                    {imageUrls.map((url, index) => (
                      <ImageListItem
                        key={index}
                        onClick={() => setSelectedImage(url)}
                        sx={{
                          cursor: "pointer",
                          border:
                            url === selectedImage
                              ? "2px solid #4f46e5"
                              : "none",
                          borderRadius: "5px",
                          padding: "2px",
                        }}
                      >
                        <img
                          src={url}
                          alt={`Thumbnail ${index + 1}`}
                          style={{
                            objectFit: "cover",
                            height: "100%",
                            borderRadius: "5px",
                          }}
                        />
                      </ImageListItem>
                    ))}
                  </ImageList>
                </Box>

                {/* Right column - Preview */}
                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{
                      mb: 2,
                      textAlign: "center",
                    }}
                  >
                    Vad eleverna kommer att se:
                  </Typography>

                  <LessonCard
                    name={lessonName}
                    comment={lessonComment}
                    timestamp={new Date()}
                    imageUrl={selectedImage}
                    lessonType={lessonType}
                    size="large"
                    theme={theme}
                  />
                </Box>
              </Box>
            </Box>
          );
        } else if (shareWithFriends && !isLessonSharing) {
          // Show comment UI for friend sharing without lesson
          return (
            <Box sx={{ mt: 2 }}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle1" sx={{ mb: 1 }}>
                    Kommentar (valfritt)
                  </Typography>
                  <TextField
                    fullWidth
                    multiline
                    rows={7}
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    placeholder="Skriv en kommentar..."
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle1" sx={{ mb: 1 }}>
                    Ladda upp kompletterande fil (valfritt)
                  </Typography>
                  <Box
                    onDragEnter={handleDragEnter}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      minHeight: "195px",
                      width: "100%",
                      borderRadius: "16px",
                      bgcolor: isDragging
                        ? "rgba(0, 0, 0, 0.05)"
                        : "rgba(0, 0, 0, 0.02)",
                      border: "2px dashed",
                      borderColor: isDragging
                        ? "primary.main"
                        : "rgba(0, 0, 0, 0.1)",
                      transition: "all 0.3s ease-in-out",
                      "&:hover": {
                        borderColor: "primary.main",
                        bgcolor: "rgba(0, 0, 0, 0.04)",
                      },
                    }}
                  >
                    <input
                      type="file"
                      accept=".docx,.doc,.txt"
                      onChange={handleFileUpload}
                      style={{ display: "none" }}
                      id="share-file-upload"
                    />
                    <label
                      htmlFor="share-file-upload"
                      style={{ cursor: "pointer", textAlign: "center" }}
                    >
                      {uploading ? (
                        <CircularProgress size={40} />
                      ) : uploadedFile ? (
                        <Box sx={{ textAlign: "center" }}>
                          <CheckCircleOutline
                            sx={{ fontSize: 40, color: "success.main", mb: 2 }}
                          />
                          <Typography
                            variant="body2"
                            color="success.main"
                            sx={{ fontWeight: "600" }}
                          >
                            "{uploadedFile.name}" uppladdad!
                          </Typography>
                          <Button
                            variant="success"
                            size="small"
                            onClick={(e) => {
                              e.preventDefault();
                              setUploadedFile(null);
                              setFileUrl("");
                            }}
                            sx={{
                              mt: 1,
                              padding: "7px",
                              paddingLeft: "15px",
                              paddingRight: "15px",
                              fontSize: "0.8rem",
                            }}
                          >
                            Välj en annan fil
                          </Button>
                        </Box>
                      ) : (
                        <Box sx={{ textAlign: "center" }}>
                          <CloudUpload
                            sx={{
                              fontSize: 40,
                              color: isDragging
                                ? "primary.main"
                                : "primary.main",
                              mb: 2,
                              transform: isDragging ? "scale(1.1)" : "scale(1)",
                              transition: "transform 0.2s ease-in-out",
                            }}
                          />
                          <Typography variant="body2" color="text.secondary">
                            {isDragging
                              ? "Släpp filen här"
                              : "Dra och släpp fil här"}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ mt: 1 }}
                          >
                            eller
                          </Typography>
                          <Button
                            component="span"
                            variant="print"
                            size="small"
                            sx={{
                              mt: 1,
                              padding: "7px",
                              paddingLeft: "15px",
                              paddingRight: "15px",
                              fontSize: "0.8rem",
                            }}
                          >
                            Välj fil
                          </Button>
                        </Box>
                      )}
                    </label>
                  </Box>
                  {fileError && (
                    <Typography
                      color="error"
                      variant="caption"
                      sx={{ display: "block", textAlign: "center", mt: 1 }}
                    >
                      {fileError}
                    </Typography>
                  )}
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    sx={{ display: "block", textAlign: "center", mt: 1 }}
                  >
                    Stödda filformat: .docx, .doc, .txt • Max storlek: 5MB
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          );
        }
        // Show student selection UI for non-friend sharing
        return (
          <Box sx={{ mt: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectAllStudents}
                  onChange={handleSelectAllStudents}
                />
              }
              label="Välj alla elever"
              sx={{
                mb: 2,
                "& .MuiFormControlLabel-label": {
                  fontSize: "0.9rem",
                },
              }}
            />
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox checked={false} sx={{ visibility: "hidden" }} />
                    </TableCell>
                    <TableCell>Namn</TableCell>
                    <TableCell>E-post</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {students.map((student) => (
                    <TableRow
                      key={student.id}
                      hover
                      onClick={() => handleStudentToggle(student.id)}
                      selected={selectedStudents.includes(student.id)}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedStudents.includes(student.id)}
                          onChange={() => handleStudentToggle(student.id)}
                        />
                      </TableCell>
                      <TableCell>
                        <Box
                          sx={{ display: "flex", alignItems: "center", gap: 1 }}
                        >
                          <Avatar
                            sx={{ width: 22, height: 22, fontSize: "0.775rem" }}
                            src={student.photoURL}
                          >
                            {student.displayName?.charAt(0)}
                          </Avatar>
                          <span>{student.displayName}</span>
                        </Box>
                      </TableCell>
                      <TableCell>{student.email}</TableCell>
                      <TableCell>
                        <Box
                          sx={{ display: "flex", alignItems: "center", gap: 1 }}
                        >
                          {getStatusIcon(getUserStatus(student.id))}
                          <Typography sx={{ fontSize: "0.9rem" }}>
                            {getUserStatus(student.id) === "active"
                              ? "Aktiv"
                              : getUserStatus(student.id) === "paused"
                                ? "Pausad"
                                : "Låst"}
                          </Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        );

      case 2:
        if (!shareWithFriends && isLessonSharing) {
          return (
            <Box sx={{ mt: 2 }}>
              <Box sx={{ display: "flex", gap: 8 }}>
                {/* Left column - Form */}
                <Box sx={{ flex: 1 }}>
                  <Typography variant="subtitle2" sx={{ mb: 1, mt: 0 }}>
                    Lektionsnamn:
                  </Typography>
                  <TextField
                    autoFocus
                    fullWidth
                    value={lessonName}
                    placeholder="Namn på lektionen"
                    onChange={(e) => setLessonName(e.target.value)}
                    variant="outlined"
                    inputProps={{
                      maxLength: 50,
                    }}
                    sx={{
                      mb: 3,
                    }}
                  />
                  <Typography variant="subtitle2" sx={{ mb: 1, mt: 0 }}>
                    Beskrivning:
                  </Typography>
                  <TextField
                    fullWidth
                    multiline
                    rows={2}
                    value={lessonComment}
                    onChange={(e) => setLessonComment(e.target.value)}
                    placeholder="T.ex. förberedelse inför nationella provet..."
                    variant="outlined"
                    sx={{
                      mb: 3,
                    }}
                  />

                  <Typography
                    variant="subtitle2"
                    sx={{
                      mb: 0,
                      mt: 0,
                      display: selectedImage ? "block" : "none",
                    }}
                  >
                    Omslagsbild:
                  </Typography>
                  <ImageList
                    sx={{ maxHeight: 70, mt: 0.2 }}
                    cols={4}
                    rowHeight={70}
                  >
                    {imageUrls.map((url, index) => (
                      <ImageListItem
                        key={index}
                        onClick={() => setSelectedImage(url)}
                        sx={{
                          cursor: "pointer",
                          border:
                            url === selectedImage
                              ? "2px solid #4f46e5"
                              : "none",
                          borderRadius: "5px",
                          padding: "2px",
                        }}
                      >
                        <img
                          src={url}
                          alt={`Thumbnail ${index + 1}`}
                          style={{
                            objectFit: "cover",
                            height: "100%",
                            borderRadius: "5px",
                          }}
                        />
                      </ImageListItem>
                    ))}
                  </ImageList>
                </Box>

                {/* Right column - Preview */}
                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{
                      mb: 2,
                      textAlign: "center",
                    }}
                  >
                    Vad eleverna kommer att se:
                  </Typography>

                  <LessonCard
                    name={lessonName}
                    comment={lessonComment}
                    timestamp={new Date()}
                    imageUrl={selectedImage}
                    lessonType={lessonType}
                    size="large"
                    theme={theme}
                  />
                </Box>
              </Box>
            </Box>
          );
        }

      case 3:
      default:
        return (
          <Box sx={{ mt: 2 }}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Kommentar (valfritt)
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={7}
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  placeholder="Skriv en kommentar till eleverna..."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Ladda upp kompletterande fil (valfritt)
                </Typography>
                <Box
                  onDragEnter={handleDragEnter}
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  onDrop={handleDrop}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    minHeight: "195px",
                    width: "100%",
                    borderRadius: "16px",
                    bgcolor: isDragging
                      ? "rgba(0, 0, 0, 0.05)"
                      : "rgba(0, 0, 0, 0.02)",
                    border: "2px dashed",
                    borderColor: isDragging
                      ? "primary.main"
                      : "rgba(0, 0, 0, 0.1)",
                    transition: "all 0.3s ease-in-out",
                    "&:hover": {
                      borderColor: "primary.main",
                      bgcolor: "rgba(0, 0, 0, 0.04)",
                    },
                  }}
                >
                  <input
                    type="file"
                    accept=".docx,.doc,.txt"
                    onChange={handleFileUpload}
                    style={{ display: "none" }}
                    id="share-file-upload"
                  />
                  <label
                    htmlFor="share-file-upload"
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    {uploading ? (
                      <CircularProgress size={40} />
                    ) : uploadedFile ? (
                      <Box sx={{ textAlign: "center" }}>
                        <CheckCircleOutline
                          sx={{ fontSize: 40, color: "success.main", mb: 2 }}
                        />
                        <Typography
                          variant="body2"
                          color="success.main"
                          sx={{ fontWeight: "600" }}
                        >
                          "{uploadedFile.name}" uppladdad!
                        </Typography>
                        <Button
                          variant="success"
                          size="small"
                          onClick={(e) => {
                            e.preventDefault();
                            setUploadedFile(null);
                            setFileUrl("");
                          }}
                          sx={{
                            mt: 1,
                            padding: "7px",
                            paddingLeft: "15px",
                            paddingRight: "15px",
                            fontSize: "0.8rem",
                          }}
                        >
                          Välj en annan fil
                        </Button>
                      </Box>
                    ) : (
                      <Box sx={{ textAlign: "center" }}>
                        <CloudUpload
                          sx={{
                            fontSize: 40,
                            color: isDragging ? "primary.main" : "primary.main",
                            mb: 2,
                            transform: isDragging ? "scale(1.1)" : "scale(1)",
                            transition: "transform 0.2s ease-in-out",
                          }}
                        />
                        <Typography variant="body2" color="text.secondary">
                          {isDragging
                            ? "Släpp filen här"
                            : "Dra och släpp fil här"}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{ mt: 1 }}
                        >
                          eller
                        </Typography>
                        <Button
                          component="span"
                          variant="print"
                          size="small"
                          sx={{
                            mt: 1,
                            padding: "7px",
                            paddingLeft: "15px",
                            paddingRight: "15px",
                            fontSize: "0.8rem",
                          }}
                        >
                          Välj fil
                        </Button>
                      </Box>
                    )}
                  </label>
                </Box>
                {fileError && (
                  <Typography
                    color="error"
                    variant="caption"
                    sx={{
                      display: "block",
                      textAlign: "center",
                      mt: 1,
                    }}
                  >
                    {fileError}
                  </Typography>
                )}
                <Typography
                  variant="caption"
                  color="text.secondary"
                  sx={{
                    display: "block",
                    textAlign: "center",
                    mt: 1,
                  }}
                >
                  Stödda filformat: .docx, .doc, .txt • Max storlek: 5MB
                </Typography>
              </Grid>
            </Grid>
          </Box>
        );
    }
  };
  const isStepValid = (step) => {
    if (shareWithFriends) {
      switch (step) {
        case 0:
          return selectedFriends.length > 0;
        case 1:
          return isLessonSharing ? lessonName.trim().length > 0 : true;
        case 2:
          return true;
        default:
          return false;
      }
    }

    // Original validation logic
    switch (step) {
      case 0:
        return selectedRoom !== null;
      case 1:
        return selectedStudents.length > 0;
      case 2:
        return isLessonSharing ? lessonName.trim().length > 0 : true;
      case 3:
        return true;
      default:
        return false;
    }
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: "8px",
          p: 2,
          minHeight: "550px",
        },
      }}
    >
      <DialogContent>
        <Stepper
          activeStep={activeStep}
          sx={{
            width: { xs: "100%", md: isLessonSharing ? "90%" : "70%" },
            margin: "0 auto",
            mt: 2,
            mb: 6,
          }}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <Typography
          sx={{
            fontSize: "1.6rem",
            fontWeight: "700",
            textAlign: "center",
            mb: 7,
          }}
        >
          {shareWithFriends
            ? activeStep === 0
              ? "Välj vänner"
              : activeStep === 1
                ? isLessonSharing
                  ? "Skapa lektionskort"
                  : "Lägg till kommentar"
                : "Lägg till kommentar"
            : activeStep === 0
              ? "Välj rum"
              : activeStep === 1
                ? "Välj elever"
                : activeStep === 2
                  ? isLessonSharing
                    ? "Skapa lektionskort"
                    : "Lägg till kommentar"
                  : "Lägg till kommentar"}
        </Typography>

        {getStepContent(activeStep)}
      </DialogContent>

      <DialogActions sx={{ px: 3, pt: 2 }}>
        <Button onClick={handleClose} variant="print" startIcon={<ClearIcon />}>
          Avbryt
        </Button>
        <Box sx={{ flex: "1 1 auto" }} />
        {activeStep > 0 && (
          <Button onClick={handleBack} variant="print" startIcon={<WestIcon />}>
            Tillbaka
          </Button>
        )}
        {activeStep === steps.length - 1 ? (
          <Button
            onClick={handleShare}
            variant="purp"
            disabled={!isStepValid(activeStep)}
            startIcon={<GenerateIcon />}
          >
            Dela nu
          </Button>
        ) : (
          <Button
            onClick={handleNext}
            variant="purp"
            disabled={!isStepValid(activeStep)}
            endIcon={<EastIcon />}
          >
            Nästa
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ShareDialog;
