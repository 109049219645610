import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  IconButton,
  Grid,
  Typography,
  Box,
  TextField,
  InputAdornment,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import GoogleIcon from "@mui/icons-material/Google";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ShareIcon from "@mui/icons-material/Share";
import QrCodeIcon from "@mui/icons-material/QrCode2";
import ThemeFlowBox from "./ThemeFlowBox";
import { useSnackbar } from "./SnackbarHandler";
import Logger from "./Logger";
import QRCode from "react-qr-code";
import BlurryBackdrop from "./BlurryBackdrop";
import WestIcon from "@mui/icons-material/West";
import LinkIcon from "@mui/icons-material/Link";

const ShareRoomDialog = ({ open, onClose, room }) => {
  const { showSnackbar } = useSnackbar();
  const [showQRView, setShowQRView] = useState(false);
  const [showShareLinkView, setShowShareLinkView] = useState(false);

  useEffect(() => {
    if (!open) {
      setShowQRView(false);
      setShowShareLinkView(false);
    }
  }, [open]);

  const handleShareToClassroom = () => {
    Logger.log("handleShareToClassroom room: ", room);
    const url = `https://classroom.google.com/share?url=${encodeURIComponent(
      `https://studera.ai/app/skapa-konto?roomId=${room.id}`,
    )}&title=${encodeURIComponent(
      `Gå med i ${room.name}`,
    )}&body=${encodeURIComponent(
      "Följ denna länk för att gå med i Studera AI:s elevrum",
    )}&itemtype=assignment`;
    const width = 800;
    const height = 600;
    const left = (window.screen.width - width) / 2;
    const top = (window.screen.height - height) / 2;
    const windowFeatures = `width=${width},height=${height},left=${left},top=${top},toolbar=no,menubar=no,scrollbars=yes,resizable=yes`;
    window.open(url, "Google Classroom Share", windowFeatures);
  };

  const handleCopyShareLink = () => {
    setShowShareLinkView(true);
  };

  const handleShowQRCode = () => {
    setShowQRView(true);
  };

  const shareUrl = `https://studera.ai/app/skapa-konto?roomId=${room?.id}`;
  const accessCode = room?.id?.slice(0, 6) || "";

  return (
    <Dialog
      open={open}
      onClose={onClose}
      BackdropComponent={BlurryBackdrop}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: 900,
        },
      }}
    >
      <DialogTitle>
        <Typography
          variant="h6"
          component="div"
          sx={{
            fontWeight: "bold",
            fontSize: "1.6rem",
            padding: "5px",
            textAlign: "center",
            mb: 3,
            mt: 2,
          }}
        >
          Dela rummet med dina elever
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: "50px" }}>
        {!showQRView && !showShareLinkView ? (
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <ThemeFlowBox
                icon={LinkIcon}
                title="Bjud in-länk"
                subtitle="Kopiera delningslänk och dela med eleverna"
                onClick={handleCopyShareLink}
                diffView={true}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <ThemeFlowBox
                icon={GoogleIcon}
                iconImage="/img/gclass.png"
                title="Google Classroom"
                subtitle="Skapa en uppgift med länken till rummet"
                onClick={handleShareToClassroom}
                diffView={true}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <ThemeFlowBox
                icon={QrCodeIcon}
                title="QR-kod"
                subtitle={`Låt eleverna skanna en QR-kod`}
                onClick={handleShowQRCode}
                diffView={true}
              />
            </Grid>
          </Grid>
        ) : showQRView ? (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              <div
                style={{
                  padding: "16px",
                  borderRadius: "8px",
                  margin: "0 auto",
                }}
              >
                <QRCode
                  value={shareUrl}
                  size={200}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  viewBox="0 0 200 200"
                />
              </div>
              <div style={{ flex: 1, display: "none" }}>
                <div style={{ marginBottom: "24px", textAlign: "center" }}>
                  <p style={{ fontWeight: 500 }}>
                    Besök: <br />
                    https://studera.ai/app/skapa-konto
                    <br />
                    och ange koden:
                  </p>
                </div>
                <div style={{ marginBottom: "24px", textAlign: "center" }}>
                  <p style={{ fontWeight: 500, marginBottom: "4px" }}></p>
                  <p
                    style={{
                      fontFamily: "monospace",
                      fontSize: "1.1rem",
                      background: "#f5f5f5",
                      padding: "17px",
                      borderRadius: "4px",
                    }}
                  >
                    {accessCode}
                  </p>
                </div>
              </div>
            </div>

            <div>
              <p
                style={{
                  color: "rgba(0,0,0,0.6)",
                  fontSize: "1rem",
                  textAlign: "center",
                  marginTop: "20px",
                  padding: "0",
                }}
              >
                Skanna QR-koden med din mobilkamera och slutför registreringen,
                så läggs du till i rummet.
              </p>
            </div>

            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",

                  width: "100%",
                }}
              >
                <Button
                  variant="print"
                  onClick={() => setShowQRView(false)}
                  sx={{ mt: 4 }}
                >
                  <WestIcon sx={{ mr: 1 }} /> Tillbaka
                </Button>
              </div>
            </div>
          </>
        ) : (
          <Box>
            <Typography
              variant="formLabelSinUnderline"
              sx={{
                fontWeight: "500",
                width: "100%",
                display: "block",
                textAlign: "center",
                mb: 2,
              }}
            >
              Instruera eleverna att besöka denna URL:
            </Typography>

            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <TextField
                fullWidth
                disabled
                value={shareUrl}
                sx={{
                  maxWidth: "700px",
                  "& .MuiOutlinedInput-root": {
                    paddingRight: 0,
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        startIcon={<ContentCopyIcon />}
                        variant="purp"
                        onClick={() => {
                          navigator.clipboard.writeText(shareUrl);
                          showSnackbar(
                            "Länk kopierad. Nu kan du dela den med dina elever.",
                            "success",
                          );
                        }}
                        sx={{
                          height: "56px",
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 0,
                        }}
                      >
                        Kopiera
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                mt: 5,
              }}
            >
              <Button
                variant="print"
                onClick={() => setShowShareLinkView(false)}
              >
                <WestIcon sx={{ mr: 1 }} /> Tillbaka
              </Button>
            </Box>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ShareRoomDialog;
