import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  IconButton,
  Tooltip,
  Divider,
  InputAdornment,
  CircularProgress,
  Box,
} from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EmailIcon from "@mui/icons-material/Email";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import FacebookIcon from "@mui/icons-material/Facebook";
import { db } from "./firebase";
import Logger from "./Logger";
import { lessonUrlMapping } from "./functions";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import CloseIcon from "@mui/icons-material/Close";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Alert } from "@mui/material";

const API_URL =
  process.env.NODE_ENV === "production" ? "/api" : "http://localhost:3001/api";

const ShareLink = ({
  lessonId: propLessonId,
  lessonData,
  onCopy,
  user,
  lessonType,
  sx = {},
  buttonRef,
}) => {
  const [open, setOpen] = useState(false);
  const [emailDialogOpen, setEmailDialogOpen] = useState(false);
  const [isGeneratingLink, setIsGeneratingLink] = useState(false);
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [generatedLink, setGeneratedLink] = useState("");
  const [localLessonId, setLocalLessonId] = useState(propLessonId);
  const [senderEmail, setSenderEmail] = useState("");
  const [recipientEmail, setRecipientEmail] = useState("");

  useEffect(() => {
    if (user?.email) {
      setSenderEmail(user.email);
    }
  }, [user?.email]);

  useEffect(() => {
    // Kontrollera om FB SDK redan har laddats
    if (window.FB) {
      window.FB.init({
        appId: "1266986537863810",
        autoLogAppEvents: true,
        xfbml: true,
        version: "v18.0",
      });
    } else {
      // Om inte, sätt upp async init
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: "1266986537863810",
          autoLogAppEvents: true,
          xfbml: true,
          version: "v18.0",
        });
      };
    }
  }, []);

  const updateMetaTags = (lessonData) => {
    // Uppdatera title
    document
      .querySelector('meta[property="og:title"]')
      .setAttribute("content", `Studera.ai - ${lessonData.title || "Lektion"}`);

    // Uppdatera description
    document
      .querySelector('meta[property="og:description"]')
      .setAttribute(
        "content",
        lessonData.description || "En lektion skapad med Studera.ai",
      );

    // Uppdatera URL
    document
      .querySelector('meta[property="og:url"]')
      .setAttribute("content", generatedLink);
  };

  const handleFacebookShare = () => {
    if (!generatedLink) {
      onCopy("Ingen länk att dela än", "error");
      return;
    }

    if (!window.FB) {
      console.error("Facebook SDK not loaded");
      onCopy("Kunde inte ladda Facebook-delning, försök igen", "error");
      return;
    }

    try {
      window.FB.ui(
        {
          method: "share",
          href: generatedLink,
          display: "popup",
        },
        function (response) {
          if (response && !response.error_message) {
            onCopy("Delat på Facebook!", "success");
          } else if (response && response.error_message) {
            onCopy("Kunde inte dela: " + response.error_message, "error");
          }
        },
      );
    } catch (error) {
      console.error("Facebook share error:", error);
      onCopy("Något gick fel vid delning", "error");
    }
  };

  const handleOpen = async () => {
    setIsGeneratingLink(true);
    try {
      let linkId = localLessonId;

      if (!linkId && !propLessonId) {
        Logger.log("Inget lessonId finns. Sparar nytt dokument i lesson_usage");

        const docRef = await addDoc(collection(db, "lesson_usage"), {
          userId: user.uid,
          data: lessonData,
          lessonType: lessonType,
          timestamp: serverTimestamp(),
        });

        linkId = docRef.id.substring(0, 10);
        setLocalLessonId(linkId);
      } else {
        Logger.log("lessonId finns - ger länken ba.");
        linkId = linkId || propLessonId;
      }

      const link = generateShareableLink(linkId);
      setGeneratedLink(link);
      updateMetaTags(lessonData);
    } catch (error) {
      console.error("Error generating share link:", error);
      if (onCopy) {
        onCopy("Kunde inte generera delningslänk", "error");
      }
      return;
    } finally {
      setIsGeneratingLink(false);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setGeneratedLink("");
  };

  const handleEmailDialogOpen = () => {
    setEmailDialogOpen(true);
  };

  const handleEmailDialogClose = () => {
    setEmailDialogOpen(false);
    setSenderEmail("");
    setRecipientEmail("");
  };

  const generateShareableLink = (id) => {
    const currentUrl = window.location.href;
    const hasViewMode = currentUrl.includes("v=t");

    // Determine the path based on lessonType
    const path = (() => {
      switch (lessonType) {
        case "PowerPointGenerator.js":
        case "VisionAnalysis.js":
          return `/app/${lessonUrlMapping(lessonType)}/${id}`;
        default:
          return `/app/lektioner/${lessonUrlMapping(lessonType)}/${id}`;
      }
    })();

    // Base URL without query params
    const baseUrl = currentUrl.split("/app")[0] + path;

    if (!hasViewMode) {
      const queryParams = currentUrl.split("?")[1] || "";
      const newParams = queryParams ? `?${queryParams}&v=t` : "?v=t";
      return `${baseUrl}${newParams}`;
    }
    return baseUrl;
  };

  // const generateShareableLink = (id) => {
  //   const currentUrl = window.location.href;
  //   const hasViewMode = currentUrl.includes("v=t");

  //   // Follow the same pattern as handleLessonClick
  //   const path =
  //     lessonType === "PowerPointGenerator.js"
  //       ? `/app/${lessonUrlMapping(lessonType)}/${id}`
  //       : `/app/lektioner/${lessonUrlMapping(lessonType)}/${id}`;

  //   // Base URL without query params
  //   const baseUrl = currentUrl.split("/app")[0] + path;

  //   if (!hasViewMode) {
  //     const queryParams = currentUrl.split("?")[1] || "";
  //     const newParams = queryParams ? `?${queryParams}&v=t` : "?v=t";
  //     return `${baseUrl}${newParams}`;
  //   }
  //   return baseUrl;
  // };

  // const generateShareableLink = (id) => {
  //   const currentUrl = window.location.href;
  //   const hasParams = currentUrl.includes("?");
  //   const hasLessonId = currentUrl.includes("/lektioner/");
  //   const hasViewMode = currentUrl.includes("v=t");

  //   let baseUrl = currentUrl.split("?")[0];
  //   if (hasLessonId) {
  //     baseUrl = baseUrl.split("/lektioner/")[0];
  //   }

  //   baseUrl = `${baseUrl}/lektioner/${lessonUrlMapping(lessonType)}/${id}`;

  //   if (!hasViewMode) {
  //     const queryParams = currentUrl.split("?")[1] || "";
  //     const newParams = queryParams ? `?${queryParams}&v=t` : "?v=t";
  //     return `${baseUrl}${newParams}`;
  //   }

  //   return baseUrl;
  // };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(generatedLink);
      if (onCopy) {
        onCopy("Länken har kopierats till urklipp!");
      }
      handleClose();
    } catch (err) {
      if (onCopy) {
        onCopy("Kunde inte kopiera länken", "error");
      }
    }
  };

  const handleSendEmail = async (e) => {
    e.preventDefault();

    if (!senderEmail || !recipientEmail) {
      onCopy("Vänligen fyll i alla fält", "warning");
      return;
    }

    if (
      !/\S+@\S+\.\S+/.test(senderEmail) ||
      !/\S+@\S+\.\S+/.test(recipientEmail)
    ) {
      onCopy("Vänligen ange giltiga e-postadresser", "warning");
      return;
    }

    try {
      setIsSendingEmail(true);
      const response = await fetch(`${API_URL}/send-email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: user?.displayName || "En Studera.ai användare",
          email: senderEmail,
          recipientEmail,
          message: `Hej!\n\nJag vill dela en lektion med dig. Du hittar den här:\n${generatedLink}\n\nHälsningar\n${user?.displayName || "En Studera.ai användare"}`,
        }),
      });

      if (response.ok) {
        onCopy("E-post har skickats!", "success");
        handleEmailDialogClose();
      } else {
        throw new Error("Failed to send email");
      }
    } catch (error) {
      onCopy("Något gick fel. Försök igen senare.", "error");
    } finally {
      setIsSendingEmail(false);
    }
  };

  return (
    <>
      <Button
        ref={buttonRef}
        variant="success"
        onClick={handleOpen}
        startIcon={<InsertLinkIcon />}
        sx={{ ml: 2, mt: 2, ...sx }}
        disabled={isGeneratingLink || !lessonData}
      >
        {isGeneratingLink
          ? "Genererar länk..."
          : `Dela länk till ${lessonType === "VisionAnalysis.js" ? "bildanalys" : "lektionen"}`}
      </Button>

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle
          sx={{
            textAlign: "center",
            fontWeight: "700",
            padding: "25px",
            fontSize: "1.5rem",
          }}
        >
          <Box
            sx={{ display: "flex", width: "100%", justifyContent: "flex-end" }}
          >
            <IconButton
              onClick={handleClose}
              size="small"
              sx={{ position: "absolute" }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          Dela länk till lektionen
        </DialogTitle>
        <DialogContent sx={{ p: 4 }}>
          <TextField
            fullWidth
            value={generatedLink}
            variant="outlined"
            margin="dense"
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleCopy} edge="end">
                    <ContentCopyIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Alert severity="info" sx={{ mt: 2, borderRadius: 4 }}>
            Du kan kopiera länken och skicka den till en elev, en lärare eller
            vem som helst! Du kan också dela länken via e-post genom att klicka
            på knappen nedan.
          </Alert>

          <Alert severity="warning" sx={{ mt: 2, borderRadius: 4 }}>
            Kontrollera att du har sparat lektionen i din lektionsbank. Annars
            kommer den du delar med <strong>inte</strong> se dina ändringar.
            Vill du se hur lektionen kommer se ut för den som klickar på länken?
            Tryck på "Öppna länken i ny flik nedan".
          </Alert>
        </DialogContent>
        <Divider />
        <DialogActions
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Button
            onClick={handleClose}
            variant="print"
            startIcon={<CloseIcon />}
          >
            Stäng
          </Button>
          {user.admin && (
            <Button
              onClick={handleFacebookShare}
              variant="purp"
              startIcon={<FacebookIcon />}
              sx={{ ml: 2, display: "none" }}
            >
              Dela på Facebook
            </Button>
          )}
          <div>
            <Tooltip title="Se hur lektionen kommer att se ut för dem du delar den med.">
              <Button
                onClick={() => window.open(generatedLink, "_blank")}
                variant="success"
                startIcon={<OpenInNewIcon />}
                sx={{ mr: 2 }}
              >
                Öppna länken i ny flik
              </Button>
            </Tooltip>
            <Button
              onClick={handleEmailDialogOpen}
              variant="purp"
              startIcon={<EmailIcon />}
              sx={{ mr: 2 }}
            >
              Dela länk via e-post
            </Button>
            <Button
              onClick={handleCopy}
              variant="purp"
              startIcon={<ContentCopyIcon />}
            >
              Kopiera länk
            </Button>
          </div>
        </DialogActions>
      </Dialog>

      <Dialog
        open={emailDialogOpen}
        onClose={handleEmailDialogClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{ fontWeight: "600", p: 4 }}>
          Dela länk via e-post
        </DialogTitle>
        <DialogContent sx={{ p: 4 }}>
          <form onSubmit={handleSendEmail}>
            <TextField
              fullWidth
              label="Din e-postadress"
              type="email"
              value={senderEmail}
              onChange={(e) => setSenderEmail(e.target.value)}
              required
              margin="normal"
            />
            <TextField
              fullWidth
              label="Mottagarens e-postadress"
              type="email"
              value={recipientEmail}
              onChange={(e) => setRecipientEmail(e.target.value)}
              required
              margin="normal"
            />

            <DialogActions sx={{ px: 0, pt: 2 }}>
              <Button
                onClick={handleEmailDialogClose}
                variant="print"
                startIcon={<CloseIcon />}
              >
                Avbryt
              </Button>
              <Button
                type="submit"
                variant="purp"
                disabled={isSendingEmail}
                endIcon={
                  isSendingEmail ? (
                    <CircularProgress size={16} color="inherit" />
                  ) : (
                    <EmailIcon />
                  )
                }
              >
                Skicka e-post
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ShareLink;
