import React, { useRef } from "react";
import { Box, IconButton } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

const ScrollableGrid = ({ children }) => {
  const scrollContainerRef = useRef(null);
  const [canScroll, setCanScroll] = React.useState({
    left: false,
    right: false,
  });

  React.useEffect(() => {
    const container = scrollContainerRef.current;
    if (container) {
      const { canScrollLeft, canScrollRight } = checkScrollability(container);
      setCanScroll({ left: canScrollLeft, right: canScrollRight });
    }
  }, [children]);

  const scroll = (direction) => {
    const container = scrollContainerRef.current;
    if (container) {
      const scrollAmount =
        direction === "left" ? -container.offsetWidth : container.offsetWidth;
      container.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  const checkScrollability = (container) => {
    if (!container) return { canScrollLeft: false, canScrollRight: false };
    return {
      canScrollLeft: container.scrollLeft > 0,
      canScrollRight:
        container.scrollLeft < container.scrollWidth - container.offsetWidth,
    };
  };

  const handleScroll = (e) => {
    const { canScrollLeft, canScrollRight } = checkScrollability(e.target);
    setCanScroll({ left: canScrollLeft, right: canScrollRight });
  };

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        "&::before, &::after": {
          content: '""',
          position: "absolute",
          top: 0,
          bottom: 0,
          width: "100px",
          pointerEvents: "none",
          zIndex: 1,
          transition: "opacity 0.3s ease",
        },
        "&::before": {
          left: 0,

          opacity: (theme) =>
            canScroll.left && theme.palette.mode === "dark"
              ? 0
              : canScroll.left
                ? 1
                : 0,
        },
        "&::after": {
          right: 0,

          opacity: (theme) =>
            canScroll.right && theme.palette.mode === "dark"
              ? 0
              : canScroll.right
                ? 1
                : 0,
        },
      }}
    >
      <Box
        ref={scrollContainerRef}
        onScroll={handleScroll}
        sx={{
          display: "flex",
          overflowX: "auto",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          // Only add padding when scroll buttons are visible
          pl: canScroll.left ? 6 : 0,
          pr: canScroll.right ? 6 : 0,
          gap: 3,
          transition: "padding 0.3s ease",
        }}
      >
        {children}
      </Box>

      {canScroll.left && (
        <IconButton
          className="scroll-button-left"
          onClick={() => scroll("left")}
          sx={{
            position: "absolute",
            left: -20,
            top: "50%",
            transform: "translateY(-50%)",
            zIndex: 2,
            bgcolor: "background.paper",
            boxShadow: 2,
            "&:hover": {
              bgcolor: "background.default",
            },
          }}
        >
          <ChevronLeft />
        </IconButton>
      )}

      {canScroll.right && (
        <IconButton
          className="scroll-button-right"
          onClick={() => scroll("right")}
          sx={{
            position: "absolute",
            right: -20,
            top: "50%",
            transform: "translateY(-50%)",
            zIndex: 2,
            bgcolor: "background.paper",
            boxShadow: 2,
            "&:hover": {
              bgcolor: "background.default",
            },
          }}
        >
          <ChevronRight />
        </IconButton>
      )}
    </Box>
  );
};

export default ScrollableGrid;
