import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  Select,
  MenuItem,
  Typography,
  Divider,
  TextField,
  CircularProgress,
  FormControlLabel,
  Switch,
  Grid,
  Tooltip,
  Avatar,
  Chip,
  IconButton,
} from "@mui/material";

import ShareLink from "./ShareLink";
import DashedPapers from "./DashedPapers";
import LessonQuizDisplay from "./LessonQuizDisplay";
import AddonTimelineSimple from "./AddonTimelineSimple";
import TimelineSimpleDisplay from "./TimelineSimpleDisplay";
import TimelineDisplay from "./TimelineDisplay";
import LessonExercisesDisplay from "./LessonExercisesDisplay";
import ReadingComprehensionDialog from "./ReadingComprehensionDialog";
import AddonTimeline from "./AddonTimeline";
import AddonQuiz from "./AddonQuiz";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import LoadingBackdrop from "./LoadingBackdrop";
import ShareDialog from "./ShareDialog";
import ShareIcon from "@mui/icons-material/Share";
import SlideInPaper from "./SlideInPaper";
import ImageLoadingDialog from "./ImageLoadingDialog";
import ConversationDisplay from "./ConversationDisplay";

import GenerateIcon from "@mui/icons-material/AutoAwesome";
import { handlePrintConversationLesson } from "./printFunctions";
import InfoBox from "./InfoBox";
import { callApi } from "./api";
import ChatIcon from "@mui/icons-material/Chat";
import { useSnackbar } from "./SnackbarHandler";
import LessonDrawer from "./LessonDrawer";
import SaveLessonDialog from "./SaveLessonDialog";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import LanguageSelector from "./LanguageSelector";
import GradeSelector, { gradeInstructions } from "./GradeSelector";
import ProgressBar from "./ProgressBar";
import { db } from "./firebase";
import {
  collection,
  updateDoc,
  query,
  where,
  limit,
  getDocs,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";
import ImageGenerationModal from "./ImageGenerationModal";
import Voice from "./Voice";
import { ThemeSelectorFlow } from "./ThemeSelectorFlow";

import Logger from "./Logger";

import HistoryIcon from "@mui/icons-material/History";

import ImageIcon from "@mui/icons-material/Image";
import PrintIcon from "@mui/icons-material/Print";

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import { generateAndProcessImage, trackLesson } from "./functions";
import HoverableImage from "./HoverableImage";
import MicExternalOnIcon from "@mui/icons-material/MicExternalOn";
import { useSearchParams, useParams } from "react-router-dom";
import { getAdditionalThemes } from "./additionalThemes/ConversationLesson";

const ConversationLesson = ({ user, theme, styles }) => {
  const [topic, setTopic] = useState("airport");
  const [customTheme, setCustomTheme] = useState("");
  const [language, setLanguage] = useState("svenska");
  const [participantCount, setParticipantCount] = useState(2);
  const [conversationTone, setConversationTone] = useState("informell");
  const [isGenerating, setIsGenerating] = useState(false);
  const [messageCount, setMessageCount] = useState(6);
  const urlContentRef = useRef("");
  const [lessonData, setLessonData] = useState(null);
  const [isGeneratingTopicImage, setIsGeneratingTopicImage] = useState(false);
  const [isGeneratingParticipantImages, setIsGeneratingParticipantImages] =
    useState(false);
  const [progress, setProgress] = useState(0);
  const [generatedImages, setGeneratedImages] = useState(new Set());
  const { showSnackbar } = useSnackbar();
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageInstructions, setImageInstructions] = useState("");
  const [imageGenerationType, setImageGenerationType] = useState(null);
  const [openAiInstructions, setOpenAiInstructions] = useState("");

  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [titleText, setTitleText] = useState("");
  const [grade, setGrade] = useState("åk6");
  const [showTranslation, setShowTranslation] = useState(false);
  const [translationLanguage, setTranslationLanguage] = useState("spanska");
  const [showControlPanel, setShowControlPanel] = useState(false);
  const [editingMessageId, setEditingMessageId] = useState(null);
  const [editingTranslationId, setEditingTranslationId] = useState(null);

  const [selectedParticipantId, setSelectedParticipantId] = useState(null);
  const [loadingImageIndex, setLoadingImageIndex] = useState(null);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);

  const [lessonTheme, setLessonTheme] = useState("generateWithAi");
  const [predefinedTheme, setPredefinedTheme] = useState(null);
  const lessonTitleRef = useRef(null); // Add this ref
  const transcriptRef = useRef("");
  const uploadedFileRef = useRef("");

  const wikipediaContentRef = useRef("");
  const randomizedContentRef = useRef("");
  const coursePlanContentRef = useRef("");
  const bookContentRef = useRef("");

  const LESSON_VARIANTS = {
    default: {
      title: "Konversation",
      lessonType: "ConversationLesson.js",
      icon: ChatIcon,
    },
    interview: {
      title: "Intervju",
      lessonType: "ConversationLessonVariantInterview.js",
      icon: MicExternalOnIcon,
    },
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const variant = searchParams.get("type") || "default";
  const lessonConfig = LESSON_VARIANTS[variant] || LESSON_VARIANTS.default;
  const additionalThemes = getAdditionalThemes(lessonConfig.lessonType);
  const subject = searchParams.get("subject") || null;
  const viewMode = searchParams.get("v") === "t";
  const [isSharing, setIsSharing] = useState(false);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [shareDialogOpenFriends, setShareDialogOpenFriends] = useState(false);
  const quizSectionRef = useRef(null);
  const exercisesSectionRef = useRef(null);
  const timelineSectionRef = useRef(null);
  const timelineSimpleSectionRef = useRef(null);
  const translateButtonRef = useRef(null);
  const shareLinkButtonRef = useRef(null);
  const quizButtonRef = useRef(null);
  const timelineButtonRef = useRef(null);
  const timelineSimpleButtonRef = useRef(null);
  const comprehensionButtonRef = useRef(null);
  const [isExpanded, setIsExpanded] = useState(false);
  // Add click handlers for the DashedPaper components
  const handleQuizPaperClick = () => {
    quizButtonRef.current?.click();
  };

  const handleTimelineSimplePaperClick = () => {
    timelineSimpleButtonRef.current?.click();
  };

  const handleComprehensionPaperClick = () => {
    comprehensionButtonRef.current?.click();
  };

  const handleTimelinePaperClick = () => {
    timelineButtonRef.current?.click();
  };

  const { lessonId } = useParams();
  useEffect(() => {
    const loadLessonFromId = async () => {
      if (lessonId) {
        try {
          const q = query(
            collection(db, "lesson_usage"),
            where("__name__", ">=", lessonId),
            where("__name__", "<=", lessonId + "\uf8ff"),
            where("lessonType", "==", lessonConfig.lessonType),
            limit(1),
          );

          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            const docData = querySnapshot.docs[0].data();

            if (!docData.hasOpened) {
              await updateDoc(querySnapshot.docs[0].ref, {
                hasOpened: true,
                openedAt: Date.now(),
              });
            }

            setLessonData(docData.data);
            setTitleText(docData.data.title);
            // Scroll to lesson content
            setTimeout(() => {
              lessonTitleRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }, 100);

            showSnackbar("Lektionen har laddats in.", "success");
          } else {
            showSnackbar("Kunde inte hitta lektionen", "error");
          }
        } catch (error) {
          console.error("Error loading lesson:", error);
          showSnackbar("Ett fel uppstod när lektionen skulle laddas", "error");
        }
      }
    };

    loadLessonFromId();
  }, lessonId);

  useEffect(() => {
    Logger.log("lessonData: ", lessonData);
    const isDataValid =
      lessonData !== null &&
      lessonData !== undefined &&
      Object.keys(lessonData).length > 0 &&
      !Object.values(lessonData).includes("");

    if (isDataValid !== showControlPanel) {
      const timer = setTimeout(() => {
        setShowControlPanel(isDataValid);
      }, 100);

      return () => clearTimeout(timer);
    }
  }, [lessonData, showControlPanel]);

  const toggleViewMode = () => {
    const currentParams = Object.fromEntries(searchParams.entries());
    if (currentParams.v === "t") {
      delete currentParams.v;
    } else {
      currentParams.v = "t";
    }
    setSearchParams(currentParams);
  };

  const handleShare = async (
    lessonComment,
    lessonName,
    selectedImage,
    tags,
    shareData,
  ) => {
    setIsSharing(true);
    await new Promise((resolve) => setTimeout(resolve, 1500));
    try {
      // Create a document for each student and then create notifications with the doc IDs
      const sharePromises = shareData.studentIds.map(async (studentId) => {
        const documentData = {
          userId: user.uid,
          shared: true,
          sharedTo: studentId,
          comment: shareData.comment,
          sharedAt: Date.now(),
          fileUrl: shareData.fileUrl,
          lessonType: lessonConfig.lessonType,
          timestamp: serverTimestamp(),
          lessonComment,
          lessonName,
          coverImage: selectedImage,
          hasPrinted: true,
          data: lessonData,
        };

        // Create the lesson document and get its ID
        const lessonDoc = await addDoc(
          collection(db, "lesson_usage"),
          documentData,
        );

        Logger.log(
          "Adding document for student",
          studentId,
          ":",
          JSON.stringify(documentData, null, 2),
        );

        // Create notification with the lesson doc ID
        await addDoc(collection(db, "notifications"), {
          type: "lesson_shared",
          recipientId: studentId,
          senderId: user.uid,
          read: false,
          createdAt: serverTimestamp(),
          lessonData: {
            lessonId: lessonDoc.id,
            lessonType: documentData.lessonType,
            lessonName: documentData.lessonName,
            fileUrl: documentData.fileUrl,
            comment: documentData.comment,
            coverImage: selectedImage,
          },
        });

        return lessonDoc;
      });

      await Promise.all(sharePromises);
      showSnackbar(`Du har nu delat lektionen!`, "success");
    } catch (error) {
      showSnackbar("Fel vid delande av lektionen: " + error.message, "error");
      console.error(error);
    } finally {
      setIsSharing(false);
    }
  };

  const handleSaveLessonClick = () => {
    setSaveDialogOpen(true);
  };

  const handleSaveWithComment = async (comment, name, coverImage, tags) => {
    Logger.log("handleSaveWithComment: ", JSON.stringify(lessonData, null, 2));
    await trackLesson(
      user.uid,
      lessonConfig.lessonType,
      lessonData,
      null,
      true,
      comment,
      name,
      coverImage,
      tags,
    );
    setDrawerOpen(true);
    showSnackbar("Lektionen sparad i din lektionsbank!", "success");
  };

  const handleGenerateSpecificImage = async () => {
    handleCloseModal();
    if (imageGenerationType === "topic") {
      setIsGeneratingTopicImage(true);
      try {
        const prompt = `Skapa en bild som representerar ämnet ${lessonData.topic}. ${imageInstructions}`;
        const imageUrl = await generateAndProcessImage(prompt, user.uid);
        setLessonData((prevData) => ({
          ...prevData,
          topicImage: imageUrl,
        }));
        showSnackbar("Ämnesbild genererad framgångsrikt.", "success");
      } catch (error) {
        showSnackbar(
          "Misslyckades med att generera ämnesbild. Försök igen.",
          "error",
        );
      } finally {
        setIsGeneratingTopicImage(false);
      }
    } else if (
      imageGenerationType === "participants" &&
      selectedParticipantId
    ) {
      const participant = lessonData.participants[selectedParticipantId];
      setIsGeneratingParticipantImages(true);
      setLoadingImageIndex(selectedParticipantId);
      try {
        const prompt = `Skapa en porträttbild som representerar ${participant.name}, ${participant.role}. Bilden ska reflektera deras karakteristik: ${participant.characteristics}. ${imageInstructions}`;
        const imageUrl = await generateAndProcessImage(prompt, user.uid);

        setLessonData((prevData) => ({
          ...prevData,
          participants: {
            ...prevData.participants,
            [selectedParticipantId]: {
              ...prevData.participants[selectedParticipantId],
              image: imageUrl,
            },
          },
        }));
        showSnackbar("Deltagarbild har genererats!", "success");
      } catch (error) {
        showSnackbar("Fel vid generering av deltagarbild.", "error");
      } finally {
        setIsGeneratingParticipantImages(false);
        setLoadingImageIndex(null);
        setSelectedParticipantId(null);
      }
    }
  };

  const handleClearText = (index, type = "message") => {
    setLessonData((prevData) => ({
      ...prevData,
      conversation: prevData.conversation.map((msg, i) =>
        i === index ? { ...msg, [type]: "" } : msg,
      ),
    }));
    showSnackbar(
      `${type === "message" ? "Meddelandet" : "Översättningen"} har tömts`,
      "success",
    );
  };

  const handleEditMessage = (index, newText, type = "message") => {
    setLessonData((prevData) => ({
      ...prevData,
      conversation: prevData.conversation.map((msg, i) =>
        i === index ? { ...msg, [type]: newText } : msg,
      ),
    }));
    type === "message"
      ? setEditingMessageId(null)
      : setEditingTranslationId(null);
    showSnackbar(
      `${type === "message" ? "Meddelandet" : "Översättningen"} har uppdaterats`,
      "success",
    );
  };

  const toneOptions = [
    { value: "lekfull", label: "Lekfull" },
    { value: "akademisk", label: "Akademisk" },
    { value: "informell", label: "Informell" },
    { value: "formell", label: "Formell" },
    { value: "debatt", label: "Debatt" },
    { value: "neutral", label: "Neutral" },
  ];

  const handleImageButtonClick = (type) => {
    setImageGenerationType(type);
    setShowImageModal(true);
  };

  const handleCloseModal = () => {
    setShowImageModal(false);
    setImageInstructions("");
  };

  const handleGenerateWithInstructions = async () => {
    handleCloseModal();
    if (imageGenerationType === "topic") {
      await handleGenerateTopicImage();
    } else if (imageGenerationType === "participants") {
      await handleGenerateParticipantImages();
    }
  };

  const simulateProgress = (
    startProgress,
    endProgress,
    duration,
    setProgressFn,
  ) => {
    const stepSize = 0.5;
    const steps = (endProgress - startProgress) / stepSize;
    const stepDuration = duration / steps;
    let currentProgress = startProgress;

    const intervalId = setInterval(() => {
      currentProgress += stepSize;
      if (currentProgress >= endProgress) {
        clearInterval(intervalId);
      } else {
        setProgressFn(currentProgress);
      }
    }, stepDuration);

    return () => clearInterval(intervalId);
  };

  const handleGenerateConversation = async () => {
    setIsGenerating(true);
    setProgress(0);
    setLessonData(null);
    let stopProgressSimulation = null;
    try {
      stopProgressSimulation = simulateProgress(0, 90, 15000, setProgress);

      let themeToUse;

      if (lessonTheme === "youtube") {
        themeToUse = transcriptRef.current;
      } else if (lessonTheme === "customTheme") {
        themeToUse = customTheme;
      } else if (lessonTheme === "predefined") {
        themeToUse = predefinedTheme;
      } else if (lessonTheme === "fileUpload") {
        themeToUse = uploadedFileRef.current;
      } else if (lessonTheme === "wikipedia") {
        themeToUse = wikipediaContentRef.current;
      } else if (lessonTheme === "randomizer") {
        themeToUse = randomizedContentRef.current;
      } else if (lessonTheme === "book") {
        themeToUse = bookContentRef.current;
      } else if (lessonTheme === "url") {
        themeToUse = urlContentRef.current;
      } else {
        //
        themeToUse = coursePlanContentRef.current;
      }

      Logger.log("themeToUse: ", themeToUse);

      if (!themeToUse.trim()) {
        showSnackbar("Du måste välja underlag i steg 1.", "warning");
        return;
      }

      const gradeInstructionsText = gradeInstructions[grade];

      const response = await callApi(
        [
          {
            role: "system",
            content: `You are an assistant that generates engaging educational conversations between multiple participants. The conversation should be natural and flow well while maintaining the specified tone. ${openAiInstructions}. ${gradeInstructionsText}.`,
          },
          {
            role: "user",
            content: `Generate a JSON object with a conversation between ${participantCount} participants about ${themeToUse} in "${language}"${showTranslation ? ` with translations in "${translationLanguage}"` : ""}. The conversation should have ${messageCount} messages and be in a ${conversationTone} tone.

      Include characteristics for each participant that influences their perspective in the conversation.
      
      Example response:
{
  "topic": "Klimatförändringar",
  "topicImage": null,
  "title": "Dialog om klimatförändringarnas påverkan",
  "participants": {
    "participant1": {
      "name": "Maria",
      "role": "Klimatforskare",
      "characteristics": "Faktadriven och analytisk",
      "image": null
    },
    "participant2": {
      "name": "Erik",
      "role": "Miljöaktivist",
      "characteristics": "Passionerad och handlingsinriktad",
      "image": null
    }
  },
 "conversation": [
    {
      "speaker": "Maria",
      "message": "Enligt de senaste forskningsrapporterna ser vi en alarmerande ökning av den globala medeltemperaturen"
      ${showTranslation ? `,"translation": "Översättning på ${translationLanguage}"` : ""}
    },
    {
      "speaker": "Erik",
      "message": "Det är precis vad vi har varnat för! Vi måste agera nu innan det är för sent"
      ${showTranslation ? `,"translation": "Översättning på ${translationLanguage}"` : ""}
    }
  ]
}`,
          },
        ],
        8000,
        true,
        user.uid,
      );

      if (stopProgressSimulation) {
        stopProgressSimulation();
      }

      setProgress(95);

      let messageContent = response.data.choices[0].message.content.trim();
      if (messageContent.startsWith("```json")) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith("```")) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      const data = JSON.parse(messageContent);
      setProgress(100);
      await new Promise((resolve) => setTimeout(resolve, 800));

      setLessonData(data);
      setTitleText(data.title);
      showSnackbar("Konversationslektionen har genererats!", "success");

      // Scroll to lesson content
      setTimeout(() => {
        lessonTitleRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, 100);

      await trackLesson(user.uid, lessonConfig.lessonType, null, {
        participantCount,
        language,
        showTranslation,
        translationLanguage,
        themeToUse,
      });
    } catch (error) {
      console.error("Error generating conversation:", error);
      showSnackbar("Något gick fel. Försök igen.", "error");
    } finally {
      setIsGenerating(false);
    }
  };

  const handleGenerateTopicImage = async () => {
    setIsGeneratingTopicImage(true);
    try {
      const prompt = `Skapa en bild som representerar ämnet ${lessonData.topic}. ${imageInstructions}`;
      const imageUrl = await generateAndProcessImage(prompt, user.uid);
      setLessonData((prevData) => ({
        ...prevData,
        topicImage: imageUrl,
      }));
      showSnackbar("Ämnesbild genererad framgångsrikt.", "success");
    } catch (error) {
      showSnackbar(
        "Misslyckades med att generera ämnesbild. Försök igen.",
        "error",
      );
    } finally {
      setIsGeneratingTopicImage(false);
    }
  };

  const handleGenerateParticipantImages = async () => {
    setIsGeneratingParticipantImages(true);
    setGeneratedImages(new Set());
    try {
      for (const [id, participant] of Object.entries(lessonData.participants)) {
        try {
          const prompt = `Skapa en porträttbild som representerar ${participant.name}, ${participant.role}. Bilden ska reflektera deras karakteristik: ${participant.characteristics}. ${imageInstructions}`;
          const imageUrl = await generateAndProcessImage(prompt, user.uid);

          setLessonData((prevData) => ({
            ...prevData,
            participants: {
              ...prevData.participants,
              [id]: {
                ...prevData.participants[id],
                image: imageUrl,
              },
            },
          }));

          setGeneratedImages((prev) => new Set([...prev, id]));
        } catch (error) {
          console.error(
            `Error generating image for participant "${id}":`,
            error,
          );
          continue;
        }
      }
      showSnackbar("Alla deltagarbilder har genererats!", "success");
    } catch (error) {
      showSnackbar("Fel vid generering av deltagarbilder.", "error");
    } finally {
      setIsGeneratingParticipantImages(false);
    }
  };

  const isGeneratingAnyImages =
    isGeneratingTopicImage || isGeneratingParticipantImages;

  return (
    <Box sx={styles.container}>
      <Box
        sx={{
          ...styles.innerContainer,
          width: { xs: "100%", md: "100%" },
          margin: "0 auto",
        }}
      >
        {!viewMode && (
          <Typography
            variant="h1"
            gutterBottom
            sx={{
              fontWeight: "bold",
              fontSize: "1.8em",
              mb: 3,
              mt: 5,
              textAlign: "center",
            }}
          >
            {lessonConfig.title}
          </Typography>
        )}
        <LessonDrawer
          userId={user.uid}
          lessonType={lessonConfig.lessonType}
          theme={theme}
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          lessonIcon={ChatIcon}
          onSelectLesson={(lesson) => {
            setLessonData(lesson.data);

            setDrawerOpen(false);

            showSnackbar(`Lektionen har laddats in.`, "success");
            setTimeout(() => {
              lessonTitleRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }, 100);
          }}
        />
        <Box
          sx={{
            ...styles.buttonsContainer,
            ...styles.additionalButtonsContainer,
          }}
        >
          {lessonData && user.accountType === "teacher" && (
            <Button
              variant="purp"
              sx={{ ...styles.tightButton }}
              disableRipple
              onClick={() => {
                setTimeout(() => {
                  lessonTitleRef.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "start", // This ensures it scrolls to the top of the element
                  });
                }, 100);
                toggleViewMode();
              }}
              startIcon={
                searchParams.get("v") === "t" ? (
                  <FullscreenExitIcon />
                ) : (
                  <FullscreenIcon />
                )
              }
            >
              {searchParams.get("v") === "t" ? "Redigeringsläge" : "Elevläge"}
            </Button>
          )}
          {!viewMode && (
            <Button
              variant="purp"
              sx={{ ...styles.tightButton }}
              onClick={() => setDrawerOpen(true)}
              startIcon={<BookmarkBorderIcon />}
            >
              Sparat
            </Button>
          )}
        </Box>
        {!viewMode && (
          <>
            <InfoBox
              icon={lessonConfig.icon}
              title="Instruktioner för Konversations Generator"
              instructions={[
                "Välj antal deltagare i konversationen",
                "Ange samtalets tema och ton och simulera en konversation",
                "Generera bilder för tema och deltagare",
              ]}
              theme={theme}
              styles={styles}
            />
            <ThemeSelectorFlow
              key={variant}
              subject={subject}
              value={lessonTheme}
              onChange={(e) => setLessonTheme(e.target.value)}
              customTheme={customTheme}
              onCustomThemeChange={(e) => setCustomTheme(e.target.value)}
              onPredefinedThemeChange={(e) =>
                setPredefinedTheme(e.target.value)
              }
              onBookContentChange={(content) => {
                Logger.log("onBookContentChange", content.substring(0, 100));
                bookContentRef.current = content;
              }}
              onUrlContentChange={(content) => {
                Logger.log(
                  "Parent: URL content received",
                  content.substring(0, 300),
                );
                urlContentRef.current = content;
              }}
              onYoutubeTranscriptChange={(transcriptContent) => {
                Logger.log(
                  "onYoutubeTranscriptChange körs med: ",
                  transcriptContent,
                );
                transcriptRef.current = transcriptContent;
              }}
              onFileUpload={(content) => {
                Logger.log("onFileUpload körs med: ", content);
                uploadedFileRef.current = content;
              }}
              onWikipediaContentChange={(content) => {
                Logger.log(
                  "Parent: Wikipedia content received",
                  content.substring(0, 100),
                );
                wikipediaContentRef.current = content;
              }}
              onRandomizedContentChange={(content) => {
                Logger.log("randomizedContentRef", content.substring(0, 100));
                randomizedContentRef.current = content;
              }}
              onCoursePlanContentChange={(content) => {
                Logger.log("onCoursePlanChange", content.substring(0, 100));
                coursePlanContentRef.current = content;
              }}
              lessonType={lessonConfig.lessonType}
              user={user}
              theme={theme}
              styles={styles}
              onLessonSelect={(lesson) => {
                Logger.log("onLessonSelect körs");
                setLessonData(lesson.data);
                setTimeout(() => {
                  lessonTitleRef.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "start", // This ensures it scrolls to the top of the element
                  });
                }, 100);
                showSnackbar("Lektionen har laddats in.", "success");
              }}
              additionalThemes={additionalThemes}
            />
            <Box sx={styles.headlineLessonBox}>
              <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
                2. Generera lektionen
              </Typography>
            </Box>
            <Box sx={{ ...styles.freshBox, padding: "30px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "500", mt: 0 }}
                  >
                    Årskurs:
                  </Typography>
                  <Box sx={{ mt: 1, mb: 1 }}>
                    <GradeSelector grade={grade} setGrade={setGrade} />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "500", mt: 0 }}
                  >
                    Språk:
                  </Typography>
                  <Box sx={{ mt: 0.9, mb: 1 }}>
                    <LanguageSelector
                      language={language}
                      setLanguage={setLanguage}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "500", mt: 1 }}
                  >
                    Antal deltagare:
                  </Typography>
                  <FormControl fullWidth margin="normal" sx={{ mb: 1, mt: 1 }}>
                    <Select
                      value={participantCount}
                      onChange={(e) => setParticipantCount(e.target.value)}
                    >
                      {[2, 3, 4, 5].map((num) => (
                        <MenuItem key={num} value={num}>
                          {num}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "500", mt: 1 }}
                  >
                    Antal meddelanden:
                  </Typography>
                  <FormControl fullWidth margin="normal" sx={{ mb: 1, mt: 1 }}>
                    <Select
                      value={messageCount}
                      onChange={(e) => setMessageCount(e.target.value)}
                    >
                      {[4, 6, 8, 10, 12, 14, 16, 18, 20, 25, 30].map((num) => (
                        <MenuItem key={num} value={num}>
                          {num}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "500", mt: 1 }}
                  >
                    Samtalston:
                  </Typography>
                  <FormControl fullWidth margin="normal" sx={{ mb: 1, mt: 1 }}>
                    <Select
                      value={conversationTone}
                      onChange={(e) => setConversationTone(e.target.value)}
                    >
                      {toneOptions.map((tone) => (
                        <MenuItem key={tone.value} value={tone.value}>
                          {tone.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={12}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "500", mt: 2 }}
                  >
                    Vad ska konversationen fokusera på? (valfritt):
                  </Typography>
                  <FormControl fullWidth margin="normal" sx={{ mb: 3, mt: 1 }}>
                    <TextField
                      value={openAiInstructions}
                      onChange={(e) => setOpenAiInstructions(e.target.value)}
                      placeholder="T.ex. Konversationen ska handla om hur det är att upptäcka andra kulturer."
                      variant="outlined"
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={12}>
                  <FormControl component="fieldset" sx={{ mt: 1 }}>
                    <Typography
                      variant="subtitle1"
                      sx={{ fontWeight: "500", mb: 1 }}
                    >
                      Visa översättning för varje meddelande?
                    </Typography>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={showTranslation}
                          onChange={(e) => setShowTranslation(e.target.checked)}
                          color="primary"
                        />
                      }
                      label={showTranslation ? "Ja" : "Nej"}
                    />
                  </FormControl>
                </Grid>

                {showTranslation && (
                  <Grid item xs={12} md={12}>
                    <Typography
                      variant="subtitle1"
                      sx={{ fontWeight: "500", mt: 2, mb: 1 }}
                    >
                      Välj språk för översättning:
                    </Typography>
                    <LanguageSelector
                      language={translationLanguage}
                      setLanguage={setTranslationLanguage}
                    />
                  </Grid>
                )}
              </Grid>

              <Button
                variant={theme.palette.mode === "light" ? "purp" : "contained"}
                color="primary"
                onClick={handleGenerateConversation}
                disabled={isGenerating || isGeneratingAnyImages}
                sx={{ mt: 5 }}
                startIcon={
                  !isGenerating ? (
                    <GenerateIcon style={{ color: "#fff" }} />
                  ) : (
                    <CircularProgress style={{ color: "#fff" }} size={16} />
                  )
                }
              >
                {isGenerating
                  ? "Genererar konversation..."
                  : "Generera lektion"}
              </Button>

              {isGenerating && (
                <Box sx={{ mt: 7 }}>
                  <ProgressBar
                    progress={progress}
                    label="Genererar lektionen — tar 30-60 sekunder..."
                  />
                </Box>
              )}
            </Box>
          </>
        )}
        {lessonData && (
          <>
            {!viewMode && (
              <Box
                ref={lessonTitleRef}
                sx={{ ...styles.headlineLessonBox, mt: 10 }}
              >
                <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
                  3. Din lektion
                </Typography>
              </Box>
            )}
            <Box
              sx={{
                ...styles.freshBox,
                padding: "30px",

                width: viewMode ? "70%" : undefined,
                margin: viewMode ? "0 auto" : undefined,
                marginTop: viewMode ? "0px" : undefined,
                ...(viewMode && { backgroundColor: "transparent" }),
                ...(viewMode && { borderColor: "transparent" }),
              }}
            >
              {!viewMode && (
                <Box sx={{ position: "relative" }}>
                  <Box
                    sx={{
                      ...styles.lessonControlPanel,
                      ...styles.lessonControlPanelNuevo,

                      "& .MuiButton-root": {
                        ...styles.controlPanelButtons,
                        ...styles.lessonControlPanelNewButtons,
                      },
                    }}
                  >
                    <Button
                      variant="print"
                      startIcon={<SaveAsIcon />}
                      sx={{ ml: 2 }}
                      onClick={handleSaveLessonClick}
                    >
                      Spara i lektionsbank
                    </Button>

                    <Button
                      variant="success"
                      sx={{}}
                      onClick={() => setShareDialogOpenFriends(true)}
                      startIcon={<ShareIcon />}
                    >
                      Dela med vän
                    </Button>

                    <Button
                      variant="success"
                      sx={{
                        mt: 2,
                        ml: 2,
                      }}
                      onClick={() => setShareDialogOpen(true)}
                      startIcon={<ShareIcon />}
                    >
                      Dela med elever
                    </Button>
                    <ShareLink
                      buttonRef={shareLinkButtonRef}
                      key={lessonId}
                      lessonId={lessonId}
                      lessonData={lessonData}
                      user={user}
                      lessonType={lessonConfig.lessonType}
                      onCopy={(message, severity) =>
                        showSnackbar(message, severity)
                      }
                    />

                    <Tooltip title="Hur lektionen ser ut för eleverna">
                      <Button
                        variant="success"
                        onClick={() => {
                          window.scrollTo({ top: 0, behavior: "smooth" });
                          toggleViewMode();
                        }}
                        sx={{ ml: 2, mt: 2 }}
                        startIcon={
                          searchParams.get("v") === "t" ? (
                            <FullscreenExitIcon />
                          ) : (
                            <FullscreenIcon />
                          )
                        }
                      >
                        {searchParams.get("v") === "t"
                          ? "Redigeringsläge"
                          : "Elevläge"}
                      </Button>
                    </Tooltip>

                    <Button
                      variant={
                        theme.palette.mode === "light" ? "purp" : "contained"
                      }
                      color="primary"
                      onClick={() => handleImageButtonClick("topic")}
                      disabled={isGeneratingAnyImages}
                      startIcon={
                        !isGeneratingTopicImage ? (
                          <ImageIcon />
                        ) : (
                          <CircularProgress
                            style={{ color: "#fff" }}
                            size={16}
                          />
                        )
                      }
                    >
                      {isGeneratingTopicImage
                        ? "Genererar..."
                        : "Generera ämnesbild"}
                    </Button>

                    <Button
                      variant={
                        theme.palette.mode === "light" ? "purp" : "contained"
                      }
                      color="primary"
                      onClick={() => handleImageButtonClick("participants")}
                      disabled={isGeneratingAnyImages}
                      sx={{ ml: 2 }}
                      startIcon={
                        !isGeneratingParticipantImages ? (
                          <ImageIcon />
                        ) : (
                          <CircularProgress
                            style={{ color: "#fff" }}
                            size={16}
                          />
                        )
                      }
                    >
                      {isGeneratingParticipantImages
                        ? "Genererar..."
                        : "Generera deltagarbilder"}
                    </Button>

                    <Button
                      variant="print"
                      color="primary"
                      onClick={() =>
                        handlePrintConversationLesson(
                          lessonData,
                          titleText,
                          theme,
                        )
                      }
                      startIcon={<PrintIcon />}
                      sx={{ ml: 2 }}
                    >
                      Skriv ut
                    </Button>

                    <Box sx={{ display: "inline-block" }}>
                      <AddonQuiz
                        buttonRef={quizButtonRef}
                        lessonData={lessonData}
                        title={lessonData.title}
                        theme={theme}
                        user={user}
                        styles={styles}
                        showSnackbar={showSnackbar}
                        addQuizToLessonObject={(updatedData) => {
                          setLessonData(updatedData);
                          setTimeout(
                            () =>
                              quizSectionRef.current?.scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                              }),
                            100,
                          );
                        }}
                        buttonText="Lägg till interaktivt quiz"
                      />
                    </Box>
                    <Box sx={{ display: "inline-block" }}>
                      <ReadingComprehensionDialog
                        buttonRef={comprehensionButtonRef}
                        key={lessonData?.title}
                        lessonData={lessonData}
                        user={user}
                        theme={theme}
                        styles={styles}
                        buttonText="Lägg till övningar"
                        addExercisesToLessonObject={(updateFunction) => {
                          setLessonData((prevLessonData) => ({
                            ...prevLessonData,
                            exercises: [
                              ...(prevLessonData.exercises || []),
                              ...updateFunction(prevLessonData).exercises,
                            ],
                          }));
                          setTimeout(
                            () =>
                              exercisesSectionRef.current?.scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                              }),
                            100,
                          );
                        }}
                      />
                    </Box>

                    <Box sx={{ display: "inline-block" }}>
                      <AddonTimeline
                        buttonRef={timelineButtonRef}
                        lessonData={lessonData}
                        theme={theme}
                        user={user}
                        styles={styles}
                        showSnackbar={showSnackbar}
                        addTimelineToLessonObject={(updatedData) => {
                          setLessonData(updatedData);
                          setTimeout(
                            () =>
                              timelineSectionRef.current?.scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                              }),
                            100,
                          );
                        }}
                        buttonText="Lägg till interaktiv tidslinje"
                      />
                    </Box>
                    <Box sx={{ display: "inline-block" }}>
                      <AddonTimelineSimple
                        buttonRef={timelineSimpleButtonRef}
                        lessonData={lessonData}
                        theme={theme}
                        user={user}
                        styles={styles}
                        showSnackbar={showSnackbar}
                        addTimelineToLessonObject={(updatedData) => {
                          setLessonData(updatedData);
                          setTimeout(
                            () =>
                              timelineSimpleSectionRef.current?.scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                              }),
                            100,
                          );
                        }}
                        buttonText="Lägg till enkel tidslinje"
                      />
                    </Box>
                  </Box>
                </Box>
              )}
              <SaveLessonDialog
                key={titleText || ""}
                open={saveDialogOpen}
                onClose={() => setSaveDialogOpen(false)}
                onSave={handleSaveWithComment}
                theme={theme}
                lessonName={titleText || ""}
                lessonObject={lessonData}
                lessonType={lessonConfig.lessonType}
              />
              <ImageGenerationModal
                open={showImageModal}
                onClose={handleCloseModal}
                onGenerate={
                  selectedParticipantId || imageGenerationType === "topic"
                    ? handleGenerateSpecificImage
                    : handleGenerateWithInstructions
                }
                isGenerating={
                  isGeneratingTopicImage || isGeneratingParticipantImages
                }
                instructions={imageInstructions}
                setInstructions={setImageInstructions}
                generationType={imageGenerationType}
                theme={theme}
              />

              <ConversationDisplay
                lessonData={lessonData}
                titleText={titleText}
                setTitleText={setTitleText}
                isEditingTitle={isEditingTitle}
                setIsEditingTitle={setIsEditingTitle}
                isGeneratingTopicImage={isGeneratingTopicImage}
                isGeneratingParticipantImages={isGeneratingParticipantImages}
                loadingImageIndex={loadingImageIndex}
                handleImageButtonClick={handleImageButtonClick}
                setImageGenerationType={setImageGenerationType}
                setShowImageModal={setShowImageModal}
                setSelectedParticipantId={setSelectedParticipantId}
                editingMessageId={editingMessageId}
                setEditingMessageId={setEditingMessageId}
                editingTranslationId={editingTranslationId}
                setEditingTranslationId={setEditingTranslationId}
                handleEditMessage={handleEditMessage}
                handleClearText={handleClearText}
                showSnackbar={showSnackbar}
                user={user}
                theme={theme}
                language={language}
                translationLanguage={translationLanguage}
                setLessonData={setLessonData}
                viewMode={viewMode}
              />

              {lessonData?.exercises && (
                <Box ref={exercisesSectionRef}>
                  <LessonExercisesDisplay
                    exercises={lessonData.exercises}
                    onUpdateLessonData={setLessonData}
                    viewMode={viewMode}
                    theme={theme}
                    styles={styles}
                  />
                </Box>
              )}

              {lessonData?.quiz && (
                <Box
                  ref={quizSectionRef}
                  sx={{ mt: 5, mb: 5, position: "relative" }}
                >
                  <LessonQuizDisplay
                    quizData={lessonData.quiz}
                    theme={theme}
                    styles={styles}
                    userId={user.uid}
                    viewMode={viewMode}
                    setLessonData={setLessonData}
                  />
                </Box>
              )}

              {lessonData?.timelinesSimple && (
                <Box
                  ref={timelineSimpleSectionRef}
                  sx={{ mt: 5, mb: 5, position: "relative" }}
                >
                  <TimelineSimpleDisplay
                    timelineData={lessonData.timelinesSimple}
                    onTimelineDataChange={(newData) =>
                      setLessonData({
                        ...lessonData,
                        timelinesSimple: newData,
                      })
                    }
                    theme={theme}
                    styles={styles}
                    viewMode={viewMode}
                    user={user}
                  />
                </Box>
              )}

              {lessonData?.timelines && (
                <Box
                  ref={timelineSectionRef}
                  sx={{ mt: 5, mb: 5, position: "relative" }}
                >
                  <TimelineDisplay
                    timelineData={lessonData.timelines}
                    onTimelineDataChange={(newData) =>
                      setLessonData({ ...lessonData, timelines: newData })
                    }
                    theme={theme}
                    styles={styles}
                    withImages={true}
                    viewMode={viewMode}
                    user={user}
                    onDelete={
                      viewMode
                        ? undefined
                        : (indexToDelete) => {
                            setLessonData((prev) => ({
                              ...prev,
                              timelines: prev.timelines.filter(
                                (_, index) => index !== indexToDelete,
                              ),
                            }));
                            showSnackbar(
                              "Tidslinjen har tagits bort",
                              "success",
                            );
                          }
                    }
                  />
                </Box>
              )}

              {!viewMode && (
                <DashedPapers
                  handleQuizPaperClick={handleQuizPaperClick}
                  handleComprehensionPaperClick={handleComprehensionPaperClick}
                  handleTimelinePaperClick={handleTimelinePaperClick}
                  handleTimelineSimplePaperClick={
                    handleTimelineSimplePaperClick
                  }
                  quizSectionRef={quizSectionRef}
                  timelineSectionRef={timelineSectionRef}
                  timelineSimpleSectionRef={timelineSimpleSectionRef}
                  exercisesSectionRef={exercisesSectionRef}
                  lessonData={lessonData}
                  setLessonData={setLessonData}
                />
              )}

              <SlideInPaper
                isExpanded={isExpanded}
                onExpandedChange={setIsExpanded}
                lessonTitleRef={lessonTitleRef}
                viewMode={viewMode}
                toggleViewMode={toggleViewMode}
                theme={theme}
                open={showControlPanel}
                user={user}
                onPrint={() =>
                  handlePrintConversationLesson(lessonData, titleText, theme)
                }
                onShareLink={() => shareLinkButtonRef.current?.click()}
                onShareWithStudents={() => setShareDialogOpen(true)}
                onShareWithFriends={() => setShareDialogOpenFriends(true)}
                onSaveToBank={() => setSaveDialogOpen(true)}
                onStudentMode={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                  toggleViewMode();
                }}
                onGenerateExercises={() => {
                  comprehensionButtonRef.current?.click();
                }}
                onGenerateQuiz={() => {
                  quizButtonRef.current?.click();
                }}
                onGenerateAssignments={() => {
                  comprehensionButtonRef.current?.click();
                }}
                onGenerateTopicImage={() => handleImageButtonClick("topic")}
                onGenerateParticipantImages={() =>
                  handleImageButtonClick("participants")
                }
                onGenerateTimeline={() => {
                  timelineButtonRef.current?.click();
                }}
              />

              <ShareDialog
                open={shareDialogOpen}
                onClose={() => setShareDialogOpen(false)}
                onShare={handleShare}
                user={user}
                isLessonSharing={true}
                theme={theme}
                lessonName={lessonData?.title || ""}
                lessonObject={lessonData}
                lessonType={lessonConfig.lessonType}
              />

              <ShareDialog
                open={shareDialogOpenFriends}
                onClose={() => setShareDialogOpenFriends(false)}
                onShare={handleShare}
                user={user}
                isLessonSharing={true}
                theme={theme}
                lessonName={lessonData?.title || ""}
                lessonObject={lessonData}
                lessonType={lessonConfig.lessonType}
                shareWithFriends={true}
              />

              <ImageLoadingDialog
                open={isGeneratingParticipantImages || isGeneratingTopicImage}
                message="Genererar bilder... Tar 30-60 sekunder."
              />

              <LoadingBackdrop
                isOpen={isSharing}
                imagePath="/img/AI.png"
                loadingText={"Delar lektionen..."}
              />
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default ConversationLesson;
