import React from "react";
import { Box, Typography, Button, Paper, Chip } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
const ResultsView = ({
  results,
  timelines,
  onRestart,
  toggleViewMode,
  viewMode,
  elapsedTime,
}) => {
  // Calculate total errors and correct answers
  const { totalErrors, totalCorrect } = results.reduce(
    (acc, timelineResult, timelineIndex) => {
      const correctOrder = timelines[timelineIndex].timeline.sort(
        (a, b) => a.year - b.year,
      );

      const timelineStats = timelineResult.reduce(
        (stats, event, index) => {
          const isCorrect = event.year === correctOrder[index].year;
          return {
            errors: stats.errors + (isCorrect ? 0 : 1),
            correct: stats.correct + (isCorrect ? 1 : 0),
          };
        },
        { errors: 0, correct: 0 },
      );

      return {
        totalErrors: acc.totalErrors + timelineStats.errors,
        totalCorrect: acc.totalCorrect + timelineStats.correct,
      };
    },
    { totalErrors: 0, totalCorrect: 0 },
  );

  const timePerError = 30; // 30 seconds per error
  const penaltyTime = totalErrors * timePerError;
  const finalTime = elapsedTime + penaltyTime;

  return (
    <Box sx={{ mb: 6, position: "relative", marginBottom: "200px" }}>
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          textAlign: "center",
          fontWeight: "600",
          color: "#dc143c",
          fontFamily: "Plantin, Georgia, calibri",
          textTransform: "uppercase",
          fontSize: "2.4rem",
          mb: 2,
          mt: 8,
        }}
      >
        Resultat
      </Typography>

      <Box sx={{ mb: 8 }}>
        <Box sx={{ textAlign: "center", mb: 4 }}>
          <Typography variant="h6">
            Förlupen tid: {Math.floor(elapsedTime / 60)}m {elapsedTime % 60}s
          </Typography>

          <Typography variant="h6">Rätt svar: {totalCorrect}</Typography>

          <Typography variant="h6">Fel svar: {totalErrors}</Typography>

          <Typography variant="h6">
            Tidstillägg för fel: {totalErrors} fel × {timePerError}{" "}
            straffsekunder = {penaltyTime}s
          </Typography>
          <Typography variant="h5" sx={{ fontWeight: "bold", mt: 2 }}>
            Slutgiltig tid: {Math.floor(finalTime / 60)}m {finalTime % 60}s
          </Typography>
        </Box>

        {results.map((timelineResult, timelineIndex) => {
          const correctOrder = timelines[timelineIndex].timeline.sort(
            (a, b) => a.year - b.year,
          );
          return (
            <Box key={timelineIndex} sx={{ mb: 4 }}>
              <Typography
                variant="h6"
                sx={{ mb: 2, mt: 9, textAlign: "center" }}
              >
                Tidslinje {timelineIndex + 1}: {timelines[timelineIndex].title}{" "}
                (
                {
                  timelineResult.reduce(
                    (acc, event, index) => {
                      const isCorrect = event.year === correctOrder[index].year;
                      return {
                        correct: acc.correct + (isCorrect ? 1 : 0),
                        errors: acc.errors + (isCorrect ? 0 : 1),
                      };
                    },
                    { correct: 0, errors: 0 },
                  ).correct
                }{" "}
                rätt,{" "}
                {
                  timelineResult.reduce(
                    (acc, event, index) => {
                      const isCorrect = event.year === correctOrder[index].year;
                      return {
                        correct: acc.correct + (isCorrect ? 1 : 0),
                        errors: acc.errors + (isCorrect ? 0 : 1),
                      };
                    },
                    { correct: 0, errors: 0 },
                  ).errors
                }{" "}
                fel)
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                  overflowX: "auto",
                  p: 2,
                  bgcolor: "background.default",
                  borderRadius: 1,
                  minHeight: 100,
                  border: "2px dashed",
                  borderColor: "divider",
                  "&::-webkit-scrollbar": {
                    height: 8,
                  },
                  "&::-webkit-scrollbar-track": {
                    backgroundColor: "background.paper",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "primary.light",
                    borderRadius: 4,
                  },
                }}
              >
                {timelineResult.map((event, index) => {
                  const isCorrect = event.year === correctOrder[index].year;
                  return (
                    <Paper
                      key={index}
                      sx={{
                        minWidth: 191,
                        height: 220,
                        textAlign: "center",
                        position: "relative",
                        overflow: "hidden",
                        borderRadius: 2,
                        boxShadow: "none",
                      }}
                    >
                      {event.imageUrl && (
                        <Box
                          component="img"
                          src={event.imageUrl}
                          sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      )}
                      <Box
                        sx={{
                          position: "relative",
                          zIndex: 1,
                          width: "100%",
                          height: "100%",
                          background: event.imageUrl
                            ? `linear-gradient(rgba(0,0,0,0.45), rgba(0,0,0,0.45)),
           linear-gradient(to top, ${isCorrect ? "rgba(76,175,80,0.8)" : "rgba(244,67,54,0.8)"} 0%, ${isCorrect ? "rgba(76,175,80,0.6)" : "rgba(244,67,54,0.6)"} 100%)`
                            : isCorrect
                              ? "linear-gradient(135deg, #4CAF50 0%, #388E3C 100%)"
                              : "linear-gradient(135deg, #f44336 0%, #d32f2f 100%)",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "space-between",
                          padding: "16px",
                        }}
                      >
                        <Chip
                          size="small"
                          icon={
                            isCorrect ? (
                              <CheckCircleOutlineIcon />
                            ) : (
                              <CancelOutlinedIcon />
                            )
                          }
                          label={isCorrect ? "Rätt" : "Fel"}
                          sx={{
                            height: "24px",
                            borderRadius: "8px",
                            background: "white",
                            color: isCorrect ? "#27792B" : "#FF4D4D",
                            fontSize: "11px",
                            fontWeight: 600,
                            position: "absolute",
                            top: 5,
                            right: 5,
                            "& .MuiChip-icon": {
                              fontSize: 14,
                              color: "inherit",
                              fontWeight: "600",
                            },
                          }}
                        />
                        <Box
                          sx={{
                            color: "white",
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: "500",
                              mb: 1,
                              wordBreak: "break-word",
                            }}
                          >
                            {event.event}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              position: "absolute",
                              bottom: "20px",
                              left: 0,
                              right: 0,
                              color: "white",
                              fontWeight: "500",
                            }}
                          >
                            {event.year}
                          </Typography>
                        </Box>
                      </Box>
                    </Paper>
                  );
                })}
              </Box>
            </Box>
          );
        })}
      </Box>

      {viewMode && (
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: (theme) => theme.palette.background.default,
            borderTop: (theme) => `1px solid ${theme.palette.divider}`,
            zIndex: 1000,
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", md: "66%" },
              margin: "0 auto",
              paddingTop: "50px",
              paddingBottom: "50px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button
              variant="print"
              onClick={toggleViewMode}
              startIcon={<ClearIcon />}
              sx={{ minWidth: 120 }}
            >
              Gå ur spelet
            </Button>
            <Button
              variant="purp"
              onClick={onRestart}
              sx={{ minWidth: 120 }}
              startIcon={<RestartAltIcon />}
            >
              Börja om
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ResultsView;
