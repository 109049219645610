import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Typography,
  Card,
  CardContent,
  IconButton,
  Chip,
  Button,
  Avatar,
  Tooltip,
  CircularProgress,
  Tabs,
  Menu,
  Divider,
  MenuItem,
  Tab,
  alpha,
  darken,
} from "@mui/material";
import { useSnackbar } from "./SnackbarHandler";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DeleteIcon from "@mui/icons-material/Delete";
import LoadingBackdrop from "./LoadingBackdrop";
import LessonCard from "./LessonCard";
import ViewAgendaIcon from "@mui/icons-material/ViewAgenda";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import EditIcon from "@mui/icons-material/Edit";
import ShareIcon from "@mui/icons-material/Share";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import ConfirmationDialog from "./ConfirmationDialog";

import {
  lessonMapping,
  getLessonTypeParam,
  lessonUrlMapping,
} from "./functions";
import ShareDialog from "./ShareDialog";
import SchoolIcon from "@mui/icons-material/School";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AttachFileOffIcon from "@mui/icons-material/AttachFile";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import {
  collection,
  getDocs,
  deleteDoc,
  doc,
  query,
  orderBy,
  where,
  documentId,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "./firebase";
import Logger from "./Logger";
import { formatDate, createUrlSlug } from "./functions";

const SharedLessons = ({ user, theme, styles, showSharedLessons }) => {
  const [lessons, setLessons] = useState([]);
  const [loadingLessons, setLoadingLessons] = useState(false);
  const [isManaging, setIsManaging] = useState(false);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [shareDialogOpenFriends, setShareDialogOpenFriends] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [selectedLesson, setSelectedLesson] = useState(null);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [lessonToDelete, setLessonToDelete] = useState(null);

  const [userDetails, setUserDetails] = useState({});
  const [isSharing, setIsSharing] = useState(false);

  useEffect(() => {
    fetchUserLessons();
  }, [user.uid]);

  useEffect(() => {
    Logger.log("selectedLesson: ", JSON.stringify(selectedLesson, null, 2));
  }, [selectedLesson]);

  const getTabType = (currentTab, isStudent) => {
    if (isStudent) {
      // For students, there's only one tab (shared from teachers)
      return 2;
    }
    return currentTab;
  };

  const tabType = getTabType(currentTab, user.accountType === "student");

  const loadUserDetails = async (userIds) => {
    const uniqueUserIds = [...new Set(userIds)];
    const usersSnapshot = await getDocs(
      query(collection(db, "users"), where(documentId(), "in", uniqueUserIds)),
    );

    const userMap = {};
    usersSnapshot.docs.forEach((doc) => {
      userMap[doc.id] = {
        displayName: doc.data().displayName,
        photoURL: doc.data().photoURL,
      };
    });
    setUserDetails(userMap);
  };

  const handleShareClick = async (
    lessonComment,
    lessonName,
    selectedImage,
    tags,
    shareData,
  ) => {
    setIsSharing(true);
    await new Promise((resolve) => setTimeout(resolve, 1500));
    // try {
    //   // Create a document for each student
    //   const sharePromises = shareData.studentIds.map((studentId) => {
    //     const documentData = {
    //       userId: user.uid,
    //       shared: true,
    //       sharedTo: studentId,
    //       comment: shareData.comment,
    //       sharedAt: Date.now(),
    //       fileUrl: shareData.fileUrl,
    //       lessonType: selectedLesson.lessonType,
    //       timestamp: serverTimestamp(),
    //       lessonComment, // from direct args
    //       lessonName, // from direct args
    //       coverImage: selectedImage, // from direct args
    //       hasPrinted: true,
    //       data: selectedLesson.data,
    //     };
    //     Logger.log(
    //       "Adding document for student",
    //       studentId,
    //       ":",
    //       JSON.stringify(documentData, null, 2),
    //     );
    //     return addDoc(collection(db, "lesson_usage"), documentData);
    //   });
    //   await Promise.all(sharePromises);
    //   showSnackbar(`Du har nu delat lektionen!`, "success");
    // }

    try {
      // Create a document for each student and then create notifications with the doc IDs
      const sharePromises = shareData.studentIds.map(async (studentId) => {
        const documentData = {
          userId: user.uid,
          shared: true,
          sharedTo: studentId,
          comment: shareData.comment,
          sharedAt: Date.now(),
          fileUrl: shareData.fileUrl,
          lessonType: selectedLesson.lessonType,
          timestamp: serverTimestamp(),
          lessonComment, // from direct args
          lessonName, // from direct args
          coverImage: selectedImage, // from direct args
          hasPrinted: true,
          data: selectedLesson.data,
        };
        // Create the lesson document and get its ID
        const lessonDoc = await addDoc(
          collection(db, "lesson_usage"),
          documentData,
        );
        Logger.log(
          "Adding document for student",
          studentId,
          ":",
          JSON.stringify(documentData, null, 2),
        );
        // Create notification with the lesson doc ID
        await addDoc(collection(db, "notifications"), {
          type: "lesson_shared",
          recipientId: studentId,
          senderId: user.uid,
          read: false,
          createdAt: serverTimestamp(),
          lessonData: {
            lessonId: lessonDoc.id,
            lessonType: selectedLesson.lessonType,
            lessonName: lessonName,
            fileUrl: shareData.fileUrl,
            comment: shareData.comment,
            coverImage: selectedImage,
          },
        });
        return lessonDoc;
      });
      await Promise.all(sharePromises);
      showSnackbar(`Du har nu delat lektionen!`, "success");
    } catch (error) {
      showSnackbar("Fel vid delande av lektionen: " + error.message, "error");
      console.error(error);
    } finally {
      setIsSharing(false);
    }
  };

  const handleMenuOpen = (event, lesson) => {
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
    setSelectedLesson(lesson);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    setSelectedLesson(null);
  };

  const fetchUserLessons = async () => {
    setLoadingLessons(true);
    try {
      // First get all your regular lessons
      const lessonsRef = collection(db, "lesson_usage");
      const regularQuery = query(
        lessonsRef,
        where("userId", "==", user.uid),
        where("hasPrinted", "==", true),
        orderBy("timestamp", "desc"),
      );
      const regularQuerySnapshot = await getDocs(regularQuery);
      const regularLessons = regularQuerySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Then get shared lessons
      const sharedQuery = query(
        lessonsRef,
        where("shared", "==", true),
        where("sharedTo", "==", user.uid),
        orderBy("timestamp", "desc"),
      );
      const sharedQuerySnapshot = await getDocs(sharedQuery);
      const sharedLessons = sharedQuerySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Combine all lessons
      const allLessons = [...regularLessons, ...sharedLessons];
      setLessons(allLessons);

      // In the fetchUserLessons function, update the userIds collection part:
      const sharedUserIds = allLessons.flatMap((lesson) => {
        const ids = [];
        if (lesson.shared && lesson.userId) {
          ids.push(lesson.userId); // Add the teacher's ID
        }
        if (lesson.shared && lesson.userId === user.uid && lesson.sharedTo) {
          ids.push(lesson.sharedTo); // Add the student's ID
        }
        return ids;
      });

      if (sharedUserIds.length > 0) {
        await loadUserDetails([...new Set(sharedUserIds)]); // Remove duplicates
      }

      if (sharedUserIds.length > 0) {
        await loadUserDetails(sharedUserIds);
      }
    } catch (error) {
      console.error("Error fetching user lessons:", error);
    } finally {
      setLoadingLessons(false);
    }
  };
  const handleTabChange = (event, newValue) => {
    if (user.accountType === "student") {
      // For students, always set to 0 since they only see one tab
      setCurrentTab(0);
    } else {
      setCurrentTab(newValue);
    }
  };

  const getFilteredLessons = () => {
    switch (
      tabType // Changed from currentTab to tabType
    ) {
      case 0: // Regular lessons
        return lessons.filter((lesson) => !lesson.shared);
      case 1: // Shared with students
        return lessons.filter(
          (lesson) => lesson.shared && lesson.userId === user.uid,
        );
      case 2: // Shared by teachers
        return lessons.filter(
          (lesson) => lesson.shared && lesson.userId !== user.uid,
        );
      default:
        return [];
    }
  };

  const handleDeleteClick = (lessonId, event) => {
    if (event) {
      event.stopPropagation();
    }
    setLessonToDelete(lessonId);
    setShowDeleteConfirmation(true);
    setMenuAnchorEl(null);
  };

  const handleConfirmDelete = async () => {
    if (lessonToDelete) {
      try {
        await deleteDoc(doc(db, "lesson_usage", lessonToDelete));
        setLessons((prevLessons) =>
          prevLessons.filter((lesson) => lesson.id !== lessonToDelete),
        );
        showSnackbar("Lektionen har raderats", "success");
      } catch (error) {
        console.error("Error deleting lesson:", error);
        showSnackbar("Ett fel uppstod när lektionen skulle raderas", "error");
        fetchUserLessons(); // Reload lessons if deletion fails
      } finally {
        setShowDeleteConfirmation(false);
        setLessonToDelete(null);
      }
    }
  };

  const getTimeSinceShared = (sharedAt) => {
    if (!sharedAt) return "Inte delad än";
    const now = Date.now();
    const diff = now - sharedAt;
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);

    if (seconds < 60)
      return `${seconds} sekund${seconds !== 1 ? "er" : ""} sedan`;
    if (minutes < 60)
      return `${minutes} minut${minutes !== 1 ? "er" : ""} sedan`;
    if (hours < 24) return `${hours} timm${hours !== 1 ? "ar" : "e"} sedan`;

    const days = Math.floor(hours / 24);
    if (days === 1) return "Igår";
    if (days < 7) return `${days} dagar sedan`;
    const weeks = Math.floor(days / 7);
    if (weeks === 1) return "1 vecka sedan";
    if (weeks < 4) return `${weeks} veckor sedan`;
    const months = Math.floor(days / 30);
    if (months === 1) return "1 månad sedan";
    if (months < 12) return `${months} månader sedan`;
    const years = Math.floor(days / 365);
    return `${years} år sedan`;
  };

  const getUntrainedCount = (lessons) => {
    return lessons.filter(
      (lesson) =>
        lesson.shared && lesson.userId !== user.uid && !lesson.hasOpened,
    ).length;
  };

  const getUntrainedSharedByTeacherCount = (lessons) => {
    return lessons.filter(
      (lesson) =>
        lesson.shared &&
        lesson.userId === user.uid &&
        lesson.sharedTo &&
        !lesson.hasOpened,
    ).length;
  };

  const filteredLessons = getFilteredLessons();

  return (
    <Box sx={styles.container}>
      <Box sx={{ ...styles.innerContainer }}>
        <Box sx={styles.headlineLessonBox}>
          <Typography
            variant="h6"
            sx={{
              ...styles.headlineLessonBoxTitle,
              mt: 6,
              textAlign: "left",
            }}
          >
            Mina lektioner
          </Typography>
        </Box>
        <Box sx={{ ...styles.freshBox, padding: "0px" }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              background: theme.palette.mode === "dark" ? "#22303c" : "#f9f9fa",
              borderTopLeftRadius: "7px",
              borderTopRightRadius: "7px",
            }}
          >
            <Tabs
              value={currentTab}
              onChange={handleTabChange}
              sx={{
                "& .MuiTab-root": {
                  textTransform: "none",
                  minWidth: 120,
                  fontSize: "0.9rem",
                },
              }}
            >
              {user.accountType !== "student" && (
                <Tab
                  label="Mina sparade lektioner"
                  icon={<ViewAgendaIcon sx={{ fontSize: 20 }} />}
                  iconPosition="start"
                  sx={{}}
                />
              )}
              {user.accountType !== "student" && (
                <Tab
                  label={
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      Delat till elever från mig
                      {getUntrainedSharedByTeacherCount(lessons) > 0 && (
                        <Tooltip
                          title="Antal elever som inte öppnat lektionen än."
                          placement="right"
                        >
                          <Chip
                            size="small"
                            label={getUntrainedSharedByTeacherCount(lessons)}
                            sx={{
                              ml: 1,
                              height: 20,
                              minWidth: 20,
                              fontSize: "0.75rem",
                              backgroundColor: theme.palette.primary.main,
                              color: theme.palette.primary.contrastText,
                            }}
                          />
                        </Tooltip>
                      )}
                    </Box>
                  }
                  icon={<PeopleOutlineIcon sx={{ fontSize: 20 }} />}
                  iconPosition="start"
                  sx={{}}
                />
              )}
              <Tab
                label={
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    Delat från lärare till mig
                    {getUntrainedCount(lessons) > 0 && (
                      <Tooltip
                        title={`${getUntrainedCount(lessons)} lektioner du inte öppnat än`}
                        placement="right"
                      >
                        <Chip
                          size="small"
                          label={getUntrainedCount(lessons)}
                          sx={{
                            ml: 1,
                            height: 20,
                            minWidth: 20,
                            fontSize: "0.75rem",
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.primary.contrastText,
                          }}
                        />
                      </Tooltip>
                    )}
                  </Box>
                }
                icon={<SchoolIcon sx={{ fontSize: 20 }} />}
                iconPosition="start"
                sx={{}}
              />
            </Tabs>
          </Box>

          {loadingLessons ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "200px",
                p: 4,
                bgcolor: alpha(theme.palette.background.paper, 0.6),
              }}
            >
              <CircularProgress />
            </Box>
          ) : filteredLessons.length === 0 ? (
            <Box
              sx={{
                p: 4,
                textAlign: "center",
                color: "text.secondary",
                bgcolor: alpha(theme.palette.background.paper, 0.6),
                borderRadius: 2,
              }}
            >
              {tabType === 0
                ? "De lektioner du sparat i din lektionsbank hamnar här. Glöm inte att spara det du genererar!"
                : tabType === 1
                  ? "Du har inte delat några lektioner med dina elever än."
                  : "Inga lektioner har delats med dig av lärare än."}
            </Box>
          ) : (
            <Box sx={{ padding: "0px", paddingTop: "60px" }}>
              {tabType === 2 && getUntrainedCount(lessons) > 0 && (
                <Typography
                  variant="body2"
                  sx={{
                    p: 2,
                    color: "text.secondary",
                    fontSize: "1rem",
                    display: "none",
                  }}
                >
                  Din lärare har delat {getUntrainedCount(lessons)} lektion som
                  du inte öppnat ännu :)
                </Typography>
              )}

              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Namn</TableCell>

                      {tabType === 0 && <TableCell>Sparad</TableCell>}
                      {tabType === 0 && <TableCell>Taggar</TableCell>}

                      {tabType === 2 && <TableCell>Delad från</TableCell>}

                      {tabType === 1 && <TableCell>Delad till</TableCell>}

                      {(tabType === 1 || tabType === 2) && (
                        <TableCell>Öppnad</TableCell>
                      )}

                      {(tabType === 1 || tabType === 2) && (
                        <TableCell>Delad</TableCell>
                      )}

                      <TableCell>Lektionstyp</TableCell>
                      <TableCell>Bif.</TableCell>
                      <TableCell>
                        <Tooltip title="Kommentar från lärare">
                          <MailOutlineIcon
                            sx={{
                              fontSize: 20,
                              mt: 1,
                              color: theme.palette.text.secondary,
                            }}
                          />
                        </Tooltip>
                      </TableCell>
                      {user.accountType !== "student" && (
                        <TableCell align="right">Hantera</TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredLessons.map((lesson) => (
                      <TableRow
                        key={lesson.id}
                        component={Link}
                        to={`/app${lesson.lessonType !== "PowerPointGenerator.js" ? "/lektioner" : ""}/${lessonUrlMapping(lesson.lessonType)}/${lesson.id.substring(0, 10)}${getLessonTypeParam(lesson.lessonType) === "default" ? (tabType === 2 ? "?v=t" : "") : `?type=${getLessonTypeParam(lesson.lessonType)}${tabType === 2 ? "&v=t" : ""}`}`}
                        sx={{
                          textDecoration: "none",
                          cursor: "pointer",
                          "&:hover": { backgroundColor: "action.hover" },
                        }}
                      >
                        <TableCell sx={{ maxWidth: "260px" }}>
                          <Tooltip
                            placement="left"
                            title={
                              <LessonCard
                                name={lesson.lessonName}
                                comment={lesson.comment}
                                timestamp={lesson.timestamp}
                                lessonType={lesson.lessonType}
                                imageUrl={lesson.coverImage}
                                size="large"
                                centered={true}
                                width="285px"
                                height="260px"
                              />
                            }
                            PopperProps={{
                              sx: {
                                "& .MuiTooltip-tooltip": {
                                  bgcolor: "transparent",
                                  p: 0,
                                  boxShadow: "none",
                                  width: "285px",
                                },
                              },
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 2,
                              }}
                            >
                              <SchoolIcon
                                sx={{
                                  fontSize: 24,
                                  color: (theme) =>
                                    darken(theme.palette.text.main, 0.2),
                                }}
                              />
                              <Typography
                                sx={{ fontWeight: 400, fontSize: "0.9rem" }}
                              >
                                {lesson.lessonName}
                              </Typography>
                            </Box>
                          </Tooltip>
                        </TableCell>

                        {tabType === 0 && (
                          <TableCell>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                              }}
                            >
                              <AccessTimeIcon
                                sx={{
                                  fontSize: 16,
                                  color: theme.palette.text.secondary,
                                }}
                              />
                              <Typography
                                variant="body2"
                                sx={{
                                  color: theme.palette.text.secondary,
                                }}
                              >
                                {formatDate(lesson.timestamp)}
                              </Typography>
                            </Box>
                          </TableCell>
                        )}

                        {tabType === 0 && (
                          <TableCell>
                            <Box
                              sx={{
                                display: "flex",
                                gap: 0.5,
                                flexWrap: "wrap",
                              }}
                            >
                              {(lesson.tags || []).map((tag, index) => (
                                <Chip
                                  key={index}
                                  label={tag}
                                  size="small"
                                  sx={{
                                    height: "24px",
                                    background: alpha("#27792B", 0.1),
                                    color: "#27792B",
                                    fontSize: "11px",
                                    fontWeight: 500,
                                  }}
                                />
                              ))}
                            </Box>
                          </TableCell>
                        )}

                        {tabType === 1 && (
                          <TableCell>
                            {lesson.shared &&
                            lesson.userId === user.uid &&
                            lesson.sharedTo ? (
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Tooltip
                                  title={
                                    userDetails[lesson.sharedTo]?.displayName ||
                                    "Loading..."
                                  }
                                >
                                  <Avatar
                                    src={userDetails[lesson.sharedTo]?.photoURL}
                                    alt={
                                      userDetails[lesson.sharedTo]?.displayName
                                    }
                                    sx={{
                                      width: 22,
                                      height: 22,
                                      fontSize: "0.775rem",
                                    }}
                                  >
                                    {userDetails[lesson.sharedTo]
                                      ?.displayName?.[0] || "?"}
                                  </Avatar>
                                </Tooltip>
                                <Typography variant="body2" sx={{ ml: 1 }}>
                                  {userDetails[lesson.sharedTo]?.displayName ||
                                    "Loading..."}
                                </Typography>
                              </Box>
                            ) : (
                              "-"
                            )}
                          </TableCell>
                        )}

                        {tabType === 2 && (
                          <TableCell>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Tooltip
                                title={
                                  userDetails[lesson.userId]?.displayName ||
                                  "Loading..."
                                }
                              >
                                <Avatar
                                  src={userDetails[lesson.userId]?.photoURL}
                                  alt={userDetails[lesson.userId]?.displayName}
                                  sx={{
                                    width: 22,
                                    height: 22,
                                    fontSize: "0.775rem",
                                  }}
                                >
                                  {userDetails[lesson.userId]
                                    ?.displayName?.[0] || "?"}
                                </Avatar>
                              </Tooltip>
                              <Typography variant="body2" sx={{ ml: 1 }}>
                                {userDetails[lesson.userId]?.displayName ||
                                  "Loading..."}
                              </Typography>
                            </Box>
                          </TableCell>
                        )}

                        {(tabType === 1 || tabType === 2) && (
                          <TableCell>
                            <Tooltip
                              title={
                                lesson.hasOpened
                                  ? `Öppnad ${new Date(
                                      lesson.openedAt,
                                    ).toLocaleString("sv-SE", {
                                      year: "numeric",
                                      month: "2-digit",
                                      day: "2-digit",
                                      hour: "2-digit",
                                      minute: "2-digit",
                                    })}`
                                  : "Ej öppnad än"
                              }
                              arrow
                            >
                              <Chip
                                label={
                                  lesson.hasOpened ? "Öppnad" : "Ej öppnad"
                                }
                                size="small"
                                icon={
                                  lesson.hasOpened ? (
                                    <CheckCircleOutlineIcon />
                                  ) : (
                                    <HighlightOffIcon />
                                  )
                                }
                                sx={{
                                  height: "24px",
                                  backgroundColor: lesson.hasOpened
                                    ? "#27792B"
                                    : "#FF4D4D",
                                  color: "white",
                                  fontSize: "11px",
                                  fontWeight: 500,
                                  "& .MuiChip-icon": {
                                    fontSize: 14,
                                    color: "inherit",
                                  },
                                }}
                              />
                            </Tooltip>
                          </TableCell>
                        )}

                        {(tabType === 1 || tabType === 2) && (
                          <TableCell>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                              }}
                            >
                              <AccessTimeIcon
                                sx={{
                                  fontSize: 16,
                                  color: !lesson.sharedAt
                                    ? theme.palette.warning.main
                                    : theme.palette.text.secondary,
                                }}
                              />
                              <Typography
                                variant="body2"
                                sx={{
                                  color: !lesson.sharedAt
                                    ? theme.palette.warning.main
                                    : theme.palette.text.secondary,
                                }}
                              >
                                {getTimeSinceShared(lesson.sharedAt)}
                              </Typography>
                            </Box>
                          </TableCell>
                        )}
                        <TableCell>
                          <Chip
                            label={
                              lessonMapping(lesson.lessonType) || "Ingen typ"
                            }
                            size="small"
                            sx={{
                              height: "24px",
                              background: alpha(
                                theme.palette.primary.main,
                                0.1,
                              ),
                              color: theme.palette.primary.main,
                              fontSize: "11px",
                              fontWeight: 500,
                            }}
                          />
                        </TableCell>

                        <TableCell>
                          <Tooltip
                            title={
                              lesson.fileUrl
                                ? "Ladda ner fil från  lärare"
                                : "Ingen fil delad från  lärare"
                            }
                          >
                            <span
                              style={{
                                cursor: lesson.fileUrl ? "pointer" : "default",
                              }}
                              onClick={(e) => {
                                if (!lesson.fileUrl) {
                                  e.preventDefault();
                                  e.stopPropagation();
                                }
                              }}
                            >
                              <IconButton
                                size="small"
                                onClick={
                                  lesson.fileUrl
                                    ? (e) => handleDownload(lesson.fileUrl, e)
                                    : undefined
                                }
                                disabled={!lesson.fileUrl}
                                sx={{
                                  color: lesson.fileUrl
                                    ? theme.palette.primary.main
                                    : theme.palette.text.disabled,
                                  "&:hover": lesson.fileUrl
                                    ? {
                                        backgroundColor: alpha(
                                          theme.palette.primary.main,
                                          0.1,
                                        ),
                                      }
                                    : undefined,
                                  opacity: lesson.fileUrl ? 1 : 0.5,
                                }}
                              >
                                <AttachFileOffIcon sx={{ fontSize: 20 }} />
                              </IconButton>
                            </span>
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          {lesson.comment ? (
                            <Tooltip title={lesson.comment}>
                              <MailOutlineIcon
                                sx={{
                                  mt: 0.5,
                                  fontSize: 20,
                                  color: theme.palette.text.secondary,
                                }}
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip title="Ingen kommentar från lärare">
                              <MailOutlineIcon
                                sx={{
                                  mt: 0.5,
                                  fontSize: 20,
                                  color: theme.palette.text.disabled,
                                }}
                              />
                            </Tooltip>
                          )}
                        </TableCell>
                        {user.accountType !== "student" && (
                          <TableCell align="right">
                            <IconButton
                              onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                setMenuAnchorEl(event.currentTarget);
                                setSelectedLesson(lesson);
                              }}
                              size="small"
                            >
                              <MoreHorizIcon />
                            </IconButton>
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <Menu
                anchorEl={menuAnchorEl}
                open={Boolean(menuAnchorEl)}
                onClose={() => {
                  setMenuAnchorEl(null);
                  setSelectedLesson(null);
                }}
                PaperProps={{
                  sx: { borderRadius: "8px", minWidth: "160px" },
                }}
              >
                <MenuItem
                  onClick={() => setShareDialogOpenFriends(true)}
                  sx={styles.menuItemStyle}
                >
                  <ShareIcon
                    fontSize="small"
                    sx={{ mr: 1, color: "#7B68EE" }}
                  />
                  Dela med vänner
                </MenuItem>

                <MenuItem
                  onClick={() => setShareDialogOpen(true)}
                  sx={styles.menuItemStyle}
                >
                  <ShareIcon
                    fontSize="small"
                    sx={{ mr: 1, color: "#4CAF50" }}
                  />
                  Dela med elever
                </MenuItem>
                <Divider />
                <MenuItem
                  sx={styles.menuItemStyle}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteClick(selectedLesson?.id, e);
                  }}
                >
                  <DeleteIcon
                    fontSize="small"
                    sx={{ mr: 1, color: "#F44336" }}
                  />
                  Ta bort lektion
                </MenuItem>
              </Menu>
            </Box>
          )}
        </Box>
      </Box>

      <ShareDialog
        open={shareDialogOpen}
        onClose={() => setShareDialogOpen(false)}
        onShare={handleShareClick}
        user={user}
        isLessonSharing={true}
        theme={theme}
        lessonName={selectedLesson?.data.title || ""}
        lessonObject={selectedLesson}
        lessonType={selectedLesson?.lessonType}
      />

      <ShareDialog
        open={shareDialogOpenFriends}
        onClose={() => setShareDialogOpenFriends(false)}
        onShare={handleShareClick}
        user={user}
        isLessonSharing={true}
        theme={theme}
        lessonName={selectedLesson?.data.title || ""}
        lessonObject={selectedLesson}
        lessonType={selectedLesson?.lessonType}
        shareWithFriends={true}
      />

      <LoadingBackdrop
        isOpen={isSharing}
        imagePath="/img/AI.png"
        loadingText={"Delar lektionen..."}
      />

      <ConfirmationDialog
        open={showDeleteConfirmation}
        onClose={() => setShowDeleteConfirmation(false)}
        onConfirm={handleConfirmDelete}
        title="Ta bort lektion"
        message="Är du säker på att du vill ta bort denna lektion? Detta går inte att ångra."
        confirmText="Ta bort"
        cancelText="Avbryt"
      />
    </Box>
  );
};

export default SharedLessons;
