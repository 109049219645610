import React, { useState, useRef, useEffect } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CardActions,
  TextField,
  Button,
  Box,
  Chip,
  Typography,
  IconButton,
  Grid,
  CircularProgress,
  Alert,
  Paper,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Logger from "./Logger";
import { styled } from "@mui/material/styles";
import ShareLink from "./ShareLink";
import AIDialog from "./AIDialog";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import ShareIcon from "@mui/icons-material/Share";
import LoadingBackdrop from "./LoadingBackdrop";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import ShareDialog from "./ShareDialog";
import SlideInPaper from "./SlideInPaper";
import { collection, addDoc } from "firebase/firestore";
import { trackLesson } from "./functions";
import {
  query,
  where,
  limit,
  updateDoc,
  getDocs,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "./firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import VisionHistoryDrawer from "./VisionHistoryDrawer"; // Import the new drawer component
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import ExportButton from "./ExportButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import GenerateIcon from "@mui/icons-material/AutoAwesome";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import NotesIcon from "@mui/icons-material/Notes";

import { InstructionItem } from "./functions";
import { storage } from "./firebase";
import { CheckCircleOutline } from "@mui/icons-material";
import { useSearchParams, useParams, useNavigate } from "react-router-dom";
import { MathJaxContext, MathJax } from "better-react-mathjax";

import TextFieldsIcon from "@mui/icons-material/TextFields";
import TableChartIcon from "@mui/icons-material/TableChart";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ArticleIcon from "@mui/icons-material/Article";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useSnackbar } from "./SnackbarHandler";
import { marked } from "marked";

import LanguageSelector from "./LanguageSelector";
// Styled component for the upload area
const UploadArea = styled("div")(({ theme }) => ({
  border: `2px dashed ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(3),
  textAlign: "center",
  cursor: "pointer",
  backgroundColor: theme.palette.background.default,
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const VisionAnalysis = ({ user, styles, theme }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState("");
  const [prompt, setPrompt] = useState("");
  const [analysis, setAnalysis] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [isDragging, setIsDragging] = useState(false);
  const lessonTitleRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [language, setLanguage] = useState("svenska");
  const [drawerOpen, setDrawerOpen] = useState(false); // Drawer open by default
  const { showSnackbar } = useSnackbar();
  const [aiDialogOpen, setAiDialogOpen] = React.useState(false);
  const [aiDialogSimplerOpen, setAiDialogSimplerOpen] = React.useState(false);
  const [aiDialogStoryOpen, setAiDialogStoryOpen] = React.useState(false);
  const shareLinkButtonRef = useRef(null);
  const [isSharing, setIsSharing] = useState(false);
  const [showControlPanel, setShowControlPanel] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const navigate = useNavigate();
  const [shareDialogOpenFriends, setShareDialogOpenFriends] = useState(false);

  const [format, setFormat] = useState("standard");
  const [parsedContent, setParsedContent] = useState("");

  const adminIds = [
    "Z1dF5PGfjvdBGS9egzACvcSRZiN2",
    "x2rjH7JxWxc6GnFgPSTxGxN1bm63",
    "Z6BLSMsm38Un5mgW0O6Z3TToHq12",
  ];

  // Add these format buttons definitions

  const formatButtons = [
    { value: "standard", label: "Standard", icon: ArticleIcon },
    { value: "table", label: "Tabell", icon: TableChartIcon },
    { value: "bullets", label: "Punktlista", icon: FormatListBulletedIcon },

    { value: "story", label: "Engagerande historia", icon: MenuBookIcon },
  ];

  const [searchParams, setSearchParams] = useSearchParams();

  const { lessonId } = useParams();
  const viewMode = searchParams.get("v") === "t";

  const toggleViewMode = () => {
    const currentParams = Object.fromEntries(searchParams.entries());
    if (currentParams.v === "t") {
      delete currentParams.v;
    } else {
      currentParams.v = "t";
    }
    setSearchParams(currentParams);
  };

  useEffect(() => {
    const loadLessonFromId = async () => {
      if (lessonId) {
        try {
          const q = query(
            collection(db, "visions"),
            where("__name__", ">=", lessonId),
            where("__name__", "<=", lessonId + "\uf8ff"),
            limit(1),
          );

          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            const docData = querySnapshot.docs[0].data();

            if (!docData.hasOpened) {
              await updateDoc(querySnapshot.docs[0].ref, {
                hasOpened: true,
                openedAt: Date.now(),
              });
            }
            setAnalysis(docData.analysis);
            setImageUrl(docData.imageUrl);

            // Scroll to lesson content
            setTimeout(() => {
              lessonTitleRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }, 100);

            showSnackbar("Bildanalysen har laddats in.", "success");
          } else {
            showSnackbar("Kunde inte hitta Bildanalysen", "error");
          }
        } catch (error) {
          console.error("Error loading lesson:", error);
          showSnackbar(
            "Ett fel uppstod när bildanalysen skulle laddas",
            "error",
          );
        }
      }
    };

    loadLessonFromId();
  }, [lessonId]);

  useEffect(() => {
    Logger.log("lessonId: ", lessonId);
    const isDataValid = lessonId !== null && lessonId !== undefined;

    if (isDataValid !== showControlPanel) {
      const timer = setTimeout(() => {
        setShowControlPanel(isDataValid);
      }, 100);

      return () => clearTimeout(timer);
    }
  }, [lessonId, showControlPanel]);

  const handleShare = async (
    lessonComment,
    lessonName,
    selectedImage,
    tags,
    shareData,
  ) => {
    setIsSharing(true);
    await new Promise((resolve) => setTimeout(resolve, 1500));

    try {
      // Create a document for each student and send notifications
      const sharePromises = shareData.studentIds.map(async (studentId) => {
        const documentData = {
          // The user that receives it should get the doc created (studentId not user.uid)
          userId: studentId,
          imageUrl,
          prompt: prompt || "What's in this image?",
          analysis: analysis,

          createdAt: new Date(),
          lessonType: "VisionAnalysis.js",

          comment: shareData.comment,
          lessonComment,
          lessonName,
          fileUrl: shareData.fileUrl,
        };

        // Create lesson document
        const lessonDoc = await addDoc(collection(db, "visions"), documentData);

        // Create notification with lesson doc ID
        await addDoc(collection(db, "notifications"), {
          type: "vision_analysis_shared",
          recipientId: studentId,
          senderId: user.uid,
          read: false,
          createdAt: serverTimestamp(),
          lessonData: {
            lessonId: lessonDoc.id,
            lessonType: documentData.lessonType,
            lessonName: documentData.lessonName,
            fileUrl: documentData.fileUrl,
            comment: documentData.comment,
            coverImage: selectedImage,
          },
        });

        return lessonDoc;
      });

      await Promise.all(sharePromises);
      showSnackbar(`Du har nu delat bildanalysen!`, "success");
    } catch (error) {
      showSnackbar(
        "Fel vid delande av bildanalysen: " + error.message,
        "error",
      );
      console.error(error);
    } finally {
      setIsSharing(false);
    }
  };

  const handleAskAI = () => {
    setAiDialogOpen(true);
  };

  const API_URL =
    process.env.NODE_ENV === "production"
      ? "/api"
      : "http://localhost:3001/api";

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Check file size (5MB limit)
      if (file.size > 5 * 1024 * 1024) {
        showSnackbar("Bilden får inte vara större än 5MB", "error");
        return;
      }

      // Check file type and extension
      const allowedTypes = [
        "image/png",
        "image/jpeg",
        "image/webp",
        "image/gif",
      ];
      const allowedExtensions = [".png", ".jpeg", ".jpg", ".webp", ".gif"];

      // Check MIME type
      if (!allowedTypes.includes(file.type)) {
        showSnackbar(
          "Endast PNG, JPEG, WEBP och GIF-format är tillåtna",
          "error",
        );
        return;
      }

      // Check file extension
      const extension = "." + file.name.split(".").pop().toLowerCase();
      if (!allowedExtensions.includes(extension)) {
        showSnackbar(
          "Endast .png, .jpeg, .jpg, .webp och .gif-filer är tillåtna",
          "error",
        );
        return;
      }

      // For GIF files, we need to check if they're animated
      if (file.type === "image/gif") {
        // We'll load the file to check if it's animated
        const reader = new FileReader();
        reader.onload = (e) => {
          const arr = new Uint8Array(e.target.result);
          let frameCount = 0;

          // Check for multiple frames in GIF
          for (let i = 0; i < arr.length - 3; i++) {
            if (arr[i] === 0x00 && arr[i + 1] === 0x21 && arr[i + 2] === 0xf9) {
              frameCount++;
              if (frameCount > 1) {
                showSnackbar("Animerade GIF-filer stöds inte", "error");
                return;
              }
            }
          }

          // If we get here, it's a non-animated GIF
          setSelectedImage(file);
          setPreviewUrl(URL.createObjectURL(file));
          setError("");
        };
        reader.readAsArrayBuffer(file);
      } else {
        // For non-GIF files, proceed normally
        setSelectedImage(file);
        setPreviewUrl(URL.createObjectURL(file));
        setError("");
      }
    }
  };

  const saveToFirebase = async (imageUrl, analysisResult, tokensUsed) => {
    try {
      // Save analysis data to Firestore
      await addDoc(collection(db, "visions"), {
        userId: user.uid,
        imageUrl,
        prompt: prompt || "What's in this image?",
        analysis: analysisResult,
        tokensUsed,
        createdAt: new Date(),
        fileName: selectedImage.name,
        fileSize: selectedImage.size,
        fileType: selectedImage.type,
      });
    } catch (error) {
      console.error("Error saving to Firebase:", error);
      throw new Error("Failed to save analysis record");
    }
  };

  const getFormatPrompt = () => {
    //const basePrompt = `What's in this image? Svara på ${language}. Ge en så lång beskrivning som möjligt. Svara i HTML-format. `;
    const basePrompt = `What's in this image? Svara på ${language}. Ge en så lång beskrivning som möjligt. Svara i HTML-format. Om det finns matematiska formler eller ekvationer i bilden, använd LaTeX-syntax med \\( för inline formler och \\[ för displayformler. `;
    const formatPrompts = {
      standard: basePrompt,
      table: `${basePrompt} Förklara i en överskådlig tabell med viktiga punkter och exempel.`,
      bullets: `${basePrompt} Förklara i punktform. Var noggrann med att inkludera huvudpunkter och viktiga detaljer.`,
      longform: `${basePrompt} Ge en detaljerad förklaring. Inkludera bakgrund, huvudkoncept, exempel och praktiska tillämpningar.`,
      story: `${basePrompt} Förklara genom en engagerande historia med liknelser och exempel som gör det lätt att förstå.`,
    };

    return formatPrompts[format] + (prompt ? ` ${prompt}` : "");
  };

  const analyzeImage = async () => {
    if (!selectedImage) {
      setError("Välj en bild först.");
      return;
    }
    if (!adminIds.includes(user.uid)) {
      const twentyFourHoursAgo = new Date(Date.now() - 24 * 60 * 60 * 1000);

      const q = query(
        collection(db, "visions"),
        where("userId", "==", user.uid),
        where("createdAt", ">=", twentyFourHoursAgo),
        limit(1),
      );

      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        showSnackbar(
          "Du får bara analysera 1 bild/dag med gratiskontot. Uppgradera till premium.",
          "info",
        );
        return;
      }
    }
    setLoading(true);
    setError("");

    try {
      const imageRef = ref(
        storage,
        `visionImages/${user.uid}/${Date.now()}_${selectedImage.name}`,
      );
      await uploadBytes(imageRef, selectedImage);
      const imageUrl = await getDownloadURL(imageRef);
      setImageUrl(imageUrl);
      const reader = new FileReader();

      const base64Promise = new Promise((resolve, reject) => {
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(selectedImage);
      });

      const base64String = await base64Promise;
      const cleanBase64 = base64String
        .replace("data:image/jpeg;base64,", "")
        .replace("data:image/png;base64,", "");

      const response = await fetch(`${API_URL}/vision`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          base64Image: cleanBase64,
          prompt: getFormatPrompt(),
        }),
      });

      if (!response.ok) {
        const errorData = await response.text();
        throw new Error(`Server error: ${response.status} - ${errorData}`);
      }

      const data = await response.json();

      if (data.description.startsWith("```html")) {
        data.description = data.description.slice(7, -3).trim();
      } else if (data.description.startsWith("```")) {
        data.description = data.description.slice(3, -3).trim();
      }

      Logger.log("Total tokens used:", data.usage.total_tokens);

      await saveToFirebase(imageUrl, data.description, data.usage.total_tokens);

      await trackLesson(user.uid, "VisionAnalysis.js", null, {
        language,
        imageUrl,
      });

      if (!adminIds.includes(user.uid)) {
        localStorage.setItem("lastImageAnalysis", new Date().toDateString());
      }

      setAnalysis(data.description);

      setTimeout(() => {
        lessonTitleRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, 100);
    } catch (err) {
      console.error("Full error:", err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const copyToClipboard = () => {
    const parsedContent = marked.parse(analysis);
    navigator.clipboard
      .write([
        new ClipboardItem({
          "text/plain": new Blob([analysis], { type: "text/plain" }),
          "text/html": new Blob([parsedContent], { type: "text/html" }),
        }),
      ])
      .catch((err) => {
        console.error("Failed to copy with formatting: ", err);
        navigator.clipboard.writeText(analysis);
      });

    showSnackbar("Kopierat!", "success");
  };

  return (
    <>
      <Box sx={styles.container}>
        <Box
          sx={{
            ...styles.innerContainer,
            width: { xs: "100%", md: "80%" },
            margin: "0 auto",
            paddingLeft: "-30px !important",
          }}
        >
          <Box
            sx={{
              ...styles.buttonsContainer,
              ...styles.additionalButtonsContainer,
            }}
          >
            <Button
              variant="purp"
              sx={{ ...styles.tightButton }}
              onClick={() => setDrawerOpen(true)}
              startIcon={<BookmarkBorderIcon />}
            >
              Mina bildanalyser
            </Button>
          </Box>
          {!viewMode && (
            <>
              <Box sx={{ ...styles.headlineLessonBox, mt: 5, mb: 6 }}>
                <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
                  Bildanalys — Ladda upp bild & få en analys
                </Typography>
                <Box sx={{ textAlign: "center", display: "block", mt: 1 }}>
                  <Chip
                    onClick={() => {
                      navigate("/app/vision/MNjbREsNd0");
                      setTimeout(() => {
                        lessonTitleRef.current?.scrollIntoView({
                          behavior: "smooth",
                          block: "start",
                        });
                      }, 100);
                    }}
                    label={
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 0.5,
                          pointerEvents: "auto !important",
                        }}
                      >
                        Visa exempel
                        <ImageSearchIcon sx={{ fontSize: 14 }} />
                      </Box>
                    }
                    size="small"
                    sx={{
                      ...styles.greenChip,
                      height: "24px",
                      cursor: "pointer",
                      pointerEvents: "auto !important",
                      "&:hover": {
                        backgroundColor: `${theme.palette.primary.main} !important`,
                        color: "white !important",
                      },
                    }}
                  />
                </Box>
              </Box>
              <Box sx={{ ...styles.freshBox, padding: "30px" }}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
                  <label
                    htmlFor="image-upload"
                    style={{ cursor: "pointer", width: "100%" }}
                    onDragEnter={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setIsDragging(true);
                    }}
                    onDragOver={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setIsDragging(true);
                    }}
                    onDragLeave={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      const rect = e.currentTarget.getBoundingClientRect();
                      if (
                        e.clientX < rect.left ||
                        e.clientX >= rect.right ||
                        e.clientY < rect.top ||
                        e.clientY >= rect.bottom
                      ) {
                        setIsDragging(false);
                      }
                    }}
                    onDrop={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setIsDragging(false);
                      const file = e.dataTransfer.files[0];
                      if (file)
                        handleImageChange({ target: { files: [file] } });
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        minHeight: "195px",
                        paddingTop: "30px",
                        paddingBottom: "30px",
                        width: "100%",
                        borderRadius: "16px",
                        bgcolor: isDragging
                          ? "rgba(0, 0, 0, 0.05)"
                          : "rgba(0, 0, 0, 0.02)",
                        border: "2px dashed",
                        borderColor: isDragging
                          ? "primary.main"
                          : "rgba(0, 0, 0, 0.1)",
                        //transition: "all 0.3s ease-in-out",
                        "&:hover": {
                          borderColor: "primary.main",
                          bgcolor: "rgba(0, 0, 0, 0.04)",
                        },
                      }}
                    >
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        style={{ display: "none" }}
                        id="image-upload"
                      />
                      {selectedImage ? (
                        <Box sx={{ textAlign: "center" }}>
                          <Box sx={{ mb: 2 }}>
                            <img
                              src={previewUrl}
                              alt="Preview"
                              style={{
                                maxWidth: "100%",
                                maxHeight: "200px",
                                borderRadius: "8px",
                                objectFit: "contain",
                              }}
                            />
                          </Box>
                          <Typography
                            variant="body2"
                            color="success.main"
                            sx={{ fontWeight: "600", fontSize: "1.1rem" }}
                          >
                            "{selectedImage.name}" uppladdad och är redo att
                            analyseras!
                          </Typography>
                          <Button
                            onClick={(e) => {
                              e.preventDefault();
                              setSelectedImage(null);
                              setPreviewUrl("");
                              document.getElementById("image-upload").click();
                            }}
                            variant="purp"
                            startIcon={<CloudUploadIcon />}
                            sx={{
                              ...styles.tightButton,
                              mt: 3,
                            }}
                          >
                            Välj en annan bild
                          </Button>
                        </Box>
                      ) : (
                        <Box sx={{ textAlign: "center" }}>
                          <CloudUploadIcon
                            sx={{
                              fontSize: 40,
                              color: isDragging
                                ? "primary.main"
                                : "primary.main",
                              mb: 2,
                              transform: isDragging ? "scale(1.1)" : "scale(1)",
                              transition: "transform 0.2s ease-in-out",
                            }}
                          />
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ fontWeight: "500", fontSize: "1.1rem" }}
                          >
                            {isDragging
                              ? "Släpp bilden här"
                              : "Dra och släpp bild här"}
                          </Typography>
                          <Typography
                            variant="caption"
                            color="text.secondary"
                            sx={{
                              mt: 2,
                              display: "block",
                              textAlign: "center",
                            }}
                          >
                            Tillåtna format: PNG, JPEG, WEBP och icke-animerad
                            GIF
                            <br />
                            Max storlek: 5MB
                          </Typography>
                          <Button
                            variant="purp"
                            startIcon={<CloudUploadIcon />}
                            component="label"
                            htmlFor="image-upload"
                            sx={{
                              ...styles.tightButton,
                              mt: 3,
                            }}
                          >
                            Ladda upp bild
                          </Button>
                        </Box>
                      )}
                    </Box>
                  </label>
                  {error && (
                    <Alert severity="error" sx={{ mt: 2 }}>
                      {error}
                    </Alert>
                  )}
                  <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item xs={12} md={6}>
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: "500", mt: 0 }}
                      >
                        Jag vill ha analysen på:
                      </Typography>
                      <Box sx={{ mt: 1, mb: 2 }}>
                        <LanguageSelector
                          language={language}
                          setLanguage={setLanguage}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: "500", mt: 0 }}
                      >
                        Ytterligare instruktioner (valfritt):
                      </Typography>
                      <Box sx={{ mt: 1, mb: 2 }}>
                        <TextField
                          fullWidth
                          placeholder="t.ex. förklara så en 5-åring förstår"
                          variant="outlined"
                          value={prompt}
                          onChange={(e) => setPrompt(e.target.value)}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sx={{ display: "none" }}>
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: "500", mt: 2 }}
                      >
                        Jag vill ha analysen i följande format:
                      </Typography>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" },
                          gap: 2,
                          mt: 1,
                        }}
                      >
                        {formatButtons.map(({ value, label, icon: Icon }) => (
                          <Button
                            key={value}
                            variant={format === value ? "save" : "print"}
                            onClick={() => setFormat(value)}
                            sx={{ height: 45 }}
                            color={format === value ? "primary" : "inherit"}
                            startIcon={
                              format === value ? <CheckCircleIcon /> : <Icon />
                            }
                          >
                            {label}
                          </Button>
                        ))}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                <Button
                  fullWidth
                  variant="purp"
                  onClick={analyzeImage}
                  disabled={!selectedImage || loading}
                  startIcon={
                    loading ? (
                      <CircularProgress sx={{ color: "white" }} size={20} />
                    ) : (
                      <CloudUploadIcon />
                    )
                  }
                  sx={{ mt: 3 }}
                >
                  {loading
                    ? "Analyserar bilden — tar 30-60 sekunder..."
                    : "Analysera bild"}
                </Button>
              </Box>
            </>
          )}
          {analysis && (
            <>
              <Box
                ref={lessonTitleRef}
                sx={{ ...styles.headlineLessonBox, mt: 9 }}
              >
                <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
                  Analysen
                </Typography>
              </Box>
              <Box
                sx={{
                  ...styles.freshBox,
                  padding: "30px",

                  width: {
                    xs: "100%",
                    sm: viewMode ? "70%" : undefined,
                  },
                  margin: viewMode ? "0 auto" : undefined,
                }}
              >
                {!viewMode && (
                  <Box sx={{ position: "relative" }}>
                    <Box
                      sx={{
                        ...styles.lessonControlPanel,
                        ...styles.lessonControlPanelNuevo,

                        "& .MuiButton-root": {
                          ...styles.controlPanelButtons,
                          ...styles.lessonControlPanelNewButtons,
                        },
                      }}
                    >
                      {/*
                    <PrintAsPdf
                      buttonRef={pdfButtonRef}
                      onPrint={() => handlePrintClick()}
                      sx={{ ml: 2 }}
                    />
                    */}
                      <Button
                        variant="success"
                        sx={{}}
                        onClick={() => setShareDialogOpenFriends(true)}
                        startIcon={<ShareIcon />}
                      >
                        Dela med vänner
                      </Button>

                      <Button
                        variant="success"
                        sx={{
                          mt: 2,
                          ml: 2,
                        }}
                        onClick={() => setShareDialogOpen(true)}
                        startIcon={<ShareIcon />}
                      >
                        Dela med elever
                      </Button>
                      <ShareLink
                        buttonRef={shareLinkButtonRef}
                        key={lessonId}
                        lessonId={lessonId}
                        lessonData={{
                          analysis,
                          imageUrl,
                        }}
                        user={user}
                        lessonType="VisionAnalysis.js"
                        onCopy={(message, severity) =>
                          showSnackbar(message, severity)
                        }
                      />

                      <Button
                        variant="purp"
                        sx={{ mt: 3 }}
                        onClick={() => setAiDialogOpen(true)}
                        startIcon={<GenerateIcon />}
                      >
                        Diskutera med AI
                      </Button>
                      <Button
                        variant="purp"
                        sx={{ mt: 3 }}
                        onClick={() => setAiDialogSimplerOpen(true)}
                        startIcon={<NotesIcon />}
                      >
                        Förklara enklare
                      </Button>
                      <Button
                        variant="purp"
                        sx={{ mt: 3 }}
                        onClick={() => setAiDialogStoryOpen(true)}
                        startIcon={<HistoryEduIcon />}
                      >
                        Förklara i form av en historia
                      </Button>

                      <Box sx={{ display: "none", ml: 0 }}>
                        <ExportButton
                          content={analysis}
                          styles={styles}
                          bigButton={true}
                        />
                      </Box>

                      <Button
                        variant="purp"
                        sx={{ mt: 3 }}
                        onClick={copyToClipboard}
                        startIcon={<ContentCopyIcon />}
                      >
                        Kopiera
                      </Button>
                    </Box>
                    <Box
                      sx={{
                        ...styles.lessonControlPanelFade,
                      }}
                    />
                  </Box>
                )}
                <Box sx={{ textAlign: "center", mb: 5 }}>
                  <img
                    src={imageUrl || previewUrl}
                    alt="Preview"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "300px",
                      borderRadius: "8px",
                      objectFit: "contain",
                    }}
                  />
                </Box>

                <MathJaxContext>
                  <MathJax dynamic>
                    <Typography
                      variant="body1"
                      color="text.secondary"
                      sx={{
                        fontSize: "1.1rem",
                        "& p": { lineHeight: "1.8rem" },
                        "& h3,h2,h1,h4,h5,h6": {
                          marginBottom: "0px",
                          marginTop: "30px",
                          paddingBottom: "0px",
                        },
                        transition: "background-color 0.3s ease-in-out",
                        "& .MathJax": {
                          fontSize: "1.1em",
                          margin: "20px 0",
                          color:
                            theme.palette.mode === "dark" ? "#fff" : "#000",
                        },
                        "& .MathJax-Display": {
                          // DISPLAY formulas (centered equations)
                          margin: "1em 0",
                          display: "block",
                          textAlign: "center",
                        },
                        "& .MJX-TEX": {
                          // INLINE formulas (within text)
                          margin: "0 0.2em",
                          fontSize: "1.5rem",
                          verticalAlign: "middle",
                        },
                        "& hr": {
                          height: "1px",
                          border: "none",
                          backgroundColor:
                            theme.palette.mode === "dark"
                              ? "#34414c"
                              : "#e0e0e0",
                          marginTop: "50px",
                          marginBottom: "50px",
                        },
                        "& pre": {
                          backgroundColor:
                            theme.palette.mode === "dark"
                              ? "#2d3748"
                              : "#f7fafc",
                          padding: "1rem",
                          borderRadius: "4px",
                          overflowX: "auto",
                        },
                        "& code": {
                          fontFamily: "monospace",
                          fontSize: "0.9em",
                        },
                        "& table": {
                          borderCollapse: "collapse",
                          width: "100%",
                          marginBottom: "1rem",
                        },
                        "& th, & td": {
                          border: `1px solid ${theme.palette.mode === "dark" ? "#34414c" : "#e0e0e0"}`,
                          padding: "8px",
                          textAlign: "left",
                        },
                        "& th": {
                          backgroundColor:
                            theme.palette.mode === "dark"
                              ? "#1e2a35"
                              : "#f5f5f5",
                          fontWeight: "bold",
                        },
                      }}
                      dangerouslySetInnerHTML={{ __html: analysis }}
                    />
                  </MathJax>
                </MathJaxContext>
              </Box>
            </>
          )}

          <Box sx={{ ...styles.headlineLessonBox, mt: 9 }}>
            <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
              Begränsningar
            </Typography>
          </Box>
          <Box
            sx={{
              ...styles.freshBox,
              padding: "30px",
              width: {
                xs: "100%",
                sm: viewMode ? "70%" : undefined,
              },
              margin: viewMode ? "0 auto" : undefined,
            }}
          >
            <Box>
              <InstructionItem text="Medicinska bilder: Modellen är inte lämplig för att tolka specialiserade medicinska bilder som CT-skanningar och bör inte användas för medicinska råd." />
              <InstructionItem text="Annat skriftsystem: Annat skriftsystem: Modellen kan ha svårare att tolka bilder som innehåller text från icke-latinska skriftsystem, till exempel japanska eller koreanska tecken." />
              <InstructionItem text="Liten text: Förstora text i bilden för att förbättra läsbarheten, men undvik att beskära viktiga detaljer." />
              <InstructionItem text="Rotation: Modellen kan feltolka roterad eller upp-och-ned-vänd text och bilder." />
              <InstructionItem text="Visuella element: Modellen kan ha svårt att förstå grafer eller text där färger eller stilar—som heldragna, streckade eller prickade linjer—varierar." />
              <InstructionItem text="Rumsligt tänkande: Modellen har svårt med uppgifter som kräver exakt rumslig lokalisering, som att identifiera schackpositioner." />
              <InstructionItem text="Noggrannhet: Modellen kan generera felaktiga beskrivningar eller bildtexter i vissa scenarier." />
              <InstructionItem text="Bildform: Modellen har svårt med panorama- och fisheye-bilder." />
              <InstructionItem text="Metadata och storleksändring: Modellen bearbetar inte ursprungliga filnamn eller metadata, och bilder ändrar storlek före analys, vilket påverkar deras ursprungliga dimensioner." />
              <InstructionItem text="Räkning: Modellen kan ge ungefärliga antal för objekt i bilder." />
            </Box>
          </Box>
        </Box>
        <VisionHistoryDrawer
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          user={user}
          theme={theme}
          onVisionSelect={(analysis) => {
            setAnalysis(analysis.analysis);
            setImageUrl(analysis.imageUrl);
            setTimeout(() => {
              lessonTitleRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }, 100);
          }}
        />
        <AIDialog
          userId={user.uid}
          open={aiDialogOpen}
          onClose={() => setAiDialogOpen(false)}
          initialQuestion={`Förklara denna analys som är en analys baserat på en uppladdad bild: ${analysis}. Skriv på ${language}.`}
          systemMessage={`Du är en hjälpsam AI-assistent designad för att förklara och hjälpa till med den analys användaren fått av en bild. Skriv på ${language}.`}
          theme={theme}
        />

        <AIDialog
          userId={user.uid}
          open={aiDialogSimplerOpen}
          onClose={() => setAiDialogSimplerOpen(false)}
          initialQuestion={`Förklara denna analys som är en analys baserat på en uppladdad bild: ${analysis}. Skriv på ${language}. Försök förenkla det rejält så att även en 5-åring kan förstå.`}
          systemMessage={`Du är en hjälpsam AI-assistent designad för att förklara och hjälpa till med den analys användaren fått av en bild. Skriv på ${language}.`}
          theme={theme}
        />

        <AIDialog
          userId={user.uid}
          open={aiDialogStoryOpen}
          onClose={() => setAiDialogStoryOpen(false)}
          initialQuestion={`Förklara denna analys som är en analys baserat på en uppladdad bild: ${analysis}. Skriv på ${language}. Förklara i form av en engagerade historia med liknelser och exempel som gör det lätt att förstå.`}
          systemMessage={`Du är en hjälpsam AI-assistent designad för att förklara och hjälpa till med den analys användaren fått av en bild. Skriv på ${language}.`}
          theme={theme}
        />

        {/*

        <SlideInPaper
          isExpanded={isExpanded}
          onExpandedChange={setIsExpanded}
          lessonTitleRef={lessonTitleRef}
          viewMode={viewMode}
          toggleViewMode={toggleViewMode}
          theme={theme}
          open={showControlPanel}
          user={user}
          onShareLink={() => shareLinkButtonRef.current?.click()}
          onShareWithStudents={() => setShareDialogOpen(true)}
          onShareWithFriends={() => setShareDialogOpenFriends(true)}
        />

        */}

        <ShareDialog
          open={shareDialogOpen}
          onClose={() => setShareDialogOpen(false)}
          onShare={handleShare}
          user={user}
          isLessonSharing={true}
          theme={theme}
          lessonName={"Bildanalys"}
          lessonObject={analysis}
          lessonObject={{
            analysis,
            imageUrl,
          }}
          lessonType={"VisionAnalysis.js"}
        />
        <ShareDialog
          open={shareDialogOpenFriends}
          onClose={() => setShareDialogOpenFriends(false)}
          onShare={handleShare}
          user={user}
          isLessonSharing={true}
          theme={theme}
          lessonName={"Bildanalys"}
          lessonObject={{
            analysis,
            imageUrl,
          }}
          lessonType={"VisionAnalysis.js"}
          shareWithFriends={true}
        />

        <LoadingBackdrop
          isOpen={isSharing}
          imagePath="/img/AI.png"
          loadingText={"Delar bildanalysen..."}
        />
      </Box>
    </>
  );
};

export default VisionAnalysis;
