import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Tooltip,
  Divider,
  Tabs,
  Tab,
} from "@mui/material";
import { db } from "./firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import OutputHistory from "./OutputHistory";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { useNavigate } from "react-router-dom";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import LockIcon from "@mui/icons-material/Lock";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { collection, query, where, getDocs } from "firebase/firestore";
import WestIcon from "@mui/icons-material/West";
import { useSnackbar } from "./SnackbarHandler";
import { useTheme } from "@mui/material/styles";

const RoomDetails = ({ styles }) => {
  const { roomId } = useParams();
  const [room, setRoom] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const navigate = useNavigate();
  const [selectedUser, setSelectedUser] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteConfirmationText, setDeleteConfirmationText] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const { showSnackbar } = useSnackbar();
  const theme = useTheme();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);

  useEffect(() => {
    fetchRoomDetails();
  }, [roomId]);

  const fetchRoomDetails = async () => {
    try {
      // First fetch the room details
      const roomDoc = await getDoc(doc(db, "rooms", roomId));
      if (roomDoc.exists()) {
        const roomData = {
          id: roomDoc.id,
          ...roomDoc.data(),
          activeUsers: roomDoc.data().activeUsers || [],
          pausedUsers: roomDoc.data().pausedUsers || [],
          lockedUsers: roomDoc.data().lockedUsers || [],
        };

        // Then fetch all users that have this room in their rooms array
        const usersQuery = query(
          collection(db, "users"),
          where("rooms", "array-contains", roomId),
        );
        const usersSnapshot = await getDocs(usersQuery);

        const users = usersSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Set room with the fetched users
        setRoom({
          ...roomData,
          users: users,
        });
      }
    } catch (error) {
      console.error("Error fetching room details:", error);
      showSnackbar("Ett fel uppstod när rummet skulle hämtas", "error");
    }
  };

  const getUserStatus = (userId) => {
    if (room.activeUsers.includes(userId)) return "active";
    if (room.pausedUsers.includes(userId)) return "paused";
    if (room.lockedUsers.includes(userId)) return "locked";
    return "active"; // Default status
  };

  const handleStatusChange = async (userId, newStatus) => {
    try {
      const updatedRoom = { ...room };

      // Remove user from all status arrays
      updatedRoom.activeUsers = updatedRoom.activeUsers.filter(
        (id) => id !== userId,
      );
      updatedRoom.pausedUsers = updatedRoom.pausedUsers.filter(
        (id) => id !== userId,
      );
      updatedRoom.lockedUsers = updatedRoom.lockedUsers.filter(
        (id) => id !== userId,
      );

      // Add user to appropriate status array
      switch (newStatus) {
        case "active":
          updatedRoom.activeUsers.push(userId);
          break;
        case "paused":
          updatedRoom.pausedUsers.push(userId);
          break;
        case "locked":
          updatedRoom.lockedUsers.push(userId);
          break;
      }

      await updateDoc(doc(db, "rooms", roomId), {
        activeUsers: updatedRoom.activeUsers,
        pausedUsers: updatedRoom.pausedUsers,
        lockedUsers: updatedRoom.lockedUsers,
      });

      setRoom(updatedRoom);
      handleMenuClose();
      showSnackbar("Användarens status har uppdaterats!", "success");
    } catch (error) {
      console.error("Error updating user status:", error);
      showSnackbar("Ett fel uppstod när status skulle uppdateras", "error");
    }
  };

  const handleMenuOpen = (event, user) => {
    setMenuAnchorEl(event.currentTarget);
    setSelectedUser(user);
  };

  const handleMenuClose = () => {
    // Only clear selectedUser if we're not showing the delete dialog
    if (!deleteDialogOpen) {
      setSelectedUser(null);
    }
    setMenuAnchorEl(null);
  };

  const handleDeleteClick = () => {
    setDeleteDialogOpen(true);
    setMenuAnchorEl(null);
  };

  const handleDeleteConfirm = async () => {
    if (!selectedUser || deleteConfirmationText !== selectedUser.displayName)
      return;

    try {
      const updatedUsers = room.users.filter(
        (user) => user.id !== selectedUser.id,
      );
      await updateDoc(doc(db, "rooms", roomId), { users: updatedUsers });

      showSnackbar("Användaren har tagits bort från rummet", "success");
      setDeleteDialogOpen(false);
      setDeleteConfirmationText("");
      fetchRoomDetails();
    } catch (error) {
      console.error("Error removing user:", error);
      showSnackbar("Ett fel uppstod när användaren skulle tas bort", "error");
    }
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case "active":
        return <RadioButtonCheckedIcon sx={{ color: "#4CAF50" }} />;
      case "paused":
        return <PauseCircleIcon sx={{ color: "#FFA726" }} />;
      case "locked":
        return <LockIcon sx={{ color: "#F44336" }} />;
      default:
        return null;
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const filterUsersByStatus = (users) => {
    if (!users) return [];
    return users.filter((user) => {
      const status = getUserStatus(user.id);
      if (tabValue === 0) return true; // "Alla" tab
      if (tabValue === 1) return status === "active";
      if (tabValue === 2) return status === "paused";
      if (tabValue === 3) return status === "locked";
      return true;
    });
  };

  if (!room) return null;

  return (
    <Box sx={styles.container}>
      <Box
        sx={{
          ...styles.innerContainer,
          width: { xs: "100%", md: "100%" },
          margin: "0 auto",
        }}
      >
        <Box sx={{ ...styles.headlineLessonBox, mt: 8 }}>
          <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
            {room.name} — Anslutna elever
          </Typography>
        </Box>

        <Box
          sx={{
            ...styles.buttonsContainer,
            ...styles.additionalButtonsContainer,
            left: { xs: "6px", md: "0px" },
          }}
        >
          <Button variant="print" onClick={() => navigate("/app/rum")}>
            <WestIcon sx={{ mr: 1 }} /> Tillbaka
          </Button>
        </Box>
        <Box
          sx={{
            ...styles.buttonsContainer,
            ...styles.additionalButtonsContainer,
          }}
        ></Box>
        <Box
          sx={{
            ...styles.freshBox,
            padding: "0px",
            minHeight: { xs: "100%", md: "660px" },
          }}
        >
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              background: theme.palette.mode === "dark" ? "#22303c" : "#f9f9fa",
              borderTopLeftRadius: "7px",
              borderTopRightRadius: "7px",
            }}
          >
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              sx={{
                borderColor: "divider",
                "& .MuiTab-root": {
                  textTransform: "none",
                  fontWeight: "500",
                  fontSize: "0.95rem",
                },
              }}
            >
              <Tab
                icon={
                  <PeopleOutlineIcon
                    sx={{ fontSize: "20px", color: "primary.main" }}
                  />
                }
                label="Alla elever"
                sx={{
                  flexDirection: "row",
                  "& .MuiTab-iconWrapper": {
                    marginRight: 1,
                    marginBottom: 0,
                  },
                }}
              />
              <Tab
                icon={
                  <RadioButtonCheckedIcon
                    sx={{ fontSize: "20px", color: "#4CAF50" }}
                  />
                }
                label="Aktiva"
                sx={{
                  flexDirection: "row",
                  "& .MuiTab-iconWrapper": {
                    marginRight: 1,
                    marginBottom: 0,
                  },
                }}
              />
              <Tab
                icon={
                  <PauseCircleIcon
                    sx={{ fontSize: "20px", color: "#FFA726" }}
                  />
                }
                label="Pausade"
                sx={{
                  flexDirection: "row",
                  "& .MuiTab-iconWrapper": {
                    marginRight: 1,
                    marginBottom: 0,
                  },
                }}
              />
              <Tab
                icon={<LockIcon sx={{ fontSize: "20px", color: "#F44336" }} />}
                label="Låsta"
                sx={{
                  flexDirection: "row",
                  "& .MuiTab-iconWrapper": {
                    marginRight: 1,
                    marginBottom: 0,
                  },
                }}
              />
            </Tabs>
          </Box>

          <Box
            sx={{ padding: "0px", paddingTop: "30px", paddingBottom: "30px" }}
          >
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Namn</TableCell>
                    <TableCell>E-post</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell align="right">Hantera</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filterUsersByStatus(room.users)?.map((user) => (
                    <TableRow
                      key={user.id}
                      onClick={() => {
                        setSelectedStudent(user);
                        setDrawerOpen(true);
                      }}
                      sx={{
                        cursor: "pointer",
                        "&:hover": { backgroundColor: "action.hover" },
                      }}
                    >
                      <TableCell>
                        <Box
                          sx={{ display: "flex", alignItems: "center", gap: 2 }}
                        >
                          <Avatar
                            sx={{ width: 22, height: 22, fontSize: "0.775rem" }}
                            src={user.photoUrl}
                            alt={user.displayName}
                          >
                            {user.displayName?.charAt(0)}
                          </Avatar>
                          <span>{user.displayName}</span>
                        </Box>
                      </TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>
                        <Box
                          sx={{ display: "flex", alignItems: "center", gap: 1 }}
                        >
                          {getStatusIcon(getUserStatus(user.id))}
                          <Typography sx={{ fontSize: "0.9rem" }}>
                            {getUserStatus(user.id) === "active"
                              ? "Aktiv"
                              : getUserStatus(user.id) === "paused"
                                ? "Pausad"
                                : "Låst"}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          onClick={(event) => {
                            event.stopPropagation(); // Prevent navigation
                            handleMenuOpen(event, user);
                          }}
                          size="small"
                        >
                          <MoreHorizIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>

              {(!room.users || room.users.length === 0) && (
                <Box sx={{ textAlign: "center", mt: 2 }}>
                  <Typography>Inga användare i rummet</Typography>
                </Box>
              )}
            </TableContainer>
          </Box>
        </Box>

        <Menu
          anchorEl={menuAnchorEl}
          open={Boolean(menuAnchorEl)}
          onClose={handleMenuClose}
          PaperProps={{
            sx: { borderRadius: "8px", minWidth: "160px" },
          }}
        >
          <MenuItem
            onClick={() => handleStatusChange(selectedUser?.id, "active")}
            sx={styles.menuItemStyle}
          >
            <RadioButtonCheckedIcon
              fontSize="small"
              sx={{ mr: 1, color: "#4CAF50" }}
            />
            Aktiv
            {getUserStatus(selectedUser?.id) === "active" && (
              <CheckIcon sx={{ ml: "auto", color: "#7B68EE" }} />
            )}
          </MenuItem>
          <MenuItem
            onClick={() => handleStatusChange(selectedUser?.id, "paused")}
            sx={styles.menuItemStyle}
          >
            <PauseCircleIcon
              fontSize="small"
              sx={{ mr: 1, color: "#FFA726" }}
            />
            Pausad
            {getUserStatus(selectedUser?.id) === "paused" && (
              <CheckIcon sx={{ ml: "auto", color: "#7B68EE" }} />
            )}
          </MenuItem>
          <MenuItem
            onClick={() => handleStatusChange(selectedUser?.id, "locked")}
            sx={styles.menuItemStyle}
          >
            <LockIcon fontSize="small" sx={{ mr: 1, color: "#F44336" }} />
            Låst
            {getUserStatus(selectedUser?.id) === "locked" && (
              <CheckIcon sx={{ ml: "auto", color: "#7B68EE" }} />
            )}
          </MenuItem>
          <Divider />
          <MenuItem onClick={handleDeleteClick} sx={styles.menuItemStyle}>
            <DeleteIcon fontSize="small" sx={{ mr: 1, color: "#7B68EE" }} />
            Ta bort från rum
          </MenuItem>
        </Menu>

        <Dialog
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
          PaperProps={{
            sx: {
              width: "100%",
              maxWidth: "700px",
              borderRadius: "8px",
              p: 2,
            },
          }}
        >
          <DialogTitle sx={{ pb: 1, fontWeight: "700", mb: 5 }}>
            Bekräfta borttagning av användare
          </DialogTitle>
          <DialogContent>
            <Typography variant="body1" sx={{ mb: 2 }}>
              <Typography variant="body1" sx={{ mb: 2 }}>
                Skriv <strong>{`"${selectedUser?.displayName}"`}</strong> i
                fältet nedan och klicka på bekräfta-knappen för att ta bort
                användaren från rummet.
              </Typography>
            </Typography>
            <TextField
              fullWidth
              value={deleteConfirmationText}
              onChange={(e) => setDeleteConfirmationText(e.target.value)}
              placeholder="Skriv användarens namn här"
              sx={{ mt: 1 }}
            />
          </DialogContent>
          <DialogActions sx={{ px: 3, pb: 2 }}>
            <Button onClick={() => setDeleteDialogOpen(false)} variant="print">
              Avbryt
            </Button>
            <Button
              onClick={handleDeleteConfirm}
              variant="error-gradient"
              disabled={deleteConfirmationText !== selectedUser?.displayName}
            >
              Ta bort användare
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      <OutputHistory
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        student={selectedStudent}
      />
    </Box>
  );
};

export default RoomDetails;
