import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Divider,
  CircularProgress,
  Tooltip,
  FormControl,
  Select,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  MenuItem,
  Grid,
  TextField,
} from "@mui/material";

import GenerateIcon from "@mui/icons-material/AutoAwesome";
import ShareLink from "./ShareLink";
import ImageIcon from "@mui/icons-material/Image";
import ViewTimelineIcon from "@mui/icons-material/ViewTimeline";
import { lighten, alpha } from "@mui/material/styles";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import PrintIcon from "@mui/icons-material/Print";
import SlideInPaper from "./SlideInPaper";

import { db } from "./firebase";
import {
  updateDoc,
  collection,
  query,
  where,
  limit,
  getDocs,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";
import { callApi } from "./api";

import TimeBuilderEditDialog from "./TimeBuilderEditDialog";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";

import Logger from "./Logger";
import TimelineBuilderGame from "./TimelineBuilderGame";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";

import TextFieldsIcon from "@mui/icons-material/TextFields";

import LanguageSelector from "./LanguageSelector";
import ClearIcon from "@mui/icons-material/Clear";
import { ThemeSelectorFlow } from "./ThemeSelectorFlow";
import InfoBox from "./InfoBox";
import {} from "./printFunctions";
import {
  generateAndProcessImage,
  handleImageGenerationError,
} from "./functions";
import { useSnackbar } from "./SnackbarHandler";
import ProgressBar from "./ProgressBar";

import ImageGenerationModal from "./ImageGenerationModal";
import GradeSelector, { gradeInstructions } from "./GradeSelector";
import { useTheme } from "@mui/material/styles";
import HistoryIcon from "@mui/icons-material/History";
import { getStyles } from "../index";
import { trackLesson } from "./functions";

import LessonDrawer from "./LessonDrawer";
import SaveLessonDialog from "./SaveLessonDialog";
import HoverableImage from "./HoverableImage";
import { getThemes } from "./additionalThemes/TimelineLesson";
import { useSearchParams, useParams } from "react-router-dom";
import ShareDialog from "./ShareDialog";
import ShareIcon from "@mui/icons-material/Share";
import LoadingBackdrop from "./LoadingBackdrop";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import IconButton from "@mui/material/IconButton";

const TimelineBuilderLesson = ({ user }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);

  const lessonTitleRef = useRef(null); // Add this ref

  const [searchParams, setSearchParams] = useSearchParams();
  const [showControlPanel, setShowControlPanel] = useState(false);
  const [lessonTheme, setLessonTheme] = useState("");
  const [language, setLanguage] = useState("svenska");
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [grade, setGrade] = useState("åk6");
  const [progress, setProgress] = useState(0);
  const [isGeneratingLesson, setIsGeneratingLesson] = useState(false);
  const [isGeneratingImages, setIsGeneratingImages] = useState(false);
  const [isSharing, setIsSharing] = useState(false);
  const [customTheme, setCustomTheme] = useState("");
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageInstructions, setImageInstructions] = useState("");
  const [openAiInstructions, setOpenAiInstructions] = useState("");
  const viewMode = searchParams.get("v") === "t";
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [loadingImageIndex, setLoadingImageIndex] = useState(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const translateButtonRef = useRef(null);
  const shareLinkButtonRef = useRef(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [duration, setDuration] = useState(45);
  const [amountOfQuestions, setAmountOfQuestions] = useState(1);
  const [shareDialogOpenFriends, setShareDialogOpenFriends] = useState(false);
  const [predefinedTheme, setPredefinedTheme] = useState(null);
  const [lessonData, setLessonData] = useState("");
  // Add this state at the top with your other useState declarations
  // const [lessonData, setLessonData] = useState({
  //   title: "Romerska kejsare",
  //   description:
  //     "Dra och släpp händelserna till rätt plats på tidslinjen. Se om du kan få alla rätt!",
  //   timelines: [
  //     {
  //       title: "Romerska kejsare",
  //       description: "Ordna kejsarna i rätt ordning av styre",
  //       timeline: [
  //         {
  //           event: "Augustus blir den första kejsaren av Rom.",
  //           year: 27,
  //           hint: "Tänk på den romerska republiken och dess övergång till kejsardöme.",
  //           imageUrl: null,
  //         },
  //         {
  //           event: "Nero börjar sitt styre som kejsare.",
  //           year: 54,
  //           hint: "Han är känd för sina extravaganta fester och kontroverser.",
  //           imageUrl: null,
  //         },
  //         {
  //           event: "Den stora branden i Rom inträffar.",
  //           year: 64,
  //           hint: "Denna katastrof påverkade hela staden och ledde till stora förändringar.",
  //           imageUrl: null,
  //         },
  //         {
  //           event: "Vespasian blir kejsare och startar Flaviska dynastin.",
  //           year: 69,
  //           hint: "Tänk på Colosseum och dess byggande.",
  //           imageUrl: null,
  //         },
  //         {
  //           event: "Trajanus utropar Rom till sitt största territorium.",
  //           year: 117,
  //           hint: "Han är känd för sina militära framgångar och offentliga byggnadsprojekt.",
  //           imageUrl: null,
  //         },
  //         {
  //           event: "Diocletianus delar upp Romarriket i öst och väst.",
  //           year: 285,
  //           hint: "Denna uppdelning var avgörande för rikets framtid.",
  //           imageUrl: null,
  //         },
  //         {
  //           event: "Romerska riket faller under det västra kejsardömet.",
  //           year: 476,
  //           hint: "Detta årtal markerar slutet på antikens historia i Väst.",
  //           imageUrl: null,
  //         },
  //       ],
  //       articleSections: [
  //         {
  //           subheadline: "Inledningen av kejsardömet",
  //           content:
  //             "År 27 f.Kr. blev Augustus den första kejsaren av Rom. Detta markerade slutet på den romerska republiken och början på en ny era. Augustus genomförde flera viktiga reformer och stabiliserade riket efter en lång period av inbördeskrig. Hans styre präglades av fred och välstånd, känd som Pax Romana. Augustus skapade också en stark centralregering och förbättrade infrastruktur. Han var en mästerlig politiker som visste hur man skulle vinna folket. Hans regeringstid lade grunden för kommande kejsare. Många romare såg honom som en räddare. Tiden för Augustus är avgörande för förståelsen av romersk historia. Det markerar en vändpunkt i hur Rom styrdes.",
  //         },
  //         {
  //           subheadline: "Neros regeringstid",
  //           content:
  //             "År 54 e.Kr. blev Nero kejsare, känd för sina extravaganta levnadsvanor. Under hans tid ledde han ett liv i lyx, vilket skapade missnöje bland folket. Nero är också känd för sin konstnärliga ambition och för att ha tävlat i olika evenemang. Hans styre präglades av stora skandaler och misstankar om mord. En av de mest kända händelserna under hans regeringstid var den stora branden i Rom. Många trodde att han hade startat branden för att bygga sitt palats. Trots detta fortsatte han att vara en kontroversiell och fascinerande figur. Hans tid avslutades med en revolt som ledde till hans död. Neros regeringstid visar på de risker som kan komma med makt. Det är en påminnelse om att ledarskap också handlar om ansvar.",
  //         },
  //         {
  //           subheadline: "Den stora branden i Rom",
  //           content:
  //             "Den stora branden i Rom inträffade år 64 e.Kr. och orsakade förödelse i staden. Brandens ursprung är fortfarande oklart, men den påverkade hela samhällsstrukturen. Många romare förlorade sina hem och liv. Nero anklagades för att ha orsakat branden, vilket ledde till hans ökade impopularitet. Efter branden genomförde han stora byggnadsprojekt för att återuppbygga staden. Dessa förändringar inkluderade bredare gator och nya byggnader. Branden visade på sårbarheten hos staden och dess invånare. Det ledde också till en ny era av arkitektoniska innovationer. Rom förändrades på många sätt efter denna händelse. Den stora branden är en viktig del av Roms historia.",
  //         },
  //         {
  //           subheadline: "Vespasian och Flaviska dynastin",
  //           content:
  //             "År 69 e.Kr. blev Vespasian kejsare, efter en turbulent tid av maktkamp. Han grundade Flaviska dynastin, som skulle styra under de kommande trettio åren. Vespasian är känd för att ha påbörjat byggandet av Colosseum, en av Roms mest kända byggnader. Under hans styre stabiliserades riket och ekonomin förbättrades. Han genomförde också viktiga militära kampanjer för att återta kontrollen över provinser. Vespasian var pragmatisk och visste hur man skulle hantera kriser. Hans regeringstid präglades av en balans mellan makt och ansvar. Flaviska dynastin skulle fortsätta att påverka Roms politik och kultur. Vespasian och hans söner är betydande figurer i romersk historia. Hans arv är fortfarande synligt i dagens Rom.",
  //         },
  //         {
  //           subheadline: "Trajanus och imperiets storhet",
  //           content:
  //             "År 117 e.Kr. utropade Trajanus Rom till sitt största territorium. Han är känd för sina framgångar på slagfältet och för att ha expanderat riket. Trajanus genomförde också många offentliga byggnadsprojekt, inklusive marknadsplatser och vägar. Hans regeringstid anses vara en av de mest framgångsrika i romersk historia. Trajanus var en populär kejsare som prioriterade folket. Han införde reformer som förbättrade livskvaliteten för många romare. Hans styre präglades av en känsla av enighet och stabilitet. Trajanus bidrog också till Roms kultur och utbildning. Hans minnesmärken står kvar än idag. Trajanus är en symbol för Roms storhet.",
  //         },
  //         {
  //           subheadline: "Diocletianus och uppdelningen",
  //           content:
  //             "År 285 e.Kr. delade Diocletianus upp Romarriket i öst och väst. Denna uppdelning var en strategisk åtgärd för att hantera det växande riket. Diocletianus införde också flera reformer för att stärka centralmakten. Han skapade en ny administration och delade upp riket i provinser. Diocletianus var känd för att vara en hård men effektiv ledare. Hans åtgärder ledde till en viss stabilitet under en orolig tid. Uppdelningen av riket skulle få långvariga konsekvenser för Europas historia. Det markerade också början på slutet för den romerska dominansen. Diocletianus är en kontroversiell figur i romersk historia. Hans beslut har fortfarande stor betydelse.",
  //         },
  //         {
  //           subheadline: "Det västra riket faller",
  //           content:
  //             "År 476 e.Kr. föll det västra romerska riket, vilket markerade slutet på antiken. Denna händelse inträffade när den sista kejsaren avsattes av germanska stammar. Många faktorer bidrog till rikets fall, inklusive ekonomiska problem och interna stridigheter. Romarriket hade en gång varit en mäktig nation, men svaghet och korruption ledde till dess undergång. Fallandet av det västra riket var en vändpunkt i Europas historia. Det ledde till en period av osäkerhet och förändring i regionen. Många av de kulturella och politiska strukturerna förändrades. Det östra riket, känt som Bysantinska riket, fortsatte att existera i flera hundra år till. Händelsen är en påminnelse om att makt är temporär. Det västra rikets fall är en viktig del av romersk historia.",
  //         },
  //       ],
  //       articleImageUrl: null,
  //     },
  //   ],
  // });

  const subject = searchParams.get("subject") || null;
  const [soundActivated, setSoundActivated] = useState(null);

  const [audio] = useState(
    new Audio("https://sportkuriren.se/wp-content/uploads/ticking.mp3"),
  );

  const handleActivateSound = () => {
    if (soundActivated) {
      audio.pause();
      audio.currentTime = 0;
    } else {
      audio.loop = true;
      audio.play();
    }
    setSoundActivated(!soundActivated);
  };
  const additionalThemes = getThemes(subject);

  const { showSnackbar } = useSnackbar();
  const theme = useTheme();
  const styles = getStyles(theme);

  const transcriptRef = useRef("");
  const uploadedFileRef = useRef("");

  const bookContentRef = useRef("");

  const wikipediaContentRef = useRef("");
  const randomizedContentRef = useRef("");
  const coursePlanContentRef = useRef("");
  const urlContentRef = useRef("");
  useEffect(() => {
    Logger.log("subject: ", subject);
  }, [subject]);
  useEffect(() => {
    Logger.log("lessonTheme: ", lessonTheme);
  }, [lessonTheme]);

  useEffect(() => {
    Logger.log("predefinedTheme: ", predefinedTheme);
  }, [predefinedTheme]);

  useEffect(() => {
    Logger.log("customTheme: ", customTheme);
  }, [customTheme]);

  useEffect(() => {
    Logger.log("youtubeTranscript: ", transcriptRef.current);
  }, [transcriptRef.current]);

  useEffect(() => {
    Logger.log("wikipediaContentRef: ", wikipediaContentRef.current);
  }, [wikipediaContentRef.current]);
  useEffect(() => {
    Logger.log("uploadedFileRef: ", uploadedFileRef.current);
  }, [uploadedFileRef.current]);

  useEffect(() => {
    Logger.log("randomizedContentRef: ", randomizedContentRef.current);
  }, [randomizedContentRef.current]);

  useEffect(() => {
    Logger.log("coursePlanContentRef: ", coursePlanContentRef.current);
  }, [coursePlanContentRef.current]);

  const { lessonId } = useParams();
  useEffect(() => {
    const loadLessonFromId = async () => {
      if (lessonId) {
        try {
          const q = query(
            collection(db, "lesson_usage"),
            where("__name__", ">=", lessonId),
            where("__name__", "<=", lessonId + "\uf8ff"),
            where("lessonType", "==", "TimelineBuilderLesson.js"),
            limit(1),
          );

          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            const docData = querySnapshot.docs[0].data();

            if (!docData.hasOpened) {
              await updateDoc(querySnapshot.docs[0].ref, {
                hasOpened: true,
                openedAt: Date.now(),
              });
            }

            setLessonData(docData.data);

            // Scroll to lesson content
            setTimeout(() => {
              lessonTitleRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }, 100);

            showSnackbar("Lektionen har laddats in.", "success");
          } else {
            showSnackbar("Kunde inte hitta lektionen", "error");
          }
        } catch (error) {
          console.error("Error loading lesson:", error);
          showSnackbar("Ett fel uppstod när lektionen skulle laddas", "error");
        }
      }
    };

    loadLessonFromId();
  }, lessonId);

  useEffect(() => {
    Logger.log("lessonData: ", lessonData);
    const isDataValid =
      lessonData !== null &&
      lessonData !== undefined &&
      Object.keys(lessonData).length > 0 &&
      !Object.values(lessonData).includes("");

    if (isDataValid !== showControlPanel) {
      const timer = setTimeout(() => {
        setShowControlPanel(isDataValid);
      }, 100);

      return () => clearTimeout(timer);
    }
  }, [lessonData, showControlPanel]);

  const toggleViewMode = () => {
    const currentParams = Object.fromEntries(searchParams.entries());
    if (currentParams.v === "t") {
      delete currentParams.v;
    } else {
      currentParams.v = "t";
    }
    setSearchParams(currentParams);
  };

  const handleShare = async (
    lessonComment,
    lessonName,
    selectedImage,
    tags,
    shareData,
  ) => {
    setIsSharing(true);
    await new Promise((resolve) => setTimeout(resolve, 1500));
    // try {
    //   // Create a document for each student
    //   const sharePromises = shareData.studentIds.map((studentId) => {
    //     const documentData = {
    //       userId: user.uid,
    //       shared: true,
    //       sharedTo: studentId,
    //       comment: shareData.comment,
    //       sharedAt: Date.now(),
    //       fileUrl: shareData.fileUrl,
    //       lessonType: "TimelineBuilderLesson.js",
    //       timestamp: serverTimestamp(),
    //       lessonComment,
    //       lessonName,
    //       coverImage: selectedImage,
    //       hasPrinted: true,
    //       data: lessonData,
    //     };
    //     Logger.log(
    //       "Adding document for student",
    //       studentId,
    //       ":",
    //       JSON.stringify(documentData, null, 2),
    //     );
    //     return addDoc(collection(db, "lesson_usage"), documentData);
    //   });
    //   await Promise.all(sharePromises);
    //   showSnackbar(`Du har nu delat lektionen!`, "success");
    // }
    try {
      // Create a document for each student and then create notifications
      const sharePromises = shareData.studentIds.map(async (studentId) => {
        const documentData = {
          userId: user.uid,
          shared: true,
          sharedTo: studentId,
          comment: shareData.comment,
          sharedAt: Date.now(),
          fileUrl: shareData.fileUrl,
          lessonType: "TimelineBuilderLesson.js",
          timestamp: serverTimestamp(),
          lessonComment,
          lessonName,
          coverImage: selectedImage,
          hasPrinted: true,
          data: lessonData,
        };
        // Create lesson document and get ID
        const lessonDoc = await addDoc(
          collection(db, "lesson_usage"),
          documentData,
        );
        Logger.log(
          "Adding document for student",
          studentId,
          ":",
          JSON.stringify(documentData, null, 2),
        );
        // Create notification with lesson doc ID
        await addDoc(collection(db, "notifications"), {
          type: "lesson_shared",
          recipientId: studentId,
          senderId: user.uid,
          read: false,
          createdAt: serverTimestamp(),
          lessonData: {
            lessonId: lessonDoc.id,
            lessonType: documentData.lessonType,
            lessonName: documentData.lessonName,
            fileUrl: documentData.fileUrl,
            comment: documentData.comment,
            coverImage: selectedImage,
          },
        });
        return lessonDoc;
      });
      await Promise.all(sharePromises);
      showSnackbar(`Du har nu delat lektionen!`, "success");
    } catch (error) {
      showSnackbar("Fel vid delande av lektionen: " + error.message, "error");
      console.error(error);
    } finally {
      setIsSharing(false);
    }
  };

  const handleSaveLessonClick = () => {
    setSaveDialogOpen(true);
  };

  const handleSaveWithComment = async (comment, name, coverImage, tags) => {
    Logger.log(JSON.stringify(lessonData, null, 2));
    await trackLesson(
      user.uid,
      "TimelineBuilderLesson.js",
      lessonData,
      null,
      true,
      comment,
      name,
      coverImage,
      tags,
    );
    setDrawerOpen(true);
    showSnackbar("Lektionen sparad i din lektionsbank!", "success");
  };

  const simulateProgress = (startProgress, endProgress, duration) => {
    const stepSize = 0.5;
    const steps = (endProgress - startProgress) / stepSize;
    const stepDuration = duration / steps;
    let currentProgress = startProgress;

    const intervalId = setInterval(() => {
      currentProgress += stepSize;
      if (currentProgress >= endProgress) {
        clearInterval(intervalId);
      } else {
        setProgress(currentProgress);
      }
    }, stepDuration);

    return () => clearInterval(intervalId);
  };

  const handleGenerateLesson = async () => {
    setProgress(0);
    let stopProgressSimulation = null;
    setIsGeneratingLesson(true);
    const gradeInstructionsText = gradeInstructions[grade];
    setLessonData(null);
    try {
      let themeToUse;

      if (lessonTheme === "youtube") {
        themeToUse = transcriptRef.current;
      } else if (lessonTheme === "customTheme") {
        themeToUse = customTheme;
      } else if (lessonTheme === "predefined") {
        themeToUse = predefinedTheme;
      } else if (lessonTheme === "fileUpload") {
        themeToUse = uploadedFileRef.current;
      } else if (lessonTheme === "wikipedia") {
        themeToUse = wikipediaContentRef.current;
      } else if (lessonTheme === "randomizer") {
        themeToUse = randomizedContentRef.current;
      } else if (lessonTheme === "book") {
        themeToUse = bookContentRef.current;
      } else if (lessonTheme === "url") {
        themeToUse = urlContentRef.current;
      } else {
        //
        themeToUse = coursePlanContentRef.current;
      }

      Logger.log("themeToUse: ", themeToUse);

      stopProgressSimulation = simulateProgress(0, 90, 15000);

      let response = await callApi(
        [
          {
            role: "system",
            content: `You are an expert teacher creating engaging timeline lessons in ${language}. Create a timeline with Include EXACTLY 7 events that students will arrange chronologically. Each event must include a year, description, and helpful hint. Follow the exact JSON structure provided.`,
          },
          {
            role: "user",
            content: `
            Create a timeline lesson about this theme: "${themeToUse}".
            Requirements:
            - Include EXACTLY 7 significant events
            - Each event must have an exact year
            - Write clear, concise descriptions
            - Include helpful hints that don't directly give away the answer
            - Events should span a reasonable time period
            - Content should be appropriate for grade level: ${gradeInstructionsText}
            - Antalet tidslinjer ska vara EXAKT ${amountOfQuestions} stycken.
            - Naturally incorporate all 7 timeline events within the narrative of the article
            - The article should have 8 sections divided on subheadlines. Each section should be EXACTLY 10 sentences long





            - Lektionen ska vara på ${language}.

            RESPOND IN THIS EXACT JSON FORMAT:

            {
              "title": "titel",
              "description": "Dra och släpp händelserna till rätt plats på tidslinjen. Se om du kan få alla rätt!",
              "timelines": [
                {
                  "title": "Emperors of rome",
                  "description": "Order the emperors in the correct order of rule",
                  "timeline": [
                    {
                      "event": "A clear description of the event",
                      "year": 1938,
                      "hint": "A helpful hint that guides without giving away the answer"
                    }
                  ],

                  "articleSections": [
                        {
                          "subheadline": "First Important Aspect",
                          "content": "Content for the first section"
                        },
                       
                      ],

                }
              ]
            }

              Notes:

              - If the event isn't a year, for example in the case of hydrological cycle, use numbers to indicate the order like 1, 2, 3 etc.
              - If the event took place a long time ago, for example millions of years ago, like the big bang, the year should be the amount of billion years ago like  13800000.

            `,
          },
        ],
        6000,
        true,
        user.uid,
      );
      if (stopProgressSimulation) stopProgressSimulation();
      setProgress(95);

      let messageContent = response.data.choices[0].message.content.trim();
      if (messageContent.startsWith("```json")) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith("```")) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      const data = JSON.parse(messageContent);
      const modifiedData = {
        ...data,
        timelines: data.timelines.map((timeline) => ({
          ...timeline,
          articleImageUrl: null,
          timeline: timeline.timeline.map((event) => ({
            ...event,
            imageUrl: null,
          })),
        })),
      };

      setProgress(100);
      setLessonData(modifiedData);
      Logger.log(JSON.stringify(modifiedData, null, 2));
      await new Promise((resolve) => setTimeout(resolve, 800));

      await trackLesson(user.uid, "TimelineBuilderLesson.js", null, {
        language: language,
        lessonTheme: lessonTheme,
      });

      showSnackbar("Din lektion har genererats!", "success");
    } catch (error) {
      console.error("Error generating lesson:", error);
      showSnackbar(
        "Ett fel uppstod när lektionen skulle genereras. Försök igen!",
        "error",
      );
    } finally {
      setIsGeneratingLesson(false);
    }
  };

  /// Image Generation code

  const handleGenerateImages = async () => {
    if (!lessonData) return;
    setIsGeneratingImages(true);
    handleCloseModal();

    try {
      const imagePromises = lessonData.timelines.flatMap((timeline) =>
        timeline.timeline.map((event) => {
          const imagePrompt = `Create a historical image depicting: "${event.event}" from ${event.year}. ${imageInstructions}`;
          return generateAndProcessImage(imagePrompt, user.uid);
        }),
      );

      const results = await Promise.allSettled(imagePromises);

      // Fix: Track promises per timeline separately
      setLessonData((prevData) => {
        const newData = { ...prevData };
        let resultIndex = 0;

        newData.timelines = newData.timelines.map((timeline) => {
          const updatedTimeline = {
            ...timeline,
            timeline: timeline.timeline.map((event) => {
              const result = results[resultIndex++];
              return {
                ...event,
                imageUrl:
                  result.status === "fulfilled" ? result.value : event.imageUrl,
              };
            }),
          };
          return updatedTimeline;
        });

        return newData;
      });

      showSnackbar("Alla bilder har genererats!", "success");
    } catch (error) {
      handleImageGenerationError(error, true, showSnackbar);
    } finally {
      setIsGeneratingImages(false);
    }
  };

  const handleGenerateSpecificImage = async (index) => {
    setIsGeneratingImages(true);
    setLoadingImageIndex(index);
    handleCloseModal();

    try {
      //Image for the article image.
      if (index === 999) {
        const articleContent =
          lessonData.timelines[0].articleSections[0].content;

        const imagePrompt = `Create a historical image depicting: "${articleContent}". ${imageInstructions}`;
        const imageUrl = await generateAndProcessImage(imagePrompt, user.uid);

        if (imageUrl) {
          setLessonData((prevData) => {
            const newData = { ...prevData };
            newData.timelines[0].articleImageUrl = imageUrl; // Update articleImageUrl here
            return newData;
          });
          showSnackbar("Bilden har genererats!", "success");
        }
      } else {
        // Specific timeline images:
        const timelineIndex = Math.floor(
          index / lessonData.timelines[0].timeline.length,
        );
        const eventIndex = index % lessonData.timelines[0].timeline.length;
        const event = lessonData.timelines[timelineIndex].timeline[eventIndex];
        const articleContent =
          lessonData.timelines[timelineIndex].articleSections[eventIndex]
            .content;

        const imagePrompt = `Create a historical image depicting: "${event.event}" from ${event.year}. ${imageInstructions}`;

        Logger.log("articleContent: ", articleContent);
        return;
        const imageUrl = await generateAndProcessImage(imagePrompt, user.uid);

        if (imageUrl) {
          setLessonData((prevData) => {
            const newData = { ...prevData };
            newData.timelines[timelineIndex].timeline[eventIndex].imageUrl =
              imageUrl;
            return newData;
          });
          showSnackbar("Bilden har genererats!", "success");
        }
      }
    } catch (error) {
      handleImageGenerationError(error, false, showSnackbar);
    } finally {
      setIsGeneratingImages(false);
      setLoadingImageIndex(null);
    }
  };

  const handleImageButtonClick = (index = null) => {
    setSelectedImageIndex(index);
    setShowImageModal(true);
  };
  const handleCloseModal = () => {
    setShowImageModal(false);
    setImageInstructions("");
  };

  const handleGenerateWithInstructions = async () => {
    if (selectedImageIndex !== null) {
      handleCloseModal();
      await handleGenerateSpecificImage(selectedImageIndex);
    } else {
      handleCloseModal();
      await handleGenerateImages();
    }
  };

  return (
    <Box sx={styles.container}>
      <Box
        sx={{
          ...styles.innerContainer,
          width: { xs: "100%", md: "100%" },
          margin: "0 auto",
          paddingRight: viewMode ? "0px" : "24px",
        }}
      >
        {!viewMode && (
          <Typography
            variant="h1"
            gutterBottom
            sx={{
              fontWeight: "bold",
              fontSize: "1.8em",
              mb: 3,
              mt: 5,
              textAlign: "center",
            }}
          >
            Bygg tidslinjer
          </Typography>
        )}

        {viewMode && (
          <Box sx={{ position: "absolute", top: 40, left: 45, zIndex: 99999 }}>
            <IconButton onClick={toggleViewMode}>
              <ClearIcon sx={{ fontSize: "30px", color: "text.secondary" }} />
            </IconButton>

            <IconButton onClick={handleActivateSound}>
              {soundActivated ? <VolumeUpIcon /> : <VolumeOffIcon />}
            </IconButton>
          </Box>
        )}

        <Box
          sx={{
            ...styles.buttonsContainer,
            ...styles.additionalButtonsContainer,
          }}
        >
          {lessonData && user.accountType === "teacher" && (
            <Button
              variant="purp"
              sx={{ ...styles.tightButton }}
              disableRipple
              onClick={() => {
                setTimeout(() => {
                  lessonTitleRef.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "start", // This ensures it scrolls to the top of the element
                  });
                }, 100);
                toggleViewMode();
              }}
              startIcon={
                searchParams.get("v") === "t" ? (
                  <FullscreenExitIcon />
                ) : (
                  <FullscreenIcon />
                )
              }
            >
              {searchParams.get("v") === "t" ? "Redigeringsläge" : "Elevläge"}
            </Button>
          )}
          {!viewMode && (
            <Button
              variant="purp"
              sx={{ ...styles.tightButton }}
              onClick={() => setDrawerOpen(true)}
              startIcon={<BookmarkBorderIcon />}
            >
              Sparat
            </Button>
          )}
        </Box>
        <LessonDrawer
          userId={user.uid}
          lessonType={"TimelineBuilderLesson.js"}
          theme={theme}
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          lessonIcon={TextFieldsIcon}
          onSelectLesson={(lesson) => {
            setLessonData(lesson.data);
            setDrawerOpen(false);
            setTimeout(() => {
              lessonTitleRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "start", // This ensures it scrolls to the top of the element
              });
            }, 100);
            showSnackbar(`Lektionen har laddats in.`, "success");
          }}
        />

        {!viewMode && (
          <>
            <InfoBox
              icon={ViewTimelineIcon}
              title="Bygg tidslinjer"
              instructions={[
                "Välj vad temat ska vara, t.ex. vattnets kretslopp eller romerska kejsare",
                "Generera lektionen och redigera tidslinjen utifrån dina behov",
                "Dela med elever eller spara lektionen",
              ]}
              theme={theme}
              styles={styles}
            />

            <ThemeSelectorFlow
              key={subject}
              subject={subject}
              value={lessonTheme}
              onChange={(e) => setLessonTheme(e.target.value)}
              customTheme={customTheme}
              onCustomThemeChange={(e) => setCustomTheme(e.target.value)}
              onPredefinedThemeChange={(e) =>
                setPredefinedTheme(e.target.value)
              }
              onUrlContentChange={(content) => {
                Logger.log(
                  "Parent: URL content received",
                  content.substring(0, 300),
                );
                urlContentRef.current = content;
              }}
              onBookContentChange={(content) => {
                Logger.log("onBookContentChange", content.substring(0, 100));
                bookContentRef.current = content;
              }}
              onYoutubeTranscriptChange={(transcriptContent) => {
                Logger.log(
                  "onYoutubeTranscriptChange körs med: ",
                  transcriptContent,
                );
                transcriptRef.current = transcriptContent;
              }}
              onFileUpload={(content) => {
                Logger.log("onFileUpload körs med: ", content);
                uploadedFileRef.current = content;
              }}
              onWikipediaContentChange={(content) => {
                Logger.log(
                  "Parent: Wikipedia content received",
                  content.substring(0, 100),
                );
                wikipediaContentRef.current = content;
              }}
              onRandomizedContentChange={(content) => {
                Logger.log("randomizedContentRef", content.substring(0, 100));
                randomizedContentRef.current = content;
              }}
              onCoursePlanContentChange={(content) => {
                Logger.log("onCoursePlanChange", content.substring(0, 100));
                coursePlanContentRef.current = content;
              }}
              lessonType={"TimelineBuilderLesson.js"}
              user={user}
              theme={theme}
              styles={styles}
              onLessonSelect={(lesson) => {
                Logger.log("onLessonSelect körs");
                setLessonData(lesson.data);
                setTimeout(() => {
                  lessonTitleRef.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "start", // This ensures it scrolls to the top of the element
                  });
                }, 100);
                showSnackbar("Lektionen har laddats in.", "success");
              }}
              additionalThemes={additionalThemes}
            />

            <Box sx={styles.headlineLessonBox}>
              <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
                2. Generera lektionen
              </Typography>
            </Box>
            <Box sx={{ ...styles.freshBox, padding: "30px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "500", mb: 1 }}
                  >
                    Årskurs:
                  </Typography>
                  <Box sx={{ mt: 0.5, mb: 3 }}>
                    <GradeSelector grade={grade} setGrade={setGrade} />
                  </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle1" sx={{ fontWeight: "500" }}>
                    Språk:
                  </Typography>
                  <Box sx={{ mt: 1, mb: 3 }}>
                    <LanguageSelector
                      language={language}
                      setLanguage={setLanguage}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle1" sx={{ fontWeight: "500" }}>
                    Antal tidslinjer
                  </Typography>
                  <Box sx={{ mt: 1, mb: 3 }}>
                    <FormControl fullWidth>
                      <Select
                        value={amountOfQuestions}
                        onChange={(e) => setAmountOfQuestions(e.target.value)}
                        variant="outlined"
                      >
                        <MenuItem value={1}>1 tidslinje</MenuItem>
                        <MenuItem value={2} disabled>
                          2 frågor
                        </MenuItem>
                        <MenuItem value={3} disabled>
                          3 frågor
                        </MenuItem>
                        <MenuItem value={5} disabled>
                          5 tidslinjer
                        </MenuItem>
                        <MenuItem value={10} disabled>
                          10 frågor
                        </MenuItem>
                        <MenuItem value={0} disabled>
                          20 frågor
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle1" sx={{ fontWeight: "500" }}>
                    Antal sekunder för tidslinje:
                  </Typography>
                  <Box sx={{ mt: 1, mb: 3 }}>
                    <FormControl fullWidth>
                      <Select
                        value={duration}
                        onChange={(e) => setDuration(e.target.value)}
                        variant="outlined"
                      >
                        <MenuItem value={30}>30 sekunder</MenuItem>
                        <MenuItem value={45}>45 sekunder</MenuItem>
                        <MenuItem value={60}>60 sekunder</MenuItem>
                        <MenuItem value={120}>
                          120 sekunder (2 minuter)
                        </MenuItem>
                        <MenuItem value={240}>
                          240 sekunder (4 minuter)
                        </MenuItem>
                        <MenuItem value={300}>
                          300 sekunder (5 minuter)
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
              </Grid>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "12px",
                  width: "100%",
                }}
              >
                <Button
                  variant={
                    theme.palette.mode === "light" ? "purp" : "contained"
                  }
                  color="primary"
                  onClick={handleGenerateLesson}
                  disabled={isGeneratingLesson}
                  sx={{ mt: 4 }}
                  startIcon={
                    !isGeneratingLesson ? (
                      <GenerateIcon style={{ color: "#fff" }} />
                    ) : (
                      <CircularProgress style={{ color: "#fff" }} size={16} />
                    )
                  }
                >
                  {isGeneratingLesson ? "Genererar..." : "Generera lektion"}
                </Button>
              </div>
              {isGeneratingLesson && (
                <Box sx={{ mt: 6 }}>
                  <ProgressBar
                    progress={progress}
                    label="Genererar lektion — tar 30-60 sekunder..."
                  />
                </Box>
              )}
            </Box>
          </>
        )}

        {viewMode && lessonData && (
          <TimelineBuilderGame
            lessonData={lessonData}
            setLessonData={setLessonData}
            styles={styles}
            theme={theme}
            viewMode={viewMode}
            toggleViewMode={toggleViewMode}
            duration={duration}
            user={user}
            isGeneratingImages={isGeneratingImages}
            loadingImageIndex={loadingImageIndex}
            handleImageButtonClick={handleImageButtonClick}
            showSnackbar={showSnackbar}
          />
        )}

        {lessonData && (
          <>
            {!viewMode && (
              <Box
                ref={lessonTitleRef}
                sx={{ ...styles.headlineLessonBox, mt: 10 }}
              >
                <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
                  3. Din lektion
                </Typography>
              </Box>
            )}
            {!viewMode && (
              <Box sx={{ ...styles.freshBox, padding: "30px" }}>
                <Box sx={{ position: "relative" }}>
                  <Box
                    sx={{
                      ...styles.lessonControlPanel,
                      ...styles.lessonControlPanelNuevo,

                      "& .MuiButton-root": {
                        ...styles.controlPanelButtons,
                        ...styles.lessonControlPanelNewButtons,
                      },
                    }}
                  >
                    <Button
                      variant="purp"
                      onClick={() => setEditDialogOpen(true)}
                      startIcon={<EditIcon />}
                    >
                      Redigera tidslinjer & artikeln
                    </Button>
                    <Button
                      variant="print"
                      startIcon={<SaveAsIcon />}
                      sx={{ ml: 2 }}
                      onClick={handleSaveLessonClick}
                    >
                      Spara i min lektionsbank
                    </Button>

                    <Button
                      variant="success"
                      sx={{}}
                      onClick={() => setShareDialogOpenFriends(true)}
                      startIcon={<ShareIcon />}
                    >
                      Dela med vänner
                    </Button>

                    <Button
                      variant="success"
                      sx={{
                        ml: 2,
                      }}
                      onClick={() => setShareDialogOpen(true)}
                      startIcon={<ShareIcon />}
                    >
                      Dela med elever
                    </Button>
                    <ShareLink
                      buttonRef={shareLinkButtonRef}
                      key={lessonId}
                      lessonId={lessonId}
                      lessonData={lessonData}
                      user={user}
                      lessonType={"TimelineBuilderLesson.js"}
                      onCopy={(message, severity) =>
                        showSnackbar(message, severity)
                      }
                      sx={{ mt: 0 }}
                    />
                    <Tooltip title="Hur lektionen ser ut för eleverna">
                      <Button
                        variant="success"
                        onClick={() => {
                          window.scrollTo({ top: 0, behavior: "smooth" });
                          toggleViewMode();
                        }}
                        sx={{ ml: 2 }}
                        startIcon={
                          searchParams.get("v") === "t" ? (
                            <FullscreenExitIcon />
                          ) : (
                            <FullscreenIcon />
                          )
                        }
                      >
                        {searchParams.get("v") === "t"
                          ? "Redigeringsläge"
                          : "Elevläge"}
                      </Button>
                    </Tooltip>
                  </Box>
                  <Box
                    sx={{
                      ...styles.lessonControlPanelFade,
                    }}
                  />
                </Box>

                <TimelineBuilderGame
                  lessonData={lessonData}
                  setLessonData={setLessonData}
                  styles={styles}
                  theme={theme}
                  viewMode={viewMode}
                  toggleViewMode={toggleViewMode}
                  user={user}
                  duration={duration}
                  isGeneratingImages={isGeneratingImages}
                  loadingImageIndex={loadingImageIndex}
                  handleImageButtonClick={handleImageButtonClick}
                  showSnackbar={showSnackbar}
                />

                <TimeBuilderEditDialog
                  open={editDialogOpen}
                  onClose={() => setEditDialogOpen(false)}
                  lessonData={lessonData}
                  onSave={(editedData) => {
                    setLessonData(editedData);
                    showSnackbar("Ändringar sparade!", "success");
                  }}
                  isGeneratingImages={isGeneratingImages}
                  loadingImageIndex={loadingImageIndex}
                  onGenerateSpecificImage={handleGenerateSpecificImage}
                  onGenerateAllImages={() => handleImageButtonClick(null)}
                  user={user}
                  theme={theme}
                  showSnackbar={showSnackbar}
                  handleImageButtonClick={handleImageButtonClick}
                />

                <SaveLessonDialog
                  key={lessonData?.timelines[0].title}
                  open={saveDialogOpen}
                  onClose={() => setSaveDialogOpen(false)}
                  onSave={handleSaveWithComment}
                  theme={theme}
                  lessonName={lessonData?.timelines[0].title || ""}
                  lessonObject={lessonData}
                  lessonType={"TimelineBuilderLesson.js"}
                />

                <SlideInPaper
                  isExpanded={isExpanded}
                  onExpandedChange={setIsExpanded}
                  lessonTitleRef={lessonTitleRef}
                  viewMode={viewMode}
                  onEditTimelineAndArticle={() => setEditDialogOpen(true)}
                  toggleViewMode={toggleViewMode}
                  theme={theme}
                  open={showControlPanel}
                  user={user}
                  onShareLink={() => shareLinkButtonRef.current?.click()}
                  onShareWithStudents={() => setShareDialogOpen(true)}
                  onShareWithFriends={() => setShareDialogOpenFriends(true)}
                  onSaveToBank={() => setSaveDialogOpen(true)}
                  onStudentMode={() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                    toggleViewMode();
                  }}
                />

                <ShareDialog
                  open={shareDialogOpen}
                  onClose={() => setShareDialogOpen(false)}
                  onShare={handleShare}
                  user={user}
                  isLessonSharing={true}
                  theme={theme}
                  lessonName={lessonData?.title || ""}
                  lessonObject={lessonData}
                  lessonType={"EscapeRoomLesson.js"}
                />

                <ShareDialog
                  open={shareDialogOpenFriends}
                  onClose={() => setShareDialogOpenFriends(false)}
                  onShare={handleShare}
                  user={user}
                  isLessonSharing={true}
                  theme={theme}
                  lessonName={lessonData?.title || ""}
                  lessonObject={lessonData}
                  lessonType={"EscapeRoomLesson.js"}
                  shareWithFriends={true}
                />

                <LoadingBackdrop
                  isOpen={isSharing}
                  imagePath="/img/AI.png"
                  loadingText={"Delar lektionen..."}
                />

                <ImageGenerationModal
                  open={showImageModal}
                  onClose={handleCloseModal}
                  onGenerate={handleGenerateWithInstructions}
                  isGenerating={isGeneratingImages}
                  instructions={imageInstructions}
                  setInstructions={setImageInstructions}
                  theme={theme}
                />
              </Box>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default TimelineBuilderLesson;
