import React from "react";
import { useSpring, animated } from "@react-spring/web";
import {
  Box,
  TextField,
  IconButton,
  Paper,
  Typography,
  Tooltip,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import FlashcardImageUpload from "./FlashcardImageUpload";

// Create animated version of MUI Paper
const AnimatedPaper = animated(Paper);

const FlashcardItem = ({
  flashcard,
  index,
  handleFrontChange,
  handleBackChange,
  handleDeleteCard,
  handleTabPress,
  isDark,
  styles,
  user,
  showSnackbar,
  onImageUpdate,
}) => {
  const springProps = useSpring({
    from: {
      opacity: 0,
      transform: "translateY(20px)",
    },
    to: {
      opacity: 1,
      transform: "translateY(0px)",
    },
    delay: index * 20,
    config: {
      mass: 1,
      tension: 280,
      friction: 20,
    },
  });

  return (
    <AnimatedPaper
      elevation={2}
      style={springProps}
      sx={{
        position: "relative",
        p: 3,
        pt: 5,
        mb: 3,
        borderRadius: "10px",
        backgroundImage: "none",
        boxShadow: "0 3px 28px rgba(12,8,51,.06)",
      }}
    >
      {/* Flashcard Number */}
      <Typography
        variant="body2"
        sx={{
          position: "absolute",
          top: 16,
          left: 16,
          color: isDark ? "grey.400" : "grey.600",
          fontWeight: 600,
          fontSize: "1.2rem",
        }}
      >
        {index + 1}.
      </Typography>

      {/* Delete Button */}
      <Tooltip title="Ta bort flashcard">
        <IconButton
          onClick={() => handleDeleteCard(index)}
          aria-label="delete"
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
          }}
        >
          <DeleteOutlineIcon sx={{ color: "text.secondary" }} />
        </IconButton>
      </Tooltip>

      {/* Content */}
      <Box
        sx={{
          display: "flex",
          gap: 2,
          width: "100%",
          margin: 0,
          paddingTop: "20px",
        }}
      >
        <FlashcardImageUpload
          userId={user.uid}
          imageUrl={flashcard.imageUrlFront}
          onImageUpdate={(url) => onImageUpdate(index, url, "front")}
          showSnackbar={showSnackbar}
          styles={styles}
        />

        <Box sx={{ flex: 1 }}>
          <Typography
            variant="caption"
            sx={{
              color: isDark ? "grey.400" : "grey.600",
              ml: 1,
              mb: 1,
              display: "block",
              fontWeight: "500",
              fontSize: "1rem",
            }}
          >
            Framsida
          </Typography>
          <TextField
            id={`front-${index}`}
            placeholder="Kortets framsida"
            value={flashcard.front}
            onChange={(e) => handleFrontChange(index, e)}
            onKeyDown={(e) => handleTabPress(index, false, e)}
            fullWidth
            multiline
            rows={2}
            margin="none"
            sx={{
              fontWeight: "600",
            }}
          />
        </Box>

        <Box sx={{ flex: 1 }}>
          <Typography
            variant="caption"
            sx={{
              color: isDark ? "grey.400" : "grey.600",
              ml: 1,
              mb: 1,
              display: "block",
              fontWeight: "500",
              fontSize: "1rem",
            }}
          >
            Baksida
          </Typography>
          <TextField
            id={`back-${index}`}
            placeholder="Kortets baksida"
            value={flashcard.back}
            onChange={(e) => handleBackChange(index, e)}
            onKeyDown={(e) => handleTabPress(index, true, e)}
            fullWidth
            rows={2}
            multiline
            margin="none"
            sx={{
              fontWeight: "600",
            }}
          />
        </Box>

        <Box>
          <FlashcardImageUpload
            userId={user.uid}
            imageUrl={flashcard.imageUrlBack}
            onImageUpdate={(url) => onImageUpdate(index, url, "back")}
            showSnackbar={showSnackbar}
            styles={styles}
          />
        </Box>
      </Box>
    </AnimatedPaper>
  );
};

export default FlashcardItem;
