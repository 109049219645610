import React, { useState, useEffect } from "react";
import {
  Box,
  Chip,
  Button,
  Typography,
  TextField,
  Stepper,
  Step,
  StepLabel,
  Tooltip,
  Grid,
} from "@mui/material";
import { collection, addDoc } from "firebase/firestore";
import { db } from "./firebase";
import { getStyles } from "../index";
import { useTheme } from "@mui/material/styles";
import GradeSelector from "./GradeSelector";
import { shortcuts } from "./DashboardToolsShortcuts";
import LoadingBackdrop from "./LoadingBackdrop";
import GenerateIcon from "@mui/icons-material/AutoAwesome";
import { East as ArrowIcon } from "@mui/icons-material";
import WestIcon from "@mui/icons-material/West";
import { useSnackbar } from "./SnackbarHandler";
import ShortcutBox from "./ShortcutBox";
import ShareRoomDialog from "./ShareRoomDialog";

import Logger from "./Logger";

const RoomCreate = ({ user, onCancel, onFinish }) => {
  const [activeStep, setActiveStep] = useState(0);
  const theme = useTheme();
  const styles = getStyles(theme);
  const { showSnackbar } = useSnackbar();
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [showShareDialog, setShowShareDialog] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("Skapar rum...");

  const [selectedCategory, setSelectedCategory] = useState("Populära");

  const [roomData, setRoomData] = useState({
    name: `Elevrum - ${new Date()
      .toLocaleDateString("sv-SE", {
        day: "numeric",
        month: "short",
        year: "numeric",
      })
      .replace(".", "")}`,
    grade: "åk8",
    maxStudents: "25",
    roomId: null,
    selectedTools: [],
  });

  const steps = ["Skapa rummet", "Konfigurera verktyg", "Bjud in elever"];

  useEffect(() => {
    Logger.log("roomData: ", JSON.stringify(roomData, null, 2));
  }, [roomData]);

  const handleShareDialogClose = () => {
    setShareDialogOpen(false);
    onFinish(); // Navigate back to room list view
  };

  const handleCreateRoom = async () => {
    setIsLoading(true);
    setShowShareDialog(false);

    // First loading state
    setLoadingText("Skapar rum...");
    await new Promise((resolve) => setTimeout(resolve, 1500));

    // Second loading state
    setLoadingText("Begränsar elevernas verktyg...");
    await new Promise((resolve) => setTimeout(resolve, 1500));

    // Third loading state
    setLoadingText("Förbereder delning...");
    await new Promise((resolve) => setTimeout(resolve, 1500));

    try {
      const toolIds = roomData.selectedTools.map((tool) => tool.id);
      const roomRef = await addDoc(collection(db, "rooms"), {
        name: roomData.name,
        grade: roomData.grade,
        maxStudents: roomData.maxStudents,
        userId: user.uid,
        studentCount: 0,
        status: "active",
        createdAt: new Date(),
        shortcuts: toolIds,
      });
      setRoomData({ ...roomData, roomId: roomRef.id });
      showSnackbar(`Rummet "${roomData.name}" har skapats!`, "success");
      setShowShareDialog(true);
    } catch (error) {
      console.error("Error creating room:", error);
      showSnackbar(
        "Ett fel inträffade när rummet skapades. Vänligen försök igen.",
        "error",
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleNext = () => {
    if (activeStep === 1) {
      handleCreateRoom();
    }
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleGradeChange = (newGrade) => {
    setRoomData({ ...roomData, grade: newGrade });
  };

  const handleToolToggle = (shortcut) => {
    setRoomData((prevData) => {
      const isSelected = prevData.selectedTools.some(
        (tool) => tool.id === shortcut.id,
      );
      const selectedTools = isSelected
        ? prevData.selectedTools.filter((tool) => tool.id !== shortcut.id)
        : [...prevData.selectedTools, shortcut];

      if (!isSelected) {
        showSnackbar(`${shortcut.title} tillagt!`, "success");
      }

      return { ...prevData, selectedTools };
    });
  };

  const studentShortcuts = shortcuts.filter((shortcut) =>
    shortcut.roles.includes("student"),
  );

  const renderStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <Box sx={{ width: { xs: "100%", md: "65%" }, margin: "0 auto" }}>
            <Box sx={{ ...styles.freshBox, padding: "30px" }}>
              <Typography
                variant="formLabelSinUnderline"
                sx={{ fontWeight: "500", display: "block", mb: 1 }}
              >
                Namn på rummet:
              </Typography>
              <TextField
                fullWidth
                value={roomData.name}
                onChange={(e) =>
                  setRoomData({ ...roomData, name: e.target.value })
                }
                sx={{ mb: 2 }}
              />

              <Typography
                variant="formLabelSinUnderline"
                sx={{ fontWeight: "500", display: "block", mb: 1, mt: 2 }}
              >
                Årskurs:
              </Typography>
              <GradeSelector
                grade={roomData.grade}
                setGrade={handleGradeChange}
              />

              <Typography
                variant="formLabelSinUnderline"
                sx={{ fontWeight: "500", display: "block", mb: 1, mt: 4 }}
              >
                Max antal elever:
              </Typography>
              <TextField
                fullWidth
                type="number"
                value={roomData.maxStudents}
                onChange={(e) =>
                  setRoomData({ ...roomData, maxStudents: e.target.value })
                }
              />
            </Box>
          </Box>
        );
      case 1:
        return (
          <Box sx={{ mt: 2 }}>
            <Box sx={{ ...styles.headlineLessonBox, mt: 6 }}>
              <Typography
                variant="h6"
                sx={{ ...styles.headlineLessonBoxTitle, textAlign: "left" }}
              >
                Valda verktyg
              </Typography>
            </Box>

            <Box sx={{ ...styles.freshBox, padding: "20px" }}>
              <Grid container spacing={2} sx={{ mb: 0 }}>
                {roomData.selectedTools.length === 0 ? (
                  <Grid item xs={12} sm={6} md={4}>
                    <ShortcutBox
                      icon={<GenerateIcon />}
                      title="Dina valda verktyg hamnar här!"
                      subtitle="Välj de verktyg som dina elever i detta rum ska ha tillgång till."
                      isEmpty={true}
                    />
                  </Grid>
                ) : (
                  roomData.selectedTools.map((tool, index) => (
                    <Tooltip title="Ta bort från valda verktyg" placement="top">
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        <ShortcutBox
                          {...tool}
                          user={user}
                          isRoomCreatingView={true}
                          isSelected={true}
                          onSelect={() => handleToolToggle(tool)}
                        />
                      </Grid>
                    </Tooltip>
                  ))
                )}
              </Grid>
            </Box>

            <Box sx={{ ...styles.headlineLessonBox, mt: 6 }}>
              <Typography
                variant="h6"
                sx={{ ...styles.headlineLessonBoxTitle, textAlign: "left" }}
              >
                Tillgängliga verktyg
              </Typography>
            </Box>

            <Box sx={{ ...styles.freshBox, padding: "20px" }}>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mb: 3 }}>
                {[
                  "Populära",

                  ...new Set(
                    studentShortcuts.map((shortcut) => shortcut.category),
                  ),
                ]
                  .filter(Boolean)
                  .map((category, index) => (
                    <Chip
                      key={index}
                      icon={category === "Populära" ? <GenerateIcon /> : null}
                      label={category}
                      sx={{
                        fontWeight: "500",
                        padding: "7px",
                        paddingTop: "12px",
                        paddingBottom: "12px",
                        fontSize: "0.8rem",
                      }}
                      onClick={() => setSelectedCategory(category)}
                      color={
                        selectedCategory === category ? "primary" : "default"
                      }
                    />
                  ))}
              </Box>

              <Grid container spacing={2}>
                {studentShortcuts
                  .filter(
                    (shortcut) =>
                      !roomData.selectedTools.some(
                        (tool) => tool.id === shortcut.id,
                      ) &&
                      (selectedCategory === "Populära" ||
                        shortcut.category === selectedCategory),
                  )
                  .map((shortcut, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                      <ShortcutBox
                        {...shortcut}
                        user={user}
                        isRoomCreatingView={true}
                        onSelect={() => handleToolToggle(shortcut)}
                      />
                    </Grid>
                  ))}
              </Grid>
            </Box>
          </Box>
        );
      case 2:
        return (
          <>
            {showShareDialog && (
              <ShareRoomDialog
                open={true}
                onClose={handleShareDialogClose}
                room={{ id: roomData.roomId, name: roomData.name }}
              />
            )}
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Box sx={styles.container}>
        <Box
          sx={{
            ...styles.innerContainer,
            width: { xs: "100%", md: "100%" },
            margin: "0 auto",
          }}
        >
          <Box
            sx={{
              ...styles.buttonsContainer,
              ...styles.additionalButtonsContainer,
              left: { xs: "6px", md: "0px" },
            }}
          >
            {activeStep > 0 && (
              <Button variant="print" onClick={handleBack}>
                <WestIcon sx={{ mr: 1 }} /> Tillbaka
              </Button>
            )}
          </Box>

          <Box
            sx={{
              ...styles.buttonsContainer,
              ...styles.additionalButtonsContainer,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",

                gap: 1,
              }}
            >
              <Button
                variant="print"
                onClick={onCancel}
                sx={{ display: "none" }}
              >
                Avbryt
              </Button>

              {activeStep === steps.length - 1 ? (
                <Button variant="purp" onClick={onFinish}>
                  Slutför
                </Button>
              ) : (
                <Tooltip
                  title={
                    activeStep === 1 && roomData.selectedTools.length === 0
                      ? "Du måste välja minst ett verktyg"
                      : ""
                  }
                >
                  <span>
                    <Button
                      variant="purp"
                      onClick={handleNext}
                      disabled={
                        (activeStep === 0 &&
                          (!roomData.name ||
                            !roomData.grade ||
                            !roomData.maxStudents)) ||
                        (activeStep === 1 &&
                          roomData.selectedTools.length === 0)
                      }
                    >
                      Nästa <ArrowIcon sx={{ ml: 1 }} />
                    </Button>
                  </span>
                </Tooltip>
              )}
            </Box>
          </Box>

          <Stepper
            activeStep={activeStep}
            sx={{
              width: { xs: "100%", md: "50%" },
              margin: "0 auto",
              mb: 4,
              mt: 4,
            }}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          <Box sx={{ ...styles.headlineLessonBox, mt: 2, mb: 12 }}>
            <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
              {activeStep === 1
                ? "Välj verktyg för dina elever"
                : activeStep === 2
                  ? "Dela med dina elever"
                  : "Skapa rum"}
            </Typography>

            <Typography
              variant="h6"
              align="center"
              sx={{
                mt: 2,
                display: "block",
                color: "text.secondary",
                fontWeight: 400,
                fontSize: "1rem",
              }}
            >
              {activeStep === 1
                ? "Anpassa vilka verktyg eleverna i detta rum ska ha tillgång till."
                : "Lägg till information om rummet och begränsa antalet elever."}
            </Typography>
          </Box>

          {renderStepContent()}
        </Box>
        <LoadingBackdrop
          isOpen={isLoading}
          imagePath="/img/AI.png"
          loadingText={loadingText}
        />
      </Box>
    </>
  );
};

export default RoomCreate;
