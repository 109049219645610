import React from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Container,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const teacherFaqData = [
  {
    question: "Vad kan jag som lärare & pedagog använda Studera.ai till?",
    answer:
      'Vi erbjuder ett brett utbud av AI-verktyg för lärare & pedagoger, däribland möjligheten att med hjälp av AI generera lektioner och pedagogiskt material i 50 olika språk för olika ämnen som t.ex. svenska, matematik och NO. Du kan skapa allt från grundläggande läsförståelseövningar till kreativa lektioner som "På spåret", "Grej of the day" och "Mystiska bilden".',
  },
  {
    question: "Hur sparar jag lektionerna jag har genererat?",
    answer:
      'Du kan enkelt klicka på "Spara" varpå lektionen sparas i din egen privata lektionsbank, och är åtkomlig närsomhelst för att redigera, anpassa för elever med specifika behov, eller skriva ut. Du kan givetvis också skriva ut lektionen närsomhelst.',
  },

  {
    question:
      "Kan jag dela lektioner, prov, flashcards uppgifter och filer med elever?",
    answer:
      "Ja. Vi erbjuder ett säkert sätt för lärare att skapa elevrum där de enkelt kan bjuda in utvalda elever genom Google Classroom. När eleverna har sparat konto så kan lärararna dela allt material som genereras, se huruvida eleverna öppnat materialet samt få resultat och bedömningar på exempelvis prov, flashcards, uppgifter och filer. Läraren kan när som helst radera utvald elevdata.",
  },
  {
    question:
      "Kan jag anpassa materialet som genereras utifrån olika elevers behov?",
    answer:
      "Ja, klickar man på 'Anpassa svårighetsgrad' kan man som lärare skapa en separat version av det genererade materialet, anpassa längd, komplexitet i meningsstruktur samt generera det på ett helt annat språk om man så vill. Därefter kan man skriva ut den anpassade lektionen och ge denna till eleverna som nu får material anpasast utifrån deras förutsättningar.",
  },

  {
    question:
      "Har jag enkel tillgång till allt jag genererar; lektioner, prov, flashcards och filer?",
    answer:
      "Självklart. I menyn till vänster kan du se allt ditt genererade innehåll, du kan även se huruvida eleverna du delat innehållet med har öppnat materialet. Samt se deras resultat på prov och flashcards.",
  },

  {
    question:
      "Går det att generera formativa bedömningar, eller betygsätta en text?",
    answer:
      "Aboslut! Du kan välja en redan befintlig bedömningsmatris eller skapa en helt egen. Därefter behöver du bara ladda upp dokumentet eller klistra in texten du vill ha bedömd, varpå du får bedömningen genererad på 30-60 sekunder och kan därefter anpassa bedömningen, skriva ut, eller spara i ditt eget arkiv av bedömningar.",
  },

  {
    question: "Kan jag generera en lektionsplan?",
    answer:
      "Du kan enkelt generera en lektionsplan baserat på ämne från Skolverkets kursplan, Kulturrådets läslistor, artikel från SO-rummet, valfritt uppladdat dokument eller helt egna instruktioner.",
  },

  {
    question:
      "Hur säkerställs säkerheten för lärarna och elevernas data med GDPR?",
    answer:
      "Datasäkerhet är vår högsta prioritet. Alla data krypteras och lagras säkert enligt GDPR och andra dataskyddslagar. Vi säkerställer att ingen personlig information som namn, e-post eller andra identifierande uppgifter delas med  tredje part. Alla förfrågningar anonymiseras innan de skickas till AI-modellen.\n\nVidare vill vi understryka har vi aktiverat opt-out för modellträning hos OpenAI, vilket innebär att dina förfrågningar aldrig används för att förbättra deras modeller. \n\nDu kan själv ställa in om lektionerna som genereras ska sparas i vårt system, eller ej — då kan du helt enkelt bara skriva ut lektionen eller spara som PDF. Endast behöriga lärare har tillgång till sina elevers data och kan när som helst radera utvald data. Vi är transparenta med hur din data hanteras och ger dig full kontroll över din information.",
  },
  {
    question:
      "Är det pedagogiska materialet inkluderande och präglat av mångfald?",
    answer:
      "Ja, att samtliga elever i klassrummet, oavsett bakgrund och personlig identitet, ska kunna känna sig inkluderade i det pedagogiska material som genereras är en av våra högsta prioriteter. Därför kommer bilderna och lektionsmaterialet som genereras representera ett brett spektrum av perspektiv, kulturer och erfarenheter. Vi strävar således efter att generera material som speglar en mångfald av berättelser och individer, för att främja en inkluderande och respektfull lärandemiljö där alla elever känner sig sedda och värdesatta.",
  },
  {
    question: "Hur många språk stödjer ni?",
    answer:
      "Studera.ai stödjer över 50 olika språk, med särskild hänsyn till språk som talas av olika grupper i Sverige, såsom arabiska, somaliska, persiska (farsi), urdu och polska. Vi strävar efter att inkludera både stora världsspråk och mindre språk från Mellanöstern, Afrika och Asien för att säkerställa att vi möter behoven hos så många lärare och elever som möjligt.",
  },
  {
    question: "Kan jag generera bilder till mitt pedagogiska material?",
    answer:
      "Ja, en av våra mest älskade funktioner är möjligheten att - utifrån egna instruktioner - generera engagerande bilder till materialet du skapar. Du kan även ladda upp valfri fil eller välja från ett delat bildarkiv.",
  },

  {
    question: "Måste jag som lärare kontrollera allt material som genereras?",
    answer:
      "Vi rekommenderar 80-20-principen: Låt AI ta hand om grunden och det inledande arbetet, men se till att de sista 20% får din personliga prägel för att skapa innehåll som är både relevant och kontextanpassat.",
  },

  {
    question: "Hur genererar jag grupparbete?",
    answer:
      "Du kan snabbt generera grupparbete baserat på årskurs, språk, tid, ämne, syfte, antal elever, max antal elever per grupp samt huruvida du vill att det ska genereras indivudella uppgifter/roller inom gruppen.",
  },

  {
    question: "Vilka AI-modeller använder ni?",
    answer:
      "Vi stödjer flera ledande AI-modeller såsom Claude Sonnet och Sonnet 3.5 från Anthropic,  Google Gemini, Mistral Large och ChatGPT 4o från OpenAI. Vi har även vår egen AI-modell som vi ständigt finjusterar för att optimera prestandan för våra användares behov och säkerställa att material som genereras håller bästa tänkbara kvalitet.",
  },
  {
    question: "Hur kan jag få support om jag behöver hjälp?",
    answer:
      "Du kan kontakta oss via e-post på info@studera.ai så svarar vi inom 12-24 timmar. Vi uppskattar även din feedback som t.ex. förslag på nya lektioner och funktioner. Så hör gärna av dig!",
  },

  {
    question: "Är det gratis?",
    answer:
      "Ja, Studera.ai är en gratistjänst utvecklad för lärare, av lärare. Däremot finns det en daglig begränsning på antalet bilder och lektioner som man kan generera. Men tjänsten är således inte behäftad med en kostnad.",
  },
];

const studentFaqData = [
  {
    question: "Vad kan jag som elev använda Studera.ai till?",
    answer:
      "Med Studera.ai kan du få hjälp med dina studier på många olika sätt. Du kan skapa anpassade prov baserat på ditt studiematerial för att förbereda dig inför provdagen, tillsammans med bedömningar baserat på ditt resonemang, förbättringsförslag och mycket mer. Däruötver kan du generera och öva på flashcards, chatta med AI-lärare inom alla världens språk för att skräpa din språkförmåga samt få förklaringar på svåra begrepp i ett format som passar dig. Vi erbjuder något för alla elever och studenter oavsett kunskapsnivå.",
  },

  {
    question: "Hur kan jag generera quiz eller prov med AI?",
    answer:
      'Quiz-generatorn använder AI för att skapa anpassade frågor baserade på ditt studiematerial eller valfritt underlag. Du kan t.ex. ladda upp dokument, klistra in text, välja ett YouTube-klipp, en artikel från SO-rummet, valfri URL/Webbadress, ämne från Skolverkets kursplan eller helt enkelt skriva egna instruktioner. Därefter väljer du typ av frågor (öppna frågor där du skriver in svaret i ett textfält för att simulera ett verkligt prov, flervalsfrågor med 4 alternativ att välja mellan, sant/falskt samt fyll i luckan). \n\nVidare kan du själv bestämma om du vill att vi genererar ledtrådar för varje fråga. Slutligen analyserar Studera.AI underlaget du valt och skapar relevanta frågor.  Det är ett utmärkt verktyg för att testa din förståelse och förbereda dig för prov. De flesta av våra användare använder vår quiz-generator som läxförhör, så de laddar upp t.ex. ett bokkapitel eller skriver in att de vill öva på valfritt ämne, t.ex. "jämställdheten historia under 1900-talet" och får frågor på innehållet för att förbereda sig inför ett prov.',
  },

  {
    question: "Hur genererar jag flashcards med AI?",
    answer:
      'Du börjar med att välja underlag till dina flashcards, t.ex. ämne från Skolverkets kursplan, studiematerial, dokument, artiklar från SO-rummet, eller till och med YouTube-klipp. Därpå klickar du på "Generera flashcards" varpå Studera.AI identifierar nyckelkoncept och skapar flera fråga-svar-par. Du kan anpassa antalet kort, lägg till bilder till antingen framsida, baksida eller bådadera för att träna upp det visuella minnet. Vi använder också spaced repetition (SRS) för att optimera din inlärning över tid, genom att presentera korten vid optimala intervall för långsiktig minnesbehållning.',
  },

  {
    question: "Hur kan jag använda Studera.ai för att lära mig språk?",
    answer:
      "Våra AI-chatbotar är utmärkta för språkinlärning. Du kan välja en språklärare och engagera dig i konversationer på det språk du vill lära dig. Boten kan anpassa svårighetsgraden, rätta dina misstag, förklara grammatik, och till och med simulera olika rollspel som restaurangbesök eller jobbintervjuer. Detta ger dig möjlighet att öva på verklighetstrogna konversationer i en trygg miljö, där du när som helst kan ställa frågor utan att känna stress och press.",
  },
  {
    question: "Hur många språk stödjer ni?",
    answer:
      "Studera.ai stödjer över 50 olika språk, med särskild hänsyn till språk som talas av olika grupper i Sverige, såsom arabiska, somaliska, persiska (farsi), urdu och polska. Vi strävar efter att inkludera både stora världsspråk och mindre språk från Mellanöstern, Afrika och Asien för att säkerställa att vi möter behoven hos så många elever som möjligt.",
  },

  {
    question:
      "Kan jag simulera debatter för att slipa min argumentationsteknik?",
    answer:
      "Ja, du kan enkelt skapa en simulerad debatt med historiska figurer eller experter inom olika områden. Du kan välja ett ämne och en motståndare, och sedan argumentera för din ståndpunkt. Studera.AI kommer att svara med motargument baserade på ditt valda perspektiv. Detta hjälper dig att utveckla dina argumentationsfärdigheter, se olika perspektiv på komplexa frågor, och fördjupa din förståelse för olika ämnen. Du kan också (efter 20 skrivna argument) be om att få en analys på argumenten och få feedback på din debatteknik.",
  },

  {
    question:
      "Kan jag få anpassade förklaringar på olika frågor i ett format som passar mig?",
    answer:
      "Absolut! Skriv in vad du vill få förklarat (t.ex. ett matematiskt problem eller en etisk fråga), välj önskat format som passar dig som exempelvis  tabell, punktlista, utförlig text, eller - och detta är populärt - i form av en engagerande historia där förklaringarna finns inbakade i en text du själv väljer tema för. Du kan även få förklaringen på det språk du känner dig mest bekväm med.",
  },

  {
    question: "Hur kan Studera.ai hjälpa mig med högskoleprovet?",
    answer:
      'Vi erbjuder omfattande stöd för förberedelser inför högskoleprovet. Du kan träna på gamla högskoleprovprov och även generera frågor med AI för alla delar av provet (ORD, LÄS, MEK, XYZ, ELF). Klicka på "Fråga AI" om du har frågor gällande en viss fråga för att få svåra koncept förklarade på ett enkelt sätt.',
  },

  {
    question: "Kan jag skapa mina egna AI-karaktärer?",
    answer:
      "Ja, du kan skapa dina egna AI-karaktärer. Du kan anpassa karaktärens personlighet, undervisningsstil, och expertisområde. Du kan till exempel skapa en historielärare som specialiserar sig på medeltiden, eller en matematiklärare som är särskilt bra på att förklara komplexa koncept med enkla analogier. Du kan även anpassa läraren för specifika behov, som dyslexianpassning eller ADHD-anpassad kommunikation. Vårt syfte är att elever och studenter med olika förutsättningar ska kunna nyttja vår plattform för att öka sin kunskap och i förlängningen sitt självförtroende - genom att skapa en AI-lärare som kommunicerar till dem utifrån deras specifika behov, sin alldeles egen drömlärare helt enkelt.",
  },

  {
    question: "Är det gratis?",
    answer:
      "Ja, Studera.ai är helt gratis för elever och studenter. Dock finns det en daglig begränsning på antalet prov/quiz, flashcards, chattmeddelanden man kan skriva. Men utöver detta är tjänsten helt gratis för elever såväl som studenter!",
  },
];

const SimpleFAQSection = ({ student = false }) => {
  const faqData = student ? studentFaqData : teacherFaqData;

  return (
    <Box sx={{ py: 8 }}>
      <Container maxWidth="md">
        <Box>
          {faqData.map((faq, index) => (
            <Accordion
              key={index}
              sx={{
                mb: 2,
                boxShadow: "none",
                border: "none",
                "&:before": { display: "none" },
                bgcolor: "transparent",
                "& .MuiCollapse-root": {
                  bgcolor: "transparent",
                },
                "& .MuiAccordion-region": {
                  bgcolor: "transparent",
                },
              }}
              disableGutters
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ fontSize: "2.2rem" }} />}
                sx={{
                  bgcolor: "transparent",
                  "& .MuiAccordionSummary-content": {
                    margin: "12px 0",
                  },
                  "& .MuiTypography-root": {
                    transition: "color 0.3s ease",
                  },
                  "&:hover .MuiTypography-root": {
                    color: "gray",
                  },
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "600",
                    fontSize: "1.3rem",
                  }}
                >
                  {faq.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ bgcolor: "transparent", pt: 0 }}>
                <Typography
                  sx={{
                    lineHeight: "1.9rem",
                    fontSize: "1.1rem",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  {faq.answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default SimpleFAQSection;
