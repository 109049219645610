import React from "react";
import {
  Box,
  Typography,
  Button,
  IconButton,
  Tooltip,
  Avatar,
  Divider,
} from "@mui/material";
import { useSpring, animated } from "@react-spring/web";
import LinearProgress from "@mui/material/LinearProgress";
import { lighten } from "@mui/material/styles";
import ShuffleIcon from "@mui/icons-material/Shuffle";
import SpaceBarIcon from "@mui/icons-material/SpaceBar";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import Looks3Icon from "@mui/icons-material/Looks3";
import Looks4Icon from "@mui/icons-material/Looks4";
import LooksThreeIcon from "@mui/icons-material/Looks3";
import LooksFourIcon from "@mui/icons-material/Looks4";
import GenerateIcon from "@mui/icons-material/AutoAwesome";
import BookmarkIcon from "@mui/icons-material/BookmarkBorder";
import CheckCircleIcon from "@mui/icons-material/Check";

import Voice from "./Voice";
import Hint from "./Hint";
import AIDialog from "./AIDialog";
import { formatDate } from "./functions";
import RecommendationComponent from "./RecommendationComponent";

const FlashcardGame = ({
  currentCollection,
  currentCardIndex,
  showBack,
  sessionSummary,
  bookmarks,
  creatorInfo,
  originalCollectionId,
  user,
  theme,
  styles,
  isDark,
  filterFlashcards,
  onFlipCard,
  onShuffleCards,
  onToggleBookmark,
  onRateCard,
  onAskAI,
}) => {
  const { transform, opacity } = useSpring({
    opacity: showBack ? 1 : 0,
    transform: `perspective(800px) rotateY(${showBack ? 180 : 0}deg)`,
    config: { mass: 5, tension: 500, friction: 80 },
  });

  const [aiDialogOpen, setAiDialogOpen] = React.useState(false);

  const handleAskAI = () => {
    setAiDialogOpen(true);
    if (onAskAI) onAskAI();
  };

  return (
    <Box
      sx={{
        width: { xs: "100%", md: "90%" },
        display: "block",
        margin: "0 auto",
        paddingTop: "50px !important",
      }}
    >
      <AIDialog
        userId={user.uid}
        open={aiDialogOpen}
        onClose={() => setAiDialogOpen(false)}
        initialQuestion={
          currentCollection?.flashcards?.[currentCardIndex]
            ? `${currentCollection.flashcards[currentCardIndex].front} - ${currentCollection.flashcards[currentCardIndex].back}
Please explain these flashcards and help me understand the answer options. Skriv på svenska.`
            : "Please explain the current flashcard and help me understand the answer options. Skriv på svenska."
        }
        systemMessage="You are a helpful AI assistant designed to explain and assist flashcards practiceProvide clear, pedagogical explanations using analogies and examples when appropriate. Skriv på svenska, skriv kortfattat."
        theme={theme}
      />

      <Typography
        variant="h5"
        sx={{
          ...styles.headerAboveBox,
          textAlign: "center",
          display: "block",
          fontWeight: "600",
          mb: 2,
        }}
      >
        {currentCollection.collectionName}
      </Typography>

      <Box
        sx={{
          width: { xs: "100%", md: "90%" },
          height: { xs: "auto", md: "370px" },
          minHeight: { xs: "200px", md: "370px" },
          position: "relative",
          margin: "0 auto",
          mt: 2,
        }}
      >
        <LinearProgress
          variant="determinate"
          value={
            (currentCardIndex / filterFlashcards(currentCollection).length) *
            100
          }
          sx={{
            height: 7,
            borderRadius: 5,
            marginBottom: 2,
            bgcolor: (theme) => lighten(theme.palette.text.main, 0.7),
            "& .MuiLinearProgress-bar": {
              borderRadius: 5,
              backgroundColor: (theme) => theme.palette.text.main,
              transition: "transform 0.5s ease-in-out",
            },
          }}
        />

        {/* Front of card */}
        <animated.div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            backfaceVisibility: "hidden",
            opacity: opacity.to((o) => 1 - o),
            transform,
            cursor: "pointer",
          }}
          onClick={onFlipCard}
        >
          {/* Card controls */}
          <Box sx={{ position: "absolute", top: 10, right: 10, zIndex: 1 }}>
            <Tooltip title="Blanda om">
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  onShuffleCards();
                }}
                sx={{ color: theme.palette.text.primary }}
              >
                <ShuffleIcon sx={{ color: isDark ? "#fff" : "#757575" }} />
              </IconButton>
            </Tooltip>
          </Box>

          <Box sx={{ position: "absolute", top: 10, right: 50, zIndex: 1 }}>
            <Voice
              content={
                filterFlashcards(currentCollection)[currentCardIndex]?.front
              }
              fontSize={26}
              userId={user.uid}
            />
          </Box>

          <Box sx={{ position: "absolute", top: 10, left: 10, zIndex: 1 }}>
            <Tooltip title={bookmarks[currentCardIndex] ? "Sparat" : "Spara"}>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  onToggleBookmark();
                }}
                sx={{
                  color: bookmarks[currentCardIndex]
                    ? "green"
                    : theme.palette.text.primary,
                }}
              >
                {bookmarks[currentCardIndex] ? (
                  <CheckCircleIcon />
                ) : (
                  <BookmarkIcon sx={{ color: isDark ? "#fff" : "#757575" }} />
                )}
              </IconButton>
            </Tooltip>
          </Box>

          <Box
            sx={{ position: "absolute", top: "20px", left: "60px", zIndex: 1 }}
          >
            <Hint
              front={
                filterFlashcards(currentCollection)[currentCardIndex]?.front
              }
              back={filterFlashcards(currentCollection)[currentCardIndex]?.back}
              user={user}
              theme={theme}
            />
          </Box>

          {/* Card content */}
          <Typography
            variant="h6"
            sx={{
              ...styles.cardText,
              fontSize: {
                xs: "17px",
                md: "22px",
              },
              overflowY: "auto",
              maxHeight: "100%",
              wordBreak: "break-word",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {filterFlashcards(currentCollection)[currentCardIndex]
              ?.imageUrlFront ? (
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
                  gap: 2,
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <Box sx={{ textAlign: "center" }}>
                  {filterFlashcards(currentCollection)[currentCardIndex]
                    ?.front || "Inga flashcards tillgängliga"}
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src={
                      filterFlashcards(currentCollection)[currentCardIndex]
                        ?.imageUrlFront
                    }
                    alt="Card visualization"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "250px",
                      objectFit: "contain",
                      borderRadius: "10px",
                    }}
                  />
                </Box>
              </Box>
            ) : (
              filterFlashcards(currentCollection)[currentCardIndex]?.front ||
              "Inga flashcards tillgängliga"
            )}
          </Typography>
        </animated.div>

        {/* Back of card */}
        <animated.div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            backfaceVisibility: "hidden",
            opacity,
            transform: transform.to((t) => `${t} rotateY(180deg)`),
            cursor: "pointer",
          }}
          onClick={onFlipCard}
        >
          <Typography
            variant="h6"
            sx={{
              ...styles.cardText,
              fontSize: {
                xs: "17px",
                md: "22px",
              },
              overflowY: "auto",
              maxHeight: "100%",
              wordBreak: "break-word",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {filterFlashcards(currentCollection)[currentCardIndex]
              ?.imageUrlBack ? (
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
                  gap: 2,
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <Box sx={{ textAlign: "center" }}>
                  {filterFlashcards(currentCollection)[currentCardIndex]
                    ?.back || "Inga flashcards tillgängliga"}
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src={
                      filterFlashcards(currentCollection)[currentCardIndex]
                        ?.imageUrlBack
                    }
                    alt="Card visualization"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "250px",
                      objectFit: "contain",
                      borderRadius: "10px",
                    }}
                  />
                </Box>
              </Box>
            ) : (
              filterFlashcards(currentCollection)[currentCardIndex]?.back ||
              "Inga flashcards tillgängliga"
            )}
          </Typography>
        </animated.div>
      </Box>

      {/* Controls */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mt: 10,
        }}
      >
        {/* Flip card button */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            mb: 4,
          }}
        >
          <Button
            variant="purp"
            onClick={onFlipCard}
            endIcon={<SpaceBarIcon />}
            sx={{ width: { xs: "100%", md: "580px" } }}
          >
            Vänd kort
          </Button>
        </Box>

        {/* Difficulty buttons */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            gap: 2,
            mb: 4,
          }}
        >
          <Button
            variant="error-gradient"
            color={isDark ? undefined : "error"}
            onClick={() => onRateCard("fail")}
            endIcon={<LooksOneIcon />}
            disabled={!showBack}
            sx={{
              width: { xs: "100%", md: "auto" },
              maxWidth: { xs: "250px", md: "none" },
            }}
          >
            Väldigt svår
          </Button>
          <Button
            variant="warning-gradient"
            onClick={() => onRateCard("hard")}
            endIcon={<LooksTwoIcon />}
            disabled={!showBack}
            sx={{
              width: { xs: "100%", md: "auto" },
              maxWidth: { xs: "250px", md: "none" },
            }}
          >
            Svår
          </Button>
          <Button
            variant="info-gradient"
            onClick={() => onRateCard("good")}
            endIcon={<Looks3Icon />}
            disabled={!showBack}
            sx={{
              width: { xs: "100%", md: "auto" },
              maxWidth: { xs: "250px", md: "none" },
            }}
          >
            Ganska lätt
          </Button>
          <Button
            variant="success-gradient"
            onClick={() => onRateCard("easy")}
            endIcon={<Looks4Icon />}
            disabled={!showBack}
            sx={{
              width: { xs: "100%", md: "auto" },
              maxWidth: { xs: "250px", md: "none" },
            }}
          >
            Lätt
          </Button>
        </Box>

        {/* Ask AI button */}
        <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <Button
            variant="print"
            onClick={handleAskAI}
            startIcon={<GenerateIcon />}
            sx={{
              width: { xs: "100%", md: "auto" },
              maxWidth: { xs: "250px", md: "none" },
            }}
          >
            Fråga AI (F)
          </Button>
        </Box>
      </Box>

      {/* Additional info */}
      <Box sx={{ display: "none" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: { xs: "stretch", md: "flex-start" },
            justifyContent: "space-between",
            gap: 2,
            mt: 7,
            width: { xs: "100%", md: "100%" },
          }}
        >
          {creatorInfo && (
            <Box
              sx={{
                ...styles.smallBox,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor:
                  theme.palette.mode === "dark" ? "#2a3b4c57" : "#fff",
                borderRadius: "10px",
                padding: "10px",
                marginTop: { xs: "30px", md: 0 },
                width: { xs: "100%", md: "fit-content" },
              }}
            >
              <Avatar
                src={creatorInfo.avatarUrl}
                sx={{ width: 37, height: 37, mr: 2 }}
              >
                {creatorInfo.displayName[0].toUpperCase()}
              </Avatar>
              <Box>
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                  {creatorInfo.displayName}
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    variant="body2"
                    sx={{
                      color: theme.palette.text.secondary,
                      fontSize: "0.8rem",
                    }}
                  >
                    {formatDate(currentCollection.createdAt)}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}

          <Box
            sx={{
              ...styles.smallBox,
              backgroundColor:
                theme.palette.mode === "dark" ? "#2a3b4c57" : "#fff",
              borderRadius: "10px",
              width: { xs: "100%", md: "48%" },
              padding: "0px",
              margin: { xs: "0 auto", md: 0 },
              marginTop: { xs: "80px", md: 0 },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                color: theme.palette.text.secondary,
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  fontWeight: "bold",
                  fontSize: "13px",
                  flexShrink: 0,
                  padding: "20px",
                  paddingRight: "0px",
                }}
              >
                GENVÄGAR
              </Typography>
              <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "2px",
                  ml: 0,
                }}
              >
                <SpaceBarIcon />
                <LooksOneIcon />
                <LooksTwoIcon />
                <LooksThreeIcon />
                <LooksFourIcon />
                <Typography variant="body2" sx={{ ml: 1 }}>
                  (F = Fråga AI)
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box sx={{ width: { xs: "100%", md: "auto" } }}>
            <RecommendationComponent
              itemId={originalCollectionId}
              userId={user.uid}
              collectionName="flashcardCollections"
              questionText="Skulle du rekommendera dessa flashcards till andra användare?"
              largerStyle={true}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default FlashcardGame;
