import React, { useState, useRef } from "react";
import {
  Box,
  Typography,
  TextField,
  IconButton,
  Tooltip,
  Paper,
  Grid,
  Button,
  useTheme,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import GenerateIcon from "@mui/icons-material/AutoAwesome";
import HoverableImage from "./HoverableImage";
import ExercisesDialog from "./ExercisesDialog";

const WritingLessonDisplay = ({
  lessonData,
  setLessonData,
  viewMode,
  lineCount,
  lineHeight,
  isGeneratingImages,
  generatingImageIndex,
  handleImageButtonClick,
  setImageGenerationType,
  setSelectedWordIndex,
  setShowImageModal,
  user,
  showSnackbar,
  styles,
  callApi,
}) => {
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [editingWordIndex, setEditingWordIndex] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedExercise, setSelectedExercise] = useState(null);
  const [editedWord, setEditedWord] = useState("");
  const exercisesSectionRef = useRef(null);
  const theme = useTheme();

  const handleEditWord = (index) => {
    if (lessonData?.words?.[index]) {
      setEditingWordIndex(index);
      setEditedWord(lessonData.words[index].word);
    }
  };

  const handleSaveWord = (index) => {
    if (lessonData?.words?.[index]) {
      const updatedWords = [...lessonData.words];
      updatedWords[index] = {
        ...updatedWords[index],
        word: editedWord,
      };
      setLessonData({
        ...lessonData,
        words: updatedWords,
      });
      setEditingWordIndex(null);
      setEditedWord("");
      showSnackbar("Ordet har uppdaterats", "success");
    }
  };

  const handleDeleteWord = (index) => {
    if (lessonData?.words) {
      const updatedWords = lessonData.words.filter((_, i) => i !== index);
      setLessonData({
        ...lessonData,
        words: updatedWords,
      });
      showSnackbar("Ordet har tagits bort", "success");
    }
  };

  const renderExercise = (exercise) => {
    return (
      <Paper
        sx={{
          p: 4,
          mt: 5,
          borderRadius: 4,
          backgroundColor: theme.palette.background.paperHover,
          backgroundImage: "none",
        }}
      >
        <Box
          key={exercise.type}
          sx={{
            p: { xs: 0, md: 4 },
            position: "relative",
          }}
        >
          {!viewMode && (
            <Box
              sx={{
                position: "absolute",
                top: 16,
                right: 16,
                display: "flex",
                gap: 1,
                opacity: 0,
                transition: "opacity 0.2s",
                ".MuiBox-root:hover &": {
                  opacity: 1,
                },
              }}
            >
              <Tooltip title="Redigera">
                <IconButton
                  size="small"
                  onClick={() => {
                    setEditDialogOpen(true);
                    setSelectedExercise({
                      type: exercise.type,
                      content: exercise.content,
                      title: exercise.title,
                    });
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Ta bort">
                <IconButton
                  size="small"
                  onClick={() => {
                    const updatedLessonData = { ...lessonData };
                    const updatedExercises = lessonData.exercises.filter(
                      (ex) => ex.type !== exercise.type,
                    );
                    updatedLessonData.exercises = updatedExercises;
                    setLessonData(updatedLessonData);
                    showSnackbar("Övningen har tagits bort", "success");
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Box>
          )}

          <Typography variant="h6" sx={{ mb: 2, fontWeight: "500" }}>
            {exercise.title}
          </Typography>

          {exercise.type === "examples" ? (
            <Grid container spacing={3}>
              {exercise.content.map((wordExample, index) => (
                <Grid item xs={12} md={6} key={index}>
                  <Box
                    sx={{
                      padding: "25px",
                      position: "relative",
                      transition: "transform 0.2s ease-in-out",
                      backgroundColor: theme.palette.background.paper,
                      borderRadius: 2,
                      boxShadow: 1,
                      "&:hover": {
                        transform: "translateY(-4px)",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        right: "45px",
                        bottom: "-15px",
                        transform: "translateY(-50%)",
                      }}
                    ></Box>

                    <Box
                      sx={{
                        position: "absolute",
                        right: "5px",
                        bottom: "-15px",
                        transform: "translateY(-50%)",
                      }}
                    ></Box>

                    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: "bold",
                          color: theme.palette.primary.main,
                        }}
                      >
                        {wordExample.word}
                      </Typography>
                    </Box>
                    <Box sx={{ position: "relative", mb: 2 }}>
                      {wordExample.sentences.map((sentence, sentenceIndex) => (
                        <Typography
                          key={sentenceIndex}
                          variant="body1"
                          sx={{ fontStyle: "italic", mb: 1 }}
                        >
                          {sentence}
                        </Typography>
                      ))}
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography
              variant="body1"
              sx={{ whiteSpace: "pre-wrap", lineHeight: "1.8" }}
            >
              {exercise.content}
            </Typography>
          )}
        </Box>
      </Paper>
    );
  };
  return (
    <Box
      sx={{
        width: {
          xs: "100%",
          ...(viewMode && { sm: "70%" }),
        },
        margin: viewMode ? "0 auto" : undefined,
        marginTop: viewMode ? "100px" : undefined,
        ...(viewMode && { backgroundColor: "transparent" }),
        ...(viewMode && { borderColor: "transparent" }),
      }}
    >
      {/* Title Section */}
      {isEditingTitle ? (
        <TextField
          value={lessonData?.titleText || ""}
          onChange={(e) =>
            setLessonData({ ...lessonData, titleText: e.target.value })
          }
          onBlur={() => {
            setIsEditingTitle(false);
            showSnackbar("Titeln har uppdaterats", "success");
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setIsEditingTitle(false);
              showSnackbar("Titeln har uppdaterats", "success");
            }
          }}
          autoFocus
          fullWidth
          variant="standard"
          sx={{
            backgroundColor: "transparent",
            "& .MuiInputBase-input": {
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "2.6em",
              marginBottom: "1.2rem",
              marginTop: "1.5rem",
            },
          }}
          InputProps={{
            disableUnderline: true,
          }}
        />
      ) : (
        <Box
          sx={{
            position: "relative",
            display: "inline-block",
            margin: "0 auto",
            width: "100%",
            mb: 3,
            mt: viewMode ? 0 : 5,
            ":hover .edit-icon": {
              opacity: 1,
            },
          }}
        >
          <Typography
            variant="h1"
            sx={{
              fontWeight: "bold",
              fontSize: "2.6em",
              textAlign: "center",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 2,
            }}
          >
            {lessonData?.titleText || " "}
            {!viewMode && (
              <Box
                component="span"
                sx={{
                  opacity: 0,
                  transition: "opacity 0.2s",
                  display: "inline-flex",
                  gap: 0.5,
                }}
                className="edit-icon"
              >
                <Tooltip title="Redigera" placement="top">
                  <IconButton
                    size="small"
                    onClick={() => setIsEditingTitle(true)}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          </Typography>
        </Box>
      )}

      {/* Words Grid */}
      <Grid container spacing={3}>
        {lessonData?.words?.map((word, index) => (
          <Box
            key={index}
            sx={{
              position: "relative",
              ":hover .action-buttons": {
                opacity: 1,
              },
              margin: "20px",
              width: "100%",
            }}
          >
            {!viewMode && (
              <Box
                className="action-buttons"
                sx={{
                  position: "absolute",
                  top: "70px",
                  right: "0",
                  opacity: 0,
                  transition: "opacity 0.2s",
                  zIndex: 1,
                  display: "flex",
                  gap: 1,
                }}
              >
                {editingWordIndex === index ? (
                  <Tooltip title="Spara ändringar" placement="top">
                    <IconButton
                      onClick={() => handleSaveWord(index)}
                      size="small"
                    >
                      <SaveIcon />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title="Redigera ord" placement="top">
                    <IconButton
                      onClick={() => handleEditWord(index)}
                      size="small"
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title="Ta bort ord" placement="top">
                  <IconButton
                    onClick={() => handleDeleteWord(index)}
                    size="small"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            )}

            {editingWordIndex === index ? (
              <TextField
                value={editedWord}
                onChange={(e) => setEditedWord(e.target.value)}
                sx={{
                  fontWeight: "500",
                  fontSize: "23px",
                  display: "block",
                  width: "50%",
                  mb: 2,
                  mt: 8,
                  ml: 2,
                  "& .MuiInputBase-input": {
                    fontSize: "23px",
                    fontWeight: "500",
                    textTransform: "capitalize",
                  },
                }}
                autoFocus
              />
            ) : (
              <Typography
                sx={{
                  fontWeight: "600",
                  fontSize: "26px",
                  display: "block",
                  mt: 8,
                  ml: 2,
                  mb: 2,
                  textTransform: "capitalize",
                }}
              >
                {word.word}
              </Typography>
            )}

            <Grid item xs={12}>
              <Box
                sx={{
                  p: 2,
                  display: "flex",
                  alignItems: "center",
                  gap: 3,
                  borderRadius: "13px",
                  border: "1px solid #e0e0e091",
                  backgroundColor:
                    theme.palette.mode === "dark" ? "#1a2027" : "#f5f5f5",
                  ...(viewMode && { backgroundColor: "white" }),
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    width: 260,
                    height: 260,
                    flexShrink: 0,
                  }}
                >
                  <HoverableImage
                    imageUrl={word.image}
                    isLoading={isGeneratingImages}
                    loadingIndex={generatingImageIndex}
                    currentIndex={index}
                    onGenerateClick={() => {
                      setImageGenerationType("single");
                      setSelectedWordIndex(index);
                      setShowImageModal(true);
                    }}
                    userId={user.uid}
                    onImageUpdate={(newImageUrl) => {
                      const updatedWords = [...lessonData.words];
                      updatedWords[index] = {
                        ...updatedWords[index],
                        image: newImageUrl,
                      };
                      setLessonData({
                        ...lessonData,
                        words: updatedWords,
                      });
                    }}
                    height="260px"
                    theme={theme}
                    showSnackbar={showSnackbar}
                  />
                </Box>

                <Box sx={{ flex: 1, mt: 7 }}>
                  {Array(lineCount)
                    .fill(0)
                    .map((_, lineIndex) => (
                      <Box
                        key={lineIndex}
                        sx={{
                          borderBottom: "2px solid black",
                          mb: `${lineHeight}px`,
                          position: "relative",
                        }}
                      />
                    ))}
                </Box>
              </Box>
            </Grid>
          </Box>
        ))}
      </Grid>

      {/* Exercises Section */}

      {!viewMode && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 4,
            mb: 4,
            gap: 2,
          }}
        >
          <Button
            variant="print"
            startIcon={<AddIcon />}
            onClick={() => {
              setLessonData({
                ...lessonData,
                words: [
                  ...(lessonData?.words || []),
                  { word: "Ord", image: "" },
                ],
              });
              showSnackbar("Nytt ord har lagts till", "success");
            }}
          >
            Lägg till ord
          </Button>
        </Box>
      )}

      <Box ref={exercisesSectionRef}>
        {lessonData?.exercises?.map((exercise) => renderExercise(exercise))}
      </Box>
      {editDialogOpen && selectedExercise && (
        <ExercisesDialog
          exercisesSectionRef={exercisesSectionRef}
          lessonData={lessonData}
          open={editDialogOpen}
          initialStep={2}
          initialEditMode={true}
          initialExercise={selectedExercise}
          onClose={() => {
            setEditDialogOpen(false);
            setSelectedExercise(null);
          }}
          onUpdateExercise={(updatedExercise) => {
            const updatedLessonData = { ...lessonData };
            const existingExerciseIndex =
              updatedLessonData.exercises?.findIndex(
                (ex) => ex.type === updatedExercise.type,
              );

            if (existingExerciseIndex !== -1) {
              updatedLessonData.exercises[existingExerciseIndex] = {
                type: updatedExercise.type,
                title: updatedExercise.title,
                content: updatedExercise.content,
              };
            } else {
              updatedLessonData.exercises = [
                ...(updatedLessonData.exercises || []),
                {
                  type: updatedExercise.type,
                  title: updatedExercise.title,
                  content: updatedExercise.content,
                },
              ];
            }

            setLessonData(updatedLessonData);
            showSnackbar("Övningen har uppdaterats", "success");

            setTimeout(
              () =>
                exercisesSectionRef.current?.scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                }),
              100,
            );
          }}
          theme={theme}
          styles={styles}
          showSnackbar={showSnackbar}
          user={user}
          callApi={callApi}
        />
      )}
    </Box>
  );
};

export default WritingLessonDisplay;
