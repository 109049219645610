import React, { useState, useEffect } from "react";
import {
  Popover,
  Box,
  Avatar,
  Typography,
  Button,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import FeedbackDialog from "./FeedbackDialog";
import getListStyles from "./listStyles";
import WhatsNewIcon from "@mui/icons-material/NewReleases";
import SuggestIcon from "@mui/icons-material/Lightbulb";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import ClassroomIcon from "@mui/icons-material/Class";
import AddToClassIcon from "@mui/icons-material/GroupAdd";
import TocDialog from "./TocDialog";
import WhatsNewDialog from "./WhatsNewDialog";
import { useNavigate, Link } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "./firebase";
import SettingsDialog from "./SettingsDialog";
import { useAuth } from "./useAuth";

const SettingsPopover = ({
  isDark,
  openPopover,
  anchorEl,
  handlePopoverClose,
  handleLogout,
  openSettings,
  styles,
  theme,
  setOverrideTheme,
}) => {
  const { user, updateUser } = useAuth();
  const [userDisplayName, setUserDisplayName] = useState(user?.displayName);
  const [avatarUrl, setAvatarUrl] = useState(user?.photoURL);

  useEffect(() => {
    if (auth.currentUser?.displayName) {
      setUserDisplayName(auth.currentUser.displayName);
    }
  }, [auth.currentUser?.displayName]);

  useEffect(() => {
    if (user?.displayName) {
      setUserDisplayName(user.displayName);
    }
    if (user?.photoURL) {
      setAvatarUrl(user.photoURL);
    }
  }, [user?.displayName, user?.photoURL]);

  const popoverId = openPopover ? "user-popover" : undefined;
  const [open, setOpen] = useState(false);
  const { listItemStyles, listItemIconStyles, listItemTextStyles } =
    getListStyles(theme, theme.palette.mode === "dark");

  const navigate = useNavigate();

  const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(false);
  const [contactDialogOpen, setContactDialogOpen] = useState(false);

  const [tocDialogOpen, setTocDialogOpen] = useState(false);
  const [whatsNewDialogOpen, setWhatsNewDialogOpen] = useState(false);

  const handleLogout2 = async () => {
    try {
      await signOut(auth);
      navigate("/app/logga-in");
    } catch (error) {
      console.error("Error logging out:", error);
      // Optionally, you can show an error message to the user here
    }
  };

  const openFeedbackDialog = () => {
    setFeedbackDialogOpen(true);
  };

  return (
    <>
      <Popover
        id={popoverId}
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        PaperProps={{
          sx: {
            borderRadius: "10px",
            backgroundImage: "none",
            backgroundColor: isDark ? "#192734" : "white",
            p: 1,
          },
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {user && (
          <Box>
            <Box sx={{ p: 2 }}>
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <Box
                  sx={{
                    background: "linear-gradient(45deg, #60A5FA, #7C3AED)",
                    borderRadius: "50%",
                    padding: "3px",
                    display: "inline-block",
                    //boxShadow: "0px 0px 8px rgba(96, 165, 250, 0.5)",
                  }}
                >
                  <Avatar
                    src={avatarUrl}
                    alt={user.displayName}
                    sx={{
                      width: "80px",
                      height: "80px",
                    }}
                  />
                </Box>
                <Box sx={{ ml: 2 }}>
                  <Typography variant="subtitle1" sx={{ fontSize: "1.2rem" }}>
                    <strong>{userDisplayName}</strong>
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ mb: 0.5 }}
                  >
                    {user.email}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    ID: {user.uid.substring(0, 8)}
                  </Typography>
                </Box>
              </Box>

              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: "bold",
                  marginBottom: "6px",
                  display: "none",
                }}
              >
                Användning
              </Typography>
              <Box
                sx={{
                  //display: "flex",
                  display: "none",
                  justifyContent: "space-between",
                  marginBottom: "16px",
                  alignItems: "center",
                  backgroundColor: isDark ? "#22303c" : "#eee9ff",
                  borderRadius: "15px",
                  padding: "15px",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "15px",
                    fontWeight: "500",
                    color: isDark ? "#f7f7f7" : "#8b5cf6",
                  }}
                >
                  Tokens:
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "15px",
                    fontWeight: "500",
                    color: isDark ? "#f7f7f7" : "#8b5cf6",
                  }}
                >
                  {user.tokens}/
                  {user.guest
                    ? process.env.REACT_APP_GUEST_TOKEN_LIMIT
                    : process.env.REACT_APP_REGISTERED_TOKEN_LIMIT}
                </Typography>
              </Box>
            </Box>

            <Divider />

            <List
              subheader={
                <ListSubheader
                  sx={{ bgcolor: "transparent", fontWeight: "bold" }}
                >
                  Elevrum
                </ListSubheader>
              }
            >
              <ListItem
                button
                sx={{ mt: -0.7, ...listItemStyles }}
                onClick={() => {
                  handlePopoverClose();
                  navigate("/app/rum");
                }}
              >
                <ListItemIcon sx={listItemIconStyles}>
                  <AddToClassIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Skapa elevrum & bjud in elever"
                  sx={listItemTextStyles}
                />
              </ListItem>
            </List>

            <Divider />

            <List>
              <ListItem
                sx={{
                  ...listItemStyles,
                }}
                button
                onClick={() => {
                  setOpen(true);
                }}
              >
                <ListItemIcon sx={listItemIconStyles}>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Inställningar" sx={listItemTextStyles} />
              </ListItem>

              <ListItem
                button
                sx={{
                  ...listItemStyles,
                }}
                onClick={() => setContactDialogOpen(true)}
              >
                <ListItemIcon sx={listItemIconStyles}>
                  <ContactSupportIcon />
                </ListItemIcon>
                <ListItemText primary="Kontakta oss" sx={listItemTextStyles} />
              </ListItem>

              <ListItem
                button
                sx={{
                  ...listItemStyles,
                }}
                onClick={() => setWhatsNewDialogOpen(true)}
              >
                <ListItemIcon sx={listItemIconStyles}>
                  <WhatsNewIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Vad som är nytt"
                  sx={listItemTextStyles}
                />
              </ListItem>

              <ListItem
                button
                sx={{
                  ...listItemStyles,
                }}
                onClick={() => setFeedbackDialogOpen(true)}
              >
                <ListItemIcon sx={listItemIconStyles}>
                  <SuggestIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Föreslå förbättringar"
                  sx={listItemTextStyles}
                />
              </ListItem>

              <ListItem
                button
                sx={{
                  ...listItemStyles,
                }}
                onClick={() => setTocDialogOpen(true)}
              >
                <ListItemIcon sx={listItemIconStyles}>
                  <PrivacyTipIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Integritetspolicy & GDPR"
                  sx={listItemTextStyles}
                />
              </ListItem>

              <ListItem
                button
                onClick={() => {
                  handleLogout2();

                  handlePopoverClose();
                }}
                sx={{
                  ...listItemStyles,
                }}
              >
                <ListItemIcon sx={listItemIconStyles}>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary="Logga ut" sx={listItemTextStyles} />
              </ListItem>
            </List>
          </Box>
        )}
      </Popover>

      <FeedbackDialog
        open={contactDialogOpen}
        onClose={() => setContactDialogOpen(false)}
        user={user}
        contactForm="true"
      />

      <FeedbackDialog
        open={feedbackDialogOpen}
        onClose={() => setFeedbackDialogOpen(false)}
        user={user}
      />

      <SettingsDialog
        open={open}
        onClose={() => setOpen(false)}
        onUserUpdate={updateUser}
        setOverrideTheme={setOverrideTheme}
      />

      <TocDialog open={tocDialogOpen} onClose={() => setTocDialogOpen(false)} />
      <WhatsNewDialog
        open={whatsNewDialogOpen}
        onClose={() => setWhatsNewDialogOpen(false)}
      />
    </>
  );
};

export default SettingsPopover;
