import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Divider,
  CircularProgress,
  Tooltip,
  FormControl,
  Select,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  Chip,
  TableCell,
  TableBody,
  Paper,
  MenuItem,
  Grid,
  TextField,
} from "@mui/material";

import GenerateIcon from "@mui/icons-material/AutoAwesome";
import ImageIcon from "@mui/icons-material/Image";
import PrintIcon from "@mui/icons-material/Print";

import LockIcon from "@mui/icons-material/Lock";
import SecurityIcon from "@mui/icons-material/Security";
import KeyIcon from "@mui/icons-material/Key";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { alpha } from "@mui/material/styles";
import InputAdornment from "@mui/material/InputAdornment";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import RestartAltIcon from "@mui/icons-material/RestartAlt";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import { callApi } from "./api";

import Logger from "./Logger";

import TextFieldsIcon from "@mui/icons-material/TextFields";

import LanguageSelector from "./LanguageSelector";
import ClearIcon from "@mui/icons-material/Clear";
import { ThemeSelectorFlow } from "./ThemeSelectorFlow";
import InfoBox from "./InfoBox";
import { handlePrintWordCompLesson } from "./printFunctions";
import {
  generateAndProcessImage,
  handleImageGenerationError,
} from "./functions";
import { useSnackbar } from "./SnackbarHandler";
import ProgressBar from "./ProgressBar";

import ImageGenerationModal from "./ImageGenerationModal";
import GradeSelector, { gradeInstructions } from "./GradeSelector";
import { useTheme } from "@mui/material/styles";
import HistoryIcon from "@mui/icons-material/History";
import { getStyles } from "../index";
import { trackLesson } from "./functions";

import LessonDrawer from "./LessonDrawer";
import SaveLessonDialog from "./SaveLessonDialog";
import HoverableImage from "./HoverableImage";
import { getThemes } from "./additionalThemes/WordCompLesson";
import { useSearchParams, useParams } from "react-router-dom";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import IconButton from "@mui/material/IconButton";

const Quest = ({ user }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);

  const lessonTitleRef = useRef(null); // Add this ref

  const [searchParams] = useSearchParams();

  const [lessonTheme, setLessonTheme] = useState("");
  const [language, setLanguage] = useState("svenska");

  const [grade, setGrade] = useState("åk6");
  const [progress, setProgress] = useState(0);
  const [isGeneratingLesson, setIsGeneratingLesson] = useState(false);
  const [isGeneratingImages, setIsGeneratingImages] = useState(false);

  const [customTheme, setCustomTheme] = useState("");
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageInstructions, setImageInstructions] = useState("");
  const [openAiInstructions, setOpenAiInstructions] = useState("");

  const [loadingImageIndex, setLoadingImageIndex] = useState(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const [predefinedTheme, setPredefinedTheme] = useState(null);
  const [lessonData, setLessonData] = useState(null);
  const subject = searchParams.get("subject") || null;

  const additionalThemes = getThemes(subject);

  const { showSnackbar } = useSnackbar();
  const theme = useTheme();
  const styles = getStyles(theme);

  const transcriptRef = useRef("");
  const uploadedFileRef = useRef("");
  const bookContentRef = useRef("");

  const wikipediaContentRef = useRef("");
  const randomizedContentRef = useRef("");
  const coursePlanContentRef = useRef("");

  const [currentChallenge, setCurrentChallenge] = useState(0);
  const [showHints, setShowHints] = useState(false);
  const [hintIndex, setHintIndex] = useState(0);
  const [showSolution, setShowSolution] = useState(false);
  const [userAnswers, setUserAnswers] = useState({});
  const [completedChallenges, setCompletedChallenges] = useState(new Set());
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);

  const [collectedCodes, setCollectedCodes] = useState([]);
  const [finalCode, setFinalCode] = useState("");

  const handleNextHint = () => {
    if (hintIndex < lessonData.challenges[currentChallenge].hints.length - 1) {
      setHintIndex((prev) => prev + 1);
    }
  };

  const handleResetHints = () => {
    setHintIndex(0);
    setShowHints(false);
  };

  const handleShowHints = () => {
    setShowHints(true);
  };

  const handleShowSolution = () => {
    setShowSolution(true);
  };

  const handleHideSolution = () => {
    setShowSolution(false);
  };

  const handleAnswerSubmit = () => {
    const currentAnswer = userAnswers[currentChallenge];
    const correctAnswer =
      lessonData.challenges[currentChallenge].solution.answer;

    if (currentAnswer === correctAnswer) {
      const newCode =
        lessonData.challenges[currentChallenge].solution.codeFragment;
      setCollectedCodes((prev) => [...prev, newCode]);
      setCompletedChallenges((prev) => new Set([...prev, currentChallenge]));

      if (currentChallenge === lessonData.challenges.length - 1) {
        setFinalCode(lessonData.finalCode);
        setShowSuccessDialog(true);
      } else {
        showSnackbar(
          `Rätt svar! Du hittade kodfragmentet: ${newCode}`,
          "success",
        );
        setCurrentChallenge((prev) => prev + 1);
        handleResetHints();
        handleHideSolution();
      }
    } else {
      showSnackbar("Försök igen!", "error");
    }
  };

  const handleAnswerChange = (event) => {
    setUserAnswers((prev) => ({
      ...prev,
      [currentChallenge]: event.target.value,
    }));
  };

  const handleRestart = () => {
    setCurrentChallenge(0);
    setShowHints(false);
    setHintIndex(0);
    setShowSolution(false);
    setUserAnswers({});
    setCompletedChallenges(new Set());
    setShowSuccessDialog(false);
    setCollectedCodes([]); // Reset collected codes
    setFinalCode(""); // Reset final code
  };

  useEffect(() => {
    Logger.log("subject: ", subject);
  }, [subject]);
  useEffect(() => {
    Logger.log("lessonTheme: ", lessonTheme);
  }, [lessonTheme]);

  useEffect(() => {
    Logger.log("predefinedTheme: ", predefinedTheme);
  }, [predefinedTheme]);

  useEffect(() => {
    Logger.log("customTheme: ", customTheme);
  }, [customTheme]);

  useEffect(() => {
    Logger.log("youtubeTranscript: ", transcriptRef.current);
  }, [transcriptRef.current]);

  useEffect(() => {
    Logger.log("wikipediaContentRef: ", wikipediaContentRef.current);
  }, [wikipediaContentRef.current]);
  useEffect(() => {
    Logger.log("uploadedFileRef: ", uploadedFileRef.current);
  }, [uploadedFileRef.current]);

  useEffect(() => {
    Logger.log("randomizedContentRef: ", randomizedContentRef.current);
  }, [randomizedContentRef.current]);

  useEffect(() => {
    Logger.log("coursePlanContentRef: ", coursePlanContentRef.current);
  }, [coursePlanContentRef.current]);

  const handleSaveLessonClick = () => {
    setSaveDialogOpen(true);
  };

  const handleSaveWithComment = async (comment, name, coverImage, tags) => {
    Logger.log(JSON.stringify(lessonData, null, 2));
    await trackLesson(
      user.uid,
      "EscapeRoomLesson.js",
      lessonData,
      null,
      true,
      comment,
      name,
      coverImage,
      tags,
    );
    setDrawerOpen(true);
    showSnackbar("Lektionen sparad i din lektionsbank!", "success");
  };

  const simulateProgress = (startProgress, endProgress, duration) => {
    const stepSize = 0.5;
    const steps = (endProgress - startProgress) / stepSize;
    const stepDuration = duration / steps;
    let currentProgress = startProgress;

    const intervalId = setInterval(() => {
      currentProgress += stepSize;
      if (currentProgress >= endProgress) {
        clearInterval(intervalId);
      } else {
        setProgress(currentProgress);
      }
    }, stepDuration);

    return () => clearInterval(intervalId);
  };

  const handleGenerateLesson = async () => {
    setProgress(0);
    let stopProgressSimulation = null;
    setIsGeneratingLesson(true);
    const gradeInstructionsText = gradeInstructions[grade];
    setLessonData(null);
    try {
      let themeToUse;

      if (lessonTheme === "youtube") {
        themeToUse = transcriptRef.current;
      } else if (lessonTheme === "customTheme") {
        themeToUse = customTheme;
      } else if (lessonTheme === "predefined") {
        themeToUse = predefinedTheme;
      } else if (lessonTheme === "fileUpload") {
        themeToUse = uploadedFileRef.current;
      } else if (lessonTheme === "wikipedia") {
        themeToUse = wikipediaContentRef.current;
      } else if (lessonTheme === "randomizer") {
        themeToUse = randomizedContentRef.current;
      } else if (lessonTheme === "book") {
        themeToUse = bookContentRef.current;
      } else {
        //
        themeToUse = coursePlanContentRef.current;
      }

      Logger.log("themeToUse: ", themeToUse);

      stopProgressSimulation = simulateProgress(0, 90, 15000);

      let response = await callApi(
        [
          {
            role: "system",
            content: `Du är en expertlärare som skapar engagerande escape room-lektioner på ${language}. Följ det exakta JSON-formatet som anges i prompten.`,
          },
          {
            role: "user",
            content: `
  Tema: "${themeToUse}".
  Instruktioner: Skapa en escape room-lektion där varje problem ger en del av en hemlig kod.
  För årskurs: "${gradeInstructionsText}"



För varje utmaning, inkludera en kort genomgång av det konceptet som behövs för att lösa problemet. Genomgången ska innehålla:
- En kort förklaring av konceptet
- 2-3 enkla exempel
- Praktiska tips för att använda konceptet
- Totalt 3 utmaningar
  

  SVARA I FÖLJANDE JSON-FORMAT:
  {
    "title": "Titel på escape room",
    "introduction": "Introduktion på 10-20 meningar till äventyret som nämner att en hemlig kod behöver samlas",
    "challenges": [
      {
        "id": 1,

   "conceptReview": {
      "title": "Key Concept",
      "explanation": "Brief explanation of the  concept",
      "examples": ["Exempel 1", "Exempel 2"],
      "tips": ["Helpful tip 1", "Helpful tip 2"]
    },

"story": "Describe the overall situation in exactly 10 sentences. Set the scene by explaining where this is happening, who is involved, and the general backdrop. Zoom into a critical moment or event in exactly 9 sentences. Highlight the tension or urgency of the situation, explaining why it needs solving and how it connects to the overall theme.",
        "problem": "Problemet som ska lösas.",
        "hints": [
          "Första ledtråden - generell vägledning",
          "Andra ledtråden - mer specifik hjälp",
          "Sista ledtråden - mycket specifik vägledning"
        ],
        "solution": {
          "answer": "Det slutgiltiga svaret",
          "steps": [
            "Steg 1 förklaring",
            "Steg 2 förklaring",
            "Steg 3 förklaring"
          ],
          "codeFragment": "X7" // Del av den slutgiltiga koden
        },
        "unlocks": "Beskrivning av kodfragmentet som hittades"
      }
    ],
    "finalCode": "X7Y9Z3", // Den kompletta koden när alla delar samlats
    "conclusion": "Avslutning när koden är komplett"
  }




`,
          },
        ],
        6000,
        true,
        user.uid,
      );

      if (stopProgressSimulation) stopProgressSimulation();
      setProgress(95);

      let messageContent = response.data.choices[0].message.content.trim();
      if (messageContent.startsWith("```json")) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith("```")) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      const data = JSON.parse(messageContent);
      setProgress(100);

      setLessonData({
        ...data,
        images: [{ imageUrl: null }, { imageUrl: null }],
      });

      await new Promise((resolve) => setTimeout(resolve, 800));

      await trackLesson(user.uid, "EscapeRoom.js", null, {
        language: language,
        lessonTheme: lessonTheme,
      });

      showSnackbar("Din lektion har genererats!", "success");
    } catch (error) {
      console.error("Error generating lesson:", error);
      showSnackbar(
        "Ett fel uppstod när lektionen skulle genereras. Försök igen!",
        "error",
      );
    } finally {
      setIsGeneratingLesson(false);
    }
  };

  /// Image Generation code

  const handleGenerateImages = async () => {
    if (!lessonData) return;
    setIsGeneratingImages(true);
    handleCloseModal();

    try {
      const imagePrompt = `Create an image with the theme: "${lessonData.title}". ${imageInstructions}`;
      const imagePromises = lessonData.images.map(() =>
        generateAndProcessImage(imagePrompt, user.uid),
      );
      const imageUrls = await Promise.all(imagePromises);

      setLessonData((prevData) => ({
        ...prevData,
        images: imageUrls.map((imageUrl) => ({ imageUrl })),
      }));

      showSnackbar("Bilderna har genererats!", "success");
    } catch (error) {
      handleImageGenerationError(error, true, showSnackbar);
    } finally {
      setIsGeneratingImages(false);
      setLoadingImageIndex(null);
    }
  };

  const handleGenerateSpecificImage = async (index) => {
    setIsGeneratingImages(true);
    setLoadingImageIndex(index);
    handleCloseModal();

    try {
      const imagePrompt = `Create an image with the theme: "${lessonData.title}". ${imageInstructions}`;
      const imageUrl = await generateAndProcessImage(imagePrompt, user.uid);

      if (imageUrl) {
        setLessonData((prevData) => {
          const newImages = [...prevData.images];
          newImages[index] = { imageUrl };
          return {
            ...prevData,
            images: newImages,
          };
        });
        showSnackbar("Bilden har genererats!", "success");
      }
    } catch (error) {
      handleImageGenerationError(error, false, showSnackbar);
    } finally {
      setIsGeneratingImages(false);
      setLoadingImageIndex(null);
    }
  };

  const handleImageButtonClick = (index = null) => {
    setSelectedImageIndex(index);
    setShowImageModal(true);
  };
  const handleCloseModal = () => {
    setShowImageModal(false);
    setImageInstructions("");
  };

  const handleGenerateWithInstructions = async () => {
    if (selectedImageIndex !== null) {
      handleCloseModal();
      await handleGenerateSpecificImage(selectedImageIndex);
    } else {
      handleCloseModal();
      await handleGenerateImages();
    }
  };

  return (
    <Box sx={styles.container}>
      <Box
        sx={{
          ...styles.innerContainer,
          width: { xs: "100%", md: "87%" },
          margin: "0 auto",
        }}
      >
        <Typography
          variant="h1"
          gutterBottom
          sx={{
            fontWeight: "bold",
            fontSize: "1.8em",
            mb: 3,
            mt: 5,
            textAlign: "center",
          }}
        >
          X
        </Typography>
        <Box
          sx={{
            ...styles.buttonsContainer,
            ...styles.additionalButtonsContainer,
          }}
        >
          <Tooltip title="Dina sparade lektioner">
            <Button
              variant="contained"
              color="primary"
              disableRipple
              onClick={() => setDrawerOpen(true)}
              startIcon={<HistoryIcon />}
              sx={{
                padding: "8px",
                marginRight: "0px",
                paddingLeft: "14px",
                paddingRight: "3px",
                minWidth: "auto",
                textTransform: "none",
              }}
            />
          </Tooltip>
        </Box>
        <LessonDrawer
          userId={user.uid}
          lessonType={"EscapeRoomLesson.js"}
          theme={theme}
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          lessonIcon={TextFieldsIcon}
          onSelectLesson={(lesson) => {
            setLessonData(lesson.data);
            setDrawerOpen(false);
            setTimeout(() => {
              lessonTitleRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "start", // This ensures it scrolls to the top of the element
              });
            }, 100);
            showSnackbar(`Lektionen har laddats in.`, "success");
          }}
        />
        <InfoBox
          icon={TextFieldsIcon}
          title="X"
          instructions={["X", "X", "X"]}
          theme={theme}
          styles={styles}
        />

        <ThemeSelectorFlow
          subject={subject}
          value={lessonTheme}
          onChange={(e) => setLessonTheme(e.target.value)}
          customTheme={customTheme}
          onCustomThemeChange={(e) => setCustomTheme(e.target.value)}
          onPredefinedThemeChange={(e) => setPredefinedTheme(e.target.value)}
          onBookContentChange={(content) => {
            Logger.log("onBookContentChange", content.substring(0, 100));
            bookContentRef.current = content;
          }}
          onYoutubeTranscriptChange={(transcriptContent) => {
            Logger.log(
              "onYoutubeTranscriptChange körs med: ",
              transcriptContent,
            );
            transcriptRef.current = transcriptContent;
          }}
          onFileUpload={(content) => {
            Logger.log("onFileUpload körs med: ", content);
            uploadedFileRef.current = content;
          }}
          onWikipediaContentChange={(content) => {
            Logger.log(
              "Parent: Wikipedia content received",
              content.substring(0, 100),
            );
            wikipediaContentRef.current = content;
          }}
          onRandomizedContentChange={(content) => {
            Logger.log("randomizedContentRef", content.substring(0, 100));
            randomizedContentRef.current = content;
          }}
          onCoursePlanContentChange={(content) => {
            Logger.log("onCoursePlanChange", content.substring(0, 100));
            coursePlanContentRef.current = content;
          }}
          lessonType={"EscapeRoomLesson.js"}
          user={user}
          theme={theme}
          styles={styles}
          onLessonSelect={(lesson) => {
            Logger.log("onLessonSelect körs");
            setLessonData(lesson.data);
            setTimeout(() => {
              lessonTitleRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "start", // This ensures it scrolls to the top of the element
              });
            }, 100);
            showSnackbar("Lektionen har laddats in.", "success");
          }}
          additionalThemes={additionalThemes}
        />

        <Box sx={styles.headlineLessonBox}>
          <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
            2. Generera lektionen
          </Typography>
        </Box>
        <Box sx={{ ...styles.freshBox, padding: "30px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" sx={{ fontWeight: "500", mb: 1 }}>
                Årskurs:
              </Typography>
              <Box sx={{ mt: 0.5, mb: 3 }}>
                <GradeSelector grade={grade} setGrade={setGrade} />
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" sx={{ fontWeight: "500" }}>
                Språk:
              </Typography>
              <Box sx={{ mt: 1, mb: 3 }}>
                <LanguageSelector
                  language={language}
                  setLanguage={setLanguage}
                />
              </Box>
            </Grid>
          </Grid>
          <Button
            variant={theme.palette.mode === "light" ? "purp" : "contained"}
            color="primary"
            onClick={handleGenerateLesson}
            disabled={isGeneratingLesson}
            sx={{ mt: 4 }}
            startIcon={
              !isGeneratingLesson ? (
                <GenerateIcon style={{ color: "#fff" }} />
              ) : (
                <CircularProgress style={{ color: "#fff" }} size={16} />
              )
            }
          >
            {isGeneratingLesson ? "Genererar..." : "Generera lektion"}
          </Button>
          {isGeneratingLesson && (
            <Box sx={{ mt: 6 }}>
              <ProgressBar
                progress={progress}
                label="Genererar lektion — tar 30-60 sekunder..."
              />
            </Box>
          )}
        </Box>
        {lessonData && (
          <>
            <Box
              ref={lessonTitleRef}
              sx={{ ...styles.headlineLessonBox, mt: 10 }}
            >
              <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
                3. Din lektion
              </Typography>
            </Box>

            <Box
              sx={{ ...styles.freshBox, padding: "30px", position: "relative" }}
            >
              <Box sx={styles.lessonControlPanel}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    px: 1,
                  }}
                >
                  <Box>
                    <Button
                      variant={
                        theme.palette.mode === "light" ? "purp" : "contained"
                      }
                      color="primary"
                      onClick={() => handleImageButtonClick(null)}
                      disabled={isGeneratingImages}
                      startIcon={
                        !isGeneratingImages ? (
                          <ImageIcon style={{ color: "#fff" }} />
                        ) : (
                          <CircularProgress size={16} />
                        )
                      }
                    >
                      {isGeneratingImages ? "Genererar..." : "Generera bilder"}
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      variant="print"
                      startIcon={<PrintIcon />}
                      sx={{ ml: 2 }}
                      onClick={() => handlePrintWordCompLesson(lessonData)}
                    >
                      Skriv ut
                    </Button>
                    <Button
                      variant="print"
                      startIcon={<SaveAsIcon />}
                      sx={{ ml: 2 }}
                      onClick={handleSaveLessonClick}
                    >
                      Spara i lektionsbank
                    </Button>
                    <Button
                      variant="print"
                      startIcon={<RestartAltIcon />}
                      onClick={handleRestart}
                      sx={{ ml: 2 }}
                    >
                      Börja om
                    </Button>
                  </Box>
                </Box>
              </Box>

              {/* Current Challenge */}
              <Box sx={{ mt: 4 }}>
                <Typography variant="h5" sx={{ mb: 3, textAlign: "center" }}>
                  <LockIcon sx={{ mr: 1, verticalAlign: "middle" }} />
                  Utmaning {currentChallenge + 1} av{" "}
                  {lessonData.challenges.length}
                </Typography>

                {currentChallenge === 0 && (
                  <Paper
                    elevation={3}
                    sx={{
                      p: 4,
                      mb: 4,
                      background: theme.palette.primary.main,
                      color: "white",
                    }}
                  >
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: "bold",
                        mb: 2,
                        display: "flex",
                        alignItems: "center",
                        gap: 2,
                      }}
                    >
                      <SecurityIcon fontSize="large" />
                      Välkommen till Escape Room
                    </Typography>
                    <Typography variant="h6">
                      {lessonData.introduction}
                    </Typography>
                  </Paper>
                )}

                {/* Code Collection Panel */}
                <Paper
                  elevation={3}
                  sx={{
                    p: 2,
                    mb: 4,
                    background: theme.palette.background.paper,
                    borderRadius: 4,
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontWeight: "bold",
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <KeyIcon />
                      Insamlade kodfragment:
                    </Typography>
                    <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
                      {collectedCodes.map((code, index) => (
                        <Chip
                          key={index}
                          label={code}
                          icon={<VpnKeyIcon />}
                          color="primary"
                          sx={{
                            fontWeight: "bold",
                            fontSize: "1.1rem",
                            height: 32,
                          }}
                        />
                      ))}
                    </Box>
                  </Box>
                </Paper>

                {/* Main Challenge Container */}

                {/* Challenge Story */}
                <Paper sx={{ p: 3, mb: 4, borderRadius: 4 }}>
                  <Typography
                    variant="h5"
                    sx={{
                      mb: 3,
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      fontSize: "1.2rem",
                      fontWeight: "600",
                    }}
                  >
                    <AssignmentIcon />
                    Ditt uppdrag
                  </Typography>
                  <Typography variant="body1">
                    {lessonData.challenges[currentChallenge].story}
                  </Typography>
                </Paper>

                {/* Problem Section */}
                <Paper
                  sx={{
                    p: 3,
                    mb: 4,
                    borderRadius: 4,
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{ mb: 2, fontSize: "1.2rem", fontWeight: "600" }}
                  >
                    Problem att lösa:
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: "1.1rem" }}>
                    {lessonData.challenges[currentChallenge].problem}
                  </Typography>
                </Paper>

                {/* Answer Section */}
                <Box sx={{ mb: 4, mt: 6 }}>
                  <TextField
                    fullWidth
                    label="Ditt svar"
                    variant="outlined"
                    value={userAnswers[currentChallenge] || ""}
                    onChange={handleAnswerChange}
                    InputProps={{
                      sx: {
                        "& .MuiInputBase-input": {
                          // This targets both input and textarea
                          fontSize: "1rem",
                          fontWeight: "700",
                          padding: "20px",
                        },
                      },

                      startAdornment: (
                        <InputAdornment position="start">
                          <VpnKeyIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>

                {/* Hints and Solution Buttons */}
                <Box
                  sx={{
                    display: "flex",
                    gap: 2,
                    mb: 4,
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant="print"
                    onClick={handleShowHints}
                    startIcon={<HelpOutlineIcon />}
                  >
                    Visa ledtråd
                  </Button>
                  <Button
                    variant="print"
                    onClick={handleShowSolution}
                    disabled={showSolution}
                    startIcon={<LightbulbIcon />}
                  >
                    Visa lösning
                  </Button>
                </Box>

                {/* Hints Display */}
                {showHints && (
                  <Paper
                    sx={{
                      p: 3,
                      mb: 4,
                      borderRadius: 4,
                      fontSize: "1.2rem",
                      fontWeight: "600",
                    }}
                  >
                    <Typography variant="h6" sx={{ mb: 2 }}>
                      Ledtråd {hintIndex + 1}:
                    </Typography>
                    <Typography variant="body1">
                      {lessonData.challenges[currentChallenge].hints[hintIndex]}
                    </Typography>
                    {hintIndex <
                      lessonData.challenges[currentChallenge].hints.length -
                        1 && (
                      <Button
                        variant="contained"
                        onClick={handleNextHint}
                        sx={{ mt: 2 }}
                      >
                        Nästa ledtråd
                      </Button>
                    )}
                  </Paper>
                )}

                {/* Solution Display */}
                {showSolution && (
                  <Paper
                    sx={{
                      p: 3,
                      mb: 4,
                      borderRadius: 4,
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{ mb: 2, fontSize: "1.2rem", fontWeight: "600" }}
                    >
                      Lösningssteg:
                    </Typography>
                    {lessonData.challenges[currentChallenge].solution.steps.map(
                      (step, index) => (
                        <Typography key={index} variant="body1" sx={{ mb: 2 }}>
                          {index + 1}. {step}
                        </Typography>
                      ),
                    )}
                    <Typography
                      variant="h6"
                      sx={{ mt: 3, fontSize: "1.2rem", fontWeight: "600" }}
                    >
                      Svar:{" "}
                      {lessonData.challenges[currentChallenge].solution.answer}
                    </Typography>
                  </Paper>
                )}

                {/* Submit Button */}
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    variant="purp"
                    onClick={handleAnswerSubmit}
                    size="large"
                    startIcon={<LockOpenIcon />}
                  >
                    Kontrollera svar
                  </Button>
                </Box>

                {/* Concept Review Section - Initially Hidden */}
                <Accordion
                  sx={{
                    mb: 4,
                    mt: 6,
                    background: alpha(theme.palette.background.paper, 0.9),
                    borderRadius: "10px",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{
                      background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.primary.light} 90%)`,
                      color: theme.palette.primary.contrastText,
                      borderRadius: "10px 10px 0 0",
                    }}
                  >
                    <Typography
                      sx={{ display: "flex", alignItems: "center", gap: 1 }}
                    >
                      <MenuBookIcon />
                      Kunskapsbank
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      variant="h5"
                      color="primary"
                      sx={{ mb: 2, fontWeight: "bold" }}
                    >
                      {
                        lessonData.challenges[currentChallenge].conceptReview
                          .title
                      }
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 3 }}>
                      {
                        lessonData.challenges[currentChallenge].conceptReview
                          .explanation
                      }
                    </Typography>
                    <Box sx={{ mb: 3 }}>
                      <Typography variant="h6" sx={{ mb: 2 }}>
                        Exempel:
                      </Typography>
                      {lessonData.challenges[
                        currentChallenge
                      ].conceptReview.examples.map((example, index) => (
                        <Paper key={index} sx={{ p: 2, mb: 2 }}>
                          <Typography variant="body1">{example}</Typography>
                        </Paper>
                      ))}
                    </Box>
                    <Box>
                      <Typography variant="h6" sx={{ mb: 2 }}>
                        Tips:
                      </Typography>
                      {lessonData.challenges[
                        currentChallenge
                      ].conceptReview.tips.map((tip, index) => (
                        <Paper key={index} sx={{ p: 2, mb: 2 }}>
                          <Typography variant="body1">{tip}</Typography>
                        </Paper>
                      ))}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Box>
              {/* Success Dialog */}
              <Dialog
                open={showSuccessDialog}
                onClose={() => setShowSuccessDialog(false)}
              >
                <DialogContent>
                  <Typography variant="h6" gutterBottom>
                    Grattis! Du har knäckt koden!
                  </Typography>
                  <Box
                    sx={{
                      my: 2,
                      p: 2,
                      bgcolor: "background.default",
                      borderRadius: 1,
                    }}
                  >
                    <Typography
                      variant="h4"
                      align="center"
                      sx={{ fontFamily: "monospace", letterSpacing: 2 }}
                    >
                      {finalCode}
                    </Typography>
                  </Box>
                  <Typography>{lessonData.conclusion}</Typography>
                  <Button
                    variant="print"
                    onClick={() => setShowSuccessDialog(false)}
                    sx={{ mt: 2 }}
                  >
                    Stäng
                  </Button>
                </DialogContent>
              </Dialog>

              <SaveLessonDialog
                open={saveDialogOpen}
                onClose={() => setSaveDialogOpen(false)}
                onSave={handleSaveWithComment}
                theme={theme}
                lessonName={lessonData?.title || ""}
                lessonObject={lessonData}
                lessonType={"EscapeRoomLesson.js"}
              />

              <ImageGenerationModal
                open={showImageModal}
                onClose={handleCloseModal}
                onGenerate={handleGenerateWithInstructions}
                isGenerating={isGeneratingImages}
                instructions={imageInstructions}
                setInstructions={setImageInstructions}
                theme={theme}
              />
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default Quest;
