import React, { useState, useEffect, useRef } from "react";
import { useParams, Link } from "react-router-dom";
import {
  doc,
  getDoc,
  updateDoc,
  collection,
  query,
  where,
  getDocs,
  orderBy,
} from "firebase/firestore";
import { db } from "./firebase";
import PrintIcon from "@mui/icons-material/Print";
import {
  Box,
  Typography,
  CircularProgress,
  Button,
  TextField,
  Tab,
  Tabs,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import { marked } from "marked";
import { getStyles } from "../index";
import ExportButton from "./ExportButton";
import { useSnackbar } from "./SnackbarHandler";

import { useTheme } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SaveIcon from "@mui/icons-material/Save";
import Quill from "./Quill";

import SchoolIcon from "@mui/icons-material/School";
import SegmentIcon from "@mui/icons-material/Segment";
import LanguageIcon from "@mui/icons-material/Language";
import GenerateIcon from "@mui/icons-material/AutoAwesome";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import EssayListDrawer from "./EssayListDrawer";

const EssayResult = ({ user }) => {
  const { docId } = useParams();
  const [essayData, setEssayData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(false);
  const [editedResults, setEditedResults] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [reviewed, setReviewed] = useState(false);
  const { showSnackbar } = useSnackbar();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const printContentRef = useRef(null);
  const theme = useTheme();
  const styles = getStyles(theme);
  const [showOriginalText, setShowOriginalText] = useState(false);

  const [quillContent, setQuillContent] = useState("");

  // 3. Add this useEffect after your other useEffects:
  useEffect(() => {
    if (essayData?.results) {
      setQuillContent(essayData.results);
    }
  }, [essayData?.results]);

  useEffect(() => {
    const fetchEssayData = async () => {
      try {
        const docRef = doc(db, "essays", docId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setEssayData(data);
          setEditedResults(data.results);
          setReviewed(data.reviewed || false);
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching essay data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchEssayData();
  }, [docId]);

  const [historicEssays, setHistoricEssays] = useState([]);

  useEffect(() => {
    const fetchEssayData = async () => {
      try {
        const docRef = doc(db, "essays", docId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setEssayData(data);
          setEditedResults(data.results);
          setReviewed(data.reviewed || false);
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching essay data:", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchHistoricEssays = async () => {
      try {
        const q = query(
          collection(db, "essays"),
          where("userId", "==", user.uid),
          orderBy("createdAt", "desc"), // Add this line to sort by createdAt in descending order
        );
        const querySnapshot = await getDocs(q);
        const essays = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setHistoricEssays(essays);
      } catch (error) {
        console.error("Error fetching historic essays:", error);
      }
    };

    fetchEssayData();
    fetchHistoricEssays();
  }, [docId, user.uid]);

  const handleEdit = () => {
    setEditing(true);
  };
  const handlePrint = () => {
    const printWindow = window.open("", "_blank");
    // Create a sanitized version of the Quill content
    const contentToPrint = editedResults || essayData.results || "";

    printWindow.document.write(`
      <html>
        <head>
          <title>Bedömning av text från Studera.ai</title>
          <style>

  @page {
      size: A4;
      margin: 0cm 0cm 2cm 0cm;
      marks: none;
    }

          html {
      padding: 0cm 2cm 2cm 2cm !important;
      margin: 0 !important;
      margin-top: 2cm !important;
    }

    body { 
      margin: 0 !important;
      -webkit-print-color-adjust: exact !important;
      print-color-adjust: exact !important;
    }
      
            body { font-family: Arial, sans-serif; line-height: 1.6; color: #333; padding: 20px; }
            h1 { color: #2c3e50; }
            .ql-editor { padding: 0; }
            .ql-editor * { font-family: Arial, sans-serif; }
          </style>
        </head>
        <body>
          <h1>Bedömningsresultat</h1>
          <div class="ql-editor">
            ${contentToPrint}
          </div>
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };

  const handleSave = async () => {
    try {
      const docRef = doc(db, "essays", docId);
      await updateDoc(docRef, { results: editedResults });
      setEssayData({ ...essayData, results: editedResults });
      setEditing(false);
      showSnackbar("Ändringar sparade.", "success");
    } catch (error) {
      console.error("Error updating essay data:", error);
    }
  };

  const handleCancel = () => {
    setEditedResults(essayData.results);
    setEditing(false);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleDelete = (deletedId) => {
    setHistoricEssays(historicEssays.filter((essay) => essay.id !== deletedId));
  };

  const handleCopy = async () => {
    const plainText = essayData.results;
    // Convert to HTML if needed, assuming gradingResults contains markdown
    const htmlContent = marked.parse(plainText);

    try {
      // Try to copy with rich text formatting first
      await navigator.clipboard.write([
        new ClipboardItem({
          "text/plain": new Blob([plainText], { type: "text/plain" }),
          "text/html": new Blob([htmlContent], { type: "text/html" }),
        }),
      ]);
      showSnackbar("Kopierad till urklipp!", "success");
    } catch (err) {
      console.error("Fel vid formaterad kopiering: ", err);

      // Fallback to plain text if rich copy fails
      try {
        await navigator.clipboard.writeText(plainText);
        showSnackbar("Kopierad som vanlig text!", "success");
      } catch (fallbackErr) {
        console.error("Fel vid vanlig kopiering: ", fallbackErr);
        showSnackbar("Kunde inte kopiera texten", "error");
      }
    }
  };

  const handleReviewToggle = async () => {
    try {
      const docRef = doc(db, "essays", docId);
      const newReviewedState = !reviewed;
      await updateDoc(docRef, { reviewed: newReviewedState });
      setReviewed(newReviewedState);

      if (newReviewedState) {
        showSnackbar("Omdöme markerat som granskat.", "success");
      } else {
        showSnackbar("Omdöme markerat som icke granskat.", "info");
      }
    } catch (error) {
      console.error("Error updating review status:", error);
      showSnackbar(
        "Ett fel uppstod vid uppdatering av granskningsstatus.",
        "error",
      );
    }
  };

  const toggleOriginalText = () => {
    setShowOriginalText(!showOriginalText);
  };
  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!essayData) {
    return <Typography>No essay data found.</Typography>;
  }

  return (
    <Box sx={{ ...styles.container }}>
      <Box sx={styles.innerContainer}>
        <Box
          sx={{
            ...styles.buttonsContainer,
            ...styles.additionalButtonsContainer,
          }}
        >
          <Button
            variant={"contained"}
            color="primary"
            component={Link}
            startIcon={<GenerateIcon />}
            to="/app/uppsats/ny"
          >
            Ny text
          </Button>

          <Button
            variant={"contained"}
            color="primary"
            component={Link}
            startIcon={<AddIcon />}
            to="/app/matris/ny"
          >
            Ny matris
          </Button>

          <Button
            variant="contained"
            color="primary"
            disableRipple
            onClick={() => setDrawerOpen(true)}
            startIcon={<SegmentIcon />}
            sx={{}}
          >
            Bedömningar
          </Button>
        </Box>

        <Box sx={{ mt: 2, display: "none" }}>
          <Typography sx={{ ...styles.grayText }}>
            <Link
              to="/app/uppsatser"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              Bedömningar
            </Link>{" "}
            &nbsp; / &nbsp; {essayData.fileName}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            gap: "16px",
            marginBottom: "16px",
            display: "none",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
              ...styles.grayText,
            }}
          >
            <SchoolIcon sx={{ fontSize: "18px", mr: 0.3 }} />
            <Typography
              variant="body2"
              sx={{ ...styles.grayText, fontWeight: "500" }}
            >
              {essayData.educationLevel}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
              ...styles.grayText,
            }}
          >
            <HistoryEduIcon sx={{ fontSize: "18px", mr: 0.3 }} />
            <Typography
              variant="body2"
              sx={{ ...styles.grayText, fontWeight: "500" }}
            >
              {essayData.rubricName}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
              ...styles.grayText,
            }}
          >
            <LanguageIcon sx={{ fontSize: "18px", mr: 0.3 }} />
            <Typography
              variant="body2"
              sx={{ ...styles.grayText, fontWeight: "500" }}
            >
              {essayData.language}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
              ...styles.grayText,
            }}
          >
            <CalendarTodayIcon sx={{ fontSize: "18px", mr: 0.3 }} />
            <Typography
              variant="body2"
              sx={{ ...styles.grayText, fontWeight: "500" }}
            >
              {essayData.createdAt.toDate().toLocaleString()}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ ...styles.headlineLessonBox, mt: 11 }}>
          <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
            Preliminär bedömning av: {essayData.fileName}
          </Typography>
        </Box>
        <Box sx={{ ...styles.freshBox, padding: "30px", marginTop: "20px" }}>
          <Box sx={styles.lessonControlPanel}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                px: 1,
              }}
            >
              <Box>
                <Button
                  variant="print"
                  startIcon={<ContentCopyIcon />}
                  onClick={handleCopy}
                >
                  Kopiera
                </Button>
                <Button
                  variant="print"
                  color="primary"
                  onClick={handlePrint}
                  startIcon={<PrintIcon />}
                  sx={{ ml: 2 }}
                >
                  Skriv ut
                </Button>
                <Box sx={{ display: "inline-block", ml: 2 }}>
                  <ExportButton
                    content={editedResults}
                    styles={styles}
                    bigButton={true}
                  />
                </Box>
              </Box>
              <Box>
                <Button
                  variant={reviewed ? "success" : "print"}
                  startIcon={
                    <CheckCircleIcon
                      sx={{
                        color: reviewed ? "white" : "text.secondary",
                      }}
                    />
                  }
                  onClick={handleReviewToggle}
                  sx={{ ml: 2 }}
                >
                  {reviewed ? "Granskad" : "Markera som granskad"}
                </Button>
              </Box>
            </Box>
          </Box>

          <Box sx={{ marginTop: "20px" }}>
            <Quill
              value={editedResults}
              onChange={(newContent) => {
                setEditedResults(newContent);
              }}
              theme={theme}
            />
          </Box>
          <Box sx={{ marginTop: "20px" }}>
            <Button
              onClick={toggleOriginalText}
              variant="print"
              startIcon={
                showOriginalText ? <ExpandLessIcon /> : <ExpandMoreIcon />
              }
            >
              {showOriginalText ? "Dölj originaltext" : "Visa originaltext"}
            </Button>
            {showOriginalText && (
              <Typography
                variant="body1"
                sx={{ whiteSpace: "pre-wrap", marginTop: "10px" }}
              >
                {essayData.content}
              </Typography>
            )}
          </Box>
        </Box>
        <EssayListDrawer
          open={drawerOpen}
          historicEssays={historicEssays}
          theme={theme}
          onClose={() => setDrawerOpen(false)}
          onDelete={handleDelete}
        />
      </Box>
    </Box>
  );
};

export default EssayResult;
