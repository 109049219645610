import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Divider,
  Typography,
  CircularProgress,
  InputAdornment,
} from "@mui/material";
import { CheckCircleOutline } from "@mui/icons-material";
import axios from "axios";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import { Link as LinkIcon } from "@mui/icons-material";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import { useSnackbar } from "./SnackbarHandler";
const cheerio = require("cheerio");
import WikiArticleDialog from "./WikiArticleDialog";
import SORummet from "./SORummet";

import ArrowBackIosIcon from "@mui/icons-material/Replay";

const API_URL =
  process.env.NODE_ENV === "production" ? "/api" : "http://localhost:3001/api";

// Rate limiting constants
const RATE_LIMIT = 5; // Change from 3 to 5 requests
const TIME_WINDOW = 86400000; // Change from 3600000 (1 hour) to 86400000 (24 hours)
const STORAGE_KEY = "url_fetcher_requests";

const UrlFetcher = ({
  onUrlContentChange,
  theme,
  styles,
  forQuiz,
  forFlashcards,
  forPowerPoint,
  forTextlabbet,
  user,
}) => {
  const [url, setUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [remainingRequests, setRemainingRequests] = useState(RATE_LIMIT);
  const { showSnackbar } = useSnackbar();

  const adminIds = [
    "Z1dF5PGfjvdBGS9egzACvcSRZiN2",
    "x2rjH7JxWxc6GnFgPSTxGxN1bm63",
    "Z6BLSMsm38Un5mgW0O6Z3TToHq12",
  ];

  const handleArticleSelect = async (selectedUrl) => {
    setUrl(selectedUrl);
    try {
      setIsLoading(true);

      const content = await extractUrlContent(selectedUrl);
      if (content) {
        if (!addRequest()) {
          showSnackbar(
            `Daglig gräns uppnådd. Du kan hämta innehåll igen om ${getTimeUntilReset()} timmar.`,
            "error",
          );
          return;
        }
        onUrlContentChange(content);
        setShowSuccess(true);
      } else {
        showSnackbar("Kunde inte hämta innehåll från URL:en.", "error");
      }
    } catch (error) {
      if (error.message === "invalid_url") {
        showSnackbar(
          "Ogiltig URL. Vänligen ange en giltig webbadress.",
          "error",
        );
      } else if (error.message === "blocked") {
        showSnackbar(
          "Denna webbplats blockerar externa förfrågningar.",
          "error",
        );
      } else if (error.message === "not_found") {
        showSnackbar("Sidan kunde inte hittas (404).", "error");
      } else {
        showSnackbar("Ett fel uppstod när innehållet skulle hämtas.", "error");
      }
      setShowSuccess(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    updateRemainingRequests();
  }, []);

  const updateRemainingRequests = () => {
    const stored = localStorage.getItem(STORAGE_KEY);
    if (!stored) {
      setRemainingRequests(RATE_LIMIT);
      return;
    }

    const requests = JSON.parse(stored);
    const validRequests = requests.filter(
      (timestamp) => Date.now() - timestamp < TIME_WINDOW,
    );

    localStorage.setItem(STORAGE_KEY, JSON.stringify(validRequests));
    setRemainingRequests(RATE_LIMIT - validRequests.length);
  };

  const addRequest = () => {
    if (adminIds.includes(user.uid)) {
      return true; // Admins bypass rate limiting
    }

    const stored = localStorage.getItem(STORAGE_KEY);
    const requests = stored ? JSON.parse(stored) : [];

    // First check if we're already at the limit
    const validRequests = requests.filter(
      (timestamp) => Date.now() - timestamp < TIME_WINDOW,
    );

    if (validRequests.length >= RATE_LIMIT) {
      return false; // Don't add the request if we're at the limit
    }

    // If we're not at the limit, add the new request
    validRequests.push(Date.now());
    localStorage.setItem(STORAGE_KEY, JSON.stringify(validRequests));
    setRemainingRequests(RATE_LIMIT - validRequests.length);
    return true;
  };

  const isRateLimited = () => {
    if (adminIds.includes(user.uid)) {
      return false; // Admins are never rate limited
    }

    const stored = localStorage.getItem(STORAGE_KEY);
    if (!stored) return false;

    const requests = JSON.parse(stored);
    const validRequests = requests.filter(
      (timestamp) => Date.now() - timestamp < TIME_WINDOW,
    );

    return validRequests.length >= RATE_LIMIT;
  };

  const getTimeUntilReset = () => {
    const stored = localStorage.getItem(STORAGE_KEY);
    if (!stored) return 0;

    const requests = JSON.parse(stored);
    if (requests.length === 0) return 0;

    const oldestRequest = Math.min(...requests);
    const resetTime = oldestRequest + TIME_WINDOW - Date.now();
    return Math.max(0, Math.ceil(resetTime / (60 * 60000))); // Convert to hours
  };

  const extractUrlContent = async (url, maxLength = 10000) => {
    await new Promise((resolve) => setTimeout(resolve, 2000));

    try {
      const response = await axios.get(
        `${API_URL}/proxy?url=${encodeURIComponent(url)}`,
      );
      const $ = cheerio.load(response.data.content);
      let content = "";

      // Remove unwanted elements
      $("script").remove();
      $("style").remove();
      $("iframe").remove();
      $("noscript").remove();
      $(".advertisement").remove();

      // Special handling for so-rummet.se
      if (url.startsWith("https://www.so-rummet.se/")) {
        // Get the title
        const title = $("h1").first().text().trim();
        if (title) {
          content += title + "\n\n";
        }

        // Get the ingress
        const ingress = $(".ingres_nu_da").text().trim();
        if (ingress) {
          content += ingress + "\n\n";
        }

        // Get the main article text
        const mainArticle = $(".so-article-text");
        if (mainArticle.length) {
          mainArticle.find("*").each((i, element) => {
            if (element.name === "p") {
              content += $(element).text().trim() + "\n\n";
            } else if (element.name === "ul" || element.name === "ol") {
              $(element)
                .find("li")
                .each((j, li) => {
                  content += "• " + $(li).text().trim() + "\n";
                });
              content += "\n";
            } else if (element.name.match(/^h[1-6]$/)) {
              content += $(element).text().trim() + "\n\n";
            } else if (element.name === "table") {
              $(element)
                .find("tr")
                .each((j, tr) => {
                  $(tr)
                    .find("th, td")
                    .each((k, cell) => {
                      content += $(cell).text().trim() + "\t";
                    });
                  content += "\n";
                });
              content += "\n";
            }
          });
        }

        // Get the hidden "läs mer" content
        const hiddenContent = $(".hide-article");
        if (hiddenContent.length) {
          hiddenContent.find("*").each((i, element) => {
            if (element.name === "p") {
              content += $(element).text().trim() + "\n\n";
            } else if (element.name === "ul" || element.name === "ol") {
              $(element)
                .find("li")
                .each((j, li) => {
                  content += "• " + $(li).text().trim() + "\n";
                });
              content += "\n";
            } else if (element.name.match(/^h[1-6]$/)) {
              content += $(element).text().trim() + "\n\n";
            } else if (element.name === "table") {
              $(element)
                .find("tr")
                .each((j, tr) => {
                  $(tr)
                    .find("th, td")
                    .each((k, cell) => {
                      content += $(cell).text().trim() + "\t";
                    });
                  content += "\n";
                });
              content += "\n";
            }
          });
        }
      } else {
        // Default handling for other websites
        $("p, ul, ol, h1, h2, h3, h4, h5, h6").each((i, element) => {
          if (content.length < maxLength) {
            if (element.name === "p" || element.name.match(/^h[1-6]$/)) {
              content += $(element).text().trim() + "\n\n";
            } else if (element.name === "ul" || element.name === "ol") {
              $(element)
                .find("li")
                .each((j, li) => {
                  content += "• " + $(li).text().trim() + "\n";
                });
              content += "\n";
            }
          } else {
            return false;
          }
        });

        // Extract tables
        $("table").each((i, table) => {
          if (content.length < maxLength) {
            let tableContent = "";

            // Extract headers
            $(table)
              .find("th")
              .each((j, th) => {
                tableContent += $(th).text().trim() + "\t";
              });
            tableContent += "\n";

            // Extract rows
            $(table)
              .find("tr")
              .each((j, tr) => {
                $(tr)
                  .find("td")
                  .each((k, td) => {
                    tableContent += $(td).text().trim() + "\t";
                  });
                tableContent += "\n";
              });

            content += tableContent + "\n";
          }
        });
      }

      // Clean up the content
      content = content
        .replace(/\n{3,}/g, "\n\n") // Replace multiple newlines with double newlines
        .replace(/\s+/g, " ") // Replace multiple spaces with single space
        .trim(); // Remove leading/trailing whitespace

      return content.substring(0, maxLength);
    } catch (error) {
      console.error("Error fetching URL content:", error);
      if (error.response) {
        const status = error.response.data?.status;
        if (status === 403) {
          throw new Error("blocked");
        } else if (status === 404) {
          throw new Error("not_found");
        }
      }
      throw error;
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isRateLimited()) {
      const hours = getTimeUntilReset();
      showSnackbar(
        `Daglig gräns uppnådd. Du kan hämta innehåll igen om ${hours} timmar.`,
        "error",
      );
      return;
    }

    setIsLoading(true);

    try {
      const urlPattern = /^https?:\/\/.+\..+/i;
      if (!urlPattern.test(url)) {
        throw new Error("invalid_url");
      }

      const content = await extractUrlContent(url);

      if (content) {
        if (!addRequest()) {
          showSnackbar(
            `Daglig gräns uppnådd. Du kan hämta innehåll igen om ${getTimeUntilReset()} timmar.`,
            "error",
          );
          return;
        }
        onUrlContentChange(content);
        setShowSuccess(true);
        showSnackbar("Innehållet har hämtats framgångsrikt!", "success");
      } else {
        showSnackbar("Kunde inte hämta innehåll från URL:en.", "error");
      }
    } catch (error) {
      if (error.message === "invalid_url") {
        showSnackbar(
          "Ogiltig URL. Vänligen ange en giltig webbadress.",
          "error",
        );
      } else if (error.message === "blocked") {
        showSnackbar(
          "Webbadressen du skrev in blockerar externa förfrågningar. Försök med en annan.",
          "error",
        );
      } else if (error.message === "not_found") {
        showSnackbar("Sidan kunde inte hittas (404).", "error");
      } else {
        showSnackbar("Ett fel uppstod när innehållet skulle hämtas.", "error");
      }
      setShowSuccess(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleReset = () => {
    setUrl("");
    setShowSuccess(false);
  };

  return (
    <Box sx={{ width: "100%", p: 3 }}>
      {!showSuccess ? (
        <Box component="form" onSubmit={handleSubmit}>
          <Box sx={{ textAlign: "center", mb: 4 }}>
            <InsertLinkIcon
              sx={{ fontSize: "5rem", color: "text.secondary" }}
            />
          </Box>
          <Box sx={{ textAlign: "center" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                width: { xs: "100%", md: "70%" },
                margin: "0 auto",
                mb: 3,
              }}
            >
              <TextField
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                placeholder="https://www.so-rummet.se/kategorier/samhallskunskap/demokrati/demokratins-grunder"
                disabled={isLoading}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LinkIcon />
                    </InputAdornment>
                  ),
                }}
              />

              <Button
                variant="purp"
                type="submit"
                startIcon={
                  isLoading ? (
                    <CircularProgress sx={{ color: "white" }} size={16} />
                  ) : (
                    <DownloadForOfflineIcon />
                  )
                }
                sx={{ width: "300px" }}
                disabled={isLoading}
              >
                {isLoading ? "Hämtar innehåll..." : "Hämta innehåll"}
              </Button>
            </Box>
          </Box>

          <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            sx={{ mb: 2, display: "none" }}
          >
            Remaining requests: {remainingRequests} of {RATE_LIMIT}
          </Typography>

          <Box sx={{ textAlign: "center", display: "none" }}>
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: "500", mb: 1, mt: 8, fontSize: "1.1rem" }}
            >
              Eller välj en artikel från betrodda källor:
            </Typography>
          </Box>
          <Divider sx={{ mt: 6 }} />
          <Box
            sx={{ display: "flex", justifyContent: "center", gap: 2, mt: 6 }}
          >
            <>
              <SORummet
                onLinkSelect={handleArticleSelect}
                theme={theme}
                styles={styles}
              />
              <WikiArticleDialog
                onArticleSelect={handleArticleSelect}
                theme={theme}
              />
            </>
          </Box>
        </Box>
      ) : (
        <Box sx={{ ...styles.successBox }}>
          <CheckCircleOutline
            sx={{ fontSize: 60, color: "success.main", mb: 3 }}
          />
          <Typography
            variant="h6"
            sx={{
              color: "success.main",
              fontWeight: 500,
              textAlign: "center",
              width: "80%",
              margin: "0 auto",
              mb: 3,
            }}
          >
            Innehållet är nu hämtat och du kan fortsätta med att{" "}
            {forTextlabbet ? " bearbeta" : "generera"}{" "}
            {forQuiz
              ? "ditt quiz"
              : forFlashcards
                ? "dina flashcards"
                : forPowerPoint
                  ? "din PowerPoint"
                  : forTextlabbet
                    ? "din text"
                    : "din lektion"}{" "}
            nedan.
          </Typography>
          <Button
            variant="purp"
            onClick={handleReset}
            startIcon={<ArrowBackIosIcon />}
          >
            Välj annan URL
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default UrlFetcher;
