import React from "react";
import { Box, Typography } from "@mui/material";
import SchoolIcon from "@mui/icons-material/School";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CommentIcon from "@mui/icons-material/Comment";
import { lessonMapping } from "./functions";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { IconButton } from "@mui/material";

const LessonCard = ({
  name,
  comment,
  timestamp,
  lessonType,
  imageUrl,
  onClick,
  size = "large",
  theme,
  centered = false,
  width, // Add this
  height, // Add this
  selected,
  openInNewTab,
}) => {
  const defaultGradient = `linear-gradient(135deg, #667eea 0%, #764ba2 100%)`;

  const formatDate = (date) => {
    if (!date) return "";
    return date instanceof Date
      ? date.toLocaleDateString("sv-SE", {
          year: "numeric",
          month: "long",
          day: "numeric",
        })
      : date.toLocaleDateString();
  };

  const cardStyles = {
    large: {
      width: width ? `${width}px` : { xs: "100%", md: "360px" },
      height: height || 300,
      titleSize: "1.7rem",
      infoSize: "0.9rem",
    },
    small: {
      width: width ? `${width}px` : "100%",
      height: height || 190,
      titleSize: "1.2rem",
      infoSize: "0.8rem",
    },
  };

  const styles = cardStyles[size];

  return (
    <Box
      onClick={onClick}
      sx={{
        width: styles.width,
        height: styles.height,
        borderRadius: 2.5,
        overflow: "hidden",
        position: "relative",
        //Moonshine, changed the last pointer, problably should be default  when saving in savedialog fore xample
        cursor: onClick ? "pointer" : "pointer",
        boxShadow:
          size === "large"
            ? "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px"
            : "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
        transition: "transform 0.2s ease-in-out",
        "&:hover": onClick
          ? {
              transform: "scale(1.02)",
            }
          : {},
      }}
    >
      {imageUrl ? (
        <Box
          component="img"
          src={imageUrl}
          sx={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            background: defaultGradient,
          }}
        />
      )}

      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: imageUrl
            ? `linear-gradient(rgba(0,0,0,0.45), rgba(0,0,0,0.45)),
     linear-gradient(to top, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.2) 100%)`
            : "none",
          p: size === "large" ? 2 : 1.8,
          display: "flex",
          flexDirection: "column",
          justifyContent: centered ? "center" : "space-between",
          alignItems: centered ? "center" : "stretch",
        }}
      >
        {openInNewTab && (
          <IconButton
            sx={{
              position: "absolute",
              top: 4,
              right: 4,
              color: "white",
              backgroundColor: "rgba(0, 0, 0, 0.4)",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.6)",
              },
            }}
          >
            <OpenInNewIcon fontSize="small" />
          </IconButton>
        )}

        <Box sx={{ textAlign: centered ? "center" : "left", width: "100%" }}>
          <Typography
            variant="h5"
            sx={{
              color: "white",
              mb: 1,
              fontWeight: 600,
              fontSize: styles.titleSize,
            }}
          >
            {name || "Namnlös lektion"}
          </Typography>

          {comment && (
            <Box
              sx={{
                display: "flex",
                alignItems: centered ? "center" : "flex-start",
                gap: 0.5,
                justifyContent: centered ? "center" : "flex-start",
              }}
            >
              {!centered && (
                <CommentIcon
                  sx={{
                    color: "white",
                    fontSize: styles.infoSize,
                    marginTop: "5px",
                  }}
                />
              )}
              <Typography
                sx={{
                  color: "white",
                  fontSize: styles.infoSize,
                  whiteSpace: "pre-wrap",
                  wordBreak: "break-word",
                  textAlign: centered ? "center" : "left",
                }}
              >
                {comment.length > (size === "large" ? 250 : 67)
                  ? `${comment.substring(0, size === "large" ? 247 : 70)}...`
                  : comment}
              </Typography>
            </Box>
          )}
        </Box>

        {!centered && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mt: "auto",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
              <CalendarTodayIcon
                sx={{ color: "white", fontSize: styles.infoSize }}
              />
              <Typography sx={{ color: "white", fontSize: styles.infoSize }}>
                {formatDate(timestamp)}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
              <SchoolIcon sx={{ color: "white", fontSize: styles.infoSize }} />
              <Typography sx={{ color: "white", fontSize: styles.infoSize }}>
                {lessonMapping(lessonType)}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default LessonCard;
