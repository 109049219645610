import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Typography,
  Card,
  CardContent,
  IconButton,
  Chip,
  Button,
  Avatar,
  Tooltip,
  CircularProgress,
  Tabs,
  Alert,
  Menu,
  Divider,
  MenuItem,
  Tab,
  alpha,
  darken,
} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import LoadingBackdrop from "../LoadingBackdrop";
import MessageIcon from "@mui/icons-material/Message";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import ConfirmationDialog from "../ConfirmationDialog";

import CircleIcon from "@mui/icons-material/Circle";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import TextsmsIcon from "@mui/icons-material/Textsms";

import FlashcardEditorDialog from "./FlashcardEditorDialog";
import SchoolIcon from "@mui/icons-material/School";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DownloadIcon from "@mui/icons-material/Download";

import { useSnackbar } from "../SnackbarHandler";
import ViewAgendaIcon from "@mui/icons-material/ViewAgenda";

import FileDownloadIcon from "@mui/icons-material/FileDownload";

import AttachFileOffIcon from "@mui/icons-material/AttachFile";
import NumbersIcon from "@mui/icons-material/Numbers";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import EmailIcon from "@mui/icons-material/Email";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import {
  collection,
  getDocs,
  deleteDoc,
  doc,
  query,
  where,
  limit,
  documentId,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";

import { db } from "../firebase";
import { formatDate, createUrlSlug } from "../functions";
import ShareIcon from "@mui/icons-material/Share";
import ShareDialog from "../ShareDialog";

const MyFlashcards = ({
  user,
  theme,
  styles,
  showSharedFlashcards,
  reloadTrigger,
}) => {
  const [collections, setCollections] = useState([]);
  const [loadingCollections, setLoadingCollections] = useState(false);
  const [isManaging, setIsManaging] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [shareDialogOpenFriends, setShareDialogOpenFriends] = useState(false);
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [isSharing, setIsSharing] = useState(false);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [collectionToDelete, setCollectionToDelete] = useState(null);
  const [selectedCollection, setSelectedCollection] = useState(null);
  const [userDetails, setUserDetails] = useState({});
  useEffect(() => {
    fetchUserCollections();
  }, [user.uid]);

  useEffect(() => {
    // Your existing fetch logic for quizzes
    fetchUserCollections();
  }, [reloadTrigger]); // Add reloadTrigger to dependencies

  const handleEdit = (collection) => {
    console.log("Editing collection:", collection);
    // Pass the full collection object, not just collection.id
    setSelectedCollection(collection);
    setIsEditorOpen(true);
  };

  const handleShare = async ({ studentIds, comment, fileUrl }) => {
    setIsSharing(true);

    try {
      // Create a document for each student
      const sharePromises = studentIds.map(async (studentId) => {
        const documentData = {
          sharedTo: studentId,
          comment: comment,
          fileUrl: fileUrl,
          shared: true,
          userId: user.uid,
          collectionName: selectedCollection.collectionName,
          flashcards: selectedCollection.flashcards,
          isPublic: selectedCollection.isPublic,
          createdAt: Date.now(),
          category: selectedCollection.category,
          language: selectedCollection.language,
          difficulty: selectedCollection.difficulty,
          aiInstructions: selectedCollection.aiInstructions,
          selectedModel: selectedCollection.selectedModel,
          lastTrainingSession: null,
          upvotes: 0,
          downvotes: 0,
          userVotes: {},
        };

        // Create the flashcard collection document and get its ID
        const flashcardDoc = await addDoc(
          collection(db, "flashcardCollections"),
          documentData,
        );

        // Create notification
        await addDoc(collection(db, "notifications"), {
          type: "flashcards_shared",
          recipientId: studentId,
          senderId: user.uid,
          read: false,
          createdAt: serverTimestamp(),
          flashcardData: {
            collectionId: flashcardDoc.id,
            collectionName: selectedCollection.collectionName,
            fileUrl: fileUrl,
            comment: comment,
            category: selectedCollection.category,
            difficulty: selectedCollection.difficulty,
            numFlashcards: selectedCollection.flashcards.length,
          },
        });

        return flashcardDoc;
      });

      await Promise.all(sharePromises);
      showSnackbar("Du har nu delat flashcards!", "success");
      setShareDialogOpen(false);
      setShareDialogOpenFriends(false);
      setMenuAnchorEl(null);
    } catch (error) {
      console.error("Error sharing flashcards:", error);
      showSnackbar("Fel vid delande av flashcards: " + error.message, "error");
    } finally {
      setIsSharing(false);
    }
  };

  const getTabType = (currentTab, isStudent) => {
    if (!isStudent) {
      return currentTab;
    }
    return currentTab >= 1 ? 2 : 0;
  };

  const tabType = getTabType(currentTab, user.accountType === "student");

  const loadUserDetails = async (userIds) => {
    const uniqueUserIds = [...new Set(userIds)];
    const usersSnapshot = await getDocs(
      query(collection(db, "users"), where(documentId(), "in", uniqueUserIds)),
    );

    const userMap = {};
    usersSnapshot.docs.forEach((doc) => {
      userMap[doc.id] = {
        displayName: doc.data().displayName,
        photoURL: doc.data().photoURL,
      };
    });
    setUserDetails(userMap);
  };

  const handleEditClick = () => {
    showSnackbar("Vi jobbar på denna funktion. Den är snart klar! :)", "info");
  };

  const handleMenuOpen = (event, collection) => {
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
    setSelectedCollection(collection);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    setSelectedCollection(null);
  };

  const fetchUserCollections = async () => {
    setLoadingCollections(true);
    try {
      // Split into two queries for proper filtering
      const sharedQuerySnapshot = await getDocs(
        query(
          collection(db, "flashcardCollections"),

          where("sharedTo", "==", user.uid),
          limit(60),
        ),
      );

      const regularQuerySnapshot = await getDocs(
        query(
          collection(db, "flashcardCollections"),
          where("userId", "==", user.uid),
          limit(90),
        ),
      );

      // Get collections shared BY you
      const sharedByYou = regularQuerySnapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .filter((doc) => doc.shared && doc.userId === user.uid)
        .sort((a, b) => (b.createdAt || 0) - (a.createdAt || 0))
        .slice(0, 15);

      // Get regular (non-shared) collections
      const regularCollections = regularQuerySnapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .filter((doc) => !doc.shared && doc.userId === user.uid)
        .sort((a, b) => (b.createdAt || 0) - (a.createdAt || 0))
        .slice(0, 15);

      // Get collections shared TO you
      const sharedToYou = sharedQuerySnapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .filter(
          (doc) => doc.userId !== user.uid && doc.sharedTo?.includes(user.uid),
        )
        .sort((a, b) => (b.createdAt || 0) - (a.createdAt || 0))
        .slice(0, 15);

      const allCollections = [
        ...sharedByYou,
        ...regularCollections,
        ...sharedToYou,
      ];
      setCollections(allCollections);

      const sharedUserIds = allCollections.flatMap((collection) => {
        const ids = [];
        if (collection.shared && collection.userId) {
          ids.push(collection.userId); // Add the teacher's ID
        }
        if (
          collection.shared &&
          collection.userId === user.uid &&
          collection.sharedTo
        ) {
          // Handle both array and string cases for sharedTo
          if (Array.isArray(collection.sharedTo)) {
            ids.push(...collection.sharedTo);
          } else {
            ids.push(collection.sharedTo);
          }
        }
        return ids;
      });

      if (sharedUserIds.length > 0) {
        await loadUserDetails([...new Set(sharedUserIds)]); // Remove duplicates
      }
    } catch (error) {
      console.error("Error fetching user collections:", error);
    } finally {
      setLoadingCollections(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const getFilteredCollections = () => {
    switch (tabType) {
      case 0: // Regular collections
        return collections.filter((collection) => !collection.shared);
      case 1: // Shared with students
        return collections.filter(
          (collection) => collection.shared && collection.userId === user.uid,
        );
      case 2: // Shared by teachers
        return collections.filter(
          (collection) => collection.shared && collection.userId !== user.uid,
        );
      default:
        return [];
    }
  };

  const handleDeleteCollection = async (collectionId, event) => {
    if (event) {
      event.stopPropagation();
    }
    setCollectionToDelete(collectionId);
    setShowDeleteConfirmation(true);
    setMenuAnchorEl(null);
  };
  const handleConfirmDelete = async () => {
    if (collectionToDelete) {
      try {
        await deleteDoc(doc(db, "flashcardCollections", collectionToDelete));
        setCollections((prevCollections) =>
          prevCollections.filter(
            (collection) => collection.id !== collectionToDelete,
          ),
        );
        showSnackbar("Flashcards har raderats", "success");
      } catch (error) {
        console.error("Error deleting collection:", error);
        showSnackbar("Ett fel uppstod när flashcards skulle raderas", "error");
        fetchUserCollections(); // Reload collections if deletion fails
      } finally {
        setShowDeleteConfirmation(false);
        setCollectionToDelete(null);
      }
    }
  };

  const handleDownload = async (url, e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      // Extract filename from URL or use a default
      const fileName = url.substring(url.lastIndexOf("/") + 1).split("?")[0];
      link.setAttribute("download", decodeURIComponent(fileName));
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error("Download failed:", error);
      showSnackbar("Kunde inte ladda ner filen", "error");
    }
  };

  const getTimeSinceLastTraining = (lastTrainingSession) => {
    if (!lastTrainingSession) return "Aldrig tränat";
    const now = Date.now();
    const diff = now - lastTrainingSession;
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);

    if (seconds < 60)
      return `${seconds} sekund${seconds !== 1 ? "er" : ""} sedan`;
    if (minutes < 60)
      return `${minutes} minut${minutes !== 1 ? "er" : ""} sedan`;
    if (hours < 24) return `${hours} timm${hours !== 1 ? "ar" : "e"} sedan`;

    const days = Math.floor(hours / 24);
    if (days === 1) return "Igår";
    if (days < 7) return `${days} dagar sedan`;
    const weeks = Math.floor(days / 7);
    if (weeks === 1) return "1 vecka sedan";
    if (weeks < 4) return `${weeks} veckor sedan`;
    const months = Math.floor(days / 30);
    if (months === 1) return "1 månad sedan";
    if (months < 12) return `${months} månader sedan`;
    const years = Math.floor(days / 365);
    return `${years} år sedan`;
  };

  const getFlashcardResults = (flashcards) => {
    const results = { easy: 0, good: 0, hard: 0, fail: 0 };
    flashcards.forEach((card) => {
      if (card.correct === "easy") results.easy++;
      else if (card.correct === "good") results.good++;
      else if (card.correct === "hard") results.hard++;
      else if (card.correct === "fail") results.fail++;
    });
    return results;
  };

  const getUntrainedCount = (collections) => {
    return collections.filter(
      (col) =>
        col.shared && col.userId !== user.uid && !col.lastTrainingSession,
    ).length;
  };

  const getUntrainedSharedByTeacherCount = (collections) => {
    return collections.filter(
      (col) =>
        col.shared &&
        col.userId === user.uid &&
        col.sharedTo &&
        !col.lastTrainingSession,
    ).length;
  };

  const resultChips = [
    {
      name: "Väldigt svåra",
      color: "#FF4D4D",
      key: "fail",
      icon: CancelOutlinedIcon,
    },
    { name: "Svåra", color: "#C47510", key: "hard", icon: CircleIcon },
    {
      name: "Ganska lätta",
      color: "#27792B",
      key: "good",
      icon: CheckCircleOutlineIcon,
    },
    {
      name: "Lätta",
      color: "#2196F3",
      key: "easy",
      icon: CheckCircleOutlineIcon,
    },
  ];

  const filteredCollections = getFilteredCollections();

  return (
    <Box sx={{ padding: "0px" }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          background: theme.palette.mode === "dark" ? "#22303c" : "#f9f9fa",
          borderTopLeftRadius: "7px",
          borderTopRightRadius: "7px",
        }}
      >
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          sx={{
            "& .MuiTab-root": {
              textTransform: "none",
              minWidth: 120,
              fontSize: "0.9rem",
            },
          }}
        >
          <Tab
            label="Mina flashcards"
            icon={<ViewAgendaIcon sx={{ fontSize: 20 }} />}
            iconPosition="start"
          />
          {user.accountType !== "student" && (
            <Tab
              label={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  Delat till elever från mig
                  {getUntrainedSharedByTeacherCount(collections) > 0 && (
                    <Tooltip
                      title={`${getUntrainedSharedByTeacherCount(collections)} elever som inte börjat öva än`}
                      placement="right"
                    >
                      <Chip
                        size="small"
                        label={getUntrainedSharedByTeacherCount(collections)}
                        sx={{
                          ml: 1,
                          height: 20,
                          minWidth: 20,
                          fontSize: "0.75rem",
                          backgroundColor: theme.palette.primary.main,
                          color: theme.palette.primary.contrastText,
                        }}
                      />
                    </Tooltip>
                  )}
                </Box>
              }
              icon={<PeopleOutlineIcon sx={{ fontSize: 20 }} />}
              iconPosition="start"
            />
          )}
          <Tab
            label={
              <Box sx={{ display: "flex", alignItems: "center" }}>
                Delat från lärare till mig
                {getUntrainedCount(collections) > 0 && (
                  <Tooltip
                    title={`${getUntrainedCount(collections)} flashcards du inte tränat på ännu`}
                    placement="right"
                  >
                    <Chip
                      size="small"
                      label={getUntrainedCount(collections)}
                      sx={{
                        ml: 1,
                        height: 20,
                        minWidth: 20,
                        fontSize: "0.75rem",
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                      }}
                    />
                  </Tooltip>
                )}
              </Box>
            }
            icon={<SchoolIcon sx={{ fontSize: 20 }} />}
            iconPosition="start"
          />
        </Tabs>
      </Box>
      {loadingCollections ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "200px",
            p: 4,
            bgcolor: alpha(theme.palette.background.paper, 0.6),
          }}
        >
          <CircularProgress />
        </Box>
      ) : filteredCollections.length === 0 ? (
        <Box
          sx={{
            p: 4,
            textAlign: "center",
            color: "text.secondary",
            bgcolor: alpha(theme.palette.background.paper, 0.6),
            borderRadius: 2,
          }}
        >
          {tabType === 0
            ? "De flashcards du själv genererat hamnar här."
            : tabType === 1
              ? "Du har inte delat några flashcards med dina elever än."
              : "Inga flashcards har delats med dig av lärare än."}
        </Box>
      ) : (
        <Box sx={{ padding: "0px", paddingTop: "60px" }}>
          {tabType === 2 && getUntrainedCount(collections) > 0 && (
            <Alert
              severity="info"
              variant="filled"
              sx={{
                mb: 1,
                width: "90%",
                margin: "0 auto",
                paddingBottom: "2px",
                paddingTop: "2px",
                "& .MuiAlert-message": {
                  fontSize: "0.9rem",
                  lineHeight: "1rem",
                  paddingTop: "10px",
                },
                borderRadius: 4,
                display: "none",
              }}
            >
              Din lärare har delat {getUntrainedCount(collections)}{" "}
              flashcard-samling som du inte övat på ännu :)
            </Alert>
          )}

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Namn</TableCell>

                  {tabType === 2 && <TableCell>Delad från</TableCell>}

                  {tabType === 1 && <TableCell>Delat med</TableCell>}
                  <TableCell>Senaste träning</TableCell>

                  <TableCell>Resultat</TableCell>
                  <TableCell>Antal</TableCell>
                  <TableCell>Bif.</TableCell>
                  <TableCell>
                    <Tooltip title="Kommentar från lärare">
                      <MailOutlineIcon
                        sx={{
                          fontSize: 20,
                          mt: 1,
                          color: theme.palette.text.secondary,
                        }}
                      />
                    </Tooltip>
                  </TableCell>
                  {user.accountType !== "student" && (
                    <TableCell align="right">Hantera</TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredCollections.map((collection) => (
                  <TableRow
                    key={collection.id}
                    component={Link}
                    to={`/app/flashcards/${collection.id}/${createUrlSlug(collection.collectionName)}`}
                    sx={{
                      textDecoration: "none",
                      cursor: "pointer",
                      "&:hover": { backgroundColor: "action.hover" },
                    }}
                  >
                    <TableCell>
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 2 }}
                      >
                        <ViewAgendaIcon
                          sx={{
                            fontSize: 24,
                            color: (theme) =>
                              darken(theme.palette.text.main, 0.2),
                          }}
                        />
                        <Typography
                          sx={{ fontWeight: 400, fontSize: "0.9rem" }}
                        >
                          {collection.collectionName}
                        </Typography>
                      </Box>
                    </TableCell>

                    {tabType === 2 && (
                      <TableCell>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Tooltip
                            title={
                              userDetails[collection.userId]?.displayName ||
                              "Loading..."
                            }
                          >
                            <Avatar
                              src={userDetails[collection.userId]?.photoURL}
                              alt={userDetails[collection.userId]?.displayName}
                              sx={{
                                width: 22,
                                height: 22,
                                fontSize: "0.775rem",
                              }}
                            >
                              {userDetails[collection.userId]
                                ?.displayName?.[0] || "?"}
                            </Avatar>
                          </Tooltip>
                          <Typography variant="body2" sx={{ ml: 1 }}>
                            {userDetails[collection.userId]?.displayName ||
                              "Loading..."}
                          </Typography>
                        </Box>
                      </TableCell>
                    )}

                    {tabType === 1 && (
                      <TableCell>
                        {collection.shared &&
                        collection.userId === user.uid &&
                        collection.sharedTo ? (
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Tooltip
                              title={
                                userDetails[collection.sharedTo]?.displayName ||
                                "Loading..."
                              }
                            >
                              <Avatar
                                src={userDetails[collection.sharedTo]?.photoURL}
                                alt={
                                  userDetails[collection.sharedTo]?.displayName
                                }
                                sx={{
                                  width: 22,
                                  height: 22,
                                  fontSize: "0.775rem",
                                }}
                              >
                                {userDetails[collection.sharedTo]
                                  ?.displayName?.[0] || "?"}
                              </Avatar>
                            </Tooltip>
                            <Typography variant="body2" sx={{ ml: 1 }}>
                              {userDetails[collection.sharedTo]?.displayName ||
                                "Loading..."}
                            </Typography>
                          </Box>
                        ) : (
                          "-"
                        )}
                      </TableCell>
                    )}

                    <TableCell>
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        <AccessTimeIcon
                          sx={{
                            fontSize: 16,
                            color: !collection.lastTrainingSession
                              ? theme.palette.warning.main
                              : theme.palette.text.secondary,
                          }}
                        />
                        <Typography
                          variant="body2"
                          sx={{
                            color: !collection.lastTrainingSession
                              ? theme.palette.warning.main
                              : theme.palette.text.secondary,
                          }}
                        >
                          {getTimeSinceLastTraining(
                            collection.lastTrainingSession,
                          )}
                        </Typography>
                      </Box>
                    </TableCell>

                    <TableCell>
                      <Tooltip
                        onClick={(e) => e.stopPropagation()}
                        onMouseDown={(e) => e.stopPropagation()}
                        slotProps={{
                          popper: {
                            sx: {
                              "& .MuiTooltip-tooltip": {
                                bgcolor: "background.paper",
                                borderRadius: "8px",
                                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                              },
                              "& .MuiTooltip-arrow": {
                                color: "background.paper",
                              },
                            },
                          },
                        }}
                        title={
                          <Box
                            onClick={(e) => e.stopPropagation()}
                            onMouseDown={(e) => e.stopPropagation()}
                            sx={{
                              p: 2,
                              maxWidth: 400,
                              maxHeight: 400,
                              overflow: "auto",
                              bgcolor: "background.paper",
                              borderRadius: 1,
                            }}
                          >
                            {collection.flashcards.map((card, index) => (
                              <Box
                                key={index}
                                sx={{
                                  mb: 2.5,
                                  pb: 2.5,
                                  "&:not(:last-child)": {
                                    borderBottom: "1px solid",
                                    borderColor: "divider",
                                  },
                                }}
                              >
                                <Box sx={{ mb: 1 }}>
                                  <Chip
                                    size="small"
                                    icon={
                                      card.correct === "easy" ? (
                                        <CheckCircleOutlineIcon />
                                      ) : card.correct === "good" ? (
                                        <CheckCircleOutlineIcon />
                                      ) : card.correct === "hard" ? (
                                        <CircleIcon />
                                      ) : (
                                        <CancelOutlinedIcon />
                                      )
                                    }
                                    label={
                                      card.correct === "easy"
                                        ? "Lätt"
                                        : card.correct === "good"
                                          ? "Ganska lätt"
                                          : card.correct === "hard"
                                            ? "Svår"
                                            : "Väldigt svår"
                                    }
                                    sx={{
                                      height: "24px",
                                      background: alpha(
                                        card.correct === "easy"
                                          ? "#2196F3"
                                          : card.correct === "good"
                                            ? "#27792B"
                                            : card.correct === "hard"
                                              ? "#C47510"
                                              : "#FF4D4D",
                                        0.1,
                                      ),
                                      color:
                                        card.correct === "easy"
                                          ? "#2196F3"
                                          : card.correct === "good"
                                            ? "#27792B"
                                            : card.correct === "hard"
                                              ? "#C47510"
                                              : "#FF4D4D",
                                      fontSize: "11px",
                                      fontWeight: 500,
                                      "& .MuiChip-icon": {
                                        fontSize: 14,
                                        color: "inherit",
                                      },
                                    }}
                                  />
                                </Box>
                                <Typography
                                  variant="subtitle2"
                                  sx={{
                                    mb: 0.5,
                                    fontWeight: 600,
                                    color: "text.primary",
                                  }}
                                >
                                  Framsida:
                                </Typography>
                                <Typography
                                  variant="body2"
                                  sx={{
                                    mb: 1.5,
                                    color: "text.secondary",
                                  }}
                                >
                                  {card.front}
                                </Typography>
                                <Typography
                                  variant="subtitle2"
                                  sx={{
                                    mb: 0.5,
                                    fontWeight: 600,
                                    color: "text.primary",
                                  }}
                                >
                                  Baksida:
                                </Typography>
                                <Typography
                                  variant="body2"
                                  sx={{
                                    color: "text.secondary",
                                  }}
                                >
                                  {card.back}
                                </Typography>
                              </Box>
                            ))}
                          </Box>
                        }
                        arrow
                        placement="top-start"
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: "background.paper",
                              "& .MuiTooltip-arrow": {
                                color: "background.paper",
                              },
                            },
                          },
                        }}
                        arrow
                        placement="top-start"
                      >
                        <Box
                          sx={{
                            display: "flex",
                            gap: 1,
                            flexWrap: "wrap",
                            maxWidth: 400,
                          }}
                        >
                          {(() => {
                            const results = getFlashcardResults(
                              collection.flashcards,
                            );
                            return resultChips.map(
                              (chip) =>
                                results[chip.key] > 0 && (
                                  <Chip
                                    key={chip.name}
                                    label={`${chip.name}: ${results[chip.key]}`}
                                    size="small"
                                    icon={
                                      <chip.icon style={{ color: "inherit" }} />
                                    }
                                    sx={{
                                      height: "24px",
                                      background: alpha(chip.color, 0.1),
                                      color: chip.color,
                                      textTransform: "uppercase",
                                      fontSize: "11px",
                                      fontWeight: 500,
                                      "& .MuiChip-icon": {
                                        fontSize: 14,
                                        color: "inherit",
                                      },
                                    }}
                                  />
                                ),
                            );
                          })()}
                        </Box>
                      </Tooltip>
                    </TableCell>

                    <TableCell>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          color: theme.palette.text.secondary,
                        }}
                      >
                        <NumbersIcon
                          sx={{ fontSize: 16, mr: 0.5, display: "none" }}
                        />
                        <Typography variant="body2">
                          {collection.flashcards.length} st.
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Tooltip
                        title={
                          collection.fileUrl
                            ? "Ladda ner fil från din lärare"
                            : "Ingen fil delad från din lärare"
                        }
                      >
                        <span
                          style={{
                            cursor: collection.fileUrl ? "pointer" : "default",
                          }}
                          onClick={(e) => {
                            if (!collection.fileUrl) {
                              e.preventDefault();
                              e.stopPropagation();
                            }
                          }}
                        >
                          <IconButton
                            size="small"
                            onClick={
                              collection.fileUrl
                                ? (e) => handleDownload(collection.fileUrl, e)
                                : undefined
                            }
                            disabled={!collection.fileUrl}
                            sx={{
                              color: collection.fileUrl
                                ? theme.palette.primary.main
                                : theme.palette.text.disabled,
                              "&:hover": collection.fileUrl
                                ? {
                                    backgroundColor: alpha(
                                      theme.palette.primary.main,
                                      0.1,
                                    ),
                                  }
                                : undefined,
                              opacity: collection.fileUrl ? 1 : 0.5,
                            }}
                          >
                            {collection.fileUrl ? (
                              <AttachFileOffIcon sx={{ fontSize: 20 }} />
                            ) : (
                              <AttachFileOffIcon sx={{ fontSize: 20 }} />
                            )}
                          </IconButton>
                        </span>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      {collection.comment ? (
                        <Tooltip title={collection.comment}>
                          <MailOutlineIcon
                            sx={{
                              mt: 0.5,
                              fontSize: 20,
                              color: theme.palette.text.secondary,
                            }}
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Ingen kommentar från lärare">
                          <MailOutlineIcon
                            sx={{
                              mt: 0.5,
                              fontSize: 20,
                              color: theme.palette.text.disabled,
                            }}
                          />
                        </Tooltip>
                      )}
                    </TableCell>
                    {user.accountType !== "student" && (
                      <TableCell align="right">
                        <IconButton
                          onClick={(event) => {
                            event.preventDefault(); // Add this
                            event.stopPropagation();

                            setMenuAnchorEl(event.currentTarget);
                            setSelectedCollection(collection);
                          }}
                          size="small"
                        >
                          <MoreHorizIcon />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Menu
            anchorEl={menuAnchorEl}
            open={Boolean(menuAnchorEl)}
            onClose={() => {
              setMenuAnchorEl(null);
              setSelectedCollection(null);
            }}
            PaperProps={{
              sx: { borderRadius: "8px", minWidth: "160px" },
            }}
          >
            <MenuItem
              onClick={() => setShareDialogOpenFriends(true)}
              sx={styles.menuItemStyle}
            >
              <ShareIcon fontSize="small" sx={{ mr: 1, color: "#7B68EE" }} />
              Dela med vänner
            </MenuItem>

            <MenuItem
              onClick={() => setShareDialogOpen(true)}
              sx={styles.menuItemStyle}
            >
              <ShareIcon fontSize="small" sx={{ mr: 1, color: "#4CAF50" }} />
              Dela med elever
            </MenuItem>

            <MenuItem
              onClick={() => handleEdit(selectedCollection)}
              sx={styles.menuItemStyle}
            >
              <EditIcon fontSize="small" sx={{ mr: 1, color: "#4CAF50" }} />
              Redigera
            </MenuItem>
            <Divider />
            <MenuItem
              sx={styles.menuItemStyle}
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteCollection(selectedCollection?.id);
                setMenuAnchorEl(null);
              }}
            >
              <DeleteIcon fontSize="small" sx={{ mr: 1, color: "#F44336" }} />
              Ta bort flashcards
            </MenuItem>
          </Menu>
        </Box>
      )}

      <LoadingBackdrop
        isOpen={isSharing}
        imagePath="/img/AI.png"
        loadingText="Delar flashcards..."
      />
      <ShareDialog
        open={shareDialogOpen}
        onClose={() => setShareDialogOpen(false)}
        onShare={handleShare}
        user={user}
        theme={theme}
      />

      <ShareDialog
        open={shareDialogOpenFriends}
        onClose={() => setShareDialogOpenFriends(false)}
        onShare={handleShare}
        user={user}
        theme={theme}
        shareWithFriends={true}
      />

      <FlashcardEditorDialog
        open={isEditorOpen}
        onClose={() => setIsEditorOpen(false)}
        collection={selectedCollection}
        user={user}
        showSnackbar={showSnackbar}
        onEditComplete={(updatedFlashcards) => {
          setMenuAnchorEl(null);
          setSelectedCollection(null);
          fetchUserCollections();
          setIsEditorOpen(false);
        }}
      />
      <ConfirmationDialog
        open={showDeleteConfirmation}
        onClose={() => setShowDeleteConfirmation(false)}
        onConfirm={handleConfirmDelete}
        title="Ta bort flashcards"
        message="Är du säker på att du vill ta bort denna flashcard-samling? Detta går inte att ångra."
        confirmText="Ta bort"
        cancelText="Avbryt"
      />
    </Box>
  );
};

export default MyFlashcards;
