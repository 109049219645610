import {
  Science as ScienceIcon,
  Castle as CastleIcon,
  Psychology as PsychologyIcon,
  Pets as PetsIcon,
  Public as PublicIcon,
  Brush as BrushIcon,
  Calculate as CalculateIcon,
  Language as LanguageIcon,
  EmojiObjects as EmojiObjectsIcon,
  LocalFlorist as LocalFloristIcon,
  WbSunny as WbSunnyIcon,
  Workspaces as WorkspacesIcon,
  Agriculture as AgricultureIcon,
  MonitorHeart as MonitorHeartIcon,
  Park as ParkIcon,
  Biotech as BiotechIcon,
  MenuBook as MenuBookIcon,
  EmojiEvents as EmojiEventsIcon,
  Museum as MuseumIcon,
  Nature as NatureIcon,
  AccountBalance as AccountBalanceIcon,
  MilitaryTech as MilitaryTechIcon,
  Science as DNAIcon,
  Psychology as BrainIcon,
  Public as GlobeIcon,
  EmojiObjects as LightBulbIcon,
} from "@mui/icons-material";

// Default themes that show when no subject is selected
export const defaultThemes = [
  {
    type: "subheader",
    label: "Brevkorrespondens som lämpar sig extra bra för denna lektionstyp:",
  },

  {
    value: "berlinmuren-fall",
    label: "Brevväxling över Berlinmuren",
    content: "Brevväxling mellan en familj delad av Berlinmuren 1961-1989",
    Icon: CastleIcon,
  },

  {
    value: "palme-correspondence",
    label: "Brev mellan Palme och Brandt",
    content:
      "Brevväxling mellan Olof Palme och Willy Brandt om europeisk politik",
    Icon: AccountBalanceIcon,
  },

  {
    value: "anna-lindh",
    label: "Anna Lindh och EU",
    content: "Brevväxling mellan Anna Lindh och Romano Prodi om EU:s framtid",
    Icon: AccountBalanceIcon,
  },

  {
    value: "raoul-wallenberg",
    label: "Wallenbergs diplomatiska brev",
    content: "Brevväxling mellan Raoul Wallenberg och svenska UD från Budapest",
    Icon: EmojiEventsIcon,
  },

  {
    value: "carl-von-linne",
    label: "Linnés vetenskapliga korrespondens",
    content: "Brevväxling mellan Carl von Linné och andra botaniker i Europa",
    Icon: NatureIcon,
  },

  {
    value: "alfred-nobel",
    label: "Nobel och Bertha von Suttner",
    content:
      "Brevväxling mellan Alfred Nobel och fredskämpen Bertha von Suttner",
    Icon: EmojiEventsIcon,
  },

  {
    value: "astrid-lindgren",
    label: "Lindgren och förlagen",
    content: "Brevväxling mellan Astrid Lindgren och internationella förlag",
    Icon: MenuBookIcon,
  },

  {
    value: "dag-hammarskjold",
    label: "Hammarskjöld och världsledare",
    content:
      "Brevväxling mellan Dag Hammarskjöld och världsledare under Suezkrisen",
    Icon: PublicIcon,
  },

  {
    value: "greta-garbo",
    label: "Garbo och Stiller",
    content:
      "Brevväxling mellan Greta Garbo och Mauritz Stiller från Hollywood",
    Icon: MuseumIcon,
  },
];

export const subjectThemes = {
  biologi: [
    { type: "subheader", label: "Brevkorrespondensteman inom biologi:" },
    {
      value: "dna-upptackt",
      label: "Watson och Crick",
      content: "Brevväxling mellan Watson och Crick om DNA-strukturen",
      Icon: DNAIcon,
    },
    {
      value: "darwin-wallace",
      label: "Darwin och Wallace",
      content:
        "Brevväxling mellan Charles Darwin och Alfred Wallace om evolutionsteorin",
      Icon: NatureIcon,
    },
    {
      value: "fleming-florey",
      label: "Fleming och Florey",
      content:
        "Brevväxling mellan Alexander Fleming och Howard Florey om penicillin",
      Icon: BiotechIcon,
    },
    {
      value: "mendel-nageli",
      label: "Mendel och Nägeli",
      content:
        "Brevväxling mellan Gregor Mendel och Carl von Nägeli om ärftlighet",
      Icon: LocalFloristIcon,
    },
    {
      value: "pasteur-koch",
      label: "Pasteur och Koch",
      content:
        "Brevväxling mellan Louis Pasteur och Robert Koch om bakteriologi",
      Icon: ScienceIcon,
    },
    {
      value: "linnaeus-kalm",
      label: "Linné och Pehr Kalm",
      content: "Brevväxling mellan Carl von Linné och hans lärjunge Pehr Kalm",
      Icon: ParkIcon,
    },
  ],

  historia: [
    { type: "subheader", label: "Brevkorrespondensteman inom historia:" },
    {
      value: "east-west-letters",
      label: "Brev över muren",
      content: "Brevväxling mellan familjer i Öst- och Västberlin",
      Icon: CastleIcon,
    },
    {
      value: "gustav-vasa-lubeck",
      label: "Gustav Vasa och Lübeck",
      content: "Brevväxling mellan Gustav Vasa och Hansan i Lübeck",
      Icon: AccountBalanceIcon,
    },
    {
      value: "kristina-descartes",
      label: "Kristina och Descartes",
      content: "Brevväxling mellan drottning Kristina och René Descartes",
      Icon: CastleIcon,
    },
    {
      value: "axel-oxenstierna",
      label: "Gustav II Adolf och Oxenstierna",
      content: "Brevväxling mellan Gustav II Adolf och Axel Oxenstierna",
      Icon: AccountBalanceIcon,
    },
    {
      value: "karl-xii-rada",
      label: "Karl XII och rådet",
      content: "Brevväxling mellan Karl XII och rådet i Stockholm",
      Icon: CastleIcon,
    },
  ],

  fysik: [
    { type: "subheader", label: "Brevkorrespondensteman inom fysik:" },
    {
      value: "einstein-bohr",
      label: "Einstein och Bohr",
      content: "Brevväxling mellan Einstein och Bohr om kvantmekanik",
      Icon: LightBulbIcon,
    },
    {
      value: "nobel-dynamit",
      label: "Nobel och hans bror",
      content:
        "Brevväxling mellan Alfred Nobel och Robert Nobel om sprängämnen",
      Icon: ScienceIcon,
    },
    {
      value: "curie-rutherford",
      label: "Curie och Rutherford",
      content: "Brevväxling mellan Marie Curie och Ernest Rutherford",
      Icon: ScienceIcon,
    },
    {
      value: "celsius-linne",
      label: "Celsius och Linné",
      content: "Brevväxling mellan Anders Celsius och Carl von Linné",
      Icon: EmojiObjectsIcon,
    },
    {
      value: "newton-hooke",
      label: "Newton och Hooke",
      content: "Brevväxling mellan Isaac Newton och Robert Hooke",
      Icon: PublicIcon,
    },
  ],

  kemi: [
    { type: "subheader", label: "Brevkorrespondensteman inom kemi:" },
    {
      value: "scheele-bergman",
      label: "Scheele och Bergman",
      content: "Brevväxling mellan Carl Wilhelm Scheele och Torbern Bergman",
      Icon: ScienceIcon,
    },
    {
      value: "arrhenius-ostwald",
      label: "Arrhenius och Ostwald",
      content: "Brevväxling mellan Svante Arrhenius och Wilhelm Ostwald",
      Icon: WorkspacesIcon,
    },
    {
      value: "berzelius-davy",
      label: "Berzelius och Davy",
      content: "Brevväxling mellan Berzelius och Humphry Davy",
      Icon: ScienceIcon,
    },
    {
      value: "mendelejev-meyer",
      label: "Mendelejev och Meyer",
      content: "Brevväxling om det periodiska systemet",
      Icon: WorkspacesIcon,
    },
    {
      value: "lavoisier-priestley",
      label: "Lavoisier och Priestley",
      content: "Brevväxling om syrets upptäckt",
      Icon: WorkspacesIcon,
    },
  ],

  matematik: [
    { type: "subheader", label: "Brevkorrespondensteman inom matematik:" },
    {
      value: "euler-goldbach",
      label: "Euler och Goldbach",
      content: "Brevväxling om Goldbachs förmodan",
      Icon: CalculateIcon,
    },
    {
      value: "gauss-sophie",
      label: "Gauss och Sophie Germain",
      content: "Brevväxling mellan Gauss och Sophie Germain om talteori",
      Icon: CalculateIcon,
    },
    {
      value: "leibniz-newton",
      label: "Leibniz och Newton",
      content: "Brevväxling om utvecklingen av kalkylen",
      Icon: CalculateIcon,
    },
    {
      value: "turing-newman",
      label: "Turing och Newman",
      content: "Brevväxling om datorns utveckling",
      Icon: CalculateIcon,
    },
    {
      value: "lovelace-babbage",
      label: "Lovelace och Babbage",
      content: "Brevväxling om den analytiska maskinen",
      Icon: CalculateIcon,
    },
  ],

  svenska: [
    { type: "subheader", label: "Brevkorrespondensteman inom svenska:" },
    {
      value: "strindberg-siri",
      label: "Strindberg och Siri von Essen",
      content: "Brevväxling mellan August Strindberg och Siri von Essen",
      Icon: MenuBookIcon,
    },
    {
      value: "lagerlof-levertin",
      label: "Lagerlöf och Levertin",
      content: "Brevväxling mellan Selma Lagerlöf och Oscar Levertin",
      Icon: MenuBookIcon,
    },
    {
      value: "bellman-Gustaf-III",
      label: "Bellman och Gustaf III",
      content: "Brevväxling mellan Carl Michael Bellman och kung Gustaf III",
      Icon: LanguageIcon,
    },
    {
      value: "moberg-johnson",
      label: "Moberg och Eyvind Johnson",
      content: "Brevväxling om svensk utvandring till Amerika",
      Icon: MenuBookIcon,
    },
    {
      value: "lindgren-rabén",
      label: "Lindgren och Rabén & Sjögren",
      content: "Brevväxling om Pippi Långstrumps publicering",
      Icon: MenuBookIcon,
    },
    {
      value: "transtromer-bly",
      label: "Tranströmer och Robert Bly",
      content: "Brevväxling mellan Tomas Tranströmer och Robert Bly",
      Icon: EmojiEventsIcon,
    },
  ],
};

export const getThemes = (subject) => {
  if (!subject) return defaultThemes;
  return subjectThemes[subject] || defaultThemes;
};

export default getThemes;
