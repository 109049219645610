import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Divider,
  CircularProgress,
  Tooltip,
  FormControl,
  Select,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  MenuItem,
  Grid,
  TextField,
} from "@mui/material";

import GenerateIcon from "@mui/icons-material/AutoAwesome";
import ImageIcon from "@mui/icons-material/Image";
import TimelineIcon from "@mui/icons-material/Timeline"; // Add this import
import PrintIcon from "@mui/icons-material/Print";
import ShareLink from "./ShareLink";
import PrintAsPdf from "./PrintAsPdf";
import Translate from "./Translate";
import SlideInPaper from "./SlideInPaper";
import TimeBuilderEditDialog from "./TimeBuilderEditDialog";
import { callApi } from "./api";
import CustomTimeline from "./Timeline";
import Logger from "./Logger";
import MindMap from "./MindMap";
import TextFieldsIcon from "@mui/icons-material/TextFields";

import LanguageSelector from "./LanguageSelector";
import ClearIcon from "@mui/icons-material/Clear";

import { db } from "./firebase";
import {
  collection,
  query,
  where,
  limit,
  getDocs,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";

import { ThemeSelectorFlow } from "./ThemeSelectorFlow";
import InfoBox from "./InfoBox";
import { handlePrintTimelineLesson } from "./printFunctions";
import {
  generateAndProcessImage,
  handleImageGenerationError,
} from "./functions";
import { useSnackbar } from "./SnackbarHandler";
import ProgressBar from "./ProgressBar";

import ImageGenerationModal from "./ImageGenerationModal";
import GradeSelector, { gradeInstructions } from "./GradeSelector";
import { useTheme } from "@mui/material/styles";

import HistoryIcon from "@mui/icons-material/History";
import { getStyles } from "../index";
import { trackLesson } from "./functions";
import { getThemes } from "./additionalThemes/TimelineLesson";
import LessonDrawer from "./LessonDrawer";
import SaveLessonDialog from "./SaveLessonDialog";
import HoverableImage from "./HoverableImage";

import { useSearchParams, useParams } from "react-router-dom";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import IconButton from "@mui/material/IconButton";

import DashedPapers from "./DashedPapers";
import LessonQuizDisplay from "./LessonQuizDisplay";
import AddonTimelineSimple from "./AddonTimelineSimple";
import TimelineSimpleDisplay from "./TimelineSimpleDisplay";
import TimelineDisplay from "./TimelineDisplay";
import LessonExercisesDisplay from "./LessonExercisesDisplay";
import ReadingComprehensionDialog from "./ReadingComprehensionDialog";
import AddonTimeline from "./AddonTimeline";
import AddonQuiz from "./AddonQuiz";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import LoadingBackdrop from "./LoadingBackdrop";
import ShareDialog from "./ShareDialog";
import ShareIcon from "@mui/icons-material/Share";

const TimelineLesson = ({ user }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);

  const lessonTitleRef = useRef(null); // Add this ref

  const [searchParams, setSearchParams] = useSearchParams();
  const [showControlPanel, setShowControlPanel] = useState(false);
  const [lessonTheme, setLessonTheme] = useState("");
  const [language, setLanguage] = useState("svenska");
  const [isSharing, setIsSharing] = useState(false);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [grade, setGrade] = useState("åk6");
  const [progress, setProgress] = useState(0);
  const [isGeneratingLesson, setIsGeneratingLesson] = useState(false);
  const [isGeneratingImages, setIsGeneratingImages] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [customTheme, setCustomTheme] = useState("");
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageInstructions, setImageInstructions] = useState("");
  const [openAiInstructions, setOpenAiInstructions] = useState("");
  const [shareDialogOpenFriends, setShareDialogOpenFriends] = useState(false);

  const pdfButtonRef = useRef(null);
  const translateButtonRef = useRef(null);
  const shareLinkButtonRef = useRef(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [loadingImageIndex, setLoadingImageIndex] = useState(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const [predefinedTheme, setPredefinedTheme] = useState(null);
  const [lessonData, setLessonData] = useState(null);
  const subject = searchParams.get("subject") || null;
  const viewMode = searchParams.get("v") === "t";
  const [numberOfEvents, setNumberOfEvents] = useState(10);
  const additionalThemes = getThemes(subject);

  const { showSnackbar } = useSnackbar();
  const theme = useTheme();
  const styles = getStyles(theme);

  const transcriptRef = useRef("");

  const bookContentRef = useRef("");

  const uploadedFileRef = useRef("");

  const wikipediaContentRef = useRef("");
  const randomizedContentRef = useRef("");
  const coursePlanContentRef = useRef("");
  const urlContentRef = useRef("");

  const quizSectionRef = useRef(null);
  const exercisesSectionRef = useRef(null);
  const timelineSectionRef = useRef(null);
  const timelineSimpleSectionRef = useRef(null);

  const quizButtonRef = useRef(null);
  const timelineButtonRef = useRef(null);
  const timelineSimpleButtonRef = useRef(null);
  const comprehensionButtonRef = useRef(null);

  // Add click handlers for the DashedPaper components
  const handleQuizPaperClick = () => {
    quizButtonRef.current?.click();
  };

  const handleTimelineSimplePaperClick = () => {
    timelineSimpleButtonRef.current?.click();
  };

  const handleComprehensionPaperClick = () => {
    comprehensionButtonRef.current?.click();
  };

  const handleTimelinePaperClick = () => {
    timelineButtonRef.current?.click();
  };

  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [editedLessonTitle, setEditedLessonTitle] = useState("");

  const handleEventsUpdate = (newEvents) => {
    setLessonData((prevData) => ({
      ...prevData,
      events: newEvents,
    }));
  };

  useEffect(() => {
    Logger.log(
      "lessonData TimelineLesson: ",
      JSON.stringify(lessonData, null, 2),
    );
  }, [lessonData]);

  useEffect(() => {
    Logger.log("subject: ", subject);
  }, [subject]);
  useEffect(() => {
    Logger.log("lessonTheme: ", lessonTheme);
  }, [lessonTheme]);

  useEffect(() => {
    Logger.log("predefinedTheme: ", predefinedTheme);
  }, [predefinedTheme]);

  useEffect(() => {
    Logger.log("customTheme: ", customTheme);
  }, [customTheme]);

  useEffect(() => {
    Logger.log("youtubeTranscript: ", transcriptRef.current);
  }, [transcriptRef.current]);

  useEffect(() => {
    Logger.log("wikipediaContentRef: ", wikipediaContentRef.current);
  }, [wikipediaContentRef.current]);
  useEffect(() => {
    Logger.log("uploadedFileRef: ", uploadedFileRef.current);
  }, [uploadedFileRef.current]);

  useEffect(() => {
    Logger.log("randomizedContentRef: ", randomizedContentRef.current);
  }, [randomizedContentRef.current]);

  useEffect(() => {
    Logger.log("coursePlanContentRef: ", coursePlanContentRef.current);
  }, [coursePlanContentRef.current]);

  const toggleViewMode = () => {
    const currentParams = Object.fromEntries(searchParams.entries());
    if (currentParams.v === "t") {
      delete currentParams.v;
    } else {
      currentParams.v = "t";
    }
    setSearchParams(currentParams);
  };

  const { lessonId } = useParams();
  useEffect(() => {
    const loadLessonFromId = async () => {
      if (lessonId) {
        try {
          const q = query(
            collection(db, "lesson_usage"),
            where("__name__", ">=", lessonId),
            where("__name__", "<=", lessonId + "\uf8ff"),
            where("lessonType", "==", "TimelineLesson.js"),
            limit(1),
          );

          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            const docData = querySnapshot.docs[0].data();
            setLessonData(docData.data);

            // Scroll to lesson content
            setTimeout(() => {
              lessonTitleRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }, 100);

            showSnackbar("Lektionen har laddats in.", "success");
          } else {
            showSnackbar("Kunde inte hitta lektionen", "error");
          }
        } catch (error) {
          console.error("Error loading lesson:", error);
          showSnackbar("Ett fel uppstod när lektionen skulle laddas", "error");
        }
      }
    };

    loadLessonFromId();
  }, lessonId);

  useEffect(() => {
    Logger.log("lessonData: ", lessonData);
    const isDataValid =
      lessonData !== null &&
      lessonData !== undefined &&
      Object.keys(lessonData).length > 0;

    if (isDataValid !== showControlPanel) {
      const timer = setTimeout(() => {
        setShowControlPanel(isDataValid);
      }, 100);

      return () => clearTimeout(timer);
    }
  }, [lessonData, showControlPanel]);

  const handleShare = async (
    lessonComment,
    lessonName,
    selectedImage,
    tags,
    shareData,
  ) => {
    setIsSharing(true);
    await new Promise((resolve) => setTimeout(resolve, 1500));
    // try {
    //   // Create a document for each student
    //   const sharePromises = shareData.studentIds.map((studentId) => {
    //     const documentData = {
    //       userId: user.uid,
    //       shared: true,
    //       sharedTo: studentId,
    //       comment: shareData.comment,
    //       sharedAt: Date.now(),
    //       fileUrl: shareData.fileUrl,
    //       lessonType: "TimelineLesson.js",
    //       timestamp: serverTimestamp(),
    //       lessonComment,
    //       lessonName,
    //       coverImage: selectedImage,
    //       hasPrinted: true,
    //       data: lessonData,
    //     };
    //     Logger.log(
    //       "Adding document for student",
    //       studentId,
    //       ":",
    //       JSON.stringify(documentData, null, 2),
    //     );
    //     return addDoc(collection(db, "lesson_usage"), documentData);
    //   });
    //   await Promise.all(sharePromises);
    //   showSnackbar(`Du har nu delat lektionen!`, "success");
    // }
    try {
      // Create a document for each student and then create notifications
      const sharePromises = shareData.studentIds.map(async (studentId) => {
        const documentData = {
          userId: user.uid,
          shared: true,
          sharedTo: studentId,
          comment: shareData.comment,
          sharedAt: Date.now(),
          fileUrl: shareData.fileUrl,
          lessonType: "TimelineLesson.js",
          timestamp: serverTimestamp(),
          lessonComment,
          lessonName,
          coverImage: selectedImage,
          hasPrinted: true,
          data: lessonData,
        };
        // Create lesson document and get ID
        const lessonDoc = await addDoc(
          collection(db, "lesson_usage"),
          documentData,
        );
        Logger.log(
          "Adding document for student",
          studentId,
          ":",
          JSON.stringify(documentData, null, 2),
        );
        // Create notification with lesson doc ID
        await addDoc(collection(db, "notifications"), {
          type: "lesson_shared",
          recipientId: studentId,
          senderId: user.uid,
          read: false,
          createdAt: serverTimestamp(),
          lessonData: {
            lessonId: lessonDoc.id,
            lessonType: documentData.lessonType,
            lessonName: documentData.lessonName,
            fileUrl: documentData.fileUrl,
            comment: documentData.comment,
            coverImage: selectedImage,
          },
        });
        return lessonDoc;
      });
      await Promise.all(sharePromises);
      showSnackbar(`Du har nu delat lektionen!`, "success");
    } catch (error) {
      showSnackbar("Fel vid delande av lektionen: " + error.message, "error");
      console.error(error);
    } finally {
      setIsSharing(false);
    }
  };

  const handleSaveLessonClick = () => {
    setSaveDialogOpen(true);
  };

  const handleSaveWithComment = async (comment, name, coverImage, tags) => {
    Logger.log(JSON.stringify(lessonData, null, 2));
    await trackLesson(
      user.uid,
      "TimelineLesson.js",
      lessonData,
      null,
      true,
      comment,
      name,
      coverImage,
      tags,
    );
    setDrawerOpen(true);
    showSnackbar("Lektionen sparad i din lektionsbank!", "success");
  };

  const simulateProgress = (startProgress, endProgress, duration) => {
    const stepSize = 0.5;
    const steps = (endProgress - startProgress) / stepSize;
    const stepDuration = duration / steps;
    let currentProgress = startProgress;

    const intervalId = setInterval(() => {
      currentProgress += stepSize;
      if (currentProgress >= endProgress) {
        clearInterval(intervalId);
      } else {
        setProgress(currentProgress);
      }
    }, stepDuration);

    return () => clearInterval(intervalId);
  };

  const handleGenerateLesson = async () => {
    setProgress(0);
    let stopProgressSimulation = null;
    setIsGeneratingLesson(true);
    const gradeInstructionsText = gradeInstructions[grade];
    setLessonData(null);
    try {
      let themeToUse;

      if (lessonTheme === "youtube") {
        themeToUse = transcriptRef.current;
      } else if (lessonTheme === "customTheme") {
        themeToUse = customTheme;
      } else if (lessonTheme === "predefined") {
        themeToUse = predefinedTheme;
      } else if (lessonTheme === "fileUpload") {
        themeToUse = uploadedFileRef.current;
      } else if (lessonTheme === "wikipedia") {
        themeToUse = wikipediaContentRef.current;
      } else if (lessonTheme === "randomizer") {
        themeToUse = randomizedContentRef.current;
      } else if (lessonTheme === "book") {
        themeToUse = bookContentRef.current;
      } else if (lessonTheme === "url") {
        themeToUse = urlContentRef.current;
      } else {
        //
        themeToUse = coursePlanContentRef.current;
      }

      Logger.log("themeToUse: ", themeToUse);

      stopProgressSimulation = simulateProgress(0, 90, 15000);

      let response = await callApi(
        [
          {
            role: "system",
            content: `You are an expert teacher creating engaging timeline-based lessons in ${language}. Generate a chronological sequence of events that follows the exact JSON structure provided.`,
          },
          {
            role: "user",
            content: `
          
          Theme: "${themeToUse}".
          ${openAiInstructions}.
          ${gradeInstructionsText}

          SVARA I FÖLJANDE JSON-FORMAT:
  {
            "title": "Lektionstitel",
            "description": "kort beskrivning",
            "events": [
              {
                "date": "Year of event",
                "title": "Event title",
                "description": "Detailed description of the event in maximum 50 words",

              }
            ]
          }

        Notes:
          - Generate exactly ${numberOfEvents} events
          - Dates should be specific and historically accurate
          - Events should be in chronological order
      

          `,
          },
        ],
        6000,
        true,
        user.uid,
      );

      if (stopProgressSimulation) stopProgressSimulation();
      setProgress(95);

      let messageContent = response.data.choices[0].message.content.trim();
      if (messageContent.startsWith("```json")) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith("```")) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      const data = JSON.parse(messageContent);
      setProgress(100);

      const formattedData = {
        ...data,
        events: data.events.map((event) => ({
          ...event,
          imageUrl: null,
        })),
      };

      setLessonData(formattedData);

      await new Promise((resolve) => setTimeout(resolve, 800));

      showSnackbar("Din lektion har genererats!", "success");

      setTimeout(() => {
        lessonTitleRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, 100);

      await trackLesson(user.uid, "TimelineLesson.js", null, {
        language: language,
        lessonTheme: lessonTheme,
      });
    } catch (error) {
      console.error("Error generating lesson:", error);
      showSnackbar(
        "Ett fel uppstod när lektionen skulle genereras. Försök igen!",
        "error",
      );
    } finally {
      setIsGeneratingLesson(false);
    }
  };

  /// Image Generation code

  const handleGenerateImages = async () => {
    if (!lessonData) return;
    setIsGeneratingImages(true);
    handleCloseModal();

    try {
      // Set loadingImageIndex to -1 to indicate all images are loading
      setLoadingImageIndex(-1);

      const imagePromises = lessonData.events.map((event, index) => {
        const imagePrompt = `Create an image that represents the  event: "${event.title}" - "${event.description}" from ${event.date}. ${imageInstructions}`;
        return generateAndProcessImage(imagePrompt, user.uid);
      });
      const results = await Promise.allSettled(imagePromises);

      setLessonData((prevData) => ({
        ...prevData,
        events: prevData.events.map((event, index) => ({
          ...event,
          imageUrl:
            results[index].status === "fulfilled"
              ? results[index].value
              : event.imageUrl,
        })),
      }));

      showSnackbar("Bilderna har genererats!", "success");
    } catch (error) {
      handleImageGenerationError(error, true, showSnackbar);
    } finally {
      setIsGeneratingImages(false);
      setLoadingImageIndex(null);
    }
  };

  const handleGenerateSpecificImage = async (index) => {
    setIsGeneratingImages(true);
    setLoadingImageIndex(index);
    handleCloseModal();

    try {
      const event = lessonData.events[index];
      const imagePrompt = `Create an image that represents the  event: "${event.title}" - "${event.description}" from ${event.date}. ${imageInstructions}`;
      const imageUrl = await generateAndProcessImage(imagePrompt, user.uid);

      if (imageUrl) {
        setLessonData((prevData) => {
          const newEvents = [...prevData.events];
          newEvents[index] = {
            ...newEvents[index],
            imageUrl: imageUrl,
          };
          return {
            ...prevData,
            events: newEvents,
          };
        });
        showSnackbar("Bilden har genererats!", "success");
      }
    } catch (error) {
      handleImageGenerationError(error, false, showSnackbar);
    } finally {
      setIsGeneratingImages(false);
      setLoadingImageIndex(null);
    }
  };

  const handleImageUpdate = (type, index, imageUrl) => {
    if (type === "event") {
      setLessonData((prevData) => {
        const newEvents = [...prevData.events];
        newEvents[index] = {
          ...newEvents[index],
          imageUrl: imageUrl,
        };
        return {
          ...prevData,
          events: newEvents,
        };
      });
    }
  };

  const handleImageButtonClick = (index = null) => {
    setSelectedImageIndex(index);
    setShowImageModal(true);
  };
  const handleCloseModal = () => {
    setShowImageModal(false);
    setImageInstructions("");
  };

  const handleGenerateWithInstructions = async () => {
    if (selectedImageIndex !== null) {
      handleCloseModal();
      await handleGenerateSpecificImage(selectedImageIndex);
    } else {
      handleCloseModal();
      await handleGenerateImages();
    }
  };

  return (
    <Box
      sx={{
        ...styles.container,
      }}
    >
      <Box
        sx={{
          ...styles.innerContainer,
          width: { xs: "100%", md: "100%" },
          margin: "0 auto",
        }}
      >
        {!viewMode && (
          <Typography
            variant="h1"
            gutterBottom
            sx={{
              fontWeight: "bold",
              fontSize: "1.8em",
              mb: 3,
              mt: 5,
              textAlign: "center",
            }}
          >
            Tidslinjer
          </Typography>
        )}

        <LessonDrawer
          userId={user.uid}
          lessonType={"TimelineLesson.js"}
          theme={theme}
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          lessonIcon={TimelineIcon}
          onSelectLesson={(lesson) => {
            setLessonData(lesson.data);
            setDrawerOpen(false);
            setTimeout(() => {
              lessonTitleRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "start", // This ensures it scrolls to the top of the element
              });
            }, 100);
            showSnackbar(`Lektionen har laddats in.`, "success");
          }}
        />

        <Box
          sx={{
            ...styles.buttonsContainer,
            ...styles.additionalButtonsContainer,
          }}
        >
          {lessonData && user.accountType === "teacher" && (
            <Button
              variant="purp"
              sx={{ ...styles.tightButton }}
              disableRipple
              onClick={() => {
                setTimeout(() => {
                  lessonTitleRef.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "start", // This ensures it scrolls to the top of the element
                  });
                }, 100);
                toggleViewMode();
              }}
              startIcon={
                searchParams.get("v") === "t" ? (
                  <FullscreenExitIcon />
                ) : (
                  <FullscreenIcon />
                )
              }
            >
              {searchParams.get("v") === "t" ? "Redigeringsläge" : "Elevläge"}
            </Button>
          )}
          {!viewMode && (
            <Button
              variant="purp"
              sx={{ ...styles.tightButton }}
              onClick={() => setDrawerOpen(true)}
              startIcon={<BookmarkBorderIcon />}
            >
              Sparat
            </Button>
          )}
        </Box>
        {!viewMode && (
          <>
            <InfoBox
              icon={TimelineIcon}
              title="Tidslinjer"
              instructions={[
                "Välj underlag för tidslinjen",
                "Specificera antal händelser och språk",
                "Redigera och skriv ut, eller spara i din egen lektionsbank",
              ]}
              theme={theme}
              styles={styles}
            />

            <ThemeSelectorFlow
              subject={subject}
              value={lessonTheme}
              onChange={(e) => setLessonTheme(e.target.value)}
              customTheme={customTheme}
              onCustomThemeChange={(e) => setCustomTheme(e.target.value)}
              onPredefinedThemeChange={(e) =>
                setPredefinedTheme(e.target.value)
              }
              onBookContentChange={(content) => {
                Logger.log("onBookContentChange", content.substring(0, 100));
                bookContentRef.current = content;
              }}
              onUrlContentChange={(content) => {
                Logger.log(
                  "Parent: URL content received",
                  content.substring(0, 300),
                );
                urlContentRef.current = content;
              }}
              onYoutubeTranscriptChange={(transcriptContent) => {
                Logger.log(
                  "onYoutubeTranscriptChange körs med: ",
                  transcriptContent,
                );
                transcriptRef.current = transcriptContent;
              }}
              onFileUpload={(content) => {
                Logger.log("onFileUpload körs med: ", content);
                uploadedFileRef.current = content;
              }}
              onWikipediaContentChange={(content) => {
                Logger.log(
                  "Parent: Wikipedia content received",
                  content.substring(0, 100),
                );
                wikipediaContentRef.current = content;
              }}
              onRandomizedContentChange={(content) => {
                Logger.log("randomizedContentRef", content.substring(0, 100));
                randomizedContentRef.current = content;
              }}
              onCoursePlanContentChange={(content) => {
                Logger.log("onCoursePlanChange", content.substring(0, 100));
                coursePlanContentRef.current = content;
              }}
              lessonType={"TimelineLesson.js"}
              user={user}
              theme={theme}
              styles={styles}
              onLessonSelect={(lesson) => {
                Logger.log("onLessonSelect körs");
                setLessonData(lesson.data);
                setTimeout(() => {
                  lessonTitleRef.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "start", // This ensures it scrolls to the top of the element
                  });
                }, 100);
                showSnackbar("Lektionen har laddats in.", "success");
              }}
              additionalThemes={additionalThemes}
            />

            <Box sx={styles.headlineLessonBox}>
              <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
                2. Generera lektionen
              </Typography>
            </Box>
            <Box sx={{ ...styles.freshBox, padding: "50px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} sx={{ display: "none" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "500", mb: 1 }}
                  >
                    Årskurs:
                  </Typography>
                  <Box sx={{ mt: 0.5, mb: 3 }}>
                    <GradeSelector grade={grade} setGrade={setGrade} />
                  </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle1" sx={{ fontWeight: "500" }}>
                    Antal händelser:
                  </Typography>
                  <Box sx={{ mt: 1, mb: 3 }}>
                    <FormControl fullWidth>
                      <Select
                        value={numberOfEvents}
                        onChange={(e) => setNumberOfEvents(e.target.value)}
                      >
                        <MenuItem value={5}>5 händelser</MenuItem>
                        <MenuItem value={10}>10 händelser</MenuItem>
                        <MenuItem value={15}>15 händelser</MenuItem>
                        <MenuItem value={20}>20 händelser</MenuItem>
                        <MenuItem value={25} disabled>
                          25 händelser
                        </MenuItem>
                        <MenuItem value={40} disabled>
                          40 händelser
                        </MenuItem>
                        <MenuItem value={50} disabled>
                          50 händelser
                        </MenuItem>
                        <MenuItem value={60} disabled>
                          60 händelser
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle1" sx={{ fontWeight: "500" }}>
                    Språk:
                  </Typography>
                  <Box sx={{ mt: 1, mb: 3 }}>
                    <LanguageSelector
                      language={language}
                      setLanguage={setLanguage}
                    />
                  </Box>
                </Grid>
              </Grid>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "12px",
                  width: "100%",
                }}
              >
                <Button
                  variant={
                    theme.palette.mode === "light" ? "purp" : "contained"
                  }
                  color="primary"
                  onClick={handleGenerateLesson}
                  disabled={isGeneratingLesson}
                  sx={{ mt: 4 }}
                  startIcon={
                    !isGeneratingLesson ? (
                      <GenerateIcon style={{ color: "#fff" }} />
                    ) : (
                      <CircularProgress size={16} />
                    )
                  }
                >
                  {isGeneratingLesson ? "Genererar..." : "Generera lektion"}
                </Button>
              </div>
              {isGeneratingLesson && (
                <Box sx={{ mt: 6 }}>
                  <ProgressBar
                    progress={progress}
                    label="Genererar lektion — tar 30-60 sekunder..."
                  />
                </Box>
              )}
            </Box>
          </>
        )}
        {lessonData && (
          <>
            {!viewMode && (
              <Box
                ref={lessonTitleRef}
                sx={{ ...styles.headlineLessonBox, mt: 10 }}
              >
                <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
                  3. Din lektion
                </Typography>
              </Box>
            )}
            <Box
              sx={{
                ...styles.freshBox,
                padding: "30px",

                width: viewMode ? "90%" : "100%",
                margin: "0 auto",
                mt: viewMode ? 12 : 0,
                ...(viewMode && { backgroundColor: "transparent" }),
                ...(viewMode && { borderColor: "transparent" }),
              }}
            >
              {!viewMode && (
                <Box sx={{ position: "relative" }}>
                  <Box
                    sx={{
                      ...styles.lessonControlPanel,
                      ...styles.lessonControlPanelNuevo,

                      "& .MuiButton-root": {
                        ...styles.controlPanelButtons,
                        ...styles.lessonControlPanelNewButtons,
                      },
                    }}
                  >
                    <Button
                      variant="purp"
                      startIcon={<EditIcon />}
                      onClick={() => setEditDialogOpen(true)}
                    >
                      Redigera tidslinje
                    </Button>
                    <Button
                      variant="print"
                      startIcon={<SaveAsIcon />}
                      sx={{ ml: 2 }}
                      onClick={handleSaveLessonClick}
                    >
                      Spara i min lektionsbank
                    </Button>

                    <Button
                      variant="print"
                      startIcon={<PrintIcon />}
                      onClick={() => handlePrintTimelineLesson(lessonData)}
                    >
                      Skriv ut
                    </Button>

                    <Button
                      variant="success"
                      sx={{}}
                      onClick={() => setShareDialogOpenFriends(true)}
                      startIcon={<ShareIcon />}
                    >
                      Dela med vänner
                    </Button>
                    <Button
                      variant="success"
                      sx={{
                        mt: 2,
                        ml: 2,
                      }}
                      onClick={() => setShareDialogOpen(true)}
                      startIcon={<ShareIcon />}
                    >
                      Dela med elever
                    </Button>

                    <ShareLink
                      buttonRef={shareLinkButtonRef}
                      key={lessonId}
                      lessonId={lessonId}
                      lessonData={lessonData}
                      user={user}
                      lessonType={"TimelineLesson.js"}
                      onCopy={(message, severity) =>
                        showSnackbar(message, severity)
                      }
                    />

                    <Translate
                      buttonRef={translateButtonRef}
                      lessonData={lessonData}
                      onTranslated={setLessonData}
                      callApi={callApi}
                      user={user}
                      showSnackbar={showSnackbar}
                    />
                    <PrintAsPdf
                      buttonRef={pdfButtonRef}
                      onPrint={() => handlePrintTimelineLesson(lessonData)}
                      sx={{ ml: 2 }}
                    />
                    <Tooltip title="Hur lektionen ser ut för eleverna">
                      <Button
                        variant="success"
                        onClick={() => {
                          window.scrollTo({ top: 0, behavior: "smooth" });
                          toggleViewMode();
                        }}
                        startIcon={
                          searchParams.get("v") === "t" ? (
                            <FullscreenExitIcon />
                          ) : (
                            <FullscreenIcon />
                          )
                        }
                      >
                        {searchParams.get("v") === "t"
                          ? "Redigeringsläge"
                          : "Elevläge"}
                      </Button>
                    </Tooltip>

                    <Box sx={{ display: "inline-block" }}>
                      <AddonQuiz
                        buttonRef={quizButtonRef}
                        lessonData={lessonData}
                        title={lessonData.title}
                        theme={theme}
                        user={user}
                        styles={styles}
                        showSnackbar={showSnackbar}
                        addQuizToLessonObject={(updatedData) => {
                          setLessonData(updatedData);
                          setTimeout(
                            () =>
                              quizSectionRef.current?.scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                              }),
                            100,
                          );
                        }}
                        buttonText="Lägg till interaktivt prov/quiz"
                      />
                    </Box>
                    <Box sx={{ display: "inline-block", ml: 0 }}>
                      <ReadingComprehensionDialog
                        buttonRef={comprehensionButtonRef}
                        key={lessonData?.title}
                        lessonData={lessonData}
                        user={user}
                        theme={theme}
                        styles={styles}
                        buttonText="Lägg till övningar"
                        addExercisesToLessonObject={(updateFunction) => {
                          setLessonData((prevLessonData) => ({
                            ...prevLessonData,
                            exercises: [
                              ...(prevLessonData.exercises || []),
                              ...updateFunction(prevLessonData).exercises,
                            ],
                          }));
                          setTimeout(
                            () =>
                              exercisesSectionRef.current?.scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                              }),
                            100,
                          );
                        }}
                      />
                    </Box>

                    <Box sx={{ display: "inline-block", ml: 0 }}>
                      <AddonTimeline
                        buttonRef={timelineButtonRef}
                        lessonData={lessonData}
                        theme={theme}
                        user={user}
                        styles={styles}
                        showSnackbar={showSnackbar}
                        addTimelineToLessonObject={(updatedData) => {
                          setLessonData(updatedData);
                          setTimeout(
                            () =>
                              timelineSectionRef.current?.scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                              }),
                            100,
                          );
                        }}
                        buttonText="Lägg till interaktiv tidslinje"
                      />
                    </Box>
                    <Box sx={{ display: "inline-block", ml: 0, mt: 0 }}>
                      <AddonTimelineSimple
                        buttonRef={timelineSimpleButtonRef}
                        lessonData={lessonData}
                        theme={theme}
                        user={user}
                        styles={styles}
                        showSnackbar={showSnackbar}
                        addTimelineToLessonObject={(updatedData) => {
                          setLessonData(updatedData);
                          setTimeout(
                            () =>
                              timelineSimpleSectionRef.current?.scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                              }),
                            100,
                          );
                        }}
                        buttonText="Lägg till enkel tidslinje"
                      />
                    </Box>

                    <Button
                      variant={
                        theme.palette.mode === "light" ? "purp" : "contained"
                      }
                      color="primary"
                      onClick={() => handleImageButtonClick(null)}
                      disabled={isGeneratingImages}
                      sx={{ ml: 2 }}
                      startIcon={
                        !isGeneratingImages ? (
                          <ImageIcon />
                        ) : (
                          <CircularProgress size={16} />
                        )
                      }
                    >
                      {isGeneratingImages ? "Genererar..." : "Generera bilder"}
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      ...styles.lessonControlPanelFade,
                    }}
                  />
                </Box>
              )}
              <Box
                sx={{
                  position: "relative",
                  mb: 10,
                  mt: viewMode ? 0 : 10,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="h4"
                  sx={{ fontWeight: "bold", textAlign: "center" }}
                >
                  {lessonData.title}
                </Typography>
              </Box>

              <CustomTimeline
                events={lessonData.events}
                userId={user.uid}
                onImageUpdate={handleImageUpdate}
                onImageClick={handleImageButtonClick}
                generatingItemIndex={loadingImageIndex}
                showSnackbar={showSnackbar}
                theme={theme}
                viewMode={viewMode}
                onEventUpdate={handleEventsUpdate}
              />

              <SaveLessonDialog
                key={lessonData?.title || ""}
                open={saveDialogOpen}
                onClose={() => setSaveDialogOpen(false)}
                onSave={handleSaveWithComment}
                theme={theme}
                lessonName={lessonData?.title || ""}
                lessonObject={lessonData}
                lessonType={"TimelineLesson.js"}
              />

              <ImageGenerationModal
                open={showImageModal}
                onClose={handleCloseModal}
                onGenerate={handleGenerateWithInstructions}
                isGenerating={isGeneratingImages}
                instructions={imageInstructions}
                setInstructions={setImageInstructions}
                theme={theme}
              />

              <TimeBuilderEditDialog
                open={editDialogOpen}
                onClose={() => setEditDialogOpen(false)}
                lessonData={{
                  title: lessonData.title,
                  description: lessonData.description,
                  timelines: [
                    {
                      title: lessonData.title,
                      description: lessonData.description,
                      timeline: lessonData.events.map((event) => ({
                        event: event.title,
                        year: event.date,
                        imageUrl: event.imageUrl,
                        description: event.description,
                      })),
                    },
                  ],
                }}
                onSave={(editedData) => {
                  setLessonData({
                    ...lessonData,
                    title: editedData.title,
                    title: editedData.timelines[0].title,
                    description: editedData.description,
                    events: editedData.timelines[0].timeline.map((event) => ({
                      title: event.event,
                      date: event.year,
                      imageUrl: event.imageUrl,
                      description: event.description,
                      category: "major",
                    })),
                  });
                }}
                isGeneratingImages={isGeneratingImages}
                loadingImageIndex={loadingImageIndex}
                onGenerateSpecificImage={handleGenerateSpecificImage}
                onGenerateAllImages={handleGenerateImages}
                user={user}
                theme={theme}
                showSnackbar={showSnackbar}
                handleImageButtonClick={handleImageButtonClick}
                fromLessonTimelineDisplay={true}
              />

              {lessonData?.exercises && (
                <Box ref={exercisesSectionRef}>
                  <LessonExercisesDisplay
                    exercises={lessonData.exercises}
                    onUpdateLessonData={setLessonData}
                    viewMode={viewMode}
                    theme={theme}
                    styles={styles}
                  />
                </Box>
              )}

              {lessonData?.quiz && (
                <Box
                  ref={quizSectionRef}
                  sx={{ mt: 5, mb: 5, position: "relative" }}
                >
                  <LessonQuizDisplay
                    quizData={lessonData.quiz}
                    theme={theme}
                    styles={styles}
                    userId={user.uid}
                    viewMode={viewMode}
                    setLessonData={setLessonData}
                  />
                </Box>
              )}

              {lessonData?.timelinesSimple && (
                <Box
                  ref={timelineSimpleSectionRef}
                  sx={{ mt: 5, mb: 5, position: "relative" }}
                >
                  <TimelineSimpleDisplay
                    timelineData={lessonData.timelinesSimple}
                    onTimelineDataChange={(newData) =>
                      setLessonData({
                        ...lessonData,
                        timelinesSimple: newData,
                      })
                    }
                    theme={theme}
                    styles={styles}
                    viewMode={viewMode}
                    user={user}
                  />
                </Box>
              )}

              {lessonData?.timelines && (
                <Box
                  ref={timelineSectionRef}
                  sx={{ mt: 5, mb: 5, position: "relative" }}
                >
                  <TimelineDisplay
                    timelineData={lessonData.timelines}
                    onTimelineDataChange={(newData) =>
                      setLessonData({ ...lessonData, timelines: newData })
                    }
                    theme={theme}
                    styles={styles}
                    withImages={true}
                    viewMode={viewMode}
                    user={user}
                    onDelete={
                      viewMode
                        ? undefined
                        : (indexToDelete) => {
                            setLessonData((prev) => ({
                              ...prev,
                              timelines: prev.timelines.filter(
                                (_, index) => index !== indexToDelete,
                              ),
                            }));
                            showSnackbar(
                              "Tidslinjen har tagits bort",
                              "success",
                            );
                          }
                    }
                  />
                </Box>
              )}

              {!viewMode && (
                <DashedPapers
                  handleQuizPaperClick={handleQuizPaperClick}
                  handleComprehensionPaperClick={handleComprehensionPaperClick}
                  handleTimelinePaperClick={handleTimelinePaperClick}
                  handleTimelineSimplePaperClick={
                    handleTimelineSimplePaperClick
                  }
                  quizSectionRef={quizSectionRef}
                  timelineSectionRef={timelineSectionRef}
                  timelineSimpleSectionRef={timelineSimpleSectionRef}
                  exercisesSectionRef={exercisesSectionRef}
                  lessonData={lessonData}
                  setLessonData={setLessonData}
                />
              )}

              <SlideInPaper
                isExpanded={isExpanded}
                onExpandedChange={setIsExpanded}
                lessonTitleRef={lessonTitleRef}
                viewMode={viewMode}
                onEdit={() => setEditDialogOpen(true)}
                toggleViewMode={toggleViewMode}
                theme={theme}
                open={showControlPanel}
                user={user}
                onPrint={() => handlePrintTimelineLesson(lessonData)}
                onTranslate={() => translateButtonRef.current?.click()}
                onSaveAsPdf={() => pdfButtonRef.current?.click()}
                onShareLink={() => shareLinkButtonRef.current?.click()}
                onShareWithStudents={() => setShareDialogOpen(true)}
                onShareWithFriends={() => setShareDialogOpenFriends(true)}
                onSaveToBank={() => setSaveDialogOpen(true)}
                onStudentMode={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                  toggleViewMode();
                }}
                onGenerateExercises={() => {
                  comprehensionButtonRef.current?.click();
                }}
                onGenerateQuiz={() => {
                  quizButtonRef.current?.click();
                }}
                onGenerateAssignments={() => {
                  comprehensionButtonRef.current?.click();
                }}
                onGenerateTimeline={() => {
                  timelineButtonRef.current?.click();
                }}
              />

              <ShareDialog
                open={shareDialogOpen}
                onClose={() => setShareDialogOpen(false)}
                onShare={handleShare}
                user={user}
                isLessonSharing={true}
                theme={theme}
                lessonName={lessonData?.title || ""}
                lessonObject={lessonData}
                lessonType={"TimelineLesson.js"}
              />

              <ShareDialog
                open={shareDialogOpenFriends}
                onClose={() => setShareDialogOpenFriends(false)}
                onShare={handleShare}
                user={user}
                isLessonSharing={true}
                theme={theme}
                lessonName={lessonData?.title || ""}
                lessonObject={lessonData}
                lessonType={"TimelineLesson.js"}
                shareWithFriends={true}
              />

              <LoadingBackdrop
                isOpen={isSharing}
                imagePath="/img/AI.png"
                loadingText={"Delar lektionen..."}
              />
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default TimelineLesson;
