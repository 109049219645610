import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Tabs,
  Tab,
  CircularProgress,
} from "@mui/material";
import {
  collection,
  query,
  where,
  orderBy,
  limit,
  getDocs,
} from "firebase/firestore";
import { db } from "./firebase";
import { useNavigate } from "react-router-dom";
import LessonCard from "./LessonCard";
import StarIcon from "@mui/icons-material/Star";
import ScrollableGrid from "./ScrollableGrid";
import {
  lessonMapping,
  getLessonTypeParam,
  lessonUrlMapping,
} from "./functions";
import { LessonsSkeleton } from "./TeacherSkeleton";
import { shortcuts } from "./DashboardToolsShortcuts";

const TabPanel = ({ children, value, index, loading, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`lesson-tabpanel-${index}`}
    aria-labelledby={`lesson-tab-${index}`}
    {...other}
  >
    {value === index && (
      <Box sx={{ p: 3 }}>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "200px",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          children
        )}
      </Box>
    )}
  </div>
);

// Helper to get original lesson type from mapped name
const getOriginalLessonType = (mappedType) => {
  const mappings = {
    "BasicReadingCompLesson.js": "Läsförståelse",
    "BasicReadingCompLessonVariantFactText.js": "Faktatext",
    "BasicReadingCompLessonVariantInstructingText.js": "Instruerande text",
    "BasicReadingCompLessonVariantExplainingText.js": "Förklarande text",
    "BasicReadingCompLessonVariantArgumentativeText.js": "Argumenterande text",
    "BasicReadingCompLessonVariantChronicle.js": "Krönika",
    "BasicReadingCompLessonVariantReview.js": "Recension",
    "TimelineLesson.js": "Tidslinjer",
    "NewsLesson.js": "Fiktiva nyhetsredaktionen",
    "DiaryLesson.js": "Fiktiva dagböcker",

    "LettersLesson.js": "Fiktiva brev",
    "HistoricPersonLesson.js": "Historisk person",

    "TimelineBuilderLesson.js": "Bygg tidslinjer",
    "RolePlayLesson.js": "Rollkort",
    "LessonPlanRevamp.js": "Lektionsplan",
    "EscapeRoomLesson.js": "Escape Room",
    "DetectiveReadingCompLesson.js": "Läsdetektiven",
    "CreativeReadingCompLesson.js": "Kreativ läsförståelse",
    "ComparativeReadingCompLesson.js": "Jämförande läsförståelse",
    "ClueGeneratorLesson.js": "På spåret",
    "TermsGridLesson.js": "Glosor i lådor",
    "WeeklyWordsLesson.js": "Veckans ord",
    "TongueTwistersLesson.js": "Tungvrickare",
    "GrammarLesson.js": "Grammatiklektion",
    "IdiomsLesson.js": "Idiomatiska uttr.",
    "MysticImageLesson.js": "Mystiska bilden",
    "FindWordsLesson.js": "Hitta orden",
    "LetterBoxLesson.js": "Bilda ordet",
    "WritingLesson.js": "Skrivlektion",
    "SentenceFinisherLesson.js": "Forts. berättelsen",
    "NarrativeLesson.js": "Berättande text",
    "GrejOfTheDay.js": "Grej of the day",
    "WordCompLesson.js": "Ordförståelse",
    "ConversationLesson.js": "Konversation",
    "ConversationLessonVariantInterview.js": "Intervju",
    "ArgumentationLesson.js": "Argumentera",
    "JeopardyLesson.js": "Jeopardy",
    "PowerPointGenerator.js": "Generera PowerPoint",
    "MindMapLesson.js": "Tankekartor",
    "LiteratureSchool.js": "Litteraturskolan",
  };

  return (
    Object.entries(mappings).find(
      ([key, value]) => value === mappedType,
    )?.[0] || mappedType
  );
};

const FeaturedLessons = ({ theme, styles, onlySelectedLessonType }) => {
  const [lessonsByTab, setLessonsByTab] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [tabTypes, setTabTypes] = useState([]);
  const navigate = useNavigate();
  const [loadingTab, setLoadingTab] = useState(null);

  const getLessonIcon = (type) => {
    const shortcut = shortcuts.find((s) => s.title === type);
    return shortcut ? shortcut.icon : null;
  };

  const preferredTabOrder = [
    "Jeopardy", // First tab
    "Litteraturskolan", // Fifth tab
    "Escape Room", // Fifth tab
    "Rollkort", // Third tab

    "Historisk person",

    "Fiktiva dagböcker",

    "Bygg tidslinjer",
    "Lektionsplan", // Fourth tab
    "Fiktiva nyhetsredaktionen", // Fourth tab
    "Fiktiva brev", // Fourth tab

    "Tidslinjer", // Fourth tab
    "Tankekartor", // Fourth tab
  ];

  // Load available lesson types first with custom ordering
  useEffect(() => {
    const loadLessonTypes = async () => {
      try {
        setIsLoading(true);
        const q = query(
          collection(db, "lesson_usage"),
          where("featured", "==", true),
          where("hasPrinted", "==", true),
          ...(onlySelectedLessonType
            ? [where("lessonType", "==", onlySelectedLessonType)]
            : []),
        );
        const snapshot = await getDocs(q);

        if (onlySelectedLessonType) {
          setTabTypes([lessonMapping(onlySelectedLessonType)]);
          await loadLessonsForTab(lessonMapping(onlySelectedLessonType), 0);
          return;
        }

        // Get all unique mapped lesson types
        let allTypes = [
          ...new Set(
            snapshot.docs
              .filter(
                (doc) =>
                  ![
                    "DetectiveReadingCompLesson.js",
                    "CreativeReadingCompLesson.js",
                    "ComparativeReadingCompLesson.js",
                    "WordCompLesson.js",
                    "ArgumentationLesson.js",
                  ].includes(doc.data().lessonType),
              )
              .map((doc) => lessonMapping(doc.data().lessonType)),
          ),
        ].filter(Boolean);

        // Organize types based on preferred order
        let orderedTypes = [];

        // First, add all preferred types that exist in allTypes
        preferredTabOrder.forEach((type) => {
          if (allTypes.includes(type)) {
            orderedTypes.push(type);
            allTypes = allTypes.filter((t) => t !== type);
          }
        });

        // Then add remaining types alphabetically
        orderedTypes = [
          ...orderedTypes,
          ...allTypes.sort((a, b) => a.localeCompare(b)),
        ];

        setTabTypes(orderedTypes);
        // Load lessons for first tab
        await loadLessonsForTab(orderedTypes[0], 0);
      } catch (error) {
        console.error("Error loading lesson types:", error);
      } finally {
        setIsLoading(false);
      }
    };

    loadLessonTypes();
  }, []);

  const loadLessonsForTab = async (lessonType, tabIndex) => {
    // If we already have lessons for this tab, don't reload
    if (lessonsByTab[lessonType]) {
      return;
    }

    setIsLoading(true);
    try {
      const originalType = getOriginalLessonType(lessonType);

      const q = query(
        collection(db, "lesson_usage"),
        where("featured", "==", true),
        where("hasPrinted", "==", true),
        where("lessonType", "==", originalType),
        orderBy("timestamp", "desc"),
        limit(6),
      );

      const querySnapshot = await getDocs(q);
      const lessonData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        timestamp: doc.data().timestamp?.toDate(),
      }));

      setLessonsByTab((prev) => ({
        ...prev,
        [lessonType]: lessonData,
      }));
    } catch (error) {
      console.error(`Error loading lessons for ${lessonType}:`, error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTabChange = async (event, newValue) => {
    setSelectedTab(newValue);
    const selectedType = tabTypes[newValue];

    if (!lessonsByTab[selectedType]) {
      setLoadingTab(newValue);
      await loadLessonsForTab(selectedType, newValue);
      setLoadingTab(null);
    }
  };

  const handleLessonClick = (lesson) => {
    const shortId = lesson.id.substring(0, 10);
    const typeParam = getLessonTypeParam(lesson.lessonType);
    const basePath =
      lesson.lessonType !== "PowerPointGenerator.js" ? "/lektioner" : "";
    const queryString =
      typeParam === "default" ? "?v=t" : `?type=${typeParam}&v=t`;
    navigate(
      `/app${basePath}/${lessonUrlMapping(lesson.lessonType)}/${shortId}${queryString}`,
    );
  };

  return (
    <Box sx={{ width: "100%" }}>
      {!onlySelectedLessonType && (
        <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 2 }}>
          <StarIcon
            sx={{
              color: theme.palette.mode === "dark" ? "gold" : "#FFD700",
              fontSize: "2rem",
              display: "none",
            }}
          />
          <Typography
            variant="h2"
            sx={{
              fontWeight: "600",
              fontSize: "23px",
              textAlign: "center",
              width: "100%",
            }}
          >
            Exempel på vad våra användare har genererat
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          ...styles.newBox,
          padding: "0px",
          width: "100%",

          ...(onlySelectedLessonType && { backgroundColor: "transparent" }),
          ...(onlySelectedLessonType && { borderColor: "transparent" }),
        }}
      >
        {isLoading && selectedTab === 0 ? (
          <LessonsSkeleton theme={theme} tabCount={7} />
        ) : tabTypes.length > 0 ? (
          <>
            {!onlySelectedLessonType && (
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  background:
                    theme.palette.mode === "dark" ? "#22303c" : "#f9f9fa",
                  borderTopLeftRadius: "9px",
                  borderTopRightRadius: "9px",
                }}
              >
                <Tabs
                  value={selectedTab}
                  onChange={handleTabChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="lesson type tabs"
                  sx={{
                    "& .MuiTab-root": {
                      textTransform: "none",
                      minWidth: 120,
                      fontSize: "0.9rem",
                    },
                  }}
                >
                  {tabTypes.map((type, index) => (
                    <Tab
                      key={type}
                      label={type}
                      id={`lesson-tab-${index}`}
                      aria-controls={`lesson-tabpanel-${index}`}
                      icon={getLessonIcon(type)}
                      iconPosition="start"
                      sx={{
                        padding: "20px",
                        textTransform: "none",
                        fontSize: "1rem",
                        "& .MuiSvgIcon-root": {
                          fontSize: "20px", // or any size you want
                        },
                      }}
                    />
                  ))}
                </Tabs>
              </Box>
            )}
            {tabTypes.map((type, index) => (
              <TabPanel
                key={type}
                value={selectedTab}
                index={index}
                loading={loadingTab === index}
              >
                <Box sx={{ padding: "0px", paddingTop: "20px" }}>
                  <ScrollableGrid>
                    {lessonsByTab[type]?.map((lesson) => (
                      <Box
                        key={lesson.id}
                        sx={{
                          minWidth: { xs: "100%", sm: "300px", md: "344px" },
                          maxWidth: { xs: "100%", sm: "300px", md: "344px" },
                        }}
                      >
                        <Box
                          component="a"
                          href={`/app${lesson.lessonType !== "PowerPointGenerator.js" ? "/lektioner" : ""}/${lessonUrlMapping(lesson.lessonType)}/${lesson.id.substring(0, 10)}${getLessonTypeParam(lesson.lessonType) === "default" ? "?v=t" : `?type=${getLessonTypeParam(lesson.lessonType)}&v=t`}`}
                          onClick={(e) => {
                            e.preventDefault();
                            handleLessonClick(lesson);
                          }}
                          sx={{
                            cursor: "pointer",
                            textDecoration: "none",
                            "&:hover": {
                              opacity: 0.8,
                            },
                          }}
                        >
                          <LessonCard
                            name={lesson.lessonName}
                            comment={lesson.comment}
                            timestamp={lesson.timestamp}
                            lessonType={lesson.lessonType}
                            imageUrl={lesson.coverImage}
                            size="small"
                            centered={true}
                            openInNewTab={false}
                          />
                        </Box>
                      </Box>
                    ))}
                  </ScrollableGrid>
                </Box>
              </TabPanel>
            ))}
          </>
        ) : (
          <Box sx={{ textAlign: "center", mt: 4 }}>
            <Typography variant="body1" color="text.secondary">
              Inga utvalda lektioner just nu
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default FeaturedLessons;
