import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Typography,
  IconButton,
  Chip,
  Avatar,
  Button,
  Tooltip,
  CircularProgress,
  Tabs,
  Menu,
  Divider,
  MenuItem,
  Tab,
  alpha,
  darken,
} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DeleteIcon from "@mui/icons-material/Delete";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import AddLinkIcon from "@mui/icons-material/AddLink";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import EditIcon from "@mui/icons-material/Edit";
import AttachFileOffIcon from "@mui/icons-material/AttachFile";
import ShareDialog from "./ShareDialog";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import AttachmentIcon from "@mui/icons-material/Attachment";
import AddIcon from "@mui/icons-material/Add";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CircleIcon from "@mui/icons-material/Circle";
import SchoolIcon from "@mui/icons-material/School";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import LoadingBackdrop from "./LoadingBackdrop";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import { useSnackbar } from "./SnackbarHandler";
import { Link } from "react-router-dom";
import Logger from "./Logger";
import { useNavigate } from "react-router-dom";

import {
  collection,
  getDocs,
  deleteDoc,
  doc,
  serverTimestamp,
  query,
  addDoc,
  where,
  documentId,
  updateDoc,
} from "firebase/firestore";
import { db } from "./firebase";
import { formatDate, createUrlSlug } from "./functions";

const MyFiles = ({ user, theme, styles, showSharedFiles }) => {
  const [files, setFiles] = useState([]);
  const [loadingFiles, setLoadingFiles] = useState(false);
  const [currentTab, setCurrentTab] = useState(1);
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [shareDialogOpenFriends, setShareDialogOpenFriends] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);
  const [userDetails, setUserDetails] = useState({});
  const [isSharing, setIsSharing] = useState(false);

  const [shareDialogOpen, setShareDialogOpen] = useState(false);

  useEffect(() => {
    fetchUserFiles();
  }, [user.uid]);

  const getTabType = (currentTab, isStudent) => {
    if (!isStudent) {
      return currentTab;
    }
    return currentTab >= 1 ? 2 : 1; // Changed to return 1 as base since tab 0 is hidden
  };

  const tabType = getTabType(currentTab, user.accountType === "student");

  const handleShare = async ({
    roomId,
    studentIds,
    comment,
    fileUrl,
    fileName,
    fileSize,
    fileType,
    fileFormattedSize,
  }) => {
    setIsSharing(true);

    await new Promise((resolve) => setTimeout(resolve, 1500));
    // try {
    //   // Create a document for each student
    //   const sharePromises = studentIds.map((studentId) => {
    //     const documentData = {
    //       sharedTo: studentId,
    //       comment: comment,
    //       fileUrl: fileUrl,
    //       fileName: fileName,
    //       fileSize: fileSize,
    //       fileType: fileType,
    //       fileFormattedSize: fileFormattedSize,
    //       shared: true,
    //       userId: user.uid,
    //       createdAt: new Date(),
    //     };

    //     Logger.log(
    //       "Adding document for student",
    //       studentId,
    //       ":",
    //       JSON.stringify(documentData, null, 2),
    //     );

    //     return addDoc(collection(db, "files"), documentData);
    //   });

    //   await Promise.all(sharePromises);

    //   await fetchUserFiles();

    //   showSnackbar(`Du har nu delat filen med eleverna!`, "success");
    // }

    try {
      // Create a document for each student
      const sharePromises = studentIds.map(async (studentId) => {
        const documentData = {
          sharedTo: studentId,
          comment: comment,
          fileUrl: fileUrl,
          fileName: fileName,
          fileSize: fileSize,
          fileType: fileType,
          fileFormattedSize: fileFormattedSize,
          shared: true,
          userId: user.uid,
          createdAt: new Date(),
        };

        // Create the file document and get its ID
        const fileDoc = await addDoc(collection(db, "files"), documentData);
        Logger.log(
          "Adding document for student",
          studentId,
          ":",
          JSON.stringify(documentData, null, 2),
        );

        // Create notification with the file doc ID
        await addDoc(collection(db, "notifications"), {
          type: "file_shared",
          recipientId: studentId,
          senderId: user.uid,
          read: false,
          createdAt: serverTimestamp(),
          fileData: {
            fileId: fileDoc.id,
            fileName: documentData.fileName,
            fileUrl: documentData.fileUrl,
            comment: documentData.comment,
            fileType: documentData.fileType,
            fileSize: documentData.fileFormattedSize,
          },
        });
        return fileDoc;
      });

      await Promise.all(sharePromises);
      await fetchUserFiles();
      showSnackbar(`Du har nu delat filen!`, "success");
    } catch (error) {
      showSnackbar("Fel vid delande av fil: " + error.message, "error");
    } finally {
      setIsSharing(false);
    }
  };

  const getSharedUndownloadedCount = (files) => {
    return files.filter(
      (file) => file.shared && file.userId === user.uid && !file.downloaded,
    ).length;
  };

  const loadUserDetails = async (userIds) => {
    const uniqueUserIds = [...new Set(userIds)];
    const usersSnapshot = await getDocs(
      query(collection(db, "users"), where(documentId(), "in", uniqueUserIds)),
    );

    const userMap = {};
    usersSnapshot.docs.forEach((doc) => {
      userMap[doc.id] = {
        displayName: doc.data().displayName,
        photoURL: doc.data().photoURL,
      };
    });
    setUserDetails(userMap);
  };

  const handleEditClick = () => {
    showSnackbar("Vi jobbar på denna funktion. Den är snart klar! :)", "info");
  };

  const handleMenuOpen = (event, file) => {
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
    setSelectedFile(file);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    setSelectedFile(null);
  };

  const fetchUserFiles = async () => {
    setLoadingFiles(true);
    try {
      // Split into two queries for proper filtering
      const sharedQuerySnapshot = await getDocs(
        query(
          collection(db, "files"),

          where("sharedTo", "==", user.uid),
        ),
      );

      const regularQuerySnapshot = await getDocs(
        query(collection(db, "files"), where("userId", "==", user.uid)),
      );

      // Get files shared BY you
      const sharedByYou = regularQuerySnapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .filter((doc) => doc.shared && doc.userId === user.uid)
        .sort((a, b) => (b.createdAt || 0) - (a.createdAt || 0));

      // Get regular (non-shared) files
      const regularFiles = regularQuerySnapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .filter((doc) => !doc.shared && doc.userId === user.uid)
        .sort((a, b) => (b.createdAt || 0) - (a.createdAt || 0));

      // Get files shared TO you
      const sharedToYou = sharedQuerySnapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .filter(
          (doc) => doc.userId !== user.uid && doc.sharedTo?.includes(user.uid),
        )
        .sort((a, b) => (b.createdAt || 0) - (a.createdAt || 0));

      const allFiles = [...sharedByYou, ...regularFiles, ...sharedToYou];
      setFiles(allFiles);

      // In fetchUserFiles, update the userIds collection part:
      const sharedUserIds = allFiles.flatMap((file) => {
        const ids = [];
        if (file.shared && file.userId) {
          ids.push(file.userId); // Add the teacher's ID
        }
        if (file.shared && file.userId === user.uid && file.sharedTo) {
          ids.push(file.sharedTo); // Add the student's ID
        }
        return ids;
      });

      if (sharedUserIds.length > 0) {
        await loadUserDetails([...new Set(sharedUserIds)]); // Remove duplicates
      }
    } catch (error) {
      console.error("Error fetching user files:", error);
    } finally {
      setLoadingFiles(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const getFilteredFiles = () => {
    switch (tabType) {
      case 0: // Regular files
        return files.filter((file) => !file.shared);
      case 1: // Shared with students
        return files.filter((file) => file.shared && file.userId === user.uid);
      case 2: // Shared by teachers
        return files.filter((file) => file.shared && file.userId !== user.uid);
      default:
        return [];
    }
  };

  const getUndownloadedCount = (files) => {
    return files.filter(
      (file) => file.shared && file.userId !== user.uid && !file.downloaded,
    ).length;
  };

  const handleDownload = async (url, fileId, e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      const fileName = url.substring(url.lastIndexOf("/") + 1).split("?")[0];
      link.setAttribute("download", decodeURIComponent(fileName));
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);

      // Update the downloaded status in Firestore
      await updateDoc(doc(db, "files", fileId), {
        downloaded: true,
        downloadedAt: Date.now(),
      });

      // Update local state
      setFiles((prevFiles) =>
        prevFiles.map((file) =>
          file.id === fileId ? { ...file, downloaded: true } : file,
        ),
      );
    } catch (error) {
      console.error("Download failed:", error);
      showSnackbar("Kunde inte ladda ner filen", "error");
    }
  };

  const handleDeleteFile = async (fileId, event) => {
    if (event) {
      event.stopPropagation();
    }
    setFiles((prevFiles) => prevFiles.filter((file) => file.id !== fileId));
    try {
      await deleteDoc(doc(db, "files", fileId));
    } catch (error) {
      console.error("Error deleting file:", error);
      fetchUserFiles();
    }
  };

  const filteredFiles = getFilteredFiles();

  return (
    <Box sx={styles.container}>
      <Box sx={{ ...styles.innerContainer }}>
        <Box sx={styles.headlineLessonBox}>
          <Typography
            variant="h6"
            sx={{
              ...styles.headlineLessonBoxTitle,
              mt: 6,
              textAlign: "left",
            }}
          >
            Mina filer
          </Typography>
        </Box>
        <ShareDialog
          open={shareDialogOpen}
          onClose={() => setShareDialogOpen(false)}
          onShare={handleShare}
          user={user}
        />
        <ShareDialog
          open={shareDialogOpenFriends}
          onClose={() => setShareDialogOpenFriends(false)}
          onShare={handleShare}
          user={user}
          theme={theme}
          shareWithFriends={true}
        />

        <Box
          sx={{
            ...styles.buttonsContainer,
            ...styles.additionalButtonsContainer,
          }}
        >
          {user.accountType !== "student" && (
            <>
              <Button
                variant="purp"
                onClick={() => setShareDialogOpen(true)}
                startIcon={<AddLinkIcon />}
              >
                Dela fil med elever
              </Button>

              <Button
                variant="success"
                onClick={() => setShareDialogOpenFriends(true)}
                startIcon={<AddLinkIcon />}
                sx={{ ml: 1 }}
              >
                Dela fil med vänner
              </Button>
            </>
          )}
        </Box>
        <Box sx={{ ...styles.freshBox, padding: "0px" }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              background: theme.palette.mode === "dark" ? "#22303c" : "#f9f9fa",
              borderTopLeftRadius: "7px",
              borderTopRightRadius: "7px",
            }}
          >
            <Tabs
              value={currentTab}
              onChange={handleTabChange}
              sx={{
                "& .MuiTab-root": {
                  textTransform: "none",
                  minWidth: 120,
                  fontSize: "0.9rem",
                },
              }}
            >
              <Tab
                label="Mina filer"
                icon={<InsertDriveFileIcon sx={{ fontSize: 20 }} />}
                iconPosition="start"
                sx={{ display: "none" }}
              />
              {user.accountType !== "student" && (
                <Tab
                  label={
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      Delat till elever från mig
                      {getSharedUndownloadedCount(files) > 0 && (
                        <Tooltip
                          title={`${getSharedUndownloadedCount(files)} elever som inte laddat hem filen än`}
                          placement="right"
                        >
                          <Chip
                            size="small"
                            label={getSharedUndownloadedCount(files)}
                            sx={{
                              ml: 1,
                              height: 20,
                              minWidth: 20,
                              fontSize: "0.75rem",
                              backgroundColor: theme.palette.primary.main,
                              color: theme.palette.primary.contrastText,
                            }}
                          />
                        </Tooltip>
                      )}
                    </Box>
                  }
                  icon={<PeopleOutlineIcon sx={{ fontSize: 20 }} />}
                  iconPosition="start"
                />
              )}
              <Tab
                label={
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    Delat från lärare till mig
                    {getUndownloadedCount(files) > 0 && (
                      <Tooltip
                        title={`${getUndownloadedCount(files)} filer du inte laddat ned än`}
                        placement="right"
                      >
                        <Chip
                          size="small"
                          label={getUndownloadedCount(files)}
                          sx={{
                            ml: 1,
                            height: 20,
                            minWidth: 20,
                            fontSize: "0.75rem",
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.primary.contrastText,
                          }}
                        />
                      </Tooltip>
                    )}
                  </Box>
                }
                icon={<SchoolIcon sx={{ fontSize: 20 }} />}
                iconPosition="start"
              />
            </Tabs>
          </Box>

          {loadingFiles ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "200px",
                p: 4,
                bgcolor: alpha(theme.palette.background.paper, 0.6),
              }}
            >
              <CircularProgress />
            </Box>
          ) : filteredFiles.length === 0 ? (
            <Box
              sx={{
                p: 4,
                textAlign: "center",
                color: "text.secondary",
                bgcolor: alpha(theme.palette.background.paper, 0.6),
                borderRadius: 2,
              }}
            >
              {tabType === 0
                ? "De filer du laddat upp hamnar här."
                : tabType === 1
                  ? "Du har inte delat några filer med dina elever än."
                  : "Inga filer har delats med dig av lärare än."}
            </Box>
          ) : (
            <Box sx={{ padding: "0px", paddingTop: "60px" }}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Filnamn</TableCell>
                      {tabType === 2 && <TableCell>Delad från</TableCell>}
                      {tabType === 1 && <TableCell>Delat med</TableCell>}
                      <TableCell>Status</TableCell>
                      <TableCell>Uppladdad</TableCell>
                      <TableCell>Storlek</TableCell>
                      <TableCell>Typ</TableCell>
                      <TableCell align="center">Ladda ned</TableCell>
                      <TableCell align="center">
                        <Tooltip title="Kommentar från lärare">
                          <MailOutlineIcon
                            sx={{
                              fontSize: 20,
                              mt: 1,
                              color: theme.palette.text.secondary,
                            }}
                          />
                        </Tooltip>
                      </TableCell>
                      {user.accountType !== "student" && (
                        <TableCell align="right">Hantera</TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredFiles.map((file) => (
                      <TableRow
                        key={file.id}
                        sx={{
                          "&:hover": { backgroundColor: "action.hover" },
                        }}
                      >
                        <TableCell>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 2,
                            }}
                          >
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: 24,
                                color: theme.palette.primary.main,
                              }}
                            />
                            <Typography
                              sx={{ fontWeight: 400, fontSize: "0.9rem" }}
                            >
                              {file.fileName}
                            </Typography>
                          </Box>
                        </TableCell>
                        {tabType === 2 && (
                          <TableCell>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Tooltip
                                title={
                                  userDetails[file.userId]?.displayName ||
                                  "Loading..."
                                }
                              >
                                <Avatar
                                  src={userDetails[file.userId]?.photoURL}
                                  alt={userDetails[file.userId]?.displayName}
                                  sx={{
                                    width: 22,
                                    height: 22,
                                    fontSize: "0.775rem",
                                  }}
                                >
                                  {userDetails[file.userId]?.displayName?.[0] ||
                                    "?"}
                                </Avatar>
                              </Tooltip>
                              <Typography variant="body2" sx={{ ml: 1 }}>
                                {userDetails[file.userId]?.displayName ||
                                  "Loading..."}
                              </Typography>
                            </Box>
                          </TableCell>
                        )}
                        {tabType === 1 && (
                          <TableCell>
                            {file.shared &&
                            file.userId === user.uid &&
                            file.sharedTo ? (
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Tooltip
                                  title={
                                    userDetails[file.sharedTo]?.displayName ||
                                    "Loading..."
                                  }
                                >
                                  <Avatar
                                    src={userDetails[file.sharedTo]?.photoURL}
                                    alt={
                                      userDetails[file.sharedTo]?.displayName
                                    }
                                    sx={{
                                      width: 22,
                                      height: 22,
                                      fontSize: "0.775rem",
                                    }}
                                  >
                                    {userDetails[file.sharedTo]
                                      ?.displayName?.[0] || "?"}
                                  </Avatar>
                                </Tooltip>
                                <Typography variant="body2" sx={{ ml: 1 }}>
                                  {userDetails[file.sharedTo]?.displayName ||
                                    "Loading..."}
                                </Typography>
                              </Box>
                            ) : (
                              "-"
                            )}
                          </TableCell>
                        )}

                        <TableCell>
                          <Tooltip
                            title={
                              file.downloaded
                                ? `Nedladdad ${new Date(
                                    file.downloadedAt,
                                  ).toLocaleString("sv-SE", {
                                    year: "numeric",
                                    month: "2-digit",
                                    day: "2-digit",
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  })}`
                                : "Ej nedladdad än"
                            }
                            arrow
                          >
                            <Chip
                              label={
                                file.downloaded ? "Nedladdad" : "Ej nedladdad"
                              }
                              size="small"
                              icon={
                                file.downloaded ? (
                                  <CheckCircleOutlineIcon />
                                ) : (
                                  <HighlightOffIcon />
                                )
                              }
                              sx={{
                                height: "24px",
                                backgroundColor: file.downloaded
                                  ? "#27792B"
                                  : "#FF4D4D",
                                color: "white",
                                fontSize: "11px",
                                fontWeight: 500,
                                "& .MuiChip-icon": {
                                  fontSize: 14,
                                  color: "inherit",
                                },
                              }}
                            />
                          </Tooltip>
                        </TableCell>

                        <TableCell>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                            }}
                          >
                            <AccessTimeIcon sx={{ fontSize: 16 }} />
                            <Typography variant="body2">
                              {formatDate(file.createdAt)}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">
                            {(file.fileSize / 1024).toFixed(1)} KB
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Chip
                            label={file.fileType}
                            size="small"
                            sx={{
                              height: "24px",
                              fontSize: "0.75rem",
                            }}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <IconButton
                            onClick={(e) =>
                              handleDownload(file.fileUrl, file.id, e)
                            }
                            size="small"
                            sx={{ mr: 1 }}
                          >
                            <AttachFileOffIcon sx={{ color: "primary.main" }} />
                          </IconButton>
                        </TableCell>

                        <TableCell align="center">
                          {file.comment ? (
                            <Tooltip title={file.comment}>
                              <MailOutlineIcon
                                sx={{
                                  fontSize: 20,
                                  color: theme.palette.text.secondary,
                                }}
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip title="Ingen kommentar från lärare">
                              <MailOutlineIcon
                                sx={{
                                  fontSize: 20,
                                  color: theme.palette.text.disabled,
                                }}
                              />
                            </Tooltip>
                          )}
                        </TableCell>
                        {user.accountType !== "student" && (
                          <TableCell align="right">
                            <IconButton
                              onClick={(event) => handleMenuOpen(event, file)}
                              size="small"
                            >
                              <MoreHorizIcon />
                            </IconButton>
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <Menu
                anchorEl={menuAnchorEl}
                open={Boolean(menuAnchorEl)}
                onClose={handleMenuClose}
                PaperProps={{
                  sx: { borderRadius: "8px", minWidth: "160px" },
                }}
              >
                <MenuItem
                  sx={styles.menuItemStyle}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteFile(selectedFile?.id);
                    handleMenuClose();
                  }}
                >
                  <DeleteIcon
                    fontSize="small"
                    sx={{ mr: 1, color: "#F44336" }}
                  />
                  Ta bort fil
                </MenuItem>
              </Menu>
            </Box>
          )}
        </Box>
      </Box>
      <LoadingBackdrop
        isOpen={isSharing}
        imagePath="/img/AI.png"
        loadingText={"Delar fil..."}
      />
    </Box>
  );
};

export default MyFiles;
