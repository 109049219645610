import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Typography,
  Breadcrumbs,
  Grid,
  Card,
  CardContent,
  Chip,
  useTheme,
} from "@mui/material";
import {
  MenuBook,
  Science,
  Language,
  Psychology,
  SportsHandball,
  Calculate,
  History,
  Architecture,
  Church,
  Translate,
  Public,
  MusicNote,
  BiotechOutlined,
  Science as Chemistry,
  Brush,
  EmojiObjects,
  School,
} from "@mui/icons-material";
import { useNavigate, Link } from "react-router-dom";
import HandymanIcon from "@mui/icons-material/Handyman";
import ShortcutBox from "./ShortcutBox";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Palette as PaletteIcon } from "@mui/icons-material";
import { Abc as AbcIcon } from "@mui/icons-material";

import { subjects } from "./ThemeSelector";

import ThemeFlowBox from "./ThemeFlowBox";
import { getStyles } from "../index";
import { shortcuts } from "./DashboardToolsShortcuts";
import {
  doc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  getDoc,
  onSnapshot,
} from "firebase/firestore";

import { db } from "./firebase";
const TeacherShortcuts = ({ user }) => {
  const [selectedCategory, setSelectedCategory] = useState("Svenska");
  const theme = useTheme();
  const styles = getStyles(theme);
  const navigate = useNavigate();
  const [isShimmering, setIsShimmering] = useState(false);

  const [favoriteShortcuts, setFavoriteShortcuts] = useState([]);

  useEffect(() => {
    if (!user?.uid) return;

    const userRef = doc(db, "users", user.uid);

    const unsubscribe = onSnapshot(
      userRef,
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          // favoriteShortcuts is now an array of objects: [{id: string, subject: string}]
          setFavoriteShortcuts(data.favoriteShortcuts || []);
        }
      },
      (error) => {
        console.error("Error listening to favorites:", error);
      },
    );

    return () => unsubscribe();
  }, [user]);

  const getShortcutsBySubject = (subject) => {
    return shortcuts
      .filter(
        (shortcut) =>
          shortcut.belongsTo &&
          shortcut.belongsTo
            .split(",")
            .map((s) => s.trim())
            .includes(subject),
      )
      .map((shortcut) => ({
        ...shortcut,
        path: `${shortcut.path}${shortcut.path.includes("?") ? "&" : "?"}subject=${encodeURIComponent(subject.toLowerCase())}`,
      }));
  };

  const shortcuts2 = {
    Svenska: {
      clickable: true,
      icon: MenuBook,
      content: {
        Svenska: [...getShortcutsBySubject("Svenska")],
      },
    },
    Biologi: {
      clickable: true,
      icon: BiotechOutlined,
      content: {
        Biologi: [...getShortcutsBySubject("Biologi")],
      },
    },

    Matematik: {
      clickable: true,
      icon: Calculate,
      content: {
        Matematik: [...getShortcutsBySubject("Matematik")],
      },
    },
    NO: {
      clickable: true,
      icon: Science,
      content: {
        NO: [...getShortcutsBySubject("NO")],
      },
    },
    SO: {
      clickable: true,
      icon: Public,
      content: {
        SO: [...getShortcutsBySubject("SO")],
      },
    },
    Historia: {
      clickable: true,
      icon: History,
      content: {
        Historia: [...getShortcutsBySubject("Historia")],
      },
    },
    Kemi: {
      clickable: true,
      icon: Chemistry,
      content: {
        Kemi: [...getShortcutsBySubject("Kemi")],
      },
    },
    Engelska: {
      clickable: true,
      icon: Language,
      content: {
        Engelska: [...getShortcutsBySubject("Engelska")],
      },
    },
    Geografi: {
      clickable: true,
      icon: Public,
      content: {
        Geografi: [...getShortcutsBySubject("Geografi")],
      },
    },
    Teknik: {
      clickable: true,
      icon: Architecture,
      content: {
        Teknik: [...getShortcutsBySubject("Teknik")],
      },
    },
    Religion: {
      clickable: true,
      icon: Church,
      content: {
        Religion: [...getShortcutsBySubject("Religion")],
      },
    },
    Modersmål: {
      clickable: true,
      icon: School,
      content: {
        Modersmål: [...getShortcutsBySubject("Modersmål")],
      },
    },
    "Moderna språk": {
      clickable: true,
      icon: Translate,
      content: {
        "Moderna språk": [...getShortcutsBySubject("Moderna språk")],
      },
    },
    Samhällskunskap: {
      clickable: true,
      icon: Psychology,
      content: {
        Samhällskunskap: [...getShortcutsBySubject("Samhällskunskap")],
      },
    },
    Spanska: {
      clickable: true,
      icon: Language,
      content: {
        Spanska: [...getShortcutsBySubject("Spanska")],
      },
    },
    Franska: {
      clickable: true,
      icon: Language,
      content: {
        Franska: [...getShortcutsBySubject("Franska")],
      },
    },
    Tyska: {
      clickable: true,
      icon: Language,
      content: {
        Tyska: [...getShortcutsBySubject("Tyska")],
      },
    },
    Musik: {
      clickable: true,
      icon: MusicNote,
      content: {
        Musik: [...getShortcutsBySubject("Musik")],
      },
    },
    "Idrott och hälsa": {
      clickable: true,
      icon: SportsHandball,
      content: {
        "Idrott och hälsa": [...getShortcutsBySubject("Idrott och hälsa")],
      },
    },
    "Trä och metallslöjd": {
      clickable: true,
      icon: HandymanIcon,
      content: {
        "Trä och metallslöjd": [
          ...getShortcutsBySubject("Trä och metallslöjd"),
        ],
      },
    },
    Fysik: {
      clickable: true,
      icon: Calculate,
      content: {
        Fysik: [...getShortcutsBySubject("Fysik")],
      },
    },
  };

  return (
    <Box>
      <Box sx={{ ...styles.newBox, padding: "20px", width: "100%" }}>
        {/* Subject Selection Row */}

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 1,
            mb: 7,
          }}
        >
          {Object.entries(shortcuts2).map(([category, categoryData]) => (
            <Chip
              key={category}
              icon={<categoryData.icon sx={{ fontSize: "17px" }} />}
              label={category}
              sx={{
                fontWeight: "500",
                padding: "7px",
                paddingTop: "12px",
                paddingBottom: "12px",
                fontSize: "0.8rem",
              }}
              onClick={() => {
                if (categoryData.clickable && category !== selectedCategory) {
                  setIsShimmering(true);
                  setSelectedCategory(category);
                  setTimeout(() => setIsShimmering(false), 1000);
                }
              }}
              color={selectedCategory === category ? "primary" : "default"}
            />
          ))}
        </Box>

        {/* Shortcuts Display */}
        {selectedCategory && (
          <Box
            sx={{
              position: "relative",
              overflow: "hidden",
              "&::after": isShimmering
                ? {
                    content: '""',
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    animation: "colorShimmer 0.6s ease-in-out",
                    background: (theme) =>
                      `linear-gradient(90deg, transparent, white, transparent)`,
                    transform: "translateX(-100%)",
                    "@keyframes colorShimmer": {
                      "0%": { transform: "translateX(-100%)", opacity: 0 },
                      "50%": { opacity: 1 },
                      "100%": { transform: "translateX(100%)", opacity: 0 },
                    },
                  }
                : {},
            }}
          >
            {Object.entries(shortcuts2[selectedCategory].content).map(
              ([subcategory, items]) => (
                <Box key={subcategory}>
                  <Typography
                    variant="h6"
                    sx={{
                      mb: 2,
                      mt: 6,
                      fontWeight: "600",
                      fontSize: "23px",
                      textAlign: "center",
                      display: "none",
                    }}
                  >
                    {subcategory}
                  </Typography>

                  <Grid container spacing={2}>
                    {items.length > 0 ? (
                      items.map((item, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                          <ShortcutBox
                            user={user}
                            {...item}
                            diffView={false}
                            currentSubject={selectedCategory}
                            isFavorite={favoriteShortcuts.some(
                              (fav) =>
                                fav.id === item.id &&
                                fav.subject === selectedCategory,
                            )}
                            onFavoriteChange={(id, newIsFavorite) => {
                              // The state will be automatically updated by the onSnapshot listener
                              // No need to manually update favoriteShortcuts here
                            }}
                          />
                        </Grid>
                      ))
                    ) : (
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            py: 2,
                            width: "100%",
                          }}
                        >
                          <Typography
                            variant="h6"
                            sx={{ color: "text.secondary" }}
                          >
                            Snart kommer det lektioner för {subcategory} här :)
                          </Typography>
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </Box>
              ),
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default TeacherShortcuts;
