import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import TeachersGuide from "./TeachersGuide";

const InfoBox = ({
  icon: Icon,
  title,
  instructions,
  theme,
  styles,
  muiIconFontSize = null,
  teachersGuide = null,
  user = null,
}) => {
  const muiTheme = useTheme();

  return (
    <Box
      sx={{
        ...styles.freshBox,
      }}
    >
      {teachersGuide && (
        <Box
          sx={{
            position: "absolute",
            top: 20,
            right: 20,
            zIndex: 1,
          }}
        >
          <TeachersGuide guideKey={teachersGuide} theme={theme} user={user} />
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: { xs: "flex-start", md: "center" },
          gap: { xs: 2, md: 3 },
          backgroundColor:
            theme.palette.mode === "dark"
              ? "rgba(255, 255, 255, 0.05)"
              : "#fbfbfb",
          width: "100%",
          padding: { xs: "16px", md: "20px" },
          borderRadius: "12px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            alignSelf: "center",
            mb: { xs: 2, md: 0 },
          }}
        >
          <Icon
            size={110}
            sx={{
              color: "primary.main",
              fontSize: { xs: "90px", md: "130px" },
            }}
            color="primary.main"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            flex: 1,
          }}
        >
          {instructions.map((instruction, index) => (
            <InstructionItem key={index} text={instruction} styles={styles} />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

const InstructionItem = ({ text, styles }) => (
  <Box sx={{ display: "flex", alignItems: "center" }}>
    <Box
      sx={{
        width: { xs: 16, md: 20 },
        height: { xs: 16, md: 20 },
        borderRadius: "50%",
        backgroundColor: "rgba(123, 104, 238, 0.1)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        mr: 2,
      }}
    >
      <CheckIcon
        sx={{
          fontSize: { xs: 14, md: 18 },
          color: "primary.main",
        }}
      />
    </Box>
    <Typography
      variant="body2"
      sx={{
        ...styles.grayText,
        fontSize: { xs: "14px", md: "18px" },
        lineHeight: { xs: "24px", md: "31px" },
        fontWeight: "500",
      }}
    >
      {text}
    </Typography>
  </Box>
);

export default InfoBox;
