import React, { useState, useEffect, useRef } from 'react';
import {
  Typography,
  Container,
  Grid,
  Box,
  Backdrop,
  CircularProgress,
  Button,
  Stepper,
  InputAdornment,
  Step,
  StepLabel,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import YouTubeIcon from '@mui/icons-material/YouTube';
import BlurryBackdrop from './BlurryBackdrop';
import HistoryIcon from '@mui/icons-material/History';
import CancelIcon from '@mui/icons-material/Cancel';
import WavesIcon from '@mui/icons-material/Waves';
import NatureIcon from '@mui/icons-material/Nature';
import { useSnackbar } from './SnackbarHandler';
import PublicIcon from '@mui/icons-material/Public';
import LanguageIcon from '@mui/icons-material/Language';
import ScienceIcon from '@mui/icons-material/Science';
import TranslateIcon from '@mui/icons-material/Translate';
import { East as ArrowIcon } from '@mui/icons-material';
import CalculateIcon from '@mui/icons-material/Calculate';
import PeopleIcon from '@mui/icons-material/People';
import MosqueIcon from '@mui/icons-material/Mosque';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import BiotechIcon from '@mui/icons-material/Biotech';
import ThemeFlowBox from './ThemeFlowBox';
import VideoCard from './VideoCard';
import Logger from './Logger';
import CheckIcon from '@mui/icons-material/Check';
import RefreshIcon from '@mui/icons-material/Refresh';

import PsychologyIcon from '@mui/icons-material/Psychology'; // For Philosophy
import HistoryEduIcon from '@mui/icons-material/HistoryEdu'; // For History
import PaletteIcon from '@mui/icons-material/Palette'; // For Art
import AutoStoriesIcon from '@mui/icons-material/AutoStories'; // For Literature
import GroupsIcon from '@mui/icons-material/Groups'; // For Sociology
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'; // For Political Theory
import DiversityIcon from '@mui/icons-material/Diversity3'; // For African American History
import ThermostatIcon from '@mui/icons-material/Thermostat'; // For Climate

const steps = ['Välj ämne', 'Välj video', 'Video hämtad'];

const schoolSubjectsForYouTube = [
  { value: 'filosofi', label: 'Filosofi', Icon: PsychologyIcon },
  { value: 'historia', label: 'Historia', Icon: HistoryEduIcon },
  { value: 'konst', label: 'Konst', Icon: PaletteIcon },
  { value: 'litteratur', label: 'Litteratur', Icon: AutoStoriesIcon },
  { value: 'sociologi', label: 'Sociologi', Icon: GroupsIcon },
  { value: 'politisk_teori', label: 'Politisk teori', Icon: AccountBalanceIcon },
  { value: 'afroamerikanskhistoria', label: 'Afroamerikansk historia', Icon: DiversityIcon },
  { value: 'klimat', label: 'Klimat', Icon: ThermostatIcon },
  { value: 'biologi', label: 'Biologi', Icon: BiotechIcon },
];
const subjectVideosForYouTube = {
  biologi: [
    {
      title: 'Vad är liv?',
      url: 'https://www.youtube.com/watch?v=tZE_fQFK8EY&list=PL8dPuuaLjXtPW_ofbxdHNciuLoTRLPMgB&index=2&pp=iAQB',
      channel: 'CRASHCOURSE',
    },
    {
      title: 'Den vetenskapliga metoden',
      url: 'https://www.youtube.com/watch?v=xOLcZMw0hd4&list=PL8dPuuaLjXtPW_ofbxdHNciuLoTRLPMgB&index=3&pp=iAQB',
      channel: 'CRASHCOURSE',
    },
    {
      title: 'Det naturliga urvalet',
      url: 'https://www.youtube.com/watch?v=i-VeKZeyHZs&list=PL8dPuuaLjXtPW_ofbxdHNciuLoTRLPMgB&index=14&pp=iAQB',
      channel: 'CRASHCOURSE',
    },
  ],

  klimat: [
    {
      title: 'Vad innebär klimatförändring?',
      url: 'https://www.youtube.com/watch?v=9PFhrpyWV-w&list=PL8dPuuaLjXtMx8ZIQV9NduU_HFjDwykuj&index=2&pp=iAQB',
      channel: 'CRASHCOURSE',
    },

    {
      title: 'Förnybar energi',
      url: 'https://www.youtube.com/watch?v=rDkaZWirNME&list=PL8dPuuaLjXtMx8ZIQV9NduU_HFjDwykuj&index=5&pp=iAQB',
      channel: 'CRASHCOURSE',
    },

    {
      title: 'Klimatförändringens kostnad',
      url: 'https://www.youtube.com/watch?v=ML70W4O6F4E&list=PL8dPuuaLjXtMx8ZIQV9NduU_HFjDwykuj&index=12&pp=iAQB',
      channel: 'CRASHCOURSE',
    },
  ],

  afroamerikanskhistoria: [
    {
      title: 'Transatlantiska slavhandeln',
      url: 'https://www.youtube.com/watch?v=S72vvfBTQws&list=PL8dPuuaLjXtNYJO8JWpXO2JP0ezgxsrJJ&index=2&pp=iAQB',
      channel: 'CRASHCOURSE',
    },
    {
      title: 'Om Elizabeth Key',
      url: 'https://www.youtube.com/watch?v=hJUknOKAv1M&list=PL8dPuuaLjXtNYJO8JWpXO2JP0ezgxsrJJ&index=4&pp=iAQB',
      channel: 'CRASHCOURSE',
    },
    {
      title: 'Om Phillis Wheatley',
      url: 'https://www.youtube.com/watch?v=a3l9Pmza7Gs&list=PL8dPuuaLjXtNYJO8JWpXO2JP0ezgxsrJJ&index=8&pp=iAQB',
      channel: 'CRASHCOURSE',
    },
    {
      title: 'Kvinnorna under slaveriet',
      url: 'https://www.youtube.com/watch?v=eAe7ETfQ_aA&list=PL8dPuuaLjXtNYJO8JWpXO2JP0ezgxsrJJ&index=12&pp=iAQB',
      channel: 'CRASHCOURSE',
    },
    {
      title: 'Bomullsindustrin',
      url: 'https://www.youtube.com/watch?v=83eJfEFFZ74&list=PL8dPuuaLjXtNYJO8JWpXO2JP0ezgxsrJJ&index=14&pp=iAQB',
      channel: 'CRASHCOURSE',
    },
    {
      title: 'Om Zora Neale Hurston',
      url: 'https://www.youtube.com/watch?v=72ABMa_PuHU&list=PL8dPuuaLjXtNYJO8JWpXO2JP0ezgxsrJJ&index=31&pp=iAQB',
      channel: 'CRASHCOURSE',
    },
  ],

  filosofi: [
    {
      title: 'Sokrates',
      url: 'https://www.youtube.com/watch?v=yneFwkz0rrw',
      channel: 'SCHOOLOFLIFE',
    },
    {
      title: 'Platon',
      url: 'https://www.youtube.com/watch?v=b1ZvtYIajBM',
      channel: 'SCHOOLOFLIFE',
    },
    {
      title: 'Aristoteles',
      url: 'https://www.youtube.com/watch?v=WZ-0wRv-V5o',
      channel: 'SCHOOLOFLIFE',
    },
  ],
  konst: [{ title: 'Mona Lisa', url: 'https://www.youtube.com/watch?v=eQhUW9O1Wn8' }],

  sociologi: [
    {
      title: 'Alexis de Tocqueville',
      url: 'https://www.youtube.com/watch?v=Rzr3AOtFA8o&list=PLwxNMb28XmpcoeCDO0VnGUavcLUFiNcAI&index=1&pp=iAQB',
      channel: 'SCHOOLOFLIFE',
    },

    {
      title: 'Auguste Comte',
      url: 'https://www.youtube.com/watch?v=OhVamhT4Q3s&list=PLwxNMb28XmpcoeCDO0VnGUavcLUFiNcAI&index=2&pp=iAQB',
      channel: 'SCHOOLOFLIFE',
    },
    {
      title: 'Émile Durkheim',
      url: 'https://www.youtube.com/watch?v=z9W0GQvONKc&list=PLwxNMb28XmpcoeCDO0VnGUavcLUFiNcAI&index=3&pp=iAQB',
      channel: 'SCHOOLOFLIFE',
    },
    {
      title: 'Max Weber',
      url: 'https://www.youtube.com/watch?v=ICppFQ6Tabw&list=PLwxNMb28XmpcoeCDO0VnGUavcLUFiNcAI&index=4&pp=iAQB',
      channel: 'SCHOOLOFLIFE',
    },
    {
      title: 'Theodor Adorno',
      url: 'https://www.youtube.com/watch?v=4YGnPgtWhsw&list=PLwxNMb28XmpcoeCDO0VnGUavcLUFiNcAI&index=5&pp=iAQB',
      channel: 'SCHOOLOFLIFE',
    },
  ],

  politisk_teori: [
    {
      title: 'John Maynard Keynes',
      url: 'https://www.youtube.com/watch?v=qtAeINU3FKM&list=PLwxNMb28XmpeuwUhM0OT338_T5XTtJJok&index=1&pp=iAQB',
      channel: 'SCHOOLOFLIFE',
    },
    {
      title: 'Friedrich Hayek',
      url: 'https://www.youtube.com/watch?v=SHsCkinrCPE&list=PLwxNMb28XmpeuwUhM0OT338_T5XTtJJok&index=3&pp=iAQB',
      channel: 'SCHOOLOFLIFE',
    },
    {
      title: 'John Locke',
      url: 'https://www.youtube.com/watch?v=bZiWZJgJT7I&list=PLwxNMb28XmpeuwUhM0OT338_T5XTtJJok&index=4&pp=iAQB',
      channel: 'SCHOOLOFLIFE',
    },
    {
      title: 'Jean-Jacques Rousseau',
      url: 'https://www.youtube.com/watch?v=81KfDXTTtXE&list=PLwxNMb28XmpeuwUhM0OT338_T5XTtJJok&index=5&pp=iAQB',
      channel: 'SCHOOLOFLIFE',
    },
    {
      title: 'Thomas Hobbes',
      url: 'https://www.youtube.com/watch?v=9i4jb5XBX5s&list=PLwxNMb28XmpeuwUhM0OT338_T5XTtJJok&index=6&pp=iAQB',
      channel: 'SCHOOLOFLIFE',
    },
    {
      title: 'Niccolò Machiavelli',
      url: 'https://www.youtube.com/watch?v=AOXl0Ll_t9s&list=PLwxNMb28XmpeuwUhM0OT338_T5XTtJJok&index=7&pp=iAQB',
      channel: 'SCHOOLOFLIFE',
    },
  ],

  litteratur: [
    {
      title: 'Virginia Woolf',
      url: 'https://www.youtube.com/watch?v=d1W7wqXD_b0',
      channel: 'SCHOOLOFLIFE',
    },
    {
      title: 'Jane Austen',
      url: 'https://www.youtube.com/watch?v=LIYiThAyY8s&pp=ygULamFuZSBhdXN0ZW4%3D',
      channel: 'SCHOOLOFLIFE',
    },
    {
      title: 'Voltaire',
      url: 'https://www.youtube.com/watch?v=LAzKGkTIKpg&list=PLwxNMb28Xmpfv2COuuJaKzy6E2n8nSMdi&index=1&pp=iAQB',
      channel: 'SCHOOLOFLIFE',
    },
    {
      title: 'Gustave Flaubert',
      url: 'https://www.youtube.com/watch?v=XK8lZO39T-0&list=PLwxNMb28Xmpfv2COuuJaKzy6E2n8nSMdi&index=6&pp=iAQB',
      channel: 'SCHOOLOFLIFE',
    },
    {
      title: 'Leo Tolstoj',
      url: 'https://www.youtube.com/watch?v=Lr6DYLBkyG0&list=PLwxNMb28Xmpfv2COuuJaKzy6E2n8nSMdi&index=8&pp=iAQB',
      channel: 'SCHOOLOFLIFE',
    },
    {
      title: 'Marcel Proust',
      url: 'https://www.youtube.com/watch?v=9mLdo4uMJUU&list=PLwxNMb28Xmpfv2COuuJaKzy6E2n8nSMdi&index=14&pp=iAQB',
      channel: 'SCHOOLOFLIFE',
    },

    {
      title: 'Franz Kafka',
      url: 'https://www.youtube.com/watch?v=g4LyzhkDNBM&list=PLwxNMb28Xmpfv2COuuJaKzy6E2n8nSMdi&index=17&pp=iAQB',
      channel: 'SCHOOLOFLIFE',
    },
    {
      title: 'James Joyce',
      url: 'https://www.youtube.com/watch?v=1SuHkY2wAQA&list=PLwxNMb28Xmpfv2COuuJaKzy6E2n8nSMdi&index=13&pp=iAQB',
      channel: 'SCHOOLOFLIFE',
    },
    {
      title: 'Charles Dickens',
      url: 'https://www.youtube.com/watch?v=N9dB9BZWDBU&list=PLwxNMb28Xmpfv2COuuJaKzy6E2n8nSMdi&index=7&pp=iAQB',
      channel: 'SCHOOLOFLIFE',
    },
  ],
  historia: [
    {
      title: 'Forntida Egypten',
      url: 'https://www.youtube.com/watch?v=hO1tzmi1V5g&pp=ygUPY2xlb3BhdHJhIGZhY3Rz',
      channel: 'NATIONALGEOGRAPHIC',
    },

    {
      title: 'Antikens Rom',
      url: 'https://www.youtube.com/watch?v=GXoEpNjgKzg',
      channel: 'NATIONALGEOGRAPHIC',
    },
    {
      title: 'Om Katherine Johnson',
      url: 'https://www.youtube.com/watch?v=E4j_LpKzcZQ',
      channel: 'NATIONALGEOGRAPHIC',
    },

    {
      title: 'Historien om Gaza',
      url: 'https://www.youtube.com/watch?v=zdq3NVtT6Nc&pp=ygULY25uIGhpc3Rvcnk%3D',
      channel: 'CNN',
    },

    {
      title: 'Om Jackie Kennedy',
      url: 'https://www.youtube.com/watch?v=ei8XBm3-9lY',
      channel: 'CAPTIVATINGHISTORY',
    },
    {
      title: 'Vincent Van Gogh',
      url: 'https://www.youtube.com/watch?v=7oPPVl1YMP8',
      channel: 'CAPTIVATINGHISTORY',
    },
    {
      title: 'Om Fascism',
      url: 'https://www.youtube.com/watch?v=t7lUdwyq-KU',
      channel: 'CAPTIVATINGHISTORY',
    },
  ],
};

const schoolSubjects = [
  { value: 'religionskunskap', label: 'Religionskunskap', Icon: MosqueIcon },
  { value: 'historia', label: 'Historia', Icon: HistoryIcon },
  { value: 'litteratur', label: 'Litteratur', Icon: MenuBookIcon },

  { value: 'naturkunskap', label: 'Naturkunskap', Icon: NatureIcon },

  // { value: 'fysik', label: 'Fysik', Icon: WavesIcon },
  // { value: 'biologi', label: 'Biologi', Icon: NatureIcon },
  // { value: 'geografi', label: 'Geografi', Icon: PublicIcon },
  // { value: 'engelska', label: 'Engelska', Icon: LanguageIcon },
  // { value: 'kemi', label: 'Kemi', Icon: ScienceIcon },
  // { value: 'modersmål', label: 'Modersmål', Icon: PublicIcon },

  // { value: 'samhällskunskap', label: 'Samhällskunskap', Icon: PeopleIcon },
  // { value: 'svenska', label: 'Svenska', Icon: TranslateIcon },
  // { value: 'matematik', label: 'Matematik', Icon: CalculateIcon },
  // { value: 'teknik', label: 'Teknik', Icon: BiotechIcon },
];

const subjectVideos = {
  religionskunskap: [
    {
      title: 'Kristendomen förklarad',
      url: 'https://www.youtube.com/watch?v=ors0kV6oR-4&list=PLSHcVfKvOzQMGlNJOG_j_OAzyiU8ytC0O&index=1&pp=iAQB',
      channel: 'URPLAY',
    },
    {
      title: 'Islam förklarad',
      url: 'https://www.youtube.com/watch?v=kBROYF3K4Zw&list=PLSHcVfKvOzQMGlNJOG_j_OAzyiU8ytC0O&index=2&t=2s&pp=iAQB',
      channel: 'URPLAY',
    },
    {
      title: 'Hindiusmen förklarad',
      url: 'https://www.youtube.com/watch?v=q_A2QYRb0pA&list=PLSHcVfKvOzQMGlNJOG_j_OAzyiU8ytC0O&index=4&pp=iAQB',
      channel: 'URPLAY',
    },
    {
      title: 'Buddhismen förklarad',
      url: 'https://www.youtube.com/watch?v=88jkPXCa0L8&list=PLSHcVfKvOzQMGlNJOG_j_OAzyiU8ytC0O&index=5&pp=iAQB',
      channel: 'URPLAY',
    },
    {
      title: 'Sveriges sekularisering förklarad',
      url: 'https://www.youtube.com/watch?v=G5t-O3ww_7s&list=PLSHcVfKvOzQMGlNJOG_j_OAzyiU8ytC0O&index=3&pp=iAQB',
      channel: 'URPLAY',
    },
  ],
  historia: [
    {
      title: 'Antiken',
      url: 'https://www.youtube.com/watch?v=J-jOuUL-ZOw&list=PLSHcVfKvOzQMzKwqRJaL0tWHO8Gy-YdnG&index=1&pp=iAQB',
      channel: 'URPLAY',
    },
    {
      title: 'Medeltiden',
      url: 'https://www.youtube.com/watch?v=7MailENdgx4&list=PLSHcVfKvOzQMzKwqRJaL0tWHO8Gy-YdnG&index=2&t=233s&pp=iAQB',
      channel: 'URPLAY',
    },
    {
      title: 'Stormaktstiden',
      url: 'https://www.youtube.com/watch?v=zi5NImWqto8&list=PLSHcVfKvOzQMzKwqRJaL0tWHO8Gy-YdnG&index=5&pp=iAQB',
      channel: 'URPLAY',
    },

    {
      title: 'Socialismens framväxt',
      url: 'https://www.youtube.com/watch?v=bIMYUH8f0e4&list=PLSHcVfKvOzQMzKwqRJaL0tWHO8Gy-YdnG&index=13&pp=iAQB',
      channel: 'URPLAY',
    },
    {
      title: 'Sveriges demokratisering',
      url: 'https://www.youtube.com/watch?v=9Zutz9h12-E&list=PLSHcVfKvOzQMzKwqRJaL0tWHO8Gy-YdnG&index=12&pp=iAQB',
      channel: 'URPLAY',
    },
    {
      title: 'Franska revolutionen',
      url: 'https://www.youtube.com/watch?v=eZW7YzlN0QY&list=PLSHcVfKvOzQMzKwqRJaL0tWHO8Gy-YdnG&index=11&pp=iAQB',
      channel: 'URPLAY',
    },
  ],
  litteratur: [
    {
      title: 'Karin Boye förklarad',
      url: 'https://www.youtube.com/watch?v=y_8tcbe8bEs&list=PLSHcVfKvOzQPSV_NjeSIZPDi3EKe2Xa3y&index=6&pp=iAQB',
      channel: 'URPLAY',
    },
    {
      title: 'Edith Södergran förklarad',
      url: 'https://www.youtube.com/watch?v=4bfMMDEVuWg&list=PLSHcVfKvOzQPSV_NjeSIZPDi3EKe2Xa3y&index=8&pp=iAQB',
      channel: 'URPLAY',
    },
    {
      title: 'Iliaden förklarad',
      url: 'https://www.youtube.com/watch?v=EZ4MnZkchm4&list=PLSHcVfKvOzQPSV_NjeSIZPDi3EKe2Xa3y&index=16&pp=iAQB',
      channel: 'URPLAY',
    },

    {
      title: 'Don Quijote',
      url: 'https://www.youtube.com/watch?v=xmHij5uoeoo&list=PLSHcVfKvOzQPSV_NjeSIZPDi3EKe2Xa3y&index=18&pp=iAQB',
      channel: 'URPLAY',
    },
    {
      title: 'Den gudomliga  komedin',
      url: 'https://www.youtube.com/watch?v=sukWQYkAySw&list=PLSHcVfKvOzQPSV_NjeSIZPDi3EKe2Xa3y&index=17&pp=iAQB',
      channel: 'URPLAY',
    },
    {
      title: 'Robinson Crusoe',
      url: 'https://www.youtube.com/watch?v=G0RHv9CD9SY&list=PLSHcVfKvOzQPSV_NjeSIZPDi3EKe2Xa3y&index=20&pp=iAQB',
      channel: 'URPLAY',
    },
  ],

  naturkunskap: [
    {
      title: 'Protein förklarad',
      url: 'https://www.youtube.com/watch?v=iMh59rrRq_8',
    },
    {
      title: 'Genteknik förklarad',
      url: 'https://www.youtube.com/watch?v=Ktc9_Bu0SGs',
    },
    {
      title: 'Celldelning förklarad',
      url: 'https://www.youtube.com/watch?v=ReI0nXyZInE',
    },

    {
      title: 'Ozonlagret',
      url: 'https://www.youtube.com/watch?v=bsJ_vvDHV5o',
    },
    {
      title: 'Miljöfarliga metaller',
      url: 'https://www.youtube.com/watch?v=GXLCVZ7pDzY',
    },
    {
      title: 'Skogens ekologi',
      url: 'https://www.youtube.com/watch?v=DPWvAYX53rw',
    },
  ],

  // Add videos for other subjects as needed
};

export const YouTubeSelector = ({
  onTranscriptChange,
  urplay = false,
  forQuiz,
  forFlashcards,
  forPowerPoint,
}) => {
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [isLoadingTranscript, setIsLoadingTranscript] = useState(false);
  const [transcriptFetched, setTranscriptFetched] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const { showSnackbar } = useSnackbar();

  const [openUrlDialog, setOpenUrlDialog] = useState(false);
  const [customUrl, setCustomUrl] = useState('');
  const [showCustomVideoPreview, setShowCustomVideoPreview] = useState(false);
  const [customVideo, setCustomVideo] = useState(null);

  const [isCancelled, setIsCancelled] = useState(false);
  const abortControllerRef = useRef(null);

  const subjects = urplay ? schoolSubjects : schoolSubjectsForYouTube;
  const videos = urplay ? subjectVideos : subjectVideosForYouTube;

  const handleCancel = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    setIsCancelled(true);
    setIsLoadingTranscript(false);
    showSnackbar('Hämtningen av YouTube-videon avbruten.', 'info');
  };

  const handleUrlDialogOpen = () => {
    setOpenUrlDialog(true);
  };

  const handleUrlDialogClose = () => {
    setOpenUrlDialog(false);
    setCustomUrl('');
  };

  const handleCustomUrlSubmit = () => {
    if (customUrl) {
      const videoId = extractVideoId(customUrl);
      if (videoId) {
        const newVideo = {
          title: 'Video hämtad!',
          url: customUrl,
        };
        setCustomVideo(newVideo);
        setShowCustomVideoPreview(true);
        handleUrlDialogClose();
      } else {
        showSnackbar('Inkorrekt YouTube-URL. Kontrollera och försök igen.', 'error');
      }
    }
  };

  const extractVideoId = url => {
    try {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
      const match = url.match(regExp);
      return match && match[2].length === 11 ? match[2] : null;
    } catch (error) {
      console.error('Error extracting video ID:', error);
      return null;
    }
  };

  const fetchYoutubeTranscript = async (url, maxLength = 5000) => {
    try {
      setIsLoadingTranscript(true);
      setIsCancelled(false);
      const videoId = extractVideoId(url);

      if (!videoId) {
        showSnackbar('Inkorrekt YouTube-URL. Kontrollera och försök igen.', 'error');
        return null;
      }

      // Create new AbortController for this request
      abortControllerRef.current = new AbortController();
      const signal = abortControllerRef.current.signal;

      const response = await fetch(
        `https://app.digicord.site/api/v1/youtube/transcript/${videoId}`,
        { signal }
      );
      const data = await response.json();

      if (!response.ok || !data.data?.content) {
        showSnackbar(
          'Just nu går det inte att hämta vald YouTube-video. Vi jobbar på att fixa problemet! Det kommer funka snart igen.',
          'error'
        );
        return null;
      }

      let transcriptContent = data.data.content;
      if (transcriptContent.length > maxLength) {
        const lastPeriod = transcriptContent.lastIndexOf('.');
        transcriptContent =
          lastPeriod > maxLength * 0.8
            ? transcriptContent.substring(0, lastPeriod + 1)
            : transcriptContent.substring(0, maxLength);
      }

      return transcriptContent;
    } catch (error) {
      if (error.name === 'AbortError') {
        return null;
      }
      console.error('Error fetching transcript:', error);
      showSnackbar('Gick inte att hämta video från YouTube: ' + error.message, 'error');
      return null;
    } finally {
      if (!isCancelled) {
        setIsLoadingTranscript(false);
      }
    }
  };

  const handleVideoSelect = async video => {
    setIsLoadingTranscript(true);
    const transcript = await fetchYoutubeTranscript(video.url);
    if (transcript && !isCancelled) {
      onTranscriptChange(
        `Gör ${
          forQuiz
            ? 'ett quiz'
            : forFlashcards
              ? 'flashcards'
              : forPowerPoint
                ? 'en PowerPoint'
                : 'en lektion'
        } baserat på innehållet i denna: ${video.title} Transkript: ${transcript}`
      );

      // onTranscriptChange(
      //   `Gör en lektion baserat på innehållet i denna: ${video.title} Transkript: ${transcript} `
      // );
      setSelectedVideo(video);
      setTranscriptFetched(true);
      setActiveStep(2);
    }
    setIsLoadingTranscript(false);
  };

  const handleSubjectSelect = subject => {
    setSelectedSubject(subject);
    setActiveStep(1);
  };

  const handleStepClick = step => {
    if (step === 0) {
      handleReset();
    } else if (step === 1 && selectedSubject) {
      setTranscriptFetched(false);
      setSelectedVideo(null);
      setActiveStep(1);
    }
  };

  const handleReset = () => {
    setSelectedSubject(null);
    setTranscriptFetched(false);
    setSelectedVideo(null);
    setActiveStep(0);
  };

  return (
    <Container
      maxWidth='md'
      sx={{
        py: 3,
        backgroundColor: isDark ? 'rgba(255, 255, 255, 0.05)' : 'rgba(252, 252, 252, 0.17)',
        border: isDark ? '1px solid #51585f' : '1px solid #ededed69',
        borderRadius: '10px',

        position: 'relative',
      }}
    >
      <Box sx={{ width: { xs: '100%', md: '70%' }, margin: '0 auto', mb: 6, mt: 4 }}>
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          sx={{
            flex: 1,
            '& .MuiStepIcon-root': {
              color: 'rgba(0, 0, 0, 0.38)',
            },
            '& .MuiStepIcon-root.Mui-active': {
              color: '#7b68eebf',
            },
            '& .MuiStepIcon-root.Mui-completed': {
              color: '#7b68ee',
            },
            '& .MuiStepLabel-label': {
              fontSize: '0.95rem',
            },
          }}
        >
          {steps.map((label, index) => (
            <Step
              key={label}
              completed={index < activeStep}
              sx={{
                '& .MuiStepLabel-label:hover': {
                  textDecoration: index < activeStep ? 'underline' : 'none',
                },
              }}
            >
              <StepLabel
                onClick={() => handleStepClick(index)}
                sx={{
                  cursor: index <= activeStep ? 'pointer' : 'default',
                  '&:hover': index <= activeStep ? { color: theme.palette.primary.main } : {},
                }}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>

      <Tooltip title={urplay ? 'Producerat av UR Play' : 'Kurerat urval från YouTube'}>
        <Box
          sx={{
            position: 'absolute',
            top: '10px',
            right: '10px',
          }}
        >
          {urplay ? (
            <Box
              component='img'
              src='/img/urplaysmall.png'
              alt='UR Play'
              sx={{
                height: 'auto',
                width: '30px',
              }}
            />
          ) : (
            <YouTubeIcon
              sx={{
                color: '#FF0000',
                fontSize: '30px',
              }}
            />
          )}
        </Box>
      </Tooltip>
      {isLoadingTranscript && (
        <Backdrop
          transitionDuration={1000}
          sx={{
            color: '#fff',
            zIndex: theme => theme.zIndex.drawer + 1,
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 4,
            bgcolor: 'rgba(0, 0, 0, 0.85)',
            backdropFilter: 'blur(8px)',
          }}
          open={isLoadingTranscript}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 3,
            }}
          >
            {urplay ? (
              <Box
                sx={{
                  position: 'relative',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  mb: 1,
                }}
              >
                <Box
                  component='img'
                  src='/img/urplay2.png'
                  alt='UR Play Logo'
                  sx={{
                    width: 250,
                    borderRadius: '10px',
                    animation: 'pulse 1s infinite ease-in-out',
                    filter: 'drop-shadow(0 0 20px rgba(255, 255, 255, 0.2))',
                  }}
                />
              </Box>
            ) : (
              <Box
                sx={{
                  position: 'relative',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  mb: 1,
                }}
              >
                <YouTubeIcon
                  sx={{
                    fontSize: '80px',
                    color: '#f03',
                    animation: 'pulse 3s infinite ease-in-out',
                    filter: 'drop-shadow(0 0 20px rgba(255, 255, 255, 0.2))',
                  }}
                />
              </Box>
            )}

            <Typography
              variant='h6'
              sx={{
                fontWeight: 300,
                letterSpacing: 1,
                opacity: 0.9,
                textAlign: 'center',
                fontSize: '1.55rem',
                marginBottom: '20px',
              }}
            >
              {urplay ? 'Hämtar innehåll från UR Play.' : 'Hämtar innehåll från YouTube.'} <br />
              Tar ungefär 60 sekunder...
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
              <CircularProgress sx={{ color: '#ffffff' }} size={30} />
              <Button
                variant='print'
                onClick={handleCancel}
                startIcon={<CancelIcon />}
                sx={{ mt: 4 }}
              >
                Avbryt hämtning
              </Button>
            </Box>
          </Box>
        </Backdrop>
      )}

      {transcriptFetched ? (
        <Box
          sx={{
            display: 'flex',
            gap: 3,
            alignItems: 'center',
            p: 3,
            maxWidth: 900,
            mx: 'auto',
          }}
        >
          <Box sx={{ flex: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Box
                sx={{
                  bgcolor: theme.palette.success.main,
                  color: '#fff',
                  width: 40,
                  height: 40,
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  mr: 2,
                }}
              >
                <CheckIcon sx={{ fontSize: 24 }} />
              </Box>
              <Typography variant='h6' component='div' sx={{ color: theme.palette.success.main }}>
                Video hämtad!
              </Typography>
            </Box>

            <Typography color='text.secondary' sx={{ mb: 3 }}>
              Fortsätt med att skapa{' '}
              {forQuiz
                ? 'ditt quiz'
                : forFlashcards
                  ? 'dina flashcards'
                  : forPowerPoint
                    ? 'din PowerPoint'
                    : 'din lektion'}{' '}
              nedan.
            </Typography>

            <Button
              variant='outlined'
              onClick={handleReset}
              startIcon={<RefreshIcon />}
              size='small'
              sx={{ display: 'none' }}
            >
              Välj en annan video
            </Button>
          </Box>

          <Box
            sx={{
              width: '400px',
              position: 'relative',
              paddingBottom: '225px', // 16:9 aspect ratio for 400px width
              height: 0,
              overflow: 'hidden',
              borderRadius: 2,
              bgcolor: 'background.paper',
              boxShadow: 1,
              '& iframe': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                borderRadius: 'inherit',
              },
            }}
          >
            <iframe
              src={`https://www.youtube.com/embed/${extractVideoId(selectedVideo.url)}`}
              title={selectedVideo.title}
              frameBorder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowFullScreen
            />
          </Box>
        </Box>
      ) : !selectedSubject ? (
        <>
          <Box sx={{ mt: 4, mb: 4, textAlign: 'center', display: urplay ? 'none' : 'block' }}>
            <Typography variant='h6' sx={{ mb: 2, fontSize: '1.1rem' }}>
              Välj ämne:
            </Typography>
          </Box>

          <Grid container spacing={2}>
            {subjects.map(subject => (
              <Grid item xs={12} sm={6} md={4} key={subject.value}>
                <ThemeFlowBox
                  icon={subject.Icon}
                  title={subject.label}
                  onClick={() => handleSubjectSelect(subject.value)}
                />
              </Grid>
            ))}
          </Grid>
          <Box sx={{ mt: 7, textAlign: 'center', display: urplay ? 'none' : 'block' }}>
            <Typography variant='h6' sx={{ mb: 2, fontSize: '1.1rem' }}>
              Eller skriv in valfri YouTube-URL:
            </Typography>
            <Button variant='purp' color='primary' onClick={handleUrlDialogOpen}>
              Skriv in URL <ArrowIcon sx={{ ml: 1 }} />
            </Button>
          </Box>
        </>
      ) : (
        <Grid container spacing={3}>
          {(videos[selectedSubject] || []).map((video, index) => (
            <Grid item xs={12} sm={4} key={index}>
              <VideoCard video={video} onSelect={() => handleVideoSelect(video)} isDark={isDark} />
            </Grid>
          ))}
        </Grid>
      )}

      <Dialog
        open={openUrlDialog}
        onClose={handleUrlDialogClose}
        BackdropComponent={BlurryBackdrop}
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: 600,
            borderRadius: '20px',
            backgroundColor: theme.palette.mode === 'dark' ? '#22303C' : 'white',
            backgroundImage: theme.palette.mode === 'dark' ? 'none' : 'white',
            padding: { xs: '0px', md: '20px' },
          },
        }}
      >
        <DialogTitle>
          <Typography
            variant='h6'
            component='div'
            sx={{
              fontWeight: 'bold',
              fontSize: '1.6rem',
              padding: '5px',
              textAlign: 'center',
            }}
          >
            Ange YouTube-URL
          </Typography>
        </DialogTitle>
        <Typography
          variant='subtitle1'
          sx={{
            fontSize: '1rem',
            color: theme.palette.text.secondary,
            textAlign: 'center',
            mb: 3,
          }}
        >
          Exempel:
          <br /> https://www.youtube.com/watch?v=kBROYF3K4Zw
        </Typography>
        <DialogContent>
          <TextField
            autoFocus
            margin='dense'
            label='YouTube URL'
            type='url'
            fullWidth
            variant='outlined'
            value={customUrl}
            onChange={e => setCustomUrl(e.target.value)}
            sx={{ mt: 2 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <YouTubeIcon />
                </InputAdornment>
              ),
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUrlDialogClose} variant='print'>
            Avbryt
          </Button>
          <Button onClick={handleCustomUrlSubmit} variant='purp' color='primary'>
            Fortsätt <ArrowIcon sx={{ ml: 1 }} />
          </Button>
        </DialogActions>
      </Dialog>

      {customVideo && (
        <Box sx={{ display: 'none' }}>
          <VideoCard
            video={customVideo}
            onSelect={handleVideoSelect}
            isDark={isDark}
            showPreview={showCustomVideoPreview}
            setShowPreview={setShowCustomVideoPreview}
          />
        </Box>
      )}
    </Container>
  );
};

export default YouTubeSelector;
