import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  CircularProgress,
  Alert,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  Collapse,
  Grid,
  Card,
  CardContent,
  CardMedia,
  CardActions,
} from "@mui/material";
import WikipediaIcon from "./WikipediaIcon";
import { CheckCircleOutline } from "@mui/icons-material";
import axios from "axios";

import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import { Link as LinkIcon } from "@mui/icons-material";

const cheerio = require("cheerio");
import { Star } from "@mui/icons-material";
const trustedArticles = [
  "https://sv.wikipedia.org/wiki/Marie_Curie",
  "https://sv.wikipedia.org/wiki/Andra_världskriget",
  "https://sv.wikipedia.org/wiki/Fotosyntes",
  "https://sv.wikipedia.org/wiki/William_Shakespeare",
  "https://sv.wikipedia.org/wiki/Albert_Einstein",
  "https://sv.wikipedia.org/wiki/Gustav_III",
  "https://sv.wikipedia.org/wiki/Alexander_den_store",
  "https://sv.wikipedia.org/wiki/Första_världskriget",
  "https://sv.wikipedia.org/wiki/Gustav_II_Adolf",
  "https://sv.wikipedia.org/wiki/Charles_Darwin",
  "https://sv.wikipedia.org/wiki/Leonardo_da_Vinci",
  "https://sv.wikipedia.org/wiki/Franska_revolutionen",
  "https://sv.wikipedia.org/wiki/Vikingatiden",
  "https://sv.wikipedia.org/wiki/Antikens_Grekland",
  "https://sv.wikipedia.org/wiki/Romarriket",
  "https://sv.wikipedia.org/wiki/Industriella_revolutionen",
  "https://sv.wikipedia.org/wiki/Vasa_(skepp)",
  "https://sv.wikipedia.org/wiki/Medeltiden",
  "https://sv.wikipedia.org/wiki/Gustav_Vasa",
  "https://sv.wikipedia.org/wiki/Stockholms_blodbad",
  "https://sv.wikipedia.org/wiki/Carl_von_Linné",
  "https://sv.wikipedia.org/wiki/Drottning_Kristina",
  "https://sv.wikipedia.org/wiki/Kalmarunionen",
  "https://sv.wikipedia.org/wiki/Digerdöden",
  "https://sv.wikipedia.org/wiki/Kristendomen",
  "https://sv.wikipedia.org/wiki/Upplysningen",
  "https://sv.wikipedia.org/wiki/Stora_nordiska_kriget",
  "https://sv.wikipedia.org/wiki/Isaac_Newton",
  "https://sv.wikipedia.org/wiki/Johannes_Kepler",
  "https://sv.wikipedia.org/wiki/Galileo_Galilei",
  "https://sv.wikipedia.org/wiki/Wolfgang_Amadeus_Mozart",
  "https://sv.wikipedia.org/wiki/Ludwig_van_Beethoven",
  "https://sv.wikipedia.org/wiki/Vincent_van_Gogh",
  "https://sv.wikipedia.org/wiki/Astrid_Lindgren",
  "https://sv.wikipedia.org/wiki/Alfred_Nobel",
  "https://sv.wikipedia.org/wiki/Selma_Lagerlöf",
  "https://sv.wikipedia.org/wiki/August_Strindberg",
  "https://sv.wikipedia.org/wiki/Dag_Hammarskjöld",
  "https://sv.wikipedia.org/wiki/Olof_Palme",
  "https://sv.wikipedia.org/wiki/Raoul_Wallenberg",
  "https://sv.wikipedia.org/wiki/Ingmar_Bergman",
  "https://sv.wikipedia.org/wiki/ABBA",
  "https://sv.wikipedia.org/wiki/Nobelpriset",
  "https://sv.wikipedia.org/wiki/Svenska_akademien",
  "https://sv.wikipedia.org/wiki/Stockholms_tunnelbana",
  "https://sv.wikipedia.org/wiki/Sveriges_riksdag",
  "https://sv.wikipedia.org/wiki/Svenska_kyrkan",
  "https://sv.wikipedia.org/wiki/Sápmi",
  "https://sv.wikipedia.org/wiki/Birka",
  "https://sv.wikipedia.org/wiki/Uppsala_högar",
  "https://sv.wikipedia.org/wiki/Gamla_Uppsala",

  "https://sv.wikipedia.org/wiki/Trettioåriga_kriget",

  "https://sv.wikipedia.org/wiki/Skånes_historia",

  "https://sv.wikipedia.org/wiki/Sveriges_radio",
  "https://sv.wikipedia.org/wiki/Sveriges_Television",
  "https://sv.wikipedia.org/wiki/Kalmar_slott",
  "https://sv.wikipedia.org/wiki/Drottningholms_slott",
];

const WikiFetcher = ({
  onWikipediaChange,
  theme,
  styles,
  forQuiz,
  forFlashcards,
  forPowerPoint,
  forTextlabbet,
}) => {
  const [url, setUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [error, setError] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [articlePreviews, setArticlePreviews] = useState([]);
  const [previewsLoading, setPreviewsLoading] = useState(false);
  const [showStarInfo, setShowStarInfo] = useState(false);

  const handleStarInfoToggle = () => {
    setShowStarInfo(!showStarInfo);
  };
  const fetchArticleMetadata = async (articleUrl) => {
    try {
      const urlParts = articleUrl.match(
        /https?:\/\/([a-z]{2,3})\.wikipedia\.org\/wiki\/(.+)/,
      );
      if (!urlParts) return null;

      const [_, lang, pageTitle] = urlParts;
      const response = await axios.get(
        `https://${lang}.wikipedia.org/api/rest_v1/page/summary/${decodeURIComponent(pageTitle)}`,
      );

      return {
        url: articleUrl,
        title: response.data.title,
        description: response.data.extract,
        thumbnail: response.data.thumbnail?.source,
        originalimage: response.data.originalimage?.source,
      };
    } catch (error) {
      console.error("Error fetching article metadata:", error);
      return null;
    }
  };

  useEffect(() => {
    if (dialogOpen && articlePreviews.length === 0) {
      const loadPreviews = async () => {
        setPreviewsLoading(true);
        const previews = await Promise.all(
          trustedArticles.map((url) => fetchArticleMetadata(url)),
        );
        setArticlePreviews(previews.filter((preview) => preview !== null));
        setPreviewsLoading(false);
      };
      loadPreviews();
    }
  }, [dialogOpen]);

  const extractWikipediaContent = async (url, maxLength = 10000) => {
    try {
      const urlParts = url.match(
        /https?:\/\/([a-z]{2,3})\.wikipedia\.org\/wiki\/(.+)/,
      );
      if (!urlParts) {
        throw new Error("invalid_url");
      }
      const [_, lang, pageTitle] = urlParts;
      const response = await axios.get(
        `https://${lang}.wikipedia.org/api/rest_v1/page/html/${pageTitle}`,
      );
      const $ = cheerio.load(response.data);
      let content = "";
      $("p, ul, ol").each((i, element) => {
        if (content.length < maxLength) {
          if (element.name === "p") {
            content += $(element).text() + "\n\n";
          } else if (element.name === "ul" || element.name === "ol") {
            $(element)
              .find("li")
              .each((j, li) => {
                content += "• " + $(li).text() + "\n";
              });
            content += "\n";
          }
        } else {
          return false;
        }
      });
      return content.substring(0, maxLength);
    } catch (error) {
      console.error("Error fetching Wikipedia content:", error);
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError("");

    try {
      const content = await extractWikipediaContent(url);
      if (content) {
        onWikipediaChange(content);
        setShowSuccess(true);
        setError("");
      } else {
        setError("Kunde inte hämta innehåll från Wikipedia-artikeln.");
      }
    } catch (error) {
      if (error.message === "invalid_url") {
        setError("Ogiltig Wikipedia-URL. Använd en länk från wikipedia.org.");
      } else {
        setError("Ett fel uppstod när Wikipedia-innehållet skulle hämtas.");
      }
      setShowSuccess(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleReset = () => {
    setUrl("");
    setShowSuccess(false);
    setError("");
  };

  const handleArticleSelect = (selectedUrl) => {
    setUrl(selectedUrl);
    setDialogOpen(false);
  };

  return (
    <Box sx={{ width: "100%", p: 3 }}>
      {!showSuccess ? (
        <Box component="form" onSubmit={handleSubmit}>
          <Box sx={{ textAlign: "center", mb: 4 }}>
            <WikipediaIcon sx={{ fontSize: "80px" }} />
          </Box>

          <Box
            sx={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <TextField
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              placeholder="https://sv.wikipedia.org/wiki/Sverige"
              disabled={isLoading}
              sx={{ mb: 3, width: "77%" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LinkIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          {error && (
            <Box
              sx={{ display: "flex", justifyContent: "center", width: "100%" }}
            >
              <Alert severity="error" sx={{ mb: 2, width: "70%" }}>
                {error}
              </Alert>
            </Box>
          )}

          <Box
            sx={{ display: "flex", justifyContent: "center", gap: 2, mt: 2 }}
          >
            {isLoading ? (
              <CircularProgress />
            ) : (
              <>
                <Button
                  variant="purp"
                  type="submit"
                  disabled={!url}
                  startIcon={<DownloadForOfflineIcon />}
                >
                  Hämta innehåll
                </Button>
                <Button
                  variant="print"
                  onClick={() => setDialogOpen(true)}
                  startIcon={<Star sx={{ color: "#FFD700" }} />}
                >
                  Urval av stjärnmärkta artiklar
                </Button>
              </>
            )}
          </Box>
        </Box>
      ) : (
        <Box sx={{ ...styles.successBox }}>
          <CheckCircleOutline
            sx={{ fontSize: 60, color: "success.main", mb: 3 }}
          />
          <Typography
            variant="h6"
            sx={{
              color: "success.main",
              fontWeight: 500,
              textAlign: "center",
              width: "80%",
              margin: "0 auto",
              mb: 3,
            }}
          >
            Wikipedia-artikeln är nu hämtad och du kan fortsätta med att
            {forTextlabbet ? " bearbeta" : "generera"}{" "}
            {forQuiz
              ? "ditt quiz"
              : forFlashcards
                ? "dina flashcards"
                : forPowerPoint
                  ? "din PowerPoint"
                  : forTextlabbet
                    ? "din text"
                    : "din lektion"}{" "}
            nedan.
          </Typography>
          <Button variant="purp" onClick={handleReset}>
            Välj annan URL
          </Button>
        </Box>
      )}

      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth="lg"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: "20px",
            backgroundColor:
              theme.palette.mode === "dark" ? "#22303C" : "white",
            backgroundImage: theme.palette.mode === "dark" ? "none" : "white",
            padding: { xs: "0px", md: "20px" },
          },
        }}
      >
        <DialogTitle
          sx={{
            px: 3,
            py: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Typography
            variant="h6"
            component="div"
            sx={{
              fontWeight: "bold",
              fontSize: "1.6rem",
              padding: "5px",
              textAlign: "center",
            }}
          >
            Stjärnmärkta Wikipedia-artiklar
          </Typography>
        </DialogTitle>
        <Box sx={{ width: "100%", mt: 3 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              mb: 2,
            }}
          >
            <Button
              variant="print"
              startIcon={<Star sx={{ color: "#FFD700" }} />}
              onClick={handleStarInfoToggle}
            >
              {showStarInfo
                ? "Dölj information"
                : "Vad är en stjärnmärkt Wikipedia-artikel?"}
            </Button>
          </Box>

          <Collapse in={showStarInfo}>
            <Box
              sx={{
                p: 4,
                border: "1px solid",
                borderColor: "divider",
                borderRadius: 2,
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{
                  fontSize: "1.2rem",
                  color: theme.palette.text.secondary,
                  textAlign: "center",
                  mb: 4,
                }}
              >
                Nedanstående text är kopierad från Wikipedia.
              </Typography>

              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <Typography variant="h5" sx={{ mb: 3, fontWeight: "bold" }}>
                    Utmärkta artiklar på Wikipedia
                  </Typography>

                  <Typography paragraph>
                    <strong>Utmärkta artiklar</strong> är betraktade som de
                    bästa artiklarna på Wikipedia, fastställda av Wikipedias
                    skribenter. De är välskrivna, korrekta, uttömmande,
                    illustrerade och försedda med källhänvisningar. Innan
                    artiklar listas här, genomgår de en nomineringsprocess för
                    att möta våra kriterier för en utmärkt artikel.
                  </Typography>

                  <Typography paragraph>
                    För närvarande finns det 364 utmärkta artiklar av totalt 2
                    598 519 på svenska Wikipedia. Det betyder att 1/7139-del
                    (eller 0,014 procent) av artiklarna är utmärkta. En liten
                    guldstjärna (
                    <Star sx={{ color: "#FFD700", fontSize: "12px" }} />) i
                    jämnhöjd med artikelnamnet i höger hörn betyder att artikeln
                    är utmärkt.
                  </Typography>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Typography variant="h6" sx={{ mb: 3, fontWeight: "bold" }}>
                    Förutom att uppfylla kriterierna för en rekommenderad och en
                    bra artikel ska en utmärkt artikel vara ...
                  </Typography>

                  <Box sx={{ pl: 2 }}>
                    <Typography paragraph>
                      <strong>1.</strong> ... <strong>grundlig</strong>; den
                      saknar inga väsentliga faktauppgifter.
                    </Typography>

                    <Typography paragraph>
                      <strong>2.</strong> ... skriven med ett{" "}
                      <strong>genomarbetat språk</strong>
                    </Typography>

                    <Typography paragraph>
                      <strong>3.</strong> ... försedd med alla{" "}
                      <strong>länkar</strong> som är önskvärda enligt
                      riktlinjerna
                    </Typography>

                    <Typography paragraph>
                      <strong>4.</strong> ... uteslutande baserad på{" "}
                      <strong>
                        moderna, trovärdiga och auktoritativa källor
                      </strong>
                    </Typography>

                    <Typography paragraph>
                      <strong>5.</strong> ... väl försedd med tillförlitliga{" "}
                      <strong>källor</strong> och
                      <strong>noter</strong>
                    </Typography>

                    <Typography paragraph>
                      <strong>6.</strong> ... <strong>illustrerad</strong> med
                      relevanta bilder av god kvalitet
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
        </Box>

        <DialogContent>
          {previewsLoading ? (
            <Box sx={{ display: "flex", justifyContent: "center", p: 4 }}>
              <CircularProgress />
            </Box>
          ) : (
            <Grid container spacing={3} sx={{ mt: 1 }}>
              {articlePreviews.map((article, index) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                  <Card
                    sx={{
                      height: "100%",
                      display: "flex",
                      borderRadius: "10px",
                      flexDirection: "column",
                      "&:hover": {
                        boxShadow: 6,
                        cursor: "pointer",
                      },
                    }}
                    onClick={() => handleArticleSelect(article.url)}
                  >
                    {article.thumbnail && (
                      <CardMedia
                        component="img"
                        sx={{ height: 200, objectFit: "cover" }}
                        image={article.thumbnail}
                        alt={article.title}
                      />
                    )}
                    <CardContent sx={{ flexGrow: 1 }}>
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{ fontWeight: "600", fontSize: "1rem" }}
                      >
                        {article.title}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {article.description?.substring(0, 100)}
                        {article.description?.length > 200 ? "..." : ""}
                      </Typography>
                    </CardContent>
                    <CardActions sx={{ justifyContent: "center", pb: 2 }}>
                      <Button
                        variant="purp"
                        color="primary"
                        sx={{
                          width: "100%",
                          padding: "5px",
                          fontSize: "0.9rem",
                        }}
                      >
                        Välj artikel
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default WikiFetcher;
