import {
  AutoStories,
  Castle,
  Construction,
  EmojiEvents,
  Psychology,
  Science,
  School,
  Biotech,
  AutoGraph,
  Language,
  Cases,
  MenuBook,
  Psychology as PsychologyIcon,
  Functions,
} from "@mui/icons-material";

// Default themes that show when no subject is selected
export const defaultThemes = [
  {
    type: "subheader",
    label: "Teman som lämpar sig extra bra för flashcards:",
  },

  {
    value: "battles",
    label: "Stora slag i historien",
    content:
      "Skapa flashcards om avgörande slag i världshistorien. På framsidan ska slagets namn stå (t.ex. 'Slaget vid Waterloo', 'Slaget vid Stalingrad'). På baksidan ska årtalet, platsen och utgången av slaget anges, samt en kort beskrivning av dess historiska betydelse och de viktigaste ledarna involverade.",
    Icon: EmojiEvents,
  },
  {
    value: "leaders",
    label: "Historiska ledare",
    content:
      "Skapa flashcards om inflytelserika historiska ledare. På framsidan ska ledarens namn stå (t.ex. 'Elizabeth I', 'Mahatma Gandhi'). På baksidan ska ledarens levnadstid anges, tillsammans med deras viktigaste prestationer, reformer eller beslut, samt deras påverkan på sin tids samhälle och efterföljande generationer.",
    Icon: Castle,
  },
  {
    value: "revolutions",
    label: "Revolutioner",
    content:
      "Skapa flashcards om betydelsefulla revolutioner. På framsidan ska revolutionens namn stå (t.ex. 'Franska revolutionen', 'Ryska revolutionen'). På baksidan ska revolutionens tidsperiod anges, tillsammans med dess huvudsakliga orsaker, viktiga händelser under revolutionen, och dess kort- och långsiktiga konsekvenser för samhället och världspolitiken.",
    Icon: Construction,
  },
  {
    value: "dynasties",
    label: "Dynastier",
    content:
      "Skapa flashcards om historiska dynastier. På framsidan ska dynastins namn stå (t.ex. 'Tudordynastin', 'Mingdynastin'). På baksidan ska dynastins tidsperiod och grundare anges, tillsammans med några av dess mest betydande härskare och deras prestationer, samt dynastins övergripande påverkan på landets historia och kultur.",
    Icon: Castle,
  },
  {
    value: "presocratic",
    label: "Försokratiska filosofer",
    content:
      "Skapa flashcards om försokratiska filosofer. På framsidan, skriv filosofens namn. På baksidan, inkludera deras huvudsakliga bidrag till filosofin, tidsperiod de var aktiva, och eventuella kända citat eller koncept de introducerade. Täck filosofer som Thales, Anaximander, Herakleitos, och Pythagoras.",
    Icon: School,
  },
  {
    value: "plato",
    label: "Platons dialoger",
    content:
      "Utforma flashcards för Platons viktiga dialoger. På framsidan, ange dialogens titel. På baksidan, beskriv huvudtemat, lista viktiga karaktärer (särskilt Sokrates roll), och inkludera centrala filosofiska frågor som diskuteras. Inkludera dialoger som 'Republiken', 'Symposion', och 'Faidon'.",
    Icon: MenuBook,
  },
  {
    value: "aristotle",
    label: "Aristoteles kategorier",
    content:
      "Skapa flashcards för Aristoteles tio kategorier. På framsidan, skriv kategorinamnet. På baksidan, ge en koncis definition, ett tydligt exempel, och förklara kortfattat hur kategorin relaterar till Aristoteles metafysik. Inkludera alla tio kategorier som substans, kvantitet, och kvalitet.",
    Icon: Cases,
  },
  {
    value: "stoic",
    label: "Stoiska dygder",
    content:
      "Utforma flashcards om de fyra kardinaldygderna inom stoicismen. På framsidan, ange dygdens namn. På baksidan, förklara dess innebörd inom stoisk filosofi, ge ett praktiskt exempel på hur den kan tillämpas, och nämn en framstående stoisk filosof som betonade denna dygd. Inkludera visdom, rättvisa, mod, och måttfullhet.",
    Icon: Psychology,
  },
  {
    value: "fractals",
    label: "Fraktalgeometri",
    content:
      "Skapa flashcards om fraktalgeometri. Skriv namnet på en känd fraktal på framsidan. På baksidan, beskriv fraktalens egenskaper, såsom dess dimensionalitet och självlikhet. Inkludera information om hur fraktalen genereras matematiskt och vem som först beskrev eller upptäckte den. Ge exempel på hur fraktalen används inom vetenskap, konst eller andra områden, samt en kort förklaring av dess betydelse inom fraktalgeometri.",
    Icon: AutoGraph,
  },
  {
    value: "spanish_idioms",
    label: "Vanliga spanska idiom",
    content:
      "Skapa flashcards om spanska idiom. På framsidan, visa ett spanskt idiom. På baksidan, ge dess betydelse, bokstavliga översättning och ett användningsexempel.",
    Icon: Language,
  },
  {
    value: "cell_organelles",
    label: "Cellens organeller",
    content:
      "Skapa flashcards om cellens olika organeller. På framsidan, skriv namnet på en organell (t.ex. mitokondrie, endoplasmatiskt retikulum, Golgiapparat). På baksidan, beskriv organellens huvudfunktion i cellen samt dess struktur och placering i förhållande till andra organeller.",
    Icon: Biotech,
  },
  {
    value: "literary_movements",
    label: "Litterära rörelser",
    content:
      "Utforma flashcards med namnet på en litterär rörelse på framsidan. På baksidan, ange rörelsens tidsperiod, dess huvudsakliga filosofiska eller estetiska principer, minst tre kännetecken för litteraturen inom rörelsen, nämn tre betydande författare associerade med rörelsen, samt ett betydelsefullt verk som exemplifierar rörelsen.",
    Icon: MenuBook,
  },
  {
    value: "neuroplasticity",
    label: "Neuroplasticitet",
    content:
      "Skapa flashcards om neuroplasticitet. På framsidan, skriv definitioner och grundmekanismer (t.ex. synaptisk plasticitet, kortikal omorganisation, neurogenes). På baksidan, ge detaljerade förklaringar av mekanismerna, beskriv hur de bidrar till hjärnans förmåga att förändras och anpassa sig, diskutera deras betydelse för inlärning, minne och återhämtning efter hjärnskador, och ge exempel på hur kunskap om neuroplasticitet tillämpas inom neurovetenskap, psykologi och rehabiliteringsmedicin.",
    Icon: Psychology,
  },
  {
    value: "bv_formalism",
    label: "Batalin-Vilkovisky-formalism",
    content:
      "Skapa flashcards om Batalin-Vilkovisky-formalismen. På framsidan, ange de grundläggande principerna och definiera antifields. På baksidan, förklara dess användning i kvantisering av gaugeteorier, särskilt för teorier med öppna algebror. Diskutera relationen till BRST-formalismen och hur BV-formalismen hanterar mer komplexa gaugestrukturer.",
    Icon: Functions,
  },

  {
    value: "iliad",
    label: "Iliaden",
    content:
      "Utforma en serie flashcards som täcker hela Iliadens epos. Framsidan ska visa viktiga karaktärer, gudar, händelser eller koncept. Baksidan ska ge detaljer om deras roll och betydelse i berättelsen. Inkludera kort om Akilles, Hektor, Paris, Helena, grekiska och trojanska gudar, viktiga strider, och den trojanska hästen. Avsluta med kort om Homeros, eposets struktur, och dess betydelse för grekisk mytologi och litteratur.",
    Icon: MenuBook,
  },
];

// Subject-specific themes
export const subjectThemes = {
  biologi: [
    {
      type: "subheader",
      label: "Biologiteman som lämpar sig extra bra för denna lektionstyp:",
    },
  ],

  historia: [
    {
      type: "subheader",
      label: "Historieteman som lämpar sig extra bra för denna lektionstyp:",
    },
  ],

  fysik: [
    {
      type: "subheader",
      label: "Fysikteman som lämpar sig extra bra för denna lektionstyp:",
    },
  ],

  kemi: [
    {
      type: "subheader",
      label: "Kemiteman som lämpar sig extra bra för denna lektionstyp:",
    },
  ],

  matematik: [
    {
      type: "subheader",
      label: "Matematikteman som lämpar sig extra bra för denna lektionstyp:",
    },
  ],

  svenska: [
    {
      type: "subheader",
      label: "Svenskateman som lämpar sig extra bra för denna lektionstyp:",
    },
  ],
};

// Helper function to get appropriate themes based on subject
export const getThemes = (subject) => {
  if (!subject) return defaultThemes;
  return subjectThemes[subject] || defaultThemes;
};

export default getThemes;
