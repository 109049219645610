import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDropzone } from "react-dropzone";
import {
  Box,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  Button,
  TextField,
  Tooltip,
  IconButton,
  Grid,
  CircularProgress,
  Switch,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { collection, query, where, getDocs, addDoc } from "firebase/firestore";
import { db } from "./firebase";
import { useSnackbar } from "./SnackbarHandler";
import GradeSelector, { assessmentInstructions } from "./GradeSelector";
import { CheckCircleOutline } from "@mui/icons-material";
import { getStyles } from "../index";
import TableChartIcon from "@mui/icons-material/TableChart";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import { Tabs, Tab } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import mammoth from "mammoth";
import { FileUpload } from "@mui/icons-material";
import ThemeFlowBox from "./ThemeFlowBox";
import RubricsDialog from "./RubricsDialog";
import GenerateIcon from "@mui/icons-material/AutoAwesome";

import LanguageSelector from "./LanguageSelector";
import CustomInputSwitch from "./CustomInputSwitch";
import { callApi } from "./api";
import VisibilityIcon from "@mui/icons-material/Visibility";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { trackLesson } from "./functions";
import SegmentIcon from "@mui/icons-material/Segment";
import Logger from "./Logger";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate, Link } from "react-router-dom";

import ProgressBar from "./ProgressBar"; // Import the ProgressBar component

const EssayUpload = ({ user }) => {
  const [educationLevel, setEducationLevel] = useState("Gymnasium");
  const [selectedRubric, setSelectedRubric] = useState("");
  const [language, setLanguage] = useState("svenska");
  const [essayText, setEssayText] = useState("");
  const [file, setFile] = useState(null);
  const [rubrics, setRubrics] = useState([]);
  const [isManualEntry, setIsManualEntry] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [gradingResults, setGradingResults] = useState(null);
  const { showSnackbar } = useSnackbar();
  const [essayName, setEssayName] = useState("");
  const navigate = useNavigate();
  const theme = useTheme();
  const styles = getStyles(theme);
  const [isRubricsDialogOpen, setIsRubricsDialogOpen] = useState(false);
  const [progress, setProgress] = useState(0);
  const [grade, setGrade] = useState("åk9");
  const [viewingRubric, setViewingRubric] = useState(null);
  const [userRubrics, setUserRubrics] = useState([]);
  const [standardRubrics, setStandardRubrics] = useState([]);
  const [matrisTab, setMatrisTab] = useState(1);
  const lessonTitleRef = useRef(null);
  const [assessmentText, setAssessmentText] = useState(
    assessmentInstructions[grade],
  );

  useEffect(() => {
    setAssessmentText(assessmentInstructions[grade]);
  }, [grade]);
  // Add the handleMatrisTabChange function:
  const handleMatrisTabChange = (event, newValue) => {
    setMatrisTab(newValue);
  };

  useEffect(() => {
    Logger.log("selectedRubric: ", selectedRubric);
  }, [selectedRubric]);

  const fetchRubrics = async () => {
    try {
      const rubricsCollection = collection(db, "rubrics");
      // Fetch user-specific rubrics
      const userRubricsQuery = query(
        rubricsCollection,
        where("formativeComments", "==", true),
        where("userId", "==", user.uid),
      );
      const userRubricsSnapshot = await getDocs(userRubricsQuery);
      const userRubricsList = userRubricsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setUserRubrics(userRubricsList);
      // Fetch standard rubrics
      const standardRubricsQuery = query(
        rubricsCollection,
        where("custom", "==", true),
      );
      const standardRubricsSnapshot = await getDocs(standardRubricsQuery);
      const standardRubricsList = standardRubricsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setStandardRubrics(standardRubricsList);
      // Set first rubric as selected if there are any rubrics
      if (userRubricsList.length > 0) {
        setSelectedRubric(userRubricsList[0].id);
      } else if (standardRubricsList.length > 0) {
        setSelectedRubric(standardRubricsList[0].id);
      }
    } catch (error) {
      console.error("Error fetching rubrics:", error);
      showSnackbar(
        "Ett fel uppstod vid hämtning av bedömningsmatriser",
        "error",
      );
    }
  };

  // Use it in useEffect
  useEffect(() => {
    fetchRubrics();
  }, [user.uid, showSnackbar]);

  // useEffect(() => {
  //   const fetchRubrics = async () => {
  //     const rubricsCollection = collection(db, 'rubrics');
  //     const q = query(rubricsCollection, where('custom', '==', true));
  //     const querySnapshot = await getDocs(q);
  //     const rubricList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  //     setRubrics(rubricList);
  //     if (rubricList.length > 0) {
  //       setSelectedRubric(rubricList[0].id);
  //     }
  //   };

  //   fetchRubrics();
  // }, []);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (!file) return;

      const isSupported = [".docx", ".doc"].some((ext) =>
        file.name.toLowerCase().endsWith(ext),
      );

      if (!isSupported) {
        showSnackbar(
          "Filformatet stöds inte. Använd .docx eller .doc",
          "error",
        );
        return;
      }

      // Check file size (e.g., 5MB limit)
      const maxSize = 5 * 1024 * 1024; // 5MB in bytes
      if (file.size > maxSize) {
        showSnackbar("Filen är för stor. Maximal storlek är 5MB", "error");
        return;
      }

      setFile(file);
    },
    [showSnackbar],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
    },
    multiple: false,
  });

  const simulateProgress = (startProgress, endProgress, duration) => {
    const stepSize = 0.5;
    const steps = (endProgress - startProgress) / stepSize;
    const stepDuration = duration / steps;
    let currentProgress = startProgress;

    const intervalId = setInterval(() => {
      currentProgress += stepSize;
      if (currentProgress >= endProgress) {
        clearInterval(intervalId);
      } else {
        setProgress(currentProgress);
      }
    }, stepDuration);

    return () => clearInterval(intervalId);
  };

  const extractTextFromWordDocument = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async (event) => {
        try {
          const arrayBuffer = event.target.result;
          const result = await mammoth.extractRawText({ arrayBuffer });
          resolve(result.value);
        } catch (error) {
          reject(error);
        }
      };
      reader.onerror = (error) => reject(error);
      reader.readAsArrayBuffer(file);
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setProgress(0);
    let content = "";
    let fileName = "";

    try {
      if (isManualEntry) {
        if (!essayText) {
          throw new Error("Vänligen ange text");
        }
        if (!essayName) {
          throw new Error("Vänligen ange namn på texten");
        }
        content = essayText;
        fileName = essayName;
      } else {
        if (!file) {
          throw new Error("Vänligen ladda upp en fil");
        }
        content = await extractTextFromWordDocument(file);
        fileName = file.name;
      }

      const selectedRubricObject = [...userRubrics, ...standardRubrics].find(
        (rubric) => rubric.id === selectedRubric,
      );

      if (!selectedRubricObject) {
        throw new Error("Vänligen välj en bedömningsmatris");
      }

      const prompt = `Bedöm följande text baserat på den angivna bedömningsmatrisen. 
      Skriv bedömningen på ${language}. 
      Bedömningskriterier för denna årskurs: ${assessmentText}
      Ge en analys för varje kriterium, förslag på förbättringar och ett betyg. Formatera svaret som markdown med följande struktur:



<h2>Kriterieanalys</h2>

<h3>[Kriteriumets namn]</h3>
<p><strong>Analys:</strong> [Analys av detta kriterium på 3-4 meningar]</p>
<p><strong>Förbättringsförslag:</strong> [Förslag på förbättring på 3-4 meningar]</p>
<p><strong>Betyg:</strong> [Betyg för detta kriterium]</p>
[Upprepa för varje kriterium]

<h3>Övergripande betyg</h3>
<p>[Ange övergripande betyg här]</p>

<hr/>

<h2>Bedömningsresultat</h2>

<h3>Övergripande analys</h3>
<p>[Ange övergripande analys av texten här]</p>

<h3>Positiv feedback</h3>
<p>[Ange positiv feedback om textens styrkor här]</p>

<h3>Förbättringsplan</h3>
<ul>
  <li>[Förbättringsåtgärd 1]</li>
  <li>[Förbättringsåtgärd 2]</li>
  <li>[Förbättringsåtgärd 3]</li>
  <li>[Förbättringsåtgärd 4]</li>
  <li>[Förbättringsåtgärd 5]</li>
</ul>

<hr/>

<h2>Felrapport</h2>

<h3>Grammatikfel</h3>
<p>[Lista med grammatikfel och förslag på korrigeringar]</p>

<h3>Meningsbyggnadsfel</h3>
<p>[Beskrivning av problem med meningsbyggnad]</p>

<h3>Sammanhangsproblem</h3>
<p>[Identifierade problem med textens sammanhang]</p>

<h3>Tydlighetsproblem</h3>
<p>[Områden där texten kan förbättras för ökad tydlighet]</p>

<h3>Logiska inkonsekvenser</h3>
<p>[Identifierade logiska problem eller motsägelser i texten]</p>

<h3>Stavfel</h3>
<p>[Lista med stavfel och förslag på korrigeringar]</p>


Bedömningsmatris:
${JSON.stringify(selectedRubricObject)}

Text:
${content}`;

      const stopSimulation = simulateProgress(0, 90, 15000);

      let response = await callApi(
        [
          {
            role: "system",
            content: "Du är en hjälpsam AI-assistent som bedömer texter.",
          },
          { role: "user", content: prompt },
        ],
        10000,
        true,
        user.uid,
      );

      stopSimulation();
      setProgress(95);
      Logger.log(
        "Response in EssayUpload: ",
        JSON.stringify(response, null, 2),
      );
      let messageContent = response.data.choices[0].message.content.trim();

      if (messageContent.startsWith("```html")) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith("```")) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      Logger.log(messageContent);

      // Save the results to the database
      const docRef = await addDoc(collection(db, "essays"), {
        userId: user.uid,
        content: content,
        results: messageContent,
        fileName: fileName,
        educationLevel: grade,
        language: language,
        rubricId: selectedRubric,
        rubricName: selectedRubricObject.name, // Add this line to save the rubric name
        createdAt: new Date(),
      });
      setProgress(100);
      showSnackbar("Texten har bedömts och sparats", "success");
      await trackLesson(user.uid, "EssayUpload.js", null, null);
      // Navigate to the results page
      navigate(`/app/uppsats/${docRef.id}`);
    } catch (error) {
      console.error("Error grading essay:", error);
      showSnackbar(
        error.message || "Ett oväntat fel uppstod. Vänligen försök igen.",
        "error",
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ ...styles.container }}>
      <Box
        sx={{
          ...styles.innerContainer,
          width: { xs: "100%", md: "100%" },
          margin: "0 auto",
        }}
      >
        <Box
          sx={{
            ...styles.buttonsContainer,
            ...styles.additionalButtonsContainer,
          }}
        >
          <Button
            variant="purp"
            sx={{ ...styles.tightButton }}
            component={Link}
            startIcon={<AddIcon />}
            to="/app/matris/ny"
            sx={{ display: "none" }}
          >
            Ny matris
          </Button>

          <Button
            variant="purp"
            sx={{ ...styles.tightButton }}
            component={Link}
            startIcon={<SegmentIcon />}
            to="/app/uppsatser"
          >
            Dina bedömningar
          </Button>
        </Box>

        <Box sx={{ ...styles.headlineLessonBox, mt: 8 }}>
          <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
            1. Välj bedömningsmatris
          </Typography>
        </Box>
        <Box
          sx={{
            ...styles.freshBox,
            padding: "0px",
          }}
        >
          <Box sx={{ width: "100%", mb: 4 }}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                background:
                  theme.palette.mode === "dark" ? "#22303c" : "#f9f9fa",
                borderTopLeftRadius: "9px",
                borderTopRightRadius: "9px",
              }}
            >
              <Tabs
                value={matrisTab}
                onChange={handleMatrisTabChange}
                sx={{
                  "& .MuiTab-root": {
                    padding: "20px",
                    textTransform: "none",
                    fontSize: "1rem",
                  },
                }}
              >
                <Tab
                  icon={<TableChartIcon />}
                  iconPosition="start"
                  label="Mina matriser"
                />
                <Tab
                  icon={<CorporateFareIcon />}
                  iconPosition="start"
                  label="Standard matriser"
                />
              </Tabs>
            </Box>
            <Box sx={{ padding: "30px" }}>
              {matrisTab === 0 && userRubrics.length > 0 && (
                <Grid container spacing={3}>
                  {userRubrics.map((rubric) => (
                    <Grid item xs={12} md={4} key={rubric.id}>
                      <Box
                        sx={{
                          position: "relative",
                          "&:hover .visibility-icon": {
                            opacity: 1,
                          },
                        }}
                      >
                        <ThemeFlowBox
                          icon={RadioButtonUncheckedIcon}
                          title={rubric.name}
                          isSelected={selectedRubric === rubric.id}
                          onClick={() => setSelectedRubric(rubric.id)}
                          diffView={true}
                        />
                        <Tooltip title="Visa matris" placement="top">
                          <IconButton
                            size="small"
                            className="visibility-icon"
                            onClick={(e) => {
                              e.stopPropagation();
                              setViewingRubric(rubric);
                            }}
                            sx={{
                              position: "absolute",
                              top: 5,
                              right: 5,
                              color:
                                theme.palette.mode === "dark"
                                  ? "rgba(255, 255, 255, 0.7)"
                                  : "rgba(0, 0, 0, 0.54)",
                              opacity: 0,
                              transition: "opacity 0.2s ease-in-out",
                              "&:hover": {
                                color: "#8b5cf6",
                              },
                            }}
                          >
                            <VisibilityIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              )}

              {matrisTab === 1 && standardRubrics.length > 0 && (
                <Grid container spacing={3}>
                  {standardRubrics.map((rubric) => {
                    // Map rubric names to subtitles
                    const subtitles = {
                      Förklarande:
                        "Förklara varför något händer eller hur något fungerar",
                      Beskrivande:
                        "Ge en detaljerad beskrivning av ämnet eller fenomenet",
                      Argumenterande: "Övertyga läsaren med argument och bevis",
                      Berättande:
                        "Berätta en historia eller återge en händelse",
                      Instruerande: "Visa hur man gör något steg för steg",
                      Informerande:
                        "Presentera fakta och information om ett ämne",
                    };

                    return (
                      <Grid item xs={12} md={4} key={rubric.id}>
                        <Box
                          sx={{
                            position: "relative",
                            "&:hover .visibility-icon": {
                              opacity: 1,
                            },
                          }}
                        >
                          <ThemeFlowBox
                            icon={RadioButtonUncheckedIcon}
                            title={rubric.name}
                            subtitle={subtitles[rubric.name] || ""}
                            isSelected={selectedRubric === rubric.id}
                            onClick={() => setSelectedRubric(rubric.id)}
                            diffView={true}
                          />
                          <Tooltip title="Visa matris" placement="top">
                            <IconButton
                              size="small"
                              className="visibility-icon"
                              onClick={(e) => {
                                e.stopPropagation();
                                setViewingRubric(rubric);
                              }}
                              sx={{
                                position: "absolute",
                                top: 5,
                                right: 5,
                                color:
                                  theme.palette.mode === "dark"
                                    ? "rgba(255, 255, 255, 0.7)"
                                    : "rgba(0, 0, 0, 0.54)",
                                opacity: 0,
                                transition: "opacity 0.2s ease-in-out",
                                "&:hover": {
                                  color: "#8b5cf6",
                                },
                              }}
                            >
                              <VisibilityIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Grid>
                    );
                  })}
                </Grid>
              )}
            </Box>
          </Box>
          <Typography
            variant="formLabelSinUnderline"
            sx={{
              fontWeight: "500",
              display: "block",
              mb: 1,
              mt: 4,
              textAlign: "center",
            }}
          >
            Eller skapa en egen matris:
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "12px",
              width: "100%",
            }}
          >
            <Button
              variant="purp"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => setIsRubricsDialogOpen(true)}
              sx={{ mt: 2, mb: 6 }}
            >
              Skapa bedömningsmatris
            </Button>

            <RubricsDialog
              open={isRubricsDialogOpen}
              onClose={() => setIsRubricsDialogOpen(false)}
              user={user}
              onRubricsUpdated={fetchRubrics}
            />

            <RubricsDialog
              open={Boolean(viewingRubric)}
              onClose={() => setViewingRubric(null)}
              user={user}
              onRubricsUpdated={fetchRubrics}
              initialData={viewingRubric}
            />
          </div>
        </Box>

        <Box sx={{ ...styles.headlineLessonBox, mt: 12 }}>
          <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
            2. Specifika bedömningsinstruktioner
          </Typography>
        </Box>
        <Box sx={{ ...styles.freshBox, padding: "40px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" sx={{ fontWeight: "500", mt: 0 }}>
                Jag vill ha bedömningen på:
              </Typography>
              <Box sx={{ mt: 1, mb: 2 }}>
                <LanguageSelector
                  language={language}
                  setLanguage={setLanguage}
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: "500", mb: 1, mt: 0 }}
              >
                Årskurs:
              </Typography>
              <Box sx={{ mt: 0.5, mb: 2 }}>
                <GradeSelector
                  grade={grade}
                  setGrade={setGrade}
                  showHigherEducation={true}
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: "500", mb: 1, mt: 2 }}
              >
                Texten kommer bedömas utefter (anpassa utifrån behov):
              </Typography>
              <TextField
                multiline
                rows={4}
                fullWidth
                value={assessmentText}
                onChange={(e) => setAssessmentText(e.target.value)}
                sx={{ mt: 0.5 }}
              />
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ ...styles.headlineLessonBox, mt: 12 }}>
          <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
            3. Ladda upp dokument eller klistra in text
          </Typography>
        </Box>

        <Box sx={{ ...styles.freshBox, padding: "20px" }}>
          <form onSubmit={handleSubmit}>
            <Grid
              container
              spacing={2}
              sx={{
                justifyContent: "center", // Centers horizontally
                alignItems: "center", // Centers vertically
              }}
            >
              <Grid item xs={12} sm={8}>
                <Box sx={{ display: "none" }}>
                  <Typography
                    variant="formLabelSinUnderline"
                    sx={{ fontWeight: "500", mb: 1 }}
                  >
                    Utbildningsnivå:
                  </Typography>
                  <RadioGroup
                    value={educationLevel}
                    onChange={(e) => setEducationLevel(e.target.value)}
                    sx={{ mb: 4, mt: 1 }}
                  >
                    {[
                      "Mellanstadiet",
                      "Högstadiet",
                      "Gymnasium",
                      "Komvux",
                      "Högskola",
                      "Universitet",
                    ].map((level) => (
                      <FormControlLabel
                        key={level}
                        value={level}
                        control={<Radio />}
                        label={level.charAt(0).toUpperCase() + level.slice(1)}
                        sx={{
                          margin: 0,
                          "& .MuiTypography-root": { lineHeight: 1 },
                          "& .MuiRadio-root": { padding: "4px" },
                        }}
                      />
                    ))}
                  </RadioGroup>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    mb: 4,
                    mt: 2,
                  }}
                >
                  <CustomInputSwitch
                    selectedIndex={isManualEntry ? 1 : 0}
                    onIndexChange={(index) => setIsManualEntry(index === 1)}
                    options={["Ladda upp fil", "Skriv in text"]}
                    theme={theme}
                  />
                </Box>

                {isManualEntry ? (
                  <>
                    <TextField
                      multiline
                      rows={10}
                      fullWidth
                      value={essayText}
                      onChange={(e) => setEssayText(e.target.value)}
                      placeholder="Skriv eller klistra in texten här"
                      sx={{ mb: 2 }}
                    />
                    <Box>
                      <Typography
                        variant="formLabelSinUnderline"
                        sx={{ fontWeight: "500", mb: 1 }}
                      >
                        Namn:
                      </Typography>
                    </Box>
                    <TextField
                      fullWidth
                      value={essayName}
                      onChange={(e) => setEssayName(e.target.value)}
                      placeholder="Namn på texten"
                      required
                      sx={{ mb: 2, mt: 1 }}
                    />
                  </>
                ) : (
                  <Box {...getRootProps()}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",

                        minHeight: "300px",
                        width: "100%",
                        borderRadius: "16px",
                        bgcolor: isDragActive
                          ? theme.palette.mode === "dark"
                            ? "rgba(255, 255, 255, 0.1)"
                            : "rgba(0, 0, 0, 0.05)"
                          : theme.palette.mode === "dark"
                            ? "rgba(255, 255, 255, 0.05)"
                            : "rgba(0, 0, 0, 0.02)",
                        border: "2px dashed",
                        borderColor: isDragActive
                          ? "#8b5cf6"
                          : theme.palette.mode === "dark"
                            ? "rgba(255, 255, 255, 0.1)"
                            : "rgba(0, 0, 0, 0.1)",
                        transition: "all 0.3s ease-in-out",
                        "&:hover": {
                          borderColor: "#8b5cf6",
                          bgcolor:
                            theme.palette.mode === "dark"
                              ? "rgba(255, 255, 255, 0.08)"
                              : "rgba(0, 0, 0, 0.04)",
                        },
                      }}
                    >
                      <input {...getInputProps()} />

                      <CloudUploadIcon
                        sx={{
                          fontSize: 60,
                          color: "#8b5cf6",
                          mb: 3,
                          transform: isDragActive ? "scale(1.1)" : "scale(1)",
                          transition: "transform 0.2s ease-in-out",
                        }}
                      />
                      <Typography variant="h6" sx={{ mb: 1, fontWeight: 500 }}>
                        {isDragActive
                          ? "Släpp filen här"
                          : "Dra och släpp fil här"}
                      </Typography>
                      <Typography color="text.secondary" sx={{ mb: 3 }}>
                        eller
                      </Typography>
                      <Button
                        variant="print"
                        startIcon={<FileUpload />}
                        onClick={(e) => {
                          e.stopPropagation();
                          const fileInput =
                            document.querySelector('input[type="file"]');
                          if (fileInput) fileInput.click();
                        }}
                      >
                        Välj fil
                      </Button>
                    </Box>
                  </Box>
                )}

                {file && (
                  <Box sx={{ ...styles.successBox, minHeight: "180px", mt: 4 }}>
                    <CheckCircleOutline
                      sx={{ fontSize: 60, color: "success.main", mb: 3 }}
                    />
                    <Typography
                      variant="h6"
                      sx={{
                        color: "success.main",
                        fontWeight: 500,
                        textAlign: "center",
                        width: "80%",
                        margin: "0 auto",
                        mb: 2,
                      }}
                    >
                      Vald fil: {file.name}
                    </Typography>
                  </Box>
                )}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "12px",
                    width: "100%",
                  }}
                >
                  <Button
                    type="submit"
                    variant={
                      theme.palette.mode === "light" ? "purp" : "contained"
                    }
                    color="primary"
                    sx={{ mt: 8, mb: 4 }}
                    disabled={isLoading}
                    startIcon={
                      !isLoading ? (
                        <GenerateIcon style={{ color: "#fff" }} />
                      ) : (
                        <CircularProgress size={16} sx={{ color: "#fff" }} />
                      )
                    }
                  >
                    {isLoading ? "Betygsätter texten..." : "Betygsätt text"}
                  </Button>
                </div>
                {isLoading && (
                  <Grid item xs={12} sx={{ mt: 6 }}>
                    <ProgressBar
                      progress={progress}
                      label="Betygsätter text — tar 30-60 sekunder..."
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default EssayUpload;
