import React from 'react';
import { SvgIcon } from '@mui/material';

const WordIcon2019 = React.forwardRef((props, ref) => {
  return (
    <SvgIcon ref={ref} viewBox='0 0 165.73015 156.45262' {...props}>
      <g transform='translate(72.019155,34.971432)'>
        {/* White background */}
        <g transform='matrix(0.70582253,0,0,0.70582253,-2.9007322,-411.52918)'>
          <rect x='0' y='560.896' width='133.575' height='168.75' fill='#ffffff' />
        </g>

        {/* Border frame */}
        <g transform='matrix(0.70582253,0,0,0.70582253,-15.190372,-408.83223)'>
          <path
            d='M 9.48,551.34 C 4.23,551.34 0,555.55 0,560.79 V 720.2 c 0,5.23 4.23,9.45 9.48,9.45 h 135.33 c 5.25,0 9.48,-4.22 9.48,-9.45 V 560.79 c 0,-5.24 -4.23,-9.45 -9.48,-9.45 z m -1.9,7.83 H 146.63 V 721.73 H 7.58 Z'
            fill='currentColor'
          />
        </g>

        {/* Horizontal lines */}
        <g transform='matrix(0.70582253,0,0,0.70582253,8.0945708,-425.62589)'>
          <rect
            x='0'
            y='719.53802'
            width='97.416397'
            height='10.1084'
            transform='translate(0,-119.857)'
            fill='currentColor'
          />
          <rect
            x='0'
            y='719.53802'
            width='97.416397'
            height='10.1084'
            transform='translate(0,-72.2027)'
            fill='currentColor'
          />
          <rect
            x='0'
            y='719.53802'
            width='97.416397'
            height='10.1084'
            transform='translate(0,-96.0296)'
            fill='currentColor'
          />
          <rect
            x='0'
            y='719.53802'
            width='97.416397'
            height='10.1084'
            transform='translate(0,-48.3758)'
            fill='currentColor'
          />
          <rect
            x='0'
            y='719.53802'
            width='97.416397'
            height='10.1084'
            transform='translate(0,-23.8269)'
            fill='currentColor'
          />
          <rect x='0' y='719.53802' width='97.416397' height='10.1084' fill='currentColor' />
        </g>

        {/* Blue gradient background */}
        <path
          d='M 22.264618,121.48119 V -34.971432 l -94.283773,15.64103 V 105.83311 Z'
          fill='currentColor'
        />

        {/* White W letter */}
        <g transform='matrix(0.70582253,0,0,0.70582253,-55.710449,-446.47586)'>
          <path
            d='m 0,660.6 13,66.1 c 4.647163,0.4184 9.305988,0.69519 13.97,0.83 l 9.52,-43.47 c 0.395233,-1.37989 0.566059,-2.88754 0.73,-5.78 0.178927,2.91558 0.293383,3.96475 0.72,5.78 l 9.41,44.68 15.22,0.91 16.89,-73.78 -14.9,0.89 -8.57,48.96 c -0.421958,1.98927 -0.716121,3.99722 -0.72,5.77 0.146035,-1.46334 -0.09838,-3.42431 -0.72,-5.77 l -9.52,-47.8 -13.45,0.8 -10.25,47 c -0.273481,0.92316 -0.539679,2.58707 -0.72,4.33 -0.06968,-1.49804 -0.303067,-3.48451 -0.72,-5.78 l -6.93,-44.44 z'
            fill='#ffffff'
          />
        </g>
      </g>
    </SvgIcon>
  );
});

WordIcon2019.displayName = 'WordIcon2019';
export default WordIcon2019;
