import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListSubheader,
} from "@mui/material";

const gradeInstructions = {
  åk1: "För årskurs 1 (7 år) ska lektionen vara mycket enkel med lättförståeliga koncept och begrepp som passar nybörjare.",
  åk2: "För årskurs 2 (8 år) ska lektionen vara enkel och fokusera på att bygga grundläggande kunskap med tydliga exempel.",
  åk3: "För årskurs 3 (9 år) ska lektionen vara enkel men innehålla lite mer detaljerad information och börja introducera nya begrepp.",
  åk4: "För årskurs 4 (10 år) ska lektionen börja introducera koncept på medelnivå och uppmuntra till mer självständigt tänkande.",
  åk5: "För årskurs 5 (11 år) ska lektionen presentera en medelnivå av svårighet och inkludera mer komplexa idéer och samband.",
  åk6: "För årskurs 6 (12 år) ska lektionen innehålla medium svårighetsgrad med djupare förklaringar och mer avancerade koncept.",
  åk7: "För årskurs 7 (13 år) ska lektionen vara utmanande med fokus på kritiskt tänkande och mer avancerade ämnen som kräver analys.",
  åk8: "För årskurs 8 (14 år) ska lektionen vara ganska utmanande med avancerade koncept som kräver god förståelse för ämnet.",
  åk9: "För årskurs 9 (15 år) ska lektionen vara mycket utmanande med fokus på djup analys, problemlösning och förberedelse inför gymnasiet.",
  firstRing:
    "För första ring på gymnasiet (16 år) ska lektionen vara på en hög svårighetsnivå och förbereda eleverna för komplexa ämnen.",
  secondRing:
    "För andra ring på gymnasiet (17 år) ska lektionen utmana eleverna med djupgående och detaljerade ämnen som kräver omfattande förståelse.",
  thirdRing:
    "För tredje ring på gymnasiet (18 år) ska lektionen vara mycket avancerad och spegla en nästan högskolemässig svårighetsgrad.",

  // Updated university/college instructions
  uniYear1: `För första året på universitet/högskola ska materialet introducera akademiska koncept och metoder med fokus på grundläggande vetenskaplig förståelse. Innehållet ska omfatta:
- Introduktion till vetenskaplig metodik och akademiskt tänkande
- Grundläggande forskningsmetoder och statistik
- Kritiskt förhållningssätt till källor och information
- Akademisk läsförståelse och textanalys
- Grundläggande teorier inom ämnesområdet`,

  uniYear2: `För andra året på universitet/högskola ska materialet fördjupa teoretisk kunskap och utveckla analytiska färdigheter inom specifika ämnesområden. Fokus ligger på:
- Fördjupade teoretiska ramverk och modeller
- Kvalitativa och kvantitativa forskningsmetoder
- Vetenskaplig argumentation och analys
- Självständig problemformulering
- Integration av olika teoretiska perspektiv`,

  uniYear3: `För tredje året på universitet/högskola ska materialet behandla avancerade teorier och metoder med fokus på självständig forskning och analys. Detta inkluderar:
- Avancerad teoretisk förståelse och tillämpning
- Forskningsdesign och metodval
- Kritisk analys av aktuell forskning
- Självständigt forskningsarbete
- Vetenskaplig publikation och presentation`,

  masterYear1: `För första året på masternivå ska materialet vara mycket avancerat med fokus på specialiserad kunskap och forskningsmetodik. Innehållet omfattar:
- Avancerade forskningsmetoder och design
- Djupgående teoretisk specialisering
- Forskningsetik och vetenskapsteori
- Systematiska litteraturstudier
- Utveckling av forskningsprojekt`,

  masterYear2: `För andra året på masternivå ska materialet vara på expertnivå med fokus på självständig forskning och vetenskapligt bidrag. Detta innebär:
- Expertkunskap inom specialiseringsområdet
- Innovativa forskningsmetoder
- Teoretiskt och metodologiskt bidrag till fältet
- Publicerbar forskningskvalitet
- Forskningsledarskap och projekthantering`,

  doctorate: `För doktorandnivå ska materialet vara på forskningsnivå med fokus på att utveckla ny kunskap inom fältet. Detta omfattar:
- Originell och nyskapande forskning
- Utveckling av nya teorier och metoder
- Internationell forskningsstandard
- Vetenskapligt ledarskap
- Bidrag till forskningsfältets utveckling`,
};

const assessmentInstructions = {
  åk1: "För årskurs 1 bedöms grundläggande skrivförmåga, meningsbyggnad och enkla berättelser med tydlig början och slut.",
  åk2: "För årskurs 2 bedöms förmågan att skriva sammanhängande texter med enkel struktur och grundläggande skiljetecken.",
  åk3: "För årskurs 3 bedöms texternas röda tråd, styckeindelning och variation i meningsbyggnad.",
  åk4: "För årskurs 4 bedöms textbindning, styckeindelning och förmågan att anpassa texten efter syfte och mottagare.",
  åk5: "För årskurs 5 bedöms språklig variation, textstruktur och förmågan att utveckla innehållet med relevanta detaljer.",
  åk6: "För årskurs 6 bedöms genreanpassning, språklig säkerhet och förmågan att variera språket efter sammanhang.",
  åk7: "För årskurs 7 bedöms textens disposition, källhantering och förmågan att uttrycka egna åsikter med argument.",
  åk8: "För årskurs 8 bedöms analys, resonemang och förmågan att integrera källor på ett relevant sätt.",
  åk9: "För årskurs 9 bedöms kritiskt tänkande, självständiga slutsatser och förmågan att skriva välstrukturerade texter i olika genrer.",
  firstRing:
    "För första ring bedöms förmågan att skriva nyanserat, med retoriska verktyg och tydlig argumentation.",
  secondRing:
    "För andra ring bedöms vetenskapligt skrivande, källkritik och förmågan att analysera komplexa sammanhang.",
  thirdRing:
    "För tredje ring bedöms akademiskt skrivande, självständig analys och förmågan att producera längre utredande texter.",

  // New university/college assessment instructions
  uniYear1: `För första året på universitet bedöms:
- Grundläggande akademiskt skrivande och formalia
- Källhantering och referensteknik
- Förmåga att tillämpa grundläggande vetenskaplig metodik
- Analytisk förmåga och kritiskt tänkande
- Förståelse för teoretiska koncept och modeller
- Förmåga att strukturera akademiska texter`,

  uniYear2: `För andra året på universitet bedöms:
- Fördjupad teoretisk förståelse och tillämpning
- Metodologisk medvetenhet och tillämpning
- Analytisk skärpa och argumentationsförmåga
- Självständig problemformulering och analys
- Förmåga att genomföra mindre forskningsprojekt
- Integration av olika teoretiska perspektiv`,

  uniYear3: `För tredje året på universitet bedöms:
- Avancerad teoretisk förståelse och tillämpning
- Metodologisk säkerhet och självständighet
- Förmåga att producera vetenskapliga texter
- Kritisk analys av forskningslitteratur
- Självständig forskningsdesign
- Vetenskaplig presentation och kommunikation`,

  masterYear1: `För första året på masternivå bedöms:
- Avancerad forskningsmetodologisk kompetens
- Teoretisk fördjupning och specialisering
- Förmåga att designa forskningsprojekt
- Kritisk analys av forskningsfältet
- Vetenskaplig argumentation på hög nivå
- Publiceringsmogen akademisk text`,

  masterYear2: `För andra året på masternivå bedöms:
- Expertis inom forskningsområdet
- Metodologisk innovation och utveckling
- Förmåga att bidra till forskningsfältet
- Vetenskaplig originalitet och kreativitet
- Forskningsledarskap och projekthantering
- Internationell publiceringsstandard`,
};

export { gradeInstructions, assessmentInstructions };

const GradeSelector = ({ grade, setGrade, showHigherEducation }) => {
  return (
    <FormControl fullWidth margin="normal" sx={{ mt: 0, mb: 0 }}>
      <Select
        defaultValue="åk6"
        value={grade}
        onChange={(e) => setGrade(e.target.value)}
      >
        <ListSubheader
          sx={{
            color: "text.secondary",
            bgcolor: (theme) => theme.palette.background.default + "90",
          }}
        >
          Grundskolan
        </ListSubheader>

        <MenuItem value="åk1">Åk 1</MenuItem>
        <MenuItem value="åk2">Åk 2</MenuItem>
        <MenuItem value="åk3">Åk 3</MenuItem>
        <MenuItem value="åk4">Åk 4</MenuItem>
        <MenuItem value="åk5">Åk 5</MenuItem>
        <MenuItem value="åk6">Åk 6</MenuItem>
        <MenuItem value="åk7">Åk 7</MenuItem>
        <MenuItem value="åk8">Åk 8</MenuItem>
        <MenuItem value="åk9">Åk 9</MenuItem>
        <ListSubheader
          sx={{
            color: "text.secondary",
            bgcolor: (theme) => theme.palette.background.default + "90",
          }}
        >
          Gymnasiet
        </ListSubheader>
        <MenuItem value="firstRing">1:an</MenuItem>
        <MenuItem value="secondRing">2:an</MenuItem>
        <MenuItem value="thirdRing">3:an</MenuItem>

        <ListSubheader
          sx={{
            color: "text.secondary",
            bgcolor: (theme) => theme.palette.background.default + "90",
            display: showHigherEducation ? "block" : "none", // Hide with CSS instead
          }}
        >
          Universitet/Högskola
        </ListSubheader>
        <MenuItem
          value="uniYear1"
          sx={{ display: showHigherEducation ? "flex" : "none" }}
        >
          År 1 (Kandidat)
        </MenuItem>
        <MenuItem
          value="uniYear2"
          sx={{ display: showHigherEducation ? "flex" : "none" }}
        >
          År 2 (Kandidat)
        </MenuItem>
        <MenuItem
          value="uniYear3"
          sx={{ display: showHigherEducation ? "flex" : "none" }}
        >
          År 3 (Kandidat)
        </MenuItem>
        <MenuItem
          value="masterYear1"
          sx={{ display: showHigherEducation ? "flex" : "none" }}
        >
          År 1 (Master)
        </MenuItem>
        <MenuItem
          value="masterYear2"
          sx={{ display: showHigherEducation ? "flex" : "none" }}
        >
          År 2 (Master)
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default GradeSelector;
