import React, { useState, useEffect } from "react";
import { Grid, Popper, Paper, Box, IconButton } from "@mui/material";
import BallotIcon from "@mui/icons-material/Ballot";
import ShortTextIcon from "@mui/icons-material/ShortText";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import SchoolIcon from "@mui/icons-material/School";
import ThemeFlowBox from "../ThemeFlowBox";
import NewspaperIcon from "@mui/icons-material/Newspaper"; // Ny ikon för nutidsfrågor

import CenterFocusWeakIcon from "@mui/icons-material/CenterFocusWeak";
import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong";
const QuestionTypeSelector = ({
  setQuestionType,
  disableTrueFalse = false,
  theme,
  user,
}) => {
  const [selectedType, setSelectedType] = useState("multipleChoice");
  const [anchorEl, setAnchorEl] = useState(null);
  const [previewType, setPreviewType] = useState(null);
  const [popperPlacement, setPopperPlacement] = useState("right-start");

  useEffect(() => {
    setQuestionType(selectedType);
  }, [selectedType, setQuestionType]);

  const handleChange = (value) => {
    if (!(disableTrueFalse && value === "trueFalse")) {
      setSelectedType(value);
    }
  };

  const handleMouseEnter = (event, option) => {
    const rect = event.currentTarget.getBoundingClientRect();
    const viewportWidth = window.innerWidth;
    // If the element is in the right half of the viewport, show popper on the left
    const placement =
      rect.right > viewportWidth / 2 ? "left-start" : "right-start";
    setPopperPlacement(placement);
    setAnchorEl(event.currentTarget);
    setPreviewType(option);
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
    setPreviewType(null);
  };

  const options = [
    {
      value: "multipleChoice",
      label: "Flervalsfrågor",
      description: "Flervalsfrågor med ett korrekt svar",
      icon: BallotIcon,
      previewImage: "/img/flerval.png",
    },
    {
      value: "exam",
      label: "Prov",
      description: "Måste själv resonera och skriva in svaret i ett textfält",
      icon: SchoolIcon,
      previewImage: "/img/prov.png",
    },
    {
      value: "fill-in-the-blank",
      label: "Lucktext",
      description: "Frågor där man väljer ett utelämnat ord",
      icon: ShortTextIcon,
      previewImage: "/img/lucktext.png",
    },
    {
      value: "true-or-false",
      label: "Sant/Falskt",
      description: "Påståenden som är sanna eller falska",
      icon: FactCheckIcon,
      previewImage: "/img/exam.png",
    },
    ...(user.admin
      ? [
          {
            value: "current-events",
            label: "Nutidsfrågor (1X2)",
            description: "Frågor med en svarstalong med 1X2",
            icon: NewspaperIcon,
            previewImage: "/img/nutidsfraga.png",
          },
        ]
      : []),
  ];

  const open = Boolean(anchorEl);

  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{
          width: "100%",
        }}
      >
        {options.map((option) => (
          <Grid item xs={12} md={3} key={option.value}>
            <Box sx={{ position: "relative" }}>
              <ThemeFlowBox
                icon={option.icon}
                title={option.label}
                subtitle={option.description}
                label={option.value === "exam" ? "NYTT" : undefined}
                diffView={true}
                onClick={() => handleChange(option.value)}
                isSelected={selectedType === option.value}
                clickable={!(disableTrueFalse && option.value === "trueFalse")}
              />

              <IconButton
                sx={{ position: "absolute", top: 2, left: 2 }}
                onMouseEnter={(e) => handleMouseEnter(e, option)}
                onMouseLeave={handleMouseLeave}
              >
                <CenterFocusStrongIcon sx={{ fontSize: "15px" }} />
              </IconButton>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement={popperPlacement}
        sx={{ zIndex: 1300 }}
      >
        <Paper
          elevation={3}
          sx={{
            maxWidth: 400,
            backgroundColor: "background.paper",
            ml: popperPlacement === "right-start" ? 2 : undefined,
            mr: popperPlacement === "left-start" ? 2 : undefined,
            borderRadius: 2,
            p: 1,
            pb: 0.2,
          }}
        >
          {previewType && (
            <>
              <Box
                component="img"
                src={previewType.previewImage}
                alt={`${previewType.label} preview`}
                sx={{
                  width: "100%",
                  height: "auto",
                  borderRadius: 2,
                }}
              />
            </>
          )}
        </Paper>
      </Popper>
    </>
  );
};

export default QuestionTypeSelector;
