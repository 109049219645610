import React from "react";
import {
  Box,
  Typography,
  Grid,
  TextField,
  IconButton,
  Button,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import PersonIcon from "@mui/icons-material/Person";
import SchoolIcon from "@mui/icons-material/School";
import FamilyRestroomIcon from "@mui/icons-material/FamilyRestroom";
import StarIcon from "@mui/icons-material/Star";
import EventIcon from "@mui/icons-material/Event";
import HistoryIcon from "@mui/icons-material/History";
import GroupsIcon from "@mui/icons-material/Groups";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import HoverableImage from "./HoverableImage";
import { InstructionItem } from "./functions";

const HistoricPersonDisplay = ({
  lessonData,
  setLessonData,
  viewMode,
  handleImageButtonClick,
  isGeneratingImages,
  loadingImageIndex,
  theme,
  showSnackbar,
  user,
  styles,
  editMode,
}) => {
  const headingStyle = {
    fontWeight: 600,
    color: theme.palette.primary.secondary,
    marginBottom: 5,
  };

  const handleTextChange = (path, value) => {
    setLessonData((prev) => {
      const newData = { ...prev };
      let current = newData;
      const keys = path.split(".");
      const lastKey = keys.pop();

      for (const key of keys) {
        current = current[key];
      }
      current[lastKey] = value;

      return newData;
    });
  };

  const handleArrayChange = (path, index, value) => {
    setLessonData((prev) => {
      const newData = { ...prev };
      let current = newData;
      const keys = path.split(".");
      const lastKey = keys.pop();

      for (const key of keys) {
        current = current[key];
      }
      current[lastKey][index] = value;

      return newData;
    });
  };

  const handleAddArrayItem = (path, defaultValue = "") => {
    setLessonData((prev) => {
      const newData = { ...prev };
      let current = newData;
      const keys = path.split(".");
      const lastKey = keys.pop();

      for (const key of keys) {
        current = current[key];
      }
      current[lastKey] = [...current[lastKey], defaultValue];

      return newData;
    });
  };

  const handleDeleteArrayItem = (path, index) => {
    setLessonData((prev) => {
      const newData = { ...prev };
      let current = newData;
      const keys = path.split(".");
      const lastKey = keys.pop();

      for (const key of keys) {
        current = current[key];
      }
      current[lastKey] = current[lastKey].filter((_, i) => i !== index);

      return newData;
    });
  };

  const EditableText = ({ value, path, multiline = false }) =>
    editMode ? (
      <TextField
        fullWidth
        multiline={multiline}
        minRows={multiline ? 3 : 1}
        value={value}
        onChange={(e) => handleTextChange(path, e.target.value)}
        variant="outlined"
        size="small"
        sx={{ mb: 1 }}
      />
    ) : (
      <Typography variant="body1">{value}</Typography>
    );

  return (
    <Box sx={{ padding: 0 }}>
      {/* Top Section with Basic Info and Image */}
      <Box
        sx={{ ...styles.freshBox, ...styles.softBoxShadow, padding: "30px" }}
      >
        <Grid container spacing={4}>
          {/* Basic Info */}
          <Grid item xs={12} md={7}>
            {editMode ? (
              <TextField
                fullWidth
                value={lessonData.name}
                onChange={(e) => handleTextChange("name", e.target.value)}
                variant="outlined"
                size="large"
                sx={{ mb: 3 }}
              />
            ) : (
              <Typography
                variant="h4"
                gutterBottom
                sx={{ ...headingStyle, mb: 3 }}
              >
                {lessonData.name}
              </Typography>
            )}

            <EditableText
              value={lessonData.introduction}
              path="introduction"
              multiline
            />

            <Box sx={{ mt: 3 }}>
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <EventIcon color="primary" sx={{ mr: 2 }} />
                <Box sx={{ width: "100%" }}>
                  <Typography variant="subtitle1" sx={{ fontWeight: "500" }}>
                    Födelse
                  </Typography>
                  <EditableText
                    value={lessonData.early_life.birth}
                    path="early_life.birth"
                  />
                </Box>
              </Box>

              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <FamilyRestroomIcon color="primary" sx={{ mr: 2 }} />
                <Box sx={{ width: "100%" }}>
                  <Typography variant="subtitle1" sx={{ fontWeight: "500" }}>
                    Familj
                  </Typography>
                  <EditableText
                    value={lessonData.early_life.family}
                    path="early_life.family"
                  />
                </Box>
              </Box>

              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <SchoolIcon color="primary" sx={{ mr: 2 }} />
                <Box sx={{ width: "100%" }}>
                  <Typography variant="subtitle1" sx={{ fontWeight: "500" }}>
                    Utbildning
                  </Typography>
                  <EditableText
                    value={lessonData.early_life.education}
                    path="early_life.education"
                  />
                </Box>
              </Box>
            </Box>
          </Grid>

          {/* Image */}
          <Grid item xs={12} md={5}>
            <Box sx={{ textAlign: "center" }}>
              <HoverableImage
                imageUrl={lessonData.imageUrl}
                onGenerateClick={() => handleImageButtonClick(0)}
                isLoading={isGeneratingImages}
                loadingIndex={loadingImageIndex}
                currentIndex={0}
                userId={user.uid}
                onImageUpdate={(url) =>
                  setLessonData((prev) => ({ ...prev, imageUrl: url }))
                }
                theme={theme}
                showSnackbar={showSnackbar}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Achievements Section */}
      <Box
        sx={{
          ...styles.freshBox,
          ...styles.softBoxShadow,
          padding: "30px",
          mt: 8,
        }}
      >
        <Typography
          variant="h5"
          sx={{ ...headingStyle, display: "flex", alignItems: "center" }}
        >
          <StarIcon sx={{ mr: 1, verticalAlign: "middle" }} />
          Prestationer
        </Typography>
        <Grid container spacing={2}>
          {Object.entries(lessonData.achievements).map(
            ([category, achievements], categoryIndex) => (
              <Grid item xs={12} key={categoryIndex}>
                {editMode ? (
                  <TextField
                    fullWidth
                    value={category
                      .split("_")
                      .map((word, index) =>
                        index === 0
                          ? word.charAt(0).toUpperCase() + word.slice(1)
                          : word.toLowerCase(),
                      )
                      .join(" ")}
                    onChange={(e) => {
                      setLessonData((prev) => {
                        const entries = Object.entries(prev.achievements);
                        entries[categoryIndex] = [
                          e.target.value.replace(/ /g, "_"),
                          entries[categoryIndex][1],
                        ];
                        const newAchievements = Object.fromEntries(entries);
                        return {
                          ...prev,
                          achievements: newAchievements,
                        };
                      });
                    }}
                    variant="outlined"
                    InputProps={{
                      sx: {
                        "& input": {
                          fontWeight: 600,
                        },
                      },
                    }}
                    sx={{ mb: 2 }}
                  />
                ) : (
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ ...styles.softerText, fontWeight: "600", mb: 2 }}
                  >
                    {category
                      .split("_")
                      .map((word, index) =>
                        index === 0
                          ? word.charAt(0).toUpperCase() + word.slice(1)
                          : word.toLowerCase(),
                      )
                      .join(" ")}
                  </Typography>
                )}
                <Box>
                  {achievements.map((achievement, index) =>
                    editMode ? (
                      <Box
                        key={index}
                        sx={{ display: "flex", gap: 1, mb: 1.5 }}
                      >
                        <TextField
                          fullWidth
                          value={achievement}
                          onChange={(e) =>
                            handleArrayChange(
                              `achievements.${category}`,
                              index,
                              e.target.value,
                            )
                          }
                          variant="outlined"
                        />
                        <IconButton
                          color="secondary"
                          onClick={() =>
                            handleDeleteArrayItem(
                              `achievements.${category}`,
                              index,
                            )
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    ) : (
                      <InstructionItem
                        key={index}
                        text={achievement}
                        simple={true}
                        marginBottom={1.5}
                      />
                    ),
                  )}
                  {editMode && (
                    <Button
                      startIcon={<AddIcon />}
                      onClick={() =>
                        handleAddArrayItem(`achievements.${category}`)
                      }
                      variant="print"
                      size="small"
                      sx={{ mb: 2 }}
                    >
                      Lägg till ny
                    </Button>
                  )}
                </Box>
              </Grid>
            ),
          )}
        </Grid>
      </Box>

      {/* Challenges Section */}
      <Box
        sx={{
          ...styles.freshBox,
          ...styles.softBoxShadow,
          padding: "30px",
          mt: 8,
        }}
      >
        <Typography
          variant="h5"
          sx={{ ...headingStyle, display: "flex", alignItems: "center" }}
        >
          <HistoryIcon sx={{ mr: 1, verticalAlign: "middle" }} />
          Utmaningar
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                ...styles.freshBox,
                ...styles.softBoxShadow,
                padding: "30px",
              }}
            >
              <Typography
                variant="h6"
                gutterBottom
                color="secondary"
                sx={{ ...styles.softerText, fontWeight: "600", mb: 2 }}
              >
                Hinder
              </Typography>
              <Box>
                {lessonData.challenges.obstacles_faced.map((obstacle, index) =>
                  editMode ? (
                    <Box key={index} sx={{ display: "flex", gap: 1, mb: 1.5 }}>
                      <TextField
                        fullWidth
                        value={obstacle}
                        onChange={(e) =>
                          handleArrayChange(
                            "challenges.obstacles_faced",
                            index,
                            e.target.value,
                          )
                        }
                        variant="outlined"
                      />
                      <IconButton
                        onClick={() =>
                          handleDeleteArrayItem(
                            "challenges.obstacles_faced",
                            index,
                          )
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  ) : (
                    <InstructionItem
                      key={index}
                      text={obstacle}
                      simple={true}
                      marginBottom={1.5}
                    />
                  ),
                )}
                {editMode && (
                  <Button
                    startIcon={<AddIcon />}
                    onClick={() =>
                      handleAddArrayItem("challenges.obstacles_faced")
                    }
                    variant="print"
                    size="small"
                    sx={{ mb: 2 }}
                  >
                    Lägg till nytt hinder
                  </Button>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                ...styles.freshBox,
                ...styles.softBoxShadow,
                padding: "30px",
              }}
            >
              <Typography
                variant="h6"
                gutterBottom
                color="secondary"
                sx={{ ...styles.softerText, fontWeight: "600", mb: 2 }}
              >
                Kontroverser
              </Typography>
              <Box>
                {lessonData.challenges.controversies.map(
                  (controversy, index) =>
                    editMode ? (
                      <Box
                        key={index}
                        sx={{ display: "flex", gap: 1, mb: 1.5 }}
                      >
                        <TextField
                          fullWidth
                          value={controversy}
                          onChange={(e) =>
                            handleArrayChange(
                              "challenges.controversies",
                              index,
                              e.target.value,
                            )
                          }
                          variant="outlined"
                        />
                        <IconButton
                          color="secondary"
                          onClick={() =>
                            handleDeleteArrayItem(
                              "challenges.controversies",
                              index,
                            )
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    ) : (
                      <InstructionItem
                        key={index}
                        text={controversy}
                        simple={true}
                        marginBottom={1.5}
                      />
                    ),
                )}
                {editMode && (
                  <Button
                    startIcon={<AddIcon />}
                    onClick={() =>
                      handleAddArrayItem("challenges.controversies")
                    }
                    variant="print"
                    sx={{ mb: 2 }}
                  >
                    Lägg till ny kontrovers
                  </Button>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Legacy Section */}
      <Box
        sx={{
          ...styles.freshBox,
          ...styles.softBoxShadow,
          padding: "30px",
          mt: 8,
        }}
      >
        <Typography
          variant="h5"
          sx={{ ...headingStyle, display: "flex", alignItems: "center" }}
        >
          <AutoStoriesIcon sx={{ mr: 1, verticalAlign: "bottom" }} />
          Senare liv och arv
        </Typography>
        <Box sx={{ p: 2, pt: 0 }}>
          <Typography variant="subtitle1" sx={{ fontWeight: "500", mb: 1 }}>
            Senare år:
          </Typography>
          <EditableText
            value={lessonData.later_life_and_legacy.later_years}
            path="later_life_and_legacy.later_years"
            multiline
          />

          <Typography
            variant="subtitle1"
            sx={{ fontWeight: "500", mb: 1, mt: 2 }}
          >
            Död:
          </Typography>
          <EditableText
            value={lessonData.later_life_and_legacy.death}
            path="later_life_and_legacy.death"
            multiline
          />

          <Typography
            variant="subtitle1"
            sx={{ fontWeight: "500", mb: 1, mt: 2 }}
          >
            Arv:
          </Typography>
          <EditableText
            value={lessonData.later_life_and_legacy.legacy}
            path="later_life_and_legacy.legacy"
            multiline
          />
        </Box>
      </Box>

      {/* Historical Context */}
      <Box
        sx={{
          ...styles.freshBox,
          ...styles.softBoxShadow,
          padding: "30px",
          mt: 8,
        }}
      >
        <Typography
          variant="h5"
          sx={{ ...headingStyle, display: "flex", alignItems: "center" }}
        >
          <GroupsIcon sx={{ mr: 1 }} />
          Historisk kontext
        </Typography>
        <Box sx={{ p: 2, pt: 0 }}>
          <EditableText
            value={lessonData.contextual_information.historical_context}
            path="contextual_information.historical_context"
            multiline
          />

          <Typography
            variant="h6"
            gutterBottom
            color="secondary"
            sx={{ ...styles.softerText, fontWeight: "600", mb: 2, mt: 4 }}
          >
            Samtida personer
          </Typography>
          <Box>
            {lessonData.contextual_information.contemporaries.map(
              (person, index) =>
                editMode ? (
                  <Box key={index} sx={{ display: "flex", gap: 1, mb: 1.5 }}>
                    <TextField
                      fullWidth
                      value={person}
                      onChange={(e) =>
                        handleArrayChange(
                          "contextual_information.contemporaries",
                          index,
                          e.target.value,
                        )
                      }
                      variant="outlined"
                    />
                    <IconButton
                      color="secondary"
                      onClick={() =>
                        handleDeleteArrayItem(
                          "contextual_information.contemporaries",
                          index,
                        )
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                ) : (
                  <Box
                    key={index}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <InstructionItem
                      text={person}
                      simple={true}
                      marginBottom={1.5}
                    />
                  </Box>
                ),
            )}

            {editMode && (
              <Button
                startIcon={<AddIcon />}
                onClick={() =>
                  handleAddArrayItem("contextual_information.contemporaries")
                }
                variant="print"
                size="small"
                sx={{ mb: 2 }}
              >
                Lägg till samtida person
              </Button>
            )}
          </Box>
        </Box>
      </Box>

      {/* Interesting Facts */}
      <Box
        sx={{
          ...styles.freshBox,
          ...styles.softBoxShadow,
          padding: "30px",
          mt: 8,
          mb: 6,
        }}
      >
        <Typography
          variant="h5"
          sx={{ ...headingStyle, display: "flex", alignItems: "center" }}
        >
          <LightbulbIcon sx={{ mr: 1, verticalAlign: "bottom" }} />
          Intressanta fakta
        </Typography>
        <Box>
          {lessonData.interesting_facts.map((fact, index) =>
            editMode ? (
              <Box key={index} sx={{ display: "flex", gap: 1, mb: 1.5 }}>
                <TextField
                  fullWidth
                  value={fact}
                  onChange={(e) =>
                    handleArrayChange(
                      "interesting_facts",
                      index,
                      e.target.value,
                    )
                  }
                  variant="outlined"
                />
                <IconButton
                  color="secondary"
                  onClick={() =>
                    handleDeleteArrayItem("interesting_facts", index)
                  }
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            ) : (
              <Box key={index} sx={{ display: "flex", alignItems: "center" }}>
                <InstructionItem text={fact} simple={true} marginBottom={1.5} />
              </Box>
            ),
          )}
          {editMode && (
            <Button
              startIcon={<AddIcon />}
              onClick={() => handleAddArrayItem("interesting_facts")}
              variant="print"
              size="small"
              sx={{ mb: 2 }}
            >
              Lägg till intressant fakta
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default HistoricPersonDisplay;
