import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  Tooltip,
  FormControl,
  Select,
  MenuItem,
  Grid,
  TextField,
  FormControlLabel,
  Switch,
  Paper,
  Divider,
} from "@mui/material";

import GenerateIcon from "@mui/icons-material/AutoAwesome";
import PrintIcon from "@mui/icons-material/Print";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import AddonTimelineSimple from "./AddonTimelineSimple";
import DashedPapers from "./DashedPapers";
import HistoryIcon from "@mui/icons-material/History";
import ShareLink from "./ShareLink";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import TimelineSimpleDisplay from "./TimelineSimpleDisplay";
import LessonQuizDisplay from "./LessonQuizDisplay";
import LessonExercisesDisplay from "./LessonExercisesDisplay";
import TimelineDisplay from "./TimelineDisplay";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { DashedPaper } from "../StyledComponents";
import { useParams } from "react-router-dom";
import PrintAsPdf from "./PrintAsPdf";
import Translate from "./Translate";
import SlideInPaper from "./SlideInPaper";
import DateRangeIcon from "@mui/icons-material/DateRange";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import PersonIcon from "@mui/icons-material/Person";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import AddIcon from "@mui/icons-material/Add";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import ImageIcon from "@mui/icons-material/Image";
import ImageGenerationModal from "./ImageGenerationModal";
import HoverableImage from "./HoverableImage";
import { callApi } from "./api";
import { db } from "./firebase";
import {
  collection,
  query,
  where,
  limit,
  addDoc,
  updateDoc,
  getDocs,
  serverTimestamp,
} from "firebase/firestore";

import Logger from "./Logger";
import LanguageSelector from "./LanguageSelector";

import { getThemes } from "./additionalThemes/NewsLesson";
import { ThemeSelectorFlow } from "./ThemeSelectorFlow";
import InfoBox from "./InfoBox";
import { handlePrintNewsArticle } from "./printFunctions";
import AddonQuiz from "./AddonQuiz";
import AddonTimeline from "./AddonTimeline";
import { generateAndProcessImage } from "./functions";
import ShareDialog from "./ShareDialog";
import ShareIcon from "@mui/icons-material/Share";
import LoadingBackdrop from "./LoadingBackdrop";
import { useSnackbar } from "./SnackbarHandler";
import ProgressBar from "./ProgressBar";
import LessonDrawer from "./LessonDrawer";
import SaveLessonDialog from "./SaveLessonDialog";
import EditIcon from "@mui/icons-material/Edit";
import { getStyles } from "../index";
import { trackLesson, handleImageGenerationError } from "./functions";
import GradeSelector, { gradeInstructions } from "./GradeSelector";
import { useTheme } from "@mui/material/styles";
import { useSearchParams } from "react-router-dom";
import ReadingComprehensionDialog from "./ReadingComprehensionDialog";

const NewsLesson = ({ user }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);
  const lessonTitleRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageInstructions, setImageInstructions] = useState("");
  const [isGeneratingImages, setIsGeneratingImages] = useState(false);
  const [loadingImageIndex, setLoadingImageIndex] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [isSharing, setIsSharing] = useState(false);
  const timelineSimpleSectionRef = useRef(null);

  // Basic settings
  const [lessonTheme, setLessonTheme] = useState("");
  const [language, setLanguage] = useState("svenska");
  const [grade, setGrade] = useState("åk9");

  const [shareDialogOpenFriends, setShareDialogOpenFriends] = useState(false);
  const pdfButtonRef = useRef(null);
  const translateButtonRef = useRef(null);
  const shareLinkButtonRef = useRef(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showControlPanel, setShowControlPanel] = useState(false);

  // News specific settings
  const [tone, setTone] = useState("neutral");
  const [length, setLength] = useState("medel");
  const [audience, setAudience] = useState("general");
  const [headlineStyle, setHeadlineStyle] = useState("neutral");
  const [perspective, setPerspective] = useState("third-person");
  const [writingStyle, setWritingStyle] = useState("traditional");
  const [useQuotes, setUseQuotes] = useState(true);

  // Generation states
  const [progress, setProgress] = useState(0);
  const [isGeneratingLesson, setIsGeneratingLesson] = useState(false);
  const [lessonData, setLessonData] = useState(null);

  // References for content
  const [customTheme, setCustomTheme] = useState("");
  const [predefinedTheme, setPredefinedTheme] = useState(null);
  const subject = searchParams.get("subject") || null;
  const viewMode = searchParams.get("v") === "t";
  const additionalThemes = getThemes(subject);
  const { showSnackbar } = useSnackbar();
  const theme = useTheme();
  const styles = getStyles(theme);

  const transcriptRef = useRef("");
  const uploadedFileRef = useRef("");
  const bookContentRef = useRef("");
  const wikipediaContentRef = useRef("");
  const randomizedContentRef = useRef("");
  const coursePlanContentRef = useRef("");

  const quizSectionRef = useRef(null);
  const timelineSectionRef = useRef(null);
  const exercisesSectionRef = useRef(null);
  // Add refs for the quiz and comprehension buttons
  const quizButtonRef = useRef(null);
  const timelineButtonRef = useRef(null);
  const comprehensionButtonRef = useRef(null);
  const timelineSimpleButtonRef = useRef(null);
  const urlContentRef = useRef("");
  const handleTimelinePaperClick = () => {
    timelineButtonRef.current?.click();
  };

  const handleTimelineSimplePaperClick = () => {
    timelineSimpleButtonRef.current?.click();
  };

  // Add click handlers for the DashedPaper components
  const handleQuizPaperClick = () => {
    quizButtonRef.current?.click();
  };

  const handleComprehensionPaperClick = () => {
    comprehensionButtonRef.current?.click();
  };

  const { lessonId } = useParams();
  useEffect(() => {
    const loadLessonFromId = async () => {
      if (lessonId) {
        try {
          const q = query(
            collection(db, "lesson_usage"),
            where("__name__", ">=", lessonId),
            where("__name__", "<=", lessonId + "\uf8ff"),
            where("lessonType", "==", "NewsLesson.js"),
            limit(1),
          );

          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            const docData = querySnapshot.docs[0].data();

            if (!docData.hasOpened) {
              await updateDoc(querySnapshot.docs[0].ref, {
                hasOpened: true,
                openedAt: Date.now(),
              });
            }

            setLessonData(docData.data);

            // Scroll to lesson content
            setTimeout(() => {
              lessonTitleRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }, 100);

            showSnackbar("Lektionen har laddats in.", "success");
          } else {
            showSnackbar("Kunde inte hitta lektionen", "error");
          }
        } catch (error) {
          console.error("Error loading lesson:", error);
          showSnackbar("Ett fel uppstod när lektionen skulle laddas", "error");
        }
      }
    };

    loadLessonFromId();
  }, lessonId);

  useEffect(() => {
    Logger.log(
      "lessonData in newSections: ",
      JSON.stringify(lessonData, null, 2),
    );
  }, [lessonData]);

  useEffect(() => {
    Logger.log("lessonData KONTROLLPANEL: ", lessonData);
    const isDataValid =
      lessonData !== null &&
      lessonData !== undefined &&
      Object.keys(lessonData).length > 0 &&
      // Only check required fields
      lessonData.headline !== "" &&
      lessonData.ingress !== "";

    if (isDataValid !== showControlPanel) {
      const timer = setTimeout(() => {
        setShowControlPanel(isDataValid);
      }, 100);

      return () => clearTimeout(timer);
    }
  }, [lessonData, showControlPanel]);

  const handleShare = async (
    lessonComment,
    lessonName,
    selectedImage,
    tags,
    shareData,
  ) => {
    setIsSharing(true);
    await new Promise((resolve) => setTimeout(resolve, 1500));
    // try {
    //   // Create a document for each student
    //   const sharePromises = shareData.studentIds.map((studentId) => {
    //     const documentData = {
    //       userId: user.uid,
    //       shared: true,
    //       sharedTo: studentId,
    //       comment: shareData.comment,
    //       sharedAt: Date.now(),
    //       fileUrl: shareData.fileUrl,
    //       lessonType: "NewsLesson.js",
    //       timestamp: serverTimestamp(),
    //       lessonComment,
    //       lessonName,
    //       coverImage: selectedImage,
    //       hasPrinted: true,
    //       data: lessonData,
    //     };
    //     Logger.log(
    //       "Adding document for student",
    //       studentId,
    //       ":",
    //       JSON.stringify(documentData, null, 2),
    //     );
    //     return addDoc(collection(db, "lesson_usage"), documentData);
    //   });
    //   await Promise.all(sharePromises);
    //   showSnackbar(`Du har nu delat lektionen!`, "success");
    // }

    try {
      // Create a document for each student and then create notifications
      const sharePromises = shareData.studentIds.map(async (studentId) => {
        const documentData = {
          userId: user.uid,
          shared: true,
          sharedTo: studentId,
          comment: shareData.comment,
          sharedAt: Date.now(),
          fileUrl: shareData.fileUrl,
          lessonType: "NewsLesson.js",
          timestamp: serverTimestamp(),
          lessonComment,
          lessonName,
          coverImage: selectedImage,
          hasPrinted: true,
          data: lessonData,
        };
        // Create lesson document and get ID
        const lessonDoc = await addDoc(
          collection(db, "lesson_usage"),
          documentData,
        );
        Logger.log(
          "Adding document for student",
          studentId,
          ":",
          JSON.stringify(documentData, null, 2),
        );
        // Create notification with lesson doc ID
        await addDoc(collection(db, "notifications"), {
          type: "lesson_shared",
          recipientId: studentId,
          senderId: user.uid,
          read: false,
          createdAt: serverTimestamp(),
          lessonData: {
            lessonId: lessonDoc.id,
            lessonType: documentData.lessonType,
            lessonName: documentData.lessonName,
            fileUrl: documentData.fileUrl,
            comment: documentData.comment,
            coverImage: selectedImage,
          },
        });
        return lessonDoc;
      });
      await Promise.all(sharePromises);
      showSnackbar(`Du har nu delat lektionen!`, "success");
    } catch (error) {
      showSnackbar("Fel vid delande av lektionen: " + error.message, "error");
      console.error(error);
    } finally {
      setIsSharing(false);
    }
  };

  const handleSaveLessonClick = () => {
    setSaveDialogOpen(true);
  };

  const handleSaveWithComment = async (comment, name, coverImage, tags) => {
    await trackLesson(
      user.uid,
      "NewsLesson.js",
      lessonData,
      null,
      true,
      comment,
      name,
      coverImage,
      tags,
    );
    setDrawerOpen(true);
    showSnackbar("Artikeln sparad i din lektionsbank!", "success");
  };

  const toggleViewMode = () => {
    const currentParams = Object.fromEntries(searchParams.entries());
    if (currentParams.v === "t") {
      delete currentParams.v;
    } else {
      currentParams.v = "t";
    }
    setSearchParams(currentParams);
  };

  const simulateProgress = (startProgress, endProgress, duration) => {
    const stepSize = 0.5;
    const steps = (endProgress - startProgress) / stepSize;
    const stepDuration = duration / steps;
    let currentProgress = startProgress;

    const intervalId = setInterval(() => {
      currentProgress += stepSize;
      if (currentProgress >= endProgress) {
        clearInterval(intervalId);
      } else {
        setProgress(currentProgress);
      }
    }, stepDuration);

    return () => clearInterval(intervalId);
  };

  function getWritingStylePrompt(style) {
    switch (style) {
      case "traditional":
        return "Write this as a traditional news article focusing on clear, objective reporting of facts in a standard inverted pyramid structure.";

      case "investigative":
        return "Write this as an in-depth investigative piece that explores underlying causes, includes extensive research and uncovers new information.";

      case "feature":
        return "Write this as a feature story with descriptive scenes, character development and narrative elements while maintaining journalistic integrity.";

      case "column":
        return "Write this as a personal column expressing clear opinions and analysis while engaging readers with a distinctive voice.";

      case "review":
        return "Write this as a review that evaluates and critiques while providing clear reasoning and evidence for the assessment.";

      case "portrait":
        return "Write this as a personal portrait that brings the subject to life through vivid details, quotes and insights into their character.";

      case "notice":
        return "Write this as a brief, concise news notice focusing only on the most essential facts.";

      case "obituary":
        return "Write this as an obituary that honors the deceased by highlighting their life achievements, impact and legacy.";

      case "interview":
        return "Write this in an interview format with clear questions and responses that reveal insights through direct quotes.";

      case "opinion":
        return "Write this as an opinion piece that presents a clear argument supported by facts and reasoning.";

      case "breaking":
        return "Write this as urgent breaking news focusing on immediate facts and developments as they unfold.";

      default:
        return "Write this as a traditional news article.";
    }
  }

  function getLengthPrompt(length) {
    switch (length) {
      case "kort":
        return "EXACTLY 2-3 sentences per section";

      case "lång":
        return "EXACTLY 10-14 sentences per section.";

      case "medel":
        return "EXACTLY 6-8  sentences per section";

      default:
        return "EXACTLY 6-8 sentences per section.";
    }
  }

  const handleGenerateLesson = async () => {
    setProgress(0);
    let stopProgressSimulation = null;
    setIsGeneratingLesson(true);
    const gradeInstructionsText = gradeInstructions[grade];
    setLessonData(null);

    try {
      let themeToUse;

      if (lessonTheme === "youtube") {
        themeToUse = transcriptRef.current;
      } else if (lessonTheme === "customTheme") {
        themeToUse = customTheme;
      } else if (lessonTheme === "predefined") {
        themeToUse = predefinedTheme;
      } else if (lessonTheme === "fileUpload") {
        themeToUse = uploadedFileRef.current;
      } else if (lessonTheme === "wikipedia") {
        themeToUse = wikipediaContentRef.current;
      } else if (lessonTheme === "randomizer") {
        themeToUse = randomizedContentRef.current;
      } else if (lessonTheme === "book") {
        themeToUse = bookContentRef.current;
      } else if (lessonTheme === "url") {
        themeToUse = urlContentRef.current;
      } else {
        themeToUse = coursePlanContentRef.current;
      }

      stopProgressSimulation = simulateProgress(0, 90, 15000);

      let response = await callApi(
        [
          {
            role: "system",
            content: `You are an expert journalist and teacher creating engaging news articles in ${language}. 



            Create a fictitious news article that feels authentic and engaging. Follow the exact JSON structure provided in the prompt.`,
          },
          {
            role: "user",
            content: `
      Theme: "${themeToUse}"
      
      Create a news article with these specifications:
      - Tone: ${tone}

      - Target audience: ${audience}
      - Headline style: ${headlineStyle}
      - Perspective: ${perspective}
      - Writing style: ${getWritingStylePrompt(writingStyle)}
      - Include quotes in the article from people: ${useQuotes}
      ${gradeInstructionsText}
      - The news article should be written in ${language}
      - Length: EXACTLY 9 sections in total 
      - Length per section: ${getLengthPrompt(length)}
      

      RESPOND IN THIS EXACT JSON FORMAT:
      {
        "headline": "The main headline",
        "subheadline": "A compelling subheadline",
        "author": "Journalist's name",
        "date": "Publication date",
        "ingress": "A compelling introduction paragraph that summarizes the key points",
        "sections": [
          {
            "subHeader": "First Important Aspect",
            "content": "Content for the first section"
          },
          {
            "subHeader": "Second Important Aspect",
            "content": "Content for the second section"
          },
          {
            "subHeader": "Third Important Aspect",
            "content": "Content for the third section"
          },


         {
            "subHeader": "Fourth Important Aspect",
            "content": "Content for the fourth section"
          },
          {
            "subHeader": "Fifth Important Aspect",
            "content": "Content for the fifth section"
          },
          {
            "subHeader": "Sixth Important Aspect",
            "content": "Content for the sixth section"
          },
          {
            "subHeader": "Seventh Important Aspect",
            "content": "Content for the seventh section"
          }
                        {
            "subHeader": "Final Thoughts",
            "content": "Content for the final thoughts section"
          }
        ],

        "location": "Where the story takes place",
     
      }
      `,
          },
        ],
        6000,
        true,
        user.uid,
      );

      if (stopProgressSimulation) stopProgressSimulation();
      setProgress(95);

      let messageContent = response.data.choices[0].message.content.trim();
      if (messageContent.startsWith("```json")) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith("```")) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      const data = JSON.parse(messageContent);
      setProgress(100);
      setLessonData({
        ...data,
        images: [{ imageUrl: null }, { imageUrl: null }],
      });

      Logger.log("response: ", JSON.stringify(data, null, 2));

      await trackLesson(user.uid, "NewsLesson.js", null, {
        language,
        lessonTheme,
        tone,
        length,
        audience,
        headlineStyle,
        perspective,
        writingStyle,
        useQuotes,
      });

      showSnackbar("Din nyhetsartikel har genererats!", "success");
    } catch (error) {
      console.error("Error generating news article:", error);
      showSnackbar(
        "Ett fel uppstod när artikeln skulle genereras. Försök igen!",
        "error",
      );
    } finally {
      setIsGeneratingLesson(false);
    }
  };

  const handleGenerateImages = async () => {
    if (!lessonData) return;
    setIsGeneratingImages(true);
    handleCloseModal();

    try {
      const imagePrompt = `Create an image for a fictitious news artcile with the title: "${lessonData.headline}". ${imageInstructions}`;
      const imagePromises = lessonData.images.map(() =>
        generateAndProcessImage(imagePrompt, user.uid),
      );
      const imageUrls = await Promise.all(imagePromises);

      setLessonData((prevData) => ({
        ...prevData,
        images: imageUrls.map((imageUrl) => ({ imageUrl })),
      }));

      showSnackbar("Bilderna har genererats!", "success");
    } catch (error) {
      handleImageGenerationError(error, true, showSnackbar);
    } finally {
      setIsGeneratingImages(false);
      setLoadingImageIndex(null);
    }
  };

  const handleGenerateSpecificImage = async (index) => {
    setIsGeneratingImages(true);
    setLoadingImageIndex(index);
    handleCloseModal();

    try {
      const imagePrompt = `Create an image for a fictitious news artcile with the title: "${lessonData.headline}. ${imageInstructions}`;
      const imageUrl = await generateAndProcessImage(imagePrompt, user.uid);

      if (imageUrl) {
        setLessonData((prevData) => {
          const newImages = [...prevData.images];
          newImages[index] = { imageUrl };
          return {
            ...prevData,
            images: newImages,
          };
        });
        showSnackbar("Bilden har genererats!", "success");
      }
    } catch (error) {
      handleImageGenerationError(error, false, showSnackbar);
    } finally {
      setIsGeneratingImages(false);
      setLoadingImageIndex(null);
    }
  };

  const handleImageButtonClick = (index = null) => {
    setSelectedImageIndex(index);
    setShowImageModal(true);
  };

  const handleCloseModal = () => {
    setShowImageModal(false);
    setImageInstructions("");
  };

  const handleGenerateWithInstructions = async () => {
    if (selectedImageIndex !== null) {
      handleCloseModal();
      await handleGenerateSpecificImage(selectedImageIndex);
    } else {
      handleCloseModal();
      await handleGenerateImages();
    }
  };

  return (
    <Box sx={styles.container}>
      <Box
        sx={{
          ...styles.innerContainer,
          width: { xs: "100%", md: "100%" },
          margin: "0 auto",
        }}
      >
        {!viewMode && (
          <Typography
            variant="h1"
            gutterBottom
            sx={{
              fontWeight: "bold",
              fontSize: "1.8em",
              mb: 3,
              mt: 5,
              textAlign: "center",
            }}
          >
            Fiktiva nyhetsredaktionen
          </Typography>
        )}
        <Box
          sx={{
            ...styles.buttonsContainer,
            ...styles.additionalButtonsContainer,
          }}
        >
          {lessonData && user.accountType === "teacher" && (
            <Button
              variant="purp"
              sx={{ ...styles.tightButton }}
              disableRipple
              onClick={() => {
                setTimeout(() => {
                  lessonTitleRef.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "start", // This ensures it scrolls to the top of the element
                  });
                }, 100);
                toggleViewMode();
              }}
              startIcon={
                searchParams.get("v") === "t" ? (
                  <FullscreenExitIcon />
                ) : (
                  <FullscreenIcon />
                )
              }
            >
              {searchParams.get("v") === "t" ? "Redigeringsläge" : "Elevläge"}
            </Button>
          )}

          {!viewMode && (
            <Button
              variant="purp"
              sx={{ ...styles.tightButton }}
              onClick={() => setDrawerOpen(true)}
              startIcon={<BookmarkBorderIcon />}
            >
              Sparat
            </Button>
          )}
        </Box>

        <LessonDrawer
          userId={user.uid}
          lessonType={"NewsLesson.js"}
          theme={theme}
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          lessonIcon={NewspaperIcon}
          onSelectLesson={(lesson) => {
            setLessonData(lesson.data);
            setDrawerOpen(false);
            setTimeout(() => {
              lessonTitleRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "start", // This ensures it scrolls to the top of the element
              });
            }, 100);
            showSnackbar(`Artikeln har laddats in.`, "success");
          }}
        />

        {!viewMode && (
          <>
            <InfoBox
              icon={NewspaperIcon}
              title="Nyhetsartikelgenerator"
              instructions={[
                "Välj underlag för din artikel",
                "Anpassa inställningarna för artikelns stil och ton",
                "Generera en unik och engagerande artikel",
              ]}
              theme={theme}
              styles={styles}
            />

            <ThemeSelectorFlow
              key={subject}
              subject={subject}
              value={lessonTheme}
              onChange={(e) => setLessonTheme(e.target.value)}
              customTheme={customTheme}
              onCustomThemeChange={(e) => setCustomTheme(e.target.value)}
              onPredefinedThemeChange={(e) =>
                setPredefinedTheme(e.target.value)
              }
              onBookContentChange={(content) => {
                bookContentRef.current = content;
              }}
              onUrlContentChange={(content) => {
                Logger.log(
                  "Parent: URL content received",
                  content.substring(0, 300),
                );
                urlContentRef.current = content;
              }}
              onYoutubeTranscriptChange={(transcriptContent) => {
                transcriptRef.current = transcriptContent;
              }}
              onFileUpload={(content) => {
                uploadedFileRef.current = content;
              }}
              onWikipediaContentChange={(content) => {
                wikipediaContentRef.current = content;
              }}
              onRandomizedContentChange={(content) => {
                randomizedContentRef.current = content;
              }}
              onCoursePlanContentChange={(content) => {
                coursePlanContentRef.current = content;
              }}
              lessonType={"NewsLesson.js"}
              user={user}
              theme={theme}
              styles={styles}
              onLessonSelect={(lesson) => {
                Logger.log("onLessonSelect körs");
                setLessonData(lesson.data);
                setTimeout(() => {
                  lessonTitleRef.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "start", // This ensures it scrolls to the top of the element
                  });
                }, 100);
                showSnackbar("Lektionen har laddats in.", "success");
              }}
              additionalThemes={additionalThemes}
            />

            <Box sx={styles.headlineLessonBox}>
              <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
                2. Generera artikeln
              </Typography>
            </Box>

            <Box sx={{ ...styles.freshBox, padding: "30px" }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "500", mb: 1 }}
                  >
                    Årskurs:
                  </Typography>
                  <GradeSelector grade={grade} setGrade={setGrade} />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "500", mb: 1 }}
                  >
                    Språk:
                  </Typography>
                  <LanguageSelector
                    language={language}
                    setLanguage={setLanguage}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "500", mb: 1 }}
                  >
                    Ton:
                  </Typography>
                  <FormControl fullWidth>
                    <Select
                      value={tone}
                      onChange={(e) => setTone(e.target.value)}
                    >
                      <MenuItem value="formal">Formell</MenuItem>
                      <MenuItem value="casual">Vardaglig</MenuItem>
                      <MenuItem value="neutral">Neutral</MenuItem>
                      <MenuItem value="dramatic">Dramatisk</MenuItem>
                      <MenuItem value="humorous">Humoristisk</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "500", mb: 1 }}
                  >
                    Längd per stycke:
                  </Typography>
                  <FormControl fullWidth>
                    <Select
                      value={length}
                      onChange={(e) => setLength(e.target.value)}
                    >
                      <MenuItem value="kort">Kort (2-3 meningar)</MenuItem>
                      <MenuItem value="medel">Medel (6-8 meningar)</MenuItem>
                      <MenuItem value="lång">Lång (10-14 meningar)</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "500", mb: 1 }}
                  >
                    Målgrupp:
                  </Typography>
                  <FormControl fullWidth>
                    <Select
                      value={audience}
                      onChange={(e) => setAudience(e.target.value)}
                    >
                      <MenuItem value="general">Allmän publik</MenuItem>
                      <MenuItem value="youth">Ungdomar</MenuItem>
                      <MenuItem value="children">Barn</MenuItem>
                      <MenuItem value="academic">Akademisk</MenuItem>
                      <MenuItem value="professional">Yrkesverksamma</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "500", mb: 1 }}
                  >
                    Rubrikstil:
                  </Typography>
                  <FormControl fullWidth>
                    <Select
                      value={headlineStyle}
                      onChange={(e) => setHeadlineStyle(e.target.value)}
                    >
                      <MenuItem value="neutral">Neutral</MenuItem>
                      <MenuItem value="sensational">Sensationell</MenuItem>
                      <MenuItem value="clickbait">Clickbait</MenuItem>
                      <MenuItem value="serious">Seriös</MenuItem>
                      <MenuItem value="formal">Högtidlig</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "500", mb: 1 }}
                  >
                    Perspektiv:
                  </Typography>
                  <FormControl fullWidth>
                    <Select
                      value={perspective}
                      onChange={(e) => setPerspective(e.target.value)}
                    >
                      <MenuItem value="first-person">Första person</MenuItem>
                      <MenuItem value="third-person">Tredje person</MenuItem>
                      <MenuItem value="omniscient">Allvetande</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "500", mb: 1 }}
                  >
                    Typ av artikel:
                  </Typography>
                  <FormControl fullWidth>
                    <Select
                      value={writingStyle}
                      onChange={(e) => setWritingStyle(e.target.value)}
                    >
                      <MenuItem value="traditional">
                        Traditionell nyhetsrapportering
                      </MenuItem>
                      <MenuItem value="investigative">
                        Undersökande journalistik
                      </MenuItem>
                      <MenuItem value="feature">Reportage</MenuItem>
                      <MenuItem value="column">Krönika</MenuItem>

                      <MenuItem value="review">Recensioner</MenuItem>
                      <MenuItem value="portrait">Personporträtt</MenuItem>
                      <MenuItem value="notice">Notiser</MenuItem>
                      <MenuItem value="column">Krönikor</MenuItem>
                      <MenuItem value="obituary">Dödsrunor</MenuItem>
                      <MenuItem value="interview">Intervju</MenuItem>
                      <MenuItem value="opinion">Ledare/Opinion</MenuItem>
                      <MenuItem value="breaking">Breaking News</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={useQuotes}
                        onChange={(e) => setUseQuotes(e.target.checked)}
                        color="primary"
                      />
                    }
                    label="Inkludera citat"
                  />
                </Grid>
              </Grid>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "12px",
                  width: "100%",
                }}
              >
                <Button
                  variant={
                    theme.palette.mode === "light" ? "purp" : "contained"
                  }
                  color="primary"
                  onClick={handleGenerateLesson}
                  disabled={isGeneratingLesson}
                  sx={{ mt: 4 }}
                  startIcon={
                    !isGeneratingLesson ? (
                      <GenerateIcon style={{ color: "#fff" }} />
                    ) : (
                      <CircularProgress size={16} style={{ color: "#fff" }} />
                    )
                  }
                >
                  {isGeneratingLesson ? "Genererar..." : "Generera artikel"}
                </Button>
              </div>
              {isGeneratingLesson && (
                <Box sx={{ mt: 6 }}>
                  <ProgressBar
                    progress={progress}
                    label="Genererar artikel..."
                  />
                </Box>
              )}
            </Box>
          </>
        )}

        {lessonData && (
          <>
            {!viewMode && (
              <Box
                ref={lessonTitleRef}
                sx={{ ...styles.headlineLessonBox, mt: 10 }}
              >
                <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
                  3. Din artikel
                </Typography>
              </Box>
            )}
            <Box
              sx={{
                ...styles.freshBox,
                padding: "30px",

                width: {
                  xs: "100%",
                  sm: viewMode ? "70%" : undefined,
                },
                margin: viewMode ? "0 auto" : undefined,
                marginTop: viewMode ? "100px" : undefined,
              }}
            >
              {!viewMode && (
                <Box sx={{ position: "relative" }}>
                  <Box
                    sx={{
                      ...styles.lessonControlPanel,
                      ...styles.lessonControlPanelNuevo,

                      "& .MuiButton-root": {
                        ...styles.controlPanelButtons,
                        ...styles.lessonControlPanelNewButtons,
                      },
                    }}
                  >
                    <Button
                      variant="success"
                      startIcon={isEditMode ? <SaveAsIcon /> : <EditIcon />}
                      sx={{}}
                      onClick={() => {
                        if (isEditMode) {
                          // Show success message when saving changes
                          showSnackbar("Ändringarna sparade!", "success");
                        }
                        setIsEditMode(!isEditMode);
                      }}
                      sx={{
                        mt: 2,
                        ml: 2,
                        ...(isEditMode && {
                          color: "green !important",
                          "&.MuiButton-root": {
                            fontWeight: "700 !important",
                          },
                        }),
                      }}
                    >
                      {isEditMode ? "Spara ändringar" : "Redigera"}
                    </Button>
                    <Button
                      variant="print"
                      startIcon={<SaveAsIcon />}
                      sx={{ ml: 2, mt: 2 }}
                      onClick={handleSaveLessonClick}
                    >
                      Spara i min lektionsbank
                    </Button>
                    <Button
                      variant="print"
                      startIcon={<PrintIcon />}
                      onClick={() => handlePrintNewsArticle(lessonData)}
                      sx={{ mt: 2, ml: 2 }}
                    >
                      Skriv ut
                    </Button>

                    <Button
                      variant="success"
                      sx={{}}
                      onClick={() => setShareDialogOpenFriends(true)}
                      startIcon={<ShareIcon />}
                    >
                      Dela med vänner
                    </Button>
                    <Button
                      variant="success"
                      sx={{
                        mt: 2,
                        ml: 2,
                      }}
                      onClick={() => setShareDialogOpen(true)}
                      startIcon={<ShareIcon />}
                    >
                      Dela med elever
                    </Button>
                    <ShareLink
                      buttonRef={shareLinkButtonRef}
                      key={lessonId}
                      lessonId={lessonId}
                      lessonData={lessonData}
                      user={user}
                      lessonType={"NewsLesson.js"}
                      onCopy={(message, severity) =>
                        showSnackbar(message, severity)
                      }
                      sx={{ mt: 2 }}
                    />

                    <PrintAsPdf
                      buttonRef={pdfButtonRef}
                      onPrint={() => handlePrintNewsArticle(lessonData)}
                      sx={{ ml: 2 }}
                    />
                    <Translate
                      buttonRef={translateButtonRef}
                      lessonData={lessonData}
                      onTranslated={setLessonData}
                      callApi={callApi}
                      user={user}
                      showSnackbar={showSnackbar}
                    />

                    <Tooltip title="Hur lektionen ser ut för eleverna">
                      <Button
                        variant="success"
                        onClick={() => {
                          window.scrollTo({ top: 0, behavior: "smooth" });
                          toggleViewMode();
                        }}
                        sx={{ ml: 2, mt: 2 }}
                        startIcon={
                          searchParams.get("v") === "t" ? (
                            <FullscreenExitIcon />
                          ) : (
                            <FullscreenIcon />
                          )
                        }
                      >
                        {searchParams.get("v") === "t"
                          ? "Redigeringsläge"
                          : "Elevläge"}
                      </Button>
                    </Tooltip>
                    <Box sx={{ display: "inline-block" }}>
                      <ReadingComprehensionDialog
                        buttonRef={comprehensionButtonRef}
                        key={lessonData?.title}
                        lessonData={lessonData}
                        user={user}
                        theme={theme}
                        styles={styles}
                        buttonText="Lägg till övningar"
                        addExercisesToLessonObject={(updateFunction) => {
                          setLessonData((prevLessonData) => ({
                            ...prevLessonData,
                            exercises: [
                              ...(prevLessonData.exercises || []),
                              ...updateFunction(prevLessonData).exercises,
                            ],
                          }));
                          setTimeout(
                            () =>
                              exercisesSectionRef.current?.scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                              }),
                            100,
                          );
                        }}
                      />
                    </Box>
                    <Box sx={{ display: "inline-block", ml: 0 }}>
                      <AddonQuiz
                        buttonRef={quizButtonRef}
                        lessonData={lessonData}
                        title={lessonData.headline}
                        theme={theme}
                        user={user}
                        styles={styles}
                        showSnackbar={showSnackbar}
                        addQuizToLessonObject={(updatedData) => {
                          setLessonData(updatedData);
                          setTimeout(
                            () =>
                              quizSectionRef.current?.scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                              }),
                            100,
                          );
                        }}
                        buttonText="Lägg till interaktivt quiz"
                      />
                    </Box>

                    <Box sx={{ display: "inline-block", ml: 0 }}>
                      <AddonTimeline
                        buttonRef={timelineButtonRef}
                        lessonData={lessonData}
                        theme={theme}
                        user={user}
                        styles={styles}
                        showSnackbar={showSnackbar}
                        addTimelineToLessonObject={(updatedData) => {
                          setLessonData(updatedData);
                          setTimeout(
                            () =>
                              timelineSectionRef.current?.scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                              }),
                            100,
                          );
                        }}
                        buttonText="Lägg till interaktiv tidslinje"
                      />
                    </Box>

                    <Box sx={{ display: "inline-block", ml: 0 }}>
                      <AddonTimelineSimple
                        buttonRef={timelineSimpleButtonRef}
                        lessonData={lessonData}
                        theme={theme}
                        user={user}
                        styles={styles}
                        showSnackbar={showSnackbar}
                        addTimelineToLessonObject={(updatedData) => {
                          setLessonData(updatedData);
                          setTimeout(
                            () =>
                              timelineSimpleSectionRef.current?.scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                              }),
                            100,
                          );
                        }}
                        buttonText="Lägg till enkel tidslinje"
                      />
                    </Box>

                    <Button
                      variant={
                        theme.palette.mode === "light" ? "purp" : "contained"
                      }
                      color="primary"
                      onClick={() => handleImageButtonClick(null)}
                      disabled={isGeneratingImages}
                      sx={{ mt: 2 }}
                      startIcon={
                        !isGeneratingImages ? (
                          <ImageIcon />
                        ) : (
                          <CircularProgress size={16} />
                        )
                      }
                    >
                      {isGeneratingImages ? "Genererar..." : "Generera bilder"}
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      ...styles.lessonControlPanelFade,
                    }}
                  />
                </Box>
              )}

              <Paper
                elevation={0}
                sx={{
                  p: 4,
                  backgroundColor:
                    theme.palette.mode === "light" ? "#fff" : "#1a1a1a",
                  fontFamily: "Georgia, serif",
                }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    {isEditMode ? (
                      <TextField
                        fullWidth
                        variant="outlined"
                        value={lessonData?.headline}
                        onChange={(e) =>
                          setLessonData((prev) => ({
                            ...prev,
                            headline: e.target.value,
                          }))
                        }
                        InputProps={{
                          sx: {
                            fontFamily: "Georgia, serif",
                            fontWeight: 700,
                            fontSize: "2.5rem",
                            lineHeight: 1.2,
                          },
                        }}
                      />
                    ) : (
                      <Typography
                        variant="h3"
                        sx={{
                          fontFamily: "Georgia, serif",
                          fontWeight: 700,
                          mb: 2,
                          fontSize: "2.5rem",
                          lineHeight: 1.2,
                        }}
                      >
                        {lessonData?.headline}
                      </Typography>
                    )}

                    {isEditMode ? (
                      <TextField
                        fullWidth
                        variant="outlined"
                        value={lessonData?.subheadline}
                        onChange={(e) =>
                          setLessonData((prev) => ({
                            ...prev,
                            subheadline: e.target.value,
                          }))
                        }
                        InputProps={{
                          sx: {
                            fontFamily: "Georgia, serif",
                            fontWeight: 400,
                            fontSize: "1.5rem",
                            lineHeight: 1.4,
                            color: theme.palette.text.secondary,
                          },
                        }}
                        sx={{ mb: 3 }}
                      />
                    ) : (
                      <Typography
                        variant="h5"
                        sx={{
                          fontFamily: "Georgia, serif",
                          fontWeight: 400,
                          color: theme.palette.text.secondary,
                          mb: 3,
                          fontSize: "1.5rem",
                          lineHeight: 1.4,
                        }}
                      >
                        {lessonData?.subheadline}
                      </Typography>
                    )}

                    <Box
                      sx={{
                        display: "flex",
                        gap: 3,
                        mb: 4,
                        color: theme.palette.text.secondary,
                        fontSize: "0.9rem",
                      }}
                    >
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        <PersonIcon fontSize="small" />
                        {isEditMode ? (
                          <TextField
                            value={lessonData?.author}
                            onChange={(e) =>
                              setLessonData((prev) => ({
                                ...prev,
                                author: e.target.value,
                              }))
                            }
                            InputProps={{
                              sx: {
                                fontFamily: "Georgia, serif",
                              },
                            }}
                          />
                        ) : (
                          <Typography>{lessonData?.author}</Typography>
                        )}
                      </Box>
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        <DateRangeIcon fontSize="small" />
                        {isEditMode ? (
                          <TextField
                            value={lessonData?.date}
                            onChange={(e) =>
                              setLessonData((prev) => ({
                                ...prev,
                                date: e.target.value,
                              }))
                            }
                            InputProps={{
                              sx: {
                                fontFamily: "Georgia, serif",
                              },
                            }}
                          />
                        ) : (
                          <Typography>{lessonData?.date}</Typography>
                        )}
                      </Box>
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        <LocationOnIcon fontSize="small" />
                        {isEditMode ? (
                          <TextField
                            value={lessonData?.location}
                            onChange={(e) =>
                              setLessonData((prev) => ({
                                ...prev,
                                location: e.target.value,
                              }))
                            }
                            InputProps={{
                              sx: {
                                fontFamily: "Georgia, serif",
                              },
                            }}
                          />
                        ) : (
                          <Typography>{lessonData?.location}</Typography>
                        )}
                      </Box>
                    </Box>

                    <Divider sx={{ mb: 4 }} />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    {isEditMode ? (
                      <TextField
                        fullWidth
                        multiline
                        variant="outlined"
                        value={lessonData?.ingress}
                        onChange={(e) =>
                          setLessonData((prev) => ({
                            ...prev,
                            ingress: e.target.value,
                          }))
                        }
                        InputProps={{
                          sx: {
                            fontFamily: "Georgia, serif",
                            fontSize: "1.2rem",
                            fontWeight: 600,
                            lineHeight: 1.6,
                          },
                        }}
                        sx={{ mb: 8 }}
                      />
                    ) : (
                      <Typography
                        sx={{
                          fontFamily: "Georgia, serif",
                          fontSize: "1.2rem",
                          fontWeight: 600,
                          mb: 8,
                          lineHeight: 1.6,
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        {lessonData?.ingress}
                      </Typography>
                    )}

                    <Grid container spacing={4}>
                      <Grid item xs={12} md={6}>
                        {lessonData?.sections
                          ?.slice(0, 4)
                          .map((section, index) => (
                            <Box key={index} sx={{ mb: 4 }}>
                              {isEditMode ? (
                                <>
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    value={section.subHeader}
                                    onChange={(e) => {
                                      const newSections = [
                                        ...lessonData.sections,
                                      ];
                                      newSections[index] = {
                                        ...newSections[index],
                                        subHeader: e.target.value,
                                      };
                                      setLessonData((prev) => ({
                                        ...prev,
                                        sections: newSections,
                                      }));
                                    }}
                                    InputProps={{
                                      sx: {
                                        fontFamily: "Georgia, serif",
                                        fontWeight: 700,
                                        fontSize: "1.25rem",
                                        whiteSpace: "pre-wrap",
                                      },
                                    }}
                                    sx={{ mb: 2 }}
                                  />
                                  <TextField
                                    fullWidth
                                    multiline
                                    variant="outlined"
                                    value={section.content}
                                    onChange={(e) => {
                                      const newSections = [
                                        ...lessonData.sections,
                                      ];
                                      newSections[index] = {
                                        ...newSections[index],
                                        content: e.target.value,
                                      };
                                      setLessonData((prev) => ({
                                        ...prev,
                                        sections: newSections,
                                      }));
                                    }}
                                    InputProps={{
                                      sx: {
                                        fontFamily: "Georgia, serif",
                                        fontSize: "1.1rem",
                                        lineHeight: 1.8,
                                      },
                                    }}
                                    sx={{ mb: 3 }}
                                  />
                                </>
                              ) : (
                                <>
                                  <Typography
                                    variant="h6"
                                    sx={{
                                      fontFamily: "Georgia, serif",
                                      fontWeight: 700,
                                      mb: 2,
                                    }}
                                  >
                                    {section.subHeader}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontFamily: "Georgia, serif",
                                      fontSize: "1.1rem",
                                      lineHeight: 1.8,
                                      mb: 3,
                                      whiteSpace: "pre-wrap",
                                    }}
                                  >
                                    {section.content}
                                  </Typography>
                                </>
                              )}
                              {index === 2 && (
                                <HoverableImage
                                  imageUrl={lessonData.images[0]?.imageUrl}
                                  isLoading={isGeneratingImages}
                                  loadingIndex={loadingImageIndex}
                                  currentIndex={0}
                                  userId={user.uid}
                                  theme={theme}
                                  showSnackbar={showSnackbar}
                                  onGenerateClick={() =>
                                    handleImageButtonClick(0)
                                  }
                                  onImageUpdate={(newImageUrl) => {
                                    setLessonData((prev) => ({
                                      ...prev,
                                      images: prev.images.map((img, i) =>
                                        i === 0
                                          ? { imageUrl: newImageUrl }
                                          : img,
                                      ),
                                    }));
                                  }}
                                />
                              )}
                            </Box>
                          ))}
                      </Grid>

                      <Grid item xs={12} md={6}>
                        {lessonData?.sections
                          ?.slice(4, 9)
                          .map((section, index) => (
                            <Box key={index} sx={{ mb: 4 }}>
                              {index === 0 && (
                                <HoverableImage
                                  imageUrl={lessonData.images[1]?.imageUrl}
                                  isLoading={isGeneratingImages}
                                  loadingIndex={loadingImageIndex}
                                  currentIndex={1}
                                  userId={user.uid}
                                  theme={theme}
                                  showSnackbar={showSnackbar}
                                  onGenerateClick={() =>
                                    handleImageButtonClick(1)
                                  }
                                  onImageUpdate={(newImageUrl) => {
                                    setLessonData((prev) => ({
                                      ...prev,
                                      images: prev.images.map((img, i) =>
                                        i === 1
                                          ? { imageUrl: newImageUrl }
                                          : img,
                                      ),
                                    }));
                                  }}
                                />
                              )}
                              {isEditMode ? (
                                <>
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    value={section.subHeader}
                                    onChange={(e) => {
                                      const newSections = [
                                        ...lessonData.sections,
                                      ];
                                      newSections[index + 4] = {
                                        ...newSections[index + 4],
                                        subHeader: e.target.value,
                                      };
                                      setLessonData((prev) => ({
                                        ...prev,
                                        sections: newSections,
                                      }));
                                    }}
                                    InputProps={{
                                      sx: {
                                        fontFamily: "Georgia, serif",
                                        fontWeight: 700,
                                        fontSize: "1.25rem",
                                      },
                                    }}
                                    sx={{ mb: 2, mt: 2 }}
                                  />
                                  <TextField
                                    fullWidth
                                    multiline
                                    variant="outlined"
                                    value={section.content}
                                    onChange={(e) => {
                                      const newSections = [
                                        ...lessonData.sections,
                                      ];
                                      newSections[index + 4] = {
                                        ...newSections[index + 4],
                                        content: e.target.value,
                                      };
                                      setLessonData((prev) => ({
                                        ...prev,
                                        sections: newSections,
                                      }));
                                    }}
                                    InputProps={{
                                      sx: {
                                        fontFamily: "Georgia, serif",
                                        fontSize: "1.1rem",
                                        lineHeight: 1.8,
                                      },
                                    }}
                                    sx={{ mb: 3 }}
                                  />
                                </>
                              ) : (
                                <>
                                  <Typography
                                    variant="h6"
                                    sx={{
                                      fontFamily: "Georgia, serif",
                                      fontWeight: 700,
                                      mb: 2,
                                      mt: 4,
                                    }}
                                  >
                                    {section.subHeader}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontFamily: "Georgia, serif",
                                      fontSize: "1.1rem",
                                      lineHeight: 1.8,
                                      mb: 3,
                                      whiteSpace: "pre-wrap",
                                    }}
                                  >
                                    {section.content}
                                  </Typography>
                                </>
                              )}
                            </Box>
                          ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>

              {lessonData?.exercises && (
                <Box ref={exercisesSectionRef}>
                  <LessonExercisesDisplay
                    exercises={lessonData.exercises}
                    onUpdateLessonData={setLessonData}
                    viewMode={viewMode}
                    theme={theme}
                    styles={styles}
                  />
                </Box>
              )}

              {lessonData?.quiz && (
                <Box
                  ref={quizSectionRef}
                  sx={{ mt: 5, mb: 5, position: "relative" }}
                >
                  <LessonQuizDisplay
                    quizData={lessonData.quiz}
                    theme={theme}
                    styles={styles}
                    userId={user.uid}
                    viewMode={viewMode}
                    setLessonData={setLessonData}
                  />
                </Box>
              )}

              {lessonData?.timelinesSimple && (
                <Box
                  ref={timelineSimpleSectionRef}
                  sx={{ mt: 5, mb: 5, position: "relative" }}
                >
                  <TimelineSimpleDisplay
                    timelineData={lessonData.timelinesSimple}
                    onTimelineDataChange={(newData) =>
                      setLessonData({ ...lessonData, timelinesSimple: newData })
                    }
                    theme={theme}
                    styles={styles}
                    viewMode={viewMode}
                    user={user}
                  />
                </Box>
              )}

              {/* Add this where you want to display the timeline */}
              {lessonData?.timelines && (
                <Box
                  ref={timelineSectionRef}
                  sx={{ mt: 5, mb: 5, position: "relative" }}
                >
                  <TimelineDisplay
                    timelineData={lessonData.timelines}
                    onTimelineDataChange={(newData) =>
                      setLessonData({ ...lessonData, timelines: newData })
                    }
                    theme={theme}
                    styles={styles}
                    withImages={true}
                    viewMode={viewMode}
                    user={user}
                    onDelete={
                      viewMode
                        ? undefined
                        : (indexToDelete) => {
                            setLessonData((prev) => ({
                              ...prev,
                              timelines: prev.timelines.filter(
                                (_, index) => index !== indexToDelete,
                              ),
                            }));
                            showSnackbar(
                              "Tidslinjen har tagits bort",
                              "success",
                            );
                          }
                    }
                  />
                </Box>
              )}

              {!viewMode && (
                <DashedPapers
                  handleQuizPaperClick={handleQuizPaperClick}
                  handleComprehensionPaperClick={handleComprehensionPaperClick}
                  handleTimelinePaperClick={handleTimelinePaperClick}
                  handleTimelineSimplePaperClick={
                    handleTimelineSimplePaperClick
                  }
                  quizSectionRef={quizSectionRef}
                  timelineSectionRef={timelineSectionRef}
                  timelineSimpleSectionRef={timelineSimpleSectionRef}
                  exercisesSectionRef={exercisesSectionRef}
                  lessonData={lessonData}
                  setLessonData={setLessonData}
                />
              )}

              <SaveLessonDialog
                key={lessonData?.headline}
                open={saveDialogOpen}
                onClose={() => setSaveDialogOpen(false)}
                onSave={handleSaveWithComment}
                theme={theme}
                lessonName={lessonData?.headline || ""}
                lessonObject={lessonData}
                lessonType={"NewsLesson.js"}
              />
              <ImageGenerationModal
                open={showImageModal}
                onClose={handleCloseModal}
                onGenerate={handleGenerateWithInstructions}
                isGenerating={isGeneratingImages}
                instructions={imageInstructions}
                setInstructions={setImageInstructions}
                theme={theme}
              />
            </Box>

            <SlideInPaper
              isExpanded={isExpanded}
              onExpandedChange={setIsExpanded}
              lessonTitleRef={lessonTitleRef}
              viewMode={viewMode}
              onEdit={(e) => {
                e.stopPropagation();
                setIsEditMode(!isEditMode);
                setIsExpanded(false);
              }}
              toggleViewMode={toggleViewMode}
              theme={theme}
              open={showControlPanel}
              user={user}
              onClick={() => handlePrintNewsArticle(lessonData)}
              onTranslate={() => translateButtonRef.current?.click()}
              onSaveAsPdf={() => pdfButtonRef.current?.click()}
              onShareLink={() => shareLinkButtonRef.current?.click()}
              onShareWithStudents={() => setShareDialogOpen(true)}
              onShareWithFriends={() => setShareDialogOpenFriends(true)}
              onSaveToBank={() => setSaveDialogOpen(true)}
              onStudentMode={() => {
                window.scrollTo({ top: 0, behavior: "smooth" });
                toggleViewMode();
              }}
              onGenerateExercises={() => {
                comprehensionButtonRef.current?.click();
              }}
              onGenerateQuiz={() => {
                quizButtonRef.current?.click();
              }}
              onGenerateAssignments={() => {
                comprehensionButtonRef.current?.click();
              }}
              onGenerateTimeline={() => {
                timelineButtonRef.current?.click();
              }}
            />

            <ShareDialog
              open={shareDialogOpen}
              onClose={() => setShareDialogOpen(false)}
              onShare={handleShare}
              user={user}
              isLessonSharing={true}
              theme={theme}
              lessonName={lessonData?.headline || ""}
              lessonObject={lessonData}
              lessonType={"NewsLesson.js"}
            />

            <ShareDialog
              open={shareDialogOpenFriends}
              onClose={() => setShareDialogOpenFriends(false)}
              onShare={handleShare}
              user={user}
              isLessonSharing={true}
              theme={theme}
              lessonName={lessonData?.headline || ""}
              lessonObject={lessonData}
              lessonType={"NewsLesson.js"}
              shareWithFriends={true}
            />

            <LoadingBackdrop
              isOpen={isSharing}
              imagePath="/img/AI.png"
              loadingText={"Delar lektionen..."}
            />
          </>
        )}
      </Box>
    </Box>
  );
};

export default NewsLesson;
