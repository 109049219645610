import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Modal,
  Grid,
  Paper,
  Chip,
  Tabs,
  Tab,
  IconButton,
  Badge,
  Tooltip,
  Divider,
  FormGroup,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import StyleIcon from "@mui/icons-material/Style";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import BlurOnIcon from "@mui/icons-material/BlurOn";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import MoodIcon from "@mui/icons-material/Mood";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import Logger from "./Logger";
import CircleIcon from "@mui/icons-material/Circle";
import CheckIcon from "@mui/icons-material/Check";

import RestartAltIcon from "@mui/icons-material/RestartAlt";
import LightbulbIcon from "@mui/icons-material/LightbulbOutlined";
import CloseIcon from "@mui/icons-material/Close";
import SettingsIcon from "@mui/icons-material/Settings";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

const TabPanel = ({ children, value, index }) => (
  <Box
    role="tabpanel"
    hidden={value !== index}
    id={`tabpanel-${index}`}
    sx={{ width: "100%", height: "100%", overflow: "auto" }}
  >
    {value === index && children}
  </Box>
);
//hej
const CustomFormControlLabel = ({ theme, ...props }) => (
  <FormControlLabel
    {...props}
    sx={{
      margin: 0,
      padding: "8px 16px",
      borderRadius: "4px",
      width: "auto",
      "&:hover": {
        backgroundColor:
          theme.palette.mode === "dark"
            ? "rgba(255, 255, 255, 0.05)"
            : "rgba(0, 0, 0, 0.05)",
      },
      ...props.sx,
    }}
  />
);

const ImageGenerationModal = ({
  open,
  onClose,
  onGenerate,
  isGenerating,
  instructions,
  setInstructions,
  theme,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [showOptions, setShowOptions] = useState(false);
  const [formOptions, setFormOptions] = useState({
    style: {
      photo: false,
      painting: false,
      sketch: false,
      digital: false,
      anime: false,
      minimal: false,
      impressionism: false,
      cubism: false,
      watercolor: false,
    },
    era: {
      modern: false,
      viking: false,
      medieval: false,
      renaissance: false,
      future: false,
      ancient: false,
      1800: false,
      prehistoric: false,
    },
    color: {
      color: false,
      blackwhite: false,
      sepia: false,
      muted: false,
    },
    mood: {
      happy: false,
      dramatic: false,
      calm: false,
      mysterious: false,
      energetic: false,
      nostalgic: false,
      dystopic: false,
      romantic: false,
    },
    weather: {
      sunny: false,
      rainy: false,
      stormy: false,
      snowy: false,
      foggy: false,
    },
    timeOfDay: {
      day: false,
      night: false,
      sunrise: false,
      sunset: false,
      twilight: false,
    },
  });

  const presetOptions = {
    standard: {
      style: { photo: true },
      era: { modern: true },
      color: { color: true },
    },
    scary: {
      style: { digital: true },
      era: { medieval: true },
      color: { blackwhite: true, muted: true },
      mood: { dramatic: true, mysterious: true, dystopic: true },
      weather: { stormy: true, foggy: true },
      timeOfDay: { night: true, twilight: true },
    },
    dreamy: {
      style: { painting: true, impressionism: true, watercolor: true },
      color: { color: true, muted: true },
      mood: { calm: true, nostalgic: true, romantic: true },
      weather: { foggy: true },
      timeOfDay: { sunrise: true, sunset: true, twilight: true },
    },
    futuristic: {
      style: { digital: true, minimal: true },
      era: { future: true },
      color: { color: true },
      mood: { dramatic: true, mysterious: true, energetic: true },
      weather: { sunny: true },
      timeOfDay: { night: true },
    },
    vintage: {
      style: { photo: true },
      era: { 1800: true },
      color: { sepia: true, muted: true },
      mood: { calm: true, nostalgic: true, romantic: true },
      weather: { sunny: true },
      timeOfDay: { day: true, sunset: true },
    },
  };

  const getSelectedCount = (category) =>
    Object.values(formOptions[category]).filter(Boolean).length;

  const getTranslatedLabel = (category, key) => {
    const translations = {
      style: {
        photo: "Fotografisk",
        painting: "Målning",
        sketch: "Skiss",
        digital: "Digital konst",
        anime: "Anime",
        minimal: "Minimalistisk",
        impressionism: "Impressionism",
        cubism: "Kubism",
        watercolor: "Akvarell",
      },
      era: {
        modern: "Modern tid",
        viking: "Vikingatiden",
        medieval: "Medeltiden",
        renaissance: "Renässansen",
        future: "Framtid",
        ancient: "Antiken",
        1800: "1800-talet",
        prehistoric: "Förhistorisk tid",
      },
      color: {
        color: "Färg",
        blackwhite: "Svartvitt",
        sepia: "Sepia",
        muted: "Dämpade färger",
      },
      mood: {
        happy: "Glad",
        dramatic: "Dramatisk",
        calm: "Lugn",
        mysterious: "Mystisk",
        energetic: "Energisk",
        nostalgic: "Nostalgisk",
        dystopic: "Dystopisk",
        romantic: "Romantisk",
      },
      weather: {
        sunny: "Soligt",
        rainy: "Regnigt",
        stormy: "Stormigt",
        snowy: "Snöigt",
        foggy: "Dimmigt",
      },
      timeOfDay: {
        day: "Dag",
        night: "Natt",
        sunrise: "Soluppgång",
        sunset: "Solnedgång",
        twilight: "Skymning",
      },
    };
    return translations[category]?.[key] || key;
  };

  const generateStyleString = () => {
    let styleString = "";

    const selectedStyles = Object.entries(formOptions.style)
      .filter(([_, selected]) => selected)
      .map(([style, _]) => {
        const styles = {
          photo: "fotografisk stil",
          painting: "målning",
          sketch: "skiss",
          digital: "digital konst",
          anime: "anime stil",
          minimal: "minimalistisk",
          impressionism: "impressionistisk stil",
          cubism: "kubistisk stil",
          watercolor: "akvarell",
        };
        return styles[style];
      });
    if (selectedStyles.length)
      styleString += `i ${selectedStyles.join(" och ")} `;

    const selectedEras = Object.entries(formOptions.era)
      .filter(([_, selected]) => selected)
      .map(([era, _]) => {
        const eras = {
          modern: "modern tid",
          viking: "vikingatiden",
          medieval: "medeltiden",
          renaissance: "renässansen",
          future: "framtida",
          ancient: "antiken",
          1800: "1800-talet",
          prehistoric: "förhistorisk tid",
        };
        return eras[era];
      });
    if (selectedEras.length)
      styleString += `från ${selectedEras.join(" och ")} `;

    const selectedColors = Object.entries(formOptions.color)
      .filter(([_, selected]) => selected)
      .map(([color, _]) => {
        const colors = {
          blackwhite: "svartvitt",
          sepia: "sepia",
          muted: "dämpade färger",
        };
        return colors[color];
      });
    if (selectedColors.length)
      styleString += `i ${selectedColors.join(" och ")} `;

    const selectedMoods = Object.entries(formOptions.mood)
      .filter(([_, selected]) => selected)
      .map(([mood, _]) => {
        const moods = {
          happy: "glad",
          dramatic: "dramatisk",
          calm: "lugn",
          mysterious: "mystisk",
          energetic: "energisk",
          nostalgic: "nostalgisk",
          dystopic: "dystopisk",
          romantic: "romantisk",
        };
        return moods[mood];
      });
    if (selectedMoods.length)
      styleString += `med ${selectedMoods.join(" och ")} stämning `;

    const selectedWeather = Object.entries(formOptions.weather)
      .filter(([_, selected]) => selected)
      .map(([weather, _]) => {
        const weathers = {
          sunny: "soligt",
          rainy: "regnigt",
          stormy: "stormigt",
          snowy: "snöigt",
          foggy: "dimmigt",
        };
        return weathers[weather];
      });
    if (selectedWeather.length)
      styleString += `med ${selectedWeather.join(" och ")} väder `;

    const selectedTime = Object.entries(formOptions.timeOfDay)
      .filter(([_, selected]) => selected)
      .map(([time, _]) => {
        const times = {
          day: "dagtid",
          night: "natt",
          sunrise: "soluppgång",
          sunset: "solnedgång",
          twilight: "skymning",
        };
        return times[time];
      });
    if (selectedTime.length)
      styleString += `under ${selectedTime.join(" och ")} `;

    return styleString.trim();
  };

  const handleOptionToggle = (category, key) => {
    setFormOptions((prev) => ({
      ...prev,
      [category]: {
        ...prev[category],
        [key]: !prev[category][key],
      },
    }));
    setTimeout(updateInstructionsText, 0);
  };

  const updateInstructionsText = () => {
    const styleString = generateStyleString();
    if (styleString) {
      setInstructions((prev) => {
        const basePrompt = prev.split(".")[0];
        return `${basePrompt}. ${styleString}`;
      });
    }
  };

  useEffect(() => {
    Logger.log("instructions in ImageGenerationModal: ", instructions);
  }, [instructions]);

  useEffect(() => {
    const styleString = generateStyleString();
    if (styleString) {
      setInstructions((prev) => {
        const basePrompt = prev.split(".")[0];
        return `${basePrompt}. ${styleString}`;
      });
    }
  }, [formOptions]); // This will run whenever formOptions changes

  const handlePresetSelect = (preset) => {
    if (preset === "reset") {
      setFormOptions({
        style: Object.keys(formOptions.style).reduce(
          (acc, key) => ({ ...acc, [key]: false }),
          {},
        ),
        era: Object.keys(formOptions.era).reduce(
          (acc, key) => ({ ...acc, [key]: false }),
          {},
        ),
        color: Object.keys(formOptions.color).reduce(
          (acc, key) => ({ ...acc, [key]: false }),
          {},
        ),
        mood: Object.keys(formOptions.mood).reduce(
          (acc, key) => ({ ...acc, [key]: false }),
          {},
        ),
        weather: Object.keys(formOptions.weather).reduce(
          (acc, key) => ({ ...acc, [key]: false }),
          {},
        ),
        timeOfDay: Object.keys(formOptions.timeOfDay).reduce(
          (acc, key) => ({ ...acc, [key]: false }),
          {},
        ),
      });
      setInstructions((prev) => prev.split(".")[0]);
    } else {
      setInstructions("");
      // Instead of directly setting presetOptions, merge them with existing structure
      const newOptions = {
        style: { ...formOptions.style },
        era: { ...formOptions.era },
        color: { ...formOptions.color },
        mood: { ...formOptions.mood },
        weather: { ...formOptions.weather },
        timeOfDay: { ...formOptions.timeOfDay },
      };

      // First set everything to false
      Object.keys(newOptions).forEach((category) => {
        Object.keys(newOptions[category]).forEach((key) => {
          newOptions[category][key] = false;
        });
      });

      // Then apply the preset's true values
      Object.entries(presetOptions[preset]).forEach(([category, options]) => {
        Object.entries(options).forEach(([key, value]) => {
          newOptions[category][key] = value;
        });
      });

      setFormOptions(newOptions);
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        setShowOptions(false);

        onClose();
      }}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: "90%",
          maxWidth: showOptions ? 1200 : 600,
          maxHeight: "90vh",
          bgcolor: theme.palette.mode === "dark" ? "#1E2A3A" : "#ffffff",
          borderRadius: 2,
          boxShadow: 24,
          display: "flex",
          overflow: "hidden",
          transition: "max-width 0.3s ease-in-out",
        }}
      >
        {!showOptions ? (
          <Box
            sx={{
              width: "100%",
              p: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 3,
            }}
          >
            <Typography
              variant="h5"
              sx={{ mb: 2, textAlign: "center", fontWeight: "600" }}
            >
              Vill du anpassa eller generera direkt?
            </Typography>

            <Button
              variant="purp"
              fullWidth
              size="large"
              startIcon={<AutoAwesomeIcon />}
              onClick={() => {
                setShowOptions(false); // Add this
                onGenerate();
                onClose();
              }}
              sx={{ maxWidth: 280, height: 70 }}
            >
              Generera direkt
            </Button>

            <Button
              variant="print"
              fullWidth
              size="large"
              startIcon={<SettingsIcon />}
              onClick={() => setShowOptions(true)}
              sx={{ maxWidth: 280 }}
            >
              Anpassa generering
            </Button>
          </Box>
        ) : (
          <>
            {/* Left Sidebar */}
            <Box
              sx={{
                width: 240,
                borderRight: 1,
                borderColor: "divider",
                bgcolor: theme.palette.mode === "dark" ? "#171F2A" : "#f5f5f5",
              }}
            >
              <Tabs
                orientation="vertical"
                value={activeTab}
                onChange={(_, newValue) => setActiveTab(newValue)}
                sx={{
                  "& .MuiTab-root": {
                    minHeight: 48,
                    padding: "12px 16px",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: 1.5,
                    minWidth: "unset",
                    textAlign: "left",
                    borderRadius: 0,
                    "&.Mui-selected": {
                      bgcolor:
                        theme.palette.mode === "dark"
                          ? "rgba(255, 255, 255, 0.05)"
                          : "rgba(0, 0, 0, 0.05)",
                    },
                  },
                }}
              >
                <Tab
                  icon={<SettingsIcon />}
                  label="Anpassa"
                  iconPosition="start"
                  sx={{ height: "89px" }}
                />
                <Tab
                  icon={
                    <Badge
                      badgeContent={getSelectedCount("style")}
                      color="primary"
                    >
                      <StyleIcon />
                    </Badge>
                  }
                  label="Stil"
                  iconPosition="start"
                  sx={{ height: "69px" }}
                />
                <Tab
                  icon={
                    <Badge
                      badgeContent={getSelectedCount("era")}
                      color="primary"
                    >
                      <CalendarTodayIcon />
                    </Badge>
                  }
                  label="Tidsepok"
                  iconPosition="start"
                  sx={{ height: "69px" }}
                />
                <Tab
                  icon={
                    <Badge
                      badgeContent={getSelectedCount("color")}
                      color="primary"
                    >
                      <ColorLensIcon />
                    </Badge>
                  }
                  label="Färg"
                  iconPosition="start"
                  sx={{ height: "69px" }}
                />
                <Tab
                  icon={
                    <Badge
                      badgeContent={getSelectedCount("mood")}
                      color="primary"
                    >
                      <MoodIcon />
                    </Badge>
                  }
                  label="Stämning"
                  iconPosition="start"
                  sx={{ height: "69px" }}
                />
                <Tab
                  icon={
                    <Badge
                      badgeContent={
                        getSelectedCount("weather") +
                        getSelectedCount("timeOfDay")
                      }
                      color="primary"
                    >
                      <WbSunnyIcon />
                    </Badge>
                  }
                  label="Väder & Tid"
                  iconPosition="start"
                  sx={{ height: "69px" }}
                />
              </Tabs>
            </Box>

            {/* Main Content */}
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                height: "90vh",
              }}
            >
              {/* Header */}
              <Box
                sx={{
                  p: 3,
                  borderBottom: 1,
                  borderColor: "divider",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6" sx={{ fontWeight: "600" }}>
                  Anpassa bildgenerering
                </Typography>
                <IconButton
                  onClick={() => {
                    setShowOptions(false);
                    onClose();
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>

              {/* Content Area */}
              <Box sx={{ flex: 1, overflow: "auto", p: 3 }}>
                {/* Presets Panel */}
                {activeTab === 0 && (
                  <Box sx={{ textAlign: "left" }}>
                    <Typography
                      variant="subtitle1"
                      sx={{ mb: 1, fontWeight: "500" }}
                    >
                      Skriv dina instruktioner:
                    </Typography>

                    <TextField
                      fullWidth
                      multiline
                      rows={3}
                      value={instructions}
                      onChange={(e) => setInstructions(e.target.value)}
                      placeholder="Gör bilden fotorealistisk, tack."
                      sx={{ mb: 2 }}
                      InputProps={{
                        style: {
                          fontWeight: "400",
                          fontSize: "0.9rem",
                        },
                        endAdornment: (
                          <Tooltip title="Slumpa">
                            <IconButton
                              sx={{
                                position: "absolute",
                                top: "8px",
                                right: "8px",
                                padding: "4px",
                                "& img": {
                                  animation: "none",
                                  "&.spinning": {
                                    animation: "spin 0.3s ease-in-out 1",
                                  },
                                },
                                "@keyframes spin": {
                                  from: {
                                    transform: "rotate(0deg)",
                                  },
                                  to: {
                                    transform: "rotate(360deg)",
                                  },
                                },
                              }}
                              onClick={(e) => {
                                const img =
                                  e.currentTarget.querySelector("img");
                                img.classList.add("spinning");
                                setTimeout(() => {
                                  img.classList.remove("spinning");
                                  const randomInstructions = [
                                    "Skapa en fotorealistisk bild av en medelålders kaukasisk kvinnlig författare från tidigt 1900-tal. Hon har en smal kroppsbyggnad, kort mörkt hår, framträdande höga kindknotor och bär runda glasögon samt en elegant, åtsittande hatt och traditionella brittiska kläder. Hon sitter vid ett mahognybord, försjunken i djupa tankar medan hon skriver på papper med en liten, gammaldags bläckpenna.",
                                    "Generera en fotorealistisk bild som uttrycker de känslor som förmedlas i en tankfull dialog. Bilden representerar ett engagerat samtal om perspektiv på det undermedvetna kontra fri vilja som de dominerande krafterna i människans existens. Tonen är eftertänksam, nyanserad och något frågande. Kontexten är en kontemplativ diskurs mellan forskare.",
                                    "Skapa en drömlik bild av en magisk skog på natten, där träden lyser i olika färger och en liten älva flyger med ett gnistrande spår av ljus bakom sig. I bakgrunden syns en stjärnklar himmel med en gigantisk måne som reflekteras i en lugn sjö.",
                                    "Generera en bild av en futuristisk stad där alla byggnader svävar i luften och är sammankopplade med lysande broar. Gatorna nedanför är fyllda av självkörande fordon, och en stor digital skärm visar en holografisk nyhetssändning.",
                                    "Skildra ett ögonblick i en historisk fantasyvärld där en riddare i guldglänsande rustning står vid en gigantisk drake. De ser ut över ett vidsträckt kungarike från en klippa, medan solen går ner i horisonten.",
                                    "Skapa en bild av ett futuristiskt kök där en AI-robot lagar mat. Robotens armar är fullt mekaniska, men den har en ansiktsskärm som visar ett vänligt leende. Köket är fullt av högteknologiska apparater och ingredienser som svävar i luften.",
                                    "Måla en apokalyptisk scen där en övergiven stad har övertagits av naturen. Höga byggnader är täckta av mossa och växter, och en flock hjortar står mitt på en övervuxen motorväg. Solens strålar bryter genom molnen och skapar en känsla av hopp.",
                                    "Illustrera en surrealistisk scen där en person sitter på en flygande bok bland molnen. Andra böcker svävar omkring och skapar en sorts magisk bibliotekskänsla i himlen. Molnen är färgade i pastellfärger och solen syns som ett mjukt ljus i bakgrunden.",
                                    "Skapa en varm och nostalgisk bild av en gammaldags kaffebutik från 1950-talet. Butiken är fylld med färgglada skyltar, en jukebox i hörnet och människor som sitter vid små runda bord, dricker kaffe och skrattar.",
                                    "Skildra en vinterlandskapsscen där en ensam skidåkare åker genom en djup skog. Träden är täckta av snö, och det enda ljuset kommer från skidåkarens pannlampa, som lyser upp de frostiga omgivningarna.",
                                  ];
                                  if (randomInstructions.length > 0) {
                                    const randomIndex = Math.floor(
                                      Math.random() * randomInstructions.length,
                                    );
                                    setInstructions(
                                      randomInstructions[randomIndex],
                                    );
                                  }
                                }, 300); // 300ms * 3 spins
                              }}
                            >
                              <img
                                src="/img/dice.png"
                                alt="Random"
                                width="24"
                                height="24"
                              />
                            </IconButton>
                          </Tooltip>
                        ),
                      }}
                    />

                    <Grid container spacing={6} sx={{ mb: 4 }}>
                      <Grid item xs={12} md={6}>
                        <Paper
                          elevation={0}
                          sx={{
                            p: 2,
                            bgcolor:
                              theme.palette.mode === "dark"
                                ? "#1c262f"
                                : "#f5f5f5",
                            height: "100%",
                            borderRadius: 3,
                          }}
                        >
                          <Typography
                            variant="subtitle2"
                            sx={{
                              mb: 2,
                              color: "primary.main",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <LightbulbIcon
                              sx={{
                                fontSize: 18,

                                mr: 0.5,
                              }}
                            />{" "}
                            Beskrivande detaljer
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              mb: 1,
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <BlurOnIcon
                              sx={{
                                fontSize: 16,
                                color: "text.secondary",
                                mr: 0.5,
                              }}
                            />{" "}
                            Beskriv miljön i detalj: tid på dagen, väder,
                            omgivning
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              mb: 1,
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <BlurOnIcon
                              sx={{
                                fontSize: 16,
                                color: "text.secondary",
                                mr: 0.5,
                              }}
                            />{" "}
                            Specificera viktiga element: färger, material,
                            texturer
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              mb: 1,
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <BlurOnIcon
                              sx={{
                                fontSize: 16,
                                color: "text.secondary",
                                mr: 0.5,
                              }}
                            />{" "}
                            Lägg till stämningsord: lugn, dramatisk, mystisk
                          </Typography>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Paper
                          elevation={0}
                          sx={{
                            p: 2,
                            bgcolor:
                              theme.palette.mode === "dark"
                                ? "#1c262f"
                                : "#f5f5f5",
                            height: "100%",
                            borderRadius: 4,
                          }}
                        >
                          <Typography
                            variant="subtitle2"
                            sx={{
                              mb: 2,
                              color: "primary.main",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <LightbulbIcon
                              sx={{
                                fontSize: 18,

                                mr: 0.5,
                              }}
                            />{" "}
                            Konstnärliga val
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              mb: 1,
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <BlurOnIcon
                              sx={{
                                fontSize: 16,
                                color: "text.secondary",
                                mr: 0.5,
                              }}
                            />{" "}
                            Ange önskad konststil: olja, akvarell, digital art
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              mb: 1,
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <BlurOnIcon
                              sx={{
                                fontSize: 16,
                                color: "text.secondary",
                                mr: 0.5,
                              }}
                            />{" "}
                            Specificera perspektiv: porträtt, landskap, närbild
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              mb: 1,
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <BlurOnIcon
                              sx={{
                                fontSize: 16,
                                color: "text.secondary",
                                mr: 0.5,
                              }}
                            />{" "}
                            Välj konstnärliga referenser: i stil med [konstnär]
                          </Typography>
                        </Paper>
                      </Grid>
                    </Grid>
                    <Divider />
                    <Typography
                      variant="subtitle1"
                      sx={{
                        mb: 1,
                        fontWeight: "500",
                        mt: 4,
                      }}
                    >
                      Eller välj bland förinställningar:
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        mb: 3,
                        color: "text.secondary",
                        fontSize: "0.9rem",
                      }}
                    >
                      Välj bland våra förinställningar nedan eller konfigurera
                      själv i menyn till vänster
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        gap: 2,
                        flexWrap: "wrap",
                      }}
                    >
                      <Button
                        variant="print"
                        onClick={() => handlePresetSelect("standard")}
                        sx={{ minWidth: "120px" }}
                      >
                        Standard
                      </Button>
                      <Button
                        variant="print"
                        onClick={() => handlePresetSelect("scary")}
                        sx={{ minWidth: "120px" }}
                      >
                        Läskig
                      </Button>
                      <Button
                        variant="print"
                        onClick={() => handlePresetSelect("dreamy")}
                        sx={{ minWidth: "120px" }}
                      >
                        Drömsk
                      </Button>
                      <Button
                        variant="print"
                        onClick={() => handlePresetSelect("futuristic")}
                        sx={{ minWidth: "120px" }}
                      >
                        Futuristisk
                      </Button>
                      <Button
                        variant="print"
                        onClick={() => handlePresetSelect("vintage")}
                        sx={{ minWidth: "120px" }}
                      >
                        Vintage
                      </Button>
                      <Button
                        variant="success"
                        onClick={() => handlePresetSelect("reset")}
                        startIcon={<RestartAltIcon />}
                        sx={{ minWidth: "120px" }}
                      >
                        Nollställ
                      </Button>
                    </Box>
                  </Box>
                )}

                {/* Style Panel */}
                {activeTab === 1 && (
                  <FormGroup>
                    {Object.entries(formOptions.style).map(([key, value]) => (
                      <CustomFormControlLabel
                        key={key}
                        theme={theme}
                        control={
                          <Checkbox
                            checked={value}
                            onChange={() => handleOptionToggle("style", key)}
                          />
                        }
                        label={getTranslatedLabel("style", key)}
                      />
                    ))}
                  </FormGroup>
                )}

                {/* Era Panel */}
                {activeTab === 2 && (
                  <FormGroup>
                    {Object.entries(formOptions.era).map(([key, value]) => (
                      <CustomFormControlLabel
                        key={key}
                        theme={theme}
                        control={
                          <Checkbox
                            checked={value}
                            onChange={() => handleOptionToggle("era", key)}
                          />
                        }
                        label={getTranslatedLabel("era", key)}
                      />
                    ))}
                  </FormGroup>
                )}

                {/* Color Panel */}
                {activeTab === 3 && (
                  <FormGroup>
                    {Object.entries(formOptions.color).map(([key, value]) => (
                      <CustomFormControlLabel
                        key={key}
                        theme={theme}
                        control={
                          <Checkbox
                            checked={value}
                            onChange={() => handleOptionToggle("color", key)}
                          />
                        }
                        label={getTranslatedLabel("color", key)}
                      />
                    ))}
                  </FormGroup>
                )}

                {/* Mood Panel */}
                {activeTab === 4 && (
                  <FormGroup>
                    {Object.entries(formOptions.mood).map(([key, value]) => (
                      <CustomFormControlLabel
                        key={key}
                        theme={theme}
                        control={
                          <Checkbox
                            checked={value}
                            onChange={() => handleOptionToggle("mood", key)}
                          />
                        }
                        label={getTranslatedLabel("mood", key)}
                      />
                    ))}
                  </FormGroup>
                )}

                {/* Weather & Time Panel */}
                {activeTab === 5 && (
                  <>
                    <Typography
                      variant="subtitle1"
                      sx={{ mb: 2, fontWeight: 500 }}
                    >
                      Väder
                    </Typography>
                    <FormGroup>
                      {Object.entries(formOptions.weather).map(
                        ([key, value]) => (
                          <CustomFormControlLabel
                            key={key}
                            theme={theme}
                            control={
                              <Checkbox
                                checked={value}
                                onChange={() =>
                                  handleOptionToggle("weather", key)
                                }
                              />
                            }
                            label={getTranslatedLabel("weather", key)}
                          />
                        ),
                      )}
                    </FormGroup>

                    <Typography
                      variant="subtitle1"
                      sx={{ mt: 4, mb: 2, fontWeight: 500 }}
                    >
                      Tid på dygnet
                    </Typography>
                    <FormGroup>
                      {Object.entries(formOptions.timeOfDay).map(
                        ([key, value]) => (
                          <CustomFormControlLabel
                            key={key}
                            theme={theme}
                            control={
                              <Checkbox
                                checked={value}
                                onChange={() =>
                                  handleOptionToggle("timeOfDay", key)
                                }
                              />
                            }
                            label={getTranslatedLabel("timeOfDay", key)}
                          />
                        ),
                      )}
                    </FormGroup>
                  </>
                )}
              </Box>

              {/* Footer with TextField and Actions */}
              {/* Footer with Selected Options, TextField and Actions */}
              <Box sx={{ p: 3, borderTop: 1, borderColor: "divider" }}>
                {Object.values(formOptions).some((category) =>
                  Object.values(category).some(Boolean),
                ) && (
                  <>
                    <Typography
                      variant="subtitle1"
                      sx={{ fontWeight: "600", mb: 2 }}
                    >
                      Valda alternativ:
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: 1,
                        mb: 3,
                      }}
                    >
                      {Object.entries(formOptions).flatMap(
                        ([category, options]) =>
                          Object.entries(options)
                            .filter(([_, selected]) => selected)
                            .map(([key, _]) => (
                              <Chip
                                key={`${category}-${key}`}
                                label={getTranslatedLabel(category, key)}
                                onDelete={() =>
                                  handleOptionToggle(category, key)
                                }
                                sx={{ mr: 1, mb: 1 }}
                              />
                            )),
                      )}
                    </Box>
                  </>
                )}

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: 2,
                  }}
                >
                  <Button
                    startIcon={<CloseIcon />}
                    variant="print"
                    onClick={() => {
                      setShowOptions(false);
                      onClose();
                    }}
                  >
                    Avbryt
                  </Button>
                  <Button
                    variant="purp"
                    onClick={onGenerate}
                    disabled={isGenerating}
                    startIcon={<AutoAwesomeIcon />}
                  >
                    {isGenerating ? "Genererar..." : "Generera bild"}
                  </Button>
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default ImageGenerationModal;
