import React from "react";
import { Backdrop, Box, Typography, CircularProgress } from "@mui/material";

const LoadingBackdrop = ({
  isOpen = false,
  imagePath = "/img/AI.png",
  loadingText = "Loading...",
  imageAlt = "Loading Icon",
  transitionDuration = 1000,
}) => {
  return (
    <Backdrop
      transitionDuration={transitionDuration}
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 4,
        bgcolor: "rgba(0, 0, 0, 0.85)",
        backdropFilter: "blur(8px)",
        zIndex: 9999,
      }}
      open={isOpen}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 3,
        }}
      >
        <Box
          sx={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mb: 1,
          }}
        >
          <Box
            component="img"
            src={imagePath}
            alt={imageAlt}
            sx={{
              width: 120,
              height: 120,
              animation: "pulse 1s infinite ease-in-out",
              filter: "drop-shadow(0 0 20px rgba(255, 255, 255, 0.2))",
            }}
          />
        </Box>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 300,
            letterSpacing: 1,
            opacity: 0.9,
            textAlign: "center",
            fontSize: "1.55rem",
            marginBottom: "20px",
          }}
        >
          {loadingText}
        </Typography>
        <CircularProgress sx={{ color: "#ffffff" }} size="30px" />
      </Box>
    </Backdrop>
  );
};

export default LoadingBackdrop;
