import React, { useState } from "react";
import {
  FormControl,
  Select,
  MenuItem,
  Grid,
  Typography,
  Button,
} from "@mui/material";
import ReactCountryFlag from "react-country-flag";
import BookmarkIcon from "@mui/icons-material/Bookmark";

// Reuse the languageToFlag mapping for flags
const languageToFlag = {
  amhariska: "et",
  arabiska: "eg",
  albanska: "al",
  assyriska: "iq",
  bengali: "bd",
  "brasiliansk-portugisiska": "br",
  bulgariska: "bg",
  dari: "af",
  danska: "dk",
  engelska: "gb",
  estniska: "ee",
  farsi: "ir",
  finska: "fi",
  franska: "fr",
  grekiska: "gr",
  hebreiska: "il",
  hindi: "in",
  indonesiska: "id",
  isländska: "is",
  italienska: "it",
  japanska: "jp",
  kannada: "in",
  kinesiska: "cn",
  koreanska: "kr",
  kroatiska: "hr",

  kurdiska: "iq",

  lettiska: "lv",

  litauiska: "lt",
  makedonska: "mk",
  nederländska: "nl",
  norska: "no",
  polska: "pl",
  pashtu: "af",
  persiska: "ir",
  portugisiska: "pt",
  punjabi: "in",
  rumänska: "ro",
  ryska: "ru",
  serbiska: "rs",

  slovakiska: "sk",

  slovenska: "si",
  somali: "so",
  sorani: "iq",
  spanska: "es",
  svenska: "se",
  swahili: "tz",
  tagalog: "ph",
  tamil: "in",
  telugu: "in",
  thai: "th",
  tjeckiska: "cz",
  turkiska: "tr",
  tyska: "de",
  ukrainska: "ua",
  ungerska: "hu",
  urdu: "pk",
  vietnamesiska: "vn",
  wolof: "sn",

  malayalam: "in",
  marathi: "in",
  gujarati: "in",
  khmer: "kh",
  havasa: "ng",
  yoruba: "ng",
  igbo: "ng",
  burmesiska: "mm",

  armeniska: "am",
  azerbajdzjanska: "az",
  berber: "ma",
  "egyptisk-arabiska": "eg",
  georgiska: "ge",
  kabyliska: "dz",
  "maghrebi-arabiska": "ma",
  maltesiska: "mt",
  "modern-standardarabiska": "sa",
  tachelhit: "ma",

  "thai-lao": "la",
  nepali: "np",
  sinhala: "lk",
  malagasy: "mg",
  zulu: "za",
  xhosa: "za",
  afrikaans: "za",
  mongoliska: "mn",
  kazakiska: "kz",
  uzbekiska: "uz",
  tibetanska: "cn",
  karen: "mm",
};

const LanguageSelector = ({ language, setLanguage, shrinked = false }) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  if (shrinked) {
    return (
      <div>
        <Button
          variant="contained"
          color="primary"
          disableRipple
          onClick={handleOpen}
          startIcon={
            languageToFlag[language] ? (
              <ReactCountryFlag
                countryCode={languageToFlag[language].toUpperCase()}
                svg
                style={{
                  width: "20px",
                  height: "auto",
                }}
              />
            ) : (
              <BookmarkIcon />
            )
          }
          sx={{
            padding: "11px",
            marginRight: "0px",
            paddingLeft: "14px",
            paddingRight: "3px",
            minWidth: "auto",
            textTransform: "none",
          }}
        />
        <FormControl>
          <Select
            open={open}
            onClose={handleClose}
            value={language}
            onChange={(e) => {
              setLanguage(e.target.value);
              handleClose();
            }}
            renderValue={() => ""}
            sx={{ display: "none" }}
            MenuProps={{
              anchorEl: anchorEl,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              sx: {
                "& .MuiPaper-root": {
                  marginTop: "8px",
                },
              },
            }}
          >
            <MenuItem value="amhariska">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="ET"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Amhariska</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem value="afrikaans">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="ZA"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Afrikaans</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem value="albanska">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="AL"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Albanska</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem value="assyriska">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="IQ"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Assyriska</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem value="azerbajdzjanska">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="AZ"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Azerbajdzjanska</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem value="arabiska">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="SA"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Arabiska</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem value="armeniska">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="AM"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Armeniska</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem value="bengali">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="BD"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Bengali</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem value="berber">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="MA"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Berber</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem value="brasiliansk-portugisiska">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="BR"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Brasiliansk portugisiska</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem value="bulgariska">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="BG"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Bulgariska</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem value="burmesiska">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="MM"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Burmesiska</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem value="dari">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="AF"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Dari</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem value="egyptisk-arabiska">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="EG"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Egyptisk arabiska</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem value="danska">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="DK"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Danska</Typography>
                </Grid>
              </Grid>
            </MenuItem>
            <MenuItem value="engelska">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="GB"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Engelska</Typography>
                </Grid>
              </Grid>
            </MenuItem>
            <MenuItem value="estniska">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="EE"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Estniska</Typography>
                </Grid>
              </Grid>
            </MenuItem>
            <MenuItem value="farsi">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="IR"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Farsi</Typography>
                </Grid>
              </Grid>
            </MenuItem>
            <MenuItem value="finska">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="FI"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Finska</Typography>
                </Grid>
              </Grid>
            </MenuItem>
            <MenuItem value="franska">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="FR"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Franska</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem value="georgiska">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="GE"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Georgiska</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem value="grekiska">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="GR"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Grekiska</Typography>
                </Grid>
              </Grid>
            </MenuItem>
            <MenuItem value="gujarati">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="IN"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Gujarati</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem value="hebreiska">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="IL"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Hebreiska</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem value="havasa">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="NG"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Havasa</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem value="hindi">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="IN"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Hindi</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem value="igbo">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="NG"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Igbo</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem value="indonesiska">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="ID"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Indonesiska</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem value="isländska">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="IS"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Isländska</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem value="italienska">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="IT"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Italienska</Typography>
                </Grid>
              </Grid>
            </MenuItem>
            <MenuItem value="japanska">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="JP"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Japanska</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem value="kabyliska">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="DZ"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Kabyliska</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem value="kannada">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="IN"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Kannada</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem value="karen">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="MM"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Karen</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem value="kazakiska">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="KZ"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Kazakiska</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem value="kinesiska">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="CN"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Kinesiska</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem value="khmer">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="KH"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Khmer</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem value="koreanska">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="KR"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Koreanska</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem value="kroatiska">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="HR"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Kroatiska</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem value="kurdiska">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="IQ"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Kurdiska</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem value="lettiska">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="LV"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Lettiska</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem value="litauiska">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="LT"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Litauiska</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem value="maghrebi-arabiska">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="MA"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Maghrebi arabiska</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem value="makedonska">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="MK"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Makedonska</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem value="maltesiska">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="MT"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Maltesiska</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem value="malagasy">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="MG"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Malagasy</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem value="malayalam">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="IN"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Malayalam</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem value="marathi">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="IN"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Marathi</Typography>
                </Grid>
              </Grid>
            </MenuItem>
            <MenuItem value="mongoliska">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="MN"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Mongoliska</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem value="modern-standardarabiska">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="SA"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Modern standardarabiska</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem value="nederländska">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="NL"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Nederländska</Typography>
                </Grid>
              </Grid>
            </MenuItem>
            <MenuItem value="norska">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="NO"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Norska</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem value="nepali">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="NP"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Nepali</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem value="pashtu">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="AF"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Pashtu</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem value="persiska">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="IR"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Persiska</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem value="polska">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="PL"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Polska</Typography>
                </Grid>
              </Grid>
            </MenuItem>
            <MenuItem value="portugisiska">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="PT"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Portugisiska</Typography>
                </Grid>
              </Grid>
            </MenuItem>
            <MenuItem value="punjabi">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="IN"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Punjabi</Typography>
                </Grid>
              </Grid>
            </MenuItem>
            <MenuItem value="rumänska">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="RO"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Rumänska</Typography>
                </Grid>
              </Grid>
            </MenuItem>
            <MenuItem value="ryska">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="RU"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Ryska</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem value="serbiska">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="RS"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Serbiska</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem value="slovakiska">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="SK"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Slovakiska</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem value="slovenska">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="SI"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Slovenska</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem value="sinhala">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="LK"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Sinhala</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem value="somali">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="SO"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Somali</Typography>
                </Grid>
              </Grid>
            </MenuItem>
            <MenuItem value="sorani">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="IQ"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Sorani</Typography>
                </Grid>
              </Grid>
            </MenuItem>
            <MenuItem value="spanska">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="ES"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Spanska</Typography>
                </Grid>
              </Grid>
            </MenuItem>
            <MenuItem value="svenska">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="SE"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Svenska</Typography>
                </Grid>
              </Grid>
            </MenuItem>
            <MenuItem value="swahili">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="TZ"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Swahili</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem value="tachelhit">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="MA"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Tachelhit</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem value="tagalog">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="PH"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Tagalog</Typography>
                </Grid>
              </Grid>
            </MenuItem>
            <MenuItem value="tamil">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="IN"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Tamil</Typography>
                </Grid>
              </Grid>
            </MenuItem>
            <MenuItem value="telugu">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="IN"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Telugu</Typography>
                </Grid>
              </Grid>
            </MenuItem>
            <MenuItem value="thai">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="TH"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Thai</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem value="thai-lao">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="LA"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Thai-Lao</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem value="tibetanska">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="CN"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Tibetanska</Typography>
                </Grid>
              </Grid>
            </MenuItem>
            <MenuItem value="tjeckiska">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="CZ"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Tjeckiska</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem value="turkiska">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="TR"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Turkiska</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem value="tyska">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="DE"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Tyska</Typography>
                </Grid>
              </Grid>
            </MenuItem>
            <MenuItem value="ukrainska">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="UA"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Ukrainska</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem value="ungerska">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="HU"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Ungerska</Typography>
                </Grid>
              </Grid>
            </MenuItem>
            <MenuItem value="urdu">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="PK"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Urdu</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem value="uzbekiska">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="UZ"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Uzbekiska</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem value="wolof">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="SN"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Wolof</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem value="xhosa">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="ZA"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Xhosa</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem value="yoruba">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="NG"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Yoruba</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem value="zulu">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="ZA"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Zulu</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem value="vietnamesiska">
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <ReactCountryFlag
                    countryCode="VN"
                    svg
                    style={{ width: "20px", height: "auto" }}
                  />
                </Grid>
                <Grid item>
                  <Typography>Vietnamesiska</Typography>
                </Grid>
              </Grid>
            </MenuItem>
          </Select>
        </FormControl>
      </div>
    );
  }

  return (
    <FormControl fullWidth margin="normal" sx={{ mb: 0, mt: 0 }}>
      <Select
        value={language}
        onChange={(e) => setLanguage(e.target.value)}
        renderValue={(selected) => (
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              {languageToFlag[selected] && (
                <ReactCountryFlag
                  countryCode={languageToFlag[selected].toUpperCase()}
                  svg
                  style={{
                    width: "20px",
                    height: "auto",
                  }}
                />
              )}
            </Grid>
            <Grid item>
              <Typography>
                {selected.charAt(0).toUpperCase() + selected.slice(1)}
              </Typography>
            </Grid>
          </Grid>
        )}
      >
        <MenuItem value="amhariska">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="ET"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Amhariska</Typography>
            </Grid>
          </Grid>
        </MenuItem>

        <MenuItem value="afrikaans">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="ZA"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Afrikaans</Typography>
            </Grid>
          </Grid>
        </MenuItem>

        <MenuItem value="albanska">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="AL"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Albanska</Typography>
            </Grid>
          </Grid>
        </MenuItem>

        <MenuItem value="assyriska">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="IQ"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Assyriska</Typography>
            </Grid>
          </Grid>
        </MenuItem>

        <MenuItem value="azerbajdzjanska">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="AZ"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Azerbajdzjanska</Typography>
            </Grid>
          </Grid>
        </MenuItem>

        <MenuItem value="arabiska">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="SA"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Arabiska</Typography>
            </Grid>
          </Grid>
        </MenuItem>

        <MenuItem value="armeniska">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="AM"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Armeniska</Typography>
            </Grid>
          </Grid>
        </MenuItem>

        <MenuItem value="bengali">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="BD"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Bengali</Typography>
            </Grid>
          </Grid>
        </MenuItem>

        <MenuItem value="berber">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="MA"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Berber</Typography>
            </Grid>
          </Grid>
        </MenuItem>

        <MenuItem value="brasiliansk-portugisiska">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="BR"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Brasiliansk portugisiska</Typography>
            </Grid>
          </Grid>
        </MenuItem>

        <MenuItem value="bulgariska">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="BG"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Bulgariska</Typography>
            </Grid>
          </Grid>
        </MenuItem>

        <MenuItem value="burmesiska">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="MM"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Burmesiska</Typography>
            </Grid>
          </Grid>
        </MenuItem>

        <MenuItem value="dari">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="AF"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Dari</Typography>
            </Grid>
          </Grid>
        </MenuItem>

        <MenuItem value="egyptisk-arabiska">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="EG"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Egyptisk arabiska</Typography>
            </Grid>
          </Grid>
        </MenuItem>

        <MenuItem value="danska">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="DK"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Danska</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem value="engelska">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="GB"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Engelska</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem value="estniska">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="EE"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Estniska</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem value="farsi">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="IR"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Farsi</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem value="finska">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="FI"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Finska</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem value="franska">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="FR"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Franska</Typography>
            </Grid>
          </Grid>
        </MenuItem>

        <MenuItem value="georgiska">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="GE"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Georgiska</Typography>
            </Grid>
          </Grid>
        </MenuItem>

        <MenuItem value="grekiska">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="GR"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Grekiska</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem value="gujarati">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="IN"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Gujarati</Typography>
            </Grid>
          </Grid>
        </MenuItem>

        <MenuItem value="hebreiska">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="IL"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Hebreiska</Typography>
            </Grid>
          </Grid>
        </MenuItem>

        <MenuItem value="havasa">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="NG"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Havasa</Typography>
            </Grid>
          </Grid>
        </MenuItem>

        <MenuItem value="hindi">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="IN"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Hindi</Typography>
            </Grid>
          </Grid>
        </MenuItem>

        <MenuItem value="igbo">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="NG"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Igbo</Typography>
            </Grid>
          </Grid>
        </MenuItem>

        <MenuItem value="indonesiska">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="ID"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Indonesiska</Typography>
            </Grid>
          </Grid>
        </MenuItem>

        <MenuItem value="isländska">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="IS"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Isländska</Typography>
            </Grid>
          </Grid>
        </MenuItem>

        <MenuItem value="italienska">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="IT"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Italienska</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem value="japanska">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="JP"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Japanska</Typography>
            </Grid>
          </Grid>
        </MenuItem>

        <MenuItem value="kabyliska">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="DZ"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Kabyliska</Typography>
            </Grid>
          </Grid>
        </MenuItem>

        <MenuItem value="kannada">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="IN"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Kannada</Typography>
            </Grid>
          </Grid>
        </MenuItem>

        <MenuItem value="karen">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="MM"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Karen</Typography>
            </Grid>
          </Grid>
        </MenuItem>

        <MenuItem value="kazakiska">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="KZ"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Kazakiska</Typography>
            </Grid>
          </Grid>
        </MenuItem>

        <MenuItem value="kinesiska">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="CN"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Kinesiska</Typography>
            </Grid>
          </Grid>
        </MenuItem>

        <MenuItem value="khmer">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="KH"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Khmer</Typography>
            </Grid>
          </Grid>
        </MenuItem>

        <MenuItem value="koreanska">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="KR"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Koreanska</Typography>
            </Grid>
          </Grid>
        </MenuItem>

        <MenuItem value="kroatiska">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="HR"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Kroatiska</Typography>
            </Grid>
          </Grid>
        </MenuItem>

        <MenuItem value="kurdiska">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="IQ"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Kurdiska</Typography>
            </Grid>
          </Grid>
        </MenuItem>

        <MenuItem value="lettiska">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="LV"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Lettiska</Typography>
            </Grid>
          </Grid>
        </MenuItem>

        <MenuItem value="litauiska">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="LT"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Litauiska</Typography>
            </Grid>
          </Grid>
        </MenuItem>

        <MenuItem value="maghrebi-arabiska">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="MA"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Maghrebi arabiska</Typography>
            </Grid>
          </Grid>
        </MenuItem>

        <MenuItem value="makedonska">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="MK"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Makedonska</Typography>
            </Grid>
          </Grid>
        </MenuItem>

        <MenuItem value="maltesiska">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="MT"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Maltesiska</Typography>
            </Grid>
          </Grid>
        </MenuItem>

        <MenuItem value="malagasy">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="MG"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Malagasy</Typography>
            </Grid>
          </Grid>
        </MenuItem>

        <MenuItem value="malayalam">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="IN"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Malayalam</Typography>
            </Grid>
          </Grid>
        </MenuItem>

        <MenuItem value="marathi">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="IN"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Marathi</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem value="mongoliska">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="MN"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Mongoliska</Typography>
            </Grid>
          </Grid>
        </MenuItem>

        <MenuItem value="modern-standardarabiska">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="SA"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Modern standardarabiska</Typography>
            </Grid>
          </Grid>
        </MenuItem>

        <MenuItem value="nederländska">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="NL"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Nederländska</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem value="norska">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="NO"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Norska</Typography>
            </Grid>
          </Grid>
        </MenuItem>

        <MenuItem value="nepali">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="NP"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Nepali</Typography>
            </Grid>
          </Grid>
        </MenuItem>

        <MenuItem value="pashtu">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="AF"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Pashtu</Typography>
            </Grid>
          </Grid>
        </MenuItem>

        <MenuItem value="persiska">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="IR"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Persiska</Typography>
            </Grid>
          </Grid>
        </MenuItem>

        <MenuItem value="polska">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="PL"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Polska</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem value="portugisiska">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="PT"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Portugisiska</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem value="punjabi">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="IN"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Punjabi</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem value="rumänska">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="RO"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Rumänska</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem value="ryska">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="RU"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Ryska</Typography>
            </Grid>
          </Grid>
        </MenuItem>

        <MenuItem value="serbiska">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="RS"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Serbiska</Typography>
            </Grid>
          </Grid>
        </MenuItem>

        <MenuItem value="slovakiska">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="SK"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Slovakiska</Typography>
            </Grid>
          </Grid>
        </MenuItem>

        <MenuItem value="slovenska">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="SI"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Slovenska</Typography>
            </Grid>
          </Grid>
        </MenuItem>

        <MenuItem value="sinhala">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="LK"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Sinhala</Typography>
            </Grid>
          </Grid>
        </MenuItem>

        <MenuItem value="somali">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="SO"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Somali</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem value="sorani">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="IQ"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Sorani</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem value="spanska">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="ES"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Spanska</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem value="svenska">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="SE"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Svenska</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem value="swahili">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="TZ"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Swahili</Typography>
            </Grid>
          </Grid>
        </MenuItem>

        <MenuItem value="tachelhit">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="MA"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Tachelhit</Typography>
            </Grid>
          </Grid>
        </MenuItem>

        <MenuItem value="tagalog">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="PH"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Tagalog</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem value="tamil">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="IN"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Tamil</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem value="telugu">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="IN"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Telugu</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem value="thai">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="TH"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Thai</Typography>
            </Grid>
          </Grid>
        </MenuItem>

        <MenuItem value="thai-lao">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="LA"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Thai-Lao</Typography>
            </Grid>
          </Grid>
        </MenuItem>

        <MenuItem value="tibetanska">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="CN"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Tibetanska</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem value="tjeckiska">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="CZ"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Tjeckiska</Typography>
            </Grid>
          </Grid>
        </MenuItem>

        <MenuItem value="turkiska">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="TR"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Turkiska</Typography>
            </Grid>
          </Grid>
        </MenuItem>

        <MenuItem value="tyska">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="DE"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Tyska</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem value="ukrainska">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="UA"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Ukrainska</Typography>
            </Grid>
          </Grid>
        </MenuItem>

        <MenuItem value="ungerska">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="HU"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Ungerska</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem value="urdu">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="PK"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Urdu</Typography>
            </Grid>
          </Grid>
        </MenuItem>

        <MenuItem value="uzbekiska">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="UZ"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Uzbekiska</Typography>
            </Grid>
          </Grid>
        </MenuItem>

        <MenuItem value="wolof">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="SN"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Wolof</Typography>
            </Grid>
          </Grid>
        </MenuItem>

        <MenuItem value="xhosa">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="ZA"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Xhosa</Typography>
            </Grid>
          </Grid>
        </MenuItem>

        <MenuItem value="yoruba">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="NG"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Yoruba</Typography>
            </Grid>
          </Grid>
        </MenuItem>

        <MenuItem value="zulu">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="ZA"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Zulu</Typography>
            </Grid>
          </Grid>
        </MenuItem>

        <MenuItem value="vietnamesiska">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ReactCountryFlag
                countryCode="VN"
                svg
                style={{ width: "20px", height: "auto" }}
              />
            </Grid>
            <Grid item>
              <Typography>Vietnamesiska</Typography>
            </Grid>
          </Grid>
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default LanguageSelector;
