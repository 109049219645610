import React, { useState } from "react";
import {
  Drawer,
  Box,
  Typography,
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  CircularProgress,
} from "@mui/material";
import {
  Create,
  Mic,
  History,
  PushPin,
  Person,
  ContentCopy,
  ChevronRight,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import { useSnackbar } from "./SnackbarHandler";
import RecommendationComponent from "./RecommendationComponent";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import { formatUpvotes } from "./functions";
import FlagIcon from "@mui/icons-material/Flag";
import InfoIcon from "@mui/icons-material/Info";
import LinkIcon from "@mui/icons-material/Link";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import LoadingBackdrop from "./LoadingBackdrop";
import { useNavigate } from "react-router-dom";
import Logger from "./Logger";
import IosShareIcon from "@mui/icons-material/IosShare";
import TuneIcon from "@mui/icons-material/Tune";
import BookmarkIcon from "@mui/icons-material/Bookmark";

import QuizIcon from "@mui/icons-material/Quiz";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db } from "./firebase";
import ShareDialog from "./ShareDialog";

const TeacherInfoDrawer = ({
  open,
  onClose,
  teacher,
  user,
  styles,
  loggedInUser,
  theme,
  handleCreateNewChat,
  setDrawerOpen,
  setDescriptionDrawerOpen,
  setSavedMessagesDrawerOpen,
  setEditDrawerOpen,
  setIsCreating,
  handleShareConversation,

  generateQuiz,
  currentChatId,
}) => {
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isGeneratingQuiz, setIsGeneratingQuiz] = useState(false);
  const isDark = theme.palette.mode === "dark";

  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [shareDialogOpenFriends, setShareDialogOpenFriends] = useState(false);
  const [isSharing, setIsSharing] = useState(false);

  const [shareDialogOpenConv, setShareDialogOpenConv] = useState(false);
  const [shareDialogOpenConvFriends, setShareDialogOpenConvFriends] =
    useState(false);

  const handleShare = async ({ studentIds, comment }) => {
    setIsSharing(true);

    try {
      // Create notifications for each recipient
      const sharePromises = studentIds.map(async (recipientId) => {
        await addDoc(collection(db, "notifications"), {
          type: "teacher_shared",
          recipientId: recipientId,
          senderId: loggedInUser.uid,
          read: false,
          createdAt: serverTimestamp(),
          teacherData: {
            teacherId: teacher.id,
            teacherName: teacher.characterName,
            comment: comment,
            imageUrl: teacher.imageUrl,
          },
        });
      });

      await Promise.all(sharePromises);
      showSnackbar("Du har nu delat läraren!", "success");
      setShareDialogOpen(false);
      setShareDialogOpenFriends(false);
    } catch (error) {
      Logger.error("Error sharing teacher:", error);
      showSnackbar("Ett fel uppstod när läraren skulle delas.", "error");
    } finally {
      setIsSharing(false);
    }
  };

  // Add new handler for sharing conversations
  const handleShareConversation2 = async ({ studentIds, comment }) => {
    setIsSharing(true);

    try {
      const sharePromises = studentIds.map(async (recipientId) => {
        await addDoc(collection(db, "notifications"), {
          type: "conversation_shared",
          recipientId: recipientId,
          senderId: loggedInUser.uid,
          read: false,
          createdAt: serverTimestamp(),
          conversationData: {
            conversationId: currentChatId,
            teacherId: teacher.id,
            teacherName: teacher.characterName,
            comment: comment,
            imageUrl: teacher.imageUrl,
          },
        });
      });

      await Promise.all(sharePromises);
      showSnackbar("Du har nu delat konversationen!", "success");
      setShareDialogOpenConv(false);
      setShareDialogOpenConvFriends(false);
    } catch (error) {
      Logger.error("Error sharing conversation:", error);
      showSnackbar("Ett fel uppstod när konversationen skulle delas.", "error");
    } finally {
      setIsSharing(false);
    }
  };

  const copyTeacherUrl = () => {
    Logger.log("teacher object: ", teacher);

    const url = `https://studera.ai/app/chat/${teacher.id}`;
    navigator.clipboard.writeText(url).then(() => {
      showSnackbar("Länk till karaktären kopierad!", "success");
    });
  };

  const handleEditTeacher = () => {
    navigate(`/app/larare/${teacher.id}`);
  };

  const handleGenerateQuiz = async () => {
    setIsGeneratingQuiz(true);
    await generateQuiz();
    setIsGeneratingQuiz(false);
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: {
            width: { xs: "75%", sm: "50%", md: "25%" },

            boxShadow: "none",
            borderLeft: isDark ? "1px solid #34414c" : "1px solid #e0e0e0",
            backgroundImage: "none",
          },
        }}
        ModalProps={{
          BackdropProps: {
            invisible: true,
          },
        }}
      >
        <Box
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          {/* Teacher Info */}
          <Box sx={{ display: "flex", alignItems: "center", mt: 0 }}>
            <Avatar
              src={teacher.imageUrl}
              alt={teacher.characterName}
              sx={{
                width: 70,
                height: 70,
                marginRight: 2,
                borderRadius: "100px",
                border: "2px solid white",
                boxShadow: isDark ? "0 2px 10px rgba(0,0,0,0.2)" : "none",
              }}
            />
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                variant="h1"
                sx={{
                  ...styles.header,
                  borderBottom: "0px",
                  fontWeight: "500",
                  fontSize: "18px",
                  marginTop: "0px",
                  marginBottom: "4px",
                  marginLeft: { xs: "0px", md: "inherit" },
                }}
              >
                {teacher.characterName}
              </Typography>
              <Box
                sx={{
                  ...styles.grayText,
                  mt: 0,
                  fontSize: "13px",
                  fontWeight: "500",
                }}
              >
                Av @{user?.displayName || "anonym"}
              </Box>

              <Box
                sx={{
                  ...styles.grayText,
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                  marginTop: "6px",
                }}
              >
                <ThumbUpOffAltIcon sx={{ fontSize: "15px" }} />
                <Typography
                  variant="body2"
                  sx={{ fontSize: "13px", lineHeight: 1, fontWeight: "500" }}
                >
                  {formatUpvotes(teacher.upvotes)}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              mt: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: 1,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Button
                variant={theme.palette.mode === "light" ? "contained" : "clean"}
                color="primary"
                disableRipple
                onClick={copyTeacherUrl}
                startIcon={<ContentCopy />}
                sx={{
                  ...styles.shrinkButton,
                  "& .MuiButton-startIcon > *:nth-of-type(1)": {
                    fontSize: "17px",
                    color: isDark ? "#bbb" : "#797979",
                  },
                }}
              ></Button>
              <RecommendationComponent
                userId={loggedInUser.uid}
                itemId={teacher.id}
                collectionName="publicTeachers"
              />
            </Box>

            <Box sx={{ display: "flex", gap: 1 }}>
              <Button
                variant={theme.palette.mode === "light" ? "contained" : "clean"}
                color="primary"
                disableRipple
                startIcon={<FlagIcon />}
                sx={{
                  ...styles.shrinkButton,
                  "& .MuiButton-startIcon > *:nth-of-type(1)": {
                    fontSize: "17px",
                    color: isDark ? "#bbb" : "#797979",
                  },
                }}
              ></Button>
              <Button
                variant={theme.palette.mode === "light" ? "contained" : "clean"}
                color="primary"
                disableRipple
                onClick={() => {
                  setDescriptionDrawerOpen(true);
                }}
                startIcon={<InfoIcon />}
                sx={{
                  ...styles.shrinkButton,
                  "& .MuiButton-startIcon > *:nth-of-type(1)": {
                    fontSize: "17px",
                    color: isDark ? "#bbb" : "#797979",
                  },
                }}
              ></Button>
            </Box>
          </Box>

          <Divider sx={{ mt: 2, mb: 1 }} />

          {/* Menu Items */}
          <List
            sx={{
              backgroundColor:
                theme.palette.mode === "dark" ? "transparent" : "inherit",
              flexGrow: 1,
            }}
          >
            <ListItem
              variant="compactList"
              className="rounded"
              button
              disabled={isGeneratingQuiz}
              onClick={() => {
                onClose();
                handleCreateNewChat();
              }}
            >
              <ListItemIcon>
                <Create />
              </ListItemIcon>
              <ListItemText primary="Ny chatt" />
            </ListItem>

            <ListItem
              variant="compactList"
              className="rounded"
              disabled={isGeneratingQuiz}
              button
              onClick={() => {
                setDrawerOpen(true);
              }}
            >
              <ListItemIcon>
                <History />
              </ListItemIcon>
              <ListItemText primary="Historik" />
              <ChevronRight />
            </ListItem>
            <ListItem
              disabled={isGeneratingQuiz}
              variant="compactList"
              className="rounded"
              button
              onClick={() => {
                setSavedMessagesDrawerOpen(true);
              }}
            >
              <ListItemIcon>
                <BookmarkIcon />
              </ListItemIcon>
              <ListItemText primary="Sparat" />
              <ChevronRight />
            </ListItem>

            <ListItem
              variant="compactList"
              className="rounded"
              button
              disabled={isGeneratingQuiz}
            >
              <ListItemIcon>
                <Mic />
              </ListItemIcon>
              <ListItemText primary="Röst" />

              <ChevronRight />
            </ListItem>
            <Divider sx={{ mb: 1, mt: 1 }} />
            <ListItem
              variant="compactList"
              className="rounded"
              button
              onClick={handleGenerateQuiz}
              disabled={isGeneratingQuiz}
            >
              <ListItemIcon>
                {isGeneratingQuiz ? (
                  <CircularProgress size={16} color="inherit" />
                ) : (
                  <QuizIcon />
                )}
              </ListItemIcon>
              <ListItemText
                primary={
                  isGeneratingQuiz
                    ? "Skapar quiz..."
                    : "Quiz baserat på konversationen"
                }
              />
            </ListItem>
            <Divider sx={{ mb: 1, mt: 1 }} />
            <ListItem
              variant="compactList"
              className="rounded"
              button
              onClick={() => setShareDialogOpenFriends(true)}
            >
              <ListItemIcon>
                <PersonAddIcon />
              </ListItemIcon>
              <ListItemText primary="Dela karaktär med vänner" />
            </ListItem>

            <ListItem
              variant="compactList"
              className="rounded"
              button
              onClick={() => setShareDialogOpen(true)}
            >
              <ListItemIcon>
                <IosShareIcon />
              </ListItemIcon>
              <ListItemText primary="Dela karaktär med elever" />
            </ListItem>
            <Divider sx={{ mb: 1, mt: 1 }} />

            <ListItem
              variant="compactList"
              className="rounded"
              button
              onClick={() => setShareDialogOpenConvFriends(true)}
            >
              <ListItemIcon>
                <PersonAddIcon />
              </ListItemIcon>
              <ListItemText primary="Dela konversation med vänner" />
            </ListItem>

            <ListItem
              variant="compactList"
              className="rounded"
              button
              onClick={() => setShareDialogOpenConv(true)}
            >
              <ListItemIcon>
                <IosShareIcon />
              </ListItemIcon>
              <ListItemText primary="Dela konversation med elever" />
            </ListItem>

            <ListItem
              variant="compactList"
              className="rounded"
              button
              onClick={() => {
                handleShareConversation();
              }}
            >
              <ListItemIcon>
                <LinkIcon />
              </ListItemIcon>
              <ListItemText primary="Kopiera URL till konversationen" />
            </ListItem>
          </List>
          {/* Bottom Menu Items */}
          <List>
            <Divider sx={{ mb: 1 }} />
            <ListItem
              variant="compactList"
              className="rounded"
              disabled={isGeneratingQuiz}
              button
              onClick={() => {
                setEditDrawerOpen(true);
              }}
            >
              <ListItemIcon>
                <TuneIcon />
              </ListItemIcon>
              <ListItemText primary="Redigera karaktär" />
            </ListItem>

            <ListItem
              variant="compactList"
              disabled={isGeneratingQuiz}
              className="rounded"
              button
              onClick={() => {
                setIsCreating(true);
                setEditDrawerOpen(true);
              }}
            >
              <ListItemIcon>
                <PersonAddIcon />
              </ListItemIcon>
              <ListItemText primary="Skapa ny karaktär" />
            </ListItem>
          </List>
        </Box>
      </Drawer>

      <ShareDialog
        open={shareDialogOpen}
        onClose={() => setShareDialogOpen(false)}
        onShare={handleShare}
        user={loggedInUser}
      />

      <ShareDialog
        open={shareDialogOpenFriends}
        onClose={() => setShareDialogOpenFriends(false)}
        onShare={handleShare}
        user={loggedInUser}
        shareWithFriends={true}
      />

      {/* Conversation sharing dialogs */}
      <ShareDialog
        open={shareDialogOpenConv}
        onClose={() => setShareDialogOpenConv(false)}
        onShare={handleShareConversation2}
        user={loggedInUser}
      />

      <ShareDialog
        open={shareDialogOpenConvFriends}
        onClose={() => setShareDialogOpenConvFriends(false)}
        onShare={handleShareConversation2}
        user={loggedInUser}
        shareWithFriends={true}
      />

      <LoadingBackdrop
        isOpen={isSharing}
        imagePath="/img/AI.png"
        loadingText="Delar..."
      />
    </>
  );
};

export default TeacherInfoDrawer;
