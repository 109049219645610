import React, { useState, useRef, useCallback, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  IconButton,
  Tooltip,
  CircularProgress,
  Grid,
  Paper,
  Input,
  Menu,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { callApiWithStreaming } from "./api"; // Adjust import path as needed
import PulsatingDot from "./PulsatingDot"; // Adjust import path as needed
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import CreateIcon from "@mui/icons-material/Create"; // New import for custom instruction icon
import WordIcon2019 from "./WordIcon2019";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import ShuffleIcon from "@mui/icons-material/Shuffle";
import { Tabs, Tab } from "@mui/material";
import TranslateIcon from "@mui/icons-material/Translate";
import SummarizeIcon from "@mui/icons-material/Summarize";
import AssignmentIcon from "@mui/icons-material/Assignment";
import BuildIcon from "@mui/icons-material/Build";
import TableChartIcon from "@mui/icons-material/TableChart";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CompressIcon from "@mui/icons-material/Compress";
import AllOutIcon from "@mui/icons-material/AllOut";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import ExpandIcon from "@mui/icons-material/Expand";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import SendIcon from "@mui/icons-material/Send";
import LanguageSelector from "./LanguageSelector";
import GavelIcon from "@mui/icons-material/Gavel";
import LinkIcon from "@mui/icons-material/Link";
import RepeatIcon from "@mui/icons-material/Repeat";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import QuizIcon from "@mui/icons-material/Quiz";
import RateReviewIcon from "@mui/icons-material/RateReview";
import ViewStreamIcon from "@mui/icons-material/ViewStream";
import LanguageIcon from "@mui/icons-material/Language";
import UpdateIcon from "@mui/icons-material/Update";
import HistoryIcon from "@mui/icons-material/History";
import StopIcon from "@mui/icons-material/Stop";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckIcon from "@mui/icons-material/Check";
import SpellcheckIcon from "@mui/icons-material/Spellcheck";
import InfoBox from "./InfoBox";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import EmojiObjectsIcon from "@mui/icons-material/EmojiObjects";
import BarChartIcon from "@mui/icons-material/BarChart";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import BrushIcon from "@mui/icons-material/Brush";
import LocalFloristIcon from "@mui/icons-material/LocalFlorist";
import { marked } from "marked";
import DOMPurify from "dompurify";
import mammoth from "mammoth";
import { getStyles } from "../index";
import Suggestions from "./Suggestions";
import { ThemeSelectorFlow } from "./ThemeSelectorFlow";
import WordIcon from "./Icons/WordIcon";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import TheaterComedyIcon from "@mui/icons-material/TheaterComedy";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import IdeaGenerator from "./IdeaGenerator";
import Logger from "./Logger";
import { useLocation } from "react-router-dom";
// New imports for export functionality
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Document, Packer, Paragraph, TextRun, HeadingLevel } from "docx";
import { saveAs } from "file-saver";

import ListAltIcon from "@mui/icons-material/ListAlt";
import CategoryIcon from "@mui/icons-material/Category";

import SchoolIcon from "@mui/icons-material/School";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PsychologyIcon from "@mui/icons-material/Psychology";
import BalanceIcon from "@mui/icons-material/Balance";
import TransformIcon from "@mui/icons-material/Transform";

const TextManager = ({ user }) => {
  const [outputText, setOutputText] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [activeButton, setActiveButton] = useState(null);
  const [isCopied, setIsCopied] = useState(false);
  const abortControllerRef = useRef(null);
  const outputBoxRef = useRef(null);
  const theme = useTheme();
  const styles = getStyles(theme);
  const [inputLength, setInputLength] = useState(0);
  const maxInputLength = 2000;
  const [anchorEl, setAnchorEl] = useState(null);
  const [customInstruction, setCustomInstruction] = useState("");

  const [language, setLanguage] = useState("arabiska");

  const subjectInputRef = useRef(null);
  const [visibleButtons, setVisibleButtons] = useState([]);
  const [showAllButtons, setShowAllButtons] = useState(true);
  const [actionText, setActionText] = useState(
    "2. Välj vad du vill göra med texten",
  );
  const location = useLocation();
  const [currentTab, setCurrentTab] = useState(0);
  const resultsSectionRef = useRef(null);

  const [lessonTheme, setLessonTheme] = useState("");
  const transcriptRef = useRef("");
  const uploadedFileRef = useRef("");

  const [predefinedTheme, setPredefinedTheme] = useState(null);

  const [customTheme, setCustomTheme] = useState("");
  const wikipediaContentRef = useRef("");
  const randomizedContentRef = useRef("");
  const coursePlanContentRef = useRef("");
  const urlContentRef = useRef("");
  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  useEffect(() => {
    Logger.log("lessonTheme: ", lessonTheme);
  }, [lessonTheme]);

  useEffect(() => {
    Logger.log("predefinedTheme: ", predefinedTheme);
  }, [predefinedTheme]);

  useEffect(() => {
    Logger.log("customTheme: ", customTheme);
  }, [customTheme]);

  useEffect(() => {
    Logger.log("youtubeTranscript: ", transcriptRef.current);
  }, [transcriptRef.current]);

  useEffect(() => {
    Logger.log("wikipediaContentRef: ", wikipediaContentRef.current);
  }, [wikipediaContentRef.current]);
  useEffect(() => {
    Logger.log("uploadedFileRef: ", uploadedFileRef.current);
  }, [uploadedFileRef.current]);

  useEffect(() => {
    Logger.log("randomizedContentRef: ", randomizedContentRef.current);
  }, [randomizedContentRef.current]);

  useEffect(() => {
    Logger.log("coursePlanContentRef: ", coursePlanContentRef.current);
  }, [coursePlanContentRef.current]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const sourceParam = params.get("function");

    if (sourceParam) {
      setShowAllButtons(false);
      switch (sourceParam) {
        case "variera":
          setVisibleButtons(["Variera"]);
          setActionText("2. Variera");
          break;
        case "summera":
          setVisibleButtons(["Summera"]);
          setActionText("2. Summera");
          break;
        case "ratta":
          setVisibleButtons(["Rätta"]);
          setActionText("2. Rätta");
          break;
        case "formatera":
          setVisibleButtons(["Formatera"]);
          setActionText("2. Formatera");
          break;
        case "faktakolla":
          setVisibleButtons(["Faktakolla"]);
          setActionText("2. Faktakolla");
          break;
        case "punktlista":
          setVisibleButtons(["Punktlista"]);
          setActionText("2. Punktlista");
          break;
        case "tabell":
          setVisibleButtons(["Tabell"]);
          setActionText("2. Tabell");
          break;
        case "forbattra":
          setVisibleButtons(["Förbättra"]);
          setActionText("2. Förbättra");
          break;
        case "kritisera":
          setVisibleButtons(["Kritisera"]);
          setActionText("2. Kritisera");
          break;
        case "omstrukturera":
          setVisibleButtons(["Omstrukturera"]);
          setActionText("2. Omstrukturera");
          break;
        case "argumentanalysera":
          setVisibleButtons(["Argumentationsanalysera"]);
          setActionText("2. Argumentationsanalys");
          break;
        case "konceptideer":
          setVisibleButtons(["Jämför koncept & idéer"]);
          setActionText("2. Jämför koncept & idéer");
          break;
        case "intertextuellanalys":
          setVisibleButtons(["Intertextuell analys"]);
          setActionText("2. Intertextuell analys");
          break;
        case "generera-studieuppgifter":
          setVisibleButtons(["Generera studieuppgifter"]);
          setActionText("2. Generera studieuppgifter");
          break;
        case "generera-flervalsfragor":
          setVisibleButtons(["Generera flervalsfrågor"]);
          setActionText("2. Generera flervalsfrågor");
          break;
        default:
          setVisibleButtons([]);
          setShowAllButtons(true);
          setActionText("2. Välj vad du vill göra med texten");
          break;
      }
    } else {
      setVisibleButtons([]);
      setShowAllButtons(true);
      setActionText("2. Välj vad du vill göra med texten");
    }
    window.scrollTo(0, 0);
  }, [location]);

  const handleExportClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleExportClose = () => {
    setAnchorEl(null);
  };
  const handleCustomInstructionChange = (event) => {
    setCustomInstruction(event.target.value);
  };

  const handleCustomSubmit = () => {
    if (customInstruction.trim()) {
      handleButtonClick(customInstruction, "Custom");
    }
  };

  const exportToPDF = async () => {
    const parsedHtml = marked.parse(outputText);

    // Create PDF document
    const doc = new jsPDF();

    // Create temporary div to hold content
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = parsedHtml;

    // Set initial y position
    let yPos = 20;

    // Set font
    doc.setFont("helvetica");
    doc.setFontSize(12);

    // Function to add text with word wrap
    const addWrappedText = (text, x, y, maxWidth) => {
      const lines = doc.splitTextToSize(text, maxWidth);
      doc.text(lines, x, y);
      return y + lines.length * 7;
    };

    // Process content
    const processNode = (node, isTableChild = false) => {
      if (node.nodeType === 3 && isTableChild) {
        return;
      }

      if (node.nodeType === 3) {
        // Text node
        if (node.textContent.trim()) {
          yPos = addWrappedText(node.textContent.trim(), 20, yPos, 170);
          yPos += 5;
        }
        return;
      }

      if (node.nodeType !== 1) return;

      const tagName = node.tagName.toLowerCase();

      switch (tagName) {
        case "h1":
        case "h2":
        case "h3":
          const level = parseInt(node.tagName[1]) - 1;
          doc.setFontSize(16 - level * 2);
          yPos = addWrappedText(node.textContent, 20, yPos, 170);
          doc.setFontSize(12);
          yPos += 10;
          return;

        case "p":
          yPos = addWrappedText(node.textContent, 20, yPos, 170);
          yPos += 7;
          return;

        case "ul":
        case "ol":
          Array.from(node.children).forEach((li, index) => {
            const bullet = tagName === "ul" ? "•" : `${index + 1}.`;
            yPos = addWrappedText(`${bullet} ${li.textContent}`, 25, yPos, 165);
            yPos += 5;
          });
          yPos += 5;
          return;

        default:
          if (node.textContent.trim() && !node.children.length) {
            yPos = addWrappedText(node.textContent, 20, yPos, 170);
            yPos += 5;
          }
      }

      // Check if we need a new page
      if (yPos > doc.internal.pageSize.height - 20) {
        doc.addPage();
        yPos = 20;
      }

      // Process children
      if (!["p", "h1", "h2", "h3"].includes(tagName)) {
        Array.from(node.childNodes).forEach((child) =>
          processNode(child, isTableChild),
        );
      }
    };

    // Process the content
    Array.from(tempDiv.childNodes).forEach((node) => processNode(node, false));

    // Save the PDF
    doc.save("textlabbet_resultat.pdf");
    handleExportClose();
  };

  const exportToWord = async () => {
    const parsedHtml = marked.parse(outputText);

    // Create proper Word HTML format
    const htmlContent = `
    <html xmlns:o='urn:schemas-microsoft-com:office:office' 
          xmlns:w='urn:schemas-microsoft-com:office:word'
          xmlns='http://www.w3.org/TR/REC-html40'>
    <head>
      <meta charset='utf-8'>
      <title>Export</title>
      <!--[if gte mso 9]>
      <xml>
        <w:WordDocument>
          <w:View>Print</w:View>
          <w:Zoom>90</w:Zoom>
          <w:DoNotOptimizeForBrowser/>
        </w:WordDocument>
      </xml>
      <![endif]-->
      <style>
        body, p, div, table, td, th {
          font-family: Calibri, sans-serif;
          font-size: 12pt;
        }
        table { border-collapse: collapse; width: 100%; }
        td, th { border: 1px solid black; padding: 8px; }
      </style>
    </head>
    <body>
      ${parsedHtml}
    </body>
    </html>
  `;

    // Create blob with Word-specific HTML
    const blob = new Blob([htmlContent], {
      type: "application/vnd.ms-word;charset=utf-8",
    });

    // Create download link
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "textlabbet_resultat.doc";

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
    handleExportClose();
  };

  const handleIdeaGenerated = (idea) => {
    if (subjectInputRef.current) {
      subjectInputRef.current.value = idea;
      setInputLength(idea.length);
    }
  };

  const handleInputChange = (event) => {
    setInputLength(event.target.value.length);
  };

  const buttons = [
    {
      label: "Variera",
      icon: ShuffleIcon,
      instruction: "Variera texten utan att ändra innebörden.",
    },
    {
      label: "Summera",
      icon: SummarizeIcon,
      instruction: "Sammanfatta texten kortfattat.",
    },
    {
      label: "Formatera",
      icon: FormatAlignLeftIcon,
      instruction:
        "Ge texten bättre formatering, t.ex. rubriker, stycken och fetstil för att förbättra läsbarheten.",
    },
    {
      label: "Komplicera",
      icon: BuildIcon,
      instruction: "Gör texten mer komplex och avancerad.",
    },
    {
      label: "Förenkla",
      icon: AutoFixHighIcon,
      instruction: "Förenkla texten så att den blir lättare att förstå.",
    },

    {
      label: "Faktakolla",
      icon: FactCheckIcon,
      instruction:
        "Identifiera påståenden i texten och försök verifiera dem mot tillförlitliga källor.",
    },
    {
      label: "Punktlista",
      icon: FormatListBulletedIcon,
      instruction: "Omvandla texten till en tydlig punktlista.",
    },

    {
      label: "Tabell",
      icon: TableChartIcon,
      instruction: "Omvandla texten till en tydlig tabell.",
    },

    {
      label: "Förbättra",
      icon: TrendingUpIcon,
      instruction: "Förbättra textens flyt och ton, gör den mer engagerande.",
    },
    {
      label: "Metaforifiera",
      icon: BrushIcon,
      instruction:
        "Infoga fler metaforer och bildspråk för en mer målande text.",
    },
    {
      label: "Krydda",
      icon: LocalFloristIcon,
      instruction:
        "Lägg till starkare adjektiv och adverb för en mer färgstark text.",
    },

    {
      label: "Förkorta",
      icon: CompressIcon,
      instruction: "Korta ner texten utan att tappa viktigt innehåll.",
    },
    {
      label: "Förlänga",
      icon: ExpandIcon,
      instruction: "Utöka texten med mer detaljer och förklaringar.",
    },
    {
      label: "Parafrasera",
      icon: RepeatIcon,
      instruction: "Omformulera texten med andra ord.",
    },
    {
      label: "Analysera",
      icon: AnalyticsIcon,
      instruction: "Analysera textens innehåll, struktur och budskap.",
    },
    {
      label: "Kritisera",
      icon: RateReviewIcon,
      instruction: "Ge konstruktiv kritik på textens innehåll och upplägg.",
    },
    {
      label: "Omstrukturera",
      icon: ViewStreamIcon,
      instruction: "Ändra textens struktur för bättre flöde och läsbarhet.",
    },
    {
      label: "Kontextualisera",
      icon: LanguageIcon,
      instruction: "Sätt texten i ett bredare sammanhang.",
    },
    {
      label: "Modernisera",
      icon: UpdateIcon,
      instruction: "Uppdatera texten till ett mer modernt språkbruk.",
    },
    {
      label: "Arkaisera",
      icon: HistoryIcon,
      instruction: "Omforma texten till ett äldre språkbruk.",
    },
    {
      label: "Rätta",
      icon: SpellcheckIcon,
      instruction:
        "Rätta stavfel och grammatiska fel i texten. Stavfel markeras med understrykning, grammatiska fel med fetstil, och andra typer av fel på lämpligt sätt.",
    },
    {
      label: "Konceptualisera",
      icon: LightbulbIcon,
      instruction:
        "Abstrahera texten till dess grundläggande koncept och idéer.",
    },
    {
      label: "Brainstorma",
      icon: EmojiObjectsIcon,
      instruction: "Generera idéer och tankar relaterade till textens ämne.",
    },
    {
      label: "Ordfrekvensberäkna",
      icon: BarChartIcon,
      instruction:
        "Analysera ordanvändning och frekvens i texten. Skriv ut exakt hur många ord det finns om det finns väldigt många liknande ord.",
    },
    {
      label: "Humorisera",
      icon: SentimentVerySatisfiedIcon,
      instruction: "Lägg till humoristiska inslag i texten.",
    },
    {
      label: "Dramatisera",
      icon: TheaterComedyIcon,
      instruction: "Gör texten mer dramatisk och känsloladdad.",
    },
    {
      label: "Poetisera",
      icon: MusicNoteIcon,
      instruction: "Omvandla texten till en poetisk form med rim och rytm.",
    },

    {
      label: "Argumentationsanalysera",
      icon: GavelIcon,
      instruction:
        "Identifiera och utvärdera argument som presenteras i texten.",
    },
    {
      label: "Jämför koncept & idéer",
      icon: CompareArrowsIcon,
      instruction:
        "Skapa en strukturerad jämförelse mellan olika koncept eller idéer i texten.",
    },

    {
      label: "Intertextuell analys",
      icon: LinkIcon,
      instruction:
        "Föreslå kopplingar mellan texten och andra relevanta texter eller verk.",
    },

    {
      label: "Generera studieuppgifter",
      icon: AssignmentIcon,
      instruction:
        "Skapa studieuppgifter och diskussionsfrågor baserade på textens innehåll.",
    },
    {
      label: "Generera flervalsfrågor",
      icon: QuizIcon,
      instruction:
        "Skapa flervalsfrågor (A-E) baserade på textens innehåll. Strukturera det som ett prov med facit i slutet.",
    },
    {
      label: "Generera fyll i luckan-frågor",
      icon: TextFieldsIcon,
      instruction:
        'Skapa "Fylla i luckan"-frågor baserade på textens innehåll. Strukturera det som ett prov med facit i slutet.',
    },
  ];

  useEffect(() => {
    if (outputBoxRef.current) {
      outputBoxRef.current.scrollTop = outputBoxRef.current.scrollHeight;
    }
  }, [outputText]);

  const handleButtonClick = async (instruction, label) => {
    if (isProcessing) {
      abortControllerRef.current.abort();
      setIsProcessing(false);
      setActiveButton(null);
      return;
    }
    window.scrollTo(0, document.body.scrollHeight);

    if (resultsSectionRef.current) {
      resultsSectionRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }

    setIsProcessing(true);
    setActiveButton(label);
    setOutputText("");
    abortControllerRef.current = new AbortController();

    let themeToUse = (
      lessonTheme === "youtube"
        ? transcriptRef.current
        : lessonTheme === "customTheme"
          ? customTheme
          : lessonTheme === "predefined"
            ? predefinedTheme
            : lessonTheme === "fileUpload"
              ? uploadedFileRef.current
              : lessonTheme === "wikipedia"
                ? wikipediaContentRef.current
                : lessonTheme === "randomizer"
                  ? randomizedContentRef.current
                  : lessonTheme === "url"
                    ? urlContentRef.current
                    : coursePlanContentRef.current
    )?.slice(0, 5000);

    Logger.log("themeToUse: ", themeToUse);

    try {
      let streamingContent = "";
      const onChunk = (chunk) => {
        streamingContent += chunk;
        const htmlContent = marked(streamingContent);
        const sanitizedHtml = DOMPurify.sanitize(htmlContent);
        setOutputText(sanitizedHtml);
      };

      await callApiWithStreaming(
        [
          {
            role: "system",
            content: `Du är en hjälpsam assistent som modifierar text enligt instruktioner.  Svara på: ${language}`,
          },
          {
            role: "user",
            content: `${instruction} Här är texten: ${themeToUse}. Svara på: ${language} `,
          },
        ],
        onChunk,
        user.uid,
        abortControllerRef.current.signal,
      );
    } catch (error) {
      if (error.name === "AbortError") {
        Logger.log("Stream was aborted");
      } else {
        console.error("Error calling AI API:", error);
        setOutputText(
          "Ett fel uppstod vid kommunikation med AI. Vänligen försök igen.",
        );
      }
    } finally {
      setIsProcessing(false);
      setActiveButton(null);
    }
  };

  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(outputText.replace(/<[^>]+>/g, ""));
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  }, [outputText]);

  const handleSelect = (selected) => {
    if (selected.text) {
      if (subjectInputRef.current) {
        subjectInputRef.current.value = selected.value;
      }
    }
  };

  const getExampleForFunction = (label) => {
    switch (label) {
      case "Variera":
        return '"Katten satt på mattan" kan bli "Den lurviga fyrbenta vännen vilade på golvbeklädnaden."';
      case "Summera":
        return "En lång artikel om klimatförändringar kan sammanfattas till dess huvudpunkter och slutsatser.";
      case "Komplicera":
        return '"Solen skiner" kan utvecklas till "Det celestiala objektet emitterar elektromagnetisk strålning som penetrerar atmosfären."';
      case "Förenkla":
        return '"Den komplexa metaboliska processen" kan förenklas till "Hur kroppen bryter ner mat."';
      case "Förkorta":
        return "En omfattande projektbeskrivning kan kortas ner till de mest väsentliga punkterna.";
      case "Förlänga":
        return '"Sverige är vackert" kan utökas med beskrivningar av landskap, kultur och sevärdheter.';
      case "Parafrasera":
        return '"Det regnar ute" kan omformuleras till "Himlen gråter sina droppar över marken."';
      case "Analysera":
        return "En dikt kan analyseras för dess teman, bildspråk och struktur.";
      case "Kritisera":
        return "En uppsats kan granskas för dess argument, källanvändning och struktur.";
      case "Omstrukturera":
        return "En kronologisk berättelse kan omstruktureras för att börja in medias res.";
      case "Kontextualisera":
        return "En historisk händelse kan sättas i sitt bredare sociala och politiska sammanhang.";
      case "Modernisera":
        return "En text från 1800-talet kan uppdateras med modernt språkbruk och referenser.";
      case "Arkaisera":
        return "En modern nyhet kan omskrivas i stil med en 1700-tals pamflett.";
      case "Rätta":
        return 'En text med stavfel som "Jag ska gå till skolan imorgon" kan korrigeras till "Jag ska gå till skolan i morgon" med understrykningar för rättade fel.';
      case "Konceptualisera":
        return 'En detaljerad beskrivning av en smartphone kan abstraheras till koncept som "kommunikation", "teknologi" och "mobilitet".';
      case "Brainstorma":
        return 'För en text om klimatförändringar kan relaterade idéer som "förnybara energikällor", "koldioxidneutralitet" och "cirkulär ekonomi" genereras.';
      case "Ordfrekvensberäkna":
        return 'I en text kan ordfrekvensen analyseras, t.ex. "och: 15, att: 12, det: 10" för att visa de mest använda orden.';
      case "Humorisera":
        return "En torr nyhetsartikel kan omvandlas till en rolig sketch.";
      case "Dramatisera":
        return "En enkel beskrivning av en promenad kan bli till en spännande äventyrsberättelse.";
      case "Poetisera":
        return "En vardaglig konversation kan bli till en lyrisk dikt.";
      case "Faktakolla":
        return "En text om klimatförändringar kan få påståenden verifierade med aktuell forskning.";
      case "Punktlista":
        return "En lång beskrivande text om ett ämne kan omvandlas till en koncis punktlista med huvudpoänger.";
      case "Förbättra":
        return "En torr, faktaspäckad text kan förvandlas till en engagerande berättelse som fångar läsarens intresse.";
      case "Metaforifiera":
        return '"Ekonomin växer" kan bli "Landets ekonomi blomstrar som en välskött trädgård på våren."';
      case "Krydda":
        return '"Huset var stort" kan bli "Den majestätiska herrgården tornade upp sig mot himlen."';
      case "Generera flervalsfrågor":
        return 'En text om solsystemet kan generera frågor som "Vilken planet är störst? A) Jorden B) Mars C) Jupiter D) Saturnus E) Neptunus" med Jupiter som korrekt svar.';

      case "Formatera":
        return "En lång text utan struktur kan få rubriker, underrubriker, styckeindelningar och viktiga delar i fetstil.";
      case "Generera studieuppgifter":
        return 'En text om fotosyntesen kan generera uppgifter som "Förklara fotosyntesens process steg för steg" eller "Jämför fotosyntesen med cellandningen".';
      case "Fylla i luckan":
        return 'En text om vattnets kretslopp kan generera frågor som "Vatten avdunstar från hav och sjöar och bildar ______ (moln) i atmosfären."';

      default:
        return "Exempel kommer snart...";
    }
  };

  const buttonCategories = [
    {
      category: "Anpassad instruktion",
      buttons: [], // Empty since we'll handle this differently
      customContent: (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "20px",
            maxWidth: "800px",
            margin: "0 auto",
          }}
        >
          <Typography variant="h6" sx={{ mb: 3, textAlign: "center" }}>
            Skriv din egen instruktion för hur texten ska bearbetas
          </Typography>

          <TextField
            fullWidth
            variant="outlined"
            placeholder="Förklara vad du vill göra med din text"
            value={customInstruction}
            onChange={handleCustomInstructionChange}
            sx={{ mb: 3 }}
          />

          <Button
            variant="purp"
            onClick={handleCustomSubmit}
            disabled={isProcessing || !customInstruction.trim()}
            endIcon={<AutoAwesomeIcon />}
            sx={{ borderRadius: "12px", mb: 4 }}
          >
            Bearbeta text
          </Button>

          <Typography variant="subtitle1" sx={{ mb: 2, textAlign: "center" }}>
            Exempel på anpassade instruktioner:
          </Typography>

          <Grid container spacing={3} sx={{ maxWidth: "800px" }}>
            <Grid item xs={12} md={6}>
              <Paper
                elevation={0}
                sx={{
                  p: 2,
                  bgcolor:
                    theme.palette.mode === "dark" ? "#1c262f" : "#f5f5f5",
                  height: "100%",
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{ mb: 1, color: "primary.main" }}
                >
                  Språklig bearbetning
                </Typography>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  • "Gör texten mer formell och professionell"
                </Typography>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  • "Anpassa språket för en yngre målgrupp"
                </Typography>
                <Typography variant="body2">
                  • "Översätt till akademisk stil"
                </Typography>
              </Paper>
            </Grid>

            <Grid item xs={12} md={6}>
              <Paper
                elevation={0}
                sx={{
                  p: 2,
                  bgcolor:
                    theme.palette.mode === "dark" ? "#1c262f" : "#f5f5f5",
                  height: "100%",
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{ mb: 1, color: "primary.main" }}
                >
                  Innehållsbearbetning
                </Typography>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  • "Utveckla argumenten och lägg till exempel"
                </Typography>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  • "Lägg till statistik och källor"
                </Typography>
                <Typography variant="body2">
                  • "Gör texten mer övertygande"
                </Typography>
              </Paper>
            </Grid>

            <Grid item xs={12} md={6}>
              <Paper
                elevation={0}
                sx={{
                  p: 2,
                  bgcolor:
                    theme.palette.mode === "dark" ? "#1c262f" : "#f5f5f5",
                  height: "100%",
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{ mb: 1, color: "primary.main" }}
                >
                  Strukturell bearbetning
                </Typography>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  • "Dela upp i stycken med underrubriker"
                </Typography>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  • "Skapa en tydlig inledning och avslutning"
                </Typography>
                <Typography variant="body2">
                  • "Omorganisera för bättre flöde"
                </Typography>
              </Paper>
            </Grid>

            <Grid item xs={12} md={6}>
              <Paper
                elevation={0}
                sx={{
                  p: 2,
                  bgcolor:
                    theme.palette.mode === "dark" ? "#1c262f" : "#f5f5f5",
                  height: "100%",
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{ mb: 1, color: "primary.main" }}
                >
                  Kreativ bearbetning
                </Typography>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  • "Gör texten mer beskrivande och målande"
                </Typography>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  • "Lägg till dialog och karaktärsutveckling"
                </Typography>
                <Typography variant="body2">
                  • "Skapa en mer spännande berättarstil"
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      ),
    },

    {
      category: "Översätta",
      buttons: [],
      customContent: (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "20px",
            maxWidth: "800px",
            margin: "0 auto",
          }}
        >
          <Typography variant="h6" sx={{ mb: 3, textAlign: "center" }}>
            Välj språk att översätta till
          </Typography>

          <Grid container spacing={3} sx={{ maxWidth: "800px" }}>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                margin: "0 auto",
              }}
            >
              <Box sx={{ mt: 1, mb: 3, width: "400px" }}>
                <LanguageSelector
                  language={language}
                  setLanguage={setLanguage}
                />
              </Box>

              <Button
                variant="purp"
                onClick={() =>
                  handleButtonClick(
                    `Översätt texten till ${language}. Skriv inte vad du översatt från, utan skriv ENDAST själva översättningen.`,
                    "Översätt text",
                  )
                }
                disabled={isProcessing}
                endIcon={<TranslateIcon />}
                sx={{ borderRadius: "12px", mb: 4 }}
              >
                Översätt text
              </Button>
            </Grid>
          </Grid>

          <Typography variant="subtitle1" sx={{ mb: 2, textAlign: "center" }}>
            Tips för översättning:
          </Typography>

          <Grid container spacing={3} sx={{ maxWidth: "800px" }}>
            <Grid item xs={12} md={6}>
              <Paper
                elevation={0}
                sx={{
                  p: 2,
                  bgcolor:
                    theme.palette.mode === "dark" ? "#1c262f" : "#f5f5f5",
                  height: "100%",
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{ mb: 1, color: "primary.main" }}
                >
                  Allmänna tips
                </Typography>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  • Kontrollera att originaltexten är tydlig och korrekt
                </Typography>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  • Var extra uppmärksam på facktermer och idiomatiska uttryck
                </Typography>
                <Typography variant="body2">
                  • Granska översättningen för kulturella referenser
                </Typography>
              </Paper>
            </Grid>

            <Grid item xs={12} md={6}>
              <Paper
                elevation={0}
                sx={{
                  p: 2,
                  bgcolor:
                    theme.palette.mode === "dark" ? "#1c262f" : "#f5f5f5",
                  height: "100%",
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{ mb: 1, color: "primary.main" }}
                >
                  Kvalitetskontroll
                </Typography>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  • Verifiera att all information är korrekt översatt
                </Typography>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  • Kontrollera grammatik och språkspecifika regler
                </Typography>
                <Typography variant="body2">
                  • Säkerställ att textens ton bevaras i översättningen
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      ),
    },

    {
      category: "Omstrukturering",
      buttons: [
        {
          label: "Summera",
          icon: SummarizeIcon,
          instruction: "Sammanfatta texten kortfattat.",
        },
        {
          label: "Variera",
          icon: ShuffleIcon,
          instruction: "Variera texten utan att ändra innebörden.",
        },

        {
          label: "Punktlista",
          icon: FormatListBulletedIcon,
          instruction: "Omvandla texten till en tydlig punktlista.",
        },
        {
          label: "Tabell",
          icon: TableChartIcon,
          instruction: "Omvandla texten till en tydlig tabell.",
        },

        {
          label: "Komplicera",
          icon: BuildIcon,
          instruction: "Gör texten mer komplex och avancerad.",
        },

        {
          label: "Omstrukturera",
          icon: ViewStreamIcon,
          instruction: "Ändra textens struktur för bättre flöde och läsbarhet.",
        },
        {
          label: "Förlänga",
          icon: ExpandIcon,
          instruction: "Utöka texten med mer detaljer och förklaringar.",
        },
        {
          label: "Förkorta",
          icon: CompressIcon,
          instruction: "Korta ner texten utan att tappa viktigt innehåll.",
        },
        {
          label: "Parafrasera",
          icon: RepeatIcon,
          instruction: "Omformulera texten med andra ord.",
        },
        {
          label: "Skapa disposition",
          icon: ListAltIcon,
          instruction: "Generera en strukturerad outline av texten.",
        },
        {
          label: "Tematisera",
          icon: CategoryIcon,
          instruction: "Gruppera innehållet efter teman eller ämnen.",
        },
        {
          label: "Jämför och kontrastera",
          icon: CompareArrowsIcon,
          instruction:
            "Omstrukturera texten för att framhäva likheter och skillnader.",
        },
      ],
    },
    {
      category: "Förbättring",
      buttons: [
        {
          label: "Förbättra",
          icon: TrendingUpIcon,
          instruction:
            "Förbättra textens flyt och ton, gör den mer engagerande.",
        },
        {
          label: "Krydda",
          icon: LocalFloristIcon,
          instruction:
            "Lägg till starkare adjektiv och adverb för en mer färgstark text.",
        },
        {
          label: "Metaforifiera",
          icon: BrushIcon,
          instruction:
            "Infoga fler metaforer och bildspråk för en mer målande text.",
        },
        {
          label: "Formatera",
          icon: FormatAlignLeftIcon,
          instruction:
            "Ge texten bättre formatering, t.ex. rubriker, stycken och fetstil för att förbättra läsbarheten.",
        },
        {
          label: "Modernisera",
          icon: UpdateIcon,
          instruction: "Uppdatera texten till ett mer modernt språkbruk.",
        },
        {
          label: "Akademisera",
          icon: SchoolIcon,
          instruction: "Anpassa språket till en mer akademisk stil.",
        },
        {
          label: "Förtydliga",
          icon: VisibilityIcon,
          instruction:
            "Lägg till förklaringar och exempel för att göra texten mer lättförståelig.",
        },
      ],
    },
    {
      category: "Analys och kritik",
      buttons: [
        {
          label: "Analysera",
          icon: AnalyticsIcon,
          instruction: "Analysera textens innehåll, struktur och budskap.",
        },
        {
          label: "Kritisera",
          icon: RateReviewIcon,
          instruction: "Ge konstruktiv kritik på textens innehåll och upplägg.",
        },
        {
          label: "Argumentationsanalysera",
          icon: GavelIcon,
          instruction:
            "Identifiera och utvärdera argument som presenteras i texten.",
        },
        {
          label: "Kontextualisera",
          icon: LanguageIcon,
          instruction: "Sätt texten i ett bredare sammanhang.",
        },
        {
          label: "Intertextuell analys",
          icon: LinkIcon,
          instruction:
            "Föreslå kopplingar mellan texten och andra relevanta texter eller verk.",
        },
        {
          label: "Begreppsanalys",
          icon: PsychologyIcon,
          instruction: "Identifiera och förklara nyckelbegrepp i texten.",
        },
        {
          label: "Etisk analys",
          icon: BalanceIcon,
          instruction:
            "Undersök etiska aspekter och implikationer av textens innehåll.",
        },
      ],
    },
    {
      category: "Pedagogik",
      buttons: [
        {
          label: "Generera studieuppgifter",
          icon: AssignmentIcon,
          instruction:
            "Skapa studieuppgifter och diskussionsfrågor baserade på textens innehåll.",
        },
        {
          label: "Generera flervalsfrågor",
          icon: QuizIcon,
          instruction:
            "Skapa flervalsfrågor (A-E) baserade på textens innehåll.",
        },
        {
          label: "Generera fyll i luckan-frågor",
          icon: TextFieldsIcon,
          instruction:
            'Skapa "Fylla i luckan"-frågor baserade på textens innehåll.',
        },
      ],
    },
    {
      category: "Rättning och faktakoll",
      buttons: [
        {
          label: "Rätta",
          icon: SpellcheckIcon,
          instruction: "Rätta stavfel och grammatiska fel i texten.",
        },
        {
          label: "Faktakolla",
          icon: FactCheckIcon,
          instruction:
            "Identifiera påståenden i texten och försök verifiera dem mot tillförlitliga källor.",
        },
        {
          label: "Ordfrekvensberäkna",
          icon: BarChartIcon,
          instruction: "Analysera ordanvändning och frekvens i texten.",
        },
      ],
    },
    {
      category: "Kreativitet",
      buttons: [
        {
          label: "Humorisera",
          icon: SentimentVerySatisfiedIcon,
          instruction: "Lägg till humoristiska inslag i texten.",
        },
        {
          label: "Dramatisera",
          icon: TheaterComedyIcon,
          instruction: "Gör texten mer dramatisk och känsloladdad.",
        },
        {
          label: "Poetisera",
          icon: MusicNoteIcon,
          instruction: "Omvandla texten till en poetisk form med rim och rytm.",
        },
        {
          label: "Brainstorma",
          icon: EmojiObjectsIcon,
          instruction:
            "Generera idéer och tankar relaterade till textens ämne.",
        },
        {
          label: "Perspektivskifte",
          icon: TransformIcon,
          instruction: "Omskriv texten från ett annat perspektiv.",
        },
      ],
    },
  ];

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const arrayBuffer = e.target.result;
        try {
          const result = await mammoth.extractRawText({ arrayBuffer });
          if (subjectInputRef.current) {
            subjectInputRef.current.value = result.value.slice(
              0,
              maxInputLength,
            );
          }
        } catch (error) {
          console.error("Error processing Word file:", error);
          // You might want to show an error message to the user here
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  return (
    <Box sx={styles.container}>
      <Box
        sx={{ ...styles.innerContainer, padding: { xs: "0px", md: undefined } }}
      >
        <Typography
          variant="h1"
          sx={{ ...styles.header, textAlign: "center", mb: 4 }}
        >
          Textlabbet
        </Typography>

        <InfoBox
          icon={CreateIcon}
          title="Textlabbet"
          instructions={[
            "Ange din text manuellt eller ladda upp fil",
            "Välj vad du vill göra med din text genom flera bearbetningsalternativ",
            "Kopiera eller exportera texten som PDF- eller Word-dokument",
          ]}
          theme={theme}
          styles={styles}
        />

        <ThemeSelectorFlow
          value={lessonTheme}
          onChange={(e) => setLessonTheme(e.target.value)}
          customTheme={customTheme}
          onCustomThemeChange={(e) => setCustomTheme(e.target.value)}
          onPredefinedThemeChange={(e) => setPredefinedTheme(e.target.value)}
          onYoutubeTranscriptChange={(transcriptContent) => {
            Logger.log(
              "onYoutubeTranscriptChange körs med: ",
              transcriptContent,
            );
            transcriptRef.current = transcriptContent;
          }}
          onUrlContentChange={(content) => {
            Logger.log(
              "Parent: URL content received",
              content.substring(0, 300),
            );
            urlContentRef.current = content;
          }}
          onFileUpload={(content) => {
            Logger.log("onFileUpload körs med: ", content);
            uploadedFileRef.current = content;
          }}
          onWikipediaContentChange={(content) => {
            Logger.log(
              "Parent: Wikipedia content received",
              content.substring(0, 100),
            );
            wikipediaContentRef.current = content;
          }}
          onRandomizedContentChange={(content) => {
            Logger.log("randomizedContentRef", content.substring(0, 100));
            randomizedContentRef.current = content;
          }}
          onCoursePlanContentChange={(content) => {
            Logger.log("onCoursePlanChange", content.substring(0, 100));
            coursePlanContentRef.current = content;
          }}
          lessonType={"TextManager.js"}
          user={user}
          theme={theme}
          styles={styles}
          forTextlabbet={true}
        />

        <Box sx={{ ...styles.headlineLessonBox, mt: 0 }}>
          <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
            {actionText}
          </Typography>
        </Box>
        <Box
          sx={{
            ...styles.freshBox,
            padding: "0px",
            // When !showAllButtons, make the box more compact
            height: !showAllButtons ? "300px" : "auto",
            transition: "height 0.3s ease", // Smooth transition for height change
            overflow: "hidden", // Add this
          }}
        >
          <Box
            sx={{
              width: "100%",
              position: "relative",
              height: !showAllButtons ? "300px" : "auto",
            }}
          >
            {/* Add overlay when !showAllButtons */}
            {!showAllButtons && (
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  bgcolor: "rgba(0, 0, 0, 0.2)",
                  backdropFilter: "blur(3px)",
                  WebkitBackdropFilter: "blur(3px)", // For Safari support

                  zIndex: 1,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 4,
                  borderRadius: 2,
                }}
              >
                {visibleButtons.map((buttonLabel) => {
                  const button = buttons.find((b) => b.label === buttonLabel);
                  if (button) {
                    const { label, icon: Icon, instruction } = button;
                    return (
                      <Button
                        key={label}
                        variant={
                          theme.palette.mode === "light" ? "purp" : "contained"
                        }
                        startIcon={
                          isProcessing && activeButton === label ? (
                            <StopIcon />
                          ) : (
                            <Icon />
                          )
                        }
                        onClick={() => handleButtonClick(instruction, label)}
                        disabled={isProcessing && activeButton !== label}
                        sx={{
                          transform: "scale(1.1)",
                          mb: 2,
                        }}
                      >
                        {label}
                      </Button>
                    );
                  }
                  return null;
                })}

                <Button
                  variant="success"
                  startIcon={<AllOutIcon />}
                  onClick={() => {
                    setShowAllButtons(true);
                    setActionText("2. Välj vad du vill göra med texten");
                  }}
                >
                  Visa fler alternativ
                </Button>
              </Box>
            )}

            <Box sx={{ opacity: !showAllButtons ? 0.3 : 1 }}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  background:
                    theme.palette.mode === "dark" ? "#22303c" : "#f9f9fa",
                  borderTopLeftRadius: "9px",
                  borderTopRightRadius: "9px",
                }}
              >
                <Tabs
                  value={currentTab}
                  onChange={handleTabChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  allowScrollButtonsMobile
                >
                  {buttonCategories.map((category, index) => (
                    <Tab
                      key={category.category}
                      icon={
                        category.category === "Omstrukturering" ? (
                          <ViewStreamIcon />
                        ) : category.category === "Förbättring" ? (
                          <AutoFixHighIcon />
                        ) : category.category === "Analys och kritik" ? (
                          <AnalyticsIcon />
                        ) : category.category === "Pedagogik" ? (
                          <SchoolIcon />
                        ) : category.category === "Rättning och faktakoll" ? (
                          <SpellcheckIcon />
                        ) : category.category === "Kreativitet" ? (
                          <EmojiObjectsIcon />
                        ) : category.category === "Anpassad instruktion" ? (
                          <CreateIcon />
                        ) : category.category === "Översätta" ? (
                          <TranslateIcon />
                        ) : null
                      }
                      iconPosition="start"
                      label={category.category}
                    />
                  ))}
                </Tabs>
              </Box>

              {buttonCategories.map((category, index) => (
                <Box
                  key={category.category}
                  role="tabpanel"
                  hidden={currentTab !== index}
                  sx={{ mt: 3, padding: "30px", paddingBottom: "60px" }}
                >
                  {currentTab === index && (
                    <>
                      {category.customContent ? (
                        category.customContent
                      ) : (
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                          {category.buttons.map(
                            ({ label, icon: Icon, instruction }) => (
                              <Button
                                key={label}
                                variant={
                                  theme.palette.mode === "light"
                                    ? "purp"
                                    : "outlined"
                                }
                                startIcon={
                                  isProcessing && activeButton === label ? (
                                    <StopIcon />
                                  ) : (
                                    <Icon />
                                  )
                                }
                                onClick={() =>
                                  handleButtonClick(instruction, label)
                                }
                                disabled={
                                  isProcessing && activeButton !== label
                                }
                              >
                                {label}
                              </Button>
                            ),
                          )}
                        </Box>
                      )}
                    </>
                  )}
                </Box>
              ))}
            </Box>
          </Box>
        </Box>

        <Box
          ref={resultsSectionRef}
          sx={{ ...styles.headlineLessonBox, mt: 10 }}
        >
          <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
            3. Resultat
          </Typography>
        </Box>
        <Box sx={{ ...styles.freshBox, padding: "50px", mb: 5 }}>
          <Box
            ref={outputBoxRef}
            sx={{
              borderRadius: "15px",
              backgroundColor:
                theme.palette.mode === "dark" ? "#1c262f" : "#fcfcfc",
              border:
                theme.palette.mode === "dark"
                  ? "1px solid #3b4651"
                  : "1px solid #ededed",
              p: 2,
              minHeight: "300px",
              maxHeight: "300px",
              overflowY: "auto",
              "& p": { my: 1 },
              scrollBehavior: "smooth",
              position: "relative",

              "& h3,h2,h1,h4,h5,h6": {
                marginBottom: "0px",
                marginTop: "30px",
                paddingBottom: "0px",
              },
              transition: "background-color 0.3s ease-in-out",

              "& hr": {
                height: "1px",
                border: "none",
                backgroundColor:
                  theme.palette.mode === "dark" ? "#34414c" : "#e0e0e0",
                marginTop: "50px",
                marginBottom: "50px",
              },
              "& pre": {
                backgroundColor:
                  theme.palette.mode === "dark" ? "#2d3748" : "#f7fafc",
                padding: "1rem",
                borderRadius: "4px",
                overflowX: "auto",
              },
              "& code": {
                fontFamily: "monospace",
                fontSize: "0.9em",
              },
              "& table": {
                borderCollapse: "collapse",
                width: "100%",
                marginBottom: "1rem",
              },
              "& th, & td": {
                border: `1px solid ${theme.palette.mode === "dark" ? "#34414c" : "#e0e0e0"}`,
                padding: "8px",
                textAlign: "left",
              },
              "& th": {
                backgroundColor:
                  theme.palette.mode === "dark" ? "#1e2a35" : "#f5f5f5",
                fontWeight: "bold",
              },
            }}
          >
            <Tooltip title="Kopiera">
              <IconButton
                onClick={handleCopy}
                sx={{ position: "absolute", top: 8, right: 8, zIndex: 1 }}
              >
                {isCopied ? <CheckIcon /> : <ContentCopyIcon />}
              </IconButton>
            </Tooltip>

            {isProcessing && !outputText && (
              <Box sx={{ display: "flex", alignItems: "left", height: "100%" }}>
                <CircularProgress size={30} />
              </Box>
            )}
            {!isProcessing && !outputText && (
              <Typography sx={{ fontStyle: "italic", color: "text.secondary" }}>
                Din behandlade text hamnar här...
              </Typography>
            )}

            <div dangerouslySetInnerHTML={{ __html: outputText }} />
          </Box>

          {/* New export button */}
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              variant="print"
              startIcon={<DownloadForOfflineIcon />}
              onClick={handleExportClick}
              disabled={!outputText}
            >
              Exportera
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleExportClose}
              PaperProps={{
                sx: {
                  ...styles.roundMenu,
                  borderRadius: "8px",
                },
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <MenuItem onClick={exportToPDF} sx={styles.menuItemStyle}>
                <PictureAsPdfIcon
                  fontSize="small"
                  style={{ marginRight: 8, color: "#7B68EE" }}
                />
                Exportera som PDF
              </MenuItem>
              <MenuItem onClick={exportToWord} sx={styles.menuItemStyle}>
                <WordIcon2019
                  fontSize="small"
                  style={{ marginRight: 8, color: "#7B68EE", fontSize: "18px" }}
                />
                Exportera som Word-fil
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TextManager;
