// other test.js
import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  collection,
  addDoc,
  deleteDoc,
  getDocs,
  query,
  where,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db } from "./firebase";
import {
  scrollToBottom,
  sendMessage,
  fetchConversations,
  deleteAllMessages,
  createNewChat,
  fetchChats,
  convoType,
  updateChatName,
  generateIdeas,
  handleNewChat,
  handleChatNameEdit,
  finalizeChatNameEdit,
  saveChatName,
  startEditing,
} from "./messageFunctions";
import { debounce } from "lodash";

import Logger from "./Logger";
import { v4 as uuidv4 } from "uuid";
import { callApiWithStreaming } from "./api"; // Import the new function

const useGeneralChat = (
  user,
  convoType,
  initialMessages = [],
  teacher,
  language,
) => {
  const [input, setInput] = useState("");
  const [messages, setMessages] = useState(initialMessages);
  const [loading, setLoading] = useState(true);
  const [loadingMessage, setLoadingMessage] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [clientAnchorEl, setClientAnchorEl] = useState(null);
  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [chats, setChats] = useState([]);
  const [currentChatId, setCurrentChatId] = useState(null);
  const [editingChat, setEditingChat] = useState({ id: null, name: "" });
  const [loadingLatestChat, setLoadingLatestChat] = useState(false);
  const [loadingChat, setLoadingChat] = useState(false);
  const [creatingNewChat, setCreatingNewChat] = useState(false);
  const [savingChatName, setSavingChatName] = useState(false);
  const initialChatCreated = useRef(false);
  const [loadingOldChats, setLoadingOldChats] = useState(false);
  const [base64Image, setBase64Image] = useState("");
  const [currentChatName, setCurrentChatName] = useState("");

  /////
  ///////////// KOMMENTERA TILL HIT RESET ////////////////// ///
  ////

  const [prompts, setPrompts] = useState([]);
  const [promptDialogOpen, setPromptDialogOpen] = useState(false);

  // Add these functions to the hook
  const fetchPrompts = useCallback(async () => {
    if (user && user.uid) {
      const q = query(
        collection(db, "prompts"),
        where("userId", "==", user.uid),
        //where('conversationType', '==', convoType)
      );
      Logger.log("convoType: ", convoType);
      const querySnapshot = await getDocs(q);
      const fetchedPrompts = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        text: doc.data().text,
        description: doc.data().description,
      }));
      setPrompts(fetchedPrompts);
    }
  }, [user, convoType]);

  const addPrompt = useCallback(
    async (promptText, promptDescription) => {
      if (user && user.uid) {
        const docRef = await addDoc(collection(db, "prompts"), {
          userId: user.uid,
          conversationType: convoType,
          text: promptText,
          description: promptDescription,
        });
        setPrompts((prevPrompts) => [
          ...prevPrompts,
          { id: docRef.id, text: promptText, description: promptDescription },
        ]);
      }
    },
    [user, convoType],
  );
  // useGeneralChat.js

  const deletePrompt = useCallback(
    async (promptId) => {
      if (user && user.uid) {
        await deleteDoc(doc(db, "prompts", promptId));
        setPrompts((prevPrompts) =>
          prevPrompts.filter((prompt) => prompt.id !== promptId),
        );
      }
    },
    [user],
  );

  // Add this useEffect to fetch prompts when the component mounts
  useEffect(() => {
    fetchPrompts();
  }, [fetchPrompts]);

  useEffect(() => {
    Logger.log("language in useGeneralChat:", language);
  }, [language]);

  const streamingContentRef = useRef("");
  const [tempStreamingId] = useState(() => "streaming-" + Date.now());
  const [completedStreamingId, setCompletedStreamingId] = useState(null);

  const [isLive, setIsLive] = useState(false);
  let streamingComplete = false;

  const [streamingMessage, setStreamingMessage] = useState(null);
  const streamingMessageRef = useRef(null);

  const [isStreaming, setIsStreaming] = useState(false);
  const abortControllerRef = useRef(null);

  const handleStopStreaming = useCallback(() => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      // Skapa en ny AbortController för framtida användning
      abortControllerRef.current = new AbortController();
    }

    // Uppdatera streaming-status
    setIsStreaming(false);

    // Avsluta det nuvarande meddelandet som det är
    setMessages((prevMessages) => {
      const lastMessage = prevMessages[prevMessages.length - 1];
      if (lastMessage && lastMessage.isStreaming) {
        const abortedMessage = { ...lastMessage, isStreaming: false };

        // Spara det avbrutna meddelandet till databasen
        // addDoc(collection(db, "conversations"), {
        //     userId: user.uid,
        //     message: abortedMessage.content,
        //     role: abortedMessage.role,
        //     conversationType: convoType,
        //     chatId: currentChatId,
        //     createdAt: new Date(),
        //      messageId: uuidv4()
        // }).then(docRef => {
        //     abortedMessage.id = docRef.id;
        // }).catch(error => {
        //     console.error('Error saving aborted message:', error);
        // });

        const abortedMessageId = uuidv4();
        addDoc(collection(db, "conversations"), {
          userId: user.uid,
          message: abortedMessage.content,
          role: abortedMessage.role,
          conversationType: convoType,
          chatId: currentChatId,
          createdAt: new Date(),
          messageId: abortedMessageId,
        })
          .then((docRef) => {
            abortedMessage.id = docRef.id;
            Logger.log("Aborted message saved:", {
              userId: user.uid,
              message: abortedMessage.content,
              role: abortedMessage.role,
              conversationType: convoType,
              chatId: currentChatId,
              createdAt: new Date(),
              id: docRef.id,
              messageId: abortedMessageId,
            });
          })
          .catch((error) => {
            console.error("Error saving aborted message:", error);
          });

        return [
          ...prevMessages.slice(0, -1),
          { ...lastMessage, isStreaming: false },
        ];
      }

      return prevMessages;
    });

    // Återställ UI-element om det behövs
    setLoadingMessage(false);

    Logger.log("Streaming avbruten av användaren");
  }, [setMessages, setLoadingMessage, user.uid, convoType, currentChatId]);

  const handleStreamingMessage = useCallback((chunk) => {
    setIsLive(true);
    setStreamingMessage((prevMessage) => {
      const newMessage = prevMessage
        ? { ...prevMessage, content: prevMessage.content + chunk }
        : {
            role: "assistant",
            content: chunk,
            id: "streaming-" + Date.now(),
            isStreaming: true,
          };

      // Update the messages array
      setMessages((prevMessages) => {
        const streamingIndex = prevMessages.findIndex(
          (msg) => msg.id === newMessage.id,
        );
        if (streamingIndex === -1) {
          return [...prevMessages, newMessage];
        } else {
          return prevMessages.map((msg, index) =>
            index === streamingIndex ? newMessage : msg,
          );
        }
      });

      return newMessage;
    });
  }, []);

  const handleStreamComplete = useCallback(
    (messageId) => {
      setIsLive(false);
      setMessages((prevMessages) => {
        if (!streamingMessage) {
          console.warn("handleStreamComplete: streamingMessage is null");
          return prevMessages;
        }
        // Find the index of the streaming message
        const streamingIndex = prevMessages.findIndex(
          (msg) => msg.id === streamingMessage.id,
        );
        if (streamingIndex === -1) {
          // If not found, add it to the end
          return [
            ...prevMessages,
            { ...streamingMessage, id: messageId, isStreaming: false },
          ];
        } else {
          // If found, update it in place
          return prevMessages.map((msg, index) =>
            index === streamingIndex
              ? { ...streamingMessage, id: messageId, isStreaming: false }
              : msg,
          );
        }
      });
      setStreamingMessage(null);
    },
    [streamingMessage],
  );

  const sendMessageWithStreaming = async (
    input,
    user,
    messages,
    setMessages,
    setLoading,
    setInput,
    conversationType,
    chatId,
    numRecentMessages = 5,
    sender = "user",
    img = "",
  ) => {
    Logger.log("sendMessageWithStreaming: called with arguments", {
      input,
      user,
      messages,
      setMessages,
      setLoading,
      setInput,
      conversationType,
      chatId,
      numRecentMessages,
      sender,
      img,
    });

    if (input.trim() === "" || !chatId) {
      console.error("sendMessageWithStreaming: Invalid input or chatId");
      return;
    }

    setLoading(true);

    const stableBotMessageId = "bot-" + Date.now();
    console.log(`Created stableBotMessageId: ${stableBotMessageId}`);

    // Add optimistic bot message
    const optimisticBotMessage = {
      role: "assistant",
      content: "",
      id: stableBotMessageId,
      conversationType,
      chatId,
      createdAt: new Date(),
      isStreaming: true,
    };
    setMessages((prevMessages) => {
      console.log(`Adding optimistic bot message: ${stableBotMessageId}`);
      return [...prevMessages, optimisticBotMessage];
    });

    try {
      setIsStreaming(true);
      abortControllerRef.current = new AbortController();

      const messageId = await addDoc(collection(db, "conversations"), {
        userId: user.uid,
        message: input,
        role: "user",
        conversationType: conversationType,
        chatId: chatId,
        createdAt: new Date(),
        messageId: uuidv4(),
      }).then((docRef) => docRef.id);

      Logger.log("sendMessageWithStreaming: New message ID", messageId);

      let systemMessageContent = "Du är en chatt-bot...";
      if (conversationType === "chat") {
        systemMessageContent = `${
          user.accountType === "teacher" || user.accountType === "substitute"
            ? `Du är en pedagogisk assistent som hjälper lärare med planering, bedömning, pedagogiska frågor och annat administrativt arbete. Du har djup förståelse för det svenska skolsystemet, läroplaner och pedagogiska metoder. Hjälp till med att utveckla lektionsplaneringar, ge förslag på bedömningsmatriser, och diskutera pedagogiska strategier.`
            : `Du är en chatbot som hjälper folk med deras läxor, studier eller något annat de behöver hjälp med.`
        }
      Du jobbar för Studera.ai.
      Håll samtalet levande, var personlig i ditt tilltal.  
            Frågar någon efter en lektionsplan, så ge dem det, men säg även att de kan använda https://studera.ai/app/lektioner/lektionsplan för att skapa vackra lektionsplaner på nolltid.
VIKTIGT: Svara alltid på språket ${language}.


      ${user.latexActivated ? "Om du ska skriva LaTeX-syntax, säkerställ att den är omsluten av dubbla dollartecken ($$) för korrekt rendering." : ""}
      `;
      } else if (conversationType === "history") {
        systemMessageContent = "Du är en historielärare...";
      } else if (conversationType === "spanish") {
        systemMessageContent =
          "Eres un profesor de español que puede hablar español. Sé alentador y trata de mantener la conversación en todo momento. Todas tus respuestas deben ser en español, incluso si el usuario escribe en sueco.";
      } else if (/^[a-zA-Z0-9]{20}$/.test(conversationType)) {
        systemMessageContent = `
        Du är ${teacher.characterName}. ${teacher.tagline}
        Beskrivning: ${teacher.description}
        Din röst och talsätt: ${teacher.voice}
        
        Din personlighet: ${teacher.personality}
        
        Din kommunikationsstil: ${teacher.communicationStyle}
        Din bakgrund: ${teacher.background}

        ${user.latexActivated ? "Om du ska skriva LaTeX-syntax, säkerställ att den är omsluten av dubbla dollartecken ($$) för korrekt rendering." : ""}

        
        ${teacher.dyslexiaAdapted ? "Du är anpassad för att undervisa elever med dyslexi. Var extra tydlig och använd lämpliga strategier: använd enkla och tydliga meningar, håll ett långsamt samtalstempo, bekräfta förståelse, upprepa och sammanfatta viktig information, tillhandahåll skriftligt material med enkel text, och ge tid för reflektion" : ""}
        ${teacher.adhdAdapted ? "Du är anpassad för att undervisa elever med ADHD. För att stödja dessa elever: ge tydliga och korta instruktioner, bryt ner uppgifter i mindre delar, använd visuella hjälpmedel, var flexibel med pauser, säkerställ en strukturerad och förutsägbar miljö, ge ofta positiv feedback och visa tålamod och förståelse." : ""}
        Använd all denna information för att forma din personlighet och ditt sätt att interagera. Svara alltid i enlighet med dessa egenskaper.
        OBS: Hälsningsfrasen ska endast användas en gång per chattkonversation. Därefter ska du inte repetera hälsningsfrasen utan gå direkt till svaren eller instruktionerna baserat på elevens frågor och behov.
      `;
      }

      // Moonshine tagit bort så man kan skapa rena karaktärer:
      // Din undervisningsstil: ${teacher.teachingStyle}
      // Din pedagogiska filosofi: ${teacher.pedagogicalPhilosophy}
      // Ditt förhållningssätt till misstag: ${teacher.approachToMistakes}

      Logger.log("systemMessageContent: ", systemMessageContent);
      const recentMessages = messages
        .slice(Math.max(messages.length - numRecentMessages, 0))
        .map((msg) => ({
          role: msg.role === "bot" ? "assistant" : msg.role,
          content: msg.content,
        }));

      let context = [
        { role: "system", content: systemMessageContent },
        ...recentMessages,
        { role: "user", content: input },
      ];

      let fullResponse = "";

      await callApiWithStreaming(
        context,
        (chunk) => {
          fullResponse += chunk;
          setMessages((prevMessages) => {
            console.log(
              `Updating message ${stableBotMessageId} with new content`,
            );
            const updatedMessages = prevMessages.map((msg) =>
              msg.id === stableBotMessageId
                ? { ...msg, content: fullResponse, isStreaming: true }
                : msg,
            );
            return updatedMessages;
          });
        },
        user.uid,
        abortControllerRef.current.signal,
      );

      const botMessageId = await addDoc(collection(db, "conversations"), {
        userId: user.uid,
        message: fullResponse,
        role: "assistant",
        conversationType: conversationType,
        chatId: chatId,
        createdAt: new Date(),
        messageId: uuidv4(),
      }).then((docRef) => docRef.id);

      // Update the streaming message with the final content and Firestore ID
      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.id === stableBotMessageId
            ? {
                ...msg,
                firestoreId: botMessageId,
                content: fullResponse,
                isStreaming: false,
              }
            : msg,
        ),
      );

      if (messages.length === 0) {
        const chatName = input.slice(0, 20) + (input.length > 20 ? "..." : "");
        await updateDoc(doc(db, "chats", chatId), {
          name: chatName,
          firstMessageContent: input,
        });
        setCurrentChatName(chatName);
      }

      setLoading(false);
      setInput("");
    } catch (error) {
      if (error.name === "AbortError") {
        Logger.log("Request was aborted");
        // Do not remove the optimistic bot message
      } else {
        console.error("Error in API call:", error);
        // Remove the optimistic bot message in case of other errors
        setMessages((prevMessages) =>
          prevMessages.filter((msg) => msg.id !== stableBotMessageId),
        );
      }
    } finally {
      setLoading(false);
      setIsStreaming(false);
      abortControllerRef.current = null;
    }
  };

  const optimisticUpdate = (content, shouldSubmit) => {
    if (user && user.uid && content.trim() !== "" && currentChatId) {
      const newMessage = {
        role: "user",
        content: content,
        id: "temp-" + Date.now(),
        conversationType: convoType,
        chatId: currentChatId,
        createdAt: new Date(),
      };

      setMessages((prevMessages) => [...prevMessages, newMessage]);
      setInput("");
      setLoadingMessage(true);

      return newMessage;
    }
    return null;
  };
  const handleSendMessage = (inputValue, existingOptimisticMessage = null) => {
    if (inputValue.trim() === "" || !currentChatId) return;

    let optimisticMessage = existingOptimisticMessage;
    if (!optimisticMessage) {
      optimisticMessage = optimisticUpdate(inputValue, true);
    }

    if (optimisticMessage) {
      setInput("");
      setLoadingMessage(true);
      setStreamingMessage(null);

      sendMessageWithStreaming(
        inputValue,
        user,
        messages,
        setMessages,
        setLoadingMessage,
        setInput,
        convoType,
        currentChatId,
      ).catch((error) => {
        console.error("Error in sendMessageWithStreaming:", error);
        setMessages((prevMessages) =>
          prevMessages.filter((msg) => msg.id !== optimisticMessage.id),
        );
      });
    }
  };

  let processedLength = 0;

  //////////////////// TILL

  ///// OLD CODE:

  const handleImageUpload = (base64Image) => {
    setBase64Image(base64Image);
    Logger.log("base64Image: ", base64Image);
  };

  useEffect(() => {
    Logger.log("Current chat ID updated:", currentChatId);
  }, [currentChatId]);

  useEffect(() => {
    Logger.log("fetching from chatid");

    if (user && !initialChatCreated.current) {
      setLoadingLatestChat(true);
      fetchChats(user, convoType).then((fetchedChats) => {
        const sortedChats = fetchedChats.sort(
          (a, b) => b.createdAt.toDate() - a.createdAt.toDate(),
        );
        setChats(sortedChats);
        if (sortedChats.length > 0) {
          //setCurrentChatId(sortedChats[0].id);
          //Logger.log("fechinf from chatid: ", currentChatId);

          const latestChat = sortedChats[0];
          setCurrentChatId(latestChat.id);
          setCurrentChatName(latestChat.name); // Uppdatera currentChatName här
          Logger.log("fetching from chatid: ", latestChat.id);

          fetchConversations(
            user,
            setMessages,
            convoType,
            latestChat.id,
          ).finally(() => setLoadingLatestChat(false));
        } else {
          createNewChat(user, convoType).then((newChatId) => {
            setCurrentChatId(newChatId);
            setCurrentChatName("Ny chat");
            setChats([{ id: newChatId, name: "Ny chat" }]);
            setLoadingLatestChat(false);
          });
        }
        initialChatCreated.current = true;
      });
      setLoading(false);
    }
  }, [user, convoType]);

  // useEffect(() => {
  //     if (user) {
  //         fetchConversations(user, setMessages, convoType, currentChatId)
  //             .finally(() => setLoading(false));
  //     }
  // }, [user]);

  React.useEffect(() => {
    const scrollToBottom = () => {
      const scrollTimeout = setTimeout(() => {
        if (messagesEndRef.current) {
          messagesEndRef.current.scrollIntoView({
            behavior: "smooth",
            block: "end",
          });
        }
      }, 300); // Increased delay

      return () => clearTimeout(scrollTimeout);
    };

    scrollToBottom();

    // Use ResizeObserver to detect content changes
    const resizeObserver = new ResizeObserver(scrollToBottom);
    if (messagesEndRef.current) {
      resizeObserver.observe(messagesEndRef.current);
    }

    return () => {
      if (messagesEndRef.current) {
        resizeObserver.unobserve(messagesEndRef.current);
      }
    };
  }, [messages, loading, currentChatId, loadingLatestChat, loadingChat]);

  useEffect(() => {
    if (drawerOpen) {
      setLoadingOldChats(true);
      refreshChatList();
    }
  }, [drawerOpen]);

  const refreshChatList = async () => {
    if (user) {
      try {
        const fetchedChats = await fetchChats(user, convoType);
        const sortedChats = fetchedChats.sort(
          (a, b) => b.createdAt.toDate() - a.createdAt.toDate(),
        );
        setChats(sortedChats);
      } catch (error) {
        console.error("Error refreshing chat list:", error);
      } finally {
        setLoadingOldChats(false);
      }
    }
  };

  const debouncedCreateNewChat = debounce(async () => {
    setCreatingNewChat(true);
    const newChatId = await handleNewChat(
      user,
      convoType,
      setCurrentChatId,
      setMessages,
      setChats,
    );
    if (newChatId) {
      setChats((prevChats) => {
        if (!prevChats.some((chat) => chat.id === newChatId)) {
          return [
            { id: newChatId, name: "Ny chat", createdAt: new Date() },
            ...prevChats,
          ];
        }
        return prevChats;
      });
    }
    setCreatingNewChat(false);
    setCurrentChatName("Ny chat");
  }, 300);

  const handleCreateNewChat = () => {
    debouncedCreateNewChat();
  };

  const handleChatSelect = (chatId) => {
    setLoadingChat(true);
    setCurrentChatId(chatId);
    const selectedChat = chats.find((chat) => chat.id === chatId);
    setCurrentChatName(selectedChat ? selectedChat.name : "Ny chat");
    fetchConversations(user, setMessages, convoType, chatId).finally(() =>
      setLoadingChat(false),
    );
    setDrawerOpen(false);
  };

  const handleChatNameChange = useCallback((e) => {
    setEditingChat((prev) => ({ ...prev, name: e.target.value }));
  }, []);

  const handleSaveChatName = async () => {
    setSavingChatName(true);
    try {
      await saveChatName(editingChat, updateChatName, setChats, setEditingChat);
      setCurrentChatName(editingChat.name);
    } catch (error) {
      console.error("Error saving chat name:", error);
    } finally {
      setSavingChatName(false);
    }
  };

  const handleStartEditing = (chatId, currentName) =>
    startEditing(chatId, currentName, setEditingChat);

  // const handleSendMessage = useCallback((inputValue) => {
  //     const newMessage = optimisticUpdate(inputValue, true);
  //     if (newMessage) {
  //         sendMessage(inputValue, user, messages, setMessages, setLoadingMessage, setInput, convoType, currentChatId)
  //             .catch(error => {
  //                 console.error('Error in sendMessage:', error);
  //                 setMessages(prevMessages => prevMessages.filter(msg => msg.id !== newMessage.id));
  //             })
  //             .finally(() => {
  //                 setLoadingMessage(false);
  //             });
  //     }
  // }, [user, messages, convoType, currentChatId]);

  const handleInputChange = useCallback((newInput) => {
    setInput(newInput);
  }, []);

  const handleGenerateIdeas = async (content, shouldSubmit = false) => {
    let newMessage = null;
    if (shouldSubmit) {
      newMessage = optimisticUpdate(content, shouldSubmit);
    }

    try {
      let ideas = null;

      if (shouldSubmit) {
        Logger.log("handleSendMessage körs i handleGenerateIdeas");
        handleSendMessage(content, newMessage);
      } else {
        ideas = await generateIdeas(content, convoType, shouldSubmit);
      }

      return ideas;
    } catch (error) {
      console.error("Error in handleGenerateIdeas:", error);
      if (newMessage) {
        setMessages((prevMessages) =>
          prevMessages.filter((msg) => msg.id !== newMessage.id),
        );
      }
    } finally {
      setLoadingMessage(false);
    }
  };

  const handleDeleteAllMsgs = async () => {
    if (user && user.uid && currentChatId) {
      await deleteAllMessages(user, setMessages, convoType, currentChatId);
    } else {
      console.error(
        "User ID or chat ID is missing when trying to delete messages",
      );
    }
  };

  return {
    input,
    messages,
    loading,
    loadingMessage,
    anchorEl,
    clientAnchorEl,
    selectedPrompt,
    messagesEndRef,
    inputRef,
    drawerOpen,
    chats,
    currentChatId,
    editingChat,
    loadingLatestChat,
    loadingChat,
    creatingNewChat,
    savingChatName,
    loadingOldChats,
    base64Image,
    handleImageUpload,
    setInput,
    setAnchorEl,
    setClientAnchorEl,
    setSelectedPrompt,
    setDrawerOpen,
    handleCreateNewChat,
    handleChatSelect,
    handleChatNameChange,
    handleSaveChatName,
    handleStartEditing,
    handleSendMessage,
    handleInputChange,
    handleDeleteAllMsgs,
    setLoadingMessage,
    setMessages,
    sendMessage,
    handleGenerateIdeas,
    optimisticUpdate,
    streamingMessage,
    handleSendMessage,

    handleStreamComplete,
    completedStreamingId,
    isLive,
    isStreaming,
    handleStopStreaming,
    currentChatName,
    prompts,
    promptDialogOpen,
    setPromptDialogOpen,
    addPrompt,
    deletePrompt,
  };
};

export default useGeneralChat;
