import {
  Science as ScienceIcon,
  Castle as CastleIcon,
  Psychology as PsychologyIcon,
  Pets as PetsIcon,
  Public as PublicIcon,
  Brush as BrushIcon,
  Calculate as CalculateIcon,
  Language as LanguageIcon,
  EmojiObjects as EmojiObjectsIcon,
  LocalFlorist as LocalFloristIcon,
  WbSunny as WbSunnyIcon,
  Workspaces as WorkspacesIcon,
  Agriculture as AgricultureIcon,
  MonitorHeart as MonitorHeartIcon,
  Park as ParkIcon,
  Biotech as BiotechIcon,
  MenuBook as MenuBookIcon,
  EmojiEvents as EmojiEventsIcon,
  Museum as MuseumIcon,
  Nature as NatureIcon,
  AccountBalance as AccountBalanceIcon,
  MilitaryTech as MilitaryTechIcon,
  Science as DNAIcon,
  Psychology as BrainIcon,
  Public as GlobeIcon,
  EmojiObjects as LightBulbIcon,
} from "@mui/icons-material";

// Default themes that show when no subject is selected
export const defaultThemes = [
  {
    type: "subheader",
    label: "Dagböcker som lämpar sig extra bra för denna lektionstyp:",
  },

  {
    value: "berlinmuren-fall",
    label: "Berlinmurens fall 1989",
    content:
      "Gör en dagbok från en östtysk medborgare under Berlinmurens fall 1989",
    Icon: CastleIcon,
  },

  {
    value: "olof-palme",
    label: "Olof Palmes sista dag",
    content: "Gör en dagbok från Olof Palmes sista dag i livet",
    Icon: AccountBalanceIcon,
  },

  {
    value: "anna-lindh",
    label: "Anna Lindhs sista dagar",
    content: "Gör en dagbok från Anna Lindhs sista dagar som utrikesminister",
    Icon: AccountBalanceIcon,
  },
  {
    value: "raoul-wallenberg",
    label: "Raoul Wallenberg i Budapest",
    content:
      "Gör en dagbok från Raoul Wallenbergs räddningsaktioner i Budapest",
    Icon: EmojiEventsIcon,
  },

  {
    value: "carl-von-linne",
    label: "Linnés upptäcktsresor",
    content: "Gör en dagbok från Carl von Linnés forskningsresor",
    Icon: NatureIcon,
  },
  {
    value: "alfred-nobel",
    label: "Nobels testamente",
    content: "Gör en dagbok från när Alfred Nobel skrev sitt testamente",
    Icon: EmojiEventsIcon,
  },
  {
    value: "astrid-lindgren",
    label: "Astrids krigsdagböcker",
    content: "Gör en dagbok baserad på Astrid Lindgrens krigsdagböcker",
    Icon: MenuBookIcon,
  },
  {
    value: "dag-hammarskjold",
    label: "Hammarskjölds FN-tid",
    content:
      "Gör en dagbok från Dag Hammarskjölds tid som FN:s generalsekreterare",
    Icon: PublicIcon,
  },
  {
    value: "greta-garbo",
    label: "Garbo i Hollywood",
    content: "Gör en dagbok från Greta Garbos tid i Hollywood",
    Icon: MuseumIcon,
  },
];

export const subjectThemes = {
  biologi: [
    { type: "subheader", label: "Dagboksteman inom biologi:" },
    {
      value: "dna-upptackt",
      label: "Upptäckten av DNA-molekylen",
      content:
        "Gör en dagbok från James Watson eller Francis Crick under upptäckten av DNA-strukturen 1953",
      Icon: DNAIcon,
    },
    {
      value: "darwin-galapagos",
      label: "Darwin på Galápagosöarna",
      content: "Gör en dagbok från Charles Darwins resa till Galápagosöarna",
      Icon: NatureIcon,
    },
    {
      value: "fleming-penicillin",
      label: "Flemings penicillinupptäckt",
      content:
        "Gör en dagbok från Alexander Fleming när han upptäckte penicillinet",
      Icon: BiotechIcon,
    },
    {
      value: "mendel-arftlighet",
      label: "Mendels ärftlighetsforskning",
      content:
        "Gör en dagbok från Gregor Mendel under hans ärftlighetsexperiment",
      Icon: LocalFloristIcon,
    },
    {
      value: "pasteur-mikrobiologi",
      label: "Pasteurs mikrobiologiska arbete",
      content:
        "Gör en dagbok från Louis Pasteur under hans banbrytande mikrobiologiska experiment",
      Icon: ScienceIcon,
    },
    {
      value: "linnaeus-expedition",
      label: "Linnés lappländska resa",
      content: "Gör en dagbok från Carl von Linnés expedition till Lappland",
      Icon: ParkIcon,
    },
  ],

  historia: [
    { type: "subheader", label: "Dagboksteman inom historia:" },
    {
      value: "berlinmuren-fall",
      label: "Berlinmurens fall 1989",
      content:
        "Gör en dagbok från en östtysk medborgare under Berlinmurens fall 1989",
      Icon: CastleIcon,
    },
    {
      value: "gustav-vasa-dalarna",
      label: "Gustav Vasa i Dalarna",
      content: "Gör en dagbok från Gustav Vasas flykt i Dalarna",
      Icon: AccountBalanceIcon,
    },
    {
      value: "digerdoden",
      label: "Digerdöden i Stockholm",
      content: "Gör en dagbok från en läkare under digerdöden i Stockholm",
      Icon: MonitorHeartIcon,
    },
    {
      value: "kristina-abdikering",
      label: "Drottning Kristinas abdikering",
      content: "Gör en dagbok från drottning Kristinas sista dagar som regent",
      Icon: CastleIcon,
    },
    {
      value: "stockholms-blodbad",
      label: "Stockholms blodbad",
      content: "Gör en dagbok från en vittne till Stockholms blodbad",
      Icon: CastleIcon,
    },
    {
      value: "kalmar-unionen",
      label: "Kalmarunionens bildande",
      content: "Gör en dagbok från Margaretas kröning i Kalmar",
      Icon: AccountBalanceIcon,
    },
  ],

  fysik: [
    { type: "subheader", label: "Dagboksteman inom fysik:" },
    {
      value: "oppenheimer-manhattan",
      label: "Oppenheimer och Manhattanprojektet",
      content: "Gör en dagbok från Oppenheimer under arbetet med atombomben",
      Icon: MilitaryTechIcon,
    },
    {
      value: "einstein-relativitet",
      label: "Einsteins relativitetsteori",
      content:
        "Gör en dagbok från Einstein när han utvecklade relativitetsteorin",
      Icon: LightBulbIcon,
    },
    {
      value: "nobel-dynamit",
      label: "Nobels dynamitexperiment",
      content:
        "Gör en dagbok från Alfred Nobel under utvecklingen av dynamiten",
      Icon: ScienceIcon,
    },
    {
      value: "curie-radium",
      label: "Marie Curies radiumprojekt",
      content: "Gör en dagbok från Marie Curie under hennes arbete med radium",
      Icon: ScienceIcon,
    },
    {
      value: "celsius-termometer",
      label: "Celsius termometerskala",
      content:
        "Gör en dagbok från Anders Celsius när han utvecklade sin temperaturskala",
      Icon: EmojiObjectsIcon,
    },
    {
      value: "newton-gravitation",
      label: "Newtons gravitationsupptäckt",
      content:
        "Gör en dagbok från Newton när han utvecklade gravitationsteorin",
      Icon: PublicIcon,
    },
  ],

  kemi: [
    { type: "subheader", label: "Dagboksteman inom kemi:" },
    {
      value: "scheele-upptackter",
      label: "Scheeles laboratorium",
      content:
        "Gör en dagbok från Carl Wilhelm Scheele under hans kemiska upptäckter",
      Icon: ScienceIcon,
    },
    {
      value: "arrhenius-syror",
      label: "Arrhenius och syrateorin",
      content: "Gör en dagbok från Svante Arrhenius arbete med syror och baser",
      Icon: WorkspacesIcon,
    },
    {
      value: "berzelius-grundamnen",
      label: "Berzelius grundämnesarbete",
      content: "Gör en dagbok från Berzelius upptäckter av nya grundämnen",
      Icon: ScienceIcon,
    },
    {
      value: "mendelejev-systemet",
      label: "Mendelejevs periodiska system",
      content: "Gör en dagbok från skapandet av det periodiska systemet",
      Icon: WorkspacesIcon,
    },
    {
      value: "boyle-gaslagar",
      label: "Boyles gasexperiment",
      content: "Gör en dagbok från Robert Boyle under hans arbete med gaslagar",
      Icon: ScienceIcon,
    },
    {
      value: "lavoisier-oxidation",
      label: "Lavoisiers oxidationsteori",
      content: "Gör en dagbok från Lavoisiers arbete med oxidation",
      Icon: WorkspacesIcon,
    },
  ],

  matematik: [
    { type: "subheader", label: "Dagboksteman inom matematik:" },
    {
      value: "euler-broarna",
      label: "Eulers Königsbergproblem",
      content:
        "Gör en dagbok från Euler när han löste problemet med Königsbergs broar",
      Icon: CalculateIcon,
    },
    {
      value: "pythagoras-skola",
      label: "Pythagoras matematikskola",
      content: "Gör en dagbok från Pythagoras och hans matematikskola",
      Icon: CalculateIcon,
    },
    {
      value: "hypatia-alexandria",
      label: "Hypatia i Alexandria",
      content: "Gör en dagbok från Hypatias tid som matematiker i Alexandria",
      Icon: CalculateIcon,
    },
    {
      value: "gauss-ungdom",
      label: "Unge Gauss beräkningar",
      content:
        "Gör en dagbok från Carl Friedrich Gauss tidiga matematiska upptäckter",
      Icon: CalculateIcon,
    },
    {
      value: "turing-enigma",
      label: "Turing och Enigmakoden",
      content:
        "Gör en dagbok från Alan Turings arbete med att knäcka Enigmakoden",
      Icon: CalculateIcon,
    },
    {
      value: "lovelace-program",
      label: "Ada Lovelaces program",
      content:
        "Gör en dagbok från Ada Lovelace när hon skrev världens första datorprogram",
      Icon: CalculateIcon,
    },
  ],

  svenska: [
    { type: "subheader", label: "Dagboksteman inom svenska:" },
    {
      value: "strindberg-paris",
      label: "Strindberg i Paris",
      content: "Gör en dagbok från August Strindbergs tid i Paris",
      Icon: MenuBookIcon,
    },
    {
      value: "lagerlof-marbacka",
      label: "Lagerlöf på Mårbacka",
      content: "Gör en dagbok från Selma Lagerlöfs liv på Mårbacka",
      Icon: MenuBookIcon,
    },
    {
      value: "bellman-epistlar",
      label: "Bellmans epistlar",
      content:
        "Gör en dagbok från Carl Michael Bellman när han skrev sina epistlar",
      Icon: LanguageIcon,
    },
    {
      value: "moberg-utvandring",
      label: "Moberg researchar utvandringen",
      content:
        "Gör en dagbok från Vilhelm Mobergs research för utvandrarserierna",
      Icon: MenuBookIcon,
    },
    {
      value: "lindgren-villekulla",
      label: "Astrid skriver Pippi",
      content:
        "Gör en dagbok från när Astrid Lindgren skrev om Pippi Långstrump",
      Icon: MenuBookIcon,
    },
    {
      value: "transtromer-nobelpris",
      label: "Tranströmer får Nobelpriset",
      content: "Gör en dagbok från Tomas Tranströmers Nobelprisutdelning",
      Icon: EmojiEventsIcon,
    },
  ],
};

export const getThemes = (subject) => {
  if (!subject) return defaultThemes;
  return subjectThemes[subject] || defaultThemes;
};

export default getThemes;
