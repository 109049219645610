import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  IconButton,
  Avatar,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Voice from "./Voice";
import HoverableImage from "./HoverableImage";

const ConversationDisplay = ({
  lessonData,
  titleText,
  setTitleText,
  isEditingTitle,
  setIsEditingTitle,
  isGeneratingTopicImage,
  isGeneratingParticipantImages,
  loadingImageIndex,
  handleImageButtonClick,
  setImageGenerationType,
  setShowImageModal,
  setSelectedParticipantId,
  editingMessageId,
  setEditingMessageId,
  editingTranslationId,
  setEditingTranslationId,
  handleEditMessage,
  handleClearText,
  showSnackbar,
  user,
  theme,
  language,
  translationLanguage,
  setLessonData,
  viewMode,
}) => {
  const [editingParticipantId, setEditingParticipantId] = useState(null);
  const [editingParticipantRoleId, setEditingParticipantRoleId] =
    useState(null);

  const handleEditParticipantName = (participantId, newName) => {
    setLessonData((prevData) => ({
      ...prevData,
      participants: {
        ...prevData.participants,
        [participantId]: {
          ...prevData.participants[participantId],
          name: newName,
        },
      },
      conversation: prevData.conversation.map((message) => ({
        ...message,
        speaker:
          message.speaker === prevData.participants[participantId].name
            ? newName
            : message.speaker,
      })),
    }));
    showSnackbar("Deltagarens namn har uppdaterats", "success");
  };

  const handleEditParticipantRole = (participantId, newRole) => {
    setLessonData((prevData) => ({
      ...prevData,
      participants: {
        ...prevData.participants,
        [participantId]: {
          ...prevData.participants[participantId],
          role: newRole,
        },
      },
    }));
    showSnackbar("Deltagarens roll har uppdaterats", "success");
  };

  return (
    <Box sx={{ mt: 8 }}>
      {/* Title */}
      {isEditingTitle ? (
        <TextField
          value={titleText}
          onChange={(e) => setTitleText(e.target.value)}
          onBlur={() => {
            setIsEditingTitle(false);
            showSnackbar("Titeln på lektionen har uppdaterats", "success");
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setIsEditingTitle(false);
              showSnackbar("Titeln på lektionen har uppdaterats", "success");
            }
          }}
          autoFocus
          fullWidth
          variant="standard"
          sx={{
            backgroundColor: "transparent",
            "& .MuiInputBase-input": {
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "2.6em",
              marginBottom: "1.2rem",
              marginTop: "1.5rem",
            },
          }}
          InputProps={{
            disableUnderline: true,
          }}
        />
      ) : (
        <Box
          sx={{
            position: "relative",
            display: "inline-block",
            margin: "0 auto",
            width: "100%",
            mb: 7,
            mt: 0,
            ":hover .edit-icon": {
              opacity: 1,
            },
          }}
        >
          <Typography
            variant="h1"
            sx={{
              fontWeight: "bold",
              fontSize: "2.6em",

              textAlign: "center",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 2,
            }}
          >
            {titleText || " "}
            <Box
              component="span"
              sx={{
                opacity: 1,
                transition: "opacity 0.2s",
                display: viewMode ? "none" : "inline-flex",
                gap: 0.5,
              }}
              className="edit-icon"
            >
              <Tooltip title="Redigera" placement="top">
                <IconButton
                  size="small"
                  onClick={() => setIsEditingTitle(true)}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Typography>
        </Box>
      )}

      {/* Topic Image */}
      <Box
        sx={{
          mb: 6,
          mt: 2,
          position: "relative",
          width: "350px",
          margin: "0 auto",
        }}
      >
        <HoverableImage
          imageUrl={lessonData.topicImage}
          isLoading={isGeneratingTopicImage}
          onGenerateClick={() => {
            setImageGenerationType("topic");
            setShowImageModal(true);
          }}
          userId={user.uid}
          onImageUpdate={(newImageUrl) => {
            setLessonData((prevData) => ({
              ...prevData,
              topicImage: newImageUrl,
            }));
          }}
          height="350px"
          showSnackbar={showSnackbar}
          theme={theme}
        />
      </Box>

      {/* Participant Avatars Row */}

      <Typography
        variant="subtitle"
        sx={{
          color: "text.secondary",
          display: "block",
          fontWeight: "600",
          mt: 4,
          fontSize: "1.2rem",
          mb: 2,
          textAlign: "center",
        }}
      >
        Deltagare:
      </Typography>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: 3,
          mb: 4,
          mt: 3,
          flexWrap: "wrap",
        }}
      >
        {Object.entries(lessonData.participants).map(([id, participant]) => (
          <Box
            key={id}
            sx={{
              textAlign: "center",
              position: "relative",
              width: "240px", // Increased width
              display: "flex", // Added flex display
              flexDirection: "column", // Stack children vertically
              alignItems: "center", // Center children horizontally
            }}
          >
            <Box
              sx={{
                width: "100px", // Match HoverableImage size
                height: "100px", // Match HoverableImage size
              }}
            >
              <HoverableImage
                imageUrl={participant.image}
                isLoading={
                  isGeneratingParticipantImages && loadingImageIndex === id
                }
                loadingIndex={loadingImageIndex}
                currentIndex={id}
                onGenerateClick={() => {
                  setSelectedParticipantId(id);
                  setImageGenerationType("participants");
                  setShowImageModal(true);
                }}
                userId={user.uid}
                onImageUpdate={(newImageUrl) => {
                  setLessonData((prevData) => ({
                    ...prevData,
                    participants: {
                      ...prevData.participants,
                      [id]: {
                        ...prevData.participants[id],
                        image: newImageUrl,
                      },
                    },
                  }));
                }}
                showSnackbar={showSnackbar}
                tinyMode={true}
                size={100}
                theme={theme}
              />
            </Box>
            {editingParticipantId === id ? (
              <TextField
                defaultValue={participant.name}
                variant="standard"
                autoFocus
                onBlur={(e) => {
                  handleEditParticipantName(id, e.target.value);
                  setEditingParticipantId(null);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleEditParticipantName(id, e.target.value);
                    setEditingParticipantId(null);
                  }
                }}
                sx={{
                  mt: 1,
                  width: "100%",
                  "& .MuiInputBase-input": {
                    textAlign: "center",
                    fontWeight: "600",
                    fontSize: "1rem",
                  },
                }}
                InputProps={{
                  disableUnderline: viewMode,
                }}
              />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 0.5,
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontWeight: "600",
                    fontSize: "1rem",
                    mt: 1,
                    width: "100%",
                    cursor: viewMode ? "default" : "pointer",
                    "&:hover": !viewMode && {
                      bgcolor: "action.hover",
                      borderRadius: "4px",
                    },
                  }}
                  onClick={() => !viewMode && setEditingParticipantId(id)}
                >
                  {participant.name}
                </Typography>
                {!viewMode && (
                  <Tooltip title="Redigera namn" placement="top">
                    <IconButton
                      size="small"
                      onClick={() => setEditingParticipantId(id)}
                      sx={{ mt: 1 }}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            )}
            {editingParticipantRoleId === id ? (
              <TextField
                defaultValue={participant.role}
                variant="standard"
                autoFocus
                onBlur={(e) => {
                  handleEditParticipantRole(id, e.target.value);
                  setEditingParticipantRoleId(null);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleEditParticipantRole(id, e.target.value);
                    setEditingParticipantRoleId(null);
                  }
                }}
                sx={{
                  "& .MuiInputBase-input": {
                    textAlign: "center",
                    fontWeight: "400",
                    fontSize: "0.85rem",
                  },
                }}
                InputProps={{
                  disableUnderline: viewMode,
                }}
              />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 0.5,
                }}
              >
                <Typography
                  variant="caption"
                  sx={{
                    display: "block",
                    fontWeight: "400",
                    fontSize: "0.85rem",
                    width: "100%",
                    wordWrap: "break-word",
                    overflowWrap: "break-word",
                    whiteSpace: "normal",
                    cursor: viewMode ? "default" : "pointer",
                    "&:hover": !viewMode && {
                      bgcolor: "action.hover",
                      borderRadius: "4px",
                    },
                  }}
                  onClick={() => !viewMode && setEditingParticipantRoleId(id)}
                >
                  {participant.role}
                </Typography>
                {!viewMode && (
                  <Tooltip title="Redigera roll" placement="top">
                    <IconButton
                      size="small"
                      onClick={() => setEditingParticipantRoleId(id)}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            )}
          </Box>
        ))}
      </Box>

      {/* Conversation Messages */}
      {/* Conversation Messages */}
      <Box sx={{ mt: 7, display: "flex", flexDirection: "column", gap: 2 }}>
        {lessonData.conversation.map((message, index) => {
          const participant = Object.values(lessonData.participants).find(
            (p) => p.name === message.speaker,
          );

          return (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: index % 2 === 0 ? "flex-start" : "flex-end",
                mb: 4,
                maxWidth: "85%",
                alignSelf: index % 2 === 0 ? "flex-start" : "flex-end",
                position: "relative",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  mb: 1.3,
                  flexDirection: index % 2 === 0 ? "row" : "row-reverse",
                }}
              >
                <Avatar
                  src={
                    participant?.image ||
                    "https://placehold.co/50?text=Bild&font=Raleway"
                  }
                  alt={message.speaker}
                  sx={{ width: 52, height: 52 }}
                />
                <Typography
                  variant="subtitle2"
                  sx={{ fontWeight: "600", fontSize: "1.275rem" }}
                >
                  {message.speaker}
                </Typography>
              </Box>
              <Box
                sx={{
                  backgroundColor: (theme) =>
                    index % 2 === 0
                      ? theme.palette.mode === "dark"
                        ? "#15202b"
                        : "#e0e0e0"
                      : theme.palette.primary.main,
                  color: (theme) =>
                    index % 2 === 1 && theme.palette.primary.contrastText,
                  borderRadius: "16px",
                  borderTopLeftRadius: index % 2 === 0 ? "4px" : "16px",
                  borderTopRightRadius: index % 2 === 1 ? "4px" : "16px",
                  padding: "12px 16px",
                  maxWidth: "70%",
                  position: "relative",
                  "&::before": {
                    content: '""',
                    position: "absolute",
                    top: 0,
                    [index % 2 === 0 ? "left" : "right"]: -8,
                    borderStyle: "solid",
                    borderWidth: "8px",
                    borderColor: (theme) =>
                      `${
                        index % 2 === 0
                          ? theme.palette.mode === "dark"
                            ? "#15202b"
                            : "#e0e0e0"
                          : theme.palette.primary.main
                      } transparent transparent transparent`,
                  },
                }}
              >
                {/* Message */}
                {editingMessageId === index ? (
                  <TextField
                    fullWidth
                    multiline
                    defaultValue={message.message}
                    onBlur={(e) => {
                      handleEditMessage(index, e.target.value, "message");
                      setEditingMessageId(null);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && !e.shiftKey) {
                        e.preventDefault();
                        handleEditMessage(index, e.target.value, "message");
                        setEditingMessageId(null);
                      }
                    }}
                    autoFocus
                  />
                ) : (
                  <Typography
                    variant="body1"
                    sx={{
                      "&:hover .controls": {
                        opacity: 1,
                      },
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    {message.message || " "}
                    <Box
                      component="span"
                      className="controls"
                      sx={{
                        opacity: 0,
                        transition: "opacity 0.2s",
                        display: viewMode ? "none" : "inline-flex",
                        gap: 0.5,
                      }}
                    >
                      <Voice
                        content={message.message}
                        fontSize={20}
                        userId={user.uid}
                        language={language}
                        tooltipPlacement="top"
                      />

                      <Tooltip title="Redigera" placement="top">
                        <IconButton
                          size="small"
                          onClick={() => setEditingMessageId(index)}
                        >
                          <EditIcon
                            sx={{
                              color:
                                index % 2 === 0 ? "text.secondary" : "white",
                            }}
                            fontSize="small"
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Töm" placement="top">
                        <IconButton
                          size="small"
                          onClick={() => handleClearText(index, "message")}
                        >
                          <DeleteOutlineIcon
                            sx={{
                              color:
                                index % 2 === 0 ? "text.secondary" : "white",
                            }}
                            fontSize="small"
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Typography>
                )}

                {/* Translation */}
                {message.translation && (
                  <Box
                    sx={{
                      mt: 1,
                      pt: 1,
                      borderTop: "1px solid rgba(128, 128, 128, 0.2)",
                      fontStyle: "italic",
                    }}
                  >
                    {editingTranslationId === index ? (
                      <TextField
                        fullWidth
                        multiline
                        defaultValue={message.translation}
                        onBlur={(e) => {
                          handleEditMessage(
                            index,
                            e.target.value,
                            "translation",
                          );
                          setEditingTranslationId(null);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter" && !e.shiftKey) {
                            e.preventDefault();
                            handleEditMessage(
                              index,
                              e.target.value,
                              "translation",
                            );
                            setEditingTranslationId(null);
                          }
                        }}
                        autoFocus
                      />
                    ) : (
                      <Typography
                        variant="body1"
                        sx={{
                          "&:hover .controls": {
                            opacity: 1,
                          },
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        {message.translation || "..."}
                        <Box
                          component="span"
                          className="controls"
                          sx={{
                            opacity: 0,
                            transition: "opacity 0.2s",
                            display: viewMode ? "none" : "inline-flex",
                            gap: 0.5,
                          }}
                        >
                          <Voice
                            content={message.translation}
                            fontSize={20}
                            userId={user.uid}
                            language={translationLanguage}
                            tooltipPlacement="top"
                          />

                          <Tooltip title="Redigera" placement="top">
                            <IconButton
                              size="small"
                              onClick={() => setEditingTranslationId(index)}
                            >
                              <EditIcon
                                sx={{
                                  color:
                                    index % 2 === 0
                                      ? "text.secondary"
                                      : "white",
                                }}
                                fontSize="small"
                              />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Töm" placement="top">
                            <IconButton
                              size="small"
                              onClick={() =>
                                handleClearText(index, "translation")
                              }
                            >
                              <DeleteOutlineIcon
                                sx={{
                                  color:
                                    index % 2 === 0
                                      ? "text.secondary"
                                      : "white",
                                }}
                                fontSize="small"
                              />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Typography>
                    )}
                  </Box>
                )}
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default ConversationDisplay;
