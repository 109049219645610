import ScienceIcon from "@mui/icons-material/Science";
import CastleIcon from "@mui/icons-material/Castle";
import PsychologyIcon from "@mui/icons-material/Psychology";
import PublicIcon from "@mui/icons-material/Public";
import CalculateIcon from "@mui/icons-material/Calculate";
import LanguageIcon from "@mui/icons-material/Language";
import EmojiObjectsIcon from "@mui/icons-material/EmojiObjects";
import LocalFloristIcon from "@mui/icons-material/LocalFlorist";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import ParkIcon from "@mui/icons-material/Park";
import BiotechIcon from "@mui/icons-material/Biotech";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import EnergySavingsLeafIcon from "@mui/icons-material/EnergySavingsLeaf";
import FactoryIcon from "@mui/icons-material/Factory";
import VaccinesIcon from "@mui/icons-material/Vaccines";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import GavelIcon from "@mui/icons-material/Gavel";
import ArticleIcon from "@mui/icons-material/Article";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";

// Default themes that show when no subject is selected
export const defaultThemes = [
  {
    type: "subheader",
    label:
      "Generella rollspelsteman som lämpar sig extra bra för denna lektionstyp:",
  },
  {
    value: "socialism-capitalism",
    label: "Socialism vs Kapitalism",
    content:
      "Gör ett rollspel där eleverna representerar olika ekonomiska system",
    Icon: AccountBalanceIcon,
  },
  {
    value: "renewable-fossil",
    label: "Förnybar vs Fossil energi",
    content: "Gör ett rollspel om olika energikällor och deras påverkan",
    Icon: EnergySavingsLeafIcon,
  },
  {
    value: "city-country",
    label: "Stad vs Landsbygd",
    content: "Gör ett rollspel om för- och nackdelar med olika levnadssätt",
    Icon: ParkIcon,
  },
  {
    value: "technology-tradition",
    label: "Teknologi vs Tradition",
    content:
      "Gör ett rollspel om balansen mellan teknologisk utveckling och traditioner",
    Icon: EmojiObjectsIcon,
  },
  {
    value: "globalization",
    label: "Globalisering",
    content: "Gör ett rollspel om globaliseringens effekter",
    Icon: PublicIcon,
  },
  {
    value: "democracy-autocracy",
    label: "Demokrati vs Autokrati",
    content: "Gör ett rollspel om olika styrelseskick",
    Icon: GavelIcon,
  },
];

// Subject-specific themes
export const subjectThemes = {
  biologi: [
    {
      type: "subheader",
      label:
        "Biologiska rollspelsteman som lämpar sig extra bra för denna lektionstyp:",
    },
    {
      value: "gmo-debate",
      label: "GMO-debatten",
      content: "Gör ett rollspel om genmodifierade organismer",
      Icon: BiotechIcon,
    },
    {
      value: "vaccination-debate",
      label: "Vaccinationsdebatten",
      content: "Gör ett rollspel om vacciner och folkhälsa",
      Icon: VaccinesIcon,
    },
    {
      value: "ecosystem-management",
      label: "Ekosystemhantering",
      content: "Gör ett rollspel om bevarande vs utveckling av naturområden",
      Icon: ParkIcon,
    },
    {
      value: "meat-plant-diet",
      label: "Köttätare vs Växtbaserad kost",
      content: "Gör ett rollspel om olika kosthållningar",
      Icon: RestaurantIcon,
    },
    {
      value: "animal-testing",
      label: "Djurförsök i forskning",
      content: "Gör ett rollspel om etik inom medicinsk forskning",
      Icon: MonitorHeartIcon,
    },
    {
      value: "climate-actions",
      label: "Klimatåtgärder",
      content: "Gör ett rollspel om olika sätt att hantera klimatkrisen",
      Icon: WbSunnyIcon,
    },
  ],

  historia: [
    {
      type: "subheader",
      label:
        "Historiska rollspelsteman som lämpar sig extra bra för denna lektionstyp:",
    },
    {
      value: "industrial-workers",
      label: "Arbetare vs Fabriksägare",
      content: "Gör ett rollspel om industriella revolutionen",
      Icon: FactoryIcon,
    },
    {
      value: "cold-war",
      label: "Kalla kriget",
      content: "Gör ett rollspel om öst vs väst under kalla kriget",
      Icon: PublicIcon,
    },
    {
      value: "colonialism",
      label: "Kolonialism",
      content: "Gör ett rollspel om kolonialismens effekter",
      Icon: CastleIcon,
    },
    {
      value: "reformation",
      label: "Reformation vs Katolicism",
      content: "Gör ett rollspel om reformationen",
      Icon: CastleIcon,
    },
    {
      value: "enlightenment",
      label: "Upplysningen",
      content: "Gör ett rollspel om vetenskap vs religion",
      Icon: EmojiObjectsIcon,
    },
    {
      value: "suffrage-movement",
      label: "Rösträttsrörelsen",
      content: "Gör ett rollspel om kampen för allmän rösträtt",
      Icon: PublicIcon, // Changed from SocialMovementIcon as it's not in MUI
    },
  ],

  fysik: [
    {
      type: "subheader",
      label:
        "Fysiska rollspelsteman som lämpar sig extra bra för denna lektionstyp:",
    },
    {
      value: "nuclear-power",
      label: "Kärnkraft",
      content: "Gör ett rollspel om kärnkraftens för- och nackdelar",
      Icon: ScienceIcon,
    },
    {
      value: "space-exploration",
      label: "Rymdutforskning",
      content: "Gör ett rollspel om prioriteringar inom rymdforskning",
      Icon: PublicIcon,
    },
    {
      value: "ai-development",
      label: "AI-utveckling",
      content: "Gör ett rollspel om artificiell intelligens",
      Icon: PsychologyIcon,
    },
    {
      value: "quantum-classical",
      label: "Kvantfysik vs Klassisk fysik",
      content: "Gör ett rollspel om olika fysikaliska världsbilder",
      Icon: ScienceIcon,
    },
    {
      value: "fusion-power",
      label: "Fusionskraft",
      content: "Gör ett rollspel om framtidens energiförsörjning",
      Icon: EmojiObjectsIcon,
    },
    {
      value: "climate-technology",
      label: "Klimatteknik",
      content: "Gör ett rollspel om tekniska lösningar på klimatkrisen",
      Icon: EnergySavingsLeafIcon,
    },
  ],

  kemi: [
    {
      type: "subheader",
      label:
        "Kemiska rollspelsteman som lämpar sig extra bra för denna lektionstyp:",
    },
    {
      value: "plastic-debate",
      label: "Plastdebatten",
      content: "Gör ett rollspel om plast i samhället",
      Icon: ScienceIcon,
    },
    {
      value: "chemical-safety",
      label: "Kemikaliesäkerhet",
      content: "Gör ett rollspel om kemikalier i vardagen",
      Icon: ContentPasteIcon,
    },
    {
      value: "medicine-development",
      label: "Läkemedelsutveckling",
      content: "Gör ett rollspel om ny medicinsk behandling",
      Icon: MonitorHeartIcon,
    },
    {
      value: "clean-tech",
      label: "Ren teknologi",
      content: "Gör ett rollspel om miljövänlig produktion",
      Icon: EnergySavingsLeafIcon,
    },
    {
      value: "food-additives",
      label: "Tillsatser i mat",
      content: "Gör ett rollspel om livsmedelskemi",
      Icon: RestaurantIcon,
    },
    {
      value: "chemical-industry",
      label: "Kemisk industri",
      content: "Gör ett rollspel om industrins miljöpåverkan",
      Icon: FactoryIcon,
    },
  ],

  matematik: [
    {
      type: "subheader",
      label:
        "Matematiska rollspelsteman som lämpar sig extra bra för denna lektionstyp:",
    },
    {
      value: "statistics-ethics",
      label: "Statistik och etik",
      content: "Gör ett rollspel om användning av statistik",
      Icon: TrendingUpIcon,
    },
    {
      value: "math-education",
      label: "Matematikundervisning",
      content: "Gör ett rollspel om olika pedagogiska metoder",
      Icon: CalculateIcon,
    },
    {
      value: "ai-mathematics",
      label: "AI i matematik",
      content: "Gör ett rollspel om teknologi i matematiken",
      Icon: PsychologyIcon,
    },
    {
      value: "real-world-math",
      label: "Vardagsmatematik",
      content: "Gör ett rollspel om matematikens relevans",
      Icon: CalculateIcon,
    },
    {
      value: "math-commerce",
      label: "Matematik i handel",
      content: "Gör ett rollspel om ekonomiska beräkningar",
      Icon: AccountBalanceIcon,
    },
    {
      value: "math-research",
      label: "Matematisk forskning",
      content: "Gör ett rollspel om forskningsprioriteringar",
      Icon: ScienceIcon,
    },
  ],

  svenska: [
    {
      type: "subheader",
      label:
        "Svenska rollspelsteman som lämpar sig extra bra för denna lektionstyp:",
    },
    {
      value: "language-change",
      label: "Språkförändring",
      content: "Gör ett rollspel om språkets utveckling",
      Icon: LanguageIcon,
    },
    {
      value: "digital-traditional",
      label: "Digital vs Traditionell litteratur",
      content: "Gör ett rollspel om litteraturens framtid",
      Icon: ArticleIcon,
    },
    {
      value: "minority-languages",
      label: "Minoritetsspråk",
      content: "Gör ett rollspel om språkliga rättigheter",
      Icon: LanguageIcon,
    },
    {
      value: "social-media-language",
      label: "Språk i sociala medier",
      content: "Gör ett rollspel om moderna kommunikationsformer",
      Icon: LanguageIcon,
    },
    {
      value: "news-media",
      label: "Nyhetsjournalistik",
      content: "Gör ett rollspel om medierapportering",
      Icon: ArticleIcon,
    },
    {
      value: "literature-censorship",
      label: "Litterär censur",
      content: "Gör ett rollspel om yttrandefrihet",
      Icon: ArticleIcon,
    },
  ],
};

// Helper function to get appropriate themes based on subject
export const getThemes = (subject) => {
  if (!subject) return defaultThemes;
  return subjectThemes[subject] || defaultThemes;
};

export default getThemes;
