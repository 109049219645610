import {
  History,
  Science,
  Language,
  Calculate,
  Public,
  Biotech,
  Psychology,
  EmojiEvents,
  Park,
  Agriculture,
  MonitorHeart,
  MenuBook,
} from '@mui/icons-material';

// Default themes that show when no subject is selected
export const defaultThemes = [
  { type: 'subheader', label: 'Teman som lämpar sig extra bra för Jeopardy-lektioner:' },
  {
    value: 'allmant-vetenskap',
    label: 'Allmän vetenskap',
    content: 'Skapa en Jeopardy med blandade vetenskapliga frågor inom olika områden',
    Icon: Science,
  },
  {
    value: 'varldshistoria',
    label: 'Världshistoria',
    content: 'Skapa en Jeopardy med frågor om viktiga historiska händelser och personer',
    Icon: History,
  },
  {
    value: 'kultur-underhallning',
    label: 'Kultur & Underhållning',
    content: 'Skapa en Jeopardy med frågor om film, musik, konst och litteratur',
    Icon: MenuBook,
  },
  {
    value: 'geografi-varlden',
    label: 'Geografi & Världen',
    content: 'Skapa en Jeopardy med frågor om länder, huvudstäder och geografiska fenomen',
    Icon: Public,
  },
  {
    value: 'sport-fritid',
    label: 'Sport & Fritid',
    content: 'Skapa en Jeopardy med frågor om olika sporter och idrottsprestationer',
    Icon: EmojiEvents,
  },
];

// Subject-specific themes
export const subjectThemes = {
  filosofi: [
    { type: 'subheader', label: 'Filosofiteman som lämpar sig extra bra för Jeopardy:' },
    {
      value: 'antikens-filosofer',
      label: 'Antikens filosofer',
      content: 'Skapa en Jeopardy med frågor om de gamla grekiska och romerska filosoferna',
      Icon: History,
    },
    {
      value: 'etik-moral',
      label: 'Etik & Moral',
      content: 'Skapa en Jeopardy med frågor om etiska teorier och moraliska dilemman',
      Icon: Psychology,
    },
    {
      value: 'logik-argumentation',
      label: 'Logik & Argumentation',
      content: 'Skapa en Jeopardy med frågor om logiska resonemang och argumentationsanalys',
      Icon: Calculate,
    },
    {
      value: 'politisk-filosofi',
      label: 'Politisk filosofi',
      content: 'Skapa en Jeopardy med frågor om olika politiska filosofier och tänkare',
      Icon: Public,
    },
    {
      value: 'existentialism',
      label: 'Existentialism',
      content: 'Skapa en Jeopardy med frågor om existentialistiska tänkare och koncept',
      Icon: Psychology,
    },
    {
      value: 'kunskapsteori',
      label: 'Kunskapsteori',
      content: 'Skapa en Jeopardy med frågor om epistemologi och olika kunskapssyner',
      Icon: MenuBook,
    },
    {
      value: 'medvetandefilosofi',
      label: 'Medvetandefilosofi',
      content: 'Skapa en Jeopardy med frågor om medvetandet och dess filosofiska frågeställningar',
      Icon: Psychology,
    },
    {
      value: 'moderna-filosofer',
      label: 'Moderna filosofer',
      content: 'Skapa en Jeopardy med frågor om filosofer från upplysningstiden till nutid',
      Icon: History,
    },
    {
      value: 'religionsfilosofi',
      label: 'Religionsfilosofi',
      content: 'Skapa en Jeopardy med frågor om filosofiska perspektiv på religion och tro',
      Icon: Public,
    },
  ],

  biologi: [
    { type: 'subheader', label: 'Biologiteman som lämpar sig extra bra för Jeopardy:' },
    {
      value: 'manniskokroppen',
      label: 'Människokroppen',
      content: 'Skapa en Jeopardy med frågor om människans anatomi och fysiologi',
      Icon: Biotech,
    },
    {
      value: 'djurriket',
      label: 'Djurriket',
      content: 'Skapa en Jeopardy med frågor om olika djurarter och deras egenskaper',
      Icon: Park,
    },
    {
      value: 'vaxtriket',
      label: 'Växtriket',
      content: 'Skapa en Jeopardy med frågor om olika växtarter och botanik',
      Icon: Agriculture,
    },
    {
      value: 'ekologi-miljo',
      label: 'Ekologi & Miljö',
      content: 'Skapa en Jeopardy med frågor om ekosystem och miljöfrågor',
      Icon: Public,
    },
    {
      value: 'genetik-arv',
      label: 'Genetik & Arv',
      content: 'Skapa en Jeopardy med frågor om ärftlighet och genetiska processer',
      Icon: MonitorHeart,
    },
  ],

  historia: [
    { type: 'subheader', label: 'Historieteman som lämpar sig extra bra för Jeopardy:' },
    {
      value: 'svenska-kungar',
      label: 'Svenska regenter',
      content: 'Skapa en Jeopardy med frågor om Sveriges kungar och drottningar',
      Icon: History,
    },
    {
      value: 'viktiga-uppfinningar',
      label: 'Uppfinningar & Innovationer',
      content: 'Skapa en Jeopardy med frågor om historiska uppfinningar',
      Icon: Science,
    },
    {
      value: 'krig-konflikter',
      label: 'Krig & Konflikter',
      content: 'Skapa en Jeopardy med frågor om historiska krig och konflikter',
      Icon: History,
    },
    {
      value: 'forntida-civilisationer',
      label: 'Forntida civilisationer',
      content: 'Skapa en Jeopardy med frågor om antika kulturer och samhällen',
      Icon: History,
    },
    {
      value: 'sveriges-historia',
      label: 'Sveriges historia',
      content: 'Skapa en Jeopardy med frågor om viktiga händelser i svensk historia',
      Icon: History,
    },
  ],

  matematik: [
    { type: 'subheader', label: 'Matematikteman som lämpar sig extra bra för Jeopardy:' },
    {
      value: 'geometriska-former',
      label: 'Geometri & Former',
      content: 'Skapa en Jeopardy med frågor om geometriska former och beräkningar',
      Icon: Calculate,
    },
    {
      value: 'algebra-ekvationer',
      label: 'Algebra & Ekvationer',
      content: 'Skapa en Jeopardy med frågor om algebraiska uttryck och ekvationer',
      Icon: Calculate,
    },
    {
      value: 'matematiska-begrepp',
      label: 'Matematiska begrepp',
      content: 'Skapa en Jeopardy med frågor om viktiga matematiska begrepp och termer',
      Icon: Calculate,
    },
    {
      value: 'problemlosning',
      label: 'Problemlösning',
      content: 'Skapa en Jeopardy med matematiska problemlösningsuppgifter',
      Icon: Calculate,
    },
    {
      value: 'statistik-sannolikhet',
      label: 'Statistik & Sannolikhet',
      content: 'Skapa en Jeopardy med frågor om statistik och sannolikhetslära',
      Icon: Calculate,
    },
  ],

  svenska: [
    { type: 'subheader', label: 'Svenskateman som lämpar sig extra bra för Jeopardy:' },
    {
      value: 'ordkunskap',
      label: 'Ordkunskap & Synonymer',
      content: 'Skapa en Jeopardy med frågor om ord, synonymer och ordspråk',
      Icon: Language,
    },
    {
      value: 'grammatik',
      label: 'Grammatik & Språkregler',
      content: 'Skapa en Jeopardy med frågor om svensk grammatik och språkregler',
      Icon: Language,
    },
    {
      value: 'litteraturkanon',
      label: 'Svensk litteratur',
      content: 'Skapa en Jeopardy med frågor om svenska författare och deras verk',
      Icon: MenuBook,
    },
    {
      value: 'sprakhistoria',
      label: 'Språkhistoria',
      content: 'Skapa en Jeopardy med frågor om svenska språkets utveckling',
      Icon: Language,
    },
    {
      value: 'poesi-stilfigurer',
      label: 'Poesi & Stilfigurer',
      content: 'Skapa en Jeopardy med frågor om olika diktformer och stilistiska grepp',
      Icon: MenuBook,
    },
  ],
};

// Helper function to get appropriate themes based on subject
export const getThemes = subject => {
  if (!subject) return defaultThemes;
  return subjectThemes[subject] || defaultThemes;
};

export default getThemes;
