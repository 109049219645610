const booksData = {
  gymnasiet: {
    romaner: [
      {
        title: "Allt går sönder",
        year: 1958,
        author: "Chinua Achebe",
        description:
          "En postkolonial klassiker. Berättelsen följer huvudpersonen Okonkwo, men den handlar också om hur hela det gamla samhället där Ibofolket bor förändras fullständigt när européerna börjar etablera sig i Afrika. Genom att följa Okonkwos liv får vi en inblick i vad denna förändring innebär för de människor som har levt mycket traditionsbundet, men som plötsligt ser hur det mesta förändras. Achebe är inte okritisk till det gamla, men de nya herrarnas oförsonlighet framgår tydligt.",
      },
      {
        title: "Ankomsten",
        year: 2006,
        author: "Shaun Tan",
        description:
          "Ordlös klassiker med ett imponerande antal bilder. Boken handlar om en ensam människas möte med ett främmande land. Huvudpersonen lämnar sin familj och ger sig ut på en oviss resa. Färgval och inramningar bidrar till bildernas betydelse och sätter fokus på ordlösa sätt att kommunicera. Hela uppslag varvas med mindre bilder i långa sekvenser. Läsaren får engagera sig med tankar och känslor för att fylla tomrummet mellan bilderna. Här synliggörs den viktiga skillnaden mellan att beskriva och att tolka bilder och det liv som bilderna gestaltar. Shaun Tan tilldelades ALMA-priset 2011.",
      },
      {
        title: "Bror",
        year: 2023,
        author: "Alex Khourie",
        description:
          "Spännande om att välja kriget och förlora sig själv. Romanen skildrar Husseins och hans kompisars väg in i gängkriminalitet i förorten Alby. Det är drivet och lyhört, med ett poetiskt och våldsamt språk. Skrivet från Husseins egen synvinkel. De rappa och trovärdiga dialogerna skapar en stämning av gemenskap. Det är rakt igenom klaustrofobiskt men också klargörande om Hussein och hans tillvaro. Nominerades till Nordiska rådets ungdomslitteraturpris 2024.",
      },
      {
        title: "Där allt var bra",
        year: 2018,
        author: "Tamara Bach",
        description:
          "Kortroman om Ben som tillbringar en påsk i farfars gamla stuga tillsammans med sin storasyster Annika. Hon som alltid har varit den förnuftiga av syskonen har precis börjat plugga på universitetet. Ben har hoppat av gymnasiet och vet inte längre vart han är på väg. Med ett subtilt och överraskande språk skildras ingenmanslandet mellan ungdom och vuxenhet. Att återvända till stugan är att återvända till den plats där allt en gång var bra. Kan syskonen fortfarande mötas?",
      },
      {
        title: "De små tingens gud",
        year: 1997,
        author: "Arundhati Roy",
        description:
          "Tvillingarna Rahel och Estha möts i den sydindiska hemstaden. De har varit ifrån varandra under två decennier. De skildes åt efter att deras mor Ammu blivit påkommen med att ha en kärleksaffär med den kastlösa och oberörbara snickaren Velutha. Den tragiska familjehistorien berättas mot en fond av religiösa, politiska och kulturella regler och hinder. Språket förmedlar Indiens dofter, färger och ljud.",
      },
      {
        title: "Doktor Glas",
        year: 1905,
        author: "Hjalmar Söderberg",
        description:
          "En deprimerande leda drabbar Doktor Glas en varm sommar i Stockholm i slutet av 1800-talet. Den stora handling som han längtar efter att utföra kommer då till honom i Helga Gregorius gestalt. Hon vill slippa ifrån makens, pastor Gregorius, krav på sex. Doktor Glas har plötsligt makt och möjlighet att styra över liv och död. Helgas lycka ställs mot pastor Gregorius liv. I dagbokens form får vi följa hur doktor Glas reflekterar över olika möjliga handlingsalternativ och deras konsekvenser, allt inbäddat i en av våra klassiska Stockholmsskildringar.",
      },
      {
        title: "Fåglarna",
        year: 1957,
        author: "Tarjei Vesaas",
        description:
          "Vesaas bok är en kärleksfull skildring av hur det är att ses som udda av sin omgivning. Mattis vill väl och han vill vara som alla andra och också bidra med sitt arbete till hushållet, men det går inte alltid som han vill. Mattis har andra gåvor, och när morkullan flyger i skymningen betyder det mer för honom än för andra som bara ser en fågel. Mattis liv är inte lätt och han vare sig förstår eller blir förstådd av andra människor, men Vesaas skildrar honom ändå med stor värme.",
      },
      {
        title: "Främlingen",
        year: 1942,
        author: "Albert Camus",
        description:
          "Människan är fri att välja – eller tvungen att välja – och varje val får konsekvenser. Meursault, den vanliga människa som är huvudperson i denna berättelse, kan uppfattas som amoralisk. Tog han hand om sin döende mor? Varför hjälper han till med ett brev som leder till att en kvinna misshandlas? Älskar han Marie, och varför skjuter han en man på stranden så att denne dör? Hade allt sett annorlunda ut om han tyckt att det var värt pengarna att besöka sin döende mor? Albert Camus fick Nobelpriset i litteratur 1957.",
      },
      {
        title: "Förvandlingen",
        year: 1915,
        author: "Franz Kafka",
        description:
          "Klassisk kortroman om Gregor Samsa som en morgon vaknar upp och känner sig som en jättelik och klumpig insekt. Att vara en insekt ställer alla vardagens sysslor och relationer på ända. Gregor stänger in sig på sitt rum och följer häpet sin familjs reaktioner genom dörrspringan, samtidigt som de betraktar honom med stigande avsmak. Berättad i tredje person men från Gregors perspektiv. Mångtydig roman med egendomlig stämning som är både verklig och drömlik.",
      },
      {
        title: "Göra sig kvitt Eddy Bellegueule",
        year: 2014,
        author: "Édouard Louis",
        description:
          "Självbiografisk kortroman om Eddy som växer upp i en liten bruksort i norra Frankrike. Berättelsen formar sig till en skoningslös uppgörelse med den egna uppväxten i ett fattigt arbetarhem – en förhatlig tillvaro fylld av vilsna och söndersupna män, rasism, homofobi och pennalism. Men romanen är också en inkännande skildring av hur det kan vara att upptäcka sin egen sexualitet och ta sig vidare från sociala mönster som begränsar.",
      },
      {
        title: "Hunden",
        year: 1986,
        author: "Kerstin Ekman",
        description:
          "Att se världen med en hunds ögon är något speciellt. Ekman får oss verkligen att förstå hur det är att vara en annan varelse med både liknande och helt annorlunda tankar om livet. Hundens liv är ordlöst men fångas ändå av författaren så att vi kan förstå det. Respekten för ett liv som inte är mänskligt är grundmurad i denna skildring av det som alla levande varelser har gemensamt: livsviljan. En berättelse som också klart visar att naturen har ett liv oberoende av människan.",
      },
      {
        title: "Intet",
        year: 2010,
        author: "Janne Teller",
        description:
          "Filosofisk kortroman om människans längtan efter livets mening. Pierre Anthon kommer en dag på att livet inte har någon mening och klättrar upp i ett träd. Där sitter han och trakasserar klasskamraterna med sin visdom om alltings intighet. De bestämmer sig för att motbevisa honom. Insiktsfullt men också utmanande om vad som ger betydelse i våra liv. En absurd, våldsam och mångbottnad berättelse som tänjer på gränserna för vad ungdomslitteratur kan vara.",
      },
      {
        title: "Jellicoe Road",
        year: 2006,
        author: "Melina Marchetta",
        description:
          "Den sjuttonåriga Taylor går sista året på internatskolan Jellicoe School. Hon hamnade där när hon som elvaåring övergavs på 7-eleven av sin mamma och omhändertogs av Hannah, som blir hennes beskyddare. Hannah skriver på en roman som Taylor får ta del av i fragment. Hon anar att den har beröringspunkter med hennes eget liv. Under berättelsens gång vävs de händelser som pågår i nutid, som konflikter med andra skolor i närheten, samman med Taylors förflutna.",
      },
      {
        title: "Kejsarn av Portugallien",
        year: 1914,
        author: "Selma Lagerlöf",
        description:
          "Den fattiga torparen Jan i Skrolycka älskar sin dotter Klara Gulla så till den grad att han, när hon lämnat hemmet för att hjälpa till med familjens ekonomi, förlorar greppet om verkligheten och utnämner sig själv till Kejsare av Portugallien. Faderns galenskap blir en belastning för familjen och den älskade dottern när hon så småningom kommer hem. Frigörelse och försoning mellan generationer har här en av sina starkaste berättelser.",
      },
      {
        title: "Kvinnor och äppelträd",
        year: 1933,
        author: "Moa Martinson",
        description:
          "Genom Sally och Ellen ges en skildring av livet för de sämst ställda i Sverige i början av 1900-talet. De utgör det sista ledet i ett kvinnligt släktträd som sträcker sig tre generationer bakåt. Då inledde Mor Sofia och Fredrika kampen mot smuts, fylleri och ett tyngande barnafödande genom att bada en gång i veckan. Samtidigt som berättarstilen är drastisk och medryckande, vilar den på en mystik som förenar människa med naturens växlingar och stjärnornas gång över himlavalvet.",
      },
      {
        title: "Minnet av vatten",
        year: 2012,
        author: "Emma Itäranta",
        description:
          "Postapokalyptisk berättelse om sjuttonåriga Noria Kaitio som lever i en tid efter den stora miljökatastrofen. Kina har tagit makten över den Skandinaviska unionen och militären håller ett hårt grepp om befolkningen. Människorna söker i plastgravar efter användbara saker från den gamla eran men framför allt: det är ont om vatten. Noria själv ska bli temästare som sin far men stöter på problem. Ett poetiskt utforskande av miljökatastrofens konsekvenser och den moraliska situation som uppstår när en del har vatten och andra inte.",
      },
      {
        title: "Missat samtal",
        year: 2021,
        author: "Nora Dåsnes",
        description:
          "Grafisk roman som laborerar med tystnad och färgval. Handlingen utspelar sig en månad efter terrorattentatet på Utøya och rör sig på två tidsplan. Läsaren får följa Rebecka som precis har börjat gymnasiet. Hon försöker koncentrera sig på vardagen, men kan inte glömma det som hände den 22 juli 2011. Berättelsen fokuserar inte på själva terrorattentatet. I stället skildras hur händelsen upplevdes av dem som inte var där, och hur den påverkade unga människors liv i Norge. Missat samtal fick Nordiska rådets barn- och ungdomslitteraturpris 2022.",
      },
      {
        title: "Never let me go",
        year: 2005,
        author: "Kazuo Ishiguro",
        description:
          "Kazuo Ishiguro, tilldelad Nobelpris i litteratur 2017, berättar genom Kathy H om en dystopisk framtid som till det yttre påminner om England på 1990-talet. Den nu trettioåriga Kathy berättar i återblickar om sin uppväxt på internatskolan Hailsham tillsammans med Ruth C och Tommy D. Kamraterna genomlever barndom, tonår och tidigt vuxenliv ovetande om varifrån de kommer. Stegvis får de reda på att syftet med deras liv är att de ska bli organdonatorer för att sedan dö.",
      },
      {
        title: "Nordiska myter. Från Yggdrasil till Ragnarök",
        year: 2017,
        author: "Neil Gaiman",
        description:
          "I en serie episoder gestaltas den fornnordiska kosmologin och mytologin, från begynnelsen till slutet, på ett konkret, spännande och ofta humoristiskt sätt. Gudarna har, som sina grekiska motsvarigheter, mänskliga drag som vi kan spegla oss i: Odens längtan efter vishet, Tors godmodiga dundrande som försätter honom i kniviga situationer, Lokes list och förslagenhet och Freja, den vackra och sköna med gyllene hår. Att det hela i mångt och mycket är ett familjedrama gör det inte sämre.",
      },
      {
        title: "Nässlorna blomma",
        year: 1935,
        author: "Harry Martinson",
        description:
          "Den lille Martin förlorar först sina föräldrar, fadern dör och modern emigrerar, och sedan sin älskade storasyster. Denna barndomsskildring är en av de mörkaste som skrivits samtidigt som den lyser och glimmar i det precisa språket och Martins förmåga att höja sig över allt elände som drabbar honom som utackorderat sockenbarn. En av de få ljuspunkterna hittar han i skolan, där han får vara sig själv och känna glädjen av att som en kung behärska rättstavningen. Harry Martinson tilldelades Nobelpriset i litteratur 1974.",
      },

      {
        title: "Omständigheter",
        year: 2000,
        author: "Annie Ernaux",
        description:
          "Berättaren testar sig efter en tillfällig förbindelse för aids på en klinik i Paris. Beskedet att hon inte är smittad får henne att minnas att hon genomgått samma känslor mellan hopp och förtvivlan när hon flera decennier tidigare fått beskedet att hon var gravid. Minnena från hennes försök att genomgå en abort, ofta återberättade i starkt koncentrerade scener och episoder, blandas med utdrag ur dagböcker och den vuxna berättarens reflektioner. Annie Ernaux tilldelades Nobelpriset i litteratur 2022.",
      },
      {
        title: "Persepolis",
        year: 2000,
        author: "Marjane Satrapi",
        description:
          "I denna grafiska roman får vi följa en flickas utveckling till ung kvinna i Iran och Österrike. Vi får mycket tydligt möjlighet att förstå hur svårt det var att växa upp i förtryckets Iran, men också hur det friare Österrike kan bli en väldigt ensam plats för en flykting. Människans ensamhet i ett liv med en fot i varje land skildras uttrycksfullt, och insikten att inget land kan erbjuda allt växer fram under läsningen. Man måste ändå välja, och det gör också Marjane trots att det innebär flera uppbrott. En modern serieklassiker.",
      },
      {
        title: "Stöld",
        year: 2021,
        author: "Ann-Helén Laestadius",
        description:
          "Att vara renägande same idag kan innebära både stolthet och rädsla. Att dessutom vara ung same kan innebära stora påfrestningar, både med krav från den egna gruppen och hot från majoritetssamhället. Redan som liten flicka känner Elsa samernas utsatthet på mycket nära håll, när hon blir vittne till den anonyma grymhet som renarna inte sällan utsätts för. Hon förstår också med tiden att den konflikt som finns mellan renägande samer och majoritetssamhället har negativa konsekvenser på fler än ett sätt.",
      },
      {
        title: "Vi kom över havet",
        year: 2011,
        author: "Julie Otsuka",
        description:
          "I denna kollektivroman, där ordet förs av ett allomfattande vi, skildras hur unga japanskor i början av 1900-talet lockas till giftermål till USA. De blivande makarna är japanska män som tidigare emigrerat till det nya landet. I brev har de lockat kvinnorna med stiliga utseenden och löften om en bekymmersfri tillvaro. Den verklighet som de efter båtfärden kommer fram till är dock helt annorlunda. Kvinnornas enskilda öden återspeglar samhälleliga missförhållanden i världspolitikens skugga.",
      },
      {
        title: "Vinterflickor",
        year: 2024,
        author: "Laurie Halse Anderson",
        description:
          "En mycket stark skildring av hur självskadebeteende i olika former påverkar både kropp och själ hos unga människor så mycket att de kan dö. En berättelse om hur vänskap både kan vara stark och destruktiv. En tydlig och smärtsam inifrånskildring av hur den sjukas blick på sig själv ständigt förvanskas, så att spegelbilden för den sjuka visar något helt annat än det alla andra ser. Men trots allt det mörka är det också en skildring av att det finns hopp. Laurie Halse Anderson tilldelades ALMA-priset 2023.",
      },
    ],

    noveller: [
      {
        title: "Arvsled",
        year: 2020,
        author: "Annika Norlin",
        description:
          "Den ensamstående mamman Susanne arbetar inom hemtjänsten. Hon har en historia av våldsamma relationer bakom sig. Chanslös i livet, får hon höra, eftersom kvinnorna i hennes släkt alltid tycks välja fel män. Att dömas av andra men också av sig själv kan vara förlamande. Är det ens möjligt att bryta mönster? En berättelse om makt och frigörelse, med rikt språk och svart humor. Inger hopp om förändring och en annan framtid.",
      },
      {
        title: "Det tredje ljuset",
        year: 2021,
        author: "Claire Keegan",
        description:
          "En liten flicka skickas under några sommarmånader till fosterföräldrar som har en gård med åkermark och boskap i Irland. En hjärtskärande långnovell där ett barn får uppleva trygghet och glädje för första gången i sitt liv. Koncentrerat berättat om barnets utsatthet i en berättelse som kallas 'en nutida klassiker' på Irland.",
      },
      {
        title: "Dygdens lön",
        year: 1884,
        author: "August Strindberg",
        description:
          "Den unge Theodor försöker navigera livet och sin framväxande sexualitet i en repressiv tid. Både hans och hans jämnårigas tankar kretsar mest kring sex, men skolan och kyrkan predikar sträng avhållsamhet till och med i tanken. Theodor tar den påstådda synden och dygden på fullaste allvar. Han lider, skäms, och växer upp till en bruten man. Novellens föraktfulla skildring av Theodors konfirmation var orsaken till den så kallade Giftasprocessen, där Strindberg åtalades för hädelse men frikändes.",
      },
      {
        title: "Fettpärlan",
        year: 1880,
        author: "Guy de Maupassant",
        description:
          "En grupp borgare flyr den preussiska belägringen av den normandiska staden Rouen. I sällskapet finns den prostituerade Fettpärlan, en godhjärtad ung kvinna som fått sitt öknamn på grund av sin övervikt. Under flykten stoppas sällskapet av en preussisk officer som endast låter dem resa vidare om han först får ligga med Fettpärlan. Hon vägrar, till det övriga sällskapets allt större förtret. Guy de Maupassants debut är en vass klassiker om sex, status och grupptryck.",
      },
      {
        title: "Heder",
        year: 2011,
        author: "Jens Lapidus",
        description:
          "Miljön är som ofta i Lapidus författarskap Stockholms kriminella värld. Adam släpps från fängelset för ett brott där han vägrat avslöja identiteten på den verkligt ansvarige. När han kommer ut har han missat stora delar av sin sons uppväxt och är skuldsatt. Adam försöker ta sig upp från botten och tror han ska få stöd från mannen som egentligen borde ha dömts. Vad är heder? Och vad ska man med den till om man inte har något annat?",
      },
      {
        title: "I farmors hus",
        year: 1953,
        author: "Stig Dagerman",
        description:
          "En pojke vandrar runt i sin farmors hus och letar efter tystnaden, men hör bara ljudet av liv. Efter att i en dagdröm om döden råkat ha sönder sin sedan länge bortgångna farfars stövel känner pojken dock hur världen tystnar – en tystnad han själv måste fylla med lögner för att dölja sitt brott för farmodern. Dagermans novell är en gåtfull och melankolisk betraktelse av livet och döden, sanningen och lögnen. Den lämnar mycket öppet för tolkning och lämpar sig väl för en symbolisk läsning.",
      },
      {
        title: "Kappan",
        year: 1842,
        author: "Nikolaj Gogol",
        description:
          "Akakij Akakijevitj är en anspråkslös och fattig tjänsteman som lever för sitt arbete. När han låter sy upp en ny kappa får hans liv ny mening, men glädjen blir kortvarig. Vid ett rån blir kappan stulen och Akakij Akakijevitj förkyler sig och dör. Snart ryktas det på S:t Petersburgs gator om en vålnad som försöker rycka ytterkläderna av förbipasserande. Kappan har, med sin blandning av mörk realism, humor och övernaturliga inslag, haft stort inflytande på den ryska litteraturen.",
      },
      {
        title: "Kommer hem och är snäll",
        year: 1944,
        author: "Lars Ahlin",
        description:
          "Sören kommer hem berusad till Tyra, långt senare än han lovat. Tyra hatar sig själv för att hon inte klarar av att spela rollen som hunsad men lycklig hustru. Sören försöker intala både henne och sig själv att han är snäll som överhuvudtaget kommit hem. Trots allt kan de försonas i sin kärlek, även om bägge vet att samma konflikt ska återkomma gång på gång. Ahlins novell är en realistisk och nyanserad skildring av relationer, könsroller och kärlekens både förlåtande och destruktiva kraft.",
      },
      {
        title: "Kräftfångst",
        year: 2019,
        author: "Pooneh Rohi",
        description:
          "I lättläst jagform berättar en man om en natt när han, tillsammans med fru och dotter, ger sig ut för att fiska kräftor. I den stillsamma sensommarnatten går tankarna till barndomen i en helt annan miljö. Med finstämd melankoli skildras mellanförskap och längtan efter en självklar tillhörighet. Berättarjaget kan inte riktigt vila i det ursprungliga, men känner sig inte som en naturlig del av det nya. I stället försöker han ge sin dotter den tillhörighet han själv saknar, en plats att kalla sin egen.",
      },
      {
        title: "Till vår ära",
        year: 2001,
        author: "Alejandro Leiva Wenger",
        description:
          "Leiva Wengers debut väckte uppmärksamhet för sitt då nydanande språk och sina skildringar av förorten. I titelnovellen Till vår ära ska en mamma köpa mjölk, men det mest vardagliga blir oöverkomligt när både landet och språket är nya. Liksom övriga berättelser i samlingen utforskar denna korta och gripande novell gränser mellan olika kulturer och språk. Berättaren är ett ovanligt 'vi' som betraktar mamman på avstånd men också påverkar handlingen, vilket väcker många tankar. Till vår ära utsågs till 2023 års Stockholm läser-bok.",
      },
      {
        title: "Om vintern i de stora skogarna",
        year: 1910,
        author: "Dan Andersson",
        description:
          "Den gamle Yxsjö-Anders vandrar mödosamt genom den djupa skogen en stormig vinternatt. Nästintill ihjälfrusen når han hemmet och dör precis innanför tröskeln, en trots allt lycklig död eftersom han levt sitt liv i generositet och godhet. Novellen presenteras som en skröna, hoppysslad av rykten, drömmar och sådant som berättaren hört viskas av timret i stugväggar. Trots tematiken är den en av Dan Anderssons ljusare berättelser, präglad av hans tankar om människans nödvändiga försoning med döden.",
      },
      {
        title: "Pyrrhussegrar",
        year: 1886,
        author: "Mathilda Malling",
        description:
          "Den tjugofyraåriga Märta Ulfklo framlever sina sista dagar på en schweizisk kurort, dödssjuk efter att under hela sitt unga vuxenliv förnekat sin sexuella lust. Genom tillbakablickar visas tillfällen då Märta hade kunnat ge sig hän, men i stället lägger band på sig. Novellen ansågs av sin samtid mycket kontroversiell. Det som provocerade var skildringen av kvinnlig sexualitet som naturligt inneboende och av män som objekt för kvinnlig åtrå. Novellen gavs ut under pseudonymen Stella Kleve.",
      },
      {
        title: "På söndagar knullar man",
        year: 1979,
        author: "Vita Andersen",
        description:
          "På söndagar knullar man, tycker Kurt. De övriga dagarna måste man gå upp tidigt och jobba. Om man inte sjukskriver sig förstås. Flera gånger. Och sedan förlorar jobbet. Vita Andersens skildring av hur berättarjaget underordnar sig Kurts alla nycker är osentimental och humoristisk men inte utan en underliggande svärta.",
      },
      {
        title: "Ur mörkret",
        year: 1888,
        author: "Victoria Benedictsson",
        description:
          "En gammal kvinna berättar för första gången om sitt liv och hur det kringskurits av misogyni. Hon berättar om sin pappa för vilken hon från början varit en besvikelse på grund av sitt kön, om svikna kärlekar och om bitterheten i att ständigt föraktas av både sig själv och omgivningen. Benedictssons novell är en mörk men klarsynt uppgörelse som både ger inblick i 1800-talets kvinnoroll och har bäring än idag.",
      },
    ],

    poesi: [
      {
        title: "12 år",
        year: 2013,
        author: "Yahya Hassan",
      },
      {
        title: "Den enda stunden",
        year: 1833,
        author: "Johan Ludvig Runeberg",
      },
      {
        title: "Det naturliga urvalet (II)",
        year: 2024,
        author: "Malte Persson",
      },
      {
        title: "Det är vackrast när det skymmer",
        year: 1919,
        author: "Pär Lagerkvist",
      },
      {
        title: "Död amazon",
        year: 1942,
        author: "Hjalmar Gullberg",
      },
      {
        title: "En kärleksvisa",
        year: 1898,
        author: "Gustaf Fröding",
      },
      {
        title: "Gemenskap",
        year: 2012,
        author: "Lina Ekdahl",
      },
      {
        title: "Hey Joe",
        year: 2003,
        author: "Johannes Anyuru",
      },
      {
        title: "Ja visst gör det ont",
        year: 1935,
        author: "Karin Boye",
      },
      {
        title: "Jag tror",
        year: 1941,
        author: "Gunnar Ekelöf",
      },

      {
        title: "Jorden till Yosra",
        year: 2022,
        author: "Warsan Shire",
      },
      {
        title: "Till förruttnelsen",
        year: 1813,
        author: "Erik Johan Stagnelius",
      },
      {
        title: "Med den döda katten i baksätet",
        year: 2024,
        author: "Ingela Strandberg",
      },
      {
        title: "Min familj anlände hit i en marxistisk idétradition",
        year: 2013,
        author: "Athena Farrokhzad",
      },
      {
        title: "Putte är så underbar",
        year: 1983,
        author: "Kristina Lugn",
      },
      {
        title: "Snigeln",
        year: 1988,
        author: "Werner Aspenström",
      },
      {
        title: "Säg till om jag stör",
        year: 1984,
        author: "Eeva Kilpi",
      },
      {
        title: "Vierge moderne",
        year: 1916,
        author: "Edith Södergran",
      },
    ],
  },

  högstadiet: {
    romaner: [
      {
        title: "Agnes Cecilia - en sällsam historia",
        year: 1981,
        author: "Maria Gripe",
        description:
          "Nora är föräldralös och bor hos släktingar som också är hennes fosterföräldrar. I det gamla huset som Nora på något konstigt sätt känner sig hemma i börjar underliga saker att hända. Steg hörs, en bok ramlar ur bokhyllan och en klocka börjar gå baklänges. Något vill meddela sig med Nora, en historia vill bli berättad. Vad som kan tyckas vara en spökberättelse är också en berättelse om att komma till insikt om hur mättad och magisk verkligheten kan vara. Om man bara öppnar sina sinnen för det okända.",
      },
      {
        title: "Anne Franks dagbok",
        year: 1947,
        author: "Anne Frank",
        description:
          "Familjen Frank flyr till Holland när hotet från nazisterna blir alltför påträngande i Tyskland. När Tyskland invaderar Holland måste de fly igen, denna gång till en dold vind i ett hus i Amsterdam. Tillvaron i familjens gömställe skildras genom den trettonåriga dottern Annes dagbok. Under två år får vi ta del av familjens liv och Annes funderingar som tonåring. Dagboken avslutas två dagar innan familjen avslöjas och Anne förs bort till förintelselägret Bergen-Belsen. Där avlider hon ett halvt år senare.",
      },
      {
        title: "Barnens ö",
        year: 1976,
        author: "PC Jersild",
        description:
          "Det är tänkt att den snart elvaårige Reine ska tillbringa sommaren på kolonin Barnens ö i Stockholms skärgård, medan hans mamma reser för att arbeta på lasarettet i Uddevalla. Vad hans mamma inte vet är att Reine tänker strunta i att åka till kolonin och i stället bo ensam i lägenheten under sommaren. Under två sommarmånader utforskar Reine världen omkring sig och försöker lösa de stora livsfrågorna: Finns Gud? Vad är döden? Varför fortplantar vi oss på det sätt som vi gör?",
      },
      {
        title: "Brev till mamma",
        year: 2008,
        author: "Teresa Cárdenas",
        description:
          "En afro-kubansk tonårsflicka skriver brev till sin mamma som har dött. De korta breven återger en kärlekslös vardag fylld av rasism, misshandel och förtryck, men också av längtan och hoppfullhet. Brevformen gör boken lättläst men också tänkvärd och gripande. Berättelsen växer i tomrummet mellan breven och mellan raderna. Fin och kritisk skildring av den afro-kubanska befolkningens situation med magiska inslag från Yorubareligionen. En klassisk brevroman i kort format som kan inspirera till eget skrivande.",
      },
      {
        title: "Brun flicka drömmer",
        year: 2014,
        author: "Jacqueline Woodson",
        description:
          "Självbiografiskt om att växa upp som svart flicka i USA. Minnesbilder från familjens liv i Ohio och New York flätas samman med släktens historia till en berättelse om rätten att få existera som alla andra. En poetisk berättelse om att finna sin röst och bli författare. Levandegör medborgarrättsrörelsens kamp och hur rassegregering formade relationerna mellan svarta och vita på 1960-talet. ALMA-pristagare 2018.",
      },
      {
        title: "Carrie",
        year: 1974,
        author: "Stephen King",
        description:
          "Carries hämnd på sina plågoandar är en ryslig men också vemodig och mångtydig berättelse. Med sin gåtfulla kraft kan hon få saker att hända med blotta viljan. Skildringen av Carries upptäckt av vem hon är och vad hon kan göra, vävs samman med vetenskapliga rapporter och vittnesskildringar. Romanen liknar en fiktiv dokumentär som låter läsaren närma sig det som sker från olika håll. En inflytelserik debut som fortsätter att ställa oroande frågor: Vad är synd och hur ska vi bete oss mot våra medmänniskor? Klassiker i skräckgenren.",
      },
      {
        title: "En studie i rött",
        year: 1887,
        author: "Arthur Conan Doyle",
        description:
          '"Jag ser att ni varit i Afghanistan", säger Sherlock Holmes till doktor Watson första gången de möts. Senare får vi veta hur Sherlock Holmes med en oöverträffad slutledningsförmåga kan konstatera detta utan tidigare kännedom om Watson. En studie i rött är den inledande berättelsen om deckarparets många kriminalfall, nedtecknade av den observante men förundrade Watson. Här sätts standarden för den framtida kriminalromanen med en skarpsinnig och excentrisk privatdetektiv i huvudrollen.',
      },
      {
        title: "Flugornas herre",
        year: 1954,
        author: "William Golding",
        description:
          "Ett flygplan med skolpojkar störtar på en ö i Stilla havet. Inga vuxna överlever och pojkarna är strandade på vad som verkar vara ett paradis. Men frihetskänslan övergår snart till en mörk kamp för överlevnad. Skildringen av hur ondskan tränger fram bakom pojkarnas civiliserade yta sker mot bakgrund av andra världskriget och hotet från atombomben. Är människan i grunden ond och självisk eller god och omtänksam? Klassiker i traditionen efter Robinson Crusoe.",
      },
      {
        title: "Himlabrand",
        year: 2021,
        author: "Moa Backe Åstot",
        description:
          "Den sextonårige renskötaren Ánte i Jokkmokk blir kär i barndomsvännen Erik. Han söker på Flashback och undrar om det finns andra samer som blir kära i någon av samma kön. Boken väver samman de samiska traditionerna och språket med samtidens nya möjligheter till en varm kärlek som står helt på egna ben. Finkänsligt om hur två unga människor trevande och passionerat närmar sig varandra. Samtidigt en eldfängd uppgörelse med Sveriges koloniala historia.",
      },
      {
        title: "Hjärtans fröjd",
        year: 1992,
        author: "Per Nilsson",
        description:
          'I en berättartekniskt uppfriskande och medryckande historia får vi i nutid och i återblickar ta del av den "unge mannens" första stora kärlek, den rödhåriga "Hjärtans fröjd" som han ser för första gången på bussen till skolan. De tvära kasten mellan gränslös eufori och självmordsplanering skildras trovärdigt och med ett inbyggt spänningsmoment som gör att man fortsätter läsa. Går det att vara vänner om någon är kär i den andra?',
      },
      {
        title: "Inuti huvudet är jag kul",
        year: 2018,
        author: "Lisa Bjärbo",
        description:
          "Medryckande realistisk berättelse med populärkulturella referenser. Liv flyttar med sin pappa från Stockholm till ett fallfärdigt hus i Småland där en ny gymnasieskola och förhoppningsvis nya vänner väntar. Livs sociala pappa är hennes trygga punkt. Själv vill hon helst slippa all uppmärksamhet, och paniken kring sociala situationer ligger hela tiden nära. Med tiden får hon kontakt med nya människor som visar sig vara annorlunda än hennes gamla kompisar.",
      },
      {
        title: "Janne, min vän",
        year: 1985,
        author: "Peter Pohl",
        description:
          "Plötsligt dyker den rödhårige Janne upp från ingenstans och blir en del av gänget. När Krille är med Janne öppnas livet mot en spännande och farlig värld bortom skolans planlagda vardag. Berättelsen om Janne börjar med att han är försvunnen och eftersöks av polisen. Krille berättar vad han sett och vet om den mystiske kamraten. En spännande och gripande historia rullas upp. Klassisk ungdomsroman som skildrar Södermalm på 1950-talet, med inslag av Stockholmsslang.",
      },
      {
        title: "Kyrkdjävulen",
        year: 1994,
        author: "Mikael Niemi",
        description:
          "När klassens historielärare vid ett besök i kyrkan i Pajala visar upp den djävulsstatyett som förvaras där börjar oförklarliga saker hända. Huvudpersonen Matti och hans två vänner Simon och Malin dras in i en kedja av mer eller mindre övernaturliga och mystiska händelser. Vid sidan av huvudberättelsen får vi ta del av Mattis tankar om sitt förhållande till sitt ursprung och sin kulturella hemhörighet i Tornedalen, konkretiserat i frågan om han ska lära sig finska eller inte.",
      },
      {
        title: "Lila Hibiskus",
        year: 2003,
        author: "Chimamanda Ngozi Adichie",
        description:
          "Hur lever man under förtryck – i familjen, religionen och samhället – och hur kan man frigöra sig? I denna berättelse för den femtonåriga Kambili ordet. Hennes familj anförs av en far som i det yttre kritiserar det nigerianska styrelseskicket, men som i hemmet är en tyrann och religiös fanatiker. Vi får följa hur Kambili bokstavligen och bildligt kan börja skönja friheten och en ny framtid efter de mörka åren i ett postkolonialt Nigeria.",
      },
      {
        title: "Maresi. Krönikor från Röda klostret",
        year: 2014,
        author: "Maria Turtschaninoff",
        description:
          "Maresi lever i ett kloster på ön Menos tillsammans med flickor och kvinnor som har dragit sig undan männens värld. Hon tillbringar dagarna i klosterskolan och på biblioteket när hon inte arbetar med att skörda de dyrbara röda snäckorna på öns stränder. En dag anländer Jai som flyr från sin förtryckande far och det blir snart uppenbart att han är ute efter hennes liv. Övärldsfantasy som omsorgsfullt skapar en tät och suggestiv men också tankeväckande värld.",
      },
      {
        title: "Möss och människor",
        year: 1937,
        author: "John Steinbeck",
        description:
          "En klassiker om den lilla människans utsatthet. Två lantarbetare som lever från dag till dag. Lennie är stor och stark, men inte så begåvad. Han är snäll och vill väl, men det blir ofta ändå fel och tragedin är inte långt borta. George är Lennies enda vän och den som tar honom i försvar mot en oförstående omvärld. George är också den som berättar för Lennie om deras gemensamma dröm om en liten egen gård där Lennie ska få föda upp sina egna kaniner. John Steinbeck tilldelades Nobelpriset i litteratur 1962.",
      },
      {
        title: "När hundarna kommer",
        year: 2015,
        author: "Jessica Schiefauer",
        description:
          "När våldet tar över och styr unga människors tillvaro kan fruktansvärda saker hända. En ung människas liv släcks av ett oförsonligt och svårförståeligt hat. I efterdyningarna av ett mord förändras människors liv oåterkalleligt. Kärleken mellan två unga kämpar för att överleva, men oddsen för lycka är små. De destruktiva krafterna är så starka att de tar över även det allra vackraste. Det finns hopp, men det tar tid innan ljuset sakta kommer åter.",
      },
      {
        title: "Om detta talar man endast med kaniner",
        year: 2013,
        author: "Anna Höglund",
        description:
          "Bilderbok om en kanin med mycket känsliga sinnen. Att vara en kanin är att inte riktigt smälta in och det blir gärna fel när man försöker säga något som låter rätt. Bilderbokens olika uppslag formar sig till en berättelse om en rädd kanins vardag. Men tagna var för sig framstår de som poetiska visdomsord att utforska och fundera över. Bildernas referenser till andra kända konstverk ger en ytterligare dimension åt skildringen av utanförskap och gemenskap. Årets svenska bilderbok 2014.",
      },
      {
        title: "Pappan och havet",
        year: 1965,
        author: "Tove Jansson",
        description:
          'Det här är berättelsen om vad som händer när Muminpappan inte känner sig behövd. För att han ska kunna uppfylla den självpåtagna uppgiften att skydda och ta hand om sin familj måste de komma bort från den trygga Mumindalen. Familjen reser med den egna båten till en ö långt ute till havs. Vistelsen blir fylld av utmaningar och de hamnar till slut "i en trasslig härva av skrämsel". Berättelsen är på ytan måhända en saga om några egensinniga troll, men rymmer tankar, poesi och naturmystik som blir angelägna för oss alla.',
      },
      {
        title: "Stjärnlösa nätter",
        year: 2011,
        author: "Arkan Asaad",
        description:
          "Romanen bygger på författarens egna erfarenheter och skildrar hur den nittonårige Amàr pressas att gifta sig med sin kusin vid ett besök i Irak. Ett laddat vittnesmål om att slitas mellan familjens traditioner och sin egen individuella lycka. Språket är okonstlat och berättelsen gestaltar träffande hur svårt det är att göra rätt i en omöjlig situation. Boken fick Stiftelsen Teskedsordens bokpris 2011.",
      },
      {
        title: "Ingen normal flyttar hit",
        year: 2020,
        author: "Pär Sahlin",
        description:
          "Första boken om Joar och hans kompisar i det lilla samhället Sävaträsk. Lukas från Stockholm besöker på sommarlovet sin farfar och blir kompis med Joar. När Joar inte arbetar med sin epa är han nere på torget och tittar efter Minna. En samtida och engagerande skildring av kärlek och gemenskap – men också av närvarande och frånvarande föräldrar. Skildringen är psykologiskt trovärdig och lyckas bygga en levande värld i dett lättlästa formatet som läsaren gärna återvänder till.",
      },

      {
        title: "Tatueraren i Auschwitz",
        year: 2018,
        author: "Heather Morris",
        description:
          "Lale Sokolov har uppgiften att tatuera in nummer på sina medfångar i koncentrationslägret. För att hantera detta stänger han av sina känslor – tills han möter Gita. Mänsklighetens mörka och grymma historia skildras här, men också kärlekens kraft. Romanen är skriven i presens med ett enkelt språk. Den är baserad på en sann kärlekshistoria men ska inte läsas dokumentärt. Här väcks frågor om överlevnad, skuld och vad som är rätt och fel.",
      },
      {
        title: "Trollkarlen från Övärlden",
        year: 1968,
        author: "Ursula K. Le Guin",
        description:
          "Heden föds i en fattig familj på ön Gont och växer upp till den omtalade drakmästaren Ged. Berättelsen följer honom från magikerskolan på ön Roke till uppgörelsen med Skuggan i Övärldens utkant. Le Guins fantasivärld jämförs inte sällan med J. R. R. Tolkiens och utgör en milstolpe i traditionen före Harry Potter. Det är en poetisk och suggestiv skildring av hur Ged möter det onda som också är en mörkare del av honom själv. Till sin hjälp på resan har läsaren en detaljerad karta över Övärlden. Klassiker i fantasygenren.",
      },
      {
        title: "Vi ska ju bara cykla förbi",
        year: 2022,
        author: "Ellen Strömberg",
        description:
          "Kompisarna Mandy och Malin går i nian och är på väg mot något nytt. De rör sig från barndomens tråkiga vardag, ut mot det äventyr som den unga vuxenheten kan förväntas vara. Till bokens styrkor hör de ömsint tecknade personerna och skildringen av längtan och osäkerhet. Boken har ett rappt, humoristiskt och livsfilosofiskt jag-berättande, och formar sig till en protest mot alla påtvingade livsval. Augustpristagare 2022.",
      },
      {
        title: "Yani",
        year: 2022,
        author: "Nora Khalil",
        description:
          "När Amir får ett utvisningsbesked försöker vännerna Rayan och Caspian ordna så att han får stanna. Historien berättas av Rayan som håller noga koll på allt som sker runt omkring honom. Han stöttar sin klasskamrat Wahida vars bror blir brutalt ihjälskjuten med flera skott i benen, magen och huvudet. Men han har också en egen sorg att bära. Romanen talar emot förenklade skildringar av förorten med en tonsäkerhet som stannar kvar. En humoristisk men också gripande berättelse som tar ungdomars vardag på allvar.",
      },
    ],
    noveller: [
      {
        title: "14 februari",
        year: 2009,
        author: "Jonas Karlsson",
        description:
          "Gymnasieeleven Peter sitter på en lektion i svenska när elevkårsgruppen kommer in för att dela ut alla hjärtans dag-rosor. Representanterna från elevkåren läser upp namn efter namn på elever som får gå fram och ta emot sina rosor, men Peter ropas aldrig upp. Hur skapas och bevaras maktordningar på en skola? Hög igenkänningsfaktor i Jonas Karlssons novell som handlar om hierarkier, utanförskap, avund, vånda och vänskap.",
      },
      {
        title: "Att döda ett barn",
        year: 1948,
        author: "Stig Dagerman",
        description:
          "Stig Dagermans klassiska novell skrevs på beställning för att bilda opinion kring frågan om hastighetsbegränsningar i trafiken, något som vid tidpunkten avskaffats på svenska vägar. Ett kärlekspar är ute tidigt för en bilutflykt, en barnfamilj gör sina morgonbestyr. När mannen i hög fart kör förbi familjens hus krockar han med barnet som just skickats ut på ett ärende. Den dödliga olyckans för- och efterspel skildras ingående och brutalt drabbande ur alla inblandades perspektiv.",
      },
      {
        title: "Bastutronen",
        year: 2020,
        author: "Axel Åhman",
        description:
          "Komiskt och österbottniskt om manlighet. En ung man kliver in i en finsk simhallsbastu och slår sig av misstag ner på bastutronen, det vill säga platsen med tillgång till två ämbar och den enda skopan. Sedan följer en överhettad kamp mot de andra bastubadarna; äldre män med lång bastuvana och därmed hög status.",
      },
      {
        title: "Bortbytingen",
        year: 1915,
        author: "Selma Lagerlöf",
        description:
          "Ett bondpar blir bestulna på sin son av ett troll som lämnar sitt eget barn kvar. Mannen vill överge bortbytingen, men kvinnan kan inte förmå sig till det. Hon tar i stället hem trollungen och sköter den som sin egen, trots makens och tjänarnas växande hat mot både henne och bortbytingen. Det visar sig till sist att hennes godhet och offer är det enda som räddar sonen och gör att han kan återvända hem. Bortbytingen är en fantasieggande berättelse som dessutom ger inblick i svensk folktro. Selma Lagerlöf tilldelades Nobelpriset i litteratur 1909.",
      },
      {
        title: "Ett halvt ark papper",
        year: 1903,
        author: "August Strindberg",
        description:
          "En ung man i färd att flytta hittar en papperslapp med telefonnummer kvarglömd i hallen. Genom de nummer som står där återupplever han sitt liv tillsammans med den nyligen bortgångna Alice, via telefonnummer till bland annat blomsterbud, möbelhandlare, barnmorska och slutligen begravningsbyrån. Strindbergs novell är en gripande och förhållandevis lättläst kärlekshistoria med en nyskapande och originell form.",
      },
      {
        title: "Den sista vägen",
        year: 1947,
        author: "Rachel Korn",
        description:
          "Vems liv ska offras? Det är den brutala frågan en judisk familj ställs inför i ett tyskkontrollerat område 1942. Om de inte väljer en familjemedlem för deportation kommer nazisterna att föra bort hela familjen. Sömlöst får vi vandra mellan några i familjen och deras tankar om det omänskliga beslut de måste fatta.",
      },
      {
        title: "Den svarta katten",
        year: 1843,
        author: "Edgar Allan Poe",
        description:
          "Den svarta katten är en av världens mest lästa och älskade skräckhistorier. Från fängelset berättar en namnlös man om hur han i alkoholtriggade vansinnesutbrott mördat först sin katt och senare sin fru. Hans brott avslöjas av den nya, identiska katt som kommit för att hemsöka honom, en katt som kanske är ett förkroppsligande av hans samvete. Det är en kuslig och obehaglig berättelse, men också en psykologisk studie av en förövares drivkrafter och samvetskval.",
      },
      {
        title: "En sann historia",
        year: 2018,
        author: "Olga Tokarczuk",
        description:
          "En professor på en konferens i ett främmande land passar på att promenera i staden. På väg tillbaka till hotellet får han syn på en kvinna som faller och slår sig illa. När professorn försöker hjälpa kvinnan blir han misstagen för att vara någon han inte är. Han flyr och allt går fel. En berättelse om missförstånd och om hur vi betraktar den andre. Olga Tokarczuk fick Nobelpriset i litteratur 2018.",
      },
      {
        title: "Frisöndagen",
        year: 1938,
        author: "Harry Martinson",
        description:
          "I åtta år lovas en kvinna av sin familj en söndag fri från hushållsarbete och en resa ut på den landsbygd som hon efter ett liv i staden bara har en vag minnesbild av. Till sist gör mamman själv slag i saken och flyr plikterna för en dag. Men inte ens ensam på landet kan hon undkomma det mentala ok som tidens kvinnoroll utgör, både i familjen och i samhället. Genom skildringen av familjens självbedrägeri och mammans sviktande självkänsla väcker novellen frågor om hur vi bör behandla varandra. Harry Martinson tilldelades Nobelpriset i litteratur 1974.",
      },
      {
        title: "Juan-les-Pins",
        year: 2017,
        author: "Sandra Beijer",
        description:
          "Det är fyra månader kvar av årskurs åtta. De tre bästa vännerna ska äntligen få åka till franska rivieran. Drömmen är så stark att till och med den besvärliga skolgången blir uthärdlig. I väntan förvandlas klassrumsfönstren till ovala flygplansfönster och skolmatsalens potatismos till en sydfransk sandstrand. Bitterljuvt i jag-form med lätt och vackert språk om eskapism, vänskap och längtan efter att bli någon annan än den man är.",
      },
      {
        title: "Kyssen",
        year: 1907,
        author: "Hjalmar Söderberg",
        description:
          "En flicka och en pojke sitter bredvid varandra och fantiserar om att kyssas. Han är egentligen mer intresserad av en annan och rädd för vad som ska hända om de blir påkomna. Hon planerar i förväg hur hon kränkt ska reagera på att han tar sig sådana friheter. När kyssen väl kommer blir de i stället sittande länge och bekänner till sist sin eviga kärlek för varandra. Söderbergs klassiska novell är en rar och lättillgänglig kärlekshistoria med hög igenkänningsfaktor.",
      },
      {
        title: "Min son blir inte snickare",
        year: 1941,
        author: "Karin Boye",
        description:
          "Karl-Gunnar älskade träslöjden i lågstadiet men vantrivs i övrigt i skolan. När han några år senare får ett ärende till ett snickeri väcks längtan att få ägna sig åt träslöjden igen. Genom en framväxande vänskap med snickaren börjar Karl-Gunnar drömma om en ljusare framtid än den familjen stakat ut åt honom. Men omgivningen, med pappan i spetsen, förbjuder honom att överge studierna för ett praktiskt yrke. Boyes novell kretsar kring klass, drömmar och vikten av att själv få forma sitt liv.",
      },
      {
        title: "Svalor är idioter!",
        year: 1936,
        author: "Ivar Lo-Johansson",
        description:
          "I gränslandet mellan lek och sadism hittar en grupp statarbarn på olika sätt att plåga fåglar och insekter. En särskild fascination väcker svalorna, som vuxenvärlden hyser sådan vördnad för. Med stor omsorg bygger barnen falska svalbon, och när svalorna inte låter sig luras måste de tvingas. Svalor är idioter! är en del av Lo-Johanssons novellsvit om statarnas livsvillkor och behandlar teman som fattigdom och utsatthet, gruppdynamik och vad som väcker vår inneboende brutalitet.",
      },
      {
        title: "Vänner, ur Korridorer. 12 noveller om högstadiet",
        year: 2020,
        author: "Ester Roxberg",
        description:
          "Ella ser upp till sin kompis Kitty, som är rebellisk och inte backar för någon, inte ens de tuffa killarna på skolan. Under en husvagnssemester med Kitty, hennes mamma och mammans nya kille inser Ella att vännernas liv skiljer sig åt på fler sätt än hon trott. Fartfyllt om två flickors vänskap med det sociala arvet som fond.",
      },
    ],
    poesi: [
      {
        title: "C-dur",
        year: 1962,
        author: "Tomas Tranströmer",
      },
      {
        title: "Du har tappat ditt ord",
        year: 1933,
        author: "Nils Ferlin",
      },
      {
        title: "Elda under din vrede",
        year: 1979,
        author: "Ingrid Sjöstrand",
      },
      {
        title: "I rörelse",
        year: 1927,
        author: "Karin Boye",
      },
      {
        title: "Jag hade rena händer",
        year: 2016,
        author: "Ingvild Lothe",
      },
      {
        title: "Jorden kan du inte göra om",
        year: 1954,
        author: "Stig Dagerman",
      },
      {
        title: "pappa. du har ingenting att säga när du ringer",
        year: 2015,
        author: "Rupi Kaur",
      },
      {
        title: "Skräcken",
        year: 1944,
        author: "Anna Greta Wide",
      },
      {
        title: "Slutet och början",
        year: 1993,
        author: "Wislawa Szymborska",
      },
      {
        title: "Sommarräkning",
        year: 1966,
        author: "Sonja Åkesson",
      },
      {
        title: "Solnedgång på havet",
        year: 1883,
        author: "August Strindberg",
      },
      {
        title: "Spela kula",
        year: 1926,
        author: "Sten Selander",
      },
      {
        title: "Stjärnorna",
        year: 1916,
        author: "Edith Södergran",
      },
      {
        title: "Valtal",
        year: 2007,
        author: "Ulf Karl Olov Nilsson (UKON)",
      },
      {
        title: "Var inte rädd för mörkret",
        year: 1920,
        author: "Erik Blomberg",
      },
    ],
  },

  mellanstadiet: {
    romaner: [
      {
        title: "Akamella Andarnas rike",
        year: 2022,
        author: "Lowe Aspviken",
        illustrator: "Peter Bergting",
        description:
          "Spökhistoria från tornedalen som utspelar sig i början av 1900-talet. Syskonen Gustava och Georg bor i Stockholm men släkten kommer från Muodoslompolo i Tornedalen. När Georg oförklarligt försvinner börjar en lika spännande som kuslig historia. Berättelsen bygger på tornedalska myter och skapar realistisk närvarokänsla genom smidig användning av ord på minoritetsspråket meänkieli.",
      },
      {
        title: "Boken om allting",
        year: 2012,
        author: "Guus Kuijer",
        description:
          "Filosofisk kortroman som vill ge ett svar på vad som krävs för att en människa ska kunna bli lycklig. Den fantasifulle Thomas lever i en religiös familj som domineras av en våldsam pappa som misshandlar både honom och mamman. I berättelsen som utspelar sig i Nederländerna på 1950-talet skildras hur en ohållbar situation kan övervinnas genom humor och gemenskap. ALMA-pristagare 2012.",
      },
      {
        title: "Balladen om en bruten näsa",
        year: 2012,
        author: "Arne Svingen",
        description:
          "Bart växer upp i en utsatt del av stan. Han bor i en nedgången lägenhet och trapphuset är fullt med spår efter narkomaner. Mamman är alkoholiserad och har svårt att sköta både lägenhet och arbete. En väg ut ur mörkret öppnas av vännen Ada som kommer från en annan del av stan. Allt skildras från Barts livsbejakande och kloka perspektiv, med mycket humor och romanpersoner som lever kvar.",
      },
      {
        title: "Comedy Queen",
        year: 2018,
        author: "Jenny Jägerfeld",
        description:
          "Runt Sashas mamma var det nästan alltid ledsamt och till slut valde hon att ta sitt liv. Sasha sammanställer därför en lista på sju saker som hon måste göra för att inte bli som sin mamma. För läsaren blir det en känslomässig resa mellan skratt och gråt. Berättelsen skildrar den gropiga resan mot självkännedom och ett nytt förhållningssätt till sorg.",
      },
      {
        title: "Dödskallen",
        year: 2023,
        author: "Jon Klassen",
        description:
          "I denna bildroman i kortformat ger sig lilla Ottilia ut i den kalla skogen. Hon välkomnas in i ett hus där en ensam dödskalle huserar. Ottilia räddar sin nya vän från den som hemsöker honom – ett huvudlöst skelett! Denna sagolika spökhistoria berättas med korta sakliga meningar där det vibrerar under ytan.",
      },
      {
        title: "En liten bok om kärlek",
        year: 2015,
        author: "Ulf Stark",
        illustrator: "Ida Björs",
        description:
          "Att bokens huvudperson fick namnet Fred hjälpte föga; det blev krig ändå. I den ena vågskålen finns vinterkyla, mörkläggning, ransonering och en pappa som bevakar en gräns i norr. I den andra finns kamratskap, julgransljus, en säck med julmat och barnet i mammas mage.",
      },
      {
        title: "Ett hål",
        year: 2022,
        author: "Yael Frankel",
        description:
          "Bilderboken med ett riktigt hål i pärmen. Hålet är en viktig del av berättelsen. Det försvinner inte, det går inte att fylla med annat. Är hålet saknaden efter någon? Det är en sorglig plats, ändå tar sig berättaren dit varje dag – för det är också varmt och inspirerande, läkande och tryggt.",
      },
      {
        title: "Falafelflickorna",
        year: 2018,
        author: "Christina Wahldén",
        description:
          "Hawa drömmer om polishögskolan, behöver öva sig och startar en liten detektivbyrå. Det börjar alltså lekfullt, men snart ställs hon inför två svåra fall: En flicka som mot sin vilja ska giftas bort och två flickor som hotas av anhöriga för att de är kära i varandra. Boken tar upp hur fall av detta slag ska hanteras, liksom information om barns rättigheter.",
      },
      {
        title: "Fågeln i mig flyger vart den vill",
        year: 2017,
        author: "Sara Lundberg",
        description:
          "I denna biografi berättas i poetiska texter och bilder om den jämtländska konstnären Berta Hansson (1910–1994). Ur hennes flickjags perspektiv gestaltas livet på bondgården, och konstnärsdrömmen. Den enda som riktigt förstår Berta dör i TBC. Hur kan Bertas konstnärsdröm leva vidare?",
      },
      {
        title: "Hajtänder",
        year: 2022,
        author: "Anna Woltz",
        description:
          "Kortroman om elvaåriga Atlanta som hanterar vardagens små och stora problem genom att ge sig ut på en cykeltur runt sjön Ijsselmeer – om hon inte klarar de 360 kilometrarna kommer allt att gå fel. På cykelturen möter hon Finley som lämnat sitt hem efter ett bråk med sin mamma.",
      },
      {
        title: "Jefferson",
        year: 2018,
        author: "Jean-Claude Mourlevat",
        description:
          "Igelkotten Jefferson anklagas för att ha mördat sin frisör som är grävling. Jefferson och kamraten Gilbert anar att det är en människa som är skyldig och ger sig av till deras land för att lösa brottet. Boken är en smart blandning av fabel och deckare, förvånansvärt realistisk, våldsam och humoristisk. ALMA-pristagare 2021.",
      },
      {
        title: "Kattvinden",
        year: 2018,
        author: "Helena Öberg",
        illustrator: "Kristin Lidström",
        description:
          "I denna bildroman möter läsaren två flickor i ett nutidsplan och två pojkar i historisk tid. Genom händelser i ett hus bildas sprickor mellan tidsplanen. De leder fram till ett både faktiskt och existentiellt utforskande för personerna. Texten är lättläst, och i långa avsnitt bär endast de vidunderligt flödande illustrationerna fram historien.",
      },
      {
        title: "Kulla-Gulla",
        year: 1945,
        author: "Martha Sandwall-Bergström",
        description:
          "I Småland vid 1900-talets början har en föräldralös flicka auktionerats ut till den lägstbjudande och blivit tjänstehjon hos en barnrik torparfamilj. Så småningom visar det sig att flickan är barnbarn till patron på herrgården som torpet lyder under. Nu ställs hon inför ett svårt etiskt dilemma.",
      },
      {
        title: "Kensukes rike",
        year: 2001,
        author: "Michael Morpurgo",
        illustrator: "Michael Foreman",
        description:
          "Den tolvårige Michael ramlar av föräldrarnas båt när de seglar över Korallhavet norr om Australien. Han flyter i land på en ö och möter en skeppsbruten japan vid namn Kensuke. Äventyrlig skildring om att finna förankringspunkter utanför familjen och bortom västerlandet.",
      },
      {
        title: "Legenden om Sally Jones",
        year: 2008,
        author: "Jakob Wegelius",
        description:
          "Läsaren får följa gorillan Sally Jones på hennes livsresa, från födseln i den afrikanska regnskogen, via Istanbul, Trakien och Makedonien, till Port Said, Sydkinesiska sjön, Borneo och Amerika. I bild och text skildras äventyrets exotiska magi, över hav och land, längs prickade linjer på omsorgsfullt gjorda kartor.",
      },
      {
        title: "Matilda",
        year: 1988,
        author: "Roald Dahl",
        illustrator: "Quentin Blake",
        description:
          "Matilda växer upp i en familj som inte bryr sig om henne. I skolan möter hon en drakliknande rektor som gör saker och ting ännu värre. Men det visar sig att hon är en flicka med oanade krafter. Fantasifullt och festligt om att finna sin inre kraft och övervinna sina fiender.",
      },
      {
        title: "Min mamma är en ängel",
        year: 2020,
        author: "Gunilla Lundgren, Le Glaténge Pen Club",
        illustrator: "Amanda Eriksson",
        description:
          "Här möter vi Angelica som bor i ett radhus i Sverige och Flori som kommer från Rumänien. Bägge talar romska dialekter, men Flori bär konstiga kläder, har för stora skor och hennes mamma är en ängel och pappan försvunnen.",
      },
      {
        title: "Nattkorpen",
        year: 2021,
        author: "Johan Rundberg",
        description:
          "En natt lämnas ett nyfött barn till ett barnhus i 1800-talets Stockholm. I centrum för denna historiska spänningsroman om de mest utsatta människorna, särskilt barnen, står ett udda par. Det är barnhusflickan Mika och polisen Hoff som tillsammans ska lösa gåtan.",
      },
      {
        title: "Odysseus pojke",
        year: 2020,
        author: "Annika Thor",
        illustrator: "Ishtar Bäcklund Dakhil",
        description:
          "Här berättas Odysseus äventyr ur hans väntande – och växande – son Thelemakos perspektiv. På hans ö Ithaka finns det trygga med vaggsånger och lekar, men också den oroande maktkampen.",
      },
      {
        title: "Ronja Rövardotter",
        year: 1981,
        author: "Astrid Lindgren",
        illustrator: "Ilon Wikland",
        description:
          "Det börjar med en åsknatt, och en flicka som föds i en borg i skogen. Lika starka och mäktiga som åskknallarna kommer känslorna mellan flickan och hennes far, rövarhövdingen, att bli. Men även känslorna mellan flickan och sonen i den konkurrerande rövarätten.",
      },
      {
        title: "Sen kom vintern",
        year: 2019,
        author: "Per Gustavsson",
        description:
          "Den ena pärmens insida täcks av brun päls, den andra av vit – och däremellan händer det! I spegeln upptäcker haren ett vitt strå i sin päls. Snart är hela han vit och världen utanför likaså! Men haren vill att allt ska vara som vanligt och köper inte de förklaringar som björnen presenterar.",
      },
      {
        title: "Tordyveln flyger i skymningen",
        year: 1978,
        author: "Maria Gripe",
        description:
          "I denna roman, ett slags fantastisk realism, erbjuds tre småländska ungdomar ett sommarjobb som handlar om att vattna blommor i byns äldsta hus. Mycket snart ställs de inför gåtor och dras in i händelser som öppnar kanaler till gångna tider och fjärran länder.",
      },
      {
        title: "Veras krig",
        year: 2022,
        author: "Katarina Kuick",
        description:
          "Boken skildrar belägringen av Leningrad under andra världskriget. Det är ingen hjältehistoria utan en skildring av vad som sker med en stad som belägras i 872 dagar. Handlingen kretsar runt besväret med att få tag i mat och klara vardagen.",
      },
      {
        title: "Vitsippor och pissråttor",
        year: 2023,
        author: "Oskar Kroon",
        illustrator: "Hanna Klinthage",
        description:
          "Kaj och hans äldre bror Krister bor med sin pappa. Kaj ser upp till Krister som kan mycket och vågar vara sig själv. Men i skolan förföljs Krister av Sacke, och Kaj bestämmer sig för att hjälpa till.",
      },
    ],
    noveller: [
      {
        title: "Anrop från inre rymden",
        year: 2017,
        author: "Elin Nilsson",
        description:
          "Här har åtta jag-berättelser om flickor och pojkar på gränsen mellan barndom och ungdom samlats. De består av korta episoder där tankar och associationer vecklar ut sig – som anrop från inre rymder.",
      },
      {
        title: "Det osynliga barnet och andra berättelser",
        year: 1962,
        author: "Tove Jansson",
        description:
          "Noveller som förtjänar att bli lästa flera gånger av såväl barn som vuxna. Mest känd är titelnovellen, om Ninni som en mörk och regnig kväll kommer till Muminfamiljens hus. Hon har blivit osynlig efter att ha bott hos en ironisk tant som inte tycker om henne.",
      },
      {
        title: "Framtid. 11 berättelser för mellanstadiet",
        year: 2020,
        author: "Red: Katja Tydén",
        description:
          "Novellsamling om framtiden, som vi egentligen inte vet något bestämt om, vare sig den ligger nära eller långt borta. Här finns en dystopisk novell om Eltons födelsedag i en framtid där regnet tagit över och solen aldrig tittar fram.",
      },
      {
        title: "Skräck. Elva rysare för mellanstadiet",
        year: 2017,
        author: "Red: Olivia Demant",
        description:
          "Elva författare bjuder på lika många rysare. Dessa varierar i omfång, och några drar åt det muntliga medan andra är mer litterära. Spänning skapas enligt ett klassiskt paradigm: natt i stället för dagsljus, fullmåne i stället för nymåne, en djup tjärn i stället för en sjö.",
      },
      {
        title: "Tolv",
        year: 2023,
        authors: ["Lisa Bjärbo", "Johanna Lindbäck", "Sara Ohlsson"],
        description:
          "Tolv berättelser om tolv personer från samma klass vars liv korsar varandra och flätas samman. Novellerna handlar bland andra om Fatima som inte kommer överens med sin mamma, Melvin som flyttar runt på olika jour- och familjehem och Husse som kan gå hur långt som helst när han är tillsammans med Molly.",
      },
    ],
    poesi: [
      {
        title: "Folk. Främlingar och vänner - nån du kanske känner!",
        year: 2019,
        authors: ["Emma Adbåge", "Lisen Adbåge"],
        description:
          "En människas relationer till 'folk' kan se mycket olika ut. Folk kan vara någon man bara mött – eller någon man fött! Dikterna i denna samling handlar om stora och små, om den blyga och de mer spralliga, och om de som gillar starka kryddor eller bara vill fika.",
      },
      {
        title: "Livet, ju. En samling dikter",
        year: 2023,
        author: "Mårten Melin",
        illustrator: "Sofia Falkenhem",
        description:
          "Precis som livet är denna diktsamling fylld med känslor av olika slag och magnitud. Dikterna är hämtade ur författarens tidigare produktion och organiserade i fem teman, från 'Jag' till 'På liv och död'.",
      },
      {
        title: "Livet och lite annat. Verser om stort och smått",
        year: 2022,
        author: "Lena Sjöberg",
        description:
          "Detta är en samling illustrerade dagsverser med rim och rytm och ett rikt innehåll. Bokens omslag med ett huvud vars hår formar sig till en trädkronas grenar, ger en antydan om innehållets riktning och spännvidd.",
      },
      {
        title: "Regn",
        year: 2018,
        author: "Anders Holmer",
        description:
          "I denna bilderbok med en haikuliknande dikt på varje uppslag görs nedslag på olika platser på jorden. Den gemensamma nämnaren är regn, men inte bara i form av vatten utan också frön, sand och blommor.",
      },
      {
        title: "Tid för poesi",
        year: 2024,
        authors: ["Ann Boglind", "Anna Nordlund"],
        publisher: "Svenska Akademien",
        description:
          "Med denna diktsamling vill Svenska Akademien sprida glädjen att läsa litteratur tillsammans. Samlingen utgörs av dikter från 1600-talet och framåt. Här är både Göran Greider och Erik Gustaf Geijer representerade, liksom Laleh Pourkarim och Henry Parland.",
      },
    ],
  },

  lågstadiet: {
    romaner: [
      {
        title: "Alla går iväg",
        year: 2015,
        author: "Eva Lindström",
        description:
          "Frank känner sig ensam, inte minst när han ser hur kul Titti, Palle och Milan har det. Han går hem, och där gråter han tårar i kastrullen. Sedan kokar han marmelad av dem och bjuder de tre andra på te och rostat bröd. Texten i denna bilderbok är kortfattad, men i illustrationerna finns ledtrådar som kan kasta ljus över relationen mellan Frank och de andra.",
      },
      {
        title: "Blå ugglan",
        year: 2023,
        author: "Lotta Geffenblad",
        description:
          "Ett mörkt ämne i en blåskimrande bok. Granlund och Stenberg bor i en liten stuga tillsammans. Så dör Stenberg, och Granlund gråter floder. Plötsligt dyker en gigantisk blå uggla upp, flyttar in i stugan och deltar i rutinerna. En bok om sorgens olika faser.",
      },
      {
        title: "Brorsans kompis Robban",
        year: 2024,
        author: "Maja Hjertzell",
        description:
          "Det bullrar om denna korta kapitelbok! Vi möter jagberättaren Jim, åtta år, hans äldre bror Rasmus och deras bästa kompisar Torgny och Robban. Bakom bullret förs ett lågmält samtal om de större pojkarnas 'skojbråk' och 'tvärtomspråk' och om vem som bestämmer i de mindre pojkarnas lek.",
      },
      {
        title: "Bröderna Lejonhjärta",
        year: 1973,
        author: "Astrid Lindgren",
        description:
          "Skorpan är mycket svårt sjuk men tröstas av sin storebror Jonatans sagor om Nangijala där de en dag ska mötas. Berättelsen handlar om liv och död, ont och gott, rädsla och mod. Den är lika mycket en skildring med psykologisk botten som en saga med mytisk kraft.",
      },
      {
        title: "Dagens Harri",
        year: 2013,
        author: "Susanna Alakoski",
        description:
          "I denna barnroman möter vi Tiia vars mamma är död, men som tillsammans med sin pappa bildar ett slags utökad familj med kamraten Peggy och hennes mamma. De två flickorna delar både vardag och fest – och allergiska problem.",
      },
      {
        title: "Din tur, Adrian",
        year: 2015,
        authors: ["Helena Öberg", "Kristin Lidström"],
        description:
          "Titeln anspelar på en klassrumssituation; det är den tioårige Adrians tur att läsa högt och bokstäverna bara hoppar omkring. Men här finns också hans tursamma – och sagolika – möten med en hund och hennes människa.",
      },
      {
        title: "Ett rum till Lisen",
        year: 2021,
        authors: ["Elin Johansson", "Emma Svedjeland", "Emma Adbåge"],
        description:
          "I en by med tre hus bor Naima, nio år. Det är sommarlov, hennes kompis är bortrest men i grannhuset flyttar Lisen in. De två inleder ett projekt som går ut på att sova i husets alla rum och betygsätta dem.",
      },
      {
        title: "Frekes fot",
        year: 2023,
        author: "Pija Lindenbaum",
        description:
          "Freke vaknar på morgonen och upptäcker att både hans ena sko och fot är borta. Ramberättelsen handlar om hans envetna letande efter dem. Det här är en bok som väcker tankar och reflektioner kring funktionsnedsättning och om att förlora något, men ändå gå vidare i livet.",
      },
      {
        title: "Förr i tiden i skogen",
        year: 1993,
        authors: ["Thomas Tidholm", "Anna-Clara Tidholm"],
        description:
          "Denna bilderbok tar oss till tiden före urbaniseringen, precis när den allmänna skolplikten blir verklighet. I en stuga i skogen bor Natan, en liten pojke som vill till skolan utan att veta vad skola är.",
      },
      {
        title: "Haj-Jenny",
        year: 2017,
        authors: ["Lisa Lundmark", "Charlotte Ramel"],
        description:
          "Här erbjuds vi att dela Jennys perspektiv. Hon är tystlåten och trivs med sina egna tankar och intressen. Därmed uppfyller hon inte skolans krav på att ta plats, räcka upp handen och uttrycka sig muntligt.",
      },
      {
        title: "Historien om Bodri",
        year: 2019,
        authors: ["Hédi Fried", "Stina Wirsén"],
        description:
          "Detta är en högläsningsbok som baseras på författarens erfarenheter av nazismen. I centrum står två systrar. Berättelsen handlar om hur de skiljs från sin kamrat, sin hund och sina föräldrar.",
      },
      {
        title: "Ingen utom jag",
        year: 2024,
        author: "Sara Lundberg",
        description:
          "'Mamma, vet du?' säger pojken på badplatsen. 'Du är bryggan och jag är båten.' Han lyckas knyta upp knuten mellan dem och med sin båt ge sig in i ett grönt skimmer och sedan, ordlöst, vidare jorden runt.",
      },
      {
        title: "Jag gör dig frisk, sa lilla Björn",
        year: 2004,
        author: "Janosch",
        description:
          "I denna rikt illustrerade barnbok möter vi förmänskligade djur i en lantlig trakt. Lilla Tiger har blivit sjuk och lilla Björn lovar att hjälpa honom. Ja, hela vänkretsen sluter upp runt den sjuke.",
      },
      {
        title: "Jag heter Beata",
        year: 2018,
        authors: ["Katarina Kieri", "Anna Sandler"],
        description:
          "Beata är åtta år. Hon besöker gärna sin mormor i Tornedalen men kan inte kommunicera så bra med henne. Mormor talar ju finska, eller meänkieli som man säger idag.",
      },
      {
        title: "Jag och alla",
        year: 2019,
        authors: ["Ylva Karlsson", "Sara Lundberg"],
        description:
          "På varje uppslag i denna bilderbok presenteras ett barn i text och bild. Texterna inleds med 'Jag är...' följt av barnets namn, några upplysningar om personen och en fundering på något annat barn.",
      },
      {
        title: "Jordgubbsbarnen",
        year: 2019,
        author: "Sara Olausson",
        description:
          "I ett litet hus i en by bor ett par syskon. Det blir sommarlov med både glädjeämnen och arbete med att samla skrot. Så sker en olycka, ekonomin förvärras och deras föräldrar måste ge sig av till det avlägsna Sverige för att arbeta som jordgubbsplockare.",
      },

      {
        title: "Katitzi; Katitzi och Swing",
        year: 2015,
        authors: ["Katarina Taikon", "Joanna Hellgren"],
        description:
          "Denna volym innehåller de två första delarna i den klassiska serien om den romska flickan Katitzi. I första delen introduceras den sjuåriga huvudpersonen som länge bott på barnhem men nu flyttar till sin familj i ett tältläger. Samtidigt med läsaren lär Katitzi känna familjen och de nya villkoren.",
      },
      {
        title: "Lilli, farfar och norrskenet",
        year: 2022,
        authors: ["Elin Marakatt", "Anita Midbjer"],
        description:
          "I Sápmi är det eftermiddag och redan mörkt. Hemma gråter lillebror och föräldrarna tröstar och tröstar. Lilli smyger ut från huset och följer med farfar för att mata renarna i stället. Plötsligt syns ett norrsken på himlen, fladdrande i grönt och lila. Boken finns på en rad olika språk, bland annat svenska, finska, meänkieli och samiska språk.",
      },
      {
        title: "Mitt bottenliv. Av en ensam axolotl",
        year: 2020,
        author: "Linda Bondestam",
        description:
          "Det finns en dubbelhet redan i titeln på denna sällsynt färgsprakande bilderbok. Den lille axolotlen ser ut som en sagofigur men existerar, dock akut utrotningshotad. Den berättar om sitt liv men hjälper också läsaren att få syn på 'lunsarnas' liv, det vill säga människornas, och hur detta påverkar livsmiljön.",
      },
      {
        title: "Nidstången. PAX",
        year: 2014,
        authors: ["Åsa Larsson", "Ingela Korsell"],
        illustrator: "Henrik Jonsson",
        description:
          "PAX-serien består av tio delar och inleds med Nidstången. Här presenteras väktarna som ska skydda det magiska biblioteket under kyrkan i Mariefred. Två bröder ska hjälpa dem. Detta urbana fantasy-epos är som gjort för att skapa bokslukare skolade i fornnordisk mytologi.",
      },
      {
        title: "Presenten",
        year: 2023,
        authors: ["Emma Virke", "Emelie Östergren"],
        description:
          "Denna säregna bilderbok inleds med Billys födelsedag i vår egen tid, men den tar även med läsaren till två andra tider. Detta görs med hjälp av greppet 'bok i bok i bok', och genom faktiska hål kan karaktärerna röra sig mellan böckerna och förenas.",
      },
      {
        title: "Rymlingarna",
        year: 2018,
        authors: ["Ulf Stark", "Kitty Crowther"],
        description:
          "Bokens illustrationer fångar författarens sätt att kombinera vemod och humor. Huvudpersonen Lill-Gottfrid försöker uppfylla sin hjärtsjuke farfars önskningar. De handlar dels om att ta honom till det hus som han en gång byggt och bott i med farmor, dels om att få honom att sluta svära och börja tala vackert.",
      },
      {
        title: "Sagan om det röda äpplet",
        year: 1974,
        author: "Jan Lööf",
        description:
          "Hur kommer det sig att det gröna plastäpplet förvandlades till ett riktigt saftigt rött äpple? Varje nytt uppslag ger en ny oväntad vändning i den här berättelsen. De små detaljerna bildar en underbar helhet och avslöjar ibland så mycket mer än vad texten berättar. En barnboksklassiker!",
      },
      {
        title: "Stora boken om Sandvargen",
        year: 2006,
        authors: ["Åsa Lind", "Kristina Digman"],
        description:
          "Här har de tre böckerna om Sandvargen samlats. I centrum står Zackarina som bor med sina föräldrar i ett hus vid havet. Där, på stranden, har hon funnit en sällsynt sandvarg som är lekfull, vis och som alltid har tid. Bokens kapitel är korta men kan fungera som utgångspunkt för filosofiska samtal utan slut.",
      },
      {
        title: "Såret",
        year: 2022,
        author: "Emma Adbåge",
        description:
          "Det saknas inte dramatik när berättarjaget ramlar på skolgården och slår upp ett sår på knät. När den värsta smärtan gått över inträder en känsla av utvaldhet och till och med upphöjelse. Här bjuds det på ett mycket fint samspel mellan text och bild.",
      },
      {
        title: "Två av allt",
        year: 2020,
        authors: ["Rose Lagercrantz", "Rebecka Lagercrantz"],
        description:
          "Lulu säger att det man inte kan prata om, måste man skriva – och att barn både förstår och vill veta! Så börjar hennes man Eli skriva ner de minnen som utgör bokens handling: Elis och Lulus barndom i Transsylvanien, Lulus avsked, nazismen som smyger sig på, tiden i koncentrationsläger, åren i Sverige och resan över till USA.",
      },
      {
        title: "Vem ska trösta knyttet",
        year: 1960,
        author: "Tove Jansson",
        description:
          "Ett mycket ensamt troll, ett knytt, ger sig ut på en äventyrlig färd men är alldeles för blyg för att knyta kontakter med andra. Väl framme vid havet tar allt en vändning, när ett skrutt behöver stöd och hjälp av en vän. Berättelsen är en modern klassiker som är fylld av starka känslor.",
      },
    ],
    abcBocker: [
      {
        title: "ABC",
        year: 1961,
        authors: ["Lennart Hellsing", "Poul Ströyer"],
        description:
          "På varje uppslag presenteras en bokstav, såväl versal som gemen, i en specifik färg. Här finns också en vers, färgglatt och fyndigt illustrerad, som oftast inleds med namnet på aktuell bokstavsperson.",
      },
      {
        title: "ABC-resan",
        year: 1945,
        author: "Elsa Beskow",
        description:
          "A som i Apelsin, B som i Banan och C som i Cyklar. När Anna och Bo ska ut på sin bildningsresa i alfabetets tecken följer dockan med.",
      },
      {
        title: "Livets ABC",
        year: 2020,
        author: "Lisen Adbåge",
        description:
          "Parallellen mellan livet och alfabetet är själva utgångspunkten – båda har en början och ett slut! Livslinjen och bokstäverna läggs här sida vid sida och tvinnas på ett produktivt sätt samman.",
      },
      {
        title: "Sportig ABC-bok",
        year: 2020,
        authors: ["Åsa Sandell", "Malin Norlander"],
        description:
          "I denna ABC-bok möter vi bara flickor som presenteras med sportanknytning, till exempel Armbrytar-Anna och Brottar-Britta.",
      },
      {
        title: "Sportig ABC-bok igen!",
        year: 2021,
        authors: ["Åsa Sandell", "Annabella Gustafsson"],
        description:
          "I denna ABC-bok möter vi bara pojkar som presenteras med sportanknytning, som Aikido-Alf och Balett-Bilal.",
      },
    ],
    noveller: [
      {
        title:
          "Ovetenskapliga studier. Nio bevis för saker som förmodligen aldrig har hänt",
        year: 2023,
        authors: ["Emma Karinsdotter", "Marcus-Gunnar Pettersson"],
        description:
          "Kan det finnas bevis för något som aldrig har hänt? Ja, kanske är de nio novellerna i denna tankeutmanande samling just detta – och därmed också ett försvar för litteratur som 'skön lögn'.",
      },
      {
        title: "Pappan som försvann och andra berättelser för barn och vuxna",
        year: 2003,
        author: "Anna-Clara Tidholm",
        description:
          "Denna samling rymmer ett slags sagor för vår tid, mer eller mindre realistiska. De befolkas av barn och vuxna som ibland tycks vilja göra uppror mot ideal och förväntningar.",
      },
      {
        title: "Sunnanäng",
        year: 1959,
        authors: ["Astrid Lindgren", "Ilon Wikland"],
        description:
          "Orden 'för länge sen i fattigdomens dagar' inleder berättelserna Sunnanäng, Spelar min lind, sjunger min näktergal, Tu tu tu! och Junker Nils av Eka. Samtliga utgår från en bristsituation.",
      },
    ],
    poesi: [
      {
        title: "Djur som ingen sett utom vi",
        year: 2016,
        authors: ["Ulf Stark", "Linda Bondestam"],
        description:
          "De korta verserna ger en glimt av en rad okända djur. Ändå verkar de bekanta – som Nomadinen med sina ständigt nya bon, Klumpantropusen, trött och tung, eller Gulalan med sina vingar och sin halsballong.",
      },
      {
        title: "En bro av poesi",
        year: 2021,
        authors: ["Ann Boglind", "Anna Nordlund"],
        description:
          "Med denna diktsamling för barn vill Svenska Akademien sprida glädjen att läsa och skriva. Här finns sextio dikter av äldre och nyare poeter som Almqvist och Adbåge, Blomberg och Boyacioglu samt Heidenstam och Hellsing.",
      },
      {
        title: "Halsen rapar, hjärtat slår - rim för 0-100 år!",
        year: 2016,
        authors: ["Emma Adbåge", "Lisen Adbåge"],
        description:
          "När två bild- och ordmänniskor skapar en diktbok tillsammans blir resultatet alldeles eget. Men med motiven, rytmen och rimmen ingår boken samtidigt i en tradition där vi finner Barbro Lindgren och Lennart Hellsing.",
      },
      {
        title: "Ljusligheter",
        year: 2023,
        authors: ["Hanna Lundström", "Maija Hurme"],
        description:
          "Det här är en diktsamling fylld av ljus, och det står att finna i de mest skiftande sammanhang. I både dikt och bild, i bilderboksformat, får läsaren möta ett skimmer i körsbärsträdens blommor.",
      },
      {
        title: "Under ett rabarberblad",
        year: 2016,
        author: "Lena Sjöberg",
        description:
          "I poeten Lena Sjöbergs verser kan det finnas både stort och smått, men även liv och död. Med naturen som fond träder stora skeenden fram, med få ord i dikterna.",
      },
    ],
  },
};

export default booksData;
