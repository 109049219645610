import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  Tooltip,
  FormControl,
  Select,
  MenuItem,
  Grid,
  TextField,
  FormControlLabel,
  Switch,
  Paper,
  Divider,
} from "@mui/material";

import GenerateIcon from "@mui/icons-material/AutoAwesome";

import PrintIcon from "@mui/icons-material/Print";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import AddonTimelineSimple from "./AddonTimelineSimple";
import HistoryIcon from "@mui/icons-material/History";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import LetterContent from "./LetterContent";
import ShareLink from "./ShareLink";
import TimelineSimpleDisplay from "./TimelineSimpleDisplay";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import TimelineDisplay from "./TimelineDisplay";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import AddonTimeline from "./AddonTimeline";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import { useParams } from "react-router-dom";
import DateRangeIcon from "@mui/icons-material/DateRange";
import PersonIcon from "@mui/icons-material/Person";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import AddIcon from "@mui/icons-material/Add";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import LessonQuizDisplay from "./LessonQuizDisplay";
import AddonQuiz from "./AddonQuiz";
import ReadingComprehensionDialog from "./ReadingComprehensionDialog";
import DashedPapers from "./DashedPapers";
import LessonExercisesDisplay from "./LessonExercisesDisplay";
import ImageIcon from "@mui/icons-material/Image";
import ImageGenerationModal from "./ImageGenerationModal";
import HoverableImage from "./HoverableImage";

import PrintAsPdf from "./PrintAsPdf";
import SlideInPaper from "./SlideInPaper";
import Translate from "./Translate";

import { callApi } from "./api";
import { db } from "./firebase";
import {
  collection,
  query,
  where,
  limit,
  addDoc,
  updateDoc,
  getDocs,
  serverTimestamp,
} from "firebase/firestore";

import Logger from "./Logger";
import LanguageSelector from "./LanguageSelector";

import { getThemes } from "./additionalThemes/LetterLesson";
import { ThemeSelectorFlow } from "./ThemeSelectorFlow";
import InfoBox from "./InfoBox";
import { handlePrintLetterLesson } from "./printFunctions";

import { generateAndProcessImage } from "./functions";
import ShareDialog from "./ShareDialog";
import ShareIcon from "@mui/icons-material/Share";
import LoadingBackdrop from "./LoadingBackdrop";
import { useSnackbar } from "./SnackbarHandler";
import ProgressBar from "./ProgressBar";
import LessonDrawer from "./LessonDrawer";
import SaveLessonDialog from "./SaveLessonDialog";
import EditIcon from "@mui/icons-material/Edit";
import { DashedPaper } from "../StyledComponents";
import { getStyles } from "../index";
import { trackLesson, handleImageGenerationError } from "./functions";
import GradeSelector, { gradeInstructions } from "./GradeSelector";
import { useTheme } from "@mui/material/styles";
import { useSearchParams } from "react-router-dom";

import DiaryContent from "./DiaryContent";

const LettersLesson = ({ user }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);
  const lessonTitleRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageInstructions, setImageInstructions] = useState("");
  const [isGeneratingImages, setIsGeneratingImages] = useState(false);
  const [loadingImageIndex, setLoadingImageIndex] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [isSharing, setIsSharing] = useState(false);
  const quizSectionRef = useRef(null);
  const exercisesSectionRef = useRef(null);
  const timelineSectionRef = useRef(null);
  const urlContentRef = useRef("");
  // Basic settings
  const [lessonTheme, setLessonTheme] = useState("");
  const [language, setLanguage] = useState("svenska");
  const [grade, setGrade] = useState("åk9");
  const [numOfDiaryEntries, setNumOfDiaryEntries] = useState(5);
  const [diaryEntryLength, setDiaryEntryLength] = useState("Långa");

  // Generation states
  const [progress, setProgress] = useState(0);
  const [isGeneratingLesson, setIsGeneratingLesson] = useState(false);
  const [lessonData, setLessonData] = useState("");

  const translateButtonRef = useRef(null);
  const pdfButtonRef = useRef(null);
  const shareLinkButtonRef = useRef(null);
  const [isExpanded, setIsExpanded] = useState(false);

  // References for content
  const [customTheme, setCustomTheme] = useState("");
  const [predefinedTheme, setPredefinedTheme] = useState(null);
  const subject = searchParams.get("subject") || null;
  const viewMode = searchParams.get("v") === "t";
  const additionalThemes = getThemes(subject);
  const { showSnackbar } = useSnackbar();
  const theme = useTheme();
  const styles = getStyles(theme);
  const [showControlPanel, setShowControlPanel] = useState(false);

  const transcriptRef = useRef("");
  const uploadedFileRef = useRef("");
  const bookContentRef = useRef("");
  const wikipediaContentRef = useRef("");
  const randomizedContentRef = useRef("");
  const coursePlanContentRef = useRef("");

  // Add refs for the quiz and comprehension buttons
  const quizButtonRef = useRef(null);
  const timelineButtonRef = useRef(null);
  const comprehensionButtonRef = useRef(null);
  const timelineSimpleButtonRef = useRef(null);
  const timelineSimpleSectionRef = useRef(null);
  const [shareDialogOpenFriends, setShareDialogOpenFriends] = useState(false);

  // Add click handlers for the DashedPaper components

  const handleTimelinePaperClick = () => {
    timelineButtonRef.current?.click();
  };

  const handleQuizPaperClick = () => {
    quizButtonRef.current?.click();
  };

  const handleTimelineSimplePaperClick = () => {
    timelineSimpleButtonRef.current?.click();
  };

  const handleComprehensionPaperClick = () => {
    comprehensionButtonRef.current?.click();
  };

  const { lessonId } = useParams();

  useEffect(() => {
    Logger.log("lessonData: ", JSON.stringify(lessonData, null, 2));
  }, [lessonData]);

  useEffect(() => {
    const loadLessonFromId = async () => {
      if (lessonId) {
        try {
          const q = query(
            collection(db, "lesson_usage"),
            where("__name__", ">=", lessonId),
            where("__name__", "<=", lessonId + "\uf8ff"),
            where("lessonType", "==", "LettersLesson.js"),
            limit(1),
          );

          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            const docData = querySnapshot.docs[0].data();

            if (!docData.hasOpened) {
              await updateDoc(querySnapshot.docs[0].ref, {
                hasOpened: true,
                openedAt: Date.now(),
              });
            }

            setLessonData(docData.data);

            // Scroll to lesson content
            setTimeout(() => {
              lessonTitleRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }, 100);

            showSnackbar("Lektionen har laddats in.", "success");
          } else {
            showSnackbar("Kunde inte hitta lektionen", "error");
          }
        } catch (error) {
          console.error("Error loading lesson:", error);
          showSnackbar("Ett fel uppstod när lektionen skulle laddas", "error");
        }
      }
    };

    loadLessonFromId();
  }, lessonId);

  useEffect(() => {
    Logger.log("lessonData: ", lessonData);
    const isDataValid =
      lessonData !== null &&
      lessonData !== undefined &&
      Object.keys(lessonData).length > 0 &&
      !Object.values(lessonData).includes("");

    if (isDataValid !== showControlPanel) {
      const timer = setTimeout(() => {
        setShowControlPanel(isDataValid);
      }, 100);

      return () => clearTimeout(timer);
    }
  }, [lessonData, showControlPanel]);

  const handleShare = async (
    lessonComment,
    lessonName,
    selectedImage,
    tags,
    shareData,
  ) => {
    setIsSharing(true);
    await new Promise((resolve) => setTimeout(resolve, 1500));
    // try {
    //   // Create a document for each student
    //   const sharePromises = shareData.studentIds.map((studentId) => {
    //     const documentData = {
    //       userId: user.uid,
    //       shared: true,
    //       sharedTo: studentId,
    //       comment: shareData.comment,
    //       sharedAt: Date.now(),
    //       fileUrl: shareData.fileUrl,
    //       lessonType: "LettersLesson.js",
    //       timestamp: serverTimestamp(),
    //       lessonComment,
    //       lessonName,
    //       coverImage: selectedImage,
    //       hasPrinted: true,
    //       data: lessonData,
    //     };
    //     Logger.log(
    //       "Adding document for student",
    //       studentId,
    //       ":",
    //       JSON.stringify(documentData, null, 2),
    //     );
    //     return addDoc(collection(db, "lesson_usage"), documentData);
    //   });
    //   await Promise.all(sharePromises);
    //   showSnackbar(`Du har nu delat lektionen!`, "success");
    // }

    try {
      // Create a document for each student and then create notifications
      const sharePromises = shareData.studentIds.map(async (studentId) => {
        const documentData = {
          userId: user.uid,
          shared: true,
          sharedTo: studentId,
          comment: shareData.comment,
          sharedAt: Date.now(),
          fileUrl: shareData.fileUrl,
          lessonType: "LettersLesson.js",
          timestamp: serverTimestamp(),
          lessonComment,
          lessonName,
          coverImage: selectedImage,
          hasPrinted: true,
          data: lessonData,
        };
        // Create lesson document and get ID
        const lessonDoc = await addDoc(
          collection(db, "lesson_usage"),
          documentData,
        );
        Logger.log(
          "Adding document for student",
          studentId,
          ":",
          JSON.stringify(documentData, null, 2),
        );
        // Create notification with lesson doc ID
        await addDoc(collection(db, "notifications"), {
          type: "lesson_shared",
          recipientId: studentId,
          senderId: user.uid,
          read: false,
          createdAt: serverTimestamp(),
          lessonData: {
            lessonId: lessonDoc.id,
            lessonType: documentData.lessonType,
            lessonName: documentData.lessonName,
            fileUrl: documentData.fileUrl,
            comment: documentData.comment,
            coverImage: selectedImage,
          },
        });
        return lessonDoc;
      });
      await Promise.all(sharePromises);
      showSnackbar(`Du har nu delat lektionen!`, "success");
    } catch (error) {
      showSnackbar("Fel vid delande av lektionen: " + error.message, "error");
      console.error(error);
    } finally {
      setIsSharing(false);
    }
  };

  const handleSaveLessonClick = () => {
    setSaveDialogOpen(true);
  };

  const handleSaveWithComment = async (comment, name, coverImage, tags) => {
    await trackLesson(
      user.uid,
      "LettersLesson.js",
      lessonData,
      null,
      true,
      comment,
      name,
      coverImage,
      tags,
    );
    setDrawerOpen(true);
    showSnackbar("Lektionen sparad i din lektionsbank!", "success");
  };

  const toggleViewMode = () => {
    const currentParams = Object.fromEntries(searchParams.entries());
    if (currentParams.v === "t") {
      delete currentParams.v;
    } else {
      currentParams.v = "t";
    }
    setSearchParams(currentParams);
  };

  const simulateProgress = (startProgress, endProgress, duration) => {
    const stepSize = 0.5;
    const steps = (endProgress - startProgress) / stepSize;
    const stepDuration = duration / steps;
    let currentProgress = startProgress;

    const intervalId = setInterval(() => {
      currentProgress += stepSize;
      if (currentProgress >= endProgress) {
        clearInterval(intervalId);
      } else {
        setProgress(currentProgress);
      }
    }, stepDuration);

    return () => clearInterval(intervalId);
  };

  const handleGenerateLesson = async () => {
    setProgress(0);
    let stopProgressSimulation = null;
    setIsGeneratingLesson(true);
    const gradeInstructionsText = gradeInstructions[grade];
    setLessonData(null);

    try {
      let themeToUse;

      if (lessonTheme === "youtube") {
        themeToUse = transcriptRef.current;
      } else if (lessonTheme === "customTheme") {
        themeToUse = customTheme;
      } else if (lessonTheme === "predefined") {
        themeToUse = predefinedTheme;
      } else if (lessonTheme === "fileUpload") {
        themeToUse = uploadedFileRef.current;
      } else if (lessonTheme === "wikipedia") {
        themeToUse = wikipediaContentRef.current;
      } else if (lessonTheme === "randomizer") {
        themeToUse = randomizedContentRef.current;
      } else if (lessonTheme === "book") {
        themeToUse = bookContentRef.current;
      } else if (lessonTheme === "url") {
        themeToUse = urlContentRef.current;
      } else {
        themeToUse = coursePlanContentRef.current;
      }

      stopProgressSimulation = simulateProgress(0, 90, 15000);

      let response = await callApi(
        [
          {
            role: "system",
            content: `You are an expert historian and creative writer specializing in crafting authentic-feeling letter correspondences between two people. 
      The letters should feel personal and engaging while maintaining accuracy to the specified theme or historical context. 
      Follow the exact JSON structure provided in the prompt.`,
          },
          {
            role: "user",
            content: `
            - Theme: "${themeToUse}"
            - Number of letters: Exactly ${numOfDiaryEntries}
            - Average length of each letter: 15 sentences
            - Length of each letter: ${diaryEntryLength}
            - Language: ${language}

            RESPOND IN THIS EXACT JSON FORMAT:
            {
              "title": "title of correspondence",
              "correspondence": [
                {
                  "sender": "string",
                  "recipient": "string",
                  "date": "YYYY-MM-DD",
                  "content": "string"
                }
              ]
            }
            `,
          },
        ],
        6000,
        true,
        user.uid,
      );

      if (stopProgressSimulation) stopProgressSimulation();
      setProgress(95);

      let messageContent = response.data.choices[0].message.content.trim();
      if (messageContent.startsWith("```json")) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith("```")) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      const data = JSON.parse(messageContent);
      setProgress(100);
      setLessonData({
        ...data,
        correspondence: data.correspondence.map((entry) => ({
          ...entry,
          imageUrl: null,
        })),
      });

      Logger.log("response: ", JSON.stringify(lessonData, null, 2));

      await trackLesson(user.uid, "LettersLesson.js", null, {
        language,
        lessonTheme,
      });

      showSnackbar("Din lektion har genererats!", "success");
    } catch (error) {
      console.error("Error generating lesson:", error);
      showSnackbar(
        "Ett fel uppstod när lektionen skulle genereras. Försök igen!",
        "error",
      );
    } finally {
      setIsGeneratingLesson(false);
    }
  };

  const handleGenerateImages = async () => {
    if (!lessonData) return;
    setIsGeneratingImages(true);
    handleCloseModal();

    try {
      const imagePromises = lessonData.correspondence.map((letter) => {
        const prompt = `Create a photorealistic image depicting this : ${letter.content.slice(0, 300)}.Do NOT generate images of letters but rather the emotion the content is expression. ${imageInstructions}`;
        return generateAndProcessImage(prompt, user.uid);
      });

      const results = await Promise.allSettled(imagePromises);

      setLessonData((prevData) => ({
        ...prevData,
        correspondence: prevData.correspondence.map((letter, index) => ({
          ...letter,
          imageUrl:
            results[index].status === "fulfilled" ? results[index].value : null,
          imageError:
            results[index].status === "rejected"
              ? results[index].reason?.message || "Failed to generate image"
              : null,
        })),
      }));

      // Count successful and failed generations
      const successful = results.filter((r) => r.status === "fulfilled").length;
      const failed = results.filter((r) => r.status === "rejected").length;

      // Show appropriate message based on results
      if (failed === 0) {
        showSnackbar("Bilderna har genererats!", "success");
      } else if (successful === 0) {
        showSnackbar("Kunde inte generera några bilder.", "error");
      } else {
        showSnackbar(
          `${successful} bilder genererades. ${failed} misslyckades.`,
          "warning",
        );
      }
    } catch (error) {
      handleImageGenerationError(error, true, showSnackbar);
    } finally {
      setIsGeneratingImages(false);
      setLoadingImageIndex(null);
    }
  };

  const handleGenerateSpecificImage = async (index) => {
    if (!lessonData?.correspondence[index]) return;
    setIsGeneratingImages(true);
    setLoadingImageIndex(index);
    handleCloseModal();
    try {
      const letter = lessonData.correspondence[index];
      const prompt = `Create a photorealistic image depicting this  ${letter.content.slice(0, 300)}. Do NOT generate images of letters but rather the emotion the content is expression.  ${imageInstructions}`;
      const imageUrl = await generateAndProcessImage(prompt, user.uid);
      if (imageUrl) {
        setLessonData((prevData) => ({
          ...prevData,
          correspondence: prevData.correspondence.map((letter, i) =>
            i === index ? { ...letter, imageUrl } : letter,
          ),
        }));
        showSnackbar("Bilden har genererats!", "success");
      }
    } catch (error) {
      handleImageGenerationError(error, false, showSnackbar);
    } finally {
      setIsGeneratingImages(false);
      setLoadingImageIndex(null);
    }
  };
  const handleImageButtonClick = (index = null) => {
    setSelectedImageIndex(index);
    setShowImageModal(true);
  };

  const handleCloseModal = () => {
    setShowImageModal(false);
    setImageInstructions("");
  };

  const handleGenerateWithInstructions = async () => {
    if (selectedImageIndex !== null) {
      handleCloseModal();
      await handleGenerateSpecificImage(selectedImageIndex);
    } else {
      handleCloseModal();
      await handleGenerateImages();
    }
  };

  return (
    <Box sx={styles.container}>
      <Box
        sx={{
          ...styles.innerContainer,
          width: { xs: "100%", md: "100%" },
          margin: "0 auto",
        }}
      >
        {!viewMode && (
          <Typography
            variant="h1"
            gutterBottom
            sx={{
              fontWeight: "bold",
              fontSize: "1.8em",
              mb: 3,
              mt: 5,
              textAlign: "center",
            }}
          >
            Fiktiv brevkorrespondens
          </Typography>
        )}
        <Box
          sx={{
            ...styles.buttonsContainer,
            ...styles.additionalButtonsContainer,
          }}
        >
          {lessonData && user.accountType === "teacher" && (
            <Button
              variant="purp"
              sx={{ ...styles.tightButton }}
              disableRipple
              onClick={() => {
                setTimeout(() => {
                  lessonTitleRef.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "start", // This ensures it scrolls to the top of the element
                  });
                }, 100);
                toggleViewMode();
              }}
              startIcon={
                searchParams.get("v") === "t" ? (
                  <FullscreenExitIcon />
                ) : (
                  <FullscreenIcon />
                )
              }
            >
              {searchParams.get("v") === "t" ? "Redigeringsläge" : "Elevläge"}
            </Button>
          )}

          {!viewMode && (
            <Button
              variant="purp"
              sx={{ ...styles.tightButton }}
              onClick={() => setDrawerOpen(true)}
              startIcon={<BookmarkBorderIcon />}
            >
              Sparat
            </Button>
          )}
        </Box>

        <LessonDrawer
          userId={user.uid}
          lessonType={"LettersLesson.js"}
          theme={theme}
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          lessonIcon={NewspaperIcon}
          onSelectLesson={(lesson) => {
            setLessonData(lesson.data);
            setDrawerOpen(false);
            setTimeout(() => {
              lessonTitleRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "start", // This ensures it scrolls to the top of the element
              });
            }, 100);
            showSnackbar(`Lektionen har laddats in.`, "success");
          }}
        />

        {!viewMode && (
          <>
            <InfoBox
              icon={ForwardToInboxIcon}
              title="Fiktiva brevväxlingar"
              instructions={[
                "Generera en fiktiv brevkorrespondens från t.ex. två historisk personer från olika tidsperioder",
                "Redigera, generera bilder och lägg till övningar efter eget behov",
                "Dela med elever, spara eller skriv ut",
              ]}
              theme={theme}
              styles={styles}
            />

            <ThemeSelectorFlow
              key={subject}
              subject={subject}
              value={lessonTheme}
              onChange={(e) => setLessonTheme(e.target.value)}
              customTheme={customTheme}
              onCustomThemeChange={(e) => setCustomTheme(e.target.value)}
              onPredefinedThemeChange={(e) =>
                setPredefinedTheme(e.target.value)
              }
              onBookContentChange={(content) => {
                bookContentRef.current = content;
              }}
              onUrlContentChange={(content) => {
                Logger.log(
                  "Parent: URL content received",
                  content.substring(0, 300),
                );
                urlContentRef.current = content;
              }}
              onYoutubeTranscriptChange={(transcriptContent) => {
                transcriptRef.current = transcriptContent;
              }}
              onFileUpload={(content) => {
                uploadedFileRef.current = content;
              }}
              onWikipediaContentChange={(content) => {
                wikipediaContentRef.current = content;
              }}
              onRandomizedContentChange={(content) => {
                randomizedContentRef.current = content;
              }}
              onCoursePlanContentChange={(content) => {
                coursePlanContentRef.current = content;
              }}
              lessonType={"LettersLesson.js"}
              user={user}
              theme={theme}
              styles={styles}
              onLessonSelect={(lesson) => {
                Logger.log("onLessonSelect körs");
                setLessonData(lesson.data);
                setTimeout(() => {
                  lessonTitleRef.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "start", // This ensures it scrolls to the top of the element
                  });
                }, 100);
                showSnackbar("Lektionen har laddats in.", "success");
              }}
              additionalThemes={additionalThemes}
            />

            <Box sx={styles.headlineLessonBox}>
              <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
                2. Generera lektionen
              </Typography>
            </Box>

            <Box sx={{ ...styles.freshBox, padding: "40px" }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "500", mb: 1 }}
                  >
                    Årskurs:
                  </Typography>
                  <GradeSelector grade={grade} setGrade={setGrade} />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "500", mb: 1 }}
                  >
                    Språk:
                  </Typography>
                  <LanguageSelector
                    language={language}
                    setLanguage={setLanguage}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <Typography
                      variant="formLabelSinUnderline"
                      sx={{ fontWeight: "500", mt: 1 }}
                    >
                      Antal brev
                    </Typography>
                    <Select
                      labelId="num-questions-label"
                      id="num-questions-select"
                      value={numOfDiaryEntries}
                      sx={{ mt: 1 }}
                      onChange={(e) => setNumOfDiaryEntries(e.target.value)}
                    >
                      <MenuItem value={2} selected>
                        2
                      </MenuItem>
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={5}>7</MenuItem>

                      {user.admin && <MenuItem value={10}>10</MenuItem>}
                      <Tooltip title="Endast tillgängligt för premium" arrow>
                        <span>
                          <MenuItem value={15} disabled>
                            15
                          </MenuItem>
                          <MenuItem value={20} disabled>
                            20
                          </MenuItem>
                          <MenuItem value={30} disabled>
                            30
                          </MenuItem>
                          <MenuItem value={40} disabled>
                            40
                          </MenuItem>
                          <MenuItem value={50} disabled>
                            50
                          </MenuItem>
                        </span>
                      </Tooltip>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl fullWidth sx={{ mt: 1 }}>
                    <Typography
                      variant="formLabelSinUnderline"
                      sx={{ fontWeight: "500" }}
                    >
                      Längd på breven
                    </Typography>
                    <Select
                      sx={{ mt: 1 }}
                      value={diaryEntryLength}
                      onChange={(e) => setDiaryEntryLength(e.target.value)}
                    >
                      <MenuItem value="Korta">Korta</MenuItem>
                      <MenuItem value="Medellånga">Medellånga</MenuItem>
                      <MenuItem value="Långa">Långa</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "12px",
                  width: "100%",
                }}
              >
                <Button
                  variant={
                    theme.palette.mode === "light" ? "purp" : "contained"
                  }
                  color="primary"
                  onClick={handleGenerateLesson}
                  disabled={isGeneratingLesson}
                  sx={{ mt: 7 }}
                  startIcon={
                    !isGeneratingLesson ? (
                      <GenerateIcon style={{ color: "#fff" }} />
                    ) : (
                      <CircularProgress size={16} style={{ color: "#fff" }} />
                    )
                  }
                >
                  {isGeneratingLesson ? "Genererar..." : "Generera lektion"}
                </Button>
              </div>
              {isGeneratingLesson && (
                <Box sx={{ mt: 6 }}>
                  <ProgressBar
                    progress={progress}
                    label="Genererar lektionplan — tar 30-60 sekunder..."
                  />
                </Box>
              )}
            </Box>
          </>
        )}

        {lessonData && (
          <>
            {!viewMode && (
              <Box
                ref={lessonTitleRef}
                sx={{ ...styles.headlineLessonBox, mt: 10 }}
              >
                <Typography variant="h6" sx={styles.headlineLessonBoxTitle}>
                  3. Din lektion
                </Typography>
              </Box>
            )}
            <Box
              sx={{
                ...styles.freshBox,
                padding: "30px",

                width: viewMode ? "70%" : undefined,
                margin: viewMode ? "0 auto" : undefined,
                marginTop: viewMode ? "100px" : undefined,
              }}
            >
              {!viewMode && (
                <Box sx={{ position: "relative" }}>
                  <Box
                    sx={{
                      ...styles.lessonControlPanel,
                      ...styles.lessonControlPanelNuevo,

                      "& .MuiButton-root": {
                        ...styles.controlPanelButtons,
                        ...styles.lessonControlPanelNewButtons,
                      },
                    }}
                  >
                    <Button
                      variant="success"
                      sx={{
                        mt: 2,
                        ml: 2,
                        ...(isEditMode && {
                          color: "green !important",
                          "&.MuiButton-root": {
                            fontWeight: "700 !important",
                          },
                        }),
                      }}
                      onClick={() => setIsEditMode(!isEditMode)}
                      startIcon={isEditMode ? <SaveAsIcon /> : <EditIcon />}
                    >
                      {isEditMode ? "Spara ändringar" : "Redigera breven"}
                    </Button>

                    <Button
                      variant="print"
                      startIcon={<SaveAsIcon />}
                      sx={{ mt: 2, ml: 2 }}
                      onClick={handleSaveLessonClick}
                    >
                      Spara i min lektionsbank
                    </Button>
                    <Button
                      variant="print"
                      startIcon={<PrintIcon />}
                      sx={{ mt: 2, ml: 0 }}
                      onClick={() => handlePrintLetterLesson(lessonData)}
                    >
                      Skriv ut
                    </Button>

                    <Button
                      variant="success"
                      sx={{
                        mt: 2,
                        ml: 2,
                      }}
                      onClick={() => setShareDialogOpen(true)}
                      startIcon={<ShareIcon />}
                    >
                      Dela med elever
                    </Button>

                    <Button
                      variant="success"
                      sx={{}}
                      onClick={() => setShareDialogOpenFriends(true)}
                      startIcon={<ShareIcon />}
                    >
                      Dela med vänner
                    </Button>
                    <ShareLink
                      buttonRef={shareLinkButtonRef}
                      key={lessonId}
                      lessonId={lessonId}
                      lessonData={lessonData}
                      user={user}
                      lessonType={"LettersLesson.js"}
                      onCopy={(message, severity) =>
                        showSnackbar(message, severity)
                      }
                      sx={{ mt: 2 }}
                    />
                    <PrintAsPdf
                      buttonRef={pdfButtonRef}
                      onPrint={() => handlePrintLetterLesson(lessonData)}
                      sx={{ ml: 2 }}
                    />

                    <Translate
                      buttonRef={translateButtonRef}
                      lessonData={lessonData}
                      onTranslated={setLessonData}
                      callApi={callApi}
                      user={user}
                      showSnackbar={showSnackbar}
                    />

                    <Tooltip title="Hur lektionen ser ut för eleverna">
                      <Button
                        variant="success"
                        onClick={() => {
                          window.scrollTo({ top: 0, behavior: "smooth" });
                          toggleViewMode();
                        }}
                        sx={{ ml: 2, mt: 2 }}
                        startIcon={
                          searchParams.get("v") === "t" ? (
                            <FullscreenExitIcon />
                          ) : (
                            <FullscreenIcon />
                          )
                        }
                      >
                        {searchParams.get("v") === "t"
                          ? "Redigeringsläge"
                          : "Elevläge"}
                      </Button>
                    </Tooltip>

                    <Box sx={{ display: "inline-block" }}>
                      <AddonQuiz
                        buttonRef={quizButtonRef}
                        lessonData={lessonData}
                        title={lessonData.title}
                        theme={theme}
                        user={user}
                        styles={styles}
                        showSnackbar={showSnackbar}
                        addQuizToLessonObject={(updatedData) => {
                          setLessonData(updatedData);
                          setTimeout(
                            () =>
                              quizSectionRef.current?.scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                              }),
                            100,
                          );
                        }}
                        buttonText="Lägg till interaktivt quiz"
                      />
                    </Box>
                    <Box sx={{ display: "inline-block", ml: 0 }}>
                      <ReadingComprehensionDialog
                        buttonRef={comprehensionButtonRef}
                        key={lessonData?.title}
                        lessonData={lessonData}
                        user={user}
                        theme={theme}
                        styles={styles}
                        buttonText="Lägg till övningar"
                        addExercisesToLessonObject={(updateFunction) => {
                          setLessonData((prevLessonData) => ({
                            ...prevLessonData,
                            exercises: [
                              ...(prevLessonData.exercises || []),
                              ...updateFunction(prevLessonData).exercises,
                            ],
                          }));
                          setTimeout(
                            () =>
                              exercisesSectionRef.current?.scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                              }),
                            100,
                          );
                        }}
                      />
                    </Box>
                    <Box sx={{ display: "inline-block", ml: 0 }}>
                      <AddonTimeline
                        buttonRef={timelineButtonRef}
                        lessonData={lessonData}
                        theme={theme}
                        user={user}
                        styles={styles}
                        showSnackbar={showSnackbar}
                        addTimelineToLessonObject={(updatedData) => {
                          setLessonData(updatedData);
                          setTimeout(
                            () =>
                              timelineSectionRef.current?.scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                              }),
                            100,
                          );
                        }}
                        buttonText="Lägg till interaktiv tidslinje"
                      />
                    </Box>
                    <Box sx={{ display: "inline-block", ml: 0 }}>
                      <AddonTimelineSimple
                        buttonRef={timelineSimpleButtonRef}
                        lessonData={lessonData}
                        theme={theme}
                        user={user}
                        styles={styles}
                        showSnackbar={showSnackbar}
                        addTimelineToLessonObject={(updatedData) => {
                          setLessonData(updatedData);
                          setTimeout(
                            () =>
                              timelineSimpleSectionRef.current?.scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                              }),
                            100,
                          );
                        }}
                        buttonText="Lägg till enkel tidslinje"
                      />
                    </Box>
                    <Button
                      variant={
                        theme.palette.mode === "light" ? "purp" : "contained"
                      }
                      color="primary"
                      onClick={() => handleImageButtonClick(null)}
                      disabled={isGeneratingImages}
                      startIcon={
                        !isGeneratingImages ? (
                          <ImageIcon />
                        ) : (
                          <CircularProgress size={16} />
                        )
                      }
                      sx={{ mt: 2 }}
                    >
                      {isGeneratingImages
                        ? "Genererar bilder..."
                        : "Generera bilder"}
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      ...styles.lessonControlPanelFade,
                    }}
                  />
                </Box>
              )}

              <LetterContent
                lessonData={lessonData}
                setLessonData={setLessonData}
                isEditMode={isEditMode}
                theme={theme}
                styles={styles}
                isGeneratingImages={isGeneratingImages}
                loadingImageIndex={loadingImageIndex}
                handleImageButtonClick={handleImageButtonClick}
                user={user}
                showSnackbar={showSnackbar}
              />

              {lessonData?.timelinesSimple && (
                <Box
                  ref={timelineSimpleSectionRef}
                  sx={{ mt: 5, mb: 5, position: "relative" }}
                >
                  <TimelineSimpleDisplay
                    timelineData={lessonData.timelinesSimple}
                    onTimelineDataChange={(newData) =>
                      setLessonData({ ...lessonData, timelinesSimple: newData })
                    }
                    theme={theme}
                    styles={styles}
                    viewMode={viewMode}
                    user={user}
                  />
                </Box>
              )}

              {/* Add this where you want to display the timeline */}
              {lessonData?.timelines && (
                <Box
                  ref={timelineSectionRef}
                  sx={{ mt: 5, mb: 5, position: "relative" }}
                >
                  <TimelineDisplay
                    timelineData={lessonData.timelines}
                    onTimelineDataChange={(newData) =>
                      setLessonData({ ...lessonData, timelines: newData })
                    }
                    theme={theme}
                    styles={styles}
                    withImages={true}
                    viewMode={viewMode}
                    user={user}
                    onDelete={
                      viewMode
                        ? undefined
                        : (indexToDelete) => {
                            setLessonData((prev) => ({
                              ...prev,
                              timelines: prev.timelines.filter(
                                (_, index) => index !== indexToDelete,
                              ),
                            }));
                            showSnackbar(
                              "Tidslinjen har tagits bort",
                              "success",
                            );
                          }
                    }
                  />
                </Box>
              )}
              {lessonData?.exercises && (
                <Box ref={exercisesSectionRef}>
                  <LessonExercisesDisplay
                    exercises={lessonData.exercises}
                    onUpdateLessonData={setLessonData}
                    viewMode={viewMode}
                    theme={theme}
                    styles={styles}
                  />
                </Box>
              )}
              {lessonData?.quiz && (
                <Box
                  ref={quizSectionRef}
                  sx={{ mt: 5, mb: 5, position: "relative" }}
                >
                  <LessonQuizDisplay
                    quizData={lessonData.quiz}
                    theme={theme}
                    styles={styles}
                    userId={user.uid}
                    viewMode={viewMode}
                    setLessonData={setLessonData}
                  />
                </Box>
              )}
              {!viewMode && (
                <DashedPapers
                  handleQuizPaperClick={handleQuizPaperClick}
                  handleComprehensionPaperClick={handleComprehensionPaperClick}
                  handleTimelinePaperClick={handleTimelinePaperClick}
                  handleTimelineSimplePaperClick={
                    handleTimelineSimplePaperClick
                  }
                  quizSectionRef={quizSectionRef}
                  timelineSectionRef={timelineSectionRef}
                  timelineSimpleSectionRef={timelineSimpleSectionRef}
                  exercisesSectionRef={exercisesSectionRef}
                  lessonData={lessonData}
                  setLessonData={setLessonData}
                />
              )}

              <SaveLessonDialog
                key={lessonData?.title}
                open={saveDialogOpen}
                onClose={() => setSaveDialogOpen(false)}
                onSave={handleSaveWithComment}
                theme={theme}
                lessonName={lessonData?.title || ""}
                lessonObject={lessonData}
                lessonType={"LettersLesson.js"}
              />
              <ImageGenerationModal
                open={showImageModal}
                onClose={handleCloseModal}
                onGenerate={handleGenerateWithInstructions}
                isGenerating={isGeneratingImages}
                instructions={imageInstructions}
                setInstructions={setImageInstructions}
                theme={theme}
              />
            </Box>

            <SlideInPaper
              isExpanded={isExpanded}
              onExpandedChange={setIsExpanded}
              lessonTitleRef={lessonTitleRef}
              viewMode={viewMode}
              toggleViewMode={toggleViewMode}
              theme={theme}
              open={showControlPanel}
              onEdit={(e) => {
                e.stopPropagation();
                setIsEditMode(!isEditMode);
                setIsExpanded(false);
              }}
              user={user}
              onPrint={() => handlePrintLetterLesson(lessonData)}
              onTranslate={() => translateButtonRef.current?.click()}
              onSaveAsPdf={() => pdfButtonRef.current?.click()}
              onShareLink={() => shareLinkButtonRef.current?.click()}
              onShareWithStudents={() => setShareDialogOpen(true)}
              onShareWithFriends={() => setShareDialogOpenFriends(true)}
              onSaveToBank={() => setSaveDialogOpen(true)}
              onStudentMode={() => {
                window.scrollTo({ top: 0, behavior: "smooth" });
                toggleViewMode();
              }}
              onGenerateExercises={() => {
                comprehensionButtonRef.current?.click();
              }}
              onGenerateQuiz={() => {
                quizButtonRef.current?.click();
              }}
              onGenerateAssignments={() => {
                comprehensionButtonRef.current?.click();
              }}
              onGenerateImages={() => handleImageButtonClick(null)}
              onGenerateTimeline={() => {
                timelineButtonRef.current?.click();
              }}
            />

            <ShareDialog
              open={shareDialogOpen}
              onClose={() => setShareDialogOpen(false)}
              onShare={handleShare}
              user={user}
              isLessonSharing={true}
              theme={theme}
              lessonName={lessonData?.title || ""}
              lessonObject={lessonData}
              lessonType={"LettersLesson.js"}
            />

            <ShareDialog
              open={shareDialogOpenFriends}
              onClose={() => setShareDialogOpenFriends(false)}
              onShare={handleShare}
              user={user}
              isLessonSharing={true}
              theme={theme}
              lessonName={lessonData?.title || ""}
              lessonObject={lessonData}
              lessonType={"LettersLesson.js"}
              shareWithFriends={true}
            />
            <LoadingBackdrop
              isOpen={isSharing}
              imagePath="/img/AI.png"
              loadingText={"Delar lektionen..."}
            />
          </>
        )}
      </Box>
    </Box>
  );
};

export default LettersLesson;
