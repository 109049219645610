import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Stack,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { East as ArrowIcon } from "@mui/icons-material";
import { AutoFixHigh, RestartAlt } from "@mui/icons-material";
import LanguageSelector from "./LanguageSelector";

const RoleplayDialog = ({ open, onClose, onSubmit, theme }) => {
  const [studentRole, setStudentRole] = useState("");
  const [aiRole, setAiRole] = useState("");
  const [scenario, setScenario] = useState("");
  const [interaction, setInteraction] = useState("collaborative");
  const [intensity, setIntensity] = useState("normal");
  const [language, setLanguage] = useState("svenska");
  const [isExampleLoaded, setIsExampleLoaded] = useState(false);
  const loadExample = () => {
    if (isExampleLoaded) {
      // Reset everything
      setStudentRole("");
      setAiRole("");
      setScenario("");
      setInteraction("collaborative");
      setIntensity("normal");
      setIsExampleLoaded(false);
    } else {
      // Load example
      setStudentRole("Miljöaktivist");
      setAiRole("Industriledare");
      setScenario(
        "Debattmöte om företagets planerade expansion av fabriken nära ett naturreservat",
      );
      setInteraction("argumentative");
      setIntensity("challenging");
      setIsExampleLoaded(true);
    }
  };

  const handleSubmit = () => {
    if (!studentRole.trim() || !aiRole.trim() || !scenario.trim()) return;

    const interactionMap = {
      collaborative: "samarbetsinriktad dialog",
      argumentative: "argumenterande dialog",
      educational: "pedagogisk dialog",
      casual: "avslappnad dialog",
    };

    const intensityMap = {
      light: "lätt",
      normal: "normal",
      intense: "intensiv",
      challenging: "utmanande",
    };

    const prompt = `Du spelar rollen som ${aiRole}, och jag är ${studentRole}. Scenariot är: ${scenario}. 
    Dialogstil: ${interactionMap[interaction]}. Intensitet: ${intensityMap[intensity]}.  Språk: ${language}. 
    Börja med att presentera dig i din roll och ställ sedan en relevant fråga till mig i min roll.`;

    onSubmit(prompt);
    onClose();
    setStudentRole("");
    setAiRole("");
    setScenario("");
    setInteraction("collaborative");
    setIntensity("normal");
  };

  const interactionTypes = [
    { value: "collaborative", label: "Samarbetsinriktad" },
    { value: "argumentative", label: "Argumenterande" },
    { value: "educational", label: "Pedagogisk" },
    { value: "casual", label: "Avslappnad" },
  ];

  const intensityLevels = [
    { value: "light", label: "Lätt" },
    { value: "normal", label: "Normal" },
    { value: "intense", label: "Intensiv" },
    { value: "challenging", label: "Utmanande" },
  ];

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: 730,

          backgroundColor:
            theme.palette.mode === "dark" ? "background.default" : "white",
          border:
            theme.palette.mode === "dark"
              ? "1px solid #323d48"
              : "1px solid #e2e8f0",
          p: 3,
        },
      }}
    >
      <IconButton
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 16,
          top: 16,
          color:
            theme.palette.mode === "dark"
              ? "rgba(255, 255, 255, 0.7)"
              : "inherit",
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 1,
          color:
            theme.palette.mode === "dark"
              ? "rgba(255, 255, 255, 0.7)"
              : "inherit",
          pt: 2,
        }}
      >
        <Typography variant="h5" fontWeight={700}>
          Skapa ett rollspel
        </Typography>
      </DialogTitle>
      <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
        <Button
          variant="purp"
          size="small"
          onClick={loadExample}
          startIcon={isExampleLoaded ? <RestartAlt /> : <AutoFixHigh />}
          sx={{
            minWidth: "unset",
            px: 2,
            py: 0.5,
            fontSize: "0.75rem",
          }}
        >
          {isExampleLoaded ? "Nollställ" : "Visa exempel"}
        </Button>
      </Box>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: 4,
          pb: 4,
        }}
      >
        {/* Role inputs side by side */}

        <Typography variant="subtitle1" fontWeight={600}>
          Välj roller och scenario:
        </Typography>
        <Box sx={{ display: "flex", gap: 2, width: "100%", mb: 3, mt: 2 }}>
          <TextField
            autoFocus
            fullWidth
            value={studentRole}
            onChange={(e) => setStudentRole(e.target.value)}
            label="Din roll"
            placeholder="T.ex. Miljöaktivist"
          />
          <TextField
            fullWidth
            value={aiRole}
            onChange={(e) => setAiRole(e.target.value)}
            label="AI:s roll"
            placeholder="T.ex. Industriledare"
          />
        </Box>

        <TextField
          fullWidth
          value={scenario}
          onChange={(e) => setScenario(e.target.value)}
          label="Scenario"
          placeholder="T.ex. Debattmöte om företagets planerade expansion av fabriken nära ett naturreservat"
          sx={{ mb: 4 }}
        />

        <Stack spacing={2} width="100%" alignItems="center" sx={{ mb: 4 }}>
          <Typography variant="subtitle1" fontWeight={600}>
            Välj språk:
          </Typography>
          <LanguageSelector language={language} setLanguage={setLanguage} />
        </Stack>

        <Stack spacing={3} width="100%" alignItems="center">
          <Typography variant="subtitle1" fontWeight={600}>
            Välj dialogstil:
          </Typography>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: 2,
              width: "100%",
            }}
          >
            {interactionTypes.map(({ value, label }) => (
              <Button
                key={value}
                variant={interaction === value ? "save" : "print"}
                onClick={() => setInteraction(value)}
                sx={{ height: 45 }}
                color={interaction === value ? "primary" : "inherit"}
                startIcon={interaction === value ? <CheckCircleIcon /> : null}
              >
                {label}
              </Button>
            ))}
          </Box>
        </Stack>

        <Stack spacing={3} width="100%" alignItems="center" sx={{ mt: 4 }}>
          <Typography variant="subtitle1" fontWeight={600}>
            Välj intensitetsnivå:
          </Typography>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: 2,
              width: "100%",
            }}
          >
            {intensityLevels.map(({ value, label }) => (
              <Button
                key={value}
                variant={intensity === value ? "save" : "print"}
                onClick={() => setIntensity(value)}
                sx={{ height: 45 }}
                color={intensity === value ? "primary" : "inherit"}
                startIcon={intensity === value ? <CheckCircleIcon /> : null}
              >
                {label}
              </Button>
            ))}
          </Box>
        </Stack>
      </DialogContent>

      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          onClick={handleSubmit}
          variant="purp"
          disabled={!studentRole.trim() || !aiRole.trim() || !scenario.trim()}
          sx={{ width: 200, height: 45 }}
        >
          Starta rollspel <ArrowIcon sx={{ ml: 1 }} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RoleplayDialog;
