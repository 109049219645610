import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Chip,
  Button,
  CircularProgress,
  Tabs,
  Tab,
  alpha,
  darken,
  Avatar,
  Tooltip,
  Table,
  Divider,
  Alert,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Menu,
  MenuItem,
  TableRow,
  IconButton,
} from "@mui/material";
import LoadingBackdrop from "../LoadingBackdrop";
import Grid3x3Icon from "@mui/icons-material/Grid3x3";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DeleteIcon from "@mui/icons-material/Delete";
import { handlePrintQuiz, handlePrint1X2Quiz } from "../printFunctions";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import ConfirmationDialog from "../ConfirmationDialog";

import PrintIcon from "@mui/icons-material/Print";
import ShareIcon from "@mui/icons-material/Share";
import ShareDialog from "../ShareDialog";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import TeacherQuizDisplay from "../TeacherQuizDisplay"; // Adjust the import path as needed

import EditIcon from "@mui/icons-material/Edit";

import { useNavigate } from "react-router-dom";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CircleIcon from "@mui/icons-material/Circle";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import SchoolIcon from "@mui/icons-material/School";
import GamesIcon from "@mui/icons-material/Games";
import { Link } from "react-router-dom";
import { createUrlSlug } from "../functions";
import { db } from "../firebase";
import {
  collection,
  getDocs,
  query,
  where,
  documentId,
  deleteDoc,
  doc,
  addDoc,
  serverTimestamp,
  limit,
} from "firebase/firestore";

import Logger from "../Logger";
import BallotIcon from "@mui/icons-material/Ballot";
import ShortTextIcon from "@mui/icons-material/ShortText";
import FactCheckIcon from "@mui/icons-material/FactCheck";

import AttachFileOffIcon from "@mui/icons-material/AttachFile";
import { useSnackbar } from "../SnackbarHandler";

const MyQuizzes = ({
  user,
  theme,
  styles,
  showSharedQuizzes,
  reloadTrigger,
}) => {
  const [quizzes, setQuizzes] = useState([]);
  const [isSharing, setIsSharing] = useState(false);
  const [loadingMyQuizzes, setLoadingMyQuizzes] = useState(false);
  const [isManaging, setIsManaging] = useState(false);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [shareDialogOpenFriends, setShareDialogOpenFriends] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [isSaving, setIsSaving] = useState(false);
  const navigate = useNavigate();
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [userDetails, setUserDetails] = useState({});
  const { showSnackbar } = useSnackbar();
  const [selectedQuiz, setSelectedQuiz] = useState(null);
  const [showTeacherQuizDialog, setShowTeacherQuizDialog] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  useEffect(() => {
    loadQuizzes();
  }, [user.uid]);

  // Inside MyQuizzes component
  useEffect(() => {
    // Your existing fetch logic for quizzes
    loadQuizzes();
  }, [reloadTrigger]); // Add reloadTrigger to dependencies

  useEffect(() => {
    Logger.log("selectedQuiz: ", JSON.stringify(selectedQuiz, null, 2));
  }, [selectedQuiz]);

  const getTabType = (currentTab, isStudent) => {
    if (!isStudent) {
      return currentTab;
    }
    return currentTab >= 1 ? 2 : 0;
  };

  const formatTime = (seconds) => {
    if (!seconds) return null;
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    if (minutes === 0) return `${remainingSeconds} sekunder`;
    return `${minutes} min${minutes > 1 ? "" : ""} ${remainingSeconds} sek`;
  };

  const tabType = getTabType(currentTab, user.accountType === "student");

  const handleMenuOpen = (event, quiz) => {
    setMenuAnchorEl(event.currentTarget);
    setSelectedQuiz(quiz);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    setSelectedQuiz(null);
  };

  const handleEditClick = () => {
    setShowTeacherQuizDialog(true); // First set dialog to show
    setMenuAnchorEl(null); // Just close menu, DON'T CLEAR SELECTED QUIZ
  };
  const handleDeleteClick = () => {
    setShowDeleteConfirmation(true);
    setMenuAnchorEl(null); // Close the menu
  };

  const handleConfirmDelete = async () => {
    if (selectedQuiz) {
      try {
        await deleteDoc(doc(db, "quiz", selectedQuiz.id));
        setQuizzes((prevQuizzes) =>
          prevQuizzes.filter((quiz) => quiz.id !== selectedQuiz.id),
        );
        showSnackbar("Quizzet har raderats", "success");
      } catch (error) {
        console.error("Error deleting quiz:", error);
        showSnackbar("Ett fel uppstod när quizzet skulle raderas", "error");
      } finally {
        setShowDeleteConfirmation(false);
        setSelectedQuiz(null);
      }
    }
  };

  const loadUserDetails = async (userIds) => {
    const uniqueUserIds = [...new Set(userIds)];

    const usersSnapshot = await getDocs(
      query(
        collection(db, "users"),
        where(documentId(), "in", uniqueUserIds),
        limit(30),
      ),
    );

    const userMap = {};
    usersSnapshot.docs.forEach((doc) => {
      userMap[doc.id] = {
        displayName: doc.data().displayName,
        photoURL: doc.data().photoURL,
      };
    });

    setUserDetails(userMap);
  };

  const loadQuizzes = async () => {
    try {
      setLoadingMyQuizzes(true);

      // Split into two queries for proper filtering
      const sharedQuerySnapshot = await getDocs(
        query(
          collection(db, "quiz"),

          where("sharedTo", "==", user.uid),
          limit(30),
        ),
      );

      const regularQuerySnapshot = await getDocs(
        query(collection(db, "quiz"), where("userId", "==", user.uid)),
      );

      // Get quizzes shared BY you
      const sharedByYou = regularQuerySnapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .filter((doc) => doc.shared && doc.userId === user.uid)
        .sort((a, b) => (b.createdAt || 0) - (a.createdAt || 0))
        .slice(0, 15);

      // Get regular (non-shared) quizzes
      const regularQuizzes = regularQuerySnapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .filter((doc) => !doc.shared && doc.userId === user.uid)
        .sort((a, b) => (b.createdAt || 0) - (a.createdAt || 0))
        .slice(0, 15);

      // Get quizzes shared TO you
      const sharedToYou = sharedQuerySnapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .filter(
          (doc) => doc.userId !== user.uid && doc.sharedTo?.includes(user.uid),
        )
        .sort((a, b) => (b.createdAt || 0) - (a.createdAt || 0))
        .slice(0, 15);

      const allQuizzes = [...sharedByYou, ...regularQuizzes, ...sharedToYou];
      setQuizzes(allQuizzes);

      const sharedUserIds = allQuizzes.flatMap((quiz) => {
        const ids = [];
        if (quiz.shared && quiz.userId) {
          ids.push(quiz.userId); // Add the teacher's ID
        }
        if (quiz.shared && quiz.userId === user.uid && quiz.sharedTo) {
          ids.push(quiz.sharedTo); // Add the student's ID
        }
        return ids;
      });

      if (sharedUserIds.length > 0) {
        await loadUserDetails([...new Set(sharedUserIds)]); // Remove duplicates
      }
    } catch (error) {
      console.error("Error fetching quizzes:", error);
    } finally {
      setLoadingMyQuizzes(false);
    }
  };

  const handleShare = async ({ roomId, studentIds, comment, fileUrl }) => {
    // Handle the share logic here

    Logger.log("Sharing quiz:", JSON.stringify(selectedQuiz, null, 2));
    Logger.log("With room:", roomId);
    Logger.log("To students:", studentIds);
    Logger.log("Comment:", comment);
    setIsSharing(true);

    await new Promise((resolve) => setTimeout(resolve, 1800));

    // try {
    //   // Create a document for each student
    //   const sharePromises = studentIds.map((studentId) => {
    //     const documentData = {
    //       sharedTo: studentId,
    //       comment: comment,
    //       fileUrl: fileUrl,
    //       results: selectedQuiz.results,
    //       shared: true,
    //       userId: user.uid,
    //       quizName: selectedQuiz.quizName,
    //       createdAt: Date.now(),
    //       byAdmin: "No",
    //       isPublic: selectedQuiz.isPublic,
    //       questionType: selectedQuiz.questionType,
    //       category: selectedQuiz.category,
    //       difficulty: selectedQuiz.difficulty,
    //       aiInstructions: selectedQuiz.aiInstructions,
    //       lastTrainingSession: null,
    //       language: selectedQuiz.language,
    //       aiModel: selectedQuiz.aiModel,
    //       upvotes: 0,
    //       downvotes: 0,
    //       userVotes: {},
    //     };

    //     Logger.log(
    //       "Adding document for student",
    //       studentId,
    //       ":",
    //       JSON.stringify(documentData, null, 2),
    //     );

    //     return addDoc(collection(db, "quiz"), documentData);
    //   });

    //   await Promise.all(sharePromises);
    //   Logger.log("Quiz shared saved to DB");
    //   showSnackbar("Du har nu delat quizzet med dina elever!", "success");
    //   // Close the share dialog
    //   setShareDialogOpen(false);
    //   setShareDialogOpenFriends(false);
    // }

    try {
      // Create a document for each student
      const sharePromises = studentIds.map(async (studentId) => {
        const documentData = {
          sharedTo: studentId,
          comment: comment,
          fileUrl: fileUrl,
          results: selectedQuiz.results,
          shared: true,
          userId: user.uid,
          quizName: selectedQuiz.quizName,
          createdAt: Date.now(),
          byAdmin: "No",
          isPublic: selectedQuiz.isPublic,
          questionType: selectedQuiz.questionType,
          category: selectedQuiz.category,
          difficulty: selectedQuiz.difficulty,
          aiInstructions: selectedQuiz.aiInstructions,
          lastTrainingSession: null,
          language: selectedQuiz.language,
          aiModel: selectedQuiz.aiModel,
          upvotes: 0,
          downvotes: 0,
          userVotes: {},
        };

        // Create the quiz document and get its ID
        const quizDoc = await addDoc(collection(db, "quiz"), documentData);

        Logger.log(
          "Adding document for student",
          studentId,
          ":",
          JSON.stringify(documentData, null, 2),
        );

        // Create notification
        await addDoc(collection(db, "notifications"), {
          type: "quiz_shared",
          recipientId: studentId,
          senderId: user.uid,
          read: false,
          createdAt: serverTimestamp(),
          quizData: {
            quizId: quizDoc.id,
            quizName: selectedQuiz.quizName,
            fileUrl: fileUrl,
            comment: comment,
            category: selectedQuiz.category,
            difficulty: selectedQuiz.difficulty,
          },
        });

        return quizDoc;
      });

      await Promise.all(sharePromises);
      Logger.log("Quiz shared saved to DB");
      showSnackbar("Du har nu delat quizzet med dina elever!", "success");
      // Close the share dialog
      setShareDialogOpen(false);
      setShareDialogOpenFriends(false);
    } catch (error) {
      Logger.error("Error sharing quiz:", error);
      showSnackbar("Ett fel uppstod när quizzet skulle delas.", "error");
    } finally {
      setIsSharing(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleQuizUpdate = (updatedQuizData) => {
    // Update the selected quiz in state
    setSelectedQuiz((prevQuiz) => ({
      ...prevQuiz,
      results: updatedQuizData,
    }));

    // Update the quiz in the quizzes array
    setQuizzes((prevQuizzes) =>
      prevQuizzes.map((quiz) =>
        quiz.id === selectedQuiz.id
          ? { ...quiz, results: updatedQuizData }
          : quiz,
      ),
    );
  };

  const getFilteredQuizzes = () => {
    switch (
      tabType // Changed from currentTab to tabType
    ) {
      case 0: // Regular quizzes
        return quizzes.filter((quiz) => !quiz.shared);
      case 1: // Shared with students
        return quizzes.filter(
          (quiz) => quiz.shared && quiz.userId === user.uid,
        );
      case 2: // Shared by teachers
        return quizzes.filter(
          (quiz) => quiz.shared && quiz.userId !== user.uid,
        );
      default:
        return [];
    }
  };

  const getUntrainedCount = (quizzes) => {
    return quizzes.filter(
      (quiz) =>
        quiz.shared && quiz.userId !== user.uid && !quiz.lastTrainingSession,
    ).length;
  };

  const getUntrainedSharedByTeacherCount = (quizzes) => {
    return quizzes.filter(
      (quiz) =>
        quiz.shared &&
        quiz.userId === user.uid &&
        quiz.sharedTo &&
        !quiz.lastTrainingSession,
    ).length;
  };

  const handleDeleteQuiz = async (quizId, event) => {
    if (event) {
      event.stopPropagation();
    }
    setQuizzes((prevQuizzes) =>
      prevQuizzes.filter((quiz) => quiz.id !== quizId),
    );
    try {
      await deleteDoc(doc(db, "quiz", quizId));
    } catch (error) {
      console.error("Error deleting quiz:", error);
      loadQuizzes(); // Reload quizzes if deletion fails
    }
  };

  const handleDownload = async (url, e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      // Extract filename from URL or use a default
      const fileName = url.substring(url.lastIndexOf("/") + 1).split("?")[0];
      link.setAttribute("download", decodeURIComponent(fileName));
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error("Download failed:", error);
      showSnackbar("Kunde inte ladda ner filen", "error");
    }
  };

  const getTimeSinceLastTraining = (lastTrainingSession) => {
    if (!lastTrainingSession) return "Aldrig tränat";
    const now = Date.now();
    const diff = now - lastTrainingSession;
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);

    if (seconds < 60)
      return `${seconds} sekund${seconds !== 1 ? "er" : ""} sedan`;
    if (minutes < 60)
      return `${minutes} minut${minutes !== 1 ? "er" : ""} sedan`;
    if (hours < 24) return `${hours} timm${hours !== 1 ? "ar" : "e"} sedan`;

    const days = Math.floor(hours / 24);
    if (days === 1) return "Igår";
    if (days < 7) return `${days} dagar sedan`;
    const weeks = Math.floor(days / 7);
    if (weeks === 1) return "1 vecka sedan";
    if (weeks < 4) return `${weeks} veckor sedan`;
    const months = Math.floor(days / 30);
    if (months === 1) return "1 månad sedan";
    if (months < 12) return `${months} månader sedan`;
    const years = Math.floor(days / 365);
    return `${years} år sedan`;
  };

  const calculateResults = (results) => {
    if (
      !results ||
      !results.some((result) => result.selectedOption || result.type === "exam")
    )
      return { correct: 0, wrong: 0 };

    const totalQuestions = results.length;
    const correctAnswers = results.filter((result) => {
      if (result.type === "exam") {
        return result.isCorrect;
      }
      return result.selectedOption === result.correct;
    }).length;

    return { correct: correctAnswers, wrong: totalQuestions - correctAnswers };
  };
  const filteredQuizzes = getFilteredQuizzes();

  return (
    <Box sx={{ padding: "0px" }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          background: theme.palette.mode === "dark" ? "#22303c" : "#f9f9fa",
          borderTopLeftRadius: "7px",
          borderTopRightRadius: "7px",
        }}
      >
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          sx={{
            "& .MuiTab-root": {
              textTransform: "none",
              minWidth: 120,
              fontSize: "0.9rem",
            },
          }}
        >
          <Tab
            label="Mina quiz"
            icon={<GamesIcon sx={{ fontSize: 20 }} />}
            iconPosition="start"
          />
          {user.accountType !== "student" && (
            <Tab
              label={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  Delat till elever från mig
                  {getUntrainedSharedByTeacherCount(quizzes) > 0 && (
                    <Tooltip
                      title={`${getUntrainedSharedByTeacherCount(quizzes)} elever som inte börjat öva än`}
                      placement="right"
                    >
                      <Chip
                        size="small"
                        label={getUntrainedSharedByTeacherCount(quizzes)}
                        sx={{
                          ml: 1,
                          height: 20,
                          minWidth: 20,
                          fontSize: "0.75rem",
                          backgroundColor: theme.palette.primary.main,
                          color: theme.palette.primary.contrastText,
                        }}
                      />
                    </Tooltip>
                  )}
                </Box>
              }
              icon={<PeopleOutlineIcon sx={{ fontSize: 20 }} />}
              iconPosition="start"
            />
          )}
          <Tab
            label={
              <Box sx={{ display: "flex", alignItems: "center" }}>
                Delat från lärare till mig
                {getUntrainedCount(quizzes) > 0 && (
                  <Tooltip
                    title={`${getUntrainedCount(quizzes)} quiz du inte tränat på ännu`}
                    placement="right"
                  >
                    <Chip
                      size="small"
                      label={getUntrainedCount(quizzes)}
                      sx={{
                        ml: 1,
                        height: 20,
                        minWidth: 20,
                        fontSize: "0.75rem",
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                      }}
                    />
                  </Tooltip>
                )}
              </Box>
            }
            icon={<SchoolIcon sx={{ fontSize: 20 }} />}
            iconPosition="start"
          />
        </Tabs>
      </Box>
      {loadingMyQuizzes ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "200px",
            p: 4,
            bgcolor: alpha(theme.palette.background.paper, 0.6),
          }}
        >
          <CircularProgress />
        </Box>
      ) : filteredQuizzes.length === 0 ? (
        <Box
          sx={{
            p: 4,
            textAlign: "center",
            color: "text.secondary",
            bgcolor: alpha(theme.palette.background.paper, 0.6),
            borderRadius: 2,
          }}
        >
          {tabType === 0
            ? "De quiz du själv genererat hamnar här."
            : tabType === 1
              ? "Du har inte delat några quiz med dina elever än."
              : "Inga quiz har delats med dig av lärare än."}
        </Box>
      ) : (
        <Box sx={{ padding: "0px", paddingTop: "60px" }}>
          {tabType === 2 && getUntrainedCount(quizzes) > 0 && (
            <Alert
              severity="info"
              variant="filled"
              sx={{
                display: "none",
                margin: "30px",
                marginTop: "0px",
                marginBottom: "30px",
                textAlign: "left",
                "& .MuiAlert-message": {
                  fontSize: "0.9rem",
                  lineHeight: "1.4rem",
                },
                borderRadius: 4,
              }}
            >
              Din lärare har delat {getUntrainedCount(quizzes)} quiz som du inte
              övat på ännu :)
            </Alert>
          )}
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Quiz namn</TableCell>
                  {tabType === 2 && <TableCell>Delad från</TableCell>}

                  {tabType === 1 && <TableCell>Delat med</TableCell>}

                  <TableCell>Senaste träning</TableCell>
                  <TableCell>Resultat</TableCell>
                  <TableCell>Frågetyp</TableCell>
                  <TableCell>Bif.</TableCell>
                  <TableCell>
                    <Tooltip title="Kommentar från lärare">
                      <MailOutlineIcon
                        sx={{
                          fontSize: 20,
                          mt: 1,
                          color: theme.palette.text.secondary,
                        }}
                      />
                    </Tooltip>
                  </TableCell>
                  {user.accountType !== "student" && (
                    <TableCell align="right">Hantera</TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredQuizzes.map((quiz) => {
                  const urlSlug = createUrlSlug(quiz.quizName);
                  return (
                    <TableRow
                      key={quiz.id}
                      component={Link}
                      to={`/app/quiz/${quiz.id}/${urlSlug}`}
                      sx={{
                        textDecoration: "none",
                        cursor: "pointer",
                        "&:hover": { backgroundColor: "action.hover" },
                      }}
                    >
                      <TableCell>
                        <Box
                          sx={{ display: "flex", alignItems: "center", gap: 2 }}
                        >
                          <GamesIcon
                            sx={{
                              fontSize: 24,
                              color: (theme) =>
                                darken(theme.palette.text.main, 0.2),
                            }}
                          />
                          <Box>
                            <Typography
                              sx={{ fontWeight: 400, fontSize: "0.9rem" }}
                            >
                              {quiz.quizName}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>

                      {tabType === 2 && (
                        <TableCell>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Tooltip
                              title={
                                userDetails[quiz.userId]?.displayName ||
                                "Loading..."
                              }
                            >
                              <Avatar
                                src={userDetails[quiz.userId]?.photoURL}
                                alt={userDetails[quiz.userId]?.displayName}
                                sx={{
                                  width: 22,
                                  height: 22,
                                  fontSize: "0.775rem",
                                }}
                              >
                                {userDetails[quiz.userId]?.displayName?.[0] ||
                                  "?"}
                              </Avatar>
                            </Tooltip>
                            <Typography variant="body2" sx={{ ml: 1 }}>
                              {userDetails[quiz.userId]?.displayName ||
                                "Loading..."}
                            </Typography>
                          </Box>
                        </TableCell>
                      )}

                      {tabType === 1 && (
                        <TableCell>
                          {quiz.shared &&
                          quiz.userId === user.uid &&
                          quiz.sharedTo ? (
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Tooltip
                                title={
                                  userDetails[quiz.sharedTo]?.displayName ||
                                  "Loading..."
                                }
                              >
                                <Avatar
                                  src={userDetails[quiz.sharedTo]?.photoURL}
                                  alt={userDetails[quiz.sharedTo]?.displayName}
                                  sx={{
                                    width: 22,
                                    height: 22,
                                    fontSize: "0.775rem",
                                  }}
                                >
                                  {userDetails[quiz.sharedTo]
                                    ?.displayName?.[0] || "?"}
                                </Avatar>
                              </Tooltip>
                              <Typography variant="body2" sx={{ ml: 1 }}>
                                {userDetails[quiz.sharedTo]?.displayName ||
                                  "Loading..."}
                              </Typography>
                            </Box>
                          ) : (
                            "-"
                          )}
                        </TableCell>
                      )}
                      <TableCell>
                        <Box
                          sx={{ display: "flex", alignItems: "center", gap: 1 }}
                        >
                          <AccessTimeIcon
                            sx={{
                              fontSize: 16,
                              color: !quiz.lastTrainingSession
                                ? theme.palette.warning.main
                                : theme.palette.text.secondary,
                            }}
                          />
                          <Typography
                            variant="body2"
                            sx={{
                              color: !quiz.lastTrainingSession
                                ? theme.palette.warning.main
                                : theme.palette.text.secondary,
                            }}
                          >
                            {getTimeSinceLastTraining(quiz.lastTrainingSession)}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        {quiz.results?.some(
                          (result) =>
                            result.selectedOption || result.type === "exam",
                        ) && (
                          <Tooltip
                            onClick={(e) => e.stopPropagation()}
                            onMouseDown={(e) => e.stopPropagation()}
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  width: "300px !important",
                                  maxWidth: "300px !important",
                                },
                              },
                            }}
                            slotProps={{
                              popper: {
                                sx: {
                                  "& .MuiTooltip-tooltip": {
                                    bgcolor: "background.paper",
                                    borderRadius: "8px",
                                    width: "300px",
                                    maxWidth: "300px !important",
                                    boxShadow:
                                      "0px 4px 20px rgba(0, 0, 0, 0.1)",
                                  },
                                  "& .MuiTooltip-arrow": {
                                    color: "background.paper",
                                  },
                                },
                              },
                            }}
                            title={
                              <Box
                                onClick={(e) => e.stopPropagation()}
                                onMouseDown={(e) => e.stopPropagation()}
                                sx={{
                                  p: 2,
                                  maxWidth: 400,
                                  maxHeight: 400,
                                  overflow: "auto",
                                  bgcolor: "background.paper",
                                  borderRadius: 1,
                                }}
                              >
                                {quiz.results.map((result, index) => (
                                  <Box
                                    key={index}
                                    sx={{
                                      mb: 2.5,
                                      pb: 2.5,
                                      "&:not(:last-child)": {
                                        borderBottom: "1px solid",
                                        borderColor: "divider",
                                      },
                                    }}
                                  >
                                    <Box sx={{ mb: 1 }}>
                                      <Chip
                                        size="small"
                                        icon={
                                          result.isCorrect ? (
                                            <CheckCircleOutlineIcon />
                                          ) : (
                                            <CancelOutlinedIcon />
                                          )
                                        }
                                        label={
                                          result.isCorrect
                                            ? "Rätt svar"
                                            : "Fel svar"
                                        }
                                        sx={{
                                          height: "24px",
                                          background: alpha(
                                            result.isCorrect
                                              ? "#27792B"
                                              : "#FF4D4D",
                                            0.1,
                                          ),
                                          color: result.isCorrect
                                            ? "#27792B"
                                            : "#FF4D4D",
                                          fontSize: "11px",
                                          fontWeight: 500,
                                          "& .MuiChip-icon": {
                                            fontSize: 14,
                                            color: "inherit",
                                          },
                                        }}
                                      />
                                    </Box>

                                    <Typography
                                      variant="subtitle2"
                                      sx={{
                                        mb: 0.5,
                                        fontWeight: 600,
                                        color: "text.primary",
                                      }}
                                    >
                                      Fråga:
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      sx={{ mb: 1.5, color: "text.secondary" }}
                                    >
                                      {result.question}
                                    </Typography>

                                    {result.type === "exam" ? (
                                      <>
                                        <Typography
                                          variant="subtitle2"
                                          sx={{
                                            mb: 0.5,
                                            fontWeight: 600,
                                            color: "text.primary",
                                          }}
                                        >
                                          Elevens svar:
                                        </Typography>
                                        <Typography
                                          variant="body2"
                                          sx={{
                                            mb: 1.5,
                                            color: "text.secondary",
                                          }}
                                        >
                                          {result.userAnswer}
                                        </Typography>

                                        <Typography
                                          variant="subtitle2"
                                          sx={{
                                            mb: 0.5,
                                            fontWeight: 600,
                                            color: "text.primary",
                                          }}
                                        >
                                          Förklaring:
                                        </Typography>
                                        <Typography
                                          variant="body2"
                                          sx={{
                                            mb: 1.5,
                                            color: "text.secondary",
                                          }}
                                        >
                                          {result.explanation}
                                        </Typography>

                                        <Typography
                                          variant="subtitle2"
                                          sx={{
                                            mb: 0.5,
                                            fontWeight: 600,
                                            color: "text.primary",
                                          }}
                                        >
                                          Korrekt svar:
                                        </Typography>
                                        <Typography
                                          variant="body2"
                                          sx={{ color: "text.secondary" }}
                                        >
                                          {result.answer}
                                        </Typography>
                                      </>
                                    ) : (
                                      <>
                                        <Typography
                                          variant="subtitle2"
                                          sx={{
                                            mb: 0.5,
                                            fontWeight: 600,
                                            color: "text.primary",
                                          }}
                                        >
                                          Ditt svar:
                                        </Typography>
                                        <Typography
                                          variant="body2"
                                          sx={{
                                            mb: 1.5,
                                            color: "text.secondary",
                                          }}
                                        >
                                          {result.selectedOption}
                                        </Typography>
                                        {!result.isCorrect && (
                                          <>
                                            <Typography
                                              variant="subtitle2"
                                              sx={{
                                                mb: 0.5,
                                                fontWeight: 600,
                                                color: "text.primary",
                                              }}
                                            >
                                              Rätt svar:
                                            </Typography>
                                            <Typography
                                              variant="body2"
                                              sx={{ color: "text.secondary" }}
                                            >
                                              {result.correct}
                                            </Typography>
                                          </>
                                        )}
                                      </>
                                    )}

                                    {result.timeSpent && (
                                      <Box sx={{ mt: 1.5, mb: 1.5 }}>
                                        <Typography
                                          variant="subtitle2"
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: 1,
                                            color: "text.secondary",
                                            fontSize: "0.8rem",
                                          }}
                                        >
                                          <TimerOutlinedIcon
                                            sx={{ fontSize: "1rem" }}
                                          />
                                          Tid på frågan:{" "}
                                          {formatTime(result.timeSpent)}
                                        </Typography>
                                      </Box>
                                    )}

                                    {result.hints &&
                                      result.revealedHints &&
                                      result.revealedHints.length > 0 && (
                                        <>
                                          <Typography
                                            variant="subtitle2"
                                            sx={{
                                              mt: 1.5,
                                              mb: 0.5,
                                              fontWeight: 600,
                                              color: "text.primary",
                                            }}
                                          >
                                            Använda ledtrådar (
                                            {result.revealedHints.length}/
                                            {result.hints.length}):
                                          </Typography>
                                          {result.revealedHints.map(
                                            (hintIndex) => (
                                              <Typography
                                                key={hintIndex}
                                                variant="body2"
                                                sx={{
                                                  color: "text.secondary",
                                                  display: "flex",
                                                  alignItems: "center",
                                                  gap: 1,
                                                  mb: 0.5,
                                                }}
                                              >
                                                <CircleIcon
                                                  sx={{ fontSize: 8 }}
                                                />
                                                {result.hints[hintIndex]}
                                              </Typography>
                                            ),
                                          )}
                                        </>
                                      )}
                                  </Box>
                                ))}

                                {quiz.results.some((r) => r.timeSpent) && (
                                  <Box sx={{ mt: 2, mb: 1.5 }}>
                                    <Typography
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 1,
                                        color: "text.secondary",
                                        fontSize: "0.8rem",
                                      }}
                                    >
                                      <TimerOutlinedIcon
                                        sx={{ fontSize: "1rem" }}
                                      />
                                      Total tid:{" "}
                                      {formatTime(
                                        quiz.results.reduce(
                                          (total, r) =>
                                            total + (r.timeSpent || 0),
                                          0,
                                        ),
                                      )}
                                    </Typography>
                                  </Box>
                                )}
                              </Box>
                            }
                            arrow
                            placement="top-start"
                          >
                            <Box sx={{ display: "flex", gap: 1 }}>
                              {(() => {
                                const { correct, wrong } = calculateResults(
                                  quiz.results,
                                );
                                return (
                                  <>
                                    <Chip
                                      icon={<CheckCircleOutlineIcon />}
                                      label={`${correct} rätt`}
                                      size="small"
                                      sx={{
                                        height: "24px",
                                        background: alpha("#27792B", 0.1),
                                        color: "#27792B",
                                        textTransform: "uppercase",
                                        fontSize: "11px",
                                        fontWeight: 500,
                                        "& .MuiChip-icon": {
                                          fontSize: 14,
                                          color: "#27792B",
                                        },
                                      }}
                                    />
                                    <Chip
                                      icon={<CancelOutlinedIcon />}
                                      label={`${wrong} fel`}
                                      size="small"
                                      sx={{
                                        height: "24px",
                                        background: alpha("#FF4D4D", 0.1),
                                        color: "#FF4D4D",
                                        textTransform: "uppercase",
                                        fontSize: "11px",
                                        fontWeight: 500,
                                        "& .MuiChip-icon": {
                                          fontSize: 14,
                                          color: "#FF4D4D",
                                        },
                                      }}
                                    />
                                  </>
                                );
                              })()}
                            </Box>
                          </Tooltip>
                        )}
                      </TableCell>
                      <TableCell>
                        <Box
                          sx={{ display: "flex", alignItems: "center", gap: 1 }}
                        >
                          {quiz.questionType === "multipleChoice" ? (
                            <BallotIcon
                              sx={{ color: "text.secondary", fontSize: 18 }}
                            />
                          ) : quiz.questionType === "fill-in-the-blank" ? (
                            <ShortTextIcon
                              sx={{ color: "text.secondary", fontSize: 18 }}
                            />
                          ) : quiz.questionType === "exam" ? (
                            <SchoolIcon
                              sx={{ color: "text.secondary", fontSize: 18 }}
                            />
                          ) : quiz.questionType === "current-events" ? (
                            <Grid3x3Icon
                              sx={{ color: "text.secondary", fontSize: 18 }}
                            />
                          ) : quiz.questionType === "true-or-false" ? (
                            <FactCheckIcon
                              sx={{ color: "text.secondary", fontSize: 18 }}
                            />
                          ) : null}
                          <Typography variant="body2">
                            {quiz.questionType === "multipleChoice"
                              ? "Flervalsfrågor"
                              : quiz.questionType === "fill-in-the-blank"
                                ? "Lucktext"
                                : quiz.questionType === "true-or-false"
                                  ? "Sant/Falskt"
                                  : quiz.questionType === "exam"
                                    ? "Prov"
                                    : quiz.questionType === "current-events"
                                      ? "1X2"
                                      : ""}
                          </Typography>
                        </Box>
                      </TableCell>

                      <TableCell>
                        <Tooltip
                          title={
                            collection.fileUrl
                              ? "Ladda ner fil från din lärare"
                              : "Ingen fil delad från din lärare"
                          }
                        >
                          <span
                            style={{
                              cursor: collection.fileUrl
                                ? "pointer"
                                : "default",
                            }}
                            onClick={(e) => {
                              if (!collection.fileUrl) {
                                e.preventDefault();
                                e.stopPropagation();
                              }
                            }}
                          >
                            <IconButton
                              size="small"
                              onClick={
                                collection.fileUrl
                                  ? (e) => handleDownload(collection.fileUrl, e)
                                  : undefined
                              }
                              disabled={!collection.fileUrl}
                              sx={{
                                color: collection.fileUrl
                                  ? theme.palette.primary.main
                                  : theme.palette.text.disabled,
                                "&:hover": collection.fileUrl
                                  ? {
                                      backgroundColor: alpha(
                                        theme.palette.primary.main,
                                        0.1,
                                      ),
                                    }
                                  : undefined,
                                opacity: collection.fileUrl ? 1 : 0.5,
                              }}
                            >
                              {collection.fileUrl ? (
                                <AttachFileOffIcon sx={{ fontSize: 20 }} />
                              ) : (
                                <AttachFileOffIcon sx={{ fontSize: 20 }} />
                              )}
                            </IconButton>
                          </span>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        {quiz.comment ? (
                          <Tooltip title={quiz.comment}>
                            <MailOutlineIcon
                              sx={{
                                mt: 0.5,
                                fontSize: 20,
                                color: theme.palette.text.secondary,
                              }}
                            />
                          </Tooltip>
                        ) : (
                          <Tooltip title="Ingen kommentar från lärare">
                            <MailOutlineIcon
                              sx={{
                                mt: 0.5,
                                fontSize: 20,
                                color: theme.palette.text.disabled,
                              }}
                            />
                          </Tooltip>
                        )}
                      </TableCell>
                      {user.accountType !== "student" && (
                        <TableCell align="right">
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-end",
                              gap: 1,
                            }}
                          >
                            <IconButton
                              onClick={(e) => {
                                e.preventDefault(); // Add this
                                e.stopPropagation();
                                handleMenuOpen(e, quiz);
                              }}
                              size="small"
                            >
                              <MoreHorizIcon />
                            </IconButton>
                          </Box>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <Menu
            anchorEl={menuAnchorEl}
            open={Boolean(menuAnchorEl)}
            onClose={handleMenuClose}
            PaperProps={{
              sx: { borderRadius: "8px", minWidth: "160px" },
            }}
          >
            <MenuItem onClick={handleEditClick} sx={styles.menuItemStyle}>
              <EditIcon
                fontSize="small"
                sx={{ mr: 1, color: "text.secondary" }}
              />
              Redigera
            </MenuItem>

            <MenuItem
              onClick={() => setShareDialogOpenFriends(true)}
              sx={styles.menuItemStyle}
            >
              <ShareIcon fontSize="small" sx={{ mr: 1, color: "#7B68EE" }} />
              Dela med vänner
            </MenuItem>

            <MenuItem
              onClick={() => setShareDialogOpen(true)}
              sx={styles.menuItemStyle}
            >
              <ShareIcon fontSize="small" sx={{ mr: 1, color: "#4CAF50" }} />
              Dela med elever
            </MenuItem>
            <MenuItem
              onClick={() =>
                selectedQuiz.questionType === "current-events"
                  ? handlePrint1X2Quiz(
                      selectedQuiz.results,
                      selectedQuiz.quizName,
                      selectedQuiz.questionType,
                      theme.palette.primary.main,
                    )
                  : handlePrintQuiz(
                      selectedQuiz.results,
                      selectedQuiz.quizName,
                      selectedQuiz.questionType,
                      theme.palette.primary.main,
                    )
              }
              sx={styles.menuItemStyle}
            >
              <PrintIcon
                fontSize="small"
                sx={{ mr: 1, color: "text.secondary" }}
              />
              Skriv ut
            </MenuItem>
            <Divider />

            <MenuItem onClick={handleDeleteClick} sx={styles.menuItemStyle}>
              <DeleteIcon fontSize="small" sx={{ mr: 1, color: "#F44336" }} />
              Radera
            </MenuItem>
          </Menu>
        </Box>
      )}
      <LoadingBackdrop
        isOpen={isSharing}
        imagePath="/img/AI.png"
        loadingText="Delar quiz..."
      />
      <ShareDialog
        open={shareDialogOpen}
        onClose={() => setShareDialogOpen(false)}
        onShare={handleShare}
        user={user}
        theme={theme}
      />

      <ShareDialog
        open={shareDialogOpenFriends}
        onClose={() => setShareDialogOpenFriends(false)}
        onShare={handleShare}
        user={user}
        theme={theme}
        shareWithFriends={true}
      />

      {showTeacherQuizDialog && selectedQuiz && (
        <TeacherQuizDisplay
          quiz={selectedQuiz.results}
          selectedQuiz={selectedQuiz}
          user={user}
          quizId={selectedQuiz.id}
          quizName={selectedQuiz.quizName}
          onClose={() => setShowTeacherQuizDialog(false)} // ADD THIS
          questionType={selectedQuiz.questionType}
          onQuizUpdate={handleQuizUpdate} // Add this
          onNameChange={(newName) =>
            setSelectedQuiz((prev) => ({ ...prev, quizName: newName }))
          } // Add this line
          onPrint={() =>
            selectedQuiz.questionType === "current-events"
              ? handlePrint1X2Quiz(
                  selectedQuiz.results,
                  selectedQuiz.quizName,
                  selectedQuiz.questionType,
                  theme.palette.primary.main,
                )
              : handlePrintQuiz(
                  selectedQuiz.results,
                  selectedQuiz.quizName,
                  selectedQuiz.questionType,
                  theme.palette.primary.main,
                )
          }
          onShare={() => setShareDialogOpen(true)}
          onShareWithFriends={() => setShareDialogOpenFriends(true)}
          onSave={() => {
            loadQuizzes();
            //setShowTeacherQuizDialog(false);
          }}
          reloadMyQuizzes={() => loadQuizzes()}
          editMode={true}
          styles={styles}
        />
      )}

      <ConfirmationDialog
        open={showDeleteConfirmation}
        onClose={() => setShowDeleteConfirmation(false)}
        onConfirm={handleConfirmDelete}
        title="Radera quiz"
        message="Är du säker på att du vill radera detta quiz? Detta går inte att ångra."
        confirmText="Radera"
        cancelText="Avbryt"
      />
    </Box>
  );
};

export default MyQuizzes;
