import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/system";
import { Box, Button, List } from "@mui/material";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

import { lighten, darken, alpha } from "@mui/material/styles";

import Paper from "@mui/material/Paper";

export const DashedPaper = styled(Paper)(({ theme }) => ({
  minWidth: 300,
  minHeight: 300,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  borderRadius: theme.shape.borderRadius * 2,
  border: "2px dashed",
  boxShadow: "none",
  borderColor: theme.palette.divider,
  backgroundColor:
    theme.palette.mode === "dark"
      ? alpha(theme.palette.primary.main, 0.05) // Very subtle primary tint in dark mode
      : lighten(theme.palette.primary.main, 0.98),
  cursor: "pointer",
  "&:hover": {
    borderColor: theme.palette.primary.main,
    backgroundColor:
      theme.palette.mode === "dark"
        ? alpha(theme.palette.primary.main, 0.1) // Slightly stronger tint on hover
        : lighten(theme.palette.primary.main, 0.92),
  },
}));

export const OptionButton = ({ children, sx = {}, ...props }) => (
  <Button
    {...props}
    sx={{
      margin: (theme) => theme.spacing(1),
      width: { xs: "100%", md: "45%" },
      textTransform: "none",
      fontSize: "1rem",
      borderRadius: "10px",
      borderColor: (theme) => theme.palette.divider,
      borderBottom: (theme) => `4px solid ${theme.palette.divider}`,
      display: "flex",
      justifyContent: "left",
      textAlign: "left",
      padding: (theme) => theme.spacing(1.5),
      "&:hover": {
        borderColor: (theme) => theme.palette.divider,
        borderBottom: (theme) => `4px solid ${theme.palette.divider}`,
        backgroundColor: (theme) => theme.palette.background.paperHover,
      },
      "& .MuiSvgIcon-root": {
        color: (theme) => theme.palette.text.main,
      },
      "&.MuiButton-contained": {
        outline: "none",
        backgroundColor: (theme) => theme.palette.text.marked,
        fontWeight: "bold",
        color: (theme) => theme.palette.text.white,
        borderColor: (theme) => darken(theme.palette.text.marked, 0.2),
        "& .MuiSvgIcon-root": {
          color: (theme) => theme.palette.text.white,
        },
      },
      // Allow background color to be overridden
      backgroundColor: (theme) => theme.palette.background.paper,
      ...sx,
    }}
  >
    {children}
  </Button>
);
//

export const GradientBackground = styled(Box)(({ theme, noSvg = false }) => ({
  position: "relative",
  color: theme.palette.common.white,
  overflow: "hidden",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background:
      "radial-gradient(circle at center, rgb(106, 80, 243) 0%, rgb(62, 46, 141) 82%)",
    zIndex: 1,
  },
  "&::after": {
    content: noSvg ? "none" : '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundImage: noSvg ? "none" : 'url("/img/hepp.svg")',
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    opacity: 0.7,
    zIndex: 2,
  },
  "& > *": {
    position: "relative",
    zIndex: 3,
  },
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: "50px",
  padding: "12px 30px",
  fontWeight: "bold",
  textTransform: "none",
  transition: "all 0.3s ease",
  "&:hover": {
    transform: "translateY(-2px)",
    boxShadow: "0 6px 20px rgba(107, 70, 193, 0.4)",
  },
}));

export const FeatureSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(10, 0),
  background: theme.palette.background.default,
  position: "relative",
}));

export const FeatureList = styled(List)(({ theme }) => ({
  "& .MuiListItem-root": {
    "& .MuiListItemIcon-root": {
      // Target the icon INSIDE the list item
      backgroundColor: "transparent !important",
      "&:hover": {
        backgroundColor: "transparent !important",
      },
    },
  },
  "& *": {
    pointerEvents: "none",
    transition: "none !important",
    "&:hover": {
      backgroundColor: "transparent !important",
      color: "inherit !important",
      transform: "none !important",
      boxShadow: "none !important",
    },
  },
}));

export const ResponsiveVideo = ({
  light,
  dark,
  alt,
  maxWidth,
  theme,
  styled = false,
}) => {
  const videoRef = useRef(null);

  const containerRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const videoSrc = theme.palette.mode === "dark" ? dark : light;

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      },
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (videoRef.current) {
      if (isVisible) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  }, [isVisible]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load();
    }
  }, [videoSrc]);

  const videoStyle = {
    width: "100%",
    height: "auto",
    borderRadius: "13px",
    ...(styled && {
      border: "2px solid #e8e8e8",
      boxShadow:
        "rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px",
    }),
  };

  return (
    <Link to="/app" style={{ textDecoration: "none" }}>
      <div ref={containerRef} style={{ maxWidth: `100%`, margin: "0 auto" }}>
        <video
          ref={videoRef}
          key={videoSrc}
          loop
          muted
          playsInline
          style={videoStyle}
        >
          <source src={videoSrc} type="video/mp4" />
          {alt}
        </video>
      </div>
    </Link>
  );
};
