import React from "react";
import { Box, Typography, Chip, Tooltip } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { doc, updateDoc, arrayUnion, arrayRemove } from "firebase/firestore";
import { useSnackbar } from "./SnackbarHandler";
import { db } from "./firebase";
import StarIcon from "@mui/icons-material/Star";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";

const ShortcutBox = ({
  id,
  icon,
  title,
  subtitle,
  path,
  label,
  diffView = false,
  user,
  isFavorite,
  onFavoriteChange,
  isEmpty = false,
  currentSubject,
  subjectOrCategory,
  isFavoriteView = false,
  isRoomCreatingView = false,
  isSelected = false,
  onSelect,
}) => {
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";
  const isAllakando = theme.palette.themeType === "allakando";

  const { showSnackbar } = useSnackbar();

  const handleFavoriteClick = async (e) => {
    e.preventDefault();
    if (!user?.uid) return;

    const userRef = doc(db, "users", user.uid);
    const newIsFavorite = !isFavorite;
    const favoriteItem = {
      id,
      subject: currentSubject,
    };

    try {
      await updateDoc(userRef, {
        favoriteShortcuts: newIsFavorite
          ? arrayUnion(favoriteItem)
          : arrayRemove(favoriteItem),
      });
      if (onFavoriteChange) {
        onFavoriteChange(id, newIsFavorite);
      }
      showSnackbar(
        newIsFavorite
          ? `${title} lades till i favoriter`
          : `${title} togs bort från favoriter`,
        newIsFavorite ? "success" : "info",
      );
    } catch (error) {
      console.error("Error updating favorites:", error);
      showSnackbar("Ett fel uppstod när favoriter skulle uppdateras", "error");
    }
  };

  const handleClick = (e) => {
    if (isRoomCreatingView) {
      e.preventDefault();
      onSelect?.();
    }
  };

  const getBackgroundColor = () => {
    if (isAllakando) return theme.palette.background.card;
    return isDark ? "rgba(255, 255, 255, 0.05)" : "rgba(223, 222, 225, 0.19)";
  };

  const getIconColor = () => {
    if (isAllakando) return theme.palette.primary.main;
    return isDark ? "rgba(255, 255, 255, 0.7)" : "#8b5cf6";
  };

  const getHoverBackgroundColor = () => {
    if (isAllakando) return theme.palette.background.cardHover;
    return isDark ? "rgba(255, 255, 255, 0.1)" : "rgba(139, 92, 246, 0.1)";
  };

  const getHoverIconColor = () => {
    if (isAllakando) return theme.palette.primary.hover;
    return isDark ? "rgb(16, 151, 218)" : "#6b46c1";
  };

  const getLabelStyle = (labelText) => {
    if (isAllakando) {
      return {
        backgroundColor: theme.palette.label.background,
        color: theme.palette.label.text,
      };
    }

    if (labelText === "NYTT") {
      return {
        backgroundColor: isDark ? "#2B4C2A" : "#C5F2B8",
        color: isDark ? "#3bbd28" : "#249314",
      };
    }

    if (labelText === "UPPDATERAD") {
      return {
        backgroundColor: isDark ? "#4C2B4C" : "#F2B8F2",
        color: isDark ? "#bd28bd" : "#931493",
      };
    }

    if (labelText === "INTERAKTIV") {
      return {
        backgroundColor: isDark ? "#300000" : "#FFCDD2",
        color: isDark ? "#FF5252" : "#B71C1C",
      };
    }
    return {
      backgroundColor: isDark ? "#2a3b4c" : "#e0e7ff",
      color: isDark ? "#60a5fa" : "#3b82f6",
    };
  };

  const BoxWrapper = isRoomCreatingView ? Box : Link;
  const boxProps = isRoomCreatingView
    ? { onClick: handleClick }
    : { to: path, style: { textDecoration: "none" } };

  return (
    <BoxWrapper {...boxProps}>
      <Box
        sx={{
          backgroundColor: getBackgroundColor(),
          borderRadius: "12px",
          padding: "16px",
          cursor: isEmpty ? "default" : "pointer",
          height: diffView ? "185px" : "100px",
          transition: "all 0.3s ease",
          position: "relative",
          border: isEmpty
            ? "1px dashed rgba(128, 128, 128, 0.4)"
            : isAllakando
              ? undefined
              : isDark
                ? undefined
                : "1px solid #e0e0e080",
          "&:hover": !isEmpty && {
            backgroundColor: getHoverBackgroundColor(),
            transform: "translateY(-2px)",
            "& .icon, & .star": {
              color: getHoverIconColor(),
            },
          },
        }}
      >
        {isRoomCreatingView && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: isSelected ? "" : "rgba(0, 0, 0, 0.7)",
              borderRadius: "12px",
              display: "flex",
              alignItems: "center",
              justifyContent: isSelected ? "flex-end" : "center",
              opacity: isSelected ? 1 : 0,
              transition: "opacity 0.3s ease",
              "&:hover": {
                opacity: 1,
              },
              padding: isSelected ? "0 5px" : 0,
            }}
          >
            {isSelected ? (
              <RemoveCircleIcon
                sx={{
                  color: "#F44336",
                  fontSize: 30,
                }}
              />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  borderRadius: "12px",
                }}
              >
                <Box
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onSelect?.();
                  }}
                  sx={{
                    flex: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "rgba(255, 255, 255, 0.1)",
                    },
                  }}
                >
                  <AddIcon sx={{ color: "#fff", fontSize: 30 }} />
                  <Typography sx={{ color: "#fff", ml: 1, fontWeight: "bold" }}>
                    Lägg till
                  </Typography>
                </Box>
                <Box
                  component="a"
                  href={path}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => e.stopPropagation()}
                  sx={{
                    flex: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderLeft: "1px solid rgba(255, 255, 255, 0.2)",
                    textDecoration: "none",
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "rgba(255, 255, 255, 0.1)",
                    },
                  }}
                >
                  <OpenInNewIcon sx={{ color: "#fff", fontSize: 25 }} />
                  <Typography sx={{ color: "#fff", ml: 1, fontWeight: "bold" }}>
                    Öppna
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        )}

        {(isFavoriteView ? currentSubject : label) && (
          <Chip
            label={isFavoriteView ? currentSubject : label}
            size="small"
            sx={{
              position: "absolute",
              top: "8px",
              right: "8px",
              fontWeight: "600",
              fontSize: isAllakando ? "0.8rem" : "0.7rem",
              height: "20px",
              ...getLabelStyle(label),
            }}
          />
        )}

        {!isRoomCreatingView &&
          (isFavorite ? (
            <Tooltip title="Ta bort från favoriter">
              <StarIcon
                className="star"
                onClick={handleFavoriteClick}
                sx={{
                  position: "absolute",
                  bottom: "8px",
                  right: "8px",
                  fontSize: 23,
                  color: theme.palette.primary.main,
                  transition: "color 0.3s ease",
                  cursor: "pointer",
                  "&:hover": {},
                }}
              />
            </Tooltip>
          ) : (
            <Tooltip title="Lägg till i favoriter">
              <StarBorderIcon
                className="star"
                onClick={handleFavoriteClick}
                sx={{
                  position: "absolute",
                  bottom: "8px",
                  right: "8px",
                  fontSize: 23,
                  color: isDark
                    ? "rgba(255, 255, 255, 0.5)"
                    : "rgba(255, 255, 255, 0.5)",
                  transition: "color 0.3s ease",
                  cursor: "pointer",
                }}
              />
            </Tooltip>
          ))}

        <Box
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: diffView ? "column" : "row",
            alignItems: "center",
            justifyContent: diffView ? "center" : "flex-start",
          }}
        >
          {typeof icon === "string" ? (
            <img
              src={icon}
              alt={title}
              style={{
                width: 35,
                height: 35,
                objectFit: "cover",
                borderRadius: "50%",
                marginRight: diffView ? 0 : 16,
                marginBottom: diffView ? 8 : 0,
              }}
            />
          ) : (
            React.cloneElement(icon, {
              sx: {
                fontSize: 40,
                color: getIconColor(),
                mr: diffView ? 0 : 2,
                mb: diffView ? 1 : 0,
                transition: "color 0.3s ease",
              },
              className: "icon",
              style: {
                marginBottom: diffView ? "8px" : 0,
                transition: "color 0.3s ease",
              },
              size: 40,
              color: getIconColor(),
            })
          )}
          <Box
            sx={{
              textAlign: diffView ? "center" : "left",
              display: "flex",
              flexDirection: "column",
              alignItems: diffView ? "center" : "flex-start",
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                color: isAllakando
                  ? theme.palette.text.primary
                  : isDark
                    ? "rgba(255, 255, 255, 0.7)"
                    : "#2c2f33",
                fontWeight: 600,
                fontSize: diffView ? 19 : isAllakando ? 17 : 15,
                marginTop: diffView ? "5px" : undefined,
              }}
            >
              {title}
            </Typography>
            {subtitle && (
              <Typography
                variant="body2"
                sx={{
                  color: isAllakando
                    ? theme.palette.text.primary
                    : isDark
                      ? "rgba(255, 255, 255, 0.5)"
                      : "#718096",
                  fontSize: isAllakando && !diffView ? 15 : 14,
                  marginTop: diffView ? "10px" : undefined,
                  width: diffView ? "80%" : undefined,
                }}
              >
                {subtitle}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </BoxWrapper>
  );
};

export default ShortcutBox;
