import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  IconButton,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Paper,
  List,
  ListItem,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import HoverableImage from "./HoverableImage";

import { alpha, lighten } from "@mui/material/styles";

const RolePlayEditor = ({
  open,
  onClose,
  lessonData,
  onSave,
  userId,
  showSnackbar,
  theme,
  isGeneratingImages,
  loadingImageIndex,
  handleImageButtonClick,
}) => {
  const [editedData, setEditedData] = useState(() => {
    return {
      ...lessonData,
      roles: lessonData.roles.map((role) => ({
        ...role,
        imageUrl: role.imageUrl, // Explicitly preserve imageUrl
        responsibilities: [...role.responsibilities],
        arguments: role.arguments.map((arg) => ({ ...arg })),
      })),
    };
  });

  useEffect(() => {
    setEditedData({
      ...lessonData,
      roles: lessonData.roles.map((role) => ({
        ...role,
        imageUrl: role.imageUrl, // Explicitly preserve imageUrl
        responsibilities: [...role.responsibilities],
        arguments: role.arguments.map((arg) => ({ ...arg })),
      })),
    });
  }, [lessonData]);

  const handleImageUpdate = (roleIndex, imageUrl) => {
    setEditedData((prev) => {
      const newRoles = [...prev.roles];
      newRoles[roleIndex] = {
        ...newRoles[roleIndex],
        imageUrl: imageUrl,
      };
      return { ...prev, roles: newRoles };
    });
  };

  const handleTitleChange = (e) => {
    setEditedData((prev) => ({
      ...prev,
      title: e.target.value,
    }));
  };

  const handleDescriptionChange = (e) => {
    setEditedData((prev) => ({
      ...prev,
      description: e.target.value,
    }));
  };

  // Role card management
  const addRole = () => {
    const newRole = {
      role: "",
      description: "",
      responsibilities: [""],
      arguments: [{ headline: "", argument: "" }],
      conviction: "",
      imageUrl: null, // Explicitly include imageUrl
    };
    setEditedData((prev) => ({
      ...prev,
      roles: [...prev.roles, newRole],
    }));
  };

  const removeRole = (roleIndex) => {
    setEditedData((prev) => ({
      ...prev,
      roles: prev.roles.filter((_, index) => index !== roleIndex),
    }));
  };

  const handleRoleChange = (roleIndex, field, value) => {
    setEditedData((prev) => {
      const newRoles = [...prev.roles];
      newRoles[roleIndex] = {
        ...prev.roles[roleIndex],
        imageUrl: prev.roles[roleIndex].imageUrl, // Explicitly preserve imageUrl
        [field]: value,
      };
      return { ...prev, roles: newRoles };
    });
  };
  // Responsibilities management
  const addResponsibility = (roleIndex) => {
    setEditedData((prev) => {
      const newRoles = [...prev.roles];
      newRoles[roleIndex].responsibilities.push("");
      return { ...prev, roles: newRoles };
    });
  };

  const removeResponsibility = (roleIndex, respIndex) => {
    setEditedData((prev) => {
      const newRoles = [...prev.roles];
      newRoles[roleIndex].responsibilities = newRoles[
        roleIndex
      ].responsibilities.filter((_, index) => index !== respIndex);
      return { ...prev, roles: newRoles };
    });
  };

  const handleResponsibilityChange = (roleIndex, respIndex, value) => {
    setEditedData((prev) => {
      const newRoles = [...prev.roles];
      newRoles[roleIndex].responsibilities[respIndex] = value;
      return { ...prev, roles: newRoles };
    });
  };

  // Arguments management
  const addArgument = (roleIndex) => {
    setEditedData((prev) => {
      const newRoles = [...prev.roles];
      newRoles[roleIndex].arguments.push({ headline: "", argument: "" });
      return { ...prev, roles: newRoles };
    });
  };

  const removeArgument = (roleIndex, argIndex) => {
    setEditedData((prev) => {
      const newRoles = [...prev.roles];
      newRoles[roleIndex].arguments = newRoles[roleIndex].arguments.filter(
        (_, index) => index !== argIndex,
      );
      return { ...prev, roles: newRoles };
    });
  };

  const handleArgumentChange = (roleIndex, argIndex, field, value) => {
    setEditedData((prev) => {
      const newRoles = [...prev.roles];
      newRoles[roleIndex].arguments[argIndex] = {
        ...newRoles[roleIndex].arguments[argIndex],
        [field]: value,
      };
      return { ...prev, roles: newRoles };
    });
  };

  // Debate starters management
  const addDebateStarter = () => {
    setEditedData((prev) => ({
      ...prev,
      debate_starters: [...prev.debate_starters, ""],
    }));
  };

  const removeDebateStarter = (index) => {
    setEditedData((prev) => ({
      ...prev,
      debate_starters: prev.debate_starters.filter((_, i) => i !== index),
    }));
  };

  const handleDebateStarterChange = (index, value) => {
    setEditedData((prev) => {
      const newStarters = [...prev.debate_starters];
      newStarters[index] = value;
      return { ...prev, debate_starters: newStarters };
    });
  };

  const handleSave = () => {
    onSave(editedData);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: { minHeight: "80vh", minWidth: "1200px" },
      }}
    >
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: "600" }}>
            Redigera Rollspel
          </Typography>
          <Box>
            <Button
              startIcon={<SaveIcon />}
              variant="purp"
              onClick={handleSave}
              sx={{ mr: 1 }}
            >
              Spara
            </Button>
            <Button
              startIcon={<CancelIcon />}
              variant="print"
              onClick={onClose}
            >
              Avbryt
            </Button>
          </Box>
        </Box>
      </DialogTitle>

      <DialogContent dividers>
        <Box sx={{ mb: 4 }}>
          <Typography variant="subtitle1" sx={{ fontWeight: "500", mb: 1 }}>
            Titel
          </Typography>
          <TextField
            fullWidth
            value={editedData.title}
            onChange={handleTitleChange}
            sx={{ mb: 2 }}
          />

          <Typography variant="subtitle1" sx={{ fontWeight: "500", mb: 1 }}>
            Beskrivning
          </Typography>
          <TextField
            fullWidth
            value={editedData.description}
            onChange={handleDescriptionChange}
            multiline
            rows={3}
            sx={{ mb: 2 }}
          />
        </Box>

        {/* Role Cards Section */}
        {/* Role Cards Grid Section */}
        <Grid container spacing={2}>
          {editedData.roles.map((role, roleIndex) => (
            <Grid item xs={12} md={6} key={roleIndex}>
              <Accordion
                defaultExpanded
                sx={{
                  mb: 2,
                  backgroundColor: "background.paperHover",
                  border: "1px solid",
                  borderColor: alpha("#6D31ED", 0.12),
                  borderRadius: 4,
                  "&:hover": {
                    borderColor: alpha("#6D31ED", 0.24),
                  },

                  boxShadow: "0px 2px 4px rgba(109, 49, 237, 0.05)",
                  "&:before": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  sx={{
                    borderRadius: 4,
                    "&.Mui-expanded": {
                      backgroundColor: lighten("#6D31ED", 0.94),
                    },
                    "&:hover": {
                      backgroundColor: lighten("#6D31ED", 0.96),
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ fontWeight: "600" }}>
                      Roll {roleIndex + 1}: {role.role || "Ny roll"}
                    </Typography>
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        removeRole(roleIndex);
                      }}
                      sx={{ mr: 2 }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </AccordionSummary>

                <AccordionDetails>
                  <Box
                    sx={{ display: "flex", flexDirection: "column", gap: 1 }}
                  >
                    <Paper
                      variant="outlined"
                      sx={{ p: 2, borderRadius: 2, mt: 3 }}
                    >
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: "500", mb: 2 }}
                      >
                        Rolltitel
                      </Typography>
                      <TextField
                        fullWidth
                        value={role.role}
                        onChange={(e) =>
                          handleRoleChange(roleIndex, "role", e.target.value)
                        }
                      />
                    </Paper>
                    {/* Image Section */}
                    <Box
                      sx={{ backgroundColor: "#f9f9f9", p: 2, borderRadius: 2 }}
                    >
                      <Box sx={{ width: "270px", margin: "0 auto" }}>
                        <HoverableImage
                          imageUrl={role.imageUrl}
                          onImageUpdate={(url) =>
                            handleImageUpdate(roleIndex, url)
                          }
                          userId={userId}
                          showSnackbar={showSnackbar}
                          onGenerateClick={() =>
                            handleImageButtonClick(roleIndex)
                          }
                          theme={theme}
                          height="270px"
                          isLoading={isGeneratingImages}
                          loadingIndex={loadingImageIndex}
                          currentIndex={roleIndex}
                        />
                      </Box>
                    </Box>
                    <Paper
                      variant="outlined"
                      sx={{ p: 2, borderRadius: 2, mt: 3 }}
                    >
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: "500", mb: 2 }}
                      >
                        Rollbeskrivning
                      </Typography>
                      <TextField
                        fullWidth
                        value={role.description}
                        onChange={(e) =>
                          handleRoleChange(
                            roleIndex,
                            "description",
                            e.target.value,
                          )
                        }
                        multiline
                        rows={2}
                      />
                    </Paper>
                    {/* Responsibilities Section */}
                    <Paper
                      variant="outlined"
                      sx={{ p: 2, borderRadius: 2, mt: 3 }}
                    >
                      <Typography
                        variant="subtitle1"
                        sx={{ mb: 2, fontWeight: "500" }}
                      >
                        Ansvarsområden
                      </Typography>
                      {role.responsibilities.map((resp, respIndex) => (
                        <Box
                          key={respIndex}
                          sx={{ display: "flex", gap: 1, mb: 2 }}
                        >
                          <TextField
                            fullWidth
                            value={resp}
                            onChange={(e) =>
                              handleResponsibilityChange(
                                roleIndex,
                                respIndex,
                                e.target.value,
                              )
                            }
                          />
                          <IconButton
                            onClick={() =>
                              removeResponsibility(roleIndex, respIndex)
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      ))}
                      <Button
                        startIcon={<AddIcon />}
                        onClick={() => addResponsibility(roleIndex)}
                        variant="print"
                        size="small"
                      >
                        Lägg till ansvarsområde
                      </Button>
                    </Paper>

                    {/* Arguments Section */}
                    <Paper
                      variant="outlined"
                      sx={{ p: 2, borderRadius: 2, mt: 3 }}
                    >
                      <Typography
                        variant="subtitle1"
                        sx={{ mb: 2, fontWeight: "500" }}
                      >
                        Argument
                      </Typography>
                      {role.arguments.map((arg, argIndex) => (
                        <Box key={argIndex} sx={{ mb: 3 }}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              mb: 1,
                            }}
                          >
                            <TextField
                              fullWidth
                              label="Rubrik"
                              value={arg.headline}
                              onChange={(e) =>
                                handleArgumentChange(
                                  roleIndex,
                                  argIndex,
                                  "headline",
                                  e.target.value,
                                )
                              }
                              sx={{ mr: 1, mt: 3 }}
                            />
                            <IconButton
                              onClick={() =>
                                removeArgument(roleIndex, argIndex)
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                          <TextField
                            fullWidth
                            sx={{ mt: 1 }}
                            label="Argument"
                            value={arg.argument}
                            onChange={(e) =>
                              handleArgumentChange(
                                roleIndex,
                                argIndex,
                                "argument",
                                e.target.value,
                              )
                            }
                            multiline
                            rows={2}
                          />
                        </Box>
                      ))}
                      <Button
                        startIcon={<AddIcon />}
                        onClick={() => addArgument(roleIndex)}
                        variant="print"
                        size="small"
                      >
                        Lägg till argument
                      </Button>
                    </Paper>
                    <Paper
                      variant="outlined"
                      sx={{ p: 2, borderRadius: 2, mt: 3 }}
                    >
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: "500", mb: 2 }}
                      >
                        Övertygelse
                      </Typography>
                      <TextField
                        fullWidth
                        value={role.conviction}
                        onChange={(e) =>
                          handleRoleChange(
                            roleIndex,
                            "conviction",
                            e.target.value,
                          )
                        }
                        multiline
                        rows={2}
                      />
                    </Paper>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Grid>
          ))}
        </Grid>

        <Button
          startIcon={<AddIcon />}
          onClick={addRole}
          variant="print"
          sx={{ mt: 2, mb: 4 }}
        >
          Lägg till roll
        </Button>

        {/* Debate Starters Section */}
        <Paper variant="outlined" sx={{ p: 2, borderRadius: 2, mt: 4 }}>
          <Typography variant="h6" sx={{ mb: 2, fontWeight: "600" }}>
            Debattstartare
          </Typography>
          {editedData.debate_starters.map((starter, index) => (
            <Box key={index} sx={{ display: "flex", gap: 1, mb: 2 }}>
              <TextField
                fullWidth
                value={starter}
                onChange={(e) =>
                  handleDebateStarterChange(index, e.target.value)
                }
              />
              <IconButton onClick={() => removeDebateStarter(index)}>
                <DeleteIcon />
              </IconButton>
            </Box>
          ))}
          <Button
            startIcon={<AddIcon />}
            onClick={addDebateStarter}
            variant="print"
            size="small"
          >
            Lägg till debattstartare
          </Button>
        </Paper>
      </DialogContent>
    </Dialog>
  );
};

export default RolePlayEditor;
