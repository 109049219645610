import React from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  Tooltip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import HoverableImage from "./HoverableImage";
import { alpha, darken, lighten } from "@mui/material/styles";

const LetterContent = ({
  lessonData,
  setLessonData,
  isEditMode,
  theme,
  isGeneratingImages,
  loadingImageIndex,
  handleImageButtonClick,
  user,
  showSnackbar,
  styles,
  viewMode,
}) => {
  const handleTitleChange = (e) => {
    setLessonData((prev) => ({
      ...prev,
      title: e.target.value,
    }));
  };

  const handleEntryChange = (index, field, value) => {
    setLessonData((prev) => ({
      ...prev,
      correspondence: prev.correspondence.map((entry, i) =>
        i === index ? { ...entry, [field]: value } : entry,
      ),
    }));
  };

  const handleDeleteEntry = (index) => {
    setLessonData((prev) => ({
      ...prev,
      correspondence: prev.correspondence.filter((_, i) => i !== index),
    }));
  };

  const handleAddEntry = () => {
    setLessonData((prev) => ({
      ...prev,
      correspondence: [
        ...prev.correspondence,
        {
          sender: "",
          recipient: "",
          date: new Date().toISOString().split("T")[0],
          content: "",
          imageUrl: null,
        },
      ],
    }));
  };

  return (
    <>
      {isEditMode ? (
        <TextField
          fullWidth
          value={lessonData.title}
          onChange={handleTitleChange}
          sx={{
            ...styles.editingTextField,
            mb: 6,
            "& .MuiInputBase-input": {
              textAlign: "center",
              fontSize: "2.5rem",
              fontFamily: "'Palatino', serif",
              color: theme.palette.mode === "light" ? "#5c4a42" : "#d4a373",
            },
          }}
        />
      ) : (
        <Typography
          variant="h2"
          sx={{
            textAlign: "center",
            mb: 6,
            fontSize: "2.5rem",
            fontFamily: "'Palatino', serif",
            color: theme.palette.mode === "light" ? "#5c4a42" : "#d4a373",
            borderBottom:
              theme.palette.mode === "light"
                ? "2px solid #d4a373"
                : "2px solid #666",
            pb: 2,
          }}
        >
          {lessonData.title}
        </Typography>
      )}

      {lessonData.correspondence.map((letter, index) => (
        <Box
          key={index}
          sx={{
            mb: 8,
            position: "relative",
            "&::after": {
              content: '""',
              position: "absolute",
              bottom: "-30px",
              left: "50%",
              transform: "translateX(-50%)",
              width: "40%",
              height: "1px",
              backgroundColor:
                theme.palette.mode === "light" ? "#d4a373" : "#666",
              opacity: 0.5,
            },
          }}
        >
          {isEditMode && (
            <Box
              sx={{
                position: "absolute",
                top: -20,
                right: -20,
                opacity: 0,
                transition: "opacity 0.2s",
                ".MuiBox-root:hover &": { opacity: 1 },
              }}
            >
              <Tooltip title="Ta bort brev">
                <IconButton onClick={() => handleDeleteEntry(index)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Box>
          )}

          <Box
            sx={{
              ...styles.freshBox,

              display: "flex",
              gap: 4,
              flexDirection: { xs: "column", md: "row" },

              backgroundColor:
                index % 2 === 0
                  ? alpha(theme.palette.primary.main, 0.02)
                  : alpha(theme.palette.text.primary, 0.05),
              padding: "30px",
              borderRadius: 2,
            }}
          >
            <Box
              sx={{
                flex: "0 0 300px",
              }}
            >
              <Box
                sx={{
                  p: 2,
                  backgroundColor:
                    theme.palette.mode === "light" ? "white" : "#1a1a1a",
                  borderRadius: "7px",
                  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                  transform: index % 2 === 0 ? "rotate(-1deg)" : "rotate(1deg)",
                  transition: "transform 0.3s ease",
                  "&:hover": {
                    transform: "rotate(0deg)",
                  },
                }}
              >
                <HoverableImage
                  imageUrl={letter.imageUrl}
                  isLoading={isGeneratingImages}
                  loadingIndex={loadingImageIndex}
                  currentIndex={index}
                  onGenerateClick={() => handleImageButtonClick(index)}
                  onImageUpdate={(url) => {
                    setLessonData((prev) => ({
                      ...prev,
                      correspondence: prev.correspondence.map((e, i) =>
                        i === index ? { ...e, imageUrl: url } : e,
                      ),
                    }));
                  }}
                  userId={user.uid}
                  theme={theme}
                  showSnackbar={showSnackbar}
                  height="200px"
                />
              </Box>
            </Box>

            <Box sx={{ flex: 1 }}>
              {isEditMode ? (
                <Box
                  sx={{
                    mb: 3,
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                  }}
                >
                  <TextField
                    fullWidth
                    label="Från"
                    value={letter.sender}
                    onChange={(e) =>
                      handleEntryChange(index, "sender", e.target.value)
                    }
                    sx={styles.editingTextField}
                  />
                  <TextField
                    fullWidth
                    label="Till"
                    value={letter.recipient}
                    onChange={(e) =>
                      handleEntryChange(index, "recipient", e.target.value)
                    }
                    sx={styles.editingTextField}
                  />
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <CalendarTodayIcon
                      sx={{
                        color:
                          theme.palette.mode === "light" ? "#d4a373" : "#666",
                      }}
                    />
                    <TextField
                      type="date"
                      value={letter.date}
                      onChange={(e) =>
                        handleEntryChange(index, "date", e.target.value)
                      }
                      sx={{
                        ...styles.editingTextField,
                        "& .MuiInputBase-input": {
                          fontFamily: "'Palatino', serif",
                          color:
                            theme.palette.mode === "light"
                              ? "#5c4a42"
                              : "#d4a373",
                          fontStyle: "italic",
                        },
                      }}
                    />
                  </Box>
                </Box>
              ) : (
                <Box sx={{ mb: 3 }}>
                  <Typography
                    sx={{
                      fontSize: "1.1rem",
                      fontFamily: "'Palatino', serif",
                    }}
                  >
                    <Typography
                      component="span"
                      sx={{
                        fontWeight: 500,
                        fontFamily: "'Palatino', serif",
                      }}
                    >
                      Från:
                    </Typography>{" "}
                    {letter.sender}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "1.1rem",
                      fontFamily: "'Palatino', serif",

                      mb: 2,
                    }}
                  >
                    <Typography
                      component="span"
                      sx={{
                        fontWeight: 500,
                        fontFamily: "'Palatino', serif",
                      }}
                    >
                      Till:
                    </Typography>{" "}
                    {letter.recipient}
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <CalendarTodayIcon
                      sx={{
                        color:
                          theme.palette.mode === "light" ? "#d4a373" : "#666",
                      }}
                    />
                    <Typography
                      sx={{
                        fontFamily: "'Palatino', serif",
                        color:
                          theme.palette.mode === "light"
                            ? "#5c4a42"
                            : "#d4a373",
                        fontStyle: "italic",
                      }}
                    >
                      {new Date(letter.date).toLocaleDateString("sv-SE", {
                        weekday: "long",
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}
                    </Typography>
                  </Box>
                </Box>
              )}

              {isEditMode ? (
                <TextField
                  multiline
                  fullWidth
                  minRows={6}
                  value={letter.content}
                  onChange={(e) =>
                    handleEntryChange(index, "content", e.target.value)
                  }
                  sx={{
                    ...styles.editingTextField,
                    "& .MuiInputBase-input": {
                      whiteSpace: "pre-wrap",
                      lineHeight: 1.8,
                      fontFamily: "'Palatino', serif",
                      color:
                        theme.palette.mode === "light" ? "#2d2d2d" : "#e0e0e0",
                    },
                  }}
                />
              ) : (
                <Typography
                  sx={{
                    whiteSpace: "pre-wrap",
                    lineHeight: 1.8,
                    fontFamily: "'Palatino', serif",
                    fontSize: "1.1rem",
                    color:
                      theme.palette.mode === "light" ? "#2d2d2d" : "#e0e0e0",
                    position: "relative",
                    pl: 4,
                    "&::before": {
                      content: '"\\201C"',
                      position: "absolute",
                      left: 0,
                      top: -10,
                      fontSize: "3rem",
                      color:
                        theme.palette.mode === "light" ? "#d4a373" : "#666",
                      opacity: 0.3,
                    },
                  }}
                >
                  {letter.content}
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      ))}

      {isEditMode && (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4, mb: 6 }}>
          <Button
            variant="print"
            onClick={handleAddEntry}
            startIcon={<AddIcon />}
          >
            Lägg till brev
          </Button>
        </Box>
      )}
    </>
  );
};

export default LetterContent;
