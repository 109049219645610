import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, Button } from "@mui/material";
import { Palette as PaletteIcon } from "@mui/icons-material";
import { Abc as AbcIcon } from "@mui/icons-material";
import { EditNote as EditNoteIcon } from "@mui/icons-material";
import GenerateIcon from "@mui/icons-material/AutoAwesome";
import ShortcutBox from "./ShortcutBox";
import { shortcuts } from "./DashboardToolsShortcuts";

import {
  doc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  getDoc,
  onSnapshot,
} from "firebase/firestore";

import { db } from "./firebase";

const DashboardLessonsShortcuts = ({
  role,
  resetCategory,
  theme,
  category,
  user,
}) => {
  const [selectedCategory, setSelectedCategory] = useState("Populära");

  const excludedShortcuts = ["Dashboard", "Shortcut Title 2"];

  const [favoriteShortcuts, setFavoriteShortcuts] = useState([]);

  useEffect(() => {
    if (!user?.uid) return;

    const userRef = doc(db, "users", user.uid);

    const unsubscribe = onSnapshot(
      userRef,
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          // favoriteShortcuts is now an array of objects: [{id: string, subject: string}]
          setFavoriteShortcuts(data.favoriteShortcuts || []);
        }
      },
      (error) => {
        console.error("Error listening to favorites:", error);
      },
    );

    return () => unsubscribe();
  }, [user]);

  useEffect(() => {
    // If category prop is provided, use it instead of 'Lektioner'
    setSelectedCategory(category || "Lektioner");
  }, [role, resetCategory, category]);

  const filteredShortcuts = shortcuts.filter(
    (shortcut) =>
      (shortcut.roles.includes(role) || shortcut.roles.includes("both")) &&
      !excludedShortcuts.includes(shortcut.title) &&
      (selectedCategory === "Populära" ||
        shortcut.category === selectedCategory),
  );

  // Only get categories if no category prop is provided
  const categories = !category
    ? [
        ...new Set(
          shortcuts
            .filter(
              (shortcut) =>
                (shortcut.roles.includes(role) ||
                  shortcut.roles.includes("both")) &&
                !excludedShortcuts.includes(shortcut.title),
            )
            .map((shortcut) => shortcut.category),
        ),
      ].filter(Boolean)
    : [];

  const limitedShortcuts =
    selectedCategory === "Populära"
      ? filteredShortcuts.slice(0, 12)
      : filteredShortcuts;

  useEffect(() => {
    console.log("category selected: ", selectedCategory);
  }, [selectedCategory]);

  return (
    <Box sx={{ mt: 4 }}>
      {/* Only render categories if no category prop is provided */}
      {!category && (
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 1,
            mb: 6,
            display: "none",
          }}
        >
          {categories.map((cat, index) => (
            <Button
              key={index}
              variant={selectedCategory === cat ? "contained" : "outlined"}
              onClick={() => setSelectedCategory(cat)}
              startIcon={cat === "Lektioner" ? <GenerateIcon /> : null}
              sx={{
                borderRadius: "30px",
                textTransform: "none",
                fontWeight: "400",
                borderBottom:
                  theme.palette.mode === "dark" ? "none" : undefined,
                "&:hover": {
                  borderBottom:
                    theme.palette.mode === "dark" ? "none" : undefined,
                },
              }}
            >
              {cat}
            </Button>
          ))}
        </Box>
      )}
      <Grid container spacing={2}>
        {limitedShortcuts.map((shortcut, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <ShortcutBox
              user={user}
              diffView={false}
              {...shortcut}
              currentSubject={category}
              isFavorite={favoriteShortcuts.some(
                (fav) => fav.id === shortcut.id,
              )}
              onFavoriteChange={(id, newIsFavorite) => {
                // The state will be automatically updated by the onSnapshot listener
                // No need to manually update favoriteShortcuts here
              }}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default DashboardLessonsShortcuts;
