import React, { useState, useEffect } from "react";
import {
  Drawer,
  Box,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  Paper,
  CircularProgress,
} from "@mui/material";
import { DeleteOutline, Close, OpenInNew } from "@mui/icons-material";
import ChevronRight from "@mui/icons-material/ChevronRight";

import {
  collection,
  query,
  where,
  getDocs,
  deleteDoc,
  orderBy,
  doc,
} from "firebase/firestore";
import { db } from "./firebase";
import Logger from "./Logger";

const VisionHistoryDrawer = ({
  open,
  onClose,
  user,
  theme,
  onVisionSelect,
}) => {
  const [visions, setVisions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedVision, setSelectedVision] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const isDark = theme.palette.mode === "dark";

  const fetchVisions = async () => {
    setLoading(true);
    try {
      let visionsQuery;
      if (user?.uid) {
        // Fetch user's visions
        visionsQuery = query(
          collection(db, "visions"),
          where("userId", "==", user.uid),
          orderBy("createdAt", "desc"),
        );
      } else {
        // Fetch example visions using predefined IDs
        const exampleIds = [
          "example1",
          "example2",
          "example3",
          "example4",
          "example5",
        ];
        visionsQuery = query(
          collection(db, "visions"),
          where("__name__", "in", exampleIds),
        );
      }

      const snapshot = await getDocs(visionsQuery);
      const visionsData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setVisions(visionsData);
    } catch (error) {
      console.error("Error fetching visions:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      fetchVisions();
    }
  }, [open, user]);

  const handleDelete = async (id, event) => {
    event.stopPropagation();
    try {
      await deleteDoc(doc(db, "visions", id));
      setVisions(visions.filter((vision) => vision.id !== id));
    } catch (error) {
      console.error("Error deleting vision:", error);
    }
  };

  const handleVisionClick = (vision) => {
    Logger.log(vision);
    onVisionSelect(vision);
    onClose();
  };
  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: {
            width: { xs: "100%", sm: "400px" },
            boxShadow: "none",
            borderLeft: isDark ? "1px solid #34414c" : "1px solid #e0e0e0",
            backgroundImage: "none",
          },
        }}
      >
        <Box
          sx={{
            p: 3,
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <IconButton onClick={onClose} color="inherit">
              <ChevronRight />
            </IconButton>
            <Typography variant="h6" sx={{ fontSize: "17px", ml: 2 }}>
              Sparade bildanalyser
            </Typography>
          </Box>

          {loading ? (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
              <CircularProgress />
            </Box>
          ) : visions.length === 0 ? (
            <Box sx={{ textAlign: "center", mt: 4, color: "text.secondary" }}>
              <Typography>
                {user?.uid
                  ? "Inga sparade bildanalyser än"
                  : "Laddar exempelanalyser..."}
              </Typography>
            </Box>
          ) : (
            <List sx={{ flexGrow: 1, overflow: "auto" }}>
              {visions.map((vision) => (
                <ListItem
                  key={vision.id}
                  sx={{
                    p: 0,
                    mb: 2,
                    cursor: "pointer",
                    "&:hover": {
                      "& .delete-button": {
                        opacity: 1,
                      },
                    },
                  }}
                  onClick={() => handleVisionClick(vision)}
                >
                  <Paper
                    elevation={1}
                    sx={{
                      width: "100%",
                      overflow: "hidden",
                      borderRadius: 2,
                    }}
                  >
                    <Box
                      sx={{
                        position: "relative",
                        height: "200px",
                        backgroundImage: `url(${vision.imageUrl})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        "&::after": {
                          content: '""',
                          position: "absolute",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          backgroundColor: "rgba(0,0,0,0.1)",
                        },
                      }}
                    >
                      <IconButton
                        className="delete-button"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          deleteDoc(doc(db, "visions", vision.id))
                            .then(() => {
                              setVisions(
                                visions.filter((v) => v.id !== vision.id),
                              );
                            })
                            .catch((error) => {
                              console.error("Error deleting vision:", error);
                            });
                        }}
                        sx={{
                          display: "none",
                          position: "absolute",
                          top: 8,
                          right: 8,
                          opacity: 0,
                          transition: "opacity 0.2s",
                          backgroundColor: "rgba(0,0,0,0.5)",
                          zIndex: 10,
                          "&:hover": {
                            backgroundColor: "rgba(0,0,0,0.7)",
                          },
                        }}
                      >
                        <DeleteOutline sx={{ color: "white" }} />
                      </IconButton>
                    </Box>

                    <Box sx={{ p: 2, display: "none" }}>
                      <Typography noWrap>
                        {vision.analysis?.slice(0, 100)}
                        {vision.analysis?.length > 100 ? "..." : ""}
                      </Typography>
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          window.open(
                            `/app/vision/${vision.id.substring(0, 10)}`,
                            "_blank",
                          );
                        }}
                        sx={{
                          display: "none",
                          mt: 1,
                          color: "text.secondary",
                          "&:hover": {
                            color: "primary.main",
                          },
                        }}
                      >
                        <OpenInNew fontSize="small" />
                      </IconButton>
                    </Box>
                  </Paper>
                </ListItem>
              ))}
            </List>
          )}
        </Box>
      </Drawer>

      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            pb: 1,
          }}
        >
          <Typography variant="h6">Bildanalys</Typography>
          <IconButton onClick={() => setDialogOpen(false)}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {selectedVision && (
            <>
              <Box
                component="img"
                src={selectedVision.imageUrl}
                sx={{
                  width: "100%",
                  maxHeight: "60vh",
                  objectFit: "contain",
                  borderRadius: 1,
                  mb: 2,
                }}
              />
              <Typography>{selectedVision.analysis}</Typography>
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default VisionHistoryDrawer;
