import React, { useState, useCallback, useEffect } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
  Box,
  Typography,
  Paper,
  CircularProgress,
  Button,
} from "@mui/material";
import { lighten } from "@mui/material/styles";
import NumbersIcon from "@mui/icons-material/Numbers";
import { useSnackbar } from "./SnackbarHandler";

import ImageGenerationModal from "./ImageGenerationModal";
import { Chip } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import {
  generateAndProcessImage,
  handleImageGenerationError,
} from "./functions";
import TimeBuilderEditDialog from "./TimeBuilderEditDialog";
import Logger from "./Logger";
import EditIcon from "@mui/icons-material/Edit";

import { IconButton, Tooltip } from "@mui/material";
import {
  RestartAlt,
  Shuffle,
  LockOpen,
  Lock,
  DeleteOutline,
} from "@mui/icons-material";

const TimelineSlot = ({
  index,
  event,
  onMove,
  isActive,
  showYears,
  showValidation,
  timelineData,
}) => {
  const [{ isDragging }, drag] = useDrag({
    type: "event",
    item: () => ({ type: "event", index, event }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: !!event && isActive && !showValidation,
  });

  const [{ isOver }, drop] = useDrop({
    accept: "event",
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
    hover: (draggedItem, monitor) => {
      if (!monitor.isOver({ shallow: true })) return;

      const dragIndex = draggedItem.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) return;

      onMove(dragIndex, hoverIndex);
      draggedItem.index = hoverIndex;
    },
  });

  const ref = (node) => {
    drag(drop(node));
  };

  // Inside the TimelineSlot component, replace the current validation logic with this:
  const isCorrect = showValidation
    ? (() => {
        // Sort the timeline data to get the correct order
        const sortedEvents = [...timelineData[0].timeline].sort(
          (a, b) => a.year - b.year,
        );
        // Find the correct index where this event should be
        const correctIndex = sortedEvents.findIndex(
          (e) => e.year === event.year,
        );
        // Check if the current index matches where it should be
        return index === correctIndex;
      })()
    : null;

  return (
    <Paper
      ref={ref}
      sx={{
        maxWidth: 200,
        height: 200,
        textAlign: "center",
        opacity: isDragging ? 0 : 1,
        bgcolor: isOver ? "action.hover" : "background.paper",
        border: (theme) =>
          !isActive || showValidation
            ? `1px solid ${lighten(theme.palette.text.secondary, 0.3)}`
            : `1px dashed ${lighten(theme.palette.text.secondary, 0.3)}`,
        cursor: event && isActive && !showValidation ? "grab" : "default",
        borderRadius: 3,
        boxShadow: "none",
        position: "relative",
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        transform: isOver ? "scale(1.02)" : "scale(1)",
        transition: "transform 0.2s ease, background-color 0.2s ease",
        "&:hover": (theme) => ({
          background: `linear-gradient(to right, white, white) padding-box,
           linear-gradient(to right, transparent, transparent) border-box`,
          border:
            !isActive || showValidation
              ? `1px solid ${lighten(theme.palette.text.secondary, 0.3)}`
              : `2px dashed ${theme.palette.primary.main}`,
        }),
        "&:active": (theme) => ({
          cursor: event && isActive && !showValidation ? "grabbing" : "default",
          border:
            !isActive || showValidation
              ? `1px solid ${lighten(theme.palette.text.secondary, 0.3)}`
              : `2px dashed ${theme.palette.primary.main}`,
        }),
      }}
    >
      {event?.imageUrl && (
        <Box
          component="img"
          src={event.imageUrl}
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      )}
      {event && (
        <Box
          sx={{
            position: "relative",
            zIndex: 1,
            width: "100%",
            height: "100%",
            background: event.imageUrl
              ? `linear-gradient(rgba(0,0,0,0.45), rgba(0,0,0,0.45)),
                 ${
                   showValidation
                     ? `linear-gradient(to top, ${isCorrect ? "rgba(76,175,80,0.8)" : "rgba(244,67,54,0.8)"} 0%, ${isCorrect ? "rgba(76,175,80,0.6)" : "rgba(244,67,54,0.6)"} 100%)`
                     : "linear-gradient(to top, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.2) 100%)"
                 }`
              : showValidation
                ? isCorrect
                  ? "linear-gradient(135deg, #4CAF50 0%, #388E3C 100%)"
                  : "linear-gradient(135deg, #f44336 0%, #d32f2f 100%)"
                : "linear-gradient(135deg, #667eea 0%, #764ba2 100%)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "5px",
            opacity: isDragging ? 0 : 1,
            borderRadius: 2,
          }}
        >
          {showValidation && (
            <Chip
              size="small"
              icon={
                isCorrect ? <CheckCircleOutlineIcon /> : <CancelOutlinedIcon />
              }
              label={isCorrect ? "Rätt" : "Fel"}
              sx={{
                height: "24px",
                borderRadius: "8px",
                background: "white",
                color: isCorrect ? "#27792B" : "#FF4D4D",
                fontSize: "11px",
                fontWeight: 600,
                position: "absolute",
                top: 5,
                right: 5,
                "& .MuiChip-icon": {
                  fontSize: 14,
                  color: "inherit",
                  fontWeight: "600",
                },
              }}
            />
          )}
          <Box
            sx={{
              color: "white",
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                fontWeight: "500",
                color: "white",
                wordBreak: "break-word",
              }}
            >
              {event.event}
            </Typography>

            {showYears && (
              <Typography
                variant="body2"
                sx={{
                  position: "absolute",
                  bottom: "7px",
                  left: 0,
                  right: 0,
                  color: "white",
                  fontWeight: "500",
                }}
              >
                {event.year}
              </Typography>
            )}
          </Box>
        </Box>
      )}
    </Paper>
  );
};
//

const TimelineContent = ({
  timelineData,
  onTimelineDataChange,
  user,
  theme,
  viewMode,
}) => {
  const [isLocked, setIsLocked] = useState(timelineData[0]?.isLocked || false);

  const [showYears, setShowYears] = useState(false);
  const [showValidation, setShowValidation] = useState(false);

  const { showSnackbar } = useSnackbar();
  const [isGeneratingImages, setIsGeneratingImages] = useState(false);
  const [loadingImageIndex, setLoadingImageIndex] = useState(null);
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageInstructions, setImageInstructions] = useState("");
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  // Update events when timelineData changes
  useEffect(() => {
    if (timelineData[0]?.timeline) {
      const newEvents = timelineData[0].timeline.map((event, index) => ({
        ...event,
        originalIndex: index,
      }));
      setEvents(newEvents);
    }
  }, [JSON.stringify(timelineData)]); // This will detect deep changes in the timeline data

  const validateTimeline = () => {
    setShowValidation(!showValidation);
  };

  const handleImageButtonClick = (index = null) => {
    setSelectedImageIndex(index);
    setShowImageModal(true);
  };

  const handleCloseModal = () => {
    setShowImageModal(false);
    setImageInstructions("");
  };

  const handleGenerateSpecificImage = async (index) => {
    setIsGeneratingImages(true);
    setLoadingImageIndex(index);
    handleCloseModal();

    try {
      const timelineIndex = 0; // Since we're working with a single timeline
      const event = timelineData[timelineIndex].timeline[index];

      const imagePrompt = `Create a historical image depicting: "${event.event}" from ${event.year}. ${imageInstructions}`;
      const imageUrl = await generateAndProcessImage(imagePrompt, user.uid);

      if (imageUrl) {
        const newData = [...timelineData];
        newData[timelineIndex].timeline[index].imageUrl = imageUrl;
        onTimelineDataChange(newData);
        showSnackbar("Bilden har genererats!", "success");
      }
    } catch (error) {
      handleImageGenerationError(error, false, showSnackbar);
    } finally {
      setIsGeneratingImages(false);
      setLoadingImageIndex(null);
    }
  };

  const handleGenerateWithInstructions = async () => {
    if (selectedImageIndex !== null) {
      handleCloseModal();
      await handleGenerateSpecificImage(selectedImageIndex);
    }
  };

  const handleEditClick = () => {
    setIsEditDialogOpen(true);
  };

  const handleEditClose = () => {
    setIsEditDialogOpen(false);
  };

  const handleEditSave = (editedData) => {
    Logger.log(
      "editedData.timelines: ",
      JSON.stringify(editedData.timelines, null, 2),
    );
    onTimelineDataChange(editedData.timelines);
    setIsEditDialogOpen(false);
    showSnackbar("Ändringar sparade!", "success");
  };

  const resetOrder = () => {
    const sortedEvents = [...events].sort(
      (a, b) => a.originalIndex - b.originalIndex,
    );
    setEvents(sortedEvents);

    // Sync with parent timeline data
    const updatedData = [...timelineData];
    updatedData[0] = {
      ...updatedData[0],
      timeline: sortedEvents,
    };
    onTimelineDataChange(updatedData);
  };

  const randomizeOrder = () => {
    const shuffledEvents = [...events].sort(() => Math.random() - 0.5);
    setEvents(shuffledEvents);

    // Sync with parent timeline data
    const updatedData = [...timelineData];
    updatedData[0] = {
      ...updatedData[0],
      timeline: shuffledEvents,
    };
    onTimelineDataChange(updatedData);
  };
  const [events, setEvents] = useState(() =>
    timelineData[0]?.timeline.map((event, index) => ({
      ...event,
      originalIndex: index,
    })),
  );

  const moveItem = useCallback((dragIndex, hoverIndex) => {
    setEvents((prevEvents) => {
      const newEvents = [...prevEvents];
      const [movedItem] = newEvents.splice(dragIndex, 1);
      newEvents.splice(hoverIndex, 0, movedItem);
      return newEvents;
    });
  }, []);

  if (!timelineData || !timelineData.length) {
    return;
  }

  const handleLockToggle = () => {
    const newLockedState = !isLocked;
    setIsLocked(newLockedState);

    // Update the timeline data with the new locked state
    const updatedData = timelineData.map((timeline) => ({
      ...timeline,
      isLocked: newLockedState,
    }));
    onTimelineDataChange(updatedData);
  };

  const handleDeleteTimeline = () => {
    // Show confirmation dialog using the MUI Dialog component
    const confirmDelete = window.confirm(
      "Säker på att du vill ta bort tidslinjen?",
    );

    if (confirmDelete) {
      // Filter out the current timeline
      onTimelineDataChange(null);

      // Show success message
      showSnackbar("Tidslinjen raderad!", "success");
    }
  };

  return (
    <Box
      sx={{
        position: "relative",
        "&:hover .timeline-controls": { opacity: 1 },
      }}
    >
      <Typography variant="h6" sx={{ mb: 2, fontWeight: "600" }}>
        {timelineData[0].title}
      </Typography>
      {!isLocked && (
        <Box
          sx={{
            position: "absolute",
            left: "50%",
            bottom: -50,
            transform: "translate(-50%, -50%)",
            zIndex: 3,
          }}
        >
          <Button
            variant={showValidation ? "purp" : "success"}
            sx={{
              minWidth: 200,
            }}
            startIcon={<CheckCircleOutlineIcon />}
            onClick={validateTimeline}
          >
            {showValidation ? "Visa tidslinjen igen" : "Validera resultat"}
          </Button>
        </Box>
      )}
      <Box
        className="timeline-controls"
        sx={{
          position: "absolute",
          top: 8,
          right: 8,
          zIndex: 2,
          display: viewMode ? "none" : "flex",
          gap: 1,
          opacity: 0,
          transition: "opacity 0.2s ease",
        }}
      >
        <Tooltip title="Redigera" placement="top">
          <IconButton size="small" onClick={handleEditClick}>
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>

        <Tooltip title="Visa årtal/ordning" placement="top">
          <IconButton size="small" onClick={() => setShowYears(!showYears)}>
            <NumbersIcon fontSize="small" />
          </IconButton>
        </Tooltip>

        <Tooltip title="Visa korrekt ordningsföljd" placement="top">
          <IconButton size="small" onClick={resetOrder}>
            <RestartAlt fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Slumpa ordningen" placement="top">
          <IconButton size="small" onClick={randomizeOrder}>
            <Shuffle fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip
          placement="top"
          title={
            isLocked
              ? "Lås upp tidslinjen (interaktiv)"
              : "Lås tidslinjen (endast visuell)"
          }
        >
          <IconButton size="small" onClick={handleLockToggle}>
            {isLocked ? (
              <Lock fontSize="small" />
            ) : (
              <LockOpen fontSize="small" />
            )}
          </IconButton>
        </Tooltip>
        <Tooltip placement="top" title="Ta bort tidslinjen">
          <IconButton size="small" onClick={handleDeleteTimeline}>
            <DeleteOutline fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>

      <Paper
        sx={{
          display: "flex",
          gap: 2,
          overflowX: "auto",

          p: 4,
          mb: 10,
          borderRadius: 4,
          position: "relative",
          backgroundColor: theme.palette.background.paperHover,
          backgroundImage: "none",

          minWidth: 0, // Add this
          "& > *": {
            // Add this
            flex: "0 0 200px", // Add this - matches your TimelineSlot maxWidth
          },
          "&::-webkit-scrollbar": {
            height: 8,
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "background.paper",
            margin: "0 20px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: lighten(theme.palette.text.secondary, 0.8),
            borderRadius: 4,

            backgroundClip: "content-box", // Add this - ensures the thumb respects padding
          },
        }}
      >
        {events.map((event, index) => (
          <TimelineSlot
            key={event.originalIndex}
            index={index}
            event={event}
            onMove={moveItem}
            isActive={!isLocked}
            showYears={showYears}
            showValidation={showValidation}
            timelineData={timelineData} // Pass the full timeline data
          />
        ))}
      </Paper>

      <TimeBuilderEditDialog
        open={isEditDialogOpen}
        onClose={handleEditClose}
        lessonData={{ timelines: timelineData }}
        onSave={handleEditSave}
        isGeneratingImages={isGeneratingImages}
        loadingImageIndex={loadingImageIndex}
        onGenerateSpecificImage={handleGenerateSpecificImage}
        onGenerateAllImages={() => handleImageButtonClick(null)}
        user={user}
        theme={theme}
        showSnackbar={showSnackbar}
        handleImageButtonClick={handleImageButtonClick}
        fromLessonTimelineDisplay={true}
      />

      <ImageGenerationModal
        open={showImageModal}
        onClose={handleCloseModal}
        onGenerate={handleGenerateWithInstructions}
        isGenerating={isGeneratingImages}
        instructions={imageInstructions}
        setInstructions={setImageInstructions}
        theme={theme}
      />
    </Box>
  );
};

// Wrapper component that provides the DND context
const TimelineDisplay = ({
  timelineData,
  onTimelineDataChange,
  viewMode,
  user,
  theme,
}) => {
  return (
    <DndProvider backend={HTML5Backend}>
      <TimelineContent
        timelineData={timelineData}
        onTimelineDataChange={onTimelineDataChange}
        viewMode={viewMode}
        user={user}
        theme={theme}
      />
    </DndProvider>
  );
};

export default TimelineDisplay;
