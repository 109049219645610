import React, { useState, useRef } from "react";
import pptxgen from "pptxgenjs";
import { Button } from "@mui/material";
import PresentationIcon from "@mui/icons-material/Slideshow";
import CircularProgress from "@mui/material/CircularProgress";

import PowerPointIcon from "./PowerPointIcon";

const PptxGenerator = ({
  lessonData,
  theme,
  disabled,
  buttonText = "Powerpoint", // New prop with default value
  loadingText = "Genererar...", // New prop for loading state text
  fromPowerPointGenerator = false,
  variant = "print",
  sx = {},
  buttonRef,
}) => {
  const [isGenerating, setIsGenerating] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const THEME = {
    colors: {
      primary: "#6424c1",
      secondary: "#f5f5f5",
      text: "#333333",
      white: "#FFFFFF",
      black: "#000000",
      titleSlide: "#1a237e", // Deep indigo background for first slide
    },
    fonts: {
      heading: "Gill Sans MT",
      body: "Gill Sans MT",
    },
  };

  const generatePresentation = async () => {
    try {
      setIsGenerating(true);
      const pres = new pptxgen();

      pres.layout = "LAYOUT_16x9";
      pres.author = "Studera.ai";

      let slide = pres.addSlide();
      slide.background = { color: THEME.colors.titleSlide };

      if (!fromPowerPointGenerator) {
        slide.addText(
          [
            {
              text: "Grej of the Day\n\n",
              options: {
                fontSize: 44,
                color: THEME.colors.white,
                bold: true,
                fontFace: THEME.fonts.heading,
              },
            },
            {
              text: lessonData.clue + "...",
              options: {
                fontSize: 32,
                color: THEME.colors.white,
                fontFace: THEME.fonts.body,
              },
            },
          ],
          {
            x: 0.5,
            y: "30%",
            w: "90%",
            h: "40%",
            align: "center",
            valign: "middle",
          },
        );
        slide = pres.addSlide();
        slide.background = { color: THEME.colors.titleSlide };
      }

      // Topic reveal

      slide.addText(lessonData.topic, {
        x: 0.5,
        y: 1.5,
        w: "90%",
        fontSize: 60,
        color: THEME.colors.white,
        bold: true,
        align: "center",
        fontFace: THEME.fonts.heading,
      });

      if (lessonData.topicImage) {
        slide.addImage({
          path: lessonData.topicImage,
          x: "32%", // centering the 4x4 container on the slide
          y: 2.5,
          sizing: {
            type: "contain",
            w: 4,
            h: 4,
          },
        });
      }

      // Fact slides
      Object.entries(lessonData.highLevelFacts).forEach(([section, data]) => {
        slide = pres.addSlide();
        slide.background = { color: THEME.colors.white };

        // Section title
        slide.addText(section, {
          x: 0.5,
          y: 0.5,
          w: "90%",
          fontSize: 32,
          color: THEME.colors.titleSlide,
          bold: true,
          fontFace: THEME.fonts.heading,
        });

        const startY = data.image ? 1.3 : 1.5;
        const textWidth = data.image ? "45%" : "90%";
        const textX = data.image ? 5 : 0.5;

        if (data.image) {
          slide.addImage({
            path: data.image,
            x: 0.5,
            y: 1.3,
            w: 4,
            h: 4,
          });
        }

        // Bullet points with body font
        data.facts.forEach((fact, index) => {
          const yPos = startY + index * 1.2;
          slide.addText(fact, {
            x: textX,
            y: yPos,
            w: textWidth,
            h: 1, // Fixed height that's tall enough for a good clickable area
            fontSize: 18,
            color: THEME.colors.text,
            bullet: true,
            breakLine: true,
            fontFace: THEME.fonts.body,
            valign: "top", // Align text to top of box
            margin: 10,
          });
        });
      });

      await pres.writeFile({
        fileName: `${lessonData.topic} - Studera.ai.pptx`,
      });

      setNotification({
        open: true,
        message: "Presentation generated successfully!",
        severity: "success",
      });
    } catch (error) {
      console.error("Failed to generate presentation:", error);
      setNotification({
        open: true,
        message: "Failed to generate presentation. Please try again.",
        severity: "error",
      });
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <>
      <Button
        ref={buttonRef}
        variant={variant}
        onClick={generatePresentation}
        disabled={isGenerating || disabled}
        startIcon={
          isGenerating ? (
            <CircularProgress sx={{ color: "white" }} size={16} />
          ) : (
            <PowerPointIcon />
          )
        }
        sx={{ ml: 2, ...sx }}
      >
        {isGenerating ? loadingText : buttonText}
      </Button>
    </>
  );
};

export default PptxGenerator;
